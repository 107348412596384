/**
 *  External Imports
 */
import Grid from "@mui/material/Grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {arrayOf, func, number, shape, string} from "prop-types";

/**
 *  Internal Imports
 */
import { getCommunityStatus } from "../../../api/onboarding-api";
import styles from "./index.module.css";
import useScrollToTop from "../../commons/scroll-top";
import CommunityWidgetBody from "../../controls/community-widget-body";
import WidgetCompleteProfile from "./widget-complete-profile";
import WidgetFindConnections from "./widget-find-connections";
import CommunityConnectModal from "../../controls/community-connect-modal";
import {communityMappedAPI} from "../../../api/community-api";
import {WIDGET_TYPES} from "../../../constants/widget-types";
import {useConnectionsUsersList, useUserListFilter} from "../../../utils/hooks";


const CommunityRecommended = (props) => {
    // Variables
    const { recommendedUsers } = props;
	const { initUsers, loadMoreUsers } = props;

    const [searchTerm, setSearchTerm] = useState("");

	const { isFullyLoaded, isLoaded, loadMoreData, setSortBy, isSortLoaded } = 
		useConnectionsUsersList(initUsers, loadMoreUsers, communityMappedAPI.getMappedRecommendations);

	const { filteredUserList } = useUserListFilter(searchTerm, recommendedUsers);

    const [isCompleteProfile, setIsCompleteProfile] = useState(true);
    const [profileProgressVal, setProfileProgressVal] = useState(0);

    const initDataCancelTokenSrc = axios.CancelToken.source();

    const onSearchChange = (value) => {
		setSearchTerm(value);
	};

    // Init Functions
    const initData = async () => {
        // Variables
        let data, currProfileProgressVal, isCompleteCurrProfile;

        // Emit a request and checking a status
        data = await getCommunityStatus(initDataCancelTokenSrc.token);

        if (data?.isCanceled) {
            return;
        }

        // Preparing a progress data
        currProfileProgressVal = data?.percent_complete ?? 0;
        isCompleteCurrProfile = currProfileProgressVal > 99;

        // Setting the data
        setIsCompleteProfile(isCompleteCurrProfile);
        setProfileProgressVal(currProfileProgressVal);
    };

    // Functions Call
    useScrollToTop();

    // Life Cycles
    useEffect(() => {
        initData();

        return () => initDataCancelTokenSrc.cancel();
    }, []);

    // Return
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            className={styles.body}>
            <Grid container item xs={8}>
                <div className={`${styles.bodyItem} ${styles.bodyItem_md}`}>
                    <CommunityWidgetBody 
                        data-testid="widgetBody"
                        usersList={filteredUserList}
                        isLoaded={isLoaded}
                        isFullyLoaded={isFullyLoaded}
                        isSortLoaded={isSortLoaded}
                        title={"Recommended"}
                        inputPlaceholder={"Search Recommended Connections"}
                        setSortBy={setSortBy}
                        onSearchChange={onSearchChange}
                        loadMoreData={loadMoreData}
                        widgetType={WIDGET_TYPES.RECOMMENDED}
                    />
                </div>
            </Grid>

            <Grid
                container
                item
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                xs={4}>
                {!isCompleteProfile && (
                    <div className={styles.bodyItem}>
                        <WidgetCompleteProfile
                            profileProgress={profileProgressVal}
                        />
                    </div>
                )}

                <div className={styles.bodyItem}>
                    <WidgetFindConnections data-testid="widgetFindConnections" />
                </div>
            </Grid>
            <CommunityConnectModal />
        </Grid>
    );
};

CommunityRecommended.propTypes = {
	recommendedUsers: arrayOf(
		shape(
			{
				idRecommendation: number.isRequired,
				userIcon: string.isRequired,
				name: string.isRequired,
				organization: string.isRequired,
				reason: string.isRequired,
				status: string.isRequired,
			}
		)
	).isRequired,
	
	initUsers: func.isRequired,
	loadMoreUsers: func.isRequired,
};

/**
 *  Exports
 */
export default CommunityRecommended;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import ProjectCard from './ProjectCard';

function ProjectsGrid({
  projects,
  caseCounts,
  selectedProjects,
  onProjectClick,
  onCheckToggle
}) {
  return (
    <Grid container spacing={3}>
      {projects?.map((p) => (
        <Grid key={p.projectId} item xs={4}>
          <ProjectCard
            key={p.projectId}
            heading={p.studyName}
            subHeading={p.projectId}
            projectId={p.projectId}
            available={p.isAccessible}
            description={p.descriptionOfStudy}
            onClick={() => onProjectClick(p, caseCounts)}
            checked={!!selectedProjects[p.projectId]}
            {...{ onCheckToggle }}
          />
        </Grid>
      ))}
    </Grid>
  );
}

ProjectsGrid.propTypes = {
  projects: PropTypes.array,
  selectedProjects: PropTypes.object,
  onCheckToggle: PropTypes.func,
};

export default ProjectsGrid;

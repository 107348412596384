

import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Classes from './index.module.css'
import DropZone from 'react-dropzone';
import DocumentIcon from '../../../assets/images/Doc.M.png';
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty';
import LinearProgress from '@mui/material/LinearProgress';
import ErrorText from '../error-text';
import OutlinedInput from '@mui/material/OutlinedInput';
import ClosePng from '../../../assets/images/close.png'
import map from 'lodash/map'
import v4 from 'uuid/v4'
import { DCA_UPLOAD_IRB_KEY, DCA_UPLOAD_LOGO_KEY, DCA_FORM_COMPLETED_TEXT } from '../../../constants/strings';
import { getDCADownloadUrl, deleteDCADIFFile, getProjectDetailsUploadUrl } from '../../../api/dca-form-api'
import Modal from '../../commons/modal'
import { uploadFileToS3 } from '../../../utils/aws-sdk-helper';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
        borderLeft: '1px dotted'
    },
    float: {
        flexGrow: 1,
        marginRight: "15px"
    },
    clearfix: {
        clear: 'both'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const ProjectDetails = (props) => {
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const {
        projectDetailsProjectName,
        projectDetailsSynopsisText,
        projectDetailsIRBApprovalNumber,
        projectDetailsIRBNumberFile,
        projectDetailsDesignatedZone,
        projectDetailsRestrictionOfUSeText,
        projectDetailsIsDatasetDownloadable,
        projectDetailsAckStatement,
        projectDetailsTypeOfData,
        projectDetailsOtherTypeDataText,
        projectDetailsTotalSizeOfData,
        projectDetailsLogoFile,
        projectDetailsObjectiveOfStudy,
        projectDetailsDescriptionOfStudy,
        projectDetailsDataDescription,
        projectDetailsStudyDesign,
        projectDetailsNumberOfGrants,
        projectDetailsDurationOfMonths,
        projectDetailsLocationOfStudy,
        projectDetailsPublications,
        isFormSubmitted,
        isResearcherAllowed,
        presentUUID,
        logoS3Key,
        irbApprovalCopyS3Key, 
        currentDCAFormStage,
        
        setProjectDetailsProjectName,
        setProjectDetailsProjectSynopsisText,
        setProjectDetailsIRBApprovalNumber,
        setIRBNumberFile,
        setDesignatedZone,
        setRestrictionOfUseText,
        setIsDatasetDownloadable,
        setAckStatement,
        setTypeOfData,
        setOtherTypeDataText,
        setTotalSizeOfData,
        setLogoFile,
        setObjectiveOfStudy,
        setDescriptionOfStudy,
        setDataDescription,
        setStudyDesign,
        setNumberOfGrants,
        setDurationOfMonths,
        setLocationOfStudy,
        setProjectDetailsPublications,
        removeProjectDetailsPublications,
        setPublicationName,
        setPublicationURL,
        setIsResearcherAllowed,
        updateSnackBar,
        setProjectDetailsIRBApprovalKey
    } = props;

    const [ addPublicationClick, setAddPublicationClick ] = useState(false);
    const [ fileKey, setFileKey ] = useState('');
    const [ deleteFileModal, setDeleteFileModal ] = useState(false);
    const [ uploadPercentage, setUploadPercentage ] = useState(0);
    const validPublications = () => {
        let isFormValid = false;
        map(projectDetailsPublications, (usersData)=>{
            for(let key in usersData){
                if(!isEmpty(usersData[key])){
                    isFormValid = true;
                }else{
                    isFormValid = false;
                    break;
                }
            }
        })
        return isFormValid;
    }

    function handleAddFields() {
        setAddPublicationClick(true);
        const isValidPublication = validPublications();
        if(isValidPublication){
            setProjectDetailsPublications({publicationName: '', publicationUrl: ''});
            setAddPublicationClick(false);
        } 

    }
    const onDownloadFileClick = async (key) => {
        try {
            const url = await getDCADownloadUrl(key);
            if (!isEmpty(url)) {
                window.open(url, "_blank")
            }
        } catch (error) {
            console.log('error in getting download url');
        }
    }
    const removePublications = (index) => {
        removeProjectDetailsPublications(index);
    }
    
    const onProjectNameChange = event => {
        setProjectDetailsProjectName(event.target.value);
    }
    const onProjectSynopsisChange = event => {
        setProjectDetailsProjectSynopsisText(event.target.value);;
    }
    const onIRBNumberChange = event => {
        setProjectDetailsIRBApprovalNumber(event.target.value);;
    }
    const onIrbNumberFileDrop = async (fileData) => {
        try {
            setIRBNumberFile(fileData);
            const fileKeyName = DCA_UPLOAD_IRB_KEY;
            const preSignedData = await getProjectDetailsUploadUrl(presentUUID);
            let config = {
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadPercentage(percentCompleted);
                }
            };
            const res = await uploadFileToS3(fileData[0], preSignedData, config);
                setUploadPercentage(0);
                updateSnackBar('File uploaded successfully.', 'success');
                setProjectDetailsIRBApprovalKey(`${presentUUID}/${fileData[0]?.name}`);
            
        } catch (error) {
            console.log('error in getting url', error);
            setUploadPercentage(0);
            updateSnackBar('Error in file upload.', 'error');
            setIRBNumberFile({});
            setProjectDetailsIRBApprovalKey('');
        }
    }

    const onDesignatedZoneChange = event => {
        setDesignatedZone(event.target.value);
    }
    const onRestrictionsOfUseChange = event => {
        setRestrictionOfUseText(event.target.value);
    }
    const onDatasetDownloadableChange = event => {
        setIsDatasetDownloadable(event.target.value);
    }
    const onResearcherAllowedChange = event => {
        setIsResearcherAllowed(event.target.value);
    }
    const onAckStatementChange = event => {
        setAckStatement(event.target.value);
    }
    const onTypeOfDataChange = event => {
        setTypeOfData(event.target.value);
    }
    const onOtherTypeDataChange = event => {
        setOtherTypeDataText(event.target.value);
    }
    const onTotalSizeOfDataChange = event => {
        setTotalSizeOfData(event.target.value);
    }
    const onDeleteFile = (key) => {
        setFileKey(key);
        setDeleteFileModal(true);
    }
    const closeDeleteFileModal = () => {
        setFileKey('');
        setDeleteFileModal(false);
    }
    const onDeleteFileConfirm = async () => {
        try {
            const body = {
                "type": "dca",
                "key": fileKey
            }
            const res = await deleteDCADIFFile(body);
            setFileKey('');
            setDeleteFileModal(false);
            setProjectDetailsIRBApprovalKey('');
            setIRBNumberFile({});
            props.updateSnackBar('File deleted successfully', 'success');
            
        } catch (error) {
            props.updateSnackBar('Error in deleting file', 'error');
            setDeleteFileModal(false);
        }
    }
   const FILE_DELETE = {
    modalTitle: 'Are you sure you want to delete this file?',
    positiveButtonText: 'Delete',
    negativeButtonText: "Cancel",
    positiveButtonAction: onDeleteFileConfirm,
    negativeButtonAction: closeDeleteFileModal
}
    const disableTextInput =  (currentDCAFormStage === DCA_FORM_COMPLETED_TEXT);
    const originalFileName = !isEmpty(irbApprovalCopyS3Key) ? irbApprovalCopyS3Key?.substring(irbApprovalCopyS3Key.indexOf('/')+1) || '': '';
    return (
        <div className={`${Classes.container} ${Classes.mainContainer}`} id='cvb-dca-stage3ProjectDetailsContainer'>
            <Modal
                    open={deleteFileModal}
                    handleClose={closeDeleteFileModal}
                    dialogProps={FILE_DELETE}
                    disableTitleDivider={true}
                />
            <h3 className={Classes.formTitle}>Project Details</h3>
            <Grid container spacing={3} direction='column'>
                <Grid item xs={6}>
                    <div className={Classes.fieldCont}>
                        <label id='cvb-dca-stage3ProjectNameLabel'>Project Name<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={projectDetailsProjectName}
                            onChange={onProjectNameChange}
                            placeholder='Insert descriptive project/study name'
                            name='projectDetailsProjectName'
                            id='projectDetailsProjectName'
                            disabled={disableTextInput}
                        />
                        {
                            isFormSubmitted && isEmpty(projectDetailsProjectName) &&
                            <ErrorText errorMessage="Enter valid project name" />
                        }
                    </div>
                </Grid>
                <Grid item xs={10}>
                    <div className={Classes.textareaCont}>
                        <label>Project Synopsis* (100 words max):</label>
                        <textarea
                            type="text"
                            name="Project Synopsis"
                            onChange={onProjectSynopsisChange}
                            placeholder="Please insert a descriptive narrative paragraph of the project for posting in BRAINCommons"
                            value={projectDetailsSynopsisText}
                            rows={4}
                            id='projectSynopsis'
                            disabled={disableTextInput}
                        >
                        </textarea>
                        {
                            isFormSubmitted && isEmpty(projectDetailsSynopsisText) &&
                            <ErrorText errorMessage="Enter project synopsis" />
                        }
                    </div>
                </Grid>
                <Grid item xs={11}>
                <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <label id='cvb-dca-stage3IRBLabel' htmlFor='irbNumber'>IRB Approval Number (Optional)</label>
                        <p id='cvb-dca-stage3IRBDescription'>If contributed data was gathered for research research provide IRB Approval Number and attach Copy of approval</p>
                    </div>
                    <Grid container>
                        <Grid item xs={6}>
                            <OutlinedInput
                                fullWidth
                                value={projectDetailsIRBApprovalNumber}
                                onChange={onIRBNumberChange}
                                placeholder='Enter number'
                                name='irbNumber'
                                id='irbNumber'
                                disabled={disableTextInput}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div className={Classes.fieldCont} id='cvb-dca-stage3uploadContainer'>
                        <div>
                            <label id='cvb-dca-stage3uploadDescription'>Attach copy of approval (If applicable)</label>
                        </div>
                        <Grid container>
                            <Grid xs={6} className={Classes.dropzoneWrapper}>
                                <div className={Classes.dropZoneButtonContainer} id='cvb-dca-stage3uploadDropBlock'>
                                    <DropZone
                                        onDrop={(acceptedFiles) => onIrbNumberFileDrop(acceptedFiles)}
                                        multiple={false}
                                        disabled={disableTextInput}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <div className={Classes.uploadImageDiv}>
                                                    <img src={DocumentIcon} alt='document' />
                                                    <p className={Classes.chooseFileText} id='cvb-dca-stage3uploadDropText'>
                                                        Drag and drop or
                                                            <span>
                                                            <button className={`${Classes.chooseFileButton}`}>
                                                                Select files
                                                                </button>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </DropZone>
                                    {/* <LinearProgress variant="determinate" value={onImageDrop(props.value)} /> */}
                                </div>
                                <progress className={Classes.progressBar} value={uploadPercentage} max={100}/>
                                <p className={Classes.fileNameText} id='cvb-dca-stage3uploadFileName'>
                                        {!isEmpty(projectDetailsIRBNumberFile) ? get(projectDetailsIRBNumberFile, '[0].name', '')
                                        :  originalFileName }
                                </p>
                                {!isEmpty(irbApprovalCopyS3Key) &&
                                    <div className={Classes.downloadDeleteButtonWrapper}>
                                        <button id='cvb-dca-stage3uploadFileDownloadButton' className={`solid-button medium-size-button ${Classes.actionButtons}`}
                                            onClick={() => onDownloadFileClick(irbApprovalCopyS3Key)}>
                                            Download
                                    </button>
                                    {!disableTextInput && <button id='cvb-dca-stage3uploadFileDeleteButton' className={`solid-button medium-size-button ${Classes.actionButtons} ${Classes.deleteButton}`}
                                            onClick={() => onDeleteFile(irbApprovalCopyS3Key)}>
                                            Delete
                                    </button>}
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                {/* {(isCertificateSubmit && isEmpty(selectedCertificatedNameToUpload))
                    && <div errorMessage={'Please select file to upload'} />
                } */}
                {/* <p className={Classes.fileNameAndSize}>{`${fileNameToUpload}`} <span>{`${fileSize}`} </span></p> */}
                <Grid item xs={11}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={10}>
                    <div className={Classes.fieldCont}>
                        <div>
                            <label id='cvb-dca-stage3DesignatedZone'>Designated Zone<sup> *</sup></label>
                            <p id='cvb-dca-stage3ZoneDescription'>Zone designation applies to entire dataset</p>
                        </div>
                        <div className={Classes.flexContainer}>
                            <Grid container justifyContent='space-between'>
                                <Grid item xs={4} className={Classes.radioCheckContainer}>
                                    <label id='cvb-dca-designatedZones1Label' className={Classes.container}> Zone 1 (Public Access )</label>
                                    <input disabled={disableTextInput} id='cvb-dca-designatedZones1' type="radio" checked={ projectDetailsDesignatedZone === 'zone1'} name="zone" value='zone1' onChange={onDesignatedZoneChange} />
                                    <span className={Classes.checkmark}></span>
                                </Grid>
                                <Grid item xs={4} className={Classes.radioCheckContainer}>
                                    <label id='cvb-dca-designatedZones2Label' className={Classes.container}>Zone 2 (Controlled Access)</label>
                                    <input disabled={disableTextInput} id='cvb-dca-designatedZones2' type="radio" checked={ projectDetailsDesignatedZone === 'zone2'} name="zone" value='zone2' onChange={onDesignatedZoneChange}/>
                                    <span className={Classes.checkmark}></span>
                                </Grid>
                                <Grid item xs={4} className={Classes.radioCheckContainer}>
                                    <label id='cvb-dca-designatedZones3Label' className={Classes.container}>Zone 3 (Restricted Access)</label>
                                    <input disabled={disableTextInput} id='cvb-dca-designatedZones3' type="radio" checked={ projectDetailsDesignatedZone === 'zone3'} name="zone" value='zone3' onChange={onDesignatedZoneChange}/>
                                    <span className={Classes.checkmark}></span>
                                </Grid>
                            </Grid>
                        </div>
                        {
                            isFormSubmitted && isEmpty(projectDetailsDesignatedZone) &&
                            <ErrorText errorMessage="Please select above field" />
                        }
                    </div>
                </Grid>
                {  projectDetailsDesignatedZone === 'zone2' &&  
                    <Grid item xs={10} >
                        <label>Is use by qualified researchers from industry allowed ?<sup> *</sup></label>
                        <Grid container>
                            <Grid item xs={2} className={Classes.radioCheckContainer}>
                                <label className={Classes.container}>Yes</label>
                                <input disabled={disableTextInput} id='cvb-dca-researcherAllowed' type="radio" checked={isResearcherAllowed === "yes"} value='yes' onChange={onResearcherAllowedChange} name="researcherAllowed" />
                                <span className={Classes.checkmark}></span>
                            </Grid>
                            <Grid item xs={2} className={Classes.radioCheckContainer}>
                                <label className={Classes.container}>No</label>
                                <input disabled={disableTextInput} id='cvb-dca-researcherNotAllowed' type="radio" checked={isResearcherAllowed === "no"} onChange={onResearcherAllowedChange} value='no' name="researcherAllowed" />
                                <span className={Classes.checkmark}></span>
                            </Grid>
                        </Grid>
                        {
                            isFormSubmitted && isEmpty(projectDetailsIsDatasetDownloadable) &&
                            <ErrorText errorMessage="Please select above field" />
                        }
                    </Grid>
                }
                { projectDetailsDesignatedZone === 'zone3' && <Grid item xs={10}>
                    <div className={Classes.textareaCont}>
                        <label id='cvb-dca-stage3RestrictionOfUseLabel'>Restriction On Use</label>
                        <textarea
                            type="text"
                            name="restrictionOfUse"
                            onChange={onRestrictionsOfUseChange}
                            placeholder="If submitting to zone 3, please indicate if there are ANY restrictions on use."
                            value={projectDetailsRestrictionOfUSeText}
                            rows={4}
                            id='restrictionOfUse'
                            disabled={disableTextInput}
                        >
                        </textarea>
                        {/* {
                        isFormSubmitted && isEmpty(projectDetailsRestrictionOfUSeText) &&
                        <ErrorText errorMessage="Enter valid project name" />
                    } */}
                    </div>
                </Grid>}
                <Grid item xs={11}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={10} >
                    <label id='cvb-dca-stage3DatasetUsers'>Dataset downloadable by Users?<sup> *</sup></label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.container} id='cvb-dca-datasetDownloadableYes'>Yes</label>
                            <input disabled={disableTextInput} id='datasetDownloadable' type="radio" checked={projectDetailsIsDatasetDownloadable === "yes"} value='yes' onChange={onDatasetDownloadableChange} name="dataset" />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.container} id='cvb-dca-datasetDownloadableNo'>No</label>
                            <input disabled={disableTextInput} type="radio" id='cvb-dca-datasetDownloadableFieldNo' checked={projectDetailsIsDatasetDownloadable === "no"} onChange={onDatasetDownloadableChange} value='no' name="dataset" />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                    {
                            isFormSubmitted && isEmpty(projectDetailsIsDatasetDownloadable) &&
                            <ErrorText errorMessage="Please select above field" />
                        }
                </Grid>
                <Grid item xs={10} >
                    <div className={Classes.textareaCont}>
                        <label id='cvb-dca-acknowledgeStatementLabel'>Acknowledging statement (optional)</label>
                        <p id='cvb-dca-acknowledgeStatementDescription' >Data users will be required insert statement acknowledging the Data Contributor in their grants/ publications.
                        Please indicate exact wording for attribution to be inserted in this in these documents.
                        </p>
                        <textarea
                            rows={4}
                            type="text"
                            name="ackStatement"
                            onChange={onAckStatementChange}
                            placeholder="Insert attribution phrase to be associated with the Contributed Data." 
                            id='ackStatement'  
                            value={projectDetailsAckStatement} 
                            disabled={disableTextInput} 
                        >
                        </textarea>
                    </div>
                </Grid>
                <Grid item xs={12}>
                        <label id='cvb-dca-typeOfDataLabel'>Type of Data<sup> *</sup></label>
                        <Grid container>
                            <Grid item xs={4} className={Classes.radioCheckContainer}>
                                <label className={Classes.container} id='cvb-dca-GenomeLabel'> Genome Sequencing  </label>
                                <input disabled={disableTextInput} onChange={onTypeOfDataChange} id='cvb-dca-GenomeRadio' type="radio" 
                                checked={projectDetailsTypeOfData === 'genomic'} name="dataType" value='genomic'/>
                                <span className={Classes.checkmark}></span>
                            </Grid>
                            <Grid item xs={2} className={Classes.radioCheckContainer}>
                                <label id='cvb-dca-ImagingLabel'  className={Classes.container}>Imaging</label>
                                <input disabled={disableTextInput} id='cvb-dca-ImagingField' onChange={onTypeOfDataChange} type="radio" 
                                checked={projectDetailsTypeOfData === 'imaging'} name="dataType" value='imaging' />
                                <span className={Classes.checkmark}></span>
                            </Grid>
                            <Grid item xs={2} className={Classes.radioCheckContainer}>
                                <label className={Classes.container} id='cvb-dca-PreclinicalLabel'>Preclinical </label>
                                <input onChange={onTypeOfDataChange} id='cvb-dca-PreclinicalField' type="radio" disabled={disableTextInput}
                                checked={projectDetailsTypeOfData === 'preclinical'} name="dataType" value='preclinical'/>
                                <span className={Classes.checkmark}></span>
                            </Grid>
                            <Grid item xs={2} className={Classes.radioCheckContainer}>
                                <label className={Classes.container} id='cvb-dca-clinicalLabel'> Clinical</label>
                                <input onChange={onTypeOfDataChange} id='cvb-dca-clinicalField' type="radio" disabled={disableTextInput}
                                checked={projectDetailsTypeOfData === 'clinical'} name="dataType" value='clinical'/>
                                <span className={Classes.checkmark}></span>
                            </Grid>
                        </Grid>
                        {
                            isFormSubmitted && isEmpty(projectDetailsTypeOfData) &&
                            <ErrorText errorMessage="Please select above field" />
                        }
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems='center'>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={`${Classes.container} ${Classes.typeOfDataOtherLabel}`} id='cvb-dca-otherLabel'>Other</label>
                            <input disabled={disableTextInput} onChange={onTypeOfDataChange} id='cvb-dca-otherField' type="radio" 
                             checked={projectDetailsTypeOfData === 'other'} value='other' name="dataType"  />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        {projectDetailsTypeOfData === 'other' && <Grid xs={4}>
                            <OutlinedInput
                                type="text"
                                fullWidth
                                name="otherTypeData"
                                onChange={onOtherTypeDataChange}
                                placeholder="Please provide description"
                                id='otherTypeData'
                                value={projectDetailsOtherTypeDataText}
                                disabled={disableTextInput}
                            />
                            {
                            isFormSubmitted && (projectDetailsTypeOfData === 'other' && isEmpty(projectDetailsOtherTypeDataText)) &&
                            <ErrorText errorMessage="Enter description" />
                        }
                        </Grid>
                          
                        }
                    </Grid>
                      
                </Grid>
                <Grid item xs={6}>
                        <label id='cvb-dca-estimateLabel'>Estimated total size of data (Terabytes/year)</label>
                        <OutlinedInput
                            type="text"
                            fullWidth
                            id='cvb-dca-estimateField'
                            name="Project Details"
                            onChange={onTotalSizeOfDataChange}
                            placeholder="Insert approximate volume of dataset"
                            value={projectDetailsTotalSizeOfData}
                            disabled={disableTextInput}
                        />
                         {/* {
                            isFormSubmitted && isEmpty(projectDetailsTotalSizeOfData) &&
                            <ErrorText errorMessage="Enter valid project name" />
                        } */}
                </Grid>

            </Grid>
        </div>
    );
}

ProjectDetails.defaultProps = {
    projectDetailsProjectName: '',
    projectDetailsSynopsisText: '',
    projectDetailsIRBApprovalNumber: '',
    projectDetailsIRBNumberFile: null,
    projectDetailsDesignatedZone: '',
    projectDetailsRestrictionOfUSeText: '',
    projectDetailsIsDatasetDownloadable: '',
    projectDetailsAckStatement: '',
    projectDetailsTypeOfData: '',
    projectDetailsOtherTypeDataText: '',
    projectDetailsTotalSizeOfData: '',
    projectDetailsLogoFile: null,
    projectDetailsObjectiveOfStudy: '',
    projectDetailsDescriptionOfStudy: '',
    projectDetailsDataDescription: '',
    projectDetailsStudyDesign: '',
    projectDetailsNumberOfGrants: '',
    projectDetailsDurationOfMonths: '',
    projectDetailsLocationOfStudy: '',
    projectDetailsPublications: [],
    isFormSubmitted: false,
    isResearcherAllowed: '',
    presentUUID: '',
    logoS3Key: '',
    irbApprovalCopyS3Key: '',
    currentDCAFormStage: '',

    setProjectDetailsProjectName: () => { },
    setProjectDetailsProjectSynopsisText: () => { },
    setProjectDetailsIRBApprovalNumber: () => { },
    setIRBNumberFile: () => { },
    setDesignatedZone: () => { },
    setRestrictionOfUseText: () => { },
    setIsDatasetDownloadable: () => { },
    setAckStatement: () => { },
    setTypeOfData: () => { },
    setOtherTypeDataText: () => { },
    setTotalSizeOfData: () => { },
    setLogoFile: () => { },
    setObjectiveOfStudy: () => { },
    setDescriptionOfStudy: () => { },
    setDataDescription: () => { },
    setStudyDesign: () => { },
    setNumberOfGrants: () => { },
    setDurationOfMonths: () => { },
    setLocationOfStudy: () => { },
    setProjectDetailsPublications: () => { },
    removeProjectDetailsPublications: () => { },
    setPublicationURL: () => { },
    setPublicationName: () => { },
    setIsResearcherAllowed: () => { },
    updateSnackBar: () => { },
    setProjectDetailsIRBApprovalKey: () => { }
}

export default ProjectDetails;

import {
    SET_BANNER_COOKIE,
    SET_ON_OFF_COOKIE
} from '../action-types'

const initialState = {
    showCookieBanner: false,
    onOffCookie: {
        all: false,
        performance: true,
        socialMedia: false
    }
}

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BANNER_COOKIE: {
                return {
                    ...state,
                    showCookieBanner: action.payload
                }
            
        }
        case SET_ON_OFF_COOKIE: {
            return {
                ...state,
                onOffCookie: action.payload 
            }
        
    }
        default : return state
    }
}

export default homeReducer;
import axios from 'axios'
import {env} from '../env'
import get from 'lodash/get'
import { getCognitoIdToken } from './utilityMethods';
import { Auth } from 'aws-amplify'

const CERTIFICATE_BASE_URL = `${env.REACT_APP_BASE_API_PATH}/user`;

export const getCertificates = async (userSub='') => {
    try {
        const token = await getCognitoIdToken();
        let sub = userSub;
        if(!sub){
            const userValues = await Auth.currentUserInfo();
            sub = userValues?.attributes?.sub;
        }
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${CERTIFICATE_BASE_URL}/certificates/${sub}`, { headers: header });
        return get(res, 'data', []);
    } catch (error) {
        throw Error(error.message);
    }
}

export const getCertificateUrl = async (certificateName, userSub='') => {
    try{
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        let sub = userSub;
        if(!sub){
            const userValues = await Auth.currentUserInfo();
            sub = userValues?.attributes?.sub;
        }
        const res = await axios.get(`${CERTIFICATE_BASE_URL}/certificate/${sub}/${certificateName}`, { headers: header });
        return get(res, 'data', {});
    }catch(error){
        console.log('error in getting cert url', error)
    }
}

export const postCertificateDetails = async (data) => {
    try{
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.post(`${CERTIFICATE_BASE_URL}/certificate/upload`, data, { headers: header });
        return get(res, 'data', {});
    }catch(error){
        console.log('error in getting cert url', error)
    }
}

export const deleteCertificate = async (name) => {
    try{
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.delete(`${CERTIFICATE_BASE_URL}/certificate/${name}`,{ headers: header });
        return get(res, 'status', '');
    }catch(error){
        throw Error(error.message);
    }
}

import React from 'react'
import styles from "./index.module.css";
import CustomLoader from '../custom-loader';

const BlockPreloader = () =>  (<div className={styles.emptyState}>
            <div className={styles.loaderWrap}>
                <CustomLoader componentLoader/>
            </div>
        </div>);
export default BlockPreloader;

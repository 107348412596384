import {
    UPDATE_SNACK_BAR, UPDATE_PROFILE_PICTURE_URL,
    PULL_USER_DETAILS_COUNT, UPDATE_IS_ANY_WORKSPACE_STARTED,
    UPDATE_WEB_SOCKET_CONNECTION, UPDATE_SOCKET_MESSAGE, SET_ALL_NOTIFICATIONS_DATA
} from '../action-types'

const initialState = {
    snackBarState: {},
    profilePictureUrl: "",
    pullCount: 0,
    hasAnyWorkspaceStarted: [],
    webSocket: null,
    onSocketMessage: null,
    allNotificationsData: []
}

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SNACK_BAR: {
            if (action.payload) {
                return {
                    ...state,
                    snackBarState: { ...action.payload }
                }
            }
            break;
        }
        case UPDATE_PROFILE_PICTURE_URL: {
            return {
                ...state,
                profilePictureUrl: action.payload
            }
        }
        case PULL_USER_DETAILS_COUNT: {
            return {
                ...state,
                pullCount: state.pullCount + 1
            }
        }
        case UPDATE_IS_ANY_WORKSPACE_STARTED: {
            return {
                ...state,
                hasAnyWorkspaceStarted: action.payload
            }
        }
        case UPDATE_WEB_SOCKET_CONNECTION: {
            return {
                ...state,
                webSocket: action.payload
            }
        }
        case UPDATE_SOCKET_MESSAGE: {
            return {
                ...state,
                onSocketMessage: action.payload
            }
        }
        case SET_ALL_NOTIFICATIONS_DATA: {
            return {
                ...state,
                allNotificationsData: action.payload
            }
        }
        default: return state
    }
}

export default globalReducer;
import { SET_DCA_STAGE, SET_DCA_AGREEMENT_DATE, SET_DCA_CLIENT_NAME, SET_DCA_CONTRIBUTOR_NAME,
    SET_CONTRIBUTOR_SIGNING_NAME, SET_CONTRIBUTOR_SIGNING_BY_TEXT, SET_CONTRIBUTOR_SIGNING_TITLE, 
    SET_CONTRIBUTOR_SIGNING_DATE, SET_DCA_REVIEW_DATE, SET_DCA_REVIEW_APPROVE_TEXT, SET_DCA_REVIEW_BY_TEXT,
    SET_DCA_REVIEWER_NAME, SET_DCA_REVIEWER_TITLE, SET_DCA_REVIEWER_SIGN_DATE, SET_DCA_ALL_FORM_FIELDS
, RESET_DCA_FORM, SET_CURRENT_DCA_FORM_STAGE, SET_DCA_SIGNATURE, SET_ACKNOWLEDGMENT_SIGNATURE,
  SET_INSTITUTE_SIGNATURE
} from '../action-types'
export const setDCAStage = (value) => {
    return {
        type: SET_DCA_STAGE,
        payload: value
    }
}
export const setDCAAgreementDate = (value) => {
    return {
        type: SET_DCA_AGREEMENT_DATE,
        payload: value
    }
}
export const setDCAClientName = (value) => {
    return {
        type: SET_DCA_CLIENT_NAME,
        payload: value
    }
}
export const setDCAContributorName = (value) => {
    return {
        type: SET_DCA_CONTRIBUTOR_NAME,
        payload: value
    }
}
export const setContributorSigningName = (value) => {
    return {
        type: SET_CONTRIBUTOR_SIGNING_NAME,
        payload: value
    }
}

export const setContributorSigningByText = (value) => {
    return {
        type: SET_CONTRIBUTOR_SIGNING_BY_TEXT,
        payload: value
    }
}

export const setContributorSigningTitle = (value) => {
    return {
        type: SET_CONTRIBUTOR_SIGNING_TITLE,
        payload: value
    }
}

export const setContributorSigningDate = (value) => {
    return {
        type: SET_CONTRIBUTOR_SIGNING_DATE,
        payload: value
    }
}

export const setDcaReviewDate = (value) => {
    return {
        type: SET_DCA_REVIEW_DATE,
        payload: value
    }
}
export const setDcaReviewApproveText = (value) => {
    return {
        type: SET_DCA_REVIEW_APPROVE_TEXT,
        payload: value
    }
}
export const setDcaReviewByText = (value) => {
    return {
        type: SET_DCA_REVIEW_BY_TEXT,
        payload: value
    }
}
export const setDcaReviewerName = (value) => {
    return {
        type: SET_DCA_REVIEWER_NAME,
        payload: value
    }
}
export const setDcaReviewerTitle = (value) => {
    return {
        type: SET_DCA_REVIEWER_TITLE,
        payload: value
    }
}
export const setDcaReviewerSignDate = (value) => {
    return {
        type: SET_DCA_REVIEWER_SIGN_DATE,
        payload: value
    }
}
export const setDCAAllFormFields = (value) => {
    return {
        type: SET_DCA_ALL_FORM_FIELDS,
        payload: value
    }
}
export const resetDCAForm = () => {
    return{
        type: RESET_DCA_FORM
    }
}
export const setCurrentDCAFormStage = (value) => {
    return{
        type: SET_CURRENT_DCA_FORM_STAGE,
        payload: value
    }
}
export const setDCASignature = (value) => {
    return{
        type: SET_DCA_SIGNATURE,
        payload: value
    }
}
export const setAcknowledgmentSignature = (value) =>{
    return{
        type: SET_ACKNOWLEDGMENT_SIGNATURE,
        payload: value
    }
}
export const setInstituteSignature = (value) =>{
    return{
        type: SET_INSTITUTE_SIGNATURE,
        payload: value
    }
}
/**
 *  External Imports
 */
 import {connect} from "react-redux";

/**
 *  Imports
 */
import CommunitySearchResults from "./CommunitySearchResults";
import {fetchSortedGlobalSearchUsersAction} from "../../../redux/actions/communityActions";

const mapStateToProps = ({community: {globalSearchUsers, globalSearchQuery}}) => ({
	globalSearchUsers, globalSearchQuery
});

const mapDispatchToProps = (dispatch) => ({
	fetchSortedSearchUsers: (cancelToken, searchQuery, sort, order) => dispatch(fetchSortedGlobalSearchUsersAction(cancelToken, searchQuery, sort, order))
});

/**
 *  Exports
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CommunitySearchResults);

import {
    SET_PROJECT_PI_FIRST_NAME, SET_PROJECT_PI_LAST_NAME, SET_PROJECT_PI_MIDDLE_NAME, SET_PROJECT_PI_CREDENTIALS,
    SET_PROJECT_PI_AFFILIATION, SET_PROJECT_PI_INSTITUTE_EMAIL, SET_PROJECT_PI_FIELDS, RESET_PROJECT_PI_FIELDS
} from '../action-types'
import get from 'lodash/get'
const initialState = {
    projectPIFirstName: '',
    projectPILastName: '',
    projectPIMiddleName: '',
    projectPICredentials: '',
    projectPIAffiliation: '',
    projectPIInstituteEmail: ''
}

const projectPIReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROJECT_PI_FIRST_NAME: {
            return {
                ...state,
                projectPIFirstName: action.payload
            }
        }
        case SET_PROJECT_PI_LAST_NAME: {
            return {
                ...state,
                projectPILastName: action.payload
            }
        }
        case SET_PROJECT_PI_MIDDLE_NAME: {
            return {
                ...state,
                projectPIMiddleName: action.payload
            }
        }
        case SET_PROJECT_PI_CREDENTIALS: {
            return {
                ...state,
                projectPICredentials: action.payload
            }
        }
        case SET_PROJECT_PI_AFFILIATION: {
            return {
                ...state,
                projectPIAffiliation: action.payload
            }
        }
        case SET_PROJECT_PI_INSTITUTE_EMAIL: {
            return {
                ...state,
                projectPIInstituteEmail: action.payload
            }
        }
        case SET_PROJECT_PI_FIELDS: {
            const data = action.payload;
            return {
                projectPIFirstName: get(data, 'firstName', ''),
                projectPILastName: get(data, 'lastName', ''),
                projectPIMiddleName: get(data, 'middleName', ''),
                projectPICredentials: get(data, 'credentials', ''),
                projectPIAffiliation: get(data, 'affiliation', ''),
                projectPIInstituteEmail: get(data, 'institutionalEmail', ''),
            }
        }
        case RESET_PROJECT_PI_FIELDS: {
            return {
            projectPIFirstName: '',
            projectPILastName: '',
            projectPIMiddleName: '',
            projectPICredentials: '',
            projectPIAffiliation: '',
            projectPIInstituteEmail: ''}
        }
        default: return state
    }
}

export default projectPIReducer;
import React, { useState } from 'react';
import Modal from '../../commons/modal'
import { updateWorkspaceOwnershipTransferStatus } from '../../../api/workspace-api/workspace-api'
import Classes from './index.module.css';
import Grid from '@mui/material/Grid'

const OwnershipTransferActionModel = ({
  showOwnershipTransfer,
  setShowOwnershipTransfer,
  updateSnackBar,
  fetchAfterSuccessfulTransfer
}) => {

  const [isTransferApproved, setIsTransferApproved] = useState(false)

  const onStatusUpdate = async (didAccept) => {
    try {
      await updateWorkspaceOwnershipTransferStatus(showOwnershipTransfer?.request_id, didAccept ? "accepted" : "rejected")
      const message = `${didAccept ? 'Successfully Accepted' : 'Rejected'} the ownership transfer request`;
      updateSnackBar(message, 'success')
    }
    catch (error) {
      const message = `Unable to  ${didAccept ? 'Accept' : 'Reject'} the ownership`;
      updateSnackBar(message, 'error')
    }
    fetchAfterSuccessfulTransfer(showOwnershipTransfer)
    
  }



  const owner = showOwnershipTransfer?.workspace_owner || {}
  return <>
    <Modal
      open={!!showOwnershipTransfer && !isTransferApproved}
      handleClose={() => setShowOwnershipTransfer(null)}
      dialogProps={{
        modalTitle: 'Workspace Ownership Transfer',
        positiveButtonText: 'Agree',
        negativeButtonText: "I'll decide later",
        additionalButtonText: "Reject",
        additionalButtonAction: () => { onStatusUpdate(false); setShowOwnershipTransfer(null) },
        positiveButtonAction: () => { onStatusUpdate(true); setIsTransferApproved(true) },
        negativeButtonAction: () =>  setShowOwnershipTransfer(null),
      }}
      disableTitleDivider={true}
    >
      <Grid container className={Classes.mainContainerModal}>
        <Grid item xs={12} md={12} lg={12}>
          <div>
            <p>It is your responsibility to ensure that the WorkSpace does not contain any personal or otherwise confidential files. Please ensure that you review the contents of the WorkSpace immediately after taking ownership of it and that you delete any files that you or other users whom the
              WorkSpace is shared with, should not have access to.</p>
            <p>By clicking “Agree” below, you confirm that you have read this warning and that you accept the WorkSpace and its contents and that BRAINCommons cannot be held responsible for any accidental disclosure of confidential information that results from your omission to delete them.</p>
          </div>

        </Grid>
      </Grid>
    </Modal>
    <Modal
      open={!!showOwnershipTransfer && isTransferApproved}
      handleClose={() => setShowOwnershipTransfer(null)}
      dialogProps={{
        modalTitle: 'Workspace Ownership Transfer',
        positiveButtonText: 'Ok',
        // negativeButtonText: "",
        positiveButtonAction: () => setShowOwnershipTransfer(null),
        // negativeButtonAction: () => onStatusUpdate(false),
      }}
      disableTitleDivider={true}
    >
      <Grid container className={Classes.mainContainerModal}>
        <Grid item xs={12} md={12} lg={12}>
          <div>
            <p>You have accepted to become the new owner of WorkSpace {showOwnershipTransfer?.workspace_id}.</p>
            <p>In order to be able to use the BC-Client or SDK, you will need to copy your API key into the WorkSpace.</p>
            <p>Make sure that you review the Users that the WorkSpace is currently shared with, if any, to ensure that only authorised users have access to it.</p>
            <p><b>Important:</b> Please ensure that you review all files stored in the WorkSpace and that you  delete any files that you or other users whom the WorkSpace is shared with, should not have access to.</p>
          </div>
        </Grid>
      </Grid>
    </Modal>

  </>
}

export default OwnershipTransferActionModel;
import axios from 'axios'
import {env} from '../../env'
import get from 'lodash/get'
import { BASE_URL } from '../../constants/google-login-urls'
import { getCognitoIdToken } from '../utilityMethods';
import { Auth } from 'aws-amplify'

const WORKSPACE_BASE_URL = `${env.REACT_APP_BASE_API_PATH}/workspace`;
const WORKSPACE_USER_BASE_URL = `${env.REACT_APP_BASE_API_PATH}/workspaces`;

export const getAllWorkspaces = async () => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.get(WORKSPACE_BASE_URL, { headers: header });
        return get(res, 'data', []);
    } catch (error) {
        throw Error(error.message);
    }
}

export const getAllWorkspacesForUser = async (userSub) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.get(`${WORKSPACE_USER_BASE_URL}/${userSub}`, { headers: header });
        return get(res, 'data', []);
    } catch (error) {
        throw Error(error.message);
    }
}

export const getWorkspaceStatus = async (workspaceId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${WORKSPACE_BASE_URL}/${workspaceId}/status`, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getUsersForNotebookShare = async () => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${BASE_URL}/iam/user/similar-access-user-list`, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getWorkspaceData = async (start_date = "", end_date = "") => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = start_date && end_date ? `${BASE_URL}/cost/workspace?start_date=${start_date}&end_date=${end_date}` : `${BASE_URL}/cost/workspace`
        const res = await axios.get(url, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getLockedWorkspaceCount = async () => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${BASE_URL}/cost/workspace/locked_workspace_count`
        const res = await axios.get(url, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getTopupWorkspaceCount = async () => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${BASE_URL}/cost/workspace/top_up_requested_count`
        const res = await axios.get(url, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getWorkspaceDataDayWise = async (start_date = "", end_date = "") => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = start_date && end_date ? `${BASE_URL}/cost/workspace/total?start_date=${start_date}&end_date=${end_date}` : `${BASE_URL}/cost/workspace/total`
        const res = await axios.get(url, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getEachWorkspaceDataDayWise = async (id, start_date = "", end_date = "") => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${BASE_URL}/cost/workspace/${id}?start_date=${start_date}&end_date=${end_date}`
        const res = await axios.get(url, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getActiveUsersForWorkspace = async (workspaceId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${WORKSPACE_BASE_URL}/${workspaceId}/active_users`, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getSharedUsersForWorkspace = async (workspaceId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${WORKSPACE_BASE_URL}/${workspaceId}/sharedusers`, { headers: header });
        return get(res, 'data', []);
    } catch (error) {
        throw Error(error.message);
    }
}

export const getWorkspaceLaunchUrl = async (workspaceId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${WORKSPACE_BASE_URL}/${workspaceId}/launchurl`, { headers: header });
        return get(res, 'data', {});
    } catch (error) {
        throw Error(error.message);
    }
}

export const stopWorkspace = async (workspaceId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.post(`${WORKSPACE_BASE_URL}/${workspaceId}/stop`, null, { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const startWorkspace = async (workspaceId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.post(`${WORKSPACE_BASE_URL}/${workspaceId}/start`, null, { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const quitWorkspace = async (workspaceId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.post(`${WORKSPACE_BASE_URL}/${workspaceId}/quit`, null, { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const shareWorkspaceWithUsers = async (workspaceId, userData) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.post(`${WORKSPACE_BASE_URL}/${workspaceId}/share`, userData, { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error?.response?.data?.error);
    }
}

export const shareNotebookWithUsers = async (notebookId, userData) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.post(`${WORKSPACE_BASE_URL}/${notebookId}/share`, userData, { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getWorkspaceDetails = async (workspaceId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${WORKSPACE_BASE_URL}/${workspaceId}`, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const unShareWorkspaceWithUsers = async (workspaceId, userData) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.delete(`${WORKSPACE_BASE_URL}/${workspaceId}/unshare`, { data: userData, headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const updateServiceAgreement = async (data) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.post(`${WORKSPACE_BASE_URL}/serviceagreement`, data, { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const updateWorkspaceRequest = async (body, id) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        if(id){
            const res = await axios.put(`${WORKSPACE_BASE_URL}/request/${id}`, body, { headers: header });
            return get(res, 'data', '');
        }
        const res = await axios.post(`${WORKSPACE_BASE_URL}/request`, body, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.error);
    }
}

export const updateWorkspaceOwnershipTransferStatus = async (id, action) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.put(`${WORKSPACE_BASE_URL}/transfer/${id}/${action}`, {}, { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getWorkspaceRequests = async (userSub = '') => {
    try {
        const token = await getCognitoIdToken();
        let sub = userSub;
        if (!userSub) {
            const userValues = await Auth.currentUserInfo();
            sub = userValues?.attributes?.sub;
        }
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.get(`${WORKSPACE_BASE_URL}/requests/${sub}`, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getServiceAgreement = async (userEmail = '') => {
    try {
        const token = await getCognitoIdToken();
        let email = userEmail;
        if (!userEmail) {
            const userValues = await Auth.currentUserInfo();
            email = userValues?.attributes?.email;
        }
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.get(`${WORKSPACE_BASE_URL}/serviceagreement/${email}`, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getServiceAgreementDetails = async (userSub = '', id) => {
    try {
        const token = await getCognitoIdToken();
        let sub = userSub;
        if (!userSub) {
            const userValues = await Auth.currentUserInfo();
            sub = userValues?.attributes?.sub;
        }
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.get(`${WORKSPACE_BASE_URL}/request/${sub}/${id}`, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const getWorkspaceUsernamePassword = async (workspaceId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${WORKSPACE_BASE_URL}/password/${workspaceId}`, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getAllWorkspaceOwnerShipDetails = async () => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${WORKSPACE_BASE_URL}/transfer/_all`, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getWorkspaceOwnerShipDetails = async (requestId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${WORKSPACE_BASE_URL}/transfer/${requestId}`, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getUsersListForOwnershipChange = async (user_guid) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${WORKSPACE_BASE_URL}/transfer/eligible_users/${user_guid}`, { headers: header });
        return get(res, 'data', null);
    } catch (error) {
        throw Error(error.message);
    }
}


export const submitWorkspaceTransfer = async (body) => {
    try {
        const token = await getCognitoIdToken();

        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.post(`${WORKSPACE_BASE_URL}/transfer`, body, { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw error;
    }
}

export const getAllWorkspaceCarts = async () => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${BASE_URL}/cost/workspace_cart/`;
        const res = await axios.get(url, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const createWorkspaceCart = async (body) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${BASE_URL}/cost/workspace_cart`;
        const res = await axios.put(url, body, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const updateWorkspaceCart = async (body, orderId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${BASE_URL}/cost/workspace_cart/${orderId}`;
        const res = await axios.put(url, body, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const submitWorkspaceCartForApproval = async (orderId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${BASE_URL}/cost/workspace_cart/${orderId}/submit`;
        const res = await axios.put(url, {}, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const updateLockedWorkspaceStatus = async (workspaceId, body) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${BASE_URL}/cost/workspace/${workspaceId}/update_locked_status`;
        const res = await axios.put(url, body, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const updateDoNotDeleteWorkspaceFlag = async (workspaceId, body) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${BASE_URL}/cost/workspace/${workspaceId}/update_do_not_delete`;
        const res = await axios.put(url, body, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const deleteWorkspace = async (userSub, workspaceId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.delete(`${BASE_URL}/cost/workspace/${userSub}/${workspaceId}`, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const updateWorkspaceName = async (workspaceId, body) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${WORKSPACE_BASE_URL}/${workspaceId}`;
        const res = await axios.put(url, body, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.error);
    }
}
/**
 *  External Imports
 */
import {connect} from "react-redux";

/**
 *  Internal Imports
 */
import {toggleGlobalSearchPopover, initCommunityGlobalSearchQueryAction} from "../../../redux/actions/communityActions";

import CommunityGlobalSearchButton from "./CommunityGlobalSearchButton";

/**
 *  Functions
 */
 const mapStateToProps = ({ community: { globalSearchPopoverOpen } }) => ({
    globalSearchPopoverOpen
});

const mapDispatchToProps = (dispatch) => ({
	togglePopover: (isOpen) => dispatch(toggleGlobalSearchPopover(isOpen)),
	setSearchQuery: (query) => dispatch(initCommunityGlobalSearchQueryAction(query)),
});

/**
 *  Exports
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CommunityGlobalSearchButton);

/**
 *  External Imports
 */
import { func, number, string, bool } from "prop-types";

import React, { useEffect } from "react";

/**
 *  Internal Imports
 */
import CustomModal from "../../commons/modal";
import iconSuccess from "../../../assets/icons/success_icon.svg";
import styles from "./index.module.css";
import UserAvatar from "../../commons/user-avatar";

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 */
const CommunitySuccessModal = (props) => {
    const { showCommunitySuccessModal, userIcon, name, email, id } = props;
    const { onDialogClose, onDialogReset, onSuccessEvent, connectMethod } =
        props;

    const modalProps = {
        loadStatus: false,

        modalTitle: "Congratulations!",
        modalTitleClass: styles.modalTitle,

        positiveButtonText: "OK, I got it",
        positiveButtonClass: styles.modalSuccessBtn,
        positiveButtonAction: async () => await onModalCloseEvent(),
    };

    const modalBtnStyleProps = {
        minWidth: "auto",
        padding: "8px 24px 8px 24px",
        fontSize: ".875rem",
        margin: "0 210px 0 0",
    };

    const onModalCloseEvent = async () => {
        // Emit a request
        const { status } = await connectMethod({ id });

        // // Close the modal window
        onDialogClose();

        // Checking a response status
        if (status === 200) {
            onSuccessEvent(id);
        }
    };

    useEffect(() => {
        return () => onDialogReset();
    }, []);

    return (
        <CustomModal
            data-testid="modal"
            open={showCommunitySuccessModal}
            dialogProps={modalProps}
            buttonStyleProps={modalBtnStyleProps}
            disableTitleDivider={true}
            disableBottomHorizontalDivider={true}>
            <div className={styles.modalBody}>
                <div className={styles.modalBodyTitleArea}>
                    <img
                        data-testid="iconSuccess"
                        className={styles.modalBodyTitleIcon}
                        src={iconSuccess}
                        alt="Icon Success"
                    />

                    <div className={styles.modalBodyTitleText} data-testid="modalTitle">
                        You are now connected!
                    </div>
                </div>

                <div className={styles.modalDescriptionArea} data-testid="modalText">
                    Here is the email address for your new connection. Please
                    feel free to communicate directly with each other. We hope
                    you find these conversations to be fruitful.
                </div>

                <div className={styles.modalUserInfoArea}>
                    <div className={styles.userAvatarWrap}>
                        <UserAvatar data-testid="userIcon" userIcon={userIcon}></UserAvatar>
                    </div>

                    <div className={styles.modalUserName} data-testid="userName">{name}</div>

                    <div className={styles.modalUserMail} data-testid="userEmail">{email}</div>
                </div>
            </div>
        </CustomModal>
    );
};

/**
 *  Properties
 */
CommunitySuccessModal.propTypes = {
    id: number.isRequired,
    userIcon: string.isRequired,
    name: string.isRequired,
    email: string.isRequired,
    showCommunitySuccessModal: bool.isRequired,

    onDialogClose: func.isRequired,
    onDialogReset: func.isRequired,
    connectMethod: func.isRequired,
    onSuccessEvent: func.isRequired,
};

/**
 *  Exports
 */
export default CommunitySuccessModal;

/**
 *  Internal Imports
 */

import WidgetChatsList from "./WidgetChatsList";

/**
 *  Exports
 */
export default WidgetChatsList;

import React, { useRef, useEffect, useState } from 'react'
import CustomForceGraph from '../../controls/custom-force-graph'
import moreIcon from '../../../assets/icons/data_model_more.svg'
import dataModelIcon from '../../../assets/icons/characteristics.svg'
import classes from './index.module.css'
import CustomLoader from '../../commons/custom-loader';
import { COLOURS_DATA_MODEL, BACKGROUND_COLORS_DATA_MODEL } from '../../../constants/strings'
import { sendGAEvents } from '../../../api/generic-api'


const DataModelGraphWrapper = props => {
  const stylesObj = {
    button: {
      padding: '10px 15px',
      borderRadius: 5,
      border: '1px solid #72b7e5',
      background: '#fff',
      boxShadow: '0px 2px 8px #00000022'
    },
    zoomInButton: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    zoomOutButton: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }
  }
  return <div ref={props.elementRef}>
    <CustomForceGraph
      setTransformPoints={props.setTransformPoints}
      transformPoints={props.transformPoints}
      nodes={props.plotNodes || []}
      links={props.plotLinks || []}
      width='100%'
      height='100vh'
      enableZoom
      config={{
        rankSep: 200
      }}
      buttonStyles={stylesObj}
      animate
    />
  </div>
}

const areEqual = (prevProps, nextProps) => {
  return prevProps.plotNodes === nextProps.plotNodes &&
    prevProps.plotLinks === nextProps.plotLinks;
}

const DataModelGraphWrapperMemo = React.memo(DataModelGraphWrapper, areEqual)


function DataModelGraph(props) {

  const [plotNodes, setPlotNodes] = useState([])
  const [plotLinks, setPlotLinks] = useState([])
  const [nodes, setNodes] = useState([])
  const [subGroupNodes, setSubGroupNodes] = useState([])
  const [forceUpdate, setForceUpdate] = useState(true)
  const [transformPoints, setTransformPoints] = useState(null)


  useEffect(() => {
    generateGraphData(nodes, subGroupNodes)
  }, [nodes, subGroupNodes])


  useEffect(() => {
    const { graphPoints, subGroupPoints } = generateGraphNodes(
      props.graphData || {}, props.itemCollection || {}
    )
    setNodes(graphPoints)
    setSubGroupNodes(subGroupPoints)
  }, [props.graphData, props.itemCollection])

  // Recursive Function : Prepares the nodes and its links of the prepared graph data
  const generateGraphNodes = (graphData, graphPoints) => {
    const subGroupPoints = []
    for (let p of graphPoints) {
      if (p.subGroups) {
        for (let eachLink of p.subGroups) {
          const linkID = graphPoints.find(
            item => item.label === eachLink.target_type
          )
          if (linkID) {
            subGroupPoints.push({
              source: linkID.ID,
              target: p.ID
            })
          }
        }
      }
    }
    return { graphPoints: graphPoints, subGroupPoints: subGroupPoints }
  }

  // Get the parent ID of a card which is in visible state
  const getVisibleParentID = (graphPoints, nodeId) => {
    const foundElement = graphPoints.find(item => item.ID === nodeId)
    if (foundElement) {
      if (foundElement.isVisible) {
        return foundElement.ID
      } else {
        return getVisibleParentID(graphPoints, foundElement.parentID)
      }
    } else {
      return null
    }
  }
  // Prepares the graph data on load
  const generateGraphData = (graphPoints, subGroupNodes) => {
    setForceUpdate(true)
    let graphLinks = []
    let finalNodes = graphPoints
      .map((item, index) => {
        if (item?.isVisible && !item?.shouldBeHidden) {
          return getNodeData(index, item.label, item)
        }
      })
      .filter(item => item)
    let openPoints = graphPoints.filter(item => item.isVisible && !item?.shouldBeHidden)
    for (let item of openPoints) {
      if (item.parentID != null) {
        graphLinks.push({
          source: item.parentID,
          target: item.ID
        })
      }
    }
    for (let groupNode of subGroupNodes) {
      if (
        graphPoints.find(item => item.ID === groupNode.source).isVisible &&
        graphPoints.find(item => item.ID === groupNode.target).isVisible &&
        !graphPoints.find(item => item.ID === groupNode.target).shouldBeHidden &&
        !graphPoints.find(item => item.ID === groupNode.source).shouldBeHidden
      ) {
        graphLinks.push(groupNode)
      } else if (
        graphPoints.find(item => item.ID === groupNode.target).isVisible
      ) {
        const shallowParentID = getVisibleParentID(
          graphPoints,
          groupNode.source
        )
        if (shallowParentID) {
          graphLinks.push({
            source: shallowParentID,
            target: groupNode.target
          })
        }
      }
    }
    setPlotNodes(finalNodes || [])
    setPlotLinks(graphLinks)

    // To be Investigated
    // Chart nodes are not rendering properly soon after certain state update
    // Handling by removing the entire graph and plotting it again
    // instead allow react to render dom where required
    setTimeout(() => {
      setForceUpdate(false)
    }, 100)
  }

  let graphRef = useRef(null)

  // Expand or close card children
  const toggleCardState = elementName => {
    const nodesClone = [...nodes]
    let foundItemID = nodesClone.find(
      item => item?.label?.toLowerCase() === elementName && !item.isDefault && item.children
    )?.ID
    if (foundItemID) {
      for (let node of nodesClone) {
        if (node.parentID === foundItemID) {
          node.isVisible = !node.isVisible
        }
      }
    }
    else {
      return;
    }
    setNodes(nodesClone)
  }

  // HTML code for the card nodes in the graph
  const getNodeData = (index, label = '', item) => {
    const ignoreLabels = ["program", "project", "study", "case"]
    const shouldLabelBeIgnored = !ignoreLabels.find(item => item === label.toLowerCase())
    const hasChildrens = item?.children > 0 && shouldLabelBeIgnored;
    const nodeTitle =
      item?.children > 0 && shouldLabelBeIgnored ? item.children + ' Nodes' : 'Node '
    const cardClass =
      item?.children > 0 && shouldLabelBeIgnored ? 'card-container-has-child' : 'card-container'
    const isCategory = item?.isCategory;
    const availableCount = item?.availableCount || "";
    const moreButtonClass = (isCategory || item?.availableCount) ? 'more-card-button hide-button' : "more-card-button "
    const dataModelIconLink = dataModelIcon
    const moreIconLink = moreIcon

    return {
      id: index,
      label: `<div id='cvb-dataModel-cardContainer' class=${cardClass} style='background-color: ${hasChildrens ? item.backgroundShade : 'none'}'>
                    <div id='cvb-dataModel-cardHeader' class="card-header" style='background-color: ${item.headerColor}; 
                      display: ${item?.children && shouldLabelBeIgnored ? 'none !important' : 'block'}'>&nbsp;</div>
                    <span id='cvb-dataModel-nodeTitle' class="nodeTitle">${nodeTitle}</span>
                    <button id='cvb-dataModel-moreButton'  class='${moreButtonClass}'>
                    <img src=${moreIconLink} alt="" />
                    </button>
                    <div id='cvb-dataModel-cardContentContainer' class="dataModelContent">
                    <p id='cvb-dataModel-cardContentLabel' class='identifier-label'>${formatLabel(label?.label || label)}</p>
                    <p id='cvb-dataModel-cardCContentNodeCount' class='available-count-container'>${availableCount}</p>
                    </div>
                </div>`,
      labelType: 'html',
      config: {
        style: 'fill: #fff',
        width: 400,
        height: 130
      }
    }
  }

  const formatLabel = label => {
    return label.split('_').join(' ')
  }

  // Enabling  actions for the HTML cards present inside the graph
  const enableActions = () => {
    try {
      let cardContainerWithoutElements = graphRef?.current?.getElementsByClassName(
        'card-container'
      )
      let cardContainerWithElements = graphRef?.current?.getElementsByClassName(
        'card-container-has-child'
      )
      const divElement = [
        ...cardContainerWithoutElements,
        ...cardContainerWithElements
      ]
      for (let ele of divElement) {
        const icon = ele.getElementsByTagName('button')
        icon[0].addEventListener('click', e => {
          const clickedElement = ele.getElementsByClassName(
            'identifier-label'
          )?.[0]?.innerText || ''
          if (clickedElement) {
            const constructedString = clickedElement.split(" ").join('_')
            props.setSelectedItem(constructedString.toLowerCase());
            props.setShowPropertiesModal(true);
          }
          e.stopPropagation();
        })
        ele.addEventListener('click', () => {
          const clickedElement =
            ele.getElementsByClassName('identifier-label')?.[0]?.innerText || ''
          toggleCardState(
            clickedElement
              .split(' ')
              .join('_')
              .toLowerCase()
          );
          const nodeValue = clickedElement.split(' ').join('_').toLowerCase();
          if (props.dataModelType === 'ALL') {
            sendGAEvents('select_bc_model_graph_node', 'bc_model_graph_view', 'Graph view node selected',
              { 'bc_model_node': nodeValue });
          } else if (props.dataModelType === 'PARTIAL') {
            sendGAEvents('select_bc_study_model_graph_node', 'bc_model_graph_view', 'Study model Graph node selected',
              { 'bc_study_model_node': nodeValue });
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!forceUpdate) {
      enableActions()
    }
  }, [nodes, plotNodes, plotLinks, subGroupNodes, forceUpdate])

  if (forceUpdate) {
    return <CustomLoader />
  }

  return (
    <DataModelGraphWrapperMemo
      elementRef={graphRef}
      plotNodes={plotNodes}
      plotLinks={plotLinks}
      setTransformPoints={setTransformPoints}
      transformPoints={transformPoints}
    />
  )
}

export default DataModelGraph;
import React from 'react';
import { Box, Tooltip, Typography, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  block: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  wrapSecondary: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,

    '&  .MuiIconButton-root': {
      backgroundColor: 'white',
      paddingRight: '0.75rem !important',
      cursor: 'pointer',

      '& svg': {
        width: '1rem',
        height: '1rem',
        color: 'rgba(3, 18, 53, 0.3)',

        '&:hover': {
          color: theme.palette.grey[600],
        },
      },
    },
  },
}));

const CohortFilterItem = (props) => {
  const classes = useStyles();
  const { filter, handleChange } = props;

  return (
    <Box className={classes.block} maxWidth="100%" key={filter.key}>
      <Tooltip title={`Toggle ${filter.key ?? ''} filter`} placement="left">
        <Checkbox
          size="small"
          checked={filter.enabled}
          onChange={() => {
            if (handleChange) handleChange(filter.key);
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Tooltip>

      <Box className={classes.wrapSecondary}>
        <Typography component="span" variant="subtitle1">
          {filter.key.replace(/_/g, ' ')}
        </Typography>
      </Box>
    </Box>
  );
};

export default CohortFilterItem;

import ContributedDatasetStage from './ContributedDatasetStage'
import { connect } from 'react-redux'
const mapStateToProps = state => {
    return {
        dcaStage: state.dca.dcaStage,
        projectDetailsDesignatedZone: state.projectDetails.projectDetailsDesignatedZone,
    }
}
const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ContributedDatasetStage);
import { SET_DIF_STAGE, SET_DIF_CURRENT_STAGE } from '../action-types'
import lowerCase from 'lodash/lowerCase'
const initialState = {
   difStage: 1,
   currentDIFFormStage: ''
}

const dataInventoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DIF_STAGE: {
            const stage = lowerCase(action.payload) !== 'completed' ? Number(action.payload) : 1;
            return {
                ...state,
                difStage: stage
            }
        }
        case SET_DIF_CURRENT_STAGE: {
            return {
                ...state,
                currentDIFFormStage: action.payload
            }
        }
        default: return state
    }
}

export default dataInventoryReducer;
import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { PrimitiveContainer } from '../../primitives/PrimitiveContainer';
import {
  Box,
  ButtonBase,
  Checkbox,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { vars } from '../../../../../assets/variables';

const { darkTextColor, primaryColor } = vars;

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: '14rem',
    cursor: 'pointer',
    '& .MuiTypography-root': {
      fontSize: '0.875rem',
      lineHeight: '1.25rem !important',
    },
    '& .MuiCheckbox-root': {
      height: 'max-content',
      padding: 0,
      zIndex: 999,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.5rem',

    '& .heading': {
      fontWeight: 500,
      color: darkTextColor,
      textTransform: 'capitalize',
    },
    '& .MuiTypography-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  description: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    '& .MuiTypography-root': {
      height: '3.75rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 1.5rem',

    '& .MuiTypography-root': {
      textTransform: 'capitalize',
      fontSize: '0.875rem',
    },
    '& .MuiButtonBase-root': {
      fontWeight: 500,
      fontSize: '0.875rem',
      color: primaryColor,
      textTransform: 'unset',
    },
  },
}));

const ProjectCard = (props) => {
  const classes = useStyles();
  const {
    projectId,
    heading,
    subHeading,
    description,
    available,
    checked,
    onClick,
    onCheckToggle,
    loading = false,
  } = props;

  return (
    <PrimitiveContainer
      onClick={(e) => {
        if ('checked' in e.target) {
          return;
        }
        onClick();
      }}
    >
      <Box className={classes.root}>
        <Stack direction="row" spacing={1} className={classes.header}>
          <Box flex={1} width="calc(100% - 32px)">
            {loading ? (
              <Skeleton width="50%" height={20} />
            ) : (
              <Typography className="heading">{heading}</Typography>
            )}
            {loading && !subHeading ? (
              <Skeleton width="70%" height={20} />
            ) : subHeading ? (
              <Typography>{subHeading}</Typography>
            ) : null}
          </Box>
          {loading && !available ? (
            <Skeleton width={20} height={24} />
          ) : (
            available && (
              <Checkbox
                color="primary"
                value={projectId}
                checked={checked ?? false}
                onChange={onCheckToggle}
              />
            )
          )}
        </Stack>

        <Box className={classes.description}>
          {loading ? (
            <Skeleton width="100%" height={60} />
          ) : (
            <Typography>{description}</Typography>
          )}
        </Box>
        <Divider />
        <Box className={classes.footer}>
          {loading && !available ? (
            <Skeleton width={68} height={20} />
          ) : available ? (
            <Typography>available</Typography>
          ) : (
            <Typography>restricted</Typography>
          )}

          {loading ? (
            <Skeleton width={78} height={20} />
          ) : (
            <ButtonBase fontSize="small">Learn more</ButtonBase>
          )}
        </Box>
      </Box>
    </PrimitiveContainer>
  );
};

export default ProjectCard;

ProjectCard.propTypes = {
  projectId: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  description: PropTypes.string,
  available: PropTypes.bool,
  checked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onCheckToggle: PropTypes.func,
};

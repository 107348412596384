/**
 *  External Imports
 */
import React, { useState, useEffect, useRef } from "react";
import {
    arrayOf,
    func,
    number,
    shape,
    string,
    bool,
    oneOfType,
} from "prop-types";
import { Text } from '@visx/text';

/**
 *  Internal Imports
 */
import {
    CONSTELLATION_GRAPH_NODE_TYPE,
    CONSTELLATION_USER_TYPE,
    DEFAULT_TOOLTIP_HEIGHT,
} from "../../../../constants/constellation";
import { getRealTextWidthPx } from "../../../..//utils/stringFormatter";
import styles from "./index.module.css";

const FONT_SETTINGS = "10px 'Raleway', sans-serif";

const LABEL_DEFAULT_COLOR = "#637BBD";
const LABEL_SELECTABLE_COLOR = "#E7EAF2";
const KEYWORD_DEFAULT_COLOR = "#B2A7D2";
const LABEL_BASIC_WIDTH = 48;
const LABEL_BASIC_HEIGHT = 36;
const LABEL_MAX_TEXT_WIDTH = 140;
const LABEL_PADDING_SIZE = 4;
const LABEL_TEXT_COLOR = "#FFFFFF";
const TABS_PLACEHOLDER_WIDTH = 242;
const TABS_PLACEHOLDER_HEIGHT = 140;

const CommunityConstellationGraphNode = (props) => {
    const { nodeType, size, metadata, isSelectable, graphUsersType } =
        props.node;
    const { constellationGraphReady } = props;
    const { selectLabel, onTooltipToggle, onLabelHover, setNodeParams } = props;

    const textNode = useRef(null);

    const [currentTextSize, setCurrentTextSize] = useState(0);
    const [currentLabelSize, setCurrentLabelSize] = useState(0);
    const [currentTextHeight, setCurrentTextHeight] = useState(0);

    const isMainNode = () => {
        return nodeType === CONSTELLATION_GRAPH_NODE_TYPE.CURRENT_USER;
    };

    const isLabelNode = () => {
        return nodeType === CONSTELLATION_GRAPH_NODE_TYPE.LABEL;
    };

    const isCommonUserNode = () => {
        return nodeType === CONSTELLATION_GRAPH_NODE_TYPE.COMMON_USER;
    };

    const onLabelSelect = (label) => {
        if (!isSelectable || !isLabelNode() || !constellationGraphReady) {
            return;
        }
        selectLabel(label);
    };

    const getTooltipParams = () => {
        const currentUserId = metadata?.connectionId
            ? metadata.connectionId
            : metadata?.recommendationId
            ? metadata.recommendationId
            : metadata.id;

        return {
            x: props.node.x,
            y: props.node.y,
            connectionId: metadata?.connectionId,
            userId: currentUserId,
            userStatus: metadata.status,
            userIcon: metadata.userIcon,
            userName: metadata.fullName,
            userOrganization: metadata.organization,
            userConnections: metadata.connectionLinks,
            isIncomingConnection: metadata?.isIncoming || false,
            nodeSize: size,
            userType: graphUsersType,
            userEmail: metadata.email,
            userWeight: metadata.weight,
            isOpposite: props.node.y < DEFAULT_TOOLTIP_HEIGHT
        };
    };

    const getLabelIdValue = () => {
        return metadata.value.replace(/\s/g, "").toLowerCase();
    };

    const onLabelHovered = (label) => {
        if (!isLabelNode() || !constellationGraphReady) {
            return;
        }
        onLabelHover(label);
    };

    const getLabelParams = () => {
        return {
            id: getLabelIdValue(),
            x: props.node.x,
            y: props.node.y,
            isSelectable,
            value: metadata.value,
            isKeyword: metadata.isKeyword
        };
    };

    const getNodeParams = () => ({
        id: metadata.id,
        x: props.node.x,
        y: props.node.y,
        onClick: () => onTooltipToggle(getTooltipParams())
    });

    useEffect(() => {
        if (constellationGraphReady && isCommonUserNode()) {
            setNodeParams(getNodeParams())
        }
    }, [constellationGraphReady, nodeType])

    useEffect(() => {
        if (nodeType === CONSTELLATION_GRAPH_NODE_TYPE.LABEL) {
            const countedSize = getRealTextWidthPx(
                metadata.value,
                FONT_SETTINGS
            );
            const currentSize =
                countedSize > LABEL_MAX_TEXT_WIDTH
                    ? LABEL_MAX_TEXT_WIDTH
                    : countedSize;
            setCurrentTextSize(currentSize);
        }
    }, [nodeType, metadata.value]);

    useEffect(() => {
        if (textNode?.current) {
            const countedHeight =
                textNode?.current.getBoundingClientRect().height +
                LABEL_PADDING_SIZE * 2;
            setCurrentTextHeight(
                countedHeight < LABEL_BASIC_HEIGHT
                    ? LABEL_BASIC_HEIGHT
                    : countedHeight
            );
        }

        if (currentTextSize) {
            const countedLabelSize = currentTextSize + LABEL_PADDING_SIZE * 4;
            setCurrentLabelSize(
                countedLabelSize < LABEL_BASIC_WIDTH
                    ? LABEL_BASIC_WIDTH
                    : countedLabelSize
            );
        }
    });

    return (
        <>
            {isMainNode() && (
                <g data-testid="constellationCurrentUser">
                    <defs>
                        <filter id="shadow">
                            <feDropShadow
                                dx="0.2"
                                dy="0.4"
                                stdDeviation="0.2"
                            />
                        </filter>
                        <pattern id="userIcon" width="100%" height="100%">
                            <image
                                data-testid="constellationCurrentUserImage"
                                xlinkHref={metadata.userIcon}
                                width={size * 2}
                                height={size * 2}
                            />
                        </pattern>
                    </defs>
                    <circle r={size} fill={LABEL_TEXT_COLOR} />
                    <circle
                        r={size}
                        filter={"url(#shadow)"}
                        fill={"url(#userIcon)"}
                    />
                </g>
            )}
            {isLabelNode() && currentTextSize && (
                <g  data-testid="constellationLabel"
                    fill={
                        isSelectable && !metadata.isSelected
                            ? LABEL_SELECTABLE_COLOR
                            : metadata.isKeyword
                            ? KEYWORD_DEFAULT_COLOR
                            : LABEL_DEFAULT_COLOR
                    }
                    color={
                        isSelectable && !metadata.isSelected
                            ? LABEL_DEFAULT_COLOR
                            : LABEL_TEXT_COLOR
                    }>
                    <g  data-testid="constellationLabelText"
                        id={getLabelIdValue()}
                        className={
                            isSelectable
                                ? styles.selectableItem
                                : styles.nonSelectableItem
                        }
                        onMouseEnter={() => onLabelHovered(getLabelParams())}
                        onClick={() => onLabelSelect(getLabelParams())}
                        fill={"inherit"}>
                        <rect
                            x={(currentLabelSize / 2) * -1}
                            y={0}
                            rx={20}
                            ry={20}
                            width={currentLabelSize + LABEL_PADDING_SIZE}
                            height={currentTextHeight}
                        />
                        <Text
                            innerTextRef={textNode}
                            verticalAnchor="middle"
                            textAnchor="middle"
                            x={0}
                            y={currentTextHeight / 2 - LABEL_PADDING_SIZE / 2}
                            dx={LABEL_PADDING_SIZE / 4}
                            dy={LABEL_PADDING_SIZE / 4}
                            style={{ fontSize: "0.75rem" }}
                            width={currentTextSize}
                            fill="currentColor"
                        >
                            {metadata.value}
                        </Text>
                    </g>
                </g>
            )}
            {isCommonUserNode() && (
                <g
                    data-testid="constellationCommonUser"
                    id={`node_${metadata.id}`}
                    className={`${styles.selectableItem} click-outside-ignore`}
                    onClick={() => onTooltipToggle(getTooltipParams())}>
                    <pattern x={0} id={metadata.id} width="100%" height="100%">
                        <image
                            data-testid="constellationCommonUserImage"
                            xlinkHref={metadata.userIcon}
                            width={size * 2}
                            height={size * 2}
                        />
                    </pattern>
                    <circle r={size} fill={LABEL_TEXT_COLOR} />
                    <circle
                        r={size}
                        strokeWidth={
                            graphUsersType ===
                            CONSTELLATION_USER_TYPE.RECOMMENDED
                                ? 2
                                : 0
                        }
                        stroke={LABEL_DEFAULT_COLOR}
                        fill={`url(#${metadata.id})`}
                    />
                </g>
            )}

            {nodeType === CONSTELLATION_GRAPH_NODE_TYPE.TABS && (
                <rect
                    width={TABS_PLACEHOLDER_WIDTH}
                    height={TABS_PLACEHOLDER_HEIGHT}
                    x={(TABS_PLACEHOLDER_WIDTH / 2) * -1}
                    fill="transparent"
                    ></rect>
            )}
        </>
    );
};

CommunityConstellationGraphNode.propTypes = {
    node: shape({
        id: number.isRequired,
        index: number,
        nodeType: number.isRequired,
        metadata: oneOfType([
            shape({
                allLabels: arrayOf(string),
                userIcon: string.isRequired,
                current: bool.isRequired,
                designation: string.isRequired,
                firstName: string.isRequired,
                fullName: string.isRequired,
                id: number.isRequired,
                isUserUnsubscribedFromPendingNotificationEmail: bool.isRequired,
                lastName: string.isRequired,
                organization: string.isRequired,
                userSub: string.isRequired,
                labelsAnswer: arrayOf(string),
                labelsKeyword: arrayOf(string),
                connectionLinks: arrayOf(string),
                weight: number,
                connectionId: number,
                status: string,
                email: string.isRequired,
            }),
            shape({isKeyword: bool.isRequired, value: string.isRequired}),
        ]),
        size: number.isRequired,
        isSelectable: bool,
        graphUsersType: string,
    }),

    constellationGraphReady: bool.isRequired,

    selectLabel: func.isRequired,
    onTooltipToggle: func,
    onLabelHover: func,
};

export default CommunityConstellationGraphNode;

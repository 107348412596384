import React, { useEffect, useState } from 'react';
import {env} from '../env'
import { Route } from "react-router-dom"
import { Router, Redirect } from '@reach/router';
import ProtectedRoute from './ProtectedRoute'
import Register from '../components/pages/register'
import AuthenticatedLanding from '../components/pages/authenticated-landing'
import ForgotPassword from '../components/pages/forgot-password';
import UserOnboardHome from '../components/pages/user-onboard'
import ConfirmCongitoSignUp from '../components/pages/confirm-cognito-signup'
import { getUserProfile, getUserPermissions } from '../api/profile-api'
import { getCommunityOptInStatus } from "../api/onboarding-api";
import { Hub } from 'aws-amplify';
import SnackBar from '../components/commons/snack-bar'
import { Auth } from 'aws-amplify'
import TellUsMore from '../components/pages/tell-us-more'
import {getResourceActions} from '../utils/resourceActionsFormatter';
import CookieBanner from '../components/commons/cookie-banner';
import { addGoogleAnalytics, getCookie, setCookie, updateGAEventsPageTitleOnPageChange } from '../api/generic-api';
import { navigate } from '@reach/router';
import CookiesDetails from '../components/pages/bc-cookies-details';
import DeactivationSuccess from '../components/pages/deactivate/deactivation-success';
import Feedback from '../components/pages/feedback';
import RegisterByInvite from '../components/pages/register-by-invite';

const GA_MEASUREMENT_ID = `${env.REACT_APP_GA_MEASUREMENT_ID}`

const Root = (props) => {

    const [isUserValidated, setIsUserValidated] = useState(undefined);
    const [isScriptInjected, setIsScriptInjected] = useState(false);
    const { setBannerCookie = ()=>{ }} = props;
    const { showCookieBanner = false } = props;
    const [ loginButtonClick, setLoginButtonClick ] = useState(false);
    const [ onCookiePage, setOnCookiePage ] = useState(false);

    useEffect(()=> {
        checkForGACookies();
    }, [])

    function injectScript(src) {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', resolve);
            script.addEventListener('error', e => reject(e.error));
            document.head.appendChild(script);
        });
    }
    
     const injectGAScript = async () => {
        await injectScript(`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`)
            .then(() => {
               console.log('script injected');
               addGoogleAnalytics();
               setIsScriptInjected(true);
            }).catch(error => {
                console.error(error);
            });
    }
    const checkForGACookies = async () => {
        var user = getCookie("cvb_user");
        if (user != "") {
            setBannerCookie(false);
            if(user === 'true'){
                window[`ga-disable-${GA_MEASUREMENT_ID}`] = false;
                await injectGAScript();
            }
        } else {
            setBannerCookie(true);
        }
    } 
    // const checkForGACookies = async () => {
    //     setCookie("cvb_user", true, 365);
    //     await injectGAScript();
    // } 
    
    /**
     * @todo I think must do move this into protected and autorise components
     * 
     */
    useEffect(() => {
        if (!window.location.href.includes("code") && !window.location.href.includes("feedback")) {
            fetchUserDetails();
        }
    }, []);

    useEffect(() => {
        Hub.listen('auth', (data) => {
            const { payload } = data;
            if (payload?.event == "signIn") {
                fetchUserDetails()
            }
        })
    }, []);


    useEffect(() => {
        if (props.isUserLoggedIn) {
            setIsUserValidated(true)
        }
    }, [props.isUserLoggedIn]);

    const fetchUserDetails = async () => {
        try {
            let user = await Auth.currentAuthenticatedUser();
            const details = await getUserProfile(user?.attributes?.sub);
            const userPermissions = await getUserPermissions();
            const incognitoStatus = await getCommunityOptInStatus();
            setIncognitoStatus(incognitoStatus?.private);
            let resourceActions = getResourceActions(userPermissions);
            details.user_details['permissions'] = userPermissions;
            details.user_details['resourceActions'] = resourceActions;
            await updateUserDetailsToStore(details.user_details);
        }
        catch (error) {
            updateUserDetailsToStore(null)
            console.log(error)
        }
    }


    const updateUserDetailsToStore = async payload => {
        if (payload) {
            await props.updateUserDetails(payload);
            await props.updateUserLogin(true);
            setIsUserValidated(true);
        }
        else {
            setIsUserValidated(false);
            props.updateUserLogin(false);
            props.updateUserDetails(null);
        }
    }

    const setIncognitoStatus = (isPrivate) => {
        props.updateUserIncognitoStatus(isPrivate);
    };
    
    const onSeeCookiesClick = () => {
        navigate('/cookie-details', {state: { fromFooter: false }});
      }
      const onAcceptClick = async () => {
        setBannerCookie(false);
        setCookie("cvb_user", true, 365);
        await injectGAScript();
    }
    const onLoginButtonClick= ()=> {
        setLoginButtonClick(true);
        setTimeout(() => {
            setLoginButtonClick(false);
        }, 1000);
    }
    const onCookieDetailPage = (value) =>{
        setOnCookiePage(value);
    }

    return (
        <React.Fragment>
            {   !onCookiePage && 
                <CookieBanner loginButtonClick={loginButtonClick} onAcceptClick={onAcceptClick} onSeeCookiesClick={onSeeCookiesClick} showCookieBanner={showCookieBanner} />
            }
            <Router id="router" primary={false}>
                <Feedback path="/feedback"/>
                <Register onLoginButtonClick={onLoginButtonClick} path="/register" exact />
                <RegisterByInvite onLoginButtonClick={onLoginButtonClick} path="/register-beta" exact />
                <ForgotPassword path="/forgot-password" exact />
                <ConfirmCongitoSignUp path="/confirm-signup" exact />
                <CookiesDetails onCookieDetailPage={onCookieDetailPage} showCookieBanner={showCookieBanner} path="/cookie-details" exact/>
                <DeactivationSuccess  path="/deactivation-success" exact />
                <ProtectedRoute isScriptInjected={isScriptInjected} path="/onboarding/*" component={UserOnboardHome} onLoginButtonClick={onLoginButtonClick} isUserLoggedIn={isUserValidated} />
                {env.REACT_APP_COMMUNITY_HIDDEN !== 'true' && <ProtectedRoute isScriptInjected={isScriptInjected} component={TellUsMore} isUserLoggedIn={isUserValidated} onLoginButtonClick={onLoginButtonClick} path="/tell-us-more/:id" />}
                <ProtectedRoute isScriptInjected={isScriptInjected} path="/*" component={AuthenticatedLanding} onLoginButtonClick={onLoginButtonClick} isUserLoggedIn={isUserValidated} />
                <Redirect from="/" to="/" default noThrow />
            </Router>
            <SnackBar />
        </React.Fragment>
    )
}

export default Root;
import DIFPrimaryInvestigator from './DIFPrimaryInvestigator'
import { connect } from 'react-redux'
import{ setDifPIStreetAddress, setDifPICountry,
    setDifPIState, setDifPICity, setDifPIMunicipality, setDifPIZipCode,
    setDifPIFirstName, setDifPILastName, setDifPIPhoneNumber, setDifPIDepartmentName, 
    setDifPIEmail
} from '../../../redux/actions/difPIActions'
const mapStateToProps = state => {
    return {
        difPIFirstName: state.difPI.difPIFirstName,
        difPILastName: state.difPI.difPILastName,
        difPIPhoneNumber: state.difPI.difPIPhoneNumber,
        difPIEmail: state.difPI.difPIEmail,
        difPIDepartmentName: state.difPI.difPIDepartmentName,
        difPIStreetAddress: state.difPI.difPIStreetAddress,
        difPICountry: state.difPI.difPICountry,
        difPIState: state.difPI.difPIState,
        difPICity: state.difPI.difPICity,
        difPIMunicipality: state.difPI.difPIMunicipality,
        difPIZipCode: state.difPI.difPIZipCode,
        currentDIFFormStage: state.dataInventory.currentDIFFormStage
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setDifPIStreetAddress: async (value) => await dispatch(setDifPIStreetAddress(value)),
        setDifPICountry: async (value) => await dispatch(setDifPICountry(value)),
        setDifPIState: async (value) => await dispatch(setDifPIState(value)),
        setDifPICity: async (value) => await dispatch(setDifPICity(value)),
        setDifPIMunicipality: async (value) => await dispatch(setDifPIMunicipality(value)),
        setDifPIZipCode: async (value) => await dispatch(setDifPIZipCode(value)),
        setDifPIFirstName: async (value) => await dispatch(setDifPIFirstName(value)),
        setDifPILastName: async (value) => await dispatch(setDifPILastName(value)),
        setDifPIPhoneNumber: async (value) => await dispatch(setDifPIPhoneNumber(value)),
        setDifPIDepartmentName: async (value) => await dispatch(setDifPIDepartmentName(value)),
        setDifPIEmail: async (value) => await dispatch(setDifPIEmail(value)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DIFPrimaryInvestigator);
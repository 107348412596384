import { SET_USER_LOGIN, SET_USER_DETAILS, SET_USER_PRIVATE_STATUS } from '../action-types'

export const updateUserLogin = (value) => {
    return {
        type: SET_USER_LOGIN,
        payload: value
    }
}

export const updateUserDetails = payload => {
    return {
        type: SET_USER_DETAILS,
        payload: {...payload}
    }
}

export const updateUserIncognitoStatus = value => {
    return {
        type: SET_USER_PRIVATE_STATUS,
        payload: value
    };
};
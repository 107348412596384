import EditProfileHome from './EditProfileHome'
import { connect } from 'react-redux'
import { setTabsValueInEditProfile } from '../../../redux/actions/editProfileActions'
import { updateSnackBar, updatePullCount } from '../../../redux/actions/globalActions'
import { updateUserDetails } from '../../../redux/actions/userActions'
const mapStateToProps = state => {
    return {
        tabValue: state.editProfile.tabValue,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setTabsValueInEditProfile: async (value) => await dispatch(setTabsValueInEditProfile(value)),
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type)),
        updatePullCount: () => dispatch(updatePullCount()),
        updateUserDetails: payload => dispatch(updateUserDetails(payload)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(EditProfileHome);

import MyStuff from './MyStuff'
import { connect } from 'react-redux'
import { getUserCohorts, resetMyStuffStore } from '../../../redux/actions/myStuffActions'
import { setDCAStage, resetDCAForm, setCurrentDCAFormStage } from '../../../redux/actions/dcaActions'
import { resetProjectDetails } from '../../../redux/actions/projectDetailsActions'
import { resetProjectInstitutionForm } from '../../../redux/actions/projectPrimaryInstitutionalActions'
import { resetPIForm } from '../../../redux/actions/projectPIActions'
import { resetProjectAdminForm } from '../../../redux/actions/projectAdminActions'
import { resetZone3UserForm } from '../../../redux/actions/zone3UsersActions'
import { resetAuthSubmitterForm } from '../../../redux/actions/authorizeSubmitterActions'
import { setDifStage } from '../../../redux/actions/dataInventoryActions'
import { resetInstitutionFormData } from '../../../redux/actions/difInstitutionActions'
import { resetPrimaryInvestigatorData } from '../../../redux/actions/difPIActions'
import { resetDataSharingData } from '../../../redux/actions/difDataSharingActions'
import { resetOtherPersonnelData } from '../../../redux/actions/difOtherPersonalActions'
import { resetTimelineData } from '../../../redux/actions/difTimelineActions'
import { resetIndividualDatasetData } from '../../../redux/actions/difIndividualDatasetActions'
import { setInstitutionalCertAdminName } from '../../../redux/actions/institutionalCertActions'
import { resetDifStudyData } from '../../../redux/actions/difStudyActions'
const mapStateToProps = state => {
    return {
        userCohorts: state.myStuff.userCohorts,
        lastEvaluatedKey: state.myStuff.lastEvaluatedKey,
        userDetails: state.user.userDetails,
        allWorkspaces: state.workspace.allWorkspaces
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUserCohorts: async (limit, lastExecutedKey) => await dispatch(getUserCohorts(limit, lastExecutedKey)),
        resetMyStuffStore: async () => dispatch(resetMyStuffStore())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyStuff);
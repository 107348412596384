/**
 *  External Imports
 */
import { connect } from "react-redux";

/**
 *  Internal Imports
 */
import CommunityWidgetBodyItem from "./CommunityWidgetBodyItem";

const mapStateToProps = ({ community: { fullscreenView } }) => ({
    fullscreenView,
});

export default connect(mapStateToProps, null)(CommunityWidgetBodyItem);

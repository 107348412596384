import { getStudyGalleryItemDetails } from '../api/graphql/study-gallery';
import { GET_ANALYSIS_DATA_VALUES } from '../api/graphql-query-helpers/graphql';
import {
  saveCohortWithoutID,
  saveCohortToDBApi,
  getCohortsForUserApi,
  deleteCohort,
} from '../api/analysis-api';
import {
  getCohortFiltersValues,
  getCohortAggregatesData,
  getCohortStatisticsData,
} from '../api/graphql/exploreData';
import { getAccessibleProjects } from '../api/upload-data-api';
import {getStudyParticipants} from "../redux/actions/studyGalleryActions";

export async function getFilters() {
  return await GET_ANALYSIS_DATA_VALUES;
}

export async function getStatisticsForVariables(
  cohort,
  variableName1,
  variableName2
) {
  if (variableName1 === '') return [];
  const cohortsStatisticsData = await getCohortStatisticsData(cohort, [
    variableName1,
    variableName2,
  ]);
  // Check if the returned histogram has any valid values which can be shown on the chart.
  return !cohortsStatisticsData.data._aggregation.case[variableName1]
    .histogram?.every( element => element.termsFields === null) ?
      cohortsStatisticsData.data._aggregation.case[variableName1].histogram
    : [];
}

export async function getStudiesForCohort(cohort) {
  if(!cohort.data.length) {
    return []
  }
  const cohortProjectIds = cohort.data.project_id.histogram
    .filter((s) => s.count > 0)
    .map((s) => s.key);
  return cohortProjectIds.length > 0
    ? await getStudyGalleryItemDetails(cohortProjectIds)
    : [];
}

export async function getStudyParticipantsForCohort(cohort) {
  const cohortProjectIds = cohort.data?.project_id?.histogram
      .filter(({ count }) => count > 0)
      .map(({ key }) => key) || [];

  //Generate filter value from cohort project ids
  const filterValue = cohortProjectIds.reduce((obj, item) => ({ ...obj, [item]: true }), {});

  return cohortProjectIds.length > 0
      ? await getStudyParticipants({ project_id: filterValue })
      : [];
}

export async function getFiltersValues(filterColumnsAttributes, fieldName) {
  const filterValuesQuery = await getCohortFiltersValues(
    filterColumnsAttributes,
    fieldName
  );
  const filtersValues = filterValuesQuery.data._aggregation.case;
  return filtersValues[fieldName] ? filtersValues[fieldName]['histogram'] : [];
}

export async function getCohortAggregates(cohort, fields) {
  const result = await getCohortAggregatesData(cohort, fields);

  // Return if no query fields were selected, resulting in an invalid query.
  if(result === null) {
    return null;
  }

  let populatedCohort = result.data._aggregation.case;

  // Only filter empty project cohorts.
  if(cohort.cohort_type === "project") {
    populatedCohort = filterCohortsWithNoData(result.data._aggregation.case);
  }

  if(populatedCohort !== null) {
    return calculateSubjectsCount(populatedCohort);
  }

  return null;
}

function filterCohortsWithNoData(cohort) {
  const dataKeys = Object.keys(cohort).filter(key => key !== '__typename');

  const filteredHistogram = dataKeys.every(key => {
    const histogram = cohort[key]?.histogram;

    if (!histogram || histogram.length === 0) {
      return true;
    }

    return histogram.every(histogramObj => {
      return histogramObj.min === null && histogramObj.max === null;
    });
  });

  if (!filteredHistogram) {
    return cohort;
  }

  return null;
}

function calculateSubjectsCount(cohort) {
  // Pick any key from what has been queried and use it to generate the subjects count.
  const countKey = Object.keys(cohort).filter(keyName => keyName !== '__typename').pop();
  const count = cohort[countKey].histogram.reduce((prev, el) => el.count > 0 ? el.count + prev : prev, 0);

  return {
    subjects: {
      histogram: [
        {
          min: count,
          max: count,
          __typename: 'BucketsForNestedNumberAgg',
        },
      ],
    },
    ...cohort,
  };
}

export async function getUserCohorts(limit = 100, exclusive_start_key = {}) {
  const response = await getCohortsForUserApi(limit, exclusive_start_key);
  const cohorts = response?.cohorts?.map((cohort) => {
    return { ...cohort, cohort_query: JSON.parse(cohort.cohort_query) };
  });
  return cohorts;
}

export async function addCohort(cohortName, query, user) {
  const cohortQuery = JSON.stringify(query);
  const workspaceCohortQuery = JSON.stringify({filter: query});

  const body = {
    cohort_name: cohortName,
    query: cohortQuery,
    cohort_type: 'cohort',
    analysis: {},
    //Used for export cohort to workspace
    filter_for_export_to_workspace: workspaceCohortQuery,
  };

  return await saveCohortToDBApi(body);
}

export async function getProjectsCohorts(user) {
  const availableProjects = await getAccessibleProjects();
  return availableProjects.map((p) => ({
    uuid: p.project_id,
    cohort_name: p.project_id,
    cohort_type: 'project',
    cohort_query: {
      OR: [
        {
          IN: {
            project_id: [p.project_id],
          },
        },
      ],
    },
    created_at: p.createdAt,
  }));
}

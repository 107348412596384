/**
 *  External Imports
 */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { navigate } from "@reach/router";
import { func, bool, string, object } from "prop-types";

/**
 *  Internal Imports
 */
import { COMMUNITY_ACTION_BUTTONS } from "../../../constants/buttons/community";
import useScrollToTop from "../../commons/scroll-top";
import SectionHeader from "../../commons/section-header";
import { communityMappedAPI } from "../../../api/community-api";
import styles from "./index.module.css";
import { PAGES_DEFINITIONS } from "../../../constants/strings";

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 */
const Community = (props) => {
    // Variables
    const { open, navigateTo, navigationState, toggleCommunityView, isUserPrivate } = props;
    const { initTherapeuticAreas, initMolecularClasses, resetRedirectPath } =
        props;

    const initDataCancelTokenSrc = axios.CancelToken.source();

    const sectionHeaderProps = {
        title: "Community",
        pageDefinition: PAGES_DEFINITIONS["community"],
        defaultActionButtons: [...COMMUNITY_ACTION_BUTTONS],
    };

    const initData = async () => {
        const {
            isCanceled,
            data: { molecularClasses, therapeuticAreas },
        } = await communityMappedAPI.getMappedGlobalSearchFilters(
            initDataCancelTokenSrc.token
        );

        if (isCanceled) {
            return;
        }

        initTherapeuticAreas(therapeuticAreas);
        initMolecularClasses(molecularClasses);
    };

    // Functions Call
    useScrollToTop();

    // Life Cycles
    useEffect(() => {
        isUserPrivate ? navigate("/") : initData();

        return () => {
            initDataCancelTokenSrc.cancel();
        };
    }, [isUserPrivate]);

    useEffect(() => {
        if (navigateTo) {
            navigationState
                ? navigate(navigateTo, { state: navigationState })
                : navigate(navigateTo);
            resetRedirectPath();
        }
    }, [navigateTo]);

    useEffect(() => {
        toggleCommunityView(open);
    }, [open]);

    // Return
    return (
        <div className={styles.community}>
            <div className={styles.container}>
                <Grid container>
                    <SectionHeader
                        data-testid="sectionHeader" 
                        open={open}
                        sectionHeaderProps={sectionHeaderProps}
                    />
                </Grid>
                {props.children}
            </div>
        </div>
    );
};

/**
 *  Properties
 */
Community.propTypes = {
    isUserPrivate: bool.isRequired,
    open: bool.isRequired,
    navigateTo: string.isRequired,
    navigationState: object,
    initTherapeuticAreas: func.isRequired,
    initMolecularClasses: func.isRequired,
    resetRedirectPath: func.isRequired,
    toggleCommunityView: func.isRequired,
};

/**
 *  Exports
 */
export default Community;

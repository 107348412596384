
import { connect } from 'react-redux'
import AdminUserProfile from './AdminProfile'
import { updateSnackBar } from '../../../redux/actions/globalActions'
const mapStateToProps = state => {
    return {
        userData: state.userAdminReducer.userData
    }
}

const mapDispatchToProps = dispatch => ({
    updateSnackBar: async (message, type) => await dispatch(updateSnackBar(message, type)),
})


export default connect(mapStateToProps, mapDispatchToProps)(AdminUserProfile);
import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import {env} from '../../../env'
import Classes from './index.module.css'
import Grid from '@mui/material/Grid'
import { navigate } from '@reach/router'
import BackButtonArrow from '../../../assets/icons/left_arrow.png'
import TrainingAndCertificate from "../../controls/training-and-certificate"
import ServiceAgreement from '../../controls/service-agreement'
import { updateServiceAgreement, updateWorkspaceRequest, getServiceAgreementDetails } from '../../../api/workspace-api/workspace-api'
import isEmpty from 'lodash/isEmpty'
import isDate from 'lodash/isDate'
import moment from 'moment'
import { scrollToGivenTop } from '../../../utils/input-validations'
import { Auth } from 'aws-amplify'
import capitalize from 'lodash/capitalize'
import cloneDeep from 'lodash/cloneDeep'
import { getUserProfile, getOrganizationList } from '../../../api/profile-api'
import get from 'lodash/get';
import useScrollToTop from '../../commons/scroll-top';
import { VALID_EMAIL_REGEX } from '../../../constants/regex'
import { sendGAEvents } from '../../../api/generic-api'
import ErrorText from '../../controls/error-text';
import OutlinedInput from '@mui/material/OutlinedInput'
import { getUsersForNotebookShare } from '../../../api/workspace-api/workspace-api'
import { SIGNUP_ERROR_TEXTS } from '../../../constants/strings';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MultiSelect from '../../commons/multi-select'
import { getAccessibleProjects } from '../../../api/upload-data-api'
import CommonDatePicker from '../../commons/common-date-picker';
import uniq from 'lodash/uniq';
import InputChips from '../../controls/input-chips'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
import { getUserSearchAdminApi } from '../../../api/admin/user'
import map from 'lodash/map'
import debounce from 'lodash/debounce'
import DropDown from '../../commons/dropdown'
import { COUNTRY_LIST } from '../../../constants/strings'
import Checkbox from '@mui/material/Checkbox';
import SignatureCanvas from 'react-signature-canvas'
import { validatePhoneNumber, validateEmailAddress } from '../../../utils/input-validations';
import InputAdornment from '@mui/material/InputAdornment';

const RequestAdvanceCompute = props => {
	const [stage, setStage] = useState(1);
	const { serviceAgreementDate = null, clientName = '', agreementYears = '', signatoryName = '', signatoryTitle = '',
		nonCommercialCheck = false, servicesCheck, agreementChecks, id } = props;
	const [isLoading, setIsLoading] = useState(false)
	const [researcherSign, setResearcherSign] = useState('')
	const [isFormSubmitted, setIsFormSubmitted] = useState(false)
	const [userDetails, setUserDetails] = useState({});

	const [projectTitle, setProjectTitle] = useState('');

	const [dataAnalysis, setDataAnalysis] = useState('');
	const [workspaceType, setWorkspaceType] = useState('');
	const [contractNumber, setContractNumber] = useState('');
	const [date, setDate] = useState(moment(new Date()).format('MM/DD/YYYY'));

	const [editRequestDetails, setEditRequestDetails] = useState({});
	const [contactNumber, setContactNumber] = useState('');
	const [contractTitle, setContractTitle] = useState('');
	const [contractAddress, setContractAddress] = useState('');
	const [contractCountry, setContractCountry] = useState('');
	const [contractState, setContractState] = useState('');
	const [contractCity, setContractCity] = useState('');
	const [contractZipCode, setContractZipCode] = useState('');
	const [creditAmount, setCreditAmount] = useState(null);

	const [billingTitle, setBillingTitle] = useState('');
	const [billingName, setBillingName] = useState('');
	const [billingEmail, setBillingEmail] = useState('');
	const [billingAddress, setBillingAddress] = useState('');
	const [billingCountry, setBillingCountry] = useState('');
	const [billingState, setBillingState] = useState('');
	const [billingCity, setBillingCity] = useState('');
	const [billingZipCode, setBillingZipCode] = useState('');
	const [sameBillingAddress, setSameBillingAddress] = useState(null);
	const [billingContactNumber, setBillingContactNumber] = useState('');

	const [isAgreeSelected, setIsAgreeSelected] = useState(false);

	const [allOrganizations, setAllOrganizations] = useState([]);
	const [organizationsList, setOrganizationsList] = useState([]);
	const [organization, setOrganization] = useState('');
	const [otherOrganization, setOtherOrganization] = useState('');
	const [billingOrganization, setBillingOrganization] = useState('');
	const [otherBillingOrganization, setOtherBillingOrganization] = useState('');

	const [openCalender, setOpenCalender] = useState(false);
	const [selectedCalenderInput, setSelectedCalenderInput] = useState('Select date');
	const [workspaceAccessDate, setWorkspaceAccessDate] = useState('');

	const [isFormUpdating, setIsFormIsUpdating] = useState(false);

	const sigRef = useRef(null);
	const [userText, setUserText] = useState({ text: '', isValid: false, isSubmit: false, invalidText: SIGNUP_ERROR_TEXTS.EMAIL, isAlreadyPresent: false });
	const [availableSearchProjectsList, setAvailableSearchProjectsList] = useState(null)
	const [selectedProjects, setSelectedProjects] = useState({
		values: availableSearchProjectsList || [],
		selectedValues: []
	})
	const [filteredUsersList, setFilteredUsersList] = useState([]);
	const [tagsInputText, setTagsInputText] = useState('');
	const [usersForShare, setUsersForShare] = useState([])
	const [selectedUsers, setSelectedUsers] = useState([])
	const [isUserListLoading, setIsUserListLoading] = useState(false);

	const agreementText = env.REACT_APP_AGREEMENT_TEXT

	const isSignatureValid = () => !sigRef?.current?.isEmpty();
	
	const clearSignature = () => {
        sigRef.current.clear();
        setResearcherSign('')
	}

	const createDUPSign = async () => (!isEmpty(researcherSign) && !isEmpty(sigRef.current)) && (await sigRef?.current?.fromDataURL(researcherSign));

	const fetchAvailableProjects = async () => {
		try {
			const res = await getAccessibleProjects();
			let projectIds = []
			if (res ?.length) {
				projectIds = res ?.map(x => x.project_id);
			}
			setAvailableSearchProjectsList(projectIds)
		}
		catch (error) {
			console.log(error)
		}
	}

	const fetchWorksaceRequest = async (id) => {
		try {
			const advancedComputeRequest = await getServiceAgreementDetails(props.userDetails.username, id);
			setEditRequestDetails(advancedComputeRequest);
			const org = find(organizationsList, (attr) => attr['name'] === advancedComputeRequest?.additional_fields?.user_information?.organization_name);
			const billingorg = find(organizationsList, (attr) => attr['name'] === advancedComputeRequest?.additional_fields?.billing_information?.organization_name);
			if(org){
				setOrganization(advancedComputeRequest?.additional_fields?.user_information?.organization_name)
			}else{
				setOrganization('Other')
				setOtherOrganization(advancedComputeRequest?.additional_fields?.user_information?.organization_name)
			}
			
			if(advancedComputeRequest?.additional_fields?.billing_same_as_user_details){
				setSameBillingAddress(true);
			}else{
				setBillingName(advancedComputeRequest?.additional_fields?.billing_information?.name)
				setBillingEmail(advancedComputeRequest?.additional_fields?.billing_information?.email)
				setBillingContactNumber(advancedComputeRequest?.additional_fields?.billing_information?.phone)
				setBillingTitle(advancedComputeRequest?.additional_fields?.billing_information?.title)
				setBillingAddress(advancedComputeRequest?.additional_fields?.billing_information?.address?.street)
				setBillingCity(advancedComputeRequest?.additional_fields?.billing_information?.address?.city)
				setBillingCountry(advancedComputeRequest?.additional_fields?.billing_information?.address?.country)
				setBillingState(advancedComputeRequest?.additional_fields?.billing_information?.address?.state)
				setBillingZipCode(advancedComputeRequest?.additional_fields?.billing_information?.address?.zip_code)
				if(billingorg){
					setBillingOrganization(advancedComputeRequest?.additional_fields?.billing_information?.organization_name)
				}else{
					setBillingOrganization('Other')
					setOtherBillingOrganization(advancedComputeRequest?.additional_fields?.billing_information?.organization_name)
				}
			}

			setContractNumber(advancedComputeRequest?.request_id)
			setContactNumber(advancedComputeRequest?.additional_fields?.user_information?.phone)
			setContractAddress(advancedComputeRequest?.additional_fields?.user_information?.address?.street)
			setContractCountry(advancedComputeRequest?.additional_fields?.user_information?.address?.country)
			setContractCity(advancedComputeRequest?.additional_fields?.user_information?.address?.city)
			setContractState(advancedComputeRequest?.additional_fields?.user_information?.address?.state)
			setContractZipCode(advancedComputeRequest?.additional_fields?.user_information?.address?.zip_code)
			setWorkspaceType(advancedComputeRequest?.workspace_type)
			setDataAnalysis(advancedComputeRequest?.additional_fields?.proposal)
			setWorkspaceAccessDate(advancedComputeRequest?.additional_fields?.duration ? moment(advancedComputeRequest?.additional_fields?.duration)?.toDate() : '')
			setProjectTitle(advancedComputeRequest?.additional_fields?.project_title)
			setCreditAmount(parseFloat(advancedComputeRequest?.credit_amount))
			setContractTitle(advancedComputeRequest?.additional_fields?.user_information?.title)
			setDate(advancedComputeRequest?.additional_fields?.date)
			//setResearcherSign(advancedComputeRequest?.additional_fields?.signature)
			//setIsAgreeSelected(true)

		}
		catch (error) {
			console.log(error)
		}
	}
	

	const getOrganizations = async () => {
		try {
			const res = await getOrganizationList();
			const orgResList = res ?.organizations || []
            setOrganizationsList(orgResList);
			if (orgResList.length > 0) {
				let orgList = map(orgResList, (org) => org ?.name);
				orgList = orgList.sort();
				setAllOrganizations(orgList);
				if(!id){
					const org = find(orgResList, (attr) => attr['id'] === props.userDetails.organization_id);
					if (props.userDetails.custom_attributes.other_organization) {
						setOtherOrganization(props.userDetails.custom_attributes.other_organization)
					}
					if(org){
						setOrganization(org.name)
					}
					setContractAddress(props.userDetails?.institution_details?.streetAddress)
					setContractCountry(props.userDetails?.institution_details?.country)
					setContactNumber(props.userDetails?.phone_number)
					setContractCity(props.userDetails?.institution_details?.city)
					setContractState(props.userDetails?.institution_details?.state)
					setContractZipCode(props.userDetails?.institution_details?.zipCode)
					setContractTitle(props.userDetails?.designation)
					
				}
			} else {
				setAllOrganizations([]);
			}


		} catch (error) {
			setAllOrganizations([]);
			console.log('error in getting organization list')
		}
	}

	const onOrganizationTextChange = (value) => {
		setOrganization(value)
		if(value !== 'Other'){
			setOtherOrganization('')
		}
	}

	const onBillingOrganizationTextChange = (value) => {
		setBillingOrganization(value)
		if(value !== 'Other'){
			setOtherBillingOrganization('')
		}
	}

	useEffect(() => {
		fetchAvailableProjects();
		getOrganizations();
	}, [])

	useEffect(() => {
		if(id){
			fetchWorksaceRequest(id)
		}
	},[organizationsList])

	const debounceUserListAPICall = useCallback(debounce((tagsInputText) => {
		if (!isEmpty(tagsInputText)) {
			setIsUserListLoading(true);
			getUsersForShare(tagsInputText);
		} else {
			setIsUserListLoading(false);
		}
	}, 2000), []);

	const getUsersForShare = async (tagsInputText) => {
		try {
			const users = await getUserSearchAdminApi(tagsInputText);
			if (users ?.users) {
				let userEmailList = [];
				map((users ?.users || []), (user) => userEmailList.push(user.email));
				setUsersForShare(userEmailList);
				setIsUserListLoading(false);
			}
		}
		catch (error) {
			setIsUserListLoading(false);
			setUsersForShare([]);
			console.log(error)
		}
	}

	useScrollToTop();

	const onSignatureEnd = async () => {
        const serviceAgreementSign = sigRef?.current?.toDataURL();
        setResearcherSign(serviceAgreementSign);
    }


	const accessWorkspaceRequest = async () => {
		try {
			let billingOrganizationValue = ''
			if(sameBillingAddress){
				billingOrganizationValue = otherOrganization ? otherOrganization : organization
			}else{
				billingOrganizationValue = otherBillingOrganization ? otherBillingOrganization : billingOrganization
			}
			const body = {
				"request_id": id ? id : "",
				"workspace_type": workspaceType,
				"credit_amount": parseFloat(creditAmount),
				"additional_fields": {
				  "project_title": projectTitle,
				  "proposal": dataAnalysis,
				  "duration": workspaceAccessDate ? moment(workspaceAccessDate).format('MM/DD/YYYY') : '',
				  "user_information": {
					"title": contractTitle,
					"name": props.userDetails.first_name + ' ' + props.userDetails.last_name,
					"email": props.userDetails.email,
					"organization_name": otherOrganization ? otherOrganization : organization,
					"phone": contactNumber,
					"address": {
					  "street": contractAddress,
					  "city": contractCity,
					  "state": contractState,
					  "zip_code": contractZipCode,
					  "country": contractCountry
					}
				  },
				  "billing_same_as_user_details": sameBillingAddress ? sameBillingAddress : false,
				  "billing_information": {
					"title": billingTitle || (sameBillingAddress && contractTitle),
					"name": billingName || (sameBillingAddress && props.userDetails.first_name + ' ' + props.userDetails.last_name),
					"email": billingEmail || (sameBillingAddress && props.userDetails.email),
					"organization_name": billingOrganizationValue,
					"phone": billingContactNumber || (sameBillingAddress && contactNumber),
					"address": {
					  "street": billingAddress || (sameBillingAddress && contractAddress),
					  "city": billingCity || (sameBillingAddress && contractCity),
					  "state": billingState || (sameBillingAddress && contractState),
					  "zip_code": billingZipCode || (sameBillingAddress && contractZipCode),
					  "country": billingCountry || (sameBillingAddress && contractCountry),
					}
				  },
				  "date": moment.utc().format('MM/DD/YYYY'),
				  "signature": researcherSign
				}
			}
			setIsFormIsUpdating(true);
			const res = await updateWorkspaceRequest(body, id);
			
			sendGAEvents('submit_workspace_access_request', 'workspace_access', 'workspace access request submit');
			props.updateSnackBar(res?.message || 'Request to advanced compute has been successfully submitted.', 'success');
			navigate('/my-profile')
			
		} catch (error) {
			console.log('error in acess workspace request', error);
			props.updateSnackBar(error?.message || 'Error in advanced compute request', 'error');
		} finally {
			setIsFormIsUpdating(false);
		}
	}

	const onCancelAndButtonClick = () => {
		navigate('/my-profile');
		props.resetAgreementValues();
	}

	const isFormValid = () => {
		let validForm = true;
		if (isEmpty(projectTitle?.trim())) {
			validForm = false
		}
		if (isEmpty(workspaceType?.trim())) {
			validForm = false;
		}
		if (isEmpty(contractAddress?.trim())) {
			validForm = false;
		}
		if (isEmpty(contractCity?.trim())) {
			validForm = false;
		}
		if (isEmpty(contractCountry)) {
			validForm = false;
		}
		if (isEmpty(contractState?.trim())) {
			validForm = false;
		}
		if (isEmpty(contractZipCode?.trim())) {
			validForm = false;
		}
		if (!validatePhoneNumber(contactNumber)) {
			validForm = false;
		}
		if(creditAmount <= 0){
			validForm = false
		}
		if (isEmpty(billingName?.trim()) && !sameBillingAddress) {
			validForm = false;
		}
		if (!validateEmailAddress(billingEmail) && !sameBillingAddress) {
			validForm = false;
		}
		if (!validatePhoneNumber(billingContactNumber) && !sameBillingAddress) {
			validForm = false;
		}
		if (isEmpty(billingAddress?.trim()) && !sameBillingAddress) {
			validForm = false;
		}
		if (isEmpty(billingCity?.trim()) && !sameBillingAddress) {
			validForm = false;
		}
		if (isEmpty(billingCountry?.trim()) && !sameBillingAddress) {
			validForm = false;
		}
		if (isEmpty(billingState?.trim()) && !sameBillingAddress) {
			validForm = false;
		}
		if (isEmpty(billingZipCode?.trim()) && !sameBillingAddress) {
			validForm = false;
		}
		if(!researcherSign){
			validForm = false;
		}
		if(!isAgreeSelected){
			validForm = false;
		}
		return validForm;
	}

	const onRequestButtonClick = async () => {
		setIsFormSubmitted(true);
		if (isFormValid()) {
			accessWorkspaceRequest();
		}
	}

	const onWorkspaceSelection = (type) => {
		setWorkspaceType(type)
	}

	const userResults = useMemo(() => {
		const searchText = userText['text'] || "";
		return usersForShare ?.filter(item => item.toLowerCase().includes(searchText.toLowerCase()))
	}, [usersForShare, userText['text']])

	const onProjectTitleChange = (event) => {
		setProjectTitle(event.target.value)
	}

	const onContractTitleChange = (event) => {
		setContractTitle(event.target.value)
	}

	const onContactNumberChange = (event) => {
		setContactNumber(event.target.value)
	}

	const onSameBillingAddress = (value) => {
		if(value){
			setSameBillingAddress(value)
			setBillingName('')
			setBillingEmail('')
			setBillingContactNumber('')
			setBillingTitle('')
			setBillingAddress('')
			setBillingCity('')
			setBillingCountry('')
			setBillingState('')
			setBillingZipCode('')
			setBillingOrganization('')
			// if(organization === 'Other'){
			// 	setOtherBillingOrganization(otherOrganization)
			// }
		}else{
			setSameBillingAddress('')
		}
	}

	

	const onDataAnalysisChange = (event) => {
		setDataAnalysis(event.target.value)
	}

	const handleDataCategoryStateChange = (selectedValues) => {
		const clone = cloneDeep(selectedProjects)
		clone.selectedValues = selectedValues;
		setSelectedProjects(clone)
	}

	const handleUsersStateChange = (user) => {
		const isUserPresent = findIndex(selectedUsers, (item) => item === user) > -1;
		if (!isUserPresent) {
			const clone = cloneDeep(selectedUsers);
			clone.push(user);
			setSelectedUsers(clone);
			setTagsInputText('');
		}
	}
	/** to deletes added users. Also push deleted user to userlist to filter */
	const deleteSelectedTagFromList = (index) => {
		let presentTags = cloneDeep(selectedUsers);
		if (index > -1) {
			presentTags.splice(index, 1);
		}
		setSelectedUsers(presentTags);
		setTagsInputText('');
	}

	const onTagsInputTextChange = value => {
		setIsUserListLoading(true);
		setTagsInputText(value);
		debounceUserListAPICall(value);
	}

	const onWorkspaceAccessChange = (date) => {
		const calenderInputText = moment(date).format('MM/DD/YYYY');
		setSelectedCalenderInput(calenderInputText);
		setWorkspaceAccessDate(date);
	}

	return (
		<div className={Classes.editProfileMainContainer}>
			<div className={Classes.editProfileChildContainer}>
				<Grid container direction='column'>
					<p id='cvb-requestAdvanceCompute-backButton' onClick={() => navigate('/my-profile')} className={Classes.backButton}>
						<span><img alt='back button arrow' src={BackButtonArrow} /></span>
						<p id='cvb-requestAdvanceCompute-backButtonText' onClick={onCancelAndButtonClick} className={Classes.backButtonText}>Back</p>
					</p>
					<h3 id='cvb-requestAdvanceCompute-heading' className={Classes.editProfileText}>Requesting Advanced Compute</h3>

					{<div className={Classes.agreementHeader}>
						<Grid container direction='column'>
							<div className={Classes.formStepperHorizontal}>
								<div className={Classes.formStep}>
									<div className={stage == 1 ? Classes.formStepActiveCircle : Classes.formStepCircle}><span></span></div>
									<div id='cvb-requestAdvanceCompute-serviceAgreement' className={Classes.formStepTitle}>Submit Proposal</div>
									<div id='cvb-requestAdvanceCompute-signServiceAgreementText' className={Classes.formStepOptional}>Submit proposed analysis to be conducted with the use of a workspace</div>
									<div className={Classes.formStepBarLeft}></div>
									<div className={Classes.formStepBarRight}></div>
								</div>
							</div>
						</Grid>
					</div>}

				</Grid>

				<Grid container direction='row' className={Classes.advancedComputeContainer}>
					<div className={Classes.impTitleHeaderContainer}>
						<p id='cvb-serviceAgreement-importantHeading' className={Classes.alertTitle}>Important </p>
						<p id='cvb-serviceAgreement-fillServAgrInfo'>Please provide the proposed use of the workspace services. This should
						include the proposed analysis and any information you can provide about the workspace instance capabilities
						needed to support these efforts. This proposal will be submitted to the BRAINCommons (BC) Team.
						You will be provided information about next steps from the BC Team within one week.</p>
					</div>
				</Grid>

				<Grid spacing={3} xs={10} container direction='row' className={Classes.advancedComputeContainer}>

					<Grid item xs={10}>
						<p className={`${Classes.formTitle}`} id="cvb-advancedCompute-proposedScope">Proposed Scope</p>
					</Grid>

					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='projectTitle' id="cvb-advancedCompute-projectTitleLabel">Project Title<sup> *</sup></label>
						<OutlinedInput
							type="text"
							value={projectTitle}
							error={isFormSubmitted && isEmpty(projectTitle?.trim())}
							fullWidth
							inputProps={{ maxLength: 256 }}
							onChange={onProjectTitleChange}
							id='cvb-advancedCompute-projectTitle'
							name='projectTitle'
						/>
						{
							isFormSubmitted && isEmpty(projectTitle?.trim()) &&
							<ErrorText errorMessage="Enter Project Title" />
						}
					</Grid>

					<Grid item xs={10} className={Classes.fieldCont}>
						<div className={Classes.textareaCont}>
							<label htmlFor='dataAnalysis' id="cvb-advancedCompute-dataAnalysisLabel">Brief description of data analysis methods</label>
							<textarea rows={5} name='dataAnalysis' type="text"
								value={dataAnalysis} placeholder="Enter Brief description of data analysis methods"
								onChange={onDataAnalysisChange}
								id="cvb-advancedCompute-dataAnalysis"
							></textarea>
						</div>
					</Grid>



					<Grid item xs={10} className={Classes.fieldCont}>
						<label id="cvb-advancedCompute-workspaceInstanceLabel">Type of Workspace <sup> *</sup></label>
						<p>Note that it is not possible to switch to a different type of Workspace after it has been provisioned. You are strongly
							advised to review the <a target="_blank" rel="noreferrer" href="https://help.braincommons.org/article/99-workspace-types-and-costs">characteristics of each Workspace type</a>, before making a selection.
						</p>
						<Grid container>
							<Grid item xs={2} className={Classes.radioCheckContainer}>
								<label className={Classes.radioContainer} id="cvb-advancedCompute-ec2Label">Linux</label>
								<input id='isDataCurated1' type="radio" name="isDataCurated"
									checked={workspaceType === 'EC2' ? true : false}
									onChange={() => onWorkspaceSelection('EC2')} />
								<span className={Classes.checkmark}></span>
							</Grid>
							<Grid item xs={2} className={Classes.radioCheckContainer}>
								<label className={Classes.radioContainer} id="cvb-advancedCompute-windowsLabel">Windows</label>
								<input id='isDataCurated2' type="radio" name="isDataCurated"
									checked={workspaceType === 'WINDOWS' ? true : false}
									onChange={() => onWorkspaceSelection('WINDOWS')}
									value={workspaceType} />
								<span className={Classes.checkmark}></span>
							</Grid>
						</Grid>
						{
							isFormSubmitted && isEmpty(workspaceType) &&
							<ErrorText errorMessage="Select a Type of Workspace" />
						}
					</Grid>

					<Grid item xs={5} className={Classes.fieldCont}>
						<label>Expected duration of workspace access needed to complete the analysis</label>
						<CommonDatePicker
							onCalenderDateSelected={onWorkspaceAccessChange}
							minDate={moment().add(0, 'd').toDate()}
							date={workspaceAccessDate}
							id='cvb-advancedCompute-workspaceDate'
							canClearInput />
					</Grid>

					<Grid item xs={10}>
						<p className={`${Classes.formTitle}`} id="cvb-advancedCompute-contract">BRAINCommons&trade; ADVANCED COMPUTE CONTRACT (the “Contract”)</p>
					</Grid>
					{
						id ?
						<Grid item xs={10} className={Classes.fieldCont}>
							<Grid xs={6}>
								<label htmlFor='contractNumber' id="cvb-advancedCompute-contractNumber">Contract Number</label>
								<OutlinedInput
									type="text"
									disabled
									value={contractNumber}
									fullWidth
									inputProps={{ maxLength: 256 }}
									id='cvb-advancedCompute-contractNumberInput'
									name='contractNumber'
								/>
							</Grid>
						</Grid> : null
					}
					

					<Grid item xs={10} className={Classes.fieldCont}>
						<Grid xs={6}>
							<label htmlFor='date'>Application Date</label>
							<OutlinedInput
								type="text"
								disabled
								value={date}
								fullWidth
								id='cvb-advancedCompute-date'
								name='date'
							/>
						</Grid>
					</Grid>


					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='firstName' id="cvb-advancedCompute-firstNameLabel"> Name<sup> *</sup></label>
						<OutlinedInput
							type="text"
							value={`${props.userDetails.first_name} ${props.userDetails.last_name}`}
							disabled
							fullWidth
							id='cvb-advancedCompute-firstName'
							name='firstName'
							placeholder='Enter Name'

						/>
					</Grid>

					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='firstName' id="cvb-advancedCompute-lastNameLabel"> Email<sup> *</sup></label>
						<OutlinedInput
							type="text"
							value={props.userDetails.email}
							disabled
							fullWidth
							id='cvb-advancedCompute-lastName'
							name='lastName'
							placeholder='Enter email'
						/>
					</Grid>


					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='firstName' id="cvb-advancedCompute-lastNameLabel"> Title</label>
						<OutlinedInput
							type="text"
							value={contractTitle}
							onChange={onContractTitleChange}
							fullWidth
							id='cvb-advancedCompute-lastName'
							name='lastName'
							placeholder='Enter title'
						/>
					</Grid>

					<Grid item xs={5}>
						<label id='cvb-advancedCompute-contactNumberLabel' htmlFor='contactNumber'>Contact Number<sup> *</sup></label>
						<OutlinedInput
							fullWidth
							value={contactNumber}
							onChange={onContactNumberChange}
							error={isFormSubmitted && !validatePhoneNumber(contactNumber)}
							placeholder='Enter contact number'
							name='contactNumber'
							id='cvb-advancedCompute-contactNumberInput'
						/>
						<p className={Classes.hintText}>Country code is optional</p>
						{
							isFormSubmitted && !validatePhoneNumber(contactNumber) &&
							<ErrorText errorMessage="Please enter valid contact number" />
						}
					</Grid>



					<Grid item xs={5}>
						<label id='cvb-userOnboarding-organizationLabel' htmlFor="organization" > Organization<sup> *</sup></label>
						<DropDown
							values={allOrganizations}
							handleFilterStateChange={onOrganizationTextChange}
							selectedFilter={organization}
							error={isFormSubmitted && isEmpty(organization?.trim())}
							placeholder={'Select your organization'}
							customClass={Classes.organizationField}
							id='cvb-userOnboarding-organizationInput'
						/>
						{
							isFormSubmitted && isEmpty(organization?.trim()) &&
							<ErrorText errorMessage="Please select an organization" />
						}
					</Grid>

					{
						organization === 'Other' &&

						<Grid item xs={5}>
							<label id='cvb-userOnboarding-organizationLabel' htmlFor="organization" > Organization Name<sup> *</sup></label>
							<OutlinedInput
								fullWidth
								value={otherOrganization} onChange={e => setOtherOrganization(e.target.value)}
								placeholder='Enter your organization name' name='otherOrganizationName'
								error={isFormSubmitted && isEmpty(otherOrganization?.trim())}
								id='cvb-userOnboarding-otherOrganizationLabel'
							/>
							{
								isFormSubmitted && isEmpty(otherOrganization?.trim()) &&
								<ErrorText errorMessage="Please enter a valid organization name" />
							}

						</Grid>

					}

					<Grid item xs={10} className={Classes.fieldCont}>
						<Grid item xs={6} className={Classes.fieldCont}>
							<label htmlFor='country' id="cvb-DIFFormStep1-countryLabel">Country<sup> *</sup></label>
							<DropDown
								id="cvb-DIFFormStep1-instituteCountry"
								name='instituteCountry'
								values={COUNTRY_LIST}
								handleFilterStateChange={value => setContractCountry(value)}
								selectedFilter={contractCountry}
								error={isFormSubmitted && isEmpty(contractCountry)}
								placeholder={'Select Country'}
								searchable
								isClearable
							/>
							{
								isFormSubmitted && isEmpty(contractCountry) &&
								<ErrorText errorMessage="Please select a country" />
							}
						</Grid>
					</Grid>
					<Grid item xs={10} className={Classes.fieldCont}>
						<label htmlFor='firstName' id="cvb-advancedCompute-lastNameLabel"> Street address<sup> *</sup></label>
						<OutlinedInput
							type="text"
							value={contractAddress}
							onChange={e => setContractAddress(e.target.value)}
							error={isFormSubmitted && isEmpty(contractAddress?.trim())}
							fullWidth
							id='cvb-advancedCompute-lastName'
							name='lastName'
						/>
						{
							isFormSubmitted && isEmpty(contractAddress?.trim()) &&
							<ErrorText errorMessage="Please enter street address" />
						}
					</Grid>
					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">City<sup> *</sup></label>
						<OutlinedInput
							type="text"
							value={contractCity}
							onChange={e => setContractCity(e.target.value)}
							error={isFormSubmitted && isEmpty(contractCity?.trim())}
							fullWidth
							id='cvb-advancedCompute-organization'
							name='organization'
							placeholder='Enter city'
						/>
						{
							isFormSubmitted && isEmpty(contractCity?.trim()) &&
							<ErrorText errorMessage="Please enter city" />
						}
					</Grid>
					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">State<sup> *</sup></label>
						<OutlinedInput
							type="text"
							value={contractState}
							onChange={e => setContractState(e.target.value)}
							error={isFormSubmitted && isEmpty(contractState?.trim())}
							fullWidth
							id='cvb-advancedCompute-organization'
							name='organization'
							placeholder='Enter state'

						/>
						{
							isFormSubmitted && isEmpty(contractState?.trim()) &&
							<ErrorText errorMessage="Please enter state" />
						}
					</Grid>
					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">Zip Code<sup> *</sup></label>
						<OutlinedInput
							type="text"
							value={contractZipCode}
							fullWidth
							error={isFormSubmitted && isEmpty(contractZipCode?.trim())}
							onChange={e => setContractZipCode(e.target.value)}
							id='cvb-advancedCompute-organization'
							name='organization'
							placeholder='Enter zipcode'
						/>
						{
							isFormSubmitted && isEmpty(contractZipCode?.trim()) &&
							<ErrorText errorMessage="Please enter zip code" />
						}
					</Grid>




					<Grid item xs={10}>
						<p className={`${Classes.formTitle}`} id="cvb-advancedCompute-workspaceUserDetails">Billing Information</p>
					</Grid>
					
					<Grid item xs={10}>
						<Checkbox
							color="primary"
							checked={sameBillingAddress}
							value={sameBillingAddress}
							onChange={(e) => { onSameBillingAddress(e.target.checked); }}
							id='cvb-termsOfUse-checkbox'
						/> <label className={Classes.labelText}>Same as above</label>
					</Grid>



					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='firstName' id="cvb-advancedCompute-firstNameLabel"> Title</label>
						<OutlinedInput
							type="text"
							value={billingTitle || (sameBillingAddress && contractTitle)}
							onChange={e => setBillingTitle(e.target.value)}
							fullWidth
							disabled={sameBillingAddress}
							id='cvb-advancedCompute-firstName'
							name='firstName'
							placeholder='Enter Title'

						/>
					</Grid>

					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='firstName' id="cvb-advancedCompute-firstNameLabel"> Name<sup> *</sup></label>
						<OutlinedInput
							type="text"
							value={billingName || (sameBillingAddress && props.userDetails.first_name + ' ' + props.userDetails.last_name)}
							onChange={e => setBillingName(e.target.value)}
							error={isFormSubmitted && isEmpty(billingName?.trim()) && !sameBillingAddress}
							fullWidth
							disabled={sameBillingAddress}
							id='cvb-advancedCompute-firstName'
							name='firstName'
							placeholder='Enter Name'

						/>
						{
							isFormSubmitted && isEmpty(billingName?.trim()) && !sameBillingAddress &&
							<ErrorText errorMessage="Please enter name" />
						}
					</Grid>

					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='firstName' id="cvb-advancedCompute-lastNameLabel"> Email<sup> *</sup></label>
						<OutlinedInput
							type="text"
							value={billingEmail || (sameBillingAddress && props.userDetails.email)}
							onChange={e => setBillingEmail(e.target.value)}
							error={isFormSubmitted && !validateEmailAddress(billingEmail) && !sameBillingAddress}
							fullWidth
							disabled={sameBillingAddress}
							id='cvb-advancedCompute-lastName'
							name='lastName'
							placeholder='Enter email'
						/>
						{
							isFormSubmitted && !validateEmailAddress(billingEmail) && !sameBillingAddress &&
							<ErrorText errorMessage="Please enter valid email" />
						}
					</Grid>

					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='firstName' id="cvb-advancedCompute-lastNameLabel"> Contact Number<sup> *</sup></label>
						<OutlinedInput
							type="text"
							value={billingContactNumber || (sameBillingAddress && contactNumber)}
							disabled={sameBillingAddress}
							onChange={e => setBillingContactNumber(e.target.value)}
							error={isFormSubmitted && !validatePhoneNumber(billingContactNumber) && !sameBillingAddress}
							fullWidth
							id='cvb-advancedCompute-lastName'
							name='lastName'
							placeholder='Enter email'
						/>
						<p className={Classes.hintText}>Country code is optional</p>
						{
							isFormSubmitted && !sameBillingAddress && 
							!validatePhoneNumber(billingContactNumber) &&
							<ErrorText errorMessage="Please enter valid contact number" />
						}
					</Grid>


					<Grid item xs={5}>
						<label id='cvb-userOnboarding-organizationLabel' htmlFor="organization" > Organization<sup> *</sup></label>
						<DropDown
							values={allOrganizations}
							handleFilterStateChange={onBillingOrganizationTextChange}
							selectedFilter={billingOrganization || (sameBillingAddress && organization)}
							placeholder={'Select your organization'}
							error={isFormSubmitted && isEmpty(billingOrganization) && !sameBillingAddress}
							disable={sameBillingAddress}
							customClass={Classes.organizationField}
							id='cvb-userOnboarding-organizationInput'
						/>
						{
							isFormSubmitted && isEmpty(billingOrganization) && !sameBillingAddress &&
							<ErrorText errorMessage="Please select an organization" />
						}
					</Grid>

					
					{
						(billingOrganization === 'Other' || (sameBillingAddress && organization === 'Other') ) &&


						<Grid item xs={5} className={Classes.fieldCont}>
							<label htmlFor='firstName' id="cvb-advancedCompute-lastNameLabel"> Organization Name<sup> *</sup></label>
							<OutlinedInput
								type="text"
								value={otherBillingOrganization || (sameBillingAddress && otherOrganization)}
								onChange={e => setOtherBillingOrganization(e.target.value)}
								error={isFormSubmitted && isEmpty(otherBillingOrganization?.trim()) && !sameBillingAddress}
								fullWidth
								disabled={sameBillingAddress}
								id='cvb-advancedCompute-lastName'
								name='lastName'
								placeholder='Enter email'
							/>
							{
								isFormSubmitted && isEmpty(otherBillingOrganization?.trim()) && !sameBillingAddress &&
								<ErrorText errorMessage="Please select an organization" />
							}
						</Grid>
					}


					<Grid item xs={10} className={Classes.fieldCont}>
						<Grid item xs={6} className={Classes.fieldCont}>
							<label htmlFor='country' id="cvb-DIFFormStep1-countryLabel">Country<sup> *</sup></label>
							<DropDown
								id="cvb-DIFFormStep1-instituteCountry"
								name='instituteCountry'
								values={COUNTRY_LIST}
								handleFilterStateChange={value => setBillingCountry(value)}
								disable={sameBillingAddress}
								selectedFilter={billingCountry || (sameBillingAddress && contractCountry)}
								error={isFormSubmitted && isEmpty(billingCountry) && !sameBillingAddress}
								placeholder={'Select Country'}
								searchable
								isClearable
							/>
							{
								isFormSubmitted && isEmpty(billingCountry) && !sameBillingAddress &&
								<ErrorText errorMessage="Please select a country" />
							}
						</Grid>
					</Grid>
					<Grid item xs={10} className={Classes.fieldCont}>
						<label htmlFor='firstName' id="cvb-advancedCompute-lastNameLabel"> Street address<sup> *</sup></label>
						<OutlinedInput
							type="text"
							value={billingAddress || (sameBillingAddress && contractAddress)}
							disabled={sameBillingAddress}
							onChange={e => setBillingAddress(e.target.value)}
							error={isFormSubmitted && isEmpty(billingAddress?.trim()) && !sameBillingAddress}
							fullWidth
							id='cvb-advancedCompute-lastName'
							name='lastName'
						/>
						{
							isFormSubmitted && isEmpty(billingAddress?.trim()) && !sameBillingAddress &&
							<ErrorText errorMessage="Please enter street address" />
						}
					</Grid>
					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">City<sup> *</sup></label>
						<OutlinedInput
							type="text"
							value={billingCity || (sameBillingAddress && contractCity)}
							disabled={sameBillingAddress}
							onChange={e => setBillingCity(e.target.value)}
							error={isFormSubmitted && isEmpty(billingCity?.trim()) && !sameBillingAddress}
							fullWidth
							id='cvb-advancedCompute-organization'
							name='organization'
							placeholder='Enter city'
						/>
						{
							isFormSubmitted && isEmpty(billingCity?.trim()) && !sameBillingAddress &&
							<ErrorText errorMessage="Please enter city" />
						}
					</Grid>
					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">State<sup> *</sup></label>
						<OutlinedInput
							type="text"
							value={billingState || (sameBillingAddress && contractState)}
							disabled={sameBillingAddress}
							onChange={e => setBillingState(e.target.value)}
							error={isFormSubmitted && isEmpty(billingState?.trim()) && !sameBillingAddress}
							fullWidth
							id='cvb-advancedCompute-organization'
							name='organization'
							placeholder='Enter state'

						/>
						{
							isFormSubmitted && isEmpty(billingState?.trim()) && !sameBillingAddress &&
							<ErrorText errorMessage="Please enter state" />
						}
					</Grid>
					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">Zip Code<sup> *</sup></label>
						<OutlinedInput
							type="text"
							value={billingZipCode || (sameBillingAddress && contractZipCode)}
							disabled={sameBillingAddress}
							fullWidth
							error={isFormSubmitted && isEmpty(billingZipCode?.trim()) && !sameBillingAddress}
							onChange={e => setBillingZipCode(e.target.value)}
							id='cvb-advancedCompute-organization'
							name='organization'
							placeholder='Enter zipcode'
						/>
						{
							isFormSubmitted && isEmpty(billingZipCode?.trim()) && !sameBillingAddress &&
							<ErrorText errorMessage="Please enter zip code" />
						}
					</Grid>


					<Grid item xs={10}>
						<p className={`${Classes.formTitle}`} id="cvb-advancedCompute-workspaceAccountCredit">WorkSpace Account Credit</p>
						<p>Before using the WorkSpace you will need to purchase pre-paid credits to use towards your WorkSpace Account (“Credit”) and pay a one-time maintenance fee (“Fee”). For each WorkSpace you wish to create, you will need to enter into a separate BRAINCommons Advanced Compute Contract and separately purchase Credits for each WorkSpace.</p>
						<p>Once you complete and execute this Contract, BC Manager will review your Contract details within a reasonable time and issue an invoice in the amount of the Credit plus the Fee set forth in your Contract to the billing address provided in this Contract. You will not have access to the WorkSpace and will not have the ability to use certain tools until receipt of full payment of such invoice by BC Manager.</p>
 						<p>You acknowledge that cost in connection with each WorkSpace may vary depending on many factors, including type of WorkSpace, amount of data stored and usage and that it is your responsibility to ensure that sufficient Credits are added to your WorkSpace. You understand that you will be charged a separate non-reimbursable Fee for establishing each WorkSpace.  You understand that if your Credit balance falls to zero for a given WorkSpace, such WorkSpace will be immediately frozen and you may be locked out of such WorkSpace and BC Manager will have the right to delete such WorkSpace. For more information and to help you decide the appropriate amount of Credit to maintain in your WorkSpace Account, please review the information provided <a rel="noreferrer" target="_blank"  href="https://help.braincommons.org/article/99-workspace-types-and-costs">here</a>.</p>
						<p>When the remaining Credit is, in BC Manager’s sole opinion, sufficient for only five (5) weeks of use based on past WorkSpace usage (as calculated by BC Manager), we will use commercially reasonable efforts to notify you and upon your request in writing to add additional Credits to a given WorkSpace, we will issue an invoice for such amount. Credits will not be added to your WorkSpace until payment is received by BC Manager.</p>
						<p>Notwithstanding any of the foregoing, any estimates and notices provided by the BC Manager or otherwise published on this BC Platform is only for User’s convenience and is not guaranteed.  Neither BRAINCommons LLC nor BC Manager makes any representations or warranties with respect to such estimates and notices, including as to its accuracy.</p>
					</Grid>

					<Grid item xs={5} className={Classes.fieldCont}>
						<label htmlFor='creditAmount' id="cvb-advancedCompute-creditAmount">
							Amount of Credit to be added to the WorkSpace<sup> *</sup>
							(<a rel="noreferrer" target="_blank"  href="https://help.braincommons.org/article/99-workspace-types-and-costs">Click here for more information that will help you decide the appropriate amount of Credit for your WorkSpace)</a>:
						</label>
						<OutlinedInput
							type="number"
							value={creditAmount}
							fullWidth
							error={isFormSubmitted && creditAmount <= 0}
							onChange={e => setCreditAmount(e.target.value)}
							id='cvb-advancedCompute-creditAmountInput'
							name='creditAmount'
							placeholder='Enter credit amount'
							startAdornment={(
								<InputAdornment position="start">
									$
								</InputAdornment>
							)}
						/>
						{
							isFormSubmitted && creditAmount <= 0 &&
							<ErrorText errorMessage="Please enter a valid credit amount" />
						}
					</Grid>
							
				</Grid>

				<Grid item xs={12} className={Classes.signatureMainContainer} >
					<div className={Classes.signatureContainer}>
						<p><label>Sign Policy<sup> *</sup></label></p>
						<p>Sign your name on the trackpad and press any key when finished</p>
						<p className={Classes.signBoxText}>By signing below, I confirm that I will comply with the terms and conditions set forth on this BRAINCommons Advanced Compute Contract in addition to the BRAINCommons Data Use Agreement and other applicable policies and terms of use of the BC Platform.</p>
						<div id='signature-pad' className={Classes.signaturePad}>
							<div className={Classes.clickHeretoBegin}></div>
							<SignatureCanvas penColor='black'
								ref={sigRef }
								onEnd={onSignatureEnd}
								canvasProps={{ width: 500, height: 150, className: 'sigCanvas' }} />
							<button className={`no-outlined-button ${Classes.clearButton}`} onClick={clearSignature}>Clear</button>
							{
								isFormSubmitted && !isSignatureValid() &&
								<ErrorText errorMessage="Signature mandatory" />
							}
						</div>
					</div>
					<div xs={3} className={Classes.agreeContainer}>
						<span className={Classes.agreeCheckbox}>
							<Checkbox
								color="primary"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
								value={isAgreeSelected}
								checked={isAgreeSelected}
								onChange={(e) => setIsAgreeSelected(e.target.checked)}
							/>
						</span>
						<span className={Classes.agreeContent}>
							{agreementText ? agreementText : "I confirm that by signing this Agreement I am legally bound by its terms and conditions"}
						</span>
					</div>
					{
						isFormSubmitted && !isAgreeSelected &&
						<ErrorText errorMessage="Please confirm agreement" />
					}
				</Grid>

			</div>
			<div className={Classes.agreementFormButtons}>
				<button className={`no-outlined-button`} onClick={onCancelAndButtonClick} >
					Cancel
                </button>
				<button disabled={isFormUpdating} onClick={() => onRequestButtonClick()} className={`medium-size-button solid-button ${Classes.requestAccessButton}`}>
					Request
                </button>
			</div>
		</div>
	)
}

export default RequestAdvanceCompute

/**
 *  External Imports
 */
import React from "react";
import { bool, string } from "prop-types";

/**
 *  Internal Imports
 */

import styles from "./index.module.css";

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 */
const RateStar = (props) => {
    const { isSelected, rateText } = props;

    return (
        <div className={styles.rateStar}>
            <svg data-testid="star"
                className={`${
                    isSelected ? styles.rateStarImgSelected : styles.rateStarImg
                }`}
                fill="none"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m16 1.618 3.1167 9.5922 0.1123 0.3455h10.449l-8.1597 5.9283-0.2938 0.2136 0.1122 0.3455 3.1167 9.5922-8.1596-5.9284-0.2939-0.2135-0.2939 0.2135-8.1596 5.9284 3.1167-9.5922 0.1122-0.3455-0.2938-0.2136-8.1597-5.9283h10.449l0.1123-0.3455 3.1167-9.5922z"
                    stroke="#637BBD"
                />
            </svg>
            <span data-testid="starText" className={styles.rateText}>{rateText}</span>
        </div>
    );
};

/**
 *  Properties
 */
RateStar.propTypes = {
    isSelected: bool.isRequired,
    rateText: string.isRequired
};

/**
 *  Exports
 */
export default RateStar;

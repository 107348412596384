// user redux constants
export const SET_USER_LOGIN = "SET_USER_LOGIN"
export const SET_USER_DETAILS = "SET_USER_DETAILS"
export const SET_USER_PRIVATE_STATUS = "SET_USER_PRIVATE_STATUS";

//signup form action types
export const SET_USER_FIRST_NAME = "SET_USER_FIRST_NAME"
export const SET_USER_LAST_NAME = "SET_USER_LAST_NAME"
export const SET_USER_PASSWORD = "SET_USER_PASSWORD"
export const SET_EMAIL = "SET_EMAIL"
export const SET_ORG_NAME = "SET_ORG_NAME"
export const SET_CONFIRM_PASSWORD = "SET_CONFIRM_PASSWORD"

//user onboard form action types
export const CHANGE_STEPPER_COUNT = "CHANGE_STEPPER_COUNT"
export const SET_ONBOARDING_FIRST_NAME = "SET_ONBOARDING_FIRST_NAME"
export const SET_ONBOARDING_LAST_NAME = "SET_ONBOARDING_LAST_NAME"
export const SET_ONBOARDING_TITLE = "SET_ONBOARDING_TITLE"
export const SET_ONBOARDING_DEPARTMENT = "SET_ONBOARDING_DEPARTMENT"
export const SET_ONBOARDING_INSTITUTION_NAME = "SET_ONBOARDING_INSTITUTION_NAME"
export const SET_ONBOARDING_CITY = "SET_ONBOARDING_CITY"
export const SET_ONBOARDING_COUNTRY = "SET_ONBOARDING_COUNTRY"
export const SET_ONBOARDING_STATE = "SET_ONBOARDING_STATE"
export const SET_ONBOARDING_SECTOR = "SET_ONBOARDING_SECTOR"
export const SET_ONBOARDING_DEGREE = "SET_ONBOARDING_DEGREE"

export const SET_USERS_DESIGNATION = "SET_USERS_DESIGNATION"
export const SET_USERS_ORGANIZATION_NAME = "SET_USERS_ORGANIZATION_NAME"
export const SET_USERS_BIO = "SET_USERS_BIO"
export const SET_PROFILE_PICTURE_OF_USER = "SET_PROFILE_PICTURE_OF_USER"
export const SET_TAGS_INPUT_TEXT = "SET_TAGS_INPUT_TEXT"
export const SET_TAGS_LIST = "SET_TAGS_LIST"
export const SET_KEYWORDS_LIST = "SET_KEYWORDS_LIST"
export const CLEAR_ONBOARD_FORM = "CLEAR_ONBOARD_FORM"
export const SET_ONBOARD_INITIAL_STATE = "SET_ONBOARD_INITIAL_STATE"
export const SET_USERS_OTHER_DESIGNATION = "SET_USERS_OTHER_DESIGNATION"
export const SET_RESEARCHER_GOALS = "SET_RESEARCHER_GOALS"
export const SET_SCIENTIFIC_BACKGROUND = "SET_SCIENTIFIC_BACKGROUND"
export const SET_ZONE_2_REQUEST_PERSONAL_DETAILS_INITIAL_STATE = "SET_ZONE_2_REQUEST_PERSONAL_DETAILS_INITIAL_STATE"
export const SET_OTHER_ORG_NAME_PROP = "SET_OTHER_ORG_NAME_PROP"
export const SET_OTHER_HIGHEST_EDUCATION = 'SET_OTHER_HIGHEST_EDUCATION'
export const SET_OTHER_ORGANIZATION_NAME = 'SET_OTHER_ORGANIZATION_NAME'

//edit profile action types
export const SET_TAB_VALUE_IN_EDIT_PROFILE = "SET_TAB_VALUE_IN_EDIT_PROFILE"
export const SET_EDIT_CONTACT_NUMBER = "SET_EDIT_CONTACT_NUMBER"
export const SET_EDIT_STREET_ADDRESS = "SET_EDIT_STREET_ADDRESS"
export const SET_EDIT_CITY = "SET_EDIT_CITY"
export const SET_EDIT_STATE = "SET_EDIT_STATE"
export const SET_EDIT_ZIP_CODE = "SET_EDIT_ZIP_CODE"
export const SET_EDIT_COUNTRY = "SET_EDIT_COUNTRY"
export const SET_EDIT_PROFILE_INITIAL_STATE = "SET_EDIT_PROFILE_INITIAL_STATE"
export const SET_EDIT_INSTITUTION_NAME = "SET_EDIT_INSTITUTION_NAME"
export const SET_USERS_LINKEDIN_PROFILE = "SET_USERS_LINKEDIN_PROFILE"
export const SET_USERS_GITHUB_PROFILE = "SET_USERS_GITHUB_PROFILE"
export const SET_USERS_TWITTER_PROFILE = "SET_USERS_TWITTER_PROFILE"
export const SET_USERS_GOOGLE_PROFILE = "SET_USERS_GOOGLE_PROFILE"
export const SET_USERS_ORCHID_PROFILE = "SET_USERS_ORCHID_PROFILE"
export const SET_USERS_PERSONAL_PROFILE = "SET_USERS_PERSONAL_PROFILE"


//analysis data action types
export const ADD_NEW_ANALYSIS = "ADD_NEW_ANALYSIS"
export const SET_SELECTED_ANALYSIS = "SET_SELECTED_ANALYSIS"
export const SET_X_AXIS_ANALYSIS_ATTRIBUTE = 'SET_X_AXIS_ANALYSIS_ATTRIBUTE'
export const SET_Y_AXIS_ANALYSIS_ATTRIBUTE = "SET_Y_AXIS_ANALYSIS_ATTRIBUTE"
export const SET_CHART_TYPE_ANALYSIS_ATTRIBUTE = "SET_CHART_TYPE_ANALYSIS_ATTRIBUTE"
export const REMOVE_ANALYSIS = "REMOVE_ANALYSIS"
export const SAVE_ANALYSIS_COHORT = "SAVE_ANALYSIS_COHORT"
export const ON_ANALYZE_COHORT = "ON_ANALYZE_COHORT"


// Explore Data action types
export const UPDATE_EXPLORE_DATA_FILTERS = "UPDATE_EXPLORE_DATA_FILTERS"
export const UPDATE_EXPLORE_DATA = "UPDATE_EXPLORE_DATA"
export const UPDATE_CUSTOMIZE_OPTIONS = "UPDATE_CUSTOMIZE_OPTIONS"
export const ON_COHORT_SELECTED = "ON_COHORT_SELECTED"
export const UPDATE_EXPLORE_TOTAL_COUNT = "UPDATE_EXPLORE_TOTAL_COUNT"
export const UPDATE_ACCESSIBLE_EXPLORE_TOTAL_COUNT = "UPDATE_ACCESSIBLE_EXPLORE_TOTAL_COUNT"
export const UPDATE_EXPLORE_DATA_LOADER = "UPDATE_EXPLORE_DATA_LOADER"
export const UPDATE_EXCLUDE_FROM_EXPLORATION = "UPDATE_EXCLUDE_FROM_EXPLORATION"
export const UPDATE_PROJECTS_COUNT = 'UPDATE_PROJECTS_COUNT'
export const UPDATE_EXPLORE_DATA_FILTER_SELECTION = 'UPDATE_EXPLORE_DATA_FILTER_SELECTION'
export const UPDATE_EXPLORE_SUN_BURST_GRAPH_DATA = "UPDATE_EXPLORE_SUN_BURST_GRAPH_DATA"
export const UPDATE_EXPLORE_AGE_AT_ENROLLMENT_GRAPH_DATA = "UPDATE_EXPLORE_AGE_AT_ENROLLMENT_GRAPH_DATA"
export const UPDATE_UNSTRUCTURED_FILES_GRAPH_DATA = "UPDATE_UNSTRUCTURED_FILES_GRAPH_DATA"


// My Stuff action types
export const UPDATE_USER_COHORTS = "UPDATE_USER_COHORTS"
export const UPDATE_ANALYSIS_WITH_ID = "UPDATE_ANALYSIS_WITH_ID"
export const UPDATE_LAST_EVALUATED_KEY = "UPDATE_LAST_EVALUATED_KEY"
export const UPDATE_SELECTED_WORKSPACE_FILE_DATA = "UPDATE_SELECTED_WORKSPACE_FILE_DATA"

//Global Actions
export const UPDATE_SNACK_BAR = "UPDATE_SNACK_BAR"
export const UPDATE_PROFILE_PICTURE_URL = "UPDATE_PROFILE_PICTURE_URL"
export const PULL_USER_DETAILS_COUNT = "PULL_USER_DETAILS_COUNT"
export const UPDATE_IS_ANY_WORKSPACE_STARTED = "UPDATE_IS_ANY_WORKSPACE_STARTED"
export const UPDATE_WEB_SOCKET_CONNECTION = "UPDATE_WEB_SOCKET_CONNECTION"
export const UPDATE_SOCKET_MESSAGE = "UPDATE_SOCKET_MESSAGE"

//Service Actions
export const SET_AGREEMENT_DATE = "SET_AGREEMENT_DATE"
export const SET_CLIENT_NAME = "SET_CLIENT_NAME"
export const SET_AGREEMENT_YEAR = "SET_AGREEMENT_YEAR"
export const SET_SIGNATORY_NAME = "SET_SIGNATORY_NAME"
export const SET_SIGNATORY_TITLE = "SET_SIGNATORY_TITLE"
export const SET_NON_COMMERCIAL_CHECK = "SET_NON_COMMERCIAL_CHECK"
export const SET_SERVICES_CHECK = "SET_SERVICES_CHECK"
export const SET_AGREEMENT_CHECK = "SET_AGREEMENT_CHECK"


//dca actions
export const SET_DCA_STAGE = "SET_DCA_STAGE"
export const SET_DCA_AGREEMENT_DATE = "SET_DCA_AGREEMENT_DATE"
export const SET_DCA_CLIENT_NAME = "SET_DCA_CLIENT_NAME"
export const SET_DCA_CONTRIBUTOR_NAME = "SET_DCA_CONTRIBUTOR_NAME"
export const SET_CONTRIBUTOR_SIGNING_NAME = "SET_CONTRIBUTOR_SIGNING_NAME"
export const SET_CONTRIBUTOR_SIGNING_BY_TEXT = "SET_CONTRIBUTOR_SIGNING_BY_TEXT"
export const SET_CONTRIBUTOR_SIGNING_TITLE = "SET_CONTRIBUTOR_SIGNING_TITLE"
export const SET_CONTRIBUTOR_SIGNING_DATE = "SET_CONTRIBUTOR_SIGNING_DATE"

export const SET_DCA_REVIEW_DATE = "SET_DCA_REVIEW_DATE"
export const SET_DCA_REVIEW_APPROVE_TEXT = "SET_DCA_REVIEW_APPROVE_TEXT"
export const SET_DCA_REVIEW_BY_TEXT = "SET_DCA_REVIEW_BY_TEXT"
export const SET_DCA_REVIEWER_NAME = "SET_DCA_REVIEWER_NAME"
export const SET_DCA_REVIEWER_TITLE = "SET_DCA_REVIEWER_TITLE"
export const SET_DCA_REVIEWER_SIGN_DATE = "SET_DCA_REVIEWER_SIGN_DATE"
export const SET_DCA_ALL_FORM_FIELDS = "SET_DCA_ALL_FORM_FIELDS"
export const RESET_DCA_FORM = "RESET_DCA_FORM"
export const SET_CURRENT_DCA_FORM_STAGE = "SET_CURRENT_DCA_FORM_STAGE"
export const SET_DCA_SIGNATURE = "SET_DCA_SIGNATURE"
export const SET_ACKNOWLEDGMENT_SIGNATURE = "SET_ACKNOWLEDGMENT_SIGNATURE"
export const SET_INSTITUTE_SIGNATURE = "SET_INSTITUTE_SIGNATURE"

//project details action
export const SET_PROJECT_DETAILS_PROJECT_NAME = "SET_PROJECT_DETAILS_PROJECT_NAME"
export const SET_PROJECT_DETAILS_PROJECT_SYNOPSIS_TEXT = "SET_PROJECT_DETAILS_PROJECT_SYNOPSIS_TEXT"
export const SET_PROJECT_DETAILS_IRB_APPROVAL_NUMBER = "SET_PROJECT_DETAILS_IRB_APPROVAL_NUMBER"
export const SET_PROJECT_DETAILS_IRB_NUMBER_FILE = "SET_PROJECT_DETAILS_IRB_NUMBER_FILE"
export const SET_PROJECT_DETAILS_DESIGNATED_ZONE = "SET_PROJECT_DETAILS_DESIGNATED_ZONE"
export const SET_PROJECT_DETAILS_RESTRICTION_OF_USE_TEXT = "SET_PROJECT_DETAILS_RESTRICTION_OF_USE_TEXT"
export const SET_PROJECT_DETAILS_IS_DATASET_DOWNLOADABLE = "SET_PROJECT_DETAILS_IS_DATASET_DOWNLOADABLE"
export const SET_PROJECT_DETAILS_ACK_STATEMENT = "SET_PROJECT_DETAILS_ACK_STATEMENT"
export const SET_PROJECT_DETAILS_TYPE_OF_DATA = "SET_PROJECT_DETAILS_TYPE_OF_DATA"
export const SET_PROJECT_DETAILS_OTHER_TYPE_DATA_TEXT = "SET_PROJECT_DETAILS_OTHER_TYPE_DATA_TEXT"
export const SET_PROJECT_DETAILS_TOTAL_SIZE_OF_DATA = "SET_PROJECT_DETAILS_TOTAL_SIZE_OF_DATA"
export const SET_PROJECT_DETAILS_LOGO_FILE = "SET_PROJECT_DETAILS_LOGO_FILE"
export const SET_PROJECT_DETAILS_OBJECTIVE_OF_STUDY = "SET_PROJECT_DETAILS_OBJECTIVE_OF_STUDY"
export const SET_PROJECT_DETAILS_DESCRIPTION_OF_STUDY = "SET_PROJECT_DETAILS_DESCRIPTION_OF_STUDY"
export const SET_PROJECT_DETAILS_DATA_DESCRIPTION = "SET_PROJECT_DETAILS_DATA_DESCRIPTION"
export const SET_PROJECT_DETAILS_STUDY_DESIGN = "SET_PROJECT_DETAILS_STUDY_DESIGN"
export const SET_PROJECT_DETAILS_NUMBER_OF_GRANTS = "SET_PROJECT_DETAILS_NUMBER_OF_GRANTS"
export const SET_PROJECT_DETAILS_DURATION_OF_MONTHS = "SET_PROJECT_DETAILS_DURATION_OF_MONTHS"
export const SET_PROJECT_DETAILS_LOCATION_OF_STUDY = "SET_PROJECT_DETAILS_LOCATION_OF_STUDY"
export const SET_PROJECT_DETAILS_PUBLICATIONS = "SET_PROJECT_DETAILS_PUBLICATIONS"
export const REMOVE_PROJECT_DETAILS_PUBLICATIONS = "REMOVE_PROJECT_DETAILS_PUBLICATIONS"
export const SET_PROJECT_DETAILS_PUBLICATION_URL = "SET_PROJECT_DETAILS_PUBLICATION_URL"
export const SET_PROJECT_DETAILS_PUBLICATION_NAME = "SET_PROJECT_DETAILS_PUBLICATION_NAME"
export const SET_IS_RESEARCHER_ALLOWED = "SET_IS_RESEARCHER_ALLOWED"
export const SET_PROJECT_DETAILS_FORM_FIELDS = "SET_PROJECT_DETAILS_FORM_FIELDS"
export const RESET_PROJECT_DETAILS_FORM_FIELDS = "RESET_PROJECT_DETAILS_FORM_FIELDS"
export const SET_PROJECT_DETAILS_IRB_APPROVAL_KEY = 'SET_PROJECT_DETAILS_IRB_APPROVAL_KEY'
export const UPDATE_SELECTED_PROJECTS = 'UPDATE_SELECTED_PROJECTS'

// ppi actions
export const SET_PPI_FIRST_NAME = "SET_PPI_FIRST_NAME"
export const SET_PPI_LAST_NAME = "SET_PPI_LAST_NAME"
export const SET_PPI_MIDDLE_NAME = "SET_PPI_MIDDLE_NAME"
export const SET_PPI_CREDENTIALS = "SET_PPI_CREDENTIALS"
export const SET_PPI_INSTITUTE_NAME = "SET_PPI_INSTITUTE_NAME"
export const SET_PPI_INSTITUTE_EMAIL = "SET_PPI_INSTITUTE_EMAIL"
export const SET_PPI_INSTITUTE_STREET_ADDRESS = "SET_PPI_INSTITUTE_STREET_ADDRESS"
export const SET_PPI_INSTITUTE_CITY = "SET_PPI_INSTITUTE_CITY"
export const SET_PPI_INSTITUTE_STATE = "SET_PPI_INSTITUTE_STATE"
export const SET_PPI_INSTITUTE_ZIP_CODE = "SET_PPI_INSTITUTE_ZIP_CODE"
export const SET_PPI_INSTITUTE_COUNTRY = "SET_PPI_INSTITUTE_COUNTRY"
export const SET_PROJECT_INSTITUTIONAL_FORM_FIELDS = "SET_PROJECT_INSTITUTIONAL_FORM_FIELDS"
export const RESET_PROJECT_INSTITUTIONAL_FORM_FIELDS = "RESET_PROJECT_INSTITUTIONAL_FORM_FIELDS"
// project admin actions
export const SET_PROJECT_ADMIN_FIRST_NAME = "SET_PROJECT_ADMIN_FIRST_NAME"
export const SET_PROJECT_ADMIN_LAST_NAME = "SET_PROJECT_ADMIN_LAST_NAME"
export const SET_PROJECT_ADMIN_MIDDLE_NAME = "SET_PROJECT_ADMIN_MIDDLE_NAME"
export const SET_PROJECT_ADMIN_CREDENTIALS = "SET_PROJECT_ADMIN_CREDENTIALS"
export const SET_PROJECT_ADMIN_INSTITUTE_NAME = "SET_PROJECT_ADMIN_INSTITUTE_NAME"
export const SET_PROJECT_ADMIN_INSTITUTE_EMAIL = "SET_PROJECT_ADMIN_INSTITUTE_EMAIL"
export const SET_PROJECT_ADMIN_INSTITUTE_STREET_ADDRESS = "SET_PROJECT_ADMIN_INSTITUTE_STREET_ADDRESS"
export const SET_PROJECT_ADMIN_INSTITUTE_CITY = "SET_PROJECT_ADMIN_INSTITUTE_CITY"
export const SET_PROJECT_ADMIN_INSTITUTE_STATE = "SET_PROJECT_ADMIN_INSTITUTE_STATE"
export const SET_PROJECT_ADMIN_INSTITUTE_ZIP_CODE = "SET_PROJECT_ADMIN_INSTITUTE_ZIP_CODE"
export const SET_PROJECT_ADMIN_INSTITUTE_COUNTRY = "SET_PROJECT_ADMIN_INSTITUTE_COUNTRY"
export const SET_PROJECT_ADMINISTRATOR_FIELDS = "SET_PROJECT_ADMINISTRATOR_FIELDS"
export const RESET_PROJECT_ADMINISTRATOR_FIELDS = "RESET_PROJECT_ADMINISTRATOR_FIELDS"
//project PI actions
export const SET_PROJECT_PI_FIRST_NAME = "SET_PROJECT_PI_FIRST_NAME"
export const SET_PROJECT_PI_LAST_NAME = "SET_PROJECT_PI_LAST_NAME"
export const SET_PROJECT_PI_MIDDLE_NAME = "SET_PROJECT_PI_MIDDLE_NAME"
export const SET_PROJECT_PI_CREDENTIALS = "SET_PROJECT_PI_CREDENTIALS"
export const SET_PROJECT_PI_AFFILIATION = "SET_PROJECT_PI_AFFILIATION"
export const SET_PROJECT_PI_INSTITUTE_EMAIL = "SET_PROJECT_PI_INSTITUTE_EMAIL"
export const SET_PROJECT_PI_FIELDS = "SET_PROJECT_PI_FIELDS"
export const RESET_PROJECT_PI_FIELDS = "RESET_PROJECT_PI_FIELDS"
//institutional cert 
export const SET_INSTITUTIONAL_CERT_ADMIN_NAME = "SET_INSTITUTIONAL_CERT_ADMIN_NAME"
export const SET_INSTITUTIONAL_CERT_ADMIN_SIGNATURE = "SET_INSTITUTIONAL_CERT_ADMIN_SIGNATURE"

//zone 3 users 
export const SET_ZONE3_USERS_FIRST_NAME = "SET_ZONE3_USERS_FIRST_NAME"
export const SET_ZONE3_USERS_LAST_NAME = "SET_ZONE3_USERS_LAST_NAME"
export const SET_ZONE3_USERS_MIDDLE_NAME = "SET_ZONE3_USERS_MIDDLE_NAME"
export const SET_ZONE3_USERS_AFFILIATION = "SET_ZONE3_USERS_AFFILIATION"
export const SET_ZONE3_USERS_INSTITUTIONAL_EMAIL = "SET_ZONE3_USERS_INSTITUTIONAL_EMAIL"
export const ADD_ZONE3_USER_DETAILS = "ADD_ZONE3_USER_DETAILS"
export const REMOVE_ZONE3_USER = "REMOVE_ZONE3_USER"
export const SET_ZONE_3_FORM_FIELDS = "SET_ZONE_3_FORM_FIELDS"
export const RESET_ZONE_3_FORM_FIELDS = "RESET_ZONE_3_FORM_FIELDS"
//authorize submitter
export const SET_AUTHORIZE_SUBMITTERS_FIRST_NAME = "SET_AUTHORIZE_SUBMITTERS_FIRST_NAME"
export const SET_AUTHORIZE_SUBMITTERS_LAST_NAME = "SET_AUTHORIZE_SUBMITTERS_LAST_NAME"
export const SET_AUTHORIZE_SUBMITTERS_MIDDLE_NAME = "SET_AUTHORIZE_SUBMITTERS_MIDDLE_NAME"
export const SET_AUTHORIZE_SUBMITTERS_AFFILIATION = "SET_AUTHORIZE_SUBMITTERS_AFFILIATION"
export const SET_AUTHORIZE_SUBMITTERS_INSTITUTIONAL_EMAIL = "SET_AUTHORIZE_SUBMITTERS_INSTITUTIONAL_EMAIL"
export const ADD_AUTHORIZE_SUBMITTERS_DETAILS = "ADD_AUTHORIZE_SUBMITTERS_DETAILS"
export const REMOVE_AUTHORIZE_SUBMITTER = "REMOVE_AUTHORIZE_SUBMITTER"
export const SET_AUTHORIZE_SUBMITTERS_CAN_UPLOAD = "SET_AUTHORIZE_SUBMITTERS_CAN_UPLOAD"
export const SET_AUTHORIZE_SUBMITTERS_CAN_MODIFY = "SET_AUTHORIZE_SUBMITTERS_CAN_MODIFY"
export const SET_AUTHORIZE_SUBMITTERS_CAN_REMOVE = "SET_AUTHORIZE_SUBMITTERS_CAN_REMOVE"
export const SET_AUTHORIZED_SUBMITTERS_FIELDS = "SET_AUTHORIZED_SUBMITTERS_FIELDS"
export const RESET_AUTHORIZED_SUBMITTERS_FIELDS = "RESET_AUTHORIZED_SUBMITTERS_FIELDS"

// Study Gallery Actions
export const UPDATE_STUDY_GALLERY_FILTERS = "UPDATE_STUDY_GALLERY_FILTERS"
export const UPDATE_STUDY_GALLERY_COUNT = "UPDATE_STUDY_GALLERY_COUNT"
export const UPDATE_STUDY_GALLERY_DATA = "UPDATE_STUDY_GALLERY_DATA"
export const UPDATE_STUDY_GALLERY_DETAILS = "UPDATE_STUDY_GALLERY_DETAILS"
export const RESET_AGREEMENT_VALUES = "RESET_AGREEMENT_VALUES"
export const UPDATE_INITIAL_STUDY_GALLERY_FILTER = "UPDATE_INITIAL_STUDY_GALLERY_FILTER"

//data inventory actions 
export const SET_DIF_STAGE = "SET_DIF_STAGE"
export const SET_DATA_SHARING_FORM_DATA = "SET_DATA_SHARING_FORM_DATA"
export const SET_OTHER_PERSONAL_FORM_DATA = "SET_OTHER_PERSONAL_FORM_DATA"
export const SET_TIMELINE_FORM_DATA = "SET_TIMELINE_FORM_DATA"
export const SET_INDIVIDUAL_DATASET_FORM_DATA = "SET_INDIVIDUAL_DATASET_FORM_DATA"
export const SET_DIF_CURRENT_STAGE = "SET_DIF_CURRENT_STAGE"

//dif institution actions
export const SET_DIF_INSTITUTION_INSTITUTION_NAME = "SET_DIF_INSTITUTION_INSTITUTION_NAME"
export const SET_DIF_INSTITUTION_STREET_ADDRESS = "SET_DIF_INSTITUTION_STREET_ADDRESS"
export const SET_DIF_INSTITUTION_COUNTRY = "SET_DIF_INSTITUTION_COUNTRY"
export const SET_DIF_INSTITUTION_STATE = "SET_DIF_INSTITUTION_STATE"
export const SET_DIF_INSTITUTION_CITY = "SET_DIF_INSTITUTION_CITY"
export const SET_DIF_INSTITUTION_MUNICIPALITY = "SET_DIF_INSTITUTION_MUNICIPALITY"
export const SET_DIF_INSTITUTION_ZIP_CODE = "SET_DIF_INSTITUTION_ZIP_CODE"
export const RESET_INSTITUTIONAL_FORM_DATA = 'RESET_INSTITUTIONAL_FORM_DATA'
export const SET_INSTITUTIONAL_FORM_DATA = "SET_INSTITUTIONAL_FORM_DATA"

//dif PI actions
export const SET_DIF_PI_STREET_ADDRESS = "SET_DIF_PI_STREET_ADDRESS"
export const SET_DIF_PI_COUNTRY = "SET_DIF_PI_COUNTRY"
export const SET_DIF_PI_STATE = "SET_DIF_PI_STATE"
export const SET_DIF_PI_CITY = "SET_DIF_PI_CITY"
export const SET_DIF_PI_MUNICIPALITY = "SET_DIF_PI_MUNICIPALITY"
export const SET_DIF_PI_ZIP_CODE = "SET_DIF_PI_ZIP_CODE"
export const SET_DIF_PI_FIRST_NAME = "SET_DIF_PI_FIRST_NAME"
export const SET_DIF_PI_LAST_NAME = "SET_DIF_PI_LAST_NAME"
export const SET_DIF_PI_PHONE_NUMBER = "SET_DIF_PI_PHONE_NUMBER"
export const SET_DIF_PI_DEPARTMENT_NAME = "SET_DIF_PI_DEPARTMENT_NAME"
export const SET_DIF_PI_EMAIL = "SET_DIF_PI_EMAIL"
export const SET_PRIMARY_INVESTIGATION_FORM_DATA = "SET_PRIMARY_INVESTIGATION_FORM_DATA"
export const RESET_PRIMARY_INVESTIGATION_FORM_DATA = 'RESET_PRIMARY_INVESTIGATION_FORM_DATA'
//dif data sharing actions 
export const SET_DIF_DATA_SHARING_CONTRIBUTE_NEW_DATA_SET = 'SET_DIF_DATA_SHARING_CONTRIBUTE_NEW_DATA_SET'
export const SET_DIF_DATA_SHARING_DATASET_NAME = 'SET_DIF_DATA_SHARING_DATASET_NAME'
export const SET_DIF_DATA_SHARING_PERMISSION_TO_SHARE = 'SET_DIF_DATA_SHARING_PERMISSION_TO_SHARE'
export const SET_DIF_DATA_SHARING_IRB_NUMBER = 'SET_DIF_DATA_SHARING_IRB_NUMBER'
export const SET_DIF_DATA_SHARING_DOCUMENT_FILE = 'SET_DIF_DATA_SHARING_DOCUMENT_FILE'
export const SET_DIF_DATA_SHARING_IS_PROTECTED_DATA = 'SET_DIF_DATA_SHARING_IS_PROTECTED_DATA'
export const SET_DIF_DATA_SHARING_DE_IDENTIFY = 'SET_DIF_DATA_SHARING_DE_IDENTIFY'
export const RESET_DATA_SHARING_FORM_DATA = 'RESET_DATA_SHARING_FORM_DATA'
export const SET_DIF_DATA_SHARING_ASSOCIATED_EMAIL = 'SET_DIF_DATA_SHARING_ASSOCIATED_EMAIL'

//dif other personal actions
export const ADD_DIF_OTHER_PERSONAL_USERS = 'ADD_DIF_OTHER_PERSONAL_USERS'
export const REMOVE_DIF_OTHER_PERSONAL_USERS = 'REMOVE_DIF_OTHER_PERSONAL_USERS'
export const UPDATE_DIF_OP_USERS_FIRST_NAME = 'UPDATE_DIF_OP_USERS_FIRST_NAME'
export const UPDATE_DIF_OP_USERS_LAST_NAME = 'UPDATE_DIF_OP_USERS_LAST_NAME'
export const UPDATE_DIF_OP_USERS_TITLE = 'UPDATE_DIF_OP_USERS_TITLE'
export const UPDATE_DIF_OP_USERS_EMAIL = 'UPDATE_DIF_OP_USERS_EMAIL'
export const DIF_OP_WILL_OTHER_UPLOAD_DATA = 'DIF_OP_WILL_OTHER_UPLOAD_DATA'
export const RESET_OTHER_PERSONAL_FORM_DATA = 'RESET_OTHER_PERSONAL_FORM_DATA'
//dif timeline actions
export const SET_DIF_TIMELINE_IS_DEADLINE_TO_UPLOAD = 'SET_DIF_TIMELINE_IS_DEADLINE_TO_UPLOAD'
export const SET_DIF_TIMELINE_IS_DEADLINE_TO_DATA_ACCESS = 'SET_DIF_TIMELINE_IS_DEADLINE_TO_DATA_ACCESS'
export const SET_DIF_TIMELINE_IS_DATA_EMBARGOED = 'SET_DIF_TIMELINE_IS_DATA_EMBARGOED'
export const SET_DIF_TIMELINE_SUBMISSION_DATE = 'SET_DIF_TIMELINE_SUBMISSION_DATE'
export const SET_DIF_TIMELINE_DATA_ACCESS_DEADLINE_DATE = 'SET_DIF_TIMELINE_DATA_ACCESS_DEADLINE_DATE'
export const SET_DIF_TIMELINE_DATA_EMBARGOED_DATE = 'SET_DIF_TIMELINE_DATA_EMBARGOED_DATE'
export const SET_DIF_TIMELINE_DATA_EMBARGOED_DESC = 'SET_DIF_TIMELINE_DATA_EMBARGOED_DESC'
export const RESET_TIMELINE_FORM_DATA = 'RESET_TIMELINE_FORM_DATA'
//dif individual dataset actions
export const ADD_DIF_INDIVIDUAL_DATASET = 'ADD_DIF_INDIVIDUAL_DATASET'
export const SET_DIF_INDIVIDUAL_DATASET_ACTIVE_INDEX = 'SET_DIF_INDIVIDUAL_DATASET_ACTIVE_INDEX'
export const UPDATE_DI_DATASET_NAME = 'UPDATE_DI_DATASET_NAME'
export const UPDATE_DI_OWNER_TYPE = 'UPDATE_DI_OWNER_TYPE'
export const UPDATE_DI_OWNER_NAME = 'UPDATE_DI_OWNER_NAME'
export const UPDATE_DI_OWNER_TITLE = 'UPDATE_DI_OWNER_TITLE'
export const UPDATE_DI_OWNER_AFFILIATION = 'UPDATE_DI_OWNER_AFFILIATION'
export const UPDATE_DI_OWNER_EMAIL = 'UPDATE_DI_OWNER_EMAIL'
export const UPDATE_DI_ABSTRACT = 'UPDATE_DI_ABSTRACT'
export const UPDATE_DI_AGENCY_NAME = 'UPDATE_DI_AGENCY_NAME'
export const UPDATE_DI_AGENCY_NUMBER = 'UPDATE_DI_AGENCY_NUMBER'
export const UPDATE_DI_DISEASE_AREA = 'UPDATE_DI_DISEASE_AREA'
export const UPDATE_DI_CURATED_TO_STANDARD = 'UPDATE_DI_CURATED_TO_STANDARD'
export const UPDATE_DI_STANDARD = 'UPDATE_DI_STANDARD'
export const UPDATE_DI_RESTRICTED_ACCESS = 'UPDATE_DI_RESTRICTED_ACCESS'
export const UPDATE_DI_ALLOW_DATA_DOWNLOAD = 'UPDATE_DI_ALLOW_DATA_DOWNLOAD'
export const UPDATE_DI_NUMBER_OF_SAMPLES = 'UPDATE_DI_NUMBER_OF_SAMPLES'
export const UPDATE_DI_ESTIMATED_SIZE = 'UPDATE_DI_ESTIMATED_SIZE'
export const UPDATE_DI_TIME_SERIES = 'UPDATE_DI_TIME_SERIES'
export const UPDATE_DI_DATASET_COMPLETE = 'UPDATE_DI_DATASET_COMPLETE'
export const UPDATE_DI_UPLOAD_COMPLETE_DATE = 'UPDATE_DI_UPLOAD_COMPLETE_DATE'
export const UPDATE_DI_SUBJECT_SPACE_STUDY = 'UPDATE_DI_SUBJECT_SPACE_STUDY'
export const UPDATE_DI_SUBJECT_SPACE_CAPTURE_INFO = 'UPDATE_DI_SUBJECT_SPACE_CAPTURE_INFO'
export const UPDATE_DI_BIOSPECIMEN_STUDY = 'UPDATE_DI_BIOSPECIMEN_STUDY'
export const UPDATE_DI_BIOSPECIMEN_CAPTURE_INFO = 'UPDATE_DI_BIOSPECIMEN_CAPTURE_INFO'
export const UPDATE_CAN_YOU_PROVIDE_DATA_DICT = 'UPDATE_CAN_YOU_PROVIDE_DATA_DICT'
export const UPDATE_OTHER_IMAGING_TEXT = 'UPDATE_OTHER_IMAGING_TEXT'
export const UPDATE_OTHER_PRECLINICAL_TEXT = 'UPDATE_OTHER_PRECLINICAL_TEXT'
export const UPDATE_OTHER_GENOMIC_TEXT = 'UPDATE_OTHER_GENOMIC_TEXT'
export const UPDATE_OTHER_PROTEOMIC_TEXT = 'UPDATE_OTHER_PROTEOMIC_TEXT'
export const UPDATE_OTHER_WHOLE_GENOME_TEXT = 'UPDATE_OTHER_WHOLE_GENOME_TEXT'
export const UPDATE_IMAGING_OPTION = 'UPDATE_IMAGING_OPTION'
export const UPDATE_PRECLINICAL_OPTION = 'UPDATE_PRECLINICAL_OPTION'
export const UPDATE_GENOMIC_OPTION = 'UPDATE_GENOMIC_OPTION'
export const UPDATE_PROTEOMIC_OPTION = 'UPDATE_PROTEOMIC_OPTION'
export const UPDATE_WHOLE_GENOME_OPTION = 'UPDATE_WHOLE_GENOME_OPTION'
export const UPDATE_OTHER_TYPE_OF_DATA_TEXT = 'UPDATE_OTHER_TYPE_OF_DATA_TEXT'
export const UPDATE_IS_CLINICAL = 'UPDATE_IS_CLINICAL'
export const UPDATE_IS_PUBLICATION_PRESENT = 'UPDATE_IS_PUBLICATION_PRESENT'
export const ADD_DIF_PUBLICATIONS = 'ADD_DIF_PUBLICATIONS'
export const UPDATE_PUBLICATION_NAME = 'UPDATE_PUBLICATION_NAME'
export const UPDATE_PUBLICATION_URL = 'UPDATE_PUBLICATION_URL'
export const REMOVE_DIF_PUBLICATIONS = 'REMOVE_DIF_PUBLICATIONS'
export const SET_DATA_DICT_DOCUMENTS = 'SET_DATA_DICT_DOCUMENTS'
export const SET_DATA_DICT_RELATIONSHIP_DOCUMENTS = 'SET_DATA_DICT_RELATIONSHIP_DOCUMENTS'
export const REMOVE_SELECTED_DATASET_TAB = 'REMOVE_SELECTED_DATASET_TAB'
export const RESET_INDIVIDUAL_DATASET_FORM = 'RESET_INDIVIDUAL_DATASET_FORM'
export const UPDATE_DI_DATASET_MORE_DATA_ADDED = "UPDATE_DI_DATASET_MORE_DATA_ADDED"
export const UPDATE_MODAL_SYSTEM = "UPDATE_MODAL_SYSTEM"
export const UPDATE_MODAL_SYSTEM_OTHER_TEXT = "UPDATE_MODAL_SYSTEM_OTHER_TEXT"
export const UPDATE_DISEASE_FEATURE = "UPDATE_DISEASE_FEATURE"
export const UPDATE_TYPE_OF_DATA_DISEASE_AREA = "UPDATE_TYPE_OF_DATA_DISEASE_AREA"
export const UPDATE_TYPE_OF_DATA_DISEASE_AREA_TEXT = "UPDATE_TYPE_OF_DATA_DISEASE_AREA_TEXT"
export const UPDATE_METABOLOMIC_OPTION = "UPDATE_METABOLOMIC_OPTION"
export const UPDATE_BEHAVIORAL_TEXT = "UPDATE_BEHAVIORAL_TEXT"
export const UPDATE_PHYSIOLOGICAL_OUTCOME_TEXT = "UPDATE_PHYSIOLOGICAL_OUTCOME_TEXT"
export const UPDATE_TISSUE_SAMPLE = "UPDATE_TISSUE_SAMPLE"
export const UPDATE_BEHAVIORAL_ASSESSMENT = "UPDATE_BEHAVIORAL_ASSESSMENT"
export const UPDATE_WEARABLE_SENSORS = "UPDATE_WEARABLE_SENSORS"
export const UPDATE_DERIVED_SENSOR_DATA = "UPDATE_DERIVED_SENSOR_DATA"
export const UPDATE_DO_YOU_NEED_DATA_CURATED_SERVICE = "UPDATE_DO_YOU_NEED_DATA_CURATED_SERVICE"
export const UPDATE_IS_DATA_CURATED = "UPDATE_IS_DATA_CURATED"
export const UPDATE_CURATION_DATE = "UPDATE_CURATION_DATE"
export const UPDATE_DATASET_CURATOR_NAME = "UPDATE_DATASET_CURATOR_NAME"
export const UPDATE_DATASET_CURATOR_EMAIL = "UPDATE_DATASET_CURATOR_EMAIL"
export const UPDATE_DATASET_CURATOR_INSTITUTION = "UPDATE_DATASET_CURATOR_INSTITUTION"
export const UPDATE_OTHER_METABOLOMIC_TEXT = "UPDATE_OTHER_METABOLOMIC_TEXT"
export const UPDATE_WEARABLE_SENSORS_OTHER_TEXT = "UPDATE_WEARABLE_SENSORS_OTHER_TEXT"
export const UPDATE_TISSUE_SAMPLE_OTHER_TEXT = "UPDATE_TISSUE_SAMPLE_OTHER_TEXT"
export const UPDATE_DERIVED_SENSOR_DATA_OTHER_TEXT = "UPDATE_DERIVED_SENSOR_DATA_OTHER_TEXT"
export const REMOVE_DATA_DICT_DOCUMENTS = "REMOVE_DATA_DICT_DOCUMENTS"
//User Admin Actions
export const UPDATE_USER_ADMIN_DATA = "UPDATE_USER_ADMIN_DATA"

//workspace actions
export const SET_ALL_WORKSPACES = 'SET_ALL_WORKSPACES'
export const SET_ALL_WORKSPACES_CARTS = 'SET_ALL_WORKSPACES_CARTS'

//Explore Home
export const UPDATE_EXPLORE_HOME_DATA = 'UPDATE_EXPLORE_HOME_DATA'
export const UPDATE_EXPLORE_FILES_DATA = "UPDATE_EXPLORE_FILES_DATA"
//study actions
export const SET_DIF_STUDY_NAME = "SET_DIF_STUDY_NAME"
export const SET_DIF_STUDY_OBJECTIVE = "SET_DIF_STUDY_OBJECTIVE"
export const SET_DIF_STUDY_DESCRIPTION = "SET_DIF_STUDY_DESCRIPTION"
export const SET_DIF_STUDY_DESIGN = "SET_DIF_STUDY_DESIGN"
export const SET_DIF_STUDY_LOGO_FILE = "SET_DIF_STUDY_LOGO_FILE"
export const SET_DIF_STUDY_NUMBER_OF_GRANTS = "SET_DIF_STUDY_NUMBER_OF_GRANTS"
export const SET_DIF_STUDY_DURATION_OF_MONTHS = "SET_DIF_STUDY_DURATION_OF_MONTHS"
export const SET_DIF_STUDY_LOCATION_OF_STUDY = "SET_DIF_STUDY_LOCATION_OF_STUDY"
export const SET_DIF_STUDY_PUBLICATIONS = "SET_DIF_STUDY_PUBLICATIONS"
export const SET_DIF_STUDY_PUBLICATION_NAME = "SET_DIF_STUDY_PUBLICATION_NAME"
export const SET_DIF_STUDY_PUBLICATION_URL = "SET_DIF_STUDY_PUBLICATION_URL"
export const REMOVE_DIF_STUDY_PUBLICATIONS = "REMOVE_DIF_STUDY_PUBLICATIONS"
export const SET_DIF_STUDY_AGENCY_NAME = "SET_DIF_STUDY_AGENCY_NAME"
export const SET_DIF_STUDY_AGENCY_NUMBER = "SET_DIF_STUDY_AGENCY_NUMBER"
export const SET_DIF_STUDY_DATA = "SET_DIF_STUDY_DATA"
export const RESET_DIF_STUDY_DATA = "RESET_DIF_STUDY_DATA"
export const SET_DIF_STUDY_LOGO_S3_KEY = "SET_DIF_STUDY_LOGO_S3_KEY"
export const SET_DIF_STUDY_OPT_IN = "SET_DIF_STUDY_OPT_IN"

export const SET_BANNER_COOKIE = "SET_BANNER_COOKIE"

// Community Actions
export const COMMUNITY_LEFT_MENU_ACTIVE_ITEM_CHANGE = "COMMUNITY_LEFT_MENU_ACTIVE_ITEM_CHANGE";
export const COMMUNITY_LEFT_MENU_ITEM_COUNTERS_CHANGE = "COMMUNITY_LEFT_MENU_ITEM_COUNTERS_CHANGE";
export const COMMUNITY_LEFT_MENU_ITEM_CLICK = "COMMUNITY_LEFT_MENU_ITEM_CLICK";
export const COMMUNITY_LEFT_MENU_ITEM_TOGGLE = "COMMUNITY_LEFT_MENU_ITEM_TOGGLE";

export const COMMUNITY_RECOMMENDED_USERS_INIT = "COMMUNITY_RECOMMENDED_USERS_INIT";
export const COMMUNITY_RECOMMENDED_USER_CONNECT = "COMMUNITY_RECOMMENDED_USER_CONNECT";
export const COMMUNITY_RECOMMENDED_USER_DECLINE = "COMMUNITY_RECOMMENDED_USER_DECLINE";
export const COMMUNITY_RECOMMENDED_USERS_LOAD_MORE = "COMMUNITY_RECOMMENDED_USERS_LOAD_MORE";

export const COMMUNITY_CONNECTIONS_USERS_INIT = "COMMUNITY_CONNECTIONS_USERS_INIT";
export const COMMUNITY_CONNECTIONS_USERS_DELETE = "COMMUNITY_CONNECTIONS_USERS_DELETE";
export const COMMUNITY_CONNECTIONS_USERS_LOAD_MORE = "COMMUNITY_CONNECTIONS_USERS_LOAD_MORE";

export const COMMUNITY_INCOMING_USERS_INIT = "COMMUNITY_INCOMING_USERS_INIT";
export const COMMUNITY_INCOMING_USER_ACCEPT = "COMMUNITY_INCOMING_USER_ACCEPT";
export const COMMUNITY_INCOMING_USER_DECLINE = "COMMUNITY_INCOMING_USER_DECLINE";
export const COMMUNITY_INCOMING_USERS_LOAD_MORE = "COMMUNITY_INCOMING_USERS_LOAD_MORE";

export const COMMUNITY_PENDING_USERS_INIT = "COMMUNITY_PENDING_USERS_INIT";
export const COMMUNITY_PENDING_USERS_LOAD_MORE = "COMMUNITY_PENDING_USERS_LOAD_MORE";

export const COMMUNITY_MESSAGES_CHAT_WS_INIT = "COMMUNITY_MESSAGES_CHAT_WS_INIT";
export const COMMUNITY_MESSAGES_CHAT_WS_CLOSE = "COMMUNITY_MESSAGES_CHAT_WS_CLOSE";

export const COMMUNITY_MESSAGES_CHAT_USERS_LIST_INIT = "COMMUNITY_MESSAGES_CHAT_USERS_LIST_INIT";

export const COMMUNITY_MESSAGES_CHAT_RECIPIENT_ID_INIT = "COMMUNITY_MESSAGES_CHAT_RECIPIENT_ID_INIT";

export const COMMUNITY_MESSAGES_CHAT_MSG_INIT = "COMMUNITY_MESSAGES_CHAT_MSG_INIT";
export const COMMUNITY_MESSAGES_CHAT_MSG_ADD_RECIPIENT = "COMMUNITY_MESSAGES_CHAT_MSG_ADD_RECIPIENT";
export const COMMUNITY_MESSAGES_CHAT_MSG_ADD_SENDER = "COMMUNITY_MESSAGES_CHAT_MSG_ADD_SENDER";
export const COMMUNITY_MESSAGES_CHAT_MSG_RESET = "COMMUNITY_MESSAGES_CHAT_MSG_RESET";

export const COMMUNITY_GLOBAL_SEARCH_USERS_INIT = "COMMUNITY_GLOBAL_SEARCH_USERS_INIT";
export const COMMUNITY_GLOBAL_SEARCH_USER_CONNECT = "COMMUNITY_GLOBAL_SEARCH_USER_CONNECT";
export const COMMUNITY_GLOBAL_SEARCH_THERAPEUTIC_AREAS_INIT = "COMMUNITY_GLOBAL_SEARCH_THERAPEUTIC_AREAS_INIT";
export const COMMUNITY_GLOBAL_SEARCH_MOLECULAR_CLASSES_INIT = "COMMUNITY_GLOBAL_SEARCH_MOLECULAR_CLASSES_INIT";
export const COMMUNITY_GLOBAL_SEARCH_POPOVER_TOGGLE = "COMMUNITY_GLOBAL_SEARCH_POPOVER_TOGGLE";
export const COMMUNITY_GLOBAL_SEARCH_QUERY_INIT = "COMMUNITY_GLOBAL_SEARCH_QUERY_INIT";

export const COMMUNITY_CONNECT_MODAL_OPEN = "COMMUNITY_CONNECT_MODAL_OPEN";
export const COMMUNITY_CONNECT_MODAL_CLOSE = "COMMUNITY_CONNECT_MODAL_CLOSE";
export const COMMUNITY_CONNECT_MODAL_RESET = "COMMUNITY_CONNECT_MODAL_RESET";

export const COMMUNITY_SUCCESS_MODAL_OPEN = "COMMUNITY_SUCCESS_MODAL_OPEN";
export const COMMUNITY_SUCCESS_MODAL_CLOSE = "COMMUNITY_SUCCESS_MODAL_CLOSE";
export const COMMUNITY_SUCCESS_MODAL_RESET = "COMMUNITY_SUCCESS_MODAL_RESET";

export const COMMUNITY_DELETE_MODAL_OPEN = "COMMUNITY_DELETE_MODAL_OPEN";
export const COMMUNITY_DELETE_MODAL_CLOSE = "COMMUNITY_DELETE_MODAL_CLOSE";
export const COMMUNITY_DELETE_MODAL_RESET = "COMMUNITY_DELETE_MODAL_RESET";

export const COMMUNITY_REDIRECT = "COMMUNITY_REDIRECT";
export const COMMUNITY_REDIRECT_RESET = "COMMUNITY_REDIRECT_RESET";

export const COMMUNITY_FULLSCREEN_TOGGLE = "COMMUNITY_FULLSCREEN_TOGGLE";

export const COMMUNITY_CONSTELLATION_LABELS_INIT = "COMMUNITY_CONSTELLATION_LABELS_INIT";
export const COMMUNITY_CONSTELLATION_GRAPH_READY = "COMMUNITY_CONSTELLATION_GRAPH_READY";

export const COMMUNITY_CONSTELLATION_LABELS_SELECT = "COMMUNITY_CONSTELLATION_LABELS_SELECT";
export const COMMUNITY_CONSTELLATION_LABELS_SELECT_CLEAR = "COMMUNITY_CONSTELLATION_LABELS_SELECT_CLEAR";

export const COMMUNITY_CONSTELLATION_RECOMMENDED_USERS_SET = "COMMUNITY_CONSTELLATION_RECOMMENDED_USERS_SET";
export const COMMUNITY_CONSTELLATION_CONNECTED_USERS_SET = "COMMUNITY_CONSTELLATION_CONNECTED_USERS_SET";
export const COMMUNITY_CONSTELLATION_CONNECTED_USER_DELETE = "COMMUNITY_CONSTELLATION_CONNECTED_USER_DELETE";
export const COMMUNITY_CONSTELLATION_RECOMMENDED_USER_CONNECT = "COMMUNITY_CONSTELLATION_RECOMMENDED_USER_CONNECT";
export const COMMUNITY_CONSTELLATION_RECOMMENDED_USER_DECLINE = "COMMUNITY_CONSTELLATION_RECOMMENDED_USER_DECLINE";
export const COMMUNITY_CONSTELLATION_INCOMING_USER_ACCEPT = "COMMUNITY_CONSTELLATION_INCOMING_USER_ACCEPT";
export const COMMUNITY_CONSTELLATION_INCOMING_USER_DECLINE = "COMMUNITY_CONSTELLATION_INCOMING_USER_DECLINE";

export const COMMUNITY_CONSTELLATION_TOOLTIP_SET = "COMMUNITY_CONSTELLATION_TOOLTIP_SET";
export const COMMUNITY_CONSTELLATION_TOOLTIP_OPEN = "COMMUNITY_CONSTELLATION_TOOLTIP_OPEN";

export const COMMUNITY_CONSTELLATION_LABEL_NODE_HOVER = "COMMUNITY_CONSTELLATION_LABEL_NODE_HOVER";
export const COMMUNITY_CONSTELLATION_LABEL_NODE_UPDATE = "COMMUNITY_CONSTELLATION_LABEL_NODE_UPDATE";
export const COMMUNITY_CONSTELLATION_LABEL_NODE_RESET = "COMMUNITY_CONSTELLATION_LABEL_NODE_RESET";

export const COMMUNITY_CONSTELLATION_NODE_PARAMS_SET = "COMMUNITY_CONSTELLATION_NODE_PARAMS_SET";
export const COMMUNITY_CONSTELLATION_NODE_PARAMS_RESET = "COMMUNITY_CONSTELLATION_NODE_PARAMS_RESET";

export const SET_ON_OFF_COOKIE = 'SET_ON_OFF_COOKIE'

export const SET_LOADED_WORKSPACES = "SET_LOADED_WORKSPACES"
export const SET_ALL_NOTIFICATIONS_DATA = 'SET_ALL_NOTIFICATIONS_DATA'

export const SET_ONBOARDING_HIGHEST_EDUCATION = 'SET_ONBOARDING_HIGHEST_EDUCATION'

export const SET_OPENED_TAB = 'SET_OPENED_TAB'

export const SET_OPENED_NOTIFICATION = 'SET_OPENED_NOTIFICATION'
import React, { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../../../commons/accordion/Accordion';
import { DialogContentText, Grid, Typography } from '@mui/material';
import { getDefaultSliderValue } from '../../../explore-cohorts/utils';
import { CustomSlider } from '../filters/CustomSlider';
import CohortFilterItem from '../../../explore-cohorts/CohortFilterItem';
import { Box } from '@mui/system';
import { debounce } from 'lodash';
import { titleCase } from '../../utils';

const minDistance = 10;

const useStyles = makeStyles(() => ({
  text: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
}));

const ProjectFilterAccordion = ({
  searchTerm,
  filters,
  filterKey,
  handleFilterChange,
}) => {
  const classes = useStyles();
  const [activePanelOpen, setActivePanelOpen] = React.useState(true);
  const [sliderValues, setSliderValues] = React.useState(
    () => getDefaultSliderValue(filters) ?? [20, 70]
  );

  const filterArr = useMemo(
    () => (filters ? Object.entries(filters) : []),
    [filters]
  );

  const headerText = filterKey?.replace(/_/g, ' ');

  const debounceSliderValues = useCallback(
    debounce((value) => {
      handleFilterChange(filterKey, '_', value);
    }, 800),
    [filterKey]
  );
  const checkAfterSplitFilterBySpaceAndUnderscore = (searchterm) => {
    const searchTerms = searchterm.split(/[\s_]+/);
    return searchTerms.every((term) =>
      filterKey.split(/[\s_]+/)
        .some((key) => key.toLowerCase()
          .includes(term.toLowerCase())
        )
    );
  };


  const refinedFilters = useMemo(() => {
    // filter enabled filters by search term
    const filtered =
      Array.isArray(filterArr) &&
      filterArr.length > 1 &&
      filterArr?.filter(([filterKey, value]) =>
        filterKey.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
        checkAfterSplitFilterBySpaceAndUnderscore(searchTerm)
      );
    return !searchTerm ? filterArr : filtered;
  }, [filters, filterArr, searchTerm]);

  const handleSliderChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setSliderValues([clamped, clamped + minDistance]);
        debounceSliderValues([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setSliderValues([clamped - minDistance, clamped]);
        debounceSliderValues([clamped - minDistance, clamped]);
      }
    } else {
      setSliderValues(newValue);
      debounceSliderValues(newValue);
    }
  };

  useEffect(() => {
    if (filters && filters?.min !== undefined) {
      setSliderValues([filters?.range?.min, filters?.range?.max]);
    }
  }, [filters]);

  const renderDetail = useMemo(() => {
    if (filters && filters?.min !== undefined) {
      return (
        <>
          <CustomSlider
            getAriaLabel={() => 'Minimum distance shift'}
            value={sliderValues}
            valueLabelDisplay="on"
            onChange={handleSliderChange}
            disableSwap
            min={filters?.min ? filters?.min : 0}
            max={filters?.max ? filters?.max : 100}
            step={1}
          />
        </>
      );
    }

    if (filters && filterArr) {
      return (
        <>
          <Grid container>
            {refinedFilters && refinedFilters.length > 0 ? (
              refinedFilters.map(([attrKey, value], index) => (
                <Grid
                  key={attrKey}
                  item
                  xs={refinedFilters.length > 1 ? 6 : 12}
                >
                  <CohortFilterItem
                    key={attrKey}
                    filter={{ key: attrKey, enabled: value }}
                    handleChange={() => {
                      handleFilterChange(filterKey, attrKey);
                    }}
                  />
                </Grid>
              ))
            ) : (
              <DialogContentText
                className={classes.text}
              >{`No projects filters   ${
                !searchTerm ? '' : `starts with ${searchTerm}`
              }`}</DialogContentText>
            )}
          </Grid>
        </>
      );
    }

    return (
      <Box paddingX={2} paddingY={1}>
        <Typography>No Filter data</Typography>
      </Box>
    );
  }, [
    filters,
    filterArr,
    sliderValues,
    refinedFilters,
    classes,
    searchTerm,
    handleFilterChange,
  ]);

  if (searchTerm.length >= 1 && !headerText?.includes(searchTerm) && refinedFilters.length === 0) {
    return null;
  }

  return (
    <Accordion
      expanded={activePanelOpen}
      onChange={() => setActivePanelOpen(!activePanelOpen)}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography>
          {headerText && titleCase(headerText)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{renderDetail}</AccordionDetails>
    </Accordion>
  );
};

export default ProjectFilterAccordion;

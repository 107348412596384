import React, { useState, useEffect } from 'react';
import Modal from '../../../commons/modal'
import classes from './index.module.css'
import DropZone from 'react-dropzone'
import DocumentIcon from '../../../../assets/images/Doc.M.png'
import ImageVerticalIcon from '../../../../assets/icons/MoreOptionsVertical.S-dark.svg';
import MuiPopover from '@mui/material/Popover';
import { updateZone2Status, uploadFileForZone2StatusUpdate } from '../../../../api/admin/admin-dashboard'
import withStyles from '@mui/styles/withStyles';
import { uploadFileToS3 } from '../../../../utils/aws-sdk-helper'
import CommonDatePicker from '../../../commons/common-date-picker';
import Grid from '@mui/material/Grid'
import moment from 'moment'
import CloseSmallIcon from '../../../../assets/icons/close-small.svg'
import Checkbox from '@mui/material/Checkbox';
import ErrorText from '../../../controls/error-text';
import isEmpty from 'lodash/isEmpty'

const MAX_FILE_SIZE = 10000000

const ACTION_ITEMS = [
  {
    "name": "Pending Admin Approval",
    id: "PENDING_ADMIN_APPROVAL",
  },
  {
    "name": "Additional Information Requested",
    id: "ADDITIONAL_INFORMATION_REQUESTED"
  },
  {
    "name": "Approved",
    id: "APPROVED"
  },
  {
    "name": "Rejected",
    id: "REJECTED"
  },
]

const Popover = withStyles(theme => ({
  root: {
    overflowY: 'scroll',
  },
  paper: {
    minWidth: 192,
    right: 'auto !important',
    marginTop: '32px !important',
  }
}))(MuiPopover);



const StatusUpdateModalContent = ({ id, uploadedFiles, setUploadedFiles,
  zone2RequestExpiryDate, setZone2RequestExpiryDate,
  zone2Type, setZone2Type, isFormSubmitted, errorMessages, isAgreeSelected, setIsAgreeSelected }) => {
  const [message, setMessage] = useState(null);
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);

  const handleOnDrop = (files) => {
    setMessage(null)
    const oversizedItem = files?.find(({ size }) => size > MAX_FILE_SIZE);
    if (oversizedItem) {
      setMessage('Please upload files below 2MB');
      return;
    }
    setUploadedFiles(files)
  }

  const handleClose = itemIndex => {
    setUploadedFiles(uploadedFiles.filter((_, index) => index !== itemIndex))
  }
  const onZone2TypeSelection = (type) => {
    setZone2Type(type)
  }

  const handleDateChange = value => {
    setZone2RequestExpiryDate(value);
    setIsCalenderOpen(false)
  }
  const statusText = () => {
    if (id === ACTION_ITEMS[0].id) {
      return "Pending Admin Approval"
    }
    if (id === ACTION_ITEMS[1].id) {
      return "Additional Information Requested"
    }
    if (id === ACTION_ITEMS[2].id) {
      return "Approved"
    }
    if (id === ACTION_ITEMS[3].id) {
      return "Rejected"
    }
  }
  const calenderOpenCloseClass = isCalenderOpen ? classes.calenderOpenClass : classes.calenderCloseClass;
  const headerText = `Update status of zone 2 request to ${statusText()}?`



  const uploadFileText = `${id === ACTION_ITEMS[0].id ? 'Upload Signed Institutional Certification' : 'Upload Data Access Committee Form'}`
  return (<div className={classes.modalContainer}>
    <div className={classes.titleHeaderContainer}>
      <h1>{headerText}</h1>

      <h1>

      </h1>
    </div>
    {
      id === ACTION_ITEMS[2].id &&
      <>

        <div className={`${classes.datePickerWrapper} ${calenderOpenCloseClass}`}>
          <label id='cvb-adminDashboard-approveZone2Modal-expiryDateLabel'>Expiry Date : <sup> *</sup> </label>
          <Grid item xs={6}>
            <CommonDatePicker
              onCalenderDateSelected={handleDateChange}
              minDate={moment().add(1, 'd').toDate()}
              date={zone2RequestExpiryDate}
              errorMessage='Please select expiry date'
              id='cvb-adminDashboard-approveZone2Modal-inputDate'
              isFromModal
              canClearInput
            />
          </Grid>

        </div>

        <Grid item xs={10} className={classes.fieldCont}>
          <label id="cvb-adminDashboard-workspaceInstanceLabel">Zone 2 Type <sup> *</sup></label>
          <Grid container>
            <Grid item xs={5} className={classes.radioCheckContainer}>
              <label className={classes.radioContainer} id="cvb-adminDashboard-academiaLabel">Qualified Researcher (Academia)</label>
              <input id='academia' type="radio" name="isDataCurated"
                onChange={() => onZone2TypeSelection('zone2-academia')}
              />
              <span className={classes.checkmark}></span>
            </Grid>
            <Grid item xs={5} className={classes.radioCheckContainer}>
              <label className={classes.radioContainer} id="cvb-adminDashboard-industryLabel">Qualified Researcher (Industry)</label>
              <input id='industry' type="radio" name="isDataCurated"
                onChange={() => onZone2TypeSelection('zone2-industry')} />
              <span className={classes.checkmark}></span>
            </Grid>
          </Grid>
        </Grid>
        {
          errorMessages?.['zone2Type'] &&
          <ErrorText errorMessage={errorMessages?.['zone2Type']} />
        }
      </>

    }
    { id !== ACTION_ITEMS[1].id &&
    <>
    <label id="cvb-zone2Approval-attachLabel" className={classes.uploadLabel}>{uploadFileText} <sup> *</sup></label>
    <p>Upload file in PDF format. Max file size allowed is 10MB.</p>
    <div id='cvb-trainingAndCertificate-fileDropInput' className={classes.dropZoneButtonContainer}>
      <DropZone
        onDrop={handleOnDrop}
        multiple={false}
        accept='application/pdf'
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={classes.uploadImageDiv}>
              <img src={DocumentIcon} alt='document' />
              <p className={classes.chooseFileText}>
                Drag and drop files here or
                <span>
                  <button id='cvb-reportAProblem-selectFile-button' className={`${classes.chooseFileButton}`}>
                    Select files
                  </button>
                </span>
              </p>
            </div>
          </div>
        )}
      </DropZone>
    </div>
    </>
  }
    {
      errorMessages?.['fileUpload'] &&
      <ErrorText errorMessage={errorMessages?.['fileUpload']} />
    }
    {
      message &&
      <ErrorText errorMessage={message} />
    }
    {
      uploadedFiles?.map(({ name }, index) => {
        return <span key={`${name}-${index}`}>
          {name}
          <button className={classes.closeButton} onClick={() => handleClose(index)}>
            <img src={CloseSmallIcon} alt='close small' />
          </button>
        </span>
      })
    }
    {
      ((id === ACTION_ITEMS[2].id )|| (id === ACTION_ITEMS[3].id)) &&
      <>
        <div className={classes.agreeContainer}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            value={isAgreeSelected}
            onChange={(e) => setIsAgreeSelected(e.target.checked)}
          />
          I confirm that I want to perform the action as it is irreversible.
        </div>
        {
          errorMessages?.['concentConfirmation'] &&
          <ErrorText errorMessage={errorMessages?.['concentConfirmation']} />
        }
      </>
    }

  </div>)
}

const AdditionalInformationRequestedModalContent = () => {
  return (<div className={classes.modalContainer}>
    <div className={classes.titleHeaderContainer}>
      <h1>Update status of zone 2 request to ‘Additional Information Requested’?</h1>
    </div>
    <p className={classes.modalDescriptionText}>Application will be put on hold until requestor sends additional information.</p>
  </div>)
}

const PendingAdditionalInformationModal = ()=>{
  return (<div className={classes.modalContainer}>
    <div className={classes.titleHeaderContainer}>
      <h1>Update status of zone 2 request to Pending Admin Approval?</h1>
    </div>
    <p className={classes.modalDescriptionText}>Application will be put on hold until requestor sends additional information.</p>
  </div>)
}
const Zone2ActionsPopOver = ({ currentData, updateSnackBar }) => {

  const getDate = (date) => {
    return moment(date).format("YYYY-MM-DD")
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [isUploading, setIsUploading] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [zone2RequestExpiryDate, setZone2RequestExpiryDate] = useState(new Date());
  const [zone2Type, setZone2Type] = useState(undefined);
  const [isAgreeSelected, setIsAgreeSelected] = useState(false)
  const [errorMessages, setErrorMessages] = useState({})

  const openPopover = !!anchorEl;

  useEffect(() => {
    if (Object.keys(errorMessages || {})?.length > 0) {
      validationMessage();
    }
  }, [uploadedFiles, zone2RequestExpiryDate, zone2Type, isAgreeSelected])

  const handleDownArrowClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClosePopover = () => {
    setAnchorEl(null);
    setUploadedFiles([])
  }


  const getSubmitBody = () => {
    if (modalData === ACTION_ITEMS[0].id && currentData?.request_status === "ADDITIONAL_INFORMATION_REQUESTED") {
      return {
        "request_id": currentData?.request_id || '',
        "user_sub": currentData?.user_sub || '',
        "status": "PENDING_FOR_ADMIN_APPROVAL",
      }
    }
    
    if (modalData === ACTION_ITEMS[0].id) {
      return {
        "request_id": currentData?.request_id || '',
        "user_sub": currentData?.user_sub || '',
        "status": "PENDING_FOR_ADMIN_APPROVAL",
        "signed_ao_certificate": "data_use_policy_approved_by_institution.pdf" || "",
      }
    }

    if (modalData === ACTION_ITEMS[1].id) {
      return {
        "request_id": currentData?.request_id || '',
        "user_sub": currentData?.user_sub || '',
        "status": "ADDITIONAL_INFORMATION_REQUESTED",
        "zone2_type": zone2Type
      }
    }

    if (modalData === ACTION_ITEMS[2].id) {
      return {
        "request_id": currentData?.request_id || '',
        "user_sub": currentData?.user_sub || '',
        "status": "APPROVED",
        "data_access_committee_file_name": uploadedFiles[0]?.name || "",
        "expiry_date": getDate(zone2RequestExpiryDate),
        "zone2_type": zone2Type
      }
    }
    if (modalData === ACTION_ITEMS[3].id) {
      return {
        "request_id": currentData?.request_id || '',
        "user_sub": currentData?.user_sub || '',
        "status": "REJECTED",
        "data_access_committee_file_name": uploadedFiles[0]?.name || "",
        // "expiry_date": getDate(zone2RequestExpiryDate),
        // "zone2_type": zone2Type
      }
    }
  }

  const validationMessage = () => {
    if (modalData === ACTION_ITEMS[1].id) {
      return true;
    }
    let errors = {}
    if(modalData === ACTION_ITEMS[0].id && currentData?.request_status === 'ADDITIONAL_INFORMATION_REQUESTED'){
      return true;
    }
    if (uploadedFiles?.length === 0) {
      errors['fileUpload'] = "Please upload a file";
    }
    if (modalData === ACTION_ITEMS[2].id) {
      if (!zone2Type ) {
        errors['zone2Type'] = "Please select a valid zone 2 type";
      }
      if (!isAgreeSelected ) {
        errors['concentConfirmation'] = "Please agree the terms and conditions"
      }
    }
    if (modalData === ACTION_ITEMS[3].id) {
      if (!isAgreeSelected ) {
        errors['concentConfirmation'] = "Please agree the terms and conditions"
      }
    }
    setErrorMessages(errors)
    console.log(errors)
    return Object.keys(errors)?.length === 0;
  }


  const handleSubmit = async () => {
    const isValid =  validationMessage();
    if (!isValid) {
      return;
    }

    setIsUploading(true)
    try {
      let response = null;
      if (uploadedFiles?.length > 0) {
        response = await uploadFileForZone2StatusUpdate(uploadedFiles[0], modalData, currentData?.user_sub || '', modalData);
        if (response) {
          await uploadFileToS3(uploadedFiles[0], response)
        }
      }
      const body = getSubmitBody()
      const response1 = await updateZone2Status(body)
      updateSnackBar(response1?.message || "Successfully updated", 'success')
      handleClose()
    }
    catch (error) {
      console.log(error, error?.response)
      updateSnackBar(error?.response?.data?.error || "Something went wrong, Please try again!", 'error')
    }
    setIsUploading(false)
  }

  const handleClose = () => {
    setModalData(null)
    setUploadedFiles([])
    setAnchorEl(null);
    setErrorMessages(false)
  }

  const modalProps = {
    negativeButtonText: "Cancel",
    positiveButtonText: "Update Status",
    popupButtonDisable: isUploading, 
    loadStatus: false,
    negativeButtonAction: handleClose,
    positiveButtonAction: handleSubmit,
  };


  const getModalContent = () => {
    switch (modalData) {
      case ACTION_ITEMS[0].id: {
        const isAdditionalInfo = currentData?.request_status === "ADDITIONAL_INFORMATION_REQUESTED";
        if (isAdditionalInfo) {
          return <PendingAdditionalInformationModal />
        }
        else {
          return <StatusUpdateModalContent
            id={ACTION_ITEMS[0].id}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            zone2RequestExpiryDate={zone2RequestExpiryDate}
            setZone2RequestExpiryDate={setZone2RequestExpiryDate}
            zone2Type={zone2Type}
            setZone2Type={setZone2Type}
            errorMessages={errorMessages}
          />
        }
      }
      case ACTION_ITEMS[1].id: return <AdditionalInformationRequestedModalContent />
      case ACTION_ITEMS[2].id: return <StatusUpdateModalContent
        id={ACTION_ITEMS[2].id}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        zone2RequestExpiryDate={zone2RequestExpiryDate}
        setZone2RequestExpiryDate={setZone2RequestExpiryDate}
        zone2Type={zone2Type}
        setZone2Type={setZone2Type}
        errorMessages={errorMessages}
        isAgreeSelected={isAgreeSelected}
        setIsAgreeSelected={setIsAgreeSelected}
      />
      case ACTION_ITEMS[3].id: return <StatusUpdateModalContent
        id={ACTION_ITEMS[3].id}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        errorMessages={errorMessages}
        isAgreeSelected={isAgreeSelected}
        setIsAgreeSelected={setIsAgreeSelected}
      />
      default: return <></>
    }
  }

  return <>
    <Modal open={!!modalData} handleClose={() => setModalData(null)}
      dialogProps={modalProps} disableTitleDivider >
      <div>
        {getModalContent()}
      </div>
    </Modal>
    <img
      src={ImageVerticalIcon}
      alt='verticalIcon'
      className={classes.rightIconImage}
      onClick={(e) => handleDownArrowClick(e)} />
    <Popover
      open={openPopover}
      elevation={3}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        horizontal: 'right',
      }}
      transformOrigin={{
        horizontal: 'right',
      }}
    >
      <ul className={classes.dropdownListContainer}>
        <li id='cvb-zone2Popover-popoverModifyData' className={classes.popoverHeader}>
          Update Status To
        </li>
        {ACTION_ITEMS.map(({ name, id }) => {
          if (id !== ACTION_ITEMS[0].id &&
            currentData?.request_status?.toLowerCase() === ("PENDING_FOR_CERTIFICATION_APPROVAL")?.toLowerCase()) {
            return null;
          }
          if (currentData?.request_status?.toLowerCase() === ("ADDITIONAL_INFORMATION_REQUESTED")?.toLowerCase() &&
            ((id === ACTION_ITEMS[1].id) || (id === ACTION_ITEMS[2].id)|| (id === ACTION_ITEMS[3].id))) {
            return null;
          }
          if (currentData?.request_status?.toLowerCase() === ("PENDING_FOR_ADMIN_APPROVAL")?.toLowerCase() &&
            (id === ACTION_ITEMS[0].id)) {
            return null;
          }
          return <li id='cvb-zone2Popover-popoverModifyData' key={id} onClick={() => setModalData(id)}>
            {name}
          </li>
        })}
      </ul>

    </Popover>
  </>
}

export default Zone2ActionsPopOver;
/**
 *  External Imports
 */
import {connect} from "react-redux";

/**
 *  Internal Imports
 */
import {initRecommendedUsersAction, openCommunityConnectModalAction, connectRecommendedUserAction, declineRecommendedUserAction, redirectCommunityAction} from "../../../redux/actions/communityActions";

import CommunityWidgetRecommendedConnections from "./CommunityWidgetRecommendedConnections";

/**
 *  Functions
 */
const mapStateToProps = ({community: {recommendedUsers}}) => ({
	recommendedUsers,
});

const mapDispatchToProps = (dispatch) => ({
	initUsers: (recommendedUsers) => dispatch(initRecommendedUsersAction(recommendedUsers)),
	onConnectDialogOpen: (dialogConnectData) => dispatch(openCommunityConnectModalAction(dialogConnectData)),
	onConnectUser: (recommendationID) => dispatch(connectRecommendedUserAction(recommendationID)),
	onDeclineUser: (recommendationID) => dispatch(declineRecommendedUserAction(recommendationID)),
	onRecommendedPageRedirect: (path, pathState) => dispatch(redirectCommunityAction(path, pathState)),
});

/**
 *  Exports
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CommunityWidgetRecommendedConnections);

import React, { useEffect, useState } from 'react'
import { getCertificateUrl } from '../../../api/certificate-api'
import get from 'lodash/get'
import PDFViewer from 'pdf-viewer-reactjs'

const CertificatePreview = props => {
    const [certificateUrl, setCertificateUrl] = useState('');
    const { selectedCertificateName = "", userSub = "" } = props;

    const loadInitialData = async () => {
        try {
            const res = await getCertificateUrl(selectedCertificateName, userSub);
            const certUrl = get(res, 'url', '');
            setCertificateUrl(certUrl);
        } catch (error) {
            console.log('error in getting cert url', error)
        }
    }
    useEffect(() => {
        loadInitialData();
    }, [])
    return (
        <p>
            {
                certificateUrl &&
                <PDFViewer
                    id='cvb-pdfViewer'
                    document={{
                        url: certificateUrl,
                    }}
                />
            }
        </p>
    )
}
export default CertificatePreview;
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const BarChartIcon = (props) => <SvgIcon  viewBox="0 0 22 20" {...props} height="22" width="20">
    <path d="M7 14H5V7H7V14ZM11 14H9V4H11V14ZM15 14H13V10H15V14ZM17.5 16.1H2.5V2H17.5V16.1ZM17.5 0H2.5C1.4 0 0.5 0.9 0.5 2V16C0.5 17.1 1.4 18 2.5 18H17.5C18.6 18 19.5 17.1 19.5 16V2C19.5 0.9 18.6 0 17.5 0Z" fill="#667085" />
</SvgIcon>

export const FilterIcon = (props) => <SvgIcon height="20" width="18"  viewBox="0 0 20 18" {...props} >
    <path d="M18.3334 2.5H1.66675L8.33342 10.3833V15.8333L11.6667 17.5V10.3833L18.3334 2.5Z" fill="none" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</SvgIcon>







import { SET_OPENED_TAB } from "../action-types";

const initialState = {
    openedTab: null
}

const adminDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_OPENED_TAB: {
            return {
                ...state,
                openedTab: action.payload
            }
        }
        default: return state;
    }
}

export default adminDashboardReducer;
import MyCohorts from './MyCohorts'
import { connect } from 'react-redux'
import { onAnalyzeCohort, setSelectedAnalysis } from '../../../redux/actions/analysisDataActions'
import { refreshUserCohorts } from '../../../redux/actions/myStuffActions'
import { updateSnackBar } from '../../../redux/actions/globalActions'
const mapStateToProps = state => {
    return {
        analysisCohorts: state.analyzeData.analysisCohorts,
        userCohorts: state.myStuff.userCohorts,
        allWorkspaces: state.workspace.allWorkspaces,
        userDetails: state.user.userDetails

    }
}
const mapDispatchToProps = dispatch => {
    return {
        onAnalyzeCohort: (value) => dispatch(onAnalyzeCohort(value)),
        setSelectedAnalysis: (value) => dispatch(setSelectedAnalysis(value)),
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type)),
        refreshUserCohorts: async () => await dispatch(refreshUserCohorts())
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(MyCohorts)

import React, { useState, useMemo, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import strokesBG from '../../../assets/images/strokes-bg.png'
import classes from './index.module.css'
import MultiSelect from '../../commons/multi-select'
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownArrow from '../../../assets/icons/down-arrow.svg'
import ImgCases from '../../../assets/icons/cases.svg'
import ImgProjects from '../../../assets/icons/projects.svg'
import v4 from 'uuid/v4'
import { getDataModelSchema } from '../../../api/data-model-api'
import uniq from 'lodash/uniq'
import OutlinedInput from '@mui/material/OutlinedInput'
import { split } from 'apollo-boost'
import Dropdown from '../../commons/dropdown'
import { getSearchResults, saveSearch, getDemographicCount } from '../../../api/search-results'
import CheckBoxOptionsPopOver from '../../controls/checkbox-options-popover'
import Modal from '../../commons/modal'
import ErrorText from '../../controls/error-text'
import { navigate } from '@reach/router'
import CustomLoader from '../../commons/custom-loader';
import { isEmpty } from 'lodash'
import InfoGreyIcon from '../../../assets/images/InfoGreyIcon.png'
import useScrollToTop from '../../commons/scroll-top';
import Checkbox from '@mui/material/Checkbox';
import TablePagination from '../../commons/table-pagination'
import findIndex from 'lodash/findIndex'
import { BorderStyle } from '@mui/icons-material'
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip'
import { getAccessibleProjectList } from '../../../api/graphql/uploadData'
import { sendGAEvents } from '../../../api/generic-api'
import map from 'lodash/map'
const SearchResults = props => {


    const [dictionary, setDictionary] = useState({})
    const [searchPopoverKeyWord, setSearchPopOverKeyWord] = useState("")
    const [dataCategorySelectedValue, setDataCategorySelectedValue] = useState("")
    const [projectSelectedValues, setProjectsSelectedValues] = useState([])
    const [isReady, setIsReady] = useState(false)
    const [searchResults, setSearchResults] = useState({})
    const [customizeOptions, setCustomizeOptions] = useState([])
    const [displayResults, setDisplayResults] = useState([])
    const [openSaveCohortPopUp, setOpenSaveCohortPopUp] = useState(false)
    const [cohortName, setCohortName] = useState("")
    const [emptyCohortNameError, setEmptyCohortNameError] = useState("")
    const [searchDataCategoryError, setSearchDataCategoryError] = useState("")
    const [pageNumber, setPageNumber] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [userPageInputValue, setUserPageInputValue] = useState(1);
    const [totalSearchResultsCount, setTotalCount] = useState(0)
    const [jsonDownloadStatus, setJsonDownloadStatus] = useState(null)
    const [tsvDownloadStatus, setTsvDownloadStatus] = useState(null)
    const [availableSearchProjectsList, setAvailableSearchProjectsList] = useState(null)
    const [isAllSelectModeActive, setIsAllSelectModeActive] = useState(false)
    const [isFormUpdating, setIsFormUpdating] = useState(false);

    const getOnLoadData = async () => {
        try {
            const resp = await getDataModelSchema();
            setDictionary(resp)
        }
        catch (error) {
            console.log(error)
        }
    }
    useScrollToTop();

    useEffect(() => {
        getOnLoadData();
        // props.getExploreDataTotalCount();
    }, [])



    const parseUrlProps = async () => {
        if (props.id) {
            try {
                let dataCategoryItem = ""
                let projectSelectedItems = []
                let keywordItem = ""
                const selectedItems = props.id.split("&");
                for (let item of selectedItems) {
                    const splitWord = item.split("=")
                    if (splitWord.length == 2) {
                        if (splitWord[0] === "dataCategory") {
                            setDataCategorySelectedValue(splitWord[1])
                            dataCategoryItem = splitWord[1]
                        }
                        if (splitWord[0] === "projects") {
                            setProjectsSelectedValues(splitWord[1].split(","))
                            projectSelectedItems = splitWord[1].split(",")
                        }
                        if (splitWord[0] === "keyword") {
                            setSearchPopOverKeyWord(splitWord[1])
                            keywordItem = splitWord[1]
                        }
                    }
                }
                getTotalRecordsCount(dataCategoryItem, projectSelectedItems, keywordItem)
            } catch (error) {
                console.log(error)
            }
        }
    }

    const fetchAvailableProjects = async () => {
        try {
            const res = await getAccessibleProjectList();
            const projectIds = res?.map(x => x.project_id)
            const uniqIds = uniq(projectIds)
            setAvailableSearchProjectsList(uniqIds)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchAvailableProjects()
    }, [])

    const getTotalRecordsCount = async (dataCategoryItem, projectSelectedItems, keywordItems) => {
        try {
            setIsReady(false)
            setPageNumber(0)
            const body = {
                "node_name": dataCategoryItem,
                "project_ids": projectSelectedItems?.length > 0 ? projectSelectedItems : null,
                "search_filter": isEmpty(keywordItems) ? null : keywordItems
            }
            const res = await getDemographicCount(body);
            const countKey = Object.keys(res?.data || {})
            for (let key of countKey) {
                if (key.includes("_count")) {
                    setTotalCount(res?.data?.[key]);
                    setIsReady(true)
                    break;
                }
            }
        } catch (error) {
            console.log(error)
        }finally{
            setIsFormUpdating(false);
        }
    }

    useEffect(() => {
        parseUrlProps();
    }, [])

    const getDataCategoryValues = useMemo(() => {
        if (dictionary) {
            const allKeys = Object.keys(dictionary || {}).filter(x => !x.startsWith("_"))
            const values = []
            for (const key of allKeys) {
                values.push(dictionary[key].title)
            }
            return values;
        }
        return []
    }, [dictionary])

    const projectsList = useMemo(() => {
        if (!props.exploreData || props.exploreData.length == 0)
            return [];
        const items = props.exploreData.map((item) => item["project_id"]).filter(item => item);
        return uniq(items);
    }, [props.exploreData])


    const loadSearchResult = async () => {
        try {
            setIsLoading(true)
            if (!dataCategorySelectedValue) {
                setSearchDataCategoryError(true);
                setIsLoading(false)
                return;
            } else {
                setSearchDataCategoryError(false)
            }
            const list = getDisplayableResults()
            if (list.length == 0) {
                const dictKeys = Object.keys(dictionary)
                const matchedKey = dictKeys.find(x => dictionary[x]?.title === dataCategorySelectedValue) || dataCategorySelectedValue
                const body = {
                    "node_name": matchedKey,
                    "project_ids": projectSelectedValues?.length > 0 ? projectSelectedValues : null,
                    "search_filter": isEmpty(searchPopoverKeyWord) ? null : searchPopoverKeyWord
                }
                const result = await getSearchResults(body, pageNumber + 1);
                let newResults = result?.[matchedKey] || []
                newResults = newResults.map(x => {
                    return { ...x, isSelected: isAllSelectModeActive }
                })
                let searchResultsClone = { ...searchResults }
                for (let k = 0; k < totalSearchResultsCount; k++) {
                    if (!searchResultsClone[k]) {
                        searchResultsClone[k] = null;
                    }
                }
                for (let i = (pageNumber) * 10, j = 0; i < (pageNumber + 1) * 10 && i < totalSearchResultsCount; i++, j++) {
                    searchResultsClone[i] = newResults[j]
                }
                setSearchResults(searchResultsClone)
            }
            setTimeout(() => {
                setIsLoading(false)
            }, 100)
        }
        catch (error) {
            console.log(error)
            setIsLoading(false)
            setSearchResults([])
        }
    }

    const constructData = () => {
        const finalSearchResults = Object.values(searchResults)
        if (finalSearchResults?.length > 0) {
            const dictKeys = Object.keys(dictionary)
            const matchedKey = dictKeys.find(x => dictionary[x]?.title === dataCategorySelectedValue) || dataCategorySelectedValue
            const requiredItems = dictionary?.[matchedKey]?.required || [];
            let keys = Object.keys(finalSearchResults[0] || {})
            keys = keys.filter(x => x !== "isSelected")
            let totalOptions = []
            for (let item of keys) {
                totalOptions.push({
                    name: item,
                    isSelected: !!requiredItems.find(x => x === item)
                })
            }
            totalOptions = totalOptions.filter(x => !Array.isArray(finalSearchResults[0]?.[x.name]))
            setCustomizeOptions(totalOptions)
        }
    }


    useEffect(() => {
        const selectedCustomization = customizeOptions.filter(item => item.isSelected)
        if (customizeOptions.length >= 0 && selectedCustomization.length >= 0) {
            let keysToBeShown = customizeOptions.map(item => {
                if (item.isSelected) {
                    return item.name;
                }
            }).filter(x => x);
            keysToBeShown.unshift("submitter_id")
            let finalResult = []
            const finalSearchResults = Object.values(searchResults).filter(x => x)
            for (let result of finalSearchResults) {
                const item = {}
                for (let key of keysToBeShown) {
                    if (!Array.isArray(result[key])) {
                        item[key] = result[key];
                    }
                }
                finalResult.push(item)
            }
            setDisplayResults(finalResult)
        }
        else {
            setDisplayResults([])
        }
    }, [customizeOptions])


    useEffect(() => {
        const finalSearchResults = Object.values(searchResults)
        const dictKeys = Object.keys(dictionary || {})
        const matchedKey = dictKeys.find(x => dictionary[x]?.title === dataCategorySelectedValue) || dataCategorySelectedValue
        if (finalSearchResults?.length > 0 && dictionary?.[matchedKey] && customizeOptions?.length == 0) {
            constructData()
        }
    }, [searchResults, dictionary])

    useEffect(() => {
        if (isReady)
            loadSearchResult()
    }, [isReady])

    const handleCustomizationChange = selectedItem => {
        if (!selectedItem) {
            const requiredItems = dictionary?.[dataCategorySelectedValue]?.required || [];
            const isAllItemsSelected = customizeOptions.filter(item =>
                item.isSelected).length === customizeOptions.length;
            setCustomizeOptions(customizeOptions.map(item => {
                const isRequiredItem = findIndex(requiredItems, (x) => item.name === x) > -1;
                if (isAllItemsSelected) {
                    if (isRequiredItem) {
                        return { ...item, isSelected: true }
                    } else {
                        return { ...item, isSelected: !isAllItemsSelected }
                    }
                } else {
                    return { ...item, isSelected: !isAllItemsSelected }
                }
            }))
        }
        else {
            const newArray = customizeOptions.map(item => {
                if (item.name === selectedItem.name) {
                    return { ...item, isSelected: !item.isSelected }
                }
                return item
            })
            setCustomizeOptions(newArray)
        }
    }

    const headerFields = Object.keys(displayResults?.[0] || {})


    const closeSaveCohortModal = () => {
        setOpenSaveCohortPopUp(false)
        setEmptyCohortNameError('')
        setCohortName("")
    }

    const onSaveCohortModal = async () => {
        try {
            if (cohortName) {
                const dictKeys = Object.keys(dictionary)
                const matchedKey = dictKeys.find(x => dictionary[x]?.title === dataCategorySelectedValue) || dataCategorySelectedValue
                const body = {
                    "node_name": matchedKey,
                    "project_ids": projectSelectedValues?.length > 0 ? projectSelectedValues : null,
                    "search_name": cohortName,
                    "search_filter": searchPopoverKeyWord
                }
                setIsFormUpdating(true);
                const res = await saveSearch(body);
                if (res) {
                    setOpenSaveCohortPopUp(false)
                    setEmptyCohortNameError('');
                    props.updateSnackBar('search results saved successfully', 'success');
                    navigate("/my-stuff")
                }
            } else {
                setEmptyCohortNameError("Please enter the search name")
            }
        }
        catch (error) {
            setEmptyCohortNameError(error?.response?.data?.detail || 'Something went wrong. Please try later.')
        }finally{
            setIsFormUpdating(false);
        }
    }

    const SAVE_COHORT_MODAL = {
        modalTitle: "Save Search",
        modalContent: "",
        positiveButtonText: "Save Search",
        negativeButtonText: "Cancel",
        positiveButtonAction: onSaveCohortModal,
        negativeButtonAction: closeSaveCohortModal
    }

    const maxCount = (totalSearchResultsCount > 0) ? Math.ceil(totalSearchResultsCount / 10) : 0

    useEffect(() => {
        if (isReady) {
            loadSearchResult()
        }
    }, [pageNumber])

    useEffect(() => {
        setPageNumber(0)
    }, [totalSearchResultsCount])


    const getPropertyDetails = (label) => {
        try {
            if (label) {
                const item = dictionary?.[label];
                if (item) {
                    let details = []
                    const requiredItems = item?.required || [];
                    const systemProperties = item?.systemProperties || [];
                    let properties = item?.properties || {};
                    let includedProps = []
                    for (let prop of requiredItems) {
                        let currentPropType = properties[prop]?.type || ''
                        if (!currentPropType) {
                            const isAnyOf = properties[prop]?.anyOf
                            currentPropType = isAnyOf?.map(x => x.type) || ''
                        }
                        let obj = {
                            label: prop,
                            isRequired: 'Required',
                            description: properties[prop]?.description || 'No Description',
                            term: properties[prop]?.term || '',
                            type: currentPropType || '',
                            enum: properties[prop]?.enum || '',
                            anyOf: properties[prop]?.anyOf
                        }
                        details.push(obj);
                        includedProps.push(prop)
                    }
                    for (let prop of systemProperties) {
                        delete properties[prop]
                    }
                    for (let prop of Object.keys(properties)) {

                        if (includedProps.find(x => x === prop)) {
                            continue;
                        }
                        let currentPropType = properties[prop]?.type || ''
                        if (!currentPropType) {
                            const isAnyOf = properties[prop]?.anyOf
                            currentPropType = isAnyOf?.map(x => x.type) || ''
                        }
                        let obj = {
                            label: prop,
                            isRequired: 'No',
                            description: properties[prop]?.description || 'No Description',
                            term: properties[prop]?.term || '',
                            type: currentPropType || '',
                            enum: properties[prop]?.enum || '',
                            anyOf: properties[prop]?.anyOf
                        }
                        details.push(obj);
                    }
                    return details;
                }
            }
            return []
        }
        catch (error) {
            console.log('Error in getting the property details', error);
            return []
        }

    }


    const getResultsToDownload = async (isJSONDownload) => {
        try {
            if (isJSONDownload) {
                setJsonDownloadStatus(0)
            } else {
                setTsvDownloadStatus(0)
            }
            const dictKeys = Object.keys(dictionary)
            const matchedKey = dictKeys.find(x => dictionary[x]?.title === dataCategorySelectedValue) || dataCategorySelectedValue
            const details = getPropertyDetails(matchedKey)
            let headerKeys = {}
            if (!isJSONDownload) {
                for (let detail of details) {
                    let currentLabel = detail.label;
                    let innerProperties = []
                    if (detail?.anyOf?.length > 0) {
                        const innerProps = detail.anyOf[0]?.items?.properties || {}
                        innerProperties = Object.keys(innerProps)
                    }
                    if (innerProperties.length > 0) {
                        for (const prop of innerProperties) {
                            if (prop === "id") {
                                continue;
                            }
                            headerKeys[`${currentLabel}.${prop}`] = detail.isRequired;
                        }
                    } else {
                        headerKeys[currentLabel] = detail.isRequired
                    }
                }
            } else {
                for (let detail of details) {
                    let innerProperties = []
                    if (detail?.anyOf?.length > 0) {
                        const innerProps = detail.anyOf[0]?.items?.properties || {}
                        innerProperties = Object.keys(innerProps)
                    }
                    if (innerProperties?.length > 0) {
                        headerKeys[detail.label] = {}
                        for (const prop of innerProperties) {
                            if (prop === "id") {
                                continue;
                            }
                            headerKeys[detail.label][prop] = detail.isRequired;
                        }
                        continue;
                    }
                    headerKeys[detail.label] = detail.isRequired
                }
            }
            const body = {
                "node_name": matchedKey,
                "project_ids": projectSelectedValues?.length > 0 ? projectSelectedValues : null,
                "search_filter": isEmpty(searchPopoverKeyWord) ? null : searchPopoverKeyWord
            }
            let chunkedRecords = []
            const RECORD_SIZE = 500;
            const finalSearchResults = Object.values(searchResults)
            const isAnyItemSelected = finalSearchResults?.find(x => x?.isSelected)

            const shouldPullData = isAllSelectModeActive || !isAnyItemSelected

            let totalChunksLength = (!shouldPullData) ? 0 : totalSearchResultsCount / RECORD_SIZE

            for (let i = 0; i <= totalChunksLength; i = i + 1) {
                let newRecords = []
                if (!shouldPullData) {
                    newRecords = finalSearchResults?.filter(x => x?.isSelected)
                } else {
                    const result = await getSearchResults(body, i + 1, RECORD_SIZE);
                    newRecords = result?.[matchedKey] || []
                }
                if (isJSONDownload) {
                    newRecords = newRecords.map(x => {
                        const headerKeysKeys = Object.keys(headerKeys);
                        let obj = {}
                        for (let key of headerKeysKeys) {
                            if (typeof headerKeys[key] === 'object') {
                                const innerKeys = Object.keys(headerKeys[key]);
                                if (!obj[key]) {
                                    obj[key] = {}
                                }
                                for (let innerKey of innerKeys) {
                                    obj[key][innerKey] = x?.[key]?.[0]?.[innerKey] || ''
                                }
                            } else {
                                obj[key] = x?.[key] || ''
                            }
                        }
                        return obj
                    })
                } else {
                    newRecords = newRecords.map(x => {
                        const headerKeysKeys = Object.keys(headerKeys);
                        let obj = {}
                        for (let key of headerKeysKeys) {
                            const splitKey = key.split(".")
                            if (splitKey.length === 1) {
                                obj[key] = x?.[key] || ''
                            } else {
                                obj[key] = x?.[splitKey[0]]?.[0]?.[splitKey[1]] || ''
                            }
                        }
                        return obj
                    })
                }
                chunkedRecords = [...chunkedRecords, ...newRecords]
                if (isJSONDownload) {
                    setJsonDownloadStatus(i * 100 / (totalSearchResultsCount / RECORD_SIZE))
                } else {
                    setTsvDownloadStatus(i * 100 / (totalSearchResultsCount / RECORD_SIZE))
                }
            }
            return chunkedRecords;
        }
        catch (error) {
            return []
        }
    }



    const handleDownloadJson = async () => {
        let finalSearchResults = await getResultsToDownload(true);
        let printResults = finalSearchResults;
        const hasAnyItemSelected = finalSearchResults.find(item => item.isSelected);
        if (hasAnyItemSelected) {
            printResults = finalSearchResults.filter(item => item.isSelected)
        }

        if (printResults?.length > 0) {
            const data = JSON.stringify(printResults, undefined, 4)
            let blob = new Blob([data], { type: 'text/json' });
            let e = document.createEvent('MouseEvents');
            let a = document.createElement('a')
            a.download = dataCategorySelectedValue + '_searchResults.json'
            a.href = window.URL.createObjectURL(blob)
            a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
            e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
            a.dispatchEvent(e)
        }
        setJsonDownloadStatus(null)
    }

    const convertJsonToTSVFormat = (json) => {
        let fields = Object.keys(json[0])
        let replacer = function (key, value) { return value === null ? '' : value }
        let tsv = json.map(function (row) {
            return fields.map(function (fieldName) {
                return JSON.stringify(row[fieldName], replacer)
            }).join('\t')
        })
        tsv.unshift(fields.join('\t')) // add header column
        tsv = tsv.join('\r\n');
        return tsv;
    }

    const onDownloadTSV = async () => {
        let searchResults = await getResultsToDownload(false);
        for (let result of searchResults) {
            let keys = Object.keys(result || {})
            for (let key of keys) {
                try {
                    if (Array.isArray(result[key]) && result[key]?.length == 1) {
                        if (typeof result[key][0] === 'object') {
                            const innerKeys = Object.keys(result[key][0])
                            for (let innerKey of innerKeys) {
                                result[`${key}.${innerKey}`] = result[key][0][innerKey]
                            }
                            delete result[key];
                        }
                    }
                }
                catch (error) {
                    continue;
                }
            }
        }
        let printResults = searchResults;
        const hasAnyItemSelected = searchResults.find(item => item.isSelected);
        if (hasAnyItemSelected) {
            printResults = searchResults.filter(item => item.isSelected)
        }
        if (printResults?.length > 0) {
            let formattedData = convertJsonToTSVFormat(printResults);
            console.log(formattedData, printResults)
            let blob = new Blob([formattedData], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { 
                navigator.msSaveBlob(blob, dataCategorySelectedValue + "_searchResults.tsv");
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { 
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", dataCategorySelectedValue + "_searchResults.tsv");
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }
        setTsvDownloadStatus(null)
    }

    const getIsAllSelected = () => {
        if (searchResults) {
            const finalSearchResults = Object.values(searchResults)
            for (let result of finalSearchResults) {
                if (!result?.isSelected) {
                    return false;
                }
            }
            return true
        }
    }

    const selectAllSearchResults = () => {
        const newValue = !isAllSelectModeActive
        const keys = Object.keys(searchResults)
        const searchResultsClone = { ...searchResults }
        for (let key of keys) {
            if (searchResultsClone[key]) {
                searchResultsClone[key].isSelected = newValue;
            }
        }
        setSearchResults(searchResultsClone)
        setIsAllSelectModeActive(newValue)
    }

    const toggleCustomSelectedItem = (item) => {
        const searchResultsClone = { ...searchResults }
        const keys = Object.keys(searchResultsClone)
        for (let key of keys) {
            if (searchResultsClone[key]?.id === item.id) {
                searchResultsClone[key].isSelected = !searchResultsClone[key].isSelected;
                break;
            }
        }
        setSearchResults(searchResultsClone)
        const isAllItemsSelected = getIsAllSelected() && Object.values(searchResults).length == totalSearchResultsCount;
        setIsAllSelectModeActive(isAllItemsSelected)
    }

    const isAllSelected = getIsAllSelected()

    const setTablePage = number => {
        setPageNumber(number);
    }

    const renderDummyContent = () => {
        return <div id='cvb-searchResult-dummyComponent' className={classes.contentMainContentArea}>
            <div className={classes.contentContainer}>
                <div className={classes.tableHeaders}>
                    <div className={classes.leftPanelContent}>
                        <div className={classes.customizeHeader}>
                            <p className={classes.resultsCount}>...</p>
                        </div>
                    </div>
                </div>
                <TableContainer
                    component={Paper}
                    elevation={0}
                    className={classes.tableContainer}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><Skeleton width="100%" /></TableCell>
                                <TableCell><Skeleton width="100%" /></TableCell>
                                <TableCell><Skeleton width="100%" /></TableCell>
                                <TableCell><Skeleton width="100%" /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                new Array(10).fill(1).map(() => {
                                    return <TableRow key={v4()}>
                                        <TableCell><Skeleton width="100%" /></TableCell>
                                        <TableCell><Skeleton width="100%" /></TableCell>
                                        <TableCell><Skeleton width="100%" /></TableCell>
                                        <TableCell><Skeleton width="100%" /></TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    }
    const sendDataToGA = (nodeName, projects, keyword) => {
        map(projects, (project) => {
            sendGAEvents('search', 'search_data', 'search data', { 'node_name': nodeName, 'project': project, 'keyword': keyword });
        })
    }
    const handleOnSearch = () => {
        setIsFormUpdating(true);
        setSearchResults({})
        setIsAllSelectModeActive(false)
        sendDataToGA(dataCategorySelectedValue, projectSelectedValues, searchPopoverKeyWord);
        setCustomizeOptions([])
        const dictKeys = Object.keys(dictionary)
        const matchedKey = dictKeys.find(x => dictionary[x]?.title === dataCategorySelectedValue) || dataCategorySelectedValue
        getTotalRecordsCount(matchedKey, projectSelectedValues, searchPopoverKeyWord)
    }

    const getDisplayableResults = () => {
        const finalSearchResults = Object.values(searchResults)
        return finalSearchResults.slice(pageNumber * 10, (pageNumber + 1) * 10).filter(x => x)
    }

    const displayableResults = getDisplayableResults()

    return (
        <div className={classes.mainContainer}>
            <Modal
                open={openSaveCohortPopUp}
                handleClose={(closeSaveCohortModal)}
                dialogProps={SAVE_COHORT_MODAL}
                disableTitleDivider
            >
                <div className={classes.saveCohortContentContainer}>
                    <div className={classes.cohortInputContainer}>
                        <label id='cvb-searchResult-searchName-label' htmlFor='cohortName'>Search Name</label>
                        <OutlinedInput
                            value={cohortName}
                            onChange={e => { setCohortName(e.target.value); setEmptyCohortNameError("") }}
                            placeholder='Enter search name'
                            id='cvb-searchResult-searchName-input'
                        />
                        {emptyCohortNameError && <p><ErrorText errorMessage={emptyCohortNameError} /></p>}
                    </div>
                    <p id='cvb-searchResult-cohortInfo-text' className={classes.cohortInfoText}>
                        <span>
                            <img alt='info icon' className={classes.infoIcon} src={InfoGreyIcon} />
                        </span>
                        You can access this saved search later from "My Stuff" Page
                    </p>
                </div>
            </Modal>
            <div className={classes.innerContainerSesarch}>
                {/* <Grid item xs={12} lg={12} md={12} className={classes.backroundImageContainer}>
                </Grid> */}
                <div className={[classes.searchSection, classes.searchSectionInner].join(' ')}>
                    <Grid item xs={9} lg={9}>
                        {/* <div className={classes.searchTitle}>Search User</div> */}
                        <div className={classes.searchResultsForm}>
                            <div className={classes.searchResultsFormItems}>
                                <label id='cvb-searchResult-dataCategory-label'>Data Category</label>
                                <Dropdown
                                    searchable
                                    parseText
                                    values={getDataCategoryValues}
                                    handleFilterStateChange={(item) => { setDataCategorySelectedValue(item); setSearchDataCategoryError(false) }}
                                    selectedFilter={dataCategorySelectedValue}
                                    filterCategory={"dataCategory"}
                                    placeholder="Select" />
                                {
                                    searchDataCategoryError &&
                                    <p><ErrorText errorMessage={"Please enter data category"} /></p>
                                }
                            </div>
                            <div className={classes.searchResultsFormItems}>
                                <label>Project <span>(Optional)</span></label>
                                <MultiSelect
                                    searchable
                                    values={availableSearchProjectsList || []}
                                    handleFilterStateChange={(item) => setProjectsSelectedValues(item)}
                                    selectedValues={projectSelectedValues}
                                    filterCategory={"dataCategory"}
                                    placeholder="Select"
                                />
                            </div>
                            <div className={classes.searchResultsFormItems}>
                                <label id='cvb-searchResults-keyWord-label'>Keyword <span>(Optional)</span></label>
                                <OutlinedInput fullWidth type='text'
                                    value={searchPopoverKeyWord}
                                    placeholder='Enter what are you looking for'
                                    onChange={e => setSearchPopOverKeyWord(e.target.value)}
                                    className={classes.keywordSearchField}
                                    autoComplete="off"
                                    id='cvb-searchResults-keywordsInput'
                                />
                            </div>
                            <div className={classes.searchButtonContainer}>
                                <InputAdornment position="end">
                                    <button
                                        disabled={isFormUpdating}
                                        id='cvb-searchResults-search-button'
                                        onClick={handleOnSearch}
                                        className={`medium-size-button solid-button ${classes.searchRecordMain}`}>Search</button>
                                </InputAdornment>

                            </div>
                        </div>
                    </Grid>
                </div>
            </div>
            <Grid container className={classes.searchRecordsTableContainer}>
                <Grid item xs={9} ls={9} md={9}>
                    <div>
                        {
                            isLoading ?
                                <div>
                                    {
                                        renderDummyContent()
                                    }
                                </div> :
                                headerFields?.length > 0 && Object.values(searchResults)?.length > 0 ?
                                    <div className={classes.contentMainContentArea}>
                                        <div className={classes.contentContainer}>
                                            <div className={classes.tableHeaders}>
                                                <div className={classes.leftPanelContent}>
                                                    <div className={classes.customizeHeader}>
                                                        <p id='cvb-searchResults-showing-pageNumber' className={classes.resultsCount}>
                                                            Showing {((pageNumber) * 10) + 1} - {((pageNumber) * 10) + 10 > totalSearchResultsCount ? totalSearchResultsCount : ((pageNumber) * 10) + 10} of {totalSearchResultsCount}
                                                        </p>
                                                        <p className={classes.customizeMenu}>
                                                            <CheckBoxOptionsPopOver
                                                                data={customizeOptions}
                                                                toggleCustomSelectedItem={handleCustomizationChange} />
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={classes.rightPanelContent}>
                                                    <ul>
                                                        <li>
                                                            <button id='cvb-searchResults-exportAsJson-button' className={`small-size-button transparent-button ${classes.searchResultsExport}`}
                                                                onClick={handleDownloadJson}>
                                                                Export as JSON
                                                        </button>
                                                        </li>
                                                        <li>
                                                            <button id='cvb-searchResults-exportAsTsv-button' className={`small-size-button transparent-button ${classes.searchResultsExport}`}
                                                                onClick={onDownloadTSV}>
                                                                Export as TSV
                                                        </button>
                                                        </li>
                                                        <li>
                                                            <button id='cvb-searchResults-saveSearch-button' className={`small-size-button transparent-button ${classes.searchResultsExport}`}
                                                                onClick={() => setOpenSaveCohortPopUp(true)}
                                                                disabled={isFormUpdating}
                                                                >
                                                                Save Search
                                                        </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {
                                                (jsonDownloadStatus != null || tsvDownloadStatus != null) &&
                                                <Grid container className={classes.downloadProgressContainer}>
                                                    {
                                                        jsonDownloadStatus != null &&
                                                        <Grid item xs={12} ls={12} md={12} className={classes.downloadProgressJSON}>
                                                            <p id='cvb-searchResults-downloadingJson-text'>Downloading JSON file</p>
                                                            <LinearProgress id='cvb-searchResults-downloadingJson-progress' variant="determinate" value={jsonDownloadStatus} />{Math.round(jsonDownloadStatus)}%
                                                        </Grid>
                                                    }
                                                    {
                                                        tsvDownloadStatus != null &&
                                                        <Grid item xs={12} ls={12} md={12} className={classes.downloadProgressTSV}>
                                                            <p id='cvb-searchResults-downloadingTsv-text'>Downloading TSV file</p>
                                                            <LinearProgress id='cvb-searchResults-downloadingTsv-progress' variant="determinate" value={tsvDownloadStatus} />{Math.round(tsvDownloadStatus)}%

                                                        </Grid>
                                                    }
                                                </Grid>
                                            }
                                            {
                                                <TableContainer
                                                    component={Paper}
                                                    elevation={0}
                                                    className={classes.tableContainer}>
                                                    <Table className={classes.table} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                {
                                                                    headerFields.map((item, index) => {
                                                                        return <TableCell key={v4()}>
                                                                            {
                                                                                index === 0 &&
                                                                                <span className={classes.tableCheckbox}>
                                                                                    <Checkbox
                                                                                        checked={isAllSelectModeActive}
                                                                                        onClick={() => { selectAllSearchResults() }}
                                                                                        color="primary"
                                                                                        id={`cvb-searchResults-selectAll-${item}`}
                                                                                        indeterminate={Object.values(searchResults).find(x => x?.isSelected) &&
                                                                                            !isAllSelectModeActive}
                                                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                                    />
                                                                                </span>
                                                                            }

                                                                            {item}</TableCell>
                                                                    })
                                                                }
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                displayableResults?.map((item) => {
                                                                    return <TableRow key={v4()}>
                                                                        {
                                                                            headerFields?.map((innerItem, index) => {
                                                                                return <TableCell align="left" key={v4()}
                                                                                    className={innerItem === "id" ? classes.idData : ""}>
                                                                                    {
                                                                                        index === 0 &&
                                                                                        <span className={classes.tableCheckbox}>
                                                                                            <Checkbox
                                                                                                checked={item?.isSelected}
                                                                                                id={`cvb-searchResults-select-${item?.[innerItem]}`}
                                                                                                onClick={() => { toggleCustomSelectedItem(item) }}
                                                                                                color="primary"
                                                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                                            />
                                                                                        </span>
                                                                                    }

                                                                                    <Tooltip placement="bottom-start" className={classes.userNameTitle} title={item?.[innerItem]}>
                                                                                        <span className={classes.userNameTitle}>{item?.[innerItem]}</span>
                                                                                    </Tooltip>
                                                                                </TableCell>
                                                                            })
                                                                        }
                                                                    </TableRow>
                                                                })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            }
                                            <div className={classes.tablePagination}>
                                                <TablePagination
                                                    maxCount={maxCount}
                                                    page={pageNumber}
                                                    setPage={setTablePage}
                                                />
                                            </div>
                                        </div>
                                    </div> :
                                    <div>No search records</div>
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default SearchResults

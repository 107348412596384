import { Grid, Slide } from '@mui/material';
import React, { useState, useEffect } from 'react'
import Classes from './index.module.css'
const CookieBanner = (props) => {
    const { onAcceptClick = () =>{ }, onSeeCookiesClick = () => { }, showCookieBanner = false, isCookiesPage = false,
    onCancelClick = () => { }, loginButtonClick = false
    } = props;

    let animatedClasses = loginButtonClick ? ` ${Classes.animated} ${Classes.shake}` : '';
    return (
        <Slide direction="up" in={showCookieBanner} timeout={500} mountOnEnter unmountOnExit>
            <div className={`${Classes.bannerContainer} ${animatedClasses}`}>
                <Grid container spacing={6}>
                    <Grid item xs={8} className={Classes.cookieContent}>
                        <p className={Classes.heading}>BRAINCommons (BC) uses cookies</p>
                        <p className={Classes.subheading}>We use cookies to distinguish you from other users of the BC Platform.
                        This helps us to provide you with an optimized experience and allows us to make continual improvements.
                        This information might be about you, your preferences or your device and is mostly used to make the
                        BC Platform work as you expect it to. The information doesn’t usually directly identify you, but it can
            give you a more personalized experience.</p>
                        <hr />
                    </Grid>

                    <Grid item xs={4} className={Classes.buttonContainer}>
                        {!isCookiesPage ? <button className={`large-size-button ${Classes.seeCookieButton}`} onClick={onSeeCookiesClick}>See Cookies Settings</button>
                        : <button className={`large-size-button ${Classes.seeCookieButton}`} onClick={onCancelClick}>Cancel</button>
                        }
                        {!isCookiesPage && <button className={`large-size-button ${Classes.acceptButton}`} onClick={onAcceptClick}>Accept</button>}
                    </Grid>
                </Grid>
            </div>
        </Slide>
    )
}

export default CookieBanner;
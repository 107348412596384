import React, { useEffect, useState, useRef } from 'react'
import Classes from './index.module.css'
import Grid from '@mui/material/Grid'
import { navigate } from '@reach/router'
import BackButtonArrow from '../../../assets/icons/left_arrow.png'
import DataUsePolicy from '../../controls/data-use-policy'
import InstitutionalCertification from '../../controls/institutional-certification'
import { getDesignationList, getOrganizationList, getUserPermissions, getUserProfile, updateDataUsePolicy, updateUserProfile } from '../../../api/profile-api'
import { Auth } from 'aws-amplify'
import isEmpty from 'lodash/isEmpty'
import { scrollToGivenTop } from '../../../utils/input-validations'
import { VALID_EMAIL_REGEX } from '../../../constants/regex'
import moment from 'moment'
import { isDate } from 'lodash'
import TrainingAndCertificate from "../../controls/training-and-certificate"
import Modal from '../../commons/modal'
import CautionIcon from '../../../assets/images/caution.svg';
import { sendGAEvents } from '../../../api/generic-api'
import {  createDataRequest, getSubmittedDUP } from '../../../api/profile-api'
import CustomLoader from '../../commons/custom-loader';
import UserOnboard from '../user-onboard/UserOnboard'
import { useSelector, useDispatch } from 'react-redux'
import { getResourceActions } from '../../../utils/resourceActionsFormatter'
import { updateInitialOnboardState, zone2AccessProfileInitialState } from '../../../redux/actions/userOnboardActions'
import { editProfileInitialState } from '../../../redux/actions/editProfileActions'
import map from 'lodash/map'
import find from 'lodash/find'
import { lowerCase } from 'lodash'
export default function ZoneRequestAccess(props) {

    const [stage, setStage] = useState(1);
    const {userDetails = {}} = props;
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [currentZone, setCurrentZone] = useState('');

    //Data use policy fields

    const sigRef = useRef()
    const [agreedDataUsePolicy, setAgreedDataUsePolicy] = useState(false)
    const [researcherSign, setResearcherSign] = useState('')
    const [streetAddress, setStreetAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [country, setCountry] = useState('')

    //institutional certificates fields
    const [institution, setInstitution] = useState('')
    const [signatureEmail, setSignatureEmail] = useState('')
    const [streetAddressOfInst, setStreetAddressOfInst] = useState('')
    const [cityOfInst, setCityOfInst] = useState('')
    const [stateOfInst, setStateOfInst] = useState('')
    const [zipCodeOfInst, setZipCodeOfInst] = useState('')
    const [countryOfInst, setCountryOfInst] = useState('')
    const [certificateName, setCertificateName] = useState('')
    const [adminName, setAdminName] = useState('')
    const [signingDate, setSigningDate] = useState('');
    const [certificates, setCertificates] = useState([]);
    const [isFormUpdating, setIsFormIsUpdating] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const userOnboardProps = useSelector(state => state.userOnboard)
    const editProfileProps = useSelector(state => state.editProfile)
    const reduxDispatcher = useDispatch();
    const { 
        firstName,
        lastName,
        organization,
        otherOrganizationName,
        highestEducation,
        otherHighestEducation,
        scientificBackground,
        designation,
        otherDesignation,
        researchersGoals
    } = userOnboardProps;
    const createDUPSign = async (data) => (!isEmpty(data) && !isEmpty(sigRef.current)) && (await sigRef?.current?.fromDataURL(data));
    const [ openCertificateDeleteModal, setOpenCertificateDeleteModal ] = useState(false);
    const [allOrganization, setAllOrganization] = useState([]);
    const [organizationsList, setOrganizationsList] = useState([]);
    const [allDesignation, setAllDesignation] = useState([]);
    const [designationList, setDesignationList] = useState([]);
    const [isAgreeSelected, setIsAgreeSelected] = useState(false);

    useEffect(() => {
        if(currentZone === 'Zone 2'){
            setStage(1);
        }else if(currentZone === 'Zone 3'){
            setStage(2);
        }
    }, [currentZone])

    useEffect(() => {
        getInitialData();
    }, [])
    useEffect(() => {
        getOrganizations();
    }, [])

    useEffect(() => {
        getDesignations();
    }, [])

    const getDesignations = async () => {
        try {
            const res = await getDesignationList();
            const desResList = res?.designations || [];
            setDesignationList(desResList);
            if(desResList.length > 0){
                let desList = map(desResList, (des)=> des?.name);
                desList = desList.sort();
                //orgList.push('Other');
                setAllDesignation(desList);
            }else{
                setAllDesignation([]);
            }
            
        } catch (error) {
            setAllDesignation([]);
            console.log('error in getting organization list')
        }
    }
    const getOrganizations = async () => {
        try {
            const res = await getOrganizationList();
            const orgResList = res?.organizations || [];
            setOrganizationsList(orgResList);
            if(orgResList.length > 0){
                let orgList = map(orgResList, (org)=> org?.name);
                orgList = orgList.sort();
                setAllOrganization(orgList);
            }else{
                setAllOrganization([]);
            }
            
        } catch (error) {
            setAllOrganization([]);
            console.log('error in getting organization list')
        }
    }
    const getInitialData = async () => {
        try {
            let userDetails = await Auth.currentUserInfo();
            const userInfo = await getUserProfile(userDetails?.attributes?.sub)
            userDetails = userInfo?.user_details;
            reduxDispatcher(updateInitialOnboardState(userDetails || {}))
            reduxDispatcher(zone2AccessProfileInitialState(userDetails || {}))
        }
        catch (error) {
            console.log(error)
        }
    }

    const checkForDUPFormErrorFields = () => {
        if(sigRef?.current?.isEmpty()){
            const signatureElement = document.getElementById('signature-pad');
            scrollToGivenTop(signatureElement);
            return;
        }
        if(!isAgreeSelected){
            const signatureElement = document.getElementById('signature-pad');
            scrollToGivenTop(signatureElement);
            return;
        }
        if(!isDate(signingDate)){
            const dateElement = document.getElementById('signingDate');
            scrollToGivenTop(dateElement);
            return;
        }
        if(isEmpty(state) || isEmpty(city) || isEmpty(streetAddress) || isEmpty(zipCode) || isEmpty(country)){
            const cityElement = document.getElementById('city');
            scrollToGivenTop(cityElement);
            return;
        }
    }

    useEffect(() => {
        if(props?.location?.state?.type){
            setCurrentZone(props.location.state.type)
        }else{
            setCurrentZone("Zone 2");
        }
    },[props?.location?.state?.type])

    const getSubmittedDUPRequest = async () => {
        try {
            const requests = await getSubmittedDUP(userDetails?.['username']);
            if(requests?.isDupSubmitted){
                navigate('/my-profile')
            }
            if(requests){
                if(requests?.stage){
                    setStage(parseInt(requests?.stage)) 
                }
                setResearcherSign(requests?.researcherSign || '')
                setStreetAddress(requests?.researcherAddress?.streetName || '')
                setCity(requests?.researcherAddress?.city || '')
                setState(requests?.researcherAddress?.state || '')
                setZipCode(requests?.researcherAddress?.zipCode || '')
                setCountry(requests?.researcherAddress?.country || '')
                setInstitution(requests?.certificationApproverDetails?.nameOfTheInstitution || '')
                setSignatureEmail(requests?.certificationApproverDetails?.emailOfsignatorAtIntitution || '')
                setStreetAddressOfInst(requests?.certificationApproverDetails?.addressOfInstitution?.streetName || '')
                setCityOfInst(requests?.certificationApproverDetails?.addressOfInstitution?.city || '')
                setStateOfInst(requests?.certificationApproverDetails?.addressOfInstitution?.state || '')
                setZipCodeOfInst(requests?.certificationApproverDetails?.addressOfInstitution?.zipCode || '')
                setCountryOfInst(requests?.certificationApproverDetails?.addressOfInstitution?.country || '')
                setCertificateName(requests?.certificationName || '')
                setAdminName(requests?.adminName || '')
                if(requests?.date){
                    const timestamp = Date.parse(requests?.date);
                    if (isNaN(timestamp) == false) {
                        setSigningDate(new Date(requests.date))
                    }
                }
                if(requests?.researcherSign){
                    await createDUPSign(requests.researcherSign)
                }
            }
            
        }catch (error) {
            console.log(error);
        }finally{
            setIsLoading(false);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getSubmittedDUPRequest();
    },[])

    const saveAsDraft = async () => {
        try{
            const dateToSend = signingDate ? moment(signingDate).format('YYYY-MM-DD') : '';
            const body = {
                "researcherSign": researcherSign || "",
                "researcherName": (userDetails?.["first_name"] || "") + " " + (userDetails?.['last_name'] || "")  || '',
                "researcherEmail": userDetails?.["email"] || '',
                "date": dateToSend || "",
                "researcherAddress": {
                    "streetName": streetAddress || "",
                    "city": city || "",
                    "state": state || "",
                    "zipCode": zipCode || "",
                    "country": country || ""
                },
                "certificationApproverDetails": {
                    "nameOfTheInstitution": institution || "",
                    "emailOfsignatorAtIntitution": signatureEmail || "",
                    "addressOfInstitution": {
                        "streetName": streetAddressOfInst || "",
                        "city": cityOfInst || "",
                        "state": stateOfInst || "",
                        "zipCode": zipCodeOfInst || "",
                        "country": countryOfInst || ""
                    }
                },
                "stage": stage + '',
                "certificationName": certificateName || "",
                "adminName": adminName || ""
            }
            const dupResponse = await updateDataUsePolicy(body);
            props.updateSnackBar(`Request for ${currentZone} Access saved.`, "Success");

        } catch (error) {
            props.updateSnackBar(`Unable to save data for ${currentZone} Access`, "Error");
        }
        
    }

    const handleNext = () => {
        setIsFormSubmitted(true);
        if(stage == 1){
            //checkForPersonalDetailsFields();
            //API call 
            onSubmitEdit();
            setIsFormSubmitted(false);
        }
        if(stage == 2){
            checkForDUPFormErrorFields();
            if (isAgreeSelected && !!state && !!city && !!streetAddress && !!zipCode && !!country && !sigRef?.current?.isEmpty() && isDate(signingDate)) {
                switch(currentZone){
                    case 'Zone 2': {
                        sendGAEvents('submit_zone2_data_use_agreement', 'zone2_access', 'Zone2 data use agreement submit');
                        break;
                    }
                    case 'Zone 3': {
                        sendGAEvents('submit_zone3_data_use_agreement', 'zone3_access', 'Zone3 data use agreement submit');
                        break;
                    }
                    default: break;
                }
                setResearcherSign(sigRef?.current?.toDataURL());
                setStage(3)
                setIsFormSubmitted(false)
            }
        }else if(stage == 3){
            checkForICFormFields();
            if (!isEmpty(institution) && checkForValidEmail() && !isEmpty(cityOfInst) && !isEmpty(zipCodeOfInst) &&
            !isEmpty(countryOfInst) && !isEmpty(stateOfInst) && !isEmpty(certificateName) && !isEmpty(adminName)) {
                switch(currentZone){
                    case 'Zone 2': {
                        sendGAEvents('submit_zone2_institutional_certificate', 'zone2_access', 'Zone2 Institutional certificate submit');
                        break;
                    }
                    case 'Zone 3': {
                        sendGAEvents('submit_zone3_institutional_certificate', 'zone3_access', 'Zone3 Institutional certificate submit');
                        break;
                    }
                    default: break;
                }
                setStage(4)
                setIsFormSubmitted(false)
            }
        } 
    }

    const checkForValidEmail = () => {
        let re = VALID_EMAIL_REGEX;
        const isValidEmail = re.test(signatureEmail);
        return isValidEmail;
    }
    const checkForICFormFields = () => {
        if(isEmpty(institution) || !checkForValidEmail() ){
            const institutionNameElement = document.getElementById('institutionName');
            scrollToGivenTop(institutionNameElement);
            return;
        }
        if(isEmpty(cityOfInst) || isEmpty(zipCodeOfInst) || isEmpty(countryOfInst) || isEmpty(stateOfInst)){
            const cityElement = document.getElementById('instituteCity');
            scrollToGivenTop(cityElement);
            return;
        }
        if(isEmpty(certificateName) || isEmpty(adminName)){
            const adminNameElement = document.getElementById('adminName');
            scrollToGivenTop(adminNameElement);
            return;
        }
    }
    const handleSubmit = async () => {
        try {
            if (certificates.length < 1) {
                props.updateSnackBar('Please make sure you have uploaded a certificate.', 'error');
            }
            else {
                setIsFormSubmitted(true);
                switch(currentZone){
                    case 'Zone 2': {
                        sendGAEvents('submit_zone2_request', 'zone2_access', 'zone 2 access request submit');
                        break;
                    }
                    case 'Zone 3': {
                        sendGAEvents('submit_zone3_request', 'zone3_access', 'zone 3 access request submit');
                        break;
                    }
                    default: break;
                }
                const dateToSend = moment(signingDate).format('YYYY-MM-DD');
                const body = {
                    "researcherSign": researcherSign,
                    "researcherName": (userDetails?.["first_name"] || "") + " " + (userDetails?.['last_name'] || ""),
                    "researcherEmail": userDetails?.["email"] || '',
                    "date": dateToSend,
                    "researcherAddress": {
                        "streetName": streetAddress,
                        "city": city,
                        "state": state,
                        "zipCode": zipCode,
                        "country": country
                    },
                    "certificationApproverDetails": {
                        "nameOfTheInstitution": institution,
                        "emailOfsignatorAtIntitution": signatureEmail,
                        "addressOfInstitution": {
                            "streetName": streetAddressOfInst,
                            "city": cityOfInst,
                            "state": stateOfInst,
                            "zipCode": zipCodeOfInst,
                            "country": countryOfInst
                        }
                    },
                    "stage": "Completed",
                    "certificationName": certificateName,
                    "adminName": adminName
                }
                setIsFormIsUpdating(true);
                const dupResponse = await updateDataUsePolicy(body);
                let zoneBody = {
                    dup_request_id: dupResponse?.request_id
                };
                let requestType = 'zone2';
                if(currentZone === 'Zone 3'){
                    zoneBody.projects = props?.location?.state?.projects;
                    requestType = 'zone3';
                }
                await createDataRequest(requestType,zoneBody);
                navigate("/my-profile");
                props.updateSnackBar(`Request for ${currentZone} Access completed.`, "Success");
            }
        }
        catch (error) {
            console.log(error)
            const errorMessage = error?.response?.data?.error ? error?.response?.data?.error : `Unable to request ${currentZone} Access`;
            props.updateSnackBar(`${errorMessage}`, "Error")
        }
        finally{
            setIsFormIsUpdating(false);
        }
    }


    const backHandle = () => {

        if (stage === 1 && (currentZone === 'Zone 2')) {
            switch (props?.location?.state?.from) {
                case 'exploreDataZone': navigate("/explore"); break;
                case 'myProfile': navigate("/my-profile"); break;
                default: navigate("/my-profile");
            }
        }
        else if(stage === 2 && (currentZone === 'Zone 3')) {
            switch (props?.location?.state?.from) {
                case 'exploreDataZone': navigate("/explore"); break;
                case 'myProfile': navigate("/my-profile"); break;
                default: navigate("/my-profile");
            }
        }else{
            setStage(stage-1)
        }
    }

    const getCurrentDate = () => {
        const day = new Date().getDate();
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();
        return `${year}-${month}-${day}`
    }

    const onSigningDateChange = value => {
        setSigningDate(value);
    }
    const handleCancel = () => {
        setOpenCertificateDeleteModal(true);
    }

    const handleCancelAction = () => {
        switch (props?.location?.state?.from) {
            case 'exploreDataZone': navigate("/explore"); break;
            case 'myProfile': navigate("/my-profile"); break;
            default: navigate("/my-profile");
        }
    }
    
    const onCertificateChange = (cert) =>{
        setCertificates(cert);
    }

    const closeDeleteCertificateModal = () => {
        setOpenCertificateDeleteModal(false);
    }

    const validateFields = (field) => {
        switch (field) {
            case 'firstName': {
                isEmpty(firstName['text']) ? props.setOnboardingFirstName({ ...firstName, isSubmit: true, isValid: false }) :
                    props.setOnboardingFirstName({ ...firstName, isSubmit: true, isValid: true });
                return !isEmpty(firstName['text']);
            }
            case 'lastName': {
                isEmpty(lastName['text']) ? props.setOnboardingLastName({ ...lastName, isSubmit: true, isValid: false }) :
                    props.setOnboardingLastName({ ...lastName, isSubmit: true, isValid: true });
                return !isEmpty(lastName['text']);
            }
            case 'orgName': {
                isEmpty(organization['text']) ? props.setUsersOrganizationName({ ...organization, isSubmit: true, isValid: false }) :
                    props.setUsersOrganizationName({ ...organization, isSubmit: true, isValid: true });
                if (organization['text'] === "Other") {
                    return !isEmpty(otherOrganizationName);
                }
                return !isEmpty(organization['text']);
            }
            case 'education': {
                isEmpty(highestEducation['text']) ? props.setOnboardingEducation({ ...highestEducation, isSubmit: true, isValid: false }) :
                    props.setOnboardingEducation({ ...highestEducation, isSubmit: true, isValid: true });
                if (lowerCase(highestEducation['text']) === "other") {
                    isEmpty(otherHighestEducation['text']) ? props.setOtherHighestEducation({ ...otherHighestEducation, isSubmit: true, isValid: false }) :
                    props.setOtherHighestEducation({ ...otherHighestEducation, isSubmit: true, isValid: true });
                    return !isEmpty(otherHighestEducation['text']);
                }
                return !isEmpty(highestEducation['text']);
            }
            case 'scientificBackground': {
                isEmpty(scientificBackground['text']) ?  props.setScientificBackground({ ...scientificBackground, isSubmit: true, isValid: false }):
                    props.setScientificBackground({ ...scientificBackground, isSubmit: true, isValid: true });
                return !isEmpty(scientificBackground['text']);
            }
            case 'role': {
                if(!isEmpty(designation)){
                    if (lowerCase(designation) === "other") {
                        return !isEmpty(otherDesignation);
                    }
                }
                return !isEmpty(designation);
            }
            case 'researchGoal': {
                isEmpty(researchersGoals['text']) ?  props.setResearcherGoals({ ...researchersGoals, isSubmit: true, isValid: false }):
                    props.setResearcherGoals({ ...researchersGoals, isSubmit: true, isValid: true });
                return !isEmpty(researchersGoals['text']);
            }
            default: return false;
        }
    }

    const formSubmitBody = (customValues) => {
        let body = {};
        const orgId = find(organizationsList,(org) => org.name === userOnboardProps?.organization['text'])?.id || '';
        const desId = find(designationList,(desig) => desig.name === userOnboardProps?.designation)?.id || '';
        
        if (userOnboardProps.firstName?.text) {
            body['first_name'] = userOnboardProps.firstName.text
        }
        if (userOnboardProps.lastName?.text) {
            body['last_name'] = userOnboardProps.lastName.text
        }
        if (userOnboardProps.designation) {
            body['designation'] = userOnboardProps.designation
        }
        if (userOnboardProps.organization?.text) {
            body['organization'] = userOnboardProps.organization.text
        }
        if (userOnboardProps?.otherOrganizationName) {
            body['organization'] = userOnboardProps.otherOrganizationName
        }
        let otherOrgCustomAttribute = {};
        let otherDesCustomAttribute = {};
        let prevAttributeUpdated = {}
        let previousCustomAttributes = props.userDetails?.custom_attributes || {};

        const isOrganizationOther = `${userOnboardProps?.organization['text']}`.toLowerCase() == "other";
        const isDesignationOther = `${userOnboardProps?.designation}`.toLowerCase() == "other";
        if(isOrganizationOther){
            otherOrgCustomAttribute = {"other_organization": userOnboardProps?.otherOrganizationName};
        }else{
            delete previousCustomAttributes['other_organization'];
        }
        if(isDesignationOther){
            otherDesCustomAttribute = {"other_designation": userOnboardProps?.otherDesignation};
        }else{
            delete previousCustomAttributes['other_designation']
        }
        let highestEduToPass = lowerCase(userOnboardProps?.highestEducation.text) === 'other' ? userOnboardProps?.otherHighestEducation?.text : userOnboardProps?.highestEducation.text;
        body['professional_bio'] =  userOnboardProps?.bio
        body["phone_number"] =  userOnboardProps?.contactNumber
        body['custom_attributes'] = {
            ...previousCustomAttributes,
            ...otherOrgCustomAttribute,
            ...otherDesCustomAttribute,
            "highestEducation": highestEduToPass
        }
        body['organization_id'] = orgId
        body['designation_id'] = desId
        body['institution_details'] = {}
        body['institution_details']['institution_name'] = otherOrgCustomAttribute?.other_organization || ''
        body['institution_details']['streetAddress'] = userOnboardProps?.streetAddress
        body['institution_details']['city'] = userOnboardProps?.city?.text
        body['institution_details']['state'] = userOnboardProps?.stateName?.text
        body['institution_details']['zipCode'] = userOnboardProps?.zipCode
        body['institution_details']['country'] = userOnboardProps?.country?.text
        body['institution_details']['researcherGoals'] = userOnboardProps?.researchersGoals?.text || ""
        body['custom_attributes']['scientificBackground'] = userOnboardProps?.scientificBackground?.text || ""
        return body;
    }

    const onSubmitEdit = async (customValues) => {
        try {
            const isValidFirstName = validateFields('firstName');
            const isValidLastName = validateFields('lastName');
            let isValidOrgName = validateFields('orgName');
            let isEducationValid = validateFields('education'); 
            let isValidScientificBack = validateFields('scientificBackground');
            let isValidRole= validateFields('role');
            let isValidResearchGoal= validateFields('researchGoal');
            if( isValidFirstName && isValidLastName && isValidOrgName && isEducationValid && isValidScientificBack && isValidRole && isValidResearchGoal){
                const body = formSubmitBody(customValues);
                let user = await Auth.currentAuthenticatedUser();
                //let result = await Auth.updateUserAttributes(user, body);
                if (user) {
                    let profileUpdate = await updateUserProfile(user?.attributes?.sub, body);
                    props.updateSnackBar('Profile updated successfully', 'success');
                    const latestProfileDetails = await getUserProfile(user?.attributes?.sub);
                const userPermissions = await getUserPermissions();
                let resourceActions = getResourceActions(userPermissions);
                latestProfileDetails.user_details['permissions'] = userPermissions;
                latestProfileDetails.user_details['resourceActions'] = resourceActions;
                props.updateUserDetails(latestProfileDetails?.user_details || {});
                
                reduxDispatcher(editProfileInitialState(latestProfileDetails?.user_details || {}))
                    setStage(2)
                    //navigate('/my-profile');
                }
            }
        }
        catch (error) {
            console.log(error);
            props.updateSnackBar('Error while updating profile', 'error');
        }
    }
    
    const ZONE2_REQUEST_MODAL = {
        modalTitle: `Cancel ${currentZone} Access Request`,
        positiveButtonText: 'Cancel Request',
        negativeButtonText: "Continue Request",
        positiveButtonAction: handleCancelAction,
        negativeButtonAction: closeDeleteCertificateModal,
        imageSrc: CautionIcon
    }

    return (
        <div className={Classes.editProfileMainContainer}>
            <div className={Classes.editProfileChildContainer}>
                <Grid container direction='column'>
                    <Modal
                        open={openCertificateDeleteModal}
                        handleClose={closeDeleteCertificateModal}
                        dialogProps={ZONE2_REQUEST_MODAL}
                        disableTitleDivider={true}
                        enableImage={true}
                    > <div>In order to download data, you need to complete Data Use Agreement.</div> </Modal>
                    <p onClick={backHandle} className={Classes.backButton}>
                        <span><img alt='back button arrow' src={BackButtonArrow} /></span>
                        <p className={Classes.backButtonText}>Back</p>
                    </p>
                    <h3 className={Classes.editProfileText}>Requesting {currentZone} Data</h3>
                    <div className={Classes.agreementHeader}>
                        <Grid container direction='column'>

                            <div className={Classes.formStepperHorizontal}>
                               {currentZone === 'Zone 2' && <div className={Classes.formStep}>
                                    <div className={Classes.activeInactiveDotContainer}>
                                        <div className={stage == 1 ? Classes.formStepActiveCircle
                                            : ((stage == 2 || stage == 3 || stage == 4)? Classes.firstFormCompleted : Classes.formStepCircle)}><span></span>
                                        </div>
                                    </div>
                                    <div className={Classes.formStepTitle}>Personal Details</div>
                                    <div className={Classes.formStepOptional}>Tell us more about you
                                    </div>
                                    <div className={Classes.formStepBarLeft}></div>
                                    <div className={Classes.formStepBarRight}></div>
                                </div>}
                                <div className={Classes.formStep}>
                                    <div className={Classes.activeInactiveDotContainer}>
                                        <div  className={stage == 2 ? Classes.formStepActiveCircle
                                            : ((stage == 3  || stage == 4)? Classes.firstFormCompleted : Classes.formStepCircle)}><span></span></div>
                                    </div>
                                    <div className={Classes.formStepTitle}>Data Use Agreement</div>
                                    <div className={Classes.formStepOptional}>Please sign the Data Use Agreement in order to request access to {currentZone} data.</div>
                                        <div className={Classes.formStepBarLeft}></div>
                                        <div className={Classes.formStepBarRight}></div>
                                </div>
                               
                                <div className={Classes.formStep}>
                                    <div className={Classes.activeInactiveDotContainer}>
                                        <div className={stage == 3 ? Classes.formStepActiveCircle
                                            : (stage == 4 ? Classes.firstFormCompleted : Classes.formStepCircle)}><span></span></div>
                                    </div>
                                    <div className={Classes.formStepTitle}>INSTITUTIONAL CERTIFICATION</div>
                                    <div className={Classes.formStepOptional}>Please complete the Institutional Certification and submit it to your Institution for approval.
                                    </div>
                                    <div className={Classes.formStepBarLeft}></div>
                                    <div className={Classes.formStepBarRight}></div>
                                </div>
                                <div className={Classes.formStep}>
                                    <div className={stage == 4 ? Classes.formStepActiveCircle : Classes.formStepCircle}><span></span></div>
                                    <div id='cvb-requestAdvanceCompute-trainingCert' className={Classes.formStepTitle}>Training Certificate</div>
                                    <div id='cvb-requestAdvanceCompute-uploadTrainingCert' className={Classes.formStepOptional}>Please complete the mandatory training and upload certificate.</div>
                                    <div className={Classes.formStepBarLeft}></div>
                                    <div className={Classes.formStepBarRight}></div>
                                </div>
                            </div>
                        </Grid>
                    </div>

                </Grid>
                {
                    stage == 1 && (currentZone === 'Zone 2')  && !isLoading &&
                    <React.Fragment>
                        <div className={Classes.impTitleHeaderContainer}>
                            <p className={Classes.alertTitle}>Important </p>
                            <p>Fill in the missing information about you. Please note that the changes made here will also be updated in your profile.</p>
                        </div>
                        <UserOnboard
                                zone2Request
                                editProfile
                            />
                    </React.Fragment>
                   
                }
                {
                    stage == 2 && !isLoading &&
                    <DataUsePolicy
                        sigRef={sigRef}
                        userDetails={userDetails}
                        agreedDataUsePolicy={agreedDataUsePolicy}
                        setAgreedDataUsePolicy={setAgreedDataUsePolicy}
                        researcherSign={researcherSign}
                        setResearcherSign={setResearcherSign}
                        setStreetAddress={setStreetAddress}
                        streetAddress={streetAddress}
                        state={state}
                        setState={setState}
                        city={city}
                        zipCode={zipCode}
                        setCity={setCity}
                        setZipCode={setZipCode}
                        country={country}
                        setCountry={setCountry}
                        getCurrentDate={getCurrentDate}
                        isFormSubmitted={isFormSubmitted}
                        setIsFormSubmitted={setIsFormSubmitted} 
                        onSigningDateChange={onSigningDateChange}
                        signingDate={signingDate}
                        currentZone={currentZone}
                        setIsAgreeSelected={setIsAgreeSelected}
                        isAgreeSelected={isAgreeSelected}
                        />
                }
                {
                    stage == 3 && !isLoading &&
                    <InstitutionalCertification
                        institution={institution}
                        setInstitution={setInstitution}
                        signatureEmail={signatureEmail}
                        setSignatureEmail={setSignatureEmail}
                        streetAddressOfInst={streetAddressOfInst}
                        setStreetAddressOfInst={setStreetAddressOfInst}
                        cityOfInst={cityOfInst}
                        setCityOfInst={setCityOfInst}
                        stateOfInst={stateOfInst}
                        setStateOfInst={setStateOfInst}
                        zipCodeOfInst={zipCodeOfInst}
                        setZipCodeOfInst={setZipCodeOfInst}
                        countryOfInst={countryOfInst}
                        setCountryOfInst={setCountryOfInst}
                        certificateName={certificateName}
                        setCertificateName={setCertificateName}
                        adminName={adminName}
                        setAdminName={setAdminName}
                        isFormSubmitted={isFormSubmitted}
                        setIsFormSubmitted={setIsFormSubmitted}
                        currentZone={currentZone} />
                }
                {
                    stage == 4 && !isLoading &&
                    <div className={Classes.trainingAndCertWrapper}>
                        <TrainingAndCertificate 
                            fromDUP
                            onCertificateChange={onCertificateChange}
                            backHandle={backHandle}
                            currentZone={currentZone}
                        />
                    </div>
                }
                {
                    isLoading && <CustomLoader />
                }
            </div>
            <div className={Classes.agreementFormButtons}>
                <button className={`no-outlined-button ${Classes.cancelButtonSpace}`} onClick={handleCancel} >
                    Cancel
                </button>
                <button disabled={isFormUpdating} onClick={saveAsDraft} className={`medium-size-button solid-button ${Classes.requestAccessButton}`} >
                    Save
                </button>
                {
                    (stage == 1 || stage == 2 || stage == 3) ?
                        <button disabled={isFormUpdating} onClick={handleNext} className={`medium-size-button solid-button ${Classes.requestAccessButton}`} >
                            Next
                        </button> :
                        <button disabled={isFormUpdating} id='submit-zone2-request-button' className={`medium-size-button solid-button ${Classes.requestAccessButton}`} onClick={handleSubmit} >
                            Submit
                        </button>
                }
            </div>
        </div>
    )
}
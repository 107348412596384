import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import MuiDialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid'
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ImgClose from '../../../assets/images/close.png'
import CustomClasses from './index.module.css'
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownArrow from '../../../assets/icons/down-arrow.svg'
import ImgCases from '../../../assets/icons/cases.svg'
import ImgProjects from '../../../assets/icons/projects.svg'
import ImgBackWard from '../../../assets/icons/backwards.svg'
import ImgForward from '../../../assets/icons/forward.svg'
import ImgFastForward from '../../../assets/icons/fast_farward.svg'
import ImgBackWardGrey from '../../../assets/icons/grey_first.svg'
import ImgForwardGrey from '../../../assets/icons/grey_forward.svg'
import ImgFastForwardGrey from '../../../assets/icons/grey_last.svg'
import ImgFastBackGrey from '../../../assets/icons/grey_back.svg'
import ImgReverse from '../../../assets/icons/reverse_forward.svg'
import v4 from 'uuid/v4'
import {EMPTY_EXPORT_TABLE_NAME_ERROR } from '../../../constants/strings'
import Modal from '../../commons/modal'
import ErrorText from '../../controls/error-text'
import OutlinedInput from '@mui/material/OutlinedInput'
import TablePagination from '../../commons/table-pagination'
import { sendGAEvents } from '../../../api/generic-api';
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        maxHeight: '80vh',
        zIndex: 9999999
    },
    closeButton: {
        position: 'absolute',
        right: 40,
        top: 40,
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, totalCount, ...other } = props;
    return (
        <MuiDialogTitle>
            <div className={CustomClasses.subHeaderContent}>
                <div className={CustomClasses.leftPanelContent}>
                    <h2 id='cvb-tablePopup-casesCount' className={CustomClasses.casesTableTitle}> Cases ({totalCount})</h2>
                </div>
                <div className={CustomClasses.rightPanelContent}>
                    <IconButton
                        id='cvb-tablePopup-closeButton'
                        aria-label="close"
                        className={CustomClasses.closeButton}
                        onClick={() => onClose(false)}
                        size="large">
                        <img id='cvb-tablePopup-closeIcon' className={CustomClasses.closeIcon} src={ImgClose} alt="close" />
                    </IconButton>
                </div>
            </div>

        </MuiDialogTitle>
    );
});

const Dialog = withStyles(theme => ({
    container: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        backdropFilter: 'blur(2px)'
    },
    paper: {
        borderRadius: 10,
        padding: '14px 14px 0px 14px'
    }
}))(MuiDialog);

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


function TablePopup(props) {
    const { handleClose, open, dialogProps, data = [],
         totalCount = 0,
        excludeFromExploration, customizeTabElements,
        projectsCount = 0, studyCount = 0 } = props;
    const { modalTitle, modalContent } = dialogProps || {};
    const [pageNumber, setPageNumber] = useState(0)
    const [openExportTable, setOpenExportTable] = useState(false);
    const [exportTableName, setExportTableName] = useState('');
    const [emptyExportTableNameError, setEmptyExportTableNameError] = useState(false);

    useEffect(() => {
        if (open) {
            setPageNumber(0)
        }
    }, [open, excludeFromExploration]);


    const getTableHeaders = () => {
        if (!data || !Array.isArray(data) || data.length == 0)
            return []
        let keys;
        let selectedCustomizedElements = customizeTabElements?.filter(item => item.isSelected) || [];
        const isCustomizeUsed = selectedCustomizedElements.length > 0 &&
            selectedCustomizedElements.length < customizeTabElements.length;
        if (isCustomizeUsed) {
            keys = Object.keys(data[0]).filter(item =>
                selectedCustomizedElements.find(innerItem => innerItem.name === item))
        }
        else {
            keys = Object.keys(data[0]);
        }
        if (keys && keys.length > 0) {
            return keys.map(item => {
                if (item !== "__typename") {
                    const splitWords = item.toString().split("_");
                    const capitalizedWords = splitWords.map(item => item.charAt(0).toUpperCase() + item.slice(1))
                    return { value: capitalizedWords.join(" "), key: item }
                }
                return null;
            }).filter(item => item)
        }
        return [];
    }

    const tableHeaders = getTableHeaders();
    const maxCount = totalCount ? Math.ceil(totalCount / 10) : 0

    const sliceInitialIndex = pageNumber == 0 ? 0 : pageNumber * 10;
    const sliceEndIndex = (pageNumber + 1) * 10;

    const displayData = data && data.length > 0 && data.slice(sliceInitialIndex, sliceEndIndex) || []

    const onOpenExportTable = () => {
        setOpenExportTable(true)
    }
    const closeExportTable = () => {
        setOpenExportTable(false)
        setExportTableName('')
    }
    const downloadCSVFromJson = (filename, arrayOfJson) => {
        // convert JSON to CSV
        const replacer = (key, value) => value === null ? '' : value 
        const header = Object.keys(arrayOfJson[0])
        let csv = arrayOfJson.map(row => header.map(fieldName => 
        JSON.stringify(row[fieldName], replacer)).join(','))
        csv.unshift(header.join(','))
        csv = csv.join('\r\n')
      
        // Create link and download
        var link = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    const getProcessedData = () => {
        let processedData = []
        if(props.data?.length > 0){
            let keys = Object.keys(props.data[1] || {})
            for(let item of props.data){
                let newObj = {}
                for(let key of keys){
                    if(Array.isArray(item[key])){
                        newObj[key] = item[key].join(", ");
                    }else{
                        newObj[key] = item[key];
                    }
                }
                processedData.push(newObj)
            }
        }
        return processedData;
       
    }

    const exploreTableButton = () => {
        try {
            if (!exportTableName) {
                setEmptyExportTableNameError(true);
                return;
            }
            if (props.data?.length > 0) {
                downloadCSVFromJson(exportTableName + '-' + new Date() + ".csv", getProcessedData())
            }
            setOpenExportTable(false)
        }
        catch (error) {
            console.log(error)
        }
    }
    const onExportTableNameChange = e => {
        setExportTableName(e.target.value);
        setEmptyExportTableNameError(false);
    }

    const onPositiveButtonClick = () => {
        sendGAEvents('download_explore_data_table', 'explore_data_table', 'download explore table');
        { exploreTableButton() }
        setExportTableName('')
    }
  
    const EXPORT_TABLE_MODAL = {
        modalTitle: "Save Table",
        modalContent: "",
        positiveButtonText: 'Download Table',
        negativeButtonText: "Cancel",
        positiveButtonAction: onPositiveButtonClick,
        negativeButtonAction: closeExportTable
    }
    const setTablePage = (number) => {
        setPageNumber(number);
    }
    return (
        <div >
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
                <DialogTitle id onClose={handleClose} totalCount={totalCount} >
                    {modalTitle}
                </DialogTitle>
                <DialogContent dividers>
                    {modalContent}
                    {props.children}
                    <div>
                        <div className={CustomClasses.subHeaderContent}>
                            <div className={CustomClasses.leftPanelContent}>
                                {props.excludeFromExplorationComponent}
                            </div>
                            <div className={CustomClasses.rightPanelContent}>
                                <ul>
                                    {
                                        !props?.exportButtonHidden ?
                                            <li> <button id='cvb-tablePopup-exportTable-button' className={`medium-size-button solid-button ${CustomClasses.requestAccessButton}`} onClick={onOpenExportTable}>
                                                Download Table
                                                </button>
                                            </li> : null
                                    }
                                    <li id='cvb-tablePopup-projectCount'><img src={ImgProjects} alt="Projects Icon" /> {projectsCount} Projects</li>
                                    <li id='cvb-tablePopup-studyCount'><img src={ImgCases} alt="Projects Icon" /> {studyCount} Cases</li>
                                </ul>
                            </div>
                        </div>
                        <div className={CustomClasses.contentMainContentArea}>
                            <div className={CustomClasses.contentContainer}>
                                <div className={CustomClasses.tableHeaders}>
                                    <div className={CustomClasses.leftPanelContent}>
                                        <div className={CustomClasses.customizeHeader}>
                                            {props.customizeComponent}
                                            <p id='cvb-tablePopup-showingCount' className={CustomClasses.resultsCount}>
                                                Showing {(pageNumber * 10) + 1} - {(pageNumber * 10) + 10 > totalCount ? totalCount : (pageNumber * 10) + 10} of {totalCount}
                                            </p>
                                        </div>
                                    </div>
                                    <div className={CustomClasses.rightPanelContent}>
                                    </div>
                                </div>
                                {
                                    totalCount > 0 &&
                                    <TableContainer
                                        id='cvb-tablePopup-tableContainer'
                                        component={Paper}
                                        elevation={0}
                                        className={CustomClasses.tableContainer}>
                                        <Table  aria-label="simple table" className={CustomClasses.table}>
                                            <TableHead>
                                                <TableRow>
                                                    {
                                                        tableHeaders && tableHeaders.length > 0 &&
                                                        tableHeaders.map(item => {
                                                            return <TableCell id={`cvb-tablePopup-tableHeaders-${item?.value}`} className={CustomClasses.stickyHeader} key={v4()}>{item?.value}</TableCell>
                                                        })
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    displayData && displayData.length > 0 &&
                                                    displayData.map(item => {
                                                        return <TableRow key={v4()}>
                                                            {
                                                                tableHeaders.map(tableHeader => {
                                                                    return <TableCell id={`cvb-tablePopup-tableCell-${item[tableHeader?.key]}`} key={v4()} align="left">
                                                                        {item[tableHeader?.key]}
                                                                    </TableCell>
                                                                })
                                                            }
                                                        </TableRow>
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                                {(displayData && displayData.length) ? <div className={CustomClasses.tablePagination}>
                                <TablePagination
                                    maxCount={maxCount}
                                    page={pageNumber}
                                    setPage={setTablePage}
                                />
                                </div> : null}

                            </div>
                        </div>
                    </div>
                </DialogContent>
                <Modal
                    open={openExportTable}
                    handleClose={closeExportTable}
                    dialogProps={EXPORT_TABLE_MODAL}
                    disableTitleDivider
                >
                    <div className={CustomClasses.saveCohortContentContainer}>
                        <div className={CustomClasses.cohortInputContainer}>
                            <label htmlFor='exportTable'>Table</label>
                            <OutlinedInput
                                value={exportTableName}
                                onChange={onExportTableNameChange}
                                placeholder='Enter table name'
                                id='cvb-tablePopup-exportTableName-input'
                            />
                            {emptyExportTableNameError && <ErrorText errorMessage={EMPTY_EXPORT_TABLE_NAME_ERROR} />}
                        </div>
                    </div>
                </Modal>
            </Dialog>
        </div>
    );
}

const areEqual = (prevProps, nextProps) => {
    return prevProps.open === nextProps.open &&
        prevProps.data === nextProps.data &&
        prevProps.exploreFilters === nextProps.exploreFilters &&
        prevProps.totalCount === nextProps.totalCount &&
        prevProps.excludeFromExploration === nextProps.excludeFromExploration &&
        prevProps.customizeTabElements === nextProps.customizeTabElements
}


const memorizedComponent = React.memo(TablePopup, areEqual);

export default memorizedComponent;
/**
 *  External Imports
 */
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { func, bool, arrayOf, string, instanceOf } from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Popover from "@mui/material/Popover";
import Select from "@mui/material/Select";

/**
 *  Internal Imports
 */
import styles from "./index.module.css";
import { minLengthCheckForSearch } from "./../../../utils/input-validations";
import ErrorText from "../../controls/error-text";
import {
    selectFieldMenuUseStyles,
    selectFieldUseStyles,
    popoverUseStyles,
    VALIDATION_ERROR_KEYS,
    DEFAULT_ERROR_STATE,
    DEFAULT_FIELD_STATE,
    isDefaultFieldVal,
    getRenderVal,
} from "./CommunityGlobalSearchPopover.helper";
import { getRequestParams } from "../../../utils/query-params.helper";
import CustomLoader from "../../commons/custom-loader";

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 *
 */
const CommunityGlobalSearchPopover = (props) => {
    const {
        globalSearchPopoverOpen,
        molecularClasses,
        therapeuticAreas,
        globalSearchAnchorElement,
    } = props;
    const { togglePopover, fetchData } = props;

    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [inputValidationErr, setInputValidationErr] =
        useState(DEFAULT_ERROR_STATE);
    const [isLoading, setIsLoading] = useState(false);

    const [nameField, setNameField] = useState(DEFAULT_FIELD_STATE);
    const [institutionField, setInstitutionField] =
        useState(DEFAULT_FIELD_STATE);
    const [therapeuticAreaField, setTherapeuticAreaField] =
        useState(DEFAULT_FIELD_STATE);
    const [molecularClassField, setMolecularClassField] =
        useState(DEFAULT_FIELD_STATE);

    const popoverStyles = popoverUseStyles();
    const selectFieldStyles = selectFieldUseStyles();
    const selectFieldMenuStyles = selectFieldMenuUseStyles();
    const searchResultsCancelTokenSrc = useRef();

    const selectFieldMenuProps = {
        classes: {
            paper: selectFieldMenuStyles.root,
        },
    };

    const onNameFieldChange = (event) => {
        setNameField(event.target.value);
        validateFormOnInput(
            event.target.value,
            VALIDATION_ERROR_KEYS.NAME_ERROR
        );
    };
    const onInstitutionFieldChange = (event) => {
        setInstitutionField(event.target.value);
        validateFormOnInput(
            event.target.value,
            VALIDATION_ERROR_KEYS.INSTITUTION_ERROR
        );
    };
    const onTherapeuticAreaFieldChange = (event) => {
        setTherapeuticAreaField(event.target.value);
        validateFormOnSelect();
    };
    const onMolecularClassFieldChange = (event) => {
        setMolecularClassField(event.target.value);
        validateFormOnSelect();
    };

    const validateFormOnSelect = () => {
        if (!nameField && !institutionField) {
            setIsFormInvalid(false);
        }
    };

    const validateFormOnInput = (searchText, errorKey) => {
        const validField = minLengthCheckForSearch(searchText);
        const nextErrorState = {
            ...inputValidationErr,
            [errorKey]: !validField,
        };
        setInputValidationErr(nextErrorState);

        const hasAnyErrors = Object.keys(nextErrorState).every(
            (key) => !nextErrorState[key]
        );
        setIsFormInvalid(!hasAnyErrors);
    };

    const onModalClose = () => {
        resetToken();
        togglePopover(false);
    };

    const resetToken = () => {
        if (searchResultsCancelTokenSrc.current) {
            searchResultsCancelTokenSrc.current.cancel();
        }
    };

    const onResetBtnClick = () => resetForm();

    const onSearchBtnClick = async (e) => {
        e.preventDefault();
        if (isFormInvalid) {
            return;
        }
        setIsLoading(true);

        resetToken();

        searchResultsCancelTokenSrc.current = axios.CancelToken.source();

        await fetchData(
            searchResultsCancelTokenSrc.current.token,
            getRequestParams({
                name: nameField,
                org: institutionField,
                therapeutic: therapeuticAreaField,
                molecular: molecularClassField,
            })
        );

        onModalClose();
    };

    const resetForm = () => {
        setNameField(DEFAULT_FIELD_STATE);
        setInstitutionField(DEFAULT_FIELD_STATE);
        setTherapeuticAreaField(DEFAULT_FIELD_STATE);
        setMolecularClassField(DEFAULT_FIELD_STATE);
        setInputValidationErr(DEFAULT_ERROR_STATE);
        setIsFormInvalid(true);
    };

    useEffect(() => {
        const areAllFieldsEmpty =
            !nameField &&
            !institutionField &&
            !therapeuticAreaField &&
            !molecularClassField;
        if (areAllFieldsEmpty) {
            setIsFormInvalid(true);
        }
    }, [
        nameField,
        institutionField,
        therapeuticAreaField,
        molecularClassField,
    ]);

    useEffect(() => {
        if (globalSearchPopoverOpen) {
            setIsLoading(false);
        }
    }, [globalSearchPopoverOpen]);

    useEffect(() => {
        return () => resetToken();
    }, []);

    return (
        <Popover data-testid="globalSearchPopover"
            className={`${popoverStyles.root} ${styles.communityGlobalSearchPopover}`}
            open={globalSearchPopoverOpen}
            anchorEl={globalSearchAnchorElement}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            onClose={onModalClose}>
            <form
                className={styles.popoverContainer}
                onSubmit={(e) => onSearchBtnClick(e)}>
                <div className={styles.popoverFieldsArea}>
                    <div className={styles.popoverFieldArea}>
                        <div data-testid="nameLabel" className={styles.popoverFieldLabel}>Name</div>

                        <input data-testid="nameField"
                            className={styles.popoverFieldInput}
                            type="text"
                            placeholder="Enter Name"
                            value={nameField}
                            onChange={onNameFieldChange}
                        />
                        {inputValidationErr.nameFieldErr && (
                            <ErrorText data-testid="error" errorMessage="Enter at least 3 characters" />
                        )}
                    </div>

                    <div className={styles.popoverFieldArea}>
                        <div className={styles.popoverFieldLabel} data-testid="organizationLabel">
                            Organization
                        </div>

                        <input data-testid="organizationField"
                            className={styles.popoverFieldInput}
                            type="text"
                            placeholder="Enter Organization"
                            value={institutionField}
                            onChange={onInstitutionFieldChange}
                        />
                        {inputValidationErr.institutionFieldErr && (
                            <ErrorText errorMessage="Enter at least 3 characters" />
                        )}
                    </div>

                    <div className={styles.popoverFieldArea}>
                        <div className={styles.popoverFieldLabel} data-testid="areaLabel">
                            Therapeutic Area of Interest
                        </div>

                        <Select data-testid="areaField"
                            className={
                                isDefaultFieldVal(therapeuticAreaField)
                                    ? `${selectFieldStyles.root} ${styles.popoverFieldSelectPlaceholder}`
                                    : selectFieldStyles.root
                            }
                            displayEmpty={true}
                            value={therapeuticAreaField}
                            renderValue={getRenderVal(
                                "Select Therapeutic Area"
                            )}
                            input={<OutlinedInput />}
                            MenuProps={selectFieldMenuProps}
                            onChange={onTherapeuticAreaFieldChange}>
                            {therapeuticAreas.map((value, id) => (
                                <MenuItem data-testid="areaFieldOption" key={id} value={value}>
                                    <p data-testid="areaFieldOptionText"
                                        className={
                                            styles.popoverFieldSelectItem
                                        }>
                                        {value}
                                    </p>
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                    <div className={styles.popoverFieldArea}>
                        <div className={styles.popoverFieldLabel} data-testid="classLabel">
                            Molecular Class
                        </div>

                        <Select data-testid="classField"
                            className={
                                isDefaultFieldVal(molecularClassField)
                                    ? `${selectFieldStyles.root} ${styles.popoverFieldSelectPlaceholder}`
                                    : selectFieldStyles.root
                            }
                            displayEmpty={true}
                            value={molecularClassField}
                            renderValue={getRenderVal("Select Molecular Class")}
                            input={<OutlinedInput />}
                            MenuProps={selectFieldMenuProps}
                            onChange={onMolecularClassFieldChange}>
                            {molecularClasses.map((value, id) => (
                                <MenuItem data-testid="classFieldOption" key={id} value={value}>
                                    <p data-testid="classFieldOptionText"
                                        className={
                                            styles.popoverFieldSelectItem
                                        }>
                                        {value}
                                    </p>
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>

                <div className={styles.popoverActionsArea}>
                    {!isLoading ? (
                        <>
                            <button
                                data-testid="resetBtn"
                                type="button"
                                className={styles.resetBtn}
                                onClick={onResetBtnClick}>
                                Reset
                            </button>

                            <button
                                data-testid="searchBtn"
                                type="submit"
                                className={
                                    isFormInvalid
                                        ? `${styles.searchBtn} cvb-button-disabled`
                                        : styles.searchBtn
                                }>
                                Search
                            </button>
                        </>
                    ) : (
                        <div data-testid="loader" className={styles.loaderWrap}>
                            <CustomLoader componentLoader />
                        </div>
                    )}
                </div>
            </form>
        </Popover>
    );
};

/**
 *  Properties
 */
CommunityGlobalSearchPopover.propTypes = {
    globalSearchPopoverOpen: bool.isRequired,
    therapeuticAreas: arrayOf(string).isRequired,
    molecularClasses: arrayOf(string).isRequired,
    globalSearchAnchorElement: instanceOf(Element),
    togglePopover: func.isRequired,
    fetchData: func.isRequired,
};

/**
 *  Exports
 */
export default CommunityGlobalSearchPopover;

import InstitutionalCertificationStage from './InstitutionalCertificationStage'
import { connect } from 'react-redux'
import { setInstituteSignature } from '../../../redux/actions/dcaActions'
import { setInstitutionalCertAdminName } from '../../../redux/actions/institutionalCertActions'

const mapStateToProps = state => {
    return {
        adminName: state.institutionalCert.adminName,
        ppiInstituteName: state.ppi.ppiInstituteName,
        ppiInstituteStreetAddress: state.ppi.ppiInstituteStreetAddress,
        ppiInstituteCity: state.ppi.ppiInstituteCity,
        ppiInstituteState: state.ppi.ppiInstituteState,
        ppiInstituteZipCode: state.ppi.ppiInstituteZipCode,
        ppiInstituteCountry: state.ppi.ppiInstituteCountry,
        currentDCAFormStage: state.dca.currentDCAFormStage,
        instituteSignature: state.dca.instituteSignature,
        contributorSigningName: state.dca.contributorSigningName,
        contributorSigningTitle: state.dca.contributorSigningTitle,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setInstitutionalCertAdminName: async (value) => await dispatch(setInstitutionalCertAdminName(value)),
        setInstituteSignature: async (value) => await dispatch(setInstituteSignature(value))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InstitutionalCertificationStage)
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import { CohortInfoStudies } from '.';
import CohortStatistics from './tabs/CohortStatistics';
import CohortAttribute from './tabs/CohortCriteria';
import {
  getStudiesForCohort,
  getStatisticsForVariables, getStudyParticipantsForCohort,
} from '../../../service/CohortsService';
import ExportWorkspaceModal from '../../commons/export-workspace-modal/ExportWorkspaceModal';
import { sendGAEvents } from '../../../api/generic-api';
import { exportCohortToWorkspace } from '../../../api/saved-search-api';
import { get } from 'lodash';
import { deleteCohort } from '../../../api/analysis-api';

const [TAB_INFO, TAB_STATS, TAB_CRITERIA] = [0, 1, 2];
const useStyles = makeStyles((theme) => ({
  tabs: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
  },
  header: {
    borderBottom: '1px solid #D0D5DD',
    backgroundColor: 'white',
  },
  footer: {
    backgroundColor: 'white',
    boxShadow:
      '0px -4px 8px rgba(16, 24, 40, 0.1), 0px -2px 4px rgba(16, 24, 40, 0.06)',
    '& .MuiButton-root': {
      flex: 1,
      padding: '10px 18px',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`cohortInfo-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const CohortInfo = ({
  cohortInfo,
  cohortStudies,
  closeCohortInfo,
  allWorkspaces,
  updateCohortsRefresh,
  updateSelectedCohorts,
  ...props
}) => {
  const styles = useStyles();
  const [selectedTab, setSelectedTab] = React.useState(TAB_INFO);
  const [loading, setLoading] = React.useState(false);
  const [exportWorkspaceModal, setExportWorkspaceModal] = React.useState(false);
  const [removeCohortModal, setRemoveCohortModal] = React.useState(false);
  const [studies, setStudies] = React.useState([]);
  const [participants, setParticipants] = React.useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = React.useState(
    () => allWorkspaces[0]
  );

  React.useEffect(() => {
    getStudiesForCohort(cohortInfo).then(setStudies);
  }, [cohortInfo]);

  React.useEffect(() => {
    getStudyParticipantsForCohort(cohortInfo).then(setParticipants);
  }, [cohortInfo]);


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const closeExportWorkspaceModal = () => {
    setExportWorkspaceModal(false);
  };

  const closeRemoveCohortModal = () => {
    setRemoveCohortModal(false);
  };

  const getCohortName = () => {
    if (cohortInfo?.cohort_name?.length > 20) {
      return cohortInfo?.cohort_name.toString().substring(0, 20) + '...';
    }
    return cohortInfo?.cohort_name || 'Cohort';
  };

  const isUserCohort = (cohort) => {
    return cohort.cohort_type !== 'project';
  };

  const openRemoveCohortModal = () => {
    const cohortName = getCohortName();
    sendGAEvents(
      'remove_cohort',
      'remove_cohort',
      'selected remove cohort option',
      { cohort_name: cohortName }
    );
    setRemoveCohortModal(true);
  };

  const openModal = () => {
    const cohortName = getCohortName();
    sendGAEvents(
      'export_cohort',
      'export_cohort',
      'selected export cohort option',
      { cohort_name: cohortName }
    );
    if (allWorkspaces.length <= 1) {
      onExportCohortToWorkspace();
    } else {
      setExportWorkspaceModal(true);
    }
  };

  const updateSelectedWorkspace = (workspaceId) => {
    setSelectedWorkspace(workspaceId);
  };

  const onExportCohortToWorkspace = async () => {
    try {
      const cohortId = get(cohortInfo, 'uuid', '');
      if (selectedWorkspace) {
        setLoading(true);
        const body = {
          cohort_id: cohortId,
          workspace_id: selectedWorkspace,
        };
        const res = await exportCohortToWorkspace(body);
        props.updateSnackBar(
          `Successfully Exported Cohort to Workspace`,
          'Success'
        );
        closeExportWorkspaceModal();
        setLoading(false);
      } else {
        setExportWorkspaceModal(true);
      }
    } catch (error) {
      props.updateSnackBar(`Failed to Export Cohort to Workspace`, 'Error');
      closeExportWorkspaceModal();
      setLoading(false);
    }
  };
  const onRemoveCohort = async () => {
    try {
      const cohortName = getCohortName();
      const cohortId = get(cohortInfo, 'uuid', '');
      sendGAEvents(
        'remove_cohort',
        'remove_cohort',
        'selected remove cohort option',
        { cohort_name: cohortName }
      );
      setLoading(true);
      await deleteCohort(cohortId);
      closeCohortInfo();
      closeRemoveCohortModal();
      updateSelectedCohorts({ [cohortId]: null });
      props.updateSnackBar('Successfully deleted', 'Success');
      
      
      updateCohortsRefresh();
      
    } catch (error) {
      props.updateSnackBar('Unable to delete the cohort', 'Error');
    }
  };

  const EXPORT_WORKSPACE_MODAL_PROPS = {
    modalTitle: 'Export To Workspace',
    positiveButtonText: 'Export To Workspace',
    negativeButtonText: 'Cancel',
    positiveButtonAction: onExportCohortToWorkspace,
    negativeButtonAction: closeExportWorkspaceModal,
    popupButtonDisable: loading,
  };

  return (
    <>
      <Box
        position="relative"
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        overflow="hidden"
        minWidth="40vw"
        maxWidth="600px"
      >
        <Box
          p={2}
          position="sticky"
          top={0}
          zIndex={1}
          className={styles.header}
        >
          <Box position="relative" mb={4}>
            <Typography variant="h2">{cohortInfo.cohort_name}</Typography>
            {studies.length ? <Typography variant="subtitle1">
              From {studies.map((s) => s.studyName).join(', ')}
            </Typography>: null}
            <IconButton
              onClick={closeCohortInfo}
              sx={{ position: 'absolute', right: 0, top: 0 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="buttons"
            className={styles.tabs}
            centered
            indicatorColor="transparent"
          >
            <Tab label="Project details" value={TAB_INFO} />
            <Tab label="Cohort Statistics" value={TAB_STATS} />
            <Tab label="Cohort Criteria" value={TAB_CRITERIA} />
          </Tabs>
        </Box>

        <Box flex={1} overflow="auto">
          <Box p={2}>
            <TabPanel value={selectedTab} index={TAB_INFO}>
              {(studies && participants) && <CohortInfoStudies studies={studies} participants={participants} />}
            </TabPanel>
            <TabPanel value={selectedTab} index={TAB_STATS}>
              <CohortStatistics cohort={cohortInfo} />
            </TabPanel>
            <TabPanel value={selectedTab} index={TAB_CRITERIA}>
              <CohortAttribute cohort={cohortInfo} />
            </TabPanel>
          </Box>
        </Box>
        <Box
          p={2}
          className={styles.footer}
          justifyContent="center"
          display="flex"
          width="100%"
          bottom={0}
          zIndex={1}
        >
          {isUserCohort(cohortInfo) && (
              <>
            <Button
              variant="outlined"
              color="error"
              onClick={openRemoveCohortModal}
            >
              Remove Cohort
            </Button>
            <Button variant="contained" onClick={openModal}>
              Export to Workspace
            </Button>
              </>
          )}
        </Box>
      </Box>
      <ExportWorkspaceModal
        open={exportWorkspaceModal}
        handleClose={closeExportWorkspaceModal}
        loading={loading}
        modalProps={EXPORT_WORKSPACE_MODAL_PROPS}
        allWorkspaces={allWorkspaces}
        updateSelectedWorkspace={updateSelectedWorkspace}
      />
      <Dialog
        open={removeCohortModal}
        onClose={closeRemoveCohortModal}
        maxWidth="xs"
      >
        <DialogTitle>Remove cohort?</DialogTitle>
        <DialogContent>
          Are you sure you want to remove this cohort? This action cannot be
          undone.
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeRemoveCohortModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={onRemoveCohort}
          >
            Remove Cohort
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CohortInfo;

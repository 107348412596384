
export const formatStudyFiltersData = (response = {}) => {

    let modifiedResponse = {
        "_aggregation":{
            "study":{
                "zone": {
                    "histogram": []
                },
                "diagnosis": {
                    "histogram": []
                },
                "data_type": {
                    "histogram": []
                }
            }
        }
    }
    modifiedResponse._aggregation.study.zone.histogram = modifiedResponse._aggregation.study.zone.histogram.concat(response?.data?._aggregation?.accessible?.zone?.histogram)
    modifiedResponse._aggregation.study.diagnosis.histogram = modifiedResponse._aggregation.study.diagnosis.histogram.concat(response?.data?._aggregation?.accessible?.diagnosis?.histogram)
    modifiedResponse._aggregation.study.data_type.histogram = modifiedResponse._aggregation.study.data_type.histogram.concat(response?.data?._aggregation?.accessible?.data_type?.histogram)

    modifiedResponse._aggregation.study.zone.histogram = modifiedResponse._aggregation.study.zone.histogram.concat(response?.data?._aggregation?.unaccessible?.zone?.histogram)
    modifiedResponse._aggregation.study.diagnosis.histogram = modifiedResponse._aggregation.study.diagnosis.histogram.concat(response?.data?._aggregation?.unaccessible?.diagnosis?.histogram)
    modifiedResponse._aggregation.study.data_type.histogram = modifiedResponse._aggregation.study.data_type.histogram.concat(response?.data?._aggregation?.unaccessible?.data_type?.histogram)

    if(modifiedResponse._aggregation.study.zone.histogram.length){
        let output = [];

        modifiedResponse._aggregation.study.zone.histogram.forEach(function(item) {
            let existing = output.filter(function(v, i) {
                return v.key == item.key;
            });
            if (existing.length) {
                let existingIndex = output.indexOf(existing[0]);
                if(item.termsFields?.length){
                    output[existingIndex].termsFields[0].terms = output[existingIndex].termsFields[0].terms.concat(item.termsFields[0].terms);
                }
            } else {
                if (typeof item.termsFields == 'string')
                item.termsFields = [item.termsFields];
                output.push(item);
            }
        });
        modifiedResponse._aggregation.study.zone.histogram = output;
    }

    if(modifiedResponse._aggregation.study.diagnosis.histogram.length){
        let output = [];
        modifiedResponse._aggregation.study.diagnosis.histogram.forEach(function(item) {
        let existing = output.filter(function(v, i) {
            return v.key == item.key;
        });
        if (existing.length) {
            let existingIndex = output.indexOf(existing[0]);
            if(item.termsFields?.length){
                output[existingIndex].termsFields[0].terms = output[existingIndex].termsFields[0].terms.concat(item.termsFields[0].terms);
            }
        } else {
            if (typeof item.termsFields == 'string')
            item.termsFields = [item.termsFields];
            output.push(item);
        }
        });
        modifiedResponse._aggregation.study.diagnosis.histogram = output;
    }

    if(modifiedResponse._aggregation.study.data_type.histogram.length){
        let output = [];
        modifiedResponse._aggregation.study.data_type.histogram.forEach(function(item) {
        let existing = output.filter(function(v, i) {
            return v.key == item.key;
        });
        if (existing.length) {
            let existingIndex = output.indexOf(existing[0]);
            if(item.termsFields?.length){
                output[existingIndex].termsFields[0].terms = output[existingIndex].termsFields[0].terms.concat(item.termsFields[0].terms);
            }
        } else {
            if (typeof item.termsFields == 'string')
            item.termsFields = [item.termsFields];
            output.push(item);
        }
        });
        modifiedResponse._aggregation.study.data_type.histogram = output;
    }
    

    return modifiedResponse;
}

import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { makeStyles } from '@mui/styles';
import { FilterList } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { allSchemaDict } from '../filter-schema';
import { ALL_OPERATORS } from '../constants';


const useStyles = makeStyles((theme) => ({
  dot: {
    border: '1px solid #E7EAF2',
    padding: theme.spacing(1),
    margin: '0px 0px 0px  -14px',
  },
  noBorder: {
    border: 'none',
    padding: '4px 2px',
    margin: '0px 0px 0px  -14px',
    marginRight: '14px',
  },
  line: {
    width: '1px',
    backgroundColor: '#E7EAF2',
  },
  content: {
    marginTop: '-4px',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
  },
  operator: {
    minWidth: theme.spacing(7),
  },
}));

const CohortAttribute = ({ cohort }) => {
  const classes = useStyles();
  if (!cohort) {
    return ``;
  }
  const attributes = mapCohortQuery(cohort.cohort_query);

  return (
    <React.Fragment>
      <Timeline
        position="right"
        sx={{
          '& .MuiTimelineItem-root:before': {
            flex: 0,
            paddingLeft: '0px !important',
          },
        }}
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot
              variant="outlined"
              color="grey"
              className={classes.noBorder}
            >
              <FilterList />
            </TimelineDot>
            <TimelineConnector className={classes.line} />
          </TimelineSeparator>
          <TimelineContent className={classes.content}>
            ‘{cohort.cohort_name}’ includes all subjects matching{' '}
            {attributes.AND
              ? '  all the following attributes'
              : 'any of the following attributes'}
            :
          </TimelineContent>
        </TimelineItem>

        {attributes.blocks
          .filter((item) => item)
          .map((attribute, index) => {
            return (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot
                    variant="outlined"
                    color="grey"
                    className={classes.dot}
                  >
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ width: '12px', height: '12px' }}
                    />
                  </TimelineDot>
                  {index < attributes.blocks.length - 1 && (
                    <TimelineConnector className={classes.line} />
                  )}
                </TimelineSeparator>
                <TimelineContent className={classes.content}>
                  <AttributeBlock content={attribute} />
                </TimelineContent>
              </TimelineItem>
            );
          })}
      </Timeline>
    </React.Fragment>
  );
};

export default CohortAttribute;

const AttributeBlock = ({ content }) => {
  const classes = useStyles();
  const hasChildren = !!content?.blocks && content.blocks.length;
  return (
    <Stack
      role="menu"
      spacing={2}
      sx={{
        '& .css-e53awj-MuiStack-root>:not(style)+:not(style)': {
          marginLeft: 0,
        },
      }}
    >
      <Stack direction="row" spacing={2}>
        <Typography className={classes.operator}>{content.operator}</Typography>
        {!!content.text && (
          <Typography
            component="span"
            color={content.isPryTextColor ? 'text.primary' : 'primary'}
            sx={{ marginLeft: 0 }}
          >
            {content.text}
          </Typography>
        )}
      </Stack>
      {hasChildren && (
        <Stack pl={7} spacing={2}>
          {content.blocks.map((child, index) => (
            <AttributeBlock key={index} content={child} />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

// .css-e53awj-MuiStack-root>:not(style)+:not(style) {

AttributeBlock.proTypes = {
  content: PropTypes.shape({
    text: PropTypes.string.isRequired,
    operator: PropTypes.string.isRequired,
    isPryTextColor: PropTypes.bool,
    blocks: PropTypes.arrayOf(
      PropTypes.exact({
        content: PropTypes.shape({
          text: PropTypes.string.isRequired,
          operator: PropTypes.string.isRequired,
          isPryTextColor: PropTypes.bool,
        }),
      })
    ),
  }),
};

function mapCohortQuery(query, parentOp, index = 0) {
  const op = index === 0 ? 'When' : query.AND ? 'And' : 'Or';
  if (query.AND || query.OR) {
    return { operator: parentOp, ...mapCohortQueryFirstLevel(query) };
  } else if (query.IN) {
    return { operator: parentOp, text: cohortQueryInText(query) };
  } else {
    for (const operator of ALL_OPERATORS) {
      if (query[operator.value]) {
        return { 
          operator: parentOp, 
          text: `${formatVal(Object.keys(query[operator.value])[0])} ${operator.name} ${formatVal(Object.values(query[operator.value])[0])}` };
      }
    }
    return {
      operator: parentOp,
      text: `[cannot display]`,
    };
  } 
}

function cohortQueryInText(query) {
  if (Object.values(query.IN)[0].length > 1) {
    return `${formatVal(Object.keys(query.IN)[0])} in ${
      Object.values(query.IN)[0].map(v => formatVal(v)).join(', ')
    }`;
  } else {
    return `${formatVal(Object.keys(query.IN)[0])} = ${
      formatVal(Object.values(query.IN)[0][0])
    }`;
  }
}

function formatNum(number) {
  return new Intl.NumberFormat('en-IN', { maximumFractionDigits: 2 }).format(
    number
  );
}

function formatSpacedVal(val) {
  const spacedVar = val.split('_').join(' ');

  return spacedVar;
}

function formatVal(val) {
  if (typeof val === 'number') {
    return formatNum(val);
  } else if (typeof val === 'string') {
    if(allSchemaDict[val]) {
      return allSchemaDict[val].title;
    }
    return formatSpacedVal(val);
  } else {
    return val;
  }
}

function mapCohortQueryFirstLevel(query) {
  const lte = query.AND?.find((o) => o.lte)?.lte;
  const gte = query.AND?.find((o) => o.gte)?.gte;
  if (!(lte && gte)) {
    return {
      text: `${query.AND ? 'All' : 'Any'} of the following are true`,
      blocks: (query.AND ? query.AND : query.OR).map((e, i) =>
        mapCohortQuery(e, i === 0 ? 'When' : query.AND ? 'And' : 'Or', i)
      ),
      isPryTextColor: true,
    };
  } else {
    return {
      text: `${formatVal(Object.keys(gte)[0])} between ${formatVal(
        Object.values(gte)[0]
      )} and ${formatVal(Object.values(lte)[0])}`,
    };
  }
}

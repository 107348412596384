/**
 *  External Imports
 */
import React from "react";
import { string } from "prop-types";
import iconConnection from "../../../assets/icons/icon-connection.svg";

/**
 *  Internal Imports
 */

import styles from "./index.module.css";

const CommunityEmptyState = (props) => {
    const { text = "connections" } = props;

    return (
        <div className={styles.emptyState} data-testid="emptyState">
            <img
                data-testid="emptyIcon"
                className={styles.emptyStateIcon}
                src={iconConnection}
                alt="Icon Connection"
            />
            <p className={styles.emptyStateText} data-testid="emptyText">
                You don't have any {text} yet
            </p>
        </div>
    );
};

CommunityEmptyState.propTypes = {
    text: string.isRequired,
};

export default CommunityEmptyState;

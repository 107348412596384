import React, { useState, useEffect } from 'react'
import Classes from './index.module.css'
import get from 'lodash/get'
import Grid from '@mui/material/Grid'
import {
    getWorkspaceStatus, getWorkspaceDataDayWise, getEachWorkspaceDataDayWise
} from '../../../api/workspace-api/workspace-api';
import {
    WORKSPACE_STATUS_STARTED,
    WORKSPACE_STATUS_STARTING,
    WORKSPACE_STATUS_STOPPING,
    WORKSPACE_STATUS_STOPPED,
    PREPAID_CARD_INFO_TEXT
} from '../../../constants/strings';
import BackButtonArrow from '../../../assets/icons/left_arrow.png'
import isEmpty from 'lodash/isEmpty';
import WorkspaceUsageDetailsCard from '../../commons/keyword-detail-card'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import CalenderIcon from '../../../assets/icons/calender-icon.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DollarSignIcon from '../../../assets/icons/dollar-sign.svg'
import WorkspaceIcon from '../../../assets/icons/workspaces-icon.svg'
import { Router, navigate } from '@reach/router';
import WorkspaceUsageSummaryGraphContainers from '../workspace-usage-summary-graph-containers'
import { findIndex, filter, find, includes, cloneDeep, groupBy, meanBy, sumBy, isDate } from 'lodash'
import moment from 'moment';
import { WORKSPACE_COST_SUMMARY_LEGEND_KEYS } from '../../../constants/strings'
import { getWorkspaceTransactions, getUserBalance, getWorkspaceBalance } from '../../../api/account-api'
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    startOfYear,
    endOfYear,
    addYears,
    startOfQuarter
} from '../../../utils/date-formatter';
import TransactionTable from '../transaction-table';
const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    startOfLastFullYearDay: startOfDay(addDays(new Date(), -365)),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    startOfQuarter: startOfQuarter(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1))
};

const sideBarOptions = () => {
    const customDateObjects = [
        {
            label: "Today",
            range: () => ({
                startDate: defineds.startOfToday,
                endDate: defineds.endOfToday
            })
        },
        {
            label: "Yesterday",
            range: () => ({
                startDate: defineds.startOfYesterday,
                endDate: defineds.endOfYesterday
            })
        },
        {
            label: "Last Week",
            range: () => ({
                startDate: defineds.startOfLastWeek,
                endDate: defineds.endOfLastWeek
            })
        },
        {
            label: "Last Month",
            range: () => ({
                startDate: defineds.startOfLastMonth,
                endDate: defineds.endOfLastMonth
            })
        },
        {
            label: "Month to date",
            range: () => ({
                startDate: defineds.startOfMonth,
                endDate: defineds.endOfToday
            })
        },
        {
            label: "Quarter to date ",
            range: () => ({
                startDate: defineds.startOfQuarter,
                endDate: defineds.endOfToday
            })
        },
        {
            label: "Year to date",
            range: () => ({
                startDate: defineds.startOfYear,
                endDate: defineds.endOfToday
            })
        }
    ];

    return customDateObjects;
};

const WorkspaceDetails = props => {
    const workspaceId = get(props, 'id', '');
    const [workspaceDetails, setWorkspaceDetails] = useState(get(props, 'location.state.workspaceDetails', ''));
    const [workspaceStatus, setWorkspaceStatus] = useState(get(props, 'location.state.status', ''));
    const workspaceStatusBackground = workspaceStatus === WORKSPACE_STATUS_STARTED || workspaceStatus === WORKSPACE_STATUS_STARTING || workspaceStatus === WORKSPACE_STATUS_STOPPING
        ? Classes.activeWorkspaceBackground
        : Classes.stoppedWorkspaceBackground;

    const [selectionDateRage, setSelectedDateRange] = useState({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfToday,
        key: 'selection'
    });
    const [openCalender, setOpenCalender] = useState(false);
    const [selectedCalenderInput, setSelectedCalenderInput] = useState('');
    const [dataWithReferenceLine, setDataWithReferenceLine] = useState([]);
    const [monthlyUsage, setMonthlyUsage] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [transactionData, setTransactionData] = useState([])
    const [accountBalance, setAccountBalance] = useState(0);
    const [recentAppliedDateRange, setRecentAppliedDateRange] = useState({
        startDate: startOfMonth(new Date()),
        endDate: endOfDay(new Date()),
        key: 'selection'
      })

    const getCurrentUserAccountBalance = async () => {
        try {
            const res = await getWorkspaceBalance(workspaceId);
            setAccountBalance(res?.account_balance || 0);
        } catch (e) {
            console.log('error in account balance get', e)
        }
    }
useEffect(() => {
    getCurrentUserAccountBalance()
}, [props.workspace, props.dashboard, workspaceId])

    const getAllTransactionsData = async () => {
        try {
            if (!isEmpty(workspaceId)) {
                //API CALL 
                const res = await getWorkspaceTransactions(workspaceId);
                setTransactionData(res);
            }
        } catch (e) {
            setTransactionData([]);
            console.log(e);
        }

    }

    useEffect(() => {
        getAllTransactionsData()
    }, [workspaceId])

    const onCalenderSelect = (data) => {
        setSelectedDateRange(data.selection);
    }
    //hides calender
    const onCalenderCancelButtonClick = () => {
        setOpenCalender(false);
        setSelectedDateRange(recentAppliedDateRange)
    }
    //selected range
    const onResetDateRangeSelection = () => {
        setSelectedDateRange({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfToday,
            key: 'selection',
        });
        setRecentAppliedDateRange({
            startDate: startOfMonth(new Date()),
            endDate: endOfDay(new Date()),
            key: 'selection'
          });
    }

    const getWorkspacesDataForTimeRangeChart = async () => {
        try {
            const start_date = moment(defineds.startOfMonth).format('YYYY-MM-DD')
            const end_date = moment(defineds.endOfToday).format('YYYY-MM-DD')
            const allWorkspacesData = await getEachWorkspaceDataDayWise(workspaceId, start_date, end_date);
            //API CALL
            setDataWithReferenceLine(allWorkspacesData?.workspace?.day_wise_workspace_cost_reponse);
            setMonthlyUsage(allWorkspacesData?.this_month_usage);
            if (!workspaceDetails) {
                setWorkspaceDetails(allWorkspacesData?.workspace?.details);
            }
        } catch (error) {
            console.log('error in getting time chart data')
        } finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        window.scroll(0, 0)
        getWorkspacesDataForTimeRangeChart();
    }, []);

    //makes API call
    const onApplyDateRangeButtonClick = async () => {
        //check if start date and date is actual date in selection
        const isValidStartDate = isDate(selectionDateRage?.startDate || null);
        const isValidEndDate = isDate(selectionDateRage?.endDate || null);
        if (isValidStartDate && isValidEndDate) {
            //make API call
            const allWorkspacesData = await getEachWorkspaceDataDayWise(workspaceId, moment(selectionDateRage?.startDate).format('YYYY-MM-DD'), moment(selectionDateRage?.endDate).format('YYYY-MM-DD'));
            //API CALL
            setDataWithReferenceLine(allWorkspacesData?.workspace?.day_wise_workspace_cost_reponse);
            onCalenderCancelButtonClick()
        };
        setRecentAppliedDateRange(selectionDateRage);
    }

    const sideBar = sideBarOptions();

    const staticRanges = [
        ...createStaticRanges(sideBar)
    ];

    useEffect(() => {
        const calenderInputLabel = document.querySelector('.rdrStaticRangeSelected')?.firstChild?.textContent || `${moment(selectionDateRage?.startDate).format('MM-DD-YYYY')} - ${moment(selectionDateRage?.endDate).format('MM-DD-YYYY')}`;
        setSelectedCalenderInput(calenderInputLabel);

    }, [selectionDateRage])


    const getCurrentWorkspaceStatus = async () => {
        try {
            if (!isEmpty(workspaceId)) {
                const res = await getWorkspaceStatus(workspaceId);
                const status = get(res, 'status', '');
                setWorkspaceStatus(status);
            }
        } catch (error) {
            console.log('error in getting status');
            setWorkspaceStatus('');
        }
    }

    useEffect(() => {
        if (isEmpty(workspaceStatus)) {
            getCurrentWorkspaceStatus()
        }
    }, [workspaceStatus])

    const goBack = () => {
        const resource = props?.dashboard ? '/dashboard' : '/explore/tools/workspace';
        navigate(resource);
    }

    const getFormattedLegends = (data, value, index) => {
        let labelToShow = '';
        const labelIndex = findIndex(WORKSPACE_COST_SUMMARY_LEGEND_KEYS, (key) => data.includes(key));
        labelIndex > -1 ? labelToShow = WORKSPACE_COST_SUMMARY_LEGEND_KEYS[labelIndex] : labelToShow = '';
        return labelToShow;
    }
    const getXAxisFormattedLabel = (data, value, index) => {
        const isDateValue = new Date(data) instanceof Date && !isNaN(new Date(data));
        if (isDateValue) {
            return moment(data).format('MMMM Do');
        }
        return data;
    }
    const filterTransactionDataOnDate = (startDate, endDate) =>{
        const filteredTransactionData = filter(transactionData?.transactions, (data)=> moment(data?.date).isBetween(moment(startDate), moment(endDate)));
       return filteredTransactionData;
    }
    return (
        <React.Fragment>
            <div className={Classes.container}>
                <div>
                    <p id='cvb-adminProfile-backButton' onClick={goBack} className={Classes.backButton}>
                        <span><img alt='back button arrow' src={BackButtonArrow} /></span>
                        <p id='cvb-adminProfile-backButtonText' className={Classes.backButtonText}>
                            {
                                props?.dashboard ? "Back To Dashboard" : props?.workspace ? "Back To Workspaces" : ''
                            }
                        </p>
                    </p>
                </div>
                {
                    workspaceStatus &&
                    <div style={{ fontSize: '32px' }}>
                        {workspaceId}
                        <p id='cvb-workspaceFileDirectory-type-text' className={`${Classes.workspaceStatus} ${workspaceStatusBackground}`} >{workspaceStatus}</p>
                    </div>
                }

                {
                    props?.workspace &&
                    <div className={Classes.mainContainer}>
                        <p className={Classes.title}>Workspace Cost Summary</p>
                        <div style={{ position: 'relative' }}>
                            <Grid container spacing={2}>
                                <Grid item lg={4} md={4} xs={4} className={Classes.usageDetailsCardWrapper}>
                                    <WorkspaceUsageDetailsCard
                                        value={`$${monthlyUsage?.toFixed(2) || ''}`}
                                        activeWorkspace={''}
                                        backgroundColor='#DFECD1'
                                        topBorderColor='#4C8811'
                                        cardIcon={DollarSignIcon}
                                        title='This Month’s Usage'
                                        dollarSign={true}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} xs={4} className={Classes.usageDetailsCardWrapper}>
                                    <WorkspaceUsageDetailsCard
                                        value={`$${accountBalance?.toFixed(2) || ''}`}
                                        activeWorkspace={''}
                                        backgroundColor='#D6ECF5'
                                        topBorderColor='#72B7E5'
                                        cardIcon={DollarSignIcon}
                                        title='Prepaid balance'
                                        dollarSign={true}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                }

                {
                    props?.dashboard &&
                    <div>
                        <Grid item xs={12} md={12} lg={12} >
                                <Grid container spacing={2}>
                                    <Grid item lg={4} md={4} xs={4} className={Classes.usageDetailsCardWrapper}>
                                        <WorkspaceUsageDetailsCard
                                            value={`$${monthlyUsage?.toFixed(2) || ''}`}
                                            activeWorkspace={''}
                                            backgroundColor='#DFECD1'
                                            topBorderColor='#4C8811'
                                            cardIcon={DollarSignIcon}
                                            title='This Month’s Usage'
                                            dollarSign={true}
                                        />
                                    </Grid>
                                    <Grid item lg={4} md={4} xs={4} className={Classes.usageDetailsCardWrapper}>
                                        <WorkspaceUsageDetailsCard
                                            value={`$${accountBalance?.toFixed(2) || ''}`}
                                            activeWorkspace={''}
                                            backgroundColor='#D6ECF5'
                                            topBorderColor='#72B7E5'
                                            cardIcon={DollarSignIcon}
                                            title='Prepaid balance'
                                            dollarSign={true}
                                            showInfoIcon
                                            infoTooltipTitle={PREPAID_CARD_INFO_TEXT}
                                        />
                                    </Grid>
                                </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} className={Classes.dashboardCostContainer}>
                            <div className={Classes.detailsContainer}>
                                <div className={Classes.item}>
                                    <div className={Classes.workspaceDetailsTitle}>Owner</div>
                                    <div className={Classes.workspaceDetailsValue}>{workspaceDetails?.owner || '-'}</div>
                                    <div className={Classes.workspaceDetailsEmail}>{workspaceDetails?.email || '-'}</div>
                                </div>
                                <div className={Classes.item}>
                                    <div className={Classes.workspaceDetailsTitle}>Shared Users</div>
                                    <div className={Classes.workspaceDetailsValue}>{workspaceDetails?.shared_users || '-'}</div>
                                </div>
                                <div className={Classes.item}>
                                    <div className={Classes.workspaceDetailsTitle}>Usage (This Month)</div>
                                    <div className={Classes.workspaceDetailsValue}>{monthlyUsage ? '$' + monthlyUsage?.toFixed(2) : '-'}</div>
                                </div>
                                <div className={Classes.item}>
                                    <div className={Classes.workspaceDetailsTitle}>Workspace Last Login</div>
                                    <div className={Classes.workspaceDetailsValue}>{workspaceDetails?.last_login ? `${moment(workspaceDetails?.last_login).format("YYYY/MM/DD")} at ${moment(workspaceDetails?.last_login).format("HH:mm A")}` : '-'}</div>
                                </div>
                                <div className={Classes.item}>
                                    <div className={Classes.workspaceDetailsTitle}>Provision Date</div>
                                    <div className={Classes.workspaceDetailsValue}>{workspaceDetails?.commissioned_date ? moment(workspaceDetails?.commissioned_date).format("YYYY/MM/DD") : '-'}</div>
                                </div>
                                <div className={Classes.item}>
                                    <div className={Classes.workspaceDetailsTitle}>Deprovision Date</div>
                                    <div className={Classes.workspaceDetailsValue}>{workspaceDetails?.decommissioned_date ? moment(workspaceDetails?.decommissioned_date).format("YYYY/MM/DD") : '-'}</div>
                                </div>
                            </div>

                        </Grid>

                    </div>
                }


                <Grid item xs={12} md={12} lg={12} className={Classes.graphContainerBox}>
                    <div style={{ position: 'relative', zIndex: '999', right: '60px' }}>
                        <div className={Classes.datePickerMainContainer}>
                            <label className={Classes.calenderInputLabel}>Date</label>
                            <div onClick={() => setOpenCalender(!openCalender)} className={Classes.calenderContainer}>
                                <span className={Classes.text}>{selectedCalenderInput}</span>
                                <span className={Classes.searchIcon}>
                                    <img src={CalenderIcon} alt='search icon' />
                                </span>
                            </div>
                            {openCalender ?
                                <ClickAwayListener onClickAway={() => setOpenCalender(false)}>
                                    <div className={Classes.datePickerContainer}>
                                        <DateRangePicker
                                            ranges={[selectionDateRage]}
                                            onChange={onCalenderSelect}
                                            staticRanges={staticRanges}
                                            inputRanges={[]}
                                            months={2}
                                            direction="horizontal"
                                        />
                                        <div className={Classes.applyResetButtonContainer}>
                                            <button onClick={onResetDateRangeSelection} className={`medium-size-button no-outlined-button ${Classes.reset}`}>Reset to default</button>
                                            <button onClick={onCalenderCancelButtonClick} className={`medium-size-button no-outlined-button ${Classes.cancel}`}>Cancel</button>
                                            <button onClick={onApplyDateRangeButtonClick} className={`small-size-button solid-button ${Classes.apply}`}>Apply</button>
                                        </div>
                                    </div>
                                </ClickAwayListener> : null}
                        </div>
                    </div>

                    <WorkspaceUsageSummaryGraphContainers
                        title='Billing History'
                        subDetails=''
                        canCustomize={false}
                        data={dataWithReferenceLine}
                        yAxisLabel='Usage in $'
                        withReferenceLine={true}
                        dataKeys={[{ key: 'data_transfer_cost', color: '#1F1768' }, { key: 'compute_cost', color: '#7487C0' }, { key: 'storage_cost', color: '#5f93c2' }, { key: 'other_cost', color: '#91BEE4' }]}
                        tooltipKeys={['storage_cost', 'compute_cost', 'data_transfer_cost', 'other_cost']}
                        xAxisDataKey='date'
                        tooltipTitleKey='date'
                        getFormattedLegends={getFormattedLegends}
                        xAxisLabelTickFormatter={getXAxisFormattedLabel}
                        isLoading={isLoading}
                    />
                    {!isEmpty(transactionData) &&
                        <div className={Classes.transactionTableContainer}>
                            <TransactionTable
                                transactionData={transactionData}
                                filterTransactionDataOnDate={filterTransactionDataOnDate}
                            />
                        </div>
                    }
                </Grid>
            </div>


        </React.Fragment>
    )
}

export default WorkspaceDetails;
import ViewServiceAgreement from './ViewServiceAgreement'
import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        userDetails: state.user.userDetails
    }
}


export default connect(mapStateToProps)(ViewServiceAgreement)
import React from 'react'
import Grid from '@mui/material/Grid'
import Classes from './index.module.css'
import 'react-alice-carousel/lib/alice-carousel.css'
import v4 from 'uuid/v4'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";



const CarouselContent = props => {

    return (
        
        <div key={props.key} >
            <Grid item xs={12}>
                <img className={Classes.eventImage} src={props.image} alt='signin left thumbnail' />
                <p className={Classes.eventImageTitle}>{props.eventTitle}</p>
                <p className={Classes.eventImageDate}>{props.eventDate}</p>
                <p className={Classes.eventDescriptions}>{props.eventDesc}</p>
            </Grid>
            <button className={`medium-size-button transparent-button ${Classes.viewMoreButton}`}
                onClick={props.onViewMoreClick}>View more</button>
        </div>
       
    )
}

const LeftContainerForLogin = (props) => {
    const onViewMoreClick = (eventLink) => {
        window.open(eventLink, "_blank")
    }
    const settings = {
        dots:true,
        autoplaySpeed:6000,
        autoplay:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: true,
        variableWidth: false,
        arrows:false
      };

    return (
        <Grid container justifyContent='center' spacing={0} alignItems='center' item xs={6} className={[Classes.signinLeftContainer, props.className].join(' ')}>
            <Grid direction='column' spacing={2} container item xs={5} className={Classes.carousalBox}>
                {
                    props.newsArticles?.length > 0 &&
                   <Slider {...settings}>
                        {
                            props.newsArticles?.length > 0 &&
                            props.newsArticles.map(eventData => {
                                return <CarouselContent key={v4()} image={eventData['image']}
                                    eventDate={eventData['date']}
                                    eventTitle={eventData['heading']}
                                    eventDesc={eventData['preview']}
                                    onViewMoreClick={() => onViewMoreClick(eventData['link'])} />
                            })
                        }
                   </Slider>
                }
            </Grid>
        </Grid>
    );
}

export default LeftContainerForLogin;
import ProjectGallery from './ProjectGallery';
import { connect } from 'react-redux';
import { updateSnackBar } from '../../../redux/actions/globalActions';
import {
  getPaginatedStudyGalleryDetails,
  getStudyDetailById,
  getStudyGalleryData,
  getStudyGalleryFilters,
  getTotalCount,
  updateSelectedProjects,
} from '../../../redux/actions/studyGalleryActions';
import { getStudyGalleryItemDetails } from '../../../api/graphql/study-gallery';
import projectPrimaryInstitutionalForm from '../../controls/project-primary-institutional-form';

const mapStateToProps = (state) => {
  return {
    filters: state.studyGallery.filters,
    projectGalleryData: state.studyGallery.studyGalleryData,
    initialProjectGalleryData: state.studyGallery.initialStudyGalleryData,
    projectGalleryDetails: state.studyGallery.studyGalleryDetails,
    totalCount: state.studyGallery.totalCount,
    userDetails: state.user.userDetails,
    selectedProjects: state.studyGallery.selectedProjects,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProjectGalleryFilters: async () =>
    await dispatch(getStudyGalleryFilters()),
  getProjectGalleryData: async (filterValues, offset, resultsPerRequest) =>
    await dispatch(
      getStudyGalleryData(filterValues, offset, resultsPerRequest)
    ),
  getTotalCount: async (filterValues) =>
    await dispatch(getTotalCount(filterValues)),
  getProjectDetailById: async (projectId) =>
    await dispatch(getStudyDetailById(projectId)),
  getProjectGalleryItemDetails: async (projectIds) =>
    await getStudyGalleryItemDetails(projectIds),
  getPaginatedProjectGalleryDetails: async (projectIds) =>
    await getPaginatedStudyGalleryDetails(projectIds),
  updateSnackBar: async (message, type) =>
    await dispatch(updateSnackBar(message, type)),
  updateSelectedProjectsinGallery: async (projects) =>
    await dispatch(updateSelectedProjects(projects)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectGallery);

import {
    UPDATE_STUDY_GALLERY_FILTERS,
    UPDATE_STUDY_GALLERY_DATA,
    UPDATE_STUDY_GALLERY_COUNT,
    UPDATE_INITIAL_STUDY_GALLERY_FILTER,
    UPDATE_STUDY_GALLERY_DETAILS,
    UPDATE_SELECTED_PROJECTS,
} from '../action-types'

const initialState = {
    filters: {},
    totalCount: 0,
    studyGalleryData: null,
    initialStudyGalleryData: null,
    studyGalleryDetails: [],
    selectedProjects: {},
}

const studyGalleryReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_STUDY_GALLERY_FILTERS: {
            return {
                ...state,
                filters: action.payload
            }
        }
        case UPDATE_INITIAL_STUDY_GALLERY_FILTER: {
            return {
                ...state,
                initialStudyGalleryData: action.payload
            }
        }
        case UPDATE_STUDY_GALLERY_DATA: {
            return {
                ...state,
                studyGalleryData: action.payload
            }
        }
        case UPDATE_STUDY_GALLERY_COUNT: {
            return {
                ...state,
                totalCount: action.payload
            }
        }
        case UPDATE_STUDY_GALLERY_DETAILS: {
            return {
                ...state,
                studyGalleryDetails: action.payload
            }
        }
        case UPDATE_SELECTED_PROJECTS: {
            return {
                ...state,
                selectedProjects: action.payload
            }
        }
        default: return state;
    }
}

export default studyGalleryReducer;
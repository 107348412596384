import AnalyzeAttributesSelectionCard from './AnalyzeAttributesSelectionCard'
import { connect } from 'react-redux'
import { setXAxisAnalysisAttribute, setYAxisAnalysisAttribute, setChartTypeAnalysisAttribute } from '../../../redux/actions/analysisDataActions'
const mapStateToProps = state => {
    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setXAxisAnalysisAttribute: (value) => dispatch(setXAxisAnalysisAttribute(value)),
        setYAxisAnalysisAttribute: (value) => dispatch(setYAxisAnalysisAttribute(value)),
        setChartTypeAnalysisAttribute: (value) => dispatch(setChartTypeAnalysisAttribute(value)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AnalyzeAttributesSelectionCard);

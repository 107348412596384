import { connect } from 'react-redux'
import WorkspaceOwnerShipChange from './WorkspaceOwnerShipChange'
import { updateSnackBar } from '../../../redux/actions/globalActions';

const mapStateToProps = state => ({
  userDetails: state.user.userDetails
})

const mapDispatchToProps = dispatch => {
  return {
    updateSnackBar: async (message, type) => await dispatch(updateSnackBar(message, type)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceOwnerShipChange);
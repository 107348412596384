import { SET_DIF_PI_STREET_ADDRESS, SET_DIF_PI_COUNTRY,
    SET_DIF_PI_STATE, SET_DIF_PI_CITY, SET_DIF_PI_MUNICIPALITY, SET_DIF_PI_ZIP_CODE 
    , SET_DIF_PI_FIRST_NAME, SET_DIF_PI_LAST_NAME, SET_DIF_PI_PHONE_NUMBER, SET_DIF_PI_DEPARTMENT_NAME, 
    SET_DIF_PI_EMAIL, SET_PRIMARY_INVESTIGATION_FORM_DATA, RESET_PRIMARY_INVESTIGATION_FORM_DATA

} from '../action-types'
import get from 'lodash/get'
const initialState = {
   difPIFirstName: '',
   difPILastName: '',
   difPIPhoneNumber: '',
   difPIEmail: '',
   difPIDepartmentName: '',
   difPIStreetAddress: '',
   difPICountry: '',
   difPIState: '',
   difPICity: '',
   difPIMunicipality: '',
   difPIZipCode: ''
}

const difPIReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DIF_PI_STREET_ADDRESS: {
            return {
                ...state,
                difPIStreetAddress: action.payload
            }
        }
        case SET_DIF_PI_COUNTRY: {
            return {
                ...state,
                difPICountry: action.payload
            }
        }
        case SET_DIF_PI_STATE: {
            return {
                ...state,
                difPIState: action.payload
            }
        }
        case SET_DIF_PI_CITY: {
            return {
                ...state,
                difPICity: action.payload
            }
        }
        case SET_DIF_PI_MUNICIPALITY: {
            return {
                ...state,
                difPIMunicipality: action.payload
            }
        }
        case SET_DIF_PI_ZIP_CODE: {
            return {
                ...state,
                difPIZipCode: action.payload
            }
        }
        case SET_DIF_PI_FIRST_NAME: {
            return {
                ...state,
                difPIFirstName: action.payload
            }
        }
        case SET_DIF_PI_LAST_NAME: {
            return {
                ...state,
                difPILastName: action.payload
            }
        }
        case SET_DIF_PI_PHONE_NUMBER: {
            return {
                ...state,
                difPIPhoneNumber: action.payload
            }
        }
        case SET_DIF_PI_DEPARTMENT_NAME: {
            return {
                ...state,
                difPIDepartmentName: action.payload
            }
        }
        case SET_DIF_PI_EMAIL: {
            return {
                ...state,
                difPIEmail: action.payload
            }
        }
        case SET_PRIMARY_INVESTIGATION_FORM_DATA : {
            const formData = action.payload;
            return {
                difPIFirstName: get(formData, 'first_name', ''),
                difPILastName: get(formData, 'last_name', ''),
                difPIPhoneNumber: get(formData, 'phone', ''),
                difPIEmail: get(formData, 'institutional_email', ''),
                difPIDepartmentName: get(formData, 'department', ''),
                difPIStreetAddress: get(formData, 'street_address', ''),
                difPICountry: get(formData, 'country', ''),
                difPIState: get(formData, 'state', ''),
                difPICity: get(formData, 'city', ''),
                difPIMunicipality: get(formData, 'municipality', ''),
                difPIZipCode: get(formData, 'zip_code', '')
            }
        }
        case RESET_PRIMARY_INVESTIGATION_FORM_DATA : {
            return {
                difPIFirstName: '',
                difPILastName: '',
                difPIPhoneNumber: '',
                difPIEmail: '',
                difPIDepartmentName: '',
                difPIStreetAddress: '',
                difPICountry: '',
                difPIState: '',
                difPICity: '',
                difPIMunicipality: '',
                difPIZipCode: ''
            }    
        }
        default: return state
    }
}

export default difPIReducer;
/**
 *  External Imports
 */
import React from "react";
import Grid from "@mui/material/Grid";

/**
 *  Internal Imports
 */
import CommunityWidgetRecommendedConnections from "../../controls/community-widget-recommended-connections";
import styles from "./index.module.css";

const CommunityConnectionRequests = (props) => {

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <Grid container item xs={8}>
                    <div className={styles.main}>{props.children}</div>
                </Grid>
                <Grid
                    container
                    item
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    xs={4}>
                    <div className={styles.aside}>
                        <CommunityWidgetRecommendedConnections data-testid="widgetRecommendedConnections" />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default CommunityConnectionRequests;

import { SET_INSTITUTIONAL_CERT_ADMIN_NAME, SET_INSTITUTIONAL_CERT_ADMIN_SIGNATURE } from '../action-types'

const initialState = {
    adminName: '',
    institutionalCertificationSignature: null
}

const institutionalCertReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INSTITUTIONAL_CERT_ADMIN_NAME: {
            return {
                ...state,
                adminName: action.payload
            }
        }
        case SET_INSTITUTIONAL_CERT_ADMIN_SIGNATURE: {
            return {
                ...state,
                institutionalCertificationSignature: action.payload
            }
        }
        default: return state
    }
}

export default institutionalCertReducer;
import { Box, Paper, Typography, Stack, Divider, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { map, max, round } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text,
  Rectangle,
} from 'recharts';
import { vars } from '../../../../../../assets/variables';
import { hexColorVariants } from '../../../../../../utils/generatePalette';
import { useColors } from '../../../../../../utils/hooks';
import { BarChartLoader } from '../../../skeletons/ChartLoaders';

const { subHeaderColor, primaryTextColor, borderColor } = vars;
const rangeFractions = 4;
const maxChars = 12;
const COLOR_TYPE = 'hex';

function defaultTermsField(field) {
  return [
    {
      field,
      terms: [
        {
          key: null,
          count: 0,
        },
      ],
    },
  ];
}

function dataMinMax(data, xKey) {
  const firstEl = data[0].termsFields[0].terms[0][xKey];
  const minMax = { min: firstEl, max: firstEl };
  for (const el of data) {
    for (const v of el.termsFields[0].terms) {
      if (v[xKey] > minMax.max) {
        minMax.max = parseFloat(v[xKey]);
      }
      if (v[xKey] < minMax.min) {
        minMax.min = parseFloat(v[xKey]);
      }
    }
  }
  return minMax;
}

const useStyles = makeStyles(() => ({
  divider: {
    borderColor: borderColor,
  },
}));

function chunkLabel(label) {
 
  // The label must be split into chunks of maxChars characters only on spaces. 
  // Chunks more than 3 are disregarded
  return label.split(/[- ]+/g).reduce((acc, curr) => {
    if (acc.length === 0) {
      return [curr];
    }
    const lastChunk = acc[acc.length - 1];
    if (lastChunk.length + curr.length + 1 <= maxChars) {
      acc[acc.length - 1] = `${lastChunk} ${curr}`;
    } else {
      // if the chunk is too long add ... at the end to sum up to maxChars, 
      // otherwise the chunk is too short and it will be added to the next chunk
      if (curr.length >= maxChars) {
        curr = `${curr.slice(0, maxChars-3)}...`;
      }
      acc.push(curr);
    }
    return acc;
  }, []).splice(0, 2);

}

// TODO implement custom x-axis label
function ProjectBarChart(props) {
  const {
    chartData,
    xKey,
    valKey,
    xLabel,
    isStacked,
    valBins,
    xUnit,
    defaultCount,
    field,
    loading = false,
    showBarValue = false,
  } = props;
  const colors = useColors(COLOR_TYPE);
  const styles = useStyles();

  function formatData() {
    return chartData.map((d) => {
      if (d.termsFields === null || undefined) {
        return {
          ...d,
          termsFields: defaultTermsField(field),
        };
      }
      return d;
    });
  }

  const [data, setData] = React.useState(() => formatData());

  const isNumbers = isStacked && !isNaN(data[0]?.termsFields[0]?.terms[0].key); // Assumption: if first is number, all are numbers
  let dataRange = null;

  if (isNumbers) {
    dataRange = dataMinMax(data, xKey, valKey);
  }

  function allKeys() {
    let keys = new Set();

    if (isNumbers) {
      if (valBins) return [...Object.values(valBins), 'N/A'];

      const step = (dataRange.max - dataRange.min) / rangeFractions;
      const ranges = [...Array(rangeFractions)].map(
        (x, i) => dataRange.min + i * step
      );
      return ranges.map(
        (r, i) =>
          `${r}-${i < ranges.length - 1 ? ranges[i + 1] : dataRange.max}`
      );
    } else {
      for (const v of data) {
        keys = new Set([
          ...Object.values(v.termsFields[0].terms).map((term) => term.key),
          ...keys,
        ]);
      }
    }
    keys.add('N/A');

    return Array.from(keys);
  }

  function rangedValueToKey(v) {
    if (valBins) {
      return (
        valBins[Object.keys(valBins).find((k) => parseInt(k) > parseInt(v))] ??
        'N/A'
      );
    }
    const step = (dataRange.max - dataRange.min) / rangeFractions;
    return keys[Math.floor(v / step)] || keys[keys.length - 1];
  }

  function keyValues(dataElement) {
    let tot = 0;
    const obj = { 'N/A': 0 };
    if (!isNumbers) {
      for (const term of dataElement.termsFields[0].terms) {
        tot += term[valKey];
        obj[term[xKey]] = defaultCount ?? term[valKey];
      }
    } else {
      const keys = isStacked && allKeys(valKey);
      for (const rangeValue of keys) {
        obj[rangeValue] = 0;
      }
      for (const term of dataElement.termsFields[0].terms) {
        if (!term[xKey]) {
          continue; // skip "null" key terms
        }
        tot += term[valKey];
        obj[rangedValueToKey(term[xKey])] += term[valKey];
      }
    }
    if (tot < dataElement.count) {
      obj['N/A'] += dataElement.count - tot;
    }
    return obj;
  }

  function keyToLabel(histElement) {
    if (Array.isArray(histElement[xKey])) {
      return histElement[xKey].join('-') + xUnit;
    }
    return histElement[xKey].replace('_', ' ');
  }

  function flattenData() {
    const sorted = data?.[0]?.[valKey] // assume value exist if it exist in first prop
      ? data.sort((d, d1) => d1[valKey] - d[valKey])
      : data;

    const flatten = sorted.map((d) => {
      let value;
      if (Array.isArray(d.termsFields[0].terms)) {
        value = d.termsFields[0].terms.reduce((prev, acc) => {
          if (!isNaN(acc[valKey])) {
            prev += acc[valKey];
          }

          return prev;
        }, 0);
      }

      return {
        [valKey]:
          defaultCount && Array.isArray(d.termsFields[0].terms)
            ? d.termsFields[0].terms.length * defaultCount
            : d[valKey] ?? value,
        field: d.termsFields[0].field,
        terms: d.termsFields[0].terms,
        [xKey]: keyToLabel(d),
        ...keyValues(d),
      };
    });

    return flatten;
  }

  const refinedData = flattenData(data, xKey, valKey);

  const keys = isStacked && allKeys(valKey);

  useEffect(() => {
    const formattedData = formatData();
    setData(formattedData);
  }, [chartData]);

  var tooltip;
  //Custom Tooltip popper
  const CustomTooltip = ({ active, payload }) => {
    if (!active || !tooltip) return null;
    for (const bar of payload)
      if (bar.dataKey === tooltip) {
        return (
          <Paper
            elevation={1}
            sx={(theme) => ({
              paddingY: '0.5rem',
              paddingX: theme.spacing(1),
              border: 'none',
              maxWidth: '350px',
              zIndex: 16,
              color: subHeaderColor,
            })}
          >
            {isStacked && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <div
                  style={{
                    backgroundColor: `${bar.color}`,
                    width: '0.75rem',
                    height: '0.75rem',
                    borderRadius: '0.25rem',
                    flexShrink: 0,
                  }}
                />
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  lineHeight={1.125}
                  textTransform="capitalize"
                >
                  {`${bar.name}`}
                </Typography>
              </Stack>
            )}
            {map(Object.keys(bar.payload), (key, index) => {
              const isTooltipKeyPresent = key === tooltip;

              return isTooltipKeyPresent && showBarValue ? (
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  textTransform="capitalize"
                  key={index}
                >
                  {`# ${round(bar.payload[key])}`}
                </Typography>
              ) : null;
            })}
          </Paper>
        );
      }

    return null;
  };

  const renderBarChart = useMemo(() => {
    if (loading && data.length <= 0) {
      return <BarChartLoader />;
    } else if (Array.isArray(data) && data.length > 0) {
      return (
        <ScrollContainer vertical={false}>
          <Box minWidth={data.length * 40} py={1}>
            <ResponsiveContainer width="100%" height={225}>
              <BarChart
                width="100%"
                data={refinedData}
                barCategoryGap={'25%'}
                margin={[0, 0, 0, 0]}
                barGap={20}
              >
                <CartesianGrid vertical={false} stroke="#F2F4F7" />
                <XAxis
                  dataKey={xKey}
                  scaleToFit={true}
                  axisLine={false}
                  tickLine={false}
                  interval={0}
                  height={65}
                  stroke={primaryTextColor}
                  tick={({ x, y, payload }) => {
                    
                    const chunks = chunkLabel(payload.value);

                    const tspans = chunks.map((s, i) => (
                      <tspan
                        x={0}
                        y={0}
                        dy={i * 12}
                        dx={-maxChars+3}
                        key={`${s}_${i}`}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {s}
                      </tspan>
                    ));
                    return (
                      <g
                        transform={`translate(${x},${y})`}
                        key={`${payload.value}`}
                      >
                        <text
                          width={68}
                          textAnchor="middle"
                          fontSize={10}
                          fill={primaryTextColor}
                          transform="rotate(-70 10 10)"
                          
                        >
                          {tspans} 
                        </text>
                      </g>
                    );
                  }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  lineHeight={10}
                  fontSize={10}
                  stroke={primaryTextColor}
                  width={38}
                ></YAxis>
                <Tooltip
                  cursor={false}
                  itemStyle={{
                    fontSize: '12px',
                    margin: 0,
                    padding: 0,
                  }}
                  labelStyle={{
                    fontSize: '14px',
                  }}
                  content={
                    <CustomTooltip data={data} showBarValue={showBarValue} />
                  }
                />

                {!!data && isStacked && keys.length ? (
                  keys.map((key, index) => {
                    return (
                      <Bar
                        id={`${key}`}
                        dataKey={key}
                        stackId="x"
                        key={`${key}`}
                        barSize={data.length < 4 ? 24 : undefined}
                        shape={(props) => {
                          // console.log(props, 'props');
                          if (props.value[1] == props[valKey]) {
                            props.radius = [4, 4, 0, 0];
                          }

                          if (props.y + props.height >= 145) {
                            props.radius = [0, 0, 4, 4];
                          }

                          if (props.payload[props.id] === props[valKey]) {
                            props.radius = [4, 4, 4, 4];
                          }

                          return <Rectangle {...props} />;
                        }}
                        onMouseOver={() => (tooltip = key)}
                        fill={alpha(
                          colors[index % colors.length],
                          hexColorVariants[
                            Math.floor(index / colors.length) %
                              hexColorVariants.length
                          ]
                        )}
                      />
                    );
                  })
                ) : (
                  <Bar
                    id={valKey}
                    dataKey={valKey}
                    radius={[10, 10, 0, 0]}
                    onMouseOver={() => (tooltip = valKey)}
                  >
                    {data.map((entry, index) => (
                      <Cell key={index} fill="#4D80C6" d />
                    ))}
                  </Bar>
                )}
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </ScrollContainer>
      );
    } else {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" pt={3}>
          No {xLabel}
        </Box>
      );
    }
  }, [data]);

  return (
    <>
      {xLabel && (
        <Box>
          <Box p={1.5} mt="0 !important" mb={0}>
            <Typography
              component="span"
              fontSize={14}
              color={subHeaderColor}
              fontWeight={500}
            >
              {xLabel}
            </Typography>
          </Box>
          <Divider className={styles.divider} />
        </Box>
      )}
      {renderBarChart}
    </>
  );
}

export default ProjectBarChart;

/**
*  External Imports
*/
import {connect} from "react-redux";

/**
*  Internal Imports
*/
import {setNodeParamsAction, selectConstellationLabelsAction} from "../../../../redux/actions/communityActions";
import CommunityConstellationGraphNode from "./CommunityConstellationGraphNode";

const mapStateToProps = ({ community: { constellationGraphReady} }) => ({
    constellationGraphReady
});

const mapDispatchToProps = (dispatch) => ({
    selectLabel: (label) => dispatch(selectConstellationLabelsAction(label)),
    setNodeParams: (nodeParams) => dispatch(setNodeParamsAction(nodeParams))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CommunityConstellationGraphNode);
import { Grid } from '@mui/material';
import React from 'react';
import ProjectCard from './connected/projects/ProjectCard';

export const ProjectsLoader = ({ count = 6 }) => {
  return (
    <Grid container spacing={3}>
      {Array(count)
        .fill('*')
        .map((_, index) => (
          <Grid key={index} item xs={4}>
            <ProjectCard
              projectId={`projectId_${index}`}
              heading={`projectId_${index}`}
              onClick={() => {}}
              loading
            />
          </Grid>
        ))}
    </Grid>
  );
};

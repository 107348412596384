import { BASE_URL } from '../../constants/google-login-urls'
import axios from 'axios'
import { getCognitoIdToken } from '../utilityMethods';
import get from 'lodash/get'

export const getDatasetForAllZones = async () => {
    try {
        const token = await getCognitoIdToken();

        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.post(`${BASE_URL}/data_access/search/guppy/aggregate/project_distribution_by_zone`, {}, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

import React from 'react'
import classes from './index.module.css';
import map from 'lodash/map'
import DefaultProfile from '../../../assets/images/user/default-profile-image.png'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox';
import scientistIcon from '../../../assets/images/scientist.svg';
import mentorIcon from '../../../assets/images/industrial.svg';
import tick from '../../../assets/images/tick.svg';
const UserTypeCard = props => {

    const activeInactiveClass = props.userData?.isChecked ? classes.activeCard : classes.inactiveCard
    return (
        <Grid item lg={3} md={4} xs={12} className={classes.userTypeCardContainer}>
            <div className={`${classes.userDetailsWrapper} ${activeInactiveClass}`}>
                <span className={classes.checkboxWrapper}>
                    <Checkbox
                        checked={props.userData?.isChecked}
                        color="primary"
                        onChange={(e) => props.onCheckboxChange(props.index, e.target.checked)}
                        id='userCheck'
                    />
                </span>
                {
                    props.userData?.title === 'Academic Scientist' ? 

                    <img src={scientistIcon} alt='user' className={classes.userTypeImage} /> : 

                    <img src={mentorIcon} alt='user' className={classes.userTypeImage} />
                }
                
                <p className={classes.userDesignationText}>{props.userData?.title}</p>
                {map(props.userData?.privilege, (details)=> <div className={classes.roleRow}><img src={tick} className={classes.tickIcon} /><p className={classes.userPrivilageDetailsText}>{details}</p></div> )}
            </div>
        </Grid>
    )
}

export default UserTypeCard;

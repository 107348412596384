import React, { useState, useEffect } from 'react'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import WorkspaceUsageDetailsCard from '../../commons/keyword-detail-card'
import Grid from '@mui/material/Grid'
import classes from './index.module.css'
import WorkspaceUsageSummaryGraphContainers from '../workspace-usage-summary-graph-containers'
import { findIndex, filter, find, includes, cloneDeep, groupBy, meanBy, sumBy, isDate, sortBy, orderBy, isEmpty, intersectionBy } from 'lodash'
import DollarSignIcon from '../../../assets/icons/dollar-sign.svg'
import WorkspaceIcon from '../../../assets/icons/workspaces-icon.svg'
import TopupIcon from '../../../assets/icons/topup.svg'
import LockIcon from '../../../assets/icons/locked.svg'
import { DUMMY_DATA_FOR_TIME_CHART, WORKSPACE_COST_SUMMARY_LEGEND_KEYS } from '../../../constants/strings'
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import CalenderIcon from '../../../assets/icons/calender-icon.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    startOfYear,
    endOfYear,
    addYears,
    startOfQuarter
} from '../../../utils/date-formatter';
import { getWorkspaceData, getWorkspaceDataDayWise, getLockedWorkspaceCount, getTopupWorkspaceCount, 
    updateLockedWorkspaceStatus, deleteWorkspace, updateDoNotDeleteWorkspaceFlag } from '../../../api/workspace-api/workspace-api'
import DropDown from '../../commons/dropdown';
import Tooltip from '@mui/material/Tooltip'
import { Router, navigate } from '@reach/router';
import SearchIcon from '../../../assets/icons/search-icon.svg';
import CloseSmall from '../../../assets/images/close.png';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ImageVerticalIcon from '../../../assets/icons/MoreOptionsVertical.S-dark.svg';
import MuiPopover from '@mui/material/Popover';
import withStyles from '@mui/styles/withStyles';
import { validateEmailAddress } from '../../../utils/input-validations'
import { getUserTransactions, getUserBalance, addUserCredit, updateCreditAmount } from '../../../api/account-api'
import Modal from '../../commons/modal'
import ErrorText from '../../controls/error-text'
import { COUNTRY_LIST, ACCOUNT_MOCK_DATA, TRANSACTION_TYPE_STATUS } from '../../../constants/strings'
import TablePagination from '../../commons/table-pagination'
import {  Switch } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete } from '@mui/material';
import imgClose from "../../../assets/images/close.png";
import { FormControl, Select, InputBase, MenuItem, Button} from '@mui/material';
import SearchIconControl from '@mui/icons-material/Search';
import InfoGreyIcon from '../../../assets/icons/InfoIcon.svg';


const Popover = withStyles(theme => ({
  root: {
    overflowY: 'scroll',
  },
  paper: {
    minWidth: 192,
    right: 'auto !important',
    marginTop: '32px !important',
  }
}))(MuiPopover);

const StyledAutocomplete = withStyles(() => ({
    root: {
      '& .MuiInputBase-root': {
        margin: 0,
        border: '1px solid #DEDEDE',
        borderBottom: 'none',
        padding: 15,
        '& .MuiInputBase-input': {
          border: 'none',
          borderBottom: '1px solid #DEDEDE',
          borderRadius: '0px',
          outline: 'none',
          boxShadow: 'none',
          borderColor: '#DEDEDE'
        }
      },
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        right: 20
      },
      '&::-webkit-scrollbar': {
        width: '7px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#fff',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#626262',
        borderRadius: '5px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#494949', 
      },
    },
    listbox: {
      border: '1px solid #DEDEDE',
      borderTop: 'none',
      ["@media (max-width:1366px)"]: {
        maxHeight: 250,
      },
      '&::-webkit-scrollbar': {
        width: '7px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#fff',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#626262',
        borderRadius: '5px',
        // borderRight: '5px white solid',
        // backgroundClip: 'padding-box',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#494949', 
      }
    },
    paper: {
      margin: 0
    }
  }))(Autocomplete);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    color: '#031235',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: "Raleway",
    height: 41,
    boxSizing: 'border-box',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    }
  },
  formControl: {
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10
  },
  
}))(InputBase);

const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    startOfLastFullYearDay: startOfDay(addDays(new Date(), -365)),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    startOfQuarter: startOfQuarter(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1))
};

const sideBarOptions = () => {
    const customDateObjects = [
        {
            label: "Today",
            range: () => ({
                startDate: defineds.startOfToday,
                endDate: defineds.endOfToday
            })
        },
        {
            label: "Yesterday",
            range: () => ({
                startDate: defineds.startOfYesterday,
                endDate: defineds.endOfYesterday
            })
        },
        {
            label: "Last Week",
            range: () => ({
                startDate: defineds.startOfLastWeek,
                endDate: defineds.endOfLastWeek
            })
        },
        {
            label: "Last Month",
            range: () => ({
                startDate: defineds.startOfLastMonth,
                endDate: defineds.endOfLastMonth
            })
        },
        {
            label: "Month to date",
            range: () => ({
                startDate: defineds.startOfMonth,
                endDate: defineds.endOfToday
            })
        },
        {
            label: "Quarter to date ",
            range: () => ({
                startDate: defineds.startOfQuarter,
                endDate: defineds.endOfToday
            })
        },
        {
            label: "Year to date",
            range: () => ({
                startDate: defineds.startOfYear,
                endDate: defineds.endOfToday
            })
        }
    ];

    return customDateObjects;
};

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        left: '2px !important',
        color: theme.palette.grey[500],
        padding: 0,
        '& + $track': {
            borderColor: '#9e9e9e',
        },
        '&$checked': {
            transform: 'translateX(12px)',
            color: '#412391',
            '& + $track': {
                opacity: 1,
                backgroundColor: '#fff',
                borderColor: '#412391',
            },
        },
    },
    thumb: {
        marginTop: 2,
        left: 2,
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid #412391`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: '#fff',
    },
    checked: {},
}))(Switch);


const WorkspaceCostSummary = React.forwardRef((props, ref) => {
    const [openCustomizePopover, setOpenCustomizePopover] = useState(false);
    const [customizeOptions, setCustomizeOptions] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dataWithReferenceLine, setDataWithReferenceLine] = useState([]);
    const [filteredCustomizeOptions, setFilteredCustomizeOptions] = useState([]);
    const [timeIntervalForGraph, setTimeIntervalForGraph] = useState(0);
    const [allWorkspaceUsageData, setAllWorkspaceUsageData] = useState({});
    const [lockedWorkspaceCount, setLockedWorkspaceCount] = useState('');
    const [topupWorkspaceCount, setTopupWorkspaceCount] = useState('');
    const [selectionDateRage, setSelectedDateRange] = useState({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfToday,
        key: 'selection',
    });
    const [openCalender, setOpenCalender] = useState(false);
    const [selectedCalenderInput, setSelectedCalenderInput] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [filterValue, setFilterValue] = useState('')
    const [searchText, setSearchText] = useState('');
    const [dataForWorkspaceDetailsTable, setDataForWorkspaceDetailsTable] = useState('');
    const [selectedStatusFilter, setSelectedStatusFilter] = useState('Show All');
    const [selectedWorkspacePaymentStatusFilter, setSelectedWorkspacePaymentStatusFilter] = useState('Show All');
    const [filteredWorkspaceTableData, setFilteredWorkspaceTableData] = useState([]);
    const [workspaceOwners, setWorkspaceOwners] = useState([]);
    const maxCount = filteredWorkspaceTableData?.length ? Math.ceil(filteredWorkspaceTableData.length / 5) : 0;
    const topNNumberOfWorkspaces = 5;
    const [isLoading, setIsLoading] = useState(true);
    const [workspaceRequestAnchorEl, setWorkspaceRequestAnchorEl] = useState(null);
    const openWorkspacePopover = !!workspaceRequestAnchorEl;
    const [currentWorkspaceDetails, setCurrentWorkspaceDetails] = useState({});
    const [OpenAddCreditsModal, setOpenAddCreditsModal] = useState(false);
    const [unlockWorkspaceModal, setUnlockWorkspaceModal] = useState(false);
    const [doNotDeleteWorkspaceModal, setDoNotDeleteWorkspaceModal] = useState(false);
    const [isFormUpdating, setIsFormUpdating] = useState(false)
    const [lockWorkspaceModal, setLockWorkspaceModal] = useState(false);
    const [deleteWorkspaceModal, setDeleteWorkspaceModal] = useState(false);
    const [openAdjustCreditsModal, setOpenAdjustCreditsModal] = useState(false);
    const [adjustCreditAmount, setAdjustCreditAmount] = useState('');
    const [adjustReasonText, setAdjustReasonText] = useState('');
    const [adjustCreditDate, setAdjustCreditDate] = useState(null)
    const [amountValue, setAmountValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [name, setNameValue] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [municipality, setMunicipality] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState('')
    const [sendRequestButtonClick, setSendRequestButtonClick] = useState(false);
    const [recentAppliedDateRange, setRecentAppliedDateRange] = useState({
        startDate: startOfMonth(new Date()),
        endDate: endOfDay(new Date()),
        key: 'selection'
      })

    

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }

    const isStreetAddressValid = () => !!streetAddress;
    const isCityValid = () => !!city;
    const isStateValid = () => !!state;
    const isZipCodeValid = () => !!zipCode;
    const isCountryValid = () => !!country;
    const isNameValid = () => !!name;
    const isAmountValid = () => !!Number(amountValue);
    const isAdjustAmountValid = () => !!Number(adjustCreditAmount);
    const isAdjustReasonTextValid = () => !!adjustReasonText;
    const setTablePage = number => {
        setPage(number)
    }

    const sideBar = sideBarOptions();

    const Menu = props => {
        return (
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: 4,
              position: 'absolute',
              zIndex: 2,
              width: '100%'
            }}
            {...props}
          />
        );
      };
      const Blanket = props => (
        <div
          style={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: 'fixed',
            zIndex: 1,
          }}
          {...props}
        />
      );
    
    const Dropdown = ({ children, isOpen, target, onClose }) => (
        <div style={{ position: 'relative', minWidth: '180px', marginLeft: '10px' }}>
          {target}
          {isOpen ? <Menu>{children}</Menu> : null}
          {isOpen ? <Blanket onClick={onClose} /> : null}
        </div>
    );

    const staticRanges = [
        // ...defaultStaticRanges,
        ...createStaticRanges(sideBar)
    ];

    const closeAddCreditModal = () => {
        setOpenAddCreditsModal(false);
        setSendRequestButtonClick(false);
    }
    const onAmountValueChange = (e) => {
        setAmountValue(e.target.value);
    }
    const onEmailChange = e => {
        setEmailValue(e.target.value);
    }
    const onNameChange = e => {
        setNameValue(e.target.value);
    }
    const closeAdjustCreditModal = ()=>{
        setSendRequestButtonClick(false);
        setOpenAdjustCreditsModal(false);
    }
    const closeUnlockWorkspaceModal = ()=>{
        setUnlockWorkspaceModal(false);
    }
    const closeLockWorkspaceModal = ()=>{
        setLockWorkspaceModal(false);
    }
    const closeDeleteWorkspaceModal = ()=>{
        setDeleteWorkspaceModal(false);
    }
    const closeDoNotDeleteWorkspaceModal = ()=>{
        setDoNotDeleteWorkspaceModal(false);
    }
    const onAdjustAmountValueChange = (e)=>{
        setAdjustCreditAmount(e.target.value);
    }
    const onAdjustReasonChange = (e)=>{
        setAdjustReasonText(e.target.value);
    }
   
    const getWorkspacesDataForTimeRangeChart = async () => {
        try {
            const allWorkspacesData = await getWorkspaceDataDayWise();
            //API CALL
            setDataWithReferenceLine(allWorkspacesData?.day_wise_total_usage_of_all_workspace);
        } catch (error) {
            console.log('error in getting time chart data')
        }
    }

    const getWorkspacesUsageData = async () => {
        try {
            const workspaceUsageData = await getWorkspaceData();
            setAllWorkspaceUsageData(workspaceUsageData);
            workspaceUsageData.workspaces = workspaceUsageData.workspaces.sort((a, b) => (a['total_cost']?.toFixed(2) > b['total_cost']?.toFixed(2)) ? 1 : -1)
            setDataForWorkspaceDetailsTable(workspaceUsageData.workspaces);
            setFilteredWorkspaceTableData(workspaceUsageData.workspaces);

        } catch (error) {
            console.log('error in getting workspace usage chart data')
        } finally {
            setIsLoading(false)
        }
    }

    const getLockedWorkspaceCountData = async () => {
        try {
            const response = await getLockedWorkspaceCount();
            setLockedWorkspaceCount(response?.locked_workspace_count);
        } catch (error) {
            console.log('error in getting locked workspace count data')
        } finally {
            setIsLoading(false)
        }
    }

    const getTopupWorkspaceCountData = async () => {
        try {
            const response = await getTopupWorkspaceCount();
            setTopupWorkspaceCount(response?.top_up_requested_count);
        } catch (error) {
            console.log('error in getting topup workspace data')
        } finally {
            setIsLoading(false)
        }
    }
    /* lifecycle methods*/
    useEffect(() => {
        let filteredWorkspacesBySearch = [];
        if (!isEmpty(searchText)) {
            filteredWorkspacesBySearch = filter(dataForWorkspaceDetailsTable, (item) => item['workspace_id']?.toLowerCase()?.includes(searchText.toLowerCase()) || item['owner']?.toLowerCase()?.includes(searchText.toLowerCase()));
        } else {
            filteredWorkspacesBySearch = dataForWorkspaceDetailsTable;
        }
        setFilteredWorkspaceTableData(filteredWorkspacesBySearch);
    }, [searchText])

    useEffect(() => {
        getWorkspacesDataForTimeRangeChart();
    }, []);

    useEffect(() => {
        getWorkspacesUsageData();
        getLockedWorkspaceCountData();
        getTopupWorkspaceCountData();
    }, []);

    useEffect(() => {
        let tempCustomizeOptions = [];
        let sortedWorkspacesByDesc = orderBy(allWorkspaceUsageData.workspaces, ['total_cost'], ['desc']);
        map(sortedWorkspacesByDesc, (item, index) => {
            if (index < 6) {
                tempCustomizeOptions.push({
                    name: item.workspace_id,
                    isSelected: true,
                    displayName: item.name
                })
            } else {
                tempCustomizeOptions.push({
                    name: item.workspace_id,
                    isSelected: false,
                    displayName: item.name
                })
            }
        });
        setCustomizeOptions(tempCustomizeOptions);
    }, [allWorkspaceUsageData])

    useEffect(() => {
        let tempFilteredData = [];
        if (customizeOptions.length) {
            tempFilteredData = filter(allWorkspaceUsageData.workspaces, (item) => {
                return find(customizeOptions, (option) => option.name === item.workspace_id).isSelected;
            });
            if (tempFilteredData.length) {
                tempFilteredData = orderBy(tempFilteredData, ['total_cost'], ['desc']);
                setFilteredData(tempFilteredData);
            } else {
                let allWSData = [];
                allWSData = orderBy(allWorkspaceUsageData.workspaces, ['total_cost'], ['desc']);
                setFilteredData(allWSData);
            }
        }
    }, [customizeOptions])

    useEffect(() => {
        setFilteredCustomizeOptions(customizeOptions);
    }, [customizeOptions])

    useEffect(() => {

        const calenderInputLabel = document.querySelector('.rdrStaticRangeSelected')?.firstChild?.textContent || `${moment(selectionDateRage?.startDate).format('MM-DD-YYYY')} - ${moment(selectionDateRage?.endDate).format('MM-DD-YYYY')}`;
            setSelectedCalenderInput(calenderInputLabel);       
    }, [selectionDateRage])
    /* lifecycle method ends*/

    const onAddCreditClick = ()=>{
        setOpenAddCreditsModal(true);
        setSendRequestButtonClick(false);
        setWorkspaceRequestAnchorEl(null)
    }
    const onAdjustCreditClick = ()=>{
        setOpenAdjustCreditsModal(true);
        setWorkspaceRequestAnchorEl(null)
    }
    const onUnlockWorkspaceClick = ()=>{
        setUnlockWorkspaceModal(true);
        setWorkspaceRequestAnchorEl(null)
    }
    const onLockWorkspaceClick = ()=>{
        setLockWorkspaceModal(true);
        setWorkspaceRequestAnchorEl(null)
    }
    const onDeleteWorkspaceClick = ()=>{
        setDeleteWorkspaceModal(true);
        setWorkspaceRequestAnchorEl(null)
    }
    const handleUsageGraphCustomizeClickAway = () => {
        setOpenCustomizePopover(false);
    }
    const handleUsageGraphCustomizeClick = () => {
        setOpenCustomizePopover(prev => !prev);
    }

    const handleCustomizeSearchChange = (searchText) => {
        if (searchText.length) {
            let tempOptions = cloneDeep(customizeOptions);
            tempOptions = filter(tempOptions, (data) => includes(data?.name, searchText));
            setFilteredCustomizeOptions(tempOptions);
        }
        else {
            setFilteredCustomizeOptions(customizeOptions);
        }
    }
    const handleCustomizationChange = selectedItem => {
        let tempFilteredData = [];
        if (!selectedItem) {
            const isAllItemsSelected = customizeOptions.filter(item =>
                item.isSelected).length === customizeOptions.length;
            setCustomizeOptions(customizeOptions.map(item => {
                if (isAllItemsSelected) {
                    return { ...item, isSelected: !isAllItemsSelected }

                } else {
                    return { ...item, isSelected: !isAllItemsSelected }
                }
            }))
        }
        else {
            const newArray = customizeOptions.map(item => {
                if (item.name === selectedItem.name) {
                    return { ...item, isSelected: !item.isSelected }
                }
                return item
            })
            setCustomizeOptions(newArray)
        }
    }
    const onCalenderSelect = (data) => {
        setSelectedDateRange(data.selection);
    }
    //hides calender
    const onCalenderCancelButtonClick = () => {
        setOpenCalender(false);
        setSelectedDateRange(recentAppliedDateRange)
    }
    //selected range
    const onResetDateRangeSelection = () => {
        setSelectedDateRange({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfToday,
            key: 'selection',
        })
        setRecentAppliedDateRange({
            startDate: startOfMonth(new Date()),
            endDate: endOfDay(new Date()),
            key: 'selection'
          });
    }
    //makes API call
    const onApplyDateRangeButtonClick = async () => {
        //check if start date and date is actual date in selection
        const isValidStartDate = isDate(selectionDateRage?.startDate || null);
        const isValidEndDate = isDate(selectionDateRage?.endDate || null);
        if (isValidStartDate && isValidEndDate) {
            setRecentAppliedDateRange(selectionDateRage);
            //make API call
            const allWorkspacesData = await getWorkspaceDataDayWise(moment(selectionDateRage?.startDate).format('YYYY-MM-DD'), moment(selectionDateRage?.endDate).format('YYYY-MM-DD'));
            const workspaceUsageData = await getWorkspaceData(moment(selectionDateRage?.startDate).format('YYYY-MM-DD'), moment(selectionDateRage?.endDate).format('YYYY-MM-DD'));
            setAllWorkspaceUsageData(workspaceUsageData);
            //API CALL
            setFilteredWorkspaceTableData(workspaceUsageData?.workspaces);
            setDataForWorkspaceDetailsTable(workspaceUsageData?.workspaces)
            setDataWithReferenceLine(allWorkspacesData?.day_wise_total_usage_of_all_workspace); 
            setOpenCalender(false);
        }
    }

    const getFormattedLegends = (data, value, index) => {
        let labelToShow = '';
        const labelIndex = findIndex(WORKSPACE_COST_SUMMARY_LEGEND_KEYS, (key) => data.includes(key));
        labelIndex > -1 ? labelToShow = WORKSPACE_COST_SUMMARY_LEGEND_KEYS[labelIndex] : labelToShow = '';
        return labelToShow;
    }
    const getXAxisFormattedLabel = (data, value, index) => {
        const isDateValue = new Date(data) instanceof Date && !isNaN(new Date(data));
        if (isDateValue) {
            return moment(data).format('MMMM Do');
        }
        return data;
    }
    const workspaceStatusOptions = ["Show All", "ACTIVE", "LOCKED", "DELETED", "UNLOCKED BY BC MANAGER", "DEPROVISIONED"]
    const workspacePaymentStatusOptions = ["Show All","TOP UP REQUESTED", "PAID"]
    const onWorkspaceStatusFilterValueChange = (value) => {
        setSelectedStatusFilter(value);
    }

    const onWorkspacePaymentStatusFilterValueChange = (value) => {
        setSelectedWorkspacePaymentStatusFilter(value);
    }

    useEffect(() => {
        const conditionArr = {};
        if(!isEmpty(selectedStatusFilter)){
            if(selectedStatusFilter !== "Show All"){
                conditionArr.status = selectedStatusFilter?.split(" ").join("_")
            }
        }
        if(!isEmpty(selectedWorkspacePaymentStatusFilter)){
            if(selectedWorkspacePaymentStatusFilter !== "Show All"){
                conditionArr.payment_status= selectedWorkspacePaymentStatusFilter?.split(" ").join("_")
            }
        }
        if(!isEmpty(selectedUser)){
            conditionArr.owner = selectedUser;
        }
        const result = filter(dataForWorkspaceDetailsTable, conditionArr);
        setFilteredWorkspaceTableData(result)

      
    }, [selectedStatusFilter, selectedWorkspacePaymentStatusFilter, selectedUser])

    useEffect(() => {
        let users = []
        if(dataForWorkspaceDetailsTable?.length){
            for(let user of dataForWorkspaceDetailsTable){
                users.push(user?.owner)
            }
        }
        users = uniq(users)
        setWorkspaceOwners(users)
    },[dataForWorkspaceDetailsTable])

    const onSearchTextChange = (e) => {
        setSearchText(e.target.value)
        setPage(0);
    }
  
    const resetAddCreditsField = () =>{
        setAmountValue('');
        setNameValue('');
        setEmailValue('');
        setCountry('');
        setStreetAddress('');
        setZipCode('');
        setCity('');
        setState('')
    }
    const onSendRequestClick = async () => {
        try {//API CALL TO SEND REQUEST
            setSendRequestButtonClick(true);
            if (isStreetAddressValid() && isCityValid() && isStateValid() && isCountryValid() && isZipCodeValid() && isNameValid() && isAmountValid()
                && validateEmailAddress(emailValue)
            ) {
                const body = {
                    "amount": amountValue,
                   "entity_id": currentWorkspaceDetails?.workspace_id,
                    "invoice_type": 'CREDIT',
                    "entity_type": "WORKSPACE_ACCOUNT",
                    "billing_information": {
                        "name": name,
                        "email": emailValue,
                        "zip_code": zipCode,
                        "street_address": streetAddress,
                        "country": country,
                        "state": state,
                        "city": city,
                    }
                }
                //API CALL
                const res = await addUserCredit(body);
                if (res) {
                    props.updateSnackBar(res?.message || "Request sent successfully", 'success')
                    resetAddCreditsField();
                    closeAddCreditModal();
                }
            }
        } catch (e) {
            props.updateSnackBar(e?.response?.data?.message || "Error is sending requesting", 'error')
            console.log(e)
        }
    }
    const resetAdjustCreditForm = ()=>{
        setAdjustCreditAmount('');
        setAdjustCreditDate(null);
        setAdjustReasonText('');
    }
    const onSendAdjustRequestClick = async () =>{
        try{
            setSendRequestButtonClick(true);
            if (isAdjustAmountValid() && isAdjustReasonTextValid()){
                const body = {
                        "amount": adjustCreditAmount,
                        "entity_id": currentWorkspaceDetails?.workspace_id,
                        "invoice_type": "ADJUSTMENT",
                        "entity_type": "WORKSPACE_ACCOUNT",
                        "comments": adjustReasonText
                    }
                const res = await updateCreditAmount(body);
                if(res){
                    props.updateSnackBar(res?.message || "Request sent successfully", 'success')
                    resetAdjustCreditForm();
                    closeAdjustCreditModal();
                  //call GET ALL TRANSACTIONS API AGAIN
                }else{
                    props.updateSnackBar(res?.message || "Error is sending adjust credit request", 'error')

                }
            }
        }catch(e){
            props.updateSnackBar(e?.response?.data?.message || "Error is sending adjust credit request", 'error')
            console.log(e)
        }
    }

    const onSendUnlockWorkspaceClick = async () =>{
        try{
            setIsFormUpdating(true)
            const body = {
                "workspace_lock_status": "UNLOCKED_BY_BC_MANAGER"
            }
            const response = await updateLockedWorkspaceStatus(currentWorkspaceDetails?.workspace_id, body);
            await getWorkspacesUsageData();
            props.updateSnackBar(response?.message || "Workspace unlocked successfully", 'success')
            setUnlockWorkspaceModal(false)
        }catch(error){
            props.updateSnackBar(error?.message || "Error in unlocking workspace", 'error')
            console.log(error)
        }finally{
            setIsFormUpdating(false)
        }
    }

    const onSendLockWorkspaceClick = async () =>{
        try{
            setIsFormUpdating(true)
            const body = {
                "workspace_lock_status": "LOCKED"
            }
            const response = await updateLockedWorkspaceStatus(currentWorkspaceDetails?.workspace_id, body);
            await getWorkspacesUsageData();
            props.updateSnackBar(response?.message || "Workspace unlocked successfully", 'success')
            setLockWorkspaceModal(false)
        }catch(error){
            props.updateSnackBar(error?.message || "Error in locking workspace", 'error')
            console.log(error)
        }finally{
            setIsFormUpdating(false)
        }
    }
    const onSendDeleteWorkspaceClick = async () =>{
        try{
            setIsFormUpdating(true)
            const response = await deleteWorkspace(currentWorkspaceDetails?.user_sub, currentWorkspaceDetails?.workspace_id);
            await getWorkspacesUsageData();
            props.updateSnackBar(response?.message || "Workspace deleted successfully", 'success')
            setDeleteWorkspaceModal(false)
        }catch(error){
            props.updateSnackBar(error?.message || "Error in deleting workspace", 'error')
            console.log(error)
        }finally{
            setIsFormUpdating(false)
        }
    }

    const onDoNotDeleteClick = (row) => {
        setDoNotDeleteWorkspaceModal(true);
        setCurrentWorkspaceDetails(row);
    }

    const onSendDoNotDeleteWorkspaceClick = async () =>{
        try{
            setIsFormUpdating(true)
            const body = {
                "do_not_delete": !currentWorkspaceDetails?.do_not_delete
            }
            const response = await updateDoNotDeleteWorkspaceFlag(currentWorkspaceDetails?.workspace_id, body);
            await getWorkspacesUsageData();
            props.updateSnackBar(response?.message, 'success')
            setDoNotDeleteWorkspaceModal(false)
        }catch(error){
            props.updateSnackBar(error?.message || "Error in making a request", 'error')
            console.log(error)
        }finally{
            setIsFormUpdating(false)
        }
    }
    const viewWorkspaceDetails = () => {
        navigate(`/dashboard/workspace/${currentWorkspaceDetails?.workspace_id}`, { state: { workspaceDetails: currentWorkspaceDetails } });
    }
    const ADD_CREDITS_MODAL = {
        modalTitle: 'Request Invoice to add credit to account balance',
        modalContent: "",
        positiveButtonText: 'Send Request',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSendRequestClick,
        negativeButtonAction: closeAddCreditModal
    }
    const ADJUST_CREDITS_MODAL = {
        modalTitle: 'Adjust Account Balance',
        modalContent: "",
        positiveButtonText: 'Update Account Balance',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSendAdjustRequestClick,
        negativeButtonAction: closeAdjustCreditModal
    }

    const UNLOCK_WORKSPACE_MODAL_PROPS = {
        modalTitle: 'Unlock Workspace',
        modalContent: "",
        positiveButtonText: 'Unlock Workspace',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSendUnlockWorkspaceClick,
        negativeButtonAction: closeUnlockWorkspaceModal,
        popupButtonDisable: isFormUpdating
    }

    const LOCK_WORKSPACE_MODAL_PROPS = {
        modalTitle: 'Lock Workspace',
        modalContent: "",
        positiveButtonText: 'Lock Workspace',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSendLockWorkspaceClick,
        negativeButtonAction: closeLockWorkspaceModal,
        popupButtonDisable: isFormUpdating
    }

    const DELETE_WORKSPACE_MODAL_PROPS = {
        modalTitle: 'Delete Workspace',
        modalContent: "",
        positiveButtonText: 'Delete Workspace',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSendDeleteWorkspaceClick,
        negativeButtonAction: closeDeleteWorkspaceModal,
        popupButtonDisable: isFormUpdating
    }

    const DO_NOT_DELETE_WORKSPACE_MODAL_PROPS = {
        modalTitle: 'Do not delete',
        modalContent: "",
        positiveButtonText: 'Do not delete',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSendDoNotDeleteWorkspaceClick,
        negativeButtonAction: closeDoNotDeleteWorkspaceModal,
        popupButtonDisable: isFormUpdating
    }
    
    return (
        <div className={classes.mainContainer} ref={ref}>
            <Modal
                open={unlockWorkspaceModal}
                handleClose={closeUnlockWorkspaceModal}
                dialogProps={UNLOCK_WORKSPACE_MODAL_PROPS} >

                <div>You can unlock the workspace to keep the workspace running, so that workspace owner can have
                     more time to process the payment. The status will be set to 'Unlocked by BC Manager' and the 
                     workspace will become available to the users.
                </div>

            </Modal>
            <Modal
                open={doNotDeleteWorkspaceModal}
                handleClose={closeDoNotDeleteWorkspaceModal}
                dialogProps={DO_NOT_DELETE_WORKSPACE_MODAL_PROPS} >

                <div>Are you sure you want to 
                    {
                        currentWorkspaceDetails?.do_not_delete ?
                        ' disable ' : ' enable '
                    }
                'Do not delete' switch for this workspace?</div>

            </Modal>
            <Modal
                open={lockWorkspaceModal}
                handleClose={closeLockWorkspaceModal}
                dialogProps={LOCK_WORKSPACE_MODAL_PROPS} >

                <div>Are you sure you want to lock this workspace?</div>

            </Modal>
            <Modal
                open={deleteWorkspaceModal}
                handleClose={closeDeleteWorkspaceModal}
                dialogProps={DELETE_WORKSPACE_MODAL_PROPS} >

                <div>Workspace will be deleted and any users that shares the Workspace or any Notebooks, will lose access to them.</div>

            </Modal>
             <Modal
              disableTitleDivider
                open={openAdjustCreditsModal}
                handleClose={closeAdjustCreditModal}
                dialogProps={ADJUST_CREDITS_MODAL} >
                <Grid item xs={6} className={classes.adjustModalField}>
                    <label className={classes.adjustModalField}>Amount<sup> *</sup></label>
                    <OutlinedInput
                        fullWidth
                        value={adjustCreditAmount}
                        onChange={onAdjustAmountValueChange}
                        placeholder='Enter amount'
                        id='amount'
                        type='number'
                        startAdornment={(
                            <InputAdornment position="start">
                                $
                            </InputAdornment>
                        )}
                    />
                    {
                        sendRequestButtonClick && !isAdjustAmountValid() &&
                        <ErrorText errorMessage="Enter valid amount" />
                    }
                </Grid>
                <Grid item xs={12}>
                        <label htmlFor='adjustmentReason' className={classes.adjustModalField}>Reason Of Adjustment<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={adjustReasonText}
                            onChange={onAdjustReasonChange}
                            placeholder='Enter reason'
                            name='adjustmentReason'
                            id='adjustmentReason'
                        />
                        {
                            sendRequestButtonClick && !isAdjustReasonTextValid() &&
                            <ErrorText errorMessage="Enter reason" />
                        }
                    </Grid>
            </Modal>
            <Modal
                open={OpenAddCreditsModal}
                handleClose={closeAddCreditModal}
                dialogProps={ADD_CREDITS_MODAL} >
                <Grid item xs={6} >
                    <label>Amount<sup> *</sup></label>
                    <OutlinedInput
                        fullWidth
                        value={amountValue}
                        onChange={onAmountValueChange}
                        placeholder='Enter amount'
                        id='amount'
                        type='number'
                        startAdornment={(
                            <InputAdornment position="start">
                                $
                            </InputAdornment>
                        )}
                    />
                    {
                        sendRequestButtonClick && !isAmountValid() &&
                        <ErrorText errorMessage="Enter valid amount" />
                    }
                </Grid>
                <h2 className={classes.billingInfoHeader}>Billing Information</h2>
                <Grid container xs={8} spacing={4}>
                    <Grid item xs={6}  >
                        <label>Name<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            onChange={onNameChange}
                            value={name}
                            placeholder='Username'
                            id='username'
                        />
                        {
                            sendRequestButtonClick && !isNameValid() &&
                            <ErrorText errorMessage="Enter valid username" />
                        }
                    </Grid>
                    <Grid item xs={6} >
                        <label>Email<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            onChange={onEmailChange}
                            value={emailValue}
                            placeholder='Email'
                            id='email'
                        />
                        {
                            sendRequestButtonClick && !validateEmailAddress(emailValue) &&
                            <ErrorText errorMessage="Enter valid email address" />
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={4} xs={8} className={classes.instituteDetailsContainer}>
                    <Grid item xs={6}>
                        <label htmlFor='country'>Country<sup> *</sup></label>
                        <DropDown
                            id='country'
                            values={COUNTRY_LIST}
                            handleFilterStateChange={setCountry}
                            selectedFilter={country}
                            placeholder={'Select Country'}
                            searchable
                            isClearable
                        />
                        {
                            sendRequestButtonClick && !isCountryValid() &&
                            <ErrorText errorMessage="Select country" />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <label htmlFor='streetAddress'>Street Address<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={streetAddress}
                            onChange={e => { setStreetAddress(e.target.value); }}
                            placeholder='Enter street address'
                            name='streetAddress'
                            id='streetAddress'
                        />
                        {
                            sendRequestButtonClick && !isStreetAddressValid() &&
                            <ErrorText errorMessage="Enter valid street address" />
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <label htmlFor='city'>City<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={city}
                            onChange={e => { setCity(e.target.value); }}
                            placeholder='Enter city'
                            name='city'
                            id='city'
                        />
                        {
                            sendRequestButtonClick && !isCityValid() &&
                            <ErrorText errorMessage="Enter valid city" />
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <label htmlFor='state'>State <sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={state}
                            onChange={e => { setState(e.target.value); }}
                            placeholder='Enter state'
                            name='state'
                            id='state'
                        />
                        {
                            sendRequestButtonClick && !isStateValid() &&
                            <ErrorText errorMessage="Enter valid state" />
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <label htmlFor='zipCode'>Zip Code<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={zipCode}
                            onChange={e => { setZipCode(e.target.value); }}
                            placeholder='Enter zip code'
                            name='zipCode'
                            id='zip'
                        />
                        {
                            sendRequestButtonClick && !isZipCodeValid() &&
                            <ErrorText errorMessage="Enter valid zip code" />
                        }
                    </Grid>

                </Grid>
            </Modal>
            <Grid container justifyContent='space-between'>
                <Grid item xs={8}>
                    <p className={classes.title}>Workspace Summary</p>
                </Grid>
                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {props.currentTab === null &&
                        <div className={classes.zoneTilesDetailsButton}>
                            <button id='cvb-adminDashboard-zone1ViewDatasetButton' className={classes.btn}
                                onClick={() => { props.setCurrentTab('workspace'); props.setDashboardTitle('Workspace Summary') }}>View Details</button>
                        </div>
                    }
                </Grid>
            </Grid>
            <div style={{ position: 'relative' }}>
                {props.currentTab === null &&
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item lg={3} md={3} className={classes.usageDetailsCardWrapper}>
                            <WorkspaceUsageDetailsCard
                                value={`${allWorkspaceUsageData?.workspaces?.length || 0}`}
                                backgroundColor='#D6ECF5'
                                topBorderColor='#72B7E5'
                                cardIcon={WorkspaceIcon}
                                cardValueSmall={true}
                                title='Active Workspaces'
                            />
                        </Grid>
                        <Grid item lg={3} md={3} className={classes.usageDetailsCardWrapper}>
                            <WorkspaceUsageDetailsCard
                                value={`$${allWorkspaceUsageData?.this_month_usage?.toFixed(2) || ''}`}
                                backgroundColor='#DFECD1'
                                topBorderColor='#4C8811'
                                cardIcon={DollarSignIcon}
                                cardValueSmall={true}
                                title='This Month’s Usage'
                                dollarSign={true}
                            />
                        </Grid>
                        <Grid item lg={3} md={3} className={classes.usageDetailsCardWrapper}>
                            <WorkspaceUsageDetailsCard
                                value={topupWorkspaceCount}
                                backgroundColor='#fcf1e0'
                                topBorderColor='#f8b64c'
                                cardIcon={TopupIcon}
                                cardValueSmall={true}
                                title='Top-up Requested'
                            />
                        </Grid>
                        <Grid item lg={3} md={3} className={classes.usageDetailsCardWrapper}>
                            <WorkspaceUsageDetailsCard
                                value={lockedWorkspaceCount}
                                backgroundColor='#f5d8d8'
                                topBorderColor='#ff0000'
                                cardIcon={LockIcon}
                                cardValueSmall={true}
                                title='Locked Workspaces'
                            />
                        </Grid>
                    </Grid>
                }
                {props.currentTab === 'workspace' && <>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item lg={3} className={classes.usageDetailsCardWrapper}>
                            <WorkspaceUsageDetailsCard
                                value={`${allWorkspaceUsageData?.workspaces?.length || 0}`}
                                backgroundColor='#D6ECF5'
                                topBorderColor='#72B7E5'
                                cardIcon={WorkspaceIcon}
                                title='Active Workspaces'
                            />
                        </Grid>
                        <Grid item lg={3} className={classes.usageDetailsCardWrapper}>
                            <WorkspaceUsageDetailsCard
                                value={`$${allWorkspaceUsageData?.this_month_usage?.toFixed(2) || ''}`}
                                backgroundColor='#DFECD1'
                                topBorderColor='#4C8811'
                                cardIcon={DollarSignIcon}
                                title='This Month’s Usage'
                                dollarSign={true}
                            />
                        </Grid>
                        <Grid item lg={6}>
                            <div className={classes.datePickerMainContainer}>
                                <label className={classes.calenderInputLabel}>Date</label>
                                <div onClick={() => setOpenCalender(!openCalender)} className={classes.calenderContainer}>
                                    <span className={classes.text}>{selectedCalenderInput}</span>
                                    <span className={classes.searchIcon}>
                                        <img src={CalenderIcon} alt='search icon' />
                                    </span>
                                </div>
                                {openCalender ?
                                    <ClickAwayListener onClickAway={() => setOpenCalender(false)}>
                                        <div className={classes.datePickerContainer}>
                                            <DateRangePicker
                                                ranges={[selectionDateRage]}
                                                onChange={onCalenderSelect}
                                                staticRanges={staticRanges}
                                                inputRanges={[]}
                                                months={2}
                                                direction="horizontal"
                                            />
                                            <div className={classes.applyResetButtonContainer}>
                                                <button onClick={onResetDateRangeSelection} className={`medium-size-button no-outlined-button ${classes.reset}`}>Reset to default</button>
                                                <button onClick={onCalenderCancelButtonClick} className={`medium-size-button no-outlined-button ${classes.cancel}`}>Cancel</button>
                                                <button onClick={onApplyDateRangeButtonClick} className={`small-size-button solid-button ${classes.apply}`}>Apply</button>
                                            </div>
                                        </div>
                                    </ClickAwayListener> : null}
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} direction='column' className={classes.allGraphsBoxMainContainer}>
                        {
                            <Grid item xs={12} md={12} lg={12} className={classes.graphContainerBox}>
                                <WorkspaceUsageSummaryGraphContainers
                                    title='Total Usage'
                                    subDetails=''
                                    canCustomize={false}
                                    data={dataWithReferenceLine}
                                    yAxisLabel='Usage in $'
                                    withReferenceLine={true}
                                    dataKeys={[{ key: 'data_transfer_cost', color: '#1F1768' }, { key: 'compute_cost', color: '#7487C0' }, { key: 'storage_cost', color: '#5f93c2' }, { key: 'other_cost', color: '#91BEE4' }]}
                                    tooltipKeys={['storage_cost', 'compute_cost', 'data_transfer_cost', 'other_cost']}
                                    xAxisDataKey='date'
                                    tooltipTitleKey='date'
                                    getFormattedLegends={getFormattedLegends}
                                    xAxisLabelTickFormatter={getXAxisFormattedLabel}
                                    isLoading={isLoading}
                                />
                            </Grid>
                        }
                        {
                            <Grid item xs={12} md={12} lg={12} className={classes.graphContainerBox}>
                                <WorkspaceUsageSummaryGraphContainers
                                    title='Usage By Workspace'
                                    subDetails={`${filteredData.length} Workspaces Selected`}
                                    canCustomize={true}
                                    handleCustomizationChange={handleCustomizationChange}
                                    customizeOptions={filteredCustomizeOptions}
                                    yAxisLabel='Usage in $'
                                    customizeTitle='Customize'
                                    data={filteredData}
                                    withReferenceLine={false}
                                    dataKeys={[{ key: 'data_transfer_cost', color: '#1F1768' }, { key: 'compute_cost', color: '#7487C0' }, { key: 'storage_cost', color: '#5f93c2' }, { key: 'other_cost', color: '#91BEE4' }]}
                                    handleSearchChange={handleCustomizeSearchChange}
                                    tooltipKeys={['storage_cost', 'compute_cost', 'data_transfer_cost', 'other_cost']}
                                    xAxisDataKey='workspace_id'
                                    tooltipTitleKey='workspace_id'
                                    getFormattedLegends={getFormattedLegends}
                                    xAxisLabelTickFormatter={getXAxisFormattedLabel}
                                    isLoading={isLoading}
                                />
                            </Grid>
                        }

                    </Grid></>}

            </div>
            {props.currentTab === 'workspace' && <div className={classes.workspaceTableContainer}>
                <p className={classes.title}>Workspaces</p>
                <Grid container spacing={2}>
                        
                <Grid item lg={3} md={3} className={classes.usageDetailsCardWrapper}>
                    <WorkspaceUsageDetailsCard
                        value={topupWorkspaceCount}
                        backgroundColor='#fcf1e0'
                        topBorderColor='#f8b64c'
                        cardIcon={TopupIcon}
                        title='Top-up Requested'
                    />
                </Grid>
                <Grid item lg={3} md={3} className={classes.usageDetailsCardWrapper}>
                    <WorkspaceUsageDetailsCard
                        value={lockedWorkspaceCount}
                        backgroundColor='#f5d8d8'
                        topBorderColor='#ff0000'
                        cardIcon={LockIcon}
                        title='Locked Workspaces'
                    />
                </Grid>
                    <Grid item lg={12}>
                        <div className={classes.tableContainerOuterBox}>
                            <div className={classes.tableContainerInnerBox}>
                                <Grid item lg={12} >
                                    <div className={`${classes.sortUserOptions} ${classes.sectionLeftContainer}`}>
                                        <ul>
                                            <li>
                                                <label id='cvb-cost-dashboard-filterByLabel-ws'>Workspace Status: </label>
                                            </li>
                                            <li id='cvb-cost-dashboard-dropdown' className={classes.sortFilter}>
                                                <DropDown
                                                    className={classes.searchSortDropdown}
                                                    values={workspaceStatusOptions}
                                                    handleFilterStateChange={onWorkspaceStatusFilterValueChange}
                                                    selectedFilter={selectedStatusFilter}
                                                    placeholder={'Select'}
                                                />
                                            </li>

                                            <li>
                                                <label id='cvb-cost-dashboard-filterByLabel-ps'>Payment Status: </label>
                                            </li>
                                            <li id='cvb-cost-dashboard-dropdown' className={classes.sortFilter}>
                                                <DropDown
                                                    className={classes.searchSortDropdown}
                                                    values={workspacePaymentStatusOptions}
                                                    handleFilterStateChange={onWorkspacePaymentStatusFilterValueChange}
                                                    selectedFilter={selectedWorkspacePaymentStatusFilter}
                                                    placeholder={'Select'}
                                                />
                                            </li>

                                            <li>
                                                <label id='cvb-cost-dashboard-filterByLabel-o'>Owner: </label>
                                            </li>

                                            <li className={classes.sortFilter}>
                                                <Dropdown
                                                    isOpen={isOpen}
                                                    className={classes.dropdown}
                                                    onClose={() => {toggleOpen()}}
                                                    target={
                                                        <Button
                                                        endIcon={!selectedUser ? <KeyboardArrowDownIcon style={{transform: !isOpen ? 'rotate(0deg)' : 'rotate(180deg)'}} /> : <img className={classes.closeIcon} src={imgClose} onClick={(e) => {
                                                            e.stopPropagation()
                                                            setSelectedUser("")
                                                        }}/>}
                                                        onClick={() => {toggleOpen()}}
                                                        isSelected={isOpen}
                                                        className={classes.button}
                                                        disableRipple
                                                        disableFocusRipple
                                                        disableElevation
                                                        fullWidth
                                                        >
                                                        {selectedUser ? `${selectedUser}` : 'Select'}
                                                        </Button>
                                                    }
                                                    >
                                                    <StyledAutocomplete
                                                        open={isOpen}
                                                        className={classes.autocomplete}
                                                        forcePopupIcon={false}
                                                        options={workspaceOwners.sort((a,b) => a.localeCompare(b))}
                                                        getOptionLabel={(option) => option}
                                                        disableCloseOnSelect={false}
                                                        filterSelectedOptions={true}
                                                        renderTags={() => null}
                                                        onChange={(e) => { setSelectedUser(e.target.innerText); setIsOpen(false) }}
                                                        renderInput={(params) => {
                                                            const { InputLabelProps, InputProps, ...rest } = params;
                                                            InputProps.endAdornment = <SearchIconControl/>
                                                            return <BootstrapInput 
                                                            placeholder='Search'
                                                            endAdornment={<SearchIconControl/>}
                                                            {...InputProps}  
                                                            {...rest} 
                                                            />
                                                        }}
                                                        >
                                                    </StyledAutocomplete>
                                                </Dropdown>
                                            </li>
                                            
                                            <li className={classes.searchContainer}>
                                                <div>
                                                    <OutlinedInput fullWidth type='text'
                                                        value={searchText}
                                                        placeholder='Search Workspace or Owner'
                                                        onChange={onSearchTextChange}
                                                        className='signup-inputs-large' name='password'
                                                        id='cvb-costSummary-searchInput'
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton aria-label="toggle password visibility" size="large">
                                                                    <img alt='visibility On' className={classes.searchDropdownIcon} src={SearchIcon} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <TableContainer

                                    elevation={0}
                                    className={classes.tableContainer}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell id='cvb-dashboard-cost-table-workspace' className={classes.searchColumn} width="10%" align="left">Workspaces</TableCell>
                                                <TableCell id='cvb-dashboard-cost-table-owner' className={classes.searchColumn} width="10%" align="left">Owner</TableCell>
                                                <TableCell id='cvb-dashboard-cost-table-shared-users' className={`${classes.searchColumn} ${classes.sharedUsersColumn}`} width="10%" align="left">Shared Users </TableCell>
                                                <TableCell id='cvb-dashboard-cost-table-total-usage' className={classes.searchColumn} width="15%" align="left">Total Usage</TableCell>
                                                <TableCell id='cvb-dashboard-cost-table-storage' className={classes.searchColumn} width="10%" align="left">Storage</TableCell>
                                                <TableCell id='cvb-dashboard-cost-table-compute' className={[classes.searchColumn, classes.searchColumnIcon].join(' ')} align="left">Compute</TableCell>
                                                <TableCell id='cvb-dashboard-cost-table-transfer' className={[classes.searchColumn, classes.searchColumnIcon].join(' ')} align="left">Transfer</TableCell>
                                                <TableCell id='cvb-dashboard-cost-table-transfer' className={[classes.searchColumn, classes.searchColumnIcon].join(' ')} align="left">Prepaid Funds</TableCell>
                                                <TableCell id='cvb-dashboard-cost-table-commissioned' className={[classes.searchColumn, classes.searchColumnIcon].join(' ')} align="left">Provision Date</TableCell>
                                                <TableCell id='cvb-dashboard-cost-table-decommissioned' className={[classes.searchColumn, classes.decommissionedColumn].join(' ')} align="left">Deprovision Date</TableCell>
                                                <TableCell id='cvb-dashboard-cost-table-last-login' className={[classes.searchColumn, classes.lastLoginColumn].join(' ')} align="left">Last Login</TableCell>
                                                <TableCell id='cvb-dashboard-cost-table-do-not-delete' className={[classes.searchColumn, classes.decommissionedColumn].join(' ')} align="left">Enable 'Do not delete'</TableCell>
                                                <TableCell id='cvb-dashboard-cost-table-status' className={`${classes.searchColumn} ${classes.statusColumn}`} width="20%" align="left">Workspace Status</TableCell>
                                                <TableCell id='cvb-dashboard-cost-table-status' className={`${classes.searchColumn} ${classes.statusColumn}`} width="20%" align="left">Payment Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {map(filteredWorkspaceTableData, (row, i) => (
                                                    <TableRow key={i} id={`cvb-dashboard-cost-table-row-${row.workspace_id}`}>
                                                        <TableCell className={[classes.organizationCell, classes.workspaceTableCell].join(" ")} component="th" scope="row">
                                                            <Tooltip placement="bottom-start" className={classes.userNameTitle} title={row?.workspace_id || ''}>
                                                                <span id={`cvb-dashboard-cost-table-workspace-${row.workspace_id}`} className={classes.userNameTitle}>{row?.workspace_id || '-'}</span>
                                                            </Tooltip>
                                                        </TableCell>

                                                        <TableCell className={[classes.organizationCell, classes.workspaceTableCell].join(" ")} component="th" scope="row">
                                                            <Tooltip placement="bottom-start" title={row?.owner || ''}>
                                                                <span id={`cvb-dashboard-cost-table-owner-${row.owner}`}>{row?.owner || '-'}</span>
                                                            </Tooltip>
                                                        </TableCell>

                                                        <TableCell className={[classes.organizationCell, classes.workspaceTableCell].join(" ")} component="th" scope="row">
                                                            <Tooltip placement="bottom-start" title={row?.shared_users || ''}>
                                                                <span id={`cvb-dashboard-cost-table-shared-users-${row.shared_users}`}>{row?.shared_users || '-'}</span>
                                                            </Tooltip>
                                                        </TableCell>

                                                        <TableCell className={[classes.emailCell, classes.workspaceTableCell].join(" ")} component="th" scope="row">
                                                            <span id={`cvb-dashboard-cost-table-total-cost-${row.total_cost}`}>{`$${row?.total_cost?.toFixed(2)}` || '-'}</span>
                                                        </TableCell>

                                                        <TableCell className={[classes.userRoleCell, classes.workspaceTableCell].join(" ")} component="th" scope="row">
                                                            <span id={`cvb-dashboard-cost-table-storage-cost-${row.storage_cost}`}>{`$${row?.storage_cost?.toFixed(2)}` || '-'}</span>
                                                        </TableCell>

                                                        <TableCell className={classes.workspaceTableCell} component="th" scope="row">
                                                            <span id={`cvb-dashboard-cost-table-compute-cost-${row.compute_cost}`}>{`$${row?.compute_cost?.toFixed(2)}` || '-'}</span>
                                                        </TableCell>

                                                        <TableCell className={classes.workspaceTableCell} component="th" scope="row">
                                                            <span id={`cvb-dashboard-cost-table-transfer-cost-${row.data_transfer_cost}`}>{`$${row?.data_transfer_cost?.toFixed(2)}` || '-'}</span>
                                                        </TableCell>

                                                        <TableCell className={classes.workspaceTableCell} component="th" scope="row">
                                                            <Tooltip placement="bottom-start" className={classes.userRoleTooltip} title={row?.prepaid_balance?.toFixed(2)}>
                                                                <span id={`cvb-dashboard-cost-table-prepaid-balance-${row.prepaid_balance}`}>{`$${row?.prepaid_balance?.toFixed(2)}` || '-'}</span>
                                                            </Tooltip>
                                                        </TableCell>

                                                        <TableCell className={classes.workspaceTableCell} component="th" scope="row">
                                                            <Tooltip placement="bottom-start" className={classes.userRoleTooltip} title={row?.commissioned_date ? moment(row?.commissioned_date).format("YYYY/MM/DD") : '-'}>
                                                                <span id={`cvb-dashboard-cost-table-commissioned-date-${row.commissioned_date}`}>
                                                                    {row?.commissioned_date ? moment(row?.commissioned_date).format("YYYY/MM/DD") : '-'}
                                                                </span>
                                                            </Tooltip>
                                                        </TableCell>

                                                        <TableCell className={[classes.workspaceTableCell, classes.decommissionedCell].join(" ")} component="th" scope="row">
                                                            <Tooltip placement="bottom-start" className={classes.userRoleTooltip} title={row?.decommissioned_date ? moment(row?.decommissioned_date).format("YYYY/MM/DD") : '-'}>
                                                                <span id={`cvb-dashboard-cost-table-decommissioned-date-${row.decommissioned_date}`}>
                                                                    {row?.decommissioned_date ? moment(row?.decommissioned_date).format("YYYY/MM/DD") : '-'}
                                                                </span>
                                                            </Tooltip>
                                                        </TableCell>

                                                        <TableCell className={classes.workspaceTableCell} component="th" scope="row">
                                                            <Tooltip placement="bottom-start" className={classes.userRoleTooltip} title={row?.last_login ? `${moment(row?.last_login).format("YYYY/MM/DD")} at ${moment(row?.last_login).format("HH:mm A")}` : '-'}>
                                                                <span id={`cvb-dashboard-cost-table-last-login-${row.last_login}`}>
                                                                    {row?.last_login ? `${moment(row?.last_login).format("YYYY/MM/DD")} at ${moment(row?.last_login).format("HH:mm A")}` : '-'}
                                                                </span>
                                                            </Tooltip>
                                                        </TableCell>

                                                        <TableCell className={[classes.workspaceTableCell, classes.doNotDeleteCell].join(" ")} component="th" scope="row">
                                                            <span id={`cvb-dashboard-cost-table-do-not-delete-${row?.workspace_id}`}>
                                                                <AntSwitch checked={row?.do_not_delete} onChange={() => onDoNotDeleteClick(row)} name='doNotDelete' />
                                                            </span>
                                                        </TableCell>

                                                        <TableCell className={`${classes.workspaceTableCell} ${classes.statusCell}`} component="th" scope="row">
                                                            <div style={{display: 'flex'}}>
                                                                {
                                                                    row.status?.toLowerCase() === "running" || row.status?.toLowerCase() === "started" || row.status?.toLowerCase() === "active" ?
                                                                        <span id={`cvb-dashboard-cost-table-status-${row.status}`} className={[classes.statusBox, classes.enabled].join(' ')}>
                                                                            {row.status?.split("_").join(" ")}
                                                                        </span> :

                                                                        row.status?.toLowerCase() === "locked" ?

                                                                        <>
                                                                            <span id={`cvb-dashboard-cost-table-status-${row.status}`} className={[classes.statusBox, classes.disabled].join(' ')}>
                                                                                {row.status?.split("_").join(" ")}
                                                                            </span>
                                                                            <span>
                                                                                <Tooltip placement="bottom-start" className={classes.userRoleTooltip} title={row?.locked_since ? `Since ${moment(new Date()).from(moment(row?.locked_since),true)}` : '-'}>
                                                                                    <img src={InfoGreyIcon} />
                                                                                </Tooltip>
                                                                            </span> 
                                                                        </>

                                                                            : row.status?.toLowerCase() === "deleted" ?

                                                                            <span id={`cvb-dashboard-cost-table-status-${row.status}`} className={[classes.statusBox, classes.disabled].join(' ')}>
                                                                                {row.status?.split("_").join(" ")}
                                                                            </span>

                                                                            : row.status?.toLowerCase() === "deprovisioned" ? 

                                                                            <span id={`cvb-dashboard-cost-table-status-${row.status}`} className={[classes.statusBox, classes.deprovisioned].join(' ')}>
                                                                                {row.status?.split("_").join(" ")}
                                                                            </span> : 

                                                                            row.status?.toLowerCase() === "unlocked_by_bc_manager" ? 

                                                                            <span id={`cvb-dashboard-cost-table-status-${row.status}`} className={[classes.statusBox, classes.draft].join(' ')}>
                                                                                {row.status?.split("_").join(" ")}
                                                                            </span> : null

                                                                }

                                                            </div>
                                                        </TableCell>

                                                        <TableCell className={`${classes.workspaceTableCell} ${classes.statusCell}`} component="th" scope="row">
                                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                                {
                                                                    row.payment_status?.toLowerCase() === "paid" ?
                                                                        <span id={`cvb-dashboard-cost-table-status-${row.payment_status}`} className={[classes.statusBox, classes.enabled].join(' ')}>
                                                                            {row.payment_status?.split("_").join(" ")}
                                                                        </span> :

                                                                        row.payment_status?.toLowerCase() === "top_up_requested" ?

                                                                            <span id={`cvb-dashboard-cost-table-status-${row.payment_status}`} className={[classes.statusBox, classes.topUpRequested].join(' ')}>
                                                                                {row.payment_status?.split("_").join(" ")}
                                                                            </span>

                                                                            : null

                                                                }

                                                                <div style={{ position: 'relative' }}>
                                                                    <img
                                                                        src={ImageVerticalIcon}
                                                                        alt='verticalIcon'
                                                                        className={classes.wsRequestRightIconImage}
                                                                        onClick={(event) => {setWorkspaceRequestAnchorEl(event.currentTarget); setCurrentWorkspaceDetails(row)}} />
                                                                    <Popover
                                                                        open={openWorkspacePopover}
                                                                        elevation={3}
                                                                        id={row?.workspace_id}
                                                                        anchorEl={workspaceRequestAnchorEl}
                                                                        onClose={() => setWorkspaceRequestAnchorEl(null)}
                                                                        anchorOrigin={{
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            horizontal: 'right',
                                                                        }}
                                                                    >
                                                                        <ul className={classes.dropdownListContainer}>


                                                                            <li onClick={() => viewWorkspaceDetails()}>
                                                                                View Details
                                                                            </li>
                                                                            <li onClick={() => onAddCreditClick()}>
                                                                                Add Credit
                                                                            </li>
                                                                            <li onClick={() => onAdjustCreditClick()}>
                                                                                Adjust Credit
                                                                            </li>
                                                                            {
                                                                                currentWorkspaceDetails?.status === "LOCKED" ?

                                                                                <li onClick={() => onUnlockWorkspaceClick()}>
                                                                                    Unlock Workspace
                                                                                </li> : null
                                                                                // <li onClick={() => onLockWorkspaceClick()}>
                                                                                //     Lock Workspace
                                                                                // </li>
                                                                            }
                                                                            {
                                                                                !currentWorkspaceDetails?.do_not_delete ?
                                                                                <li onClick={() => onDeleteWorkspaceClick()}>
                                                                                    Delete Workspace
                                                                                </li> : null
                                                                            }
                                                                            
                                                                            
                                                                        </ul>

                                                                    </Popover>
                                                                </div>
                                                            </div>
                                                        </TableCell>

                                                    </TableRow>
                                                )).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {
                                    maxCount > 0 &&
                                    <div className={classes.tablePagination}>
                                        <TablePagination
                                            maxCount={maxCount}
                                            page={page}
                                            setPage={setTablePage}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </Grid>





                </Grid> </div>}
        </div>
    );
})
WorkspaceCostSummary.displayName = 'WorkspaceCostSummary'

WorkspaceCostSummary.displayName = 'WorkspaceCostSummary'

export default WorkspaceCostSummary;
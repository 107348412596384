/**
 *  External Imports
 */
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

/**
 *  Internal Imports
 */
import BCLOGO2X from "../../../assets/images/BRAINCommons-logo@2x.png";
import styles from "./index.module.css";
import FeedbackForm from "./feedback-form";
import FeedbackInfo from "./feedback-info";
import { SUBMITTED_FEEDBACK_MESSAGE_OPTION_KEYS, DEFAULT_EMPTY_RATE } from "../../../constants/feedback";
import { communityAPI } from "../../../api/community-api";
import CustomLoader from "../../commons/custom-loader";
import errorIcon from "../../../assets/icons/error_icon_md.svg";

import { SUBMITTED_FEEDBACK_MESSAGE_OPTIONS } from "../../../constants/feedback";

const DEFAULT_SUBMIT_MESSAGE = SUBMITTED_FEEDBACK_MESSAGE_OPTION_KEYS.SUCCESS;

const FEEDBACK_RESPONSE_OPTIONS = {
    ALREADY_HAS_FEEDBACK: 409,
};

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 */
const Feedback = (props) => {
    const { location } = props;
    const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
    const [afterSubmitMessageType, setAfterSubmitMessageType] = useState(
        DEFAULT_SUBMIT_MESSAGE
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const [userToken, setUserToken] = useState("");
    const [currentUser, setCurrentUser] = useState("");
    const [feedbackRate, setFeedbackRate] = useState(DEFAULT_EMPTY_RATE);

    const checkIsFeedbackAlreadySubmitted = async (query) => {
        const { error, username } = await communityAPI.getFeedbackAvailability(query);

        if (username) {
            setCurrentUser(username)
            setIsFeedbackSubmitted(false);
            setAfterSubmitMessageType(SUBMITTED_FEEDBACK_MESSAGE_OPTION_KEYS.SUCCESS);
        } else {
            switch (error) {
                case FEEDBACK_RESPONSE_OPTIONS.ALREADY_HAS_FEEDBACK:
                    setIsFeedbackSubmitted(true);
                    setAfterSubmitMessageType(SUBMITTED_FEEDBACK_MESSAGE_OPTION_KEYS.ALREADY_SENT);
                    break;

                default:
                    setIsFeedbackSubmitted(true);
                    setAfterSubmitMessageType(SUBMITTED_FEEDBACK_MESSAGE_OPTION_KEYS.ERROR);
                    break;
            }
        }

        setIsLoaded(true);
    };

    const onFormSubmitEvent = async (value) => {
        const feedback = { ...value, token: userToken };

        const { error } = await communityAPI.postUserFeedback(feedback);

        if (error) {
            setAfterSubmitMessageType(
                error === FEEDBACK_RESPONSE_OPTIONS.ALREADY_HAS_FEEDBACK
                    ? SUBMITTED_FEEDBACK_MESSAGE_OPTION_KEYS.ALREADY_SENT
                    : SUBMITTED_FEEDBACK_MESSAGE_OPTION_KEYS.ERROR
            );
        } else {
            setAfterSubmitMessageType(
                SUBMITTED_FEEDBACK_MESSAGE_OPTION_KEYS.SUCCESS
            );
        }

        setIsFeedbackSubmitted(true);
    };

    // Preload for error image
    useEffect(() => {
        const errorImg = SUBMITTED_FEEDBACK_MESSAGE_OPTIONS['error']
        const img = new Image()
        img.src = errorImg.image
        img.alt = errorImg.imageAlt
    }, [])

    useEffect(() => {
        const query = location.search;
        let token = '';
        const queryList = query.replace(/\?/g, " ").trim().split(' ');
        queryList.forEach(queryItem => {
            if (queryItem.includes('token=')) {
                token = queryItem.replace("token=", "");
                setUserToken(token);
            }

            if (queryItem.includes('rating=')) {
                const rate = queryItem.replace("rating=", "");
                setFeedbackRate((currentState) => rate ? Number(rate) : currentState);
            }
        });

        checkIsFeedbackAlreadySubmitted(token);
    }, [location]);

    return (
        <div className={styles.feedback}>
            <div className={styles.container}>
                <Grid container>
                    <div className={styles.feedbackHeader}>
                        <img
                            data-testid="feedbackImage"
                            className={styles.feedbackHeaderLogo}
                            src={BCLOGO2X}
                            alt="BRAINCommons logo"
                        />
                    </div>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={styles.feedbackContent}>
                    <Grid container item xs={4} data-testid="feedbackContent">
                        {isLoaded ? (
                            <div className={`${styles.feedbackContentItem}`}>
                                {!isFeedbackSubmitted ? (
                                    <FeedbackForm
                                        data-testid="feedbackForm"
                                        currentRate={feedbackRate}
                                        userName={currentUser}
                                        submitEvent={(value) =>
                                            onFormSubmitEvent(value)
                                        }
                                    />
                                ) : (
                                    <FeedbackInfo
                                        data-testid="feedbackInfo"
                                        messageType={afterSubmitMessageType}
                                        errorIcon={errorIcon}
                                    />
                                )}
                            </div>
                        ) : (
                            <CustomLoader data-testid="feedbackLoader"/>
                        )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

/**
 *  Exports
 */
export default Feedback;

import DataInventoryForm from './DataInventoryForm'
import { connect } from 'react-redux'
import { setDifStage, setCurrentDIFFormStage } from '../../../redux/actions/dataInventoryActions'
import { setInstitutionFormData, resetInstitutionFormData } from '../../../redux/actions/difInstitutionActions'
import { setPrimaryInvestigatorData, resetPrimaryInvestigatorData } from '../../../redux/actions/difPIActions'
import { setDataSharingData, resetDataSharingData } from '../../../redux/actions/difDataSharingActions'
import { setOtherPersonnelData, resetOtherPersonnelData } from '../../../redux/actions/difOtherPersonalActions'
import { setTimelineData, resetTimelineData } from '../../../redux/actions/difTimelineActions'
import { setIndividualDatasetData, setDatasetCurrentActiveTab, resetIndividualDatasetData } from '../../../redux/actions/difIndividualDatasetActions'
import { updateSnackBar } from '../../../redux/actions/globalActions';
import { setStudyData } from '../../../redux/actions/difStudyActions';

const mapStateToProps = state => {
    return {
        difStage: state.dataInventory.difStage,
        currentDIFFormStage: state.dataInventory.currentDIFFormStage,
        difInstitutionInstitutionName: state.difInstitution.difInstitutionInstitutionName,
        difInstitutionStreetAddress: state.difInstitution.difInstitutionStreetAddress,
        difInstitutionCountry: state.difInstitution.difInstitutionCountry,
        difInstitutionState: state.difInstitution.difInstitutionState,
        difInstitutionCity: state.difInstitution.difInstitutionCity,
        difInstitutionMunicipality: state.difInstitution.difInstitutionMunicipality,
        difInstitutionZipCode: state.difInstitution.difInstitutionZipCode,

        difStudyName : state.difStudy.difStudyName,
        difStudyObjective : state.difStudy.difStudyObjective,
        difStudyDescription : state.difStudy.difStudyDescription,
        difStudyLogoFile : state.difStudy.difStudyLogoFile,
        difStudyDesign : state.difStudy.difStudyDesign,
        difStudyNumberOfGrants : state.difStudy.difStudyNumberOfGrants,
        difStudyDurationOfMoths : state.difStudy.difStudyDurationOfMoths,
        difStudyLocation : state.difStudy.difStudyLocation,
        difStudyPublications : state.difStudy.difStudyPublications,
        difStudyGrantInfo: state.difStudy.difStudyGrantInfo,
        logoS3Key: state.difStudy.logoS3Key,
        difStudyOptIn: state.difStudy.difStudyOptIn,
    
        difPIFirstName: state.difPI.difPIFirstName,
        difPILastName: state.difPI.difPILastName,
        difPIPhoneNumber: state.difPI.difPIPhoneNumber,
        difPIEmail: state.difPI.difPIEmail,
        difPIDepartmentName: state.difPI.difPIDepartmentName,
        difPIStreetAddress: state.difPI.difPIStreetAddress,
        difPICountry: state.difPI.difPICountry,
        difPIState: state.difPI.difPIState,
        difPICity: state.difPI.difPICity,
        difPIMunicipality: state.difPI.difPIMunicipality,
        difPIZipCode: state.difPI.difPIZipCode,

        difDataSharingIsNewDataSet: state.difDataSharing.difDataSharingIsNewDataSet,
        difDataSharingDatasetName: state.difDataSharing.difDataSharingDatasetName,
        difDataSharingPermissionToShare: state.difDataSharing.difDataSharingPermissionToShare,
        difDataSharingIRBNumber: state.difDataSharing.difDataSharingIRBNumber,
        difDataSharingDocumentFile: state.difDataSharing.difDataSharingDocumentFile,
        difDataSharingIsProtectedData: state.difDataSharing.difDataSharingIsProtectedData,
        difDataSharingIsDeIdentity: state.difDataSharing.difDataSharingIsDeIdentity,
        difDataSharingConsentDocument: state.difDataSharing.difDataSharingConsentDocument,
        
        difOPWillOtherUpload: state.difOP.difOPWillOtherUpload,
        difOPAddedUsers: state.difOP.difOPAddedUsers,

        difTimelineIsDeadlineToUpload: state.difTimeline.difTimelineIsDeadlineToUpload,
        difTimelineIsDeadlineToDataAccess: state.difTimeline.difTimelineIsDeadlineToDataAccess,
        difTimelineIsDataEmbargoed: state.difTimeline.difTimelineIsDataEmbargoed,
        difTimelineSubmissionDate: state.difTimeline.difTimelineSubmissionDate,
        difTimelineDataAccessDeadlineDate: state.difTimeline.difTimelineDataAccessDeadlineDate,
        difTimelineEmbargoedDate : state.difTimeline.difTimelineEmbargoedDate,
        difTimelineEmbargoedDesc: state.difTimeline.difTimelineEmbargoedDesc,

        individualDatasets: state.difIndividualDataset.individualDatasets,
        difDatasetActiveIndex: state.difIndividualDataset.difDatasetActiveIndex
        
    }
}

const mapDispatchToProps = dispatch => {
 
    return {
        setDifStage: async (value) => await dispatch(setDifStage(value)),
        setInstitutionFormData: async (value) => await dispatch(setInstitutionFormData(value)),
        setPrimaryInvestigatorData: async (value) => await dispatch(setPrimaryInvestigatorData(value)),
        setDataSharingData: async (value) => await dispatch(setDataSharingData(value)),
        setOtherPersonnelData: async (value) => await dispatch(setOtherPersonnelData(value)),
        setTimelineData: async (value) => await dispatch(setTimelineData(value)),
        setIndividualDatasetData: async (value) => await dispatch(setIndividualDatasetData(value)),
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type)),
        resetInstitutionFormData: () => dispatch(resetInstitutionFormData()),
        resetPrimaryInvestigatorData: () => dispatch(resetPrimaryInvestigatorData()),
        resetDataSharingData: () => dispatch(resetDataSharingData()),
        resetOtherPersonnelData: () => dispatch(resetOtherPersonnelData()),
        resetTimelineData: () => dispatch(resetTimelineData()),
        resetIndividualDatasetData: () => dispatch(resetIndividualDatasetData()), 
        setStudyData : (value) => dispatch(setStudyData(value)),
        setCurrentDIFFormStage: async (value) => await dispatch(setCurrentDIFFormStage(value)),    
        setDatasetCurrentActiveTab: async (value) => await dispatch(setDatasetCurrentActiveTab(value)),    
              

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataInventoryForm);
import React from 'react'
import Grid from '@mui/material/Grid'

import errorFace from "../../../../assets/icons/error-sad.png";
import Classes from './index.module.css'
import { navigate } from '@reach/router';

const RegisterError = () => {
    return (
        <Grid container justifyContent="flex-start" alignItems="center" direction='column' style={{gap: '50px'}}>
            <h2 className={Classes.registerErrorTitle}>Ooops...</h2>
            <img src={errorFace} alt="Error"/>
            <h3 className={Classes.registerErrorText} data-testid="messageTitle">
                The link does not exist or may have <br/> already been used.
            </h3>
            <button className={`solid-button ${Classes.registerErrorBtn}`} onClick={() => {navigate("/explore")}}>
                Home Page
            </button>
        </Grid>
    );
}

export default RegisterError
import KeytwordDetailCard from './KeywordDetailCard'
import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(KeytwordDetailCard);
import React, { useState, useEffect } from 'react'
import classes from './index.module.css'
import makeStyles from '@mui/styles/makeStyles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DownArrow from '../../../assets/icons/down-arrow.svg'
import ImgClose from '../../../assets/icons/close-small.svg'

import DropDown from '../../commons/dropdown'
import cloneDeep from 'lodash/cloneDeep'
import v4 from 'uuid/v4'
import { scrollToGivenTop } from '../../../utils/input-validations';


const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
    }
}));

export default function ExcludeFromExploration(props) {

    const {
        title = "Exclude From Exploration",
        exploreDataFilters = {},
        setExcludeFromExploration,
        excludeFromExploration,
        getProcessedFilterData = () => { },
        blockExploreDataCall = false,
        totalCount = 0
    } = props;

    const materialClasses = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedFilter, setSelectedFilter] = React.useState(false);
    const [showNewContainer, setShowNewContainer] = React.useState(true);

    const handleClick = () => {
        setOpen(prev => !prev);
    };

    const getFilterItems = () => {
        let filters = []
        if (exploreDataFilters) {
            filters = Object.keys(exploreDataFilters);
        }
        if (excludeFromExploration && excludeFromExploration.length > 0) {
            const selectedFilter = excludeFromExploration.map(item => item?.filter)
            return filters.filter(item => selectedFilter.indexOf(item) < 0)
        }
        filters = filters.filter(x => !x.startsWith("_"))
        return filters;
    }

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleFilterStateChange = value => {
        setSelectedFilter(value)
    }

    const handleFilterValuesChange = async value => {
        let exploreExplorationClone = cloneDeep(excludeFromExploration);
        exploreExplorationClone.push({ "filter": selectedFilter, "value": [...[], value] });
        await setExcludeFromExploration(exploreExplorationClone);
        setSelectedFilter("");
        setShowNewContainer(false);
    }

    const handleFilterStateAdded = (value, operatedFilter) => {
        let exploreExplorationClone = cloneDeep(excludeFromExploration);
        let filterValues = []
        if (exploreExplorationClone) {
            filterValues = exploreExplorationClone.find(item => item?.filter === operatedFilter)?.value
        }
        let isAlreadyChecked = filterValues.indexOf(value) >= 0;
        if (isAlreadyChecked) {
            for (let item of exploreExplorationClone) {
                if (item.filter === operatedFilter) {
                    item.value = item.value.filter(item => item !== value)
                }
            }
        }
        else {
            for (let item of exploreExplorationClone) {
                if (item.filter === operatedFilter) {
                    item.value = [...filterValues, value]
                }
            }
        }
        setExcludeFromExploration(exploreExplorationClone);
        setSelectedFilter("");
        setShowNewContainer(false);
    }

    const removeFilterFromExploration = filterCategory => {
        let exploreExplorationClone = excludeFromExploration.filter(item => item?.["filter"] !== filterCategory)
        setExcludeFromExploration(exploreExplorationClone);
        setShowNewContainer(true);
    }

    const getFilterValues = filter => {
        return exploreDataFilters?.[filter]?.histogram.map(item => item.key) || []
    }

    const getFilterResultValues = () => {
        const hasSelectedValues = exploreDataFilters?.[selectedFilter]?.histogram.find(item => item.isSelected);
        if (!hasSelectedValues) {
            return exploreDataFilters?.[selectedFilter]?.histogram.map(item => item.key) || []
        }
        else {
            return exploreDataFilters?.[selectedFilter]?.histogram.map(item => {
                if (item.key && item.isSelected) {
                    return item.key;
                }
            }).filter(item => item) || []
        }
    }

    const scrollToSelectedDropdown = (currentTarget)=>{
       currentTarget.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    } 
    const filterResultValues = getFilterResultValues();
    const filterItems = getFilterItems() || [];   

    useEffect(() => {
        if(open && showNewContainer){
            const scrollHeight = document.getElementById('exclude-filter-popover').scrollHeight;
            document.getElementById('exclude-filter-popover').scrollTo({behavior: "smooth", top: scrollHeight})
        }
    }, [open, showNewContainer]);

    const onAddMoreExclusionClick = () => {
        setShowNewContainer(true);
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={materialClasses.wrapper}>
                <div onClick={handleClick}>
                    <p className={classes.excludeSearch}>{title} ({excludeFromExploration.length})
                    <span className={classes.borderLeft}><img src={DownArrow} alt="Down Arrow" /></span>
                    </p>
                </div>
                {
                    open &&
                    <div className={classes.moreFiltersWrapper}>
                        <ul id='exclude-filter-popover' className={classes.moreFiltersPopover}>
                            {
                                excludeFromExploration && excludeFromExploration.length > 0 &&
                                excludeFromExploration.map(item => {
                                    const selectedItemsValue = item?.["value"].length > 1 ?
                                        item?.["value"][0] + `, ${item["value"].length - 1} More...` :
                                        item?.["value"][0] || "Select"
                                    return (
                                        <li key={v4()} className={classes.popOverCategory}>
                                            <span onClick={() => { removeFilterFromExploration(item?.["filter"]) }}>
                                                <img src={ImgClose} alt="Close" />
                                            </span>
                                            <label htmlFor='yAxis'>Filter</label>
                                            <DropDown
                                                disable
                                                selectedFilter={item?.["filter"]}
                                                values={filterItems}
                                                handleFilterStateChange={handleFilterStateChange}
                                                placeholder={'Select'}
                                                scrollToSelectedDropdown={scrollToSelectedDropdown}
                                                customClass={classes.customDropdownTitle}
                                                />
                                            <label htmlFor='yAxis'>{item?.["filter"]}</label>
                                            <DropDown
                                                searchable
                                                multiSelect
                                                selectedFilter={selectedItemsValue}
                                                selectedValues={item?.["value"]}
                                                filterCategory={item?.["filter"]}
                                                values={getFilterValues(item?.["filter"])}
                                                handleFilterStateChange={handleFilterStateAdded}
                                                placeholder={'Select'} 
                                                scrollToSelectedDropdown={scrollToSelectedDropdown}
                                                customClass={classes.customDropdownTitle}
                                                />
                                        </li>
                                    )
                                })
                            }
                            {
                                showNewContainer &&
                                <li className={classes.popOverCategory}>
                                    <label id='new-filter' htmlFor='yAxis'>Filter</label>
                                    <DropDown
                                        selectedFilter={selectedFilter}
                                        values={filterItems}
                                        handleFilterStateChange={handleFilterStateChange} 
                                        placeholder={'Select'}
                                        scrollToSelectedDropdown={scrollToSelectedDropdown}
                                        customClass={classes.customDropdownTitle}
                                        />
                                    {
                                        selectedFilter &&
                                        <>
                                            <label htmlFor='yAxis'>{selectedFilter}</label>
                                            <DropDown
                                                searchable
                                                multiSelect
                                                selectedFilter={"Select"}
                                                values={filterResultValues}
                                                handleFilterStateChange={handleFilterValuesChange}
                                                placeholder={'Select'} 
                                                scrollToSelectedDropdown={scrollToSelectedDropdown}
                                                customClass={classes.customDropdownTitle}
                                                />
                                        </>
                                    }

                                </li>
                            }
                        </ul>
                        <p className={classes.filterDetailsMore} onClick={onAddMoreExclusionClick}>
                            Add More Exclusions
                        </p>
                    </div>
                }
            </div>
        </ClickAwayListener>
    )
}

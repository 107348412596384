import React from 'react';
import {
  FolderOpenSharp,
  QueryStats,
  ListAltRounded,
  SupervisorAccountRounded,
} from '@mui/icons-material';

export function renderIcon(name) {
  switch (name) {
    case 'clinicalDataPoints':
      return <QueryStats fontSize="small" color="primary" />;
    case 'attributes':
      return <ListAltRounded fontSize="small" color="primary" />;
    case 'participants':
      return <SupervisorAccountRounded fontSize="small" color="primary" />;
    case 'projects':
      return <FolderOpenSharp fontSize="small" color="primary" />;
    default:
      return null;
  }
}

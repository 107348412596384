export const getAllQuestionaries = () => {
    return {
        "percentComplete": 40,
        "personas": ["AcademicScientist", "IndustrialExpert"],
        "selectedPersona": "AcademicScientist",
        "profiles": {
            "AcademicScientist": {
                "affiliations": ["University 1", "University 2"],
                "location": "Los Angeles, CA",
                "phone": {
                    "value": "+1-111-111-1111",
                    "isPrivate": true
                },
                "keyPublications": [
                    {
                        "name": "Blah-blah-blah",
                        "url": "https://www.google.com/"
                    }
                ],
                "projectDescription": {
                    "value": "",
                    "isPrivate": false
                },
                "keywords": ["Researcher", "Data Scientist"]
            },
            "IndustrialExpert": {
                "affiliations": [],
                "location": "",
                "phone": {
                    "value": "",
                    "isPrivate": true
                }
            }
        },
        "questionnaire": {
            "AcademicScientist": [
                {
                    "question": { "id": "TherapeuticAreaOfInterest", "text": "What are your therapeutic areas of interest?" },
                    "isMultipleChoice": true,
                    "isAllowCustomInput": true,
                    "isAnswered": true,
                    "options": [
                        { "text": "Oncology", "hint": "" },
                        { "text": "Infectious diseases", "hint": "" },
                        { "text": "Cardiovascular diseases", "hint": "" },
                        { "text": "Metabolic disorders", "hint": "" },
                        { "text": "Neurological diseases", "hint": "" },
                        { "text": "Bone/Osteoporosis", "hint": "" },
                        { "text": "Immunology", "hint": "" },
                        { "text": "Pulmonology", "hint": "" },
                        { "text": "CNS", "hint": "" },
                        { "text": "Inflammation", "hint": "" },
                        { "text": "Dermatology", "hint": "" },
                        { "text": "Internal diseases", "hint": "" },
                        { "text": "Respiratory", "hint": "" },
                        { "text": "Diabetes", "hint": "" },
                        { "text": "Rheumatology", "hint": "" },
                        { "text": "Endocrinology", "hint": "" },
                        { "text": "Nephrology", "hint": "" },
                        { "text": "Urology", "hint": "" },
                        { "text": "Gastroenterology", "hint": "" },
                        { "text": "Hematology", "hint": "" },
                        { "text": "Ophtalmology", "hint": "" },
                        { "text": "Thrombosis", "hint": "" }
                    ],
                    "answer": ["Oncology"]
                },
                {
                    "question": { "id": "MolecularTargetClass", "text": "What is molecular target class?" },
                    "isMultipleChoice": false,
                    "isAllowCustomInput": false,
                    "isAnswered": true,
                    "options": [
                        { "text": "RNA", "hint": "" },
                        { "text": "DNA", "hint": "" },
                        { "text": "Enzymes", "hint": "" },
                        { "text": "GPCRs", "hint": "" },
                        { "text": "Other cell-surface receptors", "hint": "" },
                        { "text": "Other proteins", "hint": "" }
                    ],
                    "answer": []
                },
                {
                    "question": { "id": "MainAreaOfInterest", "text": "What is your main area of interest?" },
                    "isMultipleChoice": true,
                    "isAllowCustomInput": true,
                    "isAnswered": false,
                    "options": [
                        { "text": "Target-Disease Linkage", "hint": "i.e. establishing the causal link between target and disease" },
                        { "text": "Target-related Safety aspects", "hint": "" },
                        { "text": "Innovation potential / Degree of Innovation", "hint": "i.e. medical and commercial needs" },
                        { "text": "Technical Feasibility and Druggability aspects", "hint": "i.e. target assayability, biomarker development" },
                        { "text": "Data Quality and Robustness requirements", "hint": "i.e. study design, unbiased study conduct" }
                    ],
                    "answer": []
                },
                {
                    "question": { "id": "TreatmentApproaches", "text": "What are your treatment approaches?" },
                    "isMultipleChoice": true,
                    "isAllowCustomInput": true,
                    "isAnswered": false,
                    "options": [
                        { "text": "Small molecules", "hint": "" },
                        { "text": "Biologics", "hint": "" }
                    ],
                    "answer": []
                },
                {
                    "question": { "id": "LookingFor", "text": "What are you looking for?" },
                    "isMultipleChoice": true,
                    "isAllowCustomInput": true,
                    "isAnswered": false,
                    "options": [
                        { "text": "Support in designing decision-enabling studies", "hint": "" },
                        { "text": "Support for designing high-quality 'killer' experiments and identifying Go/NoGo decision points", "hint": "" },
                        { "text": "Support in developing a project-specific Critical Path", "hint": "see GOT-IT guidelines - LINK" },
                        { "text": "Support in identifying project-specific 'value inflection points'", "hint": "" },
                        { "text": "Joint project development through academia-industry collaboration", "hint": "" },
                        { "text": "Partner for clinical phase of drug R&D project", "hint": "" },
                        { "text": "Access to new technologies and expertise", "hint": "" }
                    ],
                    "answer": []
                }
            ],
            "IndustrialExpert": [
                {
                    "question": { "id": "TargetValidationAreaOfExpertise", "text": "What is your target validation area of expertise?" },
                    "isMultipleChoice": true,
                    "isAllowCustomInput": true,
                    "isAnswered": false,
                    "options": [
                        { "text": "Data quality requirements and preclinical tool validation", "hint": "" },
                        { "text": "Decision-enabling studies", "hint": "" },
                        { "text": "Causality between target and disease", "hint": "" },
                        { "text": "In vivo Animal Models of Disease", "hint": "" },
                        { "text": "Target-related Safety and Toxicity", "hint": "" },
                        { "text": "Biomarker development", "hint": "" },
                        { "text": "Intellectual Property: FTO, Patentability", "hint": "" },
                        { "text": "Commercial needs", "hint": "" },
                        { "text": "HTS campaigns", "hint": "" }
                    ],
                    "answer": []
                },
                {
                    "question": { "id": "TherapeuticAreaOfInterest", "text": "What are your therapeutic areas of interest?" },
                    "isMultipleChoice": true,
                    "isAllowCustomInput": true,
                    "isAnswered": false,
                    "options": [
                        { "text": "Oncology", "hint": "" },
                        { "text": "Infectious diseases", "hint": "" },
                        { "text": "Cardiovascular diseases", "hint": "" },
                        { "text": "Metabolic disorders", "hint": "" },
                        { "text": "Neurological diseases", "hint": "" },
                        { "text": "Bone/Osteoporosis", "hint": "" },
                        { "text": "Immunology", "hint": "" },
                        { "text": "Pulmonology", "hint": "" },
                        { "text": "CNS", "hint": "" },
                        { "text": "Inflammation", "hint": "" },
                        { "text": "Dermatology", "hint": "" },
                        { "text": "Internal diseases", "hint": "" },
                        { "text": "Respiratory", "hint": "" },
                        { "text": "Diabetes", "hint": "" },
                        { "text": "Rheumatology", "hint": "" },
                        { "text": "Endocrinology", "hint": "" },
                        { "text": "Nephrology", "hint": "" },
                        { "text": "Urology", "hint": "" },
                        { "text": "Gastroenterology", "hint": "" },
                        { "text": "Hematology", "hint": "" },
                        { "text": "Ophtalmology", "hint": "" },
                        { "text": "Thrombosis", "hint": "" }
                    ],
                    "answer": []
                },
                {
                    "question": { "id": "LookingFor", "text": "What are you looking for?" },
                    "isMultipleChoice": true,
                    "isAllowCustomInput": true,
                    "isAnswered": false,
                    "options": [
                        { "text": "New projects for Academia-Industry collaborations", "hint": "" },
                        { "text": "New projects to invest in", "hint": "" },
                        { "text": "Access to new technologies and expertise", "hint": "" },
                        { "text": "Providing mentoring support to structure target validation and assessment processes", "hint": "" }
                    ],
                    "answer": []
                },
                {
                    "question": { "id": "MainAreaOfInterest", "text": "What is your main area of interest?" },
                    "isMultipleChoice": true,
                    "isAllowCustomInput": true,
                    "isAnswered": false,
                    "options": [
                        { "text": "Target-Disease Linkage", "hint": "i.e. establishing the causal link between target and disease" },
                        { "text": "Target-related Safety aspects", "hint": "" },
                        { "text": "Innovation potential / Degree of Innovation", "hint": "i.e. medical and commercial needs" },
                        { "text": "Technical Feasibility and Druggability aspects", "hint": "i.e. target assayability, biomarker development" },
                        { "text": "Data Quality and Robustness requirements", "hint": "i.e. study design, unbiased study conduct" }
                    ],
                    "answer": []
                },
            ]
        }
    }
}

export const getOnboardUserProfiles = () => {
    return {
            "percentComplete": 40,
            "selectedPersona": "AcademicScientist",
            "badges": []
        }
}
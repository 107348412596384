import {
    CHANGE_STEPPER_COUNT, SET_ONBOARDING_FIRST_NAME, SET_ONBOARDING_LAST_NAME,
    SET_USERS_DESIGNATION, SET_USERS_OTHER_DESIGNATION, SET_USERS_ORGANIZATION_NAME, SET_USERS_BIO, SET_PROFILE_PICTURE_OF_USER, SET_TAGS_INPUT_TEXT,
    SET_TAGS_LIST, SET_KEYWORDS_LIST, CLEAR_ONBOARD_FORM, SET_ONBOARD_INITIAL_STATE, SET_USERS_LINKEDIN_PROFILE, SET_USERS_ORCHID_PROFILE,
    SET_USERS_GITHUB_PROFILE, SET_USERS_TWITTER_PROFILE, SET_USERS_PERSONAL_PROFILE, SET_USERS_GOOGLE_PROFILE,
    SET_ONBOARDING_TITLE, SET_ONBOARDING_DEPARTMENT, SET_ONBOARDING_INSTITUTION_NAME, SET_ONBOARDING_CITY, SET_ONBOARDING_COUNTRY,
    SET_ONBOARDING_STATE, SET_ONBOARDING_SECTOR, SET_ONBOARDING_DEGREE, SET_RESEARCHER_GOALS, SET_ONBOARDING_HIGHEST_EDUCATION,
    SET_EDIT_STREET_ADDRESS, SET_EDIT_ZIP_CODE, SET_SCIENTIFIC_BACKGROUND, SET_ZONE_2_REQUEST_PERSONAL_DETAILS_INITIAL_STATE,
    SET_OTHER_ORG_NAME_PROP, SET_OTHER_HIGHEST_EDUCATION, SET_OTHER_ORGANIZATION_NAME
} from '../action-types'
import { USER_ONBOARD_FORM_ERROR_TEXT } from '../../constants/strings'

const initialState = {
    stepCount: 0,
    firstName: { text: '', isValid: false, isSubmit: false, invalidText: USER_ONBOARD_FORM_ERROR_TEXT.FIRST_NAME },
    lastName: { text: '', isValid: false, isSubmit: false, invalidText: USER_ONBOARD_FORM_ERROR_TEXT.LAST_NAME },
    designation: '',
    otherDesignation: '',
    organization: { text: '', isValid: false, isSubmit: false, },
    bio: '',
    profileImage: null,
    tagsInputText: '',
    keywordList: [],
    tagList: [],
    linkedin: '',
    google: '',
    twitter: '',
    personal: '',
    orchid: '',
    github: '',
    streetAddress: '',
    zipCode: '',
    title: { text: '', isValid: false, isSubmit: false, invalidText: "Please enter the title" },
    department: { text: '', isValid: false, isSubmit: false, invalidText: "Please enter the department" },
    institutionName: { text: '', isValid: false, isSubmit: false, invalidText: "Please enter the department" },
    city: { text: '', isValid: false, isSubmit: false, invalidText: "Please enter the city" },
    country: { text: '', isValid: false, isSubmit: false, invalidText: "Please enter the country" },
    stateName: { text: '', isValid: false, isSubmit: false, invalidText: "Please enter the state" },
    sector: { text: '', isValid: false, isSubmit: false, invalidText: "Please enter the sector" },
    degree: { text: '', isValid: false, isSubmit: false, invalidText: "Please enter the degree" },
    researchersGoals: { text: '', isValid: false, isSubmit: false, invalidText: "Please enter the researcher goals" },
    highestEducation: { text: '', isValid: false, isSubmit: false, },
    scientificBackground: { text: '', isValid: false, isSubmit: false, invalidText: "Please enter the scientific background" },
    otherOrgName: '',
    otherHighestEducation: { text: '', isValid: false, isSubmit: false, invalidText: 'Please enter valid highest education' },
    otherOrganizationName: '',
}

const userOnboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_OTHER_ORG_NAME": {
            return {
                ...state,
                otherOrganizationName: action.payload
            }
        }

        case CHANGE_STEPPER_COUNT: {
            return {
                ...state,
                stepCount: action.payload
            }
        }
        case SET_ONBOARDING_FIRST_NAME: {
            return {
                ...state,
                firstName: action.payload
            }
        }
        case SET_ONBOARDING_LAST_NAME: {
            return {
                ...state,
                lastName: action.payload
            }
        }
        case SET_ONBOARDING_DEPARTMENT: {
            return {
                ...state,
                department: action.payload
            }
        }
        case SET_ONBOARDING_COUNTRY: {
            return {
                ...state,
                country: action.payload
            }
        }
        case SET_ONBOARDING_DEGREE: {
            return {
                ...state,
                degree: action.payload
            }
        }
        case SET_ONBOARDING_SECTOR: {
            return {
                ...state,
                sector: action.payload
            }
        }
        case SET_ONBOARDING_INSTITUTION_NAME: {
            return {
                ...state,
                institutionName: action.payload
            }
        }
        case SET_ONBOARDING_STATE: {
            return {
                ...state,
                stateName: action.payload
            }
        }
        case SET_EDIT_STREET_ADDRESS: {
            return {
                ...state,
                streetAddress: action.payload
            }
        }
        case SET_EDIT_ZIP_CODE: {
            return {
                ...state,
                zipCode: action.payload
            }
        }
        case SET_ONBOARDING_TITLE: {
            return {
                ...state,
                title: action.payload
            }
        }
        case SET_ONBOARDING_CITY: {
            return {
                ...state,
                city: action.payload
            }
        }
        case SET_USERS_DESIGNATION: {
            return {
                ...state,
                designation: action.payload
            }
        }
        case SET_USERS_OTHER_DESIGNATION: {
            return {
                ...state,
                otherDesignation: action.payload
            }
        }
        case SET_USERS_ORGANIZATION_NAME: {
            return {
                ...state,
                organization: action.payload
            }
        }
        case SET_USERS_BIO: {
            return {
                ...state,
                bio: action.payload
            }
        }
        case SET_USERS_LINKEDIN_PROFILE: {
            return {
                ...state,
                linkedin: action.payload
            }
        }
        case SET_USERS_ORCHID_PROFILE: {
            return {
                ...state,
                orchid: action.payload
            }
        }
        case SET_USERS_TWITTER_PROFILE: {
            return {
                ...state,
                twitter: action.payload
            }
        }
        case SET_USERS_GITHUB_PROFILE: {
            return {
                ...state,
                github: action.payload
            }
        }
        case SET_USERS_PERSONAL_PROFILE: {
            return {
                ...state,
                personal: action.payload
            }
        }
        case SET_USERS_GOOGLE_PROFILE: {
            return {
                ...state,
                google: action.payload
            }
        }
        case SET_RESEARCHER_GOALS: {
            return {
                ...state,
                researchersGoals: action.payload
            }
        }
        case SET_PROFILE_PICTURE_OF_USER: {
            return {
                ...state,
                profileImage: action.payload
            }
        }
        case SET_TAGS_INPUT_TEXT: {
            return {
                ...state,
                tagsInputText: action.payload
            }
        }
        case SET_KEYWORDS_LIST: {
            return {
                ...state,
                keywordList: action.payload
            }
        }
        case SET_TAGS_LIST: {
            return {
                ...state,
                tagList: action.payload
            }
        }
        case SET_ONBOARDING_HIGHEST_EDUCATION: {
            return {
                ...state,
                highestEducation: action.payload
            }
        }
        case SET_SCIENTIFIC_BACKGROUND: {
            return {
                ...state,
                scientificBackground: action.payload
            }
        }
        case SET_OTHER_HIGHEST_EDUCATION: {
            return {
                ...state,
                otherHighestEducation: action.payload
            }
        }
        case CLEAR_ONBOARD_FORM: {
            return {
                stepCount: 0,
                firstName: { text: '', isValid: false, isSubmit: false, invalidText: USER_ONBOARD_FORM_ERROR_TEXT.FIRST_NAME },
                lastName: { text: '', isValid: false, isSubmit: false, invalidText: USER_ONBOARD_FORM_ERROR_TEXT.LAST_NAME },
                designation: '',
                otherDesignation: '',
                organization: '',
                bio: '',
                profileImage: null,
                tagsInputText: '',
                keywordList: [],
                tagList: [],
                linkedin: '',
                google: '',
                twitter: '',
                personal: '',
                orchid: '',
                github: '',
                highestEducation: '',
                otherHighestEducation: { text: '', isValid: false, isSubmit: false, invalidText: 'Please enter valid highest education' },
                otherOrganizationName: "",

            }
        }
        case SET_ONBOARD_INITIAL_STATE: {
            return {
                ...state,
                ...action.payload
            }
        }
        case SET_ZONE_2_REQUEST_PERSONAL_DETAILS_INITIAL_STATE : {
            return {
                ...state,
                ...action.payload
            }
        }
        default: return state
    }
}

export default userOnboardReducer;
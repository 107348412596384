import React, { useState, useEffect } from 'react';
import classes from './index.module.css'
import LoaderImg from '../../../assets/images/loader.gif'
import Modal from '../modal'
import DropZone from 'react-dropzone'
import DocumentIcon from '../../../assets/images/Doc.M.png'
import get from 'lodash/get'
import { postProblemAttachment, deleteAttachment, submitProblem } from "../../../api/report-problem-api"
import { v4 } from 'uuid'
import Grid from '@mui/material/Grid'
import CloseIcon from '../../../assets/icons/close-small.svg'
import { uploadFileToS3 } from '../../../utils/aws-sdk-helper';
import Skeleton from '@mui/material/Skeleton';
import ErrorText from '../../controls/error-text'

const MAX_FILE_SIZE = 2000000

const ReportAProblem = (props) => {
    const { open, handleClose, dialogProps } = props;
    const [uploadedData, setUploadedData] = useState([])
    const [errorMessage, setShowErrorMessage] = useState("");
    const [uploadingFile, setUploadingFile] = useState(null);
    const [requestId, setRequestId] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)
    const [description, setDescription] = useState("")
    const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("")
    const [isSubmit, setIsSubmit] = useState(false)

    const [wordCount, setWordCount] = useState(0)

    useEffect(() => {
        if (open) {
            setUploadedData([])
            setShowErrorMessage("")
            setUploadingFile(null)
            setRequestId(null)
            setIsDeleting(false)
            setDescription("")
            setDescriptionErrorMessage("")
        }
    }, [open])

    const onImageDrop = async (files) => {
        if(uploadedData?.length + files.length > 6){
            setShowErrorMessage("Cannot upload more than 6 files")
            return;
        }
        const fileSize = files?.map(({ size }) => size)
        if (fileSize.find(x => x > MAX_FILE_SIZE)) {
            setShowErrorMessage("Please upload files below 2 MB")
            return;
        }
        else {
            setShowErrorMessage("")
        }
        setUploadingFile(files)
        try {
            let request_id = requestId;
            let currentUploadedData = []
            for (let file of files) {
                let response = null;
                if (request_id) {
                    response = await postProblemAttachment({
                        file: file.name,
                        request_id: request_id
                    })
                } else {
                    response = await postProblemAttachment({
                        file: file.name,
                    })
                }
                setRequestId(response?.[0]?.request_id)
                request_id = response?.[0]?.request_id
               await uploadFileToS3(file, response?.[0]?.presigned_url, {})
                currentUploadedData.push({
                    id: v4(),
                    file
                })
            }
            setUploadedData([...uploadedData, ...currentUploadedData])
        }
        catch (error) {
            console.log(error)
        }
        setUploadingFile(null)
    }

    const onCancel = () => {
        handleClose();
    }

    const onSubmit = async () => {
        setIsSubmit(true)
        setDescriptionErrorMessage("Please fill in the description")
        try {
            const body = {
                "files": uploadedData.map(({ file }) => file.name),
                "description": description,
            }
            if (uploadedData?.length > 0) {
                body.request_id = requestId;
            }
            if (description) {
                await submitProblem(body);
                handleClose();
                setIsSubmit(false)
            }
            props.updateSnackBar('Form submitted successfully.', 'success');
        }
        catch (error) {
            props.updateSnackBar('Error in submission.', 'error');
            setIsSubmit(false)
        }
    }

    const modifiedDialogProps = {
        ...dialogProps,
        positiveButtonText: 'Send',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSubmit,
        negativeButtonAction: onCancel,
        popupButtonDisable: !(description && !isDeleting && !uploadingFile && !isSubmit)
    }

    const deleteFile = async deleteId => {
        setIsDeleting(true)
        try {
            const matchingFileName = uploadedData?.find(({ id }) => id === deleteId)?.file?.name;
            if (matchingFileName) {
                await deleteAttachment({
                    "file": matchingFileName,
                    "request_id": requestId
                })
                const currentUploadedData = uploadedData.filter(({ id }) => id !== deleteId)
                setUploadedData(currentUploadedData);
                if(currentUploadedData?.length === 0){
                    setRequestId("")
                }
            }
        }
        catch (error) {
            console.log(error)
        }
        setIsDeleting(false)
    }

    const truncateString = (text, truncateLength = 20) => {
        return text.length > 20 ? text.substring(0, 20) + "..." : text;
    }

    return (
        <Modal open={open} handleClose={handleClose} dialogProps={modifiedDialogProps} disableTitleDivider containerClass="report-a-problem">
            <Grid container>
                <Grid item md={12} xs={12} lg={12}>
                    <div className={classes.reportIssueTextarea}>
                        <label id="cvb-reportIssue-textAreaLabel">Please describe the problem <sup> *</sup></label>
                        <br />
                        <textarea rows={5} type="text"
                            value={description}
                            maxLength={1000}
                            onChange={e => { setDescription(e.target.value) }}
                            id="cvb-adminDashboard-proposalTextArea"
                            placeholder="To help us fix the problem, please be as detailed as possible. What did you expect and what happened instead?"
                        >
                        </textarea>
                        {
								<div className={`${description.trim().split(/\s+/).length > 1000 ? classes.maxWords : ''}`}>{description.trim() === '' ? 0 : description.trim().split(/\s+/).length}/1000 words</div>
						}
                        {
                            !description && isSubmit && descriptionErrorMessage &&
                            <ErrorText errorMessage={descriptionErrorMessage} />
                        }

                    </div>
                    <label id="cvb-reportIssue-attachLabel">Attach Screenshot</label>
                    <p>Upload format, format or format. Max file size allowed is 2MB.</p>
                    <div id='cvb-trainingAndCertificate-fileDropInput' className={classes.dropZoneButtonContainer}>
                        <DropZone
                            onDrop={(acceptedFiles) => onImageDrop(acceptedFiles)}
                            multiple={true}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className={classes.uploadImageDiv}>
                                        <img src={DocumentIcon} alt='document' />
                                        <p className={classes.chooseFileText}>
                                            Drag and drop files here or
                                            <span>
                                                <button id='cvb-reportAProblem-selectFile-button' className={`${classes.chooseFileButton}`}>
                                                    Select files
                                                </button>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </DropZone>
                    </div>
                    {
                        errorMessage &&
                        <div>
                            <ErrorText errorMessage={errorMessage} />
                        </div>
                    }
                    <div className={classes.uploadedView}>
                        {
                            uploadedData?.map(({ file, id }) => {
                                return <ul key={id}><li className={classes.uploadedChip}>
                                    {truncateString(file?.name) || ""}
                                    <span onClick={() => deleteFile(id)}><img src={CloseIcon} alt="close icon" /></span>
                                </li>
                                </ul>
                            })
                        }
                        {
                            uploadingFile &&
                            <div className={classes.uploadingTint}>
                                <p>Uploading in Progress</p>
                            </div>
                        }
                        {
                            isDeleting &&
                            <div className={classes.uploadingTint}>
                                <p>Deletion in Progress</p>
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>
        </Modal>
    );
}

export default ReportAProblem;

import Zone3AuthorizedUser from './Zone3AuthorizedUser'
import { connect } from 'react-redux'
import { updateZone3UsersFirstName, updateZone3UsersMiddleName, updateZone3UsersLastName, updateZone3UsersAffiliation, updateZone3UsersInstitutionalEmail
    , addZone3Users, removeZone3User
} from '../../../redux/actions/zone3UsersActions'
import { setDCAStage } from '../../../redux/actions/dcaActions'
const mapStateToProps = state => {
    return {
        zone3Users: state.zone3AuthorizedUsers.zone3Users,
        projectDetailsDesignatedZone: state.projectDetails.projectDetailsDesignatedZone,
        dcaStage: state.dca.dcaStage,
        currentDCAFormStage: state.dca.currentDCAFormStage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateZone3UsersFirstName: async (value, id) => await dispatch(updateZone3UsersFirstName(value, id)),
        updateZone3UsersMiddleName: async (value, id) => await dispatch(updateZone3UsersMiddleName(value, id)),
        updateZone3UsersLastName: async (value, id) => await dispatch(updateZone3UsersLastName(value, id)),
        updateZone3UsersAffiliation: async (value, id) => await dispatch(updateZone3UsersAffiliation(value, id)),
        updateZone3UsersInstitutionalEmail: async (value, id) => await dispatch(updateZone3UsersInstitutionalEmail(value, id)),
        addZone3Users: async (value) => await dispatch(addZone3Users(value)),
        removeZone3User: async (value) => await dispatch(removeZone3User(value)),
        setDCAStage: async (value) => await dispatch(setDCAStage(value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Zone3AuthorizedUser)
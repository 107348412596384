import WorkspaceFileDownloadOrders from './WorkspaceFileDownloadOrders'
import { connect } from 'react-redux';
import { updateSnackBar } from '../../../redux/actions/globalActions';
import { updateSelectedWorkspaceFileData } from '../../../redux/actions/myStuffActions'
const mapStateToProps = state => {
    return {
        userDetails: state.user.userDetails,
        allWorkspaces: state.workspace.allWorkspaces,
        allWorkspacesCarts: state.workspace.allWorkspacesCarts
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateSnackBar: async (message, type) => dispatch(updateSnackBar(message, type)),
        updateSelectedWorkspaceFileData:  async (data) => dispatch(updateSelectedWorkspaceFileData(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFileDownloadOrders)
/**
 *  External Imports
 */
import React, { useState, useEffect } from "react";
import { string } from "prop-types";

/**
 *  Internal Imports
 */

import styles from "./index.module.css";
import { SUBMITTED_FEEDBACK_MESSAGE_OPTIONS } from "../../../../constants/feedback";

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 */
const FeedbackInfo = (props) => {
    const { messageType } = props;
    const [currentFeedbackMessage, setCurrentFeedbackMessage] = useState(null);

    useEffect(() => {
        const currentMessage = SUBMITTED_FEEDBACK_MESSAGE_OPTIONS[messageType];
        setCurrentFeedbackMessage(currentMessage);
    }, [messageType]);

    return (
        <div className={styles.feedbackInfo}>
            {currentFeedbackMessage && (
                <>
                   {messageType === 'error' ?  
                    <svg data-testid="messageImg" className={styles.feedbackInfoIcon} fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                        <path d="m16 32c-3.1645 0-6.2579-0.9384-8.8891-2.6965-2.6312-1.7581-4.6819-4.2569-5.893-7.1806-1.211-2.9236-1.5279-6.1406-0.91049-9.2443 0.61736-3.1037 2.1412-5.9547 4.3789-8.1923s5.0886-3.7615 8.1923-4.3789c3.1037-0.61736 6.3207-0.30051 9.2443 0.91049 2.9237 1.211 5.4225 3.2618 7.1806 5.893 1.7581 2.6312 2.6965 5.7246 2.6965 8.8891-0.0127 4.2396-1.7024 8.3019-4.7003 11.3-2.9978 2.9979-7.0601 4.6876-11.3 4.7003zm0-30c-1.8417-0.01142-3.6673 0.3429-5.371 1.0424-1.7038 0.69953-3.2516 1.7303-4.554 3.0326-1.3023 1.3023-2.3331 2.8502-3.0326 4.554-0.69952 1.7037-1.0538 3.5293-1.0424 5.371-0.01142 1.8417 0.3429 3.6673 1.0424 5.371 0.69953 1.7038 1.7303 3.2517 3.0326 4.554 1.3023 1.3023 2.8502 2.3331 4.554 3.0326 1.7037 0.6995 3.5293 1.0538 5.371 1.0424 1.8417 0.0114 3.6673-0.3429 5.371-1.0424 1.7038-0.6995 3.2517-1.7303 4.554-3.0326s2.3331-2.8502 3.0326-4.554c0.6995-1.7037 1.0538-3.5293 1.0424-5.371 0.0114-1.8417-0.3429-3.6673-1.0424-5.371-0.6995-1.7038-1.7303-3.2516-3.0326-4.554-1.3023-1.3023-2.8502-2.3331-4.554-3.0326-1.7037-0.69952-3.5293-1.0538-5.371-1.0424zm1 10h-2v12h2v-12zm-2-4h2v2h-2v-2z" clipRule="evenodd" fill="#f00" fillRule="evenodd"/>
                    </svg>
                    :  <img 
                    data-testid="messageImg"
                    className={styles.feedbackInfoIcon}
                    src={currentFeedbackMessage.image}
                    alt={currentFeedbackMessage.imageAlt}
                />}
                    <div>
                        <h3 className={styles.feedbackInfoTitle} data-testid="messageTitle">
                            {currentFeedbackMessage.title}
                        </h3>
                        <p className={styles.feedbackInfoText} data-testid="messageText">
                            {currentFeedbackMessage.text} If you still have
                            questions, please send an email to our{" "}
                            <a data-testid="messageEmail" href={`mailto:${currentFeedbackMessage.email}`}>
                                support address
                            </a>{" "}
                            and we'll respond as quickly as possible.
                        </p>
                    </div>
                </>
            )}
        </div>
    );
};

/**
 *  Properties
 */
FeedbackInfo.propTypes = {
    messageType: string.isRequired,
};

/**
 *  Exports
 */
export default FeedbackInfo;

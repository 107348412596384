import {  SET_DIF_PI_STREET_ADDRESS, SET_DIF_PI_COUNTRY,
    SET_DIF_PI_STATE, SET_DIF_PI_CITY, SET_DIF_PI_MUNICIPALITY, SET_DIF_PI_ZIP_CODE 
    , SET_DIF_PI_FIRST_NAME, SET_DIF_PI_LAST_NAME, SET_DIF_PI_PHONE_NUMBER, SET_DIF_PI_DEPARTMENT_NAME, 
    SET_DIF_PI_EMAIL, SET_PRIMARY_INVESTIGATION_FORM_DATA, RESET_PRIMARY_INVESTIGATION_FORM_DATA
} from '../action-types'

export const setDifPIStreetAddress = (value) => {
    return {
        type: SET_DIF_PI_STREET_ADDRESS,
        payload: value
    }
}

export const setDifPICountry = (value) => {
    return {
        type: SET_DIF_PI_COUNTRY,
        payload: value
    }
}
export const setDifPIState = (value) => {
    return {
        type: SET_DIF_PI_STATE,
        payload: value
    }
}
export const setDifPICity = (value) => {
    return {
        type: SET_DIF_PI_CITY,
        payload: value
    }
}
export const setDifPIMunicipality = (value) => {
    return {
        type: SET_DIF_PI_MUNICIPALITY,
        payload: value
    }
}
export const setDifPIZipCode = (value) => {
    return {
        type: SET_DIF_PI_ZIP_CODE,
        payload: value
    }
}
export const setDifPIFirstName = (value) => {
    return {
        type: SET_DIF_PI_FIRST_NAME,
        payload: value
    }
}
export const setDifPILastName = (value) => {
    return {
        type: SET_DIF_PI_LAST_NAME,
        payload: value
    }
}
export const setDifPIPhoneNumber = (value) => {
    return {
        type: SET_DIF_PI_PHONE_NUMBER,
        payload: value
    }
}
export const setDifPIDepartmentName = (value) => {
    return {
        type: SET_DIF_PI_DEPARTMENT_NAME,
        payload: value
    }
}
export const setDifPIEmail = (value) => {
    return {
        type: SET_DIF_PI_EMAIL,
        payload: value
    }
}
export const setPrimaryInvestigatorData = (value) => {
    return {
        type: SET_PRIMARY_INVESTIGATION_FORM_DATA,
        payload: value
    }
}
export const resetPrimaryInvestigatorData = () => {
    return {
        type: RESET_PRIMARY_INVESTIGATION_FORM_DATA,
    }
}

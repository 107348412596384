import {
  ADD_DIF_INDIVIDUAL_DATASET,
  SET_DIF_INDIVIDUAL_DATASET_ACTIVE_INDEX,
  UPDATE_DI_DATASET_NAME,
  UPDATE_DI_OWNER_TYPE,
  UPDATE_DI_OWNER_NAME,
  UPDATE_DI_OWNER_TITLE,
  UPDATE_DI_OWNER_AFFILIATION,
  UPDATE_DI_OWNER_EMAIL,
  UPDATE_DI_ABSTRACT,
  UPDATE_DI_AGENCY_NAME,
  UPDATE_DI_AGENCY_NUMBER,
  UPDATE_DI_DISEASE_AREA,
  UPDATE_DI_CURATED_TO_STANDARD,
  UPDATE_DI_STANDARD,
  UPDATE_DI_RESTRICTED_ACCESS,
  UPDATE_DI_ALLOW_DATA_DOWNLOAD,
  UPDATE_DI_NUMBER_OF_SAMPLES,
  UPDATE_DI_ESTIMATED_SIZE,
  UPDATE_DI_TIME_SERIES,
  UPDATE_DI_DATASET_COMPLETE,
  UPDATE_DI_UPLOAD_COMPLETE_DATE,
  UPDATE_DI_SUBJECT_SPACE_STUDY,
  UPDATE_DI_SUBJECT_SPACE_CAPTURE_INFO,
  UPDATE_DI_BIOSPECIMEN_STUDY,
  UPDATE_DI_BIOSPECIMEN_CAPTURE_INFO,
  UPDATE_CAN_YOU_PROVIDE_DATA_DICT,
  UPDATE_OTHER_IMAGING_TEXT,
  UPDATE_OTHER_PRECLINICAL_TEXT,
  UPDATE_OTHER_GENOMIC_TEXT,
  UPDATE_OTHER_PROTEOMIC_TEXT,
  UPDATE_OTHER_WHOLE_GENOME_TEXT,
  UPDATE_IMAGING_OPTION,
  UPDATE_PRECLINICAL_OPTION,
  UPDATE_GENOMIC_OPTION,
  UPDATE_PROTEOMIC_OPTION,
  UPDATE_WHOLE_GENOME_OPTION,
  UPDATE_OTHER_TYPE_OF_DATA_TEXT,
  UPDATE_IS_CLINICAL,
  SET_INDIVIDUAL_DATASET_FORM_DATA,
  UPDATE_IS_PUBLICATION_PRESENT,
  ADD_DIF_PUBLICATIONS,
  UPDATE_PUBLICATION_NAME,
  UPDATE_PUBLICATION_URL,
  REMOVE_DIF_PUBLICATIONS,
  SET_DATA_DICT_DOCUMENTS,
  SET_DATA_DICT_RELATIONSHIP_DOCUMENTS,
  REMOVE_SELECTED_DATASET_TAB,
  RESET_INDIVIDUAL_DATASET_FORM,
  UPDATE_DI_DATASET_MORE_DATA_ADDED,
  UPDATE_MODAL_SYSTEM,
  UPDATE_MODAL_SYSTEM_OTHER_TEXT,
  UPDATE_DISEASE_FEATURE,
  UPDATE_TYPE_OF_DATA_DISEASE_AREA,
  UPDATE_TYPE_OF_DATA_DISEASE_AREA_TEXT,
  UPDATE_METABOLOMIC_OPTION,
  UPDATE_BEHAVIORAL_TEXT,
  UPDATE_PHYSIOLOGICAL_OUTCOME_TEXT,
  UPDATE_TISSUE_SAMPLE,
  UPDATE_BEHAVIORAL_ASSESSMENT,
  UPDATE_WEARABLE_SENSORS,
  UPDATE_DERIVED_SENSOR_DATA,
  UPDATE_DO_YOU_NEED_DATA_CURATED_SERVICE,
  UPDATE_IS_DATA_CURATED,
  UPDATE_CURATION_DATE,
  UPDATE_DATASET_CURATOR_NAME,
  UPDATE_DATASET_CURATOR_EMAIL,
  UPDATE_DATASET_CURATOR_INSTITUTION,
  UPDATE_OTHER_METABOLOMIC_TEXT,
  UPDATE_WEARABLE_SENSORS_OTHER_TEXT,
  UPDATE_TISSUE_SAMPLE_OTHER_TEXT,
  UPDATE_DERIVED_SENSOR_DATA_OTHER_TEXT,
  REMOVE_DATA_DICT_DOCUMENTS
} from '../action-types'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty';

const initialState = {
  individualDatasets: [
    {
      "dataset_name": "",
      "data_owner_type": "",
      "owner_name": "",
      "owner_title": "",
      "owner_affiliation": "",
      "owner_email": "",
      "abstract": "",
      "disease_area": "",
      "type_of_data": "",
      "clinical": {
        "imaging": "",
        "imaging_other": "",
        "genomic": "",
        "genomic_other": "",
        "proteomic": "",
        "transcriptomic": "",
        "transcriptomic_other": "",
        "other": "",
        "tissueSample": "",
        "tissueSample_other": "",
        "metabolomic": "",
        "metabolomic_other": "",
        "disease_area": "",
        "disease_area_other": "",
        "behavioral_assessment": "",
        "wearable_sensors": "",
        "wearable_sensors_other": "",
        "derived_sensor_data": "",
        "derived_sensor_data_other": ""
      },
      "preclinical": {
        "imaging": "",
        "imaging_other": "",
        "genomic": "",
        "genomic_other": "",
        "proteomic": "",
        "transcriptomic": "",
        "transcriptomic_other": "",
        "other": "",
        "tissueSample": "",
        "tissueSample_other": "",
        "modalSystem": "",
        "modalSystem_other": "",
        "diseaseFeature": "",
        "metabolomic": "",
        "metabolomic_other": "",
        "behavioralTask": "",
        "physiologicalOutcome": "",
        "disease_area": "",
        "disease_area_other": ""
      },
      "is_data_curated_to_any_standard": "",
      "dataset_name_and_standards": [],
      "restrict_data_access": "",
      "allow_data_download_by_others": "",
      "number_of_samples_in_dataset": '',
      "data_size": '',
      "is_data_longitudinal_or_time_series": '',
      "is_data_set_complete": "",
      "will_more_data_added": "",
      "data_upload_completed_date": "",
      "can_you_provide_data_dict": "",
      "data_dictionaries": [],
      "data_dictionaries_relationship_document": "",
      "subject_space": {
        "subject_belong_to_another_study": "",
        "do_you_want_to_capture_this_information": ""
      },
      "biospecimen_space": {
        "samples_belong_to_another_study": "",
        "do_you_want_to_capture_this_information": ""
      },
      "do_you_need_brain_commons_curation_services": "",
      "is_your_data_curated": "",
        "curator": {
          "name": "",
          "email": "",
          "institution": ""
        },
        "curation_date": ""
    }
  ],
  difDatasetActiveIndex: 0
}

const difIndividualDatasetReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DIF_INDIVIDUAL_DATASET: {
      let newDifDataset = cloneDeep(state.individualDatasets);
      newDifDataset.push(action.payload);
      return {
        ...state,
        individualDatasets: newDifDataset
      }
    }
    case SET_DIF_INDIVIDUAL_DATASET_ACTIVE_INDEX: {
      return {
        ...state,
        difDatasetActiveIndex: action.payload
      }
    }
    case UPDATE_DI_DATASET_NAME: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'dataset_name': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DI_OWNER_TYPE: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'data_owner_type': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DI_OWNER_NAME: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'owner_name': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DI_OWNER_TITLE: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'owner_title': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DI_OWNER_AFFILIATION: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'owner_affiliation': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DI_OWNER_EMAIL: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'owner_email': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case UPDATE_DI_ABSTRACT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'abstract': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case UPDATE_DI_DISEASE_AREA: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'disease_area': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case UPDATE_DI_CURATED_TO_STANDARD: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'is_data_curated_to_any_standard': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DI_STANDARD: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'dataset_name_and_standards': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DI_RESTRICTED_ACCESS: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'restrict_data_access': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
     case UPDATE_DI_ALLOW_DATA_DOWNLOAD: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'allow_data_download_by_others': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case UPDATE_DI_NUMBER_OF_SAMPLES: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'number_of_samples_in_dataset': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DI_ESTIMATED_SIZE: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'data_size': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DI_TIME_SERIES: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'is_data_longitudinal_or_time_series': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DI_DATASET_COMPLETE: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'is_data_set_complete': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DI_DATASET_MORE_DATA_ADDED: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'will_more_data_added': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DI_UPLOAD_COMPLETE_DATE: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'data_upload_completed_date': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
     case UPDATE_DI_SUBJECT_SPACE_STUDY: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'subject_space': {
          ...newDatasets[id]['subject_space'],
          "subject_belong_to_another_study" : value
        }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
     case UPDATE_DI_SUBJECT_SPACE_CAPTURE_INFO: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'subject_space': {
          ...newDatasets[id]['subject_space'],
          "do_you_want_to_capture_this_information" : value
        }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } case UPDATE_DI_BIOSPECIMEN_STUDY: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'biospecimen_space': {
          ...newDatasets[id]['biospecimen_space'],
          "samples_belong_to_another_study" : value
        }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DI_BIOSPECIMEN_CAPTURE_INFO: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'biospecimen_space': {
          ...newDatasets[id]['biospecimen_space'],
          "do_you_want_to_capture_this_information" : value
        }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_CAN_YOU_PROVIDE_DATA_DICT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'can_you_provide_data_dict': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_OTHER_IMAGING_TEXT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "imaging_other" : value
          }
      }
      
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_OTHER_PRECLINICAL_TEXT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "precilinical_other" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_OTHER_GENOMIC_TEXT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "genomic_other" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_OTHER_PROTEOMIC_TEXT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "proteomic_other" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_OTHER_WHOLE_GENOME_TEXT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "transcriptomic_other" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_IMAGING_OPTION: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "imaging" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_PRECLINICAL_OPTION: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "precilinical" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_GENOMIC_OPTION: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "genomic" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_PROTEOMIC_OPTION: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "proteomic" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_WHOLE_GENOME_OPTION: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "transcriptomic" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_OTHER_TYPE_OF_DATA_TEXT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "other" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_IS_CLINICAL: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'type_of_data': value
        }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_MODAL_SYSTEM: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "modalSystem" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_MODAL_SYSTEM_OTHER_TEXT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "modalSystem_other" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DISEASE_FEATURE: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "diseaseFeature" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_TYPE_OF_DATA_DISEASE_AREA: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "disease_area" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_TYPE_OF_DATA_DISEASE_AREA_TEXT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "disease_area_other" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }     
    case UPDATE_METABOLOMIC_OPTION: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "metabolomic" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case UPDATE_BEHAVIORAL_TEXT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "behavioralTask" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }  
    case UPDATE_PHYSIOLOGICAL_OUTCOME_TEXT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "physiologicalOutcome" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }  
    case UPDATE_WEARABLE_SENSORS_OTHER_TEXT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "wearable_sensors_other" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }  
    case UPDATE_TISSUE_SAMPLE: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "tissueSample" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_TISSUE_SAMPLE_OTHER_TEXT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "tissueSample_other" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    
    case UPDATE_BEHAVIORAL_ASSESSMENT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "behavioral_assessment" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }  
    case UPDATE_WEARABLE_SENSORS: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "wearable_sensors" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case UPDATE_DERIVED_SENSOR_DATA: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "derived_sensor_data" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }  
    case UPDATE_DERIVED_SENSOR_DATA_OTHER_TEXT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "derived_sensor_data_other" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }  
    case UPDATE_OTHER_METABOLOMIC_TEXT: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
          [newDatasets[id].type_of_data]:{
          ...newDatasets[id][newDatasets[id].type_of_data],
          "metabolomic_other" : value
          }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case SET_INDIVIDUAL_DATASET_FORM_DATA : {
      return {
        ...state,
        individualDatasets: action.payload
      }
    }
    case UPDATE_IS_PUBLICATION_PRESENT : {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'isPublicationPresent': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case ADD_DIF_PUBLICATIONS : {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      let newPublications = newDatasets[id]['publications'];
      if(!isEmpty(value)){
        newPublications.push(value);
      }else{
        newPublications = [];
      }
      newDatasets[id] = {
        ...newDatasets[id],
        'publications': newPublications
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_PUBLICATION_NAME : {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'publications': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }  
    case UPDATE_PUBLICATION_URL : {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'publications': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }  
    case REMOVE_DIF_PUBLICATIONS : {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'publications': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }  
    case SET_DATA_DICT_DOCUMENTS : {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id]['data_dictionaries'].push(value);
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case SET_DATA_DICT_RELATIONSHIP_DOCUMENTS : {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'data_dictionaries_relationship_document': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }  
    case REMOVE_SELECTED_DATASET_TAB: {
      const id = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets.splice(id, 1);
      return {
        ...state,
        individualDatasets: newDatasets
      }
    }
    case UPDATE_DO_YOU_NEED_DATA_CURATED_SERVICE: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'do_you_need_brain_commons_curation_services': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case UPDATE_IS_DATA_CURATED: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'is_your_data_curated': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case UPDATE_CURATION_DATE: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'curation_date': value
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case UPDATE_DATASET_CURATOR_NAME: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'curator':{
          ...newDatasets[id]['curator'],
          'name': value
        }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case UPDATE_DATASET_CURATOR_EMAIL: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'curator':{
          ...newDatasets[id]['curator'],
          'email': value
        }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case UPDATE_DATASET_CURATOR_INSTITUTION: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      newDatasets[id] = {
        ...newDatasets[id],
        'curator':{
          ...newDatasets[id]['curator'],
          'institution': value
        }
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case REMOVE_DATA_DICT_DOCUMENTS: {
      const { value, id } = action.payload;
      let newDatasets = cloneDeep(state.individualDatasets);
      let tempDataDict = newDatasets[id]['data_dictionaries'];
      if(tempDataDict.length > 0){
        tempDataDict.splice(value, 1);
      }
      newDatasets[id] = {
        ...newDatasets[id],
        'data_dictionaries': tempDataDict
      }
      return {
        ...state,
        individualDatasets: newDatasets
      }
    } 
    case RESET_INDIVIDUAL_DATASET_FORM: {
      return {
        individualDatasets: [
          {
            "dataset_name": "",
            "data_owner_type": "",
            "owner_name": "",
            "owner_title": "",
            "owner_affiliation": "",
            "owner_email": "",
            "abstract": "",
            "disease_area": "",
            "type_of_data": "",
            "clinical": {
              "imaging": "",
              "imaging_other": "",
              "genomic": "",
              "genomic_other": "",
              "proteomic": "",
              "transcriptomic": "",
              "transcriptomic_other": "",
              "other": "",
              "tissueSample": "",
              "tissueSample_other": "",
              "metabolomic": "",
              "metabolomic_other": "",
              "disease_area": "",
              "disease_area_other": "",
              "behavioral_assessment": "",
              "wearable_sensors": "",
              "wearable_sensors_other": "",
              "derived_sensor_data": "",
              "derived_sensor_data_other": ""
            },
            "preclinical": {
              "imaging": "",
              "imaging_other": "",
              "genomic": "",
              "genomic_other": "",
              "proteomic": "",
              "transcriptomic": "",
              "transcriptomic_other": "",
              "other": "",
              "tissueSample": "",
              "tissueSample_other": "",
              "modalSystem": "",
              "modalSystem_other": "",
              "diseaseFeature": "",
              "metabolomic": "",
              "metabolomic_other": "",
              "behavioralTask": "",
              "physiologicalOutcome": "",
              "physiologicalOutcome_other": "",
              "disease_area": "",
              "disease_area_other": ""
            },
            "is_data_curated_to_any_standard": "",
            "dataset_name_and_standards": [],
            "restrict_data_access": "",
            "allow_data_download_by_others": "",
            "number_of_samples_in_dataset": '',
            "data_size": '',
            "is_data_longitudinal_or_time_series": '',
            "is_data_set_complete": "",
            "will_more_data_added": "",
            "data_upload_completed_date": "",
            "can_you_provide_data_dict": "",
            "data_dictionaries": [],
            "data_dictionaries_relationship_document": "",
            "subject_space": {
              "subject_belong_to_another_study": "",
              "do_you_want_to_capture_this_information": ""
            },
            "biospecimen_space": {
              "samples_belong_to_another_study": "",
              "do_you_want_to_capture_this_information": ""
            },
            "do_you_need_brain_commons_curation_services": "",
            "is_your_data_curated": "",
            "curator": {
              "name": "",
              "email": "",
              "institution": ""
            },
            "curation_date": ""
          }
        ],
        difDatasetActiveIndex: 0
      }
    }
    default: return state
  }
  
}

export default difIndividualDatasetReducer;
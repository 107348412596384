import React, {useState, useEffect} from 'react'
import SectionHeader from '../../commons/section-header'
import classes from './index.module.css'
import BackButtonIcon from '../../../assets/icons/left_arrow.png'
import Grid from '@mui/material/Grid'
import ImageWorkspace from '../../../assets/icons/024-book-1.svg';
import WorkspaceFile from '../../controls/workspace-file';
import {getPricingOptions, submitPaymentDetails} from '../../../api/pricing-api';
import v4 from 'uuid/v4';
import { navigate } from '@reach/router';
import Modal from '../../commons/modal'
import OutlinedInput from '@mui/material/OutlinedInput'
import {formatBytes} from '../../../utils/file-size-formatter';
import moment from 'moment'
import lowerCase from 'lodash/lowerCase'
import OrderSuccess from './OrderSuccess'
import useScrollToTop from '../../commons/scroll-top';
import ErrorText from '../../controls/error-text';
import {getAllWorkspaceCarts} from '../../../api/workspace-api/workspace-api';
import {getIndividualStudyProjectOrderData} from '../../../api/study-api';
import Store from '../../../redux/store';
import { setAllWorkspacesCarts } from '../../../redux/actions/workspaceActions'
import isEmpty from 'lodash/isEmpty';

const WorkspaceFileDownloadRequestDetails = (props) => {
    const {workspaceId, orderId, projectId} = props;
    const [orderDetails, setOrderDetails] = useState({});
    const [pricingOptions, setPricingOptions] = useState([]);
    const [pricingOptionSelected, setPricingOptionSelected] = useState('');
    const [selectedPricingOptionDetails, setSelectedPricingOptionDetails] = useState('');
    const [s3CopyModal, setS3CopyModal] = useState(false);
    const [s3BucketName, setS3BucketName] = useState('');
    const [orderSuccess, setOrderSuccess] = useState(false);
    const [s3Policy, setS3Policy] = useState('');
    const sectionHeaderProps = {
        title: workspaceId ? "My WorkSpace File Download Orders" : "My Project Download Requests",
        pageDefinition: "",
    }
    const[isS3CopyInProgress, setIsS3CopyInProgress] = useState(false);
    const[isPaymentInProgress, setIsPaymentInProgress] = useState(false);
    const [formIsSubmitted, setFormIsSubmitted] = useState(false);
    const [downloadFormIsSubmitted, setDownloadFormIsSubmitted] = useState(false);

    useScrollToTop()

    useEffect(() => {
        if(workspaceId && props.allWorkspacesCarts?.workspace_carts && 
            (workspaceId in  props.allWorkspacesCarts?.workspace_carts) && 
            props.allWorkspacesCarts.workspace_carts[workspaceId]?.length ){
            for(let order of props.allWorkspacesCarts.workspace_carts[workspaceId]){
                if(order.order_id === orderId){
                    let fileSize = 0;
                    for(let file of order?.files){
                        fileSize = fileSize + file.size
                    }
                    const dayStr = moment.utc(order?.created_at)?.local().format('MM/DD/YYYY')
                    const timeStr = moment.utc(order?.created_at)?.local().format('hh:mm A')
                    order.totalFileSize = formatBytes(fileSize);
                    order.date = `${dayStr} at ${timeStr}`
                    setOrderDetails(order)
                    return
                }
            }
        }
    }, [props.allWorkspacesCarts])

    useEffect(() => {
        if(props?.location?.state?.selectedProject){
            const dayStr = moment.utc(props.location.state.selectedProject?.created_at)?.local().format('MM/DD/YYYY')
            const timeStr = moment.utc(props.location.state.selectedProject?.created_at)?.local().format('hh:mm A') 
            props.location.state.selectedProject.date = `${dayStr} at ${timeStr}`
            setOrderDetails(props.location.state.selectedProject)
        }else if(projectId){
            getIndividualStudyProjectOrderDetails();
        }
    },[props?.location?.state?.selectedProject])

    const getIndividualStudyProjectOrderDetails = async () => {
        try{
            const response = await getIndividualStudyProjectOrderData(orderId);
            const dayStr = moment.utc(response?.created_at)?.local().format('MM/DD/YYYY')
            const timeStr = moment.utc(response?.created_at)?.local().format('hh:mm A') 
            response.date = `${dayStr} at ${timeStr}`
            setOrderDetails(response);
        }catch(error){
            console.log(error)
        }
    }

    useEffect(() => {
        getPricingOptionDetails()
    },[orderId])

    const getPricingOptionDetails = async () => {
        try{
            const downloadType = workspaceId ? 'WORKSPACE' : 'PROJECT';
            const response = await getPricingOptions(downloadType, orderId);
            let pricingValues = []
            for(let pricingOption of response){
                let label = ''
                let renderAsHTML = false;
                label = `${pricingOption?.label}: $${pricingOption?.cost} ${pricingOption?.note}`
                if(pricingOption?.cost === 0){
                    label = `${pricingOption?.label}: FREE ${pricingOption?.note}`
                }
                if(pricingOption?.cost !== pricingOption?.original_cost && pricingOption?.cost !== 0){
                    label = pricingOption?.label;
                    renderAsHTML = true;
                }
                // if(pricingOption?.cost === 0){
                //     label = `${pricingOption?.label}: ${pricingOption?.note}`
                // }
                pricingValues.push({
                    ...pricingOption,
                    label: label,
                    renderAsHTML: renderAsHTML,
                    id: pricingOption?.id,
                    disabled: !pricingOption?.status?.enabled
                })
                if(pricingOption?.name?.toLowerCase()?.includes('s3')){
                    setS3Policy(pricingOption?.s3_policy)
                }
            }
            setPricingOptions(pricingValues);
        }catch(error){
            console.log(error)
        }
    }


    
    const downloadURL = (url,count) => {
        let hiddenIFrameID = 'hiddenDownloader' + count++;
        let iframe = document.createElement('iframe');
        iframe.id = hiddenIFrameID;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.src = url;
    }

    const submitPayment = async () => {
        if(!pricingOptionSelected){
            setDownloadFormIsSubmitted(true)
            return
        }
        if(selectedPricingOptionDetails?.name?.includes("s3")){
            setS3CopyModal(true)
            return
        }
        try{
            setIsPaymentInProgress(true)
            const downloadType = workspaceId ? 'WORKSPACE' : 'PROJECT';
            const response = await submitPaymentDetails(downloadType, orderId, pricingOptionSelected)
            if(response?.length){
                for (let i = 0; i < response.length; i++) {
                    downloadURL(response[i], i);
                }
            }
            const allWorkspaceCarts = await getAllWorkspaceCarts();
            if(!isEmpty(allWorkspaceCarts)){
                Store.dispatch(setAllWorkspacesCarts(allWorkspaceCarts))
            }
            setOrderSuccess(true)
        }catch(error){
            console.log(error)
            props.updateSnackBar(error?.message || "Your files could not be downloaded", "Error");
        }finally{
            setIsPaymentInProgress(false)
        }
    }

    const onPricingOptionChange = (e) => {
        setPricingOptionSelected(e.target.value);
        for (let i = 0; i < pricingOptions.length; i++) {
            if(pricingOptions[i].id == e.target.value){
                setSelectedPricingOptionDetails(pricingOptions[i])
            }
        }
    }

    const closeS3CopyModal = () => {
        setS3CopyModal(false);
    }

    const onS3Copy = async () => {
        try{
            setFormIsSubmitted(true);
            if(!s3BucketName){
                return;
            }
            setIsS3CopyInProgress(true)
            const body = {
                "target_s3_bucket": s3BucketName
            }
            const downloadType = workspaceId ? 'WORKSPACE' : 'PROJECT';
            const response = await submitPaymentDetails(downloadType, orderId, pricingOptionSelected, body);
            if(response){
                props.updateSnackBar(response?.message || "Your files will be uploaded to S3", "success");
            }
            const allWorkspaceCarts = await getAllWorkspaceCarts();
            if(!isEmpty(allWorkspaceCarts)){
                Store.dispatch(setAllWorkspacesCarts(allWorkspaceCarts))
            }
            setOrderSuccess(true)
            closeS3CopyModal();
        }catch(error){
            console.log(error)
            props.updateSnackBar(error?.message || "Your files could not be copied to S3", "error");
        }finally{
            setIsS3CopyInProgress(false)
        }
    }

    const S3_COPY_MODAL_PROPS = {
        modalTitle: 'Copy to S3 bucket',
        positiveButtonText: "Proceed",
        negativeButtonText: "Cancel",
        positiveButtonAction: onS3Copy,
        negativeButtonAction: closeS3CopyModal,
        popupButtonDisable: isS3CopyInProgress
    }

    const renderStatusClass = (status) => {
        let statusStyle = '';
        switch (lowerCase(status)) {
            case 'open': statusStyle = classes.draftText; break;
            case 'submitted for approval': statusStyle = classes.draftText; break;
            case 'rejected': statusStyle = classes.pendingText; break;
            case 'approved': statusStyle = classes.submitText; break;
            case 'available': statusStyle = classes.submitText; break;
            case 'ready for download': statusStyle = classes.submitText; break;
            case 'delivered': statusStyle = classes.deliveredBoxText; break;
            case 'scheduled for delivery': statusStyle = classes.draftText; break;
            default: break;
        }
        return statusStyle
    }

    const updateBucketPolicy = (value) => {
        setS3BucketName(value);
        let updatedPolicy = selectedPricingOptionDetails.s3_policy;
        updatedPolicy = s3Policy.replace("__bucket_name__", value);
        setSelectedPricingOptionDetails({
            ...selectedPricingOptionDetails,
            s3_policy: updatedPolicy
        })
        
    }


    return (
        <div className={classes.mainContainer}>

            <Modal
                open={s3CopyModal}
                handleClose={closeS3CopyModal}
                dialogProps={S3_COPY_MODAL_PROPS}
                disableBottomHorizontalDivider={true}
            >
                <div className={classes.deactivateBody}>
                    <p>Please provide the S3 bucket that you would like us to use. Note that, for the time being, we only accept S3 buckets in US East (N. Virginia).</p>
                </div>

                <div>

                    <label htmlFor='reason' id="cvb-pricingConfiguration-projectTitleLabel">S3 bucket name<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        onChange={e => updateBucketPolicy(e.target.value)}
                        value={s3BucketName}
                        fullWidth
                        inputProps={{ maxLength: 256 }}
                        id='cvb-pricingConfiguration-projectTitle'
                        name='reason'
                    />
                    {formIsSubmitted && !s3BucketName && <ErrorText errorMessage={'Please use an S3 bucket in US East (N. Virginia)'} />}
                </div>

                <div style={{marginTop:'15px', background:'#E7EAF2', padding:'15px'}}>
                    <label htmlFor='reason' id="cvb-pricingConfiguration-projectTitlePreview">Important</label>
                    <div>
                        Before proceeding, make sure that you have added the following policy to your S3 bucket so that we can connect to it.
                    </div>
                </div>

                <div style={{marginTop:'15px'}}>
                    <label htmlFor='reason' id="cvb-pricingConfiguration-projectTitlePreview">AWS Policy:</label>
                    <div>
                        {
                            selectedPricingOptionDetails?.s3_policy
                        }
                    </div>
                </div>
            </Modal>
            <SectionHeader
                sectionHeaderProps={sectionHeaderProps}
            />
            <div className={classes.innerContainer}>
                <div id='cvb-workSpaceFileDownload-backButtonContainer' className={classes.backButtonContainer}>
                    <p id='cvb-workSpaceFileDownload-backButtonText' onClick={() => {navigate('/my-stuff')}}><img id='cvb-adminDashboard-backButtonImage' src={BackButtonIcon} alt="black button" /> Back </p>
                </div>

                { !orderSuccess ? <> 
                <Grid container spacing={2} className={classes.cardWrapper}>
                    <Grid item xs={3}>
                        <div className={classes.cardContainer}>
                            <p className={classes.cardTitle}>Order Id</p>
                            <p className={classes.cardValue}>{orderDetails?.order_id || orderDetails?.request_id}</p>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.cardContainer}>
                            <p className={classes.cardTitle}>{workspaceId ?  'Workspace' : 'Project'}</p>
                            <p className={classes.cardValue}>{workspaceId || projectId}</p>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.cardContainer}>
                            <p className={classes.cardTitle}>Submitted On</p>
                            <p className={classes.cardValue}>{orderDetails?.date}</p>
                        </div>
                    </Grid>
                    {
                        workspaceId ?
                        <Grid item xs={3}>
                            <div className={classes.cardContainer}>
                                <p className={classes.cardTitle}>Total File Size</p>
                                <p className={classes.cardValue}>{orderDetails?.totalFileSize}</p>
                            </div>
                        </Grid> : null
                    }
                </Grid>
                
                {
                    workspaceId ?
                    <Grid container className={classes.fileDetailsSection}>
                        <Grid item xs={12} lg={12}>
                            <h4>{orderDetails?.files?.length} Files Added | {orderDetails?.totalFileSize}</h4>
                        </Grid>
                    </Grid> : null

                }

                <Grid container spacing={2} className={classes.cardWrapper}>
                    {
                        workspaceId ? 
                        <Grid item xs={6} lg={6}>
                            {   
                                orderDetails?.files?.map((file) => {
                                    return <WorkspaceFile key={v4()} file={file}></WorkspaceFile>
                                })

                            }
                        </Grid> : null
                    }
                    <Grid item xs={6} lg={6}>
                        <Grid item xs={12} lg={12}>
                        <Grid item xs={12} lg={12}>
                            <div className={classes.cardFilesContainer}>
                                <div container className={classes.cardContentContainer}>
                                    <div className={classes.cardContent}>
                                        <p className={classes.curiousText}>Submitted On: {orderDetails?.date}</p>
                                        <p className={classes.curiousText}>Status: 
                                            <span id='cvb-fileDownloadRequest-status' className={`${classes.statusText} ${renderStatusClass(orderDetails?.status)}`}>
                                                {
                                                orderDetails?.status?.replaceAll("_", " ")
                                                }
                                            </span>
                                        </p>
                                        {
                                            orderDetails?.status?.toLowerCase() === "open" && workspaceId ? 
                                            <p>
                                                <button className={`medium-size-button ${classes.proceedButton} ${classes.goToCart}`} onClick={() => {navigate(`/cart/${workspaceId}`)}}>Go to cart</button>
                                            </p> : null
                                        }
                                        {
                                            orderDetails?.status?.toLowerCase() === "delivered" ?
                                            <p className={classes.deliveredText}>Since the order is ‘delivered’, you cannot make any changes to it or download it again. Please create a new job if you need the files in a different location.</p> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        </Grid>
                        {
                            orderDetails?.status?.toLowerCase()?.includes("download") || orderDetails?.status?.toLowerCase()?.includes("available") ?
                            <Grid item xs={12} lg={12}>
                                <div className={classes.cardFilesContainer}>
                                    <div container className={classes.cardContentContainer}>
                                        <div className={classes.cardContent}>
                                            <p className={classes.curiousText}>Select Preferred Method To Download</p>
                                            <Grid item xs={12} >
                                                <label id="cvb-fileDownload-downloadlabel"></label>
                                                <Grid container>
                                                    {
                                                        pricingOptions?.map((pricingOption) => {
                                                            return <Grid key={v4()} item xs={12} className={classes.radioCheckContainer}>

                                                            <label className={classes.radioContainer} id={`cvb-fileDownload-${pricingOption?.id}`}>
                                                                {
                                                                    pricingOption?.renderAsHTML ?
                                                                    <>{pricingOption?.label}: <span style={{textDecoration: 'line-through'}}>${pricingOption?.original_cost + ' '} </span> ${pricingOption?.cost} {pricingOption?.note}
                                                                    </>  : pricingOption?.label
                                                                }
                                                            </label>
                                                            
                                                            
                                                            <input disabled={pricingOption?.disabled} id={`includeFiles1-${pricingOption?.id}`} name='includeFiles' type="radio"
                                                                value={pricingOption?.id} checked={pricingOptionSelected==pricingOption.id} onChange={onPricingOptionChange}  />
                                                            <span className={classes.checkmark}></span>

                                                            {
                                                                pricingOption?.disabled ? <div style={{marginBottom:'12px'}}>{pricingOption?.status?.reason}</div> : null
                                                            }
                                                        </Grid>
                                                        })
                                                    }

                                                    {downloadFormIsSubmitted && !pricingOptionSelected && <ErrorText errorMessage={'Please select preferred method to download'} />}
                                                    
                                                    
                                                </Grid>
                                                
                                            </Grid>
                                            <div style={{textAlign:'center'}}>
                                            <button disabled={isPaymentInProgress} className={`medium-size-button ${classes.proceedButton}`} onClick={() => {submitPayment()}}>Proceed</button></div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </Grid> : null
                        }
                    </Grid>
                </Grid></> : <OrderSuccess {...props} selectedPricingOptionDetails={selectedPricingOptionDetails} /> }
            </div>
            
        </div>

    )

}

export default WorkspaceFileDownloadRequestDetails
import PricingContainer from './PricingContainer'
import { connect } from 'react-redux'
import { updateSnackBar } from '../../../redux/actions/globalActions'

const mapStateToProps = state => {
    return {
        userDetails: state.user.userDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateSnackBar: async (message, type) => dispatch(updateSnackBar(message, type))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PricingContainer);
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid'
import classes from './index.module.css';
import Sockette from "sockette";
import map from 'lodash/map'
import { Auth } from 'aws-amplify';
import moment from 'moment'
import { navigate } from '@reach/router';
import { getNotifications } from '../../../api/profile-api'
import Skeleton from '@mui/material/Skeleton';
import ImgBackGrey from '../../../assets/icons/grey_back.svg'
import ImgBack from '../../../assets/icons/backwards.svg'
import ImgForward from '../../../assets/icons/forward.svg'
import ImgForwardGrey from '../../../assets/icons/grey_forward.svg'
import isEmpty from 'lodash/isEmpty'
import v4 from 'uuid/v4'

const AllNotificationsView = props => {

    const [notificationData, setNotificationData] = useState([])
    const [pageNumber, setPageNumber] = useState(1)
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState("")
    const [loadingNotifications, setLoadingNotifications] = useState(true)

    const isPreviousDisabled = pageNumber == 1
    const isNextDisabled = (lastEvaluatedKey === null) && notificationData?.length < ((pageNumber) * 10)

    const navigateToNotificationLocation = item => {
        navigate(item?.payload?.permalink)
        if (!item?.read) {
            if (props.webSocketConnection) {
                props.webSocketConnection.json({
                    "action": "read_notification",
                    "messages": [
                        {
                            message_id: item?.message_id
                        }
                    ]
                })
            }
        }
    }

    const fetchNotifications = async () => {
        try {
            setLoadingNotifications(true)
            const response = await getNotifications(lastEvaluatedKey ? JSON.stringify(lastEvaluatedKey) : "{}")
            const { all_notification = [], last_evaluated_key = null } = response || {}
            setNotificationData([...notificationData, ...all_notification])
            setLastEvaluatedKey(last_evaluated_key)
            setLoadingNotifications(false)
        }
        catch (error) {
            setLoadingNotifications(false)
        }
    }

    useEffect(() => {
        fetchNotifications()
    }, [])

    const showPrevious = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1)
        }
    }

    const showNext = () => {
        if (notificationData?.length > ((pageNumber) * 10)) {
            setPageNumber(pageNumber + 1)
        } else if (lastEvaluatedKey !== null) {
            fetchNotifications()
            setPageNumber(pageNumber + 1)
        }
    }

    const getCurrentData = () => {
        let data = []
        for (let i = ((pageNumber - 1) * 10); i < pageNumber * 10; i++) {
            if (notificationData[i])
                data.push(notificationData[i])
        }
        return data;
    }

    const getTimeStamp = time => {
        const differenceDate = moment().diff(time, 'days')
        if (differenceDate === 0) {
            const messageTime = moment(time).fromNow();
            const messageTimeStamp = messageTime === "a few seconds ago" ? "Just Now" : messageTime
            return messageTimeStamp
        }
        if (differenceDate === 1) {
            return `Yesterday, ${moment(time).format("h:mm A")}`
        }
        return moment(time).format("MM/DD/YYYY, h:mm A")

    }

    const renderLoadingTemplate = () => {
        return (
            <Grid item xs={5} className={classes.notificationsContainer}>
                <ul className={classes.skeletonLoadingContainer}>
                    {
                        new Array(10).fill(1)?.map((item, index) => {
                            return (
                                <li key={v4()}>
                                    <Skeleton variant="rectangular" width="100%" height="20px" />
                                    <Skeleton width="50%" />
                                    <Skeleton width="40%" />
                                </li>
                            );
                        })
                    }
                </ul>

            </Grid>
        );
    }

    return (
        <div className={classes.mainContainer}>
            <h2 className={classes.notificationsHeader} id='cvb-allNotifications-heading'>Notifications</h2>
            {loadingNotifications ?
                renderLoadingTemplate() :
                <Grid item xs={5} className={classes.notificationsContainer}>
                    <ul className={classes.notificationsList} id='cvb-allNotifications-listContainer'>
                        {
                            map(getCurrentData(), (item => {
                                const messageListStyle = item?.read ? classes.readMessage : classes.unreadMessage;
                                const messageDate = moment(item?.created_timestamp).format('MM/DD/YYYY')
                                const messageTime = moment(item?.created_timestamp).format("hh:mm A")
                                return <li id='cvb-allNotifications-listItem' className={messageListStyle} onClick={() => navigateToNotificationLocation(item)}>
                                    <h4 className={classes.notificationHeading}>{item?.payload?.header || ""}</h4>
                                    <p className={classes.notificationBody}>{item?.payload?.body || ""}</p>
                                    <p className={classes.notificationTime}> {getTimeStamp(item?.created_timestamp)} </p>

                                </li>
                            }))
                        }
                    </ul>
                </Grid>
            }
            <Grid container className={classes.paginationContainer}>
                <Grid item xs={5} className={classes.paginationItem} id='cvb-myStuffLanding-paginationSection'>
                    {
                        isPreviousDisabled ? <div><img src={ImgBackGrey} alt="previous icon" /></div> :
                            <div id='cvb-allNotifications-paginationPrevious' onClick={showPrevious}><img src={ImgBack} alt="previous icon" /></div>
                    }
                    {
                        isNextDisabled ? <div><img src={ImgForwardGrey} alt="next icon" /></div> :
                            <div id='cvb-allNotifications-paginationNext' onClick={showNext}><img src={ImgForward} alt="previous icon" /></div>
                    }
                </Grid>
            </Grid>

        </div >
    )
}

export default AllNotificationsView;

/* eslint-disable */
import React, { Component, createRef } from 'react'
import dagreD3 from 'dagre-d3'
import * as d3 from 'd3'
import "./custom-graph.css"
import zoomInIcon from '../../../assets/icons/zoomInIcon.png'
import zoomOutIcon from '../../../assets/icons/zoomOutIcon.png'
class CustomForcedGraph extends Component {
    svg = createRef()
    innerG = createRef()
    currentZoomScale = 0;

    componentDidMount() {
        this._drawChart()
    }
    componentDidUpdate() {
        this._drawChart()
    }

    _getNodeData(id) {
        return this.props.nodes.find(node => node.id === id)
    }

    _drawChart = () => {
        const {
            nodes,
            links,
            enableZoom,
            fitBoundaries = true,
            config,
            animate,
        } = this.props
        let g = new dagreD3.graphlib.Graph().setGraph(config || {})

        nodes.forEach(node => {
            return g.setNode(node.id, {
                label: node.label,
                class: node.class || '',
                labelType: node.labelType || 'string',
                ...node.config
            })
        })

        links.forEach(link =>
            g.setEdge(link.source, link.target, {
                arrowheadStyle: 'display: none',
                lineInterpolate: 'basis',
                label: link.label || '',
                class: link.class || '',
                ...link.config
            })
        )

        let render = new dagreD3.render()
        let svg = d3.select(this.svg.current)
        let inner = d3.select(this.innerG.current)

        let zoom = d3
            .zoom()
            .on('zoom', () => {
                inner.attr('transform', d3.event.transform);
                this.props.setTransformPoints(d3.event.transform)
            })

        if (enableZoom) {
            svg.call(zoom)
        }
        if (animate) {
            g.graph().transition = function transition(selection) {
                return selection.transition().duration(animate || 1000)
            }
        }

        render(inner, g)

        if (fitBoundaries) {
            const bounds = inner.node().getBBox()
            const parent = inner.node().parentElement
            const fullWidth = parent.clientWidth || parent.parentNode.clientWidth
            const fullHeight = parent.clientHeight || parent.parentNode.clientHeight
            const width = bounds.width
            const height = bounds.height
            const midX = bounds.x + width / 2
            const midY = bounds.y + height / 2
            if (width === 0 || height === 0) return
            var scale = 1.0 / Math.max(width / fullWidth, height / fullHeight)
            var translate = [
                fullWidth / 2 - scale * midX,
                fullHeight / 3 - scale * midY
            ]
            var transform = d3.zoomIdentity
                .translate(translate[0], translate[1])
                .scale(scale)

            svg
                .transition()
                .duration(animate || 0)
                .call(zoom.transform, this.props.transformPoints || transform)
        }
    }
    zoomTheChart = (zoomType) => {
        if (zoomType === "plus") {
            this.currentZoomScale += 0.1;
        } else {
            if (this.currentZoomScale > 0) {
                this.currentZoomScale -= 0.1;
            }
        }
        let svg = d3.select(this.svg.current);
        let inner = d3.select(this.innerG.current);
        const bounds = inner.node().getBBox();
        const parent = inner.node().parentElement;
        const fullWidth = parent.clientWidth || parent.parentNode.clientWidth;
        const fullHeight = parent.clientHeight || parent.parentNode.clientHeight;
        const width = bounds.width;
        const height = bounds.height;
        const midX = bounds.x + width / 2;
        const midY = bounds.y + height / 2;
        if (width === 0 || height === 0) return; // nothing to fit
        var scale =
            0.9 / Math.max(width / fullWidth, height / fullHeight) +
            this.currentZoomScale;
        var translate = [
            fullWidth / 2 - scale * midX,
            fullHeight / 3 - scale * midY,
        ];
        var transform = d3.zoomIdentity
            .translate(translate[0], translate[1])
            .scale(scale);
        let zoom = d3
            .zoom()
            .on("zoom", () => inner.attr("transform", d3.event.transform));
        svg.transition().duration(0).call(zoom.transform, transform);
    };


    render() {
        return (
            <div class="dataModelTreeGraphsSvg">
                <svg
                    width={this.props.width}
                    height={this.props.height}
                    ref={this.svg}
                    className={this.props.className || ''}
                >
                    <g ref={this.innerG} />
                </svg>
                <div class="zoomContainer">
                    <button class="zoomInButton" onClick={() => this.zoomTheChart("plus")}>
                        <img style={{width: 15, height: 15}} src={zoomInIcon} alt="zoomInIcon" />
                    </button>
                    <button class="zoomOutButton" onClick={() => this.zoomTheChart("minus")}>
                        <img style={{width: 15, height: 15}} src={zoomOutIcon} alt="zoomOutIcon" />
                    </button>
                </div>
            </div>
        )
    }
}

export default CustomForcedGraph
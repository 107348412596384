import ImgHome from '../assets/icons/Home.svg'
import ImgExplore from '../assets/icons/Explore.svg'
import ImgMyStuff from '../assets/icons/My_Stuff.svg'
import ImgAdminDashboard from '../assets/icons/dashboard_icon.svg'
import ImgAdminUser from '../assets/icons/users_icon.svg'
import DollarIcon from '../assets/icons/Price.svg'
import InvoiceIcon from '../assets/icons/invoice_icon.svg'

import {COMMUNITY_URLS} from "./urls";

export const SideBarOptions = [
    {
        title: "Explore",
        id: 'explorePath',
        resourceName: "explore",
        imageSource: ImgExplore,
        navigateRoute: "/",
        innerElements: [
            // project-galley path
            // {
            //     title: "Project Gallery",
            //     imageSource: null,
            //     navigateRoute: "/explore/project-gallery",
            // },
           
            {
                title: "Cohort Explorer",
                resourceName: "explore_cohorts",
                imageSource: null,
                navigateRoute: "/explore/cohorts",
            },
            {
                title: "Data Model",
                resourceName: "explore_data_model",
                imageSource: null,
                navigateRoute: "/explore/data-model",
            },
            // {
            //     title: "Data",
            //     imageSource: null,
            //     navigateRoute: "/explore/data/graphical-data",
            // },
            {
                title: "Tools",
                resourceName: "explore_tools",
                imageSource: null,
                navigateRoute: "/explore/tools",
            }
        ]
        
    },
    {
        title: "My Stuff",
        id: 'myStuffPath',
        resourceName: "my_stuff",
        imageSource: ImgMyStuff,
        navigateRoute: "/my-stuff"
        
    },
    {
        title: "Dashboard",
        id: 'dashboardPath',
        resourceName: "dashboard",
        imageSource: ImgAdminDashboard,
        navigateRoute: "/dashboard",
        innerElements: []
    },
    {
        title: "Users",
        id: 'usersPath',
        resourceName: "users",
        imageSource: ImgAdminUser,
        navigateRoute: "/users",
        innerElements: [
            {
                title: "Invited Users",
                imageSource: null,
                navigateRoute: "/users/invited-users",
            }
        ]
    },
    {
        title: "Invoices",
        id: 'invoicePath',
        resourceName: "invoice",
        imageSource: InvoiceIcon,
        navigateRoute: "/invoice",
    },
    {
        title: "Pricing",
        id: 'pricingPath',
        resourceName: "pricing",
        imageSource: DollarIcon,
        navigateRoute: "/pricing"
    },
    {
        title: "My Uploads",
        id: 'myUploadsPath',
        resourceName: "my_uploads",
        imageSource: ImgExplore,
        navigateRoute: "/my-uploads/structured-data/upload-data",
        innerElements: [
            {
                title: "Structured Data",
                resourceName: "structured_data",
                imageSource: null,
                navigateRoute: "/my-uploads/structured-data/upload-data",
            },
            {
                title: "Unstructured Data",
                resourceName: "unstructured_data",
                imageSource: null,
                navigateRoute: "/my-uploads/unstructured-data",
            },
            {
                title: "History",
                resourceName: "data_upload_history",
                imageSource: null,
                navigateRoute: "/my-uploads/history",
            }
        ]
        
    },
];

export const COMMUNITY_MENU_ITEM = {
    title: "Community",
    imageSource: null,
    navigateRoute: COMMUNITY_URLS.COMMUNITY,
};

import AnalysisDataVisualization from './AnalysisDataVisualization'
import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        analysisAttributes: state.analyzeData.analysisAttributes
    }
}
const mapDispatchToProps = dispatch => {
    return {
    
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AnalysisDataVisualization);
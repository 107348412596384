import ConfirmCongitoSignUp from './ConfirmCongitoSignUp'
import { connect } from 'react-redux'
import { updateUserLogin, updateUserDetails } from '../../../redux/actions/userActions'
import { getCookieFromFence, validateCookie } from '../../../api/validate-cookie'
import { updateSnackBar } from '../../../redux/actions/globalActions'

const mapStateToProps = state => {
    return {
        email: state.userSignup.email.text,
        password: state.userSignup.password
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateUserLogin: async (value) => await dispatch(updateUserLogin(value)),
        updateUserDetails: async (value) => await dispatch(updateUserDetails(value)),
        validateCookie: async () => await validateCookie(),
        getCookieFromFence: async () => await getCookieFromFence(),
        updateSnackBar: async (message, type) => dispatch(updateSnackBar(message, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCongitoSignUp)
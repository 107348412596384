import React, { useState } from 'react';
import Modal from '../../../../commons/modal';
import ErrorText from '../../../../controls/error-text';
import isEmpty from 'lodash/isEmpty'
import { requestStudyForDownload } from '../../../../../api/study-api';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';

function DownloadStudyDialog(props) {
  const { dataModelVersion, studyDetail } = props;
  const [structuredDataExportType, setStructuredDataExportType] = useState('tsv');
  const [includeUnstructuredFiles, setIncludeUnstructuredFiles] = useState('');
  const [downloadProjectDescription, setDownloadProjectDescription] = useState('');
  const [submitDownloadForm, setSubmitDownloadForm] = useState(true);

  const onStructuredDataFormatChange = (e) => {
      setStructuredDataExportType(e.target.value);
  }
  const onIncludeUnstructuredFilesChange = (e) => {
      setIncludeUnstructuredFiles(e.target.value);
  }
  const onDownloadProjectDescriptionChange = e => {
      setDownloadProjectDescription(e.target.value)
  }
  // on download of study project confirm
  const onDownloadProjectConfirm = async () => {
    try {
      setSubmitDownloadForm(true);
      if (downloadProjectDescription?.length > 512) {
          return
      }
      if ((!isEmpty(structuredDataExportType) || studyDetail?.structuredDataDownloadFormatAvailable?.length === 0) && (!isEmpty(includeUnstructuredFiles) || !studyDetail?.unstructuredDataDownloadAvailable)) {
          const body = {
              "data_model_version": dataModelVersion || '',
              "structured_data_export_format": structuredDataExportType || '',
              "include_unstructured_files": includeUnstructuredFiles === "yes" ? "true" : includeUnstructuredFiles === "no" ? "false" : "",
              "project_id": studyDetail?.projectId,
              "description": downloadProjectDescription || ''
          }
          const response = await requestStudyForDownload(body);
          console.log(body, response)
          props.updateSnackBar(response?.message || 'Project download request was successful.', 'success');
          props?.onCloseDownloadStudyModal();
      }
    } catch (error) {
      console.log(error);
      let errorMessage = "Something went wrong, Please try again later.";
      if (error?.message) {
          errorMessage = error.message
      }
      props.updateSnackBar(errorMessage, 'error');
    }
  }

const downloadStudyProps = {
  modalTitle: 'Download Project',
  positiveButtonText: 'Submit',
  negativeButtonText: "Cancel",
  positiveButtonAction: onDownloadProjectConfirm,
  negativeButtonAction: props?.onCloseDownloadStudyModal
}

  return (
    <Modal
      open={props?.openStudyDownloadModal}
      handleClose={props?.onCloseDownloadStudyModal}
      dialogProps={downloadStudyProps}
      disableBottomHorizontalDivider
    >
      <Typography>
        Your download will be prepared offline and a link to download the Project will become available in your "My Stuff" page. The link will be available for 7 days, after which you will have to submit a new request if you would like to download the same Project again.
      </Typography>

      <Box mt={3}>
        <Typography id="demo-controlled-radio-buttons-group" component='label' variant='subtitle2' sx={{ fontWeight: 'bold' }}>
          Structure Data Export Format<sup> *</sup>
        </Typography>

        <FormControl fullWidth>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={structuredDataExportType}
            onChange={onStructuredDataFormatChange}
          >
            <FormControlLabel
              value="json"
              disabled={!studyDetail?.structuredDataDownloadFormatAvailable?.includes('json')}
              control={<Radio />}
              label="Json"
            />
            <FormControlLabel
              value="tsv"
              disabled={!studyDetail?.structuredDataDownloadFormatAvailable?.includes('tsv')}
              control={<Radio />}
              label="Tsv"
            />
          </RadioGroup>
        </FormControl>

        {
          submitDownloadForm && isEmpty(structuredDataExportType) && studyDetail?.structuredDataDownloadFormatAvailable?.length &&
          <ErrorText errorMessage="Please make a selection" />
        }
      </Box>

      <Box mt={3}>
        <Typography component='label' variant='subtitle2' sx={{ fontWeight: 'bold' }}>
          Include Unstructured Files<sup> *</sup>
        </Typography>

        <FormControl fullWidth>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={includeUnstructuredFiles}
            onChange={onIncludeUnstructuredFilesChange}
          >
            <FormControlLabel
              value="yes"
              disabled={!studyDetail?.unstructuredDataDownloadAvailable}
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              value="no"
              disabled={!studyDetail?.unstructuredDataDownloadAvailable}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </FormControl>

        {
          submitDownloadForm && isEmpty(includeUnstructuredFiles) && studyDetail?.unstructuredDataDownloadAvailable &&
          <ErrorText errorMessage="Please make a selection" />
        }
      </Box>

      <Box mt={3}>
        <Typography
          component='label'
          variant='subtitle2'
          sx={{
            fontWeight: 'bold',
            mb: 1
          }}
        >
          Abstract/Description
        </Typography>

        <TextField
          variant='outlined'
          fullWidth
          error={downloadProjectDescription?.length > 512}
          helperText={`${downloadProjectDescription?.length}/512 characters`}
          multiline
          id='abstract'
          name='Description'
          value={downloadProjectDescription}
          onChange={onDownloadProjectDescriptionChange}
          rows={4}
          placeholder='Enter description'
        />

        {
          submitDownloadForm && downloadProjectDescription?.length > 512 &&
          <ErrorText
            errorMessage="Abstract/Description cannot be greater than 512 characters"
          />
          }
      </Box>
    </Modal>
  )
}
export default DownloadStudyDialog;
/**
 *  Internal Imports
 */

import WidgetChatMessages from "./WidgetChatMessages";

/**
 *  Exports
 */
export default WidgetChatMessages;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import SectionHeader from '../../commons/section-header';
import { CohortsTable, CohortInfo } from '.';
import { Drawer } from '@mui/material';
import {
  getStudiesForCohort,
} from '../../../service/CohortsService';
import { PAGES_DEFINITIONS } from '../../../constants/strings'

import ADD from '../../../assets/images/add.svg';
import { navigate, useLocation } from '@reach/router';

export function composeCohortsQueries(cohorts) {
  return {
    OR: cohorts.map((c) => c.cohort_query),
  };
}

function ExploreData(props) {
  const [cohortInfo, setCohortInfo] = React.useState(null);
  const [selectedCohorts, setSelectedCohorts] = React.useState({});
  const [cohortsRefreshCount, setCohortsRefreshCount] = React.useState(0)

  const selectedCohortEmpty = useMemo(() => {
    const isEmpty = Object.keys(selectedCohorts).length === 0;
    const notEmpty = Object.keys(selectedCohorts).length > 0;
    const hasEnabled = Object.values(selectedCohorts).find((v) => v);

    return isEmpty || (notEmpty && !hasEnabled);
  }, [selectedCohorts]);

  function showCohortInfo(cohort) {
    setCohortInfo(cohort);
  }

  function closeCohortInfo() {
    setCohortInfo(null);
  }

  function updateSelectedCohorts(cohorts) {
    setSelectedCohorts({
      ...selectedCohorts,
      ...cohorts,
    });
  }
  function updateCohortsRefresh(cohorts) {
    setCohortsRefreshCount(prev => prev + 1);
  }

  function createCohortsFromSelection() {
    navigate('/explore/cohorts/new', {
      state: {
        formDefaultData: composeCohortsQueries(
          Object.values(selectedCohorts).filter((c) => c)
        ),
      },
      replace: true,
    });
  }

  const sectionHeaderProps = {
    title: 'Cohort Explorer',
    type: 'cohortExplorer',
    pageDefinition: PAGES_DEFINITIONS['data'],
    defaultActionButtons: [
      {
        buttonText: 'New Cohort from Selection',
        handleOnClick: createCohortsFromSelection,
        variant: 'outlined',
        disabled: selectedCohortEmpty,
        description:
          !selectedCohortEmpty &&
          `${
            Object.values(selectedCohorts).filter((v) => v).length ?? ''
          } cohort${
            Object.values(selectedCohorts).filter((v) => v).length > 1
              ? 's'
              : ''
          } selected`,
      },
      {
        buttonText: 'Create Custom Cohort',
        handleOnClick: () => navigate('/explore/cohorts/new'),
        variant: 'contained',
        imageSrc: ADD,
      },
    ],
  };
  return (
    <>
      <SectionHeader
        open={props.location.state?.open ?? props.open}
        sectionHeaderProps={sectionHeaderProps}
      />
      <Box>
        <CohortsTable
          showCohortInfo={showCohortInfo}
          updateSelectedCohorts={updateSelectedCohorts}
          selectedCohorts={selectedCohorts}
          updateSelectedProjectsinGallery={props?.updateSelectedProjectsinGallery}
          cohortsRefreshCount={cohortsRefreshCount}
          location={props.location}
        />
      </Box>
      <Drawer
        anchor="right"
        open={cohortInfo != null}
        onClose={closeCohortInfo}
      >
        {cohortInfo != null && (
          <CohortInfo
            cohortInfo={cohortInfo}
            closeCohortInfo={closeCohortInfo}
            allWorkspaces={props.allWorkspaces}
            updateSnackBar={props.updateSnackBar}
            updateCohortsRefresh={updateCohortsRefresh}
            updateSelectedCohorts={updateSelectedCohorts}
          />
        )}
      </Drawer>
    </>
  );
}

export default ExploreData;

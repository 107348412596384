import React from 'react';
import { Router } from '@reach/router';
import StructuredData from '../structured-data'
import UnstructuredData from '../unstructured-data'
import UploadStructureData from '../upload-structure-data'
import StructuredUploadFiles from '../Structured-upload-files'
import ViewStructureDataStatus from '../view-structured-data-status'
import UploadHistory from '../uploads-history'
import AuthorisedRoute from '../../../root/AuthorisedRoute';

const  MyUploads = props => {
    return (
        <div>
            <Router id="myUploadsRouter" primary={false}>
                <AuthorisedRoute component={StructuredData} isUserAuthorised={props.userDetails?.resourceActions?.structured_data || false} path="/structured-data" />
                <AuthorisedRoute component={ViewStructureDataStatus} isUserAuthorised={props.userDetails?.resourceActions?.structured_data || false} path="/structured-data/view-status" />
                <AuthorisedRoute component={UnstructuredData} isUserAuthorised={props.userDetails?.resourceActions?.unstructured_data || false} path="/unstructured-data" />
                <AuthorisedRoute component={UploadStructureData} isUserAuthorised={props.userDetails?.resourceActions?.structured_data || false} path="/structured-data/upload-data" />
                <AuthorisedRoute component={StructuredUploadFiles} isUserAuthorised={props.userDetails?.resourceActions?.structured_data || false} path="/structured-data/upload-data/files/:id" />
                <AuthorisedRoute component={UploadHistory} isUserAuthorised={props.userDetails?.resourceActions?.data_upload_history || false} path="/history" />
            </Router>
        </div>
    )
}

export default MyUploads;

/**
 *  External Imports
 */
import React from "react";
import { func, bool } from "prop-types";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";

/**
 *  Internal Imports
 */
import GotItCommunity from "../../../assets/images/GotItCommunity.png";
import CustomLoader from "../../commons/custom-loader";
import classes from "./index.module.css";

const CommunityWelcome = (props) => {
    const { isCommunitySelected, isStatusLoading } = props;
    const { changeCommunitySelection } = props;

    const activeInactiveClass = !isCommunitySelected
        ? classes.activeCard
        : classes.inactiveCard;

    return (
        <div className={classes.root}>
            <div className={classes.communityWelcomeWrap}>
                <Grid container direction="row" spacing={4}>
                    <Grid item lg={7} md={7} xs={12}>
                        <p className={classes.communityText}>
                            Join our BC Communities by selecting a community
                            below and answering relevant questions that help to
                            connect you to other users in that community.
                        </p>
                    </Grid>
                </Grid>
                <Grid container direction="row" spacing={4}>
                    <Grid
                        item
                        lg={3}
                        md={4}
                        xs={12}
                        className={classes.userTypeCardContainer}>
                        <div
                            className={`${classes.userDetailsWrapper} ${activeInactiveClass}`}>
                            <span className={classes.checkboxWrapper}>
                                <Checkbox
                                    checked={!isCommunitySelected}
                                    color="primary"
                                    onChange={(e) =>
                                        changeCommunitySelection(
                                            e.target.checked
                                        )
                                    }
                                    id="userCheck"
                                />
                            </span>
                            <img
                                className={classes.communityWelcomeImg}
                                src={GotItCommunity}
                                alt="GOT-IT Community"
                            />
                            <p className={classes.userDesignationText}>
                                BC GOT-IT Community
                            </p>
                            <p className={classes.communityWelcomeText}>
                                Connecting academic scientists and industry
                                experts to close the gap between basic research
                                and drug discovery.
                            </p>
                            {isStatusLoading && (
                                <div className={classes.communityStatusLoader}>
                                    <div className={classes.loaderWrap}>
                                        <CustomLoader componentLoader />
                                    </div>
                                </div>
                            )}
                        </div>
                    </Grid>
                    <Grid
                        item
                        lg={3}
                        md={4}
                        xs={12}
                        className={classes.userTypeCardContainer}>
                        <div
                            className={`${classes.cardPlaceholder} ${classes.inactiveCard}`}>
                            <span className={classes.communityWelcomeText}>
                                New Communities Coming Soon!
                            </span>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

CommunityWelcome.propTypes = {
    isStatusLoading: bool.isRequired,
    isCommunitySelected: bool.isRequired,
    changeCommunitySelection: func.isRequired,
};

export default CommunityWelcome;

import RequestAdvanceCompute from './RequestAdvanceCompute'
import { connect } from 'react-redux'
import { updateSnackBar } from '../../../redux/actions/globalActions'
import { resetAgreementValues } from '../../../redux/actions/serviceAgreementActions'
const mapStateToProps = state => {
    return {
        serviceAgreementDate: state.serviceAgreement.serviceAgreementDate,
        clientName: state.serviceAgreement.clientName,
        agreementYears: state.serviceAgreement.agreementYears,
        signatoryName: state.serviceAgreement.signatoryName,
        signatoryTitle: state.serviceAgreement.signatoryTitle,
        nonCommercialCheck: state.serviceAgreement.nonCommercialCheck,
        servicesCheck: state.serviceAgreement.servicesCheck,
        agreementChecks: state.serviceAgreement.agreementChecks,
        userDetails: state.user.userDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type)),
        resetAgreementValues: async () => await dispatch(resetAgreementValues()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestAdvanceCompute);

import { gql } from 'apollo-boost';

export const EXPLORE_FILTERS_GRAPHQL = gql`
  fragment case_agg_data on CaseAggregation {
    _totalCount
    primary_diagnosis {
      histogram {
        key
        count
      }
    }
    project_id {
      histogram {
        key
      }
    }
  }

  query ($filter: JSON, $nestedAggFields: JSON) {
    _aggregation {
      unaccessible_projects: case(accessibility: unaccessible) {
        project_id {
          histogram {
            key
          }
        }
      }
      accessible_case_count: case(accessibility: accessible, filter: $filter) {
        _totalCount
      }
      project_id: case(accessibility: all, filter: $filter) {
        project_id {
          histogram {
            count
          }
        }
      }
      age_distribution_graph: case(
        nestedAggFields: $nestedAggFields
        filter: $filter
      ) {
        gender {
          histogram {
            key
            count
            termsFields {
              field
              terms {
                key
                count
              }
            }
          }
        }
      }
      file_data_type: case(filter: $filter) {
        data_type {
          histogram {
            key
            count
          }
        }
      }
      sub_burst_graph: case(filter: $filter) {
        meddra_soc {
          histogram {
            key
            count
          }
        }
      }
      case: case(accessibility: all, filter: $filter) {
        ...case_agg_data
      }
    }
  }
`;

export const AGE_DISTRIBUTION_GRAPH = gql`
  query ($nestedAggFields: JSON, $filter: JSON) {
    _aggregation {
      case(nestedAggFields: $nestedAggFields, filter: $filter) {
        gender {
          histogram {
            key
            count
            termsFields {
              field
              terms {
                key
                count
              }
            }
          }
        }
      }
    }
  }
`;

export const SUN_BURST_CHART = gql`
  query ($pdTermsField: JSON, $filter: JSON) {
    _aggregation {
      case(nestedAggFields: $pdTermsField, filter: $filter) {
        meddra_soc {
          histogram {
            key
            count
            termsFields {
              terms {
                key
                count
              }
            }
          }
        }
      }
    }
  }
`;

export const STUDY_GALLERY_FILTERS = gql`
  query ($nestedAggFields: JSON, $filter: JSON, $filter2: JSON) {
    _aggregation {
      accessible: study(
        accessibility: accessible
        nestedAggFields: $nestedAggFields
        filter: $filter
      ) {
        zone {
          histogram {
            key
            termsFields {
              field
              terms {
                key
                count
              }
            }
          }
        }
        diagnosis {
          histogram {
            key
            termsFields {
              field
              terms {
                key
                count
              }
            }
          }
        }
        data_type {
          histogram {
            key
            termsFields {
              field
              terms {
                key
                count
              }
            }
          }
        }
      }
      unaccessible: study(
        accessibility: unaccessible
        nestedAggFields: $nestedAggFields
        filter: $filter2
      ) {
        zone {
          histogram {
            key
            termsFields {
              field
              terms {
                key
                count
              }
            }
          }
        }
        diagnosis {
          histogram {
            key
            termsFields {
              field
              terms {
                key
                count
              }
            }
          }
        }
        data_type {
          histogram {
            key
            termsFields {
              field
              terms {
                key
                count
              }
            }
          }
        }
      }
    }
  }
`;



export const ACCESSIBLE_PROJECTS = gql`
  query ($filter: JSON) {
    _aggregation {
      accessible: study(accessibility: accessible, filter: $filter) {
        project_id {
          histogram {
            key
          }
        }
      }
    }
  }
`;

export const STUDY_GALLERY_DATA = `query($nestedAggFields: JSON,$filter: JSON, $filter2: JSON){
  _aggregation{
    case_count: case(accessibility: all,filter: $filter){
      project_id{
        histogram{
          key
          count
        }
      }
    }
    accessible: study(accessibility: accessible,filter: $filter2){
      project_id{
        histogram{
          key
        }
      }
    }
    unaccessible: study(accessibility: unaccessible, filter: $filter2){
      project_id{
          histogram{
            key
          }
        }
      }
   filters: study(filter: $filter2, filterSelf: false, accessibility: all, nestedAggFields: $nestedAggFields){
    zone {
      histogram {
        key
        termsFields {
          field
          terms {
            key
            count
          }
        }
      }
    }
    project_size{
      histogram{
        min
        max
      }
    }
    age_at_enrollment{
      histogram{
        min
        max
      }
    }
    diagnosis {
      histogram {
        key
        termsFields {
          field
          terms {
            key
            count
          }
        }
      }
    }
    data_type {
      histogram {
        key
        termsFields {
          field
          terms {
            key
            count
          }
        }
      }
    }
    ethnicity {
      histogram {
        key
        termsFields {
          field
          terms {
            key
            count
          }
        }
      }
    }
    race {
      histogram {
        key
        termsFields {
          field
          terms {
            key
            count
          }
        }
      }
    }
    gender {
      histogram {
        key
        termsFields {
          field
          terms {
            key
            count
          }
        }
      }
    }
    program_name {
      histogram {
        key
        termsFields {
          field
          terms {
            key
            count
          }
        }
      }
    }
   }
  } 
}`;

export const EXPLORE_DATA_COUNT = gql`
  query ($filter: JSON) {
    _aggregation {
      case(filter: $filter, accessibility: accessible) {
        _totalCount
      }
    }
  }
`;

export const LIST_OF_PROJECTS = gql`
  query {
    project(first: 0) {
      code
      project_id
      availability_type
    }
  }
`;

export const PROJECT_DETAILS = gql`
  query ($name: [String], $projectId: String) {
    project(project_id: $name) {
      name: project_id
      code
      id
    }
    studies: _study_count(project_id: $name)
    cases: _case_count(project_id: $name)
    transaction_log(
      project_id: $name
      order_by_desc: "created_datetime"
      state: "SUCCEEDED"
    ) {
      id
      submitter
      created_datetime
      state
    }
    datanode(project_id: $projectId, first: -1) {
      project_id
      file_name
      id
      object_id
    }
  }
`;

export const TRANSACTION_LOG = gql`
  query ($first: Int, $offset: Int, $orderBy: String) {
    transaction_log(first: $first, offset: $offset, order_by_desc: $orderBy) {
      id
      project_id
      submitter
      created_datetime
      documents {
        doc_size
        response {
          success
        }
      }
    }
  }
`;

export const TRANSACTION_LOG_WITH_FILTER = gql`
  query ($first: Int, $offset: Int, $orderBy: String, $projectId: [String]) {
    transaction_log(
      first: $first
      offset: $offset
      order_by_desc: $orderBy
      project_id: $projectId
    ) {
      id
      project_id
      submitter
      created_datetime
      documents {
        doc_size
        response {
          success
        }
      }
    }
  }
`;

export const TRANSACTION_LOG_COUNT = gql`
  query {
    _transaction_log_count
  }
`;

export const TRANSACTION_LOG_COUNT_WITH_FILTER = gql`
  query ($projectId: [String]) {
    _transaction_log_count(project_id: $projectId)
  }
`;

export const CORE_METADATA = gql`
  query gqlHelperAllSubmitterIdsByTypeQuery($project_id: [String]) {
    core_metadata_collection(project_id: $project_id, first: 0) {
      submitter_id
    }
  }
`;

export const STUDY_GALLERY_DETAILS = gql`
  query ($name: [String]) {
    study(project_id: $name, first: 1) {
      project_id
      created_datetime
      study_objective
      study_description
      updated_datetime
      submitter_id
      longitudinal
    }
  }
`;

export const STUDY_GALLERY_DETAILS_DATA = gql`
  query ($name: [String]) {
    study(project_id: $name) {
      project_id
      created_datetime
      study_objective
      study_description
      updated_datetime
      submitter_id
      longitudinal
    }
  }
`;

export const EXPLORE_DATA_FILES = gql`
  query ($filter: JSON, $offset: Int, $first: Int) {
    file(
      accessibility: accessible
      filter: $filter
      offset: $offset
      first: $first
    ) {
      file_name
      data_type
      file_size
      data_format
      object_id
      updated_datetime
      project_id
    }
  }
`;

export const EXPLORE_PROJECTS_LIST = gql`
  query {
    project(first: -1) {
      project_id
    }
  }
`;

export const GET_PROJECT_COUNT = gql`
  query {
    _aggregation {
      case {
        project_id {
          histogram {
            key
            count
          }
        }
      }
    }
  }
`;

export const GET_STUDY_GALLERY_PROJECT_DETAILS = gql`
  query ($name: [String]) {
    study_details: study(project_id: $name, first: 1) {
      study_objective
      study_description
      data_description
      study_design
      data_type
      acknowledgement
      data_repository_of_record
      additional_resource
      updated_datetime
      created_datetime
      project_id
    }
    publications: publication(project_id: $name) {
      doi
    }
    case_count: _case_count(project_id: $name)
  }
`;

export const GET_DISEASE_COUNT_BY_PROJECT = gql`
  query ($filter: JSON) {
    _aggregation {
      disease_by_project: case(
        nestedAggFields: { termsFields: ["primary_diagnosis"] }
        filter: $filter
      ) {
        project_id {
          histogram {
            key
            count
            termsFields {
              field
              terms {
                key
                count
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ASSESSMENTS_TYPES_BY_PROJECTS = gql`
  query ($filter: JSON) {
    _aggregation {
      assessments_by_project: study(
        filter: $filter
        nestedAggFields: { termsFields: ["assessments"] }
      ) {
        project_id {
          histogram {
            key
            termsFields {
              field
              terms {
                key
                count
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DATA_TYPES_BY_PROJECTS = gql`
  query ($filter: JSON) {
    _aggregation {
      data_type_by_project: study(
        filter: $filter
        nestedAggFields: { termsFields: ["data_type"] }
      ) {
        project_id {
          histogram {
            key
            termsFields {
              terms {
                key
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ANALYSIS_DATA_VALUES = [
  { label: 'Gender', value: 'gender' },
  { label: 'Race', value: 'race' },
  { label: 'Age at Enrollment', value: 'age_at_enrollment' },
  { label: 'Education Level', value: 'education_level' },
  { label: 'Employment Status', value: 'employment_status' },
  { label: 'Primary Diagnosis', value: 'primary_diagnosis' },
  { label: 'Adverse Event', value: 'ae_name' },
  { label: 'Symptom', value: 'symptom' },
  { label: 'Other Condition', value: 'comorbidity' },
  { label: 'Enrollment status', value: 'enrollment_status' },
];

/**
 *  External Imports
 */
 import { connect } from "react-redux";

/**
 *  Imports
 */
import CommunityConstellation from "./CommunityConstellation";
import {
    initConstellationLabelsAction,
    clearConstellationLabelsSelectionAction,
    setConstellationRecommendedUsersAction,
    resetConstellationLabelNodeAction,
    setConstellationConnectedUsersAction
} from "../../../redux/actions/communityActions";
import { updateSnackBar } from "../../../redux/actions/globalActions";

const mapStateToProps = ({
    community: {
        fullscreenView,
        constellationLabels,
        constellationLabelsSelected,
        constellationRecommendedUsers,
        constellationConnectedUsers
    },
}) => ({
    fullscreenView,
    constellationLabels,
    constellationLabelsSelected,
    constellationRecommendedUsers,
    constellationConnectedUsers
});

const mapDispatchToProps = (dispatch) => ({
	initLabels: (labels) => dispatch(initConstellationLabelsAction(labels)),
    updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type)),
    clearAllLabelsSelection: () => dispatch(clearConstellationLabelsSelectionAction()),
    setRecommendedUsers: (users) => dispatch(setConstellationRecommendedUsersAction(users)),
    setConnectedUsers: (users) => dispatch(setConstellationConnectedUsersAction(users)),
    resetLabelNodeParams: () => dispatch(resetConstellationLabelNodeAction()),
});
/**
 *  Exports
 */
export default connect(mapStateToProps, mapDispatchToProps)(CommunityConstellation);

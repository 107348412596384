import UnstructuredData from './UnstructuredData'
import { connect } from 'react-redux'
import { updateSnackBar } from '../../../redux/actions/globalActions'

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type))
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(UnstructuredData)
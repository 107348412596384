import CookiesDetails from './CookiesDetails'
import { connect } from 'react-redux'
import { setBannerCookie, setOnOffCookie } from '../../../redux/actions/homeActions'

const mapStateToProps = state => ({
    onOffCookie: state.home.onOffCookie
})

const mapDispatchToProps = dispatch => ({
    setBannerCookie: async () => await dispatch(setBannerCookie()),
    setOnOffCookie: async (value) => await dispatch(setOnOffCookie(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CookiesDetails);
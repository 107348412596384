/**
 *  External Imports
 */
import { connect } from "react-redux";

/**
 *  Internal Imports
 */
import {
    initGlobalSearchTherapeuticAreasAction,
    initGlobalSearchMolecularClassesAction,
    resetCommunityRedirectAction,
    toggleCommunityFullscreenViewAction
} from "../../../redux/actions/communityActions";

import Community from "./Community";

/**
 *  Functions
 */

const mapStateToProps = ({ community: { navigateTo, navigationState }, user: { isUserPrivate } }) => ({
    navigateTo, navigationState, isUserPrivate
});

const mapDispatchToProps = (dispatch) => ({
    initTherapeuticAreas: (therapeuticAreas) => dispatch(initGlobalSearchTherapeuticAreasAction(therapeuticAreas)),
    initMolecularClasses: (molecularClasses) => dispatch(initGlobalSearchMolecularClassesAction(molecularClasses)),
    resetRedirectPath: () => dispatch(resetCommunityRedirectAction()),
    toggleCommunityView: (isOpen) => dispatch(toggleCommunityFullscreenViewAction(isOpen)),
});

/**
 *  Exports
 */
export default connect(mapStateToProps, mapDispatchToProps)(Community);

import SortBy from './SortBy'
import { connect } from 'react-redux'


const mapStateToProps = (props) => {
    return {
        userDetails: props.user.userDetails
    }
}


export default connect(mapStateToProps)(SortBy);
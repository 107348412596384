import { UPDATE_USER_COHORTS, UPDATE_ANALYSIS_WITH_ID, UPDATE_LAST_EVALUATED_KEY, UPDATE_SELECTED_WORKSPACE_FILE_DATA } from '../action-types'

const initialState = {
    userCohorts: [],
    analysisWithCohortId: {},
    lastEvaluatedKey: {},
    selectedWorkspaceFileDownloadRequestData: { }
}

const myStuffReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER_COHORTS: {
            if (action.payload?.length > 0) {
                return {
                    ...state,
                    userCohorts: [...state.userCohorts, ...action.payload]
                }
            }
            else{
                return {
                    ...state,
                    userCohorts: []
                }
            }

        }
        case UPDATE_LAST_EVALUATED_KEY: {
            return {
                ...state,
                lastEvaluatedKey: action.payload
            }
        }
        case UPDATE_ANALYSIS_WITH_ID: {
            return {
                ...state,
                analysisWithCohortId: action.payload
            }
        }
        case UPDATE_SELECTED_WORKSPACE_FILE_DATA : {
            return {
                ...state,
                selectedWorkspaceFileDownloadRequestData: action.payload
            }
        }
        default: return state;
    }
}

export default myStuffReducer;
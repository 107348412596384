import React, { useEffect, useState, useRef } from 'react'
import ProjectDetails from '../project-details-form'
import ProjectPrimaryInstitutional from '../project-primary-institutional-form'
import ProjectAdministrator from '../project-administrator-form'
import ProjectPi from '../project-pi-form'
import Zone3AuthorizedUsers from '../zone3-authorized-user-form'
import AuthorizedDataSubmitters from '../authorized-data-submitters'
import Acknowledgment from '../acknowledgment-form'
import Step from '@mui/material/Step';
import MuiStepLabel from '@mui/material/StepLabel';
import StepIcon from '@mui/material/StepIcon';
import Stepper from '@mui/material/Stepper';
import StepConnector from '@mui/material/StepConnector'
import MobileStepper from '@mui/material/MobileStepper';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Classes from './index.module.css'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { navigate } from '@reach/router';
import findIndex from 'lodash/findIndex'

const ColorlibConnector = withStyles({
    vertical: {
        padding: 0
    },
    active: {
        '& $line': {
            backgroundColor:
                '#23116C',
        },
    },
    completed: {
        '& $line': {
            backgroundColor:
                '#23116C',
        },
    },
    line: {
        height: 3,
        width: 2,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
        padding: 0
    },
})(StepConnector);

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        backgroundColor: 'inherit',
        display: "flex"
    },
    float: {
        flexGrow: 1,
        marginRight: "15px"
    },
    iconContainer: {
        transform: 'scale(2)',
        marginRight: 10,
    },
    paper: {
        textAlign: 'center'
    },
});

const StepLabel = withStyles({
    label: {
        textTransform: 'uppercase',
        fontSize: '14px !important',
        fontFamily: "Raleway",
        fontWeight: '700 !important',
        marginLeft: '16px !important',
        color: 'rgb(204, 204, 204)',
    },
    active: {
        color: 'rgb(204, 204, 204) !important',
    },
    complete: {
        color: '#031235',
    }
 })(MuiStepLabel)
// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//       display: "flex"
//     },
//     float:{
//         flexGrow: 1,
//         marginRight: "15px"
//     },
//     clearfix:{
//         clear:'both'
//     },
//     paper: {
//       padding: theme.spacing(2),
//       textAlign: 'center',
//       color: theme.palette.text.secondary,
//     },
//   }));


const stepsForZone3 = ["Project Details", "Project Primary Institutional", "Project Administrator",
    "Project PI", "Zone 3 Authorized users", "Authorized Data Submitters", "Acknowledgment"];

const steps = ["Project Details", "Project Primary Institutional", "Project Administrator",
"Project PI", "Authorized Data Submitters", "Acknowledgment"];

const ContributedDatasetStage = props => {
    const { dcaStage } = props;
    const [stage, setStage] = useState(1);
    const { userDetails = {}} = props;
    const { isFormSubmitted } = props;
    const { acknowledgementRef = null } = props;
    const { projectDetailsDesignatedZone = '' } = props;
    const { presentUUID = '' } = props;
    const { isDashboard = false } = props;
    const { setIsAckAgreeSelected = () =>{ } } = props;
    const { isAckAgreeSelected = false } = props;
    //Data use policy fields
    const sigRef = useRef(null)
    const [agreedDataUsePolicy, setAgreedDataUsePolicy] = useState(false)
    const [researcherSign, setResearcherSign] = useState('')
    const [streetAddress, setStreetAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [country, setCountry] = useState('')

    const [innerStage, setInnerStage] = useState(1)
    const [isAgreeSelected, setIsAgreeSelected] = useState(false)

    const muClasses = useStyles();

    const getCurrentDate = () => {
        const day = new Date().getDate();
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();
        return `${year}-${month}-${day}`
    }
    const stepsForStepper = projectDetailsDesignatedZone === 'zone3' ? stepsForZone3 : steps;

    const CustomStepIcon = (props) => {
        let bgClass = '';
        bgClass = projectDetailsDesignatedZone === 'zone3' 
        ? (props.index >= dcaStage-2 ? Classes.incompleteSteps : Classes.currentStep)
        : ((props.index === 4 ||  props.index === 5) ? (props.index >= dcaStage-3 ? Classes.incompleteSteps : Classes.currentStep)
        : ( props.index >= dcaStage-2 ? Classes.incompleteSteps : Classes.currentStep))

        const index = findIndex(stepsForStepper, (step) => step === props.label) + 1;
        return (
            <div className={`${Classes.stepIconContainer} ${bgClass}`}>
                <span>{index}</span>
            </div>
        )
    }

    return (
        <div className={Classes.agreementContainer} id='cvb-dataContributorAgreement-stageThreeFormContainer'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div className={Classes.titleContainer}>
                        <h2 id='cvb-dataContributorAgreement-stageThreeFormHeading'>Contributed Dataset Governance Form</h2>
                        <p id='cvb-dataContributorAgreement-stageThreeFormDescription'>One form per dataset is required</p>
                    </div>
                    <div className={Classes.contributedDatasetContainer} >
                        <div style={{ position: 'relative' }}>
                            <div className={muClasses.root}>
                                <Grid className={(muClasses.float, Classes.leftContainer)} xs={4} id='cvb-dataContributorAgreement-stageThreeLeftContainer'>
                                    <Stepper connector={<ColorlibConnector />} activeStep={dcaStage-2} className={Classes.stepperCont} orientation="vertical" steps={7} position="static">
                                        {stepsForStepper.map((label, index) => (
                                            <Step key={label}>
                                                <StepLabel StepIconComponent={() => <CustomStepIcon label={label} index={index} />}>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Grid>
                                <Grid className={muClasses.float} xs={9} id='cvb-dataContributorAgreement-stageThreeRightContainer'>
                                    <div>
                                        {
                                            dcaStage == 3 &&
                                            <ProjectDetails
                                                isFormSubmitted={isFormSubmitted}
                                                presentUUID={presentUUID}
                                            />
                                        }
                                        {
                                            dcaStage == 4 &&
                                            <ProjectPrimaryInstitutional isFormSubmitted={isFormSubmitted}/>
                                        }
                                        {
                                             dcaStage == 5 &&
                                            <ProjectAdministrator isFormSubmitted={isFormSubmitted}/>
                                        }
                                        {
                                            dcaStage == 6 &&
                                            <ProjectPi isFormSubmitted={isFormSubmitted}/>
                                        }
                                        {
                                            (dcaStage == 7 && projectDetailsDesignatedZone === 'zone3') &&
                                            <Zone3AuthorizedUsers isFormSubmitted={isFormSubmitted}/>
                                        }
                                        {
                                            dcaStage == 8 &&
                                            <AuthorizedDataSubmitters isFormSubmitted={isFormSubmitted}/>
                                        }
                                        {
                                            dcaStage == 9 &&
                                            <Acknowledgment
                                                isDashboard={isDashboard}
                                                acknowledgementRef={acknowledgementRef}
                                                isFormSubmitted={isFormSubmitted}
                                                userDetails={userDetails}
                                                setIsAgreeSelected={setIsAckAgreeSelected}
                                                isAgreeSelected={isAckAgreeSelected}
                                            />
                                        }
                                    </div>
                                </Grid>
                            </div>
                        </div>

                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default ContributedDatasetStage;
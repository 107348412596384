import { SET_PROJECT_ADMIN_FIRST_NAME, SET_PROJECT_ADMIN_LAST_NAME, SET_PROJECT_ADMIN_MIDDLE_NAME, SET_PROJECT_ADMIN_CREDENTIALS, 
    SET_PROJECT_ADMIN_INSTITUTE_NAME, SET_PROJECT_ADMIN_INSTITUTE_EMAIL, SET_PROJECT_ADMIN_INSTITUTE_STREET_ADDRESS,
    SET_PROJECT_ADMIN_INSTITUTE_CITY, SET_PROJECT_ADMIN_INSTITUTE_STATE, SET_PROJECT_ADMIN_INSTITUTE_ZIP_CODE, SET_PROJECT_ADMIN_INSTITUTE_COUNTRY 
    , SET_PROJECT_ADMINISTRATOR_FIELDS, RESET_PROJECT_ADMINISTRATOR_FIELDS
} from '../action-types'
export const setProjectAdminFirstName = (value) => {
    return {
        type: SET_PROJECT_ADMIN_FIRST_NAME,
        payload: value
    }
}
export const setProjectAdminLastName = (value) => {
    return {
        type: SET_PROJECT_ADMIN_LAST_NAME,
        payload: value
    }
}
export const setProjectAdminMiddleName = (value) => {
    return {
        type: SET_PROJECT_ADMIN_MIDDLE_NAME,
        payload: value
    }
}
export const setProjectAdminCredentials = (value) => {
    return {
        type: SET_PROJECT_ADMIN_CREDENTIALS,
        payload: value
    }
}
export const setProjectAdminInstituteName = (value) => {
    return {
        type: SET_PROJECT_ADMIN_INSTITUTE_NAME,
        payload: value
    }
}
export const setProjectAdminInstituteEmail = (value) => {
    return {
        type: SET_PROJECT_ADMIN_INSTITUTE_EMAIL,
        payload: value
    }
}
export const setProjectAdminInstituteStreetAddress = (value) => {
    return {
        type: SET_PROJECT_ADMIN_INSTITUTE_STREET_ADDRESS,
        payload: value
    }
}
export const setProjectAdminInstituteCity = (value) => {
    return {
        type: SET_PROJECT_ADMIN_INSTITUTE_CITY,
        payload: value
    }
}
export const setProjectAdminInstituteState = (value) => {
    return {
        type: SET_PROJECT_ADMIN_INSTITUTE_STATE,
        payload: value
    }
}
export const setProjectAdminInstituteZipCode = (value) => {
    return {
        type: SET_PROJECT_ADMIN_INSTITUTE_ZIP_CODE,
        payload: value
    }
}
export const setProjectAdminInstituteCountry = (value) => {
    return {
        type: SET_PROJECT_ADMIN_INSTITUTE_COUNTRY,
        payload: value
    }
}
export const setProjectAdministratorFields = (value) => {
    return {
        type: SET_PROJECT_ADMINISTRATOR_FIELDS,
        payload: value
    }
}
export const resetProjectAdminForm = () => {
    return {
        type: RESET_PROJECT_ADMINISTRATOR_FIELDS,
    }
}
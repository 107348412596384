import ProjectAdministrator from './ProjectAdministratorForm'
import { connect } from 'react-redux'
import { setProjectAdminFirstName, setProjectAdminLastName, setProjectAdminMiddleName, setProjectAdminCredentials, setProjectAdminInstituteName,
    setProjectAdminInstituteEmail, setProjectAdminInstituteStreetAddress, setProjectAdminInstituteCity, setProjectAdminInstituteState,
    setProjectAdminInstituteZipCode, setProjectAdminInstituteCountry } from '../../../redux/actions/projectAdminActions'
const mapStateToProps = state => {
    return {
        projectAdminFirstName: state.projectAdmin.projectAdminFirstName,
        projectAdminLastName: state.projectAdmin.projectAdminLastName,
        projectAdminMiddleName: state.projectAdmin.projectAdminMiddleName,
        projectAdminCredentials: state.projectAdmin.projectAdminCredentials,
        projectAdminInstituteName: state.projectAdmin.projectAdminInstituteName,
        projectAdminInstituteEmail: state.projectAdmin.projectAdminInstituteEmail,
        projectAdminInstituteStreetAddress: state.projectAdmin.projectAdminInstituteStreetAddress,
        projectAdminInstituteCity: state.projectAdmin.projectAdminInstituteCity,
        projectAdminInstituteState: state.projectAdmin.projectAdminInstituteState,
        projectAdminInstituteZipCode: state.projectAdmin.projectAdminInstituteZipCode,
        projectAdminInstituteCountry: state.projectAdmin.projectAdminInstituteCountry,
        currentDCAFormStage: state.dca.currentDCAFormStage

    }
}

const mapDispatchToProps = dispatch => {
    return {
        setProjectAdminFirstName: async (value) => await dispatch(setProjectAdminFirstName(value)),
        setProjectAdminLastName: async (value) => await dispatch(setProjectAdminLastName(value)),
        setProjectAdminMiddleName: async (value) => await dispatch(setProjectAdminMiddleName(value)),
        setProjectAdminCredentials: async (value) => await dispatch(setProjectAdminCredentials(value)),
        setProjectAdminInstituteName: async (value) => await dispatch(setProjectAdminInstituteName(value)),
        setProjectAdminInstituteEmail: async (value) => await dispatch(setProjectAdminInstituteEmail(value)),
        setProjectAdminInstituteStreetAddress: async (value) => await dispatch(setProjectAdminInstituteStreetAddress(value)),
        setProjectAdminInstituteCity: async (value) => await dispatch(setProjectAdminInstituteCity(value)),
        setProjectAdminInstituteState: async (value) => await dispatch(setProjectAdminInstituteState(value)),
        setProjectAdminInstituteZipCode: async (value) => await dispatch(setProjectAdminInstituteZipCode(value)),
        setProjectAdminInstituteCountry: async (value) => await dispatch(setProjectAdminInstituteCountry(value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectAdministrator)
import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import Classes from './index.module.css'
import { COUNTRY_LIST, DIF_FORM_COMPLETED_STATUS } from '../../../constants/strings'
import v4 from 'uuid/v4'
import Select from '@mui/material/Select';
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import DropZone from 'react-dropzone';
import ClosePng from '../../../assets/images/close.png'
import get from 'lodash/get'
import ErrorText from '../error-text';
import { validateEmailAddress } from '../../../utils/input-validations'

const DIFOtherPersonal = props => {
    //const difOPAddedUsers = [1,2];
    const {
        difOPAddedUsers,
        difOPWillOtherUpload,
        isFormSubmitted,
        currentDIFFormStage,

        addDifOPUsers,
        removeOtherPersonalUser,
        updateDifOPUsersFirstName,
        updateDifOPUsersLastName,
        updateDifOPUsersTitle,
        updateDifOPUsersEmail,
        setDifOPWillOtherUploadData
    } = props;
    const [ addMoreClick, setAddMoreClick ] = useState(false);
    const [ validateAddedUsers, setValidateAddedUsers ] = useState({});
    const disableInput = currentDIFFormStage === DIF_FORM_COMPLETED_STATUS;

    const validateOPForm = () => {
        let isFormValid = false;
        map(difOPAddedUsers, (usersData, index)=>{
            setValidateAddedUsers({...validateAddedUsers, [index]: false});
            for(let key in usersData){
                const data = usersData[key];
                const isEmptyField = data === null ? false : !isEmpty(data);
                const isEmailValid = key === 'institutional_email' ? validateEmailAddress(usersData[key]) : true;
                if(isEmptyField && isEmailValid){
                    isFormValid = true;
                    setValidateAddedUsers({...validateAddedUsers, [index]: true});
                    setAddMoreClick(false);
                }else{
                    isFormValid = false;
                    setValidateAddedUsers({...validateAddedUsers, [index]: false});
                    setAddMoreClick(true);
                    break;
                }
            }
        })
        return isFormValid;
    }

    useEffect(() => {
            document.getElementById('difSubmitButton').addEventListener('click', ()=>{
                validateOPForm();
                setAddMoreClick(true);
            })
    }, []);

    const onAddMoreClick = () => {
        if (!disableInput) {
            setAddMoreClick(true);
            const isFormValid = validateOPForm();
            if (isFormValid) {
                addDifOPUsers({
                    'first_name': '',
                    'last_name': '',
                    'title': '',
                    'institutional_email': ''
                });
            }
        }
    }
    const removeUser = (id) => {
        if (!disableInput) {
            removeOtherPersonalUser(id);
            const tempValidateUsers = { ...validateAddedUsers };
            delete tempValidateUsers[id];
            setValidateAddedUsers(tempValidateUsers);
        }
    }
    const onFirstNameChange = (event, id) => {
        updateDifOPUsersFirstName(event.target.value, id);
    }
    const onLastNameChange = (event, id) => {
        updateDifOPUsersLastName(event.target.value, id);
    }
    const onTitleChange = (event, id) => {
        updateDifOPUsersTitle(event.target.value, id);
    }
    const onEmailChange = (event, id) => {
        updateDifOPUsersEmail(event.target.value, id);
    }
    const onWillOtherUploadDataChange = value => {
        setDifOPWillOtherUploadData(value);
    }
    return (
        <div className={Classes.container} id="cvb-DIFOtherPersonal-mainContainer">
            <p className={Classes.formTitle} id="cvb-DIFOtherPersonal-formHeading">Other Personnel</p>
            <Grid item xs={12} >
                <label id="cvb-DIFOtherPersonal-uploadLabel">Will you upload this data?<sup> *</sup></label>
                <Grid container>
                    <Grid item xs={2} className={Classes.radioCheckContainer}>
                        <label className={Classes.radioContainer} id="cvb-DIFOtherPersonal-uploadYesLabel">Yes</label>
                        <input id='willUploadOnBehalf1' name="willUploadOnBehalf" type="radio" 
                            disabled={disableInput}
                            checked={difOPWillOtherUpload === 'yes'} value='yes'
                            onChange={() => onWillOtherUploadDataChange('yes')} />
                        <span className={Classes.checkmark}></span>
                    </Grid>
                    <Grid item xs={2} className={Classes.radioCheckContainer}>
                        <label className={Classes.radioContainer} id="cvb-DIFOtherPersonal-uploadNoLabel">No</label>
                        <input id='willUploadOnBehalf2' name="willUploadOnBehalf" type="radio"
                            disabled={disableInput} 
                            checked={difOPWillOtherUpload === 'no'} value='no'
                            onChange={() => onWillOtherUploadDataChange('no')} />
                        <span className={Classes.checkmark}></span>
                    </Grid>
                </Grid>
                {
                    isFormSubmitted && isEmpty(difOPWillOtherUpload) &&
                    <ErrorText errorMessage="Select value" />
                }
            </Grid>
            <Grid item xs={10}>
                <div className={Classes.dottedLine}></div>
            </Grid>
            {difOPWillOtherUpload === 'no' &&
                <React.Fragment>
                    <Grid item xs={12}>
                        {map(difOPAddedUsers, (addedUsers, id) => {
                            return <div key={`difOPUsers${id}`}>
                                {!disableInput && id > 0 && <Grid item xs={10}>
                                    <img id="cvb-DIFOtherPersonal-removeLink" src={ClosePng} alt='delete' className={Classes.removeFields} onClick={() => removeUser(id)} />
                                </Grid>
                                }
                                <Grid container direction='row' spacing={3}>
                                    <Grid item xs={5} className={Classes.fieldCont}>
                                        <label htmlFor={`firstName${id}`} id="cvb-DIFOtherPersonal-firstNameLabel">First Name<sup> *</sup></label>
                                        <OutlinedInput
                                            type="text"
                                            placeholder="Enter first name"
                                            value={get(addedUsers, 'first_name', '')}
                                            onChange={(event) => onFirstNameChange(event, id)}
                                            fullWidth
                                            id={`firstName${id}`}
                                            name={`firstName${id}`}
                                            disabled={disableInput}
                                        />
                                        {
                                            (addMoreClick && !validateAddedUsers[id]) && isEmpty(addedUsers['first_name']) &&
                                            <ErrorText errorMessage="Enter first name" />
                                        }
                                    </Grid>
                                    <Grid item xs={5} className={Classes.fieldCont}>
                                        <label htmlFor={`lastName${id}`} id="cvb-DIFOtherPersonal-lastNameLabel">Last Name<sup> *</sup></label>
                                        <OutlinedInput
                                            type="text"
                                            placeholder="Enter last name"
                                            value={get(addedUsers, 'last_name', '')}
                                            onChange={(event) => onLastNameChange(event, id)}
                                            fullWidth
                                            id={`lastName${id}`}
                                            name={`lastName${id}`}
                                            disabled={disableInput}
                                        />
                                        {
                                            (addMoreClick && !validateAddedUsers[id]) && isEmpty(addedUsers['last_name']) &&
                                            <ErrorText errorMessage="Enter last name" />
                                        }
                                    </Grid>
                                    <Grid item xs={5} className={Classes.fieldCont}>
                                        <label htmlFor={`title${id}`} id="cvb-DIFOtherPersonal-titleLabel">Title<sup> *</sup></label>
                                        <OutlinedInput
                                            type="text"
                                            placeholder="Enter position"
                                            value={get(addedUsers, 'title', '')}
                                            onChange={(event) => onTitleChange(event, id)}
                                            fullWidth
                                            id={`title${id}`}
                                            name={`title${id}`}
                                            disabled={disableInput}
                                        />
                                        {
                                             (addMoreClick && !validateAddedUsers[id]) && isEmpty(addedUsers['title']) &&
                                            <ErrorText errorMessage="Enter title" />
                                        }
                                    </Grid>
                                    <Grid item xs={5} className={Classes.fieldCont}>
                                        <label htmlFor={`email${id}`} id="cvb-DIFOtherPersonal-instituteEmailLabel">Institutional Email<sup> *</sup></label>
                                        <OutlinedInput
                                            type="text"
                                            placeholder="Enter email"
                                            value={get(addedUsers, 'institutional_email', '')}
                                            onChange={(event) => onEmailChange(event, id)}
                                            fullWidth
                                            id={`email${id}`}
                                            name={`email${id}`}
                                            disabled={disableInput}
                                        />
                                        {
                                            (addMoreClick && !validateAddedUsers[id]) && !validateEmailAddress(addedUsers['institutional_email']) &&
                                            <ErrorText errorMessage="Enter email" />
                                        }
                                    </Grid>
                                    <Grid item xs={10}>
                                        <div className={Classes.dottedLine}></div>
                                    </Grid>
                                </Grid>
                            </div>
                        })
                        }
                    </Grid>
                  {!disableInput && <p className={Classes.addMoreFields} onClick={onAddMoreClick} id="cvb-DIFOtherPersonal-addMoreLink">+ Add More</p>}
                </React.Fragment>
            }
        </div>
    )
}
DIFOtherPersonal.defaultProps = {
    difOPAddedUsers: [],
    difOPWillOtherUpload: '',
    isFormSubmitted: false,
    currentDIFFormStage: '',
    addDifOPUsers: () => { },
    removeOtherPersonalUser: () => { },
    updateDifOPUsersFirstName: () => { },
    updateDifOPUsersLastName: () => { },
    updateDifOPUsersTitle: () => { },
    updateDifOPUsersEmail: () => { },
    setDifOPWillOtherUploadData: () => { }
}
export default DIFOtherPersonal;

import React, { useState, useMemo } from 'react'
import MuiOutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid';
import Classes from './index.module.css';
import MuiPaper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import ProfileImage from '../../../assets/images/user/default-profile-image.png'
import SearchIcon from '../../../assets/icons/search-icon.svg';
import map from 'lodash/map'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get'
import { SIGNUP_ERROR_TEXTS } from '../../../constants/strings';
import { VALID_EMAIL_REGEX } from '../../../constants/regex';
import ErrorText from '../error-text';
import { trimStringToTenChar } from '../../../utils/stringFormatter';
import Tooltip from '@mui/material/Tooltip'
import CloseIcon from '../../../assets/icons/close-small.svg'
import findIndex from 'lodash/findIndex';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { shareNotebook, getSharedUserForNotebook, unshareNotebook } from '../../../api/notebook-api';
import v4 from 'uuid/v4'

const Paper = withStyles(theme => ({
    root: {
        height: '100%',
        boxShadow: '0px 3px 6px #00000029',
        border: '1px dashed #637BBD',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        display: 'flex',
        width: 140,
        padding: '0px 5px',
        backgroundColor: '#FFFFFF'
    },
}))(MuiPaper);

const OutlinedInput = withStyles(theme => ({
    root: {
        height: 20,
        width: 300,
        backgroundColor: '#FFF'
    },
}))(MuiOutlinedInput);

const AddUsersToShareAll = props => {
    const [userText, setUserText] = useState({ text: '', isValid: false, isSubmit: false, invalidText: SIGNUP_ERROR_TEXTS.EMAIL, isAlreadyPresent: false });
    const { isWorkspaceShare = false } = props;
    const shareTitle = props.workspaceId;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { sharedUsersList = [] } = props;
    const [filteredUserList, setFilteredUserList] = useState([]);
    const [sharedUserId, setSharedUserId] = useState([]);
    const [disabledInputBox, setDisabledInputBox] = useState(false);
    const { unshareForSelectedUser = () => { } } = props;
    const {filesSelected} = props;
    const {shareAllFilePath} = props;

    const onUserNameChange = (val) => {
        try {
            setUserText({ ...userText, text: val, isSubmit: false });
            setIsDropdownOpen(false);
        } catch (error) {
            console.log('error in setting text', error)
        }
    }

    const onAddUserClick = async (user) => {
        try {
            let filesSelectedArray = [];
            for (var key in filesSelected) {
                filesSelectedArray.push(shareAllFilePath+key);
            }
            const data = {...user, keys: filesSelectedArray, workspaceAccountNumber: shareTitle};
            const res = await shareNotebook(data);
            const userData = { "sharedWith": user.sharedWith };
            setSharedUserId([...sharedUserId, userData]);
            setDisabledInputBox(true);
            props.updateSnackBar("Successfully Shared", "Succcess");
        } catch (error) {
            props.updateSnackBar("Unable to Share file", "Error");
            console.log('error in adding users', error);
        }
    }
    const unshareNotebookForSelectedUser = async (email) => {
        try {
            // const data = {
            //     "sharedWith": email,
            //     "key": `${folderDirectory}${fileId}`
            // };
            let loop = 0;
            for (var key in filesSelected) {
                const data = {sharedWith: email, key: shareAllFilePath+key};
                const res = await unshareNotebook(data);
                const isPresent = findIndex(sharedUserId, (user) => { return user['sharedWith'] === email;});
                if(sharedUserId?.length && loop === 0){
                    let removeUsers = sharedUserId;
                    removeUsers.splice(isPresent,1);
                    if(removeUsers?.length){
                        setSharedUserId([...removeUsers]);
                    }else{
                        setSharedUserId([]);
                    }
                    loop = 1;
                }
            }
        } catch (error) {
            console.log('error ins start or stop');
        }
    }
    const validateUserText = () => {
        let re = VALID_EMAIL_REGEX;
        re.test(userText['text']) ? setUserText({ ...userText, isSubmit: true, isValid: true })
            : setUserText({ ...userText, isSubmit: true, isValid: false });
        return re.test(userText['text']);
    }
    const checkForAlreadyPresentUser = () => {
        const isEmailValid = validateUserText();
        const isPresent = findIndex(sharedUserId, (user) => user['sharedWith'] === userText['text']) > -1;
        if (isPresent) {
            setUserText({ ...userText, isValid: isEmailValid, isSubmit: true, isAlreadyPresent: true })
        } else {
            setUserText({ ...userText, isValid: isEmailValid, isSubmit: true, isAlreadyPresent: false })
        }
        return isPresent;
    }
    const onAddUserClickToShare = (e) => {
        try {
            const isValid = validateUserText();
            const isUserAlreadyPresent = checkForAlreadyPresentUser();
            if (isValid && !isUserAlreadyPresent) {
                setUserText({ text: '', isValid: false, isSubmit: false, invalidText: SIGNUP_ERROR_TEXTS.EMAIL });
                //call add user API
                const userData = { "sharedWith": userText['text'] };
                onAddUserClick(userData);
            }
        } catch (error) {
            console.log('enable to add users', error);
        }
    }
    const userResults = useMemo(() => {
        const searchText = userText['text'] || '';
        return props.usersForShare?.filter(item => item.toLowerCase().includes(searchText?.toLowerCase()))
    }, [props.usersForShare, userText['text']]);

    const unshareWorkspace = (email) => {
        unshareNotebookForSelectedUser(email);
        setDisabledInputBox(false);
    }
    const setSearchText = value => {
        setUserText({ ...userText, text: value, isSubmit: false });
    }

    const handleClickAway = () => {
        setSearchText("")
    }
    const isAddUserDisabled = () => {
        return !props.usersForShare?.find((item) => item.toLowerCase() === userText['text']?.toLowerCase())
    }
    return (
        <React.Fragment>
            <div className={Classes.container}>
                <div className={Classes.titleContainer}>
                    <p id='cvb-addUserToShareAll-shareTitle' className={Classes.shareModalTitle}>{shareTitle}</p>
                </div>
                <div className={Classes.addUserContainer}>
                    <div className={Classes.userTextInputContainer}>
                        <label id='cvb-addUserToShareAll-user-label' htmlFor='userName'>User</label>
                        <div className={Classes.textInputButtonContainer}>
                            <div className={Classes.inputDropdownContainer}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={userResults}
                                    getOptionLabel={(option) => option}
                                    onChange={(e, newValue) => onUserNameChange(newValue)}
                                    onInputChange={(e, val) => { onUserNameChange(val) }}
                                    value={userText['text']}
                                    style={{ width: 400}}
                                    renderInput={(params) => <TextField {...params}
                                        variant="outlined"
                                        label=""
                                        margin="dense"
                                        placeholder='user'
                                        onChange={e => onUserNameChange(e.target.value)} />}
                                />
                                {(userText['isSubmit'] && !userText['isValid']) &&
                                    <div id='cvb-addUserToShareAll-errorText' className={Classes.errorTextWrapper}> <ErrorText errorMessage={SIGNUP_ERROR_TEXTS.EMAIL} /></div>
                                }
                                {(userText['isSubmit'] && userText['isValid']) && userText['isAlreadyPresent'] &&
                                    <div id='cvb-addUserToShareAll-errorText' className={Classes.errorTextWrapper}> <ErrorText errorMessage={'User is already added.'} /></div>
                                }
                            </div>
                            <button id='cvb-addUserToShareAll-shareButton' onClick={onAddUserClickToShare} disabled={isAddUserDisabled()} className={`medium-size-button ${Classes.addUserButton}`}>Add user</button>
                        </div>
                    </div>
                    <div className={Classes.userCardsContainer}>
                        <Grid container direction='row' spacing={2}>
                        {sharedUserId.map((user) => {
                            return (
                                <Grid item className={Classes.userDetailsCard} key={v4()}>
                                    <Paper className={Classes.cardPaperCustom}>
                                        <img id='cvb-addUserToShareAll-unshare' src={CloseIcon} alt='close icon' className={Classes.closeIconStyle} onClick={() => unshareNotebookForSelectedUser(user.sharedWith)} />
                                        <img id='cvb-addUserToShareAll-userProfile' alt='profile' className={Classes.userProfileImage} src={ProfileImage} />
                                        <p id='cvb-addUserToShareAll-sharedWith' className={Classes.userName}>{user.sharedWith}</p>
                                    </Paper>
                                </Grid>
                             )
                        })  
                        }
                        </Grid>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddUsersToShareAll;
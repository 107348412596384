import React from 'react';
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from '@mui/styles';
import { vars } from '../../../../assets/variables.js'

const { white, borderColor } = vars
const useStyles = makeStyles(() => ({
	root: {
		background: white,
		border: `1px solid ${borderColor}`,
		boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
		borderRadius: '0.5rem'
	}
}))

export const PrimitiveContainer = ({ children, height, ...props }) => {
	const classes = useStyles()
  return (
    <Box
			className={classes.root}
			style={{ height: height ? height : "auto" }}
			{...props}
    >
      {children}
    </Box>
  );
};

PrimitiveContainer.propTypes = {
  children: PropTypes.node,
  height: PropTypes.string,
};

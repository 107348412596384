import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Box,
  DialogContentText,
} from '@mui/material';
import { CloseOutlined, Search } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import ColumnItem from './ColumnItem';
import { useMemo } from 'react';
import { debounce } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../commons/accordion/Accordion';

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 0,
    },
  },
  dialogText: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
}));

const CustomizeColumnsDialog = ({ open, onClose, columns, updateColumns }) => {
  const classes = useStyles();
  const [activePanelOpen, setActivePanelOpen] = React.useState(true);
  const [inactivePanelOpen, setInactivePanel] = React.useState(true);

  const [value, setValue] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [activeColumns, setActiveColumns] = React.useState(() =>
    columns.filter((column) => column.enabled)
  );
  const [inactiveColumns, setInactiveColumns] = React.useState(() =>
    columns.filter((column) => !column.enabled)
  );
  const defaultColumns = [...columns];

  //debounce search term
  const debounceFn = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 800),
    []
  );

  function onSearch(event) {
    setValue(event.target.value);
    debounceFn(event.target.value);
  }

  const filteredActiveColumns = useMemo(() => {
    //filter enabled columns by search term
    const filtered = activeColumns.filter((column) =>
      column.title.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    return !searchTerm ? activeColumns : filtered;
  }, [activeColumns, columns, searchTerm]);

  const filteredInactiveColumns = useMemo(() => {
    //filter disabled columns by search term
    const filtered = inactiveColumns.filter((column) =>
      column.title.toLowerCase().startsWith(searchTerm.toLowerCase())
    );

    return !searchTerm && inactiveColumns ? inactiveColumns : filtered;
  }, [columns, inactiveColumns, searchTerm]);

  const handleColumnStatusChange = useCallback(
    async (queryVariable) => {
      const newInactiveColumns = [...inactiveColumns];
      const newActiveColumns = [...activeColumns];

      if (
        Array.isArray(inactiveColumns) &&
        !!inactiveColumns.find((col) => col.queryVariable === queryVariable)
      ) {
        const column = newInactiveColumns.find(
          (col) => col.queryVariable === queryVariable
        );
        const filtered = newInactiveColumns.filter(
          (col) => col.queryVariable !== queryVariable
        );

        // update columns
        setInactiveColumns(filtered);
        setActiveColumns([...activeColumns, { ...column, enabled: true }]);
      } else {
        const column = newActiveColumns.find(
          (col) => col.queryVariable === queryVariable
        );
        const filtered = newActiveColumns.filter(
          (col) => col.queryVariable !== queryVariable
        );

        // update columns
        setActiveColumns(filtered);
        setInactiveColumns([...inactiveColumns, { ...column, enabled: false }]);
      }
    },
    [activeColumns, inactiveColumns]
  );

  function getColumnList(type) {
    return type === 'active' ? activeColumns : inactiveColumns;
  }

  useEffect(() => {
    setActiveColumns(columns.filter((column) => column.enabled));
    setInactiveColumns(columns.filter((column) => !column.enabled));
  }, [columns]);

  /**
   * result {
   * destination {draggableId, index}
   * }
   */
  function onDragEnd(result) {
    const { source, destination } = result;
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'active') {
        const items = reorder(activeColumns, source.index, destination.index);

        setActiveColumns(items);
      } else {
        const items = reorder(inactiveColumns, source.index, destination.index);

        setInactiveColumns(items);
      }
    } else {
      const result = move(
        getColumnList(source.droppableId),
        getColumnList(destination.droppableId),
        source,
        destination
      );

      //update columns after move
      setActiveColumns(result.active);
      setInactiveColumns(result.inactive);
    }

    // move
    result.source.index, result.destination.index;
  }

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="xs"
      className={classes.dialog}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignContent="center"
          sx={{
            paddingTop: '1.5rem',
            paddingX: '1rem',
          }}
        >
          Customize
          <IconButton
            onClick={async () => {
              if (onClose) {
                //Undo any column changes if the window was closed
                updateColumns(defaultColumns);
                onClose();
              }
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box paddingX={2} paddingY={1}>
            <TextField
              placeholder="Search for Metadata"
              value={value}
              variant="outlined"
              onChange={(e) => onSearch(e)}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>
          <Droppable droppableId="active">
            {(provided) => (
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                <Accordion
                  expanded={activePanelOpen}
                  onChange={() => setActivePanelOpen(!activePanelOpen)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>Active Columns</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {filteredActiveColumns &&
                    filteredActiveColumns.length > 0 ? (
                      filteredActiveColumns.map((column, index) => (
                        <Draggable
                          key={column.queryVariable}
                          draggableId={column.queryVariable}
                          index={index}
                          type={'droppable'}
                          isDragDisabled={searchTerm.length > 0}
                        >
                          {(provided, snapshot) => (
                            <ColumnItem
                              key={column.queryVariable}
                              index={index}
                              innerRef={provided.innerRef}
                              snapshot={snapshot}
                              provided={provided}
                              column={column}
                              handleChange={(queryVariable) =>
                                handleColumnStatusChange(queryVariable)
                              }
                              isDragDisabled={searchTerm.length > 0}
                            />
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <DialogContentText
                        className={classes.dialogText}
                      >{`No active columns ${
                        !searchTerm ? '' : `starts with ${searchTerm}`
                      }`}</DialogContentText>
                    )}
                  </AccordionDetails>

                  {provided.placeholder}
                </Accordion>
              </Box>
            )}
          </Droppable>

          <Droppable droppableId="inactive">
            {(provided) => (
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                <Accordion
                  expanded={inactivePanelOpen}
                  onChange={() => setInactivePanel(!inactivePanelOpen)}
                >
                  <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <Typography fontWeight={600}>Inactive Columns</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {filteredInactiveColumns &&
                    filteredInactiveColumns.length > 0 ? (
                      filteredInactiveColumns.map((column, index) => {
                        return (
                          <Draggable
                            key={column.queryVariable}
                            draggableId={column.queryVariable}
                            index={index}
                            type={'droppable'}
                            isDragDisabled={searchTerm.length > 0}
                          >
                            {(provided, snapshot) => (
                              <ColumnItem
                                key={column.queryVariable}
                                index={index}
                                innerRef={provided.innerRef}
                                snapshot={snapshot}
                                provided={provided}
                                column={column}
                                handleChange={(queryVariable) =>
                                  handleColumnStatusChange(queryVariable)
                                }
                                isDragDisabled={searchTerm.length > 0}
                              />
                            )}
                          </Draggable>
                        );
                      })
                    ) : (
                      <DialogContentText
                        className={classes.dialogText}
                      >{`No Inactive Columns ${
                        !searchTerm ? '' : `starts with ${searchTerm}`
                      }`}</DialogContentText>
                    )}
                  </AccordionDetails>

                  {provided.placeholder}
                </Accordion>
              </Box>
            )}
          </Droppable>
        </DialogContent>

        <DialogActions
          sx={{
            display: 'block',
            padding: '1rem',
            marginTop: 0,
            minWidth: '100%',
            border: `1px solid rgba(208, 213, 221, 1)`,
            boxShadow: ` 0px -4px 8px 0px rgba(16, 24, 40, 0.1)`,
          }}
        >
          <Button
            onClick={async () => {
              const newColumns = activeColumns.concat(inactiveColumns);
              // update columns before close action
              await updateColumns(newColumns);

              onClose();
            }}
            color="primary"
            className="disable-shadow"
            variant="contained"
            fullWidth
            sx={{ minWidth: '100%' }}
          >
            Save
          </Button>
        </DialogActions>
      </DragDropContext>
    </Dialog>
  );
};

CustomizeColumnsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      queryVariable: PropTypes.string,
      type: PropTypes.string,
      enabled: PropTypes.bool.isRequired,
    })
  ).isRequired,
  updateColumns: PropTypes.func,
};

export default CustomizeColumnsDialog;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  //update enabled status before adding to new column list
  const updatedRemove = { ...removed, enabled: !removed.enabled };

  destClone.splice(droppableDestination.index, 0, updatedRemove);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

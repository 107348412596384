
import axios from 'axios'
import get from 'lodash/get'
import { BASE_URL } from '../constants/google-login-urls'
import { getCognitoIdToken } from './utilityMethods';
import memoizeOne from 'memoize-one';



export const getDataModelSchema = memoizeOne(async () => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.get(`${BASE_URL}/data_access/download/dictionary/_all`, { headers: header });
        return get(res, 'data', {});
    } catch (error) {
        throw Error(error.message);
    }
});


export const getDownloadFile = async (nodeName, format) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',

        };
        const res = await axios.get(`${BASE_URL}/data_access/download/template/${nodeName}?file_format=${format}`, { headers: header }, { responseType: 'blob' });
        const downloadUrl = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${nodeName}.${format}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        throw Error(error.message);
    }
}


import React from 'react'
import FormHelperText from '@mui/material/FormHelperText';
import ErrorOutlineIcon from '../../../assets/images/error_outline_red.png';
import get from 'lodash/get'
import Classes from './index.module.css'
const ErrorText = (props) => {
    return <FormHelperText>
        <img alt='error icon' src={ErrorOutlineIcon} />
        <span className={Classes.errorText}>{get(props, 'errorMessage', '')}</span>
    </FormHelperText>
}

export default ErrorText;

import { connect } from 'react-redux'
import AllNotificationsView from './AllNotificationsView'

const mapStateToProps = state => {
    return {
        webSocketConnection: state.global.webSocket,
        webSocketOnMessage: state.global.onSocketMessage
    }
}

export default connect(mapStateToProps)(AllNotificationsView);
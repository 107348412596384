import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import classes from './index.module.css'
import SectionHeader from '../../commons/section-header'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import v4 from 'uuid/v4';
import DropDown from '../../commons/dropdown';
import TablePagination from '../../commons/table-pagination';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '../../../assets/icons/search-icon.svg';
import moment from 'moment';
import CommonDatePicker from '../../commons/common-date-picker';
import { navigate } from '@reach/router';
import { getInvoiceDetails, updateInvoiceStatus } from '../../../api/invoice-api';
import Modal from '../../commons/modal'
import Tooltip from '@mui/material/Tooltip'
import { INVOICE_TRANSACTION_TYPE_STATUS } from '../../../constants/strings'
import InfoGreyIcon from '../../../assets/icons/InfoIcon.svg';
import withStyles from '@mui/styles/withStyles';
import map from 'lodash'
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  startOfQuarter
} from '../../../utils/date-formatter';
import CalenderIcon from '../../../assets/icons/calender-icon.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import { cloneDeep, filter, isEmpty, rest } from 'lodash'
import { useLocation } from "@reach/router"
import { isDate } from 'lodash';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
  startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
  startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
  startOfLastFullYearDay: startOfDay(addDays(new Date(), -365)),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  startOfQuarter: startOfQuarter(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOflastYear: startOfYear(addYears(new Date(), -1)),
  endOflastYear: endOfYear(addYears(new Date(), -1))
};
const sideBarOptions = () => {
  const customDateObjects = [
    {
      label: "Today",
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday
      })
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday
      })
    },
    {
      label: "Last Week",
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek
      })
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth
      })
    },
    {
      label: "Month to date",
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfToday
      })
    },
    {
      label: "Quarter to date ",
      range: () => ({
        startDate: defineds.startOfQuarter,
        endDate: defineds.endOfToday
      })
    },
    {
      label: "Year to date",
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfToday
      })
    }
  ];

  return customDateObjects;
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FCF8C8",
    color: "#222222",
    boxShadow: "-1px 1px 106px -61px rgba(74,73,74,1);",
    fontSize: 14,
    fontWeight: "medium",
    marginRight: '3px',
    fontFamily: "Raleway",
    borderRadius: 0,
    padding: 15,
    textAlign: "left"
  },
  arrow: {
    color: "#FCF8C8",
  },
  customWidth: {
    maxWidth: 200,
  },
}))(Tooltip);
const PAGE_LIMIT = 10;

const InvoiceLanding = ({ updateSnackBar }) => {

  const [searchId, setSearchId] = useState('');
  const [status, setStatus] = useState('Show All');
  const [type, setType] = useState('Show All');
  const [billedTo, setBilledTo] = useState('');
  const [dateOfIssue, setDateOfIssue] = useState('')
  const [entityId, setEntityId] = useState('');

  const [invoices, setInvoices] = useState([])
  const [pageNumber, setPageNumber] = useState(0)
  const [selectedViewInvoice, setSelectedViewInvoice] = useState(null)
  const [selectedUpdateStatusInvoice, setSelectedUpdateStatusInvoice] = useState(null)
  const [selectionDateRage, setSelectedDateRange] = useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfDay(new Date()),
    key: 'selection'
  });
  const [openCalender, setOpenCalender] = useState(false);
  const [selectedCalenderInput, setSelectedCalenderInput] = useState('');
  const [allInvoicesData, setAllInvoicesData] = useState([])
  const [recentAppliedDateRange, setRecentAppliedDateRange] = useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfDay(new Date()),
    key: 'selection'
  })
  const sectionHeaderProps = {
    title: "Invoice",
    pageDefinition: "",
  }

  const fetchData = async () => {
    try {
      let invoiceData = [];
      const response = await getInvoiceDetails();
      if (response.length) {
        response.map((data) => {
          let creditType = data?.type;
          if (creditType === 'CREDIT') {
            creditType = data?.entity_type === 'WORKSPACE_ACCOUNT' ? 'WORKSPACE CREDIT' : 'ACCOUNT CREDIT';
          }
          invoiceData.push({ ...data, type: creditType })
        })
        setInvoices(invoiceData);
        setAllInvoicesData(invoiceData);
      } else {
        setInvoices([])
      }

    }
    catch (error) {
      console.log(error)
    }
  }
  const onSelectDateOfIssue = value => {
    setDateOfIssue(value);
    setPageNumber(0);
  }

  const finalInvoices = useMemo(() => {
    return invoices?.filter(({ id = '', status: s = '', type: t = '', billed_to = '', email = '', entity_id = '', created_at = '', applyDatePickerButtonClick = false }) => {
      return `${id} ${billed_to} ${entity_id} ${email} `?.toLowerCase()?.includes(searchId?.toLowerCase()) &&
        (s === status || status === 'Show All' || status === '') &&
        (t === type || type === 'Show All' || type === '')

    })
  }, [searchId, status, type, billedTo, entityId, dateOfIssue, invoices])

  const displayedInvoices = useMemo(() => {
    return finalInvoices.slice(pageNumber * PAGE_LIMIT, (pageNumber + 1) * PAGE_LIMIT)
  }, [finalInvoices, pageNumber])

  useEffect(() => {
    fetchData()
  }, []);

  const updateStatus = async ({ status, id }) => {
    try {
      const finalStatus = status === "PAID" ? "UNPAID" : "PAID"
      const response = await updateInvoiceStatus({
        id,
        status: finalStatus
      })
      updateSnackBar(response?.message || "Status successfully updated", 'success')
      fetchData()
    }
    catch (error) {
      console.log(error)
      updateSnackBar(error?.response?.data?.message || "Something went wrong, Please try again!", 'error')
    }
  }
  const sideBar = sideBarOptions();
  const staticRanges = [
    ...createStaticRanges(sideBar)
  ];

  useEffect(() => {

    const calenderInputLabel = document.querySelector('.rdrStaticRangeSelected')?.firstChild?.textContent || `${moment(selectionDateRage?.startDate).format('MM-DD-YYYY')} - ${moment(selectionDateRage?.endDate).format('MM-DD-YYYY')}`;
    setSelectedCalenderInput(calenderInputLabel);

  }, [selectionDateRage])

  const onResetDateRangeSelection = () => {
    setSelectedDateRange({
      startDate: startOfMonth(new Date()),
      endDate: endOfDay(new Date()),
      key: 'selection'
    })
    setInvoices(allInvoicesData);
    setRecentAppliedDateRange({
      startDate: startOfMonth(new Date()),
      endDate: endOfDay(new Date()),
      key: 'selection'
    });
  }
  const onCalenderCancelButtonClick = () => {
    setOpenCalender(false);
    setSelectedDateRange(recentAppliedDateRange)
  }
  //makes API call
  const onApplyDateRangeButtonClick = () => {
    if (isDate(selectionDateRage.startDate) && isDate(selectionDateRage.endDate)) {

      let filteredData = [];
      filteredData = filter(allInvoicesData, (data) => moment(data?.created_at).isBetween(moment(selectionDateRage.startDate), moment(selectionDateRage.endDate).endOf('day')))

      setInvoices(filteredData);
      setOpenCalender(false);
      setRecentAppliedDateRange(selectionDateRage);
    }

  }
  const onCalenderSelect = (data) => {
    setSelectedDateRange(data.selection);
  }


  return (
    <div className={classes.mainContainer}>
      <SectionHeader
        //open={props.open}
        sectionHeaderProps={sectionHeaderProps}
      />

      <Modal
        open={!!selectedViewInvoice}
        handleClose={() => setSelectedViewInvoice(null)}
        dialogProps={{
          modalTitle: 'Invoice Details',
          // positiveButtonText: 'Close',
          //positiveButtonAction: () => setSelectedViewInvoice(null),
        }}
        bothPositiveButtons={false}
        disableTitleDivider={true}
        disableBottomHorizontalDivider={true}
      >
        <div>
          <div className={classes.blockItem}>
            <h4 className={classes.blockTitle}>Amount </h4>
            <p className={classes.blockContent}>$ {selectedViewInvoice?.amount}</p>
          </div>
          <div className={classes.blockItem}>
            <h4 className={classes.blockTitle}>Account Name/Workspace ID</h4>
            <p className={classes.blockContent}>{selectedViewInvoice?.entity_type === 'USER_ACCOUNT' ? selectedViewInvoice?.email : selectedViewInvoice?.entity_id}</p>
          </div>
          {
            selectedViewInvoice?.comments &&
            <div className={classes.blockItem}>
              <h4 className={classes.blockTitle}>Other Information</h4>
              <p className={classes.blockContent}>{selectedViewInvoice?.comments}</p>
            </div>
          }

          {selectedViewInvoice?.type === 'ADJUSTMENT' ? '' :
            <>
              <div className={classes.blockItem}>
                <h4 className={classes.blockTitle}>Billing Name </h4>
                <p className={classes.blockContent}>{selectedViewInvoice?.billing_information?.name}</p>
              </div>
              <div className={classes.blockItem}>
                <h4 className={classes.blockTitle}>Billing Email </h4>
                <p className={classes.blockContent}>{selectedViewInvoice?.billing_information?.email}</p>
              </div>

              <div className={classes.blockItem}>
                <h4 className={classes.blockTitle}>Billing Address </h4>
                <p className={classes.blockContent}>
                  {selectedViewInvoice?.billing_information?.street_address
                    + ', ' + selectedViewInvoice?.billing_information?.city + ', ' +
                    selectedViewInvoice?.billing_information?.state + ', ' +
                    selectedViewInvoice?.billing_information?.country + ' - ' +
                    selectedViewInvoice?.billing_information?.zip_code}
                </p>
              </div>

              <div className={classes.blockItem}>
                <h4 className={classes.blockTitle}>Billed To</h4>
                <p className={classes.blockContent}>{selectedViewInvoice?.billed_to}</p>
              </div>
            </>
          }
          <div className={classes.blockItem}>
            <h4 className={classes.blockTitle}>Type </h4>
            <p className={classes.blockContent}>{selectedViewInvoice?.type}</p>
          </div>

          <div className={classes.blockItem}>
            <h4 className={classes.blockTitle}>Date of Issue</h4>
            <p className={classes.blockContent}>
              {selectedViewInvoice?.created_at ? moment.utc(selectedViewInvoice?.created_at)?.local().format('MM/DD/YYYY') + " at " + moment.utc(selectedViewInvoice?.created_at)?.local().format('hh:mm A') : ''}
            </p>
          </div>
          <div className={classes.blockItem}>
            <h4 className={classes.blockTitle}>Invoice Id</h4>
            <p className={classes.blockContent}>{selectedViewInvoice?.id}</p>
          </div>
          <div className={classes.blockItem}>
            <h4 className={classes.blockTitle}>Status</h4>
            <p className={classes.blockContent}>
              {
                selectedViewInvoice?.type === 'ADJUSTMENT' ? "-" :
                  selectedViewInvoice?.status
              }
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        open={!!selectedUpdateStatusInvoice}
        handleClose={() => setSelectedUpdateStatusInvoice(null)}
        dialogProps={{
          modalTitle: `Update invoice status to ${selectedUpdateStatusInvoice?.status === "PAID" ? "unpaid" : "paid"}`,
          positiveButtonText: 'Update Status',
          negativeButtonText: 'Close',
          positiveButtonAction: () => { updateStatus(selectedUpdateStatusInvoice); setSelectedUpdateStatusInvoice(null) },
          negativeButtonAction: () => setSelectedUpdateStatusInvoice(null),
        }}
        disableTitleDivider={true}
      >
        <div>
          <p>Are you sure you want to update invoice status to {selectedUpdateStatusInvoice?.status === "PAID" ? "unpaid" : "paid"}?</p>
        </div>
      </Modal>
      <div className={classes.innerContainer} id='cvb-invoice-mainContainer'>
        <Grid container className={classes.searchBackground}>
          <Grid item xs={12} lg={12} md={12}>
            <div className={classes.sectionHeaderContainer}>
              <div className={classes.gridTitle}>
                <h2 id='cvb-invoice-headingText'>Invoice</h2>
              </div>
              <div className={classes.generateInvoice}>
                <button
                  className={`medium-size-button solid-button ${classes.generateInvoiceButton}`}
                  onClick={() => navigate('/invoice/generate-invoice')}> Generate Invoice</button>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className={classes.filterContentContainer}>

          <Grid item lg={12} md={12} sm={12} className={classes.contentBlock}>

            <div className={classes.sectionLeftContainer}>
            </div>

            <div className={classes.contentMainContentArea}>
              <div className={classes.contentContainer}>
                <div className={classes.headerTitle}>
                  <h3 id='cvb-invoice-recentUploadHeading'>Invoices</h3>
                </div>
                <div>
                  <div className={classes.sectionTableHeader}>
                    <div className={classes.sectionLeftContainer}>
                      <ul>
                        <li className={classes.sortFilter} id='cvb-invoice-sortByDropdown'>
                          <label id='cvb-invoice-sortByLabel'>Status: </label>
                          <DropDown
                            selectedFilter={status}
                            values={["Show All", "PAID", "UNPAID"]}
                            handleFilterStateChange={(val) => { setStatus(val); setPageNumber(0) }}
                            placeholder={'Status'}
                          />
                        </li>
                        <li className={classes.sortFilter} id='cvb-invoice-sortByDropdown'>
                          <label id='cvb-invoice-sortByLabel'>Type: </label>
                          <DropDown
                            selectedFilter={type}
                            values={INVOICE_TRANSACTION_TYPE_STATUS}
                            handleFilterStateChange={(val) => { setType(val); setPageNumber(0) }}
                            placeholder={'Type'}
                          />
                        </li>
                        <li className={classes.sorTextSearchID}>
                          <OutlinedInput fullWidth type='text'
                            value={searchId}
                            autoComplete={"off"}
                            onChange={e => { setSearchId(e.target.value); setPageNumber(0) }}
                            placeholder='Invoice id, billed to or account name/workspace ID'
                            className='signup-inputs-large'
                            id='cvb-invoice-searchByProjectField'
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton aria-label="Search records" size="large">
                                  <img alt='visibility On' src={SearchIcon} className={classes.exploreSearchIcon} />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </li>
                      </ul>
                    </div>
                    <div className={classes.sectionRightContainer}>
                      <ul className={classes.filterSection}>
                        <li style={{ position: 'relative' }} className={`${classes.sortFilterDate} ${classes.toRight} `}>
                          <div onClick={() => { setOpenCalender(!openCalender) }} className={classes.calenderContainer}>
                            <span className={classes.text}>{selectedCalenderInput}</span>
                            <span className={classes.searchIcon}>
                              <img src={CalenderIcon} alt='search icon' />
                            </span>
                          </div>
                          {openCalender ?
                            <ClickAwayListener onClickAway={() => { setOpenCalender(false); }}>
                              <div className={classes.datePickerContainer}>
                                <DateRangePicker
                                  onChange={onCalenderSelect}
                                  staticRanges={staticRanges}
                                  inputRanges={[]}
                                  months={2}
                                  direction="horizontal"
                                  ranges={[selectionDateRage]}
                                />
                                <div className={classes.applyResetButtonContainer}>
                                  <button onClick={onResetDateRangeSelection} className={`medium-size-button no-outlined-button ${classes.reset}`}>Reset to default</button>
                                  <button onClick={onCalenderCancelButtonClick} className={`medium-size-button no-outlined-button ${classes.cancel}`}>Cancel</button>
                                  <button onClick={onApplyDateRangeButtonClick} className={`small-size-button solid-button ${classes.apply}`}>Apply</button>
                                </div>
                              </div>
                            </ClickAwayListener> : null
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={classes.selectProjectTableContainer} id='cvb-invoice-invoiceTableContainer'>
                    <TableContainer
                      component={Paper}
                      elevation={0}
                      className={classes.tableContainer}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell id='cvb-invoice-tableCellSubmitter' width="15%" align="left">Billed To</TableCell>
                            <TableCell id='cvb-invoice-tableCellProject' width="15%" align="left">Type</TableCell>
                            <TableCell id='cvb-invoice-tableCellUpdatedOn' width="5%" align="left">Amount</TableCell>
                            <TableCell id='cvb-invoice-tableCellUpdatedOn' width="15%" align="left">Account Name/ Workspace ID</TableCell>
                            <TableCell id='cvb-invoice-tableCellFileSize' width="10%" align="left">Date of issue</TableCell>
                            <TableCell id='cvb-invoice-tableCellStatus' width="5%" align="left">Invoice ID</TableCell>
                            <TableCell id='cvb-invoice-tableCellStatus' width="5%" align="left">Status</TableCell>
                            <TableCell id='cvb-invoice-tableCellStatus' width="10%" align="left"></TableCell>
                            <TableCell id='cvb-invoice-tableCellStatus' width="25%" align="left"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            displayedInvoices?.map((item, index) => {
                              return <TableRow key={v4()} >
                                <TableCell id={`cvb-invoice-tableCellSubmitterID-${index}`} className={classes.submitterId} align="left">{item?.billed_to || "-"}</TableCell>
                                <TableCell id={`cvb-invoice-tableCellProjectID-${index}`} align="left" className={`${classes.dateTimeField} ${classes.projectID}`}>{item?.type || ''}
                                  {item?.type === 'ADJUSTMENT' ? <LightTooltip placement="top" className={classes.userNameTitle} arrow title={item?.comments || ''}>
                                    <img className={classes.infoIcon} src={InfoGreyIcon} alt="info icon" />
                                  </LightTooltip> : null}
                                </TableCell>
                                <TableCell id={`cvb-invoice-tableCellFileSize-${index}`} align="left">${item?.amount || "0"} </TableCell>
                                <TableCell id={`cvb-invoice-tableCellFileSize-${index}`} align="left">
                                  <Tooltip id='cvb-invoice-tableRow-tooltip' title={(item?.entity_type || '') === 'USER_ACCOUNT' ? item?.email : item?.entity_id}>
                                    <span className={classes.accountTypeField}>{(item?.entity_type || '') === 'USER_ACCOUNT' ? item?.email : item?.entity_id}</span>
                                  </Tooltip>
                                </TableCell>
                                <TableCell id={`cvb-invoice-tableCellDateTime-${index}`} className={classes.dateTimeField} align="left">
                                  {item?.created_at ? moment.utc(item?.created_at)?.local().format('MM/DD/YYYY') + " at " + moment.utc(item?.created_at)?.local().format('hh:mm A') : ''}
                                </TableCell>
                                <TableCell id={`cvb-invoice-tableCellSubmitterID-${index}`} className={classes.submitterId} align="left">{item?.id || "-"}</TableCell>
                                <TableCell id={`cvb-invoice-tableCellProjectID-${index}`} align="left" className={`${classes.dateTimeField} ${classes.projectID}`}>
                                  {(item?.type === 'ADJUSTMENT') ? '-' :
                                    item?.status?.toLowerCase() === "paid" ?
                                      <span className={classes.paidText}>{item?.status || ''}</span> :
                                      <span className={classes.unPaidText}>{item?.status || ''}</span>
                                  }
                                </TableCell>
                                <TableCell id={`cvb-invoice-tableCellFileSize-${index}`} align="left">
                                  <button onClick={() => setSelectedViewInvoice(item)}
                                    className={`no-outlined-button solid-button ${classes.viewInvoice}`}>View Invoice
                                  </button>
                                </TableCell>
                                <TableCell id={`cvb-invoice-tableCellProjectID-${index}`} align="left" className={`${classes.dateTimeField} ${classes.projectID}`}>
                                  {
                                    (item?.type !== "ADJUSTMENT" && item?.show_mark_paid_unpaid) &&
                                    <button onClick={() => setSelectedUpdateStatusInvoice(item)}
                                      className={`no-outlined-button solid-button ${classes.statusUpdateButton}`}>
                                      {item?.status === "PAID" ? 'Update status to Unpaid' : 'Update status to Paid'}
                                    </button>
                                  }
                                </TableCell>
                              </TableRow>
                            })
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  {(finalInvoices?.length || 0) > 0 &&
                    <div className={classes.tablePagination} id='cvb-invoice-tablePagination'>
                      <TablePagination
                        maxCount={Math.ceil((finalInvoices.length || 0) / PAGE_LIMIT)}
                        page={pageNumber}
                        setPage={setPageNumber}
                      />
                    </div>
                  }
                </div>
                {/* } */}
              </div>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default InvoiceLanding

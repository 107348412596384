import axios from 'axios'
import {env} from '../env'
import { BASE_URL } from '../constants/google-login-urls'
import { Auth } from 'aws-amplify'

const FENCE_CLIENT_ID = env.REACT_APP_FENCE_CLIENT_ID
export const validateCookie = async () => {
    const request = {
        withCredentials: true,
    };
    const response = await axios.get(`${BASE_URL}/user/user`, request)
    return response?.data || {};
}

export const getCookieFromFence = async () => {
    const user = await Auth.currentSession();
    const accessToken = user?.accessToken?.jwtToken || ""

    const url = `${BASE_URL}/user/login/awscognito/login?code=${accessToken}&response_type=code&redirect_uri=${window.location.host}/user&idp=awscognito&scope=openid%20user&client_id=${FENCE_CLIENT_ID}`
    await axios.get(url)
}

import React, { useState } from 'react'
import Classes from './index.module.css';
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { navigate } from '@reach/router';
import { connect } from 'react-redux'
import {
    setEditContactNumber, setEditCity, setEditStreetAddress, setEditCountry,
    setEditState, setEditZipCode, setEditInstitutionName
} from '../../../redux/actions/editProfileActions';
import { COUNTRY_LIST } from '../../../constants/strings';
import map from 'lodash/map'
import v4 from 'uuid/v4'
import ErrorText from '../../controls/error-text'
import get from 'lodash/get'
import { validatePhoneNumber } from '../../../utils/input-validations';
import DropDown from '../../commons/dropdown';

const EditContactDetails = props => {
    const { contactEmail, contactNumber, streetAddress, city, state, zipCode, country, institutionName } = props;
    const [validateFields, setValidateFields] = useState({ 'contactNumber': false });
    const [isFormSubmit, setIsFormSubmit] = useState(false);
    const [isFormUpdating, setIsFormUpdating] = useState(false);
    const onContactNumberChange = (e) => {
        props.setEditContactNumber(e.target.value);
        setIsFormSubmit(false);
    }
    const onInstitutionNameChange = (e) => {
        props.setEditInstitutionName(e.target.value);
    }
    const onStreetAddressChange = (e) => {
        props.setEditStreetAddress(e.target.value);
    }
    const onCityChange = (e) => {
        props.setEditCity(e.target.value);
    }
    const onStateChange = (e) => {
        props.setEditState(e.target.value);
    }
    const onZipCodeChange = (e) => {
        props.setEditZipCode(e.target.value);
    }
    const onCountryChange = (value) => {
        props.setEditCountry(value);
    }
    const validateContactField = () => {
        let userContact = `${contactNumber}`.trim();
        const isValidPhoneNumber = validatePhoneNumber(userContact);
        if (isValidPhoneNumber) {
            setValidateFields({ ...validateFields, 'contactNumber': true })
        } else {
            setValidateFields({ ...validateFields, 'contactNumber': false })
        }
        return isValidPhoneNumber;
    }

    const onUpdateProfileClick = async () => {
        setIsFormSubmit(true);
        const isValid = validateContactField();
        if (isValid) {
            setIsFormUpdating(true);
            await props.onSubmitEdit();
            setIsFormUpdating(false);
        }   
    }

    return (
        <div className={Classes.editContactDetailsContainer}>
            <Grid container spacing={4} direction='column'>
                <Grid item xs={8}>
                    <p id='cvb-editContactDetails-contactHeading' className={Classes.contactFormTitles}>Contact</p>
                    <Grid container spacing={4} direction='row'>
                        <Grid item xs={6}>
                            <label id='cvb-editContactDetails-emailLabel' htmlFor='email'>Email</label>
                            <OutlinedInput
                                fullWidth
                                value={get(props.userDetails, 'email', '')}
                                placeholder='Enter email'
                                name='email'
                                disabled
                                style={{ backgroundColor: 'var(--SHADE_OF_PURPLE_3)' }}
                                id='cvb-editContactDetails-emailInput'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <label id='cvb-editContactDetails-contactNumberLabel' htmlFor='contactNumber'>Contact Number<sup> *</sup></label>
                            <OutlinedInput
                                fullWidth
                                value={contactNumber}
                                onChange={onContactNumberChange}
                                placeholder='Enter contact number'
                                name='contactNumber' 
                                error={(isFormSubmit && !validateFields['contactNumber'])}
                                id='cvb-editContactDetails-contactNumberInput'
                                />
                            <p className={Classes.hintText}>Country code is optional</p>
                            {(isFormSubmit && !validateFields['contactNumber']) &&
                              <div id='cvb-editContactDetails-invalidContactError' className={Classes.errorMessage}><ErrorText errorMessage='Please enter valid phone number.' /></div>  
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={Classes.rightFormContainer}>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <p id='cvb-editContactDetails-institutionHeading' className={Classes.contactFormTitles}>Institution</p>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <label id='cvb-editContactDetails-institutionNameLabel' htmlFor='streetAddress'>Institution Name</label>
                                    <OutlinedInput
                                        fullWidth
                                        value={institutionName}
                                        onChange={onInstitutionNameChange}
                                        placeholder='Enter institution name'
                                        name='institutionName' 
                                        id='cvb-editContactDetails-institutionNameInput'
                                        />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <p id='cvb-editContactDetails-institutionAddressHeading' className={Classes.contactFormTitles}>Institution Address</p>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <label id='cvb-editContactDetails-countryLabel' htmlFor='country'>Country</label>
                                    <DropDown
                                        id='cvb-editContactDetails-countryInput'
                                        values={COUNTRY_LIST}
                                        handleFilterStateChange={onCountryChange}
                                        selectedFilter={country}
                                        placeholder={'Select Country'}
                                        searchable
                                        isClearable
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <label id='cvb-editContactDetails-streetAddressLabel' htmlFor='streetAddress'>Street Address</label>
                                    <OutlinedInput
                                        fullWidth
                                        value={streetAddress}
                                        onChange={onStreetAddressChange}
                                        placeholder='Enter street address'
                                        name='streetAddress' 
                                        id='cvb-editContactDetails-streetAddressInput'
                                        />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={6}>
                                            <label id='cvb-editContactDetails-cityLabel' htmlFor='city'>City</label>
                                            <OutlinedInput
                                                fullWidth
                                                value={city}
                                                onChange={onCityChange}
                                                placeholder='Enter city'
                                                name='city' 
                                                id='cvb-editContactDetails-cityInput'
                                                />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label id='cvb-editContactDetails-stateLabel' htmlFor='state'>State</label>
                                            <OutlinedInput
                                                fullWidth
                                                value={state}
                                                onChange={onStateChange}
                                                placeholder='Enter state'
                                                name='state' 
                                                id='cvb-editContactDetails-stateInput'
                                                />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={6}>
                                            <label id='cvb-editContactDetails-zipCodeLabel' htmlFor='zipCode'>Zip Code</label>
                                            <OutlinedInput
                                                fullWidth
                                                value={zipCode}
                                                onChange={onZipCodeChange}
                                                placeholder='Enter zip code'
                                                name='zipCode' 
                                                id='cvb-editContactDetails-zipCodeInput'
                                                />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={Classes.buttonsDiv}>
                        <button id='cvb-editContactDetails-cancelButton' onClick={() => navigate('/my-profile')} className={`no-outlined-button medium-size-button ${Classes.cancelButton}`}>Cancel</button>
                        <button id='cvb-editContactDetails-updateButton' disabled={isFormUpdating} onClick={onUpdateProfileClick} className={`medium-size-button solid-button ${Classes.updateButton}`}>Update Profile</button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        contactEmail: state.editProfile.contactEmail,
        contactNumber: state.editProfile.contactNumber,
        city: state.editProfile.city,
        streetAddress: state.editProfile.streetAddress,
        state: state.editProfile.state,
        zipCode: state.editProfile.zipCode,
        country: state.editProfile.country,
        institutionName: state.editProfile.institutionName,
        userDetails: state.user.userDetails,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setEditContactNumber: (value) => dispatch(setEditContactNumber(value)),
        setEditStreetAddress: (value) => dispatch(setEditStreetAddress(value)),
        setEditCity: (value) => dispatch(setEditCity(value)),
        setEditState: (value) => dispatch(setEditState(value)),
        setEditZipCode: (value) => dispatch(setEditZipCode(value)),
        setEditCountry: (value) => dispatch(setEditCountry(value)),
        setEditInstitutionName: (value) => dispatch(setEditInstitutionName(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditContactDetails);

import WorkspaceFileDownloadRequestDetails  from './WorkspaceFileDownloadRequest'
import { connect } from 'react-redux'
import { updateSnackBar } from '../../../redux/actions/globalActions'

const mapStateToProps = state => ({
    userDetails: state.myStuff.selectedWorkspaceFileDownloadRequestData,
    allWorkspaces: state.workspace.allWorkspaces,
    allWorkspacesCarts: state.workspace.allWorkspacesCarts
})
const mapDispatchToProps = dispatch => ({
    updateSnackBar: async (message, type) => await dispatch(updateSnackBar(message, type))
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFileDownloadRequestDetails);

import {
    SET_PROJECT_DETAILS_PROJECT_NAME, SET_PROJECT_DETAILS_PROJECT_SYNOPSIS_TEXT, SET_PROJECT_DETAILS_IRB_APPROVAL_NUMBER ,
    SET_PROJECT_DETAILS_IRB_NUMBER_FILE, SET_PROJECT_DETAILS_DESIGNATED_ZONE, SET_PROJECT_DETAILS_RESTRICTION_OF_USE_TEXT,
    SET_PROJECT_DETAILS_IS_DATASET_DOWNLOADABLE, SET_PROJECT_DETAILS_ACK_STATEMENT, SET_PROJECT_DETAILS_TYPE_OF_DATA,
    SET_PROJECT_DETAILS_OTHER_TYPE_DATA_TEXT, SET_PROJECT_DETAILS_TOTAL_SIZE_OF_DATA, SET_PROJECT_DETAILS_LOGO_FILE,
    SET_PROJECT_DETAILS_OBJECTIVE_OF_STUDY, SET_PROJECT_DETAILS_DESCRIPTION_OF_STUDY, SET_PROJECT_DETAILS_DATA_DESCRIPTION,
    SET_PROJECT_DETAILS_STUDY_DESIGN, SET_PROJECT_DETAILS_NUMBER_OF_GRANTS, SET_PROJECT_DETAILS_DURATION_OF_MONTHS,
    SET_PROJECT_DETAILS_LOCATION_OF_STUDY, SET_PROJECT_DETAILS_PUBLICATIONS, REMOVE_PROJECT_DETAILS_PUBLICATIONS,
    SET_PROJECT_DETAILS_PUBLICATION_URL, SET_PROJECT_DETAILS_PUBLICATION_NAME, SET_IS_RESEARCHER_ALLOWED, SET_PROJECT_DETAILS_FORM_FIELDS
    , RESET_PROJECT_DETAILS_FORM_FIELDS, SET_PROJECT_DETAILS_IRB_APPROVAL_KEY
} from '../action-types';


export const setProjectDetailsProjectName = (value) => {
    return {
        type: SET_PROJECT_DETAILS_PROJECT_NAME,
        payload: value
    }
}
export const setProjectDetailsProjectSynopsisText = (value) => {
    return {
        type: SET_PROJECT_DETAILS_PROJECT_SYNOPSIS_TEXT,
        payload: value
    }
}
export const setProjectDetailsIRBApprovalNumber = (value) => {
    return {
        type: SET_PROJECT_DETAILS_IRB_APPROVAL_NUMBER,
        payload: value
    }
}
export const setIRBNumberFile = (value) => {
    return {
        type: SET_PROJECT_DETAILS_IRB_NUMBER_FILE,
        payload: value
    }
}
export const setDesignatedZone = (value) => {
    return {
        type: SET_PROJECT_DETAILS_DESIGNATED_ZONE,
        payload: value
    }
}
export const setRestrictionOfUseText = (value) => {
    return {
        type: SET_PROJECT_DETAILS_RESTRICTION_OF_USE_TEXT,
        payload: value
    }
}
export const setIsDatasetDownloadable = (value) => {
    return {
        type: SET_PROJECT_DETAILS_IS_DATASET_DOWNLOADABLE,
        payload: value
    }
}
export const setAckStatement = (value) => {
    return {
        type: SET_PROJECT_DETAILS_ACK_STATEMENT,
        payload: value
    }
}
export const setTypeOfData = (value) => {
    return {
        type: SET_PROJECT_DETAILS_TYPE_OF_DATA,
        payload: value
    }
}
export const setOtherTypeDataText = (value) => {
    return {
        type: SET_PROJECT_DETAILS_OTHER_TYPE_DATA_TEXT,
        payload: value
    }
}
export const setTotalSizeOfData = (value) => {
    return {
        type: SET_PROJECT_DETAILS_TOTAL_SIZE_OF_DATA,
        payload: value
    }
}
export const setLogoFile = (value) => {
    return {
        type: SET_PROJECT_DETAILS_LOGO_FILE,
        payload: value
    }
}
export const setObjectiveOfStudy = (value) => {
    return {
        type: SET_PROJECT_DETAILS_OBJECTIVE_OF_STUDY,
        payload: value
    }
}
export const setDescriptionOfStudy = (value) => {
    return {
        type: SET_PROJECT_DETAILS_DESCRIPTION_OF_STUDY,
        payload: value
    }
}
export const setDataDescription = (value) => {
    return {
        type: SET_PROJECT_DETAILS_DATA_DESCRIPTION,
        payload: value
    }
}
export const setStudyDesign = (value) => {
    return {
        type: SET_PROJECT_DETAILS_STUDY_DESIGN,
        payload: value
    }
}
export const setNumberOfGrants = (value) => {
    return {
        type: SET_PROJECT_DETAILS_NUMBER_OF_GRANTS,
        payload: value
    }
}
export const setDurationOfMonths = (value) => {
    return {
        type: SET_PROJECT_DETAILS_DURATION_OF_MONTHS,
        payload: value
    }
}
export const setLocationOfStudy = (value) => {
    return {
        type: SET_PROJECT_DETAILS_LOCATION_OF_STUDY,
        payload: value
    }
}
export const setProjectDetailsPublications = (value) => {
    return {
        type: SET_PROJECT_DETAILS_PUBLICATIONS,
        payload: value
    }
}
export const removeProjectDetailsPublications = (value) => {
    return {
        type: REMOVE_PROJECT_DETAILS_PUBLICATIONS,
        payload: value
    }
}
export const setPublicationURL = (value, id) => {
    return {
        type: SET_PROJECT_DETAILS_PUBLICATION_URL,
        payload: {
            value,
            id
        }
    }
}
export const setPublicationName = (value, id) => {
    return {
        type: SET_PROJECT_DETAILS_PUBLICATION_NAME,
        payload: {
            value,
            id
        }
    }
}
export const setIsResearcherAllowed = (value) => {
    return {
        type: SET_IS_RESEARCHER_ALLOWED,
        payload: value
    }
}
export const setProjectDetailsFields = (value) => {
    return {
        type: SET_PROJECT_DETAILS_FORM_FIELDS,
        payload: value
    }
}
export const resetProjectDetails = () => {
    return {
        type: RESET_PROJECT_DETAILS_FORM_FIELDS,
    }
}
export const setProjectDetailsIRBApprovalKey = (value) => {
    return {
        type: SET_PROJECT_DETAILS_IRB_APPROVAL_KEY,
        payload: value
    }
}

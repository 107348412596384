/**
 *  External Imports
 */

/**
 *  Internal Imports
 */
import {RES_COMMUNITY_COUNTER_KEYS} from "../../api/community-api";

import iconMessages from "../../assets/icons/icon-chat.svg";
import iconConnection from "../../assets/icons/icon-connection.svg";
import iconRecommended from "../../assets/icons/icon-favorite.svg";
// import iconRequest from "../../assets/icons/icon-request.svg";

import {COMMUNITY_URLS, COMMUNITY_CONNECTIONS_PARENT_ROUTE} from "../urls";

/**
 *  Constants
 */
const COMMUNITY_LEFT_MENU_KEYS = {
	RECOMMENDED: "Recommended",
	REQUESTS: "Requests",
	CONNECTIONS_GROUP: "Connections Group",
	INCOMING_REQUESTS: "Incoming Requests",
	PENDING_REQUESTS: "Pending Requests",
	ACCEPTED_REQUESTS: "Current Connections",
	MESSAGES: "Messages",
};

const COMMUNITY_LEFT_MENU_ITEMS = [
	{
		uniqKey: COMMUNITY_LEFT_MENU_KEYS.RECOMMENDED,
		icon: iconRecommended,
		iconAlt: "Icon Recommended",
		text: "Recommended Connections",
		navigateRoute: COMMUNITY_CONNECTIONS_PARENT_ROUTE + COMMUNITY_URLS.RECOMMENDED,
		tooltip: "These are connections recommended for you based on your background, goals, and interests.",
		counterKey: "",
		counter: 0,
		isGroup: false,
		isActive: false,
		isOpenSubMenu: false,
		children: [],
	},
	// {
	// 	uniqKey: COMMUNITY_LEFT_MENU_KEYS.REQUESTS,
	// 	icon: iconRequest,
	// 	iconAlt: "Icon Requests",
	// 	text: "Requests",
	// 	navigateRoute: COMMUNITY_URLS.REQUESTS,
	// 	tooltip: "",
	// 	counterKey: "",
	// 	counter: 0,
	// 	isGroup: false,
	// 	isActive: false,
	// 	isOpenSubMenu: false,
	// 	children: [],
	// },
	{
		uniqKey: COMMUNITY_LEFT_MENU_KEYS.CONNECTIONS_GROUP,
		icon: iconConnection,
		iconAlt: "Icon Connections",
		text: "Connections",
		navigateRoute: "",
		tooltip: "",
		counterKey: "",
		counter: 0,
		isGroup: true,
		isActive: false,
		isOpenSubMenu: false,
		children: [
			{
				uniqKey: COMMUNITY_LEFT_MENU_KEYS.ACCEPTED_REQUESTS,
				text: "Current Connections",
				navigateRoute: `${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.REQUESTS}${COMMUNITY_URLS.ACCEPTED_REQUESTS}`,
				tooltip: "",
				counterKey: RES_COMMUNITY_COUNTER_KEYS.NEW_CONNECTION,
				counter: 0,
				isActive: false,
			},
			{
				uniqKey: COMMUNITY_LEFT_MENU_KEYS.INCOMING_REQUESTS,
				text: "Incoming Requests",
				navigateRoute: `${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.REQUESTS}${COMMUNITY_URLS.INCOMING_REQUESTS}`,
				tooltip: "These are requests from people who would like to connect with you that you have not yet responded to.",
				counterKey: RES_COMMUNITY_COUNTER_KEYS.INCOMING,
				counter: 0,
				isActive: false,
			},
			{
				uniqKey: COMMUNITY_LEFT_MENU_KEYS.PENDING_REQUESTS,
				text: "Pending Requests",
				navigateRoute: `${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.REQUESTS}${COMMUNITY_URLS.PENDING_REQUESTS}`,
				tooltip: "These are connection requests you have sent that have not yet received a response.",
				counterKey: RES_COMMUNITY_COUNTER_KEYS.PENDING,
				counter: 0,
				isActive: false,
			},
		],
	},
	{
		uniqKey: COMMUNITY_LEFT_MENU_KEYS.MESSAGES,
		icon: iconMessages,
		iconAlt: "Icon Messages",
		text: "Messages",
		navigateRoute: COMMUNITY_CONNECTIONS_PARENT_ROUTE + COMMUNITY_URLS.MESSAGES,
		tooltip: "",
		counterKey: RES_COMMUNITY_COUNTER_KEYS.NEW_MESSAGE,
		counter: 0,
		isGroup: false,
		isActive: false,
		isOpenSubMenu: false,
		children: [],
	},
];

/**
 *  Exports
 */
export {
	COMMUNITY_LEFT_MENU_KEYS,
	COMMUNITY_LEFT_MENU_ITEMS,
};

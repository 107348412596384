import { navigate } from '@reach/router'
import React, { useState, useEffect } from 'react'
import classes from './CartSuccess.module.css'
import BCDataPortalLogo from '../../../assets/images/onboarding_logo.png'
import strokesBG from '../../../assets/images/strokes-bg.png'
import Grid from '@mui/material/Grid'
import cartSuccess from '../../../assets/images/cartSuccess.svg';
import SectionHeader from '../../commons/section-header'

const CartSuccess = props => {
    
    useEffect(() => {
    }, []);

    const sectionHeaderProps = {
      title: "My Cart"
    }

    const viewMyStuff = () => {
      navigate('/my-stuff');
    }

    
    
    
    return (
        <div className={classes.mainContainer}>
            <Grid container >
                <SectionHeader
                    open={props.open}
                    sectionHeaderProps={sectionHeaderProps}
                />

            </Grid>

            <Grid className={classes.backroundImageContainer}>
                <img className={classes.strokesImage} src={strokesBG} alt='strokes background' />
            </Grid>

            <div id="cvb-cartSuccess-box" className={classes.successBox}>
                        
                <Grid container>
                    <Grid lg={12}>
                        <img src={cartSuccess}  />
                    </Grid>
                
            
                    <Grid lg={12}>
                        <div id="cvb-cartSuccess-title" className={classes.successTitle}>
                        Your request has been submitted for approval. 
                            
                        </div>
                        <div id="cvb-cartSuccess-subtitle" className={classes.successSubTitle}>After WorkSpace files download request has been approved, you can download the files. You can track the approval status from ‘My Stuff’.</div>

                        <button onClick={viewMyStuff} className={`medium-size-button ${classes.viewMyStuffButton}`}>View ‘My Stuff’</button>
                    </Grid>
                </Grid>

            </div>
            
        </div>
    )
}

export default CartSuccess
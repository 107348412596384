import Users from './Users';
import {  getUserSearchData } from '../../../redux/actions/usersAdminActions'
import { connect } from 'react-redux'
import { updateSnackBar } from '../../../redux/actions/globalActions'

const mapStateToProps = state => {
    return {
        searchData: state.userAdminReducer.searchData,
        userDetails: state.user.userDetails
    }
}

const mapDispatchToProps = dispatch => ({
    getUserSearchData: async (searchString) => await dispatch(getUserSearchData(searchString)),
    updateSnackBar: async (message, type) => dispatch(updateSnackBar(message, type))
})


export default connect(mapStateToProps, mapDispatchToProps)(Users);
import ApolloClient from 'apollo-boost';
import {env} from '../../env'
import {
    LIST_OF_PROJECTS,
    PROJECT_DETAILS,
    CORE_METADATA,
    EXPLORE_PROJECTS_LIST
} from '../graphql-query-helpers/graphql'
import { getCognitoIdToken } from '../utilityMethods';
import { gql } from 'apollo-boost';

const BASE_URL = `${env.REACT_APP_BASE_INTERNAL_URL}`

export const getListOfProjects = async () => {
    const token = await getCognitoIdToken();
    const apolloClient = new ApolloClient({
        uri: `${BASE_URL}/api/v0/submission/graphql`,
        request: (operation) => {
            operation.setContext({
                fetchOptions: {
                    credentials: "omit"
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
        }
    })

    const response = await apolloClient.query({ query: LIST_OF_PROJECTS });
    return response?.data?.project || []
}

export const getListOfProjectDetails = async (name) => {
    const token = await getCognitoIdToken();
    const apolloClient = new ApolloClient({
        uri: `${BASE_URL}/api/v0/submission/graphql`,
        request: (operation) => {
            operation.setContext({
                fetchOptions: {
                    credentials: "omit"
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
        }
    })

    const response = await apolloClient.query({ query: PROJECT_DETAILS, variables: { name: name, projectId: name } });
    return response?.data || null
}

export const getUploadDataModelData = async (queryString) => {
    const token = await getCognitoIdToken();
    const apolloClient = new ApolloClient({
        uri: `${BASE_URL}/api/v0/submission/graphql`,
        request: (operation) => {
            operation.setContext({
                fetchOptions: {
                    credentials: "omit"
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
        }
    })

    const response = await apolloClient.query({ query: gql`query ${queryString}` });
}


export const getCoreMetadataCollection = async (projectId) => {
    const token = await getCognitoIdToken();
    const apolloClient = new ApolloClient({
        uri: `${BASE_URL}/api/v0/submission/graphql`,
        request: (operation) => {
            operation.setContext({
                fetchOptions: {
                    credentials: "omit"
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
        }
    })

    const response = await apolloClient.query({ query: CORE_METADATA, variables: { project_id: projectId } });
    return response?.data?.core_metadata_collection || []
}

export const getAccessibleProjectList = async()  => {
    const token = await getCognitoIdToken();
    const apolloClient = new ApolloClient({
        uri: `${BASE_URL}/api/v0/submission/graphql`,
        request: (operation) => {
            operation.setContext({
                fetchOptions: {
                    credentials: "omit"
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
        }
    })

    const response = await apolloClient.query({ query: EXPLORE_PROJECTS_LIST});
    return response?.data?.project || []
}

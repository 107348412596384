import React from 'react'
import Classes from './index.module.css'
import failIcon from '../../../assets/icons/fail.svg'
import Grid from '@mui/material/Grid'
import get from 'lodash/get'

const InfoText = (props) => {
    const containerClassName = props.type === "Error" ? Classes.FailedStrengthCheckSuccessCont :
        Classes.passStrengthCheckSuccessCont;

    return <Grid item xs={12} className={Classes.passSuccessContainer}>
        <Grid direction='row' alignItems='center'
            className={containerClassName}>
            <img src={failIcon} alt='success mark' />
            <p className={Classes.passStrengthCheckSuccess}>
                {get(props, 'errorMessage', '')}</p>
        </Grid>
    </Grid>
}

export default InfoText;

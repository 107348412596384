import React from 'react';
import Classes from './index.module.css';

export default function DefinitionAndContributedDataStage() {
    return (
        <div className={Classes.agreementContainer} id='cvb-dataContributorAgreement-stage2FormContainer'>
            <h3 className={Classes.formTitle}>Exhibit A: Definitions</h3>
            <p>
            <b>"Affiliate"</b> of an entity means any entity which, directly or indirectly, controls, is controlled by or is under common control with such entity, where control means the ability to direct the affairs of an entity through ownership of voting interest, contract rights or otherwise.
            </p>
            <p><b>"Agreement"</b> has the meaning set forth in the preamble.</p>
            <p><b>"Applicable Law(s)"" </b> means all applicable laws, regulations, court orders, and other rules of any Governmental Authority, including, but not limited to, HIPAA, GDPR and other national, federal and state laws relating to the privacy and security of personal information.</p>
            <p><b>"Authorized Data Submitter"</b> means one or more individuals whom Contributor has specifically authorized hereunder to, as applicable, provide, modify and/or remove Contributed Data on behalf of Contributor.</p>
            <p><b>"Authorized Users"</b> means controlled access and restricted access users granted enhanced access and functionality to BC Data after submission of required Zone access request information.</p>
            <p><b>"BC Data"</b> means the BRAINCommons data provided by various data contributors. </p>
            <p><b>"BC Manager</b> means the CVB designee that grants Users certain rights based on the applicable Contributor-designated Zone Rights. </p>
            <p><b>"BRAINCommons"</b> means a knowledge commons that collocates or integrates data, storage and computing infrastructure with services, tools, interfaces and applications for managing, analyzing, and sharing data to create an interoperable resource for the brain and other research communities that enables and encourages data sharing, collaboration and data-driven discovery. </p>
            <p> <b>"Claims"</b> means claims, liabilities, losses, causes of action, damages, costs and expenses. </p>
            <p><b>"Contributed Data"</b> means the dataset which consists of de-identified preclinical and/or biomedical data, including, without limitation, clinical, genomic, imaging, or biosensor data that Contributor desires to contribute to the BRAINCommons.</p>
            <p><b>"Contributor"</b> has the meaning set forth in the preamble. </p>
            <p><b>"Contributor-designated Zone Rights"</b> or <b>"Designated Zone"</b>means rights of Users that are designated by Contributor and approved by the Data Access Committee (or, as applicable, via another approval process implemented by the BC Manager) to access and use the Contributed Data via the Platform. </p>
            <p><b>CVB</b> means Cohen Veterans Bioscience, Inc.</p>
            <p><b>"Data Use Agreement"</b> means the BRAINCommons Data Use Agreement, substantially in the form attached hereto, referenced herein or otherwise provided or made available to Contributor.</p>
            <p><b>"Effective Date"</b> has the meaning set forth in the preamble.</p>
            <p><b>"GDPR"</b> means the European Union General Data Protection Regulation (EU) 2016/679.</p>
            <p><b>"Governmental Authority"</b> means any transnational, domestic or foreign federal, state or local, governmental authority or any court, administrative agency or regulatory authority constituted or administered thereby, including any political subdivision thereof.</p>
            <p><b>"HIPAA"</b> means the Health Insurance Portability and Accountability Act of 1996, as revised by the Health Information Technology for Economic and Clinical Health (“HITECH”) Act of 2009, and all associated rules and regulations.</p>
            <p><b>"IRB"</b> means Institutional Review Board.</p>
            <p><b>"Parties"</b>  has the meaning set forth in the preamble.</p>
            <p><b>"Platform"</b> means the software (and other technology)-enabled platform for managing, analyzing and sharing biomedical data, which is provided or made available in connection with the BRAINCommons.</p>
            <p><b>"Prohibited Data"</b> means: </p>
            <ol type='i' className={Classes.olList}>
                <li>
                 any information that is regulated by: (A) any applicable national, federal, state or local law, rule, directive or regulation relating to the privacy of personal information, including, without limitation, HIPAA, the UK Data Protection Act of 2018, the GDPR, and other Applicable Laws implementing and/or supplementing the GDPR in applicable jurisdictions, and the Privacy and Electronic Communications Directive 2002/58/EC (as may be superseded by the Privacy and Electronic Communications Regulation); (B) any privacy notice or practice applicable to any personal information that Contributor contributes hereunder; or (C) any law or regulation concerning the notification to data subjects, law enforcement or other third parties of the occurrence of any actual or suspected disclosure of restricted data or personally identifiable information;
                </li>
                <li>
                any personally identifiable information; or
                </li>
                <li>
                any information or data that is owned by or exclusively licensed to any person or entity, or in respect of which the use, retention, duplication, or disclosure thereof, is restricted by any person or entity (including any Governmental Authority).
                </li>
            </ol>
            <p>For clarity, genetic data without identifiers shall not automatically be considered Prohibited Data that is subject to the limitations and requirements of Section 2.3.1.</p>
            <p><b>"Representatives"</b>means a Party’s or the Party’s Affiliates’ respective directors, officers, partners, contractors, members, employees, experts, accountants, counsel, financial advisors, agents and consultants.</p>
            <p><b>"Users"</b>  means all users of BC Data on the Platform. </p>
            <p><b>"Zone"</b> means the applicable rules regarding access to and use of certain BC Data.</p>
            <p></p>
            <h3 className={Classes.formTitle}>Exhibit B: Contributed Data Specific Terms</h3>
            <p>Contributor agrees to submit the following Contributed Data to the Platform.  Contributor agrees to de-identify the Contributed Data in conformance with 45 C.F.R. § 164.514(a)-(b), before submission to the Platform. </p>
            <p><b>Instructions:</b> <br/><br/>
            Contributor must designate the appropriate Zone for each set of Contributed Data below, as follows:
            <ul className={Classes.zoneULList}>
                <li><b>Zone 1</b>: Public access:  Use by any User. </li>
                <li><b>Zone 2</b>: Controlled access:  Use by Authorized Users after submission of a Zone access request, determination by the Data Access Committee as a qualified researcher, and approval by such committee (or, as applicable, via another approval process implemented by the BC Manager</li>
                <li><b>Zone 3</b>: Restricted access:  Use only by Contributor and by Authorized Users who are expressly designated by Contributor (after (i) submission of a Zone access request and approval by or through the Data Access Committee (or, as applicable, via another approval process implemented by the BC Manager), subject to Contributor’s Authorized User designations, and (ii) as applicable, entering into an organizational or dataset specific data use agreement).  Contributor may change the Authorized User designations associated with particular Zone 3 Contributed Data by notice to the BC Manager, which shall have a reasonable amount of time to change Authorized User access associated with that Zone 3 Contributed Data</li>
            </ul>
            </p>
            <p>Note: Contributor may change the Zone of particular Contributed Data by notice to the BC Manager, which shall have a reasonable amount of time to change the Contributor-designated Zone Rights associated with that Contributed Data.  However, Contributor acknowledges and agrees that changing the Zone of Contributed Data will in no way affect the rights of Users in Results generated using Contributed Data prior to such change.
                Contributor acknowledges and agrees that access to Contributed Data through the Platform will be governed by, and subject to, the terms of the Data Use Agreement.
                </p>
        </div>
    )
}

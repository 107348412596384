import React, { useEffect, useState, useMemo } from 'react'
import Classes from './index.module.css';
import get from 'lodash/get'
import Grid from '@mui/material/Grid';
import { navigate } from '@reach/router'
import CompletedStepPNG from '../../../assets/images/completedStep.png'
import ActiveStepPNG from '../../../assets/images/activeStep.png'
import IncompleteStepPNG from '../../../assets/images/incompleteStep.png'
import { getStageNumberFromRes } from '../../../utils/stringFormatter'
import DownloadBlueIcon from '../../../assets/icons/download.svg'
import ViewBlueIcon from '../../../assets/icons/view.svg'
import { downloadDCAForm } from '../../../api/dca-form-api';
import isEmpty from 'lodash/isEmpty'

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const DCAFormStepper = props => {
    const {
        requestData = {},
        setDCAStage = () => { },
        dashboard = false,
        hideDownload = false,
        resetDCAForm = () => {},
        resetProjectDetails = () => {},
        resetProjectInstitutionForm = () => {},
        resetPIForm = () => {},
        resetProjectAdminForm = () => {},
        resetZone3UserForm = () => {},
        resetAuthSubmitterForm = () => {},
    } = props;

    const onFormStageClick = (stage) => {

        const uuid = get(requestData, 'uuid', '');
        const formStage = get(requestData, 'stage', '');
        const stageNumber = getStageNumberFromRes(formStage);
        const requesterEmail = get(requestData, 'requesterEmail', '');
        const requesterSub = get(requestData, 'requesterSub', '');
        let setFormStage = stage;
        const route = dashboard ? 'dashboard' : 'my-stuff';
        navigate(`/${route}/data-contribute-agreement/${uuid}/${requesterSub}`, { state: { formStage: stageNumber, dashboard: dashboard, requesterSub: requesterSub, requesterEmail: requesterEmail } });

        if (stage === 3) {
            switch (formStage) {
                case '3A': setFormStage = 3; break;
                case '3B': setFormStage = 4; break;
                case '3C': setFormStage = 5; break;
                case '3D': setFormStage = 6; break;
                case '3E': setFormStage = 7; break;
                case '3F': setFormStage = 8; break;
                case '3G': setFormStage = 9; break;
                default: setFormStage = 3; break;
            }
        }
        if(stage === 4){
            setFormStage = 10;
        }
        resetDCAForm();
        resetProjectDetails();
        resetProjectInstitutionForm();
        resetPIForm();
        resetProjectAdminForm();
        resetZone3UserForm();
        resetAuthSubmitterForm();

        setDCAStage(setFormStage);
    }

    const handleDownloadFile = async() => {
        let requests = await downloadDCAForm(props.requestData?.requesterSub, props.requestData?.uuid);
        if (!isEmpty(requests)) {
            window.location.href = requests;
        }
    }

    const formCurrentStage = get(requestData, 'stage', '');

    return (
        <>
            <TableRow style={{padding: 0}} className={Classes.innerRowNotLast}>
                <TableCell width="5%"/>
                <TableCell  width="20%"/>
                <TableCell width="25%">Data Contribution Agreement</TableCell>
                <TableCell width="15%">
                </TableCell>
                <TableCell width="10%" align="center">{Number(formCurrentStage[0]) === 1
                        ? <span className={Classes.draftText}>Draft</span>
                        : <span className={Classes.submitText}>
                            {formCurrentStage === 'Completed' ? 'Submitted' : (Number(formCurrentStage[0]) === 1 ? 'Draft' : 'Completed')}
                        </span>}</TableCell>
                <TableCell width="25%" align="center">
                    <button style={{marginRight: '10px'}} onClick={() => onFormStageClick(1)} className={Classes.approveRequestButton}>
                        View
                    </button>
                    <button onClick={handleDownloadFile} className={Classes.approveRequestButton}>
                        Download
                    </button>
                </TableCell>
            </TableRow>
            <TableRow style={{padding: 0}} className={Classes.innerRowNotLast}>
                <TableCell width="5%"/>
                <TableCell width="20%"/>
                <TableCell width="25%">Definitions</TableCell>
                <TableCell width="15%"></TableCell>
                <TableCell width="10%" align="center">
                    {Number(formCurrentStage[0]) === 2
                    ? <span className={Classes.draftText}>Draft</span>
                    : <span className={Classes.submitText}>
                        {formCurrentStage === 'Completed' ? 'Submitted' :
                            (Number(formCurrentStage[0]) > 2 ? 'Completed' : '')}
                    </span>}    
                </TableCell>
                <TableCell width="25%" align="center">
                    <button style={{marginRight: '10px'}} onClick={() => onFormStageClick(2)} className={Classes.approveRequestButton}>
                        View
                    </button>
                    <button onClick={handleDownloadFile} className={Classes.approveRequestButton}>
                        Download
                    </button>
                </TableCell>
            </TableRow>
            <TableRow style={{padding: 0}} className={Classes.innerRowNotLast}>
                <TableCell width="5%"/>
                <TableCell  width="20%"/>
                <TableCell width="25%">Contributed Dataset Governance Form</TableCell>
                <TableCell width="15%"></TableCell>
                <TableCell width="10%" align="center">
                    {Number(formCurrentStage[0]) === 3 ?
                    <span className={Classes.draftText}>Draft</span> :
                    <span className={Classes.submitText}>
                        {formCurrentStage === 'Completed' ? 'Submitted' :
                            (Number(formCurrentStage[0]) > 3 ? 'Completed' : '')
                        }
                    </span>}
                </TableCell>
                <TableCell width="25%" align="center">
                    <button style={{marginRight: '10px'}} onClick={() => onFormStageClick(3)} className={Classes.approveRequestButton}>
                        View
                    </button>
                    <button onClick={handleDownloadFile} className={Classes.approveRequestButton}>
                        Download
                    </button>
                </TableCell>
            </TableRow>
            <TableRow style={{padding: 0}} className={Classes.innerRow}>
                <TableCell width="5%"/>
                <TableCell width="20%"/>
                <TableCell width="25%">Institutional Certification</TableCell>
                <TableCell width="15%"></TableCell>
                <TableCell width="10%" align="center">
                    {(Number(formCurrentStage[0]) === 4
                    ? <span className={Classes.draftText}>Draft</span> :
                    <span className={Classes.submitText}>
                        {formCurrentStage === 'Completed' ? 'Submitted' : ''}
                    </span>
                    )}
                </TableCell>
                <TableCell width="25%" align="center">
                    <button style={{marginRight: '10px'}} onClick={() => onFormStageClick(4)} className={Classes.approveRequestButton}>
                        View
                    </button>
                    <button onClick={handleDownloadFile} className={Classes.approveRequestButton}>
                        Download
                    </button>
                </TableCell>
            </TableRow>
        </>
    )

    // return (
    //     <div className={Classes.formStepperHorizontal}>
    //         <div className={Classes.formStep}>
    //             {/* <div className={Classes.formStepCircle}><span></span></div> */}
    //             <div className={Classes.formStepCircle}>
    //                 {
    //                     Number(formCurrentStage[0]) === 1 ?
    //                         <img className={Classes.stepperImages} src={ActiveStepPNG} alt='active' />
    //                         :
    //                         <img className={Classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
    //                 }
    //             </div>
    //             <div className={Classes.formStepTitle}>Data Contribution Agreement</div>
    //             <div className={Classes.formStepOptional}>
    //                 {Number(formCurrentStage[0]) === 1
    //                     ? <p className={Classes.draftText}>Draft</p>
    //                     : <p className={Classes.submitText}>
    //                         {formCurrentStage === 'Completed' ? 'Submitted' : (Number(formCurrentStage[0]) === 1 ? 'Draft' : 'Completed')}
    //                     </p>}
    //             </div>
    //             {
    //                 formCurrentStage === 'Completed' ?
    //                     <div className={Classes.viewButtonWrapper}>
    //                         <div className={Classes.viewDownloadIcon}>
    //                             <img alt='view' onClick={() => onFormStageClick(1)} src={ViewBlueIcon} />
    //                             {
    //                                 !dashboard ? 
    //                                 <img alt='download' onClick={handleDownloadFile} src={DownloadBlueIcon} />  
    //                                 : dashboard && !hideDownload ? 
    //                                 <img alt='download' onClick={handleDownloadFile} src={DownloadBlueIcon} /> : null
    //                             }
    //                         </div>
    //                     </div> :
    //                     <div className={Classes.viewButtonWrapper}>
    //                         <button onClick={() => onFormStageClick(1)} className={Classes.viewButton}>
    //                             Edit
    //                         </button>
    //                     </div>
    //             }
    //             <div className={Classes.formStepBarLeft}></div>
    //             <div className={Classes.formStepBarRight}></div>
    //         </div>
    //         <div className={Classes.formStep}>
    //             <div className={Classes.formStepCircle}>
    //                 {Number(formCurrentStage[0]) === 2 ?
    //                     <img className={Classes.stepperImages} src={ActiveStepPNG} alt='active' />
    //                     : ((Number(formCurrentStage[0]) > 2 || formCurrentStage === 'Completed')
    //                         ? <img className={Classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
    //                         : <img className={Classes.stepperImages} src={IncompleteStepPNG} alt='incomplete' />)
    //                 }
    //             </div>
    //             <div className={Classes.formStepTitle}>Definitions</div>
    //             <div className={Classes.formStepOptional}>
    //                 {Number(formCurrentStage[0]) === 2
    //                     ? <p className={Classes.draftText}>Draft</p>
    //                     : <p className={Classes.submitText}>
    //                         {formCurrentStage === 'Completed' ? 'Submitted' :
    //                             (Number(formCurrentStage[0]) > 2 ? 'Completed' : '')}
    //                     </p>}
    //             </div>
    //             {
    //                 (formCurrentStage === 'Completed' || Number(formCurrentStage[0]) >= 2) &&
    //                 <>
    //                     {
    //                         formCurrentStage === 'Completed' ?
    //                             <div className={Classes.viewButtonWrapper}>
    //                                 <div className={Classes.viewDownloadIcon}>
    //                                     <img alt='view' onClick={() => onFormStageClick(2)} src={ViewBlueIcon} />
    //                                     {
    //                                         !dashboard ? 
    //                                         <img alt='download' onClick={handleDownloadFile} src={DownloadBlueIcon} />  
    //                                         : dashboard && !hideDownload ? 
    //                                         <img alt='download' onClick={handleDownloadFile} src={DownloadBlueIcon} /> : null
    //                                     }
    //                                 </div>
    //                             </div> :
    //                             <div className={Classes.viewButtonWrapper}>
    //                                 <button onClick={() => onFormStageClick(2)} className={Classes.viewButton}>
    //                                     Edit
    //                                     </button>
    //                             </div>
    //                     }
    //                 </>
    //             }
    //             <div className={Classes.formStepBarLeft}></div>
    //             <div className={Classes.formStepBarRight}></div>
    //         </div>
    //         <div className={Classes.formStep}>
    //             <div className={Classes.formStepCircle}>
    //                 {Number(formCurrentStage[0]) === 3
    //                     ? <img className={Classes.stepperImages} src={ActiveStepPNG} alt='active' />
    //                     : ((Number(formCurrentStage[0]) > 3 || formCurrentStage === 'Completed')
    //                         ? <img className={Classes.stepperImages} src={CompletedStepPNG} alt='Completed' />

    //                         : <img className={Classes.stepperImages} src={IncompleteStepPNG} alt='incomplete' />)
    //                 }
    //             </div>
    //             <div className={Classes.formStepTitle}>Contributed Dataset Governance Form</div>
    //             <div className={Classes.formStepOptional}>
    //                 {Number(formCurrentStage[0]) === 3 ?
    //                     <p className={Classes.draftText}>Draft</p> :
    //                     <p className={Classes.submitText}>
    //                         {formCurrentStage === 'Completed' ? 'Submitted' :
    //                             (Number(formCurrentStage[0]) > 3 ? 'Completed' : '')
    //                         }
    //                     </p>}
    //             </div>
    //             {
    //                 (formCurrentStage === 'Completed' || Number(formCurrentStage[0]) >= 3) &&
    //                 <>
    //                     {
    //                         formCurrentStage === 'Completed' ?
    //                             <div className={Classes.viewButtonWrapper}>
    //                                 <div className={Classes.viewDownloadIcon}>
    //                                     <img alt='view' onClick={() => onFormStageClick(3)} src={ViewBlueIcon} />
    //                                     {
    //                                         !dashboard ? 
    //                                         <img alt='download' onClick={handleDownloadFile} src={DownloadBlueIcon} />  
    //                                         : dashboard && !hideDownload ? 
    //                                         <img alt='download' onClick={handleDownloadFile} src={DownloadBlueIcon} /> : null
    //                                     }
    //                                 </div>
    //                             </div> :
    //                             <div className={Classes.viewButtonWrapper}>
    //                                 <button onClick={() => onFormStageClick(3)} className={Classes.viewButton}>
    //                                     Edit
    //                                     </button>
    //                             </div>
    //                     }
    //                 </>
    //             }
    //             <div className={Classes.formStepBarLeft}></div>
    //             <div className={Classes.formStepBarRight}></div>
    //         </div>
    //         <div className={Classes.formStep}>
    //             <div className={Classes.formStepCircle}>
    //                 {formCurrentStage === 'Completed' ? <img className={Classes.stepperImages} src={CompletedStepPNG} alt='Completed' />
    //                     : (Number(formCurrentStage[0]) === 4
    //                         ? <img className={Classes.stepperImages} src={ActiveStepPNG} alt='active' />
    //                         : <img className={Classes.stepperImages} src={IncompleteStepPNG} alt='incomplete' />)
    //                 }
    //             </div>
    //             <div className={Classes.formStepTitle}>Institutional Certification</div>
    //             <div className={Classes.formStepOptional}>
    //                 {(Number(formCurrentStage[0]) === 4
    //                     ? <p className={Classes.draftText}>Draft</p> :
    //                     <p className={Classes.submitText}>
    //                         {formCurrentStage === 'Completed' ? 'Submitted' : ''}
    //                     </p>
    //                 )}
    //             </div>
    //             {
    //                 (formCurrentStage === 'Completed' || Number(formCurrentStage[0]) >= 4) &&
    //                 <>
    //                     {
    //                         formCurrentStage === 'Completed' ?
    //                             <div className={Classes.viewButtonWrapper}>
    //                                 <div className={Classes.viewDownloadIcon}>
    //                                     <img alt='view' onClick={() => onFormStageClick(4)} src={ViewBlueIcon} />
    //                                     {
    //                                         !dashboard ? 
    //                                         <img alt='download' onClick={handleDownloadFile} src={DownloadBlueIcon} />  
    //                                         : dashboard && !hideDownload ? 
    //                                         <img alt='download' onClick={handleDownloadFile} src={DownloadBlueIcon} /> : null
    //                                     }
    //                                 </div>
    //                             </div> :
    //                             <div className={Classes.viewButtonWrapper}>
    //                                 <button onClick={() => onFormStageClick(4)} className={Classes.viewButton}>
    //                                     Edit
    //                                     </button>
    //                             </div>
    //                     }
    //                 </>
    //             }
    //             <div className={Classes.formStepBarLeft}></div>
    //             <div className={Classes.formStepBarRight}></div>
    //         </div>
    //     </div>
    // )
}

export default DCAFormStepper;
/**
 *  External Imports
 */
import { func, number, string, bool, oneOfType } from "prop-types";

import React, { useState, useEffect } from "react";

/**
 *  Internal Imports
 */
import CustomModal from "../../commons/modal";
import styles from "./index.module.css";
import UserAvatar from "../../commons/user-avatar";

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 */
const CommunityConnectModal = (props) => {
    const [userMessage, setUserMessage] = useState("");
    const { showCommunityConnectModal, userIcon, organization, 
        // reason,
         name, id } = props;
    const { onConnectUser, connectMethod, onDialogClose, onDialogReset } = props;

    const modalBtnStyleProps = {
        minWidth: "auto",
        padding: "8px 24px 8px 24px",
        fontSize: ".875rem",
    };

    const modalProps = {
        loadStatus: false,

        modalTitle: "New Connection Request",
        modalTitleClass: styles.modalTitle,

        negativeButtonText: "Cancel",
        negativeButtonAction: () => onModalClose(),

        positiveButtonText: "Send",
        async positiveButtonAction() {
            // Emit a request
            const { status } = await connectMethod({ id, userMessage });

            // Close the modal window
            onModalClose();

            // Checking a response status
            if (status === 200) {
                onConnectUser(id);
            }
        },
    };

    const onModalClose = () => {
        onDialogClose();
        setUserMessage("");
    };

    const onMessageChange = (event) => setUserMessage(event.target.value);

    useEffect(() => {
        return () => onDialogReset();
    }, []);

    let reason = `You are sending a connection request to ${name}, another member of the community. If you wish, you can add a personal message below to let them know why you would like to connect.`

    return (
        <CustomModal data-testid="modal"
            open={showCommunityConnectModal}
            handleClose={onModalClose}
            dialogProps={modalProps}
            buttonStyleProps={modalBtnStyleProps}
            disableTitleDivider={true}
            disableBottomHorizontalDivider={true}>
            <div className={styles.modalBody}>
                <div className={styles.modalUserInfoArea}>
                    <UserAvatar data-testid="userIcon" userIcon={userIcon}></UserAvatar>

                    <div className={styles.modalUserNameArea}>
                        <div className={styles.modalUserName} data-testid="userName">{name}</div>

                        <div className={styles.modalUserOrganization} data-testid="userOrganization">
                            {organization}
                        </div>
                    </div>
                </div>
                {reason && (
                    <div data-testid="userReason"
                        className={styles.modalUserDescriptionArea}
                        dangerouslySetInnerHTML={{ __html: reason }}
                    />
                )}

                <textarea
                    data-testid="userMessage"
                    className={styles.modalTextArea}
                    placeholder="Add a message"
                    maxLength={300}
                    value={userMessage}
                    onChange={onMessageChange}
                />
            </div>
        </CustomModal>
    );
};

/**
 *  Properties
 */
CommunityConnectModal.propTypes = {
    id: oneOfType([string, number]).isRequired,
    showCommunityConnectModal: bool.isRequired,
    userIcon: string.isRequired,
    name: string.isRequired,
    organization: string.isRequired,
    reason: string.isRequired,

    onConnectUser: func.isRequired,
    connectMethod: func.isRequired,
    onDialogClose: func.isRequired,
    onDialogReset: func.isRequired,
};

/**
 *  Exports
 */
export default CommunityConnectModal;

/**
 *  External Imports
 */
import { connect } from "react-redux";

/**
 *  Internal Imports
 */
import {
    resetCommunityDeleteModalAction,
    closeCommunityDeleteModalAction,
} from "../../../../redux/actions/communityActions";

import CommunityDeleteModal from "./CommunityDeleteModal";

/**
 *  Functions
 */
const mapStateToProps = ({
    community: { showCommunityDeleteModal, dialogDeleteData },
}) => ({
    showCommunityDeleteModal,
    ...dialogDeleteData,
});

const mapDispatchToProps = (dispatch) => ({
    onDialogReset: () => dispatch(resetCommunityDeleteModalAction()),
    onDialogClose: () => dispatch(closeCommunityDeleteModalAction()),
});

/**
 *  Exports
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityDeleteModal);

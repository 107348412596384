/**
 *  External Imports
 */

/**
 *  Internal Imports
 */

/**
 *  Constants
 */
const COMMUNITY_LEFT_MENU_TIMER_MS = 5_000;

const COMMUNITY_MESSAGES_CHAT_LIST_TIMER_MS = 5_000;

/**
 *  Exports
 */
export {
	COMMUNITY_LEFT_MENU_TIMER_MS,
	
	COMMUNITY_MESSAGES_CHAT_LIST_TIMER_MS,
};

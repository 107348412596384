import NotebookInfoCards from './NotebookInfoCard'
import { connect } from 'react-redux'
import { setAllWorkspaces } from '../../../redux/actions/workspaceActions'
import { updateSnackBar } from '../../../redux/actions/globalActions'
const mapStateToProps = state => {
    return {
        allWorkspaces: state.workspace.allWorkspaces
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAllWorkspaces: (message, type) => dispatch(setAllWorkspaces(message, type)),
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NotebookInfoCards);
import React, { useRef, useEffect } from 'react'
import {env} from '../../../env'
import Classes from './index.module.css'
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid'
//import classes from './index.module.css';
import Select from '@mui/material/Select';
import SignatureCanvas from 'react-signature-canvas'
import DropDown from '../../commons/dropdown'
import { COUNTRY_LIST } from '../../../constants/strings'
import map from 'lodash/map'
import v4 from 'uuid/v4'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ErrorText from '../error-text'
import moment from 'moment'
import { isDate } from 'lodash';
import isEmpty from 'lodash/isEmpty'
import CommonDatePicker from '../../commons/common-date-picker';
import { addDays } from '../../../utils/date-formatter';

export default function DataUsePolicy(props) {

    const {
        userDetails,
        agreedDataUsePolicy,
        setAgreedDataUsePolicy,
        setStreetAddress,
        streetAddress,
        state,
        setState,
        city,
        zipCode,
        setCity,
        setZipCode,
        country,
        setCountry,
        getCurrentDate,
        sigRef,
        isFormSubmitted,
        setIsFormSubmitted,
        setResearcherSign,
        researcherSign,
        currentZone,
        isAgreeSelected,
        setIsAgreeSelected
    } = props

    const createDUPSign = async () => (!isEmpty(researcherSign) && !isEmpty(sigRef.current)) && (await sigRef?.current?.fromDataURL(researcherSign));
    const agreementText = env.REACT_APP_AGREEMENT_TEXT

    const clearSignature = () => {
        sigRef.current.clear();
        setResearcherSign('')
    }

    useEffect(() => {
        createDUPSign()
    }, [])

    const isStreetAddressValid = () => !!streetAddress;
    const isStateValid = () => !!state;
    const isCityValid = () => !!city;
    const isZipCodeValid = () => !!zipCode;
    const isCountryValid = () => !!country;
    const isSignatureValid = () => !sigRef?.current?.isEmpty();

    const onSignatureEnd = async () => {
        const dupSign = sigRef?.current?.toDataURL();
        setResearcherSign(dupSign);
    }

    const onDateChange = (value) => {
        props.onSigningDateChange(value);
    }
    return (
        <div className={Classes.mainAgreementContainer}>
            <Grid container direction='column'>
                <div className={Classes.impTitleHeaderContainer}>
                    <p className={Classes.alertTitle}>Important </p>
                    <p>Please sign the Data Use Agreement in order to request access to {currentZone} data.</p>
                </div>

            </Grid>

            <Grid container direction='column'>
                <div className={Classes.serviceAgreementBlock}>
                    <h3 className={Classes.agreementHeadingText}>Data Use Agreement</h3>
                    <ol className={Classes.duaContainer} type="1">
                        <li>
                            <u>Preamble</u>
                            <ol className={Classes.duaContainer}>
                                <li>This <b>BRAINCommons&trade; Data Use Agreement</b> (this <b>“Agreement”</b> or <b>“DUA”</b>) between BRAINCommons LLC, a limited liability company operated not for profit (<b>“BRAINCommons LLC”</b>) and the undersigned individual or entity (the “User”) sets forth the terms and conditions under which User is authorized to contribute, access and use various datasets, tools and applications within the BRAINCommons. This Agreement is made in accordance with appropriate global privacy and data protection laws and regulations, such as the EU General Data Protection Regulation (Regulation 2016/679) and the Health Insurance Portability and Accountability Act (HIPAA), and is effective from the date of approval by the BC Manager (the <b>“Effective Date”</b>). </li>
                                <li>Users may have certain rights to use certain Data that are made available through the BC Platform using a tiered data access system of Zones (Zone 1, 2, and 3) </li>
                                <li>User wishes to be authorized to access Data in Zone 2 or 3, as applicable, and therefore required to execute this Agreement. </li>
                                <li>In consideration of the mutual covenants contained herein, and for other good and valuable consideration, User and BRAINCommons LLC, intending to be legally bound, hereby agree as follows: </li>
                            </ol>
                        </li>
                        <li>
                            <u>Definitions</u>
                            <ul>
                                <li className={Classes.noLiStyle}><b>“Advanced Compute Privileges”</b> are privileges granted to designated Users of the BC Platform that meet specified requirements and have submitted the required documentation and have institutional certification approved for requested compute services. These Users have the ability to download data to workspaces and access to applications that support interoperability and tool development.</li>
                                <li className={Classes.noLiStyle}><b>“BC Executive Director”</b> is the individual representative of the BC Manager with senior responsibility and authority for the management of the BRAINCommons Platform. </li>
                                <li className={Classes.noLiStyle}><b>“BC Manager”</b> is the manager institution of the BRAINCommons with responsibility for the management of the BRAINCommons Platform. The BC Manager may be BRAINCommons LLC or its designee.</li>
                                <li className={Classes.noLiStyle}><b>“BC Platform”</b> or <b>“BRAINCommons Platform”</b> is the software (and other technology)-enabled platform for hosting, managing, analyzing and sharing information, data, tools and applications that are provided or made available in connection with the BRAINCommons.</li>
                                <li className={Classes.noLiStyle}><b>“BRAINCommons”</b> or <b>“BC”</b> is a knowledge commons that collocates or integrates data, storage and computing infrastructure with services, tools, interfaces and applications for managing, analyzing, and sharing data to create an interoperable resource for the brain and other research communities that enables and encourages data sharing, collaboration and data-driven discovery.</li>
                                <li className={Classes.noLiStyle}><b>“Controlled Access”</b> means a data access model whereby Users that are qualified researchers are granted access to several datasets in a controlled environment in the BC Platform. Controlled Access allows for use of some visualization and basic data exploration tools with the associated datasets and interaction with the community. Users granted with Controlled Access can also request Advanced Compute Privileges. See also <b>“Zone 2”</b>. </li>
                                <li className={Classes.noLiStyle}><b>“Data”</b> is any information, results, raw or processed data, including De-Identified Personal Data, that may be stored, uploaded or shared on the BC.</li>
                                <li className={Classes.noLiStyle}><b>“Data Contributor”</b> is the entity providing Data, possibly including De-identified Personal Data, to the BC Platform with legal authority to share such Data for the indicated purposes as prescribed in the BRAINCommons Data Contributor Agreement (or <b>“DCA”</b>).</li>
                                <li className={Classes.noLiStyle}><b>“Dataset-specific Use Agreement”</b> is a data use agreement in addition to this BRAINCommons Data Use Agreement and is for a specific dataset from a specific Data Contributor.</li>
                                <li className={Classes.noLiStyle}><b>“De-identified Personal Data”</b> or <b>“Pseudonymized Data”</b> means personal identifiers are replaced with nonidentifying references or Encryption Keys so that anyone working with the data is unable to identify the Data Subject without the Encryption Key.</li> 
                                <li className={Classes.noLiStyle}><b>“Educational Purposes”</b> means a purpose that relates directly to learning, teaching, training, and professional development from use of Data.  This includes non-profit and commercial activities that achieve this purpose.</li> 
                                <li className={Classes.noLiStyle}><b>“Encryption Key”</b> means a piece of data that an Encryption algorithm uses to determine exactly how to unscramble De-identified Personal Data or Pseudonymized Data. Such Encryption Keys are not provided to the BC Manager, or otherwise housed within the BC.</li>
                                <li className={Classes.noLiStyle}><b>“Process”, “Processing”</b> or <b>“Processed”</b> means any operation or set of operations that is performed on data or on sets of data, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, removal or destruction.</li>
                                <li className={Classes.noLiStyle}><b>"Public Access”</b> means a data access model whereby all Users have access to public datasets in the BC Platform. Public Access allows for use of some visualization and basic data exploration tools with the associated datasets and interaction with the community. See also <b>“Zone 1”</b>. </li>
                                <li className={Classes.noLiStyle}><b>“Research Purposes”</b> means non-commercial activities undertaken for the advancement of knowledge. It includes analysis on data to conduct a systematic investigation, including research development, testing, or evaluation, which is designed to develop or contribute to generalizable knowledge or education.</li>
                                <li className={Classes.noLiStyle}><b>“Restricted Access”</b> means a data access model whereby designated Users are granted access to one dataset or tool in a controlled environment in the BC Platform. Restricted Access allows for use of some visualization and basic data exploration tools with the designated dataset and interaction with the community. See also <b>“Zone 3”</b>. </li>
                                <li className={Classes.noLiStyle}><b>“Term”</b> means the term of this Agreement, which commences on the Effective Date until terminated pursuant to Section 6 of this Agreement.</li>
                                <li className={Classes.noLiStyle}><b>“Users”</b> means (1) registered users, including the general public, (2) authorized users who are qualified researchers, and (3) designated users designated by Data Contributor for use of a specific dataset, tool and/or application, in each case, accessing the BC Platform for Education Purposes or Research Purposes.</li>
                                <li className={Classes.noLiStyle}><b>“Zone 1”</b> is the level of ‘Public Access’ and provides Users access to Data that is subject to the BRAINCommons Terms of Use, BRAINCommons Global Privacy Notice and the BRAINCommons Global Privacy Policy.</li>
                                <li className={Classes.noLiStyle}><b>“Zone 2”</b> is the level of ‘Controlled Access’ and provides more limited access to Data to Users who are verified and meet the controlled user requirements as determined by the Data Access Committee (or, as applicable, via another approval process implemented by the BC Manager). Each such authorized User must enter into a BRAINCommons Data Use Agreement and if applicable, an organizational or Dataset-specific Use Agreement as part of his or her Zone Access Request.   </li>
                                <li className={Classes.noLiStyle}><b>“Zone 3”</b> is the level of ‘Restricted Access’ and provides access to specific datasets or tools to User(s) that are (a) specifically authorized by the Data Contributor or (b) approved through the Data Access Committee (or, as applicable, via another approval process implemented by the BC Manager). Prior to being granted access to Zone 3 Data, each such authorized User must enter into a BRAINCommons Data Use Agreement and if applicable, an organizational or Dataset-specific Use Agreement.</li>  
                                <li className={Classes.noLiStyle}><b>“Zone Access Request”</b> is the request that a User may make in order to request for access to Data that are designated as Zone 2 or Zone 3.</li>
                            </ul>
                        </li>

                        <li value="3"> 
                            <u>Institutional Certification and Data contribution</u>
                            <ol className={Classes.duaContainer}>
                                <li>If User is granted Controlled Access, then User shall submit to the BC Manager simultaneously with the execution of this Agreement, the Institutional Certification attached hereto as <b>Schedule A</b>. </li>
                                <li>Before contributing any Data to the BC, User must receive BC Manager approval and execute a BRAINCommons Data Contributor Agreement. </li>
                                
                            </ol>
                        </li>

                        <li>
                            <u>Zone 2 and Zone 3 Processing of Data</u> 
                            <ol className={Classes.duaContainer}>
                                <li>If User wishes to access Data in Zone 2 and/or Zone 3, User shall submit a Zone Access Request and provide any additional information requested by the BC Manager or the Data Access Committee. </li>
                                <li>User must state the intended use of such Data and email contact information as part of its Zone Access Request.</li>
                                <li>The BC Manager will process the submitted requests and any other materials provided, and this will be done in accordance with guidelines and methods adopted by the BC Manager, including the approval by or through a Data Access Committee. The BC Manager may contact User via email to request for additional information in connection with the Zone Access Request.</li>
                                <li>Where Advanced Compute Privileges to Zone 2 or Zone 3 is granted by the BC Manager to User, the appropriate permissions will be updated alongside functionality and account information for User.  To the extent such permitted functionality includes the ability to download and/or transfer downloaded Data or Results, User shall fully comply with all instructions, restrictions and requirements set forth on the BC Platform or otherwise provided by the BC Manager from time to time.  </li>
                                <li>The BC Manager will have broad discretion to accept, reject or delay any Zone Access Request. This will include circumstances where there is a potential conflict with any applicable informed consent materials provided by the Data Contributor.</li>
                                <li>In addition to any instructions, restrictions and requirements set forth on the BC Platform or otherwise provided by the BC Manager, if User downloads or transfers Data in Zone 2 or Zone 3 onto any device or system, User shall ensure that the device or system is at all times secured and that the Data will not be accessible by any unauthorized individual.  User shall be responsible and fully liable for any unauthorized use of downloaded or transferred Data, including unauthorized use as a result of an unsecure device or system.</li>
                                <li>Access to Zone 2 or Zone 3, as applicable, is granted to User for the duration established by the Data Contributor (or, if not specified, for one year from the date on which access is granted by the BC Manager).</li>
                                <li>User may request for a renewal of access to Zone 2 and/or Zone 3 Data and BC Manager may grant a renewal for a specified period or, if not specified, for one additional year from the date of access expiration if renewed access is granted by the BC Manager. Such renewed access is not guaranteed and is at BC Manager’s sole discretion. </li>
                                <li>In addition to this Data Use Agreement and any applicable terms of use associated with the BC, the Data Contributor may establish supplemental terms applicable to certain Zone 2 or Zone 3 Data (collectively, <b>“Additional Data Use Terms”</b>).  Additional Data Use Terms may be posted on the BC, part of an organizational or Dataset-specific Use Agreement or otherwise made available in connection with the applicable Data. </li>
                            </ol>
                        </li>

                        <li>
                            <u>Data disclosure and use</u>
                            <ol className={Classes.duaContainer}>
                                <li>User may receive and use the data made available to User via the BC only for Educational Purposes and/or Research Purposes. </li>
                                <li>User shall use appropriate contractual, technical, and organizational safeguards to prevent and mitigate any unauthorized access, use, alteration, disclosure of any and all Data in Zone 2 and Zone 3. </li>
                                <li>User shall promptly in all cases report any unauthorized use or disclosure of the Data by email to <a href="mailto:notice@braincommons.org">notice@braincommons.org</a> and in any event within 24 hours as soon as User becomes aware of such unauthorized use or disclosure. All notices received will be triaged for appropriate action and response.  </li>
                                <li>User shall not attempt to identify or contact any specific individual whose De-identified Personal Data is provided via the BC Platform.</li>
                                <li>User acknowledges that the Data Contributor reserves the right to re-assigned the designated Zone for a particular set of Data and any such re-assignment may result in the User losing access to such Data via the BC if User does not have access to the newly assigned Zone. User shall not circumvent data use controls or otherwise obtain unauthorized access to any Data or attempt or permit any other individual or entity to circumvent data use controls or otherwise obtain unauthorized access to any Data. </li>
                                <li>The BC Manager shall have the right to enforce this Agreement. </li>
                            </ol>
                        </li>

                        <li>
                            <u>Term and Termination</u>
                            <ol className={Classes.duaContainer}>
                                <li>Upon termination of this Agreement or expiration of access rights granted by BC Manager, User will promptly and securely destroy copies of all third party Data obtained through the BRAINCommons using industry-accepted methods. User will provide the BC Manager with written confirmation of such destruction. </li>
                                <li>Either party can terminate this DUA at any time, without cause, by giving the other party 30 days’ prior written notice. User’s obligations under this DUA shall remain active in their entirety until BC receives a written confirmation of the destruction of all Data, including De-identified Personal Data, obtained through the BRAINCommons. Section 7 (Indemnification) survives the termination of this DUA.</li>
                            </ol>
                        </li>

                        <li>
                            <u>Indemnification</u>
                            <ol className={Classes.duaContainer}>
                                <li>In addition to any other indemnification obligation, including the indemnification obligation set forth in the BRAINCommons Terms of Use, User shall indemnify, defend, and hold harmless BRAINCommons LLC and BC Manager and their respective affiliates, directors, officers, employees, agents, licensors, suppliers and contractors from and against all claims, losses, damages, expenses, civil monetary penalties, and costs (including attorneys' and court fees and expenses) arising out of or related to (a) any breach of this DUA by User or its directors, officers, employees, agents or contractors, including any breach or alleged breach of unsecured protected health information, or (b) any negligence or wrongful acts or omissions by User or its directors, officers, employees, agents or contractors, including without limitation, failure to perform User's obligations under this DUA or under any privacy, data protection and other applicable federal, state, or local laws.</li>
                            </ol>
                        </li>

                        <li>
                            <u>Amendment to this Data Use Agreement</u>
                            <ol className={Classes.duaContainer}>
                                <li>The parties will cooperate to amend this DUA as necessary from time to time to reflect changes in circumstances or applicable privacy and data protection laws. The BC Manager may amend this BRAINCommons Data Use Agreement at any time by providing notice to User. If User objects to the amended terms, User may terminate this Agreement pursuant to Section 6.</li>
                            </ol>
                        </li>

                        <li>
                            <u>Other provisions</u>
                            <ol className={Classes.duaContainer}>
                                <li><u>Assignment:</u> This DUA may not be assigned by User in whole or in part, without BC Manager’s written consent. BRAINCommons LLC may assign this DUA without consent or notice to User. Any attempted assignment in violation of this provision shall be null and void.</li>
                                <li><u>Entire Agreement and Severability:</u> This DUA, along with any other agreements, notices, policies and terms and conditions with respect to the BRAINCommons, collectively are the complete agreement between the parties and supersede all previous agreements or representations, written or oral, regarding use and access to data in Zone 1, 2 and/or 3 of the BRAINCommons. If any part of this DUA is held to be unenforceable, the remainder shall continue in effect.</li>
                                <li><u>Independent Contractors:</u> This DUA does not create any agency, joint venture or partnership between the parties.</li>
                                <li><u>No Third-Party Beneficiaries:</u> Nothing express or implied in this DUA is intended to or shall confer any rights, remedies, obligations or liabilities on any person other than the parties to this DUA.</li>
                                <li><u>Monitoring and Enforcement:</u> The BC Manager can use all reasonable powers to monitor and enforce this DUA. The BC Manager may report any accidental or intentional violations of this DUA to affected Users, Data Contributors, an Institutional Review Boards (IRB) or other ethics board, regulators and institutional authorities (as applicable) or as required under privacy and data protection laws and regulations.</li>
                                <li><u>Waiver:</u> Neither party’s delay or omission in exercising any right or remedy under this DUA will constitute a waiver or prevent the applicable party’s ability to exercise any right or remedy in the future.</li>
                                <li><u>Counterpart:</u>  This Agreement may be executed in counterparts and in digital format (including pdf or any electronic signature complying with the U.S. federal ESIGN Act of 2000, e.g., www.docusign.com) or any other transmission method, and each such counterpart shall have the same force and effect as an original and shall constitute an effective, binding agreement on the part of each of the undersigned. </li>
                                <li>
                                    <u>Governing Law:</u> 
                                    <ol className={Classes.duaContainer}>
                                        <li>Subject to Section 9.8.2, this Agreement shall be governed by and construed in accordance with the law of the State of New York. The parties agree to submit to the exclusive jurisdiction of the courts of the State of New York as regards any claim or matter arising out of or in connection with this Agreement.</li>
                                        <li>Where this Agreement is referred to in this context of particular processing (for example, with regard to the terms and effect of a transfer solution within its scope) the governing law in relation to that transfer solution shall be the law of the country where the applicable Data Contributor is established.</li>
                                        <li>For the avoidance of doubt, nothing in this Agreement waives or varies the rights individuals may have under their applicable national data protection law.</li>
                                    </ol>
                                </li>
                                <li>
                                    <u>Notices.</u>  Notices given under this Agreement shall be in writing and be addressed to the Parties as specified below. Notices shall be delivered by certified or registered first class mail, by commercial courier service or by email. Notices shall be deemed given three days after mailing or sending via courier, or upon acknowledgement of receipt if emailed.
                                    <p className={Classes.address}>
                                        BRAINCommons LLC<br />
                                        535 8th Avenue, 12th Floor, <br />
                                        New York, New York 10018<br />
                                        Attention:  BC Executive Director<br />
                                        Email: <a href="mailto:notice@braincommons.org">notice@braincommons.org</a>
                                    </p>

                                </li>
                                <li>
                                    <u>Severability.</u>  If, at any time, any provision of this Agreement becomes invalid, illegal or unenforceable in any respect under the law of any jurisdiction in relation to any party that shall, so long as the commercial purpose of this Agreement is still capable of performance, not in any way affect or impair:
                                    <ol className={Classes.duaContainer}>
                                        <li>the validity, legality, or enforceability in that jurisdiction and in relation to that party of any other provision of this Agreement; or</li>
                                        <li>the validity, legality, or enforceability under the law of any other jurisdiction or in relation to any other party of that or any other provision of this Agreement.</li>
                                    </ol>
                                </li>
                                <li><u>English Language.</u> In the event of translation of this Agreement into a language other than English, and there being a conflict between the translation and the English version of the Agreement, the English language version of the Agreement shall prevail.</li>
                            </ol>
                        </li>

                    </ol>
                </div>
            </Grid>
            <Grid container direction='column' spacing={2}>
                <div className={Classes.agreementFormMainContainer}>
                    <Grid item xs={8} className={Classes.agreementFormContainerNames}>
                        <p className={Classes.boldText}> I acknowledge that I have read and understood this BRAINCommons Data Use Agreement and agree to be bound by its terms and conditions.</p>
                        <div className={Classes.signatureContainer}>
                            <p><label>Sign Policy<sup> *</sup></label></p>
                            <p>Sign your name on the trackpad and press any key when finished</p>
                            <div id='signature-pad' className={Classes.signaturePad}>
                                <div className={Classes.clickHeretoBegin}></div>
                                <SignatureCanvas penColor='black'
                                    ref={sigRef}
                                    onEnd={onSignatureEnd}
                                    canvasProps={{ width: 500, height: 150, className: 'sigCanvas' }} />
                                <button className={`no-outlined-button ${Classes.clearButton}`} onClick={clearSignature}>Clear</button>
                                {
                                    isFormSubmitted && !isSignatureValid() &&
                                    <ErrorText errorMessage="Signature mandatory" />
                                }
                            </div>
                        </div>
                        <Grid container>
                            <Grid item xs={5}>

                            </Grid>
                            <Grid item xs={7}>
                                <div className={Classes.agreeContainer}>
                                    <span className={Classes.agreeCheckbox}>
                                        <Checkbox
                                            color="primary"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            value={isAgreeSelected}
                                            checked={isAgreeSelected}
                                            onChange={(e) => setIsAgreeSelected(e.target.checked)}
                                        />
                                    </span>
                                    <span className={Classes.agreeContent}>
                                        {agreementText ? agreementText : "I confirm that by signing this Agreement I am legally bound by its terms and conditions"}
                                    </span>
                                </div>
                                {
                                    isFormSubmitted && !isAgreeSelected &&
                                    <ErrorText errorMessage="Please confirm agreement" />
                                }
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid item xs={6} className={Classes.greydFields} >
                                <label>Name</label>
                                <OutlinedInput
                                    fullWidth
                                    disabled
                                    value={userDetails?.["first_name"] + " " + userDetails?.["last_name"]}
                                    placeholder='Username'
                                    id='username'
                                />
                            </Grid>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid item xs={6} className={Classes.greydFields} >
                                <label>Email</label>
                                <OutlinedInput
                                    fullWidth
                                    disabled
                                    value={userDetails?.["email"]}
                                    placeholder='Email'
                                    id='email'
                                />
                            </Grid>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid item xs={6}>
                                <label id='signingDate'>Date<sup> *</sup></label>
                                <CommonDatePicker
                                    onCalenderDateSelected={onDateChange}
                                    date={props.signingDate}
                                    minDate={moment().add(0, 'd').toDate()}
                                    showValidationError={isFormSubmitted && !isDate(props.signingDate)}
                                    errorMessage='Select date'
                                    id='cvb-DUP-dateInput'
                                    canClearInput
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={Classes.instituteDetailsContainer}>
                            <p className={`${Classes.contactFormTitles} ${Classes.addMargin}`}>Address</p>
                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <label htmlFor='country'>Country<sup> *</sup></label>
                                        <DropDown
                                            id='country'
                                            values={COUNTRY_LIST}
                                            handleFilterStateChange={setCountry}
                                            selectedFilter={country}
                                            placeholder={'Select Country'}
                                            searchable
                                            isClearable
                                        />
                                        {
                                            isFormSubmitted && !isCountryValid() &&
                                            <ErrorText errorMessage="Select country" />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <label htmlFor='streetAddress'>Street Address<sup> *</sup></label>
                                <OutlinedInput
                                    fullWidth
                                    value={streetAddress}
                                    onChange={e => { setStreetAddress(e.target.value); }}
                                    placeholder='Enter street address'
                                    name='streetAddress'
                                    id='streetAddress'
                                />
                                {
                                    isFormSubmitted && !isStreetAddressValid() &&
                                    <ErrorText errorMessage="Enter valid street address" />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <label htmlFor='city'>City<sup> *</sup></label>
                                        <OutlinedInput
                                            fullWidth
                                            value={city}
                                            onChange={e => { setCity(e.target.value); }}
                                            placeholder='Enter city'
                                            name='city'
                                            id='city'
                                        />
                                        {
                                            isFormSubmitted && !isCityValid() &&
                                            <ErrorText errorMessage="Enter valid city" />
                                        }
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label htmlFor='state'>State<sup> *</sup></label>
                                        <OutlinedInput
                                            fullWidth
                                            value={state}
                                            onChange={e => { setState(e.target.value); }}
                                            placeholder='Enter state'
                                            name='state'
                                            id='state'
                                        />
                                        {
                                            isFormSubmitted && !isStateValid() &&
                                            <ErrorText errorMessage="Enter valid state" />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <label htmlFor='zipCode'>Zip Code<sup> *</sup></label>
                                        <OutlinedInput
                                            fullWidth
                                            value={zipCode}
                                            onChange={e => { setZipCode(e.target.value); }}
                                            placeholder='Enter zip code'
                                            name='zipCode'
                                            id='zip'
                                        />
                                        {
                                            isFormSubmitted && !isZipCodeValid() &&
                                            <ErrorText errorMessage="Enter valid zip code" />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </div>
    )
}

import { SET_DIF_INSTITUTION_INSTITUTION_NAME, SET_DIF_INSTITUTION_STREET_ADDRESS, SET_DIF_INSTITUTION_COUNTRY,
    SET_DIF_INSTITUTION_STATE, SET_DIF_INSTITUTION_CITY, SET_DIF_INSTITUTION_MUNICIPALITY, SET_DIF_INSTITUTION_ZIP_CODE, 
    SET_INSTITUTIONAL_FORM_DATA, RESET_INSTITUTIONAL_FORM_DATA
} from '../action-types'
import get from 'lodash/get'
const initialState = {
   difInstitutionInstitutionName: '',
   difInstitutionStreetAddress: '',
   difInstitutionCountry: '',
   difInstitutionState: '',
   difInstitutionCity: '',
   difInstitutionMunicipality: '',
   difInstitutionZipCode: ''
}

const difInstitutionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DIF_INSTITUTION_INSTITUTION_NAME: {
            return {
                ...state,
                difInstitutionInstitutionName: action.payload
            }
        }
        case SET_DIF_INSTITUTION_STREET_ADDRESS: {
            return {
                ...state,
                difInstitutionStreetAddress: action.payload
            }
        }
        case SET_DIF_INSTITUTION_COUNTRY: {
            return {
                ...state,
                difInstitutionCountry: action.payload
            }
        }
        case SET_DIF_INSTITUTION_STATE: {
            return {
                ...state,
                difInstitutionState: action.payload
            }
        }
        case SET_DIF_INSTITUTION_CITY: {
            return {
                ...state,
                difInstitutionCity: action.payload
            }
        }
        case SET_DIF_INSTITUTION_MUNICIPALITY: {
            return {
                ...state,
                difInstitutionMunicipality: action.payload
            }
        }
        case SET_DIF_INSTITUTION_ZIP_CODE: {
            return {
                ...state,
                difInstitutionZipCode: action.payload
            }
        }
        case SET_INSTITUTIONAL_FORM_DATA: {
            const formData = action.payload;
            return {
                difInstitutionInstitutionName: get(formData, 'name', ''),
                difInstitutionStreetAddress: get(formData, 'street_address', ''),
                difInstitutionCountry: get(formData, 'country', ''),
                difInstitutionState: get(formData, 'state', ''),
                difInstitutionCity: get(formData, 'city', ''),
                difInstitutionMunicipality: get(formData, 'municipality', ''),
                difInstitutionZipCode: get(formData, 'zip_code', '')
            }
        }
        case RESET_INSTITUTIONAL_FORM_DATA: {
            return {
                difInstitutionInstitutionName: '',
                difInstitutionStreetAddress: '',
                difInstitutionCountry: '',
                difInstitutionState: '',
                difInstitutionCity: '',
                difInstitutionMunicipality: '',
                difInstitutionZipCode: ''
            }
        }
        
        default: return state
    }
}

export default difInstitutionReducer;
/**
 *  External Imports
 */
import React from "react";
import { string, func, number } from "prop-types";

/**
 *  Internal Imports
 */
import UserAvatar from "../../../commons/user-avatar";
import { useHover } from "../../../../utils/hooks";
import iconSuccess from "../../../../assets/icons/success_icon_sm.svg";
import iconClose from "../../../../assets/icons/close_icon_sm.svg";
import styles from "./index.module.css";

const CommunityWidgetRecommendedConnectionsItem = (props) => {
    const { userIcon, name, mutualDescription, idRecommendation } = props;
    const {connectRecommendedUser, declineRecommendedUser} = props;

    const [isHovered, eventHandlers] = useHover();

    return (
        <div data-testid="widgetListItem" {...eventHandlers} className={styles.listItem}>
            <div className={styles.listItemContent}>
                <UserAvatar data-testid="userIcon" midIcon={true} userIcon={userIcon}></UserAvatar>

                <div className={styles.listItemTextArea}>
                    <div className={styles.listItemTitle} data-testid="userName">{name}</div>

                    <div className={styles.listItemDescription} data-testid="userMutualDescription">
                        {mutualDescription}
                    </div>
                </div>
            </div>
            <div className={styles.listItemControls}>
                {isHovered && (
                    <>
                        <button data-testid="widgetConnectBtn" className={styles.control} onClick={() => connectRecommendedUser(idRecommendation)}>
                            <img src={iconSuccess} alt="Icon Success" />
                        </button>
                        <button data-testid="widgetDeclineBtn" className={styles.control} onClick={() => declineRecommendedUser(idRecommendation)}>
                            <img src={iconClose} alt="Icon Close" />
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

CommunityWidgetRecommendedConnectionsItem.propTypes = {
    userIcon: string.isRequired,
    name: string.isRequired,
    idRecommendation: number.isRequired,
    mutualDescription: string.isRequired,
    connectRecommendedUser: func.isRequired,
    declineRecommendedUser: func.isRequired
};

export default CommunityWidgetRecommendedConnectionsItem;

import React from 'react'
import Classes from './index.module.css';
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import { COUNTRY_LIST } from '../../../constants/strings';
import map from 'lodash/map'
import v4 from 'uuid/v4'
import Select from '@mui/material/Select';
import ErrorText from '../error-text';
import isEmpty from 'lodash/isEmpty'
import { validateEmailAddress } from '../../../utils/input-validations';
import { DCA_FORM_COMPLETED_TEXT } from '../../../constants/strings'
import DropDown from '../../commons/dropdown';
const ProjectPrimaryInstitutional = (props) => {
    const {
        ppiFirstName,
        ppiLastName,
        ppiMiddleName,
        ppiCredentials,
        ppiInstituteName,
        ppiInstituteEmail,
        ppiInstituteStreetAddress,
        ppiInstituteCity,
        ppiInstituteState,
        ppiInstituteZipCode,
        ppiInstituteCountry,
        isFormSubmitted,
        currentDCAFormStage,

        setPPIFirstName,
        setPPILastName,
        setPPIMiddleName,
        setPPICredentials,
        setPPIInstituteName,
        setPPIInstituteEmail,
        setPPIInstituteStreetAddress,
        setPPIInstituteCity,
        setPPIInstituteState,
        setPPIInstituteZipCode,
        setPPIInstituteCountry
    } = props;

    const onFirstNameChange = event => {
        setPPIFirstName(event.target.value);
    }
    const onMiddleNameChange = event => {
        setPPIMiddleName(event.target.value);
    }
    const onLastNameChange = event => {
        setPPILastName(event.target.value);
    }
    const onCredentialsChange = event => {
        setPPICredentials(event.target.value);
    }
    const onInstitutionNameChange = event => {
        setPPIInstituteName(event.target.value);
    }
    const onInstitutionalEmailChange = event => {
        setPPIInstituteEmail(event.target.value);
    }
    const onInstitutionStreetChange = event => {
        setPPIInstituteStreetAddress(event.target.value);
    }
    const onInstitutionCityChange = event => {
        setPPIInstituteCity(event.target.value);
    }
    const onInstitutionStateChange = event => {
        setPPIInstituteState(event.target.value);
    }
    const onInstitutionZipChange = event => {
        setPPIInstituteZipCode(event.target.value);
    }
    const onInstitutionCountryChange = value => {
        setPPIInstituteCountry(value);
    }
    const disableTextInput =  (currentDCAFormStage === DCA_FORM_COMPLETED_TEXT);
    return (
        <div className={Classes.container} id='cvb-dca-stage3PPICContainer'>
            <p className={Classes.formTitle}>Project Primary Institutional Contact</p>
            <Grid container className={Classes.inputContainers} spacing={3}>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label id='cvb-dca-stage3FirstNameLabel' htmlFor='firstName'>First Name<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter first name"
                        value={ppiFirstName}
                        onChange={onFirstNameChange}
                        fullWidth
                        id='firstName'
                        disabled={disableTextInput}
                    />
                    {
                        isFormSubmitted && isEmpty(ppiFirstName) &&
                        <ErrorText errorMessage="Enter valid first name" />
                    }
                </Grid>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label id='cvb-dca-stage3MiddleNameLabel' htmlFor='firstName'>Middle Name</label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter middle name"
                        value={ppiMiddleName}
                        onChange={onMiddleNameChange}
                        fullWidth
                        id='middleName'
                        disabled={disableTextInput}
                    />
                    {/* {
                        isFormSubmitted && isEmpty(ppiMiddleName) &&
                        <ErrorText errorMessage="Enter valid middle name" />
                    } */}
                </Grid>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label htmlFor='firstName' id='cvb-dca-stage3LastNameLabel'>Last Name<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter last name"
                        value={ppiLastName}
                        onChange={onLastNameChange}
                        fullWidth
                        id='lastName'
                        disabled={disableTextInput}
                    />
                    {
                        isFormSubmitted && isEmpty(ppiLastName) &&
                        <ErrorText errorMessage="Enter valid last name" />
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={4} className={Classes.fieldCont}>
                            <label id='cvb-dca-stage3CredentialsLabel' htmlFor='firstName'>Credentials<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                placeholder="Enter credentials"
                                value={ppiCredentials}
                                onChange={onCredentialsChange}
                                fullWidth
                                id='credentials'
                                disabled={disableTextInput}
                            />
                            {
                                isFormSubmitted && isEmpty(ppiCredentials) &&
                                <ErrorText errorMessage="Enter valid credentials" />
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label id='cvb-dca-stage3InstituteNameLabel' htmlFor='firstName'>Institute Name<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter institute name"
                        value={ppiInstituteName}
                        onChange={onInstitutionNameChange}
                        fullWidth
                        id='instituteName'
                        disabled={disableTextInput}
                    />
                    {
                        isFormSubmitted && isEmpty(ppiInstituteName) &&
                        <ErrorText errorMessage="Enter valid institute name" />
                    }
                </Grid>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label id='cvb-dca-stage3InstituteEmailLabel' htmlFor='firstName'>Institutional Email<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter email"
                        value={ppiInstituteEmail}
                        onChange={onInstitutionalEmailChange}
                        fullWidth
                        id='instituteEmail'
                        disabled={disableTextInput}
                    />
                    {
                        isFormSubmitted && !validateEmailAddress(ppiInstituteEmail) &&
                        <ErrorText errorMessage="Enter valid email" />
                    }
                </Grid>
                <Grid item xs={12}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={12}>
                    <p className={`${Classes.formTitle} ${Classes.institutionAddrTitle}`} id='cvb-dca-stage3InstituteAddressLabel'>Institution Address</p>
                    <Grid container className={Classes.inputContainers} spacing={3} direction='row'>
                        <Grid item xs={5}>
                            <label htmlFor='country' id='cvb-dca-stage3CountryLabel'>Country<sup> *</sup></label>
                            <DropDown
                                id='instituteCountry'
                                values={COUNTRY_LIST}
                                handleFilterStateChange={onInstitutionCountryChange}
                                selectedFilter={ppiInstituteCountry}
                                placeholder={'Select Country'}
                                disable={disableTextInput}
                                searchable
                                isClearable
                            />
                            {
                                isFormSubmitted && isEmpty(ppiInstituteCountry) &&
                                <ErrorText errorMessage="Select country" />
                            }
                        </Grid>
                        <Grid item xs={5} className={Classes.fieldCont}>
                            <label htmlFor='firstName' id='cvb-dca-stage3StreetAddressLabel'>Street Address<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                placeholder="Enter street address"
                                value={ppiInstituteStreetAddress}
                                onChange={onInstitutionStreetChange}
                                fullWidth
                                id='instituteStreetAddress'
                                disabled={disableTextInput}
                            />
                            {
                                isFormSubmitted && isEmpty(ppiInstituteStreetAddress) &&
                                <ErrorText errorMessage="Enter valid street address" />
                            }
                        </Grid>
                        <Grid item xs={5} className={Classes.fieldCont}>
                            <label htmlFor='firstName' id='cvb-dca-stage3CityLabel'>City<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                placeholder="Enter city"
                                value={ppiInstituteCity}
                                onChange={onInstitutionCityChange}
                                fullWidth
                                id='instituteCity'
                                disabled={disableTextInput}
                            />
                            {
                                isFormSubmitted && isEmpty(ppiInstituteCity) &&
                                <ErrorText errorMessage="Enter valid city" />
                            }
                        </Grid>
                        <Grid item xs={5} className={Classes.fieldCont}>
                            <label htmlFor='firstName' id='cvb-dca-stage3StateLabel'>State<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                placeholder="Enter state"
                                value={ppiInstituteState}
                                onChange={onInstitutionStateChange}
                                fullWidth
                                id='instituteState'
                                disabled={disableTextInput}
                            />
                            {
                                isFormSubmitted && isEmpty(ppiInstituteState) &&
                                <ErrorText errorMessage="Enter valid state" />
                            }
                        </Grid>
                        <Grid item xs={5} className={Classes.fieldCont}>
                            <label htmlFor='firstName' id='cvb-dca-stage3ZipCodeLabel'>Zip Code<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                placeholder="Enter zip code"
                                value={ppiInstituteZipCode}
                                onChange={onInstitutionZipChange}
                                fullWidth
                                id='instituteZipCode'
                                disabled={disableTextInput}
                            />
                            {
                                isFormSubmitted && isEmpty(ppiInstituteZipCode) &&
                                <ErrorText errorMessage="Enter valid zip code" />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

ProjectPrimaryInstitutional.defaultProps = {
    ppiFirstName: '',
    ppiLastName: '',
    ppiMiddleName: '',
    ppiCredentials: '',
    ppiInstituteName: '',
    ppiInstituteEmail: '',
    ppiInstituteStreetAddress: '',
    ppiInstituteCity: '',
    ppiInstituteState: '',
    ppiInstituteZipCode: '',
    ppiInstituteCountry: '',
    isFormSubmitted: false,
    currentDCAFormStage: '',

    setPPIFirstName: () => { },
    setPPILastName: () => { },
    setPPIMiddleName: () => { },
    setPPICredentials: () => { },
    setPPIInstituteName: () => { },
    setPPIInstituteEmail: () => { },
    setPPIInstituteStreetAddress: () => { },
    setPPIInstituteCity: () => { },
    setPPIInstituteState: () => { },
    setPPIInstituteZipCode: () => { },
    setPPIInstituteCountry: () => { },
}
export default ProjectPrimaryInstitutional;
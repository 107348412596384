import { createTheme } from "@mui/material/styles";
import { vars } from './assets/variables';

const {
  primaryFont,
  primaryColor,
  lightGrey,
  darkTextColor,
  primaryTextColor,
  white,
  btnContainedColor,
  btnOutlinedBorderColor,
  btnOutlinedPrimaryHoverBg,
  outlinedInputBorderColor,
  selectPlaceholderColor,
  errorColor,
  errorHoverColor,
  grey,
  inputLabelColor,
} = vars;

export default createTheme({
  palette: {
    text: {
      primary: "#667085",
    },
    primary: {
      main: primaryColor,
    },
    action: {
      disabledBackground: "",
      disabled: ""
    }
  },
  typography: {
    fontFamily: primaryFont,
    body1: {
      fontSize: "1em",
    },
    h2: {
      color: darkTextColor,
      fontWeight: 500,
      fontSize: "1.3rem",
      lineHeight: "2em",
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.3rem",
      lineHeight: "1.1em",
      color: primaryTextColor,
    },
    h4: {
      fontWeight: 600,
      fontSize: "1em",
      lineHeight: "1.1em",
      margin: 0,
    },
    span: {
      fontSize: "1em",
      lineHeight: "1.1em",
    },
    subtitle1: {
      fontSize: "0.9em",
      lineHeight: "1.1em",
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          backgroundColor: "white",
        },
        root: {
          borderRight: "1px solid rgba(224, 224, 224, 1)",
          fontSize: "1rem",
          borderCollapse: "collapse",
          paddingTop: "0.5em",
          paddingBottom: "0.5em",
          "& span": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },

          "& .MuiCheckbox-root": {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover td, &:hover th": {
            backgroundColor: "#F7F9FC",
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            fontWeight: 600,
            padding: "0.5em 1em",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "1.3em",

          "&.Mui-expanded": {
            minHeight: "1.3em",
          },
        },
        content: {
          margin: 0,
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderTop: "1px solid rgba(224, 224, 224, 1)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: lightGrey,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "& .MuiTypography-body1": {
            fontSize: "1rem",
            margin: 0,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiIconButton-root": {
            marginRight: "0.3em",
          },
          "& .MuiTypography-body1": {
            // fontWeight: 600,
            fontSize: "1rem",
            margin: 0,
          },
          marginBottom: 0,
          fontSize: "inherit",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: "3.125rem",
          boxShadow: 'none',
          fontSize: "0.875rem",
          padding: "0.625rem 1.125rem",
          lineHeight: "1.5rem",
          fontWeight: 600,

          '& img': {
            marginRight: '0.25rem'
          },

          '&:not(:last-child)': {
            marginRight: '0.75rem',
          },
        },

        contained: {
          background: white,
          color: btnContainedColor,

          '&:hover': {
            color: btnContainedColor,
            background: white,
          }
        },

        containedPrimary: {
          background: primaryColor,
          color: white,
          boxShadow: '0 0.25rem 0.625rem rgba(77, 128, 198, 0.4)',

          '&.disable-shadow': {
            boxShadow: 'none'
          },

          '&:hover': {
            color: white,
            background: btnContainedColor,
          }
        },

        containedError: {
          background: errorColor,
          color: white,

          '&:hover': {
            color: white,
            boxShadow: 'none',
            background: errorHoverColor,
          }
        },

        outlined: {
          borderColor: btnOutlinedBorderColor,
          backgroundColor: 'transparent',
          color: white,

          '&:hover': {
            borderColor: white,
            backgroundColor: 'rgba(255, 255, 255, .1)',
          }
        },

        sizeSmall: {
          padding: '0.5rem 0.75rem',
          lineHeight: "1.0625rem",
        },

        outlinedPrimary: {
          borderColor: primaryColor,
          background: white,
          color: primaryColor,

          '&:hover': {
            borderColor: primaryColor,
            backgroundColor: btnOutlinedPrimaryHoverBg,
          }
        },

        outlinedSecondary: {
          borderColor: primaryTextColor,
          color: primaryTextColor,

          '&:hover': {
            borderColor: primaryTextColor,
            backgroundColor: primaryTextColor,
            color: white,
          }
        },

        outlinedError: {
          borderColor: errorColor,
          color: errorColor,

          '&:hover': {
            borderColor: errorHoverColor,
            backgroundColor: 'none',
            color: errorHoverColor,
          }
        }
      },
    },
    MuiTabs: {
      variants: [
        {
          props: { variant: "buttons" },
          style: {
            backgroundColor: lightGrey,

            border: 'none',
            borderRadius: 8,
            "& .MuiTabs-indicator": {
              display: 'none',
            },
            "& .MuiTabs-scroller": {
              padding: 4,
            },
            "& .MuiTouchRipple-root": {
              display: 'none',
            },
            "& .MuiTab-root": {
              backgroundColor: 'transparent',
              borderRadius: 8,
              fontSize: "0.9rem",
              flex: 1,
            },
            "& .MuiTab-textColorPrimary": {
              color: primaryColor,
            },
            "& .MuiTab-root.Mui-selected": {
              backgroundColor: white,
              borderBottom: 'none',
              overflow: "visible",
              position: "relative",
              zIndex: 1,
              boxShadow:
                "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
            },
          },
        },
      ],
      styleOverrides: {
        indicator: {
          backgroundColor: primaryColor,
        },
        root: {
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          "& .MuiTab-root": {
            fontSize: "1rem",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: 'none',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          boxShadow: '0 0.75rem 1rem -0.25rem rgba(16, 24, 40, 0.08), 0 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.03)',
          borderRadius: '0.25rem',
          background: white,
          border: '0.0625rem solid #E7EAF2',
          fontWeight: 500,
          fontSize: '0.875rem',
          lineHeight: '1rem',
          color: primaryTextColor,
        },
        thumb: {
          color: white,
          width: '0.75rem',
          border: `0.09375rem solid ${primaryColor}`,
          height: '0.75rem',

          '&:before': {
            boxShadow: '0 0.25rem 0.5rem -0.125rem rgba(16, 24, 40, 0.1), 0 0.125rem 0.25rem -0.125rem rgba(16, 24, 40, 0.06)',
          },
        },
        track: {
          color: primaryColor,
        },
        rail: {
          color: "#EAECF0",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: grey,
          '&.Mui-checked': {
            color: inputLabelColor
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: white,
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: errorColor
            }
          },
          '&:not(.MuiInputBase-multiline)': {
            height: '2.75rem',

            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: primaryColor,
                borderWidth: '0.0625rem',
                boxShadow: '0 0 0 0.0625rem rgba(77, 128, 198, 0.3)',
              },
            },
            '&:hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: primaryColor,
              },
            },
          },
        },
        input: {
          borderRadius: '0.375rem',
          '&.MuiInputBase-inputMultiline': {
            '&::placeholder': {
              fontSize: '0.875rem',
              color: `${grey} !important`,
              fontWeight: 500,
              letterSpacing: '0.015rem',
            }
          }
        },
        notchedOutline: {
          borderColor: outlinedInputBorderColor,
          "& legend": {
            width: "auto",
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          '& .MuiTypography-root': {
            color: white,
            letterSpacing: '-0.02em',
            fontWeight: 500,
            fontSize: '1.5rem',
            lineHeight: '1.8125rem',
          },
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {},
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          boxShadow: '0 0.75rem 1rem -0.25rem rgba(16, 24, 40, 0.08), 0 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.03)',
          background: '#FFFFFF',
          border: `0.0625rem solid ${outlinedInputBorderColor}`,
          borderRadius: '0.25rem',
          fontWeight: 500,
          fontSize: '0.75rem',
          lineHeight: '1rem',
          color: primaryTextColor,
          padding: '0.625rem 0.75rem',
        },
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: '0 0.75rem 1rem -0.25rem rgba(16, 24, 40, 0.1), 0 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.05)',
          // border: 'none',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '0.625rem 0.875rem',
          fontWeight: 500,
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          color: primaryTextColor,
          borderRadius: '0.25rem',

          '&.Mui-disabled': {
            display: 'none',
          },

          '&.Mui-selected': {
            backgroundColor: primaryColor,
            boxShadow: `0 0.0625rem 0 ${btnContainedColor}`,
            color: white,

            '&.Mui-focusVisible': {
              backgroundColor: primaryColor
            },

            '&:hover': {
              backgroundColor: primaryColor,
            },
          },
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          border: `0.0625rem solid ${outlinedInputBorderColor}`,
          boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
          borderRadius: '0.375rem',
        },

        list: {
          padding: '0.75rem',
        }
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          '& em': {
            fontStyle: 'normal',
            color: selectPlaceholderColor,
          },
          borderRadius: '0.375rem',
        },
        select: {
          padding: '0.6875rem 0.75rem',
          fontWeight: 500,
          fontSize: '0.875rem',
          color: primaryTextColor,
        }
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
          marginTop: '2rem',
          justifyContent: 'space-between',
          '& .MuiButton-root': {
            width: 'calc(50% - 0.375rem)',
            margin: 0,
          },
        },
      },
    },

    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          color: primaryTextColor,
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          margin: '0.375rem 0 0',
          color: primaryTextColor,

          '&.Mui-error': {
            color: errorColor
          }
        }
      }
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          fontWeight: 500,
          fontSize: '1.125rem',
          lineHeight: '1.75rem',
          marginBottom: '0.5rem',
          color: darkTextColor,
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: '0 1.25rem 1.5rem -0.25rem rgba(16, 24, 40, 0.1), 0 0.5rem 0.5rem -0.25rem rgba(16, 24, 40, 0.04)',
          maxWidth: '25rem',
          borderRadius: '0.75rem',
          padding: '1.5rem',
        }
      }
    },
},
MuiPaper: {
  styleOverrides: {
    root: {
      borderRadius: 8,
    },
  },
},
});

import AdminDashboard from './AdminDashboard'
import { connect } from 'react-redux'
import { setDifStage } from '../../../redux/actions/dataInventoryActions'
import { setDifDataSharingAssociatedEmail } from '../../../redux/actions/difDataSharingActions'
import { updateSnackBar } from '../../../redux/actions/globalActions'
const mapStateToProps = state => {
    return {
        userDetails: state.user.userDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDifStage: async (value) => await dispatch(setDifStage(value)),
        setDifDataSharingAssociatedEmail: async (value) => await dispatch(setDifDataSharingAssociatedEmail(value)),
        updateSnackBar: async (message, type) => await dispatch(updateSnackBar(message, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);

import React from 'react';
import classes from './index.module.css';
import FiltersPopover from '../filters-popover'
import ImgClose from '../../../assets/icons/close-small.svg'
import filter from 'lodash/filter'
import join from 'lodash/join'
import v4 from 'uuid/v4'
import moment from 'moment'

function ExploreAppliedFilters(props) {
    const {
        exploreFilters = {},
        onChangeFilterItemState = () => { },
        clearAppliedFiltersPerCategory = () => { },
        resetAllFilters = () => { }
    } = props;

    // Returns the list of items in a filter that are applied per category
    const getFilterAppliedForCategory = category => {
        try {
            const histograms = exploreFilters[category].histogram;
            const appliedHistogram = filter(histograms, item => item.isSelected === true)
            let payload = {}
            payload[category] = {
                histogram: appliedHistogram
            }
            return appliedHistogram.length > 0 ? payload : null
        }
        catch (error) {
            return null;
        }
    }

    // Returns the object containing all the applied filters
    const getAppliedFilters = () => {
        const categories = Object.keys(exploreFilters);
        let appliedFilters = {}
        for (let item of categories) {
            if (exploreFilters[item]?.isDateField) {
                if (exploreFilters[item]?.selected_start_date && exploreFilters[item]?.selected_end_date) {
                    appliedFilters = {
                        ...appliedFilters, ...{
                            [item]: {
                                isDateField: true,
                                key: `${moment(exploreFilters[item]?.selected_start_date).format("MM/DD/YYYY")} - ${moment(exploreFilters[item]?.selected_end_date).format("MM/DD/YYYY")}`
                            }
                        }
                    }
                }

            } else {
                const payload = getFilterAppliedForCategory(item);
                if (payload) {
                    appliedFilters = { ...appliedFilters, ...payload }
                }
            }
        }
        return appliedFilters;
    }

    // Parse the applied filters per category as a text and returns it.
    const getAppliedFiltersTextPerCategory = (appliedFilters, category) => {
        try {
            if(appliedFilters[category]?.isDateField){
                return {
                    text: appliedFilters[category]?.key,
                    moreItems: null
                }
            }
            const filters = appliedFilters[category]?.histogram.map(item => {
                if (Array.isArray(item.key))
                    return item.key.join(" - ")
                else
                    return item.key
            }) || [];
            if (filters?.length > 0) {
                if (filters.length <= 1) {
                    return {
                        text: join(filters, ", "),
                        moreItems: null
                    }
                } else {
                    return {
                        moreItems: filters.length - 1,
                        text: filters.splice(0, 2).join(", "),
                    }
                }
            }
            else {
                return {
                    text: ""
                }
            }
        }
        catch (error) {
            return {
                text: ""
            }
        }
    }

    const appliedFilters = getAppliedFilters();

    return (
        <div className={classes.appliedFiltersBlock}>
            <ul className={classes.filterHeader}>
                <li id='cvb-exploreAppliedFilters-appliedFiltersHeader' className={classes.filterTitle}>Applied Filters</li>
                <li id='cvb-exploreAppliedFilters-removeAllFiltersButton' className={classes.headerItem} onClick={resetAllFilters}>Remove All Filters</li>
            </ul>
            <ul className={classes.filterHeader}>
                {
                    appliedFilters &&
                        Object.keys(appliedFilters).length > 0 ?
                        Object.keys(appliedFilters).map(item => {
                            const filtersAppliedItems = getAppliedFiltersTextPerCategory(appliedFilters, item);
                            return <li className={classes.filterDetails} key={v4()}>
                                <span id={`cvb-exploreAppliedFilters-filterTitle-${item}`} className={classes.filterDetailsTitle}>{item}: </span>
                                <span id={`cvb-exploreAppliedFilters-filterValues-${filtersAppliedItems.text}`} className={classes.filterDetailsValues}>{filtersAppliedItems.text}</span>
                                {
                                    filtersAppliedItems.moreItems > 0 &&
                                    <span className={classes.filterDetailsRemoveIcon}>
                                        <FiltersPopover
                                            title={filtersAppliedItems.moreItems + " More"}
                                            appliedFilters={appliedFilters[item]}
                                            category={item}
                                            onChangeFilterItemState={onChangeFilterItemState}
                                        />
                                    </span>
                                }
                                <span className={classes.filterDetailsRemoveIcon}
                                    id='cvb-exploreAppliedFilters-clearFiltersPerCategory'
                                    onClick={() => { clearAppliedFiltersPerCategory(item) }}>
                                    <img src={ImgClose} alt="Close" />
                                </span>
                            </li>
                        }) :
                        <p id='cvb-exploreAppliedFilters-noFiltersAppliedText'>No filters applied</p>
                }
            </ul>
        </div>

    )
}

export default ExploreAppliedFilters;

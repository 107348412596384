export const IN_OPERATOR = { name: "in", value: "IN" };
export const REG_OPERATORS = [
  IN_OPERATOR,
  { name: "is", value: "=" },
  { name: "is not", value: "!=" },
];
export const OPERATORS = [
  { name: "=", value: "=" },
  { name: ">", value: ">" },
  { name: "<", value: "<" },
  { name: ">=", value: ">=" },
  { name: "<=", value: "<=" },
  { name: "!=", value: "!=" },
];
export const BETWEEN_OPERATOR = { name: "between", value: "between" };
export const SLIDER_OPERATORS = [BETWEEN_OPERATOR, ...OPERATORS];

export const ALL_OPERATORS = [...REG_OPERATORS, ...OPERATORS, ...SLIDER_OPERATORS];
import ServiceAgreement from './ServiceAgreement'
import { connect } from 'react-redux'
import { setServiceAgreementDate,
    setClientName,
    setAgreementYears,
    setSignatoryName,
    setSignatoryTitle,
    setNonCommercialCheck,
    setServicesCheck,
    setAgreementChecks,
   } from '../../../redux/actions/serviceAgreementActions'
const mapStateToProps = state => {
    return {
        serviceAgreementDate: state.serviceAgreement.serviceAgreementDate,
        clientName: state.serviceAgreement.clientName,
        agreementYears: state.serviceAgreement.agreementYears,
        signatoryName: state.serviceAgreement.signatoryName,
        signatoryTitle: state.serviceAgreement.signatoryTitle,
        nonCommercialCheck: state.serviceAgreement.nonCommercialCheck,
        servicesCheck: state.serviceAgreement.servicesCheck,
        agreementChecks: state.serviceAgreement.agreementChecks,
    }
}

const mapDispatchToProps = dispatch => {
 
    return {
        setServiceAgreementDate: async (value) => await dispatch(setServiceAgreementDate(value)),
        setClientName: async (value) => await dispatch(setClientName(value)),
        setAgreementYears: async (value) => await dispatch(setAgreementYears(value)),
        setSignatoryName: async (value) => await dispatch(setSignatoryName(value)),
        setSignatoryTitle: async (value) => await dispatch(setSignatoryTitle(value)),
        setNonCommercialCheck: async (value) => await dispatch(setNonCommercialCheck(value)),
        setServicesCheck: async (value) => await dispatch(setServicesCheck(value)),
        setAgreementChecks: async (value) => await dispatch(setAgreementChecks(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAgreement);
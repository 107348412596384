import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, Typography, Switch } from '@mui/material';

import { DragHandleOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  block: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialog: {
    '& .MuiDialog-paper': {
      padding: 0,
    },
  },
  wrapSecondary: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,

    '&  .MuiIconButton-root': {
      backgroundColor: 'white',
      paddingRight: '0.75rem !important',
      cursor: 'pointer',

      '& svg': {
        width: '1rem',
        height: '1rem',
        color: 'rgba(3, 18, 53, 0.3)',

        '&:hover': {
          color: theme.palette.grey[600],
        },
      },
    },
  },
}));

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  minHeight: 36,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const ColumnItem = (props) => {
  const classes = useStyles();
  const { column, snapshot, provided, innerRef, handleChange, isDragDisabled } =
    props;

  return (
    <Box
      className={classes.block}
      maxWidth="100%"
      {...provided?.draggableProps}
      ref={innerRef}
      key={column.queryVariable}
      style={getItemStyle(
        snapshot?.isDragging,
        provided?.draggableProps?.style
      )}
    >
      <Box className={classes.wrapSecondary}>
        <Tooltip
          title={`Drag and drop ${
            isDragDisabled ? 'unavailable in search' : 'to change order'
          } `}
        >
          <Box
            display="flex"
            alignItems="center"
            {...provided?.dragHandleProps}
            className="MuiIconButton-root"
          >
            <DragHandleOutlined />
          </Box>
        </Tooltip>
        <Typography component="span" variant="subtitle1" sx={{maxWidth: "90%"}}>
          {column.title}
        </Typography>
      </Box>

      <Tooltip title="toggle column" placement="right">
        <Switch
          size="small"
          checked={column.enabled}
          onChange={() => {
            if (handleChange) handleChange(column.queryVariable);
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Tooltip>
    </Box>
  );
};

// ColumnItem.propTypes = {
//   column: PropTypes.shape({
//     title: PropTypes.string.isRequired,
//     queryVariable: PropTypes.string,
//     type: PropTypes.bool,
//     enabled: PropTypes.bool.isRequired,
//   }).isRequired,
//   handleChange: PropTypes.func,
//   // snapshot: PropTypes.any,
//   // innerRef: PropTypes.any,
//   // provided: PropTypes.any,
//   index: PropTypes.number,
// };

export default ColumnItem;

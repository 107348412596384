import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import BCLOGO2X from '../../../assets/images/BRAINCommons-logo@2x.png'
import Classes from './index.module.css'
import Modal from '../modal'
import TermsOfUse from '../terms-of-use'
import PrivacyPolicy from '../privacy-policy/PrivacyPolicy'
import ReportAProblem from '../report-a-problem'

import { ABOUT_BRAIN_COMMONS_LINK,NEWS_AND_ARTICLES_LINK,CONTACT_US_LINK,CITATION_POLICY_LINK,
        BLOG_LINK, 
        FAQ_LINK,
        REPORT_A_PROBLEM_LINK} from '../../../constants/strings'
import CookieNotice from '../cookie-notice'
import PrivacyNotice from '../privacy-notice'
import CookiePolicyDocument from '../cookie-policy-document'

const Footer = props => {
    const [email, setEmail] = useState('');
    const [openTermsOfUseModal, setOpenTermsOfUseModal] = useState(false);
    const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState(false);
    const [openCookieNoticeModal, setOpenCookieNoticeModal] = useState(false);
    const [openPrivacyNoticeModal, setOpenPrivacyNoticeModal] = useState(false);
    const [openCookiePolicyDocumentModal, setOpenCookiePolicyDocumentModal] = useState(false);
    const [openReportAProblem, setOpenReportAProblemModal] = useState(false);

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    }
    const toggleCookieNoticeModal = () => {
        setOpenCookieNoticeModal(false);
    }
    const toggleTermOfUseModal = () => {
        setOpenTermsOfUseModal(false);
    }
    const togglePrivacyPolicyModal = () => {
        setOpenPrivacyPolicyModal(false);
    }
    const togglePrivacyNoticeModal = () =>{
        setOpenPrivacyNoticeModal(false);
    }
    
    const onOpenTermsOfUseModalClick = () => {
        setOpenTermsOfUseModal(true);
    }
    const COOKIE_POLICY_DOCUMENT = {
        modalTitle: "Cookie Policy",
        modalContent: "",
    }
    const toggleReportAProblemModal = () => {
        setOpenReportAProblemModal(false);
    }
    const TERM_OF_USE_MODAL = {
        modalTitle: "Terms Of Use",
        modalContent: "",
        // positiveButtonText: "I Accept",
        // negativeButtonText: "Decline",
        // positiveButtonAction: toggleTermOfUseModal,
        // negativeButtonAction: toggleTermOfUseModal
    }
    const PRIVACY_POLICY_MODAL = {
        modalTitle: "Privacy Policy",
        modalContent: "",
        // positiveButtonText: "I Accept",
        // negativeButtonText: "Decline",
        // positiveButtonAction: togglePrivacyPolicyModal,
        // negativeButtonAction: togglePrivacyPolicyModal
    }
    const REPORT_A_PROBLEM_MODAL = {
        modalTitle: "Report A Problem",
        modalContent: "",
    }
    const COOKIE_NOTICE_MODAL = {
        modalTitle: "Cookie Notice",
        modalContent: "",
    }
    const PRIVACY_NOTICE_MODAL = {
        modalTitle: "Privacy Notice",
        modalContent: "",
    }
    const onFooterLinkClick = (footerLink) =>{
        window.open(footerLink,'_blank')
    }

    const onReportAProblemLinkClick = () =>{
        setOpenReportAProblemModal(true);
    }

    const onCookiePolicyClick = async () =>{
      const origin = window.location.origin;  
      const currentPath = window.location.pathname;
      window.location.href = `${origin}/cookie-details?pathName=${currentPath}`;
    }
    const onCookieNoticeClick = () =>{
        setOpenCookieNoticeModal(true);
    }
    const onOpenPrivacyNoticeModalClick = ()=>{
        setOpenPrivacyNoticeModal(true)
    }
    const onPrivacyPolicyClick = () =>{
        setOpenPrivacyPolicyModal(true);
    }
    const onCookiePolicyDocumentLinkClick = () =>{
        setOpenCookiePolicyDocumentModal(true);
    }
    
    return (
        <div className={Classes.footerContainer}>
            <TermsOfUse
                open={openTermsOfUseModal}
                handleClose={toggleTermOfUseModal}
                dialogProps={TERM_OF_USE_MODAL}
            />
            <CookiePolicyDocument
                open={openCookiePolicyDocumentModal}
                handleClose={()=> setOpenCookiePolicyDocumentModal(false)}
                dialogProps={COOKIE_POLICY_DOCUMENT}
            />
            <CookieNotice
                open={openCookieNoticeModal}
                handleClose={toggleCookieNoticeModal}
                dialogProps={COOKIE_NOTICE_MODAL}
            />
            <PrivacyPolicy
                open={openPrivacyNoticeModal}
                handleClose={togglePrivacyNoticeModal}
                dialogProps={PRIVACY_NOTICE_MODAL} />
            <PrivacyNotice
                open={openPrivacyPolicyModal}
                handleClose={togglePrivacyPolicyModal}
                dialogProps={PRIVACY_POLICY_MODAL} />
            <ReportAProblem
                open={openReportAProblem}
                handleClose={toggleReportAProblemModal}
                dialogProps={REPORT_A_PROBLEM_MODAL} />
            
            <Grid container className={Classes.footerMainContainer}>
                <Grid item xs={3}>
                    <Grid container justifyContent='center' alignItems='center'>
                        <img alt='BRAINcommons logo' className={Classes.bclogoImage} src={BCLOGO2X} />
                    </Grid>
                </Grid>
                <Grid item xs={3} className={Classes.footerColumns}>
                    <p id='cvb-footer-bcHeading' className={Classes.footerHeaders}>BRAINCommons</p>
                    <p id='cvb-footer-aboutBCLink' className={Classes.footerSubHeaders} onClick={() => onFooterLinkClick(ABOUT_BRAIN_COMMONS_LINK)}>About BRAINCommons</p>
                </Grid>
                <Grid item xs={3} className={Classes.footerColumns}>
                    <p className={Classes.footerHeaders}>Stay Updated</p>
                    <p id='cvb-footer-blogLink' className={Classes.footerSubHeaders} onClick={() => onFooterLinkClick(BLOG_LINK)}>Blog</p>
                    <p id='cvb-footer-newsLink' className={Classes.footerSubHeaders} onClick={() => onFooterLinkClick(NEWS_AND_ARTICLES_LINK)}>News & Events</p>
                </Grid>
                <Grid item xs={3} className={Classes.footerColumns}>
                    <p className={Classes.footerHeaders}>Support</p>
                    <p id='cvb-footer-contactUsLink' className={Classes.footerSubHeaders} onClick={() => onFooterLinkClick(FAQ_LINK)}>FAQs</p>
                    <p id='cvb-footer-helpLink' className={Classes.footerSubHeaders} onClick={()=> window.Beacon('toggle')}>Help</p>
                    <p id='cvb-footer-contactUsLink' className={Classes.footerSubHeaders} onClick={() => onFooterLinkClick(CONTACT_US_LINK)}>Contact Us</p>
                    <p id='cvb-footer-contactUsLink' className={Classes.footerSubHeaders} onClick={onReportAProblemLinkClick}>Report A Problem</p>
                </Grid>
            </Grid>
            <div className={Classes.bottomLinkContainers}>
                <p className={Classes.bottomLinks} onClick={onCookiePolicyDocumentLinkClick}>Cookies Policy</p>
                <span className={Classes.verticalLine}></span>
                <p className={Classes.bottomLinks} onClick={onCookieNoticeClick}>Cookies Notice</p>
                <span className={Classes.verticalLine}></span>
                <p className={Classes.bottomLinks} onClick={onCookiePolicyClick}>Cookie Settings</p>
                <span className={Classes.verticalLine}></span>
                <p id='cvb-footer-privacyModalButton' onClick={onOpenPrivacyNoticeModalClick} className={Classes.bottomLinks}>Privacy Notice </p>
                <span className={Classes.verticalLine}></span>
                <p className={Classes.bottomLinks} onClick={onPrivacyPolicyClick}>Privacy Policy</p>
                <span className={Classes.verticalLine}></span>
                <p id='cvb-footer-termsOfUseModalButton' onClick={onOpenTermsOfUseModalClick} className={Classes.bottomLinks}>Terms Of Use</p>
                <span className={Classes.verticalLine}></span>
                <p className={Classes.bottomLinks}>
                    <a href={CITATION_POLICY_LINK} rel="noreferrer" className={Classes.citationLink} target="_blank" >Citation Policy</a>
                </p>
            </div>
        </div>
    );
}


export default Footer;
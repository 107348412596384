import React, { useState, useEffect } from 'react'
import SectionHeader from '../../commons/section-header'
import { navigate } from '@reach/router'
import classes from './index.module.css'
import Modal from '../../commons/modal'
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid'
import SignatureCanvas from 'react-signature-canvas'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import v4 from 'uuid/v4';
import DropDown from '../../commons/dropdown';
import TablePagination from '../../commons/table-pagination';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '../../../assets/icons/search-icon.svg';
import moment from 'moment';
import CommonDatePicker from '../../commons/common-date-picker';
import Paper from '@mui/material/Paper';
import { getUserTransactions, getUserBalance, addUserCredit, updateCreditAmount } from '../../../api/account-api'
import { COUNTRY_LIST, ACCOUNT_MOCK_DATA, TRANSACTION_TYPE_STATUS } from '../../../constants/strings'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import ErrorText from '../../controls/error-text'
import { validateEmailAddress } from '../../../utils/input-validations'
import { isDate } from 'lodash';
import InfoGreyIcon from '../../../assets/icons/InfoIcon.svg';
import Tooltip from '@mui/material/Tooltip'
import withStyles from '@mui/styles/withStyles';
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    startOfYear,
    endOfYear,
    addYears,
    startOfQuarter
} from '../../../utils/date-formatter';
import CalenderIcon from '../../../assets/icons/calender-icon.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { cloneDeep, filter, isEmpty, map, rest } from 'lodash'
import { useLocation } from "@reach/router"
import TransactionTable from '../../controls/transaction-table'
const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    startOfLastFullYearDay: startOfDay(addDays(new Date(), -365)),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    startOfQuarter: startOfQuarter(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1))
};

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#FCF8C8",
        color: "#222222",
        boxShadow: "-1px 1px 106px -61px rgba(74,73,74,1);",
        fontSize: 14,
        fontWeight: "medium",
        marginRight: '3px',
        fontFamily: "Raleway",
        borderRadius: 0,
        padding: 15,
        textAlign: "left"
    },
    arrow: {
        color: "#FCF8C8",
    },
    customWidth: {
        maxWidth: 200,
    },
}))(Tooltip);

const sideBarOptions = () => {
    const customDateObjects = [
        {
            label: "Today",
            range: () => ({
                startDate: defineds.startOfToday,
                endDate: defineds.endOfToday
            })
        },
        {
            label: "Yesterday",
            range: () => ({
                startDate: defineds.startOfYesterday,
                endDate: defineds.endOfYesterday
            })
        },
        {
            label: "Last Week",
            range: () => ({
                startDate: defineds.startOfLastWeek,
                endDate: defineds.endOfLastWeek
            })
        },
        {
            label: "Last Month",
            range: () => ({
                startDate: defineds.startOfLastMonth,
                endDate: defineds.endOfLastMonth
            })
        },
        {
            label: "Month to date",
            range: () => ({
                startDate: defineds.startOfMonth,
                endDate: defineds.endOfToday
            })
        },
        {
            label: "Quarter to date ",
            range: () => ({
                startDate: defineds.startOfQuarter,
                endDate: defineds.endOfToday
            })
        },
        {
            label: "Year to date",
            range: () => ({
                startDate: defineds.startOfYear,
                endDate: defineds.endOfToday
            })
        }
    ];

    return customDateObjects;
};


const sectionHeaderProps = {
    title: "My Account",
    defaultActionButtons: []
}

const ROWS_PER_PAGE = 10;

const MyAccount = (props) => {
    const [OpenAddCreditsModal, setOpenAddCreditsModal] = useState(false);
    const [openAdjustCreditsModal, setOpenAdjustCreditsModal] = useState(false);
    const [adjustCreditAmount, setAdjustCreditAmount] = useState('');
    const [adjustReasonText, setAdjustReasonText] = useState('');
    const [adjustCreditDate, setAdjustCreditDate] = useState(null)
    const [amountValue, setAmountValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [name, setNameValue] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [municipality, setMunicipality] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState('');
    const [selectionDateRage, setSelectedDateRange] = useState({
        startDate: null,
        endDate: new Date(''),
        key: 'selection'
    });
    const [openCalender, setOpenCalender] = useState(false);
    const [selectedCalenderInput, setSelectedCalenderInput] = useState('');
    const [searchText, setSearchText] = useState('');
    const [accountTableData, setAccountTableData] = useState([]);
    const [sendRequestButtonClick, setSendRequestButtonClick] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [transactionData, setTransactionData] = useState([]);
    const [userAccountBalance, setUserAccountBalance] = useState('');
    const [page, setPage] = useState(0);
    const sideBar = sideBarOptions();
    const userSub = props?.currentUserDetails?.attributes?.sub
    const isStreetAddressValid = () => !!streetAddress;
    const isCityValid = () => !!city;
    const isStateValid = () => !!state;
    const isZipCodeValid = () => !!zipCode;
    const isCountryValid = () => !!country;
    const isNameValid = () => !!name;
    const isAmountValid = () => !!Number(amountValue);
    const isAdjustAmountValid = () => !!Number(adjustCreditAmount);
    const isAdjustReasonTextValid = () => !!adjustReasonText;

    const { search } = useLocation();


    const getQueryParam = (param) => {
        try {
          const urlParams = new URLSearchParams(search);
          return urlParams?.get(param)
        }
        catch (error) {
          return undefined;
        }
      }
    
      const queryUserName = getQueryParam('username');


    const staticRanges = [
        ...createStaticRanges(sideBar)
    ];
    const getCurrentUserAccountBalance = async () => {
        try {
            if (!isEmpty(userSub)  && !queryUserName) {
                const res = await getUserBalance(userSub);
                setUserAccountBalance(res?.account_balance)
            }else{
                if(!isEmpty(queryUserName)){
                    const res = await getUserBalance(queryUserName);
                    setUserAccountBalance(res?.account_balance)
                }
            }
        } catch (e) {
            setUserAccountBalance('-')
            console.log(e);
        }
    }

    const getAllTransactionsData = async () => {
       //setTransactionData(ACCOUNT_MOCK_DATA);
        try {
            if (!isEmpty(userSub) && !queryUserName ) {
                //API CALL 
                const res = await getUserTransactions(userSub);
                setTransactionData(res);
            }else{
                if(!isEmpty(queryUserName)){
                    const res = await getUserTransactions(queryUserName);
                    setTransactionData(res);
                }
            }
        } catch (e) {
            setTransactionData([]);
            console.log(e);
        }
    
    }
    
    useEffect(() => {
        //API CALL TO GET ALL TRANSACTIONS
        getAllTransactionsData();
    }, [userSub, queryUserName])

    useEffect(() => {
        getCurrentUserAccountBalance();
    }, [ userSub, queryUserName])

    useEffect(() => {
        const calenderInputLabel = document.querySelector('.rdrStaticRangeSelected')?.firstChild?.textContent || (isDate(selectionDateRage?.startDate) && isDate(selectionDateRage?.endDate)) ? `${moment(selectionDateRage?.startDate).format('MM-DD-YYYY')} - ${moment(selectionDateRage?.endDate).format('MM-DD-YYYY')}` : 'Select Date Range';
        setSelectedCalenderInput(calenderInputLabel);

    }, [selectionDateRage])

    useEffect(() => {
        const { transactions } = transactionData;
        let tableData = [];
        tableData = filter(transactions, (data) => ((data?.transaction_id || "") + (data?.transaction_type_id || '')).toLowerCase().includes(searchText.toLowerCase()))
        setAccountTableData(tableData);
    }, [searchText, transactionData])

    useEffect(() => {
        const { transactions } = transactionData;
        if(!isEmpty(selectedFilter)){
                if(selectedFilter === 'Show All'){  
                    setAccountTableData(transactions);
                }else{
                    let tableData = [];
                    setPage(0);
                    tableData = filter(transactions, (data)=> data?.transaction_type === selectedFilter);
                    setAccountTableData(tableData);
                }
        }else{
            setAccountTableData(transactions);
        }
      
    }, [selectedFilter])

    //selected range
    const onResetDateRangeSelection = () => {
        const { transactions } = transactionData;
        setSelectedDateRange({
            startDate: startOfMonth(new Date()),
            endDate: endOfDay(new Date()),
            key: 'selection'
        })
        setAccountTableData(transactions);
    }
    const onCalenderCancelButtonClick = () => {
        setOpenCalender(false);
    }
    //makes API call
    const onApplyDateRangeButtonClick = async () => {
        if(isDate(selectionDateRage.startDate) && isDate(selectionDateRage.endDate)){
            const { transactions } = transactionData;
            let filteredData = [];
            filteredData = filter(transactions, (data)=> moment(data?.date).isBetween(moment(selectionDateRage.startDate), moment(selectionDateRage.endDate)))
            setAccountTableData(filteredData);
            setOpenCalender(false)
        }
    }
    const onCalenderSelect = (data) => {
        setSelectedDateRange(data.selection);
    }
    const onAddCreditsButtonClick = () => {
        setOpenAddCreditsModal(true);
        setSendRequestButtonClick(false);
    }
    const resetAddCreditsField = () =>{
        setAmountValue('');
        setNameValue('');
        setEmailValue('');
        setCountry('');
        setStreetAddress('');
        setZipCode('');
        setCity('');
        setState('')
    }
    const onSendRequestClick = async () => {
        try {//API CALL TO SEND REQUEST
            setSendRequestButtonClick(true);
            if (isStreetAddressValid() && isCityValid() && isStateValid() && isCountryValid() && isZipCodeValid() && isNameValid() && isAmountValid()
                && validateEmailAddress(emailValue)
            ) {
                const body = {
                    "amount": amountValue,
                    "entity_id": queryUserName ? queryUserName : userSub,
                    "invoice_type": 'CREDIT',
                    "entity_type": "USER_ACCOUNT",
                    "billing_information": {
                        "name": name,
                        "email": emailValue,
                        "zip_code": zipCode,
                        "street_address": streetAddress,
                        "country": country,
                        "state": state,
                        "city": city,
                    }
                }
                //API CALL
                const res = await addUserCredit(body);
                if (res) {
                    props.updateSnackBar(res?.message || "Request sent successfully", 'success')
                    resetAddCreditsField();
                    closeAddCreditModal();
                }
            }
        } catch (e) {
            props.updateSnackBar(e?.response?.data?.message || "Error is sending requesting", 'error')
            console.log(e)
        }
    }
    const resetAdjustCreditForm = ()=>{
        setAdjustCreditAmount('');
        setAdjustCreditDate(null);
        setAdjustReasonText('');
    }
    const onSendAdjustRequestClick = async () =>{
        try{
            setSendRequestButtonClick(true);
            if (isAdjustAmountValid() && isAdjustReasonTextValid()){
                const body = {
                        "amount": adjustCreditAmount,
                        "entity_id": queryUserName,
                        "invoice_type": "ADJUSTMENT",
                        "entity_type": "USER_ACCOUNT",
                        "comments": adjustReasonText
                    }
                const res = await updateCreditAmount(body);
                if(res){
                    props.updateSnackBar(res?.message || "Request sent successfully", 'success')
                    resetAdjustCreditForm();
                    closeAdjustCreditModal();
                    getAllTransactionsData();
                    getCurrentUserAccountBalance();
                }else{
                    props.updateSnackBar(res?.message || "Error is sending adjust credit request", 'error')

                }
            }
        }catch(e){
            props.updateSnackBar(e?.response?.data?.message || "Error is sending adjust credit request", 'error')
            console.log(e)
        }
    }
    const onDateChange = (date) =>{
        setAdjustCreditDate(date)
    }
    const closeAddCreditModal = () => {
        setOpenAddCreditsModal(false);
        setSendRequestButtonClick(false);
    }
    const onAmountValueChange = (e) => {
        setAmountValue(e.target.value);
    }
    const onEmailChange = e => {
        setEmailValue(e.target.value);
    }
    const onNameChange = e => {
        setNameValue(e.target.value);
    }
    const onSearchTextChange = text => {
        setSearchText(text);
    }
    const setTablePage = (number) => {
        setPage(number);
    }
    const closeAdjustCreditModal = ()=>{
        setSendRequestButtonClick(false);
        setOpenAdjustCreditsModal(false);
    }
    const onAdjustAmountValueChange = (e)=>{
        setAdjustCreditAmount(e.target.value);
    }
    const onAdjustReasonChange = (e)=>{
        setAdjustReasonText(e.target.value);
    }
    const onAdjustCreditButtonClick = () =>{
        setOpenAdjustCreditsModal(true);
    }
    const filterTransactionDataOnDate = (startDate, endDate) =>{
        const filteredTransactionData = filter(transactionData?.transactions, (data)=> moment(data?.date).isBetween(moment(startDate), moment(endDate)));
       return filteredTransactionData;
    }
    const maxCount = accountTableData?.length ? Math.ceil(accountTableData.length / ROWS_PER_PAGE) : 0;

    const ADD_CREDITS_MODAL = {
        modalTitle: 'Request Invoice to add credit to account balance',
        modalContent: "",
        positiveButtonText: 'Send Request',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSendRequestClick,
        negativeButtonAction: closeAddCreditModal
    }
    const ADJUST_CREDITS_MODAL = {
        modalTitle: 'Adjust Account Balance',
        modalContent: "",
        positiveButtonText: 'Update Account Balance',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSendAdjustRequestClick,
        negativeButtonAction: closeAdjustCreditModal
    }

    const displayedInvoices = []
    return (
        <React.Fragment>
             <Modal
              disableTitleDivider
                open={openAdjustCreditsModal}
                handleClose={closeAdjustCreditModal}
                dialogProps={ADJUST_CREDITS_MODAL} >
                <Grid item xs={6} className={classes.adjustModalField}>
                    <label className={classes.adjustModalField}>Amount<sup> *</sup></label>
                    <OutlinedInput
                        fullWidth
                        value={adjustCreditAmount}
                        onChange={onAdjustAmountValueChange}
                        placeholder='Enter amount'
                        id='amount'
                        type='number'
                        startAdornment={(
                            <InputAdornment position="start">
                                $
                            </InputAdornment>
                        )}
                    />
                    {
                        sendRequestButtonClick && !isAdjustAmountValid() &&
                        <ErrorText errorMessage="Enter valid amount" />
                    }
                </Grid>
                <Grid item xs={12}>
                        <label htmlFor='adjustmentReason' className={classes.adjustModalField}>Reason Of Adjustment<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={adjustReasonText}
                            onChange={onAdjustReasonChange}
                            placeholder='Enter reason'
                            name='adjustmentReason'
                            id='adjustmentReason'
                        />
                        {
                            sendRequestButtonClick && !isAdjustReasonTextValid() &&
                            <ErrorText errorMessage="Enter reason" />
                        }
                    </Grid>
            </Modal>
            <Modal
                open={OpenAddCreditsModal}
                handleClose={closeAddCreditModal}
                dialogProps={ADD_CREDITS_MODAL} >
                <Grid item xs={6} >
                    <label>Amount<sup> *</sup></label>
                    <OutlinedInput
                        fullWidth
                        value={amountValue}
                        onChange={onAmountValueChange}
                        placeholder='Enter amount'
                        id='amount'
                        type='number'
                        startAdornment={(
                            <InputAdornment position="start">
                                $
                            </InputAdornment>
                        )}
                    />
                    {
                        sendRequestButtonClick && !isAmountValid() &&
                        <ErrorText errorMessage="Enter valid amount" />
                    }
                </Grid>
                <h2 className={classes.billingInfoHeader}>Billing Information</h2>
                <Grid container xs={8} spacing={4}>
                    <Grid item xs={6}  >
                        <label>Name<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            onChange={onNameChange}
                            value={name}
                            placeholder='Username'
                            id='username'
                        />
                        {
                            sendRequestButtonClick && !isNameValid() &&
                            <ErrorText errorMessage="Enter valid username" />
                        }
                    </Grid>
                    <Grid item xs={6} >
                        <label>Email<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            onChange={onEmailChange}
                            value={emailValue}
                            placeholder='Email'
                            id='email'
                        />
                        {
                            sendRequestButtonClick && !validateEmailAddress(emailValue) &&
                            <ErrorText errorMessage="Enter valid email address" />
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={4} xs={8} className={classes.instituteDetailsContainer}>
                    <Grid item xs={6}>
                        <label htmlFor='country'>Country<sup> *</sup></label>
                        <DropDown
                            id='country'
                            values={COUNTRY_LIST}
                            handleFilterStateChange={setCountry}
                            selectedFilter={country}
                            placeholder={'Select Country'}
                            searchable
                            isClearable
                        />
                        {
                            sendRequestButtonClick && !isCountryValid() &&
                            <ErrorText errorMessage="Select country" />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <label htmlFor='streetAddress'>Street Address<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={streetAddress}
                            onChange={e => { setStreetAddress(e.target.value); }}
                            placeholder='Enter street address'
                            name='streetAddress'
                            id='streetAddress'
                        />
                        {
                            sendRequestButtonClick && !isStreetAddressValid() &&
                            <ErrorText errorMessage="Enter valid street address" />
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <label htmlFor='city'>City<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={city}
                            onChange={e => { setCity(e.target.value); }}
                            placeholder='Enter city'
                            name='city'
                            id='city'
                        />
                        {
                            sendRequestButtonClick && !isCityValid() &&
                            <ErrorText errorMessage="Enter valid city" />
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <label htmlFor='state'>State <sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={state}
                            onChange={e => { setState(e.target.value); }}
                            placeholder='Enter state'
                            name='state'
                            id='state'
                        />
                        {
                            sendRequestButtonClick && !isStateValid() &&
                            <ErrorText errorMessage="Enter valid state" />
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <label htmlFor='zipCode'>Zip Code<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            value={zipCode}
                            onChange={e => { setZipCode(e.target.value); }}
                            placeholder='Enter zip code'
                            name='zipCode'
                            id='zip'
                        />
                        {
                            sendRequestButtonClick && !isZipCodeValid() &&
                            <ErrorText errorMessage="Enter valid zip code" />
                        }
                    </Grid>

                </Grid>
            </Modal>
            <SectionHeader
                id='cvb-cvb-myAccount-sectionHeader'
                open={props.open}
                sectionHeaderProps={sectionHeaderProps}
                onBackButtonClick={() => navigate('/my-profile')}
            />



            <div className={classes.container}>
                <div className={classes.cardContainer}>
                    <div className={classes.cardItem}>
                        <div className={classes.cardContent}>
                            <h4>My Account Balance</h4>
                            <p>You will need to have credit in your account in order to be able to download Project files.</p>
                            <h5>$ {userAccountBalance}</h5>
                            <div className={classes.cardItemActions}>
                                <button className={`solid-button medium-size-button ${classes.addCreditButton}`} onClick={onAddCreditsButtonClick}>Add Credits</button>
                                {
                                    queryUserName &&
                                    <button className={`solid-button medium-size-button ${classes.addCreditButton}`} onClick={onAdjustCreditButtonClick}>Adjust Credits</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.transactionTableContainer}>
                    <Grid item lg={12} md={12} sm={12} className={classes.contentBlock}>
                        <div className={classes.sectionLeftContainer}>
                        </div>
                        <div className={classes.contentMainContentArea}>
                           { !isEmpty(transactionData) && <TransactionTable 
                            transactionData={transactionData}
                            filterTransactionDataOnDate={filterTransactionDataOnDate}
                            queryUserName={queryUserName}
                            />
                            }
                        </div>
                    </Grid>
                </div>
            </div>
        </React.Fragment>

    )
}

export default MyAccount;
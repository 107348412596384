import {
    UPDATE_EXPLORE_DATA_FILTERS,
    UPDATE_EXPLORE_DATA,
    UPDATE_EXPLORE_HOME_DATA,
    UPDATE_CUSTOMIZE_OPTIONS,
    UPDATE_EXPLORE_TOTAL_COUNT,
    UPDATE_EXCLUDE_FROM_EXPLORATION,
    UPDATE_EXPLORE_FILES_DATA,
    UPDATE_EXPLORE_DATA_LOADER,
    UPDATE_PROJECTS_COUNT,
    UPDATE_ACCESSIBLE_EXPLORE_TOTAL_COUNT,
    UPDATE_EXPLORE_DATA_FILTER_SELECTION,
    UPDATE_EXPLORE_SUN_BURST_GRAPH_DATA,
    UPDATE_EXPLORE_AGE_AT_ENROLLMENT_GRAPH_DATA,
    UPDATE_UNSTRUCTURED_FILES_GRAPH_DATA,
} from '../action-types'

const initialState = {
    exploreDataFilters: null,
    exploreData: null,
    exploreHomeData: null,
    customizeItems: null,
    totalCount: -1,
    excludeFromExploration: [],
    exploreDataFiles: [],
    exploreDataLoader: false,
    filterBasedProjectCount: 0,
    accessibleCaseCount: -1,
    appliedFilters: {},
    sunBurstExploreData: [],
    ageAtEnrollmentGraphData: null,
    unstructuredFiltersData: [],
}

const exploreDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_EXPLORE_AGE_AT_ENROLLMENT_GRAPH_DATA: {
            return {
                ...state,
                ageAtEnrollmentGraphData: action.payload
            }
        }
        case UPDATE_UNSTRUCTURED_FILES_GRAPH_DATA: {
            return {
                ...state,
                unstructuredFiltersData: action.payload
            }
        }
        case UPDATE_EXPLORE_SUN_BURST_GRAPH_DATA: {
            return {
                ...state,
                sunBurstExploreData: action.payload
            }
        }
        case UPDATE_EXPLORE_DATA_FILTERS: {
            return {
                ...state,
                exploreDataFilters: action.payload
            }
        }
        case UPDATE_EXPLORE_DATA: {
            return {
                ...state,
                exploreData: action.payload
            }
        }
        case UPDATE_EXPLORE_HOME_DATA: {
            return {
                ...state,
                exploreHomeData: action.payload
            }
        }

        case UPDATE_CUSTOMIZE_OPTIONS: {
            return {
                ...state,
                customizeItems: action.payload
            }
        }

        case UPDATE_EXPLORE_TOTAL_COUNT: {
            return {
                ...state,
                totalCount: action.payload
            }
        }

        case UPDATE_EXPLORE_DATA_LOADER: {
            return {
                ...state,
                exploreDataLoader: action.payload
            }
        }

        case UPDATE_EXCLUDE_FROM_EXPLORATION: {
            return {
                ...state,
                excludeFromExploration: action.payload
            }
        }
        case UPDATE_EXPLORE_FILES_DATA: {
            return {
                ...state,
                exploreDataFiles: action.payload
            }
        }
        case UPDATE_PROJECTS_COUNT: {
            return {
                ...state,
                filterBasedProjectCount: action.payload
            }
        }
        case UPDATE_ACCESSIBLE_EXPLORE_TOTAL_COUNT: {
            return {
                ...state,
                accessibleCaseCount: action.payload
            }
        }
        case UPDATE_EXPLORE_DATA_FILTER_SELECTION: {
            return {
                ...state,
                appliedFilters: action.payload
            }
        }
        default: return state;
    }
}

export default exploreDataReducer;
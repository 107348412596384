import React, { useEffect } from 'react'
import { isEmpty } from 'lodash';

import Login from '../components/pages/login'
import CustomLoader from '../components/commons/custom-loader'
import { updateGAEventsPageTitleOnPageChange } from '../api/generic-api';
import SessionTimeoutCheckerHook from '../utils/session-timeout-checker.hook'


const ProtectedRoute = (props) => {
    let { component: Component, isUserLoggedIn } = props;
    SessionTimeoutCheckerHook();
    
    useEffect(() => {
        
        let pageTitle = '';
        const pagePath = props?.path || '';
        switch (pagePath) {
            case '/onboarding/*': pageTitle = 'onboarding'; break;
            case '/tell-us-more/:id': pageTitle = 'community_onboarding'; break;
            default: break;
        }
        if (!isEmpty(pageTitle)) {
            updateGAEventsPageTitleOnPageChange(pageTitle);
        } 
    }, [props?.uri || ''])
    return isUserLoggedIn === undefined ? <CustomLoader /> :
        (isUserLoggedIn ? <Component {...props} /> : <Login {...props} />);
}

export default ProtectedRoute;
import React, { useState, useEffect } from 'react'
import ImgBackWard from '../../../assets/icons/backwards.svg'
import ImgForward from '../../../assets/icons/forward.svg'
import ImgFastForward from '../../../assets/icons/fast_farward.svg'
import ImgReverse from '../../../assets/icons/reverse_forward.svg'
import ImgGreyBack from '../../../assets/icons/backGrey.svg'
import ImgBackWardGrey from '../../../assets/icons/grey_first.svg'
import ImgForwardGrey from '../../../assets/icons/grey_forward.svg'
import ImgFastForwardGrey from '../../../assets/icons/grey_last.svg'
import Grid from '@mui/material/Grid'
import classes from './index.module.css'
const TablePagination = (props) => {
    const {
        maxCount,
        page,
        setPage,
        defaultPageNumber = 0
    } = props;

    const [userPageInputValue, setUserPageInputValue] = useState(1);
    const setInputValue = (number) => {
        setUserPageInputValue(number);
    }
    const setPageNumber = (number) => {
        setPage(number);
    }
    const onPageInputChange = (e) => {
        const value = e.target.value;
        if (value.length) {
            const numberCOnv = Number(value);
            if (Number(value) > maxCount) {
                setUserPageInputValue(maxCount);
            }
            setUserPageInputValue(numberCOnv);
        }
        else {
            setUserPageInputValue('');
        }
    }
    const onInputBlur = () => {
        if (`${userPageInputValue}`.length) {
            if (Number(userPageInputValue) > 0) {
                if (Number(userPageInputValue) > maxCount) {
                    setPage(maxCount - 1);
                    setUserPageInputValue(maxCount);
                } else {
                    setPage(Number(userPageInputValue) - 1);
                }
            } else {
                setUserPageInputValue(1);
                setPage(0)
            }
        } else {
            setPage(0);
            setUserPageInputValue(1);
        }
    }
    const onPageInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (`${userPageInputValue}`.length) {
                if (Number(userPageInputValue) > 0) {
                    if (Number(userPageInputValue) > maxCount) {
                        setPage(maxCount - 1);
                        setUserPageInputValue(maxCount);
                    } else {
                        setPage(Number(userPageInputValue) - 1);
                    }
                } else {
                    setUserPageInputValue(1);
                    setPage(0)
                }
            } else {
                setPage(0);
                setUserPageInputValue(1);
            }
        }
    }
    const handleNextClick = () => {
        if (page < (maxCount - 1)) {
            setPage(page + 1);
            setUserPageInputValue(page + 2);
        }
    }

    const handlePreviousClick = () => {
        if (page >= 1) {
            setPage(page - 1);
            setUserPageInputValue(page);
        }
    }

    useEffect(() => {
        let sumBit = defaultPageNumber === 0 ? 1 : 0
        if ((page + sumBit) !== userPageInputValue) {
            setUserPageInputValue(page + sumBit)
        }
    }, [page])

    return (
        <Grid>
            <ul>
                <li id='cvb-TablePagination-BackWardButton' onClick={() => { setPageNumber(0); setInputValue(1) }}>
                    {
                        page == 0 ?
                            <img src={ImgBackWardGrey} alt="back" /> :
                            <img src={ImgFastForward} alt="back" />
                    }
                </li>
                <li id='cvb-TablePagination-BackButton' className={classes.paginationBackward} onClick={handlePreviousClick}>
                    {
                        page == 0 ?
                            <img src={ImgGreyBack} alt="back" /> :
                            <img src={ImgBackWard} alt="back" />
                    }
                </li>
                <input
                    min='1' max={maxCount}
                    onKeyPress={onPageInputKeyPress}
                    value={userPageInputValue} type='number'
                    onChange={onPageInputChange}
                    className={classes.numberInput}
                    onBlur={onInputBlur}
                    id='cvb-TablePagination-PageNumberInput'
                />
                <li id='cvb-TablePagination-PageNumberText'
                    className={classes.paginationText}>&nbsp;&nbsp;of </li>
                <li id='cvb-TablePagination-PageNumberTotalPages' className={classes.paginationTotalNumber}>&nbsp;&nbsp;{maxCount}</li>
                <li id='cvb-TablePagination-NextButton'>
                    {page + 1 === maxCount ?
                        <img src={ImgForwardGrey} alt="back" /> :
                        <img src={ImgForward} onClick={handleNextClick} alt="back" />}
                </li>
                <li id='cvb-TablePagination-ForwardButton' className={classes.paginationBackward} onClick={() => { setPageNumber(maxCount - 1); setInputValue(maxCount) }}>
                    {page + 1 === maxCount ?
                        <img src={ImgFastForwardGrey} alt="back" /> :
                        <img src={ImgReverse} onClick={handleNextClick} alt="back" />}
                </li>
            </ul>
        </Grid>
    )
}

TablePagination.defaultProps = {
    maxCount: 0,
    setPage: () => { },
    page: 0,
}
export default TablePagination
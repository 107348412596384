import React, { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ImgErrorAlert from '../../../assets/icons/error_icon.svg'
import ImgSuccessAlert from '../../../assets/icons/success_icon.svg'
import ImgCloseIcon from '../../../assets/icons/close-s-dark.svg'
import classes from './index.module.css'

const SnackBarToast = props => {
    const [vertical, setVertical] = React.useState('top')
    const [horizontal, setHorizontal] = React.useState('right')

    const handleClose = () => { props.updateSnackBar("CLOSE") };
    let timeoutWatch = null
    useEffect(() => {
        if(timeoutWatch){
            clearTimeout(timeoutWatch)
        }
        timeoutWatch = setTimeout(()=>{
            handleClose();
            setTimeout(() => {
                setVertical('top')
                setHorizontal('right')
            }, 500)
        },6000)

        if(props.placement && Object.keys(props?.placement).length > 0){
            setVertical(props.placement.vertical)
            setHorizontal(props.placement.horizontal)
        }
        
        return () => clearTimeout(timeoutWatch)
    }, [props.message])

    if (!props.type) return null;
    return (
        <Snackbar
            ContentProps={{
                classes: {
                    root: props.type?.toLowerCase() === "error" ? classes.redBanner : classes.greenBanner
                }
            }}
            anchorOrigin={{ vertical, horizontal }}
            open={props.isOpen}
            message={<span className={classes.alertImg}>
                {props.type?.toLowerCase() === "error" ? <img src={ImgErrorAlert} alt="error-icon" /> :
                    <img src={ImgSuccessAlert} alt="success-icon" />} {props.message}</span>}
            autoHideDuration={60000}
            action={
                <a onClick={handleClose}>
                    <img src={ImgCloseIcon} alt="Close icon" />
                </a>
            }
            maxSnack={3}
        >
        </Snackbar>
    )
}

export default SnackBarToast;
import MyUploads from './MyUploads'
import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        userDetails: state.user.userDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyUploads);
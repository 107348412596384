import AnalyzeData from './AnalyzeData'
import { connect } from 'react-redux'
import { saveCohortWithoutID, saveCohortToDBApi } from '../../../api/analysis-api'

import {
    addNewAnalysis, setSelectedAnalysis,
    removeAnalysis, saveAnalysisCohort
} from '../../../redux/actions/analysisDataActions'
import { updateSnackBar } from '../../../redux/actions/globalActions'

const mapStateToProps = state => {
    return {
        analysisAttributes: state.analyzeData.analysisAttributes,
        selectedAnalysis: state.analyzeData.selectedAnalysis,
        analysisCounter: state.analyzeData.analysisCounter,
        exploreDataFilters: state.exploreData.exploreDataFilters,
        exploreData: state.exploreData.exploreData,
        excludeFromExploration: state.exploreData.excludeFromExploration,
        totalCount: state.exploreData.totalCount,
        customizeItems: state.exploreData.customizeItems,
        appliedFilters: state.exploreData.appliedFilters,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        addNewAnalysis: (value) => dispatch(addNewAnalysis(value)),
        setSelectedAnalysis: (value) => dispatch(setSelectedAnalysis(value)),
        removeAnalysis: (value) => dispatch(removeAnalysis(value)),
        saveAnalysisCohort: (value) => dispatch(saveAnalysisCohort(value)),
        saveCohortWithoutID: async (body) => await saveCohortWithoutID(body),
        saveCohortToDBApi: async (body) => await saveCohortToDBApi(body),
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AnalyzeData)
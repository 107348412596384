/**
 *  External Imports
 */
import { connect } from "react-redux";

/**
 *  Internal Imports
 */
import {
    setConstellationTooltipAction,
    setConstellationLabelNodeHoverAction,
    updateConstellationLabelNodeAction,
    updateConstellationGraphReadyAction,
    resetNodeParamsAction
} from "../../../../redux/actions/communityActions";
import CommunityConstellationGraph from "./CommunityConstellationGraph";

const mapStateToProps = ({ community: { constellationTooltipParams, graphLabelsMask } }) => ({
    constellationTooltipParams, graphLabelsMask
});

const mapDispatchToProps = (dispatch) => ({
    setTooltipParams: (tooltipParams) => dispatch(setConstellationTooltipAction(tooltipParams)),
    setLabelNodeParams: (labelParams) => dispatch(setConstellationLabelNodeHoverAction(labelParams)),
    updateLabelNodeParams: (labelParams) => dispatch(updateConstellationLabelNodeAction(labelParams)),
    updateConstellationGraphReady: (isReady) => dispatch(updateConstellationGraphReadyAction(isReady)),
    resetNodeParams: () => dispatch(resetNodeParamsAction())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityConstellationGraph);

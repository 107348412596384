import React, { useState, useEffect } from 'react'
import Classes from './index.module.css';
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import map from 'lodash/map'
import CustomLoader from '../../commons/custom-loader'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip'
import moment from 'moment'
import MuiPopover from '@mui/material/Popover';
import withStyles from '@mui/styles/withStyles';
import ImageVerticalIcon from '../../../assets/icons/MoreOptionsVertical.S-dark.svg';
import { sendGAEvents } from '../../../api/generic-api';
import Modal from '../../commons/modal'
import { navigate } from '@reach/router';
import get from 'lodash/get'
import { deleteCohort } from '../../../api/analysis-api'
import { exportCohortToWorkspace } from '../../../api/saved-search-api';
import DropDown from '../../commons/dropdown';
import isEmpty from 'lodash/isEmpty'
import ErrorText from '../error-text';
import { getAllWorkspaces } from '../../../api/workspace-api/workspace-api'
import { getUserCohorts } from '../../../service/CohortsService';

const Popover = withStyles(theme => ({
    root: {
        overflowY: 'scroll',
    },
    paper: {
        minWidth: 192,
        right: 'auto !important',
        marginTop: '32px !important',
    }
}))(MuiPopover);
const MyCohorts = props => {

    const [ workspaceValues, setWorkspaceValues ] = useState([]);
    const [openShareNotebookCopyModal, setOpenShareNotebookCopyModal] = useState(false);
    const [selectedWorkspace, setSelectedWorkspace] = useState('');
    const [loading, setLoading] = useState(false);
    const [exportToWorkspace, setExportToWorkspace] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCohort, setSelectedCohort] = useState('');
    const [filteredCohorts, setFilteredCohorts] = useState('');

    const openPopover = Boolean(anchorEl);

    const handleDownArrowClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedCohort(row)
    }

    const handleClosePopover = () => {
        setAnchorEl(null);
    }

    const onWorkspaceOptionChange = (value) => {
        setExportToWorkspace(false)
        setSelectedWorkspace(value);
    }

    const fetchWorkspaces = async () => {
        try {
            let workspacesIds = [];
            if (Array.isArray(props.allWorkspaces) && props.allWorkspaces?.length) {
                workspacesIds = map(props.allWorkspaces, (workspace) => workspace['workspace_id']);
            } else {
                const res = await getAllWorkspaces();
                workspacesIds = map(res, (workspace) => workspace['workspace_id']);
            }

            if (workspacesIds?.length) {
                setWorkspaceValues(workspacesIds);
                if (workspacesIds?.length === 1) {
                    onWorkspaceOptionChange(workspacesIds[0]);
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchWorkspaces()
    }, [props.allWorkspaces])


    const onAnalyzeClick = (cohort) => {
        try {
            const cohortName = getCohortName(cohort);
            sendGAEvents('analyze_cohort', 'analyze_cohort', 'selected analyze cohort option', { 'cohort_name': cohortName });
            const analyzeAttributes = get(cohort, 'allAnalysisData', []);
            const firstAnalysis = get(analyzeAttributes, '[0]', '');
            const cohortId = get(cohort, 'uuid', '');
            props.onAnalyzeCohort(analyzeAttributes);
            props.setSelectedAnalysis(firstAnalysis);
            navigate(`/explore/data/graphical-data/${cohortId}`);
        } catch (error) {
            console.log('error on analyze click');
        }
    }

    const getCohortName = (cohort) => {
        if (cohort?.cohort_name?.length > 20) {
            return cohort?.cohort_name.toString().substring(0, 20) + "..."
        }
        return cohort?.cohort_name || "Cohort"
    }


    const handleModifyData = () => {
        onAnalyzeClick(selectedCohort);
    }

    const handleRemoveCohort = async ({uuid, cohortName}) => {
        try {
            const cohortName = getCohortName(selectedCohort);
            sendGAEvents('remove_cohort', 'remove_cohort', 'selected remove cohort option', { 'cohort_name': cohortName });
            await deleteCohort(uuid)
            props.updateSnackBar("Successfully deleted", "Success")
            handleClosePopover()
            await props.refreshUserCohorts()
            await getFilteredData()
            props.setPageNumber(null)
            props.setPageNumber(1)
        }
        catch (error) {
            props.updateSnackBar("Unable to delete the cohort", "Error")

        }
    }


    const recentlyAnalysed = (cohort) => {
        const lastAccessedAt = moment(get(cohort, 'last_accessed', ''));
        const weekAgo = moment(Date.now()).subtract(7, 'd');
        if (weekAgo < lastAccessedAt) {
            return true;
        }
    }

    const closeShareNotebookCopyModal = () => {
        setOpenShareNotebookCopyModal(false);
    }

    const onShareNotebookCopyConfirm = async () => {
        try {
            const cohortId = get(selectedCohort, 'uuid', '');
            if (selectedWorkspace) {
                setLoading(true);
                const body = {
                    "cohort_id": cohortId,
                    "workspace_id": selectedWorkspace
                };
                const res = await exportCohortToWorkspace(body);
                props.updateSnackBar(`Successfully Exported Cohort to Workspace`, "Success");
                closeShareNotebookCopyModal();
                setLoading(false);

            } else {
                setExportToWorkspace(true);
            }

        } catch (error) {
            props.updateSnackBar(`Failed to Export Cohort to Workspace`, "Error");
            closeShareNotebookCopyModal();
            setLoading(false);

        }
    }

    const SHARE_NOTEBOOK_COPY_MODAL_PROPS = {
        modalTitle: 'Export To Workspace',
        positiveButtonText: 'Export To Workspace',
        negativeButtonText: "Cancel",
        positiveButtonAction: onShareNotebookCopyConfirm,
        negativeButtonAction: closeShareNotebookCopyModal,
        popupButtonDisable: loading
    }

    const openModal = () => {
        const cohortName = getCohortName(selectedCohort);
        sendGAEvents('export_cohort', 'export_cohort', 'selected export cohort option', { 'cohort_name': cohortName });
        setOpenShareNotebookCopyModal(true);
    }



    const isRecentlyAnalysed = props?.index === 0 || props?.index === 1 || props?.index === 2;


    useEffect(() => {
        if (props.userCohorts?.length) {
            for (let cohort of props.userCohorts) {
                if (recentlyAnalysed(cohort)) {
                    cohort['recentlyAnalysed'] = true
                }
            }
        }
    }, [props.userCohorts])

    const getFilteredData = async () => {
        let updCohorts = []
        updCohorts = await getUserCohorts();

        const start = props.pageNumber !== -1 ? (props.pageNumber - 1) * props.limitPerPage : 0
        const end = props.limitPerPage * props.pageNumber

        updCohorts = updCohorts.slice(start, end)

        setFilteredCohorts(updCohorts)
    }

    useEffect(() => {
        getFilteredData()
    },[props.pageNumber])

    return (
        <Grid container item xs={12} md={12} lg={9} spacing={4} className={Classes.container} >
            <Modal
                open={openShareNotebookCopyModal}
                handleClose={closeShareNotebookCopyModal}
                dialogProps={SHARE_NOTEBOOK_COPY_MODAL_PROPS}
            >
                <div>
                    {workspaceValues?.length > 1 ?
                        <div className={Classes.selectWorkspaceContainer} id='cvb-mySavedSearch-workspaceContainer'>
                            <Grid item xs={6}>
                                <DropDown
                                    values={workspaceValues}
                                    handleFilterStateChange={onWorkspaceOptionChange}
                                    selectedFilter={selectedWorkspace}
                                    placeholder={'Select workspace'}
                                />
                                {
                                    exportToWorkspace && isEmpty(selectedWorkspace) ?
                                        <div className={Classes.errorTextDiv} id='cvb-mySavedSearch-workspaceError'>
                                            <ErrorText errorMessage='Please select workspace' />
                                        </div> : null
                                }
                                {
                                    loading ?
                                        <CustomLoader styleProp={{ marginTop: '25px' }} /> : null
                                }
                            </Grid>
                        </div>
                        :
                        <div className={Classes.selectWorkspaceContainer}>
                            <p id='cvb-mySavedSearch-workspaceDescription'> You are exporting Cohort to <span className={Classes.workspaceName}><b>{workspaceValues[0]}</b></span> Workspace.
                            </p>
                            {
                                loading ?
                                    <CustomLoader styleProp={{ marginTop: '25px' }} /> : null
                            }
                        </div>


                    }
                </div>
            </Modal>
            {
                props.showCohortsLoader ?
                    <CustomLoader /> :
                    filteredCohorts?.length ?
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell id='cvb-myCohorts-tableHeaderSearchName' className={Classes.typeColumn} width="45%">Cohort Name</TableCell>
                                        <TableCell id='cvb-myCohorts-tableHeaderSavedOn' className={Classes.searchColumn} width="35%" align="left">Saved On</TableCell>
                                        <TableCell id='cvb-myCohorts-tableHeaderDataCategory' className={Classes.searchColumn} width="20%" align="left">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        map(filteredCohorts, (row, index) => {
                                            return (
                                                <TableRow key={row?.uuid}>
                                                    <TableCell id='cvb-myCohorts-tablCellCohortName' className={[Classes.cohortTableCell, Classes.cohortNameCell].join(" ")} component="th" scope="row">
                                                        <Tooltip placement="bottom-start" className={Classes.userNameTitle} title={row?.cohort_name || ''}>
                                                            <span className={Classes.userNameTitle}>{row?.cohort_name}</span>
                                                        </Tooltip>
                                                        {
                                                            row?.recentlyAnalysed &&
                                                            <span id='cvb-myCohortCard-contentCohortRecentUpdated' className={Classes.recentlyAnalysed}>Recently analyzed</span>
                                                        }
                                                    </TableCell>

                                                    <TableCell id='cvb-myCohorts-tablCellCohortDate' className={[Classes.cohortTableCell, Classes.userTableCell].join(" ")} component="th" scope="row">
                                                        <Tooltip placement="bottom-start" title={row?.created_at || ''}>
                                                            <span>{row?.created_at ? (moment.utc(row?.created_at)?.local().format('MM/DD/YYYY') + ' at ' + moment.utc(row?.created_at)?.local().format('hh:mm A')) : ''}</span>
                                                        </Tooltip>
                                                    </TableCell>

                                                    <TableCell id='cvb-myCohorts-tablCellActions' className={[Classes.cohortTableCell, Classes.userTableCell].join(" ")} component="th" scope="row">
                                                        <Tooltip placement="bottom-start" title={row?.node_name || ''}>
                                                            <span>{row?.node_name || ''}</span>
                                                        </Tooltip>
                                                        <span className={Classes.rightIconBox}>

                                                            {/*
                                                            Hidden as part of MP-358
                                                            <button id='cvb-myCohorts-tablCellAnalyseButton' className={Classes.statusButton} onClick={() => onAnalyzeClick(row)}>Analyze</button>

                                                            {
                                                                <img
                                                                    src={ImageVerticalIcon}
                                                                    alt='verticalIcon'
                                                                    className={Classes.rightIconImage}
                                                                    onClick={(e) => handleDownArrowClick(e, row)}
                                                                />
                                                            }*/}
                                                            <button id='cvb-myCohorts-popoverRemoveCohort' className={Classes.statusButton} onClick={() => { handleRemoveCohort({uuid: row?.uuid, cohortName: row?.row_name}) }}>Remove Cohort</button>
                                                            {
                                                                workspaceValues?.length  &&
                                                                <img
                                                                    src={ImageVerticalIcon}
                                                                    alt='verticalIcon'
                                                                    className={Classes.rightIconImage}
                                                                    onClick={(e) => handleDownArrowClick(e, row)}
                                                                />
                                                            }
                                                        </span>
                                                        <Popover
                                                            id={`cvb-myCohorts-popover-${row?.id}`}
                                                            open={openPopover}
                                                            elevation={3}
                                                            anchorEl={anchorEl}
                                                            onClose={handleClosePopover}
                                                            anchorOrigin={{
                                                                horizontal: 'right',
                                                            }}
                                                            transformOrigin={{
                                                                horizontal: 'right',
                                                            }}
                                                        >
                                                            <ul className={Classes.dropdownListContainer}>
                                                                {/*
                                                                Hidden as part of MP-358
                                                                <li id='cvb-myCohorts-popoverModifyData' onClick={() => { handleModifyData() }}>Modify Data</li>
                                                                <li id='cvb-myCohorts-popoverRemoveCohort' onClick={() => { handleRemoveCohort() }}>Remove Cohort</li>*/}
                                                                {
                                                                    workspaceValues?.length &&
                                                                        <li id='cvb-myCohorts-popoverExportToWorkSpaceLink' onClick={() => { openModal() }} className={Classes.linkIcons}>
                                                                            Export To Workspace
                                                                        </li>
                                                                }
                                                            </ul>
                                                        </Popover>
                                                    </TableCell>



                                                </TableRow>
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer> : <div id='cvb-myCohorts-noSavedCohorts'>You have no saved cohorts.</div>
            }

        </Grid>

    )
}

export default MyCohorts;
/**
 *  External Imports
 */
import { func, number, string, bool } from "prop-types";
import React, { useEffect } from "react";

/**
 *  Internal Imports
 */
import CustomModal from "../../../commons/modal";
import styles from "./index.module.css";
import UserAvatar from "../../../commons/user-avatar";

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 */
const CommunityDeleteModal = (props) => {
    const {
        showCommunityDeleteModal,
        userIcon,
        name,
        organization,
        id,
    } = props;
    const { onDialogClose, onDialogReset, onDeleteUser, deleteMethod, getConstellationConnections} = props;
    const modalProps = {
        loadStatus: false,
        modalTitle: "Delete connection",
        modalTitleClass: styles.modalTitle,

        negativeButtonText: "Cancel",
        negativeButtonAction: () => onDialogClose(),
        
        positiveButtonText: "Delete",
        positiveButtonClass: styles.modalDeleteBtn,
        async positiveButtonAction() {
            const { error } = await deleteMethod(id);

            onDialogClose();

            if(getConstellationConnections) {
                getConstellationConnections();
            }

            if (!error) {
                onDeleteUser(id);
            }
        },
    };

    const modalBtnStyleProps = {
        minWidth: "auto",
        padding: "8px 24px",
        fontSize: ".875rem",
    };

    useEffect(() => {
        return () => onDialogReset();
    }, []);

    return (
        <CustomModal
            data-testid="modal"
            open={showCommunityDeleteModal}
            handleClose={onDialogClose}
            dialogProps={modalProps}
            buttonStyleProps={modalBtnStyleProps}
            disableTitleDivider={true}
            disableBottomHorizontalDivider={true}>
            <div className={styles.modalBody}>
                <div className={styles.modalUserInfoArea}>
                    <div className={styles.modalUserImage}>
                        <UserAvatar data-testid="userIcon" userIcon={userIcon}></UserAvatar>
                    </div>
                    <div>
                        <div data-testid="userName" className={styles.modalUserName}>{name}</div>
                        <div data-testid="userOrganization" className={styles.modalUserOrganization}>
                            {organization}
                        </div>
                    </div>
                </div>
                <p data-testid="modalText" className={styles.modalText}>Are you sure that you want to remove this user from your connections?</p>
            </div>
        </CustomModal>
    );
};

/**
 *  Properties
 */
CommunityDeleteModal.propTypes = {
    id: number.isRequired,
    userIcon: string.isRequired,
    name: string.isRequired,
    organization: string.isRequired,
    showCommunityDeleteModal: bool.isRequired,

    onDeleteUser: func.isRequired,
    deleteMethod: func.isRequired,
    onDialogClose: func.isRequired,
    onDialogReset: func.isRequired
};

/**
 *  Exports
 */
export default CommunityDeleteModal;

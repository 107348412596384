import axios from 'axios'
import get from 'lodash/get'
import { getCognitoIdToken } from './utilityMethods';
import { BASE_URL } from '../constants/google-login-urls';



export const getSavedSearch = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/data_access/pg/search/save`;
    const res = await axios.get(url, {headers: header });

    return res?.data || [];
}

export const deleteSavedSearch = async (id) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/data_access/pg/search/save/${id}`;
    const res = await axios.delete(url, {headers: header });

    return res?.data || [];
}

export const exportSavedSearchToWorkspace = async (data) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/data_access/pg/search/save/export_to_workspace`;
    const res = await axios.post(url, data, {headers: header });

    return res?.data || [];
}

export const exportCohortToWorkspace = async (data) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/data_access/cohort/export_to_workspace`;
    const res = await axios.post(url, data, {headers: header });

    return res?.data || [];
}





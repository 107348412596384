/**
 *  External Imports
 */
 import { connect } from "react-redux";

/**
*  Internal Imports
*/
import {
    connectConstellationRecommendedUserAction,
    openCommunityConnectModalAction,
    declineConstellationRecommendedUserAction,
    setConstellationTooltipAction,
    redirectCommunityAction,
    openCommunitySuccessModalAction,
    acceptConstellationIncomingUserByIDAction,
	declineConstellationIncomingUserByIDAction,
    openCommunityDeleteModalAction,
    deleteConstellationConnectedUserAction,
} from "../../../../redux/actions/communityActions";
import CommunityConstellationGraphTooltip from "./CommunityConstellationGraphTooltip";

const mapStateToProps = ({ community: { constellationTooltipParams, constellationTooltipOpen }}) => ({
    constellationTooltipParams, constellationTooltipOpen
});

const mapDispatchToProps = (dispatch) => ({
    onConnectDialogOpen: (dialogConnectData) => dispatch(openCommunityConnectModalAction(dialogConnectData)),
    onRecommendedConnectUser: (recommendationID) => dispatch(connectConstellationRecommendedUserAction(recommendationID)),
    onDeclineRecommendedUser: (recommendationID) => dispatch(declineConstellationRecommendedUserAction(recommendationID)),
    setTooltipParams: (tooltipParams) => dispatch(setConstellationTooltipAction(tooltipParams)),
    onMessageChatRedirect: (path, pathState) => dispatch(redirectCommunityAction(path, pathState)),
    onAcceptDialogOpen: (dialogSuccessData) => dispatch(openCommunitySuccessModalAction(dialogSuccessData)),
    onAcceptIncomingUser: (userID) => dispatch(acceptConstellationIncomingUserByIDAction(userID)),
    onDeclineIncomingUser: (userID) => dispatch(declineConstellationIncomingUserByIDAction(userID)),
    onDeleteDialogOpen: (dialogDeleteData) => dispatch(openCommunityDeleteModalAction(dialogDeleteData)),
    onDeleteConnectedUser: (connectionId) => dispatch(deleteConstellationConnectedUserAction(connectionId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityConstellationGraphTooltip);
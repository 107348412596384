
import ProjectDetails from './ProjectDetails'
import { connect } from 'react-redux'
import { setProjectDetailsProjectName, setProjectDetailsProjectSynopsisText, setProjectDetailsIRBApprovalNumber, setIRBNumberFile,
    setDesignatedZone, setRestrictionOfUseText, setIsDatasetDownloadable, setAckStatement, setTypeOfData, setOtherTypeDataText,
    setTotalSizeOfData, setLogoFile, setObjectiveOfStudy, setDescriptionOfStudy, setDataDescription, setStudyDesign, setNumberOfGrants
    , setDurationOfMonths, setLocationOfStudy, setProjectDetailsPublications, removeProjectDetailsPublications, setPublicationURL,
    setPublicationName, setIsResearcherAllowed, setProjectDetailsIRBApprovalKey
} from '../../../redux/actions/projectDetailsActions'
import { updateSnackBar } from '../../../redux/actions/globalActions'
const mapStateToProps = state => {
    return {
        projectDetailsProjectName: state.projectDetails.projectDetailsProjectName,
        projectDetailsSynopsisText: state.projectDetails.projectDetailsSynopsisText,
        projectDetailsIRBApprovalNumber: state.projectDetails.projectDetailsIRBApprovalNumber,
        projectDetailsIRBNumberFile: state.projectDetails.projectDetailsIRBNumberFile,
        projectDetailsDesignatedZone: state.projectDetails.projectDetailsDesignatedZone,
        projectDetailsRestrictionOfUSeText: state.projectDetails.projectDetailsRestrictionOfUSeText,
        projectDetailsIsDatasetDownloadable: state.projectDetails.projectDetailsIsDatasetDownloadable,
        projectDetailsAckStatement: state.projectDetails.projectDetailsAckStatement,
        projectDetailsTypeOfData: state.projectDetails.projectDetailsTypeOfData,
        projectDetailsOtherTypeDataText: state.projectDetails.projectDetailsOtherTypeDataText,
        projectDetailsTotalSizeOfData: state.projectDetails.projectDetailsTotalSizeOfData,
        projectDetailsLogoFile: state.projectDetails.projectDetailsLogoFile,
        projectDetailsObjectiveOfStudy: state.projectDetails.projectDetailsObjectiveOfStudy,
        projectDetailsDescriptionOfStudy: state.projectDetails.projectDetailsDescriptionOfStudy,
        projectDetailsDataDescription: state.projectDetails.projectDetailsDataDescription,
        projectDetailsStudyDesign: state.projectDetails.projectDetailsStudyDesign,
        projectDetailsNumberOfGrants: state.projectDetails.projectDetailsNumberOfGrants,
        projectDetailsDurationOfMonths: state.projectDetails.projectDetailsDurationOfMonths,
        projectDetailsPublications: state.projectDetails.projectDetailsPublications,
        projectDetailsLocationOfStudy: state.projectDetails.projectDetailsLocationOfStudy,
        isResearcherAllowed: state.projectDetails.isResearcherAllowed,
        logoS3Key: state.projectDetails.logoS3Key,
        irbApprovalCopyS3Key: state.projectDetails.irbApprovalCopyS3Key,
        currentDCAFormStage: state.dca.currentDCAFormStage
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setProjectDetailsProjectName: async (value) => await dispatch(setProjectDetailsProjectName(value)),
        setProjectDetailsProjectSynopsisText: async (value) => await dispatch(setProjectDetailsProjectSynopsisText(value)),
        setProjectDetailsIRBApprovalNumber: async (value) => await dispatch(setProjectDetailsIRBApprovalNumber(value)),
        setIRBNumberFile: async (value) => await dispatch(setIRBNumberFile(value)),
        setDesignatedZone: async (value) => await dispatch(setDesignatedZone(value)),
        setRestrictionOfUseText: async (value) => await dispatch(setRestrictionOfUseText(value)),
        setIsDatasetDownloadable: async (value) => await dispatch(setIsDatasetDownloadable(value)),
        setAckStatement: async (value) => await dispatch(setAckStatement(value)),
        setTypeOfData: async (value) => await dispatch(setTypeOfData(value)),
        setOtherTypeDataText: async (value) => await dispatch(setOtherTypeDataText(value)), 
        setTotalSizeOfData: async (value) => await dispatch(setTotalSizeOfData(value)),  
        setLogoFile: async (value) => await dispatch(setLogoFile(value)),  
        setObjectiveOfStudy: async (value) => await dispatch(setObjectiveOfStudy(value)),  
        setDescriptionOfStudy: async (value) => await dispatch(setDescriptionOfStudy(value)),  
        setDataDescription: async (value) => await dispatch(setDataDescription(value)),  
        setStudyDesign: async (value) => await dispatch(setStudyDesign(value)),  
        setNumberOfGrants: async (value) => await dispatch(setNumberOfGrants(value)),  
        setDurationOfMonths: async (value) => await dispatch(setDurationOfMonths(value)),
        setLocationOfStudy: async (value) => await dispatch(setLocationOfStudy(value)),  
        setProjectDetailsPublications: async (value) => await dispatch(setProjectDetailsPublications(value)),  
        removeProjectDetailsPublications: async (value) => await dispatch(removeProjectDetailsPublications(value)), 
        setPublicationURL: async (value, id) => await dispatch(setPublicationURL(value, id)), 
        setPublicationName: async (value, id) => await dispatch(setPublicationName(value, id)),  
        setIsResearcherAllowed: async (value) => await dispatch(setIsResearcherAllowed(value)), 
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type)),
        setProjectDetailsIRBApprovalKey: async (value) => dispatch(setProjectDetailsIRBApprovalKey(value)) 
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
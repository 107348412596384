import React, { useEffect, useState, useMemo } from 'react';
import { navigate } from "@reach/router"
import Grid from '@mui/material/Grid'
import classes from './index.module.css'
import BackButtonIcon from '../../../assets/icons/left_arrow.png'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '../../../assets/icons/search-icon.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getListOfProjects, getListOfProjectDetails } from '../../../api/graphql/uploadData'
import { getStructuredDataModel } from '../../../api/upload-data-api'
import { getDataModelSchema } from '../../../api/data-model-api'
import moment from 'moment'
import useScrollToTop from '../../commons/scroll-top';
import Skeleton from '@mui/material/Skeleton';
import v4 from 'uuid/v4'


function UploadStructureData() {

    const [dictionary, setDictionary] = useState(null)

    const [projects, setProjects] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingProjectProperties, setIsLoadingProjectProperties] = useState(true)
    const [projectInfo, setProjectInfo] = useState({})
    const [projectCompleteInfo, setProjectCompleteInfo] = useState({})
    const [searchText, setSearchText] = useState("")
    const [isCompleteInfoLoading, setIsCompleteInfoLoading] = useState(false)

    let projectDetails = {}

    const getProjectDetails = async (projectId) => {
        try {
            const response = await getListOfProjectDetails(projectId);
            if (response) {
                let responseObj = {
                    fileCount: response?.datanode?.length || 0,
                    cases: response["cases"],
                    studies: response["studies"]
                }
                const successfulTransaction = response?.transaction_log?.[0]
                if (successfulTransaction) {
                    if (successfulTransaction.created_datetime) {
                        responseObj["lastUploadTime"] = moment(successfulTransaction.created_datetime).format("MM/DD/YYYY HH:mm")
                    }
                    responseObj["lastUpdated"] = successfulTransaction.submitter || ''
                }
                projectDetails[projectId] = responseObj;
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    useScrollToTop();

    const formatData = async (dictionary, projectID) => {
        try {
            const response = await getStructuredDataModel(projectID);
            let responseKeys = Object.keys(response)
            responseKeys = responseKeys.filter(item => item.endsWith("_count") && response[item] > 0)
            const nodesCount = responseKeys.length;
            let recordsCount = 0;
            for (let item of responseKeys) {
                recordsCount += response[item]
            }
            return { nodesCount, recordsCount }
        }
        catch (error) {
            console.log(error)
        }
    }

    const getNodes = async () => {
        try {
            const result = await getDataModelSchema();
            const keys = Object.keys(result);
            for (let key of keys) {
                if (key.startsWith("_") || !(result[key]?.links?.length > 0) || key.includes("data_release")) {
                    delete result[key]
                }
            }
            setDictionary(result);
        }
        catch (error) {
            console.log(error)
        }
    }



    const getProjects = async () => {
        try {
            setIsLoading(true)
            setIsCompleteInfoLoading(true)
            setIsLoadingProjectProperties(true)
            const projects = await getListOfProjects();
            let promises = []
            setProjects(projects)
            setIsLoading(false)
            for (let project of projects) {
                promises.push(getProjectDetails(project.project_id))
            }
            await Promise.all(promises)
            setProjectInfo(projectDetails)
            setIsLoadingProjectProperties(false)
            let projectDetailsPromises = []
            let projectCompleteDetails = {}
            for (let project of projects) {
                projectDetailsPromises.push(formatData(dictionary, project.project_id).then(res => {
                    projectCompleteDetails[project.project_id] = res;
                }).catch(error => { console.log(error) }))
            }
            await Promise.all(projectDetailsPromises);
            setProjectCompleteInfo(projectCompleteDetails)
            setIsCompleteInfoLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getNodes();
    }, [])

    useEffect(() => {
        if (dictionary) {
            getProjects()
        }
    }, [dictionary])

    const navigateToUploadFile = id => {
        navigate(`/my-uploads/structured-data/upload-data/files/${id}`)
    }

    const filteredProjects = useMemo(() => {
        if (projects?.length > 0) {
            return projects.filter(item => item.project_id.toLowerCase().includes(searchText.toLowerCase()))
        }
        return []
    }, [projects, searchText])

    return (
        <div>
            <div className={classes.innerContainer}>
                <Grid container className={classes.searchBackground}>
                    <Grid item xs={12} lg={12} md={12}>
                        <div className={classes.sectionHeaderContainer}>
                            {/* <p onClick={() => { navigate("/my-uploads/structured-data") }} className={classes.backButton}>
                                <img src={BackButtonIcon} alt="black button" /> Back</p> */}
                            <div className={classes.gridTitle}><h2 id='cvb-structuredUpload-headingText'>Upload Structured Data</h2></div>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} className={classes.uploadTableContainer}>
                        <div className={classes.uploadTableContainerInner}>
                            <div className={classes.sectionTableHeader}>
                                <div className={classes.sectionLeftContainer}>
                                    <h4 id='cvb-structuredUpload-selectProjectText'>Select Project</h4>
                                </div>
                                <div className={classes.sectionRightContainer}>
                                    <OutlinedInput fullWidth type='text'
                                        value={searchText} placeholder='Search'
                                        onChange={e => setSearchText(e.target.value)}
                                        className='signup-inputs-large' name='password'
                                        id='cvb-structuredUpload-projectsSearchField'
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility" size="large">
                                                    <img alt='visibility On' src={SearchIcon} className={classes.exploreSearchIcon} />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                            </div>
                            <div className={classes.selectProjectTableContainer}>
                                {
                                    isLoading ? <div>Loading...</div> :
                                        <TableContainer
                                            component={Paper}
                                            elevation={0}
                                            id='cvb-structuredUpload-uploadProjectTable'
                                            className={classes.tableContainer}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell id='cvb-structuredUpload-tableProjectNameHeading' width="25%" align="left">Project Name</TableCell>
                                                        <TableCell id='cvb-structuredUpload-tableCasesHeading' width="5%" align="left">Cases</TableCell>
                                                        <TableCell id='cvb-structuredUpload-tableStudiesHeading' width="5%" align="left">Studies</TableCell>
                                                        <TableCell id='cvb-structuredUpload-tableFilesHeading' width="5%" align="left">Files</TableCell>
                                                        <TableCell id='cvb-structuredUpload-tableUploadDateHeading' width="15%" align="left">Last Upload Date</TableCell>
                                                        <TableCell id='cvb-structuredUpload-tableUpdatedByHeading' width="10%" align="left">Last Updated by</TableCell>
                                                        <TableCell id='cvb-structuredUpload-tableNodesHeading' width="10%" align="left">Nodes</TableCell>
                                                        <TableCell id='cvb-structuredUpload-tableRecordsHeading' width="10%" align="left">Records</TableCell>
                                                        <TableCell id='cvb-structuredUpload-tableSelectButtonHeading' width="15%" align="left"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        filteredProjects?.map((item, index)  => {
                                                            return <TableRow key={v4()} id={`cvb-structuredUpload-tableRow-${index}`}>
                                                                <TableCell id='cvb-structuredUpload-tableCellProjectID' align="left">{item?.project_id || ''}</TableCell>
                                                                <TableCell id='cvb-structuredUpload-tableCellCases' align="left">{isLoadingProjectProperties ? <Skeleton animation="wave" /> : projectInfo[item.project_id]?.cases || 0}</TableCell>
                                                                <TableCell id='cvb-structuredUpload-tableCellStudies' align="left">{isLoadingProjectProperties ? <Skeleton animation="wave" /> : projectInfo[item.project_id]?.studies || 0}</TableCell>
                                                                <TableCell id='cvb-structuredUpload-tableCellFileCount' align="left">{isLoadingProjectProperties ? <Skeleton animation="wave" /> : projectInfo[item.project_id]?.fileCount || 0}</TableCell>
                                                                <TableCell id='cvb-structuredUpload-tableCellUploadTime' align="left">{isLoadingProjectProperties ? <Skeleton animation="wave" /> : projectInfo[item.project_id]?.lastUploadTime || '-'}</TableCell>
                                                                <TableCell id='cvb-structuredUpload-tableCellUploadBy' align="left">{isLoadingProjectProperties ? <Skeleton animation="wave" /> : projectInfo[item.project_id]?.lastUpdated || '-'}</TableCell>
                                                                <TableCell id='cvb-structuredUpload-tableCellNodeCount' align="left">
                                                                    {isCompleteInfoLoading ? <Skeleton animation="wave" /> : (projectCompleteInfo[item.project_id]?.nodesCount || "-")}
                                                                </TableCell>
                                                                <TableCell id='cvb-structuredUpload-tableCellRecordsCount' align="left">
                                                                    {isCompleteInfoLoading ? <Skeleton animation="wave" /> : (projectCompleteInfo[item.project_id]?.recordsCount || "-")}
                                                                </TableCell>
                                                                <TableCell id={`cvb-structuredUpload-tableCellSelectProjectButton-${index}`} align="center">
                                                                    <button className={classes.projectUploadSolidButtonTransparent}
                                                                        onClick={() => navigateToUploadFile(item.project_id)}>
                                                                        Select Project</button>
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                }
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default UploadStructureData;

import {
    SET_TAB_VALUE_IN_EDIT_PROFILE, SET_EDIT_CONTACT_NUMBER, SET_EDIT_CITY, SET_EDIT_STREET_ADDRESS,
    SET_EDIT_STATE, SET_EDIT_ZIP_CODE, SET_EDIT_COUNTRY, SET_EDIT_PROFILE_INITIAL_STATE,
    SET_EDIT_INSTITUTION_NAME
} from '../action-types'
import { TEMP_CONTACT_EMAIL } from '../../constants/strings'

const initialState = {
    tabValue: 0,
    contactEmail: '',
    contactNumber: '',
    city: '',
    streetAddress: '',
    state: '',
    zipCode: '',
    country: '',
    institutionName: ''
}

const editProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TAB_VALUE_IN_EDIT_PROFILE: {
            return {
                ...state,
                tabValue: action.payload
            }
        }
        case SET_EDIT_CONTACT_NUMBER: {
            return {
                ...state,
                contactNumber: action.payload
            }
        }
        case SET_EDIT_CITY: {
            return {
                ...state,
                city: action.payload
            }
        }
        case SET_EDIT_STREET_ADDRESS: {
            return {
                ...state,
                streetAddress: action.payload
            }
        }
        case SET_EDIT_STATE: {
            return {
                ...state,
                state: action.payload
            }
        }
        case SET_EDIT_ZIP_CODE: {
            return {
                ...state,
                zipCode: action.payload
            }
        }
        case SET_EDIT_COUNTRY: {
            return {
                ...state,
                country: action.payload
            }
        }
        case SET_EDIT_PROFILE_INITIAL_STATE: {
            return {
                ...state,
                ...action.payload
            }
        }
        case SET_EDIT_INSTITUTION_NAME: {
            return {
                ...state,
                institutionName: action.payload
            }
        }
        default: return state
    }
}

export default editProfileReducer;
import MyStuffAnalysis from './MyStuffAnalysis'
import { connect } from 'react-redux'
import { getAnalysisWithCohortId } from '../../../redux/actions/myStuffActions'
import { onAnalyzeCohort, addNewAnalysis, setSelectedAnalysis, removeAnalysis } from '../../../redux/actions/analysisDataActions'
import {
    getExploreDataFilters, updateExcludeFromExploration
} from "../../../redux/actions/exploreDataActions"
import { getUserCohorts } from '../../../redux/actions/myStuffActions'
import { saveCohortWithoutID } from '../../../api/analysis-api'
import { updateSnackBar } from '../../../redux/actions/globalActions'
import { saveCohortToDBApi } from '../../../api/analysis-api'



const mapStateToProps = state => {
    return {
        analysisCohorts: state.analyzeData.analysisCohorts,
        analysisAttributes: state.analyzeData.analysisAttributes,
        selectedAnalysis: state.analyzeData.selectedAnalysis,
        analysisCounter: state.analyzeData.analysisCounter,
        analysisWithCohortId: state.myStuff.analysisWithCohortId,
        userCohorts: state.myStuff.userCohorts,
        exploreDataFilters: state.exploreData.exploreDataFilters,
        exploreData: state.exploreData.exploreData,
        totalCount: state.exploreData.totalCount,
        lastEvaluatedKey: state.myStuff.lastEvaluatedKey,
        customizeItems: state.exploreData.customizeItems,
        excludeFromExploration: state.exploreData.excludeFromExploration
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getExploreDataFilters: async () => await dispatch(getExploreDataFilters()),
        onAnalyzeCohort: (value) => dispatch(onAnalyzeCohort(value)),
        addNewAnalysis: (value) => dispatch(addNewAnalysis(value)),
        setSelectedAnalysis: (value) => dispatch(setSelectedAnalysis(value)),
        removeAnalysis: (value) => dispatch(removeAnalysis(value)),
        getAnalysisWithCohortId: async id => await dispatch(getAnalysisWithCohortId(id)),
        saveCohortToDBApi: async id => await saveCohortToDBApi(id),
        getUserCohorts: async (limit, lastExecutedKey) => await dispatch(getUserCohorts(limit, lastExecutedKey)),
        saveCohortWithoutID: async (body) => await saveCohortWithoutID(body),
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type)),
        updateExcludeFromExploration: async value => dispatch(updateExcludeFromExploration(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyStuffAnalysis);
import SideNavBar from './SideNavBar';
import { connect } from 'react-redux';
export { drawerWidth, drawerWidthClosed } from './SideNavBar';

const mapStateToProps = (state) => {
    return {
        userDetails: state.user.userDetails,
        isUserPrivate: state.user.isUserPrivate
    };
};


export default connect(mapStateToProps)(SideNavBar);
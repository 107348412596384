export const vars = {
  primaryFont: 'Raleway, sans-serif',
  primaryColor: '#4D80C6',
  lightGrey: '#FAFAFA',
  darkTextColor: '#101828',
  primaryTextColor: '#667085',
  grayTextColor: '#88939D',
  white: '#fff',
  btnContainedColor: '#315A98',
  btnOutlinedBorderColor: '#EBEDF4',
  btnOutlinedPrimaryHoverBg: '#E8F1FF',
  borderColor: '#E4E7EC',
  outlinedInputBorderColor: '#E7EAF2',
  selectPlaceholderColor: '#B3B3B3',
  subHeaderColor: '#344054',
  errorColor: '#D92D20',
  errorHoverColor: '#BB1F14',
  boxShadowColor: 'rgba(77, 128, 198, 0.3)',
  tertiaryButtonColor: '#F2F4F7',
  inputLabelColor: '#031235',
  grey: '#cccccc'
};

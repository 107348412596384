import {
  UPDATE_STUDY_GALLERY_FILTERS,
  UPDATE_STUDY_GALLERY_COUNT,
  UPDATE_STUDY_GALLERY_DATA,
  UPDATE_INITIAL_STUDY_GALLERY_FILTER,
  UPDATE_STUDY_GALLERY_DETAILS,
  UPDATE_SELECTED_PROJECTS,
} from '../action-types';
import {
  getStudyGalleryFilters as getStudyGalleryFiltersAPI,
  getStudyDataApi,
  getStudyPerigreinApi,
  getStudyDetailsPerigreinApi,
  getTotalCount as getTotalCountAPI,
  getStudyGalleryItemDetails,
  getManyStudyPerigreinApi,
} from '../../api/graphql/study-gallery';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import find from 'lodash/find';
import { gql } from 'apollo-boost';

export const getStudyGalleryFilters = () => async (dispatch) => {
  try {
    const response = await getStudyGalleryFiltersAPI();
    const filters = response?._aggregation?.study || {};
    // removing unwanted items
    const filterKeys = Object.keys(filters);
    const filterItems = {};
    for (let item of filterKeys) {
      if (filters[item]?.histogram) {
        filterItems[item] = filters[item];
        for (let eachHistogram of filters[item].histogram) {
          eachHistogram.count =
            eachHistogram?.termsFields?.[0]?.terms?.length || 0;
        }
      }
    }
    dispatch(updateStudyGalleryFilters(filterItems));
  } catch (error) {
    console.log(error);
  }
};

export const getTotalCount = (filterValues) => async (dispatch) => {
  try {
    const response = await getTotalCountAPI(filterValues);
    const totalCount = response?._aggregation?.study?.['_totalCount'] || 0;
    dispatch(updateStudyGalleryCount(totalCount));
  } catch (error) {
    console.log(error);
  }
};

export const updateResultsOnSearchText =
  (studyGalleryData, searchText) => async (dispatch) => {
    try {
      if (searchText) {
        if (studyGalleryData) {
          const filteredData = {};
          filteredData.studyGalleryData =
            studyGalleryData.studyGalleryData.filter((item) =>
              item?.key?.toLowerCase()?.includes(searchText.toLowerCase())
            );
          await dispatch(
            updateStudyGalleryCount(filteredData.studyGalleryData.length)
          );
          await dispatch(updateStudyGalleryData(filteredData));
        }
      } else {
        await dispatch(
          updateStudyGalleryCount(
            studyGalleryData?.studyGalleryData?.length || 0
          )
        );
        await dispatch(updateStudyGalleryData(studyGalleryData));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getStudyDetailById = (projectId) => async (dispatch) => {
  try {
    const detail = await getStudyPerigreinApi(projectId);
    return detail;
  } catch (error) {
    console.log(error);
  }
};

export const getStudyDetailByIds = async (projectIds) => {
  try {
    const detail = await getManyStudyPerigreinApi(projectIds);
    return detail;
  } catch (error) {
    console.log(error);
  }
};

export const getPaginatedStudyGalleryDetails = async (projectIds) => {
  let queryString = '';
  let resultData = {};
  for (let id of projectIds) {
    let newid = id.replace(/[^a-zA-Z0-9 ]/g, '');
    queryString =
      queryString +
      `${newid}_study_details: study(project_id:["${id}"], first:1) {
            study_objective
            study_description
            data_description
            study_design
            data_type
            acknowledgement
            data_repository_of_record
            additional_resource
            updated_datetime
            created_datetime
            project_id
            } ${newid}_publications: publication(project_id:["${id}"]) {
            doi
            }
            ${newid}_case_count: _case_count(project_id:["${id}"])`;
  }
  queryString = gql`query {${queryString}}`;
  const results = await getStudyDetailsPerigreinApi(queryString);
  for (let id of projectIds) {
    resultData[id] = {};
    let newId = id.replace(/[^a-zA-Z0-9 ]/g, '');
    for (const projectData in results) {
      if (projectData.includes(`${newId}_study_details`)) {
        resultData[id]['study_details'] = results[projectData][0] || {};
      }
      if (projectData.includes(`${newId}_publications`)) {
        resultData[id]['publications'] = results[projectData] || [];
      }
      if (projectData.includes(`${newId}_case_count`)) {
        resultData[id]['case_count'] = results[projectData] || 0;
      }
    }
  }
  resultData = Object.keys(resultData).map(function (k) {
    return resultData[k];
  });
  return resultData;
  //dispatch(updateStudyGalleryDetails(resultData))
};

export const getStudyGalleryData =
  (filterValues, offset = 0, recordsPerRequest = 6) =>
  async (dispatch) => {
    try {
      const resp = await getStudyDataApi(
        filterValues,
        offset,
        recordsPerRequest
      );
      const filters = resp?.data?._aggregation?.filters || {};
      const caseCounts = resp?.data?._aggregation?.case_count || {};
      // removing unwanted items
      const filterKeys = Object.keys(filters);
      const filterItems = {};
      for (let item of filterKeys) {
        if (filters[item]?.histogram) {
          filterItems[item] = filters[item];
          for (let eachHistogram of filters[item].histogram) {
            eachHistogram.count =
              eachHistogram?.termsFields?.[0]?.terms?.length || 0;
          }
        }
      }
      let zone3_projects = [];
      if (
        filters?.zone?.histogram?.length > 2 &&
        filters?.zone?.histogram[2]?.termsFields?.length &&
        filters?.zone?.histogram[2]?.termsFields[0]?.terms?.length
      ) {
        zone3_projects = filters?.zone?.histogram[2]?.termsFields[0]?.terms;
      }
      let projects =
        resp?.data?._aggregation?.accessible?.project_id?.histogram || [];
      projects = projects.map((item) => {
        return { ...item, isAccessible: true };
      });
      let unaccessibleProjects =
        resp?.data?._aggregation?.unaccessible?.project_id?.histogram || [];
      unaccessibleProjects = unaccessibleProjects.map((item) => {
        return { ...item, isAccessible: false };
      });
      projects = [...projects, ...unaccessibleProjects];
      if (zone3_projects?.length && projects?.length) {
        for (let project of projects) {
          for (let zone3_project of zone3_projects) {
            if (zone3_project['key'] === project['key']) {
              project['isZone3'] = true;
            }
          }
        }
      }
      const projectIds = projects.map((item) => item.key);
      let studyGalleryData = [];
      let studyGalleryDetails = [];
      if (projectIds?.length) {
        studyGalleryData = await getStudyGalleryItemDetails(projectIds);
        studyGalleryDetails = await getStudyDetailByIds(projectIds);
      }
      const sortedStudyGalleyData = getSortedStudyGalleryData(
        studyGalleryData,
        projects
      );
      const studyGalleryMainData = {
        studyGalleryData: sortedStudyGalleyData,
        studyData: studyGalleryData,
        studyGalleryDetails,
        filters: filterItems,
        caseCounts: caseCounts?.project_id?.histogram || [],
      };

      return studyGalleryMainData;
    } catch (error) {
      dispatch(updateStudyGalleryData([]));
    }
  };

export const getStudyParticipants = async (filterValues) => {
  try {
    const resp = await getStudyDataApi(filterValues,0,0);
    return resp?.data?._aggregation?.case_count || {};
  } catch (error) {
    return null;
  }
};

const getSortedStudyGalleryData = (studyGalleryData, projects) => {
  const sortedStudyGalleryData = [];
  map(projects, (data) => {
    const sortOrder =
      find(studyGalleryData, (item) => item.projectId === data.key)
        ?.sortOrder || projects.length + 1;
    sortedStudyGalleryData.push({ ...data, sortOrder: sortOrder });
  });
  return orderBy(sortedStudyGalleryData, (data) => data?.sortOrder);
};
const updateStudyGalleryFilters = (value) => ({
  type: UPDATE_STUDY_GALLERY_FILTERS,
  payload: value,
});
const updateStudyGalleryCount = (value) => ({
  type: UPDATE_STUDY_GALLERY_COUNT,
  payload: value,
});
const updateStudyGalleryData = (value) => ({
  type: UPDATE_STUDY_GALLERY_DATA,
  payload: value,
});
const updateStudyGalleryDetails = (value) => ({
  type: UPDATE_STUDY_GALLERY_DETAILS,
  payload: value,
});
const updateInitialStudyGalleryData = (value) => ({
  type: UPDATE_INITIAL_STUDY_GALLERY_FILTER,
  payload: value,
});
export const updateSelectedProjects = (value) => ({
  type: UPDATE_SELECTED_PROJECTS,
  payload: value,
});

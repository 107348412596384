import { SET_ALL_WORKSPACES, SET_LOADED_WORKSPACES, SET_ALL_WORKSPACES_CARTS } from '../action-types'

export const setAllWorkspaces = (value) => {
    return {
        type: SET_ALL_WORKSPACES,
        payload: value
    }
}

export const setAllWorkspacesCarts = (value) => {
    return {
        type: SET_ALL_WORKSPACES_CARTS,
        payload: value
    }
}

export const setLoadedWorkspaces = (value) => {
    return {
        type: SET_LOADED_WORKSPACES,
        payload: value
    }
}
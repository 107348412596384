import DIFIndividualDatasetTabs from './DIFIndividualDatasetTabs'
import { connect } from 'react-redux'
import { addNewDataset, setDatasetCurrentActiveTab, removeSelectedDatasetTab } from '../../../redux/actions/difIndividualDatasetActions'
const mapStateToProps = state => {
    return {
        individualDatasets: state.difIndividualDataset.individualDatasets,
        difDatasetActiveIndex: state.difIndividualDataset.difDatasetActiveIndex,
        currentDIFFormStage: state.dataInventory.currentDIFFormStage
    }
}
const mapDispatchToProps = dispatch => {
    return {
        addNewDataset: async (value) => await dispatch(addNewDataset(value)),
        setDatasetCurrentActiveTab: async (value) => await dispatch(setDatasetCurrentActiveTab(value)),
        removeSelectedDatasetTab: async (value) => await dispatch(removeSelectedDatasetTab(value)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DIFIndividualDatasetTabs);
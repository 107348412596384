import React, { useEffect, useState, useRef } from 'react'
import Classes from './index.module.css';
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import ErrorText from '../error-text';
import isEmpty from 'lodash/isEmpty'
import { validateEmailAddress } from '../../../utils/input-validations';
import { DCA_FORM_COMPLETED_TEXT } from '../../../constants/strings';
const ProjectPI = props => {
    const {
        projectPIFirstName,
        projectPILastName,
        projectPIMiddleName,
        projectPICredentials,
        projectPIAffiliation,
        projectPIInstituteEmail,
        isFormSubmitted,
        currentDCAFormStage,

        setProjectPIFirstName,
        setProjectPILastName,
        setProjectPIMiddleName,
        setProjectPICredentials,
        setProjectPIAffiliation,
        setProjectPIInstituteEmail,
    } = props;

    const onProjectPIFirstNameChange = event => {
        setProjectPIFirstName(event.target.value);
    }
    const onProjectPILastNameChange = event => {
        setProjectPILastName(event.target.value);
    }
    const onProjectPIMiddleNameChange = event => {
        setProjectPIMiddleName(event.target.value);
    }
    const onProjectPICredentialChange = event => {
        setProjectPICredentials(event.target.value);
    }
    const onProjectPIAffiliationChange = event => {
        setProjectPIAffiliation(event.target.value);
    }
    const onProjectPIEmailChange = event => {
        setProjectPIInstituteEmail(event.target.value);
    }

    const disableTextInput =  (currentDCAFormStage === DCA_FORM_COMPLETED_TEXT);
    return (
        <div className={Classes.container} id='cvb-dca-stage3PICContainer'>
            <p className={Classes.formTitle} id='cvb-dca-stage3PIHeading'>Project PI</p>
            <Grid container className={Classes.inputContainers} spacing={3}>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label htmlFor='firstName' id='cvb-dca-stage3PIFirstName'>First Name<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter first name"
                        value={projectPIFirstName}
                        onChange={onProjectPIFirstNameChange}
                        fullWidth
                        id='cvb-dca-stage3PIFirstNameField'
                        disabled={disableTextInput}
                    />
                     {
                        isFormSubmitted && isEmpty(projectPIFirstName) &&
                        <ErrorText errorMessage="Enter valid first name" />
                    }
                </Grid>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label htmlFor='middleName' id='cvb-dca-stage3PIMiddleNameLabel'>Middle Name</label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter middle name"
                        value={projectPIMiddleName}
                        onChange={onProjectPIMiddleNameChange}
                        fullWidth
                        id='cvb-dca-stage3PIMiddleNameField'
                        disabled={disableTextInput}
                    />
                     {/* {
                        isFormSubmitted && isEmpty(projectPIMiddleName) &&
                        <ErrorText errorMessage="Enter valid middle name" />
                    } */}
                </Grid>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label htmlFor='lastName' id='cvb-dca-stage3PILastNameLabel'>Last Name<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter last name"
                        value={projectPILastName}
                        onChange={onProjectPILastNameChange}
                        fullWidth
                        id='cvb-dca-stage3PILastNameField'
                        disabled={disableTextInput}
                    />
                     {
                        isFormSubmitted && isEmpty(projectPILastName) &&
                        <ErrorText errorMessage="Enter valid last name" />
                    }
                </Grid>
            </Grid>
            <Grid container className={Classes.inputContainers} spacing={3}>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label htmlFor='credentials' id='cvb-dca-stage3PICredentialsLabel'>Credentials<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter credentials"
                        value={projectPICredentials}
                        onChange={onProjectPICredentialChange}
                        fullWidth
                        id='cvb-dca-stage3PICredentialsField'
                        disabled={disableTextInput}
                    />
                     {
                        isFormSubmitted && isEmpty(projectPICredentials) &&
                        <ErrorText errorMessage="Enter valid credential" />
                    }
                </Grid>
            </Grid>
            <Grid container className={Classes.inputContainers} spacing={3}>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label htmlFor='affiliation' id='cvb-dca-stage3PIInstituteLabel'>Institutes<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter institutes"
                        value={projectPIAffiliation}
                        onChange={onProjectPIAffiliationChange}
                        fullWidth
                        id='cvb-dca-stage3PIInstituteField'
                        disabled={disableTextInput}
                    />
                     {
                        isFormSubmitted && isEmpty(projectPIAffiliation) &&
                        <ErrorText errorMessage="Enter valid institution " />
                    }
                </Grid>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label htmlFor='email' id='cvb-dca-stage3PIInstituteEmailLabel'>Institutional Email<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter email"
                        value={projectPIInstituteEmail}
                        onChange={onProjectPIEmailChange}
                        fullWidth
                        id='cvb-dca-stage3PIInstituteEmailField'
                        disabled={disableTextInput}
                    />
                     {
                        isFormSubmitted && !validateEmailAddress(projectPIInstituteEmail) &&
                        <ErrorText errorMessage="Enter valid email" />
                    }
                </Grid>
            </Grid>
        </div>
    )
}

ProjectPI.defaultProps = {
    projectPIFirstName: '',
    projectPILastName: '',
    projectPIMiddleName: '',
    projectPICredentials: '',
    projectPIAffiliation: '',
    projectPIInstituteEmail: '',
    isFormSubmitted: false,
    currentDCAFormStage: '',
    setProjectPIFirstName: ()=>{},
    setProjectPILastName: ()=>{},
    setProjectPIMiddleName: ()=>{},
    setProjectPICredentials: ()=>{},
    setProjectPIInstituteName: ()=>{},
    setProjectPIInstituteEmail: ()=>{},
}

export default ProjectPI;
import React, { useState, useEffect } from 'react'
import SectionHeader from '../../commons/section-header'
import classes from './index.module.css'
import PricingContainer from '../../controls/pricing-container'
import { Grid } from '@mui/material'
import { navigate } from '@reach/router';
import { getPricingDetails } from '../../../api/pricing-api'
import CustomLoader from '../../commons/custom-loader';

const PricingConfigList = props => {

    const [pricingDetails, setPricingDetails] = useState([])
    const [isLoading, setIsLoading ] = useState(true);

    const sectionHeaderProps = {
        title: "Pricing"
    }
    
    const loadPricingDetails = async () => {
        try {
            const pricingDetailsResponse = await getPricingDetails();
            setPricingDetails(pricingDetailsResponse)
        }
        catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadPricingDetails();
    }, [])

    return (
        <div className={classes.mainContainer}>
            <SectionHeader
                open={props.open}
                sectionHeaderProps={sectionHeaderProps}
            />

            <Grid container xs={12} className={classes.container}>
                <Grid item xs={12} lg={12} className={classes.leftContainer}>
                    
                    <div className={classes.gridTitle}>
                        <h2 id='cvb-invoice-headingText' style={{color:'#23116C'}}>Adjust Pricing of Download Options</h2>
                    </div>
                        {/* <div className={classes.generateInvoice}>
                            <button
                            className={`medium-size-button solid-button ${classes.generateInvoiceButton}`}
                            onClick={() => navigate('/pricing/add-pricing-configuration')}> Add Configuration</button>
                        </div> */}
                </Grid>
                <Grid item lg={7} xs={12} className={classes.subtitleContainer}>
                    <div item style={{color:'#031235'}}>
                        Edit pricing of various download options. 
                        When changes are made, they are applied immediately to any new or currently pending jobs to download but they do not affect any previously downloaded jobs.
                    </div>
                </Grid>
                <Grid item xs={12} className={classes.pricingContainer}>
                    
                    {
                        isLoading ? <CustomLoader /> : <PricingContainer loadPricingDetails={loadPricingDetails} pricingDetails={pricingDetails} />
                    }
                </Grid>
            </Grid>

        </div>
    )
}

export default PricingConfigList;

import { Grid, Skeleton, Stack } from '@mui/material';
import React from 'react';

export const BarChartLoader = ({ limit = 10, height = 240 }) => {
  return (
    <Grid container alignItems="end" justifyContent="center" spacing={2}>
      {Array(limit)
        .fill('*')
        .map((_, i) => (
          <Grid item>
            <Skeleton
              width={24}
              height={(i + (1 % limit)) * (height / 10 - 5)}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export const PieChartLoader = ({ limit = 4 }) => {
  return (
    <Stack direction="row" spacing={1} p={2} alignItems="center">
      <Skeleton width={170} height={170} variant="circular" />
      <Stack spacing={0.5}>
        {Array(limit)
          .fill('*')
          .map((_, i) => (
            <Skeleton key={`pie_label_${i}`} width={80} height={20} />
          ))}
      </Stack>
    </Stack>
  );
};

import React from 'react'
import TrainingAndCertificate from '../training-and-certificate'
import Grid from '@mui/material/Grid'
import Classes from './index.module.css'
const EditTrainingAndCertificates = props => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <div className={Classes.trainingAndCertContainer}>
                    <TrainingAndCertificate editCertificateView />
                </div>
            </Grid>
        </Grid>
    )
}

export default EditTrainingAndCertificates;
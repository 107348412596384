import { combineReducers } from 'redux';
import communityReducer from './communityReducer';
import userReducer from './userReducer';
import userOnboardReducer from './userOnboardReducer';
import userSignupReducer from './userSignupReducers';
import editProfileReducer from './editProfileReducer';
import AnalysisDataReducer from './analysisDataReducers';
import exploreDataReducer from './exploreDataReducer';
import myStuffReducer from './myStuffReducer';
import globalReducer from './globalReducer';
import serviceAgreementReducer from './serviceAgreementReducer';
import dcaReducer from './dcaReducer';
import projectDetailsReducer from './projectDetailsReducers';
import ppiReducer from './projectPrimaryInstitutionalReducer';
import projectAdminReducer from './projectAdminReducer';
import projectPIReducer from './projectPIReducer';
import institutionalCertReducer from './institutionalCertReducers';
import zone3AuthorizedUserReducers from './zone3AuthorizedUserReducers';
import studyGallery from './studyGallery';
import AuthorizedSubmittersReducers from './authorizedDatasetSubmittersReducers';
import dataInventoryReducer from './dataInventoryReducers';
import difInstitutionReducer from './difInstitutionReducer';
import difPIReducer from './difPIReducers';
import difDataSharingReducer from './difDataSharingReducers';
import difOtherPersonalReducers from './difOtherPersonalReducers';
import difTimelineReducers from './difTimelineReducers';
import difIndividualDatasetReducer from './difIndividualDatasetReducers';
import userAdminReducer from './adminUser';
import workspaceReducer from './workspaceReducers';
import studyReducer from './difStudyReducer';
import homeReducer from './homeReducers';
import adminDashboardReducer from './adminDashboardReducers';
import topNavBarReducer from './topNavBarReducer';

export default combineReducers({
  user: userReducer,
  userOnboard: userOnboardReducer,
  userSignup: userSignupReducer,
  editProfile: editProfileReducer,
  analyzeData: AnalysisDataReducer,
  exploreData: exploreDataReducer,
  exploreHomeData: exploreDataReducer,
  myStuff: myStuffReducer,
  global: globalReducer,
  serviceAgreement: serviceAgreementReducer,
  community: communityReducer,
  dca: dcaReducer,
  projectDetails: projectDetailsReducer,
  ppi: ppiReducer,
  projectAdmin: projectAdminReducer,
  projectPI: projectPIReducer,
  institutionalCert: institutionalCertReducer,
  zone3AuthorizedUsers: zone3AuthorizedUserReducers,
  studyGallery: studyGallery,
  authorizeDatasetSubmitter: AuthorizedSubmittersReducers,
  dataInventory: dataInventoryReducer,
  difInstitution: difInstitutionReducer,
  difPI: difPIReducer,
  difDataSharing: difDataSharingReducer,
  difOP: difOtherPersonalReducers,
  difTimeline: difTimelineReducers,
  difIndividualDataset: difIndividualDatasetReducer,
  userAdminReducer: userAdminReducer,
  workspace: workspaceReducer,
  difStudy: studyReducer,
  home: homeReducer,
  adminDashboard: adminDashboardReducer,
  topNavBar: topNavBarReducer,
});

import axios from 'axios'
import get from 'lodash/get'
import { getCognitoIdToken } from '../utilityMethods';
import { BASE_URL } from '../../constants/google-login-urls';



export const getUserSearchAdminApi = async (searchString='', groupBy='') => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/iam/user/search?query=${searchString}&group_by=${groupBy}&sort_by=`;
    const res = await axios.get(url, {headers: header });

    return res?.data || [];
}
import Cart from './cart'
import { connect } from 'react-redux'
import { updateSnackBar } from '../../../redux/actions/globalActions'

const mapDispatchToProps = dispatch => {
    return {
        updateSnackBar: async (message, type) => dispatch(updateSnackBar(message, type))
    }
}

const mapStateToProps = state => {
    return {
        userDetails: state.user.userDetails,
        allWorkspaces: state.workspace.allWorkspaces,
        allWorkspacesCarts: state.workspace.allWorkspacesCarts
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
import React, { useEffect, useState, useMemo } from 'react';
import classes from './index.module.css'
import BackButtonIcon from '../../../assets/icons/left_arrow.png'
import Grid from '@mui/material/Grid'
import { navigate } from "@reach/router"
import DropZone from 'react-dropzone'
import DocumentIcon from '../../../assets/images/Doc.M.png'
import { uploadSubmissionFile, getStructuredDataModel } from '../../../api/upload-data-api'
import ViewStructuredDataStatus from '../view-structured-data-status'
import { getDataModelSchema } from '../../../api/data-model-api'
import DataModelGraph from '../../controls/data-model-graph'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownArrow from '../../../assets/icons/down-arrow.svg'
import ImgCases from '../../../assets/icons/cases.svg'
import ImgProjects from '../../../assets/icons/projects.svg'
import v4 from 'uuid/v4'
import { chunk } from 'lodash';
import TablePagination from '../../commons/table-pagination'
import isEmpty from 'lodash/isEmpty'

var randomColor = require('randomcolor');


const RenderUploadFileContent = props => {
    const [pageNumber, setPageNumber] = useState(0);
    const [userPageInputValue, setUserPageInputValue] = useState(1);
    const data = props.data;
    const tableHeaders = useMemo(() => {
        if (props.data?.length) {
            const keys = Object.keys(props.data[0])
            return keys;
        }
        return []
    }, [props.data])

    const getRowData = useMemo(() => {
        if (!props.data?.length > 0) {
            return []
        }
        const records = []
        for (let i = (pageNumber) * 10; i < (pageNumber + 1) * 10; i++) {
            if (props.data[i])
                records.push(props.data[i])
        }
        return records
    }, [pageNumber, props.data])

    const setTablePage = number => {
        setPageNumber(number);
    }
    const maxCount = props.data?.length ? Math.ceil(props.data.length / 10) : 0

    return (
        <Grid container className={classes.tablePreviewContent}>
            <Grid item xs={9} lg={12} md={12}>
                <div >
                    <TableContainer
                        component={Paper}
                        elevation={0}
                        id='cvb-structuredUploadFiles-PreviewTableContainer'
                        className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                {
                                    tableHeaders?.length > 0 &&
                                    tableHeaders.map((item, index) => {
                                        return <TableCell key={v4()} id={`cvb-structuredUploadFiles-tableHeaders-${index}`} className={classes.tablePreviewHeader}>{item}</TableCell>
                                    })
                                }
                            </TableHead>
                            <TableBody>
                                {
                                    getRowData?.map((item, index) => {
                                        return <TableRow key={v4()}>
                                            {
                                                tableHeaders?.map(key => {
                                                    return <TableCell key={v4()} id={`cvb-structuredUploadFiles-tableCell-${index}`}>{item[key]}</TableCell>
                                                })
                                            }
                                        </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={classes.tablePagination}>
                        <TablePagination
                            maxCount={maxCount}
                            page={pageNumber}
                            setPage={setTablePage}
                        />
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

function StructuredUploadFiles(props) {
    const [fileDetails, setFileDetails] = useState(null)
    const [fileDetailsJson, setFileDetailsJson] = useState([])
    const [uploadResponse, setUploadResponse] = useState(null)
    const [dictionaryData, setDictionaryData] = useState(null)
    const [itemCollection, setItemCollection] = useState([])
    const [showPropertiesModal, setShowPropertiesModal] = useState(false)
    const [constructedGraphData, setConstructedGraphData] = useState({})
    const [selectedItem, setSelectedItem] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [dataDictUploadPercentage, setDataDictUploadPercentage] = useState(-1);
    const [uploadType, setUploadType] = useState("addNewData")

    const getSearchData = (graphData) => {
        let pointingIndex = 0;
        let graphPoints = [];
        const getNodePerLevel = (item, parentID) => {
            const pointKeys = Object.keys(item)
            const points = pointKeys.map((element, index) => {
              return {
                label: element,
                ID: index + pointingIndex,
                parentID: parentID,
                isVisible: item[element]?.isVisible || false,
                subGroups: item[element]?.subGroups,
                children: item[element]?.children?.length || 0,
                isCategory: item[element]?.isCategory || false,
                isDefault: item[element]?.isDefault || false,
                headerColor: item[element]?.headerColor,
                backgroundShade: item[element]?.backgroundShade,
                availableCount: item[element]?.availableCount,
                description: item[element]?.description || '',
                nodeProperties: item[element]?.nodeProperties || [],
                displayTitle: item[element]?.displayTitle,
                shouldBeHidden: !!item[element]?.shouldBeHidden
              }
            })
            pointingIndex = pointingIndex + points.length
            graphPoints = [...graphPoints, ...points]
            for (let point of points) {
              const pointItems = item[point?.label].children || []
              for (let pointItem of pointItems) {
                getNodePerLevel(pointItem, point.ID)
              }
            }
          }
          getNodePerLevel(graphData, null);
          setItemCollection(graphPoints);
    }

    useEffect(() => {
        if(!isEmpty(constructedGraphData)){
            getSearchData(constructedGraphData);
        }
    }, [constructedGraphData])

    function tsvJSON(tsv) {
        const lines = tsv.split('\n');
        const headers = lines.shift().split('\t');
        return lines.map(line => {
            const data = line.split('\t');
            return headers.reduce((obj, nextKey, index) => {
                obj[nextKey] = data[index];
                return obj;
            }, {});
        });
    }

    function csvToJson(csv) {
        var lines = csv.split("\n");
        var result = [];
        var headers = lines[0].split(",");
        for (var i = 1; i < lines.length; i++) {
            let obj = {};
            let currentLine = lines[i].split(",");
            for (var j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentLine[j];
            }
            result.push(obj);
        }
        return result;
    }

    const convertJsonToTSVFormat = (json) => {
        let fields = Object.keys(json[0])
        let replacer = function (key, value) { return value === null ? '' : value }
        let tsv = json.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] || ''
            }).join('\t')
        })
        tsv.unshift(fields.join('\t')) // add header column
        tsv = tsv.join('\r\n');
        return tsv;
    }

    const chunkUploadHandler = async (jsonData) => {
        try {
            const CHUNK_SIZE = 20;
            let chunks = [];
            const id = props.id;
            const splitId = id.split("-")
            let path = ''
            if (splitId.length == 2) {
                path = splitId.join("/")
            } else {
                path = splitId?.[0] + "/" + splitId.splice(1, splitId.length).join("-")
            }
            for (let i = 0; i < jsonData.length; i = i + CHUNK_SIZE) {
                let chunk = []
                for (let y = i; y < i + CHUNK_SIZE; y++) {
                    if (jsonData[y]) {
                        chunk.push(jsonData[y])
                    }
                }
                chunks.push(chunk)
            }
            let promises = []
            let submissionRequest = []
            setDataDictUploadPercentage(0)
            for (let x = 0; x < chunks.length; x++) {
                const chunk = chunks[x]
                let chunkCSVData = null
                try {
                    chunkCSVData = convertJsonToTSVFormat(chunk);
                }
                catch (error) {
                    console.log('checking', error)
                }

                try {
                    const response = await uploadSubmissionFile(path, chunkCSVData, uploadType);
                    for (let i = 0; i < chunk.length; i++) {
                        if (response?.entities?.[i]?.errors?.length == 0) {
                            submissionRequest.push({
                                id: chunk[i].file_name || chunk[i].submitter_id,
                                isSuccess: true,
                                errorMessage: ""
                            })
                        }
                        else {
                            let errorMessages = response?.entities?.[i]?.errors?.map(item => item.message || '') || []
                            let messageString = ''
                            for (let message of errorMessages) {
                                messageString += message
                            }
                            submissionRequest.push({
                                id: chunk[i].submitter_id,
                                isSuccess: false,
                                errorMessage: messageString || "Something went wrong"
                            })
                        }
                    }
                    setDataDictUploadPercentage(x * 100 / chunks.length)
                }
                catch (error) {
                    if (error?.response?.status === 504 || error?.response?.status === 401) {
                        x--;
                        continue;
                    }
                    for (let i = 0; i < chunk.length; i++) {
                        if (error?.response?.data?.entities?.[i]?.errors?.length == 0) {
                            submissionRequest.push({
                                id: chunk[i].submitter_id,
                                isSuccess: true,
                                errorMessage: ""
                            })
                        }
                        else {
                            let errorMessages = error?.response?.data?.entities?.[i]?.errors?.map(item => item.message || '') || []
                            let messageString = ''
                            for (let message of errorMessages) {
                                messageString += message
                            }
                            submissionRequest.push({
                                id: chunk[i].submitter_id,
                                isSuccess: false,
                                errorMessage: messageString || (error?.response?.data?.message || "Something went wrong")
                            })
                        }

                    }
                    setDataDictUploadPercentage(x * 100 / chunks.length)
                }
            }
            setUploadResponse(submissionRequest);

        }
        catch (error) {
            console.log(error)
        }

    }

    const handleOnSubmit = () => {
        try {
            setIsUploading(true)
            const chosenFile = fileDetails?.[0];
            const fileNameSplit = chosenFile.name.split(".")
            const fileExtension = fileNameSplit[fileNameSplit.length - 1];
            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    const data = e ? e.target.result : reader.content;
                    let jsonData = fileExtension == "tsv" ? tsvJSON(data.toString()) : csvToJson(data)
                    jsonData = jsonData.filter(x => {
                        const allValues = Object.values(x || {})
                        return allValues.find(y => y)
                    })
                    await chunkUploadHandler(jsonData)
                    setIsUploading(false)
                }
                catch (error) {
                    if (error?.response?.data && (error?.response?.status === 400)) {
                        setUploadResponse(error?.response?.data)
                    } else {
                        props.updateSnackBar('Something went wrong.', 'Error');
                        setFileDetails(null)
                    }
                    setIsUploading(false)
                }

            };
            reader.readAsText(chosenFile);
        }
        catch (error) {
            setIsUploading(false);
            console.log(error)
        }
    }
    const onDocumentDrop = async (file) => {
        setFileDetails(file);
        setFileDetailsJson([])
        const chosenFile = file?.[0];
        const fileNameSplit = chosenFile.name.split(".")
        const fileExtension = fileNameSplit[fileNameSplit.length - 1];
        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = e ? e.target.result : reader.content;
                let jsonData = fileExtension == "tsv" ? tsvJSON(data.toString()) : csvToJson(data.toString())
                jsonData = jsonData.filter(x => {
                    const allValues = Object.values(x || {})
                    return allValues.find(y => y)
                })
                setFileDetailsJson(jsonData)
            }
            catch (error) {
                console.log("Error", error)
            }
        };
        reader.readAsText(chosenFile);
    }

    const affectedRecords = useMemo(() => {
        if (uploadResponse?.length > 0) {
            const totalEntries = uploadResponse.length;
            const successfulEntries = uploadResponse.filter(item => item?.isSuccess).length
            return {
                successfulEntries: successfulEntries,
                failedEntries: totalEntries - successfulEntries,
                totalEntries: totalEntries
            }
        }
    }, [uploadResponse])

    const failureRecords = useMemo(() => {
        if (uploadResponse) {
            const errors = uploadResponse?.filter(item => {
                return !item?.isSuccess
            })
            return errors;
        }
        return []
    }, [uploadResponse])


    const getInitialLoad = async () => {
        try {
            let dictionary = await getDataModelSchema();
            const keys = Object.keys(dictionary);
            for (let key of keys) {
                if (key.startsWith("_") || !(dictionary[key]?.links?.length > 0) || key.includes("data_release")) {
                    delete dictionary[key]
                }
            }
            formatData(dictionary);
        }
        catch (error) {
            console.log(error)
        }
    }

    const formConstructedGraphData = (modalData, availableSubmissionData) => {
        const keys = Object.keys(modalData);
        const currentCategories = {}
        for (let key of keys) {
            if (key === "project" || key === "program" ||
                key === "case" || key === "study") {
                continue;
            }
            if (availableSubmissionData) {
                const matchedElement = Object.keys(availableSubmissionData).find(item => item === `_${key}_count`);
                if (!matchedElement || availableSubmissionData[matchedElement] === 0) {
                    continue;
                }
            }
            let category = modalData[key].category
            const group = modalData[key]?.links?.[0]?.subgroup
            const uniqColor = randomColor({ luminosity: 'bright' });
            if (category) {
                if (currentCategories[category]) {
                    currentCategories[category].children.push({
                        [key]: {
                            children: [],
                            subGroups: group,
                            headerColor: currentCategories[category].headerColor,
                            backgroundShade: currentCategories[category].backgroundShade,
                            availableCount: availableSubmissionData[`_${key}_count`]
                        }
                    })
                } else {
                    currentCategories[category] = {
                        children: [
                            {
                                [key]: {
                                    children: [],
                                    subGroups: group,
                                    headerColor: uniqColor,
                                    backgroundShade: uniqColor + "40",
                                    availableCount: availableSubmissionData[`_${key}_count`]
                                }
                            }
                        ],
                        isVisible: true,
                        isCategory: true,
                        headerColor: uniqColor,
                        backgroundShade: uniqColor + "40"
                    }
                }
            }
        }
        let mainPathElements = ['project', 'study', 'case']
        mainPathElements = mainPathElements.filter(item => availableSubmissionData[`_${item}_count`] > 0)
        let graphData = {}
        for (let i = mainPathElements.length - 1; i >= 0; i--) {
            const uniqColor = randomColor({ luminosity: 'bright' });
            if (i === mainPathElements.length - 1) {
                graphData[mainPathElements[i]] = {
                    children: [
                        {
                            ...currentCategories
                        }
                    ],
                    isVisible: true,
                    isDefault: true,
                    headerColor: uniqColor,
                    backgroundShade: uniqColor + "40",
                    availableCount: availableSubmissionData[`_${mainPathElements[i]}_count`]
                }
            } else {
                graphData = {
                    [mainPathElements[i]]: {
                        children: [graphData],
                        isVisible: true,
                        isDefault: true,
                        headerColor: uniqColor,
                        backgroundShade: uniqColor + "40",
                        availableCount: availableSubmissionData[`_${mainPathElements[i]}_count`]
                    }
                }
            }
        }
        setConstructedGraphData(graphData)
    }

    useEffect(() => {
        getInitialLoad()
    }, []);

    const formatData = async (dictionary) => {
        try {
            const dictEntries = Object.keys(dictionary);
            let queryString = "{"
            let countFields = []
            for (let key of dictEntries) {
                countFields.push(`_${key}_count (project_id:\"${props.id}\")`)
            }
            for (let key of dictEntries) {
                const assignedLinks = dictionary[key]?.links || [];
                for (let link of assignedLinks) {
                    if (link.name) {
                        countFields.push(`${key}_${link?.name || ''}_to_${link.target_type}_link: ${key}(with_links:[\"${link.name}\"], first: 1, project_id: \"${props.id}"\){submitter_id}`)
                    }
                    else if (link?.subgroup?.length > 0) {
                        for (let subLink of link.subgroup) {
                            countFields.push(`${key}_${subLink?.name || ''}_to_${subLink.target_type}_link: ${key}(with_links:[\"${subLink.name}\"], first: 1, project_id: \"${props.id}"\){submitter_id}`)
                        }
                    }
                }
            }
            queryString += countFields.join(",");
            queryString += "}"
            const response = await getStructuredDataModel(queryString);
            if (response) {
                formConstructedGraphData(dictionary, response)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const onUploadTypeChange = value => {
        setUploadType(value)
    }


    return (
        <div>
            <div className={classes.innerContainer}>
                <Grid container className={classes.searchBackground}>
                    {
                        uploadResponse ?
                            <ViewStructuredDataStatus affectedRecords={affectedRecords} failureRecords={failureRecords} />
                            :
                            <>
                                <Grid item xs={12} lg={12} md={12}>
                                    <div className={classes.sectionHeaderContainer}>
                                        <p id='cvb-structuredUploadFiles-backButtonText' onClick={() => { navigate("/my-uploads/structured-data/upload-data") }} className={classes.backButton}>
                                            <img id='cvb-structuredUploadFiles-backButtonIcon' src={BackButtonIcon} alt="black button" /> Back</p>
                                        <div className={classes.gridTitle}><h2>Upload Structured Data</h2></div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12}>
                                    <label id='cvb-structuredUploadFiles-selectUploadFiles'>Select Upload Type</label>
                                    <Grid container>
                                        <Grid item xs={2} lg={2} md={2} className={classes.radioCheckContainer}
                                            onClick={() => onUploadTypeChange('addNewData')}>
                                            <label id='cvb-structuredUploadFiles-selectUploadAddNewText' className={classes.radioContainer}>Add New Data</label>
                                            <input name="addNewData" type="radio"
                                                checked={uploadType === 'addNewData'} value='addNewData'
                                                id='cvb-structuredUploadFiles-selectUploadAddNewRadioButton'
                                                onChange={() => onUploadTypeChange('addNewData')} />
                                            <span className={classes.checkmark}></span>
                                        </Grid>
                                        <Grid item xs={2} lg={2} md={2} className={classes.radioCheckContainer}
                                            onClick={() => onUploadTypeChange('updateExistingData')}>
                                            <label className={classes.radioContainer}
                                                id='cvb-structuredUploadFiles-selectUploadExistingDataText'>Update Existing Data</label>
                                            <input name="updateData" type="radio"
                                                checked={uploadType === 'updateExistingData'} value='updateExistingData'
                                                id='cvb-structuredUploadFiles-selectUploadExistingDataRadioButton'
                                                onChange={() => onUploadTypeChange('updateExistingData')} />
                                            <span className={classes.checkmark}></span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} md={3} lg={3}>
                                    <div className={classes.uploadFileContainer}>
                                        <p id='cvb-structuredUploadFiles-uploadFilesText' className={classes.uploadFileText}>Upload File</p>
                                        <p id='cvb-structuredUploadFiles-uploadCSVTSVText' className={classes.uploadInfo}>Upload CSV or TSV.
                                        </p>
                                        <div className={classes.dropZoneButtonContainer} id='cvb-structuredUploadFiles-dropZoneContainer'>
                                            <DropZone
                                                accept=".csv,.tsv"
                                                onDrop={(acceptedFiles) => onDocumentDrop(acceptedFiles)}
                                                multiple={false}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <div className={classes.uploadImageDiv}>
                                                            <img src={DocumentIcon} alt='document' />
                                                            <p id='cvb-structuredUploadFiles-dropZoneInnerContainerDragText'
                                                                className={classes.chooseFileText}>
                                                                Drag and drop <br /> or
                                                                <span>
                                                                    <button id='cvb-structuredUploadFiles-dropZoneInnerContainerSelectFilesButton' className={`${classes.chooseFileButton}`}>
                                                                        Select files
                                                                    </button>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </DropZone>

                                        </div>
                                        {
                                            dataDictUploadPercentage != -1 &&
                                            <progress className={classes.progressBar} value={dataDictUploadPercentage} max={100} />
                                        }
                                        <div className={classes.agreementFormButtons}>
                                            <button id='cvb-structuredUploadFiles-cancelButton' className={`no-outlined-button`} onClick={() => { setFileDetails(null); setFileDetailsJson([]) }} disabled={isUploading}>Cancel</button>
                                            <button id='cvb-structuredUploadFiles-submitButton' className={`medium-size-button solid-button ${classes.requestAccessButton}`} disabled={isUploading} onClick={handleOnSubmit}>Submit</button>
                                        </div>
                                    </div>
                                    <div>
                                        {/* <p className={classes.uploadFileText}>Uploaded files</p> */}

                                        <p id='cvb-structuredUploadFiles-uploadFileNameText' className={classes.uploadInfo}>{fileDetails?.[0]?.name}</p>
                                    </div>
                                </Grid>
                                {
                                    fileDetailsJson?.length > 0 &&
                                    <RenderUploadFileContent data={fileDetailsJson} />
                                }
                                <Grid container>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <div className={classes.dataModelGridContainer}>
                                            <DataModelGraph
                                                setItemCollection={setItemCollection}
                                                graphData={constructedGraphData}
                                                setShowPropertiesModal={setShowPropertiesModal}
                                                setSelectedItem={setSelectedItem} 
                                                itemCollection={itemCollection}
                                                />
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                    }
                </Grid>
            </div>
        </div >
    )
}

export default StructuredUploadFiles;

import { SET_USER_DETAILS, SET_USER_LOGIN } from '../action-types';
import { setBannerCookie } from '../actions/homeActions';
import { setUserLogin } from '../actions/userActions';
//AB: TODO Replace Mock Data
import userMock from '../../mock-data/userMock';

export default store => next => action => {
    console.log(action);

    switch (action.type) {
        case SET_USER_DETAILS:
            if (!action.payload.email) {
                action.payload = userMock;
                next(setBannerCookie(false));
                break;
            }
        case SET_USER_LOGIN:
            if (!action.payload) {
                action.payload = { username: "a900dd61-27d3-42fc-b5f1-5e18a2ab95e1" }
            }
            break;
    }


    next(action);
}
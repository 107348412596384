import React from 'react';
import classes from './index.module.css'
import Modal from '../modal'


const CookiePolicy = (props) => {
const { open, handleClose, dialogProps } = props;
return (
<Modal open={open} handleClose={handleClose} dialogProps={dialogProps} disableTitleDivider disableBottomHorizontalDivider containerClass="private-policy-content">
<div className={classes.container}>
<b><span
 >1. Introduction</span></b>
<p><span 
>&nbsp;</span></p>
<p  ><span 
>This Global Cookie Policy should be
read in conjunction with the BRAIN Commons Cookie Notice.</span></p>

<p  ><span 
>The BRAIN Commons (BC) approach to
data privacy, data protection and security is rooted within the European
tradition and although there are no specific legal requirements for the use of
cookies on a federal basis across the United States, we observe global
standards in the use of these tracking technologies as described below, and
this provides protection for everyone who visits the BC website and platform.</span></p>

<p><span 
>&nbsp;</span></p>

<p><b><span><span >2.</span></span></b><b><span
 > Governing laws and
regulations</span></b></p>

<p  ><span 
>This BRAIN Commons Global Cookie Policy
complies with the following laws and regulations:</span></p>

<ul type="disc">
    <li>Art. 4(11), 13, 14, and
Recitals 94 and 30, General Data Protection Regulation (Regulation (EU)
2016/679) ('GDPR').</li>
    <li>

    Art. 5(3) and 15(a), and
Recitals 24 and 25, Directive on Privacy and Electronic Communications
(2002/58/EC) (as amended) ('<span >ePrivacy</span> Directive').
    </li>
    <li>
    Please note that the
Proposed Regulation on Privacy and Electronic Communications (2017/003(COD))
(21 January 2017) ('Draft <span >ePrivacy</span> Regulation'),
which would replace the <span >ePrivacy</span> Directive is under
consideration by the European Parliament and is expected to be adopted at the
end of 2021.
    </li>
    <li>
    Section 6, Privacy and
Electronic Communications (EC Directive) Regulations 2003 (‘PECR’) (UK law) 
    </li>
    <li>
    Data Protection Act 2018 (DPA
2018) (UK law).
    </li>
    <li>
    The Consumer Protection
from Unfair Trading Regulations 2008 (UK law).
    </li>
</ul>
<p  ><b><span 
><span >3. </span></span></b><b><span
 >Definitions</span></b></p>

<p  ><i><span 
>“Affiliate” </span></i><span
 >— an affiliate is a
website operator who can send traffic to the BC website and Platform, using
links from another website or social media platform. </span></p>

<p  ><i><span 
>“Consent”</span></i><span 
> - any freely given, specific,
informed, and unambiguous indication of the user’s (data subject's) wishes by
which she or he, by a statement or by a clear affirmative action, signifies
agreement to the processing of personal data relating to her/him (Art. 4(11), GDPR).</span></p>

<p  ><i><span 
>“First-Party and Third-Party Cookies”</span></i><span
 > — whether a cookie is
‘first’ or ‘third’ party refers to the domain placing the cookie. First-Party
cookies are those set by BC when visited by the user at the time — the website
displayed in the URL window. Third-Party cookies are cookies that are set by a
domain other than us. If a user visits the BC website or Platform and another
entity sets a cookie through the BC website or Platform, then this would be a
Third-Party cookie. </span></p>

<p  ><i><span 
>“Persistent Cookies”</span></i><span
 > — these cookies remain on
a user’s device for the period specified in the cookie. </span></p>

<p  ><i><span 
>“Session Cookies”</span></i><span
 > — these cookies allow BC to
link the actions of a user during a browser session. A browser session starts
when a user opens the browser window and finishes when they close the browser.
Session cookies are created temporarily and once you close the browser, all
session cookies are deleted.</span></p>

<p  ><i><span 
>“Social Media Cookies”</span></i><span
 > – these cookies are also
known as “analytics cookies” and collect information about how visitors use the
BC website and Platform. This information is aggregated to provide us insights
in how we can improve the experience and service we deliver to you.</span></p>

<p  ><span 
>&nbsp;</span></p>

<p  ><b><span 
><span >4. </span></span></b><b><span
 >Consent &amp; Cookie
Policy</span></b></p>

<p  ><span 
>In accordance with Section 6(1) and
(2), PECR, BC must not store or gain access to information stored, in the
terminal equipment of a subscriber or user, unless the subscriber or user of
that terminal equipment is provided with clear and comprehensive information
about the purposes of the storage of, or access to, that information; and has
given her/his consent.</span></p>

<p  ><span 
>Furthermore, consent may be signified
by a subscriber who amends or sets controls on the internet browser which the
subscriber uses or by using another application or programme to signify consent
(Section 6(3A), PECR).</span></p>

<p  ><span 
>There are two exemptions to the
collection of consent under Section 6 of the PECR, known as the ‘communication
exemption’ and ‘strictly necessary’ exemption.</span></p>

<p  ><span 
>The consent requirement won’t apply
to the technical storage of, or access to, information for the sole purpose of
carrying out the transmission of a communication over an electronic
communications network, or where such storage or access is strictly necessary
for the provision of an information society service requested by the subscriber
or user (Section 6(4), PECR).</span></p>

<p  ><span 
>The definition of consent under the GDPR
applies. To be valid, consent must be freely given, specific, and informed. It
must involve some form of unambiguous positive action, for example, ticking a
box or clicking a link, and the user must fully understand that they’re giving
consent. </span></p>

<p  ><span 
>Similarly, there can’t be
non-essential cookies set on a website's homepage before the user has consented
to them. Consent doesn’t necessarily have to be explicit consent. </span></p>

<p  ><span 
>However, consent must be given by a
clear, positive action. To ensure that consent is freely given, users should
have the means to enable or disable non-essential cookies. Particular care is
taken by BC to ensure clear and specific consent for more privacy-intrusive
cookies, such as those collecting sensitive data or cookies used for
behavioural tracking.</span></p>

<p  ><span 
>PECR doesn’t set out exactly what
information must be provided or how it must be provided. The only requirement
is that it must be clear and comprehensive information about the purposes for
the placement of cookies. </span></p>

<p  ><span 
>As the data controller, BC explains
the way the cookies and other similar technologies work, their use, how they
process personal data.</span></p>

<p  ><span 
>Further information is available on
the <u><span >Cookie Notice</span></u></span></p>

<p  ><span 
>&nbsp;</span></p>

<p  ><b><span 
><span >5. </span></span></b><b><span
 >Cookies &amp; Third
Parties</span></b></p>

<p><span 
>Third-party cookies are set by a
domain other than the one the user is visiting. This typically occurs when the
website incorporates elements from other sites, such as images, social media
plugins, or advertising. When the browser or other software fetches these
elements from the other sites, they can set cookies as well.</span></p>

<p><span 
>Where the BC website and Platform sets
third-party cookies, both the first party and the third party have a
responsibility for ensuring users are clearly informed about cookies and for
obtaining consent. In practice, it’s more difficult for a third party who has
less direct control on the interface with the user to achieve this. Users are
likely to address any concerns or complaints they have to the person they can
identify or have the relationship with.</span></p>

<p  ><span 
>&nbsp;</span></p>

<p  ><b><span 
><span>6. </span></span></b><b><span
 >Cookie Retention</span></b><span
 ></span></p>

<p><span 
>Cookie retention depends on the
purpose of the cookie. It’s also important to consider cookie duration because
this can affect the application of the exemptions under Section 6(4), PECR. </span></p>

<p  ><span 
>At BC, we ensure that the use of cookies
is proportionate in relation to the user’s intended outcomes and limited to
what’s necessary to achieve the user’s purpose, which is likely to lead towards
the determination of the duration. As a general rule, the exemptions in Section
6(4), PECR are more likely to apply to session cookies (defined as cookies that
expire at the end of a browser session), which last until the user has closed
their browser, or just slightly afterwards.</span></p>

<p  ><span 
>Further information is available on
the <u><span >Cookie
Notice</span></u></span></p>

<p  ><span 
>&nbsp;</span></p>

<p  ><b><span 
><span >7. </span></span></b><b><span
 >Other considerations</span></b></p>

<p  ><u><span 
>Cookie walls</span></u></p>

<p  ><span 
>BC website doesn’t use a cookie wall.
We do require users to agree or accept the setting of cookies before they can
access the BC Platform.</span></p>

<p  ><u><span 
>Continue browsing</span></u></p>

<p  ><span 
>BC doesn’t assume that use of the BC
website or Platform constitutes valid consent for the processing of personal
data.</span></p>

<p  ><u><span 
>Terms and conditions</span></u></p>

<p  ><span 
>BC takes your privacy, data
protection and security very seriously. Consent must be given separately from
other matters.</span></p>

<p  ><u><span 
>Penalties </span></u></p>

<p  ><span 
>Within the European Union, Regulatory
Action Policy makes clear that any formal action must be a proportionate
response to the issue it seeks to address and that monetary penalties will be
reserved for the most serious infringements of the PECR.</span></p>

<p  ><span 
>In addition, EU Member States shall
lay down the rules on penalties, including criminal sanctions where
appropriate, applicable to infringements of the national provisions adopted
pursuant to the <span >ePrivacy</span> Directive and shall take all
measures necessary to ensure that they are implemented. The penalties must be
effective, proportionate, and dissuasive (Article 15(a), <span >ePrivacy</span>
Directive) and this is unlikely to change when the e-PR is adopted (expected at
the end of 2021).</span></p>

<p  ><span 
>&nbsp;</span></p>

<p><b><span 
><span >8. </span></span></b><b><span
 >Further Information</span></b></p>

<p  ><span 
>This Cookies Policy is kept under constant
review by the Global Privacy Advisor at BRAIN Commons and should you have any
questions about this policy, please contact us: </span><span ><a
href="mailto:dataprivacy@braincommons.com"><span>dataprivacy@braincommons.com</span></a></span><span
 ></span></p>

<p  ><span 
>&nbsp;</span></p>

</div>
</Modal >
)
}

export default CookiePolicy;

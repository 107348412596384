import {
    ADD_DIF_INDIVIDUAL_DATASET,
    SET_DIF_INDIVIDUAL_DATASET_ACTIVE_INDEX,
    UPDATE_DI_DATASET_NAME,
    UPDATE_DI_OWNER_TYPE,
    UPDATE_DI_OWNER_NAME,
    UPDATE_DI_OWNER_TITLE,
    UPDATE_DI_OWNER_AFFILIATION,
    UPDATE_DI_OWNER_EMAIL,
    UPDATE_DI_ABSTRACT,
    UPDATE_DI_AGENCY_NAME,
    UPDATE_DI_AGENCY_NUMBER,
    UPDATE_DI_DISEASE_AREA,
    UPDATE_DI_CURATED_TO_STANDARD,
    UPDATE_DI_STANDARD,
    UPDATE_DI_RESTRICTED_ACCESS,
    UPDATE_DI_ALLOW_DATA_DOWNLOAD,
    UPDATE_DI_NUMBER_OF_SAMPLES,
    UPDATE_DI_ESTIMATED_SIZE,
    UPDATE_DI_TIME_SERIES,
    UPDATE_DI_DATASET_COMPLETE,
    UPDATE_DI_UPLOAD_COMPLETE_DATE,
    UPDATE_DI_SUBJECT_SPACE_STUDY,
    UPDATE_DI_SUBJECT_SPACE_CAPTURE_INFO,
    UPDATE_DI_BIOSPECIMEN_STUDY,
    UPDATE_DI_BIOSPECIMEN_CAPTURE_INFO,
    UPDATE_CAN_YOU_PROVIDE_DATA_DICT,
    UPDATE_OTHER_IMAGING_TEXT,
    UPDATE_OTHER_PRECLINICAL_TEXT,
    UPDATE_OTHER_GENOMIC_TEXT,
    UPDATE_OTHER_PROTEOMIC_TEXT,
    UPDATE_OTHER_WHOLE_GENOME_TEXT,
    UPDATE_IMAGING_OPTION,
    UPDATE_PRECLINICAL_OPTION,
    UPDATE_GENOMIC_OPTION,
    UPDATE_PROTEOMIC_OPTION,
    UPDATE_WHOLE_GENOME_OPTION,
    SET_INDIVIDUAL_DATASET_FORM_DATA,
    UPDATE_OTHER_TYPE_OF_DATA_TEXT,
    UPDATE_IS_CLINICAL,
    UPDATE_IS_PUBLICATION_PRESENT,
    ADD_DIF_PUBLICATIONS,
    UPDATE_PUBLICATION_NAME,
    UPDATE_PUBLICATION_URL,
    REMOVE_DIF_PUBLICATIONS,
    SET_DATA_DICT_DOCUMENTS,
    SET_DATA_DICT_RELATIONSHIP_DOCUMENTS,
    REMOVE_SELECTED_DATASET_TAB,
    RESET_INDIVIDUAL_DATASET_FORM,
    UPDATE_DI_DATASET_MORE_DATA_ADDED,
    UPDATE_MODAL_SYSTEM,
    UPDATE_MODAL_SYSTEM_OTHER_TEXT,
    UPDATE_DISEASE_FEATURE,
    UPDATE_TYPE_OF_DATA_DISEASE_AREA,
    UPDATE_TYPE_OF_DATA_DISEASE_AREA_TEXT,
    UPDATE_METABOLOMIC_OPTION,
    UPDATE_BEHAVIORAL_TEXT,
    UPDATE_PHYSIOLOGICAL_OUTCOME_TEXT,
    UPDATE_TISSUE_SAMPLE,
    UPDATE_BEHAVIORAL_ASSESSMENT,
    UPDATE_WEARABLE_SENSORS,
    UPDATE_DERIVED_SENSOR_DATA,
    UPDATE_DO_YOU_NEED_DATA_CURATED_SERVICE,
    UPDATE_IS_DATA_CURATED,
    UPDATE_CURATION_DATE,
    UPDATE_DATASET_CURATOR_NAME,
    UPDATE_DATASET_CURATOR_EMAIL,
    UPDATE_DATASET_CURATOR_INSTITUTION,
    UPDATE_OTHER_METABOLOMIC_TEXT,
    UPDATE_WEARABLE_SENSORS_OTHER_TEXT,
    UPDATE_TISSUE_SAMPLE_OTHER_TEXT,
    UPDATE_DERIVED_SENSOR_DATA_OTHER_TEXT,
    REMOVE_DATA_DICT_DOCUMENTS
} from '../action-types'
    
export const addNewDataset = (value) => {
    return {
        type: ADD_DIF_INDIVIDUAL_DATASET,
        payload: value
    }
}
export const setDatasetCurrentActiveTab = (value) => {
    return {
        type: SET_DIF_INDIVIDUAL_DATASET_ACTIVE_INDEX,
        payload: value
    }
}
export const updateDIDatasetName = (value, id) => {
    return {
        type: UPDATE_DI_DATASET_NAME,
        payload: {
            value,
            id
        }
    }
}
export const updateDIOwnerType = (value, id) => {
    return {
        type: UPDATE_DI_OWNER_TYPE,
        payload: {
            value,
            id
        }
    }
}
export const updateDIOwnerName = (value, id) => {
    return {
        type: UPDATE_DI_OWNER_NAME,
        payload: {
            value,
            id
        }
    }
}
export const updateDIOwnerTitle = (value, id) => {
    return {
        type: UPDATE_DI_OWNER_TITLE,
        payload: {
            value,
            id
        }
    }
}
export const updateDIOwnerAffiliation = (value, id) => {
    return {
        type: UPDATE_DI_OWNER_AFFILIATION,
        payload: {
            value,
            id
        }
    }
}
export const updateDIOwnerEmail = (value, id) => {
    return {
        type: UPDATE_DI_OWNER_EMAIL,
        payload: {
            value,
            id
        }
    }
}
export const updateDIAbstract = (value, id) => {
    return {
        type: UPDATE_DI_ABSTRACT,
        payload: {
            value,
            id
        }
    }
}
export const updateDIAgencyName = (value, id) => {
    return {
        type: UPDATE_DI_AGENCY_NAME,
        payload: {
            value,
            id
        }
    }
}
export const updateDIAgencyNumber = (value, id) => {
    return {
        type: UPDATE_DI_AGENCY_NUMBER,
        payload: {
            value,
            id
        }
    }
}
export const updateDIDiseaseArea = (value, id) => {
    return {
        type: UPDATE_DI_DISEASE_AREA,
        payload: {
            value,
            id
        }
    }
}
export const updateDICuratedToStandard = (value, id) => {
    return {
        type: UPDATE_DI_CURATED_TO_STANDARD,
        payload: {
            value,
            id
        }
    }
}
export const updateDIStandard = (value, id) => {
    return {
        type: UPDATE_DI_STANDARD,
        payload: {
            value,
            id
        }
    }
}
export const updateDIRestrictDataAccess = (value, id) => {
    return {
        type: UPDATE_DI_RESTRICTED_ACCESS,
        payload: {
            value,
            id
        }
    }
}
export const updateDIAllowDataDownload = (value, id) => {
    return {
        type: UPDATE_DI_ALLOW_DATA_DOWNLOAD,
        payload: {
            value,
            id
        }
    }
}
export const updateDINumberOfSample = (value, id) => {
    return {
        type: UPDATE_DI_NUMBER_OF_SAMPLES,
        payload: {
            value,
            id
        }
    }
}
export const updateDIEstimatedSize = (value, id) => {
    return {
        type: UPDATE_DI_ESTIMATED_SIZE,
        payload: {
            value,
            id
        }
    }
}
export const updateDITimeSeriesChange = (value, id) => {
    return {
        type: UPDATE_DI_TIME_SERIES,
        payload: {
            value,
            id
        }
    }
}
export const updateDIDatasetComplete = (value, id) => {
    return {
        type: UPDATE_DI_DATASET_COMPLETE,
        payload: {
            value,
            id
        }
    }
}
export const updateDIUploadCompletedDate = (value, id) => {
    return {
        type: UPDATE_DI_UPLOAD_COMPLETE_DATE,
        payload: {
            value,
            id
        }
    }
}
export const updateDISubjectSpaceStudy = (value, id) => {
    return {
        type: UPDATE_DI_SUBJECT_SPACE_STUDY,
        payload: {
            value,
            id
        }
    }
}
export const updateDISubjectSpaceCaptureInfo = (value, id) => {
    return {
        type: UPDATE_DI_SUBJECT_SPACE_CAPTURE_INFO,
        payload: {
            value,
            id
        }
    }
}
export const updateDIBioSpecimenStudy = (value, id) => {
    return {
        type: UPDATE_DI_BIOSPECIMEN_STUDY,
        payload: {
            value,
            id
        }
    }
}
export const updateDIBioSpecimenCaptureInfo = (value, id) => {
    return {
        type: UPDATE_DI_BIOSPECIMEN_CAPTURE_INFO,
        payload: {
            value,
            id
        }
    }
}
export const updateCanYouProvideDataDict = (value, id) => {
    return {
        type: UPDATE_CAN_YOU_PROVIDE_DATA_DICT,
        payload: {
            value,
            id
        }
    }
}
export const updateOtherImagingText = (value, id) => {
    return {
        type: UPDATE_OTHER_IMAGING_TEXT,
        payload: {
            value,
            id
        }
    }
}
export const updateOtherPreclinicalText = (value, id) => {
    return {
        type: UPDATE_OTHER_PRECLINICAL_TEXT,
        payload: {
            value,
            id
        }
    }
}
export const updateOtherGenomicText = (value, id) => {
    return {
        type: UPDATE_OTHER_GENOMIC_TEXT,
        payload: {
            value,
            id
        }
    }
}
export const updateOtherProteomicText = (value, id) => {
    return {
        type: UPDATE_OTHER_PROTEOMIC_TEXT,
        payload: {
            value,
            id
        }
    }
}
export const updateOtherWholeGenomeText = (value, id) => {
    return {
        type: UPDATE_OTHER_WHOLE_GENOME_TEXT,
        payload: {
            value,
            id
        }
    }
}
export const updateImagingOption = (value, id) => {
    return {
        type: UPDATE_IMAGING_OPTION,
        payload: {
            value,
            id
        }
    }
}
export const updatePreclinicalOption = (value, id) => {
    return {
        type: UPDATE_PRECLINICAL_OPTION,
        payload: {
            value,
            id
        }
    }
}
export const updateGenomicOption = (value, id) => {
    return {
        type: UPDATE_GENOMIC_OPTION,
        payload: {
            value,
            id
        }
    }
}
export const updateProteomicOption = (value, id) => {
    return {
        type: UPDATE_PROTEOMIC_OPTION,
        payload: {
            value,
            id
        }
    }
}
export const updateWholeGenomeOption = (value, id) => {
    return {
        type: UPDATE_WHOLE_GENOME_OPTION,
        payload: {
            value,
            id
        }
    }
}
export const setIndividualDatasetData = (value) => {
    return {
        type: SET_INDIVIDUAL_DATASET_FORM_DATA,
        payload: value
    }
}
export const updateOtherTypeOfDataText = (value, id) => {
    return {
        type: UPDATE_OTHER_TYPE_OF_DATA_TEXT,
        payload: {
            value,
            id
        }
    }
}
export const updateIsClinical = (value, id) => {
    return {
        type: UPDATE_IS_CLINICAL,
        payload: {
            value,
            id
        }
    }
}
export const updateIsPublicationsPresentChange = (value, id) => {
    return {
        type: UPDATE_IS_PUBLICATION_PRESENT,
        payload: {
            value,
            id
        }
    }
}
export const addDIFPublications = (value, id) => {
    return {
        type: ADD_DIF_PUBLICATIONS,
        payload: {
            value,
            id
        }
    }
}
export const updatePublicationName = (value, id) => {
    return {
        type: UPDATE_PUBLICATION_NAME,
        payload: {
            value,
            id
        }
    }
}
export const updatePublicationUrl = (value, id) => {
    return {
        type: UPDATE_PUBLICATION_URL,
        payload: {
            value,
            id
        }
    }
}
export const removeDIFPublications = (value, id) => {
    return {
        type: REMOVE_DIF_PUBLICATIONS,
        payload: {
            value,
            id
        }
    }
}
export const setDataDictDocuments = (value, id) => {
    return {
        type: SET_DATA_DICT_DOCUMENTS,
        payload: {
            value,
            id
        }
    }
}
export const setDataDictRelationshipDocuments = (value, id) => {
    return {
        type: SET_DATA_DICT_RELATIONSHIP_DOCUMENTS,
        payload: {
            value,
            id
        }
    }
}
export const removeSelectedDatasetTab = (id) => {
    return{
        type: REMOVE_SELECTED_DATASET_TAB,
        payload: id
    }
}
export const resetIndividualDatasetData = () => {
    return{
        type: RESET_INDIVIDUAL_DATASET_FORM,
    }
}

export const updateDIDatasetMoreDataAdded = (value, id) => {
    return {
        type: UPDATE_DI_DATASET_MORE_DATA_ADDED,
        payload: {
            value,
            id
        }
    }
}
export const updateModalSystem = (value, id) => {
    return {
        type: UPDATE_MODAL_SYSTEM,
        payload: {
            value,
            id
        }
    }
}
export const updateModalSystemOtherText = (value, id) => {
    return {
        type: UPDATE_MODAL_SYSTEM_OTHER_TEXT,
        payload: {
            value,
            id
        }
    }
}
export const updateDiseaseFeatureText = (value, id) => {
    return {
        type: UPDATE_DISEASE_FEATURE,
        payload: {
            value,
            id
        }
    }
}
export const updateTypeOfDataDiseaseArea = (value, id) => {
    return {
        type: UPDATE_TYPE_OF_DATA_DISEASE_AREA,
        payload: {
            value,
            id
        }
    }
}
export const updateOtherDiseaseAreaText = (value, id) => {
    return {
        type: UPDATE_TYPE_OF_DATA_DISEASE_AREA_TEXT,
        payload: {
            value,
            id
        }
    }
}
export const updateMetabolomicOption = (value, id) => {
    return {
        type: UPDATE_METABOLOMIC_OPTION,
        payload: {
            value,
            id
        }
    }
}
export const updateBehavioralTaskText = (value, id) => {
    return {
        type: UPDATE_BEHAVIORAL_TEXT,
        payload: {
            value,
            id
        }
    }
}
export const updatePhysiologicalOutcomeText = (value, id) => {
    return {
        type: UPDATE_PHYSIOLOGICAL_OUTCOME_TEXT,
        payload: {
            value,
            id
        }
    }
}
export const updateTissueSample = (value, id) => {
    return {
        type: UPDATE_TISSUE_SAMPLE,
        payload: {
            value,
            id
        }
    }
}
export const updateBehavioralAssessment = (value, id) => {
    return {
        type: UPDATE_BEHAVIORAL_ASSESSMENT,
        payload: {
            value,
            id
        }
    }
}
export const updateWearableSensors = (value, id) => {
    return {
        type: UPDATE_WEARABLE_SENSORS,
        payload: {
            value,
            id
        }
    }
}
export const updateDerivedSensorsData = (value, id) => {
    return {
        type: UPDATE_DERIVED_SENSOR_DATA,
        payload: {
            value,
            id
        }
    }
}
export const updateDoYouNeedCurationService = (value, id) => {
    return {
        type: UPDATE_DO_YOU_NEED_DATA_CURATED_SERVICE,
        payload: {
            value,
            id
        }
    }
}
export const updateIsDataCurated = (value, id) => {
    return {
        type: UPDATE_IS_DATA_CURATED,
        payload: {
            value,
            id
        }
    }
}
export const updateCurationDate = (value, id) => {
    return {
        type: UPDATE_CURATION_DATE,
        payload: {
            value,
            id
        }
    }
}
export const updateCuratorName = (value, id) => {
    return {
        type: UPDATE_DATASET_CURATOR_NAME,
        payload: {
            value,
            id
        }
    }
}
export const updateCuratorEmail = (value, id) => {
    return {
        type: UPDATE_DATASET_CURATOR_EMAIL,
        payload: {
            value,
            id
        }
    }
}
export const updateCuratorInstitution = (value, id) => {
    return {
        type: UPDATE_DATASET_CURATOR_INSTITUTION,
        payload: {
            value,
            id
        }
    }
}
export const updateOtherMetabolomicText = (value, id) => {
    return {
        type: UPDATE_OTHER_METABOLOMIC_TEXT,
        payload: {
            value,
            id
        }
    }
}
export const updateWearableSensorsOtherText = (value, id) => {
    return {
        type: UPDATE_WEARABLE_SENSORS_OTHER_TEXT,
        payload: {
            value,
            id
        }
    }
}
export const updateTissueSampleOtherText = (value, id) => {
    return {
        type: UPDATE_TISSUE_SAMPLE_OTHER_TEXT,
        payload: {
            value,
            id
        }
    }
}
export const updateDerivedSensorDataOtherText = (value, id) => {
    return {
        type: UPDATE_DERIVED_SENSOR_DATA_OTHER_TEXT,
        payload: {
            value,
            id
        }
    }
}
export const removeDataDictDocuments = (value, id) => {
    return {
        type: REMOVE_DATA_DICT_DOCUMENTS,
        payload: {
            value,
            id
        }
    }
}

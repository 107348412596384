import React, { useState, useEffect } from 'react'
import Classes from './index.module.css'
import Grid from '@mui/material/Grid'
import{ Modal as MUIModal} from '@mui/material'
import DropDown from '../../commons/dropdown'
import DocumentIcon from '../../../assets/images/Doc.M.png'
import DropZone from 'react-dropzone'
import { uploadFileToS3 } from '../../../utils/aws-sdk-helper'
import { getCertificates, postCertificateDetails, deleteCertificate  } from '../../../api/certificate-api'
import Modal from '../../commons/modal'
import CertificatePreview from '../certificate-preview'
import get from 'lodash/get'
import map from 'lodash/map'
import { CERTIFICATES } from '../../../constants/strings'
import ImgCertificate from '../../../assets/icons/certificate_pdf.png'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import v4 from 'uuid/v4'
import ErrorText from '../error-text'
import { TRAINING_CERTIFICATES_TITLES } from '../../../constants/strings'
import { navigate } from '@reach/router'
import DeleteIcon from '../../../assets/images/Small-close.png'
import CustomLoader from '../../commons/custom-loader'
import { sendGAEvents } from '../../../api/generic-api'
import {Calendar} from 'react-date-range';
import CalenderIcon from '../../../assets/icons/calender-icon.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CommonDatePicker from '../../commons/common-date-picker'
import { withStyles } from '@mui/styles'
import CloseIcon from '../../../assets/icons/close-small.svg'
import { BASE_URL } from '../../../constants/google-login-urls'

const TrainingAndCertificate = props => {
    const { editCertificateView = false, currentZone } = props;
    const [addedCertificates, setAddedCertificates] = useState([]);
    const [uploadedCertificate, setUploadedCertificate] = useState([]);
    const [certificatePreviewModal, setCertificatePreviewModal] = useState(false);
    const [selectedCertificateName, setSelectedCertificateName] = useState('');
    const [selectedCertificatedNameToUpload, setSelectedCertificatedNameToUpload] = useState('BRAINCommons Privacy, Data Protection and Security Training');
    const [selectedCertificatedDate, setSelectedCertificatedDate] = useState('');
    const [fileNameToUpload, setFileNameToUpload] = useState('')
    const [fileSize, setFileSize] = useState('');
    const [ isCertificateSubmit, setIsCertificateSubmit ] = useState(false);
    const [ uploadPercentage, setUploadPercentage] = useState(0);
    const [ openCertificateDeleteModal, setOpenCertificateDeleteModal ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormUpdating, setIsFormUpdating] = useState(false);
    const [openCalender, setOpenCalender] = useState(false);
    const [selectedCalenderInput, setSelectedCalenderInput] = useState('Select date');
    const [modal, setModal] = useState(false)
    const TRAINING_COURSE_LINK = 'training-course';

    const handleModalClose = () => {
        setModal(false)
    }

    const handleModalOpen = () => {
        setModal(true)
    }

    const newWindowOpen = () => {
        window.open(`${BASE_URL}/${TRAINING_COURSE_LINK}/`);
    }

    const onImageDrop = (files) => {
        const fileName = get(files, '[0].name', '');
        const fileSize = Number(get(files, '[0].size', 0)/1000).toFixed(2);
        setUploadedCertificate(files);
        setFileNameToUpload(fileName);
        setFileSize(`${fileSize} kb`);
        setIsCertificateSubmit(false);
    }
    // const onCertificateSelectionChange = name => {
    //     setSelectedCertificatedNameToUpload(name);
    //     setIsCertificateSubmit(false);
    // }
    const onCertificateBoxClick = (name) => {
        setSelectedCertificateName(name);
        setCertificatePreviewModal(true);
        if (!openCertificateDeleteModal) {
            setSelectedCertificateName(name);
            setCertificatePreviewModal(true);
        }
    }
    const saveCertificate = async () => {
        try {
            setIsCertificateSubmit(true);

            if (moment.isDate(selectedCertificatedDate) && !isEmpty(selectedCertificatedNameToUpload) && !isEmpty(uploadedCertificate)) {
                sendGAEvents('certificate_uploaded', 'training_and_certificate', 'training certificate upload',
                    { 'certificate_name': selectedCertificatedNameToUpload });
                const certificationDate = moment.utc(selectedCertificatedDate).format();
                const data = { 
                    "certificate_name":`${selectedCertificatedNameToUpload}.pdf`,
                    "certification_date": certificationDate
                }
                const preSignedData = await postCertificateDetails(data);
                if (!isEmpty(preSignedData)) {
                    let config = {
                        onUploadProgress: function (progressEvent) {
                            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadPercentage(percentCompleted);
                        }
                    };
                    setIsFormUpdating(true);
                   const res = await uploadFileToS3(uploadedCertificate[0], preSignedData, config);
                    if (res == 204 || res == 202 || res == 200) {
                        getAddedCertificates();
                        setIsCertificateSubmit(false);
                        setSelectedCertificatedDate(null);
                        setUploadedCertificate([]);
                        setFileNameToUpload('');
                        setFileSize('');
                        setUploadPercentage(0);
                        props.updateSnackBar('Certificate uploaded successfully.', 'success')
                    }
                }
            }
        } catch (error) {
            console.log('Error in saving Certificate', error);
            props.updateSnackBar('Fail to upload certificate', 'error');
            setUploadPercentage(0);
        }finally{
            setIsFormUpdating(false);
        }
    }
    useEffect(() => {
        if(props?.fromDUP){
            props.onCertificateChange(addedCertificates);
        }
    }, [addedCertificates])

    const getAddedCertificates = async () => {
        try {
            setIsLoading(true);
            const res = await getCertificates();
            const certificateList = get(res, 'certificate_list', []);
            setAddedCertificates(certificateList);
            setIsLoading(false);
        } catch (error) {
            console.log('Error in getting Certificates', error);
            setIsLoading(false);
        }
    }
    const closeCertificateReviewModal = () => {
        setCertificatePreviewModal(false);
    }
    const onCertificationDateChange = date => {
        const calenderInputText = moment(date).format('MM/DD/YYYY');
        setSelectedCalenderInput(calenderInputText); 
        setSelectedCertificatedDate(date);
        setOpenCalender(false);
    }
    useEffect(() => {
        getAddedCertificates();
    }, []);

    const onCertificateDeleteClick = ( name) => {
        setSelectedCertificateName(name);
        setCertificatePreviewModal(false);
        setOpenCertificateDeleteModal(true);
    }

    const editCertificateViewClass = editCertificateView ? Classes.editCertificateViewClass : Classes.rightContainer;

    const CERTIFICATE_VIEW_MODAL_PROPS = {
        modalTitle: selectedCertificateName,
    }


    const onDeleteCertificateConfirm = async () => {
        try {
            setOpenCertificateDeleteModal(false);
            setCertificatePreviewModal(false);
            const res = await deleteCertificate(selectedCertificateName);
            if (res == '200' || res == '202') {
                props.updateSnackBar('Certificate removed successfully from your profile', 'success');
                getAddedCertificates();
            }
        } catch (error) {
            console.log('error in delete certificate', error);
            props.updateSnackBar('Unable to remove certificates', 'error');
        }
    }
    const closeDeleteCertificateModal = () => {
        setOpenCertificateDeleteModal(false);
        setCertificatePreviewModal(false);
    }
    const CERTIFICATE_DELETE = {
        modalTitle: 'Are you sure you want to remove this certificate from profile?',
        positiveButtonText: 'Remove certificate',
        negativeButtonText: "Cancel",
        positiveButtonAction: onDeleteCertificateConfirm,
        negativeButtonAction: closeDeleteCertificateModal
    }

    const onTitleClick = (eventLink) => {
        window.open(eventLink, "_blank")
    }
    const cancelCeritificate = () => {
        if (props?.fromDUP){
            props.backHandle()
        }
        else {
            setSelectedCertificatedDate('');
            setUploadPercentage('');
            setFileNameToUpload('');
            setFileSize('');
            navigate('/my-profile')
        }
    }



    return (
        <div className={Classes.container} >
            {isLoading ? <CustomLoader/> : null}
            <Grid container>
                { !openCertificateDeleteModal && <Modal
                    open={certificatePreviewModal}
                    handleClose={closeCertificateReviewModal}
                    dialogProps={CERTIFICATE_VIEW_MODAL_PROPS}
                    disableTitleDivider={true}
                    disableBottomHorizontalDivider
                >
                    <CertificatePreview selectedCertificateName={selectedCertificateName} />
                </Modal>
                }
                 <Modal
                    open={openCertificateDeleteModal}
                    handleClose={closeDeleteCertificateModal}
                    dialogProps={CERTIFICATE_DELETE}
                    disableTitleDivider={true}
                />
                {
                    props?.fromDUP ?

                    <div className={Classes.DUPInfoContainer}>
                        <div className={Classes.DUPContainerTitle}>Important</div>
                        Please complete the three mandatory trainings. When you are done, please upload each certificate in order to obtain {currentZone} access.
                    </div> : null
                }
                <Grid item xs={8} md={8} lg={8} className={Classes.leftContainer}>
                    <p className={Classes.certificateTitle}>Training Certificate</p>
                    <p className={Classes.certificateDetails}> To qualify for access to Zone 2 or 3, or for advanced compute
                    services that include the use of a BRAINCommons workspace, you will need to complete the BRAINCommons Privacy,
                    Data Protection and Security Training that is designed to ensure that users will act responsibly. You access the 
                    required training at the link below. Please complete the training, save the training certificate as a PDF and
                    upload it here.    
                    </p>
                    <ul className={Classes.certificateNameListContainer}>
                        {TRAINING_CERTIFICATES_TITLES.map((eventData) => {
                            return <li id={`cvb-trainingAndCertificate-titles-${eventData['eventTitle']}`} key={v4()} className={Classes.certificateNameList}
                                // onClick={() => onTitleClick(eventData['eventLink'])}
                                // onClick={() => handleModalOpen(true)}
                                onClick={() => newWindowOpen()}
                                >
                                {eventData['eventTitle']}
                            </li>
                        })}
                    </ul>
                    <Grid container justifyContent='space-between'>
                        <Grid item xs={12} md={12} lg={6}>
                            <div className={Classes.datePickerWrapper}>
                                <label>Date of Completion<sup> *</sup></label>
                                <CommonDatePicker
                                    onCalenderDateSelected={onCertificationDateChange}
                                    date={selectedCertificatedDate}
                                    showValidationError={(isCertificateSubmit && !moment.isDate(selectedCertificatedDate))}
                                    maxDate={new Date()}
                                    errorMessage='Please select date'
                                    id='cvb-trainingAndCertificate-inputDate'
                                    canClearInput
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={5}>
                            <div className={Classes.uploadFileContainer}>
                                <label className={Classes.uploadFileText}>Upload File<sup> *</sup></label>
                                <p className={Classes.uploadInfo}>Upload only PDF file.
                                </p>
                                <div id='cvb-trainingAndCertificate-fileDropInput' className={Classes.dropZoneButtonContainer}>
                                    <DropZone
                                        onDrop={(acceptedFiles) => onImageDrop(acceptedFiles)}
                                        multiple={false}
                                        accept='application/pdf'
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps({ accept: 'application/pdf' })} />
                                                <div className={Classes.uploadImageDiv}>
                                                    <img src={DocumentIcon} alt='document' />
                                                    <p className={Classes.chooseFileText}>
                                                        Drag and drop or
                                                        <span>
                                                            <button id='cvb-trainingAndCertificate-selectFile-button' className={`${Classes.chooseFileButton}`}>
                                                                Select files
                                                            </button>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </DropZone>
                                </div>
                                <progress id='cvb-trainingAndCertificate-progress' className={Classes.progressBar} value={uploadPercentage} max={100}/>
                                { (isCertificateSubmit && isEmpty(uploadedCertificate))
                                && <ErrorText errorMessage={'Please select file to upload'}/>
                                }
                               {!isEmpty(uploadedCertificate) && <p className={Classes.fileNameAndSize}>{`${fileNameToUpload}`}
                                    <span id='cvb-trainingAndCertificate-fileSize'>{`${fileSize}`} </span>
                                </p>}
                            </div>

                        </Grid>
                    </Grid>
                    <div className={Classes.buttonsContainer}>
                        <button id='cvb-trainingAndCertificate-cancelUpload-button' onClick={cancelCeritificate} className={`medium-size-button no-outlined-button ${Classes.cancelButton}`} >Cancel</button>
                        <button id='cvb-trainingAndCertificate-save-button' disabled={isFormUpdating} onClick={saveCertificate} className={`medium-size-button solid-button ${Classes.saveButton}`}>Save</button>
                    </div>
                </Grid>

                <Grid item xs={4} lg={4} md={4} className={Classes.rightContainerWrapper}>
                    <div className={editCertificateViewClass}>
                        <p id='cvb-trainingAndCertificate-addedCert-title' className={Classes.addedCertificatesText}>Added Certificate</p>
                        <div className={Classes.certificatesContainerWrapper}>
                            <Grid container spacing={4}>
                                {map(addedCertificates, (certificate) => {
                                    const certificateName = get(certificate, 'certificate_name', '');
                                    const certificateDate = get(certificate, 'certification_date', '');
                                    const certDate = moment(certificateDate).format('MM/DD/YYYY');
                                    return (
                                        <Grid key={v4()} item xs={12} md={12} lg={6}>
                                            <div id='cvb-trainingAndCertificate-certificateBox' onClick={() => onCertificateBoxClick(certificateName)} className={Classes.certificateContainer}>
                                                <img src={ImgCertificate} className={Classes.certImages} alt="PDF_IMAGE" />
                                                <img id='cvb-trainingAndCertificate-deleteCertificate' src={DeleteIcon}
                                                    onClick={() => onCertificateDeleteClick(certificateName)}
                                                    className={Classes.deleteIcon}
                                                    alt="DELETE"
                                                />
                                                <p id='cvb-trainingAndCertificate-certificateName' className={Classes.certificateName}>{certificateName}</p>
                                                <p id='cvb-trainingAndCertificate-certificateDate' className={Classes.certificateDate}>{certDate}</p>
                                            </div>
                                        </Grid>
                                    )
                                }
                                )}
                            </Grid>

                        </div>
                    </div>
                </Grid>
            </Grid>

            <MUIModal
            open={modal}
            onClose={handleModalClose}
            className={Classes.iframeModal}>
                <div className={Classes.paper}>
                    <svg className={Classes.closeIcon} onClick={() => handleModalClose()} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0.700012L11.3 0L6 5.29999L0.700012 0L0 0.700012L5.29999 6L0 11.3L0.700012 12L6 6.70001L11.3 12L12 11.3L6.70001 6L12 0.700012Z" fill="#414141"/>
                    </svg>
                    <iframe className={Classes.iframe} src={`${BASE_URL}/training-course/`}></iframe>
                </div>
            </MUIModal>
        
        </div>
    );
}

export default TrainingAndCertificate;
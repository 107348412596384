import axios from 'axios'
import {env} from '../env'
import get from 'lodash/get'
import { getCognitoIdToken } from './utilityMethods';

const NOTEBOOK_BASE_URL = `${env.REACT_APP_BASE_API_PATH}/notebook`;

export const getAllNotebooks = async () => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${NOTEBOOK_BASE_URL}/get_shared_notebooks`, { headers: header });
        return get(res, 'data', []);
    } catch (error) {
        throw Error(error.message);
    }
}

export const shareNotebook = async (data) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.post(`${NOTEBOOK_BASE_URL}/share_notebook`, data,  { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getSharedUserForNotebook = async (notebookName) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.get(`${NOTEBOOK_BASE_URL}/get_shared_with_users_of_notebook/${notebookName}`, { headers: header });
        return get(res, 'data', []);
    } catch (error) {
        throw Error(error.message);
    }
}

export const unshareNotebook = async (userData) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.delete(`${NOTEBOOK_BASE_URL}/unshare_notebook`, {data: userData , headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const getPresignedUrlForNotebook = async (notebookKey) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.get(`${NOTEBOOK_BASE_URL}/get_presigned_url/${notebookKey}`, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error.message);
    }
}

export const copyNotebookToWorkspace = async (data) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.post(`${NOTEBOOK_BASE_URL}/copy_shared_notebook_to_workspace`, data,  { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error.message);
    }
}


export const copyNotebookToOwnedWorkspace = async (data) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.post(`${NOTEBOOK_BASE_URL}/copy_notebook_from_shared_workspace_to_own_workspace`, data,  { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error.message);
    }
}
/**
 *  External Imports
 */
import { connect } from "react-redux";

/**
 *  Internal Imports
 */
import {
    openCommunityConnectModalAction,
    connectRecommendedUserAction,
    declineRecommendedUserAction,
    redirectCommunityAction,
    connectGlobalSearchUserAction,
    declineIncomingUserByIDAction,
    openCommunitySuccessModalAction,
    acceptIncomingUserByIDAction,
    openCommunityDeleteModalAction,
    deleteConnectedUsersAction
} from "../../../redux/actions/communityActions";
import CommunityWidgetButtons from "./CommunityWidgetButtons";

const mapDispatchToProps = (dispatch) => ({
    onConnectDialogOpen: (dialogConnectData) => dispatch(openCommunityConnectModalAction(dialogConnectData)),
    onRecommendedConnectUser: (recommendationID) => dispatch(connectRecommendedUserAction(recommendationID)),
    onSearchConnectUser: (userID) => dispatch(connectGlobalSearchUserAction(userID)),
    onDeclineRecommendedUser: (recommendationID) => dispatch(declineRecommendedUserAction(recommendationID)),
    onDeclineIncomingUser: (userID) => dispatch(declineIncomingUserByIDAction(userID)),
    onMessageChatRedirect: (path, pathState) => dispatch(redirectCommunityAction(path, pathState)),
    onAcceptUser: (userID) => dispatch(acceptIncomingUserByIDAction(userID)),
    onAcceptDialogOpen: (dialogSuccessData) => dispatch(openCommunitySuccessModalAction(dialogSuccessData)),
    onDeleteDialogOpen: (dialogDeleteData) => dispatch(openCommunityDeleteModalAction(dialogDeleteData)),
    onDeleteConnectedUser: (userID) => dispatch(deleteConnectedUsersAction(userID)),
});

export default connect(
    null,
    mapDispatchToProps
)(CommunityWidgetButtons);

import React, { useState , useEffect} from 'react'
import Classes from './index.module.css'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import DefaultProfile from '../../../assets/images/user/default-profile-image.png'
import capitalize from 'lodash/capitalize'
import EmailIcon from '../../../assets/images/EmailIcon.png'
import MobileIcon from '../../../assets/images/MobileIcon.png'
import InstitutePinIcon from '../../../assets/images/instituteIcon.png'
import BrainOrangeIcon from '../../../assets/images/brainOrangeIcon.png'
import BrainOrangeIcon2X from '../../../assets/images/brainOrangeIcon2x.png'
import BackButtonArrow from '../../../assets/icons/left_arrow.png'
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Router, navigate } from '@reach/router';
import UserPermissions  from './UserPermissions';
import UserAbout from './UserAbout';
import { getUserProfile, getProfilePicture as getProfilePictureApi, getUserPermissions } from '../../../api/profile-api';
import isEmpty from 'lodash/isEmpty'
import useScrollToTop from '../../commons/scroll-top';
import {getResourceActions} from '../../../utils/resourceActionsFormatter';
import get from 'lodash/get';
import Tooltip from '@mui/material/Tooltip'
import v4 from 'uuid/v4'

const useStyles = makeStyles(theme => ({
    paper: {
        marginLeft: 34,
        boxShadow: 'none',
        marginTop: 6,
        marginBottom: 12,
        borderBottom: '2px solid #DEDEDE',
        marginRight: 50
    },
}))

const Tab = withStyles(theme => ({
    root: {
        minWidth: 50,
        padding: '6px 4px',
        marginRight: 30
    }
}))(MuiTab);

const getTabValue = (path) => {
    if(path.includes("permissions")){
        return 1
    }else if(path.includes("data-usage")){
        return 2
    }
    return 0
}




export default function AdminUserProfile(props) {
    const styledClasses = useStyles();
    const  tabValue  = getTabValue(props.location.pathname)
    const [userProfileData, setUserProfileData] = useState({})
    const [profilePicture, setProfilePicture] = useState(`${DefaultProfile}`)
    const [isProfileLoading, setProfileLoading] = useState(true);

    const getProfilePicture = async (id) => {
        const response = await getProfilePictureApi(id);
        setProfilePicture(response)
    }

    useScrollToTop();

    const getUserProfileData = async (id) => {
        let data = await getUserProfile(id);
        let userPermissions = await getUserPermissions(id);

        let resourceActions = getResourceActions(userPermissions);
        data.user_details['permissions'] = userPermissions;
        data.user_details['resourceActions'] = resourceActions;


        let userSubDetailsArr = [];
        const user_designation = ((data.user_details?.['designation']?.charAt(0)?.toUpperCase() + data.user_details?.['designation']?.slice(1)) || "")
        !isEmpty(user_designation) && userSubDetailsArr.push(user_designation);
        const usersOrganization = get(data.user_details, 'organization', '');
        !isEmpty(usersOrganization) && userSubDetailsArr.push(usersOrganization);
        const instituteCountry = get(data.user_details, 'institution_details.country', '');
        !isEmpty(instituteCountry) && userSubDetailsArr.push(instituteCountry);
        data.user_details['designation'] = userSubDetailsArr.join(', ');

        data.user_details['id'] = id;
        if(data?.user_details?.permissions?.user_roles?.length){
            data.user_details.permissions.user_roles.map((role) => {
                if(role.is_primary_role){
                    data.user_details.user_role = role.role_label;
                    data.user_details.user_role_expiry_date = role.role_expiry_date;
                }
            })
        }
        setUserProfileData(data.user_details)
        setProfileLoading(false)
    }

    useEffect(() => {
        getUserProfileData(props.id)
        getProfilePicture(props.id)
    }, [props.id])

    const handleChange = (event, newValue) => {
        if (newValue === 1) {
            navigate(`/admin/user-profile/${props.id}/permissions`)
        }
        if (newValue === 0) {
            navigate(`/admin/user-profile/${props.id}/`)
        }
    };

    //const profilePictureSource = (props.proPic ? props.proPic : DefaultProfile)

    const userName = (capitalize(userProfileData?.["first_name"]) + " " + capitalize(userProfileData?.["last_name"]))

    const designation = (capitalize(userProfileData?.['designation'] || " "))

    const goBack = () => {
        if(props?.location?.state?.adminDashboard){
            navigate('/dashboard', {state: {selectedUser: props?.location?.state?.selectedUser}});
        }else{
            navigate('/users', { state: { search: true, searchString: props?.location?.state?.searchString } })
        }
    }

    const onSocialClick = (link) => {
        if(link){
            window.open(link, "_blank")
        }
    }

    return (
        <div className={Classes.editProfileMainContainer}>
            <div className={Classes.editProfileChildContainer}>
                <Grid container direction='column'>
                    <p id='cvb-adminProfile-backButton' onClick={goBack} className={Classes.backButton}>
                        <span><img alt='back button arrow' src={BackButtonArrow} /></span>
                        <p id='cvb-adminProfile-backButtonText' className={Classes.backButtonText}>Back To Users</p>
                    </p>
                    <p id='cvb-adminProfile-userProfileHeading' className={Classes.editProfileText}>User Profile</p>
                </Grid>
                <Paper square >
                    <Grid container>
                        <Grid lg={3} xs={3}>
                            <Paper square xs={3} elevation={0} className={Classes.leftContainerPaper}>
                                <Grid container alignItems='center' direction='column' className={Classes.userBasicInfoContainer}>
                                    <Grid item xs={6} className={Classes.profilePicContainer}>
                                        <img id='cvb-adminProfile-userProfilePicture' src={profilePicture} className={Classes.profilePicImage} alt='default profile' />
                                        <div className={Classes.profilePicShadow}>
                                        </div>
                                    </Grid>
                                    <Tooltip title={userName} id='cvb-adminProfile-userNameTooltip'>
                                        <p id='cvb-adminProfile-userNameText' className={Classes.userName}>{userName || ''}</p>
                                    </Tooltip>
                                    <p id='cvb-adminProfile-userNameDesignation' className={Classes.designation}>{userProfileData?.designation || ''}</p>
                                    {/* <Grid item xs={12}>
                                        <Grid container direction='row' spacing={2} alignItems='center'
                                            justify='center' className={Classes.tagsContainer}>
                                            {
                                                userProfileData && userProfileData?.tags?.length > 0 ? 
                                                userProfileData['tags'].map(item => {
                                                    return <Grid key={v4()} item >
                                                        <p id={`cvb-adminProfile-tagsText-${item}`} className={Classes.tagsText}>{item}</p>
                                                    </Grid>
                                                }) : <div></div>
                                            }
                                        </Grid>
                                    </Grid> */}

                                    <Grid item xs={12} className={Classes.mediaIconsContainer}>
                                        <div id='cvb-adminProfile-orchidLink' onClick={() => onSocialClick(userProfileData?.custom_attributes?.orchid)}  className={`${Classes.idBefore} ${userProfileData?.custom_attributes?.orchid ? Classes.idColor : ""}`}> </div>
                                        <div id='cvb-adminProfile-googleLink' onClick={() => onSocialClick(userProfileData?.custom_attributes?.google)} className={`${Classes.googleBefore} ${userProfileData?.custom_attributes?.google  ? Classes.googleColor : ""}`}> </div>
                                        <div id='cvb-adminProfile-linkedinLink' onClick={() => onSocialClick(userProfileData?.custom_attributes?.linkedin)} className={`${Classes.linkedinBefore} ${userProfileData?.custom_attributes?.linkedin  ? Classes.linkedinColor : ""}`}> </div>
                                        <div id='cvb-adminProfile-twitterLink' onClick={() => onSocialClick(userProfileData?.custom_attributes?.twitter)} className={`${Classes.twitterBefore} ${userProfileData?.custom_attributes?.twitter  ? Classes.twitterColor : ""}`}> </div>
                                        <div id='cvb-adminProfile-githubLink' onClick={() => onSocialClick(userProfileData?.custom_attributes?.github)} className={`${Classes.gitBefore} ${userProfileData?.custom_attributes?.github  ? Classes.githubColor : ""}`}> </div>
                                        <div id='cvb-adminProfile-personalLink' onClick={() => onSocialClick(userProfileData?.custom_attributes?.personal)} className={`${Classes.internetBefore} ${userProfileData?.custom_attributes?.personal  ? Classes.internetColor : ""}`}> </div>
                                    </Grid>
                                    <div className={Classes.usersExtraInfoContainer}>
                                        <div className={Classes.userTypeDiv}>
                                            <img src={BrainOrangeIcon} alt='brain icon' />
                                            <p id='cvb-adminProfile-userRoleText' className={`${Classes.rightContainerInfoText} ${Classes.add5TopMargin}`}>
                                                {userProfileData?.user_role}
                                                </p>
                                        </div>
                                        <div className={Classes.usersExtraInfo}>
                                            <img src={EmailIcon} alt='email icon' />
                                            <p id='cvb-adminProfile-userEmailText' className={`${Classes.rightContainerInfoText} ${Classes.add5TopMargin}`}>
                                                {userProfileData?.['email']}
                                            </p>
                                        </div>
                                        {
                                            userProfileData?.['phone_number'] ? 

                                            <div className={Classes.usersExtraInfo}>
                                                <img src={MobileIcon} alt='mobile icon' />
                                                <p id='cvb-adminProfile-userPhoneNumberText' className={`${Classes.rightContainerInfoText} ${Classes.add5TopMargin}`}>
                                                    {userProfileData?.['phone_number']}
                                                </p>
                                            </div> : null
                                        }


                                        {
                                            !isEmpty(userProfileData?.institution_details) && !Object.values(userProfileData?.institution_details).every(o => o === "" || o === null) ?
                                            

                                            <div className={Classes.instituteDetails}>
                                            <p id='cvb-adminProfile-instituteHeading' className={Classes.rightContainerHeaders}>Institute</p>
                                            <div className={Classes.instituteContent}>
                                                <img src={InstitutePinIcon} alt='institute' />
                                                <div className={Classes.instituteInfoContainer}>
                                                    <p id='cvb-adminProfile-instituteNameText' className={Classes.rightContainerSubHeaders}>{userProfileData?.institution_details?.['institution_name']}</p>
                                                    <p id='cvb-adminProfile-instituteAddressDetails' className={`${Classes.rightContainerInfoText} ${Classes.add5TopMargin}`}>
                                                        {userProfileData?.institution_details?.address ? userProfileData?.institution_details?.address : ''}
                                                        {userProfileData?.institution_details?.city  ? ', ' + userProfileData?.institution_details?.city  : ''}
                                                        {userProfileData?.institution_details?.state ? userProfileData?.institution_details?.state  : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        </div> : null

                                        }
                                    </div>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid lg={9} xs={9} className={Classes.rightContainerTabs}>
                            
                            <Tabs
                                    value={tabValue}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleChange}
                                    classes={styledClasses.tabRoot}
                                >
                                    <Tab id='cvb-adminProfile-aboutTab' label="ABOUT" />
                                    <Tab id='cvb-adminProfile-approvalsTab' label="APPROVALS & PERMISSIONS" />
                                </Tabs><div className={Classes.tabRoot}></div>
                            {
                                !isProfileLoading ?
                                <ProfileTab updateSnackBar={props.updateSnackBar} userProfileData={userProfileData} /> : null
                            }
                        </Grid>
                    </Grid>
                </Paper>
                
            </div>
        </div>
        
    )
}

const ProfileTab = (props) => {
    const {userProfileData, updateSnackBar = ()=> { }} = props;
    const [addedCertificates, setAddedCertificates] = useState([]);
    return (
        <Router id="userProfile-route" primary={false}>
            <UserAbout path="/" addedCertificates={addedCertificates} setAddedCertificates={setAddedCertificates}  data={userProfileData}  />
            <UserPermissions updateSnackBar={updateSnackBar} path="/permissions" data={userProfileData} />
        </Router>
    )
}

import React, { useRef, useEffect } from 'react'
import Classes from './index.module.css'
import Modal from '../modal'
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import map from 'lodash/map'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import DropDown from '../../commons/dropdown'
import SignatureCanvas from 'react-signature-canvas'
import isEmpty from 'lodash/isEmpty'
import findIndex from 'lodash/findIndex'
import InputAdornment from '@mui/material/InputAdornment';

const RequestAdvancedComputeDetailsView = props => {
    const { signRef = null, organizationsList = [], open, showAgreeCheckbox, workspaceDetails, setTermsOfUseButtonDisable, handleClose, dialogProps } = props;
   
    const createSign = async (data) => await signRef?.current?.fromDataURL(data);

    useEffect(() => {
        createSign(workspaceDetails?.additional_fields?.signature)
    }, [workspaceDetails])

    const onTermsOfUseChange = (checked) => {
        if (checked) {
            setTermsOfUseButtonDisable(false)
        } else {
            setTermsOfUseButtonDisable(true)
        }
    }
    useEffect(() => {

    }, [workspaceDetails])
    let isUserOrgPresent = true;
    let isBillingOrgPresent = true;
    if (organizationsList.length > 0) {
        isUserOrgPresent = findIndex(organizationsList, (attr) => attr['name'] === workspaceDetails?.additional_fields?.user_information?.organization_name) > -1;
        isBillingOrgPresent = findIndex(organizationsList, (attr) => attr['name'] === workspaceDetails?.additional_fields?.billing_information?.organization_name) > -1;
    }

    return (
        <Modal workspaceDetails={workspaceDetails} open={open} handleClose={handleClose} dialogProps={dialogProps} disableTitleDivider disableBottomHorizontalDivider >
            {/* <div className={[Classes.contentStartText, Classes.showHeader].join(' ')}>
            <p>
                <span id='cvb-myStuff-servAgreement-submitterDetails' className={Classes.submitDetails} ><p id='cvb-myStuff-servAgreement-submittedOn' className={Classes.submitText}>Submitted</p> on {selectedWorkspaceRequest?.dayStr} at {selectedWorkspaceRequest?.timeStr}</span>
                <p id='cvb-myStuff-servAgreement-signatoryName' className={Classes.submitName}>By {latestWorkspaceRequest?.signatory_name}</p>
            </p>

        </div>
        <ViewServiceAgreement
            data={latestWorkspaceRequest}
        /> */}
            <Grid spacing={3} xs={10} container direction='row' className={Classes.advancedComputeContainer}>
                <Grid item xs={10}>
                    <p className={`${Classes.formTitle}`} id="cvb-requestAdvancedComputeDetailsView-proposedScope">Proposed Scope</p>
                </Grid>

                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='projectTitle' id="cvb-requestAdvancedComputeDetailsView-projectTitleLabel">Project Title<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        value={workspaceDetails?.additional_fields?.project_title}
                        fullWidth
                        disabled={true}
                        id='cvb-requestAdvancedComputeDetailsView-projectTitle'
                        name='projectTitle'
                    />
                </Grid>


                <Grid item xs={10} className={Classes.fieldCont}>
                    <div className={Classes.textareaCont}>
                        <label htmlFor='researchQuestion' id="cvb-requestAdvancedComputeDetailsView-researchQu[]estionLabel">Brief description of data analysis methods</label>
                        <textarea rows={5} name='researchQuestion' type="text"
                            value={workspaceDetails?.additional_fields?.proposal} placeholder="Enter Research Question"
                            disabled={true}
                            id="cvb-requestAdvancedComputeDetailsView-researchQuestion"
                        ></textarea>
                    </div>
                </Grid>

                <Grid item xs={10} className={Classes.fieldCont}>
                    <label id="cvb-advancedCompute-workspaceInstanceLabel">Type of Workspace <sup> *</sup></label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-advancedCompute-ec2Label">Linux</label>
                            <input id='isDataCurated1' type="radio" name="isDataCurated"
                                checked={workspaceDetails?.workspace_type === 'EC2' ? true : false}
                            // onChange={() => onWorkspaceSelection('EC2')} 
                            />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-advancedCompute-windowsLabel">Windows</label>
                            <input id='isDataCurated2' type="radio" name="isDataCurated"
                                checked={workspaceDetails?.workspace_type === 'WINDOWS' ? true : false}
                            //  onChange={() => onWorkspaceSelection('WINDOWS')}
                            />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>

                </Grid>
                {/* //Date */}
                <Grid item xs={10} className={Classes.fieldCont}>
                    <Grid item xs={5} className={Classes.fieldCont}>
                        <label htmlFor="workspaceDuration">Expected duration of workspace access needed to complete the analysis</label>
                        <OutlinedInput
                            type="text"
                            value={workspaceDetails?.additional_fields?.duration}
                            fullWidth
                            disabled={true}
                            id='cvb-requestAdvancedComputeDetailsView-workspaceDuration'
                            name='workspaceDuration'
                        />
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <p className={`${Classes.formTitle}`} id="cvb-requestAdvancedComputeDetailsView-proposedScope">BRAINCommons&trade; ADVANCED COMPUTE CONTRACT (the “Contract”)</p>
                </Grid>
                <Grid item xs={10} className={Classes.fieldCont}>
                    <Grid item xs={5} className={Classes.fieldCont}>
                        <label htmlFor="workspaceDuration">Application Date</label>
                        <OutlinedInput
                            type="text"
                            value={workspaceDetails?.additional_fields?.date && moment(moment.isMoment(workspaceDetails?.additional_fields?.date)) ? moment(workspaceDetails?.additional_fields?.date).format('MM/DD/YYYY') : ''}
                            fullWidth
                            disabled={true}
                            id='cvb-requestAdvancedComputeDetailsView-workspaceDuration'
                            name='workspaceDuration'
                        />
                    </Grid>
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='firstName' id="cvb-requestAdvancedComputeDetailsView-firstNameLabel">Name<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        value={workspaceDetails?.additional_fields?.user_information?.name}
                        fullWidth
                        disabled={true}
                        id='cvb-requestAdvancedComputeDetailsView-firstName'
                        name='firstName'
                    />
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='email' id="cvb-requestAdvancedComputeDetailsView-emailLabel">Email<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        value={workspaceDetails?.additional_fields?.user_information?.email}
                        fullWidth
                        disabled={true}
                        id='cvb-requestAdvancedComputeDetailsView-email'
                        name='email'
                    />
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='firstName' id="cvb-requestAdvancedComputeDetailsView-lastNameLabel">Title<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        value={workspaceDetails?.additional_fields?.user_information?.title}
                        fullWidth
                        disabled={true}
                        id='cvb-requestAdvancedComputeDetailsView-lastName'
                        name='lastName'
                    />
                </Grid>



                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='organization' id="cvb-requestAdvancedComputeDetailsView-organizationLabel">Contact Number<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        value={workspaceDetails?.additional_fields?.user_information?.phone}
                        fullWidth
                        disabled={true}
                        id='cvb-requestAdvancedComputeDetailsView-organization'
                        name='organization'
                    />
                </Grid>

                <Grid item xs={5}>
                    <label id='cvb-userOnboarding-organizationLabel' htmlFor="organization" > Organization<sup> *</sup></label>
                    <DropDown
                        values={[]}
                        handleFilterStateChange={() => { }}
                        selectedFilter={!isUserOrgPresent ? 'Other' : workspaceDetails?.additional_fields?.user_information?.organization_name}
                        placeholder={'Select your organization'}
                        customClass={Classes.organizationField}
                        id='cvb-userOnboarding-organizationInput'
                        disable
                    />

                </Grid>
                {/* Check Organization value. Change needeed here  */}
                {
                    (!isUserOrgPresent || `${workspaceDetails?.additional_fields?.user_information?.organization_name}`.toLowerCase() === 'other') &&
                    <>
                        <Grid item xs={5}>
                            <label id='cvb-userOnboarding-organizationLabel' htmlFor="organization" > Organization Name<sup> *</sup></label>
                            <OutlinedInput
                                fullWidth
                                value={workspaceDetails?.additional_fields?.user_information?.organization_name}
                                onChange={() => { }}
                                disabled
                                placeholder='Enter your organization name' name='otherOrganizationName'
                                id='cvb-userOnboarding-otherOrganizationLabel'
                            />


                        </Grid>

                        <Grid item xs={10} className={Classes.fieldCont}>
                            <Grid item xs={6} className={Classes.fieldCont}>
                                <label htmlFor='country' id="cvb-DIFFormStep1-countryLabel">Country<sup> *</sup></label>
                                <DropDown
                                    id="cvb-DIFFormStep1-instituteCountry"
                                    name='instituteCountry'
                                    values={[]}
                                    handleFilterStateChange={() => { }}
                                    selectedFilter={workspaceDetails?.additional_fields?.user_information?.address?.country}
                                    disable
                                    placeholder={'Select Country'}

                                />

                            </Grid>
                        </Grid>
                        <Grid item xs={10} className={Classes.fieldCont}>
                            <label htmlFor='firstName' id="cvb-advancedCompute-lastNameLabel"> Street address<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                value={workspaceDetails?.additional_fields?.user_information?.address?.street}
                                onChange={() => { }}
                                fullWidth
                                disabled
                                id='cvb-advancedCompute-lastName'
                                name='lastName'
                            />

                        </Grid>
                        <Grid item xs={5} className={Classes.fieldCont}>
                            <label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">City<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                value={workspaceDetails?.additional_fields?.user_information?.address?.city}
                                disabled
                                onChange={() => { }}
                                fullWidth
                                id='cvb-advancedCompute-organization'
                                name='organization'
                                placeholder='Enter city'
                            />

                        </Grid>
                        <Grid item xs={5} className={Classes.fieldCont}>
                            <label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">State<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                value={workspaceDetails?.additional_fields?.user_information?.address?.state}
                                onChange={() => { }}
                                fullWidth
                                disabled
                                id='cvb-advancedCompute-organization'
                                name='organization'
                                placeholder='Enter state'

                            />

                        </Grid>
                        <Grid item xs={5} className={Classes.fieldCont}>
                            <label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">Zip Code<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                value={workspaceDetails?.additional_fields?.user_information?.address?.zip_code}
                                fullWidth
                                disabled
                                onChange={() => { }}
                                id='cvb-advancedCompute-organization'
                                name='organization'
                                placeholder='Enter zipcode'
                            />

                        </Grid> </>
                }

                <Grid item xs={10} style={{position: 'relative'}}>
                    <p className={`${Classes.formTitle}`} id="cvb-requestAdvancedComputeDetailsView-proposedScope">Billing Information</p>
                    <div className={Classes.checkboxWrapper}>
                        <Checkbox
                            color="primary"
                            checked={workspaceDetails?.additional_fields?.billing_same_as_user_details}
                            onChange={() => { }}
                            id='cvb-termsOfUse-checkbox'
                        /> <label className={Classes.labelText}>Same as above</label>
                    </div>
                </Grid>

                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='firstName' id="cvb-advancedCompute-firstNameLabel"> Title<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        value={workspaceDetails?.additional_fields?.billing_information?.title}
                        onChange={() => { }}
                        fullWidth
                        disabled
                        id='cvb-advancedCompute-firstName'
                        name='firstName'
                        placeholder='Enter Name'

                    />

                </Grid>

                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='firstName' id="cvb-advancedCompute-firstNameLabel"> Name<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        value={workspaceDetails?.additional_fields?.billing_information?.name}
                        onChange={() => { }}
                        fullWidth
                        disabled
                        id='cvb-advancedCompute-firstName'
                        name='firstName'
                        placeholder='Enter Name'

                    />

                </Grid>

                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='firstName' id="cvb-advancedCompute-lastNameLabel"> Email<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        value={workspaceDetails?.additional_fields?.billing_information?.email}
                        onChange={() => { }}
                        fullWidth
                        disabled
                        id='cvb-advancedCompute-lastName'
                        name='lastName'
                        placeholder='Enter email'
                    />

                </Grid>

                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='firstName' id="cvb-advancedCompute-lastNameLabel"> Contact Number<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        value={workspaceDetails?.additional_fields?.billing_information?.phone}
                        disabled
                        onChange={() => { }}
                        fullWidth
                        id='cvb-advancedCompute-lastName'
                        name='lastName'
                        placeholder='Enter email'
                    />

                </Grid>


                <Grid item xs={5}>
                    <label id='cvb-userOnboarding-organizationLabel' htmlFor="organization" > Organization<sup> *</sup></label>
                    <DropDown
                        values={[]}
                        handleFilterStateChange={() => { }}
                        selectedFilter={!isBillingOrgPresent ? 'Other' : workspaceDetails?.additional_fields?.billing_information?.organization_name}
                        placeholder={'Select your organization'}
                        disable
                        customClass={Classes.organizationField}
                        id='cvb-userOnboarding-organizationInput'
                    />

                </Grid>
                {/* Billing Org change is required here.  */}
                {
                    !isBillingOrgPresent || `${workspaceDetails?.additional_fields?.billing_information?.organization_name}`.toLowerCase() === 'other' &&
                    <>

                        <Grid item xs={5} className={Classes.fieldCont}>
                            <label htmlFor='firstName' id="cvb-advancedCompute-lastNameLabel"> Organization Name<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                value={workspaceDetails?.additional_fields?.billing_information?.organization_name}
                                onChange={() => { }}
                                fullWidth
                                disabled
                                id='cvb-advancedCompute-lastName'
                                name='lastName'
                                placeholder='Enter email'
                            />

                        </Grid>


                        <Grid item xs={10} className={Classes.fieldCont}>
                            <Grid item xs={6} className={Classes.fieldCont}>
                                <label htmlFor='country' id="cvb-DIFFormStep1-countryLabel">Country<sup> *</sup></label>
                                <DropDown
                                    id="cvb-DIFFormStep1-instituteCountry"
                                    name='instituteCountry'
                                    values={[]}
                                    handleFilterStateChange={() => { }}
                                    disable
                                    selectedFilter={workspaceDetails?.additional_fields?.billing_information?.address?.country}
                                    placeholder={'Select Country'}

                                />

                            </Grid>
                        </Grid>
                        <Grid item xs={10} className={Classes.fieldCont}>
                            <label htmlFor='firstName' id="cvb-advancedCompute-lastNameLabel"> Street address<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                value={workspaceDetails?.additional_fields?.billing_information?.address?.street}
                                disabled
                                onChange={() => { }}
                                fullWidth
                                id='cvb-advancedCompute-lastName'
                                name='lastName'
                            />

                        </Grid>
                        <Grid item xs={5} className={Classes.fieldCont}>
                            <label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">City<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                value={workspaceDetails?.additional_fields?.billing_information?.address?.city}
                                disabled
                                onChange={() => { }}
                                fullWidth
                                id='cvb-advancedCompute-organization'
                                name='organization'
                                placeholder='Enter city'
                            />

                        </Grid>
                        <Grid item xs={5} className={Classes.fieldCont}>
                            <label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">State<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                value={workspaceDetails?.additional_fields?.billing_information?.address?.country?.state}
                                disabled
                                onChange={() => { }}
                                fullWidth
                                id='cvb-advancedCompute-organization'
                                name='organization'
                                placeholder='Enter state'

                            />

                        </Grid>
                        <Grid item xs={5} className={Classes.fieldCont}>
                            <label htmlFor='organization' id="cvb-advancedCompute-organizationLabel">Zip Code<sup> *</sup></label>
                            <OutlinedInput
                                type="text"
                                value={workspaceDetails?.additional_fields?.billing_information?.address?.zip_code}
                                disabled
                                fullWidth
                                onChange={() => { }}
                                id='cvb-advancedCompute-organization'
                                name='organization'
                                placeholder='Enter zipcode'
                            />

                        </Grid> </>}


                <Grid item xs={10}>
                    <p className={`${Classes.formTitle}`} id="cvb-advancedCompute-workspaceAccountCredit">WorkSpace Account Credit</p>
                </Grid>

                <Grid item xs={5} className={Classes.fieldCont}>
                    <label className={Classes.fullLabelWidth} htmlFor='creditAmount' id="cvb-advancedCompute-creditAmount">Amount of Credit to be added to the WorkSpace<sup> *</sup></label>
                    <OutlinedInput
                        type="number"
                        value={workspaceDetails?.credit_amount}
                        fullWidth
                        disabled
                        onChange={() => { }}
                        id='cvb-advancedCompute-creditAmountInput'
                        name='creditAmount'
                        placeholder='Enter credit amount'
                        startAdornment={(
                            <InputAdornment position="start">
                                $
                            </InputAdornment>
                        )}
                    />

                </Grid>
            </Grid>
            <Grid item xs={12} className={Classes.signatureMainContainer} >
                <div className={Classes.signatureContainer}>
                    <p><label>Sign Policy<sup> *</sup></label></p>
                    <p>Sign your name on the trackpad and press any key when finished</p>
                    <p className={Classes.signBoxText}>By signing below, I confirm that I will comply with the terms and conditions set forth on this BRAINCommons Advanced Compute Contract in addition to the BRAINCommons Data Use Agreement and other applicable policies and terms of use of the BC Platform.</p>
                    <div id='signature-pad' className={Classes.signaturePad}>
                        <div className={Classes.clickHeretoBegin}></div>
                        <img src={workspaceDetails?.additional_fields?.signature} alt="signature"/>
                    </div>
                </div>
                <div xs={3} className={Classes.agreeContainer}>
                    <span className={Classes.agreeCheckbox}>
                        <Checkbox
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            value={true}
                            checked={true}
                            onChange={() => { }}
                        />
                    </span>
                    <span className={Classes.agreeContent}>
                        I confirm that by signing this Agreement I am legally bound by its terms and conditions.
                    </span>
                </div>
            </Grid>
        </Modal>
    )
}

export default RequestAdvancedComputeDetailsView;
/**
 *  External Imports
 */
import React, { useState, useEffect } from "react";
import { func, number, string } from "prop-types";

/**
 *  Internal Imports
 */

import RateStar from "../../../controls/rate-star";
import styles from "./index.module.css";
import {
    DEFAULT_EMPTY_RATE,
    RATE_TEXT,
    RATE_SIZE,
    MIN_RATE_WITHOUT_COMMENT,
} from "../../../../constants/feedback";

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 */
const FeedbackForm = (props) => {
    const { currentRate, userName = "User" } = props;
    const { submitEvent } = props;

    const [rateItems, setRateItems] = useState([]);
    const [rateSelected, setRateSelected] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [hasSelectedRate, setHasSelectedRate] = useState(false);
    const [userMessage, setUserMessage] = useState("");

    const onMessageChange = (event) => setUserMessage(event.target.value);

    const updateSelectionRate = (rateIndex, isEntered) => {
        const selectedRate = rateItems.reduce((acc, element, index) => {
            acc.push(
                index <= rateIndex && !element.isManualSelection
                    ? { ...element, isSelected: isEntered }
                    : element
            );
            return acc;
        }, []);

        setRateItems(selectedRate);
    };

    const setUserSelection = (rateIndex) => {
        const clearReteItemsList = rateItems.map((element) => {
            return { ...element, isSelected: false, isManualSelection: false };
        });
        const selectedRate = clearReteItemsList.reduce(
            (acc, element, index) => {
                acc.push(
                    index <= rateIndex
                        ? {
                              ...element,
                              isSelected: true,
                              isManualSelection: true,
                          }
                        : element
                );
                return acc;
            },
            []
        );

        setRateItems(selectedRate);
        setRateSelected(
            selectedRate.filter((element) => element.isManualSelection).length
        );
        setHasSelectedRate(true);
    };

    const submitForm = () => {
        const formData = {
            text: userMessage,
            rate: rateSelected,
        };

        submitEvent(formData);
    };

    const generateRateItems = (hasRate) => {
        const rate = [];
        const rateIndex = currentRate - 1;
        for (let index = 0; index < RATE_SIZE; index++) {
            const rateItem = {
                id: `rate_${index}`,
                isSelected: hasRate && index <= rateIndex,
                isManualSelection: hasRate && index <= rateIndex,
                rateText: RATE_TEXT[index],
            };
            rate.push(rateItem);
        }
        setRateItems(rate);
        setHasSelectedRate(hasRate);
    };

    useEffect(() => {
        setIsButtonDisabled(!hasSelectedRate);
    }, [userMessage, rateItems]);

    useEffect(() => {
        generateRateItems(currentRate !== DEFAULT_EMPTY_RATE);
    }, [currentRate]);

    useEffect(() => {
        if (rateSelected >= MIN_RATE_WITHOUT_COMMENT && userMessage) {
            setUserMessage("");
        }
    }, [rateSelected, userMessage]);

    return (
        <div className={styles.form}>
            <h3 className={styles.formTitle} data-testid="formTitle">
                We’d love to hear what you think!
            </h3>
            <p className={styles.formText} data-testid="formText">
                Dear {userName}, <br />
                You have recently used BRAINCommons™. Please evaluate your
                experience. This won’t take more than 2 minutes.
            </p>
            <div className={styles.rate}>
                <h4 className={styles.rateTitle} data-testid="formSubtitle">Rate your experience</h4>
                <div className={styles.rateList}>
                    {rateItems.map((rateItem, index) => (
                        <div
                            data-testid="rateStarBlock"
                            className={styles.rateListItem}
                            key={rateItem.id}
                            onMouseEnter={() =>
                                updateSelectionRate(index, true)
                            }
                            onMouseLeave={() =>
                                updateSelectionRate(index, false)
                            }
                            onClick={() => setUserSelection(index)}>
                            <RateStar
                                data-testid="rateStar"
                                isSelected={rateItem.isSelected}
                                rateText={rateItem.rateText}
                            />
                        </div>
                    ))}
                </div>
            </div>
            {hasSelectedRate && rateSelected < MIN_RATE_WITHOUT_COMMENT && (
                <label className={styles.comment}>
                    <span className={styles.commentLabel}>Comment</span>
                    <textarea
                        data-testid="formComment"
                        className={styles.commentTextArea}
                        placeholder="Any other thoughts you’d like to share with us?"
                        maxLength={1_000}
                        value={userMessage}
                        onChange={onMessageChange}
                    />
                </label>
            )}
            <div className={styles.formBtn}>
                <button
                    data-testid="formBtn"
                    disabled={isButtonDisabled}
                    onClick={submitForm}
                    className={
                        isButtonDisabled
                            ? `${styles.submitBtn} cvb-button-disabled`
                            : styles.submitBtn
                    }>
                    Submit
                </button>
            </div>
        </div>
    );
};

/**
 *  Properties
 */
FeedbackForm.propTypes = {
    submitEvent: func.isRequired,
    currentRate: number.isRequired,
    userName: string.isRequired,
};

/**
 *  Exports
 */
export default FeedbackForm;

import React, { useMemo, useState, useEffect, useRef } from 'react'
import Classes from './index.module.css'
import AnalyzeAttributesSelectionCard from '../../controls/analyze-attributes-selection-card';
import Grid from '@mui/material/Grid'
import MuiPaper from '@mui/material/Paper'
import withStyles from '@mui/styles/withStyles';
import find from 'lodash/find'
import DotChart from '../../controls/dot-chart'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import CustomBarChart from '../../controls/bar-chart';
import createPlotlyComponent from 'react-plotly.js/factory';
import { BLUE_COLORS } from '../../../constants/strings'
import maxBy from 'lodash/maxBy'
import findIndex from 'lodash/findIndex'
import LockIcon from '../../../assets/icons/lock_icon.png'
import { isArray, isString } from 'lodash';

const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);

const Paper = withStyles(theme => ({
  root: {
    boxShadow: '0px 0px 15px #E7EAF2',
    borderRadius: 5,
    border: '1px solid #E7EAF2'
  }
}))(MuiPaper);

const AnalysisDataVisualization = props => {
  const {
    currentActive,
    analysisState = [],
    updateField = () => { },
    xAxisValues = [],
    yAxisValues = [],
    currentState = {},
    graphData = {}
  } = props;
  const containerRef = useRef(null);
  const [polyWidth, setPolyWidth] = useState(1000);
  useEffect(() => {
    reportWindowSize();
  }, []);


  const reportWindowSize = () => {
    if (containerRef && containerRef.current) {
      setPolyWidth(containerRef?.current?.offsetWidth - 10 || 1000);
      window.scroll(0, 0)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', reportWindowSize);
    return () => {
      window.removeEventListener('resize', reportWindowSize);
    };
  }, []);

  const selectedAnalysis = find(props.analysisAttributes, (attr) => attr['id'] === props.id);
  const xAttributeValue = get(selectedAnalysis, 'xAxisAttribute', '');
  const yAttributeValue = get(selectedAnalysis, 'yAxisAttribute', '');
  const representationTypeValue = get(selectedAnalysis, 'representationType', '');

  const getLabel = key => {
    if(Array.isArray(key)){
      return key.join("-")
    }
    return key;
  }
  
  const data = useMemo(() => {
    const keys = Object.keys(graphData).filter((item) => !item.startsWith("_"))
    let finalData = [];
    const firstItemKey = keys?.[0]
    if (!firstItemKey) {
      return []
    }
    const xValues = graphData[firstItemKey]?.histogram?.map(({ key }) => getLabel(key)); 
    const modifiedXValues = xValues.map(label => {
      const splittedLabel = label.split(' ')
      if(splittedLabel.length > 3){
        splittedLabel.splice(3, 0, '<br>')
        return splittedLabel.join(' ')
      } else {
        return label
      }
    })
    // get histogram object with maximum terms   
    
     const maxHistogramObj = maxBy(graphData[firstItemKey]?.histogram, (hist)=> hist?.termsFields?.[0]?.terms?.length) || {};
     const isRestrictedItemPresent = findIndex(graphData[firstItemKey]?.histogram, (ht) => ht.count < 0) > -1;

    if(isRestrictedItemPresent){
      return "The chart is hidden because you are exploring restricted access data"
    }
    if (!isEmpty(maxHistogramObj)) {
      const termFields = maxHistogramObj?.termsFields?.[0]?.terms?.map(({ key }) => getLabel(key)) || []
      for (let i = 0; i < termFields.length; i++) {
        let yValues = graphData[firstItemKey]?.histogram?.map(({ termsFields }) => termsFields?.[0]?.terms?.[i]?.count);
        finalData.push({
          x: modifiedXValues,
          y: yValues,
          name: termFields[i],
          type: 'bar',
          marker: {
            color: BLUE_COLORS[i]
          },
          hovertemplate: `(%{x}, %{y}) | ${termFields[i]} <extra></extra>`
        })
      }
    }
    return finalData;

  }, [graphData])

  var layout = {
    title: '',
    barmode: 'stack',
    bargap: 0.5,
    showline: false,
    dragmode: false,
    xaxis: {
      tickangle: 'auto',
    },
    yaxis: {
      showline: false,
      linecolor: "#fff"
    },
    legend: {
      y: -0.6,
      orientation: "h",
      traceorder: 'normal',
      font: {
        family: 'sans-serif',
        size: 12,
      }
    },
    margin: {
      l: 30,
      r: 20,
      b: 150,
      t: 20,
      pad: 5
    },
    width: polyWidth,
    height: 700,
    showlegend: false,
    hovermode: "closest"
  }

  let restrictString = null;
  if (!isEmpty(props.graphData)) {
    const selectedGraphXAxisDataKey = find(props.analysisState, (analysis) => analysis.id === currentActive)?.xAxis;
    if (!isEmpty(selectedGraphXAxisDataKey)) {
      const isRestrictedItemPresent = findIndex(props.graphData?.[selectedGraphXAxisDataKey]?.histogram, (ht) => ht.count < 0) > -1;
      restrictString = isRestrictedItemPresent ? "The chart is hidden because you are exploring restricted access data" : '';
    }
  }
  return (
    <div className={Classes.selectedTabContentContainer}>
      <Grid container spacing={2}>
        <Grid item xs={3} className={Classes.leftFiltersContainer}>
          <Paper>
            <Grid container >
              <AnalyzeAttributesSelectionCard
                selectedAnalysis={selectedAnalysis}
                currentActive={currentActive}
                analysisState={analysisState}
                updateField={updateField}
                xAxisValues={xAxisValues}
                yAxisValues={yAxisValues}
                currentState={currentState} />
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={9} className={Classes.rightVisualizationContainer}>
          <div className={Classes.visualizationContainer}>
            <div className={Classes.visualizationChildContainer}>
              <div className={Classes.visualizationChartTitleContainer}>
                <p id='cvb-analysisDataVisualization-analyseDataChart-heading' className={Classes.visualizationChartTitleText}>Analyze Data Chart</p>
              </div>
              <div ref={containerRef}>
                {
                data?.length > 0 &&

                 ( (isString(data) && !isEmpty(data)) || restrictString !== null && !isEmpty(restrictString) ?
                    <div className={`${Classes.contentContainer} ${Classes.primaryDiagnosisChart}`}>
                      <div className={Classes.graphsErrorContent}>
                        <div className={Classes.errorImageBlock}>
                          <img src={LockIcon} alt="lock icon" />
                        </div>
                        <p>
                          {restrictString}
                        </p>
                      </div>
                    </div>
                  :
                  restrictString !== null && isArray(data) &&
                  <Plot
                    hovermode='closest'
                    autosize="true"
                    data={data}
                    layout={layout}
                    config={{ hovermode: 'closest', scrollZoom: false, showEditInChartStudio: false, displayModeBar: false }}
                  />)
                }
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const areEqual = (prevProps, nextProps) => {
  if (nextProps.xAxisValues?.length === 0 || nextProps.yAxisValues?.length === 0) {
    return true;
  }
  return prevProps.currentActive === nextProps.currentActive &&
    prevProps.analysisState === nextProps.analysisState &&
    prevProps.xAxisValues === nextProps.xAxisValues &&
    prevProps.yAxisValues === nextProps.yAxisValues &&
    prevProps.currentState === nextProps.currentState &&
    prevProps.graphData === nextProps.graphData &&
    prevProps.analysisAttributes === nextProps.analysisAttributes;
}

const memorizeAnalysisDataVisualization = React.memo(AnalysisDataVisualization, areEqual)
export default memorizeAnalysisDataVisualization;
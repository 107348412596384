import React, { useState, useEffect } from 'react'
import Classes from './index.module.css'
import Grid from '@mui/material/Grid'
import strokesBG from '../../../assets/images/strokes-bg.png'
import Footer from '../../commons/footer'
import TopNavBar from '../../controls/top-nav-bar'
import UserOnboard from './UserOnboard'
import { Router } from '@reach/router'
import get from 'lodash/get'
import { Auth } from 'aws-amplify'
import {getUserProfile} from '../../../api/profile-api'

const UserOnboardHome = props => {
    const [firstName, setFirstName] = useState("")
    useEffect(() => {
        getName();
    }, [window.location.href]);

    const getName = async () => {
        try {
            const username = get(props, 'firstname.text', '');
            if (!username) {
                let currentUser = (await Auth.currentUserInfo()) || {};
                const userInfo = await getUserProfile(currentUser?.attributes?.sub);
                let userDetails = userInfo?.user_details || {};
                const name = userDetails?.first_name || "";
                setFirstName(name);
            }
            else {
                setFirstName(username)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    return (
        <div className={Classes.onBoardHomeMainContainer}>
            <TopNavBar showDataPortalLogo />
            <Grid container>
                <Grid item xs={8} lg={5} md={6} className={Classes.backroundImageContainer}>
                    <img className={Classes.strokesImage} src={strokesBG} alt='strokes background' />
                </Grid>
                <div className={Classes.headingsContainer}>
                    <p className={Classes.helloTitleText}>Hello {firstName},</p>
                    <p className={Classes.welcomeText}>Welcome to the BRAINCommons!</p>
                </div>
            </Grid>
            <Router id="useronboard-route" primary={false}>
                <UserOnboard path="/" />
            </Router>
            <Footer />
        </div>
    )
}

export default UserOnboardHome;
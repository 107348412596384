import { SET_PPI_FIRST_NAME, SET_PPI_LAST_NAME, SET_PPI_MIDDLE_NAME, SET_PPI_CREDENTIALS, 
    SET_PPI_INSTITUTE_NAME, SET_PPI_INSTITUTE_EMAIL, SET_PPI_INSTITUTE_STREET_ADDRESS, SET_PPI_INSTITUTE_CITY,
    SET_PPI_INSTITUTE_STATE, SET_PPI_INSTITUTE_ZIP_CODE, SET_PPI_INSTITUTE_COUNTRY, SET_PROJECT_INSTITUTIONAL_FORM_FIELDS
    , RESET_PROJECT_INSTITUTIONAL_FORM_FIELDS
} from '../action-types'
import get from 'lodash/get'
const initialState = {
    ppiFirstName: '',
    ppiLastName: '',
    ppiMiddleName: '',
    ppiCredentials: '',
    ppiInstituteName: '',
    ppiInstituteEmail: '',
    ppiInstituteStreetAddress: '',
    ppiInstituteCity: '',
    ppiInstituteState: '',
    ppiInstituteZipCode: '',
    ppiInstituteCountry: ''
}

const ppiReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PPI_FIRST_NAME: {
            return {
                ...state,
                ppiFirstName: action.payload
            }
        }
        case SET_PPI_LAST_NAME: {
            return {
                ...state,
                ppiLastName: action.payload
            }
        }
        case SET_PPI_MIDDLE_NAME: {
            return {
                ...state,
                ppiMiddleName: action.payload
            }
        }
        case SET_PPI_CREDENTIALS: {
            return {
                ...state,
                ppiCredentials: action.payload
            }
        }
        case SET_PPI_INSTITUTE_NAME: {
            return {
                ...state,
                ppiInstituteName: action.payload
            }
        }
        case SET_PPI_INSTITUTE_EMAIL: {
            return {
                ...state,
                ppiInstituteEmail: action.payload
            }
        }
        case SET_PPI_INSTITUTE_STREET_ADDRESS: {
            return {
                ...state,
                ppiInstituteStreetAddress: action.payload
            }
        }
        case SET_PPI_INSTITUTE_CITY: {
            return {
                ...state,
                ppiInstituteCity: action.payload
            }
        }
        case SET_PPI_INSTITUTE_STATE: {
            return {
                ...state,
                ppiInstituteState: action.payload
            }
        }
        case SET_PPI_INSTITUTE_ZIP_CODE: {
            return {
                ...state,
                ppiInstituteZipCode: action.payload
            }
        }
        case SET_PPI_INSTITUTE_COUNTRY: {
            return {
                ...state,
                ppiInstituteCountry: action.payload
            }
        }
        case SET_PROJECT_INSTITUTIONAL_FORM_FIELDS: {
            const data = action.payload;
            return {
                ppiFirstName: get(data, 'firstName', ''),
                ppiLastName: get(data, 'lastName', ''),
                ppiMiddleName: get(data, 'middleName', ''),
                ppiCredentials: get(data, 'credentials', ''),
                ppiInstituteName: get(data, 'institutionDetails.institutionName', ''),
                ppiInstituteEmail: get(data, 'institutionDetails.institutionalEmail', ''),
                ppiInstituteStreetAddress: get(data, 'institutionDetails.institutionAddress.streetAddress', ''),
                ppiInstituteCity:  get(data, 'institutionDetails.institutionAddress.city', ''),
                ppiInstituteState:  get(data, 'institutionDetails.institutionAddress.state', ''),
                ppiInstituteZipCode:  get(data, 'institutionDetails.institutionAddress.zipCode', ''),
                ppiInstituteCountry:  get(data, 'institutionDetails.institutionAddress.country', '')
            }
        }
        case RESET_PROJECT_INSTITUTIONAL_FORM_FIELDS: {
            return {
                ppiFirstName: '',
                ppiLastName: '',
                ppiMiddleName: '',
                ppiCredentials: '',
                ppiInstituteName: '',
                ppiInstituteEmail: '',
                ppiInstituteStreetAddress: '',
                ppiInstituteCity: '',
                ppiInstituteState: '',
                ppiInstituteZipCode: '',
                ppiInstituteCountry: ''
            }
        }
        default: return state
    }
}

export default ppiReducer;
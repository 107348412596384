import { navigate } from '@reach/router'
import React, { useState, useEffect } from 'react'
import classes from './index.module.css'
import BCDataPortalLogo from '../../../../assets/images/onboarding_logo.png'
import strokesBG from '../../../../assets/images/strokes-bg.png'
import Grid from '@mui/material/Grid'
import deactivationIcon from '../../../../assets/images/deactivate-success.svg';

const DeactivationSuccess = props => {
    
    useEffect(() => {
    }, []);
    
    
    
    return (
        <React.Fragment>
            <header className={classes.cookiePageheader}>
                <div className={classes.leftBCLogoContainer}>
                    <img src={BCDataPortalLogo} alt="braincommons DP logo" />
                </div>
            </header>

            <Grid item xs={8} lg={5} md={6} className={classes.backroundImageContainer}>
                <img className={classes.strokesImage} src={strokesBG} alt='strokes background' />
            </Grid>

            <div className={classes.deactivateBox}>
                        
                <Grid container>
                    <Grid lg={12}>
                        <img src={deactivationIcon}  />
                    </Grid>
                
            
                    <Grid lg={12}>
                        <div className={classes.deactivateTitle}>
                            {
                                props.location?.search?.includes("workspace") ? "Your workspace has been deleted & account has been deactivated." : "Your account has been deactivated"
                            }
                            
                        </div>
                        <div className={classes.deactivateSubTitle}>To reactivate your account, you can contact customer support at <a href="mailto:support@braincommons.org">support@braincommons.org</a> to request for it to be reactivated.</div>

                        
                    </Grid>
                </Grid>

            </div>
            
        </React.Fragment>
    )
}

export default DeactivationSuccess
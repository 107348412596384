/**
 *  External Imports
 */
import Grid from "@mui/material/Grid";
import axios from "axios";
import {arrayOf, func, shape, string} from "prop-types";
import React, {useState} from "react";

/**
 *  Internal Imports
 */
import CommunityWidgetRecommendedConnections from "../../controls/community-widget-recommended-connections";
import styles from "./index.module.css";
import CommunityWidgetBody from "../../controls/community-widget-body";
import useScrollToTop from "../../commons/scroll-top";
import {useUserListFilter} from "../../../utils/hooks";
import CommunityConnectModal from "../../controls/community-connect-modal";
import {WIDGET_TYPES} from "../../../constants/widget-types";

/**
 *  Component
 *
 *  @return {JSX.Element}
 */
const CommunitySearchResults = (props) => {
    const {globalSearchUsers, globalSearchQuery} = props;
	const { fetchSortedSearchUsers} = props;

    const [searchTerm, setSearchTerm] = useState("");
    const [isSortLoaded, setIsSortLoaded] = useState(true);
	const searchResultsCancelTokenSrc = axios.CancelToken.source();

    const {filteredUserList} = useUserListFilter(searchTerm, globalSearchUsers);
	
	// Functions
	const onSearchChange = (value) => setSearchTerm(value);

	const setSortBy = async ({sort, order}) => {
		if (globalSearchQuery) {
            setIsSortLoaded(false);
			await fetchSortedSearchUsers(searchResultsCancelTokenSrc.token, globalSearchQuery, sort, order);
            setIsSortLoaded(true);
		}
	}

    useScrollToTop();

    // Return
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            className={styles.body}>
            <Grid container item xs={8}>
                <div className={`${styles.bodyItem} ${styles.bodyItem_md}`}>
                <CommunityWidgetBody 
                        usersList={filteredUserList}
                        globalSearchQuery={globalSearchQuery}
                        isLoaded={true}
                        isSortLoaded={isSortLoaded}
                        isFullyLoaded={true}
                        widgetType={WIDGET_TYPES.SEARCH}
                        title={"Search Results"}
                        inputPlaceholder={"Search In Results"}
                        setSortBy={setSortBy}
                        onSearchChange={onSearchChange}
                    />
                </div>
            </Grid>

            <Grid
                container
                item
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                xs={4}>
                <div className={styles.bodyItem}>
                    <CommunityWidgetRecommendedConnections />
                </div>
            </Grid>
            <CommunityConnectModal />
        </Grid>
    );
};

CommunitySearchResults.propTypes = {
	globalSearchQuery: string.isRequired,
	globalSearchUsers: arrayOf(
		shape(
			{
				id: string.isRequired,
				userIcon: string.isRequired,
				name: string.isRequired,
				organization: string.isRequired,
				email: string.isRequired,
				status: string.isRequired,
			}
		)
	).isRequired,
	
	fetchSortedSearchUsers: func.isRequired,
};

/**
 *  Exports
 */
export default CommunitySearchResults;

import {
    SET_PROJECT_DETAILS_PROJECT_NAME, SET_PROJECT_DETAILS_PROJECT_SYNOPSIS_TEXT, SET_PROJECT_DETAILS_IRB_APPROVAL_NUMBER,
    SET_PROJECT_DETAILS_IRB_NUMBER_FILE, SET_PROJECT_DETAILS_DESIGNATED_ZONE, SET_PROJECT_DETAILS_RESTRICTION_OF_USE_TEXT,
    SET_PROJECT_DETAILS_IS_DATASET_DOWNLOADABLE, SET_PROJECT_DETAILS_ACK_STATEMENT, SET_PROJECT_DETAILS_TYPE_OF_DATA,
    SET_PROJECT_DETAILS_OTHER_TYPE_DATA_TEXT, SET_PROJECT_DETAILS_TOTAL_SIZE_OF_DATA, SET_PROJECT_DETAILS_LOGO_FILE,
    SET_PROJECT_DETAILS_DESCRIPTION_OF_STUDY, SET_PROJECT_DETAILS_DATA_DESCRIPTION, SET_PROJECT_DETAILS_OBJECTIVE_OF_STUDY,
    SET_PROJECT_DETAILS_DURATION_OF_MONTHS, SET_PROJECT_DETAILS_STUDY_DESIGN, SET_PROJECT_DETAILS_NUMBER_OF_GRANTS,
    SET_PROJECT_DETAILS_LOCATION_OF_STUDY, SET_PROJECT_DETAILS_PUBLICATIONS, REMOVE_PROJECT_DETAILS_PUBLICATIONS,
    SET_PROJECT_DETAILS_PUBLICATION_URL, SET_PROJECT_DETAILS_PUBLICATION_NAME, SET_IS_RESEARCHER_ALLOWED, SET_PROJECT_DETAILS_FORM_FIELDS
    , RESET_PROJECT_DETAILS_FORM_FIELDS, SET_PROJECT_DETAILS_IRB_APPROVAL_KEY
} from '../action-types';
import get from 'lodash/get';

const initialState = {
    projectDetailsProjectName: '',
    projectDetailsSynopsisText: '',
    projectDetailsIRBApprovalNumber: '',
    projectDetailsIRBNumberFile: {},
    projectDetailsDesignatedZone: '',
    projectDetailsRestrictionOfUSeText: '',
    projectDetailsIsDatasetDownloadable: '',
    projectDetailsAckStatement: '',
    projectDetailsTypeOfData: '',
    projectDetailsOtherTypeDataText: '',
    projectDetailsTotalSizeOfData: '',
    projectDetailsLogoFile: {},
    projectDetailsObjectiveOfStudy: '',
    projectDetailsDescriptionOfStudy: '',
    projectDetailsDataDescription: '',
    projectDetailsStudyDesign: '',
    projectDetailsNumberOfGrants: '',
    projectDetailsDurationOfMonths: '',
    projectDetailsLocationOfStudy: '',
    projectDetailsPublications: [{publicationName: '', publicationUrl: ''}],
    isResearcherAllowed: '',
    logoS3Key: '',
    irbApprovalCopyS3Key: ''
}

const projectDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROJECT_DETAILS_PROJECT_NAME: {
            return {
                ...state,
                projectDetailsProjectName: action.payload
            }
        }
        case SET_PROJECT_DETAILS_PROJECT_SYNOPSIS_TEXT: {
            return {
                ...state,
                projectDetailsSynopsisText: action.payload
            }
        }
        case SET_PROJECT_DETAILS_IRB_APPROVAL_NUMBER: {
            return {
                ...state,
                projectDetailsIRBApprovalNumber: action.payload
            }
        }
        case SET_PROJECT_DETAILS_IRB_NUMBER_FILE: {
            return {
                ...state,
                projectDetailsIRBNumberFile: action.payload
            }
        }
        case SET_PROJECT_DETAILS_DESIGNATED_ZONE: {
            return {
                ...state,
                projectDetailsDesignatedZone: action.payload
            }
        }
        case SET_PROJECT_DETAILS_RESTRICTION_OF_USE_TEXT: {
            return {
                ...state,
                projectDetailsRestrictionOfUSeText: action.payload
            }
        }
        case SET_PROJECT_DETAILS_IS_DATASET_DOWNLOADABLE: {
            return {
                ...state,
                projectDetailsIsDatasetDownloadable: action.payload
            }
        }
        case SET_PROJECT_DETAILS_ACK_STATEMENT: {
            return {
                ...state,
                projectDetailsAckStatement: action.payload
            }
        }
        case SET_PROJECT_DETAILS_TYPE_OF_DATA: {
            return {
                ...state,
                projectDetailsTypeOfData: action.payload
            }
        }
        case SET_PROJECT_DETAILS_OTHER_TYPE_DATA_TEXT: {
            return {
                ...state,
                projectDetailsOtherTypeDataText: action.payload
            }
        }
        case SET_PROJECT_DETAILS_TOTAL_SIZE_OF_DATA: {
            return {
                ...state,
                projectDetailsTotalSizeOfData: action.payload
            }
        }
        case SET_PROJECT_DETAILS_LOGO_FILE: {
            return {
                ...state,
                projectDetailsLogoFile: action.payload
            }
        }
        case SET_PROJECT_DETAILS_OBJECTIVE_OF_STUDY: {
            return {
                ...state,
                projectDetailsObjectiveOfStudy: action.payload
            }
        }
        case SET_PROJECT_DETAILS_DESCRIPTION_OF_STUDY: {
            return {
                ...state,
                projectDetailsDescriptionOfStudy: action.payload
            }
        }
        case SET_PROJECT_DETAILS_DATA_DESCRIPTION: {
            return {
                ...state,
                projectDetailsDataDescription: action.payload
            }
        }
        case SET_PROJECT_DETAILS_STUDY_DESIGN: {
            return {
                ...state,
                projectDetailsStudyDesign: action.payload
            }
        }
        case SET_PROJECT_DETAILS_NUMBER_OF_GRANTS: {
            return {
                ...state,
                projectDetailsNumberOfGrants: action.payload
            }
        }
        case SET_PROJECT_DETAILS_DURATION_OF_MONTHS: {
            return {
                ...state,
                projectDetailsDurationOfMonths: action.payload
            }
        }
        case SET_PROJECT_DETAILS_LOCATION_OF_STUDY: {
            return {
                ...state,
                projectDetailsLocationOfStudy: action.payload
            }
        }
        case SET_PROJECT_DETAILS_IRB_APPROVAL_KEY: {
            return {
                ...state,
                irbApprovalCopyS3Key: action.payload
            }
        }
        case SET_PROJECT_DETAILS_PUBLICATIONS: {
            const newPublication =  [...state.projectDetailsPublications];
            newPublication.push(action.payload);
            return {
                ...state,
                projectDetailsPublications: newPublication
            }
        }
        case REMOVE_PROJECT_DETAILS_PUBLICATIONS: {
            
            let newPublication =  [...state.projectDetailsPublications];
            newPublication.splice(action.payload, 1);
            return {
                ...state,
                projectDetailsPublications: newPublication
            }
        }
        case SET_PROJECT_DETAILS_PUBLICATION_URL: {
            const { value, id } = action.payload;
            let newPublication = [...state.projectDetailsPublications];
            newPublication[id] = {
                ...newPublication[id],
                publicationUrl: value
            }
            return {
                ...state,
                projectDetailsPublications: newPublication
            }
        }
        case SET_PROJECT_DETAILS_PUBLICATION_NAME: {
            const { value, id } = action.payload;
            let newPublication = [...state.projectDetailsPublications];
            newPublication[id] = {
                ...newPublication[id],
                publicationName: value
            }
            return {
                ...state,
                projectDetailsPublications: newPublication
            }
        }
        case SET_IS_RESEARCHER_ALLOWED: {
            return {
                ...state,
                isResearcherAllowed: action.payload
            }
        }
        case SET_PROJECT_DETAILS_FORM_FIELDS: {
            const data = { ...action.payload };
            const datasetDownloadableText = get(data, 'datasetDownloadableByUsers', '') ? 'yes' : 'no';
            const isResearcherAllowed = get(data, 'designatedZone.isUseByQualifiedResearchersFromIndustryAllowed', '') ? 'yes' : 'no';
            
            return {
                projectDetailsProjectName: get(data, 'projectName', ''),
                projectDetailsSynopsisText: get(data, 'projectSynopsis', ''),
                projectDetailsIRBApprovalNumber: get(data, 'irbApprovalNumber', ''),
                projectDetailsIRBNumberFile: '',
                projectDetailsDesignatedZone: get(data, 'designatedZone.designatedZoneType', ''),
                projectDetailsRestrictionOfUSeText: get(data, 'designatedZone.restrictionOfUse', ''),
                projectDetailsIsDatasetDownloadable: datasetDownloadableText,
                projectDetailsAckStatement: get(data, 'acknowledgingStatement', ''),
                projectDetailsTypeOfData: get(data, 'typeOfData.dataType', ''),
                projectDetailsOtherTypeDataText: get(data, 'typeOfData.dataDescription', ''),
                projectDetailsTotalSizeOfData: get(data, 'estimatedTotalSizeOfData', ''),
                projectDetailsLogoFile: '',
                projectDetailsObjectiveOfStudy:  get(data, 'objectiveOfStudy', ''),
                projectDetailsDescriptionOfStudy: get(data, 'descriptionOfStudy', ''),
                projectDetailsDataDescription: get(data, 'dataDescription', ''),
                projectDetailsStudyDesign: get(data, 'studyDesign', ''),
                projectDetailsNumberOfGrants: get(data, 'numberOfGrants', ''),
                projectDetailsDurationOfMonths: get(data, 'durationOfStudyInMonths', ''),
                projectDetailsLocationOfStudy: get(data, 'locationOfStudy', ''),
                projectDetailsPublications: get(data, 'publications', []),
                isResearcherAllowed: isResearcherAllowed,
                logoS3Key: get(data, 'logoS3Key', ''),
                irbApprovalCopyS3Key: get(data, 'irbApprovalCopyS3Key', '')
            }
        }
        case RESET_PROJECT_DETAILS_FORM_FIELDS: {
            return {
                projectDetailsProjectName: '',
                projectDetailsSynopsisText: '',
                projectDetailsIRBApprovalNumber: '',
                projectDetailsIRBNumberFile: {},
                projectDetailsDesignatedZone: '',
                projectDetailsRestrictionOfUSeText: '',
                projectDetailsIsDatasetDownloadable: '',
                projectDetailsAckStatement: '',
                projectDetailsTypeOfData: '',
                projectDetailsOtherTypeDataText: '',
                projectDetailsTotalSizeOfData: '',
                projectDetailsLogoFile: {},
                projectDetailsObjectiveOfStudy: '',
                projectDetailsDescriptionOfStudy: '',
                projectDetailsDataDescription: '',
                projectDetailsStudyDesign: '',
                projectDetailsNumberOfGrants: '',
                projectDetailsDurationOfMonths: '',
                projectDetailsLocationOfStudy: '',
                projectDetailsPublications: [{publicationName: '', publicationUrl: ''}],
                isResearcherAllowed: '',
                logoS3Key: '',
                irbApprovalCopyS3Key: ''
            }
        }
        default: return state
    }
}

export default projectDetailsReducer;
import { v4 } from 'uuid';

export const defaultCriterion = () => ({
  IN: {},
  id: v4(),
});

export const defaultGroupCriteria = () => ({
  operator: "AND",
  items: [
    {
      OR: {
        attribute: "",
        value: "",
        operator: "",
      },
      id: v4(),
    },
  ],
  id: v4(),
});


function isRange(items) {
  const keys = Object.values(items).flatMap(v => Object.keys(v))
  return items.length === 2 && keys.includes("gte") && keys.includes("lte")
}


export function queryToCriteria  (cohortQuery)  {
  const operator = Object.keys(cohortQuery)[0];
  const items = Object.values(cohortQuery)[0];
  if((operator === "AND" && !isRange(items)) || operator === "OR") {
      return {
          operator,
          id: v4(),
          items: items.reduce((prev, cur) => [...prev, queryToCriteria(cur)], [])
      }
  } else {
      return {
        ...cohortQuery, 
        id: v4(),
      }
  }

}


  /**
   * Recursively check if the given critera is a valid graphQl query.
   * @param obj
   * @returns {boolean}
   */
  export function hasValidCriteria(obj) {
    if (Array.isArray(obj)) { // Check if object is an array
      for (let item of obj) {
        if (!hasValidCriteria(item)) {
          return false;
        }
      }
    } else if (typeof obj === 'object' && obj !== null) { // Check if object is a non-null object
      if (Object.keys(obj).length === 0) {
        return false;
      }
      for (let key in obj) {
        const value = obj[key];
        if (Array.isArray(value) && value.length === 0) {
          return false;
        } else if (!hasValidCriteria(value)) {
          return false;
        }
      }
    }
    return Boolean(obj);
  }

export function criteriaToQuery(criteria, operator) {
  return {
    [operator.toUpperCase()]: criteria.map(c => {
      if(c.operator) {
        return criteriaToQuery(c.items, c.operator)
      }
      const newCrit = {...c};
      delete newCrit.id
      if(!hasValidCriteria(newCrit)) {
        return null;
      }
      return newCrit;
     }).filter(c => c !== null) 
  }
}

export function isQueryEmpty(query) {
  /*
  const notEmpty = {
    "OR": [
        {
            "IN": {
                "project_id": [
                    "CVB71-simulate71"
                ]
            }
        },
        {
            "AND": []
        }
    ]
  }

  const empty = {
      "OR": [
      ]
  }
  const empty1 = {
      "OR": [
          {
              "AND": []
          }
      ]
  }
  */
  if(!query) return true;
  if(query.length === 0) return true;
  if(Array.isArray(query)) {
     for(const q of query) {
        if(!isQueryEmpty(q)) {
          return false;
        }
            
     }
    return true;
  } else if(typeof query === 'object') {
      return isQueryEmpty(Object.values(query))
  }
  
  return false;
}

function removeAttributeId(attribute) {
  return Object.fromEntries(Object.entries(attribute).filter(([key, _]) => key !== 'id'));
}

export function hasEmpty(criteria) {
  let results = {};

  function compute(criteria) {
    if (Array.isArray(criteria) && criteria.length > 0) {
      for (const attribute of criteria) {
        const obj = removeAttributeId(attribute)

        if (obj.items !== undefined && Array.isArray(obj.items)) {
          compute(obj.items)
        } else {
          const keyObj = Object.values(obj)[0];
          const value = Object.values(keyObj);
          if (Array.isArray(value) && value.length > 0) {
            if (Array.isArray(value[0])) {
              if (value[0].length > 0) {
                results[attribute.id] = true;
              } else {
                results[attribute.id] = false;
              }
            } else if (value[0] === '') {
              results[attribute.id] = false;

            } else { 

              results[attribute.id] = true;
            }
          } else {
            results[attribute.id] = false;

          }
        }
      }
    }
  }

  compute(criteria);

  const resultsArr = Object.entries(results);
  const validResults =  resultsArr.filter(([key, value]) => value)

  return validResults.length !== resultsArr.length ?  true : false;
}
import { SET_DIF_INSTITUTION_INSTITUTION_NAME, SET_DIF_INSTITUTION_STREET_ADDRESS, SET_DIF_INSTITUTION_COUNTRY,
    SET_DIF_INSTITUTION_STATE, SET_DIF_INSTITUTION_CITY, SET_DIF_INSTITUTION_MUNICIPALITY, SET_DIF_INSTITUTION_ZIP_CODE, 
    SET_INSTITUTIONAL_FORM_DATA, RESET_INSTITUTIONAL_FORM_DATA
} from '../action-types'

export const setDifInstitutionInstitutionName = (value) => {
    return {
        type: SET_DIF_INSTITUTION_INSTITUTION_NAME,
        payload: value
    }
}
export const setDifInstitutionStreetAddress = (value) => {
    return {
        type: SET_DIF_INSTITUTION_STREET_ADDRESS,
        payload: value
    }
}

export const setDifInstitutionCountry = (value) => {
    return {
        type: SET_DIF_INSTITUTION_COUNTRY,
        payload: value
    }
}
export const setDifInstitutionState = (value) => {
    return {
        type: SET_DIF_INSTITUTION_STATE,
        payload: value
    }
}
export const setDifInstitutionCity = (value) => {
    return {
        type: SET_DIF_INSTITUTION_CITY,
        payload: value
    }
}
export const setDifInstitutionMunicipality = (value) => {
    return {
        type: SET_DIF_INSTITUTION_MUNICIPALITY,
        payload: value
    }
}
export const setDifInstitutionZipCode = (value) => {
    return {
        type: SET_DIF_INSTITUTION_ZIP_CODE,
        payload: value
    }
}
export const setInstitutionFormData = (value) => {
    return {
        type: SET_INSTITUTIONAL_FORM_DATA,
        payload: value
    }
}
export const resetInstitutionFormData = () => {
    return {
        type: RESET_INSTITUTIONAL_FORM_DATA
    }
}
import React from 'react'
import Modal from '../modal'
import Checkbox from '@mui/material/Checkbox';

import Classes from './index.module.css'
const PrivacyPolicy = props => {
    const { open, showAgreeCheckbox, setPrivacyPolicyButtonDisable, handleClose, dialogProps, submitted, data, policyDate, policyTime } = props;
    const onPrivacyPolicyChange = (checked) => {
        if (checked) {
            setPrivacyPolicyButtonDisable(false)
        } else {
            setPrivacyPolicyButtonDisable(true)
        }
    }
    return (

        <Modal submitted={submitted} policyDate={policyDate} policyTime={policyTime} data={data} open={open} handleClose={handleClose} dialogProps={dialogProps} disableBottomHorizontalDivider disableTitleDivider containerClass="private-policy-content">
            <div>
                <div className={Classes.impTitleHeaderContainer}>
                    <p>Important: </p>
                    <p>The following document describes the ways in which BRAINCommons gathers, uses, discloses
                and manages the data they collect during your use of the platform.</p>
                </div>
                {

                    submitted ?

                        <div className={[Classes.contentStartText, Classes.showHeader].join(' ')}>
                            <p>
                                <span id='cvb-privacyPolicy-submitterDetails' className={Classes.submitDetails} ><p id='cvb-privacyPolicy-submittedOn' className={Classes.submitText}>Submitted</p> on {policyDate} at {policyTime}</span>
                                <p id='cvb-privacyPolicy-name' className={Classes.submitName}>By {data?.first_name + ' ' + data?.last_name}</p>
                            </p>

                        </div>

                        : null
                }
                <p className={Classes.contentStartText}>
                    <b><span>BRAINCommons&trade; GLOBAL PRIVACY NOTICE </span></b>
                </p>
                <p>Last updated: 12/16/2021</p>
                <p><b>CONTENTS</b></p>

                <p>
                    <ol>

                        <li>Introduction</li>
                        <li>Terms used in this BRAINCommons Global Privacy Notice</li>
                        <li>Who controls your Personal Data?</li>
                        <li>What Personal Data do we collect?</li>
                        <li>Why do we collect Personal Data?</li>
                        <li>When do we collect your Personal Data?</li>
                        <li>Will we share your Personal Data with outside parties?</li>
                        <li>What about Special (sensitive) Personal Data?</li>
                        <li>How do we protect your Personal Data?</li>
                        <li>Where will your Personal Data be Processed?</li>
                        <li>Retention of your Personal Data</li>
                        <li>Your data protection rights</li>
                        <li>Questions and Complaints</li>

                    </ol>
                </p>
                <p>
                    <b><span>Introduction</span></b>
                </p>

                <p ><span >When you use the <span
                        >BRAINCommons&trade;</span>(“<b>BC</b>”) Platform, you trust us with your
Personal Data. We are committed to keeping that trust, which starts with
sharing our privacy practices. This BRAINCommons Global Privacy Notice explains in detail
what Personal Data (also known as Personally Identifiable Information) we
collect from Users of the BC Platform and how we use that information.</span></p>

                <p ><span >This BRAINCommons Global Privacy Notice applies to all Users and Data Subjects of the BC Platform. </span></p>

                <p ><span >This BRAINCommons Global Privacy Notice
                    is not intended to apply to any User whose Personal Data we are Processing as a
                    job applicant, employee, and supplier (service provider) of BRAINCommons LLC (“<b>BRAINCommons LLC</b>”) or any of its affiliates.</span></p>

                <p ><b><span
                    >Terms used in this BRAINCommons Global Privacy
Notice</span></b></p>

                <p>
                    <b>
                        <i>
                            <span>“Advanced Compute Privileges”</span>
                        </i>
                    </b>
                    <span> are privileges granted to designated Users of the BC Platform that meet specified requirements and 
                        have submitted the required documentation and have institutional certification approved for requested 
                        compute services. These Users have the ability to download data to workspaces and access to applications 
                        that support interoperability and tool development.
                    </span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“BC Executive Director”</span>
                        </i>
                    </b>
                    <span> is the individual representative of the BC Manager with senior responsibility and authority for the management of the BRAINCommons Platform.
                    </span>
                </p>

                <p>
                    <b>
                        <i>
                            <span>“BC Manager”</span>
                        </i>
                    </b>
                    <span> is the manager institution of the BRAINCommons with responsibility for the management of the BRAINCommons Platform. The BC Manager may be BRAINCommons LLC or its designee.
                    </span>
                </p>

                <p ><b><i><span   >“<span
                    >BRAINCommons</span>”</span></i></b> or <b><i><span>“BC” </span></i></b><span
                    >is a <span    >knowledge commons</span> that collocates or
integrates data, storage and computing infrastructure with services, tools,
interfaces and applications for managing, <span >analyzing </span>,
and sharing data to create an interoperable resource for the brain and other
research communities that enables and encourages data sharing, collaboration
and data-driven discovery. </span></p>

                <p ><b><i><span   >“<span
                    >BRAINCommons</span> Data Contributor Agreement”</span></i></b><i><span
                    > </span></i><span>is an agreement between BRAINCommons LLC and the Data Contributor that sets out the terms and conditions for contribution of Data, potentially including De-identified Personal Data, to the BC Platform. Pursuant to the BRAINCommons Data Contributor Agreement, the Data Contributor agrees to contribute certain datasets that consist of pre-clinical and/or de-identified bio-medical human data (including but not limited to clinical, genomic, imaging, or biosensor data) to the BC Platform and permits the BC Manager to grant Users certain access rights based on the such Data Contributor’s designated zone rights (i.e. Zone 1, Zone 2 or Zone 3), to access and use such contributed Data via the BC Platform subject to applicable law, the BRAINCommons Terms of Use and if applicable, the BRAINCommons Data Use Agreement and any organizational or dataset-specific use agreement.</span></p>

                <p ><b><i><span   >“<span
                    >BRAINCommons</span> Data Use Agreement</span></i><span   >”</span></b><span
                    > is an agreement that sets out requirements and standard operating
                        procedures for contribution, access, use and sharing of various datasets from
research into brain disorders and diseases within the BC Platform Zone 2 or Zone 3.</span></p>

                <p>
                    <b>
                        <i>
                            <span>
                                “<span>
                                    BRAINCommons
                                </span> Platform” 
                            </span>
                        </i>
                    </b>
                    <i>
                        <span>or “<b>BC Platform</b>” </span>
                    </i>
                    <span> is the software (and other technology)-enabled platform for managing, analyzing and sharing de-identified bio-medical data, which is provided or made available in connection with the BRAINCommons.</span></p>

                <p ><b><i><span   >“<span
                    >BRAINCommons</span> Services Agreement</span></i><span   >”</span></b><span
                    > is an agreement that sets out the terms and obligations for Advanced Compute Privileges under which BRAINCommons LLC provides certain BRAINCommons services to Users that includes: collocation/integration of data, storage and computing infrastructure with services, tools, interfaces, and applications for managing, analyzing, and sharing bio-medical data. This BRAINCommons Services Agreement will require institutional certification to approve, enable and compensate for Advanced Compute Privileges.</span></p>

                <p ><b><i><span   >“<span
                    >BRAINCommons</span> Terms of Use”</span></i></b><span   > are a set of terms that regulates the use of the BC Platform by all Users, including access levels, any services or tools made available through the BC Platform as well as the use and sharing of any content and data residing on the BC Platform.</span></p>

                <p ><a><b><i><span
                >“Controlled Access”</span></i></b><span   > means a data access model whereby Users that are qualified researchers are granted access to several datasets in a controlled environment in the BC Platform. Controlled Access allows for use of some visualization and basic data exploration tools with the associated datasets and interaction with the community. Users granted with Controlled Access can also request Advanced Compute Privileges. 
                    </span></a><span
                    >See also <b>“Zone 2”</b>. </span></p>

                <p ><b><i><span   >“<span
                    >Data</span>”</span></i></b><span > is any information, results, raw or processed data, including De-Identified Personal Data, that may be stored, uploaded or shared on the BC.</span></p>

                <p ><b><i><span   >“Data Breach”</span></i></b><i><span   > </span></i><span   > means a
security incident that affects the confidentiality, <span    >integrity </span>
or availability of data, including accidental or unlawful destruction, loss,
alteration, unauthorized disclosure or access to data.</span></p>

<p ><b><i><span   >“Data Contributor”</span></i></b><i><span   > </span></i><span   >is the entity providing Data, possibly including De-identified Personal Data, to the BC Platform with legal authority to share such Data for the indicated purposes as prescribed in the BRAINCommons Data Contributor Agreement.</span></p>

                <p ><b><i><span   >“Data
Controller”</span></i></b><span   > means the BC Manager, who makes decisions as to the purposes and means for Processing De-identified Personal Data contributed under the BRAINCommons Data Contributor Agreement and for Processing any De-identified Personal Data of BC Users accessing the BRAINCommons Platform.</span></p>

                <p ><b><i><span   >“Data
Subject”</span></i></b><span   > means the living individual whose data has been or will be collected, generated, held, used or shared (in other words, Processed) on the BC Platform.  </span></p>

                <p ><b><i><span   >“De-identified Personal Data</span></i><span   >”</span></b><span   > or <b><i>“Pseudonymized
Data”</i></b> means personal identifiers are replaced with nonidentifying references or Encryption Keys so that anyone working with the data is unable to identify the Data Subject without the Encryption Key.</span></p>

                <p ><b><i><span   >“Educational Purposes”</span></i></b><span   > means a purpose that relates directly to learning, teaching, training, and professional development from use of Data.  This includes non-profit and commercial activities that achieve this purpose. </span></p>

                <p ><b><i><span   >“Encryption”</span></i></b><span
                > means re-ordering bits of data to make it unintelligible and therefore unusable to an unauthorized person while still enabling an authorized user to use the data after the reverse process of decryption with a Encryption Key.</span></p>

                <p ><b><i><span   >“Encryption Key”</span></i></b><span
                > means a piece of data that an Encryption algorithm uses to determine exactly how to unscramble De-identified Personal Data or Pseudonymized Data. Such Encryption Keys are not provided to the BC Manager, or otherwise housed within the BC.</span></p>

                <p ><b><i><span   >“Identity
and Access Management”</span></i></b><i><span   > </span></i><span
                    > means a set of business processes and supporting technologies that enable the creation, maintenance, use and revocation of digital identity. This includes identity proofing, credential issuance, rights authorization, identity authentication, and privilege revocation. Identity and Access Management practices help ensure that the right Users gain access via the BC Platform to the right services and data at the right time, as well as making it safer, more secure, and simpler to change access rights, group memberships, and other key attributes as Users and systems grow, change, are added to or are removed.  </span></p>


                <p ><b><i><span   >“<span
                       >Personally</span> Identifiable Information (PII)”</span></i></b><i><span
                    > </span></i><span   >or <b>“<i>Personal Data”</i></b> typically includes personal contact details such as name, title, address, telephone numbers, place of employment/institution where the individual is based, email address and area of expertise, activity/medical research and interests.</span></p>

                <p ><b><i><span   >“Process”,
“Processing”</span></i></b><span   > or <b><i>“Processed”</i></b> means any operation or set of operations that is performed on data or on sets of data, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, removal or destruction.</span></p>

                <p ><b><i><span   >“Privacy,
Data Protection and Security Training”</span></i></b><i><span   > </span></i><span
                    > is the free online training offered to Users by the <span
                        >BRAINCommons</span> relating to privacy, data protection and
security. </span></p>

                <p ><a><b><i><span
                >&quot;Public Access”</span></i></b><i><span   > </span></i><span
                > means a data access model whereby all Users have access to public
                        datasets in the BC Platform. Public Access allows for use of some visualization
                        and basic data exploration tools with the associated datasets and interaction
                        with the community.
See also <b>“Zone 1”</b>.</span></a></p>

                <p ><b><i><span   >“Research Purposes”</span></i></b><i><span   > </span></i><span
                    > means non-commercial activities undertaken for the advancement of knowledge. It includes analysis on data to conduct a systematic investigation, including research development, testing, or evaluation, which is designed to develop or contribute to generalizable knowledge or education.</span></p>

                <p ><a ><b><i><span
                >“Restricted Access”</span></i></b><span   > means a data access model whereby designated Users are granted access to one dataset in a controlled environment in the BC Platform. Restricted Access allows for use of some visualization and basic data exploration tools with the designated dataset and interaction with the community.  See also <b>“Zone 3”</b>. </span></a></p>

                <span ></span>

                <p ><b><i><span   >“Special
(sensitive) Personal Data”</span></i></b><i><span   > </span></i><span
                    > includes information about a Data Subject’s ethnicity, religious beliefs, sexual orientation, health (including medical conditions), injury or disability, genetic and biometric data, criminal offences and convictions.</span></p>

                <p ><b><i><span
                >“Users”</span></i></b><i><span   > </span></i><span
                > means (1) registered users, including the general public, (2) authorized users who are qualified researchers, and (3) designated users designated by Data Contributor for use of a specific dataset, tool and/or application, in each case, accessing the BC Platform for Education Purposes or Research Purposes.</span></p>

                <p ><b><i><span   >“Zone 1”</span></i></b><span
                > is the level of ‘Public Access’ and provides Users access to Data that is subject to the BRAINCommons Terms of Use, this BRAINCommons Global Privacy Notice and BRAINCommons Global Privacy Policy.  </span></p>

                <p ><b><i><span   >“Zone 2”</span></i></b><span
                > is the level of ‘Controlled Access’ and provides more limited access to Data to Users who are verified and meet the controlled user requirements as determined by the Data Access Committee (or, as applicable, via another approval process implemented by the BC Manager). Each such authorized User must enter into a BRAINCommons Data Use Agreement and if applicable, an organizational or dataset-specific use agreement as part of his or her Zone Access Request. </span></p>

                <p ><b><i><span   >“Zone 3”</span></i></b><span
                > is the level of ‘Restricted Access’ and provides access to specific datasets or tools to User(s) that are (a) specifically authorized by the Data Contributor or (b) approved through the Data Access Committee (or, as applicable, via another approval process implemented by the BC Manager). Prior to being granted access to Zone 3 Data, each such authorized User must enter into a BRAINCommons Data Use Agreement and if applicable, an organizational or Dataset-specific Use Agreement.</span></p>

                <p ><b><i><span   >“Zone Access Request”</span></i></b><span
                > is the request that a User may make in order to request for access to Data that are designated as Zone 2 or Zone 3.</span></p>

                <p ><b><span
                    >&nbsp;</span></b></p>

                <p ><b><span
                    >Who controls your Personal Data?</span></b></p>

                <p ><span >If you use the <span
                        >BRAINCommons</span> Platform (including access to our services)
then the Data Controller is:</span></p>

                <p ><a><span
                    >BRAINCommons LLC, a Delaware limited liability company operated not for profit, with a principal place of business at One Broadway, </span></a><span ><span 
                        >14th Floor, Cambridge MA 02142
and receiving notices with </span></span><span ><span
                        >attention to BC Executive Director</span></span><span ><span
                         > at </span></span><span
                        ><span >535
8<sup>th</sup> Ave, 12<sup>th</sup> Floor, New York, NY 10018, USA. </span></span></p>

                <span ></span>

                <p ><span >For any questions, please
contact the BC Executive Director by email at  </span><span   ><a
                        href="mailto:notice@braincommons.org">notice@braincommons.org</a></span>
                        <span> or
by post at the New York, US address listed above. All notices received will be triaged for appropriate action and response.</span></p>

                <p ><span >For simplicity throughout
                    this BRAINCommons Global Privacy Notice, ‘we’, ‘us’ and ‘our’ means BRAINCommons LLC.</span></p>

                <p ><b><span
                    >What Personal Data do we collect?</span></b></p>

                <p ><span >The main reason we <span
                           >collect</span> and Process your Personal Data is to provide,
evaluate and improve your experience with us and the services we provide to
you.</span></p>

                <p ><u><span
                    >Information
you provide to us voluntarily online:</span></u></p>

                <ul  >
                    <li ><span >If <span    >you’re </span>
     making an enquiry, your contact details and other information necessary to
     fulfil your enquiry will be collected by us.</span></li>
                    <li ><span >If <span    >you’re </span>
     registering to use the BC Platform and/or use our services or during the
     course of receiving our services, the types of Personal Data collected by
     us may include:</span></li>
                    <ul  >
                        <li ><span >personalized registration
      username and password (recorded by the BC Platform);</span></li>
                        <li ><span >personal and work contact
      details (including email and phone numbers);</span></li>
                        <li ><span >proof of identity (such as
                                your credentials with your organization or institution) so that this
      information can be verified by us.</span></li>
                    </ul>
                </ul>

                <p ><u><span
                    >Information
we create/Process when you use the BC Platform and our services:</span></u></p>

                <ul  >
                    <li ><span >Details of
                            your visits to the BC Platform, Controlled Access, use of apps, and social
     media, as well as information gathered <span    >by the use of </span>
     cookies in your web browser. </span><span >Learn more about how we use cookies and similar technologies by reviewing our Global Cookie Policy. </span><span
                            ></span></li>
                    <li>
                        <span>For purposes of authentication, monitoring, completeness or accuracy, we may receive information about you from other sources, including third parties you have authorized to share information with us, and we may add or relate such information to your account. </span>
                    </li>
                    <li ><span >For all our services, we
     create records <span    >during the course of</span> providing
     services to you. This includes your utilization of Data which may include De-identified Personal Data,
     such as dates of use, type of service used and reasons for the service
     provided to you.</span></li>
                    <li ><span >Payment information for the
     services being provided.</span></li>
                    <li ><span >Social media username and public comments/feedback on social media so that we can respond to comments, questions or feedback. These online and social media sites typically have their own privacy policies explaining how they use and share your Personal Data. You should carefully review those privacy policies before using these social media sites to make sure that you’re happy with how they collect and share your Personal Data.</span></li>
                </ul>

                <p ><b><span
                    >Why do we collect Personal Data?</span></b></p>

                <p ><span >In order to provide you access and use of our services, we must collect your Personal Data, but we always collect the minimum amount of your Personal Data in order to provide you access and services that you have requested.</span></p>

                <p ><span >We collect your Personal Data in the following circumstances:</span></p>

                <p ><u><span
                    >With your Consent</span></u><span >: We rely on your consent to Process your Personal
                        Data in very limited circumstances such as your email for managing your account
on the BC Platform. </span></p>

                <p ><u><span
                    >Under BC Agreements</span></u><span >: Most of your Personal Data we Process is necessary to perform our obligations under an agreement we have with you or your employer, for example, if you’re a User with Advanced Compute Privileges. We can only provide the service to you if you provide us with the Personal Data necessary to perform the services set forth in the applicable agreement and abide by the terms and conditions, for example, that apply to Controlled Access or Restricted Access. Further details can be found in the BRAINCommons Terms of Use, BRAINCommons Data Contributor Agreement, BRAINCommons Data Use Agreement and any other BRAINCommons agreements and policies.</span></p>

                <p ><u><span
                    >Legal Obligations</span></u><span >: Across the services we provide through the BC, there are many laws that require us to Process your Personal Data. We can only provide the service to you if you provide us with the Personal Data required under law, for example, to monitor compliance with use of your Advanced Compute Privileges. </span></p>

                <p ><u><span
                    >Legitimate Interests</span></u><span >: In some situations, we Process your Personal Data to pursue our legitimate interests as a non-profit limited liability company. We will only Process your Personal Data if our legitimate interests do not materially impact your fundamental rights, freedoms or interests. Examples include: </span></p>

                <ul  >
                    <li ><span >If you are a prospective or current user, using your contact details to follow-up either by email or post to obtain your feedback on our BC Platform and service offering, reasons for choosing or not choosing our BC Platform and/or services and your experiences with BRAINCommons.</span></li>
                    <li ><span >Using your purchase, <span
                                   >utilization</span> and inquiry history to send appropriate
     information about our services available to you.</span></li>
                    <li ><span >Combining the User information of the BC Platform and services to identify trends, for educational purposes and research purposes as well as compiling statistics for improving our BC Platform or developing new services and tools. For clarity, as used in the foregoing sentence, (a) educational purposes means a purpose that relates directly to learning, teaching, training, and professional development of the De-identified Personal Data.  This includes non-profit and commercial activities that achieves this purpose; and (b) research purposes mean activities undertaken for the advancement of knowledge for the brain and other research community that enable and encourage data sharing, collaboration and data-driven discovery. It includes means analysis on data to conduct a systematic investigation, including research development, testing, or evaluation, which is designed to develop or contribute to generalizable knowledge or education. </span></li>
                    <li ><span
                            >Tracking your interactions on the BC Platform to
     determine effectiveness and improve the User experience.</span><b><span
                            ></span></b></li>
                </ul>

                <p ><b><span
                    >&nbsp;</span></b></p>

                <p ><b><span
                    >When do we collect your Personal Data?</span></b></p>

                <p  ><span >How you interact with us determines when we collect your Personal Data. </span></p>

                <p  ><span >When you use the BC Platform:</span></p>

                <ul  >
                    <li ><span
                        >Contacting us for information or customer service.</span></li>
                    <li ><span
                        >Creating an account
     and updating your Personal Data information.</span></li>
                    <li ><span
                        >Registering or making
     a reservation for the use of our services.</span></li>
                    <li ><span
                        >Making a payment for
     our services.</span></li>
                    <li ><span
                        >Downloading or
     installing one of our apps.</span></li>
                    <li ><span
                        >While receiving
     services, completing electronic forms, <span    >assessments </span>
     and other documentation.</span></li>
                </ul>

                <p ><b><span
                    >Will we share your Personal Data with outside parties?</span></b></p>

                <p ><span >We consider your Personal Data confidential and do not share it with others except as described in this BRAINCommons Global Privacy Notice. There are limited circumstances that require us to disclose your Personal Data to others, such as to deliver services, to meet our legal obligations or legitimate interests. Examples include:</span></p>

                <ul  >
                    <li ><span >Your Employer/Institution: The BC services are often made available to you in your role as an employee of an organization or institution. In order to meet our obligations with your employer, we may provide details of your use of the BC services that may include your name, dates of use, reasons for use, and other employer requested utilization details. We disclose only information relevant to the utilization of the services or necessary for the administration of the benefit(s) that are delivered to you as a result of your employment.</span></li>
                </ul>

                <ul  >
                    <li ><span >Meeting Legal Requirements: We share Personal Data if required by law or regulations, such as where there’s been a Data Breach, or as we reasonably determine to be necessary to protect our rights or the rights of others, to prevent harm to persons or property, to fight fraud, or to enforce our website terms of use. For example, a government (in the United States, European Union, Canada or India) may require us to disclose Personal Data for national security or law enforcement purposes.</span></li>
                </ul>

                <p ><b><span
                    >&nbsp;</span></b></p>

                <p ><b><span
                    >What about Special (sensitive) Personal Data?</span></b></p>

                <p ><span >We do not Process Special
(sensitive) Personal Data of Users of the BC Platform or our services. <span
                        ></span></span></p>

                <p ><b><span
                    >&nbsp;</span></b></p>

                <p ><b><span
                    >How do we protect your Personal Data?</span></b></p>

                <p ><span >We utilize appropriate technical, administrative and physical safeguards to protect the Personal Data that we collect. This includes Encryption, pseudonymization and Identity and Access Management. We provide Privacy, Data Protection and Security Training to Users of the BC Platform and conduct periodic quality assurance audits. However, no computer system or information can ever be fully protected against every possible hazard. As a result, we can’t guarantee the security and privacy of the Personal Data you provide to us.</span></p>

                <p ><span >You also play a valuable part in protecting the security of your information. Your password to access your registration information should never be shared with anyone and should be changed frequently. After you have finished using the BC Platform, you should log out and exit your browser to prevent unauthorized users from returning to your online BC account. If you believe that someone has improperly used your account or provided information about you that you did not authorize, please contact us immediately at </span><span   ><a
                        href="mailto:notice@braincommons.org"><span >notice@braincommons.org</span></a></span><span
                        > . All notices received will be triaged for appropriate action and response.  </span></p>

                <p ><b><span
                    >&nbsp;</span></b></p>

                <p ><b><span
                    >Where will your Personal Data be Processed?</span></b></p>

                <p ><span >All Personal Data is
                    Processed by us on cloud servers in the US and managed by our third party
service <span    >providers.</span> We comply with all applicable laws
and regulations relating to international transfers of Personal Data from
outside of the USA, such as the EU General Data Protection Regulation (GDPR).</span></p>

                <p ><b><span
                    >&nbsp;</span></b></p>

                <p ><b><span
                    >Retention of your Personal Data</span></b></p>

                <p ><span >Whenever we collect or Process your Personal Data, we’ll only keep it for as long as is necessary for the purpose for which it was collected and as required under law. At the end of that retention period, your Personal Data will either be deleted completely or anonymized, for example by aggregation with other data so that it can be used in a non-identifiable way for statistical analysis and business planning.</span></p>

                <p ><b><span
                    >&nbsp;</span></b></p>

                <p ><b><span
                    >Your data protection <span    >rights </span></span></b></p>

                <p  ><span >You have the right to request:</span></p>

                <ul  >
                    <li ><span
                        >Access to the Personal Data we hold about you, free of charge in
     most cases.</span></li>
                    <li ><span
                        >The correction of your Personal <span >Data when </span>
     incorrect, out of date or incomplete.</span></li>
                    <li ><span
                        >That we stop using your Personal Data for direct marketing (either
     through specific channels or all channels).</span></li>
                    <li ><span
                        >That we stop any consent-based Processing of your Personal Data
     after you withdraw that consent.</span></li>
                </ul>

                <p  ><span >To ask for copies of your Personal Data, please
complete our <span >Subject Access
Request (SAR) Form </span>and return it to </span><span   ><a
                        href="mailto:notice@braincommons.org"><span >notice@braincommons.org</span></a></span><span
                        > </span><span >. </span><span
                        >Your
                        Personal Data can be amended simply by accessing your online BC Platform
account.</span></p>

                <p  ><span >In cases where we are Processing your Personal Data on the basis of our legitimate interest, you can ask us to stop for reasons connected to your individual situation. We must then do so unless we believe we have a legitimate overriding reason to continue Processing your Personal Data.</span></p>

                <p  ><span >You have the right to stop the use of your Personal Data for direct marketing activity through all channels or selected channels. We must always comply with your request. There are several ways you can stop direct marketing communications from us:</span></p>

                <ul  >
                    <li ><span
                        >Click the ‘unsubscribe’ link in any email communication that we send
     you. We will then stop any further emails from that <span    >particular
     mailing</span> list. </span></li>
                    <li ><span
                        >If you have a BC account, log into your account and change your
     preferences.</span></li>
                </ul>

                <p  ><span >Please note that you may continue to receive
                    communications for a short period after changing your preferences until our
systems are fully updated.</span></p>

                <p  ><span >To protect the confidentiality of your Personal Data, we’ll ask you to verify your identity before proceeding with any request you make under this BRAINCommons Global Privacy Notice. If you feel that your Personal Data hasn’t been handled correctly, or you are unhappy with our response to any requests you’ve made to us regarding the use of your Personal Data, you’ve the right to lodge a complaint with the relevant data protection regulator in your territory.</span></p>

                <p ><b><span
                    >&nbsp;</span></b></p>

                <p ><b><span
                    >Questions and Complaints</span></b></p>

                <p ><span >We hope this BRAINCommons Global Privacy Notice has been helpful in
setting out the way we handle your Personal Data and your rights to control it.</span></p>

                <p ><span >If you’ve any questions that <span    >haven’t</span>
been covered, please email us at <a href="mailto:notice@braincommons.org"><span >notice@braincommons.org </span></a>. 
All notices received will be triaged for appropriate action and response.</span></p>

                <p ><span> Or write to us at Attn: BC Executive Director, BRAINCommons LLC, a limited liability company operated not for profit, located at 535 8<sup>th</sup> Ave, 12<sup>th</sup>
Floor, New York, NY 10018, USA. </span></p>

                <p ><span >&nbsp;</span></p>
                {
                    showAgreeCheckbox ?
                        <div className={Classes.checkboxContainer}>
                            <Checkbox
                                color="primary"
                                onChange={(e) => onPrivacyPolicyChange(e.target.checked)}
                                id='cvb-privacyPolicy-checkbox'
                            /> <label className={Classes.labelText}>I agree to Privacy Notice</label>
                        </div> : null
                }
            </div>
        </Modal>

    )
}

export default PrivacyPolicy;
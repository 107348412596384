import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import classes from './index.module.css'
const DashboardUserCard = (props) => {
    const {selectedUsersOrZone = null, viewDatasetDetails = { },key = 0, zone= '', count= 0, onViewClick = ()=>{ }, icon = null, title = '', type =''} = props;
    useEffect(() => {
        
    }, []);

    const checkDevicePixelRatio = window.navigator.appVersion.indexOf('Win') !== -1
    ? window.devicePixelRatio > 1.25 ? `${classes.highDPI} ${classes.zoneTilesDetailsHeader}`
        : classes.zoneTilesDetailsHeader
    : classes.zoneTilesDetailsHeader;
    
    return (
        <React.Fragment>
            {
                props.isVisible ?
                    <Grid item xs>
                        {
                            (selectedUsersOrZone == zone) ?
                                <div className={classes.zoneTilesBlocksSelected} >
                                    <div className={checkDevicePixelRatio}>
                                        <img src={icon} alt="dataset" />
                                        <p id='cvb-adminDashboard-zone1TotalDatasetHeading'>{title}</p>
                                    </div>
                                    <div className={classes.zoneTilesDetailsCount}>
                                        <h3 id='cvb-adminDashboard-zone1DatasetCount'>{count}</h3>
                                    </div>
                                </div> :
                                <div className={classes.zoneTilesBlocks}>
                                    <div className={checkDevicePixelRatio}>
                                        <img src={icon} alt="dataset" />
                                        <p id='cvb-adminDashboard-zone1TotalDatasetHeading'>{title}</p>
                                    </div>
                                    <div className={classes.zoneTilesDetailsCount}>
                                        <h3 id='cvb-adminDashboard-zone1DatasetCount'>{count}</h3>
                                    </div>
                                    {props.type === 'dataset' ?
                                        <div className={classes.zoneTilesDetailsButton}>
                                            <button id='cvb-adminDashboard-zone1ViewDatasetButton' className={classes.zoneBlockTableSolidButtonTransparent}
                                                onClick={() => { onViewClick() }}>View</button>
                                        </div>
                                        :
                                        (props.type === 'user' && props.canViewUserDetails || false) ? <div className={classes.zoneTilesDetailsButton}>
                                            <button id='cvb-adminDashboard-zone1ViewDatasetButton' className={classes.zoneBlockTableSolidButtonTransparent}
                                                onClick={() => { onViewClick() }}>View</button>
                                        </div>
                                            : null
                                    }
                                </div>
                        }
                    </Grid>
                    : null
            }
        </React.Fragment>
    )
}

export default DashboardUserCard;
import Box from "@mui/material/Box";
import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { vars } from "../../../../assets/variables";
import CriteriaBlock from "./CriteriaBlock";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CriteriaKeyOption from "./CriteriaKeyOption";
import { v4 } from "uuid";
import { Draggable, Droppable } from "react-beautiful-dnd";
import React, { useState } from "react";
import { defaultCriterion } from "./utils";

const {
  primaryColor,
  primaryTextColor,
  selectPlaceholderColor,
  outlinedInputBorderColor,
  white,
} = vars;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  minHeight: 42,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const useStyles = makeStyles((theme) => ({
  block: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  wrapSecondary: {
    filter: "drop-shadow(0 0.0625rem 0.125rem rgba(231, 234, 242, 0.6))",

    "& .MuiIconButton-root": {
      borderRadius: 0,
      height: "2.75rem",
      backgroundColor: white,
      padding: "0.875rem 0.75rem !important",
      border: `0.0625rem solid ${outlinedInputBorderColor}`,
      color: primaryTextColor,

      "& + .MuiIconButton-root": {
        borderTopRightRadius: "0.375rem",
        borderBottomRightRadius: "0.375rem",
      },

      "& svg": {
        width: "1rem",
        height: "1rem",
      },
    },

    "& .MuiButton-text": {
      borderRadius: "0.375rem 0 0 0.375rem",
      height: "2.8125rem",
      backgroundColor: white,
      padding: "0.875rem 0.75rem !important",
      border: `0.0625rem solid ${outlinedInputBorderColor}`,
      minWidth: "9.75rem",
      color: primaryTextColor,
      justifyContent: "space-between",
      margin: 0,
      fontWeight: "500",
      fontSize: "0.875rem",

      "& em": {
        fontStyle: "normal",
        color: selectPlaceholderColor,
      },

      "& svg": {
        color: primaryTextColor,
      },

      "&:hover": {
        borderColor: primaryColor,
      },

      "&[aria-describedby]": {
        borderColor: primaryColor,
        boxShadow: "0 0 0 0.0625rem rgba(77, 128, 198, 0.3)",
      },
    },

    "& .MuiFormControl-root": {
      width: "9.75rem",

      "& .MuiOutlinedInput-root": {
        borderRadius: 0,
      },
    },
  },

  card: {
    background: white,
    border: `0.0625rem solid ${outlinedInputBorderColor}`,
    boxShadow: "0 0.0625rem 0.125rem rgba(231, 234, 242, 0.6)",
    borderRadius: "0.375rem",
    minWidth: "41.625rem",
    padding: "0 0.75rem",
  },

  cardHeader: {
    minHeight: "2.6875rem",
    "& p": {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: "1.0625rem",
      color: primaryTextColor,

      "& em": {
        color: selectPlaceholderColor,
        fontStyle: "normal",
      },
    },

    "& .MuiIconButton-root": {
      borderRadius: ".25rem",
      padding: "0.5rem 0.8125rem !important",
      color: primaryTextColor,
      "&.MuiIconButton-colorPrimary": {
        color: primaryColor,
        '&[aria-expanded="true"]': {
          "& .MuiSvgIcon-root": {
            boxShadow: `0 0 0.75rem rgba(77, 128, 198, 0.4), 0 0 0 0.0625rem ${primaryColor}`,
            borderRadius: "50%",
          },
        },
      },
      "& .MuiSvgIcon-root": {
        width: "1rem",
        height: "1rem",
      },
    },
  },
}));

const GroupCriteriaBlock = (props) => {
  const classes = useStyles();
  const {
    criteria: group,
    snapshot,
    provided,
    innerRef,
    index: groupIndex,
    onDeleteGroup,
    onDeleteItem,
    updateGroup,
    parentOperator,
    setParentOperator,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const groupOperator = group.operator;

  const criteriaBlocks = group.items;

  const open = Boolean(anchorEl);

  const handleAddCriterion = (event) => {
    updateGroup({
      ...group,
      items: [...group.items, defaultCriterion()],
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function updateCriterionDefinition(id, criterion) {
    const critIdx = group.items.findIndex(c => c.id === id);
    const newItems = [...group.items];
    newItems[critIdx] = {id, ...criterion}

    updateGroup({
      ...group,
      items: newItems,
    });
  }

  const setGroupOperator = (operator) => {
    updateGroup({
      ...group,
      operator,
    });
  };

  function deleteItem(criterion) {
    onDeleteItem(criterion, group.id);
  }
  return (
    <Box
      className={classes.block}
      maxWidth="100%"
      key={group.id}
      {...provided?.draggableProps}
      ref={innerRef}
      style={getItemStyle(
        snapshot?.isDragging,
        provided?.draggableProps?.style
      )}
    >
      <CriteriaKeyOption
        index={groupIndex}
        criteriaKey={parentOperator}
        setCriteriaKey={setParentOperator}
      />

      <Box className={classes.card}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.cardHeader}
        >
          {/* <Typography>
            <em>Drag operators here to add them to this group</em>
          </Typography> */}
          <Typography>
            {groupOperator == "OR" ? "Any" : "All"} of the following are true
          </Typography>
          <Box display="flex">
            <Tooltip title="Add criterion">
              <IconButton
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleAddCriterion}
                color="primary"
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleAddCriterion}>+ Add criterion</MenuItem>
              <MenuItem onClick={handleClose}>+ Add criteria group</MenuItem>
            </Menu>

            <Tooltip title="Delete group and inner items">
              <IconButton>
                <DeleteIcon onClick={() => onDeleteGroup(group)} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Drag and drop to change order for this group">
              <Box
                display="flex"
                {...provided?.dragHandleProps}
                className="MuiIconButton-root"
              >
                <DragIndicatorIcon />
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Droppable
          droppableId={`criteriaBlock_${group.id}`}
          type={`droppableSubItem`}
        >
          {(provided) => (
            <Stack
              {...(provided?.droppableProps ?? {})}
              ref={provided?.innerRef}
            >
              {criteriaBlocks.length > 0 &&
                criteriaBlocks
                  .filter((item) => item?.id)
                  .map((criteriaItem, index) => (
                    <Draggable
                      key={criteriaItem.id}
                      draggableId={criteriaItem.id}
                      index={index}
                      type={"droppable"}
                    >
                      {(provided) => (
                        criteriaItem.items ? <GroupCriteriaBlock
                        key={criteriaItem.id}
                        index={index}
                        innerRef={provided.innerRef}
                        criteria={criteriaItem}
                        snapshot={snapshot}
                        provided={provided}
                        reorder={props.reorder}
                        updateGroup={(group) =>
                          updateCriterionDefinition(
                            criteriaItem.id,
                            group
                          )
                        }
                        onDeleteGroup={(g) => console.log("TODO delete group", g)}
                        onDeleteItem={deleteItem}
                        parentOperator={groupOperator}
                        setParentOperator={(val) => {
                          setGroupOperator(val.toUpperCase());
                        }}
                      /> : <CriteriaBlock
                          nested
                          slider
                          index={index}
                          bullet={false}
                          criteria={criteriaItem}
                          key={criteriaItem.id}
                          provided={provided}
                          snapshot={snapshot}
                          innerRef={provided.innerRef}
                          parentOperator={groupOperator}
                          onDelete={deleteItem}
                          updateCriterion={updateCriterionDefinition}
                          setParentOperator={(val) => {
                            setGroupOperator(val.toUpperCase());
                          }}
                        />
                      )}
                    </Draggable>
                  ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </Box>
    </Box>
  );
};

export default GroupCriteriaBlock;

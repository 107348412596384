import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import Classes from './index.module.css'
import { COUNTRY_LIST, DIF_CONSENT_KEY, DIF_FORM_COMPLETED_STATUS } from '../../../constants/strings'
import v4 from 'uuid/v4'
import Select from '@mui/material/Select';
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import DropZone from 'react-dropzone';
import DocumentIcon from '../../../assets/images/Doc.M.png';
import { Auth } from 'aws-amplify'
import ErrorText from '../error-text';
import { getDIFConsentDownloadUrl , getConsentDocUploadURL } from '../../../api/dif-form-api'
import { uploadFileToS3 } from '../../../utils/aws-sdk-helper'
import get from 'lodash/get'

const DIFDataSharing = props => {
    const {
        difDataSharingIsNewDataSet,
        difDataSharingDatasetName,
        difDataSharingPermissionToShare,
        difDataSharingIRBNumber,
        difDataSharingDocumentFile,
        difDataSharingIsProtectedData,
        difDataSharingIsDeIdentity,
        isFormSubmitted,
        currentRequestId,
        difDataSharingConsentDocument,
        currentDIFFormStage,
        difDataSharingAssociatedEmail,

        setDifDataSharingContributeToNewDataset,
        setDifDataSharingDatasetName,
        setDifDataSharingPermissionToShareData,
        setDifDataSharingIRBNumber,
        setDifDataSharingDocumentFile,
        setDifDataSharingIsProtectedData,
        setDifDataSharingDataDeIdentify,
        isDashboard

    } = props;
    const [userDetails, setUserDetails] = useState({});
    const [ uploadPercentage, setUploadPercentage ] = useState(0);
    const [ fileDetails, setFileDetails ] = useState({});
    const getData = async () => {
        try {
            const userValues = await Auth.currentUserInfo();
            setUserDetails(userValues?.attributes || {})
        }
        catch (error) {
            console.log('error in getting data', error);
        }
    }
    useEffect(() => {
        getData();
    }, [])
    const onContributingToNewDatasetChange = value => {
        if(value === 'yes'){
            setDifDataSharingDatasetName('');
        }
        setDifDataSharingContributeToNewDataset(value);
    }
    const onDatasetNameChange = event => {
        setDifDataSharingDatasetName(event.target.value);
    }
    const onPermissionToShareDataChange = value => {
        if(value === 'no'){
            setDifDataSharingIRBNumber('');
        }
        setDifDataSharingPermissionToShareData(value);
    }
    const onIRBNumberChange = event => {
        setDifDataSharingIRBNumber(event.target.value);
    }
    const onDocumentDrop = async (file) => {
        try{
        setFileDetails(file);
        const preSignedData = await getConsentDocUploadURL(currentRequestId, `${currentRequestId}${DIF_CONSENT_KEY}`);
        let config = {
            onUploadProgress: function (progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadPercentage(percentCompleted);
            }
        };

       const res = await uploadFileToS3(file[0], preSignedData, config);
       if (res == 204 || res == 202 || res == 200) {
        setUploadPercentage(0);
        props.updateSnackBar('Document uploaded successfully.', 'success');
        setDifDataSharingDocumentFile(file);
    }
    }catch(error){
        console.log('error in upload file');
        props.updateSnackBar('Fail to upload Document', 'error');
    }
    }
    const onProtectedDataContainChange = value => {
        if(value === 'no'){
            setDifDataSharingDataDeIdentify('');
        }
        setDifDataSharingIsProtectedData(value);
    }
    const onDataDeIdentifyChange = value => {
        setDifDataSharingDataDeIdentify(value);
    }
    const onDownloadClick = async () => {
        try {

            const url = await getDIFConsentDownloadUrl(currentRequestId, `${currentRequestId}${DIF_CONSENT_KEY}`);
            if (!isEmpty(url)) {
                window.open(url, "_blank")
            }
        } catch (error) {
            console.log('error in getting download url', error);
        }
    }

    const disableInput = currentDIFFormStage === DIF_FORM_COMPLETED_STATUS;
    const associatedEmail = !isDashboard ? userDetails?.['email'] : difDataSharingAssociatedEmail;
    return (
        <div className={Classes.container} id="cvb-DIFDataSharing-mainContainer">
            <p className={Classes.formTitle} id="cvb-DIFDataSharing-formHeading">Data Sharing</p>
            <Grid container direction='column' className={Classes.inputContainers} spacing={3}>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='institutionName' id="cvb-DIFDataSharing-associatedEmailLabel">Email Address Associated With BRAINCommons</label>
                    <OutlinedInput
                        type="text"
                        value={associatedEmail}
                        fullWidth
                        disabled
                        id="cvb-DIFDataSharing-associatedEmailElement"
                    />
                </Grid>
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={12} >
                    <label id="cvb-DIFDataSharing-contributorLabel">Are you contributing a new dataset?<sup> *</sup></label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDataSharing-contributorYesLabel">Yes</label>
                            <input id="cvb-DIFDataSharing-contributorYesRadio" name="newDataSet" type="radio"
                                disabled={disableInput}
                                checked={difDataSharingIsNewDataSet === 'yes'} value='yes'
                                onChange={() => onContributingToNewDatasetChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={10} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDataSharing-contributorNoLabel">No, updating an existing dataset</label>
                            <input id="cvb-DIFDataSharing-contributorNoRadio" name="newDataSet" type="radio"
                                disabled={disableInput}
                                checked={difDataSharingIsNewDataSet === 'no'} value='no'
                                onChange={() => onContributingToNewDatasetChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                    {
                        isFormSubmitted && isEmpty(difDataSharingIsNewDataSet) &&
                        <ErrorText errorMessage="Select value" />
                    }
                </Grid>
                {difDataSharingIsNewDataSet === 'no' &&
                    <Grid item xs={5} className={Classes.fieldCont}>
                        <label htmlFor='institutionName' id="cvb-DIFDataSharing-existingDatasetLabel">Existing Data Set<sup> *</sup></label>
                        <OutlinedInput
                            type="text"
                            placeholder="Enter data set name"
                            value={difDataSharingDatasetName}
                            onChange={onDatasetNameChange}
                            fullWidth
                            id="cvb-DIFDataSharing-existingDatasetField"
                            name='institutionName'
                            disabled={disableInput}
                        />
                    {
                        isFormSubmitted && (!isEmpty(difDataSharingIsNewDataSet) && isEmpty(difDataSharingDatasetName)) &&
                        <ErrorText errorMessage="Enter valid data set" />
                    }
                    </Grid>
                }

                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={12} >
                    <label id="cvb-DIFDataSharing-permissionLabel">Does your institution have permission to share this data?<sup> *</sup></label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDataSharing-permissionYesLabel">Yes</label>
                            <input id='permissionToShare1' name='permissionToShare' type="radio"
                                disabled={disableInput}
                                checked={difDataSharingPermissionToShare === 'yes'} value='yes'
                                onChange={() => onPermissionToShareDataChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDataSharing-permissionNoLabel">No</label>
                            <input id='permissionToShare2' name='permissionToShare' type="radio"
                                disabled={disableInput}
                                checked={difDataSharingPermissionToShare === 'no'} value='no'
                                onChange={() => onPermissionToShareDataChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                    {
                        isFormSubmitted && isEmpty(difDataSharingPermissionToShare) &&
                        <ErrorText errorMessage="Select value" />
                    }
                </Grid>
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={12} >
                    <label id="cvb-DIFDataSharing-protectedLabel">Does your data contain Protected Health Information (PHI/ PII)?<sup> *</sup></label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDataSharing-protectedYesLabel">Yes</label>
                            <input id='containProtectedData1' name="containProtectedData" type="radio"
                                disabled={disableInput}
                                checked={difDataSharingIsProtectedData === 'yes'} value='yes'
                                onChange={() => onProtectedDataContainChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFDataSharing-protectedNoLabel">No</label>
                            <input id='containProtectedData2' name="containProtectedData" type="radio"
                                disabled={disableInput}
                                checked={difDataSharingIsProtectedData === 'no'} value='no'
                                onChange={() => onProtectedDataContainChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                    {
                        isFormSubmitted && isEmpty(difDataSharingIsProtectedData) &&
                        <ErrorText errorMessage="Select value" />
                    }
                </Grid>

                {difDataSharingIsProtectedData === 'yes' &&
                    <Grid item xs={11} >
                        <label id="cvb-DIFDataSharing-dataDeIdentityLabel">You are required to contribute data de-identified. Will you remove all PHI/PII prior to contributing your data?<sup> *</sup></label>
                        <Grid container>
                            <Grid item xs={2} className={Classes.radioCheckContainer}>
                                <label className={Classes.radioContainer} id="cvb-DIFDataSharing-dataDeIdentityYesLabel">Yes</label>
                                <input id='dataDeIdentify1' type="radio" name="dataDeIdentify"
                                    disabled={disableInput}
                                    checked={difDataSharingIsDeIdentity === 'yes'} value='yes'
                                    onChange={() => onDataDeIdentifyChange('yes')} />
                                <span className={Classes.checkmark}></span>
                            </Grid>
                            <Grid item xs={2} className={Classes.radioCheckContainer}>
                                <label className={Classes.radioContainer} id="cvb-DIFDataSharing-dataDeIdentityNoLabel">No</label>
                                <input id='dataDeIdentify2' type="radio" name="dataDeIdentify"
                                    disabled={disableInput}
                                    checked={difDataSharingIsDeIdentity === 'no'} value='no'
                                    onChange={() => onDataDeIdentifyChange('no')} />
                                <span className={Classes.checkmark}></span>
                            </Grid>
                        </Grid>
                    {
                        isFormSubmitted && isEmpty(difDataSharingIsDeIdentity) &&
                        <ErrorText errorMessage="Select value" />
                    }
                    </Grid>
                }
            </Grid>
        </div>
    )
}

DIFDataSharing.defaultProps = {
    difDataSharingIsNewDataSet: '',
    difDataSharingDatasetName: '',
    difDataSharingPermissionToShare: '',
    difDataSharingIRBNumber: '',
    difDataSharingDocumentFile: '',
    difDataSharingIsProtectedData: '',
    difDataSharingIsDeIdentity: '',
    isFormSubmitted: false,
    currentRequestId: '',
    difDataSharingConsentDocument: '',
    currentDIFFormStage:'',
    isDashboard: false,
    difDataSharingAssociatedEmail: '',
    setDifDataSharingContributeToNewDataset: () => { },
    setDifDataSharingDatasetName: () => { },
    setDifDataSharingPermissionToShareData: () => { },
    setDifDataSharingIRBNumber: () => { },
    setDifDataSharingDocumentFile: () => { },
    setDifDataSharingIsProtectedData: () => { },
    setDifDataSharingDataDeIdentify: () => { }
}

export default DIFDataSharing;

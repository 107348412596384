import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import Classes from './index.module.css'
import { COUNTRY_LIST, DIF_FORM_COMPLETED_STATUS, ORGANIZATION_LIST, DIF_STUDY_LOGO } from '../../../constants/strings'
import v4 from 'uuid/v4'
import Select from '@mui/material/Select';
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import ErrorText from '../error-text';
import DropDown from '../../commons/dropdown'
import DropZone from 'react-dropzone';
import get from 'lodash/get'
import ClosePng from '../../../assets/images/close.png'
import DocumentIcon from '../../../assets/images/Doc.M.png';
import { getConsentDocUploadURL, getDIFConsentDownloadUrl } from '../../../api/dif-form-api'
import { uploadFileToS3 } from '../../../utils/aws-sdk-helper'
import { deleteDCADIFFile } from '../../../api/dca-form-api'
import Modal from '../../commons/modal'
import Checkbox from '@mui/material/Checkbox';
const DIFStudy = props => {
    const {
        difStudyName,
        difStudyObjective,
        difStudyDescription,
        difStudyLogoFile,
        difStudyDesign,
        difStudyNumberOfGrants,
        difStudyDurationOfMoths,
        isFormSubmitted,
        currentDIFFormStage,
        difStudyLocation,
        difStudyPublications,
        difStudyGrantInfo,
        currentRequestId,
        logoS3Key,
        difStudyOptIn,

        setDifStudyName,
        setDifStudyObjective,
        setDifStudyDescription,
        setDifStudyLogoFile,
        setDifStudyDesign,
        setDifStudyNumberOfGrants,
        setDifStudyAgencyName,
        setDifStudyAgencyNumber,
        setDifStudyDurationOfMonths,
        setDifStudyLocationOfStudy,
        setDifStudyPublicationName,
        setDifStudyPublicationURL,
        removeDifStudyPublications,
        setDifStudyPublications,
        setDifStudyLogoFileKey,
        setDifStudyOptIn
    } = props;
    const [addPublicationClick, setAddPublicationClick] = useState(false);
    const [ difStudyStageLogoFile, setDIFStudyStageLogoFile ] = useState({});
    const [ logoFileUploadPercentage, setLogoFileUploadPercentage ] = useState(0);
    const [ addMoreClick, setAddMoreClick ] = useState(false);
    const [ validateAddedUsers, setValidateAddedUsers ] = useState({});
    const [ fileKey, setFileKey ] = useState('');
    const [ deleteFileModal, setDeleteFileModal ] = useState(false);
    useEffect(() => {
        document.getElementById('difSubmitButton').addEventListener('click', () => {
            validPublications();
            setAddMoreClick(true);
        })
    }, []);

    const onStudyNameChange = event => {
        setDifStudyName(event.target.value);
    }
    const onObjectiveOfStudyChange = event => {
        setDifStudyObjective(event.target.value);
    }
    const onDescriptionOfStudyChange = event => {
        setDifStudyDescription(event.target.value);
    }
    const onStudyDesignChange = (event) => {
        setDifStudyDesign(event.target.value);
    }
    const onLogoFileDrop = async (fileData) => {
        try {
            setDIFStudyStageLogoFile(fileData);
            const dataDicDoc = `${DIF_STUDY_LOGO}`;
            const preSignedData = await getConsentDocUploadURL(currentRequestId);
            let config = {
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setLogoFileUploadPercentage(percentCompleted);
                }
            };
            const res = await uploadFileToS3(fileData[0], preSignedData, config);
            if (res == 204 || res == 202 || res == 200) {
                setLogoFileUploadPercentage(0);
                props.updateSnackBar('Document uploaded successfully.', 'success');
                setDifStudyLogoFile(fileData);
                setDifStudyLogoFileKey(`${currentRequestId}/${fileData[0]?.name}`);
            }
        } catch (error) {
            console.log('error in uploading', error);
            props.updateSnackBar('Document uploaded failed.', 'error');
        }
    }
    const onDownloadFileClick = async (key) => {
        try {
            const url = await getDIFConsentDownloadUrl(key);
            if (!isEmpty(url)) {
                window.open(url, "_blank")
            }
        } catch (error) {
            console.log('error in getting download url', error);
        }
    }
    const onNumberOfGrantsChange = event => {
        setDifStudyNumberOfGrants(event.target.value);
    }
    const onAgencyNameChange = (value, id) => {
        setDifStudyAgencyName(value, id);
    }
    const onAgencyNumberChange = (event, id) => {
        setDifStudyAgencyNumber(event.target.value, id);
    }
    const onDurationOfMonthsChange = event => {
        setDifStudyDurationOfMonths(event.target.value);
    }
    const onLocationOfStudyChange = event => {
        setDifStudyLocationOfStudy(event.target.value);
    }
    const onPublicationNameChange = (event, id) => {
        setDifStudyPublicationName(event.target.value, id);
    }
    const onPublicationURLChange = (event, id) => {
        setDifStudyPublicationURL(event.target.value, id);
    }
    const onStudyOptOutChange = (value) => {
        setDifStudyOptIn(value);
    }
    const removePublications = (index) => {
        removeDifStudyPublications(index);
        const tempValidateUsers = { ...validateAddedUsers };
        delete tempValidateUsers[index];
        setValidateAddedUsers(tempValidateUsers);
    }
    const validPublications = () => {
        let isFormValid = false;
        if (!isEmpty(difStudyPublications)) {
            map(difStudyPublications, (usersData, index) => {
                setValidateAddedUsers({...validateAddedUsers, [index]: false});
                for (let key in usersData) {
                    if (!isEmpty(usersData[key])) {
                        isFormValid = true;
                        setValidateAddedUsers({...validateAddedUsers, [index]: true});
                        setAddMoreClick(false);
                    } else {
                        isFormValid = false;
                        setValidateAddedUsers({...validateAddedUsers, [index]: false});
                        setAddMoreClick(true);
                        break;
                    }
                }
            })
        } else {
            isFormValid = true;
        }
        return isFormValid;
    }
    const handleAddFields = () => {
        if(!isEmpty(difStudyPublications)){
            setAddPublicationClick(true);
            setAddMoreClick(true);
        }
        const isValidPublication = validPublications();
        if (isValidPublication) {
            setDifStudyPublications({"publication_name": '', "publication_url": ''});
            setAddPublicationClick(false);
        }
    }
   const onDeleteFile = (key) => {
        setFileKey(key);
        setDeleteFileModal(true);
   }
   const closeDeleteFileModal = () => {
    setFileKey('');
    setDeleteFileModal(false);
   }
    const onDeleteFileConfirm = async () => {
        try {
            const body = {
                "type": "dif",
                "key": fileKey
            }
            const res = await deleteDCADIFFile(body);
            setFileKey('');
            setDeleteFileModal(false);
            props.updateSnackBar('File deleted successfully', 'success');
            setDifStudyLogoFile('');
            setDIFStudyStageLogoFile('');
            setDifStudyLogoFileKey('');
        } catch (error) {
            props.updateSnackBar('Error in deleting file', 'error');
            setDeleteFileModal(false);
        }
    }
   const FILE_DELETE = {
    modalTitle: 'Are you sure you want to delete this file?',
    positiveButtonText: 'Delete',
    negativeButtonText: "Cancel",
    positiveButtonAction: onDeleteFileConfirm,
    negativeButtonAction: closeDeleteFileModal
}
    const disableInput = currentDIFFormStage === DIF_FORM_COMPLETED_STATUS;
    const originalFileName = !isEmpty(logoS3Key) ? logoS3Key?.substring(logoS3Key.indexOf('/')+1) || '': '';

    return (
        <div className={Classes.container} id="cvb-DIFStudy-mainContainer">
             <Modal
                    open={deleteFileModal}
                    handleClose={closeDeleteFileModal}
                    dialogProps={FILE_DELETE}
                    disableTitleDivider={true}
                />
            <p className={Classes.formTitle} id="cvb-DIFStudy-formTitle">Study</p>
            <Grid container direction='row' className={Classes.inputContainers} spacing={3}>
            <Grid item xs={11}>
                    <div className={Classes.studyOptOutWrapper} id="cvb-DIFStudy-optCheckBox">
                    <Checkbox
                          checked={difStudyOptIn}
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          onChange={(e) => onStudyOptOutChange(e.target.checked)}
                          disabled={disableInput}
                        />
                      <span className={Classes.studyOptInText} id="cvb-DIFStudy-studyOptInText">Study information can be shared in the study gallery to help users understand the impact of my research.</span>
                    </div>
                </Grid>
                <Grid item xs={5}>
                    <div className={Classes.textareaCont}>
                        <label htmlFor='studyName' id="cvb-DIFStudy-studyNameLabel">Study Name<sup> *</sup></label>
                        <OutlinedInput
                            fullWidth
                            name="studyName"
                            onChange={onStudyNameChange}
                            placeholder="Enter study name"
                            id="cvb-DIFStudy-studyNameField"
                            value={difStudyName}
                            disabled={disableInput}
                        />
                        {
                            isFormSubmitted && isEmpty(`${difStudyName}`) &&
                            <ErrorText errorMessage="Enter study name" />
                        }
                    </div>
                </Grid>
                <Grid item xs={10}>
                    <div className={Classes.textareaCont}>
                        <label id="cvb-DIFStudy-objectiveStudyLabel">Objective Of the Study<sup> *</sup></label>
                        <textarea rows={4} type="text"  onChange={onObjectiveOfStudyChange}
                            value={difStudyObjective} placeholder="Enter objective of study"
                            disabled={disableInput}
                            id="cvb-DIFStudy-objectiveStudyField"
                        >
                        </textarea>
                        {
                            isFormSubmitted && isEmpty(difStudyObjective) &&
                            <ErrorText errorMessage="Enter objective of study" />
                        }
                    </div>
                </Grid>
                <Grid item xs={10}>
                    <div className={Classes.textareaCont}>
                        <label id="cvb-DIFStudy-objectiveStudyDescriptionLabel">Description Of the Study<sup> *</sup></label>
                        <textarea rows={4} type="text" onChange={onDescriptionOfStudyChange}
                            value={difStudyDescription} placeholder="Enter description of study"
                            disabled={disableInput}
                            id="cvb-DIFStudy-descriptionStudyField"
                        >
                        </textarea>
                        {
                            isFormSubmitted && isEmpty(difStudyDescription) &&
                            <ErrorText errorMessage="Enter description of study" />
                        }
                    </div>
                </Grid>
                <Grid item xs={5} className={Classes.dropzoneWrapper}>
                    <div>
                        <label id="cvb-DIFStudy-uploadLLogoContainer">Upload Logo</label>
                    </div>
                    <div className={Classes.dropZoneButtonContainer}>
                        <DropZone
                            onDrop={(acceptedFiles) => onLogoFileDrop(acceptedFiles)}
                            multiple={false}
                            disabled={disableInput}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className={Classes.uploadImageDiv}>
                                        <img src={DocumentIcon} alt='document' />
                                        <p className={Classes.chooseFileText} id="cvb-DIFStudy-dragDropText">
                                            Drag and drop or
                                                                <span>
                                                <button className={`${Classes.chooseFileButton}`} id="cvb-DIFStudy-selectFilesButton">
                                                    Select files
                                                                    </button>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </DropZone>
                    </div>
                    <progress className={Classes.progressBar} value={logoFileUploadPercentage} max={100}/>
                    <p className={Classes.fileNameAndSize} id="cvb-DIFStudy-fileNameAndSize">
                        {!isEmpty(difStudyStageLogoFile) ? get(difStudyStageLogoFile, '[0].name', '')
                         : originalFileName}
                          <span>{!isEmpty(difStudyStageLogoFile) ? `${get(difStudyStageLogoFile,'[0].size', '')}'kb'` : ''} </span>
                    </p>
                    {!isEmpty(logoS3Key) &&
                        <div className={Classes.downloadDeleteButtonWrapper}>
                            <button id="cvb-DIFStudy-downloadButton" className={`solid-button medium-size-button ${Classes.actionButtons}`}
                                onClick={() => onDownloadFileClick(logoS3Key)}>
                                Download
                        </button>
                           {!disableInput && <button id="cvb-DIFStudy-deleteButton" className={`solid-button medium-size-button ${Classes.actionButtons} ${Classes.deleteButton}`}
                                onClick={() => onDeleteFile(logoS3Key)}>
                                Delete
                        </button>}
                        </div>
                    }
                </Grid>
                <Grid item xs={10}>
                    <div className={Classes.textareaCont}>
                        <label id="cvb-DIFStudy-studyDesignLabel">Study Design<sup> *</sup></label>
                        <textarea rows={4} type="text" id="cvb-DIFStudy-studyDesignField" name="studyDesign" onChange={onStudyDesignChange}
                            value={difStudyDesign} placeholder="Enter study design"
                            disabled={disableInput}
                        >
                        </textarea>
                        {
                            isFormSubmitted && isEmpty(difStudyDesign) &&
                            <ErrorText errorMessage="Enter study design details" />
                        }
                    </div>
                </Grid>
                <Grid item xs={5}>
                    <div className={Classes.textareaCont}>
                        <label htmlFor='durationOfStudy' id="cvb-DIFStudy-studyDurationLabel">Duration of Study (Months)<sup> *</sup></label>
                        <OutlinedInput
                            type='number'
                            fullWidth
                            name="durationOfStudy"
                            id="durationOfStudy"
                            onChange={onDurationOfMonthsChange}
                            placeholder="Enter number"
                            inputProps={{ min: 1 }}
                            value={difStudyDurationOfMoths}
                            disabled={disableInput}
                        />
                        {
                            isFormSubmitted && isEmpty(`${difStudyDurationOfMoths}`) &&
                            <ErrorText errorMessage="Enter duration of study" />
                        }
                    </div>
                </Grid>
                <Grid item xs={10}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <div className={Classes.textareaCont}>
                                <label id="cvb-DIFStudy-studyLocationLabel">Location of the Study<sup> *</sup></label>
                                <OutlinedInput
                                    type="text"
                                    fullWidth
                                    name="locationOfStudy"
                                    id="locationOfStudy"
                                    onChange={onLocationOfStudyChange}
                                    placeholder="Enter place name"
                                    value={difStudyLocation}
                                    disabled={disableInput}
                                />
                                {
                                    isFormSubmitted && isEmpty(`${difStudyLocation}`) &&
                                    <ErrorText errorMessage="Enter location of study" />
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={12}>
                    <p className={Classes.formSubTitle} id="cvb-DIFStudy-grantHeading">Grant Information<sup> *</sup></p>
                </Grid>
                <Grid item xs={10}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <div className={Classes.textareaCont}>
                                <label id="cvb-DIFStudy-grantNumberLabel">Number of Grants<sup> *</sup></label>
                                <OutlinedInput
                                    type="number"
                                    fullWidth
                                    name="noOfGrants"
                                    onChange={onNumberOfGrantsChange}
                                    placeholder="Enter number"
                                    id='noOfGrants'
                                    inputProps={{ min: 0 }}
                                    value={difStudyNumberOfGrants}
                                    disabled={disableInput}
                                />
                                {
                                    isFormSubmitted && isEmpty(`${difStudyNumberOfGrants}`) &&
                                    <ErrorText errorMessage="Enter number of grants" />
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {map(difStudyGrantInfo, (grantInfo, index) => {
                        return <Grid container spacing={3}>
                            <Grid item xs={5} className={Classes.fieldCont}>
                                <label htmlFor='agencyName' id="cvb-DIFStudy-agencyNameLabel">Grant Agency<sup> *</sup></label>
                                <OutlinedInput
                                    type="text"
                                    placeholder="Enter grant agency"
                                    value={get(grantInfo, 'agency_name', '')}
                                    fullWidth
                                    disabled={disableInput}
                                    id='agencyName'
                                    name='agencyName'
                                    onChange={(e) => onAgencyNameChange(e.target.value, index)}
                                />
                                {
                                    isFormSubmitted && isEmpty(get(grantInfo, 'agency_name', '')) &&
                                    <ErrorText errorMessage="Enter grant number" />
                                }
                            </Grid>
                            <Grid item xs={5} className={Classes.fieldCont}>
                                <label htmlFor='agencyNumber' id="cvb-DIFStudy-agencyNumber">Agency Number<sup> *</sup></label>
                                <OutlinedInput
                                    type="text"
                                    placeholder="Enter agency number"
                                    value={get(grantInfo, 'agency_number', '')}
                                    fullWidth
                                    disabled={disableInput}
                                    id='agencyNumber'
                                    name='agencyNumber'
                                    onChange={(e) => onAgencyNumberChange(e, index)}
                                />
                                {
                                    isFormSubmitted && isEmpty(get(grantInfo, 'agency_number', '')) &&
                                    <ErrorText errorMessage="Enter agency number" />
                                }
                            </Grid>
                        </Grid>
                    })}
                </Grid>
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={12}>
                    <label id="cvb-DIFStudy-publicationLabel">Publications</label>
                    {map(difStudyPublications, (field, idx) => {
                        return (
                            <Grid key={`container${idx}`} container spacing={3} >
                                <Grid item xs={5} className={Classes.fieldCont}>
                                    <OutlinedInput
                                        type="text"
                                        id="publicationName"
                                        value={field['publication_name'] || ""}
                                        placeholder="Publications Title *"
                                        onChange={e => onPublicationNameChange(e, idx)}
                                        key={`publicationName${idx}`}
                                        disabled={disableInput}
                                        fullWidth
                                    />
                                    {
                                        (addMoreClick && !validateAddedUsers[idx]) && isEmpty(field['publication_name']) &&
                                        <ErrorText errorMessage="Enter valid publications name" />
                                    }
                                </Grid>
                                <Grid item xs={5} className={Classes.fieldCont}>
                                    <OutlinedInput
                                        type="text"
                                        value={field['publication_url'] || ""}
                                        placeholder="Publications URL *"
                                        onChange={e => onPublicationURLChange(e, idx)}
                                        key={`publicationURL${idx}`}
                                        disabled={disableInput}
                                        fullWidth
                                    />
                                    {
                                        (addMoreClick && !validateAddedUsers[idx]) && isEmpty(field['publication_url']) &&
                                        <ErrorText errorMessage="Enter valid publications url" />
                                    }
                                </Grid>

                                {(!disableInput) && <Grid item xs={1} className={Classes.closeButtonContainer}>
                                    <img id="cvb-DIFStudy-removePublication" src={ClosePng} className={Classes.removeFields} onClick={() => removePublications(idx)} />
                                </Grid>}
                            </Grid>
                        );
                    })}
                </Grid>
                <div className={Classes.flexBoxFields}>
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                   {(!disableInput) && <button id="cvb-DIFStudy-addMoreLink" type="button" className={Classes.addMoreFields} disabled={disableInput} onClick={() => handleAddFields()}>
                        + Add More
                        </button>}
                </div>
            </Grid>
        </div >
    )
}

DIFStudy.defaultProps = {
    difStudyName: "",
    difStudyObjective: "",
    difStudyDescription: "",
    difStudyLogoFile: "",
    difStudyDesign: "",
    difStudyNumberOfGrants: "",
    difStudyDurationOfMoths: "",
    isFormSubmitted: "",
    currentDIFFormStage: "",
    difStudyLocation: "",
    difStudyPublications: [],
    difStudyGrantInfo: [],
    currentRequestId: '',
    logoS3Key: '',
    difStudyOptIn: true,
    setDifStudyName: () => { },
    setDifStudyObjective: () => { },
    setDifStudyDescription: () => { },
    setDifStudyDesign: () => { },
    setDifStudyLogoFile: () => { },
    setDifStudyNumberOfGrants: () => { },
    setDifStudyAgencyName: () => { },
    setDifStudyAgencyNumber: () => { },
    setDifStudyDurationOfMonths: () => { },
    setDifStudyLocationOfStudy: () => { },
    setDifStudyPublicationName: () => { },
    setDifStudyPublicationURL: () => { },
    removeDifStudyPublications: () => { },
    setDifStudyPublications: () => { },
    setDifStudyLogoFileKey: () => { },
    setDifStudyOptIn: () => { }
}

export default DIFStudy;

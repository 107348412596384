import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import map from 'lodash/map'
import v4 from 'uuid/v4'
import WorkspaceCard from '../workspace-cards'
import { getAllWorkspaces, getWorkspaceDetails } from '../../../api/workspace-api/workspace-api'
import Collapse from '@mui/material/Collapse'
import { WorkspaceCardsData } from '../../../constants/strings'
import WorkspaceFileDirectory from '../workspace-file-directory';
import MyProfile from '../../pages/my-profile';
import makeStyles from '@mui/styles/makeStyles';

import { Router, Link, navigate } from '@reach/router';
import CustomLoader from '../../commons/custom-loader';
import { cloneDeep, findIndex, isEmpty, find } from 'lodash'

const useStyles = makeStyles(theme => ({
    accessMassages: {
        width: '64%',
        textAlign: 'center',
        padding: '30px',
        fontSize:'1.125rem',
        letterSpacing: '.36px',
        color: '#757576',
        opacity: 1,
        margin: '0 auto',
        fontFamily: "Raleway"
    },
    commonBlueButton:{
        background: '#637bbd',
        padding: '0px 20px',
        height: '40px',
        fontFamily: "Raleway"
    },
    buttonAlign:{
        textAlign:'center',
        marginLeft: '19px',
        marginTop: '12px',

    }
}));
const WorkspaceCardsContainer = (props) => {
    const [workspaces, setWorkspaces] = useState([]);
    const [ selectedCard , setSelectedCard ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    const [ workspaceAuthentication, setWorkspaceAuthentication ] = useState(false);
    const [closeFileDirectory, setCloseFileDirectory] = useState(false);
    const { setAllWorkspaces = () => { }} = props;
    const { loadedWorkspaces = [] } = props;
    const { setIsOwnedWorkspacePresent = ()=>{ } } = props;
    const materialClasses = useStyles();
    useEffect(() => {
        setWorkspaces(props.allWorkspaces);
        setAllWorkspaces(props.allWorkspaces);
    }, [props.allWorkspaces])
    useEffect(() => {
        if(!isEmpty(workspaces)){
            map(workspaces, (workspace)=>{
                const id = workspace?.workspace_id;
                getCurrentWorkspaceDetails(id)
            })
        }
    }, [workspaces])

    useEffect(() => {
        if(isEmpty(workspaces)){
            setIsOwnedWorkspacePresent(false)
        }else{
            let wkIndex = findIndex(workspaces, (data)=> data?.pi_owner);
            if(wkIndex > -1){
                setIsOwnedWorkspacePresent(true);
            }else{
                setIsOwnedWorkspacePresent(false);
            }
        }
    }, [workspaces])

    const loadAllWorkspaces = async () => {
        try {
            setIsLoading(true);
            const res = await getAllWorkspaces();
            if(res){
                setIsLoading(false);
                setWorkspaces(res);
                setAllWorkspaces(res);
            }
            if(!res.length > 0){
                setWorkspaceAuthentication(true)
            }
        } catch (error) {
            console.log('error in getting workspace');
            setIsLoading(false);
            setAllWorkspaces([])
        }
    }
    const getCurrentWorkspaceDetails = async (id) => {
        try {
            setIsLoading(true)
            if (!isEmpty(id)) {
                const res = await getWorkspaceDetails(id);
                //set workspace details
                props.setLoadedWorkspaces(res);
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            console.log('error in getting workspace details');
        }
    }
    const onViewFileDirectory = async (data) => {
        try{
            setSelectedCard(data);
        }catch{
            setSelectedCard('')
        }
    }
    const onSearchTextChange = (text) => {
        //setSearchText(text);
    }
    const onClose =()=>{
        navigate('/explore/tools/workspace')
    }
    const onClickViewFileDirectory =() =>{
        setCloseFileDirectory(false);
    }
    return (
        <Grid container spacing={4}>
                {!isLoading && workspaces.length === 0 ? 
                <div id='cvb-workspaceCardsContainer-accessMessage' className={materialClasses.accessMassages}>You don't yet have access to Advanced Compute, please request access from 
                <div className={materialClasses.buttonAlign}>
                    <Link to="/my-profile">
                    <button className={`solid-button ${materialClasses.commonBlueButton}`}>My Profile</button></Link></div>
                </div>
                :""}
            {!isLoading ? map(workspaces, (eventData) => {
                const workspaceDetails = find(loadedWorkspaces, (wp)=> wp.workspace_id === eventData.workspace_id);
                return <React.Fragment>
                    {!isEmpty(workspaceDetails) && <WorkspaceCard
                        key={eventData.workspace_id}
                        eventData={eventData}
                        onViewFileDirectory={onViewFileDirectory}
                        selectedCard={selectedCard}
                        usersForShare={props.usersForShare}
                        onClickViewFileDirectory ={onClickViewFileDirectory}
                        workspaceDetails={workspaceDetails}
                        getCurrentWorkspaceDetails={getCurrentWorkspaceDetails}
                    />}

                </React.Fragment>
            })
                : <CustomLoader />
            }
            {/* <Grid xs={12}>
                <Router id="workspace-router" primary={false}>
                    <WorkspaceFileDirectory path="/:id" usersForShare={props.usersForShare} closeFileDirectory ={closeFileDirectory} onClose={onClose} />
                </Router>
            </Grid> */}
        </Grid>
    )
}

export default WorkspaceCardsContainer;
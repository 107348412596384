import axios from 'axios'
import get from 'lodash/get'
import { BASE_URL } from '../constants/google-login-urls'
import { getCognitoIdToken } from './utilityMethods';
import { Auth } from 'aws-amplify'

export const getPricingDetails = async (id='') => {
    try {
        const token = await getCognitoIdToken();
        let url = `${BASE_URL}/cost/download_pricing/`;
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        if(id){
            url = `${BASE_URL}/cost/download_pricing/${id}`
        }
        const res = await axios.get(url, { headers: header });
        return get(res, 'data', []);
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const getPricingOptions = async (type, orderId) => {
    try {
        const token = await getCognitoIdToken();
        let url = `${BASE_URL}/cost/download_pricing/${type}/${orderId}`;
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.get(url, { headers: header });
        return get(res, 'data', []);
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const submitPaymentDetails = async (downloadType, downloadRequestId, pricingOptionId, body={}) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.post(`${BASE_URL}/cost/download_pricing/${downloadType}/${downloadRequestId}/${pricingOptionId}`, body, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const addPricingConfiguration = async (body) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.post(`${BASE_URL}/cost/download_pricing/create`, body, { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const editPricingConfiguration = async (body, id) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.put(`${BASE_URL}/cost/download_pricing/${id}`, body, { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const deletePricingConfiguration = async (id) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.put(`${BASE_URL}/cost/download_pricing/delete/${id}`, {}, { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const suspendPricingConfiguration = async (body, id) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.put(`${BASE_URL}/cost/download_pricing/suspend/${id}`, body, { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const activatePricingConfiguration = async (id) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.put(`${BASE_URL}/cost/download_pricing/activate/${id}`, {}, { headers: header });
        return get(res, 'status', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}


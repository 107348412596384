/**
 *  External Imports
 */
import Grid from "@mui/material/Grid";

import React, { useState, useEffect } from "react";

/**
 *  Internal Imports
 */
import { COMMUNITY_LEFT_MENU_KEYS } from "../../../constants/menu/community";
import { COMMUNITY_URLS, COMMUNITY_CONNECTIONS_PARENT_ROUTE } from "../../../constants/urls";
import styles from "./index.module.css";
import CommunityLeftMenu from "../../controls/community-left-menu";
import CommunityConnectModal from "../../controls/community-connect-modal";

const DEFAULT_MENU_KEY = COMMUNITY_LEFT_MENU_KEYS.ACCEPTED_REQUESTS;

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 */
const CommunityConnections = (props) => {
    const { location } = props;
    const [menuActiveKey, setMenuActiveKey] = useState(null);

    const setCurrentMenuActiveKey = () => {
        const currentPath = location.pathname.includes(COMMUNITY_URLS.REQUESTS)
            ? location.pathname.replace(`${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.REQUESTS}`, "")
            : location.pathname.replace(COMMUNITY_CONNECTIONS_PARENT_ROUTE, "");

        if (currentPath === COMMUNITY_URLS.SEARCH_RESULTS) {
            setMenuActiveKey({ menuActiveKeyValue: null });
            return;
        }

        const currentMenuKey = Object.keys(COMMUNITY_URLS).find(
            (key) => COMMUNITY_URLS[key] === currentPath
        );
        const menuState =
            COMMUNITY_LEFT_MENU_KEYS[currentMenuKey] || DEFAULT_MENU_KEY;
        setMenuActiveKey({ menuActiveKeyValue: menuState });
    };

    useEffect(() => {
        setCurrentMenuActiveKey();
    }, [location]);

    return (
        <div className={styles.communityConnections}>
            <div className={styles.container}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className={styles.body}>
                    <Grid container item xs={3}>
                        <div
                            className={`${styles.bodyItem} ${styles.bodyItem__sm}`}>
                            <CommunityLeftMenu data-testid="communityLeftMenu"  activeItemKey={menuActiveKey} />
                        </div>
                    </Grid>
                    <Grid container item xs={9}>
                        <div className={styles.bodyItem}>{props.children}</div>
                    </Grid>
                </Grid>
                <CommunityConnectModal />
            </div>
        </div>
    );
};

/**
 *  Exports
 */
export default CommunityConnections;

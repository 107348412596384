import {env} from './env'
import { BASE_URL } from '../src/constants/google-login-urls'

export default {
	apiGateway: {
		REGION: env.REACT_APP_REGION

	},
	cognito: {
		REGION: env.REACT_APP_REGION,
		USER_POOL_ID: env.REACT_APP_USER_POOL_ID,
		APP_CLIENT_ID: env.REACT_APP_CLIENT_ID,
	},
	oauth: {
		domain: env.REACT_APP_DOMAIN,
		scope: JSON.parse(env.REACT_APP_SCOPE),
		redirectSignIn: BASE_URL,
		redirectSignOut: BASE_URL,
		responseType: 'code',
	},
	federationTarget: "COGNITO_USER_POOLS"
};
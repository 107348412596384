import React, { useState, useMemo } from 'react';
import classes from './index.module.css';
import FilterCategory from '../../commons/filter-category'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SearchIcon from '../../../assets/icons/search-icon.svg';
import Checkbox from '@mui/material/Checkbox';
import v4 from 'uuid/v4'

function ExploreDataFilters(props) {

  const {
    exploreFilters = {},
    filterCollapseState = [],
    resetFilterCollapseState = function () { },
    resetAllFilters = function () { },
    handleFilterShowMoreChange = () => { },
    togglesAllItemsInFilter = () => { },
    showMoreItems = [],
    filterGroupingData = null,
    targetLocking = [],
    replacers={}
  } = props

  const filterCategory = Object.keys(exploreFilters) || [];

  const [searchText, setSearchText] = useState("")

  const getFilterDetails = key => {
    return exploreFilters[key] || {}
  }

  const handleSearch = event => {
    setSearchText(event.target.value)
  }

  const searchResults = useMemo(() => {
    let results = [];
    if (searchText) {
      const exploreFilterKeys = Object.keys(exploreFilters)
      for (let key of exploreFilterKeys) {
        let currentFilterItems = [...exploreFilters[key]?.histogram]
        if(targetLocking && targetLocking.find(x => x=== key)){
          currentFilterItems = currentFilterItems.filter(x => x.count >= 50)
        }
        results = [...results, ...(currentFilterItems?.map(item => {
          if (Array.isArray(item.key)) {
            if (item?.key?.find(x => x?.toString()?.toLowerCase()?.includes(searchText.toLowerCase()))) {
              return {
                key: item.key,
                category: key
              }
            }
          } else if (item?.key?.toLowerCase().includes(searchText.toLowerCase())) {
            return {
              key: item.key,
              category: key
            }
          }
        })?.filter(item => item) || [])]
      }
    }
    return results;
  }, [searchText, targetLocking])

  const handleClickAway = () => {
    setSearchText("")
  }


  const getCurrentFilterIndex = category => {
    return filterCategory?.indexOf(category)
  }


  return (
    <div>
      <ul className={classes.filterHeader}>
        <li id='cvb-exploreDataFilters-filtersHeading' className={classes.filterTitle}>Filters</li>
        <li id='cvb-exploreDataFilters-collapseAllButton' className={classes.headerItem} onClick={resetFilterCollapseState}>Collapse All</li>
        <li id='cvb-exploreDataFilters-resetAllFilters' className={classes.headerItem} onClick={resetAllFilters}>Reset All Filters</li>
      </ul>
      <div className={classes.filterContainer}>
        <div className={classes.paddingBottom20}>
          <OutlinedInput fullWidth type='text'
            id='cvb-exploreDataFilters-searchInput'
            value={searchText}
            placeholder='Search'
            onChange={handleSearch}
            className='signup-inputs-large' name='password'
            autoComplete="off"
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" size="large">
                  <img alt='visibility On' src={SearchIcon} className={classes.exploreSearchIcon} />
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        {
          searchResults?.length > 0 &&
          <ClickAwayListener onClickAway={handleClickAway}>

            <div className={classes.popoverSearchFilterContainer}>
              <ul className={classes.popoverSearchFilter}>
                {
                  searchResults?.map(item => {
                    return <li key={v4()} id={`cvb-exploreDataFilters-searchResults-${item.key}`} onClick={() => {
                      props.onChangeFilterItemState(true, item.category, item.key);
                      setSearchText('')

                    }}>{item.key}</li>
                  })
                }
              </ul>
            </div>
          </ClickAwayListener>
        }
        {
          filterGroupingData?.length > 0 ?
            <React.Fragment>
              {
                filterCategory?.length > 0 ?
                  <React.Fragment>
                    {
                      filterGroupingData.map((group) => {
                        return <div key={v4()}>
                          <div id={`cvb-exploreDataFilters-filterGroup-${group?.title}`} className={classes.staticTitles}>{group?.title || "Filter"}</div>
                          {
                            group.customItem ?
                              <div className={classes.customFilters}>
                                {
                                  group?.items?.map((item) => {
                                    return <div key={v4()} className={classes.customFilterItem}>
                                      <div id={`cvb-exploreDataFilters-filterGroupItem-${item.title}`} className={classes.customFilterTitle}>{item.title}</div>
                                      <div className={classes.customFilterCheckbox}>
                                        <Checkbox
                                          //checked={areAllSelected}
                                          color="primary"
                                          disabled
                                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                                          id={`cvb-exploreDataFilters-filterGroupItemCheckbox-${item.title}`}
                                        //onChange={(e) => { togglesAllItemsInFilter(filterCategoryCode, !areAllSelected) }}
                                        />
                                      </div>
                                    </div>
                                  })
                                }
                              </div> :
                              <React.Fragment>
                                {
                                  group?.items?.map((item) => {
                                    const index = getCurrentFilterIndex(item);
                                    const isTargetLockingEnabled = !!targetLocking.find(x => x === item)
                                    return <div key={v4()}>
                                      <FilterCategory
                                        filterIndex={index}
                                        filterCollapseState={filterCollapseState[index]}
                                        filterCategoryCode={item}
                                        item={getFilterDetails(item)}
                                        handleFilterShowMoreChange={handleFilterShowMoreChange}
                                        onChangeFilterItemState={props.onChangeFilterItemState}
                                        handleFilterCollapseStateChange={props.handleFilterCollapseStateChange}
                                        showMoreItems={showMoreItems}
                                        replacers={replacers}
                                        isTargetLockingEnabled={isTargetLockingEnabled}
                                        togglesAllItemsInFilter={togglesAllItemsInFilter} />
                                    </div>

                                  })
                                }
                              </React.Fragment>
                          }
                        </div>
                      })
                    }
                  </React.Fragment> :
                  <div>No Filters</div>
              }
            </React.Fragment> :
            <React.Fragment>
              {
                filterCategory && filterCategory.length > 0 ?
                  filterCategory.map((item, index) => {
                    return <div key={v4()}>
                      <FilterCategory
                        filterIndex={index}
                        filterCollapseState={filterCollapseState[index]}
                        filterCategoryCode={item}
                        item={getFilterDetails(item)}
                        handleFilterShowMoreChange={handleFilterShowMoreChange}
                        onChangeFilterItemState={props.onChangeFilterItemState}
                        handleFilterCollapseStateChange={props.handleFilterCollapseStateChange}
                        showMoreItems={showMoreItems}
                        togglesAllItemsInFilter={togglesAllItemsInFilter} />
                    </div>
                  }) : <div>No filters</div>
              }
            </React.Fragment>
        }
      </div>
    </div >
  );
}

const areEqual = (preProps, nextProps) => {
  return preProps.exploreFilters === nextProps.exploreFilters &&
    preProps.filterCollapseState === nextProps.filterCollapseState &&
    preProps.showMoreItems === nextProps.showMoreItems
}

const memorizedComponent = React.memo(ExploreDataFilters, areEqual)

export default memorizedComponent;

import DataInventoryFormStatus from './DataInventoryFormStatus'
import { connect } from 'react-redux'
import { setDCAStage } from '../../../redux/actions/dcaActions'
import { setDifStage } from '../../../redux/actions/dataInventoryActions'

const mapStateToProps = state => {
    return { 

    }
}
const mapDispatchToProps = dispatch => {
    return {
        setDCAStage: async (value) => await dispatch(setDCAStage(value)),
        setDifStage: async (value) => await dispatch(setDifStage(value)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(DataInventoryFormStatus);
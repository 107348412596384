import React, {useState, useImperativeHandle, useEffect} from "react";
import Popover from "@mui/material/Popover";
import { func } from "prop-types";

import styles from "./index.module.css";
import iconUpArrow from "../../../assets/icons/up-arrow.svg";
import iconDownArrow from "../../../assets/icons/down-arrow.svg";

const DEFAULT_SELECTION = {
    name: "Name A-Z",
    value: { order: "asc", sort: "name" },
};

const SELECT_OPTIONS = [
    {
        name: "Name A-Z",
        value: { order: "asc", sort: "name" },
    },
    {
        name: "Name Z-A",
        value: { order: "desc", sort: "name" },
    },
    {
        name: "Organization A-Z",
        value: { order: "asc", sort: "org" },
    },
    {
        name: "Organization Z-A",
        value: { order: "desc", sort: "org" },
    },
];

const SortBy = (props) => {
    const { handleValueSelect } = props;
    const { passedInRef } = props;
    const { inputValueLength } = props;
    const [currentSelection, setCurrentSelection] = useState(DEFAULT_SELECTION);
    const [anchorElement, setAnchorElement] = useState(null);
    const [visilbeStatus, setVisilbeStatus] = useState(true);

    useEffect(() => {
        if((inputValueLength >= 3) && (currentSelection.name !== SELECT_OPTIONS[0].name)) {
            handleValueSelect(DEFAULT_SELECTION);
            setCurrentSelection(DEFAULT_SELECTION);
        }
        if(inputValueLength) setVisilbeStatus(false);
        else setVisilbeStatus(true);
    }, [inputValueLength])


    const isOpenPopover = !!anchorElement;

    const onButtonClick = (event) => {
        setAnchorElement(event.currentTarget);
    };

    const onModalClose = () => {
        setAnchorElement(null);
    };

    const onSortSelection = (selection) => {
        const { value } = selection;

        setCurrentSelection(selection);
        handleValueSelect(value);
        onModalClose();
    };

    const isSelectedItem = (value) => {
        return value === currentSelection.name;
    };


    useImperativeHandle(passedInRef, () => ({
        reset: () => {
            setCurrentSelection(DEFAULT_SELECTION);
        },
    }));

    return (
        <div className={`${styles.sortArea} ${!visilbeStatus ? 'd-none' : ''}`}>
            <span data-testid="toggleText" className={styles.sortText}>Sort by:</span>
            <button data-testid="toggleBtn" className={styles.popoverBtn} onClick={onButtonClick}>
                <span data-testid="toggleSelection">{currentSelection.name}</span>
                <img data-testid="toggleIcon"
                    className={styles.sortIcon}
                    src={isOpenPopover ? iconUpArrow : iconDownArrow}
                    alt="Icon Toggle"
                />
            </button>
            <Popover
                data-testid="sortPopover"
                open={isOpenPopover}
                anchorEl={anchorElement}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                onClose={onModalClose}>
                <div>
                    {SELECT_OPTIONS.map((item, id) => (
                        <div
                            data-testid="sortPopoverOption"
                            className={isSelectedItem(item.name) ? `${styles.popoverSelectItem} ${styles.popoverSelectItemBold}` : `${styles.popoverSelectItem}`}
                            key={id}
                            onClick={() => onSortSelection(item)}>
                            <span data-testid="sortPopoverOptionText">{item.name}</span>
                        </div>
                    ))}
                </div>
            </Popover>
        </div>
    );
};

SortBy.propTypes = {
	handleValueSelect: func.isRequired,
};


export default SortBy;

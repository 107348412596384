import axios from 'axios'
import {env} from '../env'
import get from 'lodash/get'
import { UPDATE_DATA_USE_POLICY } from './endpoints'
import { getCognitoIdToken } from './utilityMethods';
import { uploadFileToS3 } from '../utils/aws-sdk-helper'
import { BASE_URL } from '../constants/google-login-urls'
import { Auth } from 'aws-amplify'
const APP_BASE_URL = `${env.REACT_APP_BASE_API_PATH}`


export const updateQuestionaries = async body => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/social/community/questionnaire`;
    const res = await axios.patch(url, body, { headers: header });
}

export const getAllQuestionaries = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/social/community/questionnaire`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const getCommunityOptInStatus = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/social/community/private`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const updateCommunityOptInStatus = async body => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/social/community/private`;
    const res = await axios.post(url, body, { headers: header });
}

export const getCommunityKeywords = async (keyword) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/social/community/autocomplete?dict=keywords&str=${keyword}`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

/**
 *  @function getCommunityStatus
 *
 *  @param {CancelToken | undefined?} [cancelToken=undefined] Set a cancel token
 *
 *  @return {Promise<object>}
 */
export const getCommunityStatus = async (cancelToken) => {
    // Variables
    const cognitoToken = await getCognitoIdToken();
    
    let response;
    
    // Emit a request
    try {
        response = await axios.get(
            `${BASE_URL}/social/community/status`,
            {
                headers: {
                    "Authorization": `Bearer ${cognitoToken}`,
                    "Content-Type": "application/json",
                },
                cancelToken,
            },
        );
    }
    catch (err) {
        response = {
            data: {
                isCanceled: !!cancelToken,
            },
        };
        
        if (typeof cancelToken === "undefined") {
            console.error("onboarding-api - getCommunityStatus:", err);
        }
    }
    
    // Return the data
    return response.data;
}

import { navigate } from '@reach/router'
import React, { useState, useEffect } from 'react'
import {env} from '../../../env'
import { COOKIES_CONTENT } from '../../../constants/strings'
import SectionHeader from '../../commons/section-header'
import classes from './index.module.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Collapse, Switch } from '@mui/material'
import DownArrow from '../../../assets/icons/down-arrow.svg'
import UpArrow from '../../../assets/icons/up-arrow.svg'
import CookieBanner from '../../commons/cookie-banner'
import { addGoogleAnalytics, deleteCookie, getCookie, setCookie } from '../../../api/generic-api'
import clsx from 'clsx';
import AppBar from '@mui/material/AppBar';
import BCDataPortalLogo from '../../../assets/images/onboarding_logo.png'
import CookieNotice from '../../commons/cookie-notice'
const GA_MEASUREMENT_ID = `${env.REACT_APP_GA_MEASUREMENT_ID}`
const sectionHeaderProps = {
    title: "BRAINCommons Cookies Settings",

}

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        left: '2px !important',
        color: theme.palette.grey[500],
        '& + $track': {
            borderColor: '#9e9e9e',
        },
        '&$checked': {
            transform: 'translateX(12px)',
            color: '#412391',
            '& + $track': {
                opacity: 1,
                backgroundColor: '#fff',
                borderColor: '#412391',
            },
        },
    },
    thumb: {
        marginTop: 2,
        left: 2,
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid #412391`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: '#fff',
    },
    checked: {},
}))(Switch);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#637BBD',
        color: '#fff !important',
        borderRight: '2px solid #fff !important'
    },
    body: {
        borderRight: '2px solid #fff',
        verticalAlign: 'initial'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: '#DBEAF0',
        '&:hover': {
            backgroundColor: '#DBEAF0 !important'
        }
    },
}))(TableRow);

const CookiesDetails = props => {
    const [expandRow, setExpandRow] = useState({
        performance: true,
        socialMedia: true,
        strict: true
    })
    const [showCookieBanner, setShowCookieBanner] = useState(false);
    const { onCookieDetailPage = () => { } } = props;
    useEffect(() => {
        onCookieDetailPage(true);
        return () => {
            onCookieDetailPage(false);
        }
    }, []);
    const onBackButtonClick = () => {
        navigate('/');
    }
    // Opt-out function
    const gaOptout = async () => {
        let gaCookieValue = `${GA_MEASUREMENT_ID}`.split('-')[1];
        window[`ga-disable-${GA_MEASUREMENT_ID}`] = true;
        await deleteCookie('_gid', '', 'Thu, 01 Jan 1970 00:00:00 UTC');
        await deleteCookie('_ga', '', 'Thu, 01 Jan 1970 00:00:00 UTC');
        await deleteCookie(`_ga_${gaCookieValue}`, '', 'Thu, 01 Jan 1970 00:00:00 UTC');
    }
    const onCookieSwitchChange = async (event) => {
        const name = event.target.name;
        const value = event.target.checked;

        if (name == 'all') {
            props.setOnOffCookie({
                all: value,
                performance: value
            });
        } else {
            props.setOnOffCookie({ ...props.onOffCookie, [name]: value });
        }
    }
    const onDropIconClick = (event, name) => {
        const newVal = !expandRow[name];
        setExpandRow({
            ...expandRow,
            [name]: newVal
        })
    }
    const onViewCookieNoticeClick = () => {
        setShowCookieBanner(true);
    }
    const onAcceptClick = () => {
        setShowCookieBanner(false);
    }
    const onCancelClick = () => {
        setShowCookieBanner(false);
    }
    const setInitialState = async () => {
        let isPerformanceCookies;
        let user = getCookie("cvb_user");
        if(user != "") {
            isPerformanceCookies = user === 'true' ? true : false
        } else {
            isPerformanceCookies = true;
        }
        props.setOnOffCookie({ ...props.onOffCookie, performance: isPerformanceCookies })
    }

    useEffect(() => {
        setInitialState();
    }, []);
    function injectScript(src) {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', resolve);
            script.addEventListener('error', e => reject(e.error));
            document.head.appendChild(script);
        });
    }
    
     const injectGAScript = async () => {
        await injectScript(`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`)
            .then(() => {
               console.log('script injected');
               addGoogleAnalytics();
            }).catch(error => {
                console.error(error);
            });
    }
    const onSaveSettingsClick = async () => {
        let isSocialCookies;
        const performanceCookieValue = props.onOffCookie?.performance || false;

        let user = getCookie("cvb_user");
        if (user != "") {
            isSocialCookies = user === 'true' ? true : false
        } else {
            isSocialCookies = false;
        }
        await deleteCookie('cvb_user', '', 'Thu, 01 Jan 1970 00:00:00 UTC');
        await setCookie("cvb_user", performanceCookieValue, 365);
        props.setBannerCookie(false);
        if (performanceCookieValue) {
            injectGAScript();
        } else {
            await gaOptout();
        }
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const navigateTo = urlParams.get('pathName') || '/';
        setTimeout(()=>{
            navigate(navigateTo);
        }, 3000)
    }
    const onCancelSettingsClick = async () =>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const navigateTo = urlParams.get('pathName') || '/';
        setTimeout(()=>{
            navigate(navigateTo);
        }, 3000)
    }
    const COOKIE_NOTICE_MODAL = {
        modalTitle: "Cookie Notice",
        modalContent: "",
    }
    return (
        <React.Fragment>
            <header className={classes.cookiePageheader}>
                <div className={classes.leftBCLogoContainer}>
                    <img src={BCDataPortalLogo} alt="braincommons DP logo" />
                </div>
            </header>
            <div className={classes.headerContainer}>
                <h1>BRAINCommons Cookies Settings</h1>
            </div>
            <CookieNotice
                open={showCookieBanner}
                handleClose={onCancelClick}
                dialogProps={COOKIE_NOTICE_MODAL}
            />
            <div className={classes.topContainer}>
                <p className={classes.heading}>Cookie Preference Center</p>
                <p className={classes.subHeading}>
                    We use cookies to distinguish you from other users of the BC Platform. This helps us to provide you with an
                    optimised experience and allows us to make continual improvements. This information might be about you,
                    your preferences or your device and is mostly used to make the BC Platform work as you expect it to.
                    The information doesn’t usually directly identify you, but it can give you a more personalized experience.
                </p>
                <p className={classes.subHeading}>Because we respect your right to privacy, you can choose not to allow some types of cookies.
                Click on the different category headings to find out more and change our default settings.
                However, blocking some types of cookies may impact your experience of the BC Platform and the services we are
                able to offer.
                    </p>
                <p className={classes.subHeading}> In some cases, data obtained from cookies is shared with third parties. You can exercise your
                right to opt out of that sharing at any time by disabling cookies.
                </p>
                <button className={`${classes.viewCookieButton}`} onClick={onViewCookieNoticeClick}>View Cookie Notice</button>
            </div>
            <div className={classes.bottomContainer}>
                <p className={classes.heading}>Manage Cookie Consent Preferences</p>
                <Collapse in={expandRow?.strict} collapsedSize={56}>

                    <div className={classes.accordianDiv}>
                        <div className={classes.dropIcon} onClick={(e) => onDropIconClick(e, 'strict')}>
                            {expandRow?.strict ? <img src={UpArrow} /> : <img src={DownArrow} />}
                        </div>
                        <div className={classes.switchButtonContainer}>
                            <span className={classes.switchSpan}>Always On</span>
                            <AntSwitch checked={true} />
                        </div>
                        <p className={classes.titles}>Strictly Necessary Cookies</p>
                        <p className={classes.content}>These cookies are strictly necessary for the website and BC Portal to function and can’t be switched off in our systems.
                        They’re usually only set in response to actions made by you that amount to a request for services; setting your privacy
                        preferences; logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some
                    parts of the website and the BC Portal won’t work as a result. These cookies don’t store any PII. </p>
                    </div>
                </Collapse>
                <Collapse in={expandRow?.performance} collapsedSize={56}>
                    <div className={classes.accordianDiv}>
                        <div className={classes.dropIcon} onClick={(e) => onDropIconClick(e, 'performance')}>
                            {expandRow?.performance ? <img src={UpArrow} /> : <img src={DownArrow} />}
                        </div>
                        <div className={classes.switchButtonContainer}>
                            <span className={classes.switchSpan}>{props.onOffCookie?.performance || false ? 'On' : 'Off'}</span>
                            <AntSwitch checked={props.onOffCookie?.performance || false} onChange={onCookieSwitchChange} name='performance' />
                        </div>
                        <p className={classes.titles}>Performance Cookies</p>
                        <p className={classes.content}>These cookies allow us or third parties to count visits, traffic sources and collect information
                        about how visitors use the BC Platform. We do this so we can measure and optimise the user experience.
                        For example, we can learn which pages users go to most often and if they get error messages from webpages.
                        All information these cookies collect is aggregated and therefore anonymous, so won’t identify you personally.
                        If you don't allow these cookies on the device you use to visit the BC Platform, we won't know when you've visited
                    the BC Platform and won't be able to monitor its performance.</p>
                    </div>
                </Collapse>
                <Collapse in={expandRow?.socialMedia} collapsedSize={56}>
                    <div className={classes.accordianDiv}>
                        <div className={classes.dropIcon} onClick={(e) => onDropIconClick(e, 'socialMedia')}>
                            {expandRow?.socialMedia ? <img src={UpArrow} /> : <img src={DownArrow} />}
                        </div>
                        <div className={classes.switchButtonContainer}>
                            <span className={classes.switchSpan}>{props.onOffCookie?.socialMedia || false ? 'On' : 'Off'}</span>
                            <AntSwitch checked={false} onChange={() => { }} name='socialMedia' />
                        </div>
                        <p className={classes.titles}>Social Media Cookies</p>
                        <p className={`${classes.content} ${classes.addMarginBottom}`}>
                            No social media cookies are used on the BRAINCommons Platform. As a registered user, you’ll have a
                            customizable profile page where you can include your social media links to other sites such as LinkedIn,
                            Google Scholar and Twitter. The BRAINCommons Platform is not responsible for the processing of any personal
                            data you may wish to share with these sites. We strongly recommend that you carefully read the privacy notice
                            and terms and conditions before activating these social media links on your profile page.
                        </p>
                    </div>
                </Collapse>
            </div>
            <div className={classes.cookiesFooter}>
                <div className={classes.buttonWrapperClass}>
                    {/* <button className={`transparent-button small-size-button ${Classes.cancelButton}`} >Previous</button> */}
                    <button className={`medium-size-button transparent-button ${classes.cancelButton}`} onClick={onCancelSettingsClick} >
                        Cancel
                    </button>
                    <button className={`medium-size-button solid-button ${classes.saveCookiesButton}`} onClick={onSaveSettingsClick} >
                        Save
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CookiesDetails
import React from 'react'
import Classes from './index.module.css'
import CloseSmallIcon from '../../../assets/icons/close-small.svg'
import Grid from '@mui/material/Grid';
import get from 'lodash/get'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import v4 from 'uuid/v4'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AnalysisTabs = props => {

  const { analysisState = [],
    currentActive = 0,
    currentState,
    setCurrentActive = () => { },
    closeAnalysisTab = () => { } } = props;

  const hasTwoOrMoreAnalysis = analysisState.length > 1


  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    infinite: false,
    variableWidth: false
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} className={Classes.customTabContainerMain}>
        <ul className={Classes.customTabContainer} id='tabs-view-slider'>
          <Slider {...settings}>
            {
              analysisState.length > 0 &&
              analysisState.map((item, index) => {
                const activeOrInactiveClass = currentActive === item?.id
                  ? Classes.activeCustomTab
                  : Classes.inActiveCustomTab;
                return <li key={v4()}
                  id={`cvb-analysisTab-tab-${index}`}
                  onClick={() => setCurrentActive(item.id)}
                  className={`${Classes.customTab} ${activeOrInactiveClass}`}
                >
                  <div className={Classes.tabsTitleContainer}>
                    <div className={Classes.tabsTitleChildContainer}>
                      <p id={`cvb-analysisTab-analysisTabText-${index}`} className={Classes.tabsTitle}>Analysis {index + 1 > 9 ? index + 1 : `0${index + 1}`} </p>
                      {
                        item?.xAxis && item?.yAxis &&
                        <p className={Classes.tabsMeta}><span id={`cvb-analysisTab-tab-${index}-xAxis`}>{item?.xAxis}</span> vs <span id={`cvb-analysisTab-tab-${index}-yAxis`}>{item?.yAxis}</span></p>
                      }
                    </div>
                    <span id={`cvb-analysisTab-closeTab-${index}`} onClick={(e) => closeAnalysisTab(e, item?.id)}
                      className={Classes.closeIconImage}>
                      {
                        hasTwoOrMoreAnalysis &&
                        <img src={CloseSmallIcon} alt='close small' />
                      }
                    </span>
                  </div>
                </li>
              })
            }
            {
              currentState?.xAxis && currentState?.yAxis &&
              <li id='cvb-analysisTab-addAnalysis' onClick={props.addNewAnalysisData}
                className={`${Classes.customTab} ${Classes.addAnalysisTab}`}>
                + Add Analysis
              </li>
            }
          </Slider>
        </ul>
      </Grid>
    </Grid>
  )
}

export default AnalysisTabs;
import React, { useEffect, useState, useMemo } from 'react'
import Classes from './index.module.css';
import { Auth } from 'aws-amplify';
import SectionHeader from '../../commons/section-header';
import isEmpty from 'lodash/isEmpty'
import Grid from '@mui/material/Grid'
import { Router } from '@reach/router'
import v4 from 'uuid/v4'
import map from 'lodash/map'
import DCAFormStepper from '../dca-form-stepper'
import CustomLoader from '../../commons/custom-loader';
import { navigate } from '@reach/router';
import moment from 'moment'
import get from 'lodash/get'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '../../commons/table-pagination';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import ViewDataForm from '../view-data-forms'
import TermsOfUse from '../../commons/terms-of-use'
import PrivacyPolicy from '../../commons/privacy-policy'
import Modal from '../../commons/modal'
import { lowerCase, filter, sortBy } from 'lodash';
import { getSavedSearch, exportSavedSearchToWorkspace } from '../../../api/saved-search-api';
import ImgBackWard from '../../../assets/icons/backwards.svg'
import ImgForward from '../../../assets/icons/forward.svg'
import ImgFastForward from '../../../assets/icons/fast_farward.svg'
import ImgBackWardGrey from '../../../assets/icons/grey_first.svg'
import ImgForwardGrey from '../../../assets/icons/grey_forward.svg'
import ImgFastForwardGrey from '../../../assets/icons/grey_last.svg'
import ImgReverse from '../../../assets/icons/reverse_forward.svg'
import ImgGreyBack from '../../../assets/icons/backGrey.svg'
import MuiPopover from '@mui/material/Popover';
import ImageVerticalIcon from '../../../assets/icons/MoreOptionsVertical.S-dark.svg';
import { getAllWorkspaces } from '../../../api/workspace-api/workspace-api'
import ErrorText from '../error-text';
import DropDown from '../../commons/dropdown';

const Popover = withStyles(theme => ({
    root: {
        overflowY: 'scroll',
    },
    paper: {
        minWidth: 192,
        right: 'auto !important',
        marginTop: '32px !important',
    }
}))(MuiPopover);
const SavedSearch = props => {

    const [savedSearch, setSavedSearch] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openShareNotebookCopyModal, setOpenShareNotebookCopyModal] = useState(false);
    const [workspaceValues, setWorkspaceValues] = useState([]);
    const [selectedWorkspace, setSelectedWorkspace] = useState('');
    const [searchId, setSearchId] = useState('');
    const [loading, setLoading] = useState(false);
    const [exportToWorkspace, setExportToWorkspace] = useState(false);
    const [userPageInputValue, setUserPageInputValue] = useState(1);

    const openPopover = Boolean(anchorEl);

    const closeShareNotebookCopyModal = () => {
        setOpenShareNotebookCopyModal(false);
    }

    const onWorkspaceOptionChange = (value) => {
        setExportToWorkspace(false)
        setSelectedWorkspace(value);
    }

    useEffect(() => {
        getSavedSearchDetails();
    }, [])

    useEffect(() => {
        loadAllWorkspaces();
    }, [props.allWorkspaces]);

    const getSavedSearchDetails = async () => {
        try {
            const res = await getSavedSearch();
            if (res?.length) {
                setSavedSearch(res);
            }

        } catch (error) {
            console.log('error in loading saved search');
        }
    }

    const maxCount = savedSearch?.length ? Math.ceil(savedSearch.length / 5) : 0;

    const handleNextClick = () => {
        if (page < (maxCount - 1)) {
            setPage(page + 1);
            setUserPageInputValue(page+2)
        }
    }

    const handlePreviousClick = () => {
        if (page >= 1) {
            setPage(page - 1);
            setUserPageInputValue(page)
        }
    }

    const handleDownArrowClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setSearchId(id);
    }

    const handleClosePopover = () => {
        setAnchorEl(null);
    }

    const loadAllWorkspaces = () => {
        try {
            if (props.allWorkspaces?.length) {
                const workspacesIds = map(props.allWorkspaces, (workspace) => workspace['workspace_id']);
                if (workspacesIds?.length) {
                    setWorkspaceValues(workspacesIds);
                    if (workspacesIds?.length === 1) {
                        onWorkspaceOptionChange(workspacesIds[0]);
                    }
                }
            } else {
                setWorkspaceValues([]);
            }
        } catch (error) {
            setWorkspaceValues([]);
        }
    }

    const onShareNotebookCopyConfirm = async () => {
        try {
            if (selectedWorkspace) {
                setLoading(true);
                const body = {
                    "id": searchId,
                    "workspace_id": selectedWorkspace
                };
                const res = await exportSavedSearchToWorkspace(body);
                props.updateSnackBar(`Successfully Exported Saved Search to Workspace`, "Success");
                closeShareNotebookCopyModal();
                setLoading(false);

            }else{
                setExportToWorkspace(true);
            }

        } catch (error) {
            props.updateSnackBar(`Failed to Export Saved Search to Workspace`, "Error");
            closeShareNotebookCopyModal();
            setLoading(false);

        }
    }


    const SHARE_NOTEBOOK_COPY_MODAL_PROPS = {
        modalTitle: 'Export To Workspace',
        positiveButtonText: 'Export To Workspace',
        negativeButtonText: "Cancel",
        positiveButtonAction: onShareNotebookCopyConfirm,
        negativeButtonAction: closeShareNotebookCopyModal,
        popupButtonDisable: loading
    }

    const openModal = () => {
        handleClosePopover();
        setOpenShareNotebookCopyModal(true);
    }

    const viewSearch = (item) => {
        let queryParams = []
        if (item?.node_name) {
            queryParams.push(`dataCategory=${item?.node_name}`)
        }
        if (item?.project_ids?.length > 0) {
            queryParams.push("projects=" + item.project_ids.join(","))
        }
        if (item?.search_filter) {
            queryParams.push("keyword=" + item?.search_filter)
        }
        navigate("/explore/search-results/" + queryParams.join("&"))
    }

    const setTablePage = (number) => {
        setPage(number);
    }

    return (
        <>

            <Modal
                open={openShareNotebookCopyModal}
                handleClose={closeShareNotebookCopyModal}
                dialogProps={SHARE_NOTEBOOK_COPY_MODAL_PROPS}
            >
                <div>
                    {workspaceValues?.length > 1 ?
                        <div className={Classes.selectWorkspaceContainer} id='cvb-mySavedSearch-workspaceContainer'>
                            <Grid item xs={6}>
                                <DropDown
                                    values={workspaceValues}
                                    handleFilterStateChange={onWorkspaceOptionChange}
                                    selectedFilter={selectedWorkspace}
                                    placeholder={'Select workspace'}
                                />
                                {
                                    exportToWorkspace && isEmpty(selectedWorkspace) ?
                                        <div className={Classes.errorTextDiv} id='cvb-mySavedSearch-workspaceError'>
                                            <ErrorText errorMessage='Please select workspace' />
                                        </div> : null
                                }
                                {
                                    loading ?
                                        <CustomLoader styleProp={{ marginTop: '25px' }} /> : null
                                }
                            </Grid>
                        </div>
                        :
                        <div className={Classes.selectWorkspaceContainer}>
                            <p id='cvb-mySavedSearch-workspaceDescription'> You are exporting saved search to <span className={Classes.workspaceName}><b>{workspaceValues[0]}</b></span> Workspace.
          </p>
                            {
                                loading ?
                                    <CustomLoader styleProp={{ marginTop: '25px' }} /> : null
                            }
                        </div>


                    }
                </div>
            </Modal>

            <div className={Classes.container}>
                <div className={Classes.myUploadHeaderContainer} id='cvb-mySavedSearch-blockContainer'>
                    <p className={Classes.myStuffSectionHeaders} id='cvb-mySavedSearch-headingText'>My Saved Searches</p>
                </div>
                <Grid container lg={8} item xs={12} md={12} spacing={4} className={Classes.authorizationContainer} >
                    <div className={Classes.savedSearchContainer} id='cvb-mySavedSearch-tableContainer'>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell id='cvb-mySavedSearch-tableHeaderSearchName' className={Classes.typeColumn} width="30%">Search Name</TableCell>
                                        <TableCell id='cvb-mySavedSearch-tableHeaderSavedOn' className={Classes.searchColumn} width="25%" align="left">Saved On</TableCell>
                                        <TableCell id='cvb-mySavedSearch-tableHeaderDataCategory' className={Classes.searchColumn} width="55%" align="left">Data Category</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {savedSearch.map((row) => (
                                        <TableRow key={row?.id}>
                                            <TableCell id='cvb-mySavedSearch-tablCellSearchName' className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
                                                <Tooltip placement="bottom-start" className={Classes.userNameTitle} title={row?.search_name || ''}>
                                                    <span className={Classes.userNameTitle}>{row?.search_name}</span>
                                                </Tooltip>
                                            </TableCell>

                                            <TableCell id='cvb-mySavedSearch-tablCellDate' className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
                                                <Tooltip placement="bottom-start" title={row?.created_at || ''}>
                                                    <span>{row?.created_at ? (moment.utc(row?.created_at)?.local().format('MM/DD/YYYY') + ' at ' + moment.utc(row?.created_at)?.local().format('hh:mm A')) : ''}</span>
                                                </Tooltip>
                                            </TableCell>

                                            <TableCell id='cvb-mySavedSearch-tablCellNodeName' className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
                                                <Tooltip placement="bottom-start" title={row?.node_name || ''}>
                                                    <span>{row?.node_name || ''}</span>
                                                </Tooltip>
                                                <span className={Classes.rightIconBox}>
                                                    {
                                                        workspaceValues?.length && props.userDetails?.resourceActions?.my_stuff?.export_saved_search_to_workspace ?
                                                        <img
                                                            src={ImageVerticalIcon}
                                                            alt='verticalIcon'
                                                            className={Classes.rightIconImage}
                                                            onClick={(e) => handleDownArrowClick(e, row?.id)}
                                                        /> : null
                                                    }
                                                    <button id='cvb-mySavedSearch-tablCellViewButton' className={Classes.statusButton} onClick={() => viewSearch(row)}>View</button>
                                                </span>
                                                <Popover
                                                    id={'WorkspaceCard-popover'}
                                                    open={openPopover}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClosePopover}
                                                    anchorOrigin={{
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <ul className={Classes.dropdownListContainer}>
                                                        <li id='cvb-mySavedSearch-tablCellExportToWorkSpaceLink' onClick={() => { openModal() }} className={Classes.linkIcons}>
                                                            Export To Workspace
                                                                </li>

                                                    </ul>
                                                </Popover>
                                            </TableCell>



                                        </TableRow>
                                    )).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}

                                </TableBody>
                            </Table>
                        </TableContainer>


                        {
                            maxCount > 0 &&
                            <div className={Classes.tablePagination} id='cvb-mySavedSearch-tablePagination'>
                                <TablePagination
                                    maxCount={maxCount}
                                    page={page}
                                    setPage={setTablePage}
                                />
                            </div>
                        }
                    </div>
                </Grid>
            </div>
        </>
    )
}

export default SavedSearch;
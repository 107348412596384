import AuthorizedDataSubmitters from './AuthorizedDataSubmitters'
import { connect } from 'react-redux'
import { updateAuthorizeSubmitterFirstName, updateAuthorizeSubmitterMiddleName, updateAuthorizeSubmitterLastName, updateAuthorizeSubmitterAffiliation, updateAuthorizeSubmitterInstitutionalEmail
    , addAuthorizeSubmitter, removeAuthorizeSubmitter, updateAuthorizeSubmitterCanUpload,
    updateAuthorizeSubmitterCanModify, updateAuthorizeSubmitterCanDelete
} from '../../../redux/actions/authorizeSubmitterActions'
const mapStateToProps = state => {
    return {
        authorizedSubmitters: state.authorizeDatasetSubmitter.authorizedSubmitters,
        currentDCAFormStage: state.dca.currentDCAFormStage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateAuthorizeSubmitterFirstName: async (value, id) => await dispatch(updateAuthorizeSubmitterFirstName(value, id)),
        updateAuthorizeSubmitterMiddleName: async (value, id) => await dispatch(updateAuthorizeSubmitterMiddleName(value, id)),
        updateAuthorizeSubmitterLastName: async (value, id) => await dispatch(updateAuthorizeSubmitterLastName(value, id)),
        updateAuthorizeSubmitterAffiliation: async (value, id) => await dispatch(updateAuthorizeSubmitterAffiliation(value, id)),
        updateAuthorizeSubmitterInstitutionalEmail: async (value, id) => await dispatch(updateAuthorizeSubmitterInstitutionalEmail(value, id)),
        addAuthorizeSubmitter: async (value) => await dispatch(addAuthorizeSubmitter(value)),
        removeAuthorizeSubmitter: async (value) => await dispatch(removeAuthorizeSubmitter(value)),
        updateAuthorizeSubmitterCanUpload: async (value, id) => await dispatch(updateAuthorizeSubmitterCanUpload(value, id)),
        updateAuthorizeSubmitterCanModify: async (value, id) => await dispatch(updateAuthorizeSubmitterCanModify(value, id)),
        updateAuthorizeSubmitterCanDelete: async (value, id) => await dispatch(updateAuthorizeSubmitterCanDelete(value, id)),
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedDataSubmitters)
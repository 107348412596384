import { SET_AGREEMENT_DATE, SET_CLIENT_NAME, SET_AGREEMENT_YEAR, SET_SIGNATORY_NAME, SET_SIGNATORY_TITLE,
    SET_NON_COMMERCIAL_CHECK, SET_SERVICES_CHECK, SET_AGREEMENT_CHECK, RESET_AGREEMENT_VALUES  } from '../action-types'

const initialState = {
    serviceAgreementDate : null,
    clientName: '',
    agreementYears: '',
    signatoryName: '',
    signatoryTitle: '',
    nonCommercialCheck: false,
    servicesCheck: {
            'service1': false,
            'service2': false,
            'service3': false
    },
    agreementChecks: {
            'services': false,
            'termsAndTermination': false,
            'compensation': false,
            'brainCommons': false,
            'intellectual': false,
            'indemnification': false,
            'confidentiality': false,
            'limitation': false,
            'useOfName': false,
            'miscellaneous': false
    }
}

const serviceAgreementReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AGREEMENT_DATE: {
            return {
                ...state,
                serviceAgreementDate: action.payload
            }
        }
        case SET_CLIENT_NAME: {
            return {
                ...state,
                clientName: action.payload
            }
        }
        case SET_AGREEMENT_YEAR: {
            return {
                ...state,
                agreementYears: action.payload
            }
        }
        case SET_SIGNATORY_NAME: {
            return {
                ...state,
                signatoryName: action.payload
            }
        }
        case SET_SIGNATORY_TITLE: {
            return {
                ...state,
                signatoryTitle: action.payload
            }
        }
        case SET_NON_COMMERCIAL_CHECK: {
            return {
                ...state,
                nonCommercialCheck: action.payload
            }
        }
        case SET_SERVICES_CHECK: {
            return {
                ...state,
                servicesCheck: action.payload
            }
        }
        case SET_AGREEMENT_CHECK: {
            return {
                ...state,
                agreementChecks: action.payload
            }
        }
       
        case RESET_AGREEMENT_VALUES: {
            return initialState   
        }

        default: return state;
    }
}

export default serviceAgreementReducer;
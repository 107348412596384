import StructuredUploadFiles from './StructuredUploadFiles'
import { updateSnackBar } from '../../../redux/actions/globalActions'
import { connect } from 'react-redux';
const mapStateToProps = state => {
    return {

    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateSnackBar: async (message, type) => await dispatch(updateSnackBar(message, type)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StructuredUploadFiles);

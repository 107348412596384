import axios from 'axios'
import { getCognitoIdToken } from './utilityMethods';
import { BASE_URL } from '../constants/google-login-urls'
import Store from '../redux/store'

export const getAllFilesToMap = async (pathLead, body) => {
    const userEmail = Store.getState()?.user?.userDetails?.email || null
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'text/tab-separated-values'
    };
    const response = await axios.get(`${BASE_URL}/index/index?acl=null&uploader=${userEmail}&start=00000000-0000-0000-0000-000000000000&limit=1024`,
        { headers: header });
    return response?.data || null
}
import { SET_AUTHORIZE_SUBMITTERS_FIRST_NAME, SET_AUTHORIZE_SUBMITTERS_LAST_NAME, SET_AUTHORIZE_SUBMITTERS_MIDDLE_NAME,
    SET_AUTHORIZE_SUBMITTERS_AFFILIATION, SET_AUTHORIZE_SUBMITTERS_INSTITUTIONAL_EMAIL
 , ADD_AUTHORIZE_SUBMITTERS_DETAILS, REMOVE_AUTHORIZE_SUBMITTER, SET_AUTHORIZE_SUBMITTERS_CAN_UPLOAD
, SET_AUTHORIZE_SUBMITTERS_CAN_MODIFY, SET_AUTHORIZE_SUBMITTERS_CAN_REMOVE, SET_AUTHORIZED_SUBMITTERS_FIELDS
, RESET_AUTHORIZED_SUBMITTERS_FIELDS
} from '../action-types'

export const updateAuthorizeSubmitterFirstName = (value, id) => {
    return {
        type: SET_AUTHORIZE_SUBMITTERS_FIRST_NAME,
        payload: {
            value,
            id
        }
    }
}
export const updateAuthorizeSubmitterMiddleName = (value, id) => {
    return {
        type: SET_AUTHORIZE_SUBMITTERS_MIDDLE_NAME,
        payload: {
            value,
            id
        }
    }
}
export const updateAuthorizeSubmitterLastName = (value, id) => {
    return {
        type: SET_AUTHORIZE_SUBMITTERS_LAST_NAME,
        payload: {
            value,
            id
        }
    }
}
export const updateAuthorizeSubmitterAffiliation = (value, id) => {
    return {
        type: SET_AUTHORIZE_SUBMITTERS_AFFILIATION,
        payload: {
            value,
            id
        }
    }
}
export const updateAuthorizeSubmitterInstitutionalEmail = (value, id) => {
    return {
        type: SET_AUTHORIZE_SUBMITTERS_INSTITUTIONAL_EMAIL,
        payload: {
            value,
            id
        }
    }
}
export const addAuthorizeSubmitter = (value) => {
    return {
        type: ADD_AUTHORIZE_SUBMITTERS_DETAILS,
        payload: value
    }
}
export const removeAuthorizeSubmitter = (value) => {
    return {
        type: REMOVE_AUTHORIZE_SUBMITTER,
        payload: value
    }
}
export const updateAuthorizeSubmitterCanUpload = (value, id) => {
    return {
        type: SET_AUTHORIZE_SUBMITTERS_CAN_UPLOAD,
        payload: {
            value,
            id
        }
    }
}
export const updateAuthorizeSubmitterCanModify = (value, id) => {
    return {
        type: SET_AUTHORIZE_SUBMITTERS_CAN_MODIFY,
        payload: {
            value,
            id
        }
    }
}
export const updateAuthorizeSubmitterCanDelete = (value, id) => {
    return {
        type: SET_AUTHORIZE_SUBMITTERS_CAN_REMOVE,
        payload: {
            value,
            id
        }
    }
}
export const setAuthorizedDataSubmittersFields = (value) => {
    return {
        type: SET_AUTHORIZED_SUBMITTERS_FIELDS,
        payload: value
    }
}
export const resetAuthSubmitterForm =()=>{
    return{
        type: RESET_AUTHORIZED_SUBMITTERS_FIELDS,
    }
}
import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import SharedPieChart from '../../../../controls/pie-chart/PieChart';
import { PrimitiveContainer } from '../../primitives/PrimitiveContainer';
import { Container } from '../../primitives/Statistics';
import { Overview } from './Overview';
import PropTypes from 'prop-types';
import { vars } from '../../../../../assets/variables';
import ProjectBarChart from './charts/ProjectsChart';

const { darkTextColor } = vars;
const PROJECTS_CHART_MIN_WIDTH = 282;
const useStyles = makeStyles(() => ({
  scrollContainer: {
    width: '100%',
    // overflowY: 'hidden',
    overflowY: 'scroll',
  },
  title: {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: darkTextColor,
    position: 'absolute',
    top: '1rem',
    left: '1rem',
  },
}));

export const Statistics = ({
  statsData,
  diseases,
  projectAssessments,
  projectDataTypes,
  loading,
  isLongitudinal,
}) => {
  const classes = useStyles();
  const longitudinalText = isLongitudinal ? 'Longitudinal' : '';

  return (
    <PrimitiveContainer py={3}>
      <Stack direction="row" overflow="hidden" width="100%" >
        <ScrollContainer
          className={classes.scrollContainer}
          ignoreElements={'span'}
          hideScrollbars
          vertical={false}
        >
          <Stack direction="row" spacing={1} flexShrink={0}>
            <Overview statsData={statsData} />

            <Box flexShrink={0} width={374}>
              <Container height="100%">
                <SharedPieChart
                  data={diseases}
                  nameKey="key"
                  dataKey="value"
                  xLabel="Diseases"
                  loading={loading}
                  isDoughnut
                />
              </Container>
            </Box>
            <Box flex={1} width={PROJECTS_CHART_MIN_WIDTH}>
              <Container height="100%">
                <ProjectBarChart
                  chartData={projectAssessments}
                  xKey="key"
                  valKey="count"
                  field="assessments"
                  xLabel={`Assessments by ${longitudinalText} Projects`}
                  loading={loading}
                  isStacked
                />
              </Container>
            </Box>
            {projectDataTypes && (
              <Box flex={1} width={PROJECTS_CHART_MIN_WIDTH} pr={3}>
                <Container height="100%">
                  <ProjectBarChart
                    chartData={projectDataTypes}
                    xKey="key"
                    valKey="count"
                    xLabel={`Data Types by ${longitudinalText} Projects`}
                    defaultCount={1}
                    loading={loading}
                    isStacked
                  />
                </Container>
              </Box>
            )}
          </Stack>
        </ScrollContainer>
      </Stack>
    </PrimitiveContainer>
  );
};

Statistics.propTypes = {
  statsData: PropTypes.object,
  diseases: PropTypes.array,
  projectAssessments: PropTypes.array,
  projectDataTypes: PropTypes.array,
  loading: PropTypes.bool,
  isLongitudinal: PropTypes.bool,
};

Statistics.defaultProps = {
  diseases: [],
};

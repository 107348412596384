import React from 'react';
import classes from './index.module.css'
import LoaderImg from '../../../assets/images/loader.gif'

function CustomLoader(props) {
    const { componentLoader, styleProp } = props;
    return (
        <div style={styleProp} className={componentLoader ? classes.componentContainer : classes.pageContainer}>
            <img src={LoaderImg} alt="loader" />
        </div>
    )
}

export default CustomLoader;

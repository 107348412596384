import axios from 'axios'
import {env} from '../env'
import { getCognitoIdToken } from './utilityMethods';
const BASE_URL = `${env.REACT_APP_BASE_API_PATH}`

const getFormedRequestHeaders = async () => {
    const token = await getCognitoIdToken();
    return {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
    };
};

export const getUserInvite = async (token) => {
    let res;
   
    try {
        res = await axios.get(`${BASE_URL}/user/invite/${token}`);
    } catch (err) {
        res = {
            data: {
                message: 'INVITE_IS_INVALID',
                error: err,
            },
        };
    }  
   
    return res.data
}

export const createUserInvite = async (payload) => {
    const header = await getFormedRequestHeaders()
    const res = await axios.post(`${BASE_URL}/user/invite`, payload, {headers: header}).catch(error => error.response)
    return res
}

export const updateSelfSignUpStatus = async (param) => {
    const header = await getFormedRequestHeaders()
    const res = await axios.put(`${BASE_URL}/user/self_sign_up/${param}`, {}, {headers: header})
    return res.data
}

export const getSelfSignUpStatus = async () => {
    // const header = await getFormedRequestHeaders()
    const res = await axios.get(`${BASE_URL}/user/self_sign_up_enabled_status`)
    return res.data
}

export const getAllInvites = async () => {
    const header = await getFormedRequestHeaders()
    let res;
   
    try {
        res = await axios.get(`${BASE_URL}/user/invite`, {headers: header});
    } catch (err) {
        res = {
            data: {
                message: 'ERROR_GETTING_ALL_INVITES',
                error: err,
            },
        };
    }  
   
    return res.data
}
export const deleteInvite = async (email) => {
    const header = await getFormedRequestHeaders()
    let res;
   
    try {
        res = await axios.delete(`${BASE_URL}/user/invite/${email}`, {headers: header});
    } catch (err) {
        res = {
            data: {
                error: err,
            },
        };
    }  
   
    return res
}

import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import classes from './index.module.css'
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Legend, PieChart, Pie, ResponsiveContainer, Tooltip
} from 'recharts';
import { map } from 'lodash';
import { DASHBOARD_PIE_CHART_COLORS } from '../../../constants/strings';
import v4 from 'uuid/v4'

const DistributionAcrossGraphs = (props) => {
    const { barChartData = [], allProjectsDetails = [], barChartDataForFiles=[], yAxisValues = []} = props;
    // useEffect(() => {
        
    // }, []);

    const roundOffTickValue = tickValue => {
        if (tickValue > 1000) {
            const simplifiedTickValue = tickValue / 1000;
            if (simplifiedTickValue % 1 == 0) {
                return simplifiedTickValue + "k";
            }
            return simplifiedTickValue.toFixed(1) + "k"
        }
        return tickValue;
    }

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        value,
        index,
        payload
      }) => {
        const RADIAN = Math.PI / 180;
        // eslint-disable-next-line
        const radius = 25 + innerRadius + (outerRadius - innerRadius);
        // eslint-disable-next-line
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        // eslint-disable-next-line
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
          <text
            x={x}
            y={y}
            fill="#000"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
          >
            <tspan x={x} style={{fontWeight: 'bold', display: 'block'}}>{payload.name}</tspan>
            <tspan x={x} dy="1.2em">Projects: {value}</tspan>
          </text>
        );
      }

    const charBarContainerWidth = barChartDataForFiles.length > 4 ? (barChartDataForFiles.length * 70) + 100 : '100%';
    return (
        <React.Fragment>
            {
                props.userDetails?.resourceActions?.dashboard?.view_data_distribution_across_zones ||

                    props.userDetails?.resourceActions?.dashboard?.view_project_distribution_across_zones ||

                    props.userDetails?.resourceActions?.dashboard?.view_file_size_distribution_across_projects ?

                    <>
                        <div className={classes.graphsBlock} >
                            <Grid container spacing={3}>
                                {/* {
                                    props.userDetails?.resourceActions?.dashboard?.view_data_distribution_across_zones ?
                                        <Grid item xs={3}>
                                            <div className={classes.graphsBlockTiles}>
                                                <h2 id='cvb-adminDashboard-dataDistributionBarChart-heading'>Data Distribution Across Zones</h2>
                                                <ResponsiveContainer height={400} width={"100%"}>
                                                    <BarChart
                                                        width={350}
                                                        height={350}
                                                        data={barChartData}

                                                        maxBarSize={50}
                                                        margin={{
                                                            top: 25, right: 50, left: -40, bottom: 5,
                                                        }}

                                                    >
                                                        <XAxis dataKey="name" />
                                                        <YAxis allowDecimals={false} />
                                                        <Tooltip />
                                                        <Legend />
                                                        <Bar dataKey="count" fill="#8884d8">
                                                            {
                                                                map(barChartData, (entry, index) => {
                                                                    return <Cell id={`cvb-adminDashboard-dataDistribution-barChart-${index}`} fill={DASHBOARD_PIE_CHART_COLORS[index % DASHBOARD_PIE_CHART_COLORS.length]} />;
                                                                })
                                                            }
                                                        </Bar>
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </Grid> : null
                                } */}

                                {
                                    props.userDetails?.resourceActions?.dashboard?.view_project_distribution_across_zones ?
                                        <Grid item xs={6} >
                                            <div className={classes.graphsBlockTiles}>
                                                <h2 id='cvb-adminDashboard-projectDistributionPieChart-heading'>Projects Distribution Across Zones</h2>
                                                <ResponsiveContainer height={400} width={"100%"}>
                                                    <PieChart width={300} height={300}>
                                                        <Legend left={50} iconType="circle" />
                                                        <Tooltip />
                                                        <Pie data={allProjectsDetails} nameKey="name" dataKey="count" cy={120} innerRadius={0} outerRadius={90} label={renderCustomizedLabel} >

                                                            {
                                                                map(allProjectsDetails, (entry, index) => {
                                                                    return <Cell id={`cvb-adminDashboard-projectDistributionPieChart-${index}`} fill={DASHBOARD_PIE_CHART_COLORS[index % DASHBOARD_PIE_CHART_COLORS.length]} />
                                                                })
                                                            }
                                                        </Pie>
                                                    </PieChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </Grid>

                                        : null
                                }

                                {
                                    props.userDetails?.resourceActions?.dashboard?.view_file_size_distribution_across_projects ?
                                        <Grid item xs={6}>
                                            <div className={classes.graphsBlockTiles}>
                                                <h2 id='cvb-adminDashboard-fileDistributionBarChart-heading'>File Size Distribution By Project</h2>
                                                <div className={`${classes.fileSizeDistBar}`}>
                                                    <p className={classes.yAxisLabel}>File Size (Mbytes)</p>
                                                    <ResponsiveContainer height={400} width={charBarContainerWidth}>
                                                        <BarChart
                                                            width={350}
                                                            height={350}
                                                            data={barChartDataForFiles}

                                                            maxBarSize={50}
                                                            margin={{
                                                                top: 25, right: 0, left: 40, bottom: 5,
                                                            }}
                                                        >
                                                            <XAxis dataKey="name" />
                                                            <YAxis type="number" tickFormatter={tick => roundOffTickValue(tick)} />
                                                            <Tooltip />
                                                            {

                                                                yAxisValues.map((item, index) => {
                                                                    return <Bar key={v4()} id={`cvb-adminDashboard-fileDistributionBarChart-${index}`} stackId="a" dataKey={item} fill={DASHBOARD_PIE_CHART_COLORS[index % DASHBOARD_PIE_CHART_COLORS.length]} />
                                                                })


                                                            }

                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                </div>
                                            </div>
                                        </Grid> : null
                                }
                            </Grid>
                        </div>


                    </> : null
            }
        </React.Fragment>
    )
}

export default DistributionAcrossGraphs;
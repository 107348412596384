import ZoneRequestAccess from './ZoneRequestAccess'
import { connect } from 'react-redux'
import { updateSnackBar, updatePullCount } from '../../../redux/actions/globalActions'
import { updateUserDetails } from '../../../redux/actions/userActions'
import{
    setOnboardingFirstName,
    setOnboardingLastName,
    setUsersOrganizationName,
    setScientificBackground,
    setOnboardingEducation,
    setOtherHighestEducation,
    setResearcherGoals
}from '../../../redux/actions/userOnboardActions';

const mapStateToProps = state => ({
    userDetails: state.user.userDetails
})

const mapDispatchToProps = dispatch => ({
    updateSnackBar: async (message, type) => dispatch(updateSnackBar(message, type)),
    updatePullCount: () => dispatch(updatePullCount()),
    updateUserDetails: payload => dispatch(updateUserDetails(payload)),

    setOnboardingFirstName: (value) => dispatch(setOnboardingFirstName(value)),
    setOnboardingLastName: (value) => dispatch(setOnboardingLastName(value)),
    setUsersOrganizationName: (value) => { dispatch(setUsersOrganizationName(value))},
    setOnboardingEducation: (value) => dispatch(setOnboardingEducation(value)),
    setScientificBackground: payload => dispatch(setScientificBackground(payload)),
    setOtherHighestEducation: payload => dispatch(setOtherHighestEducation(payload)),  
    setResearcherGoals: payload => dispatch(setResearcherGoals(payload)), 
    
})

export default connect(mapStateToProps, mapDispatchToProps)(ZoneRequestAccess);
import DataContributorAgreement from './DataContributorAgreement'
import { connect } from 'react-redux'
import { setDCAStage, setDCAAllFormFields, setCurrentDCAFormStage, setDCASignature, setAcknowledgmentSignature } from '../../../redux/actions/dcaActions'
import { setProjectDetailsFields } from '../../../redux/actions/projectDetailsActions'
import { setProjectInstitutionalFields } from '../../../redux/actions/projectPrimaryInstitutionalActions'
import { setProjectAdministratorFields } from '../../../redux/actions/projectAdminActions'
import { setProjectPIFields } from '../../../redux/actions/projectPIActions'
import { setZone3UsersFields } from '../../../redux/actions/zone3UsersActions'
import { setAuthorizedDataSubmittersFields } from '../../../redux/actions/authorizeSubmitterActions'
import { updateSnackBar } from '../../../redux/actions/globalActions'
import { setInstitutionalCertAdminName } from '../../../redux/actions/institutionalCertActions'
const mapStateToProps = state => {
    return {
        dcaStage: state.dca.dcaStage,
        dcaAgreementDate: state.dca.dcaAgreementDate,
        dcaClientName: state.dca.dcaClientName,
        dcaContributorName: state.dca.dcaContributorName,
        dataContributorAgreementSignature: state.dca.dataContributorAgreementSignature,
        
        contributorSigningByText: state.dca.contributorSigningByText,
        contributorSigningName: state.dca.contributorSigningName,
        contributorSigningTitle: state.dca.contributorSigningTitle,
        contributorSigningDate: state.dca.contributorSigningDate,
    
        dcaReviewDate : state.dca.dcaReviewDate,
        dcaReviewApproveText : state.dca.dcaReviewApproveText,
        dcaReviewByText: state.dca.dcaReviewByText,
        dcaReviewerName: state.dca.dcaReviewerName,
        dcaReviewerTitle: state.dca.dcaReviewerTitle,
        dcaReviewerSignDate: state.dca.dcaReviewerSignDate,
        currentDCAFormStage: state.dca.currentDCAFormStage,
        acknowledgmentSignature: state.dca.acknowledgmentSignature,
        instituteSignature: state.dca.instituteSignature,
        
        projectDetailsProjectName : state.projectDetails.projectDetailsProjectName,
        projectDetailsSynopsisText : state.projectDetails.projectDetailsSynopsisText,
        projectDetailsDesignatedZone : state.projectDetails.projectDetailsDesignatedZone,
        projectDetailsIsDatasetDownloadable : state.projectDetails.projectDetailsIsDatasetDownloadable,
        projectDetailsTypeOfData : state.projectDetails.projectDetailsTypeOfData,
        projectDetailsObjectiveOfStudy : state.projectDetails.projectDetailsObjectiveOfStudy,
        projectDetailsDescriptionOfStudy : state.projectDetails.projectDetailsDescriptionOfStudy,
        projectDetailsDataDescription : state.projectDetails.projectDetailsDataDescription,
        projectDetailsStudyDesign : state.projectDetails.projectDetailsStudyDesign,
        projectDetailsNumberOfGrants : state.projectDetails.projectDetailsNumberOfGrants,
        projectDetailsDurationOfMonths : state.projectDetails.projectDetailsDurationOfMonths,
        projectDetailsLocationOfStudy : state.projectDetails.projectDetailsLocationOfStudy,
        isResearcherAllowed: state.projectDetails.isResearcherAllowed,
        projectDetailsRestrictionOfUSeText: state.projectDetails.projectDetailsRestrictionOfUSeText,
        projectDetailsOtherTypeDataText: state.projectDetails.projectDetailsOtherTypeDataText,
        projectDetailsAckStatement: state.projectDetails.projectDetailsAckStatement,
        projectDetailsIRBApprovalNumber: state.projectDetails.projectDetailsIRBApprovalNumber,
        projectDetailsTotalSizeOfData: state.projectDetails.projectDetailsTotalSizeOfData,
        projectDetailsPublications: state.projectDetails.projectDetailsPublications,
        projectDetailsIRBNumberFile: state.projectDetails.projectDetailsIRBNumberFile,
        projectDetailsLogoFile: state.projectDetails.projectDetailsLogoFile,
        logoS3Key: state.projectDetails.logoS3Key,
        irbApprovalCopyS3Key: state.projectDetails.irbApprovalCopyS3Key,

        ppiFirstName: state.ppi.ppiFirstName,
        ppiLastName: state.ppi.ppiLastName,
        ppiMiddleName: state.ppi.ppiMiddleName,
        ppiCredentials: state.ppi.ppiCredentials,
        ppiInstituteName: state.ppi.ppiInstituteName,
        ppiInstituteEmail: state.ppi.ppiInstituteEmail,
        ppiInstituteStreetAddress: state.ppi.ppiInstituteStreetAddress,
        ppiInstituteCity: state.ppi.ppiInstituteCity,
        ppiInstituteState: state.ppi.ppiInstituteState,
        ppiInstituteZipCode: state.ppi.ppiInstituteZipCode,
        ppiInstituteCountry: state.ppi.ppiInstituteCountry,

        projectAdminFirstName: state.projectAdmin.projectAdminFirstName,
        projectAdminLastName: state.projectAdmin.projectAdminLastName,
        projectAdminMiddleName: state.projectAdmin.projectAdminMiddleName,
        projectAdminCredentials: state.projectAdmin.projectAdminCredentials,
        projectAdminInstituteName: state.projectAdmin.projectAdminInstituteName,
        projectAdminInstituteEmail: state.projectAdmin.projectAdminInstituteEmail,
        projectAdminInstituteStreetAddress: state.projectAdmin.projectAdminInstituteStreetAddress,
        projectAdminInstituteCity: state.projectAdmin.projectAdminInstituteCity,
        projectAdminInstituteState: state.projectAdmin.projectAdminInstituteState,
        projectAdminInstituteZipCode: state.projectAdmin.projectAdminInstituteZipCode,
        projectAdminInstituteCountry: state.projectAdmin.projectAdminInstituteCountry,

        projectPIFirstName: state.projectPI.projectPIFirstName,
        projectPILastName: state.projectPI.projectPILastName,
        projectPIMiddleName: state.projectPI.projectPIMiddleName,
        projectPICredentials: state.projectPI.projectPICredentials,
        projectPIAffiliation: state.projectPI.projectPIAffiliation,
        projectPIInstituteEmail: state.projectPI.projectPIInstituteEmail,

        dcaAllFormFields: state.dca.dcaAllFormFields,
        adminName: state.institutionalCert.adminName,

        zone3Users: state.zone3AuthorizedUsers.zone3Users,
        authorizedSubmitters: state.authorizeDatasetSubmitter.authorizedSubmitters,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setDCAStage: async (value) => await dispatch(setDCAStage(value)),
        setDCAAllFormFields: async (value) => await dispatch(setDCAAllFormFields(value)),
        setProjectDetailsFields: async (value) => await dispatch(setProjectDetailsFields(value)),
        setProjectInstitutionalFields: async (value) => await dispatch(setProjectInstitutionalFields(value)),   
        setProjectAdministratorFields: async (value) => await dispatch(setProjectAdministratorFields(value)), 
        setProjectPIFields: async (value) => await dispatch(setProjectPIFields(value)), 
        setZone3UsersFields: async (value) => await dispatch(setZone3UsersFields(value)), 
        setAuthorizedDataSubmittersFields: async (value) => await dispatch(setAuthorizedDataSubmittersFields(value)), 
        updateSnackBar: async (message, type) => await dispatch(updateSnackBar(message, type)), 
        setCurrentDCAFormStage: async (value) => await dispatch(setCurrentDCAFormStage(value)),
        setInstitutionalCertAdminName: async (value) => await dispatch(setInstitutionalCertAdminName(value)),
        setDCASignature: async (value) => await dispatch(setDCASignature(value)),
        setAcknowledgmentSignature: async (value) => await dispatch(setAcknowledgmentSignature(value)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DataContributorAgreement)
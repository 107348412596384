/**
 *  Imports
 */
 import {connect} from "react-redux";
 import {
	connectRecommendedUserAction,
	declineRecommendedUserAction,
	initRecommendedUsersAction,
	loadMoreRecommendedUsersAction,
} from "../../../redux/actions/communityActions";
import CommunityRecommended from "./CommunityRecommended";

const mapStateToProps = ({community: {recommendedUsers}}) => ({
	recommendedUsers,
});

const mapDispatchToProps = (dispatch) => ({
	initUsers: (recommendedUsers) => dispatch(initRecommendedUsersAction(recommendedUsers)),
	onConnectUser: (recommendationID) => dispatch(connectRecommendedUserAction(recommendationID)),
	onDeclineUser: (recommendationID) => dispatch(declineRecommendedUserAction(recommendationID)),
	loadMoreUsers: (loadedUsers) => dispatch(loadMoreRecommendedUsersAction(loadedUsers)),
});

/**
 *  Exports
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CommunityRecommended);

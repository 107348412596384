import React, { useEffect, useState, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FormControl, InputLabel, NativeSelect, Select, Typography, InputBase, TextField, MenuItem } from '@mui/material';
import { Autocomplete } from '@mui/material';
import classes from './index.module.css'
import moment from 'moment'
import CustomLoader from "../../commons/custom-loader";
import { approveWorkspaceCart, rejectWorkspaceCart } from '../../../api/admin/admin-dashboard'
import ImageVerticalIcon from '../../../assets/icons/MoreOptionsVertical.S-dark.svg';
import MuiPopover from '@mui/material/Popover';
import withStyles from '@mui/styles/withStyles';
import Modal from '../../commons/modal'
import {formatBytes} from '../../../utils/file-size-formatter';
import ImageWorkspace from '../../../assets/icons/024-book-1.svg';
import v4 from 'uuid/v4';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {LightTooltip} from "../community-left-menu/CommunityLeftMenu";

const Popover = withStyles(theme => ({
	root: {
		overflowY: 'scroll',
	},
	paper: {
		minWidth: 192,
		right: 'auto !important',
		marginTop: '32px !important',
	}
}))(MuiPopover);


const WorkspaceCartApprovals = ({ BootstrapInput, workspaceCartRequests, userDetails, updateSnackBar, getAllPendingApprovalWorkspaceCartsRequests }) => {
	const [workspaceCarts, setWorkspaceCarts] = useState([]);
	const [showCartFiles, setShowCartFiles] = useState(null);
	const [isLoading, setIsLoading] = useState(false)
	const [selectedInitiatedBy, setSelectedInitiatedBy] = useState(null)
	const [selectedStatus, setSelectedStatus] = useState('Show All')
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedCart, setSelectedCart] = useState({})
	const [viewFilesModal, setViewFilesModal] = useState(false)
	const [isOpenedSelectList, setIsOpenedSelectList] = useState(false)
	useEffect(() => {
		  
		setWorkspaceCarts(workspaceCartRequests)
	}, [workspaceCartRequests]);

	const openPopover = !!anchorEl;


	const renderStatus = status => {
		const statusTextToDisplay = `${status}`.replaceAll('_',' ');
		if (status ?.toLowerCase() === "approved") {
			return <span className={classes.submitText}>{statusTextToDisplay || "-"}</span>
		}
		if (status ?.toLowerCase() === "delivered") {
			return <span className={classes.deliveredBoxText}>{statusTextToDisplay || "-"}</span>
		}
		if (status ?.toLowerCase() === "ready_for_download") {
			return <span className={classes.submitText}>{statusTextToDisplay || "-"}</span>
		}
		if (status ?.toLowerCase() === "rejected") {
			return <span className={classes.rejectedText}>{statusTextToDisplay || "-"}</span>
		}
		if (status ?.toLowerCase() === "submitted_for_approval") {
			return <span className={classes.submittedText}>{statusTextToDisplay || "-"}</span>
		}
		if (status ?.toLowerCase() === "scheduled_for_delivery") {
			return <span className={classes.submitText}>{statusTextToDisplay || "-"}</span>
		}
		
		return <span>{statusTextToDisplay || "-"}</span>
	}

	const filteredRecords = useMemo(() => {
		if (!selectedInitiatedBy && !selectedStatus) {
			return workspaceCarts
		}
		return workspaceCarts.filter(({ initiatedBy, status }) => {
			if (selectedInitiatedBy && selectedStatus) {
				return selectedInitiatedBy?.toLowerCase()?.includes(initiatedBy?.toLowerCase()) && (selectedStatus === 'Show All' || selectedStatus.toLowerCase() === status?.toLowerCase()?.replaceAll('_',' '));
			}
			if (selectedInitiatedBy) {
				return selectedInitiatedBy?.toLowerCase()?.includes(initiatedBy?.toLowerCase());
			}
			if (selectedStatus) {
				return selectedStatus.toLowerCase() === status?.toLowerCase()?.replaceAll('_',' ') || selectedStatus === 'Show All';
			}
		})
	}, [workspaceCarts, selectedStatus, selectedInitiatedBy])

	const allInitiatedUsers = useMemo(() => {
		const users = workspaceCarts.map(({ initiatedBy }) => initiatedBy);
		return [...new Set(users)]
	}, [workspaceCarts])

	if (isLoading) {
		return <div>
			<CustomLoader componentLoader />
		</div>
	}

	const handleDownArrowClick = (event, cart) => {
		setAnchorEl(event.currentTarget);
		setSelectedCart(cart);
	}

	const handleClosePopover = () => {
		setAnchorEl(null);
	}

	const onApproveWorkspaceCart = async () => {
		try{
			const response = await approveWorkspaceCart(selectedCart?.order_id);
			await getAllPendingApprovalWorkspaceCartsRequests(true)
			updateSnackBar(response?.message, 'Success');
		}catch(error){
			console.log(error);
			updateSnackBar(error?.message, 'Error');
		}finally{
			handleClosePopover()
		}
	}

	const onRejectWorkspaceCart = async () => {
		try{
			const response = await rejectWorkspaceCart(selectedCart?.order_id);
			getAllPendingApprovalWorkspaceCartsRequests(true)
			updateSnackBar(response?.message, 'Success');
		}catch(error){
			console.log(error)
			updateSnackBar(error?.message, 'Error');
		}finally{
			handleClosePopover()
		}
	}

	const closeViewFilesModal = () => {
        setViewFilesModal(false);
    }

	const VIEW_FILES_MODAL_PROPS = {
        modalTitle: `${selectedCart?.files?.length} Files Added | ${selectedCart.fileSize}`,
    }

	return <div>
		<Modal
			open={viewFilesModal}
			handleClose={closeViewFilesModal}
			dialogProps={VIEW_FILES_MODAL_PROPS}
			disableBottomHorizontalDivider={true}
		>
			<div className={classes.deactivateBody}>
			{
				selectedCart?.files?.map((file) => {
					return <div key={v4()} className={classes.cardContainer}>
					<div container className={classes.cardContentContainer}>
						<div className={classes.workspaceImageContainer}>
							<img src={ImageWorkspace} className={classes.WorkspaceImage} alt='Workspace' />
						</div>
						<div className={classes.cardContent}>
							<p id={`cvb-adminDashboard-fileName`} className={classes.curiousText}>
								File {file?.name} <br />
								<div className={classes.fileType}>{file?.type}</div>
							</p>
							
						</div>
						<div className={classes.cardContent}>{formatBytes(file?.size)}</div>
					</div>
				</div>
				})
			}
			</div>
		</Modal>
		<div className={classes.selectWrapper} style={{width: '100%', maxWidth: '230px'}}>
			<FormControl style={{ margin: '15px 0' }}>
				<span>Status</span>
				<Select
                  defaultValue={selectedStatus}
                  value={selectedStatus}
                  onOpen={() => setIsOpenedSelectList(true)}
                  onClose={() => setTimeout(() => {
                    setIsOpenedSelectList(false)
                  }, 500)}
                  input={<BootstrapInput />}
                  IconComponent={KeyboardArrowDownIcon}
                  onChange={(e) => { setSelectedStatus(e.target.value) }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  className={classes.select}>
                  {['Show All', 'Approved', 'Submitted For Approval', 'Rejected', 'Delivered', 'Ready For Download', 'Scheduled For Delivery'].map((item, i) => {
                    const content = item?.split('_').join(' ');
                    return (
                        <MenuItem key={i} value={item}>
                          {(!isOpenedSelectList && item === selectedStatus && content.length >= 12) ? (
                              <LightTooltip placement="right" arrow title={content}>
                                <p className={classes.popoverFieldSelectItem}>{content}</p>
                              </LightTooltip>
                          ) : <p className={classes.popoverFieldSelectItem}>{content}</p>}
                        </MenuItem>
                    )
                  })}
                </Select>
			</FormControl>
		</div>
		<div className={classes.selectWrapper} style={{width: '100%', maxWidth: '400px'}}>
			<FormControl style={{ margin: '15px 0' }}>
				<span>Initiated By</span>
				<Autocomplete
					options={allInitiatedUsers}
					getOptionLabel={(option) => option}
					onChange={(e) => { setSelectedInitiatedBy(e.target.innerText) }}
					renderInput={(params) => {
						const { InputLabelProps, InputProps, ...rest } = params;
						return <BootstrapInput style={{ paddingRight: '0px' }} {...params.InputProps} {...rest} />
					}}
				>
				</Autocomplete>
			</FormControl>
		</div>
		<Table className={classes.table}>
			<TableHead>
				<TableRow>
					<TableCell width="15%" style={{ borderLeft: 'none' }}>Initiated By</TableCell>
					<TableCell width="14%">Order ID</TableCell>
					<TableCell width="15%">Workspace</TableCell>
					<TableCell width="10%">Total File Size</TableCell>
					<TableCell width="15%">Submission Date</TableCell>
					<TableCell width="18%">Status</TableCell>
					<TableCell width="13%"></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{
					filteredRecords ?.map((item) => {

						const dayStr = moment.utc(item?.created_at)?.local().format('MM/DD/YYYY');
						const timeStr = moment.utc(item?.created_at)?.local().format('hh:mm A');

						return <TableRow key={item.request_id}>
							<TableCell width="15%" style={{ borderLeft: 'none' }}>{item ?.initiatedBy}</TableCell>
							<TableCell width="14%">{item ?.order_id || "-"}</TableCell>
							<TableCell width="15%">{item ?.workspace_id}</TableCell>
							<TableCell width="10%">{item ?.fileSize}</TableCell>
							<TableCell width="10%">{dayStr} at {timeStr}</TableCell>
							<TableCell width="18%" style={{ borderLeft: 'none' }}>
								{renderStatus(item ?.status)}</TableCell>
							<TableCell width="13%" style={{ borderLeft: 'none' }}>
								<button onClick={() => {setSelectedCart(item); setViewFilesModal(true); }} className={classes.btnView} >View</button>
								
								{
									item?.status === "SUBMITTED_FOR_APPROVAL" && (userDetails?.resourceActions?.dashboard?.approve_workspace_files_download 
										|| userDetails?.resourceActions?.dashboard?.reject_workspace_files_download) ?
									<>
								<img
									src={ImageVerticalIcon}
									alt='verticalIcon'
									onClick={(e) => handleDownArrowClick(e, item)} />
								<Popover
									open={openPopover}
									elevation={3}
									anchorEl={anchorEl}
									onClose={handleClosePopover}
									anchorOrigin={{
										horizontal: 'right',
									}}
									transformOrigin={{
										horizontal: 'right',
									}}
								>
								
									<ul className={classes.dropdownListContainer}>
										{
											userDetails?.resourceActions?.dashboard?.approve_workspace_files_download ? 
											<li id='cvb-workspaceCart-popoverModifyData' onClick={onApproveWorkspaceCart} >
												Approve
											</li> : null
										}
										{
											userDetails?.resourceActions?.dashboard?.reject_workspace_files_download ?
											<li id='cvb-workspaceCart-popoverModifyData' onClick={onRejectWorkspaceCart} >
												Reject
											</li> : null
										}
									</ul>
								
									

								</Popover></> : null } 
							</TableCell>
						</TableRow>
					})
        }
			</TableBody>
		</Table>
	</div >
}

export default WorkspaceCartApprovals;
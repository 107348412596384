import React from 'react'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import Classes from './index.module.css'
import { COUNTRY_LIST, DIF_FORM_COMPLETED_STATUS } from '../../../constants/strings'
import v4 from 'uuid/v4'
import Select from '@mui/material/Select';
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import ErrorText from '../error-text';
import { validateEmailAddress, validatePhoneNumber } from '../../../utils/input-validations'
import DropDown from '../../commons/dropdown'
const DIFPrimaryInvestigator = props => {
    const {
        difPIFirstName,
        difPILastName,
        difPIPhoneNumber,
        difPIEmail,
        difPIDepartmentName,
        difPIStreetAddress,
        difPICountry,
        difPIState,
        difPICity,
        difPIMunicipality,
        difPIZipCode,
        currentDIFFormStage,

        setDifPIFirstName,
        setDifPILastName,
        setDifPIPhoneNumber,
        setDifPIDepartmentName,
        setDifPIEmail,
        setDifPIStreetAddress,
        setDifPICountry,
        setDifPIState,
        setDifPICity,
        setDifPIMunicipality,
        setDifPIZipCode,

        isFormSubmitted
    } = props;

    const onPIFirstNameChange = event => {
        setDifPIFirstName(event.target.value);
    }
    const onLastNameChange = event => {
        setDifPILastName(event.target.value);
    }
    const onEmailChange = event => {
        setDifPIEmail(event.target.value);
    }
    const onPhoneNumberChange = event => {
        setDifPIPhoneNumber(event.target.value);
    }
    const onDepartmentNameChange = event => {
        setDifPIDepartmentName(event.target.value);
    }
    const onStreetAddressChange = event => {
        setDifPIStreetAddress(event.target.value);
    }
    const onCountryChange = value => {
        setDifPICountry(value);
    }
    const onStateChange = event => {
        setDifPIState(event.target.value);
    }
    const onCityChange = event => {
        setDifPICity(event.target.value);
    }
    const onMunicipalityChange = event => {
        setDifPIMunicipality(event.target.value);
    }
    const onZipCodeChange = event => {
        setDifPIZipCode(event.target.value);
    }
    
    const disableInput = currentDIFFormStage === DIF_FORM_COMPLETED_STATUS;

    return (
        <div className={Classes.container} id="cvb-DIFPrimaryInvestigator-mainContainer">
            <p className={Classes.formTitle}>Primary Investigator</p>
            <Grid container direction='row' className={Classes.inputContainers} spacing={3}>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='firstName' id="cvb-DIFPrimaryInvestigator-firstNameLabel">Primary Investigator First Name<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter primary investigator name"
                        value={difPIFirstName}
                        onChange={onPIFirstNameChange}
                        fullWidth
                        id='firstName'
                        name='firstName'
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && isEmpty(difPIFirstName) &&
                        <ErrorText errorMessage="Enter first name" />
                    }
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='lastName' id="cvb-DIFPrimaryInvestigator-lastNameLabel">Last Name<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter last name"
                        value={difPILastName}
                        onChange={onLastNameChange}
                        fullWidth
                        id='lastName'
                        name='lastName'
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && isEmpty(difPILastName) &&
                        <ErrorText errorMessage="Enter last name" />
                    }
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='email' id="cvb-DIFPrimaryInvestigator-emailLabel">Institutional Email<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter email"
                        value={difPIEmail}
                        onChange={onEmailChange}
                        fullWidth
                        id='email'
                        name='email'
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && !validateEmailAddress(difPIEmail) &&
                        <ErrorText errorMessage="Enter valid email" />
                    }
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='phone' id="cvb-DIFPrimaryInvestigator-phoneLabel">Phone<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter phone number"
                        value={difPIPhoneNumber}
                        onChange={onPhoneNumberChange}
                        fullWidth
                        id='phone'
                        name='phone'
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && !validatePhoneNumber(difPIPhoneNumber) &&
                        <ErrorText errorMessage="Enter valid phone number" />
                    }
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='department' id="cvb-DIFPrimaryInvestigator-departmentLabel">Department<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter department name"
                        value={difPIDepartmentName}
                        onChange={onDepartmentNameChange}
                        fullWidth
                        id='department'
                        name='department'
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && isEmpty(difPIDepartmentName) &&
                        <ErrorText errorMessage="Enter department name" />
                    }
                </Grid>
                <Grid item xs={12}>
                    <p id="cvb-DIFPrimaryInvestigator-mailingAddressHeading" className={`${Classes.formTitle} ${Classes.addMarginTop}`}>Mailing Address</p>
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='instituteCountry' id="cvb-DIFPrimaryInvestigator-countryLabel">Country<sup> *</sup></label>
                    <DropDown
                    id='instituteCountry'
                    values={COUNTRY_LIST}
                    handleFilterStateChange={onCountryChange}
                    selectedFilter={difPICountry}
                    placeholder={'Select Country'}
                    disable={disableInput}
                    searchable
                    isClearable
                    />
                    {
                        isFormSubmitted && isEmpty(difPICountry) &&
                        <ErrorText errorMessage="Select country" />
                    }
                </Grid>
                <Grid item xs={10} className={Classes.fieldCont}>
                    <label htmlFor='streetAddress' id="cvb-DIFPrimaryInvestigator-streetAddressLabel">Street Address<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter street address"
                        value={difPIStreetAddress}
                        onChange={onStreetAddressChange}
                        fullWidth
                        id='streetAddress'
                        name='streetAddress'
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && isEmpty(difPIStreetAddress) &&
                        <ErrorText errorMessage="Enter street address" />
                    }
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='state' id="cvb-DIFPrimaryInvestigator-stateLabel">State<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter state"
                        value={difPIState}
                        onChange={onStateChange}
                        fullWidth
                        id='state'
                        name='state'
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && isEmpty(difPIState) &&
                        <ErrorText errorMessage="Enter state" />
                    }
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='city' id="cvb-DIFPrimaryInvestigator-cityLabel">City<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter city"
                        value={difPICity}
                        onChange={onCityChange}
                        fullWidth
                        id='city'
                        name='city'
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && isEmpty(difPICity) &&
                        <ErrorText errorMessage="Enter city" />
                    }
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='municipality' id="cvb-DIFPrimaryInvestigator-municipalityLabel">Municipality</label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter municipality"
                        value={difPIMunicipality}
                        onChange={onMunicipalityChange}
                        fullWidth
                        id='municipality'
                        name='municipality'
                        disabled={disableInput}
                    />
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='zipCode' id="cvb-DIFPrimaryInvestigator-zipCOdeLabel">Zip Code<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter zip code"
                        value={difPIZipCode}
                        onChange={onZipCodeChange}
                        fullWidth
                        id='zipCode'
                        name='zipCode'
                        disabled={disableInput}
                    />
                    {
                        isFormSubmitted && isEmpty(difPIZipCode) &&
                        <ErrorText errorMessage="Enter zip code" />
                    }
                </Grid>
            </Grid>
        </div>
    )
}
DIFPrimaryInvestigator.defaultProps = {
    difPIFirstName: '',
    difPILastName: '',
    difPIPhoneNumber: '',
    difPIEmail: '',
    difPIDepartmentName: '',
    difPIStreetAddress: '',
    difPICountry: '',
    difPIState: '',
    difPICity: '',
    difPIMunicipality: '',
    difPIZipCode: '',
    isFormSubmitted: false,
    currentDIFFormStage: '',
    setDifPIFirstName: () => { },
    setDifPILastName: () => { },
    setDifPIPhoneNumber: () => { },
    setDifPIDepartmentName: () => { },
    setDifPIEmail: () => { },
    setDifPIStreetAddress: () => { },
    setDifPICountry: () => { },
    setDifPIState: () => { },
    setDifPICity: () => { },
    setDifPIMunicipality: () => { },
    setDifPIZipCode: () => { }
}

export default DIFPrimaryInvestigator;

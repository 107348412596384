import React, { useEffect, useState } from 'react'
import Classes from './index.module.css'
import Grid from '@mui/material/Grid'
import { navigate } from '@reach/router';
import BackButtonArrow from '../../../assets/icons/left_arrow.png'
import findIndex from 'lodash/findIndex'
import { getAllDIFRequest } from '../../../api/dif-form-api';
import { find } from 'lodash';
import EnabledTickIcon from '../../../assets/images/enabled_icon.png'
import get from 'lodash/get'
import strokesBG from '../../../assets/images/strokes-bg.png'
const DataInventoryFormStatus = props => {
    const {
        id,
        setDCAStage = ()=>{ },
        setDifStage = ()=>{ }
    } = props;
    const [currentDIFDetails, setCurrentDIFDetails] = useState({});
    const [dcaRequestLoader, setDCARequestLoader] = useState(false);
    const formType = get(props.location, 'state.type', '');
    const loadDCARequestData = async () => {
        try {
            setDCARequestLoader(true);
            const res = await getAllDIFRequest();
            if (res) {
                const details = find(res, (requestData) => requestData['request_id'] === id);
                setCurrentDIFDetails(details);
                setDCARequestLoader(false);
            }
        } catch (error) {
            console.log('error in loading dca request');
            setDCARequestLoader(false);
        }
    }
    useEffect(() => {
        loadDCARequestData();
    }, [])
    const backHandle = () => {
        navigate('/my-stuff')
    }
    const OnViewDIFClick = () => {
        if(formType === 'dca'){
            setDCAStage(1);
            navigate(`/my-stuff/data-contribute-agreement/${id}`)
        }
        if(formType === 'dif'){
            setDifStage(1);
            navigate(`/my-stuff/data-inventory-form/${id}`)
        }
    }
    const formTitle = formType === 'dif' ? 'Data Inventory Form' : 'Data Contributor Agreement';
    const formDesc = formType === 'dif' ? `Your Data Inventory Form has been successfully submitted for review.
    You will be notified once it is published on BRAINCommons.` :  `Your Data Contributor Agreement has been successfully submitted for review.
    You will be notified once it is published on BRAINCommons.`;
    const buttonText = formType === 'dif' ? 'View Data Inventory Form' : 'View Data Contributor Agreement';
    return (
        <div className={Classes.container} id="cvb-DIFStatus-formContainer">
        <div className={Classes.divStatusFormContainer}>
                <Grid item xs={9} lg={6} md={5} className={Classes.backroundImageContainer}>
                    <img className={Classes.strokesImage} src={strokesBG} alt='strokes background' />
                </Grid>
            <p onClick={backHandle} className={Classes.backButton} id="cvb-DIFStatus-backButton">
                <span><img alt='back button arrow' src={BackButtonArrow} /></span>
                <span className={Classes.backButtonText}>Back</span>
            </p>
            <p id="cvb-DIFStatus-formTitle" className={Classes.formTitle}>{formTitle}</p>
            <div className={Classes.subContainer}>
                <Grid container>
                    <Grid item xs={6}>
                        <p className={Classes.congratsText} id="cvb-DIFStatus-congratulationsMessage">Congratulations</p>
                        <p id="cvb-DIFStatus-subInfoDescription" className={Classes.subInfoDetailsText}> 
                        {formDesc}
                        </p>
                        <button id="cvb-DIFStatus-infoBackButton"  className={`transparent-button ${Classes.viewDIVButton}`} onClick={OnViewDIFClick}>{buttonText}</button>
    {/* **************************Commented this code due to the status on steppers are wrong. We might need this code in future ***************************/}

                        {/* <div className={Classes.stepperWrapper}>
                            <div className={Classes.stepContainer}>
                                <img alt='enable icon' className={Classes.enabledTickIcon} src={EnabledTickIcon}/>
                                <div className={Classes.stepIcon}>
                                    <span>1</span>
                                    <div className={Classes.stepConnector}>

                                    </div>
                                </div>
                                <div className={Classes.stepTitlesContainer}>
                                    <p className={Classes.stepTitle}>Data Uploaded</p>
                                    <p className={Classes.stepSubTitle}>We have received your data</p>
                                </div>

                            </div>
                            <div className={Classes.stepContainer}>
                                <img alt='enable icon' className={Classes.enabledTickIcon} src={EnabledTickIcon}/>
                                <div className={Classes.stepIcon}>
                                    <span>2</span>
                                    <div className={Classes.stepConnector}>

                                    </div>
                                </div>
                                <div className={Classes.stepTitlesContainer}>
                                    <p className={Classes.stepTitle}>Awaiting Approval</p>
                                    <p className={Classes.stepSubTitle}>We’ll review and publish your data</p>
                                </div>

                            </div>
                            <div className={Classes.stepContainer}>
                                <img alt='enable icon' className={Classes.enabledTickIcon} src={EnabledTickIcon}/>
                                <div className={Classes.stepIcon}>
                                    <span>3</span>
                                    <div className={Classes.stepConnector}>

                                    </div>
                                </div>
                                <div className={Classes.stepTitlesContainer}>
                                    <p className={Classes.stepTitle}>Data Published</p>
                                    <p className={Classes.stepSubTitle}>Your data is now part of BRAINCommons</p>
                                </div>
                            </div>
                        </div> */}
                    </Grid>
                </Grid>
            </div>
        </div>
        </div>
    )
}

export default DataInventoryFormStatus
import { SET_OPENED_NOTIFICATION } from '../action-types'

const initialState = null

const setNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_OPENED_NOTIFICATION: {
            return {
                ...state,
                name: action.payload
            }
        }
        default: return state;
    }
}

export default setNotificationReducer;
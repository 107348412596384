import { cloneDeep, findIndex } from 'lodash'
import { SET_ALL_WORKSPACES, SET_LOADED_WORKSPACES, SET_ALL_WORKSPACES_CARTS } from '../action-types'

const initialState = {
   allWorkspaces: [],
   loadedWorkspaces: [],
   allWorkspacesCarts: {}
}

const workspaceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_WORKSPACES: {
            return {
                ...state,
                allWorkspaces: action.payload
            }
        }
        case SET_ALL_WORKSPACES_CARTS: {
            return {
                ...state,
                allWorkspacesCarts: action.payload
            }
        }
        case SET_LOADED_WORKSPACES: {
            let loadedWP = cloneDeep(state.loadedWorkspaces);
            const currentWorkspaceIndex = findIndex(loadedWP, (wp)=> wp.workspace_id === action.payload.workspace_id);
            if(currentWorkspaceIndex > -1){
                loadedWP[currentWorkspaceIndex] = action.payload;
            }else{
                loadedWP.push(action.payload)
            }
            return {
                ...state,
                loadedWorkspaces: loadedWP
            }
        }
        default: return state
    }
}

export default workspaceReducer;
import React, { useEffect } from 'react'
import { Box, Modal} from '@mui/material';

import classes from './index.module.css'

const WhiteListDeletionModal = props => {
  const {openDelete, setOpenDelete, currentInvite, handleDelete } = props

    return (
          <Modal
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          >
              <Box className={classes.modal}>
                <div className={classes.iconClose} onClick={() => setOpenDelete(false)}>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 0.700012L11.3 0L6 5.29999L0.700012 0L0 0.700012L5.29999 6L0 11.3L0.700012 12L6 6.70001L11.3 12L12 11.3L6.70001 6L12 0.700012Z" fill="#414141"/>
                  </svg>
                </div>
                  <h2 className={classes.modalTitle}>Delete User</h2>
                  <div className={classes.flexContainer}>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="64" height="64" rx="32" fill="#F5F5F5"/>
                      <path d="M44.9015 40.8503C44.1991 39.2785 43.1799 37.8508 41.9005 36.6467C40.625 35.4392 39.114 34.4764 37.451 33.8116C37.4361 33.8045 37.4212 33.801 37.4063 33.794C39.726 32.2111 41.234 29.6327 41.234 26.7236C41.234 21.9045 37.101 18 32 18C26.899 18 22.766 21.9045 22.766 26.7236C22.766 29.6327 24.274 32.2111 26.5937 33.7975C26.5788 33.8045 26.5639 33.808 26.549 33.8151C24.8809 34.4799 23.3841 35.4332 22.0995 36.6503C20.8213 37.8553 19.8022 39.2827 19.0985 40.8538C18.4071 42.3918 18.0343 44.0409 18.0001 45.7116C17.9991 45.7491 18.0061 45.7864 18.0206 45.8214C18.0351 45.8564 18.0569 45.8882 18.0847 45.9151C18.1124 45.942 18.1456 45.9633 18.1822 45.9779C18.2189 45.9925 18.2582 46 18.298 46H20.532C20.6958 46 20.8261 45.8769 20.8299 45.7256C20.9043 43.01 22.0586 40.4668 24.099 38.5392C26.2101 36.5447 29.0139 35.4472 32 35.4472C34.9862 35.4472 37.7899 36.5447 39.901 38.5392C41.9414 40.4668 43.0957 43.01 43.1701 45.7256C43.1739 45.8804 43.3042 46 43.468 46H45.702C45.7418 46 45.7811 45.9925 45.8178 45.9779C45.8544 45.9633 45.8876 45.942 45.9153 45.9151C45.9431 45.8882 45.9649 45.8564 45.9794 45.8214C45.9939 45.7864 46.0009 45.7491 45.9999 45.7116C45.9627 44.0301 45.5941 42.3945 44.9015 40.8503ZM32 32.7739C30.291 32.7739 28.6825 32.1442 27.4724 31.001C26.2623 29.8578 25.5958 28.3382 25.5958 26.7236C25.5958 25.109 26.2623 23.5894 27.4724 22.4462C28.6825 21.303 30.291 20.6734 32 20.6734C33.709 20.6734 35.3175 21.303 36.5276 22.4462C37.7377 23.5894 38.4042 25.109 38.4042 26.7236C38.4042 28.3382 37.7377 29.8578 36.5276 31.001C35.3175 32.1442 33.709 32.7739 32 32.7739Z" fill="#E7EAF2"/>
                    </svg>
                    <div className={classes.nameWrapper}>
                      <p className={classes.name}>{currentInvite?.first_name  || 'name'} {currentInvite?.last_name  || 'surname'}</p>
                      <p className={classes.email}>{currentInvite?.email_id}</p>
                    </div>
                  </div>
                  <p>Are you sure that you want to delete this user?</p>
                  <div className={classes.buttonContainer}>
                    <button className={`transparent-button ${classes.cancelBtn}`} onClick={() => {setOpenDelete(false)}}>Cancel</button>
                    <button className={`solid-button ${classes.deleteBtn}`} onClick={() => {handleDelete(currentInvite)}}>Delete</button>
                  </div>
              </Box>
          </Modal>
    )
}

export default WhiteListDeletionModal

/**
 *  External Imports
 */

/**
 *  Internal Imports
 */
 import {communityMappedAPI} from "../../api/community-api";
 import {COMMUNITY_URLS, COMMUNITY_CONNECTIONS_PARENT_ROUTE} from "../../constants/urls";

import {
	COMMUNITY_CONNECTIONS_USERS_INIT,
	COMMUNITY_CONNECTIONS_USERS_LOAD_MORE,
	COMMUNITY_GLOBAL_SEARCH_USER_CONNECT,
	COMMUNITY_GLOBAL_SEARCH_USERS_INIT,
	COMMUNITY_INCOMING_USER_ACCEPT,
	COMMUNITY_INCOMING_USER_DECLINE,
	COMMUNITY_INCOMING_USERS_INIT,
	COMMUNITY_INCOMING_USERS_LOAD_MORE,
	COMMUNITY_LEFT_MENU_ACTIVE_ITEM_CHANGE,
	COMMUNITY_LEFT_MENU_ITEM_CLICK,
	COMMUNITY_LEFT_MENU_ITEM_COUNTERS_CHANGE,
	COMMUNITY_LEFT_MENU_ITEM_TOGGLE,
	COMMUNITY_MESSAGES_CHAT_MSG_ADD_RECIPIENT,
	COMMUNITY_MESSAGES_CHAT_MSG_ADD_SENDER,
	COMMUNITY_MESSAGES_CHAT_MSG_INIT,
	COMMUNITY_MESSAGES_CHAT_MSG_RESET,
	COMMUNITY_MESSAGES_CHAT_RECIPIENT_ID_INIT,
	COMMUNITY_MESSAGES_CHAT_USERS_LIST_INIT,
	COMMUNITY_MESSAGES_CHAT_WS_CLOSE,
	COMMUNITY_MESSAGES_CHAT_WS_INIT,
	COMMUNITY_PENDING_USERS_INIT,
	COMMUNITY_PENDING_USERS_LOAD_MORE,
	COMMUNITY_RECOMMENDED_USER_CONNECT,
	COMMUNITY_RECOMMENDED_USER_DECLINE,
	COMMUNITY_RECOMMENDED_USERS_INIT,
	COMMUNITY_RECOMMENDED_USERS_LOAD_MORE,
	COMMUNITY_CONNECT_MODAL_OPEN,
	COMMUNITY_CONNECT_MODAL_CLOSE,
	COMMUNITY_CONNECT_MODAL_RESET,
	COMMUNITY_SUCCESS_MODAL_OPEN,
	COMMUNITY_SUCCESS_MODAL_CLOSE,
	COMMUNITY_SUCCESS_MODAL_RESET,
	COMMUNITY_GLOBAL_SEARCH_MOLECULAR_CLASSES_INIT,
	COMMUNITY_GLOBAL_SEARCH_THERAPEUTIC_AREAS_INIT,
	COMMUNITY_GLOBAL_SEARCH_POPOVER_TOGGLE,
	COMMUNITY_REDIRECT,
	COMMUNITY_REDIRECT_RESET,
	COMMUNITY_GLOBAL_SEARCH_QUERY_INIT,
	COMMUNITY_FULLSCREEN_TOGGLE,
	COMMUNITY_DELETE_MODAL_OPEN,
	COMMUNITY_DELETE_MODAL_CLOSE,
	COMMUNITY_DELETE_MODAL_RESET,
	COMMUNITY_CONNECTIONS_USERS_DELETE,
	COMMUNITY_CONSTELLATION_LABELS_INIT,
	COMMUNITY_CONSTELLATION_LABELS_SELECT,
	COMMUNITY_CONSTELLATION_LABELS_SELECT_CLEAR,
	COMMUNITY_CONSTELLATION_RECOMMENDED_USERS_SET,
	COMMUNITY_CONSTELLATION_CONNECTED_USERS_SET,
	COMMUNITY_CONSTELLATION_CONNECTED_USER_DELETE,
	COMMUNITY_CONSTELLATION_RECOMMENDED_USER_CONNECT,
	COMMUNITY_CONSTELLATION_RECOMMENDED_USER_DECLINE,
	COMMUNITY_CONSTELLATION_TOOLTIP_SET,
	COMMUNITY_CONSTELLATION_TOOLTIP_OPEN,
	COMMUNITY_CONSTELLATION_INCOMING_USER_ACCEPT,
	COMMUNITY_CONSTELLATION_INCOMING_USER_DECLINE,
	COMMUNITY_CONSTELLATION_LABEL_NODE_HOVER,
	COMMUNITY_CONSTELLATION_LABEL_NODE_UPDATE,
	COMMUNITY_CONSTELLATION_LABEL_NODE_RESET,
	COMMUNITY_CONSTELLATION_GRAPH_READY,
	COMMUNITY_CONSTELLATION_NODE_PARAMS_SET,
	COMMUNITY_CONSTELLATION_NODE_PARAMS_RESET
} from "../action-types";


/**
 *  Actions
 */
/**
 *  Left Menu Items Actions
 */
const changeLeftMenuActiveItemAction = (uniqKey) => ({
	type: COMMUNITY_LEFT_MENU_ACTIVE_ITEM_CHANGE,
	payload: {
		uniqKey,
	},
});

const changeLeftMenuItemCountersAction = (counters) => ({
	type: COMMUNITY_LEFT_MENU_ITEM_COUNTERS_CHANGE,
	payload: {
		counters,
	},
});

const clickLeftMenuItemAction = (uniqKey, fn) => ({
	type: COMMUNITY_LEFT_MENU_ITEM_CLICK,
	payload: {
		uniqKey,
		fn,
	},
});

const toggleLeftMenuItemAction = (uniqKey) => ({
	type: COMMUNITY_LEFT_MENU_ITEM_TOGGLE,
	payload: {
		uniqKey,
	},
});

/**
 *  Recommended Users Actions
 */
const initRecommendedUsersAction = (recommendedUsers) => ({
	type: COMMUNITY_RECOMMENDED_USERS_INIT,
	payload: {
		recommendedUsers,
	},
});

const connectRecommendedUserAction = (recommendationID) => ({
	type: COMMUNITY_RECOMMENDED_USER_CONNECT,
	payload: {
		recommendationID,
	},
});

const declineRecommendedUserAction = (recommendationID) => ({
	type: COMMUNITY_RECOMMENDED_USER_DECLINE,
	payload: {
		recommendationID,
	},
});

const loadMoreRecommendedUsersAction = (loadedUsers) => ({
	type: COMMUNITY_RECOMMENDED_USERS_LOAD_MORE,
	payload: {
		loadedUsers
	},
});

/**
 *  Connection Users Actions
 */
const initConnectionUsersAction = (connectionUsers) => ({
	type: COMMUNITY_CONNECTIONS_USERS_INIT,
	payload: {
		connectionUsers,
	},
});

const loadMoreConnectedUsersAction = (loadedUsers) => ({
	type: COMMUNITY_CONNECTIONS_USERS_LOAD_MORE,
	payload: {
		loadedUsers,
	},
});

const deleteConnectedUsersAction = (deletedUserId) => ({
	type: COMMUNITY_CONNECTIONS_USERS_DELETE,
	payload: {
		deletedUserId,
	},
});

const deleteConstellationConnectedUserAction = (connectionId) => ({
	type: COMMUNITY_CONSTELLATION_CONNECTED_USER_DELETE,
	payload: {
		connectionId,
	}
})

/**
 *  Incoming Users Actions
 */
const initIncomingUsersAction = (incomingUsers) => ({
	type: COMMUNITY_INCOMING_USERS_INIT,
	payload: {
		incomingUsers,
	},
});

const acceptIncomingUserByIDAction = (userID) => ({
	type: COMMUNITY_INCOMING_USER_ACCEPT,
	payload: {
		userID,
	},
});

const declineIncomingUserByIDAction = (userID) => ({
	type: COMMUNITY_INCOMING_USER_DECLINE,
	payload: {
		userID,
	},
});

const loadMoreIncomingUsersAction = (loadedUsers) => ({
	type: COMMUNITY_INCOMING_USERS_LOAD_MORE,
	payload: {
		loadedUsers,
	},
});

/**
 *  Pending Users Actions
 */
const initPendingUsersAction = (pendingUsers) => ({
	type: COMMUNITY_PENDING_USERS_INIT,
	payload: {
		pendingUsers,
	},
});

const loadMorePendingUsersAction = (loadedUsers) => ({
	type: COMMUNITY_PENDING_USERS_LOAD_MORE,
	payload: {
		loadedUsers,
	},
});

/**
 *  Messages Page Actions
 */
const initMessagesChatWSAction = (ws) => ({
	type: COMMUNITY_MESSAGES_CHAT_WS_INIT,
	payload: {
		ws,
	},
});

const closeMessagesChatWSAction = () => ({
	type: COMMUNITY_MESSAGES_CHAT_WS_CLOSE,
	payload: {},
});

const initMessagesChatUsersListAction = (usersList) => ({
	type: COMMUNITY_MESSAGES_CHAT_USERS_LIST_INIT,
	payload: {
		usersList,
	},
});

const initMessagesChatRecipientIDAction = (id) => ({
	type: COMMUNITY_MESSAGES_CHAT_RECIPIENT_ID_INIT,
	payload: {
		id,
	},
});

const initMessagesChatMsgAction = (messages) => ({
	type: COMMUNITY_MESSAGES_CHAT_MSG_INIT,
	payload: {
		messages,
	},
});

const addRecipientMessagesChatMsgAction = (message) => ({
	type: COMMUNITY_MESSAGES_CHAT_MSG_ADD_RECIPIENT,
	payload: {
		message,
	},
});

const addSenderMessagesChatMsgAction = (message) => ({
	type: COMMUNITY_MESSAGES_CHAT_MSG_ADD_SENDER,
	payload: {
		message,
	},
});

const resetMessagesChatMsgAction = () => ({
	type: COMMUNITY_MESSAGES_CHAT_MSG_RESET,
	payload: {},
});

/**
 *  Global Search Users Actions
 */
const fetchGlobalSearchUsersAction = (cancelToken, searchQuery) => {
	return async dispatch => {
		const {data, isCanceled} = await communityMappedAPI.getMappedGlobalSearchResults(cancelToken, searchQuery);
		if (!isCanceled) {
			dispatch(initCommunityGlobalSearchQueryAction(searchQuery));
			dispatch(initGlobalSearchUsersAction(data));
			dispatch(redirectCommunityAction(`${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.SEARCH_RESULTS}`))
		}
	};
}

const initGlobalSearchUsersAction = (globalSearchUsers) => ({
	type: COMMUNITY_GLOBAL_SEARCH_USERS_INIT,
	payload: {
		globalSearchUsers,
	},
});

const connectGlobalSearchUserAction = (userID) => ({
	type: COMMUNITY_GLOBAL_SEARCH_USER_CONNECT,
	payload: {
		userID,
	},
});

const initGlobalSearchTherapeuticAreasAction = (therapeuticAreas) => ({
	type: COMMUNITY_GLOBAL_SEARCH_THERAPEUTIC_AREAS_INIT,
	payload: {
		therapeuticAreas,
	},
});

const initGlobalSearchMolecularClassesAction = (molecularClasses) => ({
	type: COMMUNITY_GLOBAL_SEARCH_MOLECULAR_CLASSES_INIT,
	payload: {
		molecularClasses,
	},
});

const toggleGlobalSearchPopover = (globalSearchPopoverOpen) => ({
	type: COMMUNITY_GLOBAL_SEARCH_POPOVER_TOGGLE,
	payload: {
		globalSearchPopoverOpen
	},
});

const initCommunityGlobalSearchQueryAction = (query) => ({
	type: COMMUNITY_GLOBAL_SEARCH_QUERY_INIT,
	payload: {
		query,
	},
});

const fetchSortedGlobalSearchUsersAction = (cancelToken, searchQuery, sort, order) => {
	return async dispatch => {
		const {data} = await communityMappedAPI.getMappedGlobalSearchResults(cancelToken, searchQuery, sort, order);
		dispatch(initGlobalSearchUsersAction(data));
	};
}
/**
 *  Community Connect Modal Actions
 */

 const openCommunityConnectModalAction = (dialogConnectData) => ({
	type: COMMUNITY_CONNECT_MODAL_OPEN,
	payload: {
		dialogConnectData
	},
});

const closeCommunityConnectModalAction = () => ({
	type: COMMUNITY_CONNECT_MODAL_CLOSE,
	payload: {},
});

const resetCommunityConnectModalAction = () => ({
	type: COMMUNITY_CONNECT_MODAL_RESET,
	payload: {},
});

/**
 *  Community Success Modal Actions
 */

const openCommunitySuccessModalAction = (dialogSuccessData) => ({
	type: COMMUNITY_SUCCESS_MODAL_OPEN,
	payload: {
		dialogSuccessData
	},
});

const closeCommunitySuccessModalAction = () => ({
	type: COMMUNITY_SUCCESS_MODAL_CLOSE,
	payload: {},
});

const resetCommunitySuccessModalAction = () => ({
	type: COMMUNITY_SUCCESS_MODAL_RESET,
	payload: {},
});

/**
 *  Community Delete Modal Actions
 */

 const openCommunityDeleteModalAction = (dialogDeleteData) => ({
	type: COMMUNITY_DELETE_MODAL_OPEN,
	payload: {
		dialogDeleteData
	},
});

const closeCommunityDeleteModalAction = () => ({
	type: COMMUNITY_DELETE_MODAL_CLOSE,
	payload: {},
});

const resetCommunityDeleteModalAction = () => ({
	type: COMMUNITY_DELETE_MODAL_RESET,
	payload: {},
});

/**
 *  Community View Action
 */

const redirectCommunityAction = (path, pathState = null) => ({
	type: COMMUNITY_REDIRECT,
	payload: {
		path,
		pathState
	},
});

const resetCommunityRedirectAction = () => ({
	type: COMMUNITY_REDIRECT_RESET,
	payload: {},
});

const toggleCommunityFullscreenViewAction = (isOpen) => ({
	type: COMMUNITY_FULLSCREEN_TOGGLE,
	payload: {
		isOpen
	},
});

/**
 *  Constellation View Action
 */

 const initConstellationLabelsAction = (labels) => ({
	type: COMMUNITY_CONSTELLATION_LABELS_INIT,
	payload: {
		labels,
	},
});

const selectConstellationLabelsAction = (label) => ({
	type: COMMUNITY_CONSTELLATION_LABELS_SELECT,
	payload: {
		label,
	},
});

const clearConstellationLabelsSelectionAction = () => ({
	type: COMMUNITY_CONSTELLATION_LABELS_SELECT_CLEAR,
	payload: {},
});

const setConstellationRecommendedUsersAction = (constellationRecommended) => ({
	type: COMMUNITY_CONSTELLATION_RECOMMENDED_USERS_SET,
	payload: {
		constellationRecommended
	},
});

const setConstellationConnectedUsersAction = (constellationConnectedUsers) => ({
	type: COMMUNITY_CONSTELLATION_CONNECTED_USERS_SET,
	payload: {
		constellationConnectedUsers
	}
})

const connectConstellationRecommendedUserAction = (recommendedUserId) => ({
	type: COMMUNITY_CONSTELLATION_RECOMMENDED_USER_CONNECT,
	payload: {
		recommendedUserId
	},
});

const declineConstellationRecommendedUserAction = (recommendedUserId) => ({
	type: COMMUNITY_CONSTELLATION_RECOMMENDED_USER_DECLINE,
	payload: {
		recommendedUserId
	},
});

const acceptConstellationIncomingUserByIDAction = (userID) => ({
	type: COMMUNITY_CONSTELLATION_INCOMING_USER_ACCEPT,
	payload: {
		userID,
	},
});

const declineConstellationIncomingUserByIDAction = (userID) => ({
	type: COMMUNITY_CONSTELLATION_INCOMING_USER_DECLINE,
	payload: {
		userID,
	},
});

const setConstellationTooltipAction = (tooltipParams) => ({
	type: COMMUNITY_CONSTELLATION_TOOLTIP_SET,
	payload: {
		tooltipParams
	},
});

const setNodeParamsAction = (nodeParams) => ({
	type: COMMUNITY_CONSTELLATION_NODE_PARAMS_SET,
	payload: {
		nodeParams
	},
});

const resetNodeParamsAction = () => ({
	type: COMMUNITY_CONSTELLATION_NODE_PARAMS_RESET,
	payload: {}
});

const setConstellationTooltipOpenAction = (isOpen) => ({
	type: COMMUNITY_CONSTELLATION_TOOLTIP_OPEN,
	payload: {
		isOpen
	},
});

const setConstellationLabelNodeHoverAction = (nodeParams) => ({
	type: COMMUNITY_CONSTELLATION_LABEL_NODE_HOVER,
	payload: {
		nodeParams
	},
});

const updateConstellationLabelNodeAction = (nodeParams) => ({
	type: COMMUNITY_CONSTELLATION_LABEL_NODE_UPDATE,
	payload: {
		nodeParams
	},
});

const resetConstellationLabelNodeAction = () => ({
	type: COMMUNITY_CONSTELLATION_LABEL_NODE_RESET,
	payload: {},
});

const updateConstellationGraphReadyAction = (isReady) => ({
	type: COMMUNITY_CONSTELLATION_GRAPH_READY,
	payload: {
		isReady
	},
});

/**
 *  Exports
 */
export {
	// Left Menu Items Actions
	changeLeftMenuActiveItemAction,
	changeLeftMenuItemCountersAction,
	clickLeftMenuItemAction,
	toggleLeftMenuItemAction,
	
	// Recommended Users Actions
	initRecommendedUsersAction,
	connectRecommendedUserAction,
	declineRecommendedUserAction,
	loadMoreRecommendedUsersAction,
	
	// Connection Users Actions
	initConnectionUsersAction,
	loadMoreConnectedUsersAction,
	deleteConnectedUsersAction,
	
	// Incoming Users Actions
	initIncomingUsersAction,
	acceptIncomingUserByIDAction,
	declineIncomingUserByIDAction,
	loadMoreIncomingUsersAction,
	
	// Pending Users Actions
	initPendingUsersAction,
	loadMorePendingUsersAction,
	
	// Messages Page Actions
	initMessagesChatWSAction,
	closeMessagesChatWSAction,
	
	initMessagesChatUsersListAction,
	
	initMessagesChatRecipientIDAction,
	
	initMessagesChatMsgAction,
	addRecipientMessagesChatMsgAction,
	addSenderMessagesChatMsgAction,
	resetMessagesChatMsgAction,
	
	// Global Search Users Actions
	fetchGlobalSearchUsersAction,
	initGlobalSearchUsersAction,
	connectGlobalSearchUserAction,
	initGlobalSearchTherapeuticAreasAction,
	initGlobalSearchMolecularClassesAction,
	toggleGlobalSearchPopover,
	initCommunityGlobalSearchQueryAction,
	fetchSortedGlobalSearchUsersAction,

	// Community Connect Modal Actions
	openCommunityConnectModalAction,
	closeCommunityConnectModalAction,
	resetCommunityConnectModalAction,

	// Community Success Modal Actions
	openCommunitySuccessModalAction,
	closeCommunitySuccessModalAction,
	resetCommunitySuccessModalAction,

	// Community View Action
	redirectCommunityAction,
	resetCommunityRedirectAction,
	toggleCommunityFullscreenViewAction,

	// Community Delete Modal Actions
	openCommunityDeleteModalAction,
	closeCommunityDeleteModalAction,
	resetCommunityDeleteModalAction,

	//Constellation View Action
	initConstellationLabelsAction,
	selectConstellationLabelsAction,
	clearConstellationLabelsSelectionAction,
	setConstellationRecommendedUsersAction,
	connectConstellationRecommendedUserAction,
	declineConstellationRecommendedUserAction,
	deleteConstellationConnectedUserAction,
	setConstellationConnectedUsersAction,
	setConstellationTooltipAction,
	setConstellationTooltipOpenAction,
	acceptConstellationIncomingUserByIDAction,
	declineConstellationIncomingUserByIDAction,
	setConstellationLabelNodeHoverAction,
	updateConstellationLabelNodeAction,
	resetConstellationLabelNodeAction,
	updateConstellationGraphReadyAction,
	setNodeParamsAction,
	resetNodeParamsAction
};

import DataContributeAgreementStage from './DataContributeAgreementStage'
import { connect } from 'react-redux'
import { setDCAAgreementDate, setDCAClientName, setDCAContributorName, setContributorSigningName,
    setContributorSigningByText, setContributorSigningTitle, setContributorSigningDate, setDcaReviewDate,
    setDcaReviewApproveText, setDcaReviewByText, setDcaReviewerName, setDcaReviewerTitle, setDcaReviewerSignDate,
    setDCASignature
} from '../../../redux/actions/dcaActions'
const mapStateToProps = state => {
    return {
        dcaAgreementDate: state.dca.dcaAgreementDate,
        dcaClientName: state.dca.dcaClientName,
        dcaContributorName: state.dca.dcaContributorName,
        dataContributorAgreementSignature: state.dca.dataContributorAgreementSignature,
        contributorSigningByText: state.dca.contributorSigningByText,
        contributorSigningName: state.dca.contributorSigningName,
        contributorSigningTitle: state.dca.contributorSigningTitle,
        contributorSigningDate: state.dca.contributorSigningDate,

        dcaReviewDate : state.dca.dcaReviewDate,
        dcaReviewApproveText : state.dca.dcaReviewApproveText,
        dcaReviewByText: state.dca.dcaReviewByText,
        dcaReviewerName: state.dca.dcaReviewerName,
        dcaReviewerTitle: state.dca.dcaReviewerTitle,
        dcaReviewerSignDate: state.dca.dcaReviewerSignDate,
        currentDCAFormStage: state.dca.currentDCAFormStage
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setDCAAgreementDate: async (value) => await dispatch(setDCAAgreementDate(value)),
        setDCAClientName: async (value) => await dispatch(setDCAClientName(value)),
        setDCAContributorName: async (value) => await dispatch(setDCAContributorName(value)),

        setContributorSigningName: async (value) => await dispatch(setContributorSigningName(value)),
        setContributorSigningByText: async (value) => await dispatch(setContributorSigningByText(value)),
        setContributorSigningTitle: async (value) => await dispatch(setContributorSigningTitle(value)),
        setContributorSigningDate: async (value) => await dispatch(setContributorSigningDate(value)),
        
        setDcaReviewDate: async (value) => await dispatch(setDcaReviewDate(value)),
        setDcaReviewApproveText: async (value) => await dispatch(setDcaReviewApproveText(value)),
        setDcaReviewByText: async (value) => await dispatch(setDcaReviewByText(value)),
        setDcaReviewerName: async (value) => await dispatch(setDcaReviewerName(value)),
        setDcaReviewerTitle: async (value) => await dispatch(setDcaReviewerTitle(value)),
        setDcaReviewerSignDate: async (value) => await dispatch(setDcaReviewerSignDate(value)),
        setDCASignature: async (value) => await dispatch(setDCASignature(value)),

    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DataContributeAgreementStage)
import { SET_DIF_DATA_SHARING_CONTRIBUTE_NEW_DATA_SET, SET_DIF_DATA_SHARING_DATASET_NAME, 
    SET_DIF_DATA_SHARING_PERMISSION_TO_SHARE, SET_DIF_DATA_SHARING_IRB_NUMBER, 
    SET_DIF_DATA_SHARING_DOCUMENT_FILE, SET_DIF_DATA_SHARING_IS_PROTECTED_DATA,
    SET_DIF_DATA_SHARING_DE_IDENTIFY, SET_DATA_SHARING_FORM_DATA, RESET_DATA_SHARING_FORM_DATA,
    SET_DIF_DATA_SHARING_ASSOCIATED_EMAIL
} from '../action-types'
    
export const setDifDataSharingContributeToNewDataset = (value) => {
    return {
        type: SET_DIF_DATA_SHARING_CONTRIBUTE_NEW_DATA_SET,
        payload: value
    }
}
export const setDifDataSharingDatasetName = (value) => {
    return {
        type: SET_DIF_DATA_SHARING_DATASET_NAME,
        payload: value
    }
}
export const setDifDataSharingPermissionToShareData = (value) => {
    return {
        type: SET_DIF_DATA_SHARING_PERMISSION_TO_SHARE,
        payload: value
    }
}
export const setDifDataSharingIRBNumber = (value) => {
    return {
        type: SET_DIF_DATA_SHARING_IRB_NUMBER,
        payload: value
    }
}
export const setDifDataSharingDocumentFile = (value) => {
    return {
        type: SET_DIF_DATA_SHARING_DOCUMENT_FILE,
        payload: value
    }
}
export const setDifDataSharingIsProtectedData = (value) => {
    return {
        type: SET_DIF_DATA_SHARING_IS_PROTECTED_DATA,
        payload: value
    }
}
export const setDifDataSharingDataDeIdentify = (value) => {
    return {
        type: SET_DIF_DATA_SHARING_DE_IDENTIFY,
        payload: value
    }
}
export const setDifDataSharingAssociatedEmail = (value) => {
    return {
        type: SET_DIF_DATA_SHARING_ASSOCIATED_EMAIL,
        payload: value
    }
}
export const setDataSharingData = (value) => {
    return {
        type: SET_DATA_SHARING_FORM_DATA,
        payload: value
    }
}
export const resetDataSharingData = () => {
    return {
        type: RESET_DATA_SHARING_FORM_DATA,
    }
}

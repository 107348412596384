import SearchResults from './SearchResults'
import { connect } from 'react-redux'
import {
    getExploreDataTotalCount,
} from "../../../redux/actions/exploreDataActions";
import { updateSnackBar } from '../../../redux/actions/globalActions'
const mapStateToProps = state => {
    return {
        exploreData: state.exploreData.exploreData,
        totalCount: state.exploreData.totalCount,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getExploreDataTotalCount: async (exploreFilters, excludeFromExploration) => await dispatch(getExploreDataTotalCount(exploreFilters, excludeFromExploration)),
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
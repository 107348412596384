import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 } from 'uuid';
import OverviewBarChart from './cohort-statistics-bar-chart/StatisticsChart';
import { makeStyles } from '@mui/styles';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  getFilters,
  getStatisticsForVariables,
  getStudiesForCohort,
} from '../../../../service/CohortsService';
import { string, node } from 'prop-types';
import { BarChart, RemoveCircleOutline } from '@mui/icons-material';
import { alpha, Box } from '@mui/system';

let initialID = v4();

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSelect-outlined': {
      borderRadius: theme.spacing(1),
      background: 'white',
      '& .MuiFilledInput-root': {
        background: 'white',
      },
    },
    '& .MuiInputLabel-outlined': {
      top: -5,
    },
    '& .MuiInputLabel-shrink': {
      top: -10,
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      padding: 0,
      // width: 250,
    },
  },
};

const RANGE_BINS = {
  age_at_enrollment: {
    values: {
      2: '0-2 years',
      6: '3-6 years',
      13: '7-13 years',
      19: '14-19 years',

      45: '20-45 years',
      65: '46-65 years',
      80: '66-80 years',
      1000: 'over 80 years',
    },
    unit: ' years',
  },
  weight: {
    values: {
      40: '<40kg',
      50: '40kg-50kg',
      60: '50kg-60kg',
      70: '60kg-70kg',
      90: '80-90kg',
      100: '90kg-100kg',
      110: '100kg-110kg',
      1000: '>110kg',
    },
    unit: 'kg',
  },
  height: {
    values: {
      2: '<150cm',
      6: '151cm-160cm',
      13: '161cm-170cm',
      19: '171cm-180cm',

      45: '181cm-190cm',
      65: '>190cm',
    },
    unit: 'cm',
  },
};

// TODO  store and access chart list from redux and local storage
const CohortStatistics = ({ cohort }) => {
  const [chartList, setChartList] = useState([{ id: initialID }]);

  const onAddChart = useCallback(() => {
    const newId = v4();
    setChartList([...chartList, { id: newId }]);
  }, [chartList]);

  const onChartDelete = useCallback((id) => {
    setChartList((prevList) => prevList.filter((chart) => chart.id !== id));
  }, []);

  const onSetChartData = useCallback(({ id, data, variables }) => {
    // console.log(id, 'id');
    setChartList((prevList) =>
      prevList.map((chart) => {
        if (chart.id === id) {
          return {
            ...chart,
            data,
            variables,
          };
        }

        return chart;
      })
    );
    // console.log('chartList', chartList);
  }, []);

  return (
    <Stack py={2} spacing={2}>
      {chartList.map((chart, index) => (
        <CohortChart
          cohort={cohort}
          key={chart.id}
          indexPlus={index + 1}
          chart={chart}
          disabled={index === 0 && chartList.length === 1}
          onChartDelete={onChartDelete}
          onSetChartData={onSetChartData}
        />
      ))}
      <Button variant="outlined" onClick={onAddChart} fullWidth>
        + Add a chart
      </Button>
    </Stack>
  );
};

const CohortChart = ({
  cohort,
  chart,
  indexPlus,
  onChartDelete,
  onSetChartData,
  disabled,
}) => {
  const [firstVar, setFirstVar] = useState('');
  const [secondVar, setSecondVar] = useState('');
  const [filters, setFilters] = useState([]);
  const [secondVariableDisabled, setSecondVariableDisabled] = useState(true);

  const onVariableChange = useCallback((event) => {
    const variable = event.target.value;
    setFirstVar(variable);
    setSecondVariableDisabled(false);
  }, []);

  const onSecondVariableChange = useCallback((event) => {
    setSecondVar(event.target.value);
  }, []);

  const stacked = useMemo(() => {
    if (!!firstVar && !!secondVar) return true;
    return false;
  }, [firstVar, secondVar]);

  useEffect(() => {
    getFilters().then((filters) => setFilters(filters));
  }, []);

  useEffect(() => {
    if (firstVar || secondVar) {
      getStatisticsForVariables(cohort, firstVar, secondVar).then((data) => {
        if (onSetChartData)
          onSetChartData({
            id: chart.id,
            data,
            variables: { firstVar, secondVar },
          });
      });
    }
  }, [firstVar, secondVar]);

  return (
    <Paper
      rounded
      elevation={0}
      sx={(theme) => ({
        borderRadius: 2,
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(3),
      })}
    >
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3">{`Chart ${indexPlus}`}</Typography>
          <IconButton
            aria-label="delete"
            disabled={disabled}
            onClick={() => {
              if (onChartDelete) onChartDelete(chart.id);
            }}
          >
            <RemoveCircleOutline />
          </IconButton>
        </Stack>

        <Divider />

        <Stack spacing={2}>
          <Stack direction="row" pt={1} spacing={2}>
            <FilterSelect
              size="small"
              label="First variable"
              labelId="first-variable-label"
              value={firstVar}
              onChange={onVariableChange}
            >
              {!!filters && filters.length > 0
                ? filters.map((filter) => (
                    <MenuItem key={filter.value} value={filter.value}>
                      <Typography textTransform="">{filter.label}</Typography>
                    </MenuItem>
                  ))
                : 'No variables found'}
            </FilterSelect>

            <FilterSelect
              label="Second variable (optional)"
              labelId="first-variable-label-2"
              value={secondVar}
              onChange={onSecondVariableChange}
              disabled={secondVariableDisabled}
            >
              {!!filters && filters.length > 0
                ? filters.map((filter) => (
                    <MenuItem key={filter.value} value={filter.value}>
                      <Typography textTransform=""> {filter.label}</Typography>
                    </MenuItem>
                  ))
                : 'No variables found'}
            </FilterSelect>
          </Stack>

          {chart.data && Array.isArray(chart.data) && chart.data.length ? (
            <OverviewBarChart
              key={chart.id}
              chartData={chart.data}
              xKey="key"
              valKey="count"
              xLabel={filters.find((f) => f.value === firstVar).label}
              isStacked={stacked}
              valBins={secondVar && RANGE_BINS[secondVar]?.values}
              xUnit={firstVar && RANGE_BINS[firstVar]?.unit}
              field={secondVar ? secondVar : firstVar}
            />
          ) : (
            <Stack justifyContent="center" alignItems="center" spacing={1}>
              <Box
                px={1.5}
                py={1}
                borderRadius="50%"
                sx={(theme) => ({
                  backgroundColor: alpha(theme.palette.primary.main, 0.2),
                })}
              >
                <BarChart color="primary" />
              </Box>
              <Typography>Select at least one variable to see chart</Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

const FilterSelect = ({ labelId, id, label, children, ...props }) => {
  const classes = useStyles();

  return (
    <FormControl classes={{ root: classes.root }} variant="outlined" fullWidth>
      <InputLabel id={labelId} classes={classes.label}>
        {label}
      </InputLabel>
      <Select
        size="small"
        label={label}
        labelId={labelId}
        input={<OutlinedInput classes={{ root: classes.root }} />}
        IconComponent={(props) => <ExpandMoreRoundedIcon {...props} />}
        MenuProps={MenuProps}
        {...props}
      >
        {children}
      </Select>
    </FormControl>
  );
};

FilterSelect.propTypes = {
  id: string,
  labelId: string,
  label: string.isRequired,
  children: node,
};

const memoisedCohortStatistics = memo(CohortStatistics);
export default memoisedCohortStatistics;

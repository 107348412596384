import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import {
  createDataRequest,
  getPendingZone3RequestData,
  getSubmittedDUP,
} from '../../../../../api/profile-api';
import { difference } from 'lodash';
import { sendGAEvents } from '../../../../../api/generic-api';
import Modal from '../../../../commons/modal';
import { ZONE2_REQUEST_MODAL_TEXT } from '../../../../../constants/feedback';

export const ZoneTypes = {
  ZONE3: 'Zone 3',
  ZONE2: 'Zone 2',
  ZONE1: 'Zone 1',
};

function joinText(text) {
  return text.split(' ').join('').toLowerCase();
}

const RequestAccessDialog = ({
  openRequestAccessModal,
  projectZone,
  onCloseRequestModal,
  updateSnackBar,
  userDetails,
  studyDetail,
}) => {
  const [zone3Pending, setZone3Pending] = useState('');
  const [DUPRequest, setDUPRequest] = useState(false);
  const [zone3ButtonDisabled, setZone3ButtonDisabled] = useState(false);
  const selectedProjects = studyDetail ? [studyDetail.studyName] : [];

  const getSubmittedDUPRequest = async () => {
    try {
      const requests = await getSubmittedDUP(userDetails?.['username']);
      if (requests?.isDupSubmitted) {
        setDUPRequest(requests);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createZoneRequest = async (type = '') => {
    try {
      let body = {
        dup_request_id: DUPRequest.requestId,
      };
      setZone3ButtonDisabled(true);
      if (type === ZoneTypes.ZONE3) {
        body.projects = selectedProjects;
      }
      const reqArg = joinText(type);
      const requests = await createDataRequest(reqArg, body);
      if (type === ZoneTypes.ZONE3) {
        let remainingProjects = difference(
          zone3Pending.projects,
          selectedProjects
        );
        let zone3LatestData = {
          is_user_allow_to_request_zone3_access: remainingProjects?.length
            ? true
            : false,
          projects: remainingProjects,
        };
        setZone3Pending(zone3LatestData);
        onCloseRequestModal();
      } else {
        onCloseRequestModal();
      }
      updateSnackBar(
        `Request for ${type ? type : ZoneTypes.ZONE2} Access completed.`,
        'Success'
      );
    } catch (error) {
      const errorMessage = error?.response?.data?.error
        ? error?.response?.data?.error
        : `Unable to request ${type ? type : ZoneTypes.ZONE2} Access`;
      updateSnackBar(`${errorMessage}`, 'Error');
    } finally {
      setZone3ButtonDisabled(false);
    }
  };

  const getPendingZone3Request = async () => {
    try {
      const requests = await getPendingZone3RequestData();
      setZone3Pending(requests);
    } catch (error) {
      console.log(error);
    }
  };

  const requestZoneAccess = (zone) => {
    switch (zone) {
      case ZoneTypes.ZONE2: {
        sendGAEvents(
          'request_zone2_access',
          'zone2_access',
          'request zone 2 access'
        );
        break;
      }
      case ZoneTypes.ZONE3: {
        sendGAEvents(
          'request_zone3_access',
          'zone3_access',
          'request zone 3 access'
        );
        break;
      }
      default:
        break;
    }
    if (DUPRequest?.isDupSubmitted) {
      return createZoneRequest(zone);
    }
    if (!DUPRequest?.isDupSubmitted && zone === ZoneTypes.ZONE2) {
      navigate('/my-profile/request-access', {
        state: { from: 'myProfile', type: ZoneTypes.ZONE2 },
      });
    } else if (
      !DUPRequest?.isDupSubmitted &&
      zone === ZoneTypes.ZONE3 &&
      selectedProjects?.length
    ) {
      navigate('/my-profile/request-access', {
        state: {
          from: 'myProfile',
          type: ZoneTypes.ZONE3,
          projects: selectedProjects,
        },
      });
    }

  };

  const REQUEST_ACCESS_MODAL_PROPS = {
    modalTitle: `Request ${projectZone} Project`,
    positiveButtonText: 'Request Access',
    negativeButtonText: 'Cancel',
    positiveButtonAction: () => requestZoneAccess(projectZone),
    negativeButtonAction: onCloseRequestModal,
    popupButtonDisable: zone3ButtonDisabled,
  };

  useEffect(() => {
    getPendingZone3Request(projectZone);
    getSubmittedDUPRequest();
  }, []);

  return (
    <Modal
      open={openRequestAccessModal}
      handleClose={onCloseRequestModal}
      dialogProps={REQUEST_ACCESS_MODAL_PROPS}
      disableTitleDivider={true}
    >
      {projectZone === ZoneTypes.ZONE2 && (
        <div style={{ height: "100px" }}>
          {ZONE2_REQUEST_MODAL_TEXT}
        </div>
      )}
      {projectZone === ZoneTypes.ZONE3 && (
        <div style={{ height: '350px' }}>
          <label>
            Project:{' '}
            <sup>{studyDetail.studyName ? `${studyDetail.projectId}` : ''}</sup>
          </label>
        </div>
      )}
    </Modal>
  );
};

export default RequestAccessDialog;

RequestAccessDialog.propTypes = {
  requestAccessModal: PropTypes.bool,
  closeRequestAccessModal: PropTypes.func,
};

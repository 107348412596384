import { SET_BANNER_COOKIE, SET_ON_OFF_COOKIE } from '../action-types'

export const setBannerCookie = (value) => {
    return {
        type: SET_BANNER_COOKIE,
        payload: value
    }
}
export const setOnOffCookie = (value) =>{
    return {
        type: SET_ON_OFF_COOKIE,
        payload: value
    }
}
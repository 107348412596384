import ApolloClient from 'apollo-boost';
import { BASE_URL } from '../../constants/google-login-urls'
import {
    TRANSACTION_LOG,
    TRANSACTION_LOG_COUNT,
    TRANSACTION_LOG_COUNT_WITH_FILTER,
    TRANSACTION_LOG_WITH_FILTER
} from '../graphql-query-helpers/graphql'
import { getCognitoIdToken } from '../utilityMethods';


export const getTransactionLogs = async (first, offset, orderBy, tableSearch) => {
    const token = await getCognitoIdToken();
    const apolloClient = new ApolloClient({
        uri: `${BASE_URL}/data_access/search/pg`,
        request: (operation) => {
            operation.setContext({
                fetchOptions: {
                    credentials: "omit"
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
        }
    })
    const response = await apolloClient.query({
        query: tableSearch ? TRANSACTION_LOG_WITH_FILTER : TRANSACTION_LOG,
        variables: { first: first, offset: offset, orderBy: orderBy, projectId: tableSearch }
    });
    return response?.data || new Error("Unable to get transaction logs")
}

export const getTransactionLogsCount = async (tableSearch) => {
    const token = await getCognitoIdToken();
    const apolloClient = new ApolloClient({
        uri: `${BASE_URL}/data_access/search/pg`,
        request: (operation) => {
            operation.setContext({
                fetchOptions: {
                    credentials: "omit"
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
        }
    })
    const response = await apolloClient.query({
        query: tableSearch ? TRANSACTION_LOG_COUNT_WITH_FILTER : TRANSACTION_LOG_COUNT,
        variables: { projectId: tableSearch }
    });
    return response?.data || new Error("Unable to get transaction logs count")
}
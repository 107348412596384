import axios from 'axios'
import {env} from '../env'
import get from 'lodash/get'
import { UPDATE_DATA_USE_POLICY } from './endpoints'
import { getCognitoIdToken } from './utilityMethods';
import { uploadFileToS3 } from '../utils/aws-sdk-helper'
import { BASE_URL } from '../constants/google-login-urls'
import { Auth } from 'aws-amplify'
const APP_BASE_URL = `${env.REACT_APP_BASE_API_PATH}`


export const updateDataUsePolicy = async body => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const res = await axios.post(UPDATE_DATA_USE_POLICY + "dup", body, { headers: header });
    return res?.data || [];
}

export const updateUserRole = async (body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const res = await axios.post(`${BASE_URL}/iam/userrole`, body, { headers: header });
    return res?.data || [];
}

export const removeUserRoleRequest = async (body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const res = await axios.put(`${BASE_URL}/iam/userrole`, body, { headers: header });
    return res?.data || [];
}

export const getPendingZone3RequestData = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const res = await axios.get(UPDATE_DATA_USE_POLICY + "pending_zone3_requests", { headers: header });
    return res?.data || [];
}

export const getUserDeactivationState = async (userSub='') => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    let url = `${BASE_URL}/iam/user/deactivate/eligibility`;
    if(userSub){
        url = url +  `/${userSub}`;
    }
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const deactivateUserAccount = async (body, userSub) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    let url = `${BASE_URL}/iam/user/deactivate`;
    if(userSub){
        url = url +  `/${userSub}`;
    }
    const res = await axios.put(url, body, { headers: header });
    return res?.data || null
}

export const activateUserAccount = async (userSub) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    let url = `${BASE_URL}/iam/user/activate/${userSub}`;
    const res = await axios.put(url, {}, { headers: header });
    return res?.data || null
}

export const getDeactivationCode = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
    };
    const url = `${APP_BASE_URL}/user/deactivate/code`;
    const res = await axios.get(url, { headers: header });
    return res?.data || null
}

export const validateDeactivationCode = async (body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${APP_BASE_URL}/user/deactivate/code`;
    const res = await axios.put(url, body, { headers: header });
    return res?.data || null
}

export const submitDUP = async body => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const res = await axios.post(UPDATE_DATA_USE_POLICY + "dup", body, { headers: header });
    return res?.data || [];
}

export const getSubmittedDUP = async (userSub='') => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    let sub = userSub;
    if (!userSub) {
        const userValues = await Auth.currentUserInfo();
        sub = userValues?.attributes?.sub;
    }
    const res = await axios.get(UPDATE_DATA_USE_POLICY + "dup/" + sub, { headers: header });
    return res?.data || [];
}


export const createDataRequest = async (zone,body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const res = await axios.post(UPDATE_DATA_USE_POLICY + "request/" + zone, body, { headers: header });
}

export const getDataRequestStatus = async (zone, userSub='') => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    let sub = userSub;
    if (!userSub) {
        const userValues = await Auth.currentUserInfo();
        sub = userValues?.attributes?.sub;
    }
    const res = await axios.get(UPDATE_DATA_USE_POLICY + "request/" + zone + "/" + sub, { headers: header });
    return res?.data || [];
}

export const getUserProfile = async id => {
    const token = await getCognitoIdToken();
    let data = {};
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/iam/user/${id}`;
    const res = await axios.get(url, { headers: header });
    if (res?.data) {
        data['user_details'] = res.data;
    }
    return data || [];
}

export const getUserPermissions = async (id) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    let url = `${BASE_URL}/iam/user/permission`;
    if (id) {
        url = `${BASE_URL}/iam/user/${id}/permission`;
    }
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const getUserDocumentURLForDownload = async (userSub = '', type) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    let sub = userSub;
    if (!userSub) {
        const userValues = await Auth.currentUserInfo();
        sub = userValues?.attributes?.sub;
    }
    const url = `${APP_BASE_URL}/user/document-download-url?user_sub=${sub}&doc_type=${type}`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const getAllCertificatesURLForDownload = async (userSub = '') => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${APP_BASE_URL}/user/certificate/${userSub}/BRAINCommons%20Privacy,%20Data%20Protection%20and%20Security%20Training.pdf`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const updateUserProfile = async (id, body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    body['user_sub'] = id;
    const url = `${BASE_URL}/iam/user`;
    const res = await axios.put(url, body, { headers: header });
}

export const acceptTnc = async (id) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/iam/user/accept/tnc`;
    const res = await axios.put(url, null, { headers: header });
}

export const acceptPrivacyPolicy = async (id) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/iam/user/accept/privacy_policy`;
    const res = await axios.put(url, null, { headers: header });
}


export const getDataUsePolicy = async (userEmail = '') => {
    const token = await getCognitoIdToken();
    let email = userEmail;
    if (!userEmail) {
        const userValues = await Auth.currentUserInfo();
        email = userValues?.attributes?.email;
    }
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const res = await axios.get(UPDATE_DATA_USE_POLICY + "get_data_access_requests/" + email, { headers: header });
    return res?.data || [];
}

export const uploadProfilePicture = async file => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
    };
    const url = `${env.REACT_APP_BASE_API_PATH}/user/upload_profile_image_url`;
    const res = await axios.get(url, { headers: header });
    if (res?.data?.url) {
        const response = uploadFileToS3(file, res.data);
    }
}

export const getProfilePicture = async (userSub = '') => {
    const token = await getCognitoIdToken();
    let sub = userSub;
    if (!userSub) {
        const userValues = await Auth.currentUserInfo();
        sub = userValues?.attributes?.sub;
    }
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
    };
    const url = `${env.REACT_APP_BASE_API_PATH}/user/get_profile_image_url/${sub}`;
    const res = await axios.get(url, { headers: header });
    return res?.data || null;
}

export const getNotifications = async (exclusiveStartKey = "") => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
    };
    const url = `${BASE_URL}/svc/notification?limit=10&exclusive_start_key=${exclusiveStartKey}`;
    const res = await axios.get(url, { headers: header });
    return res?.data || null
}

export const getOrganizationList = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Content-Type': 'multipart/form-data',
    };
    const url = `${BASE_URL}/iam/organization?page_number=-1&page_size=-1`;
    const res = await axios.get(url, { headers: header });
    return res?.data || []
}

export const getDesignationList = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Content-Type': 'multipart/form-data',
    };
    const url = `${BASE_URL}/iam/designation?page_number=-1&page_size=-1`;
    const res = await axios.get(url, { headers: header });
    return res?.data || []
}
import React, { useState, useEffect, useMemo } from 'react'
import classes from './index.module.css'
import SectionHeader from '../../commons/section-header'
import DataModelGraph from '../../controls/data-model-graph'
import DataModelImage from '../../../assets/images/data-model-bg.png'
import SearchIcon from '../../../assets/icons/search-icon.svg';
import OutlinedInput from '@mui/material/OutlinedInput';
import DataModelTableIcons from '../../../assets/icons/ic_administrative.svg'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VersionIcon from '../../../assets/icons/version_blue.svg'
import ListViewIcon from '../../../assets/icons/list_view.svg'
import ListViewIconWhite from '../../../assets/icons/list_view_white.svg'
import TreeViewIcon from '../../../assets/icons/tree.svg'
import TreeViewIconWhite from '../../../assets/icons/tree_white.svg'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '../../../assets/icons/down-arrow.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dataFileIcon from '../../../assets/icons/data-file.svg'
import closeIcon from '../../../assets/icons/close-s-dark.svg'
import Grid from '@mui/material/Grid'
import v4 from 'uuid/v4'
import Modal from '../../commons/modal'
import { getDataModelSchema, getDownloadFile } from '../../../api/data-model-api'
import ImgBackWard from '../../../assets/icons/backwards.svg'
import ImgForward from '../../../assets/icons/forward.svg'
import ImgFastForward from '../../../assets/icons/fast_farward.svg'
import DownArrow from '../../../assets/icons/W-DropDown.XS.svg'
import ImgReverse from '../../../assets/icons/reverse_forward.svg'
import MuiPopover from '@mui/material/Popover';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { HEADER_NAV_ITEMS, PAGES_DEFINITIONS, STRUCTURE_ITEMS } from '../../../constants/strings'
import { getRecentUpdates } from '../../../api/recent-updates-api'
import {getAccessibleProjects, getStructuredDataModel} from '../../../api/upload-data-api'
import DropDown from '../../commons/dropdown';
import uniqBy from 'lodash/uniqBy'
import { sendGAEvents } from '../../../api/generic-api'
import isEmpty from 'lodash/isEmpty'
import CustomLoader from '../../commons/custom-loader';

var randomColor = require('randomcolor');

const Popover = withStyles(theme => ({
    root: {
        overflowY: 'scroll',
    },
    paper: {
        minWidth: 260,
        right: 10,
        left: 'auto !important',
        top: '200px !important',
        boxShadow: '0px 2px 6px #4141411F;',
        border: '1px solid #DEDEDE',
        borderRadius: 0,
        position: 'absolute'
    }
}))(MuiPopover);



export default function DataModel() {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [constructedGraphData, setConstructedGraphData] = useState({})
    const [selectedDataMode, setSelectedDataMode] = useState(1);
    const [tableData, setTableData] = useState({})
    const [showPropertiesModal, setShowPropertiesModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemCollection, setItemCollection] = useState([])
    const [searchText, setSearchText] = useState('')
    const [showResultsAnyway, setShowResultsAnyway] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [recentSearchHistory, setRecentSearchHistory] = useState([])
    const [zoomValue, setZoomValue] = useState(0)
    const [recentUpdates, setRecentUpdates] = useState([])

    const [dataModelType, setDataModelType] = useState("ALL")
    const [availableProjects, setAvailableProjects] = useState([])
    const [currentViewingProject, setCurrentViewingProject] = useState(null)

    const [currentlyExpandedItem, setCurrentlyExpandedItem] = useState(null)

    const [isLoadingDataModel, setIsLoadingDataModel] = useState(false)

    const openPopover = Boolean(anchorEl);

    const getSearchData = (graphData) => {
        let pointingIndex = 0;
        let graphPoints = [];
        const getNodePerLevel = (item, parentID) => {
            const pointKeys = Object.keys(item)
            const points = pointKeys.map((element, index) => {
                return {
                    label: element,
                    ID: index + pointingIndex,
                    parentID: parentID,
                    isVisible: item[element]?.isVisible || false,
                    subGroups: item[element]?.subGroups,
                    children: item[element]?.children?.length || 0,
                    isCategory: item[element]?.isCategory || false,
                    isDefault: item[element]?.isDefault || false,
                    headerColor: item[element]?.headerColor,
                    backgroundShade: item[element]?.backgroundShade,
                    availableCount: item[element]?.availableCount,
                    description: item[element]?.description || '',
                    nodeProperties: item[element]?.nodeProperties || [],
                    displayTitle: item[element]?.displayTitle,
                    shouldBeHidden: !!item[element]?.shouldBeHidden
                }
            })
            pointingIndex = pointingIndex + points.length
            graphPoints = [...graphPoints, ...points]
            for (let point of points) {
                const pointItems = item[point?.label].children || []
                for (let pointItem of pointItems) {
                    getNodePerLevel(pointItem, point.ID)
                }
            }
        }
        getNodePerLevel(graphData, null);
        setItemCollection(graphPoints);
    }

    useEffect(() => {
        if (!isEmpty(constructedGraphData)) {
            getSearchData(constructedGraphData);
        }
    }, [constructedGraphData])
    const sectionHeaderProps = {
        title: "Data Model",
        pageDefinition: PAGES_DEFINITIONS['data-model'],
    }

    const formatLabel = label => {
        return label.split('_').join(' ')
    }

    const handleClosePopover = () => {
        setAnchorEl(null);
    }

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const shouldBeHiddenInModal = key => {
        return key === "project" || key === "program" ||
            key === "case" || key === "study"
    }


    const generateGraphData = async () => {
        try {

            if (modalData) {

                setIsLoadingDataModel(true)
                let structuredDataModelValue = null;

                if (dataModelType === "PARTIAL" && currentViewingProject) {
                    structuredDataModelValue = await getStructuredDataModel(currentViewingProject);
                }

                const keys = Object.keys(modalData).filter(item => !item.startsWith('_'))
                const currentCategories = {}
                for (let key of keys) {

                    if (structuredDataModelValue) {
                        const matchedElement = Object.keys(structuredDataModelValue).find(item => item === `_${key}_count`);
                        if (!matchedElement || structuredDataModelValue[matchedElement] === 0) {
                            continue;
                        }
                    }

                    let category = modalData[key].category
                    const group = modalData[key]?.links?.[0]?.subgroup
                    const uniqColor = randomColor({ luminosity: 'bright' });
                    if (category) {
                        if (currentCategories[category]) {
                            currentCategories[category].children.push({
                                [key]: {
                                    children: [],
                                    subGroups: group,
                                    headerColor: currentCategories[category].headerColor,
                                    backgroundShade: currentCategories[category].backgroundShade,
                                    description: modalData[key]?.description,
                                    nodeProperties: Object.keys(modalData[key].properties || {}),
                                    availableCount: structuredDataModelValue?.[`_${key}_count`],
                                    displayTitle: modalData[key]?.title || key,
                                    shouldBeHidden: shouldBeHiddenInModal(key)
                                }
                            })
                        } else {
                            currentCategories[category] = {
                                children: [
                                    {
                                        [key]: {
                                            children: [],
                                            subGroups: group,
                                            headerColor: uniqColor,
                                            backgroundShade: uniqColor + "40",
                                            description: modalData[key]?.description,
                                            nodeProperties: Object.keys(modalData[key].properties || {}),
                                            availableCount: structuredDataModelValue?.[`_${key}_count`],
                                            displayTitle: modalData[key]?.title || key,
                                            shouldBeHidden: shouldBeHiddenInModal(key)
                                        }
                                    }
                                ],
                                isVisible: true,
                                isCategory: true,
                                headerColor: uniqColor,
                                backgroundShade: uniqColor + "40",
                                description: modalData[key]?.description,
                                nodeProperties: Object.keys(modalData[key].properties || {}),
                                displayTitle: modalData[key]?.title || key
                            }
                        }
                    }
                }
                setTableData(currentCategories)
                const mainPathElements = dataModelType === "PARTIAL" ? ['project', 'study', 'case'] : ['program', 'project', 'study', 'case']
                let graphData = {}
                for (let i = mainPathElements.length - 1; i >= 0; i--) {
                    const uniqColor = randomColor({ luminosity: 'bright' });
                    if (i === mainPathElements.length - 1) {
                        graphData[mainPathElements[i]] = {
                            children: [
                                {
                                    ...currentCategories
                                }
                            ],
                            isVisible: true,
                            isDefault: true,
                            headerColor: uniqColor,
                            backgroundShade: uniqColor + "40",
                            availableCount: structuredDataModelValue?.[`_${mainPathElements[i]}_count`],
                            displayTitle: mainPathElements[i]
                        }
                    } else {
                        graphData = {
                            [mainPathElements[i]]: {
                                children: [graphData],
                                isVisible: true,
                                isDefault: true,
                                headerColor: uniqColor,
                                backgroundShade: uniqColor + "40",
                                availableCount: structuredDataModelValue?.[`_${mainPathElements[i]}_count`],
                                displayTitle: mainPathElements[i]
                            }
                        }
                    }
                }
                setConstructedGraphData(graphData)
                setIsLoadingDataModel(false)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        generateGraphData()
    }, [modalData, currentViewingProject, dataModelType]);

    const getOnLoadData = async () => {
        try {
            const resp = await getDataModelSchema();
            setModalData(resp)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getOnLoadData();
    }, []);

    const getChildrenOfTable = category => {
        const childElements = tableData[category]?.children || [];
        return childElements.map(item => {
            return Object.keys(item)?.[0] || undefined;
        }).filter(item => item)
    }


    const onDownloadJSON = async (innerItem) => {
        try {
            if (!isEmpty(innerItem)) {
                await getDownloadFile(innerItem, "json")
            } else {
                await getDownloadFile(selectedItem, "json")
            }
        } catch (e) {
            console.log(e)
        }
    }
    // const onDownloadJSON = (item = selectedItem) => {
    //     const details = getPropertyDetails(item)
    //     let formattedDetails = {}
    //     for (let detail of details) {
    //         let innerProperties = []
    //         if (detail?.anyOf?.length > 0) {
    //             const innerProps = detail.anyOf[0]?.items?.properties || {}
    //             innerProperties = Object.keys(innerProps)
    //         }
    //         if (innerProperties?.length > 0) {
    //             formattedDetails[detail.label] = {}
    //             for (const prop of innerProperties) {
    //                 if (prop === "id") {
    //                     continue;
    //                 }
    //                 formattedDetails[detail.label][prop] = detail.isRequired;
    //             }
    //             continue;
    //         }
    //         formattedDetails[detail.label] = detail.isRequired
    //     }
    //     if (details?.length > 0) {
    //         const data = JSON.stringify(formattedDetails, undefined, 4)
    //         let blob = new Blob([data], { type: 'text/json' });
    //         let e = document.createEvent('MouseEvents');
    //         let a = document.createElement('a')
    //         a.download = item + '_properties.json'
    //         a.href = window.URL.createObjectURL(blob)
    //         a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
    //         e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    //         a.dispatchEvent(e)
    //     }
    // }

    // const convertJsonToTSVFormat = (json) => {
    //     let fields = Object.keys(json[0])
    //     let replacer = function (key, value) { return value === null ? '' : value }
    //     let tsv = json.map(function (row) {
    //         return fields.map(function (fieldName) {
    //             return JSON.stringify(row[fieldName], replacer)
    //         }).join('\t')
    //     })
    //     tsv.unshift(fields.join('\t')) // add header column
    //     tsv = tsv.join('\r\n');
    //     return tsv;
    // }

    const onDownloadTSV = async (innerItem) => {
        try {
            if (!isEmpty(innerItem)) {
                await getDownloadFile(innerItem, "tsv")
            } else {
                await getDownloadFile(selectedItem, "tsv")
            }
        } catch (e) {
            console.log(e)
        }
    }

    // const onDownloadTSV = (item = selectedItem) => {
    //     const details = getPropertyDetails(item)
    //     if (details?.length > 0) {
    //         let formattedDetails = {}
    //         for (let detail of details) {
    //             let currentLabel = detail.label;
    //             let innerProperties = []
    //             if (detail?.anyOf?.length > 0) {
    //                 const innerProps = detail.anyOf[0]?.items?.properties || {}
    //                 innerProperties = Object.keys(innerProps)
    //             }
    //             if (innerProperties.length > 0) {
    //                 for (const prop of innerProperties) {
    //                     if (prop === "id") {
    //                         continue;
    //                     }
    //                     formattedDetails[`${currentLabel}.${prop}`] = detail.isRequired;
    //                 }
    //             } else {
    //                 formattedDetails[currentLabel] = detail.isRequired
    //             }
    //         }
    //         let formattedData = convertJsonToTSVFormat([formattedDetails]);

    //         let blob = new Blob([formattedData], { type: 'text/csv;charset=utf-8;' });
    //         if (navigator.msSaveBlob) {
    //             navigator.msSaveBlob(blob, item + "_properties.tsv");
    //         } else {
    //             var link = document.createElement("a");
    //             if (link.download !== undefined) {
    //                 var url = URL.createObjectURL(blob);
    //                 link.setAttribute("href", url);
    //                 link.setAttribute("download", item + "_properties.tsv");
    //                 link.style.visibility = 'hidden';
    //                 document.body.appendChild(link);
    //                 link.click();
    //                 document.body.removeChild(link);
    //             }
    //         }
    //     }
    // }

    const VIEW_PROPERTIES_MODAL = {
        modalTitle: 'Are you sure you want to download this notebook?',
        positiveButtonText: 'Download JSON',
        negativeButtonText: "Download TSV",
        positiveButtonAction: () => onDownloadJSON(),
        negativeButtonAction: () => onDownloadTSV()
    }

    const getPropertyDetails = (label) => {
        try {
            if (label) {
                const item = modalData?.[label];
                if (item) {
                    let details = []
                    const requiredItems = item?.required || [];
                    const systemProperties = item?.systemProperties || [];
                    let properties = item?.properties || {};
                    let includedProps = []
                    for (let prop of requiredItems) {
                        let currentPropType = properties[prop]?.type || ''
                        if (!currentPropType) {
                            const isAnyOf = properties[prop]?.anyOf
                            currentPropType = isAnyOf?.map(x => x.type) || ''
                        }
                        let obj = {
                            label: prop,
                            isRequired: 'Required',
                            description: properties[prop]?.description || 'No Description',
                            term: properties[prop]?.term || '',
                            type: currentPropType || '',
                            enum: properties[prop]?.enum || '',
                            anyOf: properties[prop]?.anyOf
                        }
                        details.push(obj);
                        includedProps.push(prop)
                    }
                    for (let prop of systemProperties) {
                        delete properties[prop]
                    }
                    for (let prop of Object.keys(properties)) {

                        if (includedProps.find(x => x === prop)) {
                            continue;
                        }
                        let currentPropType = properties[prop]?.type || ''
                        if (!currentPropType) {
                            const isAnyOf = properties[prop]?.anyOf
                            currentPropType = isAnyOf?.map(x => x.type) || ''
                        }
                        let obj = {
                            label: prop,
                            isRequired: 'No',
                            description: properties[prop]?.description || 'No Description',
                            term: properties[prop]?.term || '',
                            type: currentPropType || '',
                            enum: properties[prop]?.enum || '',
                            anyOf: properties[prop]?.anyOf
                        }
                        details.push(obj);
                    }
                    return details;
                }
            }
            return []
        }
        catch (error) {
            console.log('Error in getting the property details', error);
            return []
        }

    }

    const getNodeDisplayTitle = label => {
        return modalData?.[label]?.title || label
    }

    const getSearchResults = useMemo(() => {
        if (searchText) {
            let titlesAndDescriptionSearchResult = itemCollection.filter(item => {
                return !item.isCategory && (item.displayTitle.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.description.toLowerCase().includes(searchText.toLowerCase()))
            }
            ) || []

            let nodePropertiesSearchResult = itemCollection.filter(item => {
                return !item.isCategory && (item.nodeProperties.find(item => item.toLowerCase().includes(searchText.toLowerCase())))
            }) || []
            return {
                titlesAndDescriptionsCount: titlesAndDescriptionSearchResult.length,
                nodePropertiesCount: nodePropertiesSearchResult.length,
                nodePropertiesSearchResult,
                titlesAndDescriptionSearchResult,
                results: uniqBy([...new Set([...titlesAndDescriptionSearchResult, ...nodePropertiesSearchResult])], 'label')
            }
        }
        return null
    }, [searchText]);

    const addRecentSearchItem = async (searchText) => {
        try {
            const storedData = await localStorage.getItem('recentSearch');
            if (storedData) {
                let parsedData = JSON.parse(storedData);
                parsedData.push(searchText);
                let lastFiveElements = parsedData.slice(Math.max(parsedData.length - 5, 0));
                localStorage.setItem('recentSearch', JSON.stringify(lastFiveElements))
            }
            else {
                let data = [searchText];
                localStorage.setItem('recentSearch', JSON.stringify(data))
            }
            getPreviousSearchHistory();
        }
        catch (error) {
            console.log(error)
        }
    }

    const getPreviousSearchHistory = async () => {
        try {
            const data = await localStorage.getItem('recentSearch');
            const finalData = data ? JSON.parse(data) : [];
            let searchData = [...new Set(finalData)]
            let finalSearchHistory = []
            for (let item of searchData) {
                finalSearchHistory.push({
                    key: item,
                    count: finalData.filter(x => x === item).length
                })
            }
            setRecentSearchHistory(finalSearchHistory)
        }
        catch (error) {
            return []
        }
    }

    const clearSearchHistory = async () => {
        try {
            await localStorage.removeItem('recentSearch');
            getPreviousSearchHistory()
        }
        catch (error) {
            console.log(error)
        }
    }

    const getProjects = async () => {
        try {
            const projects = await getAccessibleProjects();
            let filterProjects = uniqBy(projects, 'project_id');
            setAvailableProjects(filterProjects || [])
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getPreviousSearchHistory();
        getProjects();
    }, [])


    const getRecentUpdatesDetails = async () => {
        try {
            const response = await getRecentUpdates();
            setRecentUpdates(response)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getRecentUpdatesDetails()
    }, [])


    const getTypeValue = value => {
        if (Array.isArray(value)) {
            return <ul className={classes.enumValues}>
                {
                    value.map(item => {
                        return <li key={v4()} >{item}</li>
                    })
                }
            </ul>
        }
        return value || "--"
    }

    const updateCurrentProject = event => {
        setCurrentViewingProject(event)
    }

    const onExpansionPanelClick = (item) => {
        if (currentlyExpandedItem === item) {
            setCurrentlyExpandedItem(null)
        } else {
            setCurrentlyExpandedItem(item)
        }
        if (dataModelType === 'ALL') {
            sendGAEvents('select_bc_model_file_category', 'bc_model_file_view', 'Data model file category selected',
                { 'bc_model_file_category': item });
        } else if (dataModelType === 'PARTIAL') {
            sendGAEvents('select_bc_study_model_file_category', 'bc_model_file_view', 'Study model file category selected',
                { 'bc_study_model_file_category': item });
        }
    }
    return (
        <div>
            <SectionHeader
                sectionHeaderProps={sectionHeaderProps} />
            <div className={classes.navHeaderDataModel} id="cvb-dataModel-mainContainer">
                <div className={classes.navHeaderLeftContainer}>
                    <ul>
                        {
                            HEADER_NAV_ITEMS.map((item, index) => {
                                return <li onClick={() => { setSelectedIndex(index); setSearchText(""); setShowResultsAnyway(false) }} key={v4()}
                                    className={selectedIndex === index ? classes.selectedTab : ""}>
                                    {item}
                                </li>
                            })
                        }
                    </ul>
                </div>


                <div className={classes.navHeaderRightContainer}>
                    {
                        selectedIndex === 0 &&
                        <ul>
                            <li><OutlinedInput fullWidth type='text'
                                value={searchText}
                                id="cvb-dataModel-searchField"
                                placeholder='Search'
                                autoComplete="off"
                                onChange={e => setSearchText(e.target.value)}
                                className='signup-inputs-large' name='password'
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" size="large">
                                            <img alt='visibility On'
                                                className={classes.searchDropdownIcon}
                                                src={SearchIcon} />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            /></li>
                            <li className={classes.searchHistoryBlock} id="cvb-dataModel-searchHistoryBlock">
                                <p className={classes.searchHistoryButton} onClick={() => setAnchorEl(true)}>View Recently Viewed
                                    <img src={DownArrow} alt="Down arrow" />
                                </p>
                                <Popover
                                    id={'profile-popover'}
                                    open={openPopover}
                                    anchorEl={anchorEl}
                                    onClose={handleClosePopover}
                                    elevation={0}
                                >
                                    <ul className={classes.dropdownListContainer} id="cvb-dataModel-searchHistoryListContainer">
                                        <div className={classes.searchHistoryPopupHeader}>
                                            <div className={classes.searchHistoryLeftContainer}>
                                                <p id="cvb-dataModel-searchHistoryLastSearchText">Last Search</p>
                                            </div>
                                            <div id="cvb-dataModel-searchHistoryClearSearchLink" className={classes.searchHistoryRightContainer} onClick={() => clearSearchHistory()}>
                                                <p>Clear History</p>
                                            </div>
                                        </div>
                                        {
                                            recentSearchHistory &&
                                            recentSearchHistory.map(item => {
                                                return <li key={v4()} id="cvb-dataModel-searchHistorySearchCount"
                                                    className={classes.searchResultsText}>
                                                    <p>{getNodeDisplayTitle(item.key)}</p> <span>{item.count}</span>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </Popover>
                            </li>
                        </ul>
                    }
                </div>
            </div>
            <Grid container alignItems='center'>
                <Grid item xs={12} lg={12} md={12}>
                    <div className={classes.radioButtonGroupContainer} id='cvb-dataModelView-radioContainer'>
                        <div onClick={() => { setDataModelType("ALL") }}>
                            <div className={classes.labelsContainer}>
                                <div className={classes.radioButton} >
                                    <input id='cvb-dataModelView-radioModelView' type="radio"
                                        name="graphType" value="Dot" checked={dataModelType === "ALL"} />
                                    <label id='cvb-analyzeAttributesSelection-dotTypeGraph-dotLabel'>BC Model View</label>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => {
                            setCurrentViewingProject(availableProjects?.[0]?.project_id || null)
                            setDataModelType("PARTIAL");
                        }}>
                            <div className={classes.labelsContainer}>
                                <div className={classes.radioButton}>
                                    <input id='cvb-dataModelView-studyView'
                                        type="radio" name="graphType"
                                        value="Bar" checked={dataModelType === "PARTIAL"} />
                                    <label id='cvb-analyzeAttributesSelection-barTypeGraph-dotLabel'>BC Study Model View</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>

            {
                selectedIndex === 0 &&
                <>
                    <div className={classes.versionNavSection} id="cvb-dataModel-versionContainer">
                        <div className={classes.versionNavSectionLeftContainer}>
                            <ul>
                                <li>
                                    <p className={classes.versionText} id="cvb-dataModel-versionText"><img src={VersionIcon} alt="version icon" />Version {recentUpdates?.[0]?.version || 0}</p>
                                </li>
                                <li>
                                    {
                                        dataModelType === "PARTIAL" &&
                                        <span>
                                            <li>
                                                <label className={classes.projectTitle} id='cvb-dataModel-projectsDropdown' htmlFor='xAxis'>Project </label>
                                            </li>
                                            <li>
                                                <DropDown
                                                    values={availableProjects.map(x => x.project_id)}
                                                    handleFilterStateChange={updateCurrentProject}
                                                    selectedFilter={currentViewingProject}
                                                    placeholder={'Select'}
                                                    customClass={classes.projectDropdown}
                                                />
                                            </li>
                                        </span>
                                    }
                                </li>
                            </ul>


                        </div>

                        <div className={classes.versionNavSectionRightContainer}>
                            <ul className={classes.versionNavSectionIconNav}>
                                <li id="cvb-dataModel-treeViewButton" className={selectedDataMode === 0 ? classes.activeView : ''}
                                    onClick={() => setSelectedDataMode(0)}>
                                    <img src={selectedDataMode === 0 ? TreeViewIconWhite : TreeViewIcon} alt="tree icon" />
                                </li>
                                <li id="cvb-dataModel-tableViewButton" className={selectedDataMode === 1 ? classes.activeView : ''}
                                    onClick={() => setSelectedDataMode(1)}>
                                    <img src={selectedDataMode === 1 ? ListViewIconWhite : ListViewIcon} alt="tree icon" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    {
                        selectedDataMode === 0 ?
                            <div className={classes.dataModelTree}>

                                {
                                    isLoadingDataModel ?
                                        <div><CustomLoader /></div> :
                                        <DataModelGraph
                                            zoomValue={zoomValue}
                                            setItemCollection={setItemCollection}
                                            graphData={constructedGraphData}
                                            setShowPropertiesModal={setShowPropertiesModal}
                                            setSelectedItem={setSelectedItem}
                                            dataModelType={dataModelType}
                                            itemCollection={itemCollection}
                                        />
                                }
                            </div> :
                            <div className={classes.dataModelTableContainer}>
                                {
                                    isLoadingDataModel ?
                                        <div><CustomLoader /></div> :
                                        tableData &&
                                        Object.keys(tableData).map(item => {
                                            const childElements = getChildrenOfTable(item)
                                            return <Accordion key={v4()}
                                                expanded={currentlyExpandedItem === item}
                                                className={classes.expansionPanel + " expansion_panel"}>
                                                <AccordionSummary
                                                    expandIcon={<img src={ExpandMoreIcon} alt="icon" />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    onClick={() => onExpansionPanelClick(item)} key={v4()}
                                                    className={classes.expansionPanelHeading}
                                                >
                                                    <div className={classes.tableHeaderTitleContent}>
                                                        {/* <span className={classes.tableHeaderImg}>
                                                        <img src={DataModelTableIcons} alt="" />
                                                    </span> */}
                                                        <h3 className={classes.filterBlockHeading}>{formatLabel(item)}</h3>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails className={classes.expansionPanelDetails}>
                                                    <div className={classes.tableContent}>
                                                        {
                                                            childElements?.length > 0 &&
                                                            childElements.map(innerItem => {
                                                                const fullItem = modalData?.[innerItem];
                                                                return <TableContainer key={v4()} component={Paper} elevation={0}
                                                                    className={classes.tableContainer}>
                                                                    <Table className={classes.table} aria-label="datamodel table">
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                <TableCell component="th" scope="row"
                                                                                    className={classes.dataTableColumnTitle}>
                                                                                    {modalData?.[innerItem]?.title || innerItem}
                                                                                </TableCell>
                                                                                <TableCell align="right"
                                                                                    className={classes.dataTableColumnDescription}>
                                                                                    {fullItem?.description || ''}
                                                                                </TableCell>
                                                                                <TableCell align="right"
                                                                                    className={classes.dataTableColumnViewProperties}>
                                                                                    <p className={classes.viewPropertiesButton}
                                                                                        onClick={() => {
                                                                                            setShowPropertiesModal(true);
                                                                                            setSelectedItem(innerItem)
                                                                                        }}>
                                                                                        View Properties
                                                                                    </p>
                                                                                </TableCell>
                                                                                <TableCell align="right"
                                                                                    className={classes.dataTableColumnViewProperties}>
                                                                                    <button
                                                                                        className={`transparent-button small-size-button 
                                                                            ${classes.dataModelTableButton} `} onClick={() => {
                                                                                            setSelectedItem(innerItem)
                                                                                            onDownloadTSV(innerItem);
                                                                                        }}>
                                                                                        Download TSV
                                                                                    </button>
                                                                                </TableCell>
                                                                                <TableCell align="right"
                                                                                    className={classes.dataTableColumnViewProperties}>
                                                                                    <button className={`transparent-button small-size-button 
                                                                            ${classes.dataModelTableButton} `} onClick={() => {
                                                                                            setSelectedItem(innerItem)
                                                                                            onDownloadJSON(innerItem);
                                                                                        }}>
                                                                                        Download JSON
                                                                                    </button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            })
                                                        }
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        })
                                }
                            </div>
                    }
                </>

            }
            {
                selectedIndex === 1 &&
                <div className={classes.recentUpdatesContainer}>
                    <Grid container>
                        <Grid item xs={8} lg={8} md={6} className={classes.recentUpdatesInner}>
                            <TableContainer component={Paper} elevation={0} className={classes.recentUpdatesInnerTable}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Version</TableCell>
                                            <TableCell align="left">Release Date</TableCell>
                                            <TableCell align="left">Description</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            recentUpdates.map(item => {
                                                return <TableRow key={v4()}>
                                                    <TableCell align="left">{item?.version}</TableCell>
                                                    <TableCell align="left">{item.version_date}</TableCell>
                                                    <TableCell align="left">{item.model_change_description}</TableCell>
                                                </TableRow>
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                    </Grid>
                </div>
            }
            {
                (getSearchResults || showResultsAnyway > 0) &&
                <div className={classes.searchTextResults}>
                    <div className={classes.searchResultsInner}>
                        <div className={classes.searchResultsHeader}>
                            <div className={classes.searchResultsHeaderContent}>
                                <div className={classes.searchResultsHeaderLeftContainer}>
                                    <h2>Search Results</h2>
                                </div>
                                <div className={classes.searchResultsHeaderRightContainer}
                                    onClick={() => {
                                        setSearchText('');
                                        setShowResultsAnyway(true)
                                    }}>
                                    <p>Clear Result </p>
                                </div>
                                <button className={classes.closeSearchResultsBtn} onClick={() => { setSearchText(""); setShowResultsAnyway(false) }}>
                                    <img src={closeIcon} alt="close" />
                                </button>
                            </div>
                        </div>
                        <div className={classes.searchResultsCountTitle}>
                            <h4 className={classes.nodeTitleCountResult}>{getSearchResults?.titlesAndDescriptionsCount || 0} Matches in nodes titles & descriptions</h4>
                            {
                                getSearchResults?.titlesAndDescriptionSearchResult?.map(item => {
                                    return <div key={v4()} className={classes.searchResultsTilesInner}>
                                        <div className={classes.searchResultsTile}>
                                            <img src={dataFileIcon} alt=" " />
                                        </div>
                                        <div className={classes.searchResultsTileTitle}>
                                            <h5>{item.displayTitle}</h5>
                                            <p>{modalData[item.label]?.category || ''}</p>
                                        </div>
                                        <div className={classes.searchResultsTileViewProperties} onClick={() => {
                                            setSelectedItem(item.label);
                                            setShowPropertiesModal(true);
                                            addRecentSearchItem(item.label)
                                        }}>
                                            <p>View Properties</p>
                                        </div>

                                    </div>
                                }
                                )
                            }
                        </div>
                        <div className={classes.searchResultsCountTitle}>
                        <h4>{getSearchResults?.nodePropertiesCount || 0} Matches in nodes properties</h4>
                            {
                                getSearchResults?.nodePropertiesSearchResult?.map(item => {
                                    return <div key={v4()} className={classes.searchResultsTilesInner}>
                                        <div className={classes.searchResultsTile}>
                                            <img src={dataFileIcon} alt=" " />
                                        </div>
                                        <div className={classes.searchResultsTileTitle}>
                                            <h5>{item.displayTitle}</h5>
                                            <p>{modalData[item.label]?.category || ''}</p>
                                        </div>
                                        <div className={classes.searchResultsTileViewProperties} onClick={() => {
                                            setSelectedItem(item.label);
                                            setShowPropertiesModal(true);
                                            addRecentSearchItem(item.label)
                                        }}>
                                            <p>View Properties</p>
                                        </div>

                                    </div>
                                }
                                )
                            }
                        </div>
                    </div>
                </div>
            }
            <Modal
                open={showPropertiesModal}
                handleClose={() => setShowPropertiesModal(false)}
                dialogProps={{ ...VIEW_PROPERTIES_MODAL, modalTitle: getNodeDisplayTitle(selectedItem) }}
                disableBottomHorizontalDivider
                disableTitleDivider
                bothPositiveButtons
            >
                <TableContainer
                    component={Paper}
                    elevation={0}
                    className={classes.ViewPropertiesTableContainer}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.stickyHeader}>Property</TableCell>
                                <TableCell className={classes.stickyHeader}>Type</TableCell>
                                <TableCell className={classes.stickyHeader}>Required</TableCell>
                                <TableCell className={classes.stickyHeader}>Description</TableCell>
                                <TableCell className={classes.stickyHeader}>Term</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                getPropertyDetails(selectedItem)?.length > 0 &&
                                getPropertyDetails(selectedItem).map(item => {
                                    const currentType = item.type || (item.enum || "--")
                                    return <TableRow key={v4()}>
                                        <TableCell>{item.label}</TableCell>
                                        <TableCell>{getTypeValue(currentType)}</TableCell>
                                        <TableCell>{item.isRequired}</TableCell>
                                        <TableCell>{item.description}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Modal>

        </div >
    );
}

/**
 *  External Imports
 */
import React, { useEffect, useState, useRef } from "react";
import { number, string, oneOfType, shape, bool } from "prop-types";

import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip'
/**
 *  Internal Imports
 */
import CommunityWidgetButtons from "../community-widget-buttons";
import UserAvatar from "../../commons/user-avatar";
import iconTooltip from "../../../assets/icons/icon-info.svg";
import { RES_COMMUNITY_RECOMMENDATION_STATUSES } from "../../../api/community-api";
import { useHover, useResize } from "../../../utils/hooks";
import { getRealTextWidthPx } from "../../../utils/stringFormatter";
import styles from "./index.module.css";
import { WIDGET_TYPES } from "../../../constants/widget-types";

const FONT_SETTINGS = ".75rem 'Raleway', sans-serif";


const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#FCF8C8",
        color: "#222222",
        boxShadow: "-1px 1px 106px -61px rgba(74,73,74,1);",
        fontSize: 14,
        fontWeight: "medium",
        marginRight: '3px',
        fontFamily: "Raleway",
        borderRadius: 0,
        padding: 15,
        textAlign: "left"
    },
    arrow: {
        color: "#FCF8C8"
    },
    customWidth: {
        maxWidth: 200,
    },
}))(Tooltip);

const CommunityWidgetBodyItem = (props) => {
    const { user, widgetType, fullscreenView } = props;

    const [isMessageTruncated, setIsMessageTruncated] = useState(false);
    const [isHovered, eventHandlers] = useHover();

    const userMessage = useRef(null);

    const { width: maxUserMessageWidth } = useResize(
        userMessage,
        fullscreenView
    );

    const isEmptyStatus = () => {
        return (
            Boolean(!user.status) ||
            user.status === RES_COMMUNITY_RECOMMENDATION_STATUSES.DEFAULT
        );
    };

    const isNewStatus = () => {
        return (
            Boolean(user.status) &&
            user.status === RES_COMMUNITY_RECOMMENDATION_STATUSES.NEW
        );
    };

    const isEmailUnavailable = () => {
        return (
            widgetType === WIDGET_TYPES.SEARCH ||
            widgetType === WIDGET_TYPES.PENDING ||
            widgetType === WIDGET_TYPES.INCOMING
        );
    };

    const isIncomingWidgetType = () => {
        return widgetType === WIDGET_TYPES.INCOMING;
    };

    const checkIsUserMessageTruncated = () => {
        const realTextWidth = getRealTextWidthPx(user.text, FONT_SETTINGS);

        setIsMessageTruncated(realTextWidth > maxUserMessageWidth);
    };

    useEffect(() => {
        if (isIncomingWidgetType) {
            checkIsUserMessageTruncated();
        }
    }, [maxUserMessageWidth]);

    return (
        <div 
            data-testid="widgetItem"
            className={
                isIncomingWidgetType()
                    ? `${styles.widgetBodyItem} ${styles.topAligned}`
                    : `${styles.widgetBodyItem}`
            }
            {...eventHandlers}>
            <div className={styles.userAvatarWrap}>
                <UserAvatar 
                    data-testid="widgetIcon"
                    userIcon={user.userIcon}>
                </UserAvatar>
            </div>
            <div className={styles.userInfoArea}>
                <div className={styles.infoBlock}>
                    <div className={styles.itemLeftArea}>
                        <div className={styles.userNameArea}>
                            <div className={styles.userNameWrap}>
                                <div
                                    data-testid="name"
                                    className={styles.userName}>
                                    {user.name}
                                </div>
                                {isNewStatus() && (
                                    <div className={styles.newUserStatus}>
                                        New
                                    </div>
                                )}
                            </div>

                            {user.reason && (
                                <div className={styles.userTooltipArea}>
                                    <LightTooltip placement="right" className={styles.userTooltipIcon} arrow title={user.reason}>
                                        <img className={styles.userTooltipIcon} src={iconTooltip}  alt="Icon Tooltip" />
                                    </LightTooltip>
                                </div>
                            )}
                        </div>

                        {user.organization && (
                            <div
                                data-testid="organization"
                                className={styles.userOrganization}>
                                {user.organization}
                            </div>
                        )}
                        {user.email && !isEmailUnavailable() && (
                            <div
                                data-testid="email"
                                className={styles.userMail}>
                                    {user.email}
                            </div>
                        )}
                    </div>

                    {!isEmptyStatus() && !isNewStatus() && (
                        <div className={styles.itemRightArea}>
                            <div
                                data-testid="userStatus"
                                className={styles.userStatus}>
                                {user.status}
                            </div>

                            {/* <button data-testid="undoBtn" className={styles.undoBtn}>
                                Undo
                            </button> */}
                        </div>
                    )}

                    {(isEmptyStatus() || isNewStatus()) && isHovered && (
                        <CommunityWidgetButtons
                            data-testid="buttons"
                            id={user.idRecommendation || user.id}
                            userIcon={user.userIcon}
                            name={user.name}
                            organization={user.organization}
                            reason={user.reason}
                            recipientID={user.recipientID}
                            email={user.email}
                            widgetType={widgetType}
                        />
                    )}
                </div>
                {isIncomingWidgetType() && (
                    <div className={styles.userMessageArea} ref={userMessage}>
                        <p data-testid="userMessage" className={styles.userMessage}>{user.text}</p>
                        {isMessageTruncated && (
                            <div data-testid="showMoreBtn" className={styles.userMessageTooltip}>
                                <span className={styles.userMessageHint}>
                                    Show more
                                </span>
                                <div data-testid="userMessageTooltip" className={styles.userTooltipText}>
                                    {user.text}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

CommunityWidgetBodyItem.propTypes = {
    user: shape({
        id: oneOfType([string, number]),
        senderID: number,
        recipientID: number,
        idRecommendation: number,
        userIcon: string.isRequired,
        name: string.isRequired,
        organization: string,
        reason: string,
        status: string,
        email: string,
        text: string,
    }).isRequired,
    widgetType: string.isRequired,
    fullscreenView: bool.isRequired,
};

export default CommunityWidgetBodyItem;

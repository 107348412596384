import DIFInstitution from './DIFInstitution'
import { connect } from 'react-redux'
import{ setDifInstitutionInstitutionName, setDifInstitutionStreetAddress, setDifInstitutionCountry,
    setDifInstitutionState, setDifInstitutionCity, setDifInstitutionMunicipality, setDifInstitutionZipCode 
} from '../../../redux/actions/difInstitutionActions'
const mapStateToProps = state => {
    return {
        difInstitutionInstitutionName: state.difInstitution.difInstitutionInstitutionName,
        difInstitutionStreetAddress: state.difInstitution.difInstitutionStreetAddress,
        difInstitutionCountry: state.difInstitution.difInstitutionCountry,
        difInstitutionState: state.difInstitution.difInstitutionState,
        difInstitutionCity: state.difInstitution.difInstitutionCity,
        difInstitutionMunicipality: state.difInstitution.difInstitutionMunicipality,
        difInstitutionZipCode: state.difInstitution.difInstitutionZipCode,
        currentDIFFormStage: state.dataInventory.currentDIFFormStage
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setDifInstitutionInstitutionName: async (value) => await dispatch(setDifInstitutionInstitutionName(value)),
        setDifInstitutionStreetAddress: async (value) => await dispatch(setDifInstitutionStreetAddress(value)),
        setDifInstitutionCountry: async (value) => await dispatch(setDifInstitutionCountry(value)),
        setDifInstitutionState: async (value) => await dispatch(setDifInstitutionState(value)),
        setDifInstitutionCity: async (value) => await dispatch(setDifInstitutionCity(value)),
        setDifInstitutionMunicipality: async (value) => await dispatch(setDifInstitutionMunicipality(value)),
        setDifInstitutionZipCode: async (value) => await dispatch(setDifInstitutionZipCode(value)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DIFInstitution);
import DIFDatasetForm from './DIFDatasetForm';
import { connect } from 'react-redux';
import { 
    updateDIDatasetName,
    updateDIOwnerType,
    updateDIOwnerName,
    updateDIOwnerTitle,
    updateDIOwnerAffiliation,
    updateDIOwnerEmail,
    updateDIAbstract,
    updateDIAgencyName,
    updateDIAgencyNumber,
    updateDIDiseaseArea,
    updateDICuratedToStandard,
    updateDIStandard,
    updateDIRestrictDataAccess,
    updateDIAllowDataDownload,
    updateDINumberOfSample,
    updateDIEstimatedSize,
    updateDITimeSeriesChange,
    updateDIDatasetComplete,
    updateDIUploadCompletedDate,
    updateDISubjectSpaceStudy,
    updateDISubjectSpaceCaptureInfo,
    updateDIBioSpecimenStudy,
    updateDIBioSpecimenCaptureInfo,
    updateCanYouProvideDataDict,
    updateOtherImagingText,
    updateOtherPreclinicalText,
    updateOtherGenomicText,
    updateOtherProteomicText,
    updateOtherWholeGenomeText,
    updateImagingOption,
    updatePreclinicalOption,
    updateGenomicOption,
    updateProteomicOption,
    updateWholeGenomeOption,
    updateOtherTypeOfDataText,
    updateIsClinical,
    setIndividualDatasetData,
    updateIsPublicationsPresentChange,
    addDIFPublications,
    updatePublicationName,
    updatePublicationUrl,
    removeDIFPublications,
    setDataDictDocuments,
    setDataDictRelationshipDocuments,
    updateDIDatasetMoreDataAdded,
    updateModalSystem,
    updateModalSystemOtherText,
    updateDiseaseFeatureText,
    updateTypeOfDataDiseaseArea,
    updateOtherDiseaseAreaText,
    updateMetabolomicOption,
    updateBehavioralTaskText,
    updatePhysiologicalOutcomeText,
    updateTissueSample,
    updateBehavioralAssessment,
    updateWearableSensors,
    updateDerivedSensorsData,
    updateDoYouNeedCurationService,
    updateIsDataCurated,
    updateCurationDate,
    updateCuratorName,
    updateCuratorEmail,
    updateCuratorInstitution,
    updateOtherMetabolomicText,
    updateWearableSensorsOtherText,
    updateTissueSampleOtherText,
    updateDerivedSensorDataOtherText,
    removeDataDictDocuments
} from '../../../redux/actions/difIndividualDatasetActions';
import { updateSnackBar } from '../../../redux/actions/globalActions'
const mapStateToProps = state => {
    return {
        individualDatasets: state.difIndividualDataset.individualDatasets,
        difDatasetActiveIndex: state.difIndividualDataset.difDatasetActiveIndex,
        currentDIFFormStage: state.dataInventory.currentDIFFormStage
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateDIDatasetName: async (value, id) => await dispatch(updateDIDatasetName(value, id)),
        updateDIOwnerType: async (value, id) => await dispatch(updateDIOwnerType(value, id)),
        updateDIOwnerName: async (value, id) => await dispatch(updateDIOwnerName(value, id)),
        updateDIOwnerTitle: async (value, id) => await dispatch(updateDIOwnerTitle(value, id)),
        updateDIOwnerAffiliation: async (value, id) => await dispatch(updateDIOwnerAffiliation(value, id)),
        updateDIOwnerEmail: async (value, id) => await dispatch(updateDIOwnerEmail(value, id)),
        updateDIAbstract: async (value, id) => await dispatch(updateDIAbstract(value, id)),
        updateDIAgencyName: async (value, id) => await dispatch(updateDIAgencyName(value, id)),
        updateDIAgencyNumber: async (value, id) => await dispatch(updateDIAgencyNumber(value, id)),
        updateDIDiseaseArea: async (value, id) => await dispatch(updateDIDiseaseArea(value, id)),
        updateDICuratedToStandard: async (value, id) => await dispatch(updateDICuratedToStandard(value, id)),
        updateDIStandard: async (value, id) => await dispatch(updateDIStandard(value, id)),
        updateDIRestrictDataAccess: async (value, id) => await dispatch(updateDIRestrictDataAccess(value, id)),
        updateDIAllowDataDownload: async (value, id) => await dispatch(updateDIAllowDataDownload(value, id)),
        updateDINumberOfSample: async (value, id) => await dispatch(updateDINumberOfSample(value, id)),
        updateDIEstimatedSize: async (value, id) => await dispatch(updateDIEstimatedSize(value, id)),
        updateDITimeSeriesChange: async (value, id) => await dispatch(updateDITimeSeriesChange(value, id)),
        updateDIDatasetComplete: async (value, id) => await dispatch(updateDIDatasetComplete(value, id)),
        updateDIUploadCompletedDate: async (value, id) => await dispatch(updateDIUploadCompletedDate(value, id)),
        updateDISubjectSpaceStudy: async (value, id) => await dispatch(updateDISubjectSpaceStudy(value, id)),
        updateDISubjectSpaceCaptureInfo: async (value, id) => await dispatch(updateDISubjectSpaceCaptureInfo(value, id)),
        updateDIBioSpecimenStudy: async (value, id) => await dispatch(updateDIBioSpecimenStudy(value, id)),
        updateDIBioSpecimenCaptureInfo: async (value, id) => await dispatch(updateDIBioSpecimenCaptureInfo(value, id)),
        updateCanYouProvideDataDict: async (value, id) => await dispatch(updateCanYouProvideDataDict(value, id)),
        updateOtherImagingText: async (value, id) => await dispatch(updateOtherImagingText(value, id)),
        updateOtherPreclinicalText: async (value, id) => await dispatch(updateOtherPreclinicalText(value, id)),
        updateOtherGenomicText: async (value, id) => await dispatch(updateOtherGenomicText(value, id)),
        updateOtherProteomicText: async (value, id) => await dispatch(updateOtherProteomicText(value, id)),
        updateOtherWholeGenomeText: async (value, id) => await dispatch(updateOtherWholeGenomeText(value, id)),
        updateImagingOption: async (value, id) => await dispatch(updateImagingOption(value, id)),
        updatePreclinicalOption: async (value, id) => await dispatch(updatePreclinicalOption(value, id)),
        updateGenomicOption: async (value, id) => await dispatch(updateGenomicOption(value, id)),
        updateProteomicOption: async (value, id) => await dispatch(updateProteomicOption(value, id)),
        updateWholeGenomeOption: async (value, id) => await dispatch(updateWholeGenomeOption(value, id)),
        updateOtherTypeOfDataText: async (value, id) => await dispatch(updateOtherTypeOfDataText(value, id)),
        updateIsClinical: async (value, id) => await dispatch(updateIsClinical(value, id)),
        setIndividualDatasetData: async (value, id) => await dispatch(setIndividualDatasetData(value)),
        updateIsPublicationsPresentChange: async (value, id) => await dispatch(updateIsPublicationsPresentChange(value, id)),
        addDIFPublications: async (value, id) => await dispatch(addDIFPublications(value, id)),
        updatePublicationName: async (value, id) => await dispatch(updatePublicationName(value, id)),
        updatePublicationUrl: async (value, id) => await dispatch(updatePublicationUrl(value, id)),
        removeDIFPublications: async (value, id) => await dispatch(removeDIFPublications(value, id)),
        updateSnackBar: async (message, type) => await dispatch(updateSnackBar(message, type)),
        setDataDictDocuments: async (value, id) => await dispatch(setDataDictDocuments(value, id)),
        setDataDictRelationshipDocuments: async (value, id) => await dispatch(setDataDictRelationshipDocuments(value, id)),
        updateDIDatasetMoreDataAdded: async (value, id) => await dispatch(updateDIDatasetMoreDataAdded(value, id)),
        updateModalSystem: async (value, id) => await dispatch(updateModalSystem(value, id)),
        updateModalSystemOtherText: async (value, id) => await dispatch(updateModalSystemOtherText(value, id)),
        updateDiseaseFeatureText: async (value, id) => await dispatch(updateDiseaseFeatureText(value, id)),
        updateTypeOfDataDiseaseArea: async (value, id) => await dispatch(updateTypeOfDataDiseaseArea(value, id)),
        updateOtherDiseaseAreaText: async (value, id) => await dispatch(updateOtherDiseaseAreaText(value, id)),
        updateMetabolomicOption: async (value, id) => await dispatch(updateMetabolomicOption(value, id)),
        updateBehavioralTaskText: async (value, id) => await dispatch(updateBehavioralTaskText(value, id)),
        updatePhysiologicalOutcomeText: async (value, id) => await dispatch(updatePhysiologicalOutcomeText(value, id)),
        updateTissueSample: async (value, id) => await dispatch(updateTissueSample(value, id)),
        updateBehavioralAssessment: async (value, id) => await dispatch(updateBehavioralAssessment(value, id)), 
        updateWearableSensors: async (value, id) => await dispatch(updateWearableSensors(value, id)), 
        updateDerivedSensorsData: async (value, id) => await dispatch(updateDerivedSensorsData(value, id)), 
        updateDoYouNeedCurationService: async (value, id) => await dispatch(updateDoYouNeedCurationService(value, id)), 
        updateIsDataCurated: async (value, id) => await dispatch(updateIsDataCurated(value, id)), 
        updateCurationDate: async (value, id) => await dispatch(updateCurationDate(value, id)), 
        updateCuratorName: async (value, id) => await dispatch(updateCuratorName(value, id)), 
        updateCuratorEmail: async (value, id) => await dispatch(updateCuratorEmail(value, id)), 
        updateCuratorInstitution: async (value, id) => await dispatch(updateCuratorInstitution(value, id)),
        updateOtherMetabolomicText: async (value, id) => await dispatch(updateOtherMetabolomicText(value, id)),
        updateWearableSensorsOtherText: async (value, id) => await dispatch(updateWearableSensorsOtherText(value, id)),
        updateTissueSampleOtherText: async (value, id) => await dispatch(updateTissueSampleOtherText(value, id)),
        updateDerivedSensorDataOtherText: async (value, id) => await dispatch(updateDerivedSensorDataOtherText(value, id)),
        removeDataDictDocuments: async (value, id) => await dispatch(removeDataDictDocuments(value, id)),
        
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(DIFDatasetForm);
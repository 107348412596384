import DIFIndividualDataset from './DIFIndividualDataset'
import { connect } from 'react-redux'
const mapStateToProps = state => {
    return {
        individualDatasets: state.difIndividualDataset.individualDatasets,
        difDatasetActiveIndex: state.difIndividualDataset.difDatasetActiveIndex
    }
}
const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DIFIndividualDataset);
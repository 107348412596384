import React from 'react';
import { Provider } from 'react-redux';
import Root from './root';
import store from './redux/store';
import { HashRouter } from "react-router-dom";
import './App.css';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import muiTheme from "./theme"

const App = () => (
  <HashRouter>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
          <Provider store={store}>
        <Root path="/"/>
      </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </HashRouter>
);

export default App;
import iconSuccess from "../assets/icons/success_icon_md.svg";
import errorIcon from "../assets/icons/error_icon_md.svg";

const SUBMITTED_FEEDBACK_MESSAGE_OPTION_KEYS = {
    SUCCESS: "success",
    ALREADY_SENT: "alreadySent",
    ERROR: "error"
};

const SUBMITTED_FEEDBACK_MESSAGE_OPTIONS = {
    success: {
        image: iconSuccess,
        imageAlt: "Icon Success",
        title: "Thank you for your feedback!",
        text:
            "We carefully consider all feedback in order to improve our product.",
        email: "support@braincommons.org",
    },
    alreadySent: {
        image: iconSuccess,
        imageAlt: "Icon Success",
        title: "You have already sent feedback",
        text:
            "We carefully consider all feedback in order to improve our product.",
        email: "support@braincommons.org",
    },
    error: {
        image: errorIcon,
        imageAlt: "Icon Error",
        title: "Feedback could not be sent",
        text:
            "Unfortunately, there was an error. Try refreshing the page or logging in later.",
        email: "support@braincommons.org",
    },
};

const DEFAULT_EMPTY_RATE = -1;

const RATE_SIZE = 5;

const MIN_RATE_WITHOUT_COMMENT = 4;

const RATE_TEXT = ["Terrible", "Bad", "Okay", "Good", "Great"];

const ZONE2_REQUEST_MODAL_TEXT = 'To gain access to all Zone 2 Projects, you will need to submit a Zone Access Request and provide any additional information requested by the BRAINCommons (BC) Manager or the BC Data Access Committee to be certified as a Qualified Researcher. Click on “Request Access” to begin your submission or on “Cancel” to go back.'

export { SUBMITTED_FEEDBACK_MESSAGE_OPTIONS, SUBMITTED_FEEDBACK_MESSAGE_OPTION_KEYS, DEFAULT_EMPTY_RATE, RATE_TEXT, RATE_SIZE, MIN_RATE_WITHOUT_COMMENT, ZONE2_REQUEST_MODAL_TEXT };

import {
    UPDATE_USER_ADMIN_DATA
} from '../action-types'

const initialState = {
    searchData: []
}

const userAdminReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER_ADMIN_DATA: {
            return {
                ...state,
                searchData: action.payload
            }
        }
        default: return state;
    }
}

export default userAdminReducer;

import { ADD_DIF_OTHER_PERSONAL_USERS, REMOVE_DIF_OTHER_PERSONAL_USERS, UPDATE_DIF_OP_USERS_FIRST_NAME
    , UPDATE_DIF_OP_USERS_LAST_NAME, UPDATE_DIF_OP_USERS_TITLE, UPDATE_DIF_OP_USERS_EMAIL, DIF_OP_WILL_OTHER_UPLOAD_DATA
    , SET_OTHER_PERSONAL_FORM_DATA, RESET_OTHER_PERSONAL_FORM_DATA
} from '../action-types'
    
export const addDifOPUsers = (value) => {
    return {
        type: ADD_DIF_OTHER_PERSONAL_USERS,
        payload: value
    }
}
export const removeOtherPersonalUser = (value) => {
    return {
        type: REMOVE_DIF_OTHER_PERSONAL_USERS,
        payload: value
    }
}
export const updateDifOPUsersFirstName = (value, id) => {
    return {
        type: UPDATE_DIF_OP_USERS_FIRST_NAME,
        payload: {
            value,
            id
        }
    }
}
export const updateDifOPUsersLastName = (value, id) => {
    return {
        type: UPDATE_DIF_OP_USERS_LAST_NAME,
        payload: {
            value,
            id
        }
    }
}
export const updateDifOPUsersTitle = (value, id) => {
    return {
        type: UPDATE_DIF_OP_USERS_TITLE,
        payload: {
            value,
            id
        }
    }
}
export const updateDifOPUsersEmail = (value, id) => {
    return {
        type: UPDATE_DIF_OP_USERS_EMAIL,
        payload: {
            value,
            id
        }
    }
}
export const setDifOPWillOtherUploadData = (value) => {
    return {
        type: DIF_OP_WILL_OTHER_UPLOAD_DATA,
        payload: value
    }
}

export const setOtherPersonnelData = (value) => {
    return {
        type: SET_OTHER_PERSONAL_FORM_DATA,
        payload: value
    }
}
export const resetOtherPersonnelData = () => {
    return {
        type: RESET_OTHER_PERSONAL_FORM_DATA,
    }
}

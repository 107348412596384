/**
 *  External Imports
 */
import Grid from "@mui/material/Grid";
import React, { useState, useRef, useEffect } from "react";
import {
    bool,
    func,
    arrayOf,
    shape,
    instanceOf,
    string,
    number,
} from "prop-types";
import { ParentSize } from "@visx/responsive";
import axios from "axios";

/**
 *  Internal Imports
 */
import CommunityDeleteModal from "../community-connections-accepted/community-delete-modal";
import useScrollToTop from "../../commons/scroll-top";
import {
    CONSTELLATION_TABS,
    CONSTELLATION_USER_TYPE,
} from "../../../constants/constellation";
import CommunityConstellationTabs from "./community-constellation-tabs";
import CommunityConstellationGraphBase from "./community-constellations-graph-base";
import CommunityConstellationGraph from "./community-constellation-graph";
import { useResize } from "../../../utils/hooks";
import { communityMappedAPI, communityAPI } from "../../../api/community-api";
import CommunityConnectModal from "../../controls/community-connect-modal";
import CommunityConstellationGraphTooltip from "./community-constellation-graph-tooltip";
import CommunitySuccessModal from "../../controls/community-success-modal";
import styles from "./index.module.css";

const DEFAULT_TAB = CONSTELLATION_TABS.RECOMMENDED;

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 */
const CommunityConstellation = (props) => {
    const {
        fullscreenView,
        constellationLabels,
        constellationLabelsSelected,
        constellationRecommendedUsers,
        constellationConnectedUsers,
    } = props;

    const { 
        initLabels, 
        updateSnackBar, 
        clearAllLabelsSelection, 
        setRecommendedUsers,
        setConnectedUsers,
        resetLabelNodeParams 
    } = props;

    const [currentTab, setCurrentTab] = useState(DEFAULT_TAB);
    const [isOnUpdate, setIsOnUpdate] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [currentUser, setCurrentUser] = useState(null);

    const constellationGraphBase = useRef(null);
    const { width: currentGraphWidth } = useResize(
        constellationGraphBase,
        fullscreenView
    );
    const initRecommendedDataCancelTokenSrc = useRef();
    const initConnectedDataCancelTokenSrc = useRef();

    useScrollToTop();

    const resetToken = () => {
        if (initRecommendedDataCancelTokenSrc.current) {
            initRecommendedDataCancelTokenSrc.current.cancel();
        }

        if (initConnectedDataCancelTokenSrc.current) {
            initConnectedDataCancelTokenSrc.current.cancel();
        }
    };

    const getConstellationRecommendations = async () => {
        setIsLoading(true);
        resetBeforeLoadData();
        resetToken();

        initRecommendedDataCancelTokenSrc.current = axios.CancelToken.source();

        const {
            data,
            isCanceled,
        } = await communityMappedAPI.getMappedConstellationRecommendations(
            initRecommendedDataCancelTokenSrc.current.token
        );
        if (!isCanceled) {
            setIsLoading(false);
            setRecommendedUsers(data.recommendedUser);
            setCurrentUser(data.currentUser);
            initLabels(data.labels);
        }
    };

    const getConstellationConnections = async () => {
        setIsLoading(true);
        resetBeforeLoadData();
        resetToken();

        initConnectedDataCancelTokenSrc.current = axios.CancelToken.source();

        const {
            data,
            isCanceled,
        } = await communityMappedAPI.getMappedConstellationConnections(
            initConnectedDataCancelTokenSrc.current.token
        );
        if (!isCanceled) {
            setIsLoading(false);
            setConnectedUsers(data.connectedUser);
            setCurrentUser(data.currentUser);
            initLabels(data.labels);
        }
    };

    const resetBeforeLoadData = () => {
        setConnectedUsers([]);
        setRecommendedUsers([]);
        initLabels([]);
    };

    const onTabClick = (selectedTabValue) => {
        if (selectedTabValue === CONSTELLATION_TABS.RECOMMENDED) {
            getConstellationRecommendations();
        } else {
            getConstellationConnections();
        }
        setCurrentTab(selectedTabValue);
    };

    const onSaveToProfile = async () => {
        setIsOnUpdate(true);
        const { error } = await communityAPI.saveConstellationLabelAnswers(
            Array.from(constellationLabelsSelected)
        );

        if (!error) {
            updateSnackBar("Profile updated successfully", "success");
            resetLabelNodeParams();
            clearAllLabelsSelection();
            await getConstellationRecommendations();
        } else {
            updateSnackBar("Error while updating profile", "error");
        }

        setIsOnUpdate(false);
    };

    useEffect(() => {
        resetLabelNodeParams();
        clearAllLabelsSelection()
        getConstellationRecommendations();

        return () => resetToken();
    }, []);

    // Return
    return (
        <div className={styles.communityConstellation}>
            <div className={styles.container}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className={styles.body}>
                    <div
                        className={styles.bodyItem}
                        ref={constellationGraphBase}>
                        <div className={styles.bodyItemHeader}>
                            <CommunityConstellationTabs
                                data-testid="constellationTabs"
                                currentTab={currentTab}
                                hasRecommendations={Boolean(
                                    constellationRecommendedUsers &&
                                        constellationRecommendedUsers.length
                                )}
                                hasConnections={Boolean(
                                    constellationConnectedUsers && constellationConnectedUsers.length
                                )}
                                isOnUpdate={isOnUpdate}
                                isLoading={isLoading}
                                onTabValueSelected={onTabClick}
                                saveToProfile={onSaveToProfile}
                                isButtonDisabled={
                                    constellationLabelsSelected.size === 0
                                }
                            />
                        </div>
                        <ParentSize data-testid="constellationGraph">
                            {(parent) => (
                                <CommunityConstellationGraphBase
                                    parentWidth={parent.width}
                                    parentHeight={parent.height}
                                    parentTop={parent.top}
                                    parentLeft={parent.left}
                                    // this is the referer to the wrapper component
                                    parentRef={parent.ref}
                                    // this function can be called inside MySuperCoolVxChart to cause a resize of the wrapper component
                                    resizeParent={parent.resize}>
                                    {currentUser &&
                                        currentTab ===
                                            CONSTELLATION_TABS.RECOMMENDED && (
                                            <CommunityConstellationGraph
                                                currentUser={currentUser}
                                                userLabels={constellationLabels}
                                                userList={constellationRecommendedUsers}
                                                parentWidth={parent.width}
                                                parentHeight={parent.height}
                                                parentTop={parent.top}
                                                parentLeft={parent.left}
                                                graphUsersType={
                                                    CONSTELLATION_USER_TYPE.RECOMMENDED
                                                }
                                                isSelectionEnabled={
                                                    constellationRecommendedUsers &&
                                                    constellationRecommendedUsers.length ===
                                                        0
                                                }
                                            />
                                        )}
                                    {currentUser &&
                                        currentTab ===
                                            CONSTELLATION_TABS.CONNECTIONS && (
                                            <CommunityConstellationGraph
                                                currentUser={currentUser}
                                                userLabels={constellationLabels}
                                                userList={constellationConnectedUsers}
                                                parentWidth={parent.width}
                                                parentHeight={parent.height}
                                                parentTop={parent.top}
                                                parentLeft={parent.left}
                                                graphUsersType={
                                                    CONSTELLATION_USER_TYPE.CONNECTED
                                                }
                                                isSelectionEnabled={false}
                                            />
                                        )}
                                </CommunityConstellationGraphBase>
                            )}
                        </ParentSize>
                    </div>
                </Grid>
            </div>
            <CommunityConnectModal data-testid="connectModal" />
            <CommunitySuccessModal data-testid="successModal" />
            <CommunityDeleteModal data-testid="deleteModal"
                                  getConstellationConnections={getConstellationConnections}  />
            <CommunityConstellationGraphTooltip
                    data-testid="graphTooltip"
                    currentScreenWidth={currentGraphWidth}
                />
        </div>
    );
};

CommunityConstellation.propTypes = {
    fullscreenView: bool.isRequired,
    constellationLabels: arrayOf(
        shape({
            isKeyword: bool.isRequired,
            value: string.isRequired,
        })
    ),
    constellationLabelsSelected: instanceOf(Set).isRequired,

    updateSnackBar: func.isRequired,
    initLabels: func.isRequired,
    clearAllLabelsSelection: func.isRequired,
    resetLabelNodeParams: func.isRequired,
    constellationRecommendedUsers: arrayOf(
        shape({
            userIcon: string.isRequired,
            current: bool.isRequired,
            designation: string.isRequired,
            firstName: string.isRequired,
            fullName: string.isRequired,
            id: number.isRequired,
            isUserUnsubscribedFromPendingNotificationEmail: bool.isRequired,
            lastName: string.isRequired,
            organization: string.isRequired,
            userSub: string.isRequired,
            labelsAnswer: arrayOf(string),
            labelsKeyword: arrayOf(string),
            connectionLinks: arrayOf(string),
            weight: number,
            recommendationId: number,
            connectionId: number,
            status: string,
            email: string.isRequired
        })
    ),
};

/**
 *  Exports
 */
export default CommunityConstellation;

import {
    CHANGE_STEPPER_COUNT, SET_ONBOARDING_FIRST_NAME, SET_ONBOARDING_LAST_NAME, SET_USERS_LINKEDIN_PROFILE,
    SET_USERS_GITHUB_PROFILE,
    SET_USERS_GOOGLE_PROFILE, SET_USERS_TWITTER_PROFILE, SET_USERS_ORCHID_PROFILE, SET_USERS_PERSONAL_PROFILE,
    SET_USERS_DESIGNATION, SET_USERS_OTHER_DESIGNATION, SET_USERS_ORGANIZATION_NAME, SET_USERS_BIO, SET_PROFILE_PICTURE_OF_USER, SET_TAGS_INPUT_TEXT,
    SET_TAGS_LIST, SET_KEYWORDS_LIST, CLEAR_ONBOARD_FORM, SET_ONBOARD_INITIAL_STATE,
    SET_ONBOARDING_TITLE, SET_ONBOARDING_DEPARTMENT, SET_ONBOARDING_INSTITUTION_NAME, SET_ONBOARDING_CITY,
    SET_ONBOARDING_COUNTRY, SET_ONBOARDING_STATE, SET_ONBOARDING_SECTOR, SET_ONBOARDING_DEGREE, SET_RESEARCHER_GOALS,
    SET_ONBOARDING_HIGHEST_EDUCATION, SET_EDIT_STREET_ADDRESS, SET_EDIT_ZIP_CODE, SET_SCIENTIFIC_BACKGROUND,
    SET_ZONE_2_REQUEST_PERSONAL_DETAILS_INITIAL_STATE, SET_OTHER_ORG_NAME_PROP, SET_OTHER_HIGHEST_EDUCATION, SET_OTHER_ORGANIZATION_NAME
} from "../action-types"
import { USER_ONBOARD_FORM_ERROR_TEXT } from '../../constants/strings'

export const changeStepperCount = (value) => {
    return {
        type: CHANGE_STEPPER_COUNT,
        payload: value
    }
}
export const setEditStreetAddress = (value) => {
    return {
        type: SET_EDIT_STREET_ADDRESS,
        payload: value
    }
}
export const setEditZipCode = (value) => {
    return {
        type: SET_EDIT_ZIP_CODE,
        payload: value
    }
}
export const setOnboardingFirstName = (value) => {
    return {
        type: SET_ONBOARDING_FIRST_NAME,
        payload: value
    }
}

export const setOnboardingTitle = value => {
    return {
        type: SET_ONBOARDING_TITLE,
        payload: value
    }
}

export const setOnDepartmentChange = value => {
    return {
        type: SET_ONBOARDING_DEPARTMENT,
        payload: value
    }
}


export const setOnboardingInstitutionName = value => {
    return {
        type: SET_ONBOARDING_INSTITUTION_NAME,
        payload: value
    }
}

export const setOnboardingStateName = value => {
    return {
        type: SET_ONBOARDING_STATE,
        payload: value
    }
}

export const setOnboardSector = value => {
    return {
        type: SET_ONBOARDING_SECTOR,
        payload: value
    }
}

export const setOnboardingDegree = (value) => {
    return {
        type: SET_ONBOARDING_DEGREE,
        payload: value
    }
}

export const setOnboardingCity = value => {
    return {
        type: SET_ONBOARDING_CITY,
        payload: value
    }
}

export const setOnboardingCountry = value => {
    return {
        type: SET_ONBOARDING_COUNTRY,
        payload: value
    }
}

export const setOnboardingLastName = (value) => {
    return {
        type: SET_ONBOARDING_LAST_NAME,
        payload: value
    }
}
export const setUsersDesignation = (value) => {
    return {
        type: SET_USERS_DESIGNATION,
        payload: value
    }
}
export const setUsersOtherDesignation = (value) => {
    return {
        type: SET_USERS_OTHER_DESIGNATION,
        payload: value
    }
}
export const setUsersOrganizationName = (value) => {
    return {
        type: SET_USERS_ORGANIZATION_NAME,
        payload: value
    }
}
export const setUsersBio = (value) => {
    return {
        type: SET_USERS_BIO,
        payload: value
    }
}
export const setUsersLinkedinProfile = (value) => {
    return {
        type: SET_USERS_LINKEDIN_PROFILE,
        payload: value
    }
}
export const setUsersGithubProfile = (value) => {
    return {
        type: SET_USERS_GITHUB_PROFILE,
        payload: value
    }
}
export const setUsersTwitterProfile = (value) => {
    return {
        type: SET_USERS_TWITTER_PROFILE,
        payload: value
    }
}
export const setUsersGoogleProfile = (value) => {
    return {
        type: SET_USERS_GOOGLE_PROFILE,
        payload: value
    }
}
export const setUsersOrchidProfile = (value) => {
    return {
        type: SET_USERS_ORCHID_PROFILE,
        payload: value
    }
}
export const setUsersPersonalProfile = (value) => {
    return {
        type: SET_USERS_PERSONAL_PROFILE,
        payload: value
    }
}
export const setProfileImageOfUser = (value) => {
    return {
        type: SET_PROFILE_PICTURE_OF_USER,
        payload: value
    }
}
export const setTagsInputText = (value) => {
    return {
        type: SET_TAGS_INPUT_TEXT,
        payload: value
    }
}
export const setTagList = (value) => {
    return {
        type: SET_TAGS_LIST,
        payload: value
    }
}
export const setKeywordList = (value) => {
    return {
        type: SET_KEYWORDS_LIST,
        payload: value
    }
}

export const setResearcherGoals = value => {
    return {
        type: SET_RESEARCHER_GOALS,
        payload: value
    }
}
export const clearOnboardForm = () => {
    return {
        type: CLEAR_ONBOARD_FORM
    }
}
export const setOnboardingEducation = (value) => {
    return {
        type: SET_ONBOARDING_HIGHEST_EDUCATION,
        payload: value
    }
}
export const setScientificBackground = value => {
    return {
        type: SET_SCIENTIFIC_BACKGROUND,
        payload: value
    }
}
export const setOtherOrgNameProp = value => {
    return {
        type: SET_OTHER_ORG_NAME_PROP,
        payload: value
    }
}
export const setOtherHighestEducation = value => {
    return {
        type: SET_OTHER_HIGHEST_EDUCATION,
        payload: value
    }
}
export const setOtherOrgName = value => {
    return {
        type: SET_OTHER_ORGANIZATION_NAME,
        payload: value
    }
}

export const updateInitialOnboardState = body => {
    const initialState = {
        stepCount: 0,
        firstName: { text: '', isValid: false, isSubmit: false, invalidText: USER_ONBOARD_FORM_ERROR_TEXT.FIRST_NAME },
        lastName: { text: '', isValid: false, isSubmit: false, invalidText: USER_ONBOARD_FORM_ERROR_TEXT.LAST_NAME },
        designation: '',
        otherDesignation: '',
        organization: '',
        bio: '',
        profileImage: null,
        tagsInputText: '',
        keywordList: [],
        tagList: [],
        github: '',
        linkedin: '',
        google: '',
        twitter: '',
        orchid: '',
        personal: '',
        highestEducation: '',
        researchersGoals: { text: '', isValid: false, isSubmit: false, invalidText: "Please enter the researcher goals" },
        otherHighestEducation: { text: '', isValid: false, isSubmit: false, invalidText: 'Please enter valid highest education' },
        otherOrganizationName: ''
    }
    initialState.firstName.text = body.first_name || "";
    initialState.lastName.text = body.last_name || "";
    initialState.designation = body["designation"] || "";
    initialState.otherDesignation = body["otherDesignation"] || "";
    initialState.organization = body["organization"] || ""
    initialState.bio = body['professional_bio'] || ""
    initialState.tagList = body['tags'] || []
    initialState.github = body?.custom_attributes?.github || "";
    initialState.linkedin = body?.custom_attributes?.linkedin || "";
    initialState.google = body?.custom_attributes?.google || "";
    initialState.twitter = body?.custom_attributes?.twitter || "";
    initialState.personal = body?.custom_attributes?.personal || "";
    initialState.orchid = body?.custom_attributes?.orchid || "";
    initialState.highestEducation = body["highestEducation"] || "";
    initialState.streetAddress = body?.institution_details?.['streetAddress'] || '';
    initialState.zipCode = body?.institution_details?.['zip_code'] || '';
    initialState.researchersGoals.text = body?.institution_details?.['researcherGoals'] || '';
  
    return {
        type: SET_ONBOARD_INITIAL_STATE,
        payload: initialState
    }
}
export const zone2AccessProfileInitialState = value => {
    const initialState = {
        firstName: { text: '', isValid: false, isSubmit: false, invalidText: USER_ONBOARD_FORM_ERROR_TEXT.FIRST_NAME },
        lastName: { text: '', isValid: false, isSubmit: false, invalidText: USER_ONBOARD_FORM_ERROR_TEXT.LAST_NAME },
        designation: '',
        otherDesignation: '',
        organization: { text: '', isValid: false, isSubmit: false, },
        highestEducation: '',
        city: value?.institution_details?.['city'] || '',
        streetAddress: value?.institution_details?.['address'] || '',
        state: value?.institution_details?.['state'] || '',
        zipCode: value?.institution_details?.['zip_code'] || '',
        country: value?.institution_details?.['country'] || '',
        institutionName: value?.institution_details?.['institution_name'],
        researchersGoals: { text: '', isValid: false, isSubmit: false, invalidText: "Please enter the researcher goals" },
        otherOrganizationName: '',
        scientificBackground: { text: '', isValid: false, isSubmit: false, invalidText: "Please enter the scientific background" }
        
    }
    initialState.firstName.text = value.first_name || "";
    initialState.lastName.text = value.last_name || "";
    initialState.designation = value["designation"] || "";
    initialState.otherDesignation = value["otherDesignation"] || "";
    initialState.organization.text = value["organization"] || "";
    initialState.highestEducation = value["highestEducation"] || "";
    initialState.researchersGoals.text = value?.institution_details?.['researcherGoals'] || '';
    initialState.scientificBackground.text = value?.custom_attributes?.['scientificBackground'] || '';
    return {
        type: SET_ZONE_2_REQUEST_PERSONAL_DETAILS_INITIAL_STATE,
        payload: initialState
    }
}

import { Box, Paper, Typography, Stack, alpha } from '@mui/material';
import { map, round } from 'lodash';
import React, { useEffect } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer,
  Rectangle,
} from 'recharts';
import { hexColorVariants } from '../../../../../utils/generatePalette';
import { useColors } from '../../../../../utils/hooks';

import { ATTRIBUTES_MAP, ceilFilterValues, floorFilterValues, trimText } from '../../utils';
import { vars } from '../../../../../assets/variables';
import SVGTooltip from '../../../../commons/svg-tooltip/Tooltip';

const { primaryTextColor } = vars;

const rangeFractions = 4;
const maxChars = 8;
const COLOR_TYPE = 'hex';
const textThreshold = 12;

function defaultTermsField(field) {
  return [
    {
      field,
      terms: [
        {
          key: null,
          count: 0,
        },
      ],
    },
  ];
}

function dataMinMax(data, xKey) {
  const firstEl = data[0].termsFields[0].terms[0][xKey];
  const minMax = { min: firstEl, max: firstEl };
  for (const el of data) {
    for (const v of el.termsFields[0].terms) {
      if (v[xKey] > minMax.max) {
        minMax.max = parseFloat(v[xKey]);
      }
      if (v[xKey] < minMax.min) {
        minMax.min = parseFloat(v[xKey]);
      }
    }
  }
  return minMax;
}

function chunkText(text, rx) {
  return text
    .replace(/-/g, ' ')
    .split(' ')
    .map((s) => s.match(rx))
    .flat();
}

function chunksToSpan(chunks) {
  return chunks.map((s, i) => (
    <tspan
      x={0}
      y={0}
      dy={i * 12}
      key={`${s}_${i}`}
      style={{ textTransform: 'capitalize' }}
    >
      {s}
    </tspan>
  ));
}

// TODO implement custom x-axis label
function OverviewBarChart(props) {
  const { chartData, xKey, valKey, xLabel, isStacked, field, valBins, xUnit } =
    props;
  const xAxisRef = React.createRef();

  function roundChartKeyValues(chartObj) {
    return {
      ...chartObj,
      key: Array.isArray(chartObj.key) ? [
        floorFilterValues(chartObj.key[0]), ceilFilterValues(chartObj.key[1])
      ] : chartObj.key
    };
  }


  function formatData() {
    return chartData.map((d) => {
      const roundedData = roundChartKeyValues(d)

      if (roundedData.termsFields === null || undefined) {
        return {
          ...roundedData,
          count: roundedData.count < 0 ? 0 : roundedData.count,
          termsFields: defaultTermsField(field),
        };
      }
      return roundedData;


    });
  }
  const [data, setData] = React.useState(() => formatData());

  const colors = useColors(COLOR_TYPE);

  const isNumbers = isStacked && !isNaN(data[0]?.termsFields[0]?.terms[0].key); // Assumption: if first is number, all are numbers
  let dataRange = null;

  if (isNumbers) {
    dataRange = dataMinMax(data, xKey, valKey);
  }

  function allKeys() {
    let keys = new Set();

    if (isNumbers) {
      if (valBins) return [...Object.values(valBins), 'N/A'];

      const step = (dataRange.max - dataRange.min) / rangeFractions;
      const ranges = [...Array(rangeFractions)].map(
        (x, i) => dataRange.min + i * step
      );
      return ranges.map(
        (r, i) =>
          `${r}-${i < ranges.length - 1 ? ranges[i + 1] : dataRange.max}`
      );
    } else {
      for (const v of data) {
        keys = new Set([
          ...Object.values(v.termsFields?.[0].terms ?? {}).map(
            (term) => term.key
          ),
          ...keys,
        ]);
      }
    }
    keys.add('N/A');

    return Array.from(keys);
  }

  function rangedValueToKey(v) {
    if (valBins) {
      return (
        valBins[Object.keys(valBins).find((k) => parseInt(k) > parseInt(v))] ??
        'N/A'
      );
    }
    const step = (dataRange.max - dataRange.min) / rangeFractions;
    return keys[Math.floor(v / step)] || keys[keys.length - 1];
  }

  function keyValues(dataElement) {
    let tot = 0;
    const obj = { 'N/A': 0 };

    if (!isNumbers) {
      for (const term of dataElement.termsFields?.[0].terms) {
        tot += term[valKey];
        obj[term[xKey]] = term[valKey];
      }
    } else {
      for (const rangeValue of keys) {
        obj[rangeValue] = 0;
      }
      for (const term of dataElement.termsFields?.[0].terms) {
        tot += term[valKey];
        obj[rangedValueToKey(term[xKey])] += term[valKey];
      }
    }

    if (tot < dataElement.count) {
      obj['N/A'] += dataElement.count - tot;
    }
    return obj;
  }

  function keyToLabel(histElement) {
    if (Array.isArray(histElement[xKey])) {
      return histElement[xKey].join('-') + xUnit;
    }
    return histElement[xKey].replace('_', ' ');
  }

  function flattenData() {
    const sorted = data.sort((d, d1) => d1[valKey] - d[valKey]);

    const flatten = sorted.map((d) => {
      return {
        [valKey]: d[valKey],
        field: d.termsFields?.[0].field,
        terms: d.termsFields?.[0].terms,
        [xKey]: keyToLabel(d),
        ...keyValues(d),
      };
    });

    return flatten;
  }

  const keys = isStacked && allKeys(valKey);

  useEffect(() => {
    const formattedData = formatData();
    setData(formattedData);
  }, [chartData]);

  var tooltip;
  //Custom Tooltip popper
  const CustomTooltip = ({ active, payload }) => {
    if (!active || !tooltip) return null;
    for (const bar of payload)
      if (bar.dataKey === tooltip) {
        return (
          <Paper
            elevation={1}
            sx={(theme) => ({
              paddingY: '0.5rem',
              paddingX: theme.spacing(1),
              border: 'none',
              maxWidth: '350px',
            })}
          >
            {isStacked && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <div
                  style={{
                    backgroundColor: `${bar.color}`,
                    width: '0.75rem',
                    height: '0.75rem',
                    borderRadius: '50%',
                  }}
                />
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  textTransform="capitalize"
                >
                  {`${
                    ATTRIBUTES_MAP[bar.payload.field]?.title
                      ? ATTRIBUTES_MAP[bar.payload.field]?.title + ':'
                      : ''
                  }${bar.name}`}
                </Typography>
              </Stack>
            )}
            {map(Object.keys(bar.payload), (key, index) => {
              const isTooltipKeyPresent = key === tooltip;

              return isTooltipKeyPresent ? (
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  textTransform="capitalize"
                  key={index}
                >
                  {`# ${round(bar.payload[key])}`}
                </Typography>
              ) : null;
            })}
          </Paper>
        );
      }

    return null;
  };

  return (
    <>
      <ScrollContainer vertical={false}>
        <Box minWidth={data.length * 40}>
          <ResponsiveContainer width="100%" height={350}>
            <BarChart
              width="100%"
              data={flattenData(data, xKey, valKey)}
              barCategoryGap={'25%'}
              margin={[0, 0, 0, 0]}
              barGap={20}
            >
              <CartesianGrid vertical={false} stroke="#F2F4F7" />
              <XAxis
                dataKey={xKey}
                scaleToFit={true}
                axisLine={false}
                tickLine={false}
                interval={0}
                height={80}
                stroke={primaryTextColor}
                tick={({ x, y, payload }) => {
                  // word wrap implementation: https://github.com/recharts/recharts/issues/961
                  const rx = new RegExp(`.{1,${maxChars}}`, 'g');
                  const chunks = chunkText(
                    trimText(payload.value, textThreshold),
                    rx
                  );
                  //* svg tooltip value chunk logic
                  // const valueRX = new RegExp(`.{1,${valueMaxChars}}`, 'g');
                  // const valueChunks = chunkText(payload.value, valueRX);
                  const tspans = chunksToSpan(chunks);
                  return (
                    <g
                      transform={`translate(${x},${y})`}
                      key={`${payload.value}`}
                    >
                      <text
                        width={70}
                        textAnchor="middle"
                        fontSize={10}
                        fill={primaryTextColor}
                        transform="rotate(-70 10 10)"
                        ref={xAxisRef}
                      >
                        {tspans}
                      </text>
                      {payload.value?.length > textThreshold ? (
                        <SVGTooltip triggerRef={xAxisRef} width="80%">
                          <rect
                            x={2}
                            y={2}
                            width={'100%'}
                            height={28}
                            rx={0.5}
                            ry={0.5}
                            fill="white"
                            radius={4}
                            filter="drop-shadow(0 0.0625rem 0.125rem rgba(231, 234, 242, 0.6))"
                          />

                          <text
                            x={28}
                            y={20}
                            fontSize={12}
                            fill={primaryTextColor}
                          >
                            {payload.value}
                          </text>
                        </SVGTooltip>
                      ) : null}
                    </g>
                  );
                }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                lineHeight={10}
                fontSize={10}
                stroke={primaryTextColor}
                width={38}
              >
                <Label
                  value={'Number of data points'}
                  position="insideLeft"
                  angle={-90}
                  color="#ff0000"
                  style={{
                    textAnchor: 'middle',
                    fill: '#667085',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                />
              </YAxis>
              <Tooltip
                cursor={false}
                itemStyle={{
                  fontSize: '12px',
                  margin: 0,
                  padding: 0,
                }}
                labelStyle={{
                  fontSize: '14px',
                }}
                content={<CustomTooltip data={data} />}
              />

              {!!data && isStacked && keys.length ? (
                keys.map((key, index) => {
                  return (
                    <Bar
                      id={`${key}`}
                      dataKey={key}
                      stackId="x"
                      key={`${key}`}
                      barSize={data.length < 6 ? 24 : undefined}
                      shape={(props) => {
                        if (props.value[1] == props[valKey]) {
                          props.radius = [4, 4, 0, 0];
                        }

                        if (props.y + props.height >= 260) {
                          props.radius = [0, 0, 4, 4];
                        }

                        if (props.payload[props.id] === props[valKey]) {
                          props.radius = [4, 4, 4, 4];
                        }

                        return <Rectangle {...props} />;
                      }}
                      onMouseOver={() => (tooltip = key)}
                      fill={alpha(
                        colors[index % colors.length],
                        hexColorVariants[
                          Math.floor(index / colors.length) %
                            hexColorVariants.length
                        ]
                      )}
                    />
                  );
                })
              ) : (
                <Bar
                  id={valKey}
                  dataKey={valKey}
                  radius={4}
                  barSize={24}
                  onMouseOver={() => (tooltip = valKey)}
                >
                  {data.map((entry, index) => (
                    <Cell key={index} fill="#4D80C6" d />
                  ))}
                </Bar>
              )}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </ScrollContainer>
      <Box textAlign="center" mt="0 !important">
        <Typography component="span" fontSize={14} fontWeight={500}>
          {xLabel}
        </Typography>
      </Box>
    </>
  );
}

export default OverviewBarChart;

/**
 *  External Imports
 */
import Grid from "@mui/material/Grid";

import React from "react";

/**
 *  Internal Imports
 */
import iconGitHub from "../../../../assets/icons/icon-github-b.svg";
import iconGoogleScholar from "../../../../assets/icons/icon-google-scholar-b.svg";
import iconLinkedIn from "../../../../assets/icons/icon-linkedin-b.svg";
import iconORCID from "../../../../assets/icons/icon-orcid-b.svg";
import iconTwitter from "../../../../assets/icons/icon-twitter-b.svg";
import iconWeb from "../../../../assets/icons/icon-web-b.svg";

import styles from "./index.module.css";

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 */
const WidgetFindConnections = (props) => {
	// Variables
	const socialLinks = [
		{
			icon: iconGitHub,
			iconAlt: "Icon GitHub",
		},
		{
			icon: iconORCID,
			iconAlt: "Icon ORCID",
		},
		{
			icon: iconLinkedIn,
			iconAlt: "Icon LinkedIn",
		},
		{
			icon: iconTwitter,
			iconAlt: "Icon Twitter",
		},
		{
			icon: iconGoogleScholar,
			iconAlt: "Icon Google Scholar",
		},
		{
			icon: iconWeb,
			iconAlt: "Icon Web",
		},
	];
	
	// Return
	return (
        <div className={styles.widgetFindConnections}>
			
			<Grid container
			
			      className={styles.title}>
				
				Find connections
			</Grid>
			
			<Grid container
			
			      direction="column"
			      justifyContent="center"
			      alignItems="center"
			
			      className={styles.linksContainer}>
				
				{
					socialLinks.map(({icon, iconAlt}, index) => (
						<img className={styles.linkItemIcon}
						
						     key={index}
						
						     src={icon}
						     alt={iconAlt}
						/>
					))
				}
			</Grid>
			
			<Grid container
			
			      direction="row"
			      justifyContent="center"
			      alignItems="center"
			
			      className={styles.description}>
				
				Connect you to other users by your social profiles
			</Grid>
		</div>
    );
};

/**
 *  Exports
 */
export default WidgetFindConnections;

import React, { useEffect, useState } from 'react'
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { navigate } from '@reach/router'
import Classes from './index.module.css'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import PreviewOn from '../../../assets/images/PreviewOn.png';
import PreviewOff from '../../../assets/images/PreviewOff.png';
import BCLogo from '../../../assets/images/BRAINCommons-Platform.svg'
import ShadowImage from '../../../assets/images/shadow.png'
import OutlinedInput from '@mui/material/OutlinedInput'
import LeftContainerForLogin from './loginLeftContainer'
import { SIGNUP_ERROR_TEXTS } from '../../../constants/strings';
import ErrorText from '../../controls/error-text';
import InfoText from '../../controls/info-text';
import get from 'lodash/get'
import { VALID_EMAIL_REGEX } from '../../../constants/regex';
import * as G_Auth from '../../../constants/google-login-urls'
import { Auth } from 'aws-amplify';
import { getCookie, getNewsArticles, sendGAEvents, setCookie, updateGAEventsPageTitleOnPageChange, signUpStatus } from '../../../api/generic-api'
import {
  upperCaseCheckForPassword, specialCharCheckForPassword, numberCharCheckForPassword,
  lowerCharCheckForPassword, minLengthCheckForPassword
} from '../../../utils/input-validations'
import { getResourceActions } from '../../../utils/resourceActionsFormatter';
import { getUserProfile, getUserPermissions } from '../../../api/profile-api'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import SuccessTickDisabled from '../../../assets/images/successtick.png'
import SuccessTick from '../../../assets/images/successtickenabled.png'
import GmailLoginIcon from '../../../assets/icons/gmailLoginIcon.svg'
import OutlookLoginIcon from '../../../assets/icons/OutlookIcon.svg'
import IncommonsLoginIcon from '../../../assets/icons/IncommonsIcon.svg'
import Tooltip from '@mui/material/Tooltip'
import InfoIconModal from '../../../assets/icons/InfoIconModal.svg'
import Modal from '../../commons/modal'
import ConfirmCongitoSignUp from '../confirm-cognito-signup';
import Checkbox from '@mui/material/Checkbox';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FCF8C8",
    color: "#222222",
    boxShadow: "-1px 1px 106px -61px rgba(74,73,74,1);",
    fontSize: 14,
    fontWeight: "medium",
    marginRight: '3px',
    fontFamily: "Raleway",
    borderRadius: 0,
    padding: 15,
    textAlign: "left"
  },
  arrow: {
    color: "#FCF8C8"
  },
  customWidth: {
    maxWidth: 200,
  },
}))(Tooltip);

const Login = props => {
  const [email, setEmail] = useState({
    text: '',
    isValid: false,
    isSubmit: false,
    invalidText: SIGNUP_ERROR_TEXTS.EMAIL
  })
  const [password, setPassword] = useState("")
  const [passStrengthCheck, setPassStrengthCheck] = useState({});
  const [disableNextButton, setDisableNextButton] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [authError, setAuthError] = useState("")
  const [newsArticles, setNewsArticles] = useState(null)
  const [loginLoader, setLoginLoader] = useState(false)
  const [updatePassword, setUpdatePassword] = useState(false)
  const [openSocialMediaPopup, setOpenSocialMediaPopup] = useState(false);
  const [loginType, setLoginType] = useState('');
  const [verifyMFA, setVerifyMFA] = useState(false);
  const [mfaUserChallengeObj, setMFAUserChallengeObj] = useState({});
  const [signUpAllowed, setSignUpAllowed] = useState(false);
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    if (props?.isScriptInjected || false) {
      updateGAEventsPageTitleOnPageChange('login');
    }
  }, [props?.isScriptInjected || false])
  useEffect(() => {
    getNewsArticlesData();
    fetchSignUpStatus();
  }, []);

  const fetchSignUpStatus = async () => {
    try {
      const details = await signUpStatus();
      setSignUpAllowed(details?.sign_up_allowed);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    try {
      var search = window.location.search.substring(1);
      const parsedData = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      let errorDescription = parsedData?.error_description || parsedData?.message || "";
      errorDescription = errorDescription.split("+").join(" ")
      if (errorDescription) {
        if(`${errorDescription}`.includes('PreSignUp failed with error')){
          errorDescription = errorDescription.split('PreSignUp failed with error')[1];
        }
        setAuthError(errorDescription)
      }
    }
    catch (error) {
      console.log(error)
    }

  }, [])

  const getNewsArticlesData = async () => {
    try {
      const response = await getNewsArticles();
      setNewsArticles(response)
    }
    catch (error) {
      console.log(error)
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onChangeEmail = event => {
    setEmail({ ...email, text: event.target.value, isSubmit: false });
    setAuthError("")
  }

  const onChangePassword = event => {
    setPassword(event.target.value)
    setAuthError("")
  }

  const handleLogin = async (response) => {
    try {
      if (response) {
        const idToken = get(response, 'signInUserSession.idToken.jwtToken', '');
        const customRoleArn = get(response, 'signInUserSession.idToken.payload.cognito:roles[0]', '');
        sessionStorage.setItem('cognito_id_token', idToken);
        sessionStorage.setItem('custom-role-arn', customRoleArn);
        // await props.getCookieFromFence()
        // const userDetails = await props.validateCookie();

        const userValues = await Auth.currentUserInfo();
        const userInfo = await getUserProfile(userValues?.attributes?.sub);
        const userPermissions = await getUserPermissions();
        if (userInfo?.user_details) {
          let resourceActions = getResourceActions(userPermissions);
          userInfo.user_details['permissions'] = userPermissions;
          userInfo.user_details['resourceActions'] = resourceActions;
          props.updateUserDetails(userInfo.user_details);
        }
        await props.updateUserLogin(response)
        if (props.location?.pathname && !props.location?.pathname.includes("login")) {
          navigate((props.location?.pathname || "") + (props.location?.search || ""));
        } else {
          navigate("/explore")
        }
        setLoginLoader(false);
      }
    }
    catch (error) {
      if (error.message == "User is not confirmegind.") {
        Auth.resendSignUp(email['text'])
        navigate("/confirm-signup?email=" + email['text'], { state: { userEmailId: email['text'] } })
      }
      console.log(error)
      setAuthError(error?.message || "Unable to login");
      setLoginLoader(false);
      if (verifyMFA) {
        props.updateSnackBar(error.message + ' Please login again.', "error");
      }
    }
  }

  const handleCustomAuthSignIn = async () => {
    try {
      props.onLoginButtonClick();
      sendGAEvents('page_view', '', '', { page_title: 'login' });
      let re = VALID_EMAIL_REGEX;
      const isValidEmail = re.test(email['text']);
      if (isValidEmail && password && !props.showCookieBanner) {
        setEmail({ ...email, isSubmit: true, isValid: true });
        setLoginLoader(true);
        await Auth.signIn(email['text'], password)
          .then(user => {
            if (user?.challengeParam) {
              setVerifyMFA(true);
              setMFAUserChallengeObj(user);
            } else {
              setVerifyMFA(false);
              handleLogin(user);
            }
          })
          .catch(error => {
            if (error.message == "User is not confirmed.") {
              Auth.resendSignUp(email['text'])
              navigate("/confirm-signup?email=" + email['text'], { state: { userEmailId: email['text'] } })
            }
            console.log(error)
            setAuthError(error?.message || "Unable to login");
            setLoginLoader(false)
          });
      } else {
        if (isValidEmail) {
          setEmail(({ ...email, isSubmit: true, isValid: true }));
        } else {
          setEmail(({ ...email, isSubmit: true, isValid: false }));
        }
      }
    } catch (error) {
      setLoginLoader(false)
      console.log(error);
    }
  }

  useEffect(() => {
    checkForPassStrength();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword])

  const checkForPassStrength = () => {
    let passStrengths = cloneDeep(passStrengthCheck);
    let isUpperCaseCharPresent = false;
    let isSpecialCharPresent = false;
    let isNumberPresent = false;
    let isLowerCaseCharPresent = false;
    let minEightCharPresent = false;
    if (!isEmpty(newPassword)) {
      isUpperCaseCharPresent = upperCaseCheckForPassword(newPassword);
      isSpecialCharPresent = specialCharCheckForPassword(newPassword);
      isNumberPresent = numberCharCheckForPassword(newPassword);
      isLowerCaseCharPresent = lowerCharCheckForPassword(newPassword);
      minEightCharPresent = minLengthCheckForPassword(newPassword);
    }
    passStrengths = {
      upperCaseChar: isUpperCaseCharPresent,
      specialChar: isSpecialCharPresent,
      numberChar: isNumberPresent,
      lowerCaseChar: isLowerCaseCharPresent,
      minChar: minEightCharPresent
    }

    const disNextButton = isUpperCaseCharPresent && isSpecialCharPresent && isNumberPresent
      && isLowerCaseCharPresent && minEightCharPresent;
    setDisableNextButton(disNextButton);
    setPassStrengthCheck(passStrengths);
    return !disNextButton;
  }

  const handleCognitoSigIn = () => {
    sendGAEvents('login', 'login', 'login to portal', { method: 'cognito', page_title: 'login' });
    return Auth.signIn({ username: email.text, password: password })
  }

  const handleGoogleFederatedSignIn = async () => {
    try {
      if (!props.showCookieBanner) {
        sendGAEvents('login', 'login', 'login to portal', { method: 'google', page_title: 'login' });
        const response = await Auth.federatedSignIn({ provider: 'Google' });
      }
    }
    catch (error) {
      setAuthError(error?.message || "Something went wrong, Please try again.")
    }
  }

  const handleOffice365FederatedSignIn = async () => {
    try {
      if (!props.showCookieBanner) {
        sendGAEvents('login', 'login', 'login to portal', { method: 'office365', page_title: 'login' });
        const response = await Auth.federatedSignIn({ customProvider: 'MicrosoftAzure' });
      }
    }
    catch (error) {
      setAuthError(error?.message || "Something went wrong, Please try again.")
    }
  }

  const handleInCommonsSignIn = async () => {
    try {
      if (!props.showCookieBanner) {
        sendGAEvents('login', 'login', 'login to portal', { method: 'incommons', page_title: 'login' });
        const response = await Auth.federatedSignIn({ customProvider: 'InCommons' });
      }
    }
    catch (error) {
      setAuthError(error?.message || "Something went wrong, Please try again.")
    }
  }

  const handleOnKeyDown = event => {
    if (event.keyCode == 13) {
      handleCustomAuthSignIn();
    }
  }

  const handleUpdatePassword = async () => {
    try {
      if (confirmNewPassword === newPassword) {
        const user = await Auth.signIn(email['text'], password);
        const response = await Auth.completeNewPassword(user, newPassword, {
          "given_name": "user",
          "family_name": "user",
          "email": email['text']
        })
        if (response) {
          window.location.reload();
        }
        else {
          setAuthError("Try again!")
        }
      }
      else {
        setAuthError("New Password and Confirm New Password doesn't match")
      }

    }
    catch (error) {
      setAuthError(error.message)
    }
  }

  const onChangeNewPassword = event => {
    setNewPassword(event.target.value)
  }

  const onChangeConfirmPassword = event => {
    setConfirmNewPassword(event.target.value)
  }
  const onCreateAnAccountClick = () => {
    props.onLoginButtonClick();
    if (!props.showCookieBanner) {
      navigate('/register', { state: { signUpAllowed: signUpAllowed } });
    }
  }
  const onForgotPasswordClick = () => {
    props.onLoginButtonClick();
    if (!props.showCookieBanner) {
      navigate('/forgot-password')
    }
  }
  

  const checkIfSocialCookieSet = () => {
    let isSocialSignin = getCookie("cvb_social_signin");
    return isSocialSignin != "";
    //return true
  }
  const onSocialLoginConfirm = (type) => {
    setOpenSocialMediaPopup(false);
    const loginTypeValue = checkIfSocialCookieSet() ? type : loginType;
    setCookie("cvb_social_signin", true, 365);
    switch (loginTypeValue) {
      case "gmail": { handleGoogleFederatedSignIn(); break };
      case 'office365': handleOffice365FederatedSignIn(); break;
      case 'incommons': handleInCommonsSignIn(); break;
      default: break;
    }
  }
  const onSocialMediaButtonClick = async (type) => {
    props.onLoginButtonClick();
    if (!props.showCookieBanner) {
      setLoginType(type);
      if (checkIfSocialCookieSet()) {
        onSocialLoginConfirm(type);
      } else {
        setOpenSocialMediaPopup(true);
      }
    }
  }
  const signDataUsageModalPayload = {
    modalTitle: "",
    modalContent: '',
    negativeButtonText: "Cancel",
    positiveButtonText: "Confirm Sign-in",
    negativeButtonAction: () => { setOpenSocialMediaPopup(false) },
    positiveButtonAction: () => { onSocialLoginConfirm() }
  }
  return (
    <Grid container>
      {
        <Modal className={Classes.signInPolicyModal} open={openSocialMediaPopup} handleClose={() => setOpenSocialMediaPopup(false)} disableTitleDivider dialogProps={signDataUsageModalPayload} >
          <div className={Classes.signInPolicy}>
            <Grid container>
              <img className={Classes.warningImage} src={InfoIconModal} alt="Info Icon" />
              <Grid item xs={12} className={Classes.signInPolicyContent}>
                <h3 className={Classes.smCookieTitle}>Social Media Cookie</h3>
                <p>
                  No social media cookies are used on the BRAINCommons Platform. As a registered user, you’ll have a
                  customizable profile page where you can include your social media links to other sites such as LinkedIn, Google Scholar
                  and Twitter. The BRAINCommons Platform is not responsible for the processing of any personal data you may wish to
                  share with these sites. We strongly recommend that you carefully read the privacy notice and terms and conditions before
                  activating these social media links on your profile page.
                </p>
              </Grid>
            </Grid>
          </div>
        </Modal>
      }

      {verifyMFA ?
        <ConfirmCongitoSignUp
          mfaUserChallengeObj={mfaUserChallengeObj}
          handleLogin={handleLogin}
          setVerifyMFA={setVerifyMFA}
          signInLoader={loginLoader}
          setSignInLoader={setLoginLoader}
          verifyMFA
          signInEmail={email['text']}
          signInPassword={password}
        />
        :
        <React.Fragment>
          <LeftContainerForLogin newsArticles={newsArticles} />
          <Grid container alignItems='center' justifyContent='center' direction='column' item xs={6} className={Classes.signInRightContainer}>
            <div className={Classes.bcLogoContainer}>
              <img alt='braincommons log' className={Classes.bcLogo} src={BCLogo} />
              <img alt='shadow' className={Classes.shadowImage} src={ShadowImage} />
            </div>
            {
              !updatePassword ?
                <React.Fragment>
                  <p className={Classes.signInHeaderText}>Sign In</p>
                  {
                    authError &&
                    <Grid>
                      <InfoText type="Error" errorMessage={authError} />
                    </Grid>
                  }
                  <Grid item xs={6}>
                    <Grid container spacing={2} direction='column'>
                      {/* Need to Handle with sign in error condition      */}
                      {false && <Grid item>
                        <p className={Classes.signInErrorText}>
                          Username and password doesn’t match. Please try again
                        </p>
                      </Grid>}
                      <Grid xs={12} item>
                        <label>Email</label>
                        <OutlinedInput
                          error={(email['isSubmit'] && !email['isValid'])}
                          fullWidth type='email'
                          value={email['text']}
                          label={"a"}
                          onChange={onChangeEmail}
                          placeholder='Enter email'
                          autoComplete='off'
                          name='email'
                          InputProps={{
                            
                          }}
                        />
                        {(email['isSubmit'] && !email['isValid']) &&
                          <ErrorText errorMessage={SIGNUP_ERROR_TEXTS.EMAIL} />}
                      </Grid>
                      <Grid item className={Classes.passwordInputContainer}>
                        <label>Password</label>
                        <OutlinedInput type={showPassword ? 'text' : 'password'} placeholder='Enter your password'
                          fullWidth value={password}
                          onChange={onChangePassword}
                          onKeyDown={handleOnKeyDown}
                          error={(email['isSubmit'] && !password)}
                          label={""}
                          endAdornment={
                            <InputAdornment position="end" >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                size="large">
                                {showPassword ? <img alt='visibility On' src={PreviewOn} /> :
                                  <img alt='visibility Off' src={PreviewOff} />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {(email['isSubmit'] &&
                          !password) &&
                          <ErrorText errorMessage={SIGNUP_ERROR_TEXTS.ENTER_PASS} />}
                        <p className={Classes.forgotPasswordText}
                          onClick={onForgotPasswordClick}>Forgot your password?</p>
                      </Grid>
                      <Grid item className={Classes.signInButtonContainer}>
                        <button id='login-button' className={`solid-button ${Classes.signInButton}`}
                          disabled={loginLoader}
                          type="submit"
                          onClick={handleCustomAuthSignIn}>Sign in</button>
                      </Grid>
                      {
                        signUpAllowed &&
                        <div className={Classes.needAnAccountContainer}>
                          <p className={Classes.createAnAccountText}
                            onClick={() => onCreateAnAccountClick()}>Create an account</p>
                        </div>
                      }
                      <Grid item className={Classes.signInWithContainer}>
                        <span className={Classes.horLine}></span>
                        <p className={Classes.signInWithText}>Or Sign in with</p>
                        <span className={Classes.horLine}></span>
                      </Grid>
                      <div className={Classes.signinMethodsContainer}>
                        <LightTooltip placement="right" className={Classes.userNameTitle} arrow title="Your personal email account">
                          <button className={`contained-button ${Classes.gmailButton}`}
                            onClick={() => onSocialMediaButtonClick('gmail')}><img src={GmailLoginIcon} alt="Gmail Icon" /> Gmail</button>
                        </LightTooltip>
                      </div>
                      <Grid item className={Classes.signInWithContainerResearcher}>
                        <span className={Classes.horLineResearcher}></span>
                        <p className={Classes.signInWithTextResearcher}>Qualified Researchers must sign in with either</p>
                        <span className={Classes.horLineResearcher}></span>
                      </Grid>
                      <div className={Classes.signinMethodsContainer}>
                        {/* <LightTooltip placement="bottom" className={Classes.userNameTitle} arrow title="Your professional email account associated with your industry."> 
                                        </LightTooltip> */}
                        <button className={`contained-button ${Classes.officeButton}`} disabled={true}
                          onClick={() => onSocialMediaButtonClick('office365')}><img src={OutlookLoginIcon} alt="office Icon" /> Office 365</button>
                        <LightTooltip placement="bottom" className={Classes.userNameTitle} arrow title="Your professional email account associated with your academic institution.">
                          <button className={`contained-button ${Classes.incommonsButton}`}
                            onClick={() => onSocialMediaButtonClick('incommons')}><img src={IncommonsLoginIcon} alt="Incommons Icon" /> InCommon</button>
                        </LightTooltip>

                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment> :
                <Grid item xs={10} className={Classes.passSuccessContainer}>
                  <p className={Classes.signInHeaderText}>Update Password</p>
                  {
                    authError &&
                    <Grid>
                      <InfoText errorMessage={authError} type="Error" />
                    </Grid>
                  }
                  <Grid item>
                    <label>New Password</label>
                    <OutlinedInput
                      error={(email['isSubmit'] && !email['isValid'])}
                      fullWidth
                      value={newPassword}
                      onChange={onChangeNewPassword}
                      placeholder='Enter new password'
                      name='newPassword'
                      InputProps={{
                        classes: {
                          notchedOutline: Classes.notchedOutline
                        }
                      }}
                    />
                  </Grid>
                  {!disableNextButton ? <Grid container spacing={1} item xs={12} className={Classes.passChecksDiv}>
                    <Grid item xs={5} className={Classes.passStrengthCheck}>
                      {passStrengthCheck['upperCaseChar'] ? <img src={SuccessTick} alt='success mark' /> :
                        <img src={SuccessTickDisabled} alt='failure mark' />}
                      <p className={Classes.passStrenghtOptText}>One upper case character</p>
                    </Grid>
                    <Grid item xs={4} className={Classes.passStrengthCheck}>
                      {passStrengthCheck['specialChar'] ? <img src={SuccessTick} alt='success mark' /> :
                        <img src={SuccessTickDisabled} alt='failure mark' />}
                      <p className={Classes.passStrenghtOptText}>One special character</p>
                    </Grid>
                    <Grid item xs={3} className={Classes.passStrengthCheck}>
                      {passStrengthCheck['numberChar'] ? <img src={SuccessTick} alt='success mark' /> :
                        <img src={SuccessTickDisabled} alt='failure mark' />}
                      <p className={Classes.passStrenghtOptText}>One number</p>
                    </Grid>
                    <Grid item xs={5} className={Classes.passStrengthCheck}>
                      {passStrengthCheck['lowerCaseChar'] ? <img src={SuccessTick} alt='success mark' /> :
                        <img src={SuccessTickDisabled} alt='failure mark' />}
                      <p className={Classes.passStrenghtOptText}>One lower case character</p>
                    </Grid>
                    <Grid item xs={5} className={Classes.passStrengthCheck}>
                      {passStrengthCheck['minChar'] ? <img src={SuccessTick} alt='success mark' /> :
                        <img src={SuccessTickDisabled} alt='failure mark' />}
                      <p className={Classes.passStrenghtOptText}>Minimum 8 characters</p>
                    </Grid>
                  </Grid>
                    :

                    <Grid item xs={12} className={Classes.passSuccessContainer}>
                      <Grid direction='row' alignItems='center' container
                        className={Classes.passStrengthCheckSuccessCont}>
                        <img src={SuccessTick} alt='success mark' />
                        <p className={Classes.passStrengthCheckSuccess}>
                          Your password is secured and you are ready to proceed.</p>
                      </Grid>
                    </Grid>
                  }
                  <Grid item>
                    <label>Confirm New Password</label>
                    <OutlinedInput
                      error={(email['isSubmit'] && !email['isValid'])}
                      fullWidth
                      type={"password"}
                      value={confirmNewPassword}
                      onChange={onChangeConfirmPassword}
                      placeholder='Enter confirm password'
                      name='confirmPassword'
                      InputProps={{
                        classes: {
                          notchedOutline: Classes.notchedOutline
                        }
                      }}
                    />
                  </Grid>
                  <Grid item className={Classes.signInButtonContainer}>
                    <button className={`solid-button ${Classes.signInButton}`}
                      disabled={loginLoader || !disableNextButton}
                      onClick={handleUpdatePassword}>Update Password</button>
                  </Grid>
                </Grid>
            }
          </Grid>
        </React.Fragment>
      }
    </Grid>
  );
}

export default Login

import { Box, Button, Drawer, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { vars } from '../../../assets/variables';
import {
  getAssessmentsByProject,
  getAttributesCount,
  getClinicalDataPoints,
  getDataTypesByProject,
  getDiseasesForProject,
  getProjectsCohorts,
  getProjectsCount,
  getRecords,
  getStudyFromProject,
} from '../../../service/ProjectsService';
import SectionHeader from '../../commons/section-header';
import {
  formatFilterToServiceFilters,
  generateStatisticsFromValues,
  getInitialFilters,
} from './utils';
import { PAGES_DEFINITIONS } from '../../../constants/strings'

import { Statistics } from './connected/statistics/Statistics';
import ProjectsGrid from './connected/projects/ProjectsGrid';
import { navigate } from '@reach/router';
import { composeCohortsQueries } from '../explore-cohorts/ExploreCohorts';
import ProjectDetail from './connected/projects/ProjectDetail';
import { resetObj } from '../explore-cohorts/utils';
import ProjectFilter, { CustomCheckbox } from './connected/filters/Filter';
import AddIcon from '@mui/icons-material/Add';
import ProjectsFilterDialog from './connected/projects/ProjectsFilterDialog';
import { debounce } from 'lodash';
import { ProjectsLoader } from './ProjectsLoader';
import { FilterLoader } from './skeletons/FilterLoader';
import ADD from '../../../assets/images/add.svg';

const { borderColor } = vars;

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5rem 2.5rem',
    borderBottom: `1px solid ${borderColor}`,
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
  drawer: {
    // zIndex: 1501,
  },
}));

const FILTER_KEYS_ORDER = [
  { key: 'diagnosis', display: 'Diagnosis' },
  { key: 'data_type', display: 'Data Type' },
  { key: 'gender', display: 'Gender' },
  { key: 'age_at_enrollment', display: 'Age' },
  { key: 'race', display: 'Race' },
  { key: 'longitudinal_study', display: 'Longitudinal Study' },
];

const DEFAULT_SELECT_VALUE = false;

function sortObjectsByKey(arr, sortOrder) {
  return arr.sort((a, b) => {
    const leftKey = sortOrder.findIndex(obj => obj.key === a);
    const rightKey = sortOrder.findIndex(obj => obj.key === b);
    return rightKey - leftKey;
  });
}

function getFilterDisplayValue(key, keys) {
  const item = keys.find(item => item.key === key);
  //If the key is not found, fall back to the default value.
  return item ? item.display : key.replace(/_/g, ' ');
}

const ProjectGallery = (props) => {
  const classes = useStyles();
  const [statsData, setStatsData] = useState(null);
  const [projects, setProjects] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState(
    () => props.selectedProjects ?? {}
  );
  const [studyDetail, setStudyDetail] = useState(null);
  const [openFilterDialog, setOpenFilterDialog] = React.useState(false);
  const [projectFilters, setProjectFilters] = useState({});
  const [filtersData, setFiltersData] = useState({});
  const [caseCounts, setCaseCounts] = useState([]);
  const [diseases, setDiseases] = useState(null);
  const [projectAssessments, setProjectAssessment] = useState([]);
  const [projectDataTypes, setProjectDataTypes] = useState([]);
  const [filterByLongitudinal, setFilterByLongitudinal] = useState(false);
  const [longitudinalByIdMap, setLongitudinalByIdMap] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [projectIdToZoneMap, setProjectIdToZoneMap] = useState([]);
  const projectFiltersArr = Object.entries(projectFilters);
  const projectRef = useRef({
    projectFilters,
    projects,
  });

  const filteredProjects = useMemo(() => {
    const sortedProjects = sortProjectsBySortOrderAndAlphabet(projects)
    if (!!filterByLongitudinal && sortedProjects.length) {
      return sortedProjects.filter(
        (project) => !!longitudinalByIdMap[project.projectId]
      );
    }
    return sortedProjects;
  }, [projects, projectFilters, isLoading, filterByLongitudinal]);

  const filteredProjectDataTypes = useMemo(() => {
    if (!!filterByLongitudinal && projectDataTypes.length) {
      return projectDataTypes.filter((type) => !!longitudinalByIdMap[type.key]);
    }
    return projectDataTypes;
  }, [projectDataTypes, projectFilters, isLoading, filterByLongitudinal]);

  const filteredProjectAssessments = useMemo(() => {
    if (!!filterByLongitudinal && projectAssessments.length) {
      return projectAssessments.filter(
        (assessment) => !!longitudinalByIdMap[assessment.key]
      );
    }
    return projectAssessments;
  }, [projectAssessments, projectFilters, isLoading, filterByLongitudinal]);

  const watchFilters = useMemo(() => projectFilters, [projectFilters]);
  const filterKeys = Object.keys(watchFilters);
  const orderedFilterKeys =
    Array.isArray(filterKeys) && filterKeys.length
      ? sortObjectsByKey(filterKeys, FILTER_KEYS_ORDER)
      : [];
  const ribbonKeys =
    orderedFilterKeys.length > 1
      ? orderedFilterKeys?.slice(0, 5)?.reverse()
      : [];

  const selectedProductEmpty = useMemo(() => {
    const isEmpty = Object.keys(selectedProjects).length === 0;
    const notEmpty = Object.keys(selectedProjects).length > 0;
    const hasEnabled = Object.values(selectedProjects).find((v) => v);

    return isEmpty || (notEmpty && !hasEnabled);
  }, [selectedProjects]);

  async function createProjectsFromSelection() {
    const projects = Object.values(selectedProjects).filter((c) => c);
    const cohorts = await getProjectsCohorts(projects);
    console.log(composeCohortsQueries(cohorts), 'projectFilter');

    navigate('/explore/cohorts', {
      state: {
        formDefaultData: composeCohortsQueries(cohorts),
        selectedProjects
        // formDefaultData: constructFilterQuery(projectFilters),
      },
      replace: true,
    });
  }

  const addToSelection = (projectUuid) => {
    const newProjects = {
      ...selectedProjects,
      [projectUuid]: selectedProjects[projectUuid]
        ? null
        : projects.find((p) => p.projectId === projectUuid),
    };
    setSelectedProjects(newProjects);
    props.updateSelectedProjectsinGallery(newProjects);
    if (props.updateSelectedProjects) {
      props.updateSelectedProjects(newProjects);
    }
  };

  async function fetchOverviewData({ filters, projects, caseCounts }) {
    const dataPoints = await getClinicalDataPoints(filters);
    const attributesCount = await getAttributesCount(projects.map(p => p.projectId));
    const projectsCount = await getProjectsCount(projects);
    const participants = await getRecords(caseCounts);

    const statsData = generateStatisticsFromValues({
      // clinicalDataPoints: dataPoints,
      attributes: attributesCount,
      projects: projectsCount,
      participants: participants,
    });

    setStatsData(statsData);
  }

  async function updateProjects(updatedFilters, updateFilterState) {
    let updProjectsData = null;
    let updFilters = null;
    setSelectedProjects({});
    props.updateSelectedProjectsinGallery({});
    setIsLoading(true);
    updProjectsData = await props.getProjectGalleryData(
      updatedFilters,
      pageNumber,
      0
    );

    const projects = updProjectsData.studyData.map((project) => {
      const projectGalleryData = updProjectsData.studyGalleryData;
      const index = projectGalleryData?.findIndex(
        (item) => item.key === project.projectId
      );
      const isAccessible = projectGalleryData[index]?.isAccessible;
      return {
        ...project,
        isAccessible,
      };
    });

    setProjects(projects);

    if (updProjectsData.filters) {
      updFilters = await getInitialFilters(updProjectsData.filters);
    }

    if (Array.isArray(updProjectsData.studyGalleryDetails?.study)) {
      const longitudinalMap = updProjectsData.studyGalleryDetails.study.reduce(
        (prev, cur) => {
          if (cur.project_id !== undefined) {
            prev[cur.project_id] = cur.longitudinal;
          }
          return prev;
        },
        {}
      );
      setLongitudinalByIdMap(longitudinalMap);
    }

    setFiltersData(updProjectsData.filters);
    setCaseCounts(updProjectsData.caseCounts);
    mapProjectsToZoneId(updProjectsData.filters.zone);

    setIsLoading(false);
    //? comment remove redundant update overview call
    // fetchOverviewData({
    //   filters: updProjectsData.filters,
    //   projects,
    //   caseCounts: updProjectsData.caseCounts,
    // });

    if (updateFilterState) updateFilterState(updFilters);
  }

  const debounceUpdateProjects = useCallback(
    debounce((filtersValue) => {
      const formattedFiltersValue = formatFilterToServiceFilters(filtersValue);

      updateProjects(formattedFiltersValue);
    }, 800),
    [projectFilters]
  );

  function sortProjectsBySortOrderAndAlphabet(projects) {
    return projects && projects.sort((a, b) => {
      if (a.sortOrder === b.sortOrder) {
        return a.projectId.localeCompare(b.projectId);
      }
      return a.sortOrder - b.sortOrder;
    });
  }

  async function getDiseasesData(filterValues) {
    // get diseases pie chart data
    const project = await getDiseasesForProject(filterValues);
    const nextDiseases = project.disease_by_project?.map(({ key, count }) => ({
      key,
      value: count,
    }));
    setDiseases(nextDiseases);
  }

  async function showStudyDetail(project, caseCounts) {
    const study = await getStudyFromProject(project, caseCounts);
    setStudyDetail(study);
  }

  function mapProjectsToZoneId(data) {
    const mappings = {};

    for (const zone of data.histogram) {
      for (const termsField of zone.termsFields) {
        if (termsField.field === 'project_id') {
          for (const term of termsField.terms) {
            const projectId = term.key;
            mappings[projectId] = zone.key;
          }
        }
      }
    }

    setProjectIdToZoneMap(mappings);
  }

  function closeStudyDetail() {
    setStudyDetail(null);
  }

  function updateOverviewData({ filtersData, filteredProjects, caseCounts }) {
    setSelectedProjects({});
    fetchOverviewData({
      filters: filtersData,
      projects: filteredProjects,
      caseCounts,
    });
  }

  function handleLongitudinalCheck() {
    setFilterByLongitudinal((val) => !val);
  }

  const handleFilterChange = useCallback(
    (filterKey, attributeKey, value) => {
      const newFilters = { ...projectRef.current.projectFilters };
      const filter = newFilters[filterKey];
      let updatedFilters;

      if (filter.min !== undefined && Array.isArray(value)) {
        // update sliders values
        updatedFilters = {
          ...newFilters,
          [filterKey]: {
            ...newFilters[filterKey],
            range: {
              min: value[0],
              max: value[1],
            },
          },
        };
        setProjectFilters(updatedFilters);
        debounceUpdateProjects(updatedFilters);
      } else {
        // update filters enabled property
        updatedFilters = {
          ...newFilters,
          [filterKey]: value
            ? value
            : {
              ...newFilters[filterKey],
              [attributeKey]: !filter[attributeKey],
            },
        };

        setProjectFilters(updatedFilters);
        debounceUpdateProjects(updatedFilters);
      }
    },
    [projectRef.current.projectFilters]
  );

  const handleClearFilters = useCallback(() => {
    const newFilters = { ...projectFilters };
    let updatedFilters = {};

    for (const key in newFilters) {
      const filter = newFilters[key];
      if (filter.min !== undefined) {
        // update slider values to default
        updatedFilters[key] = {
          ...filter,
          range: { ...filter.range, min: filter.min, max: filter.max },
        };
      } else {
        //enabled all filters property
        updatedFilters[key] = resetObj(filter, DEFAULT_SELECT_VALUE);
      }
    }

    setProjectFilters(updatedFilters);
    debounceUpdateProjects(updatedFilters);
  }, [projectFilters]);

  useEffect(() => {
    updateProjects(null, (filters) => setProjectFilters(filters));
  }, []);

  useEffect(() => {
    if (filteredProjects) {
      updateOverviewData({ filtersData, filteredProjects, caseCounts });
    }

  }, [filtersData, filteredProjects, caseCounts, filterByLongitudinal]);

  useEffect(() => {
    projectRef.current = {
      projectFilters,
      projects,
    };
  }, [projectFilters, projects]);

  useEffect(() => {
    const formattedFiltersValue = formatFilterToServiceFilters(projectFilters);

    // get diseases pie chart data
    getDiseasesData(formattedFiltersValue);

    getAssessmentsByProject(formattedFiltersValue).then((data) => {
      setProjectAssessment(data);
    });

    getDataTypesByProject(formattedFiltersValue).then((data) => {
      setProjectDataTypes(data);
    });
  }, [projectFilters]);

  const sectionHeaderProps = {
    title: 'Project Gallery',
    type: 'cohortExplorer',
    pageDefinition: PAGES_DEFINITIONS['study-gallery'],

    defaultActionButtons: [
      {
        buttonText: 'Explore Cohorts from Selection',
        handleOnClick: createProjectsFromSelection,
        variant: 'outlined',
        disabled: selectedProductEmpty,
        description:
          !selectedProductEmpty &&
          `${Object.values(selectedProjects).filter((v) => v).length ?? ''
          } project${Object.values(selectedProjects).filter((v) => v).length > 1
            ? 's'
            : ''
          } selected`,
      },
      ...(selectedProductEmpty
        ? [
          {
            buttonText: 'Create Custom Cohort',
            handleOnClick: () => navigate('/explore/cohorts/new'),
            variant: 'contained',
            imageSrc: ADD,
          },
        ]
        : []),
    ],
  };

  const renderRibbonFilter = useMemo(() => {
    if (isLoading && ribbonKeys.length <= 0) return <FilterLoader />;

    if (Object.keys(watchFilters).length > 0 && Array.isArray(ribbonKeys)) {
      return (
        <Stack direction="row" spacing={1}  flexWrap="wrap">
          {ribbonKeys.map((filterKey) => {
            const currentFilter = watchFilters[filterKey] ?? {};
            return (
              <ProjectFilter
                key={filterKey}
                filters={currentFilter}
                filterKey={filterKey}
                title={getFilterDisplayValue(filterKey, FILTER_KEYS_ORDER)}
                handleFilterChange={handleFilterChange}
              />
            );
          })}
          <CustomCheckbox
            label="Longitudinal Study"
            size="small"
            checked={filterByLongitudinal}
            onChange={handleLongitudinalCheck}
          />

          <>
            <Button
              startIcon={<AddIcon fontSize="small" />}
              variant="outlined"
              size="small"
              onClick={() => {
                setOpenFilterDialog(true);
              }}
            >
              More Filters
            </Button>
          </>
        </Stack>
      );
    }
  }, [watchFilters, ribbonKeys, handleFilterChange]);

  return (
    <>
      <SectionHeader
        open={props.open}
        sectionHeaderProps={sectionHeaderProps}
      />
      <Box className={classes.filter}>
        <Typography>Filter</Typography>
        <>{renderRibbonFilter}</>
      </Box>
      <Box p={5}>
        <Statistics
          statsData={statsData}
          diseases={diseases}
          projectAssessments={filteredProjectAssessments}
          projectDataTypes={filteredProjectDataTypes}
          loading={isLoading}
          isLongitudinal={filterByLongitudinal}
        />
        <Box pt={3}>
          {isLoading ? (
            <ProjectsLoader />
          ) : Array.isArray(filteredProjects) &&
            !isLoading &&
            filteredProjects?.length ? (
            <ProjectsGrid
              projects={filteredProjects}
              caseCounts={caseCounts}
              onCheckToggle={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addToSelection(e.target.value);
              }}
              onProjectClick={showStudyDetail}
              {...{ selectedProjects }}
            />
          ) : (
            <Box display="flex" justifyContent="center">
              <Typography>
                No {filterByLongitudinal ? 'Longitudinal' : null} Projects{' '}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Drawer
        anchor="right"
        open={studyDetail != null}
        onClose={closeStudyDetail}
        className={classes.drawer}
      >
        {studyDetail != null && (
          <ProjectDetail
            studyDetail={studyDetail}
            projectZone={projectIdToZoneMap[studyDetail.projectId]}
            closeStudyDetail={closeStudyDetail}
            addToSelection={addToSelection}
            selected={!!selectedProjects[studyDetail.projectId]}
            updateSnackBar={props?.updateSnackBar}
            userDetails={props?.userDetails}
          />
        )}
      </Drawer>
      <ProjectsFilterDialog
        open={openFilterDialog}
        onClose={() => setOpenFilterDialog(false)}
        filters={Object.fromEntries(projectFiltersArr)}
        filtersArr={projectFiltersArr}
        title={`More Filters`}
        handleFilterChange={handleFilterChange}
        handleReset={handleClearFilters}
      />
    </>
  );
};

const memoisedProjectGallery = memo(ProjectGallery);
export default memoisedProjectGallery;

import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CustomLoader from '../custom-loader';
import DropDown from '../dropdown';
import { Grid } from '@mui/material';
import ErrorText from '../../controls/error-text';
import { isEmpty, map } from 'lodash';
import CustomModal from '../modal';

const useStyles = makeStyles(() => ({
  selectWorkspaceContainer: { height: '150px' },
}));

const ExportWorkspaceModal = ({
  open,
  loading,
  handleClose,
  modalProps,
  allWorkspaces,
  defaultSelectedWorkspace,
  updateSelectedWorkspace,
}) => {
  const classes = useStyles();
  const [selectedWorkspace, setSelectedWorkspace] = React.useState(() =>
    defaultSelectedWorkspace ? defaultSelectedWorkspace : ''
  );
  const [workspaceValues, setWorkspaceValues] = React.useState([]);
  const [exportToWorkspace, setExportToWorkspace] = React.useState(false);

  const onWorkspaceOptionChange = (value) => {
    setExportToWorkspace(false);
    setSelectedWorkspace(value);
    if (updateSelectedWorkspace) updateSelectedWorkspace(value);
  };

  React.useEffect(() => {
    const workspacesIds = map(
      allWorkspaces,
      (workspace) => workspace['workspace_id']
    );
    if (workspacesIds?.length) {
      setWorkspaceValues(workspacesIds);
      if (workspacesIds?.length === 1) {
        onWorkspaceOptionChange(workspacesIds[0]);
      }
    }
  }, [allWorkspaces]);

  return (
    <CustomModal open={open} handleClose={handleClose} dialogProps={modalProps}>
      <div>
        {workspaceValues?.length > 1 ? (
          <div
            className={classes.selectWorkspaceContainer}
            id="cvb-mySavedSearch-workspaceContainer"
          >
            <Grid item xs={6}>
              <DropDown
                values={workspaceValues}
                handleFilterStateChange={onWorkspaceOptionChange}
                selectedFilter={selectedWorkspace}
                placeholder={'Select workspace'}
              />
              {exportToWorkspace && isEmpty(selectedWorkspace) ? (
                <div
                  className={classes.errorTextDiv}
                  id="cvb-mySavedSearch-workspaceError"
                >
                  <ErrorText errorMessage="Please select workspace" />
                </div>
              ) : null}
              {loading ? (
                <CustomLoader styleProp={{ marginTop: '25px' }} />
              ) : null}
            </Grid>
          </div>
        ) : (
          <div className={classes.selectWorkspaceContainer}>
            <p id="cvb-mySavedSearch-workspaceDescription">
              {' '}
              You are exporting Cohort to{' '}
              <span className={classes.workspaceName}>
                <b>{workspaceValues[0]}</b>
              </span>{' '}
              Workspace.
            </p>
            {loading ? (
              <CustomLoader styleProp={{ marginTop: '25px' }} />
            ) : null}
          </div>
        )}
      </div>
    </CustomModal>
  );
};

export default ExportWorkspaceModal;

ExportWorkspaceModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
};

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

const ChortDialog = ({ open, title, description, children, actions }) => {
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>

        {children}
      </DialogContent>

      {actions && (
        <DialogActions>
          {actions}
        </DialogActions>
      )}

    </Dialog>
  );
}


export default ChortDialog;
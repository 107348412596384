import React from 'react';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '../../../assets/images/delete-x-icon.png'
import Classes from './index.module.css'
import map from 'lodash/map'
import v4 from 'uuid/v4'
import { isEmpty } from 'lodash';
import ErrorText from '../../controls/error-text'
import LoaderImg from '../../../assets/images/loader.gif'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(0.5),
    },
    chip: {
        backgroundColor: '#637BBD',
        borderRadius: 4,
        color: '#FFF',
        margin: 0
    },
}))

const InputChips = props => {
    const styledClasses = useStyles();
    const currentTagList = isEmpty(props.tagList) ? [] : props.tagList;
    const currentKeyWordList = isEmpty(props.keywordList) ? [] : props.keywordList; 
    const { tagsInputError = false, inputPlaceholder = '', infoTextLabel = '', advancedComputeView = false,
    isListLoading = false
    } = props;
    const listStyle = !advancedComputeView ? Classes.textUppercase : '';
    return (
        <React.Fragment>
            <div className={Classes.chipsContainer} id="cvb-inputChip-mainContainer">
                <div className={Classes.chips}>
                    {map(currentTagList, (chip, index) => {
                        return (
                            <span className={Classes.chip} key={v4()}>
                                <Chip
                                    key={v4()}
                                    id={`cvb-inputChipSelected-${index}`}
                                    label={chip}
                                    onDelete={() => props.deleteSelectedTagFromList(index)}
                                    className={styledClasses.chip}
                                    deleteIcon={<img id={`cvb-inputChipDelete-${index}`} src={DeleteIcon} alt="delete icon" />}
                                />
                            </span>
                        );
                    })}
                    <input id="cvb-inputChip-mainInput" onChange={(e) => props.onInputTextChange(e.target.value)} className={Classes.chipsInput} value={props.tagsInputText} placeholder={inputPlaceholder} name='tags' />
                </div>
                {isListLoading ?
                    <div id="cvb-inputChips-dropdownContainer" className={` ${Classes.overlayLoader}`}>
                        <img src={LoaderImg} alt="loader" />
                    </div>
                    :
                    currentKeyWordList?.length > 0 && props.tagsInputText?.length > 0 ?
                        <div id="cvb-inputChips-dropdownContainer" className={`${Classes.inputChipDropdownContainer} ${listStyle} `}>
                            <ul className={Classes.inputChipList}>
                                {map(currentKeyWordList, (ele) => (
                                    <li id={`cvb-dropdownValue-${ele}`} className={Classes.inputChipElements} key={v4()} value={ele} onClick={() => props.onSelectDropdown(ele)}>{ele}</li>
                                ))}
                            </ul>
                        </div> : null
                }
                <p id="cvb-inputChips-label" className={Classes.startTypingText}>{infoTextLabel}</p>
                {tagsInputError && 
                    <ErrorText errorMessage='Please remove invalid tag from input'/>
                }
            </div>
        </React.Fragment>
    )
}

export default InputChips;
import axios from 'axios'
import {env} from '../env'
import get from 'lodash/get'
import { getCognitoIdToken } from './utilityMethods';

const BASE_URL = `${env.REACT_APP_BASE_API_PATH}`

//const DATA_AGREEMENT_CONTRIBUTOR_BASE_URL = 'https://df7eeunluc.execute-api.us-east-1.amazonaws.com/data_contributor';
const DATA_AGREEMENT_CONTRIBUTOR_BASE_URL = `${BASE_URL}/data_contributor`;
export const updateDCAForm = async (data) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.post(`${DATA_AGREEMENT_CONTRIBUTOR_BASE_URL}/create_data_contributor_agreement`, data, { headers: header });
        return get(res, 'data', {});
    } catch (error) {
        throw error;
    }
}

export const getAllDCAForm = async () => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${DATA_AGREEMENT_CONTRIBUTOR_BASE_URL}/get_all_data_contributor_agreement_request`, { headers: header });
        return get(res, 'data', {});
    } catch (error) {
        console.log('error in updating dca form', error)
    }
}

export const getDCAFormFields = async (requesterEmail, uuid) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${DATA_AGREEMENT_CONTRIBUTOR_BASE_URL}/get_data_contributor_agreement_request/${requesterEmail}/${uuid}`, { headers: header });
        return get(res, 'data', {});
    } catch (error) {
        console.log('error in updating dca form', error)
    }
}

export const getProjectDetailsUploadUrl = async (id) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${DATA_AGREEMENT_CONTRIBUTOR_BASE_URL}/get_upload_url/${id}`, { headers: header });
        return get(res, 'data', {});
    } catch (error) {
        console.log('error in updating dca form', error)
    }
}
// /https://c610tjwbyl.execute-api.us-east-1.amazonaws.com/svc/data_contributor/get_download_url/68ba64f58b8142ecb3e157e36227dade
export const getDCADownloadUrl = async (id) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${DATA_AGREEMENT_CONTRIBUTOR_BASE_URL}/get_download_url/${id}`, { headers: header });
        return get(res, 'data', {});
    } catch (error) {
        console.log('error in updating dca form', error)
    }
}

export const downloadDCAForm = async (email, id) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${DATA_AGREEMENT_CONTRIBUTOR_BASE_URL}/download_data_contributor_agreement_request/${email}/${id}`, { headers: header });
        return get(res, 'data', {});
    } catch (error) {
        console.log('error in updating dca form', error)
    }
}

export const downloadDIFForm = async (userSub, difId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${BASE_URL}/user/document-download-url?doc_type=data_inventory_form&user_sub=${userSub}&dif_id=${difId}`,
            { headers: header });
        return get(res, 'data', {});
    } catch (error) {
        console.log('error in updating dca form', error)
    }
}

export const getManualDCAUploadPresignedData = async (fileName) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${DATA_AGREEMENT_CONTRIBUTOR_BASE_URL}/manual_uploaded_dca/upload_url/${fileName}`, { headers: header });
        return get(res, 'data', {});
    } catch (error) {
        console.log('error in updating dca form', error)
    }
}

export const getAllManualDCAUploads = async () => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${DATA_AGREEMENT_CONTRIBUTOR_BASE_URL}/manual_uploaded_dca`, { headers: header });
        return get(res, 'data', []);
    } catch (error) {
        console.log('error in updating dca form', error)
    }
}

export const getDownloadUrlForDCAManualUploads = async (fileName) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${DATA_AGREEMENT_CONTRIBUTOR_BASE_URL}/manual_uploaded_dca/download_url/${fileName}`, { headers: header });
        return get(res, 'data', []);
    } catch (error) {
        console.log('error in updating dca form', error)
    }
} 

export const getUserAccessDataDownloadUrl = async () => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${BASE_URL}/user/all_records`, { headers: header });
        return get(res, 'data', []);
    } catch (error) {
        console.log('error in downloading the file', error)
    }
} 

export const deleteDCAForm = async (uuid) => {
    try{
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.delete(`${DATA_AGREEMENT_CONTRIBUTOR_BASE_URL}/data_contributor_agreement/${uuid}`,{ headers: header });
        return get(res, 'status', '');
    }catch(error){
        throw Error(error.message);
    }
}
export const deleteDCADIFFile = async (dataToSend) => {
    try{
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.delete(`${BASE_URL}/data_contributor/delete_dca_object_from_s3`,{ data: dataToSend,  headers: header });
        return get(res, 'status', '');
    }catch(error){
        throw Error(error.message);
    }
}

export const deleteManualUploadedDCA = async (fileName) => {
    try{
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.delete(`${DATA_AGREEMENT_CONTRIBUTOR_BASE_URL}/manual_uploaded_dca/${fileName}`,{ headers: header });
        return get(res, 'status', '');
    }catch(error){
        throw Error(error.message);
    }
}

import React, { useState , useEffect} from 'react'
import Classes from './index.module.css'
import Grid from '@mui/material/Grid'
import { getCertificates } from '../../../api/certificate-api'
import Modal from '../../commons/modal'
import get from 'lodash/get'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import v4 from 'uuid/v4'
import ImgCertificate from '../../../assets/icons/certificate_pdf.png'
import CertificatePreview from '../../controls/certificate-preview'


const UserAbout = (props) => {
    const [selectedCertificateName, setSelectedCertificateName] = useState('');
    const [certificatePreviewModal, setCertificatePreviewModal] = useState(false);
    const onCertificateBoxClick = (name) => {
        setSelectedCertificateName(name);
        setCertificatePreviewModal(true)
    }
    const CERTIFICATE_VIEW_MODAL_PROPS = {
        modalTitle: selectedCertificateName,
    }
    const closeCertificateReviewModal = () => {
        setCertificatePreviewModal(false);
    }
    const getAddedCertificates = async () => {
        try {
            const res = await getCertificates(props.data.id);
            const certificateList = get(res, 'certificate_list', []);
            props.setAddedCertificates(certificateList);
        } catch (error) {
            console.log('error in getting certificates', error)
        }
    }

    useEffect(() => {
        if(!props.addedCertificates.length){
            getAddedCertificates();
        }
    }, []);


    return (
        <Grid>
            <Modal
                open={certificatePreviewModal}
                handleClose={closeCertificateReviewModal}
                dialogProps={CERTIFICATE_VIEW_MODAL_PROPS}
                disableTitleDivider={true}
            >
                <CertificatePreview selectedCertificateName={selectedCertificateName} userSub={props.data.id} />
            </Modal>
            <Grid xs={12} lg={12}>
                <div id='cvb-adminProfile-professionalBioHeading' className={Classes.aboutTabTitle}>Professional Bio</div>

                <p id='cvb-adminProfile-professionalBioText' className={Classes.aboutBio} >{props.data.professional_bio}</p>
            </Grid>


            <Grid xs={12} lg={12}>
                <div className={Classes.trainingAndCertificateContainer}>
                    <div className={Classes.trainingHeaderDiv}>
                        <p className={Classes.trainingRowHeader} id="cvb-adminProfile-trainingAndCertificateHeading">Training Certificate</p>
                    </div>
                    {!isEmpty(props.addedCertificates) ? <Grid container spacing={4}>
                        {map(props.addedCertificates, (certificate) => {
                            const certificateName = get(certificate, 'certificate_name', '');
                            const certificateDate = get(certificate, 'certification_date', '');
                            const certDate = moment(certificateDate).format('MM/DD/YYYY');
                            return (
                                <Grid key={v4()} item xs={6} md={3} lg={2}>
                                    <div id={`cvb-adminProfile-certificateViewButton-${certificateName}`} onClick={() => onCertificateBoxClick(certificateName)} className={Classes.certificateContainer}>
                                        <img src={ImgCertificate} className={Classes.certificateImage} alt="PDF_IMAGE" />
                                        <p id={`cvb-adminProfile-certificateName-${certificateName}`} className={Classes.certificateName}>{certificateName}</p>
                                        <p id={`cvb-adminProfile-certificateDate-${certDate}`} className={Classes.certificateDate}>{certDate}</p>
                                    </div>
                                </Grid>
                            )
                        }
                        )}
                    </Grid>
                        :
                        <div></div>
                    }
                </div>
            </Grid>
        </Grid>
    )
}

export default UserAbout



import isEmpty from 'lodash/isEmpty';

export const formFilterData = (filterValues = {}, excludeFromExploitation = [], appliedFilters) => {

    if (!filterValues && !excludeFromExploitation?.length > 1) {
        return {
            "filter": {
                "AND": []
            }
        }
    }

    const filterCategories = Object.keys(filterValues || {});
    let summationFilter = []
    let INItems = []
    if (excludeFromExploitation?.length > 0) {
        let filterBody = {
            "AND": [],
            "OR": []
        }
        for (let item of excludeFromExploitation) {
            for (let value of item?.value) {
                if (Array.isArray(value) && value.length === 2) {
                    filterBody.AND.push({
                        "OR": [
                            {
                                "<": {
                                    [item["filter"]]: value[0]
                                },
                            },
                            {
                                ">": {
                                    [item["filter"]]: value[1]
                                }
                            }

                        ]
                    })
                } else {
                    filterBody.AND.push({
                        "!=": {
                            [item["filter"]]: value === "no data" ? null : value
                        }
                    })
                }

            }

        }
        summationFilter.push(filterBody)
    }

    if (!isEmpty(appliedFilters)) {
        const appliedFiltersKeys = Object.keys(appliedFilters)
        for (let key of appliedFiltersKeys) {
            const childKeys = Object.keys(appliedFilters[key]) || []
            if (childKeys?.length > 0) {
                summationFilter.push({
                    "IN": { [key]: childKeys }
                })
            }
        }
        return {
            "filter": {
                "AND": summationFilter
            }
        }

    }

    for (let item of filterCategories) {
        const histograms = filterValues[item]?.histogram;
        const selectedItems = histograms?.map(item => {
            if (item?.isSelected) {
                return item.key;
            }
        }).filter(item => item) || [];

        let ORFilter = []
        let ANDFilter = []

        for (let innerItem of selectedItems) {
            if (Array.isArray(innerItem) && innerItem.length == 2) {
                ANDFilter.push({
                    "AND": [{
                        ">=": {
                            [item]: innerItem[0]
                        }
                    },
                    {
                        "<": {
                            [item]: innerItem[1]
                        }
                    }
                    ]
                })
            } else {
                if (INItems[item]) {
                    INItems[item].push(innerItem)
                } else {
                    INItems[item] = [innerItem]
                }
                // ORFilter.push({
                //     "=": {
                //         [item]: innerItem
                //     }
                // })
            }
        }
        ORFilter = [...ORFilter, ...ANDFilter]
        if (ORFilter.length > 0) {
            summationFilter.push({
                "OR": ORFilter
            })
        }
    }

    const INKeys = Object.keys(INItems)
    for (let key of INKeys) {
        summationFilter.push({
            "IN": { [key]: INItems[key] }
        })
    }

    return {
        "filter": {
            "AND": summationFilter
        }
    }

}

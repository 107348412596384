import axios from 'axios'
import {env} from '../env'
import { getCognitoIdToken } from './utilityMethods';
import { BASE_URL } from '../constants/google-login-urls'
const BASE_URL_INTERNAL = `${env.REACT_APP_BASE_INTERNAL_URL}`

export const uploadSubmissionFile = async (pathLead, body, uploadType) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'text/tab-separated-values',
        'Access-Control-Allow-Origin': '*'
    };
    let response;
    if (uploadType === "addNewData") {
        response = await axios.post(`${BASE_URL_INTERNAL}/api/v0/submission/${pathLead}`, body, { headers: header });
    } else {
        response = await axios.put(`${BASE_URL_INTERNAL}/api/v0/submission/${pathLead}`, body, { headers: header });
    }
    return response?.data || null
}

const getStructuredDataModelCache = {};

export const getStructuredDataModel = async (project_id) => {
    if (getStructuredDataModelCache[project_id]) {
        return getStructuredDataModelCache[project_id];
    }

    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const response = await axios.get(`${BASE_URL}/data_access/search/data_count/${project_id}`,{ headers: header });
    const data = response?.data?.data || null;

    getStructuredDataModelCache[project_id] = data;

    return data;
}

export const getAccessibleProjects = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const response = await axios.get(`${BASE_URL}/data_access/search/accessible_projects`,{ headers: header });
    return response?.data?.data?.project || null
}

export const submitMapData = async (program, project, params) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    };
    const response = await axios.post(`${BASE_URL_INTERNAL}/api/v0/submission/${program}/${project}`, JSON.stringify(params), { headers: header });
    return response?.data || null
}

export const removeFile = async (did, rev) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const response = await axios.delete(`${BASE_URL}/user/data/${did}`, { headers: header });
    return response?.data || null
}

import { Skeleton, Stack } from '@mui/material';
import React from 'react';

export const FilterLoader = ({ count = 4 }) => {
  return (
    <Stack direction="row" spacing={2}>
      {Array(count)
        .fill('*')
        .map((_, index) => (
          <Skeleton key={index} width={150} height={60} />
        ))}
    </Stack>
  );
};

import TellUsMore from './TellUsMore';
import { connect } from 'react-redux';
import { updateSnackBar } from '../../../redux/actions/globalActions';
import { updateUserIncognitoStatus } from '../../../redux/actions/userActions'


const mapStateToProps = state => {
    return {
        isUserPrivate: state.user.isUserPrivate
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type)),
        updateUserIncognitoStatus: value => dispatch(updateUserIncognitoStatus(value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TellUsMore);


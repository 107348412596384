/**
 *  External Imports
 */
import React, { useState } from "react";
import { func, string } from "prop-types";
import iconSearch from "../../../assets/icons/search-icon.svg";
import imgClose from "../../../assets/images/close.png";

/**
 *  Internal Imports
 */

import styles from "./index.module.css";

const CommunityInlineSearch = (props) => {
    const { inputPlaceholder } = props;
    const { handleUserInput } = props;
    const { inputValueHandler } = props;

    const [searchTerm, setSearchTerm] = useState("");

    const onSearchChange = (event) => {
        setSearchTerm(event.target.value);
        handleUserInput(event.target.value)
        inputValueHandler(event.target.value)
    };

    const onInputClear = () => {
        setSearchTerm("");
        handleUserInput("");
        inputValueHandler("")
    }

    return (
        <div className={styles.searchBarArea}>
            {!searchTerm ? (
                <img
                    data-testid="iconSearch"
                    className={styles.searchBarIcon}
                    src={iconSearch}
                    alt="Icon Search"
                />
            ) : (
                <div onClick={onInputClear} className={styles.clearBar}>
                    <img
                        data-testid="iconClear"
                        className={styles.clearBarIcon}
                        src={imgClose}
                        alt="Icon Clear"
                    />
                </div>
            )}

            <input
                data-testid="searchField"
                className={styles.searchBar}
                type="text"
                placeholder={inputPlaceholder}
                value={searchTerm}
                maxLength="100"
                onChange={onSearchChange}
            />
        </div>
    );
};

CommunityInlineSearch.propTypes = {
    inputPlaceholder: string.isRequired,
    handleUserInput: func.isRequired,
};

export default CommunityInlineSearch;

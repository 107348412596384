/**
 *  External Imports
 */
import React, { useRef, useEffect, useState } from "react";
import {
    arrayOf,
    func,
    number,
    shape,
    string,
    bool,
    oneOfType,
} from "prop-types";

/**
 *  Internal Imports
 */

import styles from "./index.module.css";
import SortBy from "../../controls/sort-by";
import CommunityInlineSearch from "../../controls/community-inline-search";
import CommunityWidgetBodyItem from "../../controls/community-widget-body-item";
import CustomLoader from "../../commons/custom-loader";
import CommunityEmptyState from "../../controls/community-empty-state";
import { WIDGET_TYPES } from "../../../constants/widget-types";

const CommunityWidgetBody = (props) => {
    const {
        usersList,
        isLoaded,
        isFullyLoaded,
        title,
        inputPlaceholder,
        globalSearchQuery,
        widgetType,
        isSortLoaded
    } = props;
    const { setSortBy, onSearchChange, loadMoreData } = props;
    const selectSettingsRef = useRef();
    const [inputValue, setInputValue] = useState("")

    const isOnSort = () => {
        return isLoaded && !isSortLoaded && usersList.length !== 0;
    };

    const getCurrentEmptyStateText = () => {
        switch (widgetType) {
            case WIDGET_TYPES.ACCEPTED:
                return "connections";
            case WIDGET_TYPES.INCOMING:
                return "incoming requests";
            case WIDGET_TYPES.PENDING:
                return "pending requests";
            case WIDGET_TYPES.RECOMMENDED:
                return "recommended connections";
            case WIDGET_TYPES.SEARCH:
                return "search results";
            default:
                return "connections";
        }
    };
    
    const inputValueHandler = (val) => {
        setInputValue(val)
        
    }

    useEffect(() => {
        selectSettingsRef.current.reset();
    }, [globalSearchQuery]);

    return (
        <div className={styles.widgetBody}>
            <div className={styles.headerArea}>
                <div className={styles.totalCountArea}>
                    <div className={styles.totalCountTitle} data-testid="widgetTitle">{title}</div>

                    <div className={styles.totalCountNumber} data-testid="widgetCounter">
                        {isLoaded ? usersList.length : 0}
                    </div>
                </div>

                <SortBy
                    data-testid="widgetSort"
                    inputValueLength={inputValue.length}
                    passedInRef={selectSettingsRef}
                    handleValueSelect={(v) => setSortBy(v)}
                />
            </div>
            <CommunityInlineSearch
                data-testid="widgetSearch"
                inputPlaceholder={inputPlaceholder}
                handleUserInput={(value) => onSearchChange(value)}
                inputValueHandler={inputValueHandler}
            />

            <div className={styles.listArea}>
                {isLoaded ? (
                    usersList && usersList.length ?
                        (
                            usersList.map((user) => {
                                return (
                                <CommunityWidgetBodyItem
                                    data-testid="widgetListItem"
                                    key={user.idRecommendation || user.id}
                                    user={user}
                                    widgetType={widgetType}
                                />
                            )})
                        ) :
                        (
                            <div className={styles.emptyStateWrap}>
                                <CommunityEmptyState 
                                text={getCurrentEmptyStateText()}
                                />
                            </div>
                        )
                ) : (
                    <div data-testid="widgetListLoader" className={styles.loaderWrap}>
                        <CustomLoader componentLoader />
                    </div>
                )}
                {isOnSort() && (
                    <div data-testid="widgetSortLoader"  className={styles.sortLoader}>
                        <div className={styles.loaderWrap}>
                            <CustomLoader componentLoader />
                        </div>
                    </div>
                )}
            </div>
            {/* {!isFullyLoaded && (
                <div className={styles.loadMoreArea}>
                    <button
                        onClick={loadMoreData}
                        className={styles.loadMoreBtn}>
                        Load more
                    </button>
                </div>
            )} */}
        </div>
    );
};

CommunityWidgetBody.propTypes = {
    usersList: arrayOf(
        shape({
            id: oneOfType([string, number]),
            senderID: number,
            recipientID: number,
            idRecommendation: number,
            userIcon: string.isRequired,
            name: string.isRequired,
            organization: string,
            reason: string,
            status: string,
            email: string,
            text: string,
            userUid: string,
        })
    ).isRequired,

    isLoaded: bool.isRequired,
    isFullyLoaded: bool.isRequired,
    isSortLoaded: bool.isRequired,
    title: string.isRequired,
    inputPlaceholder: string.isRequired,
    globalSearchQuery: string,

    widgetType: string.isRequired,

    setSortBy: func.isRequired,
    onSearchChange: func.isRequired,
    loadMoreData: func,
};

export default CommunityWidgetBody;

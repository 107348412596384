import { SET_AUTHORIZE_SUBMITTERS_FIRST_NAME, SET_AUTHORIZE_SUBMITTERS_LAST_NAME, SET_AUTHORIZE_SUBMITTERS_MIDDLE_NAME,
    SET_AUTHORIZE_SUBMITTERS_AFFILIATION, SET_AUTHORIZE_SUBMITTERS_INSTITUTIONAL_EMAIL
 , ADD_AUTHORIZE_SUBMITTERS_DETAILS, REMOVE_AUTHORIZE_SUBMITTER, SET_AUTHORIZE_SUBMITTERS_CAN_UPLOAD
, SET_AUTHORIZE_SUBMITTERS_CAN_MODIFY, SET_AUTHORIZE_SUBMITTERS_CAN_REMOVE, SET_AUTHORIZED_SUBMITTERS_FIELDS
, RESET_AUTHORIZED_SUBMITTERS_FIELDS
} from '../action-types'
import cloneDeep from 'lodash/cloneDeep'
const initialState = {
    authorizedSubmitters: [{
		"firstName": '',
		"middleName": '',
		"lastName": '',
		"affiliation": '',
        "institutionalEmail": '',
        "canUpload": null,
        "canModify": null,
        "canDelete": null
	}]
}

const AuthorizedSubmittersReducers = (state = initialState, action) => {
    switch (action.type) {
        case ADD_AUTHORIZE_SUBMITTERS_DETAILS: {
            let newAuthorizeSubmitters =  cloneDeep(state.authorizedSubmitters);
            newAuthorizeSubmitters.push(action.payload);
            return {
                ...state,
                authorizedSubmitters: newAuthorizeSubmitters
            }
        }
        case SET_AUTHORIZE_SUBMITTERS_FIRST_NAME: {
            const { value, id } = action.payload;
            let newAuthorizeSubmitters = cloneDeep(state.authorizedSubmitters);
            newAuthorizeSubmitters[id] = {
                ...newAuthorizeSubmitters[id],
                firstName: value
            }
            return {
                ...state,
                authorizedSubmitters: newAuthorizeSubmitters
            }
        }
        case SET_AUTHORIZE_SUBMITTERS_LAST_NAME: {
            const { value, id } = action.payload;
            let newAuthorizeSubmitters = cloneDeep(state.authorizedSubmitters);
            newAuthorizeSubmitters[id] = {
                ...newAuthorizeSubmitters[id],
                lastName: value
            }
            return {
                ...state,
                authorizedSubmitters: newAuthorizeSubmitters
            }
        }
        case SET_AUTHORIZE_SUBMITTERS_MIDDLE_NAME: {
            const { value, id } = action.payload;
            let newAuthorizeSubmitters = cloneDeep(state.authorizedSubmitters);
            newAuthorizeSubmitters[id] = {
                ...newAuthorizeSubmitters[id],
                middleName: value
            }
            return {
                ...state,
                authorizedSubmitters: newAuthorizeSubmitters
            }
        }
        case SET_AUTHORIZE_SUBMITTERS_AFFILIATION: {
            const { value, id } = action.payload;
            let newAuthorizeSubmitters = cloneDeep(state.authorizedSubmitters);
            newAuthorizeSubmitters[id] = {
                ...newAuthorizeSubmitters[id],
                affiliation: value
            }
            return {
                ...state,
                authorizedSubmitters: newAuthorizeSubmitters
            }
        }
        case SET_AUTHORIZE_SUBMITTERS_INSTITUTIONAL_EMAIL: {
            const { value, id } = action.payload;
            let newAuthorizeSubmitters = cloneDeep(state.authorizedSubmitters);
            newAuthorizeSubmitters[id] = {
                ...newAuthorizeSubmitters[id],
                institutionalEmail: value
            }
            return {
                ...state,
                authorizedSubmitters: newAuthorizeSubmitters
            }
        }
        case SET_AUTHORIZE_SUBMITTERS_CAN_UPLOAD: {
            const { value, id } = action.payload;
            let newAuthorizeSubmitters = cloneDeep(state.authorizedSubmitters);
            newAuthorizeSubmitters[id] = {
                ...newAuthorizeSubmitters[id],
                canUpload: value
            }
            return {
                ...state,
                authorizedSubmitters: newAuthorizeSubmitters
            }
        }
        case SET_AUTHORIZE_SUBMITTERS_CAN_MODIFY: {
            const { value, id } = action.payload;
            let newAuthorizeSubmitters = cloneDeep(state.authorizedSubmitters);
            newAuthorizeSubmitters[id] = {
                ...newAuthorizeSubmitters[id],
                canModify: value
            }
            return {
                ...state,
                authorizedSubmitters: newAuthorizeSubmitters
            }
        }
        case SET_AUTHORIZE_SUBMITTERS_CAN_REMOVE: {
            const { value, id } = action.payload;
            let newAuthorizeSubmitters = cloneDeep(state.authorizedSubmitters);
            newAuthorizeSubmitters[id] = {
                ...newAuthorizeSubmitters[id],
                canDelete: value
            }
            return {
                ...state,
                authorizedSubmitters: newAuthorizeSubmitters
            }
        }
        case REMOVE_AUTHORIZE_SUBMITTER: {
            let newAuthorizeSubmitters =  cloneDeep(state.authorizedSubmitters);
            newAuthorizeSubmitters.splice(action.payload, 1);
            return {
                ...state,
                authorizedSubmitters: newAuthorizeSubmitters
            }
        }
        case SET_AUTHORIZED_SUBMITTERS_FIELDS: {
            return {
                ...state,
                authorizedSubmitters: action.payload
            }
        }
        case RESET_AUTHORIZED_SUBMITTERS_FIELDS: {
            return {
                authorizedSubmitters: [{
                    "firstName": '',
                    "middleName": '',
                    "lastName": '',
                    "affiliation": '',
                    "institutionalEmail": '',
                    "canUpload": null,
                    "canModify": null,
                    "canDelete": null
                }]
            }
        }
        default: return state
    }
}

export default AuthorizedSubmittersReducers;
import {
    UPDATE_USER_ADMIN_DATA
} from '../action-types'
import {
    getUserSearchAdminApi as getUserSearchAdminApi
} from '../../api/admin/user'




export const getUserSearchData = (searchString) => async (dispatch) => {
    try {
        const resp = await getUserSearchAdminApi(searchString)
        dispatch(updateUserAdminData(resp || []))
    }
    catch (error) {
        dispatch(updateUserAdminData([]))
    }
}


const updateUserAdminData = value => ({ type: UPDATE_USER_ADMIN_DATA, payload: value })
import React from 'react';
import {env} from '../../../env'
import classes from './index.module.css'
import LoaderImg from '../../../assets/images/loader.gif'
import Modal from '../modal'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getDomainNameForCookies } from '../../../api/generic-api';

const CookieNotice = (props) => {
const { open, handleClose, dialogProps } = props;
const gaCookieName = '_ga_'+`${env.REACT_APP_GA_MEASUREMENT_ID}`.substring(2);
return (
<Modal open={open} handleClose={handleClose} dialogProps={dialogProps} disableTitleDivider disableBottomHorizontalDivider containerClass="private-policy-content">
<div className={classes.container}>
<p ><b><span>INTRODUCTION</span></b></p>

<p><span>We use cookies to distinguish you from other users of the BRAIN
            Commons (BC) website and Platform. This helps us to provide you with an optimized
            experience when you browse the BC website and Platform and allows us to make
            continual improvements. A cookie is a small file of letters and numbers that we
            store on your browser or hard drive of your device used to visit the BC website
            and Platform. This Global Cookie Notice provides you with information about cookies
we use and how to control them for the BC website and Platform.</span></p>

<p><b><span
 >TERMS USED </span></b></p>

<p><b><span
>“<i>Affiliate</i>”</span></b><span > </span><span
>— an affiliate is a website operator who can send traffic to the BC website
and Platform, using links from another website or social media platform. </span></p>

<p><b><span
>“<i>First-Party and Third-Party Cookies</i>” </span></b><span
>— whether a cookie is ‘first’ or ‘third’ party refers to the domain
                    placing the cookie. First-Party cookies are those set by BC when visited by the
                    user at the time — the website displayed in the URL window. Third-Party cookies
                    are cookies that are set by a domain other than us. If a user visits our CVB
                    website, BC website or Platform and another entity sets a cookie through the BC
website or Platform, then this would be a Third-Party cookie. </span></p>



<p><b><span
>“<i>Persistent Cookies</i>”</span></b><span > </span><span
>— these cookies remain on a user’s device for the period specified
in the cookie. </span></p>



<p><b><span
>“<i>Session Cookies</i>” </span></b><span >— these cookies
                allow us to link the actions of a user during a browser session. A browser
                session starts when a user opens the browser window and finishes when they
                close the browser. Session cookies are created temporarily and once you close
the browser, all session cookies are deleted.</span></p>



<p><b><span
>“<i>Social Media Cookies</i>”</span></b><span > – these
                cookies are also known as “analytics cookies” and collect information about how
                visitors use the BC website and Platform. This information is aggregated to
                provide us insights in how we can improve the experience and service we deliver
to you.</span></p>

<p><b><span
 >WHAT’S A COOKIE?</span></b></p>

<p><span>A cookie is a small text file that the BC website
and Platform saves on your computer or mobile device when you visit the BC website
or Platform. Cookies are then sent back to the originating website or the Platform
on each subsequent visit, or to another website that recognizes that cookie, to
develop a record of the user’s online activity. Cookies on the BC website or Platform
may be delivered in a first-party (set by us) or third-party (set by another
website) context and may also be set in association with emails you receive
from us.</span></p>

<p><span>Cookies help us enhance your
experience when using the BC website and Platform. They also help us understand
how you use our websites, such as which pages are most popular, so that we can
make continuous improvements for delivering an optimum experience for you.</span></p >

<p><b><span>IS A COOKIE PART OF PII?</span></b></p>

<p><span>“Personally identifiable information” (PII), “Personal data,” and “personal
        information” have become common terms used across jurisdictions around the world
        and are used in various statutes and regulations in different contexts and are
assigned different meanings. </span></p>

<p><span>For example, under the California Consumer Privacy Act 2018 (CCPA),
        the term “personal information” refers to any information that “identifies,
        relates to, describes, is capable of being associated with, or could reasonably
        be linked, directly or indirectly, with a particular consumer or household.”
        The CCPA includes a non-exhaustive list of data types that fall within that
        definition. That list includes “unique personal identifiers,” a term which
        itself is defined as including “cookies” that are used to “recognize a . . .
        device that is linked to a consumer or family, over time and across different
        services.” In this context, certain cookies will be considered “personal
information” for the purposes of the CCPA.</span></p>



<p><span>Similarly, the EU General Data Protection Regulation (GDPR) defines
        personal data as “any information relating to an identified or identifiable
        natural person.” Where such link exists, then such a cookie becomes part of the
set of “personal data.” </span></p>



<p><span>Not all cookies are the same or have the same purpose. We use the
following types of cookies on the BC website and Platform:</span></p>



<p><span >1.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span >Strictly Necessary Cookies </span></p>

<p><span >2.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span >Performance Cookies </span></p>

<p><span >3.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span >Social media cookies</span></p>

<p><span
>See also <u><span>How do I disable /enable
Cookies?</span></u></span></p>

<p><b><span
 >STRICTLY NECESSARY COOKIES</span></b></p>

<p><span
>These cookies are also referred to as “first-party essential
            cookies” and “session cookies”. These session cookies are essential to enable
            you to navigate the BC website and Platform and use their features, such as
            accessing secure areas. These cookies are strictly necessary for the BC website
            and Platform to function and can’t be switched off in our systems. They’re usually
            only set in response to actions made by you that amount to a request for
            services; setting your privacy preferences; logging in or filling in forms. You
            can set your browser to block or alert you about these cookies, but some parts
            of the BC website and Platform won’t work as a result. These cookies don’t store
any PII. </span></p>

<p><span
>Examples include:</span></p>

<p><span>·<span
>&nbsp;
</span></span><span >Remembering the settings you’ve applied to the
BC website and Platform such as layout, font size, preferences, colors, etc. </span></p>

<p><span>·<span
>&nbsp;
</span></span><span >Remembering a choice such as not to be asked
again to fill in a questionnaire. </span></p>

<p><span>·<span
>&nbsp;
</span></span><span >Detecting if a service has already been offered,
for example, offering a tutorial on future visits to the BC Platform. </span></p>

<p><span>·<span
>&nbsp;
</span></span><span >Fulfilling a request by you, for example, such
as submitting a comment. </span></p>

<p><span
>&nbsp;</span></p>

<p><b><span
 >PERFORMANCE COOKIES</span></b></p>

<p><span
>These cookies are also referred to as “first-party cookies”, “third-party
            cookies” or “performance cookies”. These cookies allow us or third-parties to
            count visits, traffic sources and collect information about how visitors use the
            BC website and Platform. We do this so we can measure and optimize the user
            experience. For example, we can learn which pages visitors go to most often and
            if they get error messages from webpages. All information these cookies collect
            is aggregated and therefore anonymous, so won’t identify you personally. If you
            don’t allow these cookies on the device you use to visit the website, we’ll not
know when you’ve visited our website and won’t be to monitor its performance.</span></p>

<p><span
>Examples include:</span></p>
<p><span>·<span
>&nbsp;
</span></span><span >Web analytics — where the data collected is used
            in managing the performance and design of the BC website and Platform. These
            cookies can be third-party cookies, but the information is for our exclusive
use only of the website visited. </span></p>

<p><span>·<span
>&nbsp;
</span></span><span >Affiliate tracking — where the cookie allows the
            affiliate of BC to improve the effectiveness of their website. This data can’t
be used to re-target advertisements on a third-party site.</span></p>

<p><span>·<span
>&nbsp;
</span></span><span >Error management — this cookie measures errors
            presented on our BC website and Platform, typically this will be to support
            service improvement or complaint management and will generally be closely
linked with web and platform analytics.</span></p>

<p><span>·<span
>&nbsp;
</span></span><span >Testing designs — cookies allow us to test variations
            of the BC website and Platform designs, typically using A/B or multivariate
            testing, to ensure a consistent look and feel is maintained for the user of the
BC website and BC platform in the current and subsequent sessions. </span></p>

<p><span
 >&nbsp;</span></p>

<p><b><span
 >SOCIAL MEDIA COOKIES</span></b></p>

<p><span
>These cookies are also referred to as “analytics cookies” and are
            always “third-party cookies”. These cookies may be set through our BC website and
            Platform by third parties. They may be used by those companies to build a
            profile of your interests and show you relevant content on other websites. They
            don’t store PII directly but are based on uniquely identifying your browser and
            internet device. If you don’t allow these cookies, you will experience less
targeted content.</span></p>

<p><span
>On the BC website and Platform, these are set by Google that have
            been added to enable you to share our content with a wider community. Google
            Analytics cookies are used to collect information about how visitors use the BC
            website and Platform. These cookies collect information in the aggregate to
            give us insight into how our websites are being used. We anonymize IP addresses
            in Google Analytics, and the anonymized data is transmitted to and stored by
Google on servers in the US. </span></p>
<p><u><span>List of social media cookies on the BC website and Platform</span></u></p>
<p><span>&nbsp;</span></p>

<TableContainer component={Paper} elevation={0} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                  <TableCell component="th">Cookie</TableCell>
                  <TableCell component="th" align="center">Domain</TableCell>
                  <TableCell component="th" align="center">Type</TableCell>
                  <TableCell component="th" align="center">Duration</TableCell>
                  <TableCell component="th" align="center">Description</TableCell>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell align="center">_gid</TableCell>
                    <TableCell align="center">{getDomainNameForCookies()}</TableCell>
                    <TableCell align="center">Analytics</TableCell>
                    <TableCell align="center">1 day</TableCell>
                    <TableCell align="center">Used by Google Analytics to collect 
                    tracking information; registers a unique ID used to generate statistical data on how the visitor uses the BC website</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">_ga</TableCell>
                    <TableCell align="center">{getDomainNameForCookies()}</TableCell>
                    <TableCell align="center">Analytics</TableCell>
                    <TableCell align="center">2 years</TableCell>
                    <TableCell align="center">Used by Google Analytics to collect 
                    tracking information; registers a unique ID used to generate statistical data on how the visitor uses the BC website</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">cvb_user</TableCell>
                    <TableCell align="center">{getDomainNameForCookies()}</TableCell>
                    <TableCell align="center">Functional</TableCell>
                    <TableCell align="center">1 year</TableCell>
                    <TableCell align="center">Used to track users’ selection for cookie preferences</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">{gaCookieName}</TableCell>
                    <TableCell align="center">{getDomainNameForCookies()}</TableCell>
                    <TableCell align="center">Analytics</TableCell>
                    <TableCell align="center">2 years</TableCell>
                    <TableCell align="center">Used by Google Analytics to collect tracking information; registers a unique ID used to generate statistical data on how the visitor uses the BC Platform</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">cvb_social_signin</TableCell>
                    <TableCell align="center">{getDomainNameForCookies()}</TableCell>
                    <TableCell align="center">Functional</TableCell>
                    <TableCell align="center">1 years</TableCell>
                    <TableCell align="center">Used to track users’ selection for social login</TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <p><b><span
 >&nbsp;</span></b></p>
<p><span>Last updated: 2/19/21</span></p>
<p><span
>&nbsp;</span></p>
<p><b><span
 >HOW DO I DISABLE/ENABLE
COOKIES?</span></b></p>

<p><span
>You have the option of being able to accept or reject cookies by
            modifying the settings in your browser and the cookie banner on the BC website
            and Platform. You should be aware that you may not be able to use all the
interactive features of the BC website and Platform if cookies are disabled.</span></p>



<p><span
>To manage settings on cookies through the banner, click “see cookies
settings” on the banner and you’ll be provided with cookie preferences<span
>.</span></span></p>



<p><span
>The tool will record when you have consented to our cookie policy
            and will ask for consent again annually to ensure users stay up to date with
            changes to our cookie and privacy policies. The consent tool specifically
            controls the marketing cookies and analytical cookies set by using the BC website
            and Platform. First-party essential cookies can’t be disabled nor can the tool
            be used to block cookies on third-party websites linked from the BC website and
Platform.</span></p>



<p><span
>To manage cookies in a web browser, you should go to Tools/Internet
            options/Security and Privacy Tabs to adapt the browser to your preferences. If
            you use different devices in different locations, you’ll need to ensure that
each browser is adjusted to suit your cookie preferences.</span></p>



<p><span
>Some modern browsers have a feature that will analyse website
            privacy policies and allow a user to control their privacy preferences. These
are known as ‘P3P’ features (Privacy Preferences Platform).</span></p>



<p><span
>You can easily delete any cookies that have been installed in the
cookie folder of your browser. For example, if you’re using Microsoft Windows Explorer:</span></p>

<ul type='disc'>
 <li><span
 >Open 'Windows Explorer'</span></li>
 <li><span
 >Click on the 'Search' button on the tool bar</span></li>
 <li><span
 >Type &quot;cookie&quot; into the search box for 'Folders and
 Files'</span></li>
 <li><span
 >Select 'My Computer' in the 'Look In' box</span></li>
 <li><span
 >Click 'Search Now' Double click on the folders that are found</span></li>
 <li><span
 >'Select' any cookie file</span></li>
 <li><span
 >Hit the 'Delete' button on your keyboard</span></li>
</ul>

<p><span
>If you’re not using Microsoft Windows Explorer, then you should
            select 'cookies' in the 'Help' function for information on where to find your
cookie folder.</span></p>


<p><b><span
 >COOKIES THAT HAVE BEEN SET IN
THE PAST</span></b></p>

<p><span
>Collection of your PII from our analytics cookies can be deleted. If
            cookies are deleted, the information collected prior to the preference change
            may still be used, however, we’ll stop using the disabled cookie to collect any
            further information from your user experience. For our marketing cookie, when a
            user opts out of tracking, a new cookie is placed to prevent users from being
tracked.</span></p>

<p><b><span
 >FURTHER INFORMATION</span></b></p>

<p><span
>For further information, contact the Global Privacy Advisor on
email: <a href="mailto:dataprivacy@braincommons.org">dataprivacy@braincommons.org</a></span></p>
</div>
</Modal >
)
}

export default CookieNotice;

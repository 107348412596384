import axios from 'axios'
import {env} from '../env'
import get from 'lodash/get'
import { getCognitoIdToken } from './utilityMethods';

const BASE_URL = `${env.REACT_APP_BASE_API_PATH}`

const DATA_INVENTORY_CONTRIBUTOR_BASE_URL = `${BASE_URL}/data_contributor`;
//const DATA_INVENTORY_CONTRIBUTOR_BASE_URL = `https://cecaseaxtf.execute-api.us-east-1.amazonaws.com/data_contributor`;

export const postDIFForm = async (data) => {
    try{
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.post(`${DATA_INVENTORY_CONTRIBUTOR_BASE_URL}/data_inventory_form`, data, { headers: header });
        return get(res, 'data', {});
    }catch(error){
        throw error;
    }
}
export const getAllDIFRequest = async () => {
    try{
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${DATA_INVENTORY_CONTRIBUTOR_BASE_URL}/data_inventory_form`, { headers: header });
        return get(res, 'data.dif_requests', []);
    }catch(error){
        console.log('error in updating dca form', error)
    }
}
export const getDIFFormData = async (requesterEmail, id) => {
    try{
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${DATA_INVENTORY_CONTRIBUTOR_BASE_URL}/data_inventory_form/${requesterEmail}/${id}`, { headers: header });
        return get(res, 'data', {});
    }catch(error){
        console.log('error in updating dca form', error)
    }
}
export const getConsentDocUploadURL = async (id) => {
    try{
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${DATA_INVENTORY_CONTRIBUTOR_BASE_URL}/get_dif_upload_url/${id}`, { headers: header });
        return get(res, 'data', {});
    }catch(error){
        console.log('error in updating dca form', error)
    }
}
export const getDIFConsentDownloadUrl = async (key = '') => {
    try{
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.get(`${DATA_INVENTORY_CONTRIBUTOR_BASE_URL}/get_dif_download_url/${key}`, { headers: header });
        return get(res, 'data', {});
    }catch(error){
        console.log('error in updating dca form', error)
    }
}
export const deleteDIFForm = async (uuid) => {
    try{
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const res = await axios.delete(`${DATA_INVENTORY_CONTRIBUTOR_BASE_URL}/data_inventory_form/${uuid}`,{ headers: header });
        return get(res, 'status', '');
    }catch(error){
        throw Error(error.message);
    }
}
import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import DistributionsCardContainer from '../distributions-card-container';
import DistributionAcrossGraphs from '../distribution-across-graphs';
import { getUserSearchAdminApi } from '../../../api/admin/user';
import { USER_SORT_OPTIONS, ZONE_1_KEY, ZONE_2_KEY, ZONE_3_KEY } from '../../../constants/strings';
import { get, lowerCase, map, sortBy, find } from 'lodash';
import DataDistributionDetails from '../data-distribution-details';

const DatasetAndUsersDistribution = React.forwardRef((props, ref) => {
        const { allZonesData = [], usersData =[], barChartData = [], allProjectsDetails = [], barChartDataForFiles = [], 
            yAxisValues= [], selectedZone = null, selectedUser= null
        } = props;
        const [dashboardDataTitle, setDashboardDataTitle] = useState('');
        
        // const [selectedUser, setSelectedUser] = useState(null)
        // const [selectedZone, setSelectedZone] = useState(null)
        const [userSortOption, setUserSortOption] = useState('User Name');
        const [userData, setData] = useState([])
        const [selectedZoneData, setSelectedZoneData] = useState([]);
        useEffect(() => {
            let dataDistributionTitle = "";
    
            let userAccess = false;
            let datasetAccess = false;
            if (props.userDetails?.resourceActions?.dashboard?.view_zone1_users ||
                props.userDetails?.resourceActions?.dashboard?.view_zone2_users ||
                props.userDetails?.resourceActions?.dashboard?.view_zone3_users) {
                userAccess = true;
            }
            if (props.userDetails?.resourceActions?.dashboard?.view_zone1_datasets
                || props.userDetails?.resourceActions?.dashboard?.view_zone2_datasets
                || props.userDetails?.resourceActions?.dashboard?.view_zone3_datasets) {
                datasetAccess = true;
            }
    
            if (userAccess && !datasetAccess) {
                dataDistributionTitle = 'User Distribution Across Zones';
            } else if (!userAccess && datasetAccess) {
                dataDistributionTitle = 'Datasets Distribution Across Zones';
            } else if (userAccess && datasetAccess) {
                dataDistributionTitle = 'Datasets & User Distribution Across Zones';
            }
            if (dataDistributionTitle) {
                setDashboardDataTitle(dataDistributionTitle);
            }
        }, [props.userDetails]);
    
        useEffect(() => {
            if (props.selectedUserFromQueryParams) {
                onViewUserClick(props.selectedUserFromQueryParams);
            }
        }, [usersData]);
    
        // const getUsersData = async () => {
        //     let data = await getUserSearchAdminApi('', 'zone');
        //     const processData = {};
        //     Object.keys(data).map(key => {
        //         processData[key] = data[key].map(item => {
        //             item.searchKey = `${item.name} ${item.designation} ${item.organisation} ${item.user_role}`.toLowerCase();
        //             return item
        //         })
        //     });
        //     setUsersData(processData);
        // }
    
        // useEffect(() => {
        //     getUsersData();
        // }, []);
    
        const sortUsersBySelectedOption = (option, data = []) => {
            let sortedData = [];
            switch (option) {
                case USER_SORT_OPTIONS[0]: {
                    sortedData = sortBy(data, (item) => lowerCase(item?.name));
                    break;
                }
                case USER_SORT_OPTIONS[1]: {
                    sortedData = sortBy(data, (item) => {
                        const userRoles = item?.user_roles || [];
                        let roleArr = [];
                        map(userRoles, (role) => roleArr.push(role?.role_label));
                        const roles = roleArr.join(', ');
                        return lowerCase(roles);
                    }
                    );
                    break;
                }
                case USER_SORT_OPTIONS[2]: {
                    sortedData = sortBy(data, (item) => lowerCase(item?.organisation));
                    break;
                }
                default: sortedData = []
            }
            return sortedData;
        }
        const onZoneSelect = (id) => {
            let currentSelectedZoneData = {};
    
            switch (id) {
                case 1: currentSelectedZoneData = find(allZonesData, (data) => get(data, 'key', 0) === ZONE_1_KEY);
                    break;
                case 2: currentSelectedZoneData = find(allZonesData, (data) => get(data, 'key', 0) === ZONE_2_KEY);
                    break;
                case 3: currentSelectedZoneData = find(allZonesData, (data) => get(data, 'key', 0) === ZONE_3_KEY);
                    break;
                default: break;
            }
            props.setDashboardTitle('Datasets & User Distribution Across Zones')
            props.onUserZoneSelected(null);
            props.onDatasetZoneSelected(id);
            setSelectedZoneData(currentSelectedZoneData);
        }
        
        const onViewUserClick = zone => {
            let zoneData = [];
            switch (zone) {
                case 1: {
                    zoneData = sortUsersBySelectedOption(userSortOption, usersData.zone1);
                    break;
                }
                case 2: {
                    zoneData = sortUsersBySelectedOption(userSortOption, usersData.zone2)
                    setData(usersData.zone2);
                    break;
                }
                case 3: {
                    zoneData = sortUsersBySelectedOption(userSortOption, usersData.zone3);
                    break;
                }
                default: setData(usersData.zone1);
            }
            props.onUserZoneSelected(zone);
            props.onDatasetZoneSelected(null);
            props.setDashboardTitle('Datasets & User Distribution Across Zones')
            setData(zoneData);
        }
        const onUserSortChange = value => {
            setUserSortOption(value);
            const data = sortUsersBySelectedOption(value, userData);
            setData(data);
        }

               

        return (
            <div ref={ref}>
                <DistributionsCardContainer 
                usersData={usersData} 
                onZoneSelect={onZoneSelect} 
                onViewUserClick={onViewUserClick} 
                dashboardDataTitle={dashboardDataTitle} 
                selectedZone={selectedZone}
                selectedUser={selectedUser}
                onDatasetZoneSelected={props.onDatasetZoneSelected}
                onUserZoneSelected={props.onUserZoneSelected}
                />
                { (selectedUser || selectedZone) ?
                    <DataDistributionDetails
                        selectedZone={selectedZone}
                        allZonesData={allZonesData}
                        selectedZoneData={selectedZoneData}
                        setSelectedZoneData={setSelectedZoneData}
                        selectedUser={selectedUser}
                        onUserSortChange={onUserSortChange}
                        userData={userData}
                        userSortOption={userSortOption}
                        usersData={usersData}
                        setData={setData}
                    />
                    :
                    <DistributionAcrossGraphs
                        barChartData={barChartData}
                        allProjectsDetails={allProjectsDetails}
                        barChartDataForFiles={barChartDataForFiles}
                        yAxisValues={yAxisValues}
                    />
                }
            </div>
        )
    }
)

DatasetAndUsersDistribution.displayName = 'DatasetAndUsersDistribution'

export default DatasetAndUsersDistribution;
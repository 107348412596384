
import { ADD_NEW_ANALYSIS, SET_SELECTED_ANALYSIS, SET_X_AXIS_ANALYSIS_ATTRIBUTE, 
    SET_Y_AXIS_ANALYSIS_ATTRIBUTE, SET_CHART_TYPE_ANALYSIS_ATTRIBUTE, REMOVE_ANALYSIS,
    SAVE_ANALYSIS_COHORT, ON_ANALYZE_COHORT, ON_COHORT_SELECTED } from '../action-types'
    
export const addNewAnalysis = (value) => {
    return {
        type: ADD_NEW_ANALYSIS,
        payload: value
    }
}
export const setSelectedAnalysis = (value) => {
    return {
        type: SET_SELECTED_ANALYSIS,
        payload: value
    }
}
export const setXAxisAnalysisAttribute = (value) => {
    return {
        type: SET_X_AXIS_ANALYSIS_ATTRIBUTE,
        payload: value
    }
}
export const setYAxisAnalysisAttribute = (value) => {
    return {
        type: SET_Y_AXIS_ANALYSIS_ATTRIBUTE,
        payload: value
    }
}
export const setChartTypeAnalysisAttribute = (value) => {
    return {
        type: SET_CHART_TYPE_ANALYSIS_ATTRIBUTE,
        payload: value
    }
}
export const removeAnalysis = value => {
    return {
        type: REMOVE_ANALYSIS,
        payload: value
    }
}
export const saveAnalysisCohort = value => {
    return {
        type: SAVE_ANALYSIS_COHORT,
        payload: value
    }
}
export const onAnalyzeCohort = value => {
    return {
        type: ON_ANALYZE_COHORT,
        payload: value
    }
}
export const onCohortSelected = value => {
    return {
        type: ON_COHORT_SELECTED,
        payload: value
    }
}
/**
 *  External Imports
 */
import React from "react";
import { func, string, bool } from "prop-types";

/**
 *  Internal Imports
 */
import { CONSTELLATION_TABS } from "../../../../constants/constellation";
import CustomLoader from "../../../commons/custom-loader";
import styles from "./index.module.css";

const CommunityConstellationTabs = (props) => {
    const {
        currentTab,
        hasRecommendations,
        hasConnections,
        isOnUpdate,
        isLoading,
        isButtonDisabled,
    } = props;
    const { onTabValueSelected, saveToProfile } = props;

    return (
        <div className={styles.constellationHead}>
            <ul className={styles.constellationTabs}>
                {Object.keys(CONSTELLATION_TABS).map((tabKey) => (
                    <li
                        data-testid="graphTab"
                        key={tabKey}
                        className={
                            currentTab === CONSTELLATION_TABS[tabKey]
                                ? `${styles.constellationTabsItem} ${styles.constellationTabsItemActive}`
                                : `${styles.constellationTabsItem}`
                        }>
                        <button
                            data-testid="graphBtn"
                            onClick={() =>
                                onTabValueSelected(CONSTELLATION_TABS[tabKey])
                            }
                            className={styles.constellationTabsButton}>
                            {CONSTELLATION_TABS[tabKey]}
                        </button>
                    </li>
                ))}
            </ul>
            {!isLoading && (
                <div className={styles.constellationContent} data-testid="graphTabInfo">
                    {currentTab === CONSTELLATION_TABS.RECOMMENDED && (
                        <>
                            {!hasRecommendations && (
                                <div data-testid="graphTabRecommendedEmpty" className={styles.tabInfo}>
                                    <p data-testid="graphTabRecommendedEmptyText" className={styles.tabText}>
                                        {!isOnUpdate
                                            ? "There is no information in your profile"
                                            : "Looking for recommendations..."}
                                    </p>
                                    <p data-testid="graphTabRecommendedEmptyHint" className={styles.tabTextHint}>
                                        {!isOnUpdate
                                            ? "Please select a label in the graph below to view recommendations"
                                            : "It may take a few seconds"}
                                    </p>
                                    {!isOnUpdate ? (
                                        !isButtonDisabled && (
                                            <button
                                                data-testid="graphTabRecommendedEmptyBtn"
                                                onClick={saveToProfile}
                                                className={styles.tabBtn}>
                                                Save to profile
                                            </button>
                                        )
                                    ) : (
                                        <div className={styles.loaderWrap} data-testid="graphTabRecommendedEmptyLoader">
                                            <CustomLoader componentLoader />
                                        </div>
                                    )}
                                </div>
                            )}
                            {hasRecommendations && (
                                <div data-testid="graphTabRecommended" className={styles.tabInfo}>
                                    <p className={styles.tabText} data-testid="graphTabRecommendedText">
                                        Here are your recommendations
                                    </p>
                                    <p className={styles.tabTextHint} data-testid="graphTabRecommendedHint">
                                        Select people from the graph below to add to your connections
                                    </p>
                                </div>
                            )}
                        </>
                    )}
                    {currentTab === CONSTELLATION_TABS.CONNECTIONS && (
                        <>
                            {!hasConnections && (
                                <div data-testid="graphTabConnectedEmpty" className={styles.tabInfo}>
                                    <p className={styles.tabText} data-testid="graphTabConnectedEmptyText">
                                        You have no connections
                                    </p>
                                    <p className={styles.tabTextHint} data-testid="graphTabConnectedEmptyHint">
                                        Check the "Recommended" tab for people you may wish to connect to.
                                    </p>
                                </div>
                            )}
                            {hasConnections && (
                                <div data-testid="graphTabConnected" className={styles.tabInfo}>
                                    <p data-testid="graphTabConnectedText" className={styles.tabText}>
                                        Here are the people you are connected to
                                    </p>
                                    <p className={styles.tabTextHint} data-testid="graphTabConnectedEmptyHint">
                                        Don't forget to check the "Recommended" tab for additional connection recommendations
                                    </p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
            {isLoading && (
                <div data-testid="graphTabLoader" className={styles.loaderWrap}>
                    <CustomLoader componentLoader />
                </div>
            )}
        </div>
    );
};

CommunityConstellationTabs.propTypes = {
    currentTab: string.isRequired,
    hasRecommendations: bool.isRequired,
    hasConnections: bool.isRequired,
    isLoading: bool.isRequired,
    isOnUpdate: bool.isRequired,
    isButtonDisabled: bool.isRequired,

    onTabValueSelected: func.isRequired,
    saveToProfile: func.isRequired,
};

export default CommunityConstellationTabs;

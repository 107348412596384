import React, { useCallback } from 'react';
import { navigate } from '@reach/router';

import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Input from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import SearchIcon from '@mui/icons-material/Search';

import InfoIcon from '@mui/icons-material/InfoOutlined';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import ScrollContainer from 'react-indiana-drag-scroll';
import { FilterIcon } from './Icons';
import {
  ALL_ATTRIBUTES,
  ATTRIBUTES_MAP,
  getCohortsByFilter,
  getFilteredData,
  getInitialFilters,
  resetObj,
} from './utils';
import {
  getProjectsCohorts,
  getUserCohorts,
  getCohortAggregates,
} from '../../../service/CohortsService';
import CustomizeColumnsDialog from './CustomizeColumnsDialog';
import CohortsFilterDialog from './CohortsFilterDialog';
import { Badge, Tooltip } from '@mui/material';
import LoaderImg from '../../../assets/images/loader.gif';
import { isEqual } from 'lodash';
import { omit } from 'lodash';

const MAX_ELEMENTS = 5;

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
  search: {
    width: '100%',

    padding: theme.spacing(1),
  },
  accordionSummary: {
    background: 'white',
  },
  accordionDetails: {
    padding: 0,
    '& table': {
      // marginTop: -60,
    },
  },
  leftPadding1: {
    marginLeft: theme.spacing(2),
  },
  stickyLeft: {
    position: 'sticky',
    left: 0,
    zIndex: 10,
    background: 'white',
    // whiteSpace: "nowrap",
    // maxWidth: "6em",
    overflow: 'hidden',
    boxShadow:
      '4px 0px 8px -2px rgba(16, 24, 40, 0.1), 2px 0px 4px -2px rgba(16, 24, 40, 0.06);',
    '& .MuiFormControlLabel-label': {
      width: '7em', // Set the width to adjust the alignment of the checkboxes in the table
      display: 'block',
    },

    borderLeft: '5px solid transparent',
  },
  stickyTop: {
    position: 'sticky',
    top: 0,
    background: 'white',
    boxShadow:
      '4px 0px 8px -2px rgba(16, 24, 40, 0.1), 2px 0px 4px -2px rgba(16, 24, 40, 0.06);',
  },
  stickyRight: {
    position: 'sticky',
    right: 0,
    zIndex: 10,
    minWidth: '8.5rem',
    background: 'white',
    fontWeight: 500,
    boxShadow:
      '-4px 0px 8px -2px rgba(16, 24, 40, 0.1), -2px 0px 4px -2px rgba(16, 24, 40, 0.06)',
    '& button': {
      marginRight: '0.4rem',
    },
  },
  selectedRow: {
    '& td, & th': {
      backgroundColor: '#F7F9FC',
    },
    '& th': {
      borderLeft: '5px solid var(--SHADE_OF_BLUE_2)',
      color: 'var(--SHADE_OF_BLUE_2)',
    },
  },
  scrollContainer: {
    width: '100%',
    overflow: 'auto',
  },
  overlayLoader: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '10',
    width: '99%',
    height: '100px',
  },
  overlayLoaderImg: {
    width: '50px',
  },
}));

function concatValues(values) {
  return values.map((v, i) => (
    <>
      <span style={{ whiteSpace: 'noWrap' }} key={v.key}>
        {v.key}
      </span>
      {i < values.length - 1 ? <b style={{ color: 'black' }}>, </b> : ''}
    </>
  ));
}

function removePropFromFilters(filters, property) {
  return omit(filters, property);
}

function displayCellValue(value) {
  if (value == undefined || value == null || value?.histogram?.length <= 0) {
    return 'N/A';
  }

  try {
    if (value?.histogram && value?.histogram[0]?.key !== undefined) {
      const elements = value?.histogram
        .filter((v) => v.count > 0)
        .sort((v) => -v.count);
      if (elements.length <= MAX_ELEMENTS) {
        return <span>{concatValues(elements)}</span>;
      } else {
        return (
          <span>
            {concatValues(elements.slice(0, MAX_ELEMENTS))}
            <br /> and {elements.length - MAX_ELEMENTS} more...
          </span>
        );
      }
    } else if (
      value?.histogram &&
      value?.histogram[0]?.min !== undefined &&
      value?.histogram[0]?.min != value?.histogram[0]?.max
    ) {
      return (
        <span>
          {value?.histogram[0].min.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
          -
          {value?.histogram[0].max.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        </span>
      );
    } else if (value?.histogram && value?.histogram[0]?.min !== undefined) {
      return (
        <span>
          {value?.histogram[0].min.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        </span>
      );
    }
  } catch (exc) {
    console.debug('Column error', value, exc);
    return 'N/A';
  }

  return <span>{value}</span>;
}

const ALL_COHORTS = 'all',
  MY_COHORTS = 'my';

export const CohortsTable = ({ showCohortInfo, updateSelectedCohorts, selectedCohorts, location, updateSelectedProjectsinGallery, cohortsRefreshCount, ...rest }) => {
  const defaultTab =
    new URLSearchParams(window.location.search).get('tab') ?? ALL_COHORTS; // useSearchParams does not work...

  const initialColumns = () =>
    JSON.parse(localStorage.getItem('columns')) ||
    ALL_ATTRIBUTES.map((column) => ({ ...column, enabled: true })); // get columns from local storage first
  const selectedProjects = location?.state?.selectedProjects ?? {};
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [cohorts, setCohorts] = React.useState([]);
  const [cohortFilters, setCohortFilters] = React.useState({});
  const [initialFilters, setInitialFilters] = React.useState({});

  const [openColumnsDialog, setOpenColumnsDialog] = React.useState(false);
  const [openFilterDialog, setOpenFilterDialog] = React.useState(false);
  const [currentFilterKey, setCurrentFilterKey] = React.useState('');
  const [selectedTabIdx, setSelectedTabIdx] = React.useState(
    defaultTab && defaultTab === MY_COHORTS ? 1 : 0
  );

  const [columns, setColumns] = React.useState(initialColumns);

  const filteredCohorts = React.useMemo(() => {
    const newCohorts = getCohortsByFilter(
      cohorts,
      cohortFilters.cohort_name,
      'cohort_name'
    );
    return (
      cohortFilters &&
      !isEqual(
        removePropFromFilters(cohortFilters, 'cohort_name'),
        initialFilters
      )
        ? getFilteredData(cohortFilters, cohorts)
        : Array.isArray(cohorts) && cohortFilters.cohort_name !== undefined
        ? newCohorts
        : cohorts
    ).filter((c) => c.cohort_name.toLowerCase().includes(search.toLowerCase()));
    // return cohorts;
  }, [cohortFilters, cohorts, search]);

  const activeColumns = React.useMemo(
    () => columns.filter((column) => column.enabled),
    [columns]
  );

  /**
   * Wraps a promise to always fulfill with an object describing the outcome.
   * This enables distinguishing between fulfilled and rejected promises in methods like `Promise.allSettled`.
   *
   * @param {Promise} promise - The input promise to be wrapped.
   * @returns {Promise} A promise that resolves to an object with either:
   *                    { status: 'fulfilled', value: <resolved value of the promise> }
   *                    or
   *                    { status: 'rejected', reason: <rejection reason of the promise> }
   */
  function wrapPromiseWithOutcome(promise) {
    return promise.then(
        value => ({ status: 'fulfilled', value }),
        reason => ({ status: 'rejected', reason })
    );
  }

  async function updateCohorts() {
    setLoading(true);
    try {
      let updCohorts = null;
      let filteredCohorts = [];
      if (selectedTabIdx == 0) {
        updCohorts = await getProjectsCohorts();
        
        filteredCohorts = updCohorts
        
      } else {
        updCohorts = await getUserCohorts();
        filteredCohorts = updCohorts
      }

      const promises = [];
      for (const cohort of filteredCohorts) {
        promises.push(
            wrapPromiseWithOutcome(getCohortAggregates(cohort, columns).then((data) => {
            cohort.data = data;
          }))
        );
      }
      Promise.all(promises).then((values) => {
        // Filter out cohorts without any studies and any that resulted in a rejected promise
        filteredCohorts.forEach((element, index) => {
          if (element.data === null || values[index].status !== 'fulfilled') {
            element.data = [];
          }
        });

        setCohorts(filteredCohorts);
        setLoading(false);
      });
    } catch {
      setLoading(false);
    }
  }

  const setSelectedProjectsFilters = (filters) => {
    
    if(Object.entries(selectedProjects).length !== 0) {
      
      // Set the default filters if "explore from selection" link is used
      const filterVariable = filters.projectId ? 'projectId' : filters.cohort_name ? 'cohort_name' : null;
      
     
      if(filterVariable) {
        const projectFilters = { ...filters[filterVariable] };
        Object.keys(projectFilters).forEach((projectId) => {
          projectFilters[projectId] = null;
        });
        Object.keys(selectedProjects).forEach((projectId) => {
          projectFilters[projectId] = Boolean(selectedProjects[projectId]);
        });
        setCohortFilters({
          ...filters,
          [filterVariable]: projectFilters
        })
      } else {
        setCohortFilters(filters)
      }
    } else {
      setCohortFilters(filters)
    }
  }

  async function updateFilters() {
    let filters;

    if (cohorts !== undefined) {
      filters = await getInitialFilters(cohorts);
    }
    setInitialFilters(removePropFromFilters(filters, 'cohort_name'));
    setSelectedProjectsFilters(filters);
  }

  React.useEffect(() => {
    return () => {
      setSelectedProjectsFilters({});
      updateSelectedProjectsinGallery({});
    };
  }, []);

  const currentFilter = React.useMemo(() => {
    return !!currentFilterKey && !!cohortFilters
      ? cohortFilters[currentFilterKey]
      : {};
  }, [currentFilterKey, cohortFilters]);

  React.useEffect(() => {
    // TODO cohorts values can be retrieved through guppy aggregates.
    // 1st step: retrieve the cohorts list with the filters
    // 2nd step: for each cohort, do the aggregate query to get the values for the aggregate columns
    setLoading(true);
    updateCohorts();
  }, [selectedTabIdx, cohortsRefreshCount]);

  React.useEffect(() => {
    updateFilters(cohorts);
  }, [cohorts]);

  React.useEffect(() => {
    localStorage.setItem('columns', JSON.stringify(columns));
  }, [columns]);

  const classes = useStyles();

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleCheckToggle = (e) => {
    const cohortUuid = e.target.value;
    const newSelection = {
      ...selectedCohorts,
      [cohortUuid]: selectedCohorts[cohortUuid]
        ? null
        : cohorts.find((c) => c.uuid === cohortUuid),
    };

    
    updateSelectedCohorts(newSelection);
    
  };

  const updateColumns = useCallback((columns) => {
    setColumns(columns);
  }, []);

  const handleFilterChange = useCallback(
    (filterKey, attributeKey, slidersValue) => {
      const newFilters = { ...cohortFilters };
      const filter = newFilters[filterKey];
      let updatedFilters;

      if (filter.min !== undefined && Array.isArray(slidersValue)) {
        // update sliders values
        updatedFilters = {
          ...newFilters,
          [filterKey]: {
            ...newFilters[filterKey],
            range: {
              min: slidersValue[0],
              max: slidersValue[1],
            },
          },
        };

        setCohortFilters(updatedFilters);
      } else {
        // update filters enabled property
        updatedFilters = {
          ...newFilters,
          [filterKey]: {
            ...newFilters[filterKey],
            [attributeKey]: !filter[attributeKey],
          },
        };

        setCohortFilters(updatedFilters);
      }
    },
    [cohortFilters]
  );

  const handleFilterReset = useCallback(
    (attributeKey) => {
      const newFilters = { ...cohortFilters };
      const filter = newFilters[attributeKey];
      let updatedFilters;
      if (filter.min !== undefined) {
        // update slider values to default
        updatedFilters = {
          ...newFilters,
          [attributeKey]: {
            ...filter,
            range: { ...filter.range, min: filter.min, max: filter.max },
          },
        };

        setCohortFilters(updatedFilters);
      } else {
        //enabled all filters property
        updatedFilters = {
          ...newFilters,
          [attributeKey]: resetObj(filter),
        };

        setCohortFilters(updatedFilters);
      }
    },
    [cohortFilters]
  );

  function cloneCohort(cohort) {
    navigate('/explore/cohorts/new', {
      state: { formDefaultData: cohort },
      replace: true,
    });
  }

  const CohortActions = ({ cohort }) => (
    <Box>
      <IconButton size="large" onClick={() => showCohortInfo(cohort)} >
        <InfoIcon />
      </IconButton>
      {/* <IconButton size="large">
        <BarChartIcon />
      </IconButton> */}
      <IconButton size="large" onClick={() => cloneCohort(cohort.cohort_query)}>
        <CopyIcon />
      </IconButton>
    </Box>
  );

  const toggleCustomize = () => {
    // TODO
    setOpenColumnsDialog(true);
  };

  const handleTabChange = (event, newValue) => {
    updateSelectedProjectsinGallery({});
    setSelectedTabIdx(newValue);
    navigate("/explore/cohorts")
  };

  const FilterButton = ({ col }) => {
    const filter = cohortFilters[col.queryVariable];
    const hasFilters =
      filter &&
      (col.type === 'slider'
        ? filter.min != filter.range.min || filter.max != filter.range.max
        : Object.values(filter).some((v) => !v));
    return (
      <Tooltip title="Show only cohorts matching the filter">
        <IconButton
          className={classes.leftPadding1}
          size="large"
          onClick={async () => {
            setCurrentFilterKey(col.queryVariable);
            setOpenFilterDialog(true);
          }}
          sx={{ flexGrow: 0 }}
        >
          {hasFilters ? (
            <Badge component="i" variant="dot" color="primary">
              <FilterIcon fontSize="small" />
            </Badge>
          ) : (
            <FilterIcon fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
    );
  };

  const Header = () => (
    <TableHead>
      <TableRow className={classes.stickyTop}>
        <TableCell
          key={'cohort-name'}
          component="th"
          variant="head"
          className={`${classes.headerCell} ${classes.stickyLeft}`}
        >
          <Box display="flex" justifyContent="space-between" lineHeight={1}>
            Cohort Name
            <FilterButton col={{ queryVariable: 'cohort_name' }} />
          </Box>
        </TableCell>
        {activeColumns.map((col, i) => (
          <TableCell
            key={col.title}
            component="th"
            variant="head"
            className={classes.headerCell}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {col.title}
              <FilterButton col={col} />
            </Box>
          </TableCell>
        ))}
        <TableCell component="th" className={classes.stickyRight}>
          <Button variant="text" onClick={toggleCustomize} underline="hover">
            Customize
          </Button>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const columnTooltip = (value) => {
    if (value?.histogram && value?.histogram[0]?.key !== undefined) {
      const elements = value?.histogram
        .filter((v) => v.count > 0)
        .sort((v) => -v.count);
      return <span>{concatValues(elements)}</span>;
    }
  }

  return (
    <>
      <Tabs
        value={selectedTabIdx}
        textColor="primary"
        onChange={handleTabChange}
        aria-label="Select Cohorts view"
      >
        <Tab label="Available Projects" sx={{ marginBottom: -1 }} disabled={loading}/>
        <Tab label="My Cohorts" sx={{ marginBottom: -1 }} disabled={loading} />
      </Tabs>
      <Input
        id="filter-cohorts-table"
        placeholder="Search"
        className={classes.search}
        value={search}
        onChange={handleSearch}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />

      {loading ? (
        <div
          id="cvb-myStuff-studyDownload-inputChips"
          className={classes.overlayLoader}
        >
          <img
            src={LoaderImg}
            className={classes.overlayLoaderImg}
            alt="loader"
          />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <ScrollContainer
            className={classes.scrollContainer}
            ignoreElements={'span'}
          >
            <Table stickyHeader={true}>
              <Header />

              <TableBody>
                {filteredCohorts.map((cohort) => (
                  <TableRow
                    key={cohort.uuid}
                    className={
                      selectedCohorts[cohort.uuid] ? classes.selectedRow : ''
                    }
                  >
                    <TableCell
                      key={cohort.uuid + 'name'}
                      component="th"
                      variant="head"
                      className={classes.stickyLeft}
                    >
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        value={cohort.uuid}
                        checked={selectedCohorts[cohort.uuid]}
                        onChange={handleCheckToggle}
                        label={displayCellValue(cohort.cohort_name)}
                      />
                    </TableCell>
                    {activeColumns.map((attr, i) => {
                      return ( <Tooltip placement='top' title={columnTooltip(cohort.data[attr?.queryVariable] ?? 'N/A')}>
                        <TableCell
                          key={cohort.uuid + attr?.queryVariable}
                          variant="body"
                        >
                          {displayCellValue(
                            cohort.data[attr?.queryVariable] ?? 'N/A'
                          )}
                        </TableCell>
                      </Tooltip> )}
                    )}
                    <TableCell className={classes.stickyRight}>
                      <CohortActions cohort={cohort} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollContainer>
        </TableContainer>
      )}
      <CustomizeColumnsDialog
        open={openColumnsDialog}
        onClose={() => setOpenColumnsDialog(false)}
        columns={columns}
        updateColumns={(updatedColumns) => updateColumns(updatedColumns)}
      />
      <CohortsFilterDialog
        open={openFilterDialog}
        onClose={() => setOpenFilterDialog(false)}
        filters={currentFilter}
        title={
          ATTRIBUTES_MAP[currentFilterKey]?.title ||
          currentFilterKey.replace('_', ' ')
        }
        filterKey={currentFilterKey}
        handleFilterChange={handleFilterChange}
        handleReset={handleFilterReset}
      />
    </>
  );
};

export default CohortsTable;

/**
 *  External Imports
 */
import { func, bool } from "prop-types";

import React, { useState, useEffect } from "react";

/**
 *  Internal Imports
 */

import iconSearch from "../../../assets/icons/search-white.svg";

import styles from "./index.module.css";

import CommunityGlobalSearchPopover from "./../community-global-search-popover";

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 *
 *  @TODO Add errors checking
 */
const CommunityGlobalSearchButton = (props) => {
    // Variables
    const { togglePopover } = props;
    const { globalSearchPopoverOpen } = props;

    const [anchorElm, setAnchorElm] = useState(null);

    // Functions

    // Event Functions
    const onButtonClick = (event) => {
        setAnchorElm(event.currentTarget);
        togglePopover(true);
    };

    useEffect(() => {
        if (!globalSearchPopoverOpen) {
            setAnchorElm(null);
        }
    }, [globalSearchPopoverOpen]);

    // Return
    return (
        <>
            <li data-testid="globalSearchBtn"
                className={
                    globalSearchPopoverOpen
                        ? `${styles.communityGlobalSearchButton} cvb-button-active`
                        : styles.communityGlobalSearchButton
                }
                onClick={onButtonClick}>
                <img data-testid="globalSearchImg" src={iconSearch} alt="Search" />

                <p data-testid="globalSearchText">Search</p>
            </li>
            <CommunityGlobalSearchPopover
                data-testid="globalSearchPopover"
                globalSearchAnchorElement={anchorElm}
            />
        </>
    );
};

/**
 *  Properties
 */
CommunityGlobalSearchButton.propTypes = {
    togglePopover: func.isRequired,
    globalSearchPopoverOpen: bool.isRequired,
};

/**
 *  Exports
 */
export default CommunityGlobalSearchButton;

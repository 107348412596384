import TrainingAndCertificate from './TrainingAndCertificate'
import { connect } from 'react-redux'
import { updateSnackBar } from '../../../redux/actions/globalActions'
const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TrainingAndCertificate);
import React, { useState, useEffect, useCallback } from 'react'
import Classes from './AddPricing.module.css'
import BackButtonArrow from '../../../assets/icons/left_arrow.png'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import OutlinedInput from '@mui/material/OutlinedInput'
import MultiSelect from '../../commons/multi-select'
import DropDown from '../../commons/dropdown'
import { COUNTRY_LIST } from '../../../constants/strings'
import ErrorText from '../../controls/error-text';
import { getUserSearchAdminApi } from '../../../api/admin/user'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce'
import { generateInvoiceDetails } from '../../../api/invoice-api';
import { navigate } from '@reach/router'
import { getPricingDetails, addPricingConfiguration, editPricingConfiguration } from '../../../api/pricing-api'


const AddPricingConfig = ({ updateSnackBar, id }) => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [note, setNote] = useState('');
  const [discount, setDiscount] = useState('');
  const [pricingDetails, setPricingDetails] = useState({})
  const [type, setType] = useState('');
  const [user, setUser] = useState('');
  const [userName, setUserName] = useState('')
  const [workspaceId, setWorkspaceId] = useState('');
  const [otherInformation, setOtherInformation] = useState('');
  const [errors, setErrors] = useState({})
  const [usersList, setUsersList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    let errors = {};
    if (!name) {
      errors['name'] = 'Please enter a valid name'
    }
    if (parseFloat(price) < 0 || price === '' ||  price === null) {
      errors['price'] = 'Please enter a valid price'
    }
    if (!type) {
      errors['type'] = 'Please select a value'
    }
    if (discount > 100 || discount < 0) {
      errors['discount'] = 'Please enter a valid discount'
    }
    
    setErrors(errors)
    return Object.keys(errors)?.length === 0
  }

  const getPricingConfigDetails = async () => {
    const pricingDetailsResponse = await getPricingDetails(id); 
    setPricingDetails(pricingDetailsResponse);
    setName(pricingDetailsResponse?.label)
    setPrice(pricingDetailsResponse?.price)
    setDiscount(pricingDetailsResponse?.discount)
    setNote(pricingDetailsResponse?.notes)
    setType(pricingDetailsResponse?.applies_to)
  }

  const handleRequest = async () => {
    const isFormValid = validateForm();
    let response = "";
    if (!isFormValid) {
      return;
    }
    setIsLoading(true)
    try {
      const body = {
        "name": name,
        "applies_to": type,
        "price": price,
        "unit": "GB",
        "discount": discount,
        "discount_type": "PERCENTAGE",
        "note": note 
      }
      if(id){
        response = await editPricingConfiguration(body, pricingDetails?.id);
        updateSnackBar(response?.message || "Price configuration updated successfully", 'success');
      }else{
        response = await addPricingConfiguration(body);
        updateSnackBar(response?.message || "Price configuration added successfully", 'success');
      }
      navigate('/pricing')
    }
    catch (error) {
      console.log(error)
      updateSnackBar(error?.message || "Something went wrong, Please try again!", 'error')
    }
    finally{
      setIsLoading(false)
    }
  }

  const getUsersList = async inputName => {
    try {
      const response = await getUserSearchAdminApi(inputName);
      setUsersList(response?.users || [])
      console.log(response)
    }
    catch (error) {
      setUsersList([])
      console.log(error)
    }
  }

  const onCancelAndButtonClick = () => {
		navigate('/pricing');
	}

  useEffect(() => {
    if(id){
      getPricingConfigDetails()
    }
  }, [id])



  return (
    <div className={Classes.editProfileMainContainer}>
      <div className={Classes.editProfileChildContainer}>
        <Grid container direction='column'>
          <p id='cvb-pricingConfiguration-backButton'
            // onClick={() => navigate('/my-profile')} 
            className={Classes.backButton}>
            <span><img alt='back button arrow' src={BackButtonArrow} /></span>
            <p id='cvb-pricingConfiguration-backButtonText'
              onClick={onCancelAndButtonClick}
              className={Classes.backButtonText}>Back</p>
          </p>
          <h3 id='cvb-pricingConfiguration-heading' className={Classes.editProfileText}>
            { 
              id ? 'Edit Configuration' : 'Add Configuration'
            }
          </h3>
        </Grid>
        <Grid spacing={3} xs={10} container direction='row' className={Classes.advancedComputeContainer}>
          <Grid item xs={5} className={Classes.fieldCont}>
            <label htmlFor='name' id="cvb-pricingConfiguration-projectTitleLabel">Name<sup> *</sup></label>
            <OutlinedInput
              type="text"
              value={name}
              fullWidth
              inputProps={{ maxLength: 256 }}
              onChange={e => setName(e.target.value)}
              id='cvb-pricingConfiguration-projectTitle'
              name='name'
              placeholder='Enter name'
            />
            {errors['name'] && !name && <ErrorText errorMessage={errors['name']} />}
          </Grid>
          <Grid item xs={10} className={Classes.fieldCont}>
            <label id="cvb-pricingConfiguration-workspaceInstanceLabel">Applicable To<sup> *</sup></label>
            <Grid container>
              <Grid item xs={4} className={Classes.radioCheckContainer}>
                <label className={Classes.radioContainer} id="cvb-pricingConfiguration-ec2Label">Project</label>
                <input id='isDataCurated1' type="radio" name="isDataCurated"
                  checked={type === "PROJECT"}
                  onChange={() => setType('PROJECT')}
                />
                <span className={Classes.checkmark}></span>
              </Grid>
              <Grid item xs={4} className={Classes.radioCheckContainer}>
                <label className={Classes.radioContainer} id="cvb-pricingConfiguration-windowsLabel">Workspace</label>
                <input id='isDataCurated2' type="radio" name="isDataCurated"
                  checked={type === "WORKSPACE"}
                  onChange={() => setType('WORKSPACE')}
                />
                <span className={Classes.checkmark}></span>
              </Grid>
            </Grid>
            {errors['type'] && !type && <ErrorText errorMessage={errors['type']} />}
          </Grid>

          <Grid item xs={3} className={Classes.fieldCont}>
            <label htmlFor='price' id="cvb-pricingConfiguration-projectTitleLabel">Price<sup> *</sup> $</label>
            <div>Include storage, transfer and any overheads</div>
            <OutlinedInput
              type="number"
              value={parseFloat(price)}
              fullWidth
              inputProps={{ maxLength: 256 }}
              onChange={e => setPrice(parseFloat(e.target.value))}
              id='cvb-pricingConfiguration-price'
              name='price'
              placeholder='Enter Amount'
            /> 
            {errors['price'] && (price < 0 || price === '' ||  price === null) && <ErrorText errorMessage={errors['price']} />}
          </Grid>

          <Grid item xs={3} className={Classes.fieldCont}>
            <div style={{marginTop:'85px', marginLeft:'-10px'}}>/ GB</div>
          </Grid>

          <Grid item xs={4} className={Classes.fieldCont}></Grid>

          <Grid item xs={3} className={Classes.fieldCont}>
            <label htmlFor='discount' id="cvb-pricingConfiguration-projectTitleLabel">Discount %</label>
            <OutlinedInput
              type="number"
              value={parseFloat(discount)}
              fullWidth
              inputProps={{ min:0, max: 100 }}
              onChange={e => setDiscount(e.target.value)}
              id='cvb-pricingConfiguration-discount'
              name='discount'
              placeholder='Enter discount'
            />
            {errors['discount'] && (discount > 100 || discount < 0) && <ErrorText errorMessage={errors['discount']} />}
          </Grid>
          
          <Grid item xs={10} className={Classes.fieldCont}>
            <label htmlFor='note' id="cvb-pricingConfiguration-organizationLabel">Note</label>
            <OutlinedInput
              type="text"
              value={note}
              onChange={e => setNote(e.target.value)}
              fullWidth
              id='cvb-pricingConfiguration-note'
              name='note'
              placeholder='Enter note'
            />
          </Grid>

          <Grid item xs={10} className={Classes.fieldCont}>
            <label htmlFor='organization' id="cvb-pricingConfiguration-organizationLabel">Preview (1 GB) :</label>
            <div>
              {
                name ? `${name}: ` : null
              }
              {
                (discount) && (price != 0) ?
                
                  <span style={{textDecoration: 'line-through'}}>${price + ' '} </span> 
                  
                : null
              }
              {
                name && (price == 0) ?

                <span> FREE </span> :

                (price) ?

                ` $${((price ) * 1) * ((100 - discount)/100)} ` : null
              } 
              
              {' ' + note}
            </div>
          </Grid>
          
        </Grid>
      </div>
      <div className={Classes.agreementFormButtons}>
        <button className={`no-outlined-button`}  onClick={onCancelAndButtonClick}>Cancel</button>
        <button onClick={handleRequest} disable={isLoading} className={`medium-size-button solid-button ${Classes.requestAccessButton}`}>
          Save Configuration
        </button>
      </div>
    </div>
  )
}

export default AddPricingConfig

import React from 'react'
import classes from './index.module.css'
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DownArrow from '../../../assets/icons/down-arrow.svg'
import Checkbox from '@mui/material/Checkbox';
import v4 from 'uuid/v4'
import Tooltip from '@mui/material/Tooltip'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '../../../assets/icons/search-icon.svg';

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
    }
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        textTransform: 'capitalize',
        pointerEvents: 'none'
    },
}))(Tooltip);

export default function MultiSelectWithSearch(props) {

    const {
        title = "Customize",
        data = [],
        toggleCustomSelectedItem = () => { },
        
    } = props;

    const materialClasses = useStyles();
    const [open, setOpen] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');

    const handleClick = () => {
        setOpen(prev => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const isAllItemsSelected = data && data.filter(item => item.isSelected).length === data.length

    const formatItem = inputString => {
        if (!inputString) {
            return inputString;
        }
        const removedUnderscores = inputString?.split("_").join(" ");
        const trimmedString = removedUnderscores.trim()
        return trimmedString.charAt(0).toUpperCase() + trimmedString.slice(1)
    }

    const onSearchChange = (e) =>{
        setSearchText(e.target.value);
        props.onSearchChange(e.target.value);
    }
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={materialClasses.wrapper}>
                <div id='cvb-checkBoxOptionsPopover-title-button' onClick={handleClick}>
                    <p id='cvb-checkBoxOptionsPopover-title-text' className={classes.customizeText}>{title} < span > <img src={DownArrow} alt="Down Arrow" /></span></p>
                </div>
                {
                    open &&
                    <div className={classes.moreFiltersWrapper}>
                        <ul className={classes.moreFiltersPopover}>
                            <li className={classes.popOverCategory}>
                                <TextField
                                    fullWidth
                                    value={searchText}
                                    onChange={(e) => onSearchChange(e)}
                                    id="standard-basic" label=""
                                    placeholder="Search" 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility" size="large">
                                                    <img alt='visibility On' className={classes.searchDropdownIcon} src={SearchIcon} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    />
                            </li>
                            <li className={classes.popOverCategory}>
                                <span>
                                    <Checkbox
                                        checked={isAllItemsSelected}
                                        color="primary"
                                        onClick={() => { toggleCustomSelectedItem(null) }}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        id='cvb-checkBoxOptionsPopover-allSelectCheckbox'
                                    />
                                    <span id='cvb-checkBoxOptionsPopover-allSelect-text' className={classes.filterSelectAll}>Select All</span>
                                </span>
                            </li>
                            {
                                data && data.length > 0 &&
                                data.map(item => {
                                    return (
                                        <li key={v4()}>
                                            <Checkbox
                                                id={`cvb-checkBoxOptionsPopover-singleItem-${item?.name}`}
                                                checked={item.isSelected}
                                                onClick={() => { toggleCustomSelectedItem(item) }}
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                            <LightTooltip placement="bottom-start"
                                                className={classes.filterText}
                                                title={item?.displayName || formatItem(item?.name)}>
                                                <p id={`cvb-checkBoxOptionsPopover-item-${item?.name}`} className={classes.filterText}>
                                                    {item?.displayName || formatItem(item?.name)}
                                                </p>
                                            </LightTooltip>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                }
            </div>
        </ClickAwayListener >
    );
}

import {
    UPDATE_SNACK_BAR, UPDATE_PROFILE_PICTURE_URL,
    PULL_USER_DETAILS_COUNT, UPDATE_IS_ANY_WORKSPACE_STARTED,
    UPDATE_WEB_SOCKET_CONNECTION, UPDATE_SOCKET_MESSAGE, SET_ALL_NOTIFICATIONS_DATA
} from '../action-types'
import Store from '../store';
import {env} from '../../env'
import { getAllWorkspaces, getWorkspaceStatus, getAllWorkspaceCarts } from '../../api/workspace-api/workspace-api'
import { Auth } from 'aws-amplify'
import Sockette from "sockette";
import { setAllWorkspaces, setAllWorkspacesCarts } from './workspaceActions'
import isEmpty from 'lodash/isEmpty'
export const updateSnackBar = (message, type, placement = {}) => {
    if (message === "CLOSE") {
        return { type: UPDATE_SNACK_BAR, payload: {} }
    }
    return {
        type: UPDATE_SNACK_BAR,
        payload: message ? {
            isOpen: true,
            message: message,
            type: type || "Success",
            placement: placement
        } : null
    }
}

export const fetchWorkspaceCarts = async () => {
    try {
        let allWorkspaceCarts = {}
        if(isEmpty(Store.getState()?.workspace?.allWorkspacesCarts)){
            allWorkspaceCarts = await getAllWorkspaceCarts();
        }else{
            allWorkspaceCarts = Store.getState()?.workspace?.allWorkspacesCarts
        }
        if(!isEmpty(allWorkspaceCarts)){
            Store.dispatch(setAllWorkspacesCarts(allWorkspaceCarts))
        }
    }
    catch (error) {
        console.log(error)
    }
}

export const fetchWorkSpaceOverallStatus = async () => {
    try {
        let allWorkSpaces = [];
        if(Store.getState()?.workspace?.allWorkspaces?.length <= 0){
            allWorkSpaces = await getAllWorkspaces();            
        }else{
            allWorkSpaces = Store.getState()?.workspace?.allWorkspaces;
        }
        if (allWorkSpaces?.length > 0) {
            Store.dispatch(setAllWorkspaces(allWorkSpaces))
            let allPromises = []
            let allStatus = []
            for (let workspace of allWorkSpaces) {
                allPromises.push(getWorkspaceStatus(workspace?.workspace_id).then(response => {
                    if (response?.status)
                        allStatus.push({ status: response.status, id: workspace?.workspace_id })
                }).catch(error => { console.log(error) }))
            }
            await Promise.all(allPromises);
            const startedWorkspaceIDs = allStatus?.map(item => {
                if (item.status === "STARTED") {
                    return item.id
                }
            }).filter(item => item)
            Store.dispatch({
                type: UPDATE_IS_ANY_WORKSPACE_STARTED,
                payload: startedWorkspaceIDs
            })
        }
    }
    catch (error) {
        Store.dispatch({
            type: UPDATE_IS_ANY_WORKSPACE_STARTED,
            payload: false
        })
    }
}


export const updateProfilePicture = value => ({ type: UPDATE_PROFILE_PICTURE_URL, payload: value })

export const updatePullCount = () => {
    return {
        type: PULL_USER_DETAILS_COUNT
    }
}

export const establishWebSocket = async () => {
    try {
        if (!Store.getState().global.webSocket) {
            const user = await Auth.currentSession();
            const identities = user?.idToken?.jwtToken || null;
            const ws = new Sockette(`${env.REACT_APP_NOTIFICATIONS_WEBSOCKET}?Authorizer=${identities}`,
                {
                    timeout: 5e3,
                    maxAttempts: 1,
                    onmessage: e => {
                        let data = e?.data
                        Store.dispatch({
                            type: UPDATE_SOCKET_MESSAGE,
                            payload: data
                        })
                    },
                }
            );
            Store.dispatch({
                type: UPDATE_WEB_SOCKET_CONNECTION,
                payload: ws
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export const setAllNotificationsData = (data) => {
    return {
        type: SET_ALL_NOTIFICATIONS_DATA,
        payload: data
    }
}
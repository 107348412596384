/**
 *  External Imports
 */
import { connect } from "react-redux";

/**
 *  Internal Imports
 */
import {
    resetCommunitySuccessModalAction,
    closeCommunitySuccessModalAction,
} from "../../../redux/actions/communityActions";

import CommunitySuccessModal from "./CommunitySuccessModal";

/**
 *  Functions
 */
const mapStateToProps = ({
    community: { showCommunitySuccessModal, dialogSuccessData },
}) => ({
    showCommunitySuccessModal,
    ...dialogSuccessData,
});

const mapDispatchToProps = (dispatch) => ({
    onDialogReset: () => dispatch(resetCommunitySuccessModalAction()),
    onDialogClose: () => dispatch(closeCommunitySuccessModalAction()),
});

/**
 *  Exports
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunitySuccessModal);

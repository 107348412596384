import React, { useState, useEffect } from 'react'
import Classes from './index.module.css'
import IdleTimer from 'react-idle-timer'
import moment from 'moment'
import Modal from '../../commons/modal'
import { Auth } from 'aws-amplify'
import Grid from "@mui/material/Grid";
import InfoIconModal from '../../../assets/icons/InfoIconModal.svg'

const SessionTimer = ({ forceUpdate, logout }) => {

    let counterInterval = null;

    const COUNTER = 60;

    const SESSION_TIMEOUT_MINUTES = 15;

    const [showExtendSessionModal, setShowExtendSessionModal] = useState(false)

    let tickingTimerStartTime = null;


    const toggleModal = () => {
        logout()
    }

    const updateTimer = () => {
        let count = COUNTER;
        const ele = document.getElementById("left-over-session-timeout");
        if (ele) {
            counterInterval = setInterval(() => {
                if (count > 0) {
                    count--;
                    ele.innerHTML = count;
                } else {
                    if (showExtendSessionModal) {
                        logout();
                    }
                }
            }, 1000)
        }
    }

    const handleSessionTimeout = () => {
        const lastTokenAccessedTime = localStorage.getItem("lastTokenAccessedTime")
        if (lastTokenAccessedTime) {
            const time = moment(lastTokenAccessedTime).toDate().getTime();
            const time30minsAgo = moment().subtract(SESSION_TIMEOUT_MINUTES, 'minutes').toDate().getTime();
            if (time > time30minsAgo) {
                forceUpdate();
            } else {
                setShowExtendSessionModal(true);
            }
        }
    }

    useEffect(() => {
        if (showExtendSessionModal) {
            tickingTimerStartTime = new Date().getTime()
            setTimeout(() => {
                if(tickingTimerStartTime + 200 > new Date().getTime()){
                    updateTimer();
                }else{
                    logout();
                }
            }, 100)
        }
        if (!showExtendSessionModal && counterInterval) {
            clearInterval(counterInterval)
        }
        return () => {
            clearInterval(counterInterval)
        }
    }, [showExtendSessionModal])

    const onPositiveDeclineBtnAction = () => {
        Auth.currentAuthenticatedUser();
        localStorage.setItem("lastTokenAccessedTime", new Date().toString())
        setShowExtendSessionModal(false)
        forceUpdate();
    }

    const handleNegativeDeclineModal = () => {
        setShowExtendSessionModal(false)
        logout();
    }

    const DECLINE_MODAL = {
        modalTitle: "",
        positiveButtonText: "Continue Session",
        negativeButtonText: "Logout",
        positiveButtonAction: onPositiveDeclineBtnAction,
        negativeButtonAction: handleNegativeDeclineModal
    }

    return (
        <div>
            <IdleTimer
                timeout={1000 * 60 * SESSION_TIMEOUT_MINUTES}
                onIdle={handleSessionTimeout}
                debounce={10000}
            />
            <Modal
                open={showExtendSessionModal}
                handleClose={toggleModal}
                dialogProps={DECLINE_MODAL}
                disableTitleDivider={true}
                className={Classes.sessionTimeOutModal}>
                <div className={Classes.sessionTimeOutContainer}>
                    <Grid container>
                        <Grid item xs={1}>
                            <img src={InfoIconModal} alt="Info Icon" />
                        </Grid>
                        <Grid item xs={10} className={Classes.sessionTimeOutContent}>
                            <h4>Session is going to expire. Would you like to continue?</h4>
                            <p>Left Over Time: <span className={Classes.sessionTimeOutContentTimer} id="left-over-session-timeout">60</span> Seconds</p>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </div>
    )
}

export default SessionTimer


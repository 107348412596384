import React, { useEffect, useState, useMemo } from 'react'
import Classes from './index.module.css';
import MyCohorts from '../../controls/my-cohorts';
import ImgShare from '../../../assets/icons/share-menu.svg'
import ImgRemoveCohort from '../../../assets/icons/remove-cohort.svg'
import ImgBackGrey from '../../../assets/icons/grey_back.svg'
import ImgBack from '../../../assets/icons/backwards.svg'
import ImgForward from '../../../assets/icons/forward.svg'
import ImgForwardGrey from '../../../assets/icons/grey_forward.svg'
import { Auth } from 'aws-amplify';
import SectionHeader from '../../commons/section-header';
import isEmpty from 'lodash/isEmpty'
import Grid from '@mui/material/Grid'
import Authorisation from '../../controls/authorisation-certificates';
import useScrollToTop from '../../commons/scroll-top';
import SavedSearch from '../../controls/saved-search';
import SavedStudyProjectDownloads from '../../controls/saved-study-project-downloads'
import WorkspaceFileDownloadOrders from '../../controls/workspace-file-download-orders'
const MyStuff = props => {
    
    const LIMIT_PER_PAGE = 5;
    useScrollToTop();

    useEffect(() => {
        props.resetMyStuffStore();
        setPageNumber(1);
    }, []);

    const [pageNumber, setPageNumber] = useState(-1);
    const [showCohortsLoader, setShowCohortsLoader] = useState(false)

    useEffect(() => {
        getCohortsData();
    }, [pageNumber])

    const getCohortsData = async () => {
        try {
            if (pageNumber > 0) {
                if (props.userCohorts?.length < (pageNumber) * LIMIT_PER_PAGE) {
                    setShowCohortsLoader(true)
                    if (props.userCohorts.length > 0) {
                        if (!isEmpty(props.lastEvaluatedKey)) {
                            await props.getUserCohorts(LIMIT_PER_PAGE, props.lastEvaluatedKey);
                        }
                    }
                    else {
                        await props.getUserCohorts(LIMIT_PER_PAGE, props.lastEvaluatedKey);
                    }
                    setShowCohortsLoader(false)
                }
            }
        }
        catch (error) {
            setShowCohortsLoader(false);
        }
    }

    const sectionHeaderProps = useMemo(() => {
        return {
            title: "My Stuff",
        }
    }, [])

    const handlePreviousButton = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    const handleNextButton = () => {
        if (!isEmpty(props.lastEvaluatedKey) || props.userCohorts.length >= pageNumber * LIMIT_PER_PAGE) {
            setPageNumber(pageNumber + 1)
        }
    }
    
    
    const isPreviousDisabled = pageNumber == 1
    const isNextDisabled = isEmpty(props.lastEvaluatedKey) && props.userCohorts.length <= (pageNumber * LIMIT_PER_PAGE);

    return (
        <>
            <SectionHeader
                open={props.open}
                sectionHeaderProps={sectionHeaderProps} />
            <div className={Classes.container}>
                {/* {
                    props.userDetails?.resourceActions?.my_stuff?.list_cohorts ?
                    <div id='cvb-myStuffLanding-cohortContainer'>
                        <p id='cvb-myStuffLanding-cohortHeading' className={Classes.myStuffSectionHeaders}>My Cohorts</p>
                        <p id='cvb-myStuffLanding-cohortDescription' className={Classes.myStuffSectionSubheader}>Saved Cohorts created in the Data Explorer will be located here</p>
                        <MyCohorts pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            limitPerPage={LIMIT_PER_PAGE}
                            showCohortsLoader={showCohortsLoader} />
                        <Grid container spacing={4} className={Classes.paginationContainer}>
                            <Grid item lg={8} md={12} xs={12} className={Classes.paginationItem} id='cvb-myStuffLanding-paginationSection'>
                                {
                                    isPreviousDisabled ? <div><img src={ImgBackGrey} alt="previous icon" /></div> :
                                        <div id='cvb-myStuffLanding-paginationPrevious' onClick={handlePreviousButton}><img src={ImgBack} alt="previous icon" /></div>
                                }
                                {
                                    isNextDisabled ? <div><img src={ImgForwardGrey} alt="next icon" /></div> :
                                        <div id='cvb-myStuffLanding-paginationNext' onClick={handleNextButton}><img src={ImgForward} alt="previous icon" /></div>
                                }
                            </Grid>
                        </Grid>
                    </div> : null
                } */}

                {/*    // props.userDetails?.resourceActions?.my_stuff?.list_saved_search ?
                    //     <SavedSearch userDetails={props.userDetails}></SavedSearch>
                    // : null */}

                <Authorisation userDetails={props.userDetails}></Authorisation>
                
                {
                    <SavedStudyProjectDownloads userDetails={props.userDetails}></SavedStudyProjectDownloads>
                  
                }
                {
                    <WorkspaceFileDownloadOrders />
                }

            </div>
        </>
    )
}

export default MyStuff;
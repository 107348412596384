import React, { useEffect, useState } from 'react'
import Classes from './index.module.css'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import Checkbox from '@mui/material/Checkbox'
import SignatureCanvas from 'react-signature-canvas'
import ErrorText from '../../controls/error-text'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import { VALID_EMAIL_REGEX } from '../../../constants/regex'
import DropDown from '../../commons/dropdown';
import { COUNTRY_LIST } from '../../../constants/strings'
import moment from 'moment'
import CommonDatePicker from '../../commons/common-date-picker'
const ServiceAgreement = props => {
    const [canvasClicked, setCanvasClicked] = useState(false);
    const {
        setServiceAgreementDate,
        setClientName,
        setAgreementYears,
        setSignatoryName,
        setSignatoryTitle,
        setNonCommercialCheck,
        setServicesCheck,
        setAgreementChecks,
        sigRef,
        isFormSubmitted,
        userDetails,

        institution,
        setInstitution,
        signatureEmail,
        setSignatureEmail,
        setCountryOfInst,
        streetAddressOfInst,
        setStreetAddressOfInst,
        cityOfInst,
        setCityOfInst,
        countryOfInst,
        stateOfInst,
        setStateOfInst,
        zipCodeOfInst,
        setZipCodeOfInst,

    } = props;
    const { serviceAgreementDate = null, clientName = '', agreementYears = '', signatoryName = '', signatoryTitle = '',
        nonCommercialCheck = false, servicesCheck, agreementChecks } = props;
    const fullNameOfUser = (capitalize(userDetails?.["first_name"]) + " " + capitalize(userDetails?.["last_name"]))

    const onCanvasClick = () => {
        setCanvasClicked(true);
    }
    const onServicesCheck = (name, checked) => {
        const servicesCheckData = { ...servicesCheck, [name]: checked };
        setServicesCheck(servicesCheckData);
    }
    const onAgreementCheckClick = (name, checked) => {
        const agreementCheckData = { ...agreementChecks, [name]: checked };
        setAgreementChecks(agreementCheckData);
    }
    const clearSignature = () => {
        sigRef.current.clear();
        setCanvasClicked(false);
    }
    const isFieldValid = field => !!props?.[field];

    const isEmailValid = email => {
        let reg = VALID_EMAIL_REGEX;
        return reg.test(email);
    }
    const renderErrorText = (field, name, isEmail) => {
        if (!isFormSubmitted) {
            return null;
        }
        if (isFormSubmitted && !isFieldValid(field)) {
            return <ErrorText errorMessage={`Enter a valid ${name} `} />
        }
        if (isEmail && !isEmailValid(props?.[field])) {
            return <ErrorText errorMessage={`Enter a valid ${name} `} />
        }
        return null;
    }
    // const onYearInputChange = (value) => {
    //     if (value !== '') {
    //         setAgreementYears(1);
    //     }
    //     else {
    //         setAgreementYears(value);
    //     }
    // }

    const isSignatureValid = () => !!sigRef?.current?.toDataURL();
    return (
        <div className={Classes.container}>
            <Grid container direction='column'>
                <div className={Classes.impTitleHeaderContainer}>
                    <p id='cvb-serviceAgreement-importantHeading' className={Classes.alertTitle}>Important </p>
                    <p id='cvb-serviceAgreement-fillServAgrInfo'>Please read the following agreement terms before getting started.
                        Fill out services agreement to get approved to access services in BRAINCommons.</p>
                        <p className={Classes.aoDefinitionText}>Authorizing Official: This means an executive, officer or other individual who is authorized to sign contracts on behalf of your organization or company. You can find out who that is by contacting the office of your legal counsel or chief executive. </p>
                </div>
                <Grid container direction='column'>
                    <div className={Classes.serviceAgreementBlock} id='service-agreement'>
                        <h3 id='cvb-serviceAgreement-header' className={Classes.agreementHeadingText}>Service Agreement</h3>
                        <p>This BRAINCommons Services Agreement (<b>“Agreement”</b>) is effective as of
                            <span className={Classes.inputContainer}>
                                <CommonDatePicker
                                    onCalenderDateSelected={(date) => setServiceAgreementDate(date)}
                                    date={serviceAgreementDate}
                                    minDate={moment().add(1, 'd').toDate()}
                                    showValidationError={isFormSubmitted && !serviceAgreementDate}
                                    errorMessage='Enter a service agreement date'
                                    id='cvb-serviceAgreement-selectDateInput'
                                    canClearInput
                                />
                            </span>
                            (the <b>“Effective Date”</b>) by and between Cohen Veterans Bioscience, Inc. (<b>“CVB”</b>), a Massachusetts
                            nonprofit corporation, and
                            <span className={Classes.inputContainer}>
                                <OutlinedInput
                                    placeholder='Enter client name'
                                    name='clientName'
                                    type='text'
                                    onChange={(e) => setClientName(e.target.value)}
                                    value={clientName}
                                    fullWidth
                                    id='clientName'
                                />
                                {isFormSubmitted && !clientName &&
                                    <ErrorText errorMessage="Enter a valid client name" />
                                }
                            </span>
                            (<b>“Client”</b> and collectively with CVB, the <b>“Parties”</b>).
                        </p>
                        <p>
                            CVB has provided initial funding for the development of, and coordinates governance and oversees data
                            contribution, access and services in connection with, the BRAINCommons, a knowledge commons that collocates
                            or integrates data, storage and computing infrastructure with services, tools, interfaces and applications
                            for managing, analyzing, and sharing data to create an interoperable resource for the brain and other
                            research community that enables and encourages data sharing, collaboration and data‐driven discovery
                            (the <b>“BRAINCommons”</b>). The BRAINCommons utilizes a software (and other technology)‐enabled platform
                            for managing, analyzing and sharing biomedical data, which includes a cloud‐based infrastructure that
                            is stewarded by the Open Commons Consortium and is commonly referred to as the Bionimbus PDC1. CVB or
                            its designee (as applicable, the <b>“BC Manager”</b>) is responsible for managing the BRAINCommons.
                            </p>
                        <p>Client wishes to receive certain services from the BC Manager related to the BRAINCommons.</p>
                        <p>The Parties agree as follows:</p>
                        <ol type='1' className={Classes.firstOLList}>
                            <li>
                                <b>Services.</b>
                                <p>The BC Manager will provide the services (the <b>“Services”</b>) listed in the attached
                                <b>Exhibit A</b>, as may be periodically updated.
                                </p>
                                <ol type='a' className={Classes.secondOLList}>
                                    <li>Any change to the Services will be mutually agreed in writing.</li>
                                    <li>The BC Manager will perform the Services in a professional manner in accordance in all
                                    material respects with the applicable specifications.
                                        </li>
                                    <li>
                                        Each Party will comply with all applicable laws and regulations in connection with any
                                        performance, provision or use of the Services or any data, software, tools or other
                                        materials provided or made available on or through the BRAINCommons (collectively,
                                        <b>“Content”</b>). Notwithstanding anything to the contrary in this Agreement, neither Party
                                        will have any obligation to do any act, or refrain from doing any act, in violation of any
                                        applicable laws or regulations.
                                        </li>
                                    <li>
                                        Each Party has all rights necessary to perform its obligations, and to grant any rights
                                        expressly granted to the other Party, under this Agreement.
                                        </li>
                                    <li>
                                        Client will provide all reasonably requested information and cooperation in connection
                                        with the Services. Each Party will timely provide its respective resources and
                                        performance, as designated in this Agreement.
                                        </li>
                                    <li>
                                        Each Party will be excused from any delay or failure in performance resulting from any
                                        events or circumstances beyond such Party’s reasonable control.
                                        </li>
                                    <li>
                                        EXCEPT FOR ANY WARRANTIES EXPRESSLY STATED IN THIS AGREEMENT (OR ANY
                                        RELATED WRITTEN AGREEMENT BETWEEN THE PARTIES), EACH PARTY
                                        DISCLAIMS ALL WARRANTIES OF ANY KIND RELATING TO THE SERVICES OR THIS
                                        AGREEMENT, AND THE BC MANAGER SPECIFICALLY DISCLAIMS ANY WARRANTY
                                        OF MERCHANTABILITY, NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR
                                        PURPOSE. ANY THIRD PARTY PRODUCTS, CONTENT OR SERVICES ARE PROVIDED
                                        OR MADE AVAILABLE “AS IS” WITHOUT ANY WARRANTY OF ANY KIND. The BC
                                        Manager does not warrant that the Services, or any related software or applications,
                                        will be uninterrupted or error-free.
                                        </li>
                                </ol>
                            </li>

                            <li>
                                <b>Term and Termination.</b>
                                <ol type='a' className={Classes.secondOLList}>
                                    <li>
                                        This Agreement begins on the Effective Date and continues for a period of
                                        <span className={Classes.inputContainer}>
                                            <OutlinedInput
                                                placeholder='Enter years'
                                                name='years'
                                                type='number'
                                                //onChange={(e) => onYearInputChange(e.target.value)}
                                                disabled
                                                value={1}
                                                fullWidth
                                                id='years'
                                            />
                                            {/* {
                                                isFormSubmitted && !agreementYears &&
                                                <ErrorText errorMessage="Enter valid number of years" />
                                            } */}
                                        </span>
                                        years
                                        (the <b>“Initial Term”</b>). This Agreement automatically renews on an annual basis, unless
                                        either Party provides written notice of non-renewal at least 60 days before the end of
                                        the applicable term (each, a <b>“Renewal Term”</b> and together with the Initial Term, the
                                        <b>“Term”</b>).
                                </li>
                                    <li>
                                        Either Party may terminate this Agreement, or any Services, for convenience upon
                                        60 days' prior written notice to the other Party.
                                </li>
                                    <li>
                                        Either Party may terminate this Agreement for cause upon 30 days’ written notice if the
                                        other Party has breached any material provision of this Agreement, provided that such
                                        termination shall not be effective (i) if the other Party cures the breach within such 30
                                        day period, or (ii) if the breach cannot be cured within such 30 day period, the other
                                        Party has taken commercially reasonable steps within such 30 day period to cure the
                                        breach, and the other Party thereafter cures the breach as soon as practicable.
                                </li>
                                    <li>
                                        The terms of <b>Sections 1(g), 2(d) and 3, 5, 6, 7, 8 and 10</b>, and any other provisions of this
                                        Agreement which by their nature or terms extend beyond the expiration or termination
                                        of this Agreement, shall survive any expiration or termination of this Agreement. All
                                        rights granted to Client under this Agreement, except pursuant to Section 5(b), shall
                                        automatically and immediately cease upon any expiration or termination of this
                                        Agreement.
                                </li>
                                </ol>
                            </li>
                            <li>
                                <b>Compensation.</b>
                                <ol type='a' className={Classes.secondOLList}>
                                    <li>In consideration for the provision of the Services, Client will pay to the BC Manager the
                                    amounts listed in <b>Exhibit A</b>, which amounts may be periodically updated, in accordance
                                    with the payment schedule and other terms stated therein.
                                    </li>
                                    <li>
                                        Without limiting any other rights or remedies available to it, the BC Manager may
                                        suspend its performance of the Services if any amounts owed to the BC Manager under
                                        this Agreement are more than 30 days past due until all overdue amounts owed to the
                                        BC Manager are paid in full. Late payments will be subject to a late fee of one percent
                                        (1%) per month until paid.
                                    </li>
                                    <li>
                                        Client will pay all applicable taxes with respect to the Services and any other
                                        transactions contemplated by this Agreement, except taxes based on the BC Manager’s
                                        net income and any other real property or similar taxes specifically levied on the BC
                                        Manager.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>BRAINCommons.</b>
                                <p>
                                    Client will ensure that no Client-affiliated individual or entity accesses or
                                    uses the BRAINCommons, except for registered, verified users of the BRAINCommons who
                                    have been expressly authorized by Client and the BC Manager to act on behalf of Client (each, a
                                    <b>“Client User”</b>). Client and each Client User shall comply with and be subject to any applicable
                                    terms of use associated with the BRAINCommons and the BRAINCommons Data Use Agreement
                                    set forth in <b>Exhibit B</b>, as such Exhibit may be updated by mutual agreement of the Parties from
                                    time to time (the <b>“Data Use Agreement”</b>), provided that Client will not unreasonably withhold or
                                    delay its consent to Data Use Agreement changes proposed by the BC Manager from time to time in
                                    the ordinary course. Without limiting the foregoing, Client and each Client User:
                                </p>
                                <ol type='a' className={Classes.secondOLList}>
                                    <li>
                                        shall not contribute, access, use, download, disclose, publish, distribute or otherwise
                                        transfer any Content, except as expressly authorized by the BC Manager and solely in
                                        accordance with any applicable terms of use associated with the BRAINCommons;
                                    </li>
                                    <li>
                                        shall not use or attempt to use any Services or Content in any harmful, disruptive or
                                        otherwise inappropriate manner or for any such purpose;
                                    </li>
                                    <li>
                                        shall not identify or contact, or attempt to identify or contact, any individual to which
                                        any Content pertains;
                                    </li>
                                    <li>shall not copy, reverse engineer, decompile or modify any Services or Content provided
                                    by the BC Manager without the BC Manager’s express prior written consent;
                                    </li>
                                    <li>
                                        shall not assist or authorize any individual or entity in engaging in any of the foregoing
                                        restricted activities;
                                    </li>
                                    <li>
                                        shall report any violation of any applicable terms of use associated with the BRAINCommons or the Data Use Agreement, or any unauthorized access, use, alteration or
                                        disclosure of any data, to the BC Manager immediately upon discovery, providing as
                                        much detailed information as practicable; and
                                    </li>
                                    <li>
                                        shall acknowledge in all oral or written presentations, disclosures or publications the
                                        data source and CVB in accordance with the applicable terms of use associated with the
                                        BRAINCommons and such data.
                                    </li>

                                </ol>
                            </li>
                            <li>
                                <b>Intellectual Property.</b>
                                <ol type='a' className={Classes.secondOLList}>
                                    <li>
                                        As between BC Manager and Client, all right, title, and interest in and to the BRAINCommons, Services, any related Content (excluding Client Content), and any
                                        trademarks or other proprietary indicia of the BC Manager or any of its affiliates,
                                        including any modifications, versions or derivatives of, or any feedback related to, any
                                        of the foregoing, will at all times remain the property of the BC Manager and its
                                        licensors. Any use of the foregoing inures to the sole benefit of the BC Manager, and
                                        Client hereby assigns to the BC Manager all intellectual property rights it may now or
                                        hereafter possess in any of the foregoing and will execute all documents and take all
                                        actions that may be necessary to confirm such rights. The BC Manager reserves all
                                        rights not expressly granted to Client under this Agreement.
                                    </li>
                                    <li>
                                        Subject to the terms of this Agreement, including any terms in any applicable Exhibit
                                        and the confidentiality terms in <b>Section 7</b>, the BC Manager hereby grants to Client a
                                        non-exclusive, perpetual, royalty-free right to use any reports or analyses developed
                                        and provided by the BC Manager to Client under this Agreement (collectively,
                                        <b>“Deliverables”</b>) for any [
                                            <Checkbox
                                            color="primary"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            onChange={(e) => setNonCommercialCheck(e.target.checked)}
                                            value={nonCommercialCheck}
                                        />
                                            non-commercial] research or educational purposes.
                                    </li>
                                    <li>
                                        Subject to the terms of this Agreement, including any terms in any applicable Exhibit
                                        and the confidentiality terms in <b>Section 7</b>, Client hereby grants to the BC Manager a
                                        non-exclusive, perpetual, irrevocable, royalty-free, fully-paid up, worldwide license to
                                        use any data collected in connection with the Services on a de-identified basis for any
                                        internal business purposes.
                                    </li>
                                    <li>
                                        Subject to the terms of this Agreement, including any terms in any applicable Exhibit
                                        and the confidentiality terms in <b>Section 7</b>, Client hereby grants to the BC Manager a
                                        non-exclusive, royalty-free, fully-paid up, worldwide license to use any Client Content
                                        for the purpose of providing, maintaining or improving the Services.
                                    </li>

                                </ol>

                            </li>
                            <li>
                                <b>Indemnification.</b>
                                <ol type='a' className={Classes.secondOLList}>
                                    <li>
                                        Client will indemnify, hold harmless and, at the BC Manager’s option, defend the BC
                                        Manager, its officers, directors, employees, agents and affiliates (each, a <b>“CVB
                                        Indemnitee”</b>) from and against any losses, costs, expenses, claims, damages or other
                                        liabilities, including costs of litigation and reasonable attorney fees, resulting from third
                                        party claims to the extent arising from any Client Content, from Client’s gross
                                        negligence or willful misconduct, from Client’s use of the BRAINCommons or any
                                        Services, Deliverables or Content (except to the extent arising from the BC Manager’s
                                        gross negligence or willful misconduct), or from any material breach by Client of
                                        <b>Sections 1(c), 1(d), 4(b) or 7</b>.
                                    </li>
                                    <li>
                                        The BC Manager will indemnify, defend and hold harmless Client and its officers,
                                        directors and employees (each, a <b>“Client Indemnitee”</b> and together with the CVB
                                        Indemnitees, the <b>“Indemnitees”</b>) from and against any costs, expenses, damages or
                                        other liabilities, including costs of litigation and reasonable attorney fees, resulting
                                        from third party claims to the extent arising from the BC Manager’s gross negligence or willful misconduct or from any material breach by the BC Manager of <b>Sections 1(c), 1(d)
                                        or 7</b>.
                                    </li>
                                    <li>
                                        In connection with any claim subject to the indemnification obligations of this <b>Section
                                        6</b>, each Party will reasonably cooperate, and the indemnifying Party will not settle such
                                        claim without the applicable Indemnitees’ prior written consent (such consent not to be
                                        unreasonably withheld) if such settlement would impose any liabilities or obligations on
                                        any Indemnitee.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>Confidentiality.</b>
                                <p>
                                    Except to the extent otherwise expressly authorized by the terms hereof (or by
                                    the other Party in writing), required by applicable law, or pursuant to an order entered or
                                    subpoena issued by a court of competent jurisdiction, each Party will, during the Term and
                                    thereafter, keep confidential the terms of this Agreement and all confidential or proprietary
                                    information provided to it by the other Party that is marked as confidential or, given its nature
                                    or the circumstances surrounding its disclosure, should reasonably be treated as confidential
                                    (collectively, <b>“Confidential Information”</b>). In protecting the confidentiality of Confidential
                                    Information received from the other, each Party will use the same care as it uses with its own
                                    confidential information, but not less than reasonable care. Each Party agrees not to disclose
                                    the other Party’s Confidential Information to any third party, other than its employees,
                                    directors, agents, subcontractors and advisors as the Party reasonably determines have a need
                                    to know in connection with the Services, each of whom will be advised of the confidentiality
                                    requirements of this Agreement and bound by at least substantially equivalent confidentiality
                                    obligations. Each Party agrees not to use the other Party’s Confidential Information, except in
                                    connection with the Services. Upon the other Party’s request or any expiration or termination
                                    of this Agreement, each Party will promptly return (or, at the other Party’s direction, destroy)
                                    the other Party’s Confidential Information. The restrictions in this <b>Section 7</b> will not apply to the
                                    extent that the information (i) was possessed prior to receipt from the source, (ii) was received
                                    in good faith from a third party not subject to a confidentiality obligation, (iii) now is or later
                                    becomes publicly known through no breach of confidential obligations by the recipient, or
                                    (iv) was independently developed by the recipient without use of the Confidential Information.
                                </p>
                            </li>
                            <li>
                                <b>Limitation of Liability.</b>
                                <ol type='a' className={Classes.secondOLList}>
                                    <li>
                                        EXCEPT FOR ANY BREACH OF CONFIDENTIALITY OBLIGATIONS UNDER THIS AGREEMENT OR ANY
                                        AWARD OF DAMAGES BY A COURT IN A THIRD PARTY CLAIM THAT IS THE SUBJECT TO THE
                                        INDEMNIFICATION OBLIGATIONS IN <b>SECTION 6</b>, IN NO EVENT WILL EITHER PARTY OR ITS OFFICERS,
                                        DIRECTORS, EMPLOYEES, AFFILIATES OR AGENTS BE LIABLE IN CONNECTION WITH THIS
                                        AGREEMENT FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR
                                        CONSEQUENTIAL DAMAGES OR FOR ANY BUSINESS INTERRUPTION OR LOST REVENUE, PROFITS,
                                        OPPORTUNITY OR GOODWILL, REGARDLESS OF THE BASIS OF THE CLAIM OR WHETHER ADVISED OF
                                        THE POSSIBILITY OF SUCH DAMAGES.
                                    </li>
                                    <li>
                                        EXCEPT FOR ANY INDEMNIFICATION OBLIGATIONS IN <b>SECTION 6</b>, IN NO EVENT WILL EITHER
                                        PARTY’S AGGREGATE LIABILITY IN CONNECTION WITH THIS AGREEMENT EXCEED THE TOTAL
                                        AMOUNTS PAID OR PAYABLE BY CLIENT TO THE BC MANAGER UNDER THIS AGREEMENT DURING
                                        THE TWELVE-MONTH PERIOD PRECEDING THE EVENTS FIRST GIVING RISE TO THE CLAIM.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <b>Use of Name.</b>
                                <p>
                                    Each Party reserves the right to approve, in writing and in advance, any use of its
                                    names, trademarks, logos or other proprietary indicia by the other Party. Any approved use will
                                    be in accordance with the approving Party’s applicable trademark usage guidelines (as provided
                                    or made available to the other Party).
                                </p>
                            </li>
                            <li>
                                <b>Miscellaneous.</b>
                                <ol type='a' className={Classes.secondOLList}>
                                    <li>
                                        This Agreement shall be governed by and interpreted in accordance with the internal
                                        substantive laws of the State of New York.
                                    </li>
                                    <li>
                                        Any notice or other document or communication required or permitted under this
                                        Agreement will be deemed to have been duly given only if in writing and delivered by
                                        the following method: to the email address provided by Client in connection with its
                                        registration for the BRAINCommons or such other email address as Client may dictate
                                        according to the notice provisions hereof, and for the BC Manager, to the following
                                        email address: <a href='notice@braincommons.org'>notice@braincommons.org</a> or such other email address as the BC
                                        Manager may dictate according to the notice provisions hereof. Notices shall be
                                        deemed delivered when sent provided there is reasonable indication of receipt by the
                                        Party being notified.
                                    </li>
                                    <li>
                                        This Agreement (including all attachments hereto) sets forth the entire understanding
                                        of the Parties and supersedes all prior agreements and understandings regarding the
                                        subject matter hereof. If any provision of this Agreement is found to be illegal or
                                        invalid, such provision will be modified to the extent necessary to comply with
                                        applicable law and refashioned to best approximate the original intent of the Parties,
                                        and the remaining provisions shall remain in full force and effect in accordance with
                                        their terms. The waiver of any breach or default will not constitute a waiver of any
                                        other right or any subsequent breach or default. No amendment of this Agreement is
                                        binding unless accepted in writing by authorized representatives of both Parties. The
                                        relationship between the Parties under this Agreement is that of independent
                                        contractors. This Agreement may be executed in one or more counterparts, each of
                                        which shall be deemed an original, but collectively shall constitute one and the same
                                        instrument.
                                    </li>
                                    <li>
                                        Neither Party may assign this Agreement without the written consent of the other
                                        Party; provided, however, that: (a) either Party may assign this Agreement to an
                                        affiliate; and (b) either Party may assign this Agreement to an entity that acquires all or
                                        substantially all of the assets or business of such Party.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <p>
                            Each Party has caused this Agreement to be duly executed by its respective authorized representative to
                            take effect as of the Effective Date.
                        </p>
                        <div className={Classes.servicesChecks}>
                            <div>
                                <Checkbox
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    onChange={(e) => onServicesCheck('Workspace', e.target.checked)}
                                    value={servicesCheck['Workspace']}
                                    id='cvb-serviceAgreement-workspaceService-checkbox'
                                />
                                <label  id='cvb-serviceAgreement-workspace-label' className={Classes.signPolicyLabel}>Workspace</label>
                                {
                                    isFormSubmitted && !servicesCheck['Workspace'] &&
                                    <ErrorText errorMessage="Please check this field" />
                                }
                            </div>
                        </div>
                        <div>
                            <Grid container direction='column'>
                                <h3 className={Classes.agreementHeadingText}>Institutional Certification</h3>
                                <Grid item xs={7}>
                                    <Grid container direction='row' spacing={2}>
                                        <Grid item xs={6}>
                                            <label  id='cvb-serviceAgreement-instNameLabel' htmlFor='streetAddress'>Name of the Institution</label>
                                            <OutlinedInput
                                                fullWidth
                                                value={institution}
                                                onChange={e => setInstitution(e.target.value)}
                                                placeholder='Enter Institution Name'
                                                id='institutionName'
                                            />
                                            {renderErrorText("institution", "institution")}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label htmlFor='signEmail'>Email of Authorizing Official at the Institution</label>
                                            <OutlinedInput
                                                fullWidth
                                                value={signatureEmail}
                                                onChange={e => setSignatureEmail(e.target.value)}
                                                placeholder='Enter email' 
                                                id='cvb-serviceAgreement-signatoryEmail-input'
                                                />
                                            {renderErrorText("signatureEmail", "signature email", true)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <h4  id='cvb-serviceAgreement-instAddress-label' className={Classes.contactFormTitles}>Institution Address</h4>
                                <Grid item xs={7}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <label htmlFor='country'>Country</label>
                                                    <DropDown
                                                        id='country'
                                                        values={COUNTRY_LIST}
                                                        handleFilterStateChange={setCountryOfInst}
                                                        selectedFilter={countryOfInst}
                                                        placeholder={'Select Country'}
                                                        searchable
                                                        isClearable
                                                    />
                                                    {renderErrorText("countryOfInst", "Country")}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <label  id='cvb-serviceAgreement-streetAddr-label' htmlFor='streetAddress'>Street Address</label>
                                            <OutlinedInput
                                                fullWidth
                                                value={streetAddressOfInst}
                                                onChange={e => setStreetAddressOfInst(e.target.value)}
                                                placeholder='Enter street address'
                                                name='streetAddress' 
                                                id='cvb-serviceAgreement-streetAddr-input'
                                                />
                                            {renderErrorText("streetAddressOfInst", "Street Address")}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <label htmlFor='city'>City</label>
                                                    <OutlinedInput
                                                        fullWidth
                                                        value={cityOfInst}
                                                        onChange={e => setCityOfInst(e.target.value)}
                                                        placeholder='Enter city'
                                                        name='city'
                                                        id='instituteCity'
                                                    />
                                                    {renderErrorText("cityOfInst", "City")}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <label htmlFor='state'>State</label>
                                                    <OutlinedInput
                                                        fullWidth
                                                        value={stateOfInst}
                                                        onChange={e => setStateOfInst(e.target.value)}
                                                        placeholder='Enter state'
                                                        name='state' 
                                                        id='cvb-serviceAgreement-state-input'
                                                        />
                                                    {renderErrorText("stateOfInst", "State")}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <label htmlFor='zipCode'>Zip Code</label>
                                                    <OutlinedInput
                                                        fullWidth
                                                        value={zipCodeOfInst}
                                                        onChange={e => setZipCodeOfInst(e.target.value)}
                                                        placeholder='Enter zip code'
                                                        name='zipCode' 
                                                        id='cvb-serviceAgreement-zip-input'
                                                        />
                                                    {renderErrorText("zipCodeOfInst", "Zip Code")}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={Classes.signPolicyContainer}>
                            <p>Cohen Veterans Bioscience, Inc.</p>
                            <p id='cvb-serviceAgreement-signPolicy-heading' className={Classes.signPolicyText}>
                                <label>Sign Policy<sup> *</sup></label>
                                </p>
                            <div>
                                <p className={Classes.signPolicySubText}>Sign your name on the trackpad and press any key when finished</p>
                                <div className={Classes.signHereBox} >
                                    <div id='cvb-serviceAgreement-signatureBox-container' className={Classes.clickHeretoBegin}></div>
                                    <SignatureCanvas penColor='black'
                                        ref={sigRef}
                                        canvasProps={{ width: 400, height: 150, className: 'sigCanvas' }} />

                                </div>
                                {
                                    isFormSubmitted && sigRef?.current?.isEmpty() &&
                                    <ErrorText errorMessage="Signature Required" />
                                }
                                <p onClick={clearSignature} className={Classes.clearText}>Clear</p>
                                <div className={Classes.signatureNameInputContainer}>
                                    <label className={Classes.signatoryLabel}>Name</label>
                                    <OutlinedInput
                                        value={fullNameOfUser}
                                        fullWidth
                                        disabled
                                        id='signatoryName'
                                    />
                                </div>
                                <div className={Classes.signatureNameInputContainer}>
                                    <label className={Classes.signatoryLabel}>Title</label>
                                    <OutlinedInput
                                        value={userDetails?.idp == "InCommons" ? get(userDetails, 'institution_details.title', '') : get(userDetails, 'designation', '')}
                                        fullWidth
                                        disabled
                                        id='signatoryTitle'
                                    />
                                </div>
                            </div>

                            {/* <p>Client</p>
                            <div>
                                <p className={Classes.signPolicySubText}>Sign your name on the trackpad and press any key when finished</p>
                                <div className={Classes.signHereBox} >
                                    <p className={Classes.clickHereToBeginText}>Click here to begin</p>
                                </div>
                                <p className={Classes.clearText}>Clear</p>
                                <div>
                                    <label>Name</label>
                                    <OutlinedInput
                                    value='A'
                                    />
                                     <label>Title</label>
                                    <OutlinedInput
                                    value='A'
                                    />
                                </div>
                            </div> */}
                        </div>

                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
export default ServiceAgreement;
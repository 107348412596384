import Deactivate from './Deactivate'
import { connect } from 'react-redux'
import { setTabsValueInEditProfile } from '../../../redux/actions/editProfileActions'
import { updateUserIncognitoStatus } from '../../../redux/actions/userActions'
import { updateProfilePicture } from '../../../redux/actions/globalActions'
import { updateSnackBar } from '../../../redux/actions/globalActions'

const mapStateToProps = state => {
    return {
        proPic: state.global.profilePictureUrl,
        userDetails: state.user.userDetails,
        isUserPrivate: state.user.isUserPrivate

    }
}

const mapDispatchToProps = dispatch => {
    return {
        setTabsValueInEditProfile: async (value) => await dispatch(setTabsValueInEditProfile(value)),
        updateProfilePicture: value => dispatch(updateProfilePicture(value)),
        updateSnackBar: async (message, type) => dispatch(updateSnackBar(message, type)),
        updateUserIncognitoStatus: value => dispatch(updateUserIncognitoStatus(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Deactivate);
import React, { useCallback, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { schema as ATTRIBUTE_DATA } from "../filter-schema";
import { vars } from "../../../../assets/variables";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";

const {
  primaryColor,
  primaryTextColor,
  outlinedInputBorderColor,
  white,
  btnContainedColor,
} = vars;

const styles = {
  popover: {
    background: "#ff00000 !important",
    padding: 8,
    "&  .MuiPopover-paper": {
      overflow: "inherit",
    },
  },
  attributeDropdown: {
    width:  {
      xs: "15vw",
      xl: "20em"
    },
    
    border: `0.0625rem solid ${outlinedInputBorderColor}`,

    backgroundColor: white,
    borderRadius: "0.375rem",

    "& .MuiFormControl-root": {
      marginBottom: "0.75rem",

      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },

      "& .MuiOutlinedInput-root": {
        height: "2.5rem",
        borderRadius: "0.5rem",
        backgroundColor: "rgba(231, 234, 242, 0.2)",
      },
    },

    "& .MuiListItem-root": {
      borderRadius: "0.25rem",
      position: "relative",


      "& .MuiListItemButton-root": {
        padding: "0.625rem 0.875rem",
        borderRadius: "0.25rem",
        "&.Mui-selected": {
          background: primaryColor,
          boxShadow: `0 0.0625rem 0 ${btnContainedColor}`,

          "& > .MuiListItemIcon-root": {
            color: white,
          },

          "& > .MuiListItemText-root > .MuiTypography-root": {
            color: white,
          },

          "& > .MuiBox-root > .MuiSvgIcon-root": {
            color: white,
          },
        },
      },

      "& .subMenu": {
        position: "absolute",
        top: 0,
        left: "calc(100%)",
        display: "none",
      },

      "& .MuiListItemText-root": {
        margin: 0,
      },

      "& .MuiListItemIcon-root": {
        color: primaryTextColor,
      },

      "& .MuiTypography-root": {
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        color: primaryTextColor,
        overflow: "hidden",
        textOverflow: "ellipsis"
      },

      "&:hover": {
        background: `linear-gradient(0deg, rgba(77, 128, 198, 0.1), rgba(77, 128, 198, 0.1)), ${white}`,

        "& > .subMenu": {
          display: "block",
        },

        "& > .MuiListItemButton-root > .MuiListItemText-root > .MuiTypography-root":
          {
            color: primaryColor,
          },
      },
    },
  },
};

function recursiveFilter(attributeData, query) {
  return attributeData.reduce(function filter(acc, curr) {
  
    if (curr.subcategory || curr.props) {
      const subcategory = curr?.subcategory?.reduce(filter, []);
      const props = curr?.props?.reduce(filter, []);
      if (subcategory.length || props.length) {
          return acc.concat({ ...curr, subcategory, props });
      }
    }
    if (curr.title?.toLowerCase().includes(query.toLowerCase())) {
      return acc.concat(curr);
    } else {
      return acc;
    }
  }, []);
}

function AttributeCriteriaOption(props) {

  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (e, attribute) => {
    if (attribute.queryVariable) {
      console.log(attribute, "attribute");
      setSelectedAttribute(attribute);
      props?.setAttribute(attribute);
      handleClose();
    }
  };

  //debounce search term
  const debounceFn = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 800),
    []
  );

  function onSearch(event) {
    setValue(event.target.value);
    debounceFn(event.target.value);
  }

  const [selectedAttribute, setSelectedAttribute] = useState(props?.attribute);

  const filteredAttributeData = useMemo(() => {
    //filter disabled columns by search term
    const filtered = recursiveFilter(ATTRIBUTE_DATA, searchTerm);

    return !searchTerm && ATTRIBUTE_DATA ? ATTRIBUTE_DATA : filtered;
  }, [ATTRIBUTE_DATA, searchTerm]);

  const open = Boolean(anchorEl);
  const id = open ? "attributePopover" : undefined;
  const subcategoryOptions = (subcategory) => {
    const subsub = [
      ...(subcategory.subcategory ?? []),
      ...(subcategory.props ?? []),
    ];
    return (
      <ListItem  disablePadding disableGutters key={subcategory?.title}>
        <ListItemButton
          onClick={(event) => handleListItemClick(event, subcategory)}
          selected={selectedAttribute === subcategory}
        >
          {subcategory?.icon && (
            <ListItemIcon>{subcategory?.icon}</ListItemIcon>
          )}
          <ListItemText primary={subcategory?.title} />
          {subsub?.length > 0 && (
            <Box display="flex" ml="auto">
              <ArrowRightIcon />
            </Box>
          )}
        </ListItemButton>
        {subsub?.length > 0 && (
          <>
            <Box
              key={`listMenu_${subcategory?.title}`}
              sx={styles.attributeDropdown}
              className={`subMenu`}
              padding={1.5}
            >
              <List disablePadding  key={`list_${subcategory?.title}`}>
                {subsub?.map((sub) => {
                  return subcategoryOptions(sub);
                })}
              </List>
            </Box>
          </>
        )}
      </ListItem>
    );
  };
  return (
    <>
      <Button
        disableRipple
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
      >
        {selectedAttribute ? (
          <Tooltip placement="top" title={selectedAttribute.title}>
            <Typography component="span" textTransform="capitalize">
              {selectedAttribute.title}
            </Typography>
          </Tooltip>
        ) : (
          <em>Attribute</em>
        )}
        {id ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>
      <Popover
        elevation={2}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={styles.popover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={styles.attributeDropdown} padding={1.5}>
          <TextField
            fullWidth
            placeholder="Search"
            value={value}
            onChange={(e) => onSearch(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <List disablePadding>
            {filteredAttributeData.map((attribute) => {
              return subcategoryOptions(attribute);
            })}
          </List>
        </Box>
      </Popover>
    </>
  );
}

export default AttributeCriteriaOption;

import APIKeys from './APIKeys'
import { connect } from 'react-redux'
import { updateSnackBar } from '../../../redux/actions/globalActions'
import { setAllWorkspaces } from '../../../redux/actions/workspaceActions'

const mapStateToProps = state => {
    return {
        userDetails: state.user.userDetails,
        allWorkspaces: state.workspace.allWorkspaces
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type)),
        setAllWorkspaces: (value) => dispatch(setAllWorkspaces(value))
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(APIKeys);


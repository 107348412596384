import axios from 'axios'
import get from 'lodash/get'
import { getCognitoIdToken } from './utilityMethods';
import { BASE_URL } from "../constants/google-login-urls";

export const getInvoiceDetails = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/cost/invoice?invoice_id=&invoice_type=&status=&date_of_issue=&billed_to=`;
    const res = await axios.get(url, { headers: header });
    return res?.data?.invoices || [];
}

export const updateInvoiceStatus = async (body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/cost/invoice/payment`;
    const res = await axios.put(url, body, { headers: header });
    return res?.data
}

export const generateInvoiceDetails = async (body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/cost/invoice`;
    const res = await axios.post(url, body, { headers: header });
    return res?.data || {};
}
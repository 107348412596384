/**
 *  External Imports
 */
import Grid from "@mui/material/Grid";

import axios from "axios";

import { arrayOf, func, number, shape, string } from "prop-types";

import React, { useEffect, useState } from "react";

/**
 *  Internal Imports
 */
import { communityMappedAPI } from "../../../api/community-api";
import CustomLoader from "../../commons/custom-loader";
import CommunityEmptyState from "../../controls/community-empty-state";
import CommunityWidgetRecommendedConnectionsItem from "./community-widget-recommended-connections-item";
import { communityAPI, COMMUNITY_CONNECTION_STATUSES } from "../../../api/community-api";
import {
    COMMUNITY_URLS,
    COMMUNITY_CONNECTIONS_PARENT_ROUTE,
} from "../../../constants/urls";

import styles from "./index.module.css";

/**
 *  Variables
 */
const defaultDataSize = 5;

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 */
const CommunityWidgetRecommendedConnections = (props) => {
    // Variables
    const { recommendedUsers } = props;
    const { initUsers, onConnectDialogOpen, onConnectUser, onDeclineUser, onRecommendedPageRedirect } =
        props;

    const [isLoaded, setIsLoaded] = useState(false);

    const initDataCancelTokenSrc = axios.CancelToken.source();

    const connectRecommendedUser = (idRecommendation) => {
        const currentUser = recommendedUsers.find(
            (user) => user.idRecommendation === idRecommendation
        );
        if (!currentUser) {
            return;
        }
        const dialogConnectData = {
            userIcon: currentUser.userIcon,
            organization: currentUser.organization,
            reason: currentUser.reason || "",
            name: currentUser.name,
            id: idRecommendation,
            onConnectUser: onConnectUser,
            connectMethod: communityAPI.postRecommendationByID,
        };
        onConnectDialogOpen(dialogConnectData);
    };

    const declineRecommendedUser = async (idRecommendation) => {
		const requestParams = {
            id: idRecommendation,
            actionStatus: COMMUNITY_CONNECTION_STATUSES.DECLINE,
        };
        const { status } = await communityAPI.postRecommendationByID(requestParams);

        if (status === 200) {
            onDeclineUser(idRecommendation);
        }
	};

    const onRedirectClick = () => {
		onRecommendedPageRedirect(`${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.RECOMMENDED}`);
	};

    const initData = async () => {
        const requestParams = {
            cancelToken: initDataCancelTokenSrc.token,
            dataSize: defaultDataSize,
            isWithMutual: true,
        };

        const { isCanceled, data } =
            await communityMappedAPI.getMappedRecommendations(requestParams);

        if (!isCanceled) {
            initUsers(data);
        }

        setIsLoaded(true);
    };

    // Life Cycles
    useEffect(() => {
        initData();

        return () => initDataCancelTokenSrc.cancel();
    }, []);

    // Return
    return (
        <div className={styles.communityWidgetRecommendedConnections}>
            <Grid container className={styles.title}>
                Recommended connections
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                className={styles.itemsContainer}>
                {isLoaded ? (
                    recommendedUsers && recommendedUsers.length ? (
                        recommendedUsers.map(
                            ({
                                idRecommendation,
                                userIcon,
                                name,
                                mutualDescription,
                            }) => (
                                <CommunityWidgetRecommendedConnectionsItem
                                    key={idRecommendation}
                                    data-testid="userItem"
                                    userIcon={userIcon}
                                    name={name}
                                    idRecommendation={idRecommendation}
                                    mutualDescription={mutualDescription}
                                    connectRecommendedUser={
                                        connectRecommendedUser
                                    }
                                    declineRecommendedUser={
                                        declineRecommendedUser
                                    }
                                />
                            )
                        )
                    ) : (
                        <div className={styles.emptyStateWrap}>
                            <CommunityEmptyState
                                text={"recommended connections"}
                            />
                        </div>
                    )
                ) : (
                    <div className={styles.loaderWrap}>
                        <CustomLoader componentLoader />
                    </div>
                )}
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={styles.buttonsArea}>
                <button 
                    data-testid="showAllBtn"
                    onClick={onRedirectClick}
                    className={styles.showAllBtn}>
                    Show all recommended
                </button>
            </Grid>
        </div>
    );
};

/**
 *  Properties
 */
CommunityWidgetRecommendedConnections.propTypes = {
    recommendedUsers: arrayOf(
        shape({
            idRecommendation: number.isRequired,
            userIcon: string.isRequired,
            name: string.isRequired,
            mutualDescription: string.isRequired,
            organization: string.isRequired,
            reason: string.isRequired,
        })
    ).isRequired,

    initUsers: func.isRequired,
    onConnectDialogOpen: func.isRequired,
    onConnectUser: func.isRequired,
    onDeclineUser: func.isRequired,
    onRecommendedPageRedirect: func.isRequired
};

/**
 *  Exports
 */
export default CommunityWidgetRecommendedConnections;

import React, { useEffect, useState } from 'react'
import classes from './index.module.css';
import map from 'lodash/map'
import UserTypeCard from './UserTypeCards'
import Grid from '@mui/material/Grid'
import cloneDeep from 'lodash/cloneDeep'
const WhoAreYou = props => {
    const { handleValueChange = ()=> { }} = props;
    const { whoAreYou = { }} = props;

    const onCheckboxChange = (index,value) =>{
        const userData = cloneDeep(whoAreYou?.userType);
        map(userData, (data, j)=>{
            if(index === j){
                userData[index] = {
                    ...userData[index],
                    isChecked: true
                }
                handleValueChange(1, 'personaSelected', userData[index]?.selectedPersona);
            }else{
                userData[j] = {
                    ...userData[j],
                    isChecked: false
                }
            }
        })
       
        handleValueChange(1, 'userType', userData);
    }
    return (
        <div className={classes.root}>

            <p className={classes.whoAreYouText}>Who are you?</p>
            <Grid container direction='row' spacing={4}> 
            {map(whoAreYou?.userType, (userData, index)=> {
                return <UserTypeCard userData={userData} index={index} onCheckboxChange={onCheckboxChange}/>
            })}
            </Grid>
        </div>
    )
}

export default WhoAreYou;

import { SET_USER_FIRST_NAME, SET_USER_LAST_NAME, SET_USER_PASSWORD, SET_EMAIL, SET_ORG_NAME, SET_CONFIRM_PASSWORD } from '../action-types'
import { SIGNUP_ERROR_TEXTS } from '../../constants/strings'

const initialState = {
    firstName: { text: '', isValid: false, isSubmit: false, invalidText: SIGNUP_ERROR_TEXTS.FIRSTNAME },
    lastName: { text: '', isValid: false, isSubmit: false, invalidText: SIGNUP_ERROR_TEXTS.LASTNAME },
    email: { text: '', isValid: false, isSubmit: false, invalidText: SIGNUP_ERROR_TEXTS.EMAIL },
    orgName:'',
    confirmPass: { text: '', isValid: false, isSubmit: false, invalidText: SIGNUP_ERROR_TEXTS.PASS },
    password: { text: '', isValid: false, isSubmit: false, invalidText: SIGNUP_ERROR_TEXTS.PASS }
}

const userSignupReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_FIRST_NAME: {
            return {
                ...state,
                firstName: {
                    ...action.payload
                }
            }
        }
        case SET_USER_LAST_NAME: {
            return {
                ...state,
                lastName: {
                    ...action.payload
                }
            }
        }
        case SET_USER_PASSWORD: {
            return {
                ...state,
                password: action.payload
            }
        }
        case SET_EMAIL: {
            return {
                ...state,
                email: {
                    ...action.payload
                }
            }
        }
        case SET_ORG_NAME: {
            return {
                ...state,
                orgName: action.payload
            }
        }
        case SET_CONFIRM_PASSWORD: {
            return {
                ...state,
                confirmPass: {
                    ...action.payload
                }
            }
        }
        default: return state

    }
}

export default userSignupReducer;
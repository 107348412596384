import { schema as ATTRIBUTE_DATA } from './filter-schema';

export function getQueryableAttributes(schema) {
  if(!schema) return [];
  return schema.flatMap((s) =>
    s.props.concat(getQueryableAttributes(s.subcategory))
  );
}

export const ALL_ATTRIBUTES = [
  ...getQueryableAttributes(ATTRIBUTE_DATA),
  {
    title: 'Subjects',
    queryVariable: 'subjects',
    type: 'slider',
  },
];

export const ATTRIBUTES_MAP = ALL_ATTRIBUTES.filter(
  (a) => a.queryVariable
).reduce((acc, curr) => {
  acc[curr.queryVariable] = curr;
  return acc;
}, {});
console.log(ATTRIBUTES_MAP);

export function arrayContainsAll(needle, haystack) {
  return needle.some((n) => haystack.includes(n));
}

export function isInRange(rowRange, filterRange) {
  // A row is excluded is the filter falls out of the range
  if (rowRange.min > filterRange.max || filterRange.min > rowRange.max) {
    return false;
  }

  return true;
}

export function getCohortsByFilter(cohorts, filter, filterKey) {
  const enabledFilterValues = Object.fromEntries(
    Object.entries(filter || {}).filter(([key, value]) => !!value)
  );

  return Array.isArray(cohorts) && filter
    ? cohorts.filter((cohort) =>
        Object.keys(enabledFilterValues).includes(cohort[filterKey])
      )
    : cohorts;
}

export function getFilteredData(filters, cohorts, name) {
  const defaultName = name ?? 'cohort_name';
  const filterCohortNames = Object.fromEntries(
    Object.entries(filters).filter(([key]) => key !== defaultName)
  );
  const cohortNames = Object.fromEntries(
    Object.entries(filters).filter(([key]) => key === defaultName)
  )?.[defaultName];
  const enabledCohortNames = Object.fromEntries(
    Object.entries(cohortNames || {}).filter(([key, value]) => !!value)
  );

  const filterCohorts =
    Array.isArray(cohorts) && filters
      ? cohorts.filter((obj) => {
          let allValues = {};
          Object.entries(filterCohortNames).forEach(
            ([key, filterValue], index) => {
              let histogram = undefined;
              try {
                histogram = obj.data?.[key]?.histogram;             

                if (!histogram?.length) {
                  allValues = { ...allValues, [index]: false };
                } else {
                  const keyFilters = obj.data?.[key].histogram
                  .filter((obj) => obj.key)
                  .map((obj) => obj.key);
                  const rangeValues = obj.data?.[key].histogram.filter(
                    (obj) => !obj.key
                  );
                  const enabled = Object.fromEntries(
                    Object.entries(filterValue).filter(([key, value]) => !!value)
                  );
                const filterValues = Object.keys(enabled);
                  if (
                    Array.isArray(histogram) &&
                    histogram[0].key !== undefined
                  ) {
                    const result = arrayContainsAll(keyFilters, filterValues);

                    allValues = { ...allValues, [index]: result };
                  } else {
                    const result = isInRange(rangeValues[0], filterValue.range);

                    allValues = { ...allValues, [index]: result };
                  }
                }
              } catch (e) {
                console.error(e);
              }
            }
          );

          const allPositiveValues = Object.values(allValues).filter(
            (value) => !!value
          );

          return (
            Object.keys(enabledCohortNames).includes(obj[defaultName]) &&
            allPositiveValues.length === Object.keys(filterCohortNames).length
          );
        })
      : cohorts;

  return filterCohorts;
}

export function getMinMax(prev, cur) {
  let min = prev?.min
  let max = prev?.max

  if (cur?.min != null) {
    min = Math.min(prev?.min ?? cur?.min, cur?.min)
  }
  if (cur?.max != null) {
    max = Math.max(prev?.max ?? cur?.max, cur?.max)
  }

  // get minimum and maximum array
  const obj = { min, max, range: { min, max } }; //enabled: true };
  return obj;
}

export async function getInitialFilters(cohorts, name) {
  const defaultName = name ?? 'cohort_name';
  const newCohorts = [...cohorts];
  let obj = {};
  const attribute_name = newCohorts?.reduce(
    (prev, cur) => ({ ...prev, [cur[defaultName]]: true }),
    {}
  );

  newCohorts.forEach((cohort) => {
    const { data } = cohort;

    for (const key in data) {
      const prevData = obj?.[key] ? obj?.[key.toString()] : {};
      const newHistogram = Array.isArray(cohort?.data?.[key]?.histogram)
        ? cohort?.data?.[key]?.histogram
        : [];

      const newObj = newHistogram?.reduce((prev, cur) => {
        if (cur.min !== undefined) {
          return { min: cur.min, max: cur.max };
        }

        return { ...prev, [cur.key]: true };
      }, {});

      //remove duplicates from histogram values
      const filter =
        Array.isArray(newHistogram) &&
        newHistogram.length > 0 &&
        newHistogram?.[0].key
          ? { ...prevData, ...newObj }
          : newHistogram.length > 0
            ? getMinMax(prevData, newObj)
          : { ...prevData };

      if (key !== '__typename') {
        obj = {
          ...obj,
          [key]: {
            ...filter,
          },
        };
      }
    }

    // add cohort name to filters object
    obj = {
      ...obj,
      [defaultName]: { ...attribute_name },
    };
  });

  return obj;
  // return  obj;
}

export function getDefaultSliderValue(filters) {
  if (filters && filters?.min !== undefined) {
    return [filters?.range?.min ?? 20, filters?.range.max ?? 60];
  } else {
    [40, 80];
  }
}

export function resetObj(obj, value) {
  return Object.keys(obj).reduce(
    (attrs, key) => ({
      ...attrs,
      [key]: value ?? true,
    }),
    {}
  );
}

/**
 *
 * @param {*string} text
 * @param {*number} threshold
 * @returns
 */
export function trimText(text, threshold) {
  if (text.length <= threshold) return text;
  return text.substr(0, threshold).concat('...');
}


export const formatLargeNumbers = (value) => {
  if (Math.abs(value) >= 1000000000) {
    if (value % 1000000000 === 0) {
      return (value / 1000000000) + 'B';
    } else {
      return (value / 1000000000).toFixed(2) + 'B';
    }
  }
  if (Math.abs(value) >= 1000000) {
    if (value % 1000000 === 0) {
      return (value / 1000000) + 'M';
    } else {
      return (value / 1000000).toFixed(2) + 'M';
    }
  }
  return value;
};

export const ceilFilterValues = (value) => {
  return Math.ceil(parseFloat(value) * 100) / 100;
};

export const floorFilterValues = (value) => {
  return Math.floor(parseFloat(value) * 100) / 100;
};
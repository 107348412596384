import { SET_DIF_DATA_SHARING_CONTRIBUTE_NEW_DATA_SET, SET_DIF_DATA_SHARING_DATASET_NAME, 
    SET_DIF_DATA_SHARING_PERMISSION_TO_SHARE, SET_DIF_DATA_SHARING_IRB_NUMBER, 
    SET_DIF_DATA_SHARING_DOCUMENT_FILE, SET_DIF_DATA_SHARING_IS_PROTECTED_DATA,
    SET_DIF_DATA_SHARING_DE_IDENTIFY, SET_DATA_SHARING_FORM_DATA, RESET_DATA_SHARING_FORM_DATA,
    SET_DIF_DATA_SHARING_ASSOCIATED_EMAIL
} from '../action-types'
import get from 'lodash/get'
const initialState = {
   difDataSharingIsNewDataSet: '',
   difDataSharingDatasetName: '',
   difDataSharingPermissionToShare: '',
   difDataSharingIRBNumber: '',
   difDataSharingDocumentFile: '',
   difDataSharingIsProtectedData: '',
   difDataSharingIsDeIdentity: '',
   difDataSharingConsentDocument: '',
   difDataSharingAssociatedEmail: ''
}

const difDataSharingReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_DIF_DATA_SHARING_CONTRIBUTE_NEW_DATA_SET: {
            return {
                ...state,
                difDataSharingIsNewDataSet: action.payload
            }
        }
        case SET_DIF_DATA_SHARING_DATASET_NAME: {
            return {
                ...state,
                difDataSharingDatasetName: action.payload
            }
        }
        case SET_DIF_DATA_SHARING_PERMISSION_TO_SHARE: {
            return {
                ...state,
                difDataSharingPermissionToShare: action.payload
            }
        }
        case SET_DIF_DATA_SHARING_IRB_NUMBER: {
            return {
                ...state,
                difDataSharingIRBNumber: action.payload
            }
        }
        case SET_DIF_DATA_SHARING_DOCUMENT_FILE: {
            return {
                ...state,
                difDataSharingDocumentFile: action.payload
            }
        }
        case SET_DIF_DATA_SHARING_IS_PROTECTED_DATA: {
            return {
                ...state,
                difDataSharingIsProtectedData: action.payload
            }
        }
        case SET_DIF_DATA_SHARING_DE_IDENTIFY: {
            return {
                ...state,
                difDataSharingIsDeIdentity: action.payload
            }
        }
        case SET_DIF_DATA_SHARING_ASSOCIATED_EMAIL: {
            return {
                ...state,
                difDataSharingAssociatedEmail: action.payload
            }
        }
        case SET_DATA_SHARING_FORM_DATA: {
            const formData = action.payload;
            return {
                ...state,
                difDataSharingIsNewDataSet: get(formData, 'contributing_new_datset', ''),
                difDataSharingDatasetName: get(formData, 'dataset_name', ''),
                difDataSharingPermissionToShare: get(formData, 'does_institution_have_permission', ''),
                difDataSharingIRBNumber: get(formData, 'irb_number', ''),
                difDataSharingIsProtectedData: get(formData, 'contains_phi_pii', ''),
                difDataSharingIsDeIdentity: get(formData, 'will_you_remove_phi_pii_prior', ''),
                difDataSharingConsentDocument: get(formData, 'consent_document', ''),
            }
        }
        case RESET_DATA_SHARING_FORM_DATA: {
            return{
                difDataSharingIsNewDataSet: '',
                difDataSharingDatasetName: '',
                difDataSharingPermissionToShare: '',
                difDataSharingIRBNumber: '',
                difDataSharingDocumentFile: '',
                difDataSharingIsProtectedData: '',
                difDataSharingIsDeIdentity: '',
                difDataSharingConsentDocument: '',
                difDataSharingAssociatedEmail: ''
            }
        }
        default: return state
    }
}

export default difDataSharingReducers;
import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Classes from './index.module.css'
import OutlinedInput from '@mui/material/OutlinedInput';
import SuccessTickDisabled from '../../../assets/images/successtick.png'
import SuccessTick from '../../../assets/images/successtickenabled.png'
import { navigate } from '@reach/router';
import { passwordStrengthValidation } from '../../../utils/input-validations';
import ErrorText from '../../controls/error-text';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import PreviewOn from '../../../assets/images/PreviewOn.png';
import PreviewOff from '../../../assets/images/PreviewOff.png';
import BackButtonArrow from '../../../assets/icons/left_arrow.png'
import InfoText from '../../controls/info-text'
import { Auth } from 'aws-amplify'
import isEmpty from "lodash/isEmpty"

const ChangePassword = props => {
    const [passStrengthCheck, setPassStrengthCheck] = useState({});
    const [currentPassword, setCurrentPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const [authError, setAuthError] = useState("");
    const [isFormUpdating, setIsFormIsUpdating] = useState(false);

    useEffect(() => {
        const passStrength = passwordStrengthValidation(confirmPassword);
        setPassStrengthCheck(passStrength);
    }, [confirmPassword]);
    const onCurrentPasswordChange = e => {
        setCurrentPassword(e.target.value);
        setAuthError("")
    }
    const onConfirmPasswordChange = e => {
        setConfirmPassword(e.target.value);
        setAuthError("")
    }
    const onRetypePasswordChange = e => {
        setRetypePassword(e.target.value);
        setAuthError("")
    }

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPass(!showConfirmPass);
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };


    const handleChangePassword = async () => {
        try {

            const passwordType = isEmpty(currentPassword) ? "Current" : isEmpty(confirmPassword) ? "New" : isEmpty(retypePassword) ? "Confirm" : ""
            if(passwordType){
                setAuthError(`${passwordType} Password cannot be empty!`)
                return
            }
            let isValidPass = false;
            for (let key in passStrengthCheck) {
                if (passStrengthCheck[key]) {
                    isValidPass = true;
                } else {
                    isValidPass = false;
                    break;
                }
            }
            if(!isValidPass){
                setAuthError(`Please enter a valid password`)
                return
            }
            if (confirmPassword === retypePassword) {
                setIsFormIsUpdating(true);
                const user = await Auth.currentAuthenticatedUser();
                const response = await Auth.changePassword(user, currentPassword, confirmPassword);
                props.updateSnackBar("Updated password successfully", "Success");
                navigate('/my-profile')
            }
            else {
                setAuthError("New passwords are not matching!")
            }
        }
        catch (error) {
            setAuthError("Current password is wrong")
        }finally{
            setIsFormIsUpdating(false);
        }
    }

    return (
        <Grid container justifyContent="center" alignItems="center" className={Classes.container} >
            <Grid item xs={5}>
                <Grid container direction='column' spacing={2} className={Classes.changePasswordMainContainer}>
                    <p id='cvb-changePassword-backButton' onClick={() => navigate('/my-profile')} className={Classes.backButton}>
                        <span><img alt='back button arrow' src={BackButtonArrow} /></span>
                        <p className={Classes.backButtonText}>Back</p>
                    </p>
                    <p id='cvb-changePassword-pageTitle' className={Classes.changePassTitle}>Change Password</p>
                    <Grid item xs={12}>
                        {authError && <InfoText errorMessage={authError} type="Error" />}

                        <label htmlFor='currentPassword'>Current Password</label>
                        <OutlinedInput
                            fullWidth
                            value={currentPassword}
                            onChange={onCurrentPasswordChange}
                            placeholder='Enter password'
                            name='currentPassword'
                            type='password'
                            id='cvb-changePassword-currentPasswordInput'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <label htmlFor='confirmPassword'>New Password</label>
                        <OutlinedInput
                            fullWidth
                            value={confirmPassword}
                            onChange={onConfirmPasswordChange}
                            placeholder='Enter password'
                            type={showConfirmPass ? 'text' : 'password'}
                            name='confirmPassword'
                            id='cvb-changePassword-newPasswordInput'
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        id='cvb-changePassword-showNewPasswordIcon'
                                        size="large">
                                        {showConfirmPass ? <img id='cvb-changePassword-previewOnIcon' alt='visibility On' src={PreviewOn} /> : <img id='cvb-changePassword-previewOffIcon' alt='visibility Off' src={PreviewOff} />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <Grid container className={Classes.passChecksDiv}>
                            <Grid item xs={5} className={Classes.passStrengthCheck}>
                                {passStrengthCheck['upperCaseChar']
                                    ? <img id='cvb-changePassword-upperCaseChar-successTick' className={Classes.checkMarkIcons} src={SuccessTick} alt='success mark' />
                                    : <img id='cvb-changePassword-upperCaseChar-failureTick' className={Classes.checkMarkIcons} src={SuccessTickDisabled} alt='failure mark' />}
                                <p id='cvb-changePassword-upperCaseCharText' className={Classes.passStrengthOptText}>One upper case character</p>
                            </Grid>
                            <Grid item xs={4} className={Classes.passStrengthCheck}>
                                {passStrengthCheck['specialChar']
                                    ? <img id='cvb-changePassword-oneSpecialChar-successTick' className={Classes.checkMarkIcons} src={SuccessTick} alt='success mark' />
                                    : <img id='cvb-changePassword-oneSpecialChar-failureTick' className={Classes.checkMarkIcons} src={SuccessTickDisabled} alt='failure mark' />}
                                <p id='cvb-changePassword-oneSpecialCharText' className={Classes.passStrengthOptText}>One special character</p>
                            </Grid>
                            <Grid item xs={3} className={Classes.passStrengthCheck}>
                                {passStrengthCheck['numberChar']
                                    ? <img id='cvb-changePassword-oneNumber-successTick' className={Classes.checkMarkIcons} src={SuccessTick} alt='success mark' />
                                    : <img id='cvb-changePassword-oneNumber-failureTick' className={Classes.checkMarkIcons} src={SuccessTickDisabled} alt='failure mark' />}
                                <p id='cvb-changePassword-oneNumberText' className={Classes.passStrengthOptText}>One number</p>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={5} className={Classes.passStrengthCheck}>
                                        {passStrengthCheck['lowerCaseChar']
                                            ? <img id='cvb-changePassword-oneLowerCaseChar-successTick' className={Classes.checkMarkIcons} src={SuccessTick} alt='success mark' />
                                            : <img id='cvb-changePassword-oneLowerCaseChar-failureTick' className={Classes.checkMarkIcons} src={SuccessTickDisabled} alt='failure mark' />}
                                        <p id='cvb-changePassword-oneLowerCaseCharText' className={Classes.passStrengthOptText}>One lower case character</p>
                                    </Grid>
                                    <Grid item xs={5} className={Classes.passStrengthCheck}>
                                        {passStrengthCheck['minChar']
                                            ? <img id='cvb-changePassword-min8Char-successTick' className={Classes.checkMarkIcons} src={SuccessTick} alt='success mark' />
                                            : <img id='cvb-changePassword-min8Char-failureTick' className={Classes.checkMarkIcons} src={SuccessTickDisabled} alt='failure mark' />}
                                        <p id='cvb-changePassword-min8CharText' className={Classes.passStrengthOptText}>Minimum 8 characters</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <label htmlFor='retypePassword'>Confirm Password</label>
                        <OutlinedInput
                            fullWidth
                            value={retypePassword}
                            onChange={onRetypePasswordChange}
                            onPaste={(e)=>e.preventDefault()}
                            placeholder='Re-type Password'
                            name='retypePassword'
                            type='password'
                            id='cvb-changePassword-confirmPasswordInput'
                        />

                    </Grid>
                    <div className={Classes.buttonContainer}>
                        <button className={`no-outlined-button medium-size-button `} id='cvb-changePassword-cancelButton' onClick={() => navigate('/my-profile')}>Cancel</button>
                        <button disabled={isFormUpdating} className={`large-size-button solid-button`} id='cvb-changePassword-updatePasswordButton' onClick={handleChangePassword}>Update Password</button>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ChangePassword
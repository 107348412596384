import Root from './Root';
import { updateUserLogin, updateUserDetails, updateUserIncognitoStatus } from '../redux/actions/userActions'
import { connect } from 'react-redux'
import { setBannerCookie } from '../redux/actions/homeActions';


const mapStateToProps = state => ({
    userDetails: state.user.userDetails,
    isUserLoggedIn: state.user.isUserLoggedIn,
    showCookieBanner: state.home.showCookieBanner
})

const mapDispatchToProps = dispatch => ({
    updateUserLogin: value => dispatch(updateUserLogin(value)),
    updateUserDetails: value => dispatch(updateUserDetails(value)),
    updateUserIncognitoStatus: value => dispatch(updateUserIncognitoStatus(value)),
    setBannerCookie: async (value) => await dispatch(setBannerCookie(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Root);
import { Skeleton, Stack } from '@mui/material';
import React from 'react';
import { Card, IconContainer } from '../../primitives/Statistics';


export const Overview = ({ statsData }) => {
  return (
    <Stack pl={3} spacing={1} flexShrink={0}>
      { !statsData
        ? Array(3)
            .fill('*')
            .map((_, i) => <Skeleton key={i} width={158} sx={{flex: 1}} />)
        : !!statsData && Array.isArray(Object.keys(statsData)) 
        ? Object.entries(statsData).map(([key, stats]) => {
            return (
              <Card
                {...{ key }}
                title={stats.count.toLocaleString()}
                description={stats.name}
                leftNode={<IconContainer>{stats.icon}</IconContainer>}
              />
            );
          })
        : null}
    </Stack>
  );
};

import { SET_ZONE3_USERS_FIRST_NAME, SET_ZONE3_USERS_MIDDLE_NAME, SET_ZONE3_USERS_LAST_NAME
    , SET_ZONE3_USERS_AFFILIATION, SET_ZONE3_USERS_INSTITUTIONAL_EMAIL
, ADD_ZONE3_USER_DETAILS, REMOVE_ZONE3_USER, SET_ZONE_3_FORM_FIELDS
, RESET_ZONE_3_FORM_FIELDS
} from '../action-types'

export const updateZone3UsersFirstName = (value, id) => {
    return {
        type: SET_ZONE3_USERS_FIRST_NAME,
        payload: {
            value,
            id
        }
    }
}
export const updateZone3UsersMiddleName = (value, id) => {
    return {
        type: SET_ZONE3_USERS_MIDDLE_NAME,
        payload: {
            value,
            id
        }
    }
}
export const updateZone3UsersLastName = (value, id) => {
    return {
        type: SET_ZONE3_USERS_LAST_NAME,
        payload: {
            value,
            id
        }
    }
}
export const updateZone3UsersAffiliation = (value, id) => {
    return {
        type: SET_ZONE3_USERS_AFFILIATION,
        payload: {
            value,
            id
        }
    }
}
export const updateZone3UsersInstitutionalEmail = (value, id) => {
    return {
        type: SET_ZONE3_USERS_INSTITUTIONAL_EMAIL,
        payload: {
            value,
            id
        }
    }
}
export const addZone3Users = (value) => {
    return {
        type: ADD_ZONE3_USER_DETAILS,
        payload: value
    }
}
export const removeZone3User = (value) => {
    return {
        type: REMOVE_ZONE3_USER,
        payload: value
    }
}
export const setZone3UsersFields = (value) => {
    return {
        type: SET_ZONE_3_FORM_FIELDS,
        payload: value
    }
}
export const resetZone3UserForm = (value) => {
    return {
        type: RESET_ZONE_3_FORM_FIELDS,
    }
}

import { getCohortsForUserApi, getAnalysisWithCohortID } from '../../api/analysis-api'
import { UPDATE_USER_COHORTS, UPDATE_ANALYSIS_WITH_ID, UPDATE_LAST_EVALUATED_KEY, UPDATE_SELECTED_WORKSPACE_FILE_DATA } from '../action-types'

export const getUserCohorts = (limit = 2, exclusive_start_key = {}) => async dispatch => {
    try {
        const response = await getCohortsForUserApi(limit, exclusive_start_key);
        dispatch(updateUserCohorts(response?.cohorts || []));
        dispatch(updateLastEvaluatedKey(response?.last_evaluated_key || {}))
    }
    catch (error) {
        dispatch(updateUserCohorts([]));
        dispatch(updateLastEvaluatedKey({}))
        throw error;
    }
}

export const getAnalysisWithCohortId = id => async dispatch => {
    try {
        const response = await getAnalysisWithCohortID(id);
        if (response?.query) {
            const parsedQuery = JSON.parse(response.query)
            if (parsedQuery?.analysis) {
                response.analysis = parsedQuery.analysis;
            }
        }
        dispatch(updateAnalysisWithCohortId(response))
    }
    catch (error) {
        dispatch(updateAnalysisWithCohortId({}))
    }
}
export const refreshUserCohorts = () => async (dispatch) => {
    await getUserCohorts();
    await dispatch(updateLastEvaluatedKey({}))
}

export const resetMyStuffStore = () => dispatch => {
    dispatch(updateUserCohorts([]));
    dispatch(updateLastEvaluatedKey({}))
    dispatch(updateAnalysisWithCohortId({}))
}

const updateUserCohorts = value => ({ type: UPDATE_USER_COHORTS, payload: value });
const updateAnalysisWithCohortId = value => ({ type: UPDATE_ANALYSIS_WITH_ID, payload: value });
const updateLastEvaluatedKey = value => ({ type: UPDATE_LAST_EVALUATED_KEY, payload: value })
export const updateSelectedWorkspaceFileData = value => ({ type: UPDATE_SELECTED_WORKSPACE_FILE_DATA, payload: value })


/****************************************
 *  External Imports
 ****************************************/
import cloneDeep from "lodash/cloneDeep";

/****************************************
 *  Internal Imports
 ****************************************/
import {COMMUNITY_LEFT_MENU_ITEMS} from "../../constants/menu/community";

import { getValidChatDate } from "../../utils/date-formatter";

import {RES_COMMUNITY_GLOBAL_SEARCH_STATUSES} from "../../api/community-api";

import {
	COMMUNITY_CONNECTIONS_USERS_INIT,
	COMMUNITY_GLOBAL_SEARCH_USER_CONNECT,
	COMMUNITY_GLOBAL_SEARCH_USERS_INIT,
	COMMUNITY_INCOMING_USER_ACCEPT,
	COMMUNITY_INCOMING_USER_DECLINE,
	COMMUNITY_INCOMING_USERS_INIT,
	COMMUNITY_LEFT_MENU_ACTIVE_ITEM_CHANGE,
	COMMUNITY_LEFT_MENU_ITEM_CLICK,
	COMMUNITY_LEFT_MENU_ITEM_COUNTERS_CHANGE,
	COMMUNITY_LEFT_MENU_ITEM_TOGGLE,
	COMMUNITY_MESSAGES_CHAT_MSG_ADD_RECIPIENT,
	COMMUNITY_MESSAGES_CHAT_MSG_ADD_SENDER,
	COMMUNITY_MESSAGES_CHAT_MSG_INIT,
	COMMUNITY_MESSAGES_CHAT_MSG_RESET,
	COMMUNITY_MESSAGES_CHAT_RECIPIENT_ID_INIT,
	COMMUNITY_MESSAGES_CHAT_USERS_LIST_INIT,
	COMMUNITY_MESSAGES_CHAT_WS_CLOSE,
	COMMUNITY_MESSAGES_CHAT_WS_INIT,
	COMMUNITY_PENDING_USERS_INIT,
	COMMUNITY_RECOMMENDED_USER_CONNECT,
	COMMUNITY_RECOMMENDED_USER_DECLINE,
	COMMUNITY_RECOMMENDED_USERS_INIT,
	COMMUNITY_CONNECTIONS_USERS_LOAD_MORE,
	COMMUNITY_PENDING_USERS_LOAD_MORE,
	COMMUNITY_RECOMMENDED_USERS_LOAD_MORE,
	COMMUNITY_INCOMING_USERS_LOAD_MORE,
	COMMUNITY_CONNECT_MODAL_OPEN,
	COMMUNITY_CONNECT_MODAL_CLOSE,
	COMMUNITY_CONNECT_MODAL_RESET,
	COMMUNITY_SUCCESS_MODAL_OPEN,
	COMMUNITY_SUCCESS_MODAL_CLOSE,
	COMMUNITY_SUCCESS_MODAL_RESET,
	COMMUNITY_GLOBAL_SEARCH_MOLECULAR_CLASSES_INIT,
	COMMUNITY_GLOBAL_SEARCH_THERAPEUTIC_AREAS_INIT,
	COMMUNITY_GLOBAL_SEARCH_POPOVER_TOGGLE,
	COMMUNITY_REDIRECT,
	COMMUNITY_REDIRECT_RESET,
	COMMUNITY_GLOBAL_SEARCH_QUERY_INIT,
	COMMUNITY_FULLSCREEN_TOGGLE,
	COMMUNITY_DELETE_MODAL_OPEN,
	COMMUNITY_DELETE_MODAL_CLOSE,
	COMMUNITY_DELETE_MODAL_RESET,
	COMMUNITY_CONNECTIONS_USERS_DELETE,
	COMMUNITY_CONSTELLATION_LABELS_INIT,
	COMMUNITY_CONSTELLATION_LABELS_SELECT,
	COMMUNITY_CONSTELLATION_LABELS_SELECT_CLEAR,
	COMMUNITY_CONSTELLATION_RECOMMENDED_USERS_SET,
	COMMUNITY_CONSTELLATION_CONNECTED_USERS_SET,
	COMMUNITY_CONSTELLATION_RECOMMENDED_USER_CONNECT,
	COMMUNITY_CONSTELLATION_RECOMMENDED_USER_DECLINE,
	COMMUNITY_CONSTELLATION_TOOLTIP_SET,
	COMMUNITY_CONSTELLATION_TOOLTIP_OPEN,
	COMMUNITY_CONSTELLATION_INCOMING_USER_ACCEPT,
	COMMUNITY_CONSTELLATION_INCOMING_USER_DECLINE,
	COMMUNITY_CONSTELLATION_LABEL_NODE_HOVER,
	COMMUNITY_CONSTELLATION_LABEL_NODE_UPDATE,
	COMMUNITY_CONSTELLATION_LABEL_NODE_RESET,
	COMMUNITY_CONSTELLATION_GRAPH_READY,
	COMMUNITY_CONSTELLATION_CONNECTED_USER_DELETE,
	COMMUNITY_CONSTELLATION_NODE_PARAMS_SET,
	COMMUNITY_CONSTELLATION_NODE_PARAMS_RESET
} from "../action-types";
import {FILE_FORMATS} from "../../constants/strings";

/****************************************
 *  Variables
 ****************************************/
const defaultConnectDialogStateOptions = {    
	id: 0,
	userIcon: '',
	name: '',
	organization: '',
	reason: '',
	onConnectUser: () => {},
	connectMethod: () => {},
	onDialogClose: () => {},
};

const defaultSuccessDialogStateOptions = {
    id: 0,
    userIcon: '',
    name: '',
    email: '',

    onDialogClose: () => {},
	connectMethod: () => {},
    onSuccessEvent: () => {},
};

const defaultDeleteDialogStateOptions = {    
	id: 0,
	userIcon: '',
	name: '',
	organization: '',
	onDeleteUser: () => {},
	deleteMethod: () => {},
};

const defaultConstellationTooltipParams = {
	x: 0,
	y: 0,
	connectionId: -1,
	recepientId: -1,
	userId: -1,
	userIcon: '',
	userName: '',
	userOrganization: '',
	userConnections: [],
	userStatus: '',
	nodeSize: 0,
	userWeight: 0,
	userType: '',
	userEmail: '',
	isIncomingConnection: false,
	isOpposite: false
};

const initialState = {
	leftMenuItems: cloneDeep(COMMUNITY_LEFT_MENU_ITEMS),
	
	recommendedUsers: [],
	connectionUsers: [],
	incomingUsers: [],
	pendingUsers: [],
	
	chatWS: {},
	chatUsersList: [],
	chatUserListBubbles: new Map(),
	chatUserListLastMessages: new Map(),
	chatRecipientID: null,
	chatActiveMessages: [],
	
	globalSearchUsers: [],
	therapeuticAreas: [],
	molecularClasses: [],
	globalSearchPopoverOpen: false,
	globalSearchQuery: "",

	showCommunityConnectModal: false,
	dialogConnectData: defaultConnectDialogStateOptions,

	showCommunitySuccessModal: false,
	dialogSuccessData: defaultSuccessDialogStateOptions,

	showCommunityDeleteModal: false,
	dialogDeleteData: defaultDeleteDialogStateOptions,

	navigateTo: '',
	navigationState: null,
	fullscreenView: true,

	constellationLabels: [],
	constellationLabelsSelected: new Set(),

	constellationRecommendedUsers: [],
	constellationConnectedUsers: [],

	constellationTooltipOpen: false,
	constellationTooltipParams: defaultConstellationTooltipParams,

	graphLabelsMask: new Map(),
	graphLabelsMaskHovered: null,
	nodesParams: [],

	constellationGraphReady: false
};

/****************************************
 *  Functions
 ****************************************/

/**
 *  Left Menu Items Actions
 *
 *  @TODO Make a refactoring of the code after requirements stabilization
 */
/**
 *  @function isLeftMenuGroupItem
 *
 *  @param {Array<object>} leftMenuItems Set items for left menu
 *  @param {string} uniqKey Set a unique item key
 *
 *  @return {boolean}
 */
function isLeftMenuGroupItem(leftMenuItems, uniqKey) {
	for (let i = 0, currMenuItem; i < leftMenuItems.length; i++) {
		currMenuItem = leftMenuItems[i];
		
		if (currMenuItem.uniqKey === uniqKey && currMenuItem.isGroup) {
			return true;
		}
	}
	
	return false;
}

/**
 *  @function resetLeftMenuActiveItemState
 *
 *  @param {Array<object>} leftMenuItems Set items for left menu
 */
function resetLeftMenuActiveItemState(leftMenuItems) {
	for (let i = 0, currMenuItem; i < leftMenuItems.length; i++) {
		currMenuItem = leftMenuItems[i];
		
		leftMenuItems[i].isActive = false;
		
		for (let j = 0; j < currMenuItem.children.length; j++) {
			currMenuItem.children[j].isActive = false;
		}
	}
}

/**
 *  @function updateLeftMenuState
 *
 *  @param {Array<object>} leftMenuItems Set items for left menu
 *  @param {string} uniqKey Set a unique item key
 *  @param {function?} [fn=((targetMenuItem: object): void => {})] Add a function for additional action
 */
function updateLeftMenuState(leftMenuItems, uniqKey, fn = ((targetMenuItem) => {
})) {
	for (let i = 0, currMenuItem; i < leftMenuItems.length; i++) {
		currMenuItem = leftMenuItems[i];

		if (!uniqKey) {
			leftMenuItems[i].isOpenSubMenu = false
		}
		
		// Checking that a current item is a group
		if (currMenuItem.uniqKey === uniqKey && currMenuItem.isGroup) {
			leftMenuItems[i].isOpenSubMenu = !currMenuItem.isOpenSubMenu;
			
			break;
		}
		
		// Checking that a current item is a target item
		if (currMenuItem.uniqKey === uniqKey) {
			leftMenuItems[i].isActive = true;

			fn(currMenuItem);
			
			break;
		}
		
		// Checking of the children
		for (let j = 0, currSubMenuItem; j < currMenuItem.children.length; j++) {
			currSubMenuItem = currMenuItem.children[j];
			
			if (currSubMenuItem.uniqKey === uniqKey) {
				leftMenuItems[i].isOpenSubMenu = true;
				
				currMenuItem.children[j].isActive = true;
				
				fn(currSubMenuItem);
				
				break;
			}
		}
	}
}


/**
 *  @function updateLeftMenuCountersState
 *
 *  @param {Array<object>} leftMenuItems Set items for left menu
 *  @param {RES_COMMUNITY_COUNTER_KEYS} counters Set counters object from the Community Counters API
 */
function updateLeftMenuCountersState(leftMenuItems, counters) {
	for (let i = 0, currMenuItem; i < leftMenuItems.length; i++) {
		currMenuItem = leftMenuItems[i];
		
		leftMenuItems[i].counter = counters[currMenuItem.counterKey] ?? 0;
		
		for (let j = 0, currMenuChildItem; j < currMenuItem.children.length; j++) {
			currMenuChildItem = currMenuItem.children[j];
			
			currMenuItem.children[j].counter = counters[currMenuChildItem.counterKey] ?? 0;
		}
	}
}

/**
 *  @function toggleLeftSubMenu
 *
 *  @param {Array<object>} leftMenuItems Set items for left menu
 *  @param {string} uniqKey Set a unique item key
 */
function toggleLeftSubMenu(leftMenuItems, uniqKey) {
	for (let i = 0, currMenuItem; i < leftMenuItems.length; i++) {
		currMenuItem = leftMenuItems[i];
		
		if (currMenuItem.uniqKey === uniqKey && currMenuItem.isGroup) {
			leftMenuItems[i].isOpenSubMenu = !currMenuItem.isOpenSubMenu;
			
			break;
		}
	}
}

/**
 *  @function setPendingStatusGlobalSearchUsers
 *
 *  @param {Array} globalSearchUsers 
 *  @param {number} userID 
 */
function setPendingStatusGlobalSearchUsers(globalSearchUsers, userID) {
	return globalSearchUsers.map((user) => { 
		if (user.id === userID) {
			user.status = RES_COMMUNITY_GLOBAL_SEARCH_STATUSES.PENDING
		}
		return user;
	})
}

/**
 *  @function getUpdatedChatUsersList
 *
 *  @param {Array} usersList 
 *  @param {number} id 
 *  @param {string} message 
 *  @param {number} timestamp 
 */
function getUpdatedChatUsersList(usersList, id, message, timestamp) {
    return usersList && usersList.length !== 0
        ? usersList.reduce((acc, user) => {
              acc.push(
                  user.userID === id
                      ? {
                            ...user,
                            lastMessage: message,
                            time: getValidChatDate(
                                timestamp / 1000
                            ),
                            timestamp: timestamp,
                        }
                      : user
              );
              return acc;
          }, [])
        : [];
}

/****************************************
 *  Reducer
 ****************************************/
const communityReducer = (state = initialState, action) => {
	switch (action.type) {
		/**
		 *  Left Menu Items Actions
		 */
		case COMMUNITY_LEFT_MENU_ACTIVE_ITEM_CHANGE: {
			const {leftMenuItems} = state;
			const {uniqKey} = action.payload;
			
			const rawLeftMenuItems = cloneDeep(leftMenuItems);
			
			resetLeftMenuActiveItemState(rawLeftMenuItems);
			
			updateLeftMenuState(rawLeftMenuItems, uniqKey);
			
			return {
				...state,
				leftMenuItems: cloneDeep(rawLeftMenuItems),
			};
		}
		
		case COMMUNITY_LEFT_MENU_ITEM_COUNTERS_CHANGE: {
			const {leftMenuItems} = state;
			const {counters} = action.payload;
			
			const rawLeftMenuItems = cloneDeep(leftMenuItems);
			
			updateLeftMenuCountersState(rawLeftMenuItems, counters);
			
			return {
				...state,
				leftMenuItems: cloneDeep(rawLeftMenuItems),
			};
		}
		
		case COMMUNITY_LEFT_MENU_ITEM_CLICK: {
			const {leftMenuItems} = state;
			const {uniqKey, fn} = action.payload;
			
			const rawLeftMenuItems = cloneDeep(leftMenuItems);
			
			if (!isLeftMenuGroupItem(rawLeftMenuItems, uniqKey)) {
				resetLeftMenuActiveItemState(rawLeftMenuItems);
			}
			
			updateLeftMenuState(rawLeftMenuItems, uniqKey, fn);
			
			return {
				...state,
				leftMenuItems: cloneDeep(rawLeftMenuItems),
			};
		}
		
		case COMMUNITY_LEFT_MENU_ITEM_TOGGLE: {
			const {leftMenuItems} = state;
			const {uniqKey} = action.payload;
			
			const rawLeftMenuItems = cloneDeep(leftMenuItems);
			
			toggleLeftSubMenu(rawLeftMenuItems, uniqKey);
			
			return {
				...state,
				leftMenuItems: cloneDeep(rawLeftMenuItems),
			};
		}
		
		/**
		 *  Recommended Users Actions
		 */
		case COMMUNITY_RECOMMENDED_USERS_INIT: {
			const {recommendedUsers} = action.payload;
			
			return {
				...state,
				recommendedUsers: cloneDeep(recommendedUsers),
			};
		}
		
		case COMMUNITY_RECOMMENDED_USERS_LOAD_MORE: {
			const {recommendedUsers} = state;
			const {loadedUsers} = action.payload;
			const currentUsers = [...recommendedUsers, ...loadedUsers];
			
			return {...state, recommendedUsers: cloneDeep(currentUsers)};
		}

		case COMMUNITY_RECOMMENDED_USER_CONNECT:
		case COMMUNITY_RECOMMENDED_USER_DECLINE: {
			const {recommendedUsers} = state;
			const {recommendationID} = action.payload;
			
			return {
				...state,
				recommendedUsers: recommendedUsers.filter(({idRecommendation}) => idRecommendation !== recommendationID),
			};
		}
		
		case COMMUNITY_CONNECTIONS_USERS_LOAD_MORE: {
			const {connectionUsers} = state;
			const {loadedUsers} = action.payload;
			const currentUsers = [...connectionUsers, ...loadedUsers];
			
			return {...state, connectionUsers: cloneDeep(currentUsers)};
		}

		/**
		 *  Connection Users Actions
		 */
		case COMMUNITY_CONNECTIONS_USERS_INIT: {
			const {connectionUsers} = action.payload;
			
			return {
				...state,
				connectionUsers: cloneDeep(connectionUsers),
			};
		}

		case COMMUNITY_CONNECTIONS_USERS_DELETE: {
			const {deletedUserId} = action.payload;
			const {connectionUsers} = state;
			
			return {
				...state,
				connectionUsers: connectionUsers.filter(user => user.id !== deletedUserId)
			};
		}
		
		/**
		 *  Incoming Users Actions
		 */
		case COMMUNITY_INCOMING_USERS_INIT: {
			const {incomingUsers} = action.payload;
			
			return {
				...state,
				incomingUsers: cloneDeep(incomingUsers),
			};
		}
		
		case COMMUNITY_INCOMING_USER_ACCEPT:
		case COMMUNITY_INCOMING_USER_DECLINE: {
			const {incomingUsers} = state;
			const {userID} = action.payload;
			
			return {
				...state,
				incomingUsers: incomingUsers.filter(({id}) => id !== userID),
			};
		}
		
		case COMMUNITY_INCOMING_USERS_LOAD_MORE: {
			const {incomingUsers} = state;
			const {loadedUsers} = action.payload;
			const currentUsers = [...incomingUsers, ...loadedUsers];
			
			return {...state, incomingUsers: cloneDeep(currentUsers)};
		}

		/**
		 *  Pending Users Actions
		 */
		case COMMUNITY_PENDING_USERS_INIT: {
			const {pendingUsers} = action.payload;
			
			return {
				...state,
				pendingUsers: cloneDeep(pendingUsers),
			};
		}
		
		case COMMUNITY_PENDING_USERS_LOAD_MORE: {
			const {pendingUsers} = state;
			const {loadedUsers} = action.payload;
			const currentUsers = [...pendingUsers, ...loadedUsers];
			
			return {...state, pendingUsers: cloneDeep(currentUsers)};
		}

		/**
		 *  Messages Page Actions
		 */
		case COMMUNITY_MESSAGES_CHAT_WS_INIT: {
			const {ws} = action.payload;
			
			return {
				...state,
				chatWS: ws,
			};
		}
		
		case COMMUNITY_MESSAGES_CHAT_WS_CLOSE: {
			const {chatWS} = state;
			
			if (typeof chatWS.close === "function") {
				chatWS.close();
			}
			
			return {
				...state,
				chatWS: {},
				chatUsersList: [],
				chatUserListBubbles: new Map(),
				chatUserListLastMessages: new Map(),
				chatRecipientID: null,
				chatActiveMessages: [],
			};
		}

		case COMMUNITY_CONSTELLATION_NODE_PARAMS_SET: {
			const {nodeParams} = action.payload;
			const copyNodesParams = new Map(state.nodesParams);

			copyNodesParams.set(nodeParams.id, nodeParams);

			return  {
				...state,
				nodesParams: copyNodesParams
			}
		}

		case COMMUNITY_CONSTELLATION_NODE_PARAMS_RESET: {
			return  {
				...state,
				nodesParams: []
			}
		}

		case COMMUNITY_MESSAGES_CHAT_USERS_LIST_INIT: {
			const {usersList} = action.payload;
			const currentBubbles = new Map();
			const currentLastMessages = new Map();

			usersList.map(el => {
				for(const format of FILE_FORMATS){
					if(el?.lastMessage?.includes(format)){
						el.lastMessage = 'User sent a file.'
						break;
					}
				}
				return el
			}).forEach(user => {
				currentBubbles.set(user.userID, user.counter);
				currentLastMessages.set(user.userID, {
					message: user.lastMessage,
					time: user.time
				});
			});
			
			return {
				...state,
				chatUserListBubbles: new Map(currentBubbles),
				chatUserListLastMessages: new Map(currentLastMessages),
				chatUsersList: [
					...usersList,
				],
			};
		}
		
		case COMMUNITY_MESSAGES_CHAT_RECIPIENT_ID_INIT: {
			const {id} = action.payload;
			
			return {
				...state,
				chatRecipientID: id,
			};
		}
		
		case COMMUNITY_MESSAGES_CHAT_MSG_INIT: {
			const {messages} = action.payload;
			
			return {
				...state,
				chatActiveMessages: [
					...messages,
				],
			};
		}
		
		case COMMUNITY_MESSAGES_CHAT_MSG_ADD_RECIPIENT: {
			const {chatRecipientID, chatActiveMessages, chatUserListBubbles, chatUserListLastMessages, chatUsersList} = state;
			const {message} = action.payload;

			const currentBubbles = new Map(chatUserListBubbles);
			const currentLastMessages = new Map(chatUserListLastMessages);

			const currentChatUsersList = getUpdatedChatUsersList(
                cloneDeep(chatUsersList),
                message.senderID,
                message.message,
                message.timestamp
            );

			currentLastMessages.set(message.senderID, {
				message: message.message,
				time: getValidChatDate(message.timestamp / 1000),
			});
			currentBubbles.set(message.senderID, (currentBubbles.get(message.senderID) + 1));
			
			if (message.senderID !== chatRecipientID) {
				return {
					...state,
					chatUserListBubbles: new Map(currentBubbles),
					chatUserListLastMessages: new Map(currentLastMessages),
					chatUsersList: currentChatUsersList.sort((a, b) => b.timestamp - a.timestamp)
				};
			}

			return {
				...state,
				chatActiveMessages: [
					...chatActiveMessages,
					message,
				],
				chatUserListBubbles: new Map(currentBubbles),
				chatUserListLastMessages: new Map(currentLastMessages),
				chatUsersList: currentChatUsersList.sort((a, b) => b.timestamp - a.timestamp)
			};
		}
		
		case COMMUNITY_MESSAGES_CHAT_MSG_ADD_SENDER: {
			const {
                chatActiveMessages,
                chatUserListLastMessages,
                chatUsersList,
            } = state;
            const { message } = action.payload;
            const currentLastMessages = new Map(chatUserListLastMessages);

			const currentChatUsersList = getUpdatedChatUsersList(
                cloneDeep(chatUsersList),
                message.recipientID,
                message.message,
                message.timestamp
            );

            currentLastMessages.set(message.recipientID, {
				message: message.fileName ? "User sent a file." : message.message,
                time: getValidChatDate(
                    message.timestamp / 1000,
                ),
            });
			
			return {
				...state,
				chatUserListLastMessages: new Map(currentLastMessages),
				chatActiveMessages: [
					...chatActiveMessages,
					message,
				],
				chatUsersList: currentChatUsersList.sort((a, b) => b.timestamp - a.timestamp)
			};
		}
		
		case COMMUNITY_MESSAGES_CHAT_MSG_RESET: {
			return {
				...state,
				chatActiveMessages: [],
			};
		}
		
		/**
		 *  Global Search Users Actions
		 */
		case COMMUNITY_GLOBAL_SEARCH_USERS_INIT: {
			const {globalSearchUsers} = action.payload;
			
			return {
				...state,
				globalSearchUsers: cloneDeep(globalSearchUsers),
			};
		}
		
		case COMMUNITY_GLOBAL_SEARCH_USER_CONNECT: {
			const {globalSearchUsers} = state;
			const {userID} = action.payload;
			
			return {
				...state,
				// globalSearchUsers: globalSearchUsers.filter(({id}) => id === userID),
				globalSearchUsers: setPendingStatusGlobalSearchUsers(globalSearchUsers, userID)
			};
		}

		case COMMUNITY_GLOBAL_SEARCH_MOLECULAR_CLASSES_INIT: {
			const {molecularClasses} = action.payload;
			
			return {
				...state,
				molecularClasses: cloneDeep(molecularClasses),
			};
		}

		case COMMUNITY_GLOBAL_SEARCH_THERAPEUTIC_AREAS_INIT: {
			const {therapeuticAreas} = action.payload;
			
			return {
				...state,
				therapeuticAreas: cloneDeep(therapeuticAreas),
			};
		}

		case COMMUNITY_GLOBAL_SEARCH_POPOVER_TOGGLE: {
			const {globalSearchPopoverOpen} = action.payload;
			
			return {
				...state,
				globalSearchPopoverOpen: globalSearchPopoverOpen,
			};
		}

		case COMMUNITY_GLOBAL_SEARCH_QUERY_INIT: {
			const {query} = action.payload;
			
			return {
				...state,
				globalSearchQuery: query
			};
		}

		/**
		 * Community Connect Modal Actions
		 */

		case COMMUNITY_CONNECT_MODAL_OPEN: {
			const {dialogConnectData} = action.payload;

			return {
				...state,
				showCommunityConnectModal: true,
				dialogConnectData: cloneDeep(dialogConnectData)
			};
		}

		case COMMUNITY_CONNECT_MODAL_CLOSE: {
			return {
				...state,
				showCommunityConnectModal: false,
			};
		}

		case COMMUNITY_CONNECT_MODAL_RESET: {
			return {
				...state,
				showCommunityConnectModal: false,
				dialogConnectData: defaultConnectDialogStateOptions
			};
		}

		/**
		 * Community Success Modal Actions
		 */

		case COMMUNITY_SUCCESS_MODAL_OPEN: {
			const {dialogSuccessData} = action.payload;
			
			return {
				...state,
				showCommunitySuccessModal: true,
				dialogSuccessData: cloneDeep(dialogSuccessData)
			};
		}

		case COMMUNITY_SUCCESS_MODAL_CLOSE: {
			return {
				...state,
				showCommunitySuccessModal: false,
			};
		}

		case COMMUNITY_SUCCESS_MODAL_RESET: {
			return {
				...state,
				showCommunitySuccessModal: false,
				dialogSuccessData: defaultSuccessDialogStateOptions
			};
		}

		/**
 		* Community Delete Modal Actions
 		*/

		case COMMUNITY_DELETE_MODAL_OPEN: {
			const { dialogDeleteData } = action.payload;

			return {
				...state,
				showCommunityDeleteModal: true,
				dialogDeleteData: cloneDeep(dialogDeleteData)
			};
		}

		case COMMUNITY_DELETE_MODAL_CLOSE: {
			return {
				...state,
				showCommunityDeleteModal: false,
			};
		}

		case COMMUNITY_DELETE_MODAL_RESET: {
			return {
				...state,
				showCommunityDeleteModal: false,
				dialogDeleteData: defaultDeleteDialogStateOptions
			};
		}

		/**
 		*  Community View Action
 		*/

		case COMMUNITY_REDIRECT: {
			const {path, pathState} = action.payload;
			return {
				...state,
				navigateTo: path,
				navigationState: pathState
			};
		}

		case COMMUNITY_REDIRECT_RESET: {
			return {
				...state,
				navigateTo: "",
				navigationState: null
			};
		}

		case COMMUNITY_FULLSCREEN_TOGGLE: {
			const {isOpen} = action.payload;
			return {
				...state,
				fullscreenView: isOpen
			};
		}

		case COMMUNITY_CONSTELLATION_LABELS_INIT: {
			const {labels} = action.payload;
			return {
				...state,
				constellationLabels: cloneDeep(labels)
			};
		}

		case COMMUNITY_CONSTELLATION_LABELS_SELECT: {
			const {label} = action.payload;
			const {constellationLabelsSelected, graphLabelsMask} = state;

			const currentLabelsSelection = new Set(constellationLabelsSelected);
			const currentLabelsMaskList = new Map(graphLabelsMask);

			if (currentLabelsSelection.has(label.value)) {
				currentLabelsSelection.delete(label.value);
				currentLabelsMaskList.delete(label.value)

			} else {
				currentLabelsSelection.add(label.value);
				currentLabelsMaskList.set(label.value, label)
			}

			return {
				...state,
				constellationLabelsSelected: new Set(currentLabelsSelection),
				graphLabelsMask: new Map(currentLabelsMaskList)
			};
		}

		case COMMUNITY_CONSTELLATION_LABELS_SELECT_CLEAR: {
			return {
				...state,
				constellationLabelsSelected: new Set(),
				constellationLabels: [],
				currentLabelsMaskList: new Map()
			};
		}

		case COMMUNITY_CONSTELLATION_RECOMMENDED_USERS_SET: {
			const {constellationRecommended} = action.payload;

			return {
				...state, 
				constellationRecommendedUsers: cloneDeep(constellationRecommended)
			}
		}

		case COMMUNITY_CONSTELLATION_CONNECTED_USERS_SET: {
			const {constellationConnectedUsers} = action.payload;

			return {
				...state, 
				constellationConnectedUsers: cloneDeep(constellationConnectedUsers)
			}
		}

		case COMMUNITY_CONSTELLATION_CONNECTED_USER_DELETE: {
			const { connectionId } = action.payload;
			const { constellationConnectedUsers} = state;

			return {
				...state,
				constellationConnectedUsers: constellationConnectedUsers.filter(user => user.connectionId !== connectionId)
			};
		}

		case COMMUNITY_CONSTELLATION_RECOMMENDED_USER_CONNECT: {
			const {constellationRecommendedUsers} = state;
			const {recommendedUserId} = action.payload;
			
			return {
				...state,
				constellationRecommendedUsers: constellationRecommendedUsers.map(user => {
					return user.recommendationId === recommendedUserId ? {...user, status: "Pending"} : user
				})
			};
		}

		case COMMUNITY_CONSTELLATION_RECOMMENDED_USER_DECLINE: {
			const {constellationRecommendedUsers} = state;
			const {recommendedUserId} = action.payload;
			
			return {
				...state,
				constellationRecommendedUsers: constellationRecommendedUsers.map(user => {
					return user.recommendationId === recommendedUserId ? {...user, status: "Declined"} : user
				})
			};
		}

		case COMMUNITY_CONSTELLATION_TOOLTIP_SET: {
			const {constellationTooltipParams, constellationTooltipOpen} = state;
			const {tooltipParams} = action.payload;

			const currentTooltipParams = constellationTooltipParams && constellationTooltipParams.userId !== -1
                ? tooltipParams?.userId && tooltipParams.userId === constellationTooltipParams.userId && tooltipParams.x === constellationTooltipParams.x
                    ? defaultConstellationTooltipParams
                    : tooltipParams || defaultConstellationTooltipParams
                : tooltipParams || defaultConstellationTooltipParams

			const basicTooltipState = {constellationTooltipParams: cloneDeep(currentTooltipParams)};
			const fullTooltipState = constellationTooltipOpen && Boolean(currentTooltipParams.userId !== -1) 
				? basicTooltipState 
				: {...basicTooltipState, constellationTooltipOpen: Boolean(currentTooltipParams.userId !== -1)}
			

			return {
				...state,
				...fullTooltipState
			};
		}

		case COMMUNITY_CONSTELLATION_TOOLTIP_OPEN: {
			const {isOpen} = action.payload;
			
			return {
				...state,
				constellationTooltipOpen: isOpen
			};
		}

		case COMMUNITY_CONSTELLATION_INCOMING_USER_ACCEPT: {
				const {constellationRecommendedUsers} = state;
				const {userID} = action.payload;
				
				return {
					...state,
					constellationRecommendedUsers: constellationRecommendedUsers.map(user => {
						return user.connectionId === userID ? {...user, status: "Connected"} : user
					})
				};
		}

		case COMMUNITY_CONSTELLATION_INCOMING_USER_DECLINE: {
			const {constellationRecommendedUsers} = state;
			const {userID} = action.payload;
			
			return {
				...state,
				constellationRecommendedUsers: constellationRecommendedUsers.map(user => {
					return user.connectionId === userID ? {...user, status: "Declined"} : user
				})
			};
		}

		case COMMUNITY_CONSTELLATION_LABEL_NODE_HOVER: {
			const {nodeParams} = action.payload;

			return {
				...state,
				graphLabelsMaskHovered: cloneDeep(nodeParams)
			};
		}

		case COMMUNITY_CONSTELLATION_LABEL_NODE_UPDATE: {
			const {graphLabelsMask} = state;
			const {nodeParams} = action.payload;
			const currentGraphLabelMask = new Map(graphLabelsMask);

			currentGraphLabelMask.set(nodeParams.value, nodeParams) 

			return {
				...state,
				graphLabelsMask: new Map(currentGraphLabelMask)
			};
		}

		case COMMUNITY_CONSTELLATION_LABEL_NODE_RESET: {
			return {
				...state,
				graphLabelsMask: new Map()
			};
		}

		case COMMUNITY_CONSTELLATION_GRAPH_READY: {
			const {isReady} = action.payload;
			return {
				...state,
				constellationGraphReady: isReady
			};
		}
		
		/**
		 *  Default
		 */
		default: {
			return state;
		}
	}
};

/****************************************
 *  Exports
 ****************************************/
export default communityReducer;

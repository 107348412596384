import { ADD_NEW_ANALYSIS, SET_SELECTED_ANALYSIS, SET_X_AXIS_ANALYSIS_ATTRIBUTE, SET_Y_AXIS_ANALYSIS_ATTRIBUTE, 
    SET_CHART_TYPE_ANALYSIS_ATTRIBUTE, REMOVE_ANALYSIS, SAVE_ANALYSIS_COHORT, ON_ANALYZE_COHORT, 
    ON_COHORT_SELECTED} from '../action-types'
import cloneDeep from 'lodash/cloneDeep'
import v4 from 'uuid/v4'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
const initialState = {
    analysisAttributes: [{
        id: '1',
        title: `Analysis 01`,
        xAxisAttribute: '',
        yAxisAttribute: '',
        representationType: '',
    }],
    selectedAnalysis: {},
    analysisCounter: 1,
    analysisCohorts:[],
    selectedCohort: {}
}

const addNewAnalysis = (state, attributeData) => {
    let tempAnalysisAttr = cloneDeep(state.analysisAttributes);
    tempAnalysisAttr.push(attributeData);
    return tempAnalysisAttr;
}
const setAllAttributesValue = (state, attributeData, fieldName) => {
    const attributeId = attributeData['id'];
    const attributeValue = attributeData['value'];
    const analysisAttributes = cloneDeep(state['analysisAttributes']);
    const index = findIndex(analysisAttributes, (analysisData)=> analysisData['id'] === attributeId);

    analysisAttributes[index] = {...analysisAttributes[index], [fieldName]: attributeValue };
    return analysisAttributes;
}
const removeAnalysisById = (state, id) => {
    try {
        if (!isEmpty(id)) {
            const analysisAttributes = cloneDeep(state['analysisAttributes']);
            const index = findIndex(analysisAttributes, (analysisData) => analysisData['id'] === id);
            analysisAttributes.splice(index, 1);
            return analysisAttributes;
        }
        else return state['analysisAttributes'];
    } catch (error) {
        console.log('error in removing analysis', error);
    }
}
const saveNewCohort = (state, cohortData)=>{
    try{
        let tempNewCohortsState = cloneDeep(state.analysisCohorts);
        tempNewCohortsState.push(cohortData);
        return tempNewCohortsState;
    }catch(error){
        console.log('error in saving cohorts');
    }
}

const AnalysisDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NEW_ANALYSIS: {
            const tempAnalysisAttr = addNewAnalysis(state, action.payload);
            return {
                ...state,
                analysisAttributes: tempAnalysisAttr,
                analysisCounter: state.analysisCounter + 1
            }
        }
        case SET_SELECTED_ANALYSIS: {
            return {
                ...state,
                selectedAnalysis: action.payload
            }
        }
        case SET_X_AXIS_ANALYSIS_ATTRIBUTE: {
            const newAnalysisAttributes = setAllAttributesValue(state, action.payload, 'xAxisAttribute');
            const value = action.payload['value'];
            return {
                ...state,
                analysisAttributes: newAnalysisAttributes,
                selectedAnalysis:{
                    ...state.selectedAnalysis,
                    ['xAxisAttribute']: value
                }
            }
        }
        case SET_Y_AXIS_ANALYSIS_ATTRIBUTE: {
            const newAnalysisAttributes = setAllAttributesValue(state, action.payload, 'yAxisAttribute');
            const value = action.payload['value'];
            return {
                ...state,
                analysisAttributes: newAnalysisAttributes,
                selectedAnalysis:{
                    ...state.selectedAnalysis,
                    ['yAxisAttribute']: value
                }
            }
        }
        case SET_CHART_TYPE_ANALYSIS_ATTRIBUTE: {
            const newAnalysisAttributes = setAllAttributesValue(state, action.payload, 'representationType');
            const value = action.payload['value'];
            return {
                ...state,
                analysisAttributes: newAnalysisAttributes,
                selectedAnalysis:{
                    ...state.selectedAnalysis,
                    ['representationType']: value
                }
            }
        }
        case REMOVE_ANALYSIS: {
            const newAnalysisState = removeAnalysisById(state, action.payload);
            return {
                ...state,
                analysisAttributes: newAnalysisState,
            }
        }
        case SAVE_ANALYSIS_COHORT: {
            const newCohortsState = saveNewCohort(state, action.payload);
            return {
                ...state,
                analysisCohorts: newCohortsState,
                analysisAttributes:[
                    {
                        id: '1',
                        title: `Analysis 01`,
                        xAxisAttribute: '',
                        yAxisAttribute: '',
                        representationType: '',
                    }
                ],
            }
        }
        case ON_ANALYZE_COHORT: {
            return {
                ...state,
                analysisAttributes: action.payload
            }
        }
        case ON_COHORT_SELECTED: {
            return {
                ...state,
                selectedCohort: action.payload
            }
        }
        default: return state
    }
}

export default AnalysisDataReducer;
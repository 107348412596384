import { SET_ZONE3_USERS_FIRST_NAME, SET_ZONE3_USERS_LAST_NAME, SET_ZONE3_USERS_MIDDLE_NAME, 
    SET_ZONE3_USERS_AFFILIATION, SET_ZONE3_USERS_INSTITUTIONAL_EMAIL, ADD_ZONE3_USER_DETAILS
    , REMOVE_ZONE3_USER, SET_ZONE_3_FORM_FIELDS, RESET_ZONE_3_FORM_FIELDS 
} from '../action-types'
import cloneDeep from 'lodash/cloneDeep'

const initialState = {
    zone3Users: [{
		"firstName": '',
		"middleName": '',
		"lastName": '',
		"affiliation": '',
        "institutionalEmail": ''
	}]
}

const zone3AuthorizedUserReducers = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ZONE3_USER_DETAILS: {
            let newZone3Users =  cloneDeep(state.zone3Users);
            newZone3Users.push(action.payload);
            return {
                ...state,
                zone3Users: newZone3Users
            }
        }
        case SET_ZONE3_USERS_FIRST_NAME: {
            const { value, id } = action.payload;
            let newZone3Users = cloneDeep(state.zone3Users);
            newZone3Users[id] = {
                ...newZone3Users[id],
                firstName: value
            }
            return {
                ...state,
                zone3Users: newZone3Users
            }
        }
        case SET_ZONE3_USERS_LAST_NAME: {
            const { value, id } = action.payload;
            let newZone3Users = cloneDeep(state.zone3Users);
            newZone3Users[id] = {
                ...newZone3Users[id],
                lastName: value
            }
            return {
                ...state,
                zone3Users: newZone3Users
            }
        }
        case SET_ZONE3_USERS_MIDDLE_NAME: {
            const { value, id } = action.payload;
            let newZone3Users = cloneDeep(state.zone3Users);
            newZone3Users[id] = {
                ...newZone3Users[id],
                middleName: value
            }
            return {
                ...state,
                zone3Users: newZone3Users
            }
        }
        case SET_ZONE3_USERS_AFFILIATION: {
            const { value, id } = action.payload;
            let newZone3Users = cloneDeep(state.zone3Users);
            newZone3Users[id] = {
                ...newZone3Users[id],
                affiliation: value
            }
            return {
                ...state,
                zone3Users: newZone3Users
            }
        }
        case SET_ZONE3_USERS_INSTITUTIONAL_EMAIL: {
            const { value, id } = action.payload;
            let newZone3Users = cloneDeep(state.zone3Users);
            newZone3Users[id] = {
                ...newZone3Users[id],
                institutionalEmail: value
            }
            return {
                ...state,
                zone3Users: newZone3Users
            }
        }
        case REMOVE_ZONE3_USER: {
            let newZone3Users =  cloneDeep(state.zone3Users);
            newZone3Users.splice(action.payload, 1);
            return {
                ...state,
                zone3Users: newZone3Users
            }
        }
        case SET_ZONE_3_FORM_FIELDS: {
            return {
                ...state,
                zone3Users: action.payload
            }
        }
        case RESET_ZONE_3_FORM_FIELDS: {
            return {
                zone3Users: [{
                    "firstName": '',
                    "middleName": '',
                    "lastName": '',
                    "affiliation": '',
                    "institutionalEmail": ''
                }]
            }
        }
        default: return state
    }
}

export default zone3AuthorizedUserReducers;
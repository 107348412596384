import axios from 'axios'
import {env} from '../env'
import { getCognitoIdToken } from './utilityMethods';
const BASE_URL = `${env.REACT_APP_BASE_API_PATH}`


export const saveCohortWithoutID = async (body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const response = await axios.post(`${BASE_URL}/cohort/analysis`, body, { headers: header });
    return response?.data || response
}

export const getCohortsForUserApi = async (limit, exclusiveKey) => {    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const response = await axios.get(`${BASE_URL}/cohort?limit=${limit}&exclusive_start_key=${JSON.stringify(exclusiveKey)}`, { headers: header });
    return response?.data || []
}

export const getAnalysisWithCohortID = async id => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const response = await axios.get(`${BASE_URL}/cohort/` + id, { headers: header });
    return response?.data || null
}

export const saveCohortToDBApi = async body => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const response = await axios.post(`${BASE_URL}/cohort/analysis`, body, { headers: header,
            validateStatus: status => (status >= 200 && status < 300) || status === 400},
        );
    return response?.data || null
}

export const deleteCohort = async id => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const response = await axios.delete(`${BASE_URL}/cohort/${id}`, { headers: header });
    return response?.data || []
}
import { SET_PROJECT_PI_FIRST_NAME, SET_PROJECT_PI_LAST_NAME, SET_PROJECT_PI_MIDDLE_NAME, SET_PROJECT_PI_CREDENTIALS, 
    SET_PROJECT_PI_AFFILIATION, SET_PROJECT_PI_INSTITUTE_EMAIL, SET_PROJECT_PI_FIELDS, RESET_PROJECT_PI_FIELDS
} from '../action-types'
export const setProjectPIFirstName = (value) => {
    return {
        type: SET_PROJECT_PI_FIRST_NAME,
        payload: value
    }
}
export const setProjectPILastName = (value) => {
    return {
        type: SET_PROJECT_PI_LAST_NAME,
        payload: value
    }
}
export const setProjectPIMiddleName = (value) => {
    return {
        type: SET_PROJECT_PI_MIDDLE_NAME,
        payload: value
    }
}
export const setProjectPICredentials = (value) => {
    return {
        type: SET_PROJECT_PI_CREDENTIALS,
        payload: value
    }
}
export const setProjectPIAffiliation = (value) => {
    return {
        type: SET_PROJECT_PI_AFFILIATION,
        payload: value
    }
}
export const setProjectPIInstituteEmail = (value) => {
    return {
        type: SET_PROJECT_PI_INSTITUTE_EMAIL,
        payload: value
    }
}
export const setProjectPIFields = (value) => {
    return {
        type: SET_PROJECT_PI_FIELDS,
        payload: value
    }
}
export const resetPIForm = () => {
    return {
        type: RESET_PROJECT_PI_FIELDS,
    }
}

import InstitutionalCertification from './InstitutionalCertification';
import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        userDetails: state.user.userDetails
    }
}

export default connect(mapStateToProps,null)(InstitutionalCertification);
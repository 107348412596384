import WorkspaceCardsContainer from './WorkspaceCardsContainer'
import { connect } from 'react-redux'
import { setAllWorkspaces, setLoadedWorkspaces } from '../../../redux/actions/workspaceActions'
const mapStateToProps = state => {
    return {
        loadedWorkspaces: state.workspace.loadedWorkspaces,
        allWorkspaces: state.workspace.allWorkspaces,
        allWorkspacesCarts: state.workspace.allWorkspacesCarts

    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAllWorkspaces: (value) => dispatch(setAllWorkspaces(value)),
        setLoadedWorkspaces: async (id) => dispatch(setLoadedWorkspaces(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceCardsContainer);
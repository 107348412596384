import AuthenticatedLanding from './AuthenticatedLanding'
import { connect } from 'react-redux'
import { validateCookie } from '../../../api/validate-cookie'
import { updateUserDetails } from '../../../redux/actions/userActions'

const mapStateToProps = state => {
    return {
        userDetails: state.user.userDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        validateCookie: async () => await validateCookie(),
        updateUserDetails: async (value) => await dispatch(updateUserDetails(value)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedLanding);
import React from 'react';
import { alpha, Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { vars } from '../../../../assets/variables.js';
import { maxHeight } from '@mui/system';

const { grayTextColor, darkTextColor } = vars;
const useStyles = makeStyles(() => ({
  card: {
    padding: '0.75rem',
    height: '3.813rem',
    '& .text': {
      fontSize: '0.625rem',
      fontWeight: 500,
      lineHeight: '1rem',
      color: grayTextColor,
      textTransform: 'capitalize',
    },
    '& .title': {
      fontSize: '1.25rem',
      fontWeight: 500,
      color: darkTextColor,
      whiteSpace: 'nowrap',
    },
  },
}));

export const Container = ({ children, height, maxHeight, ...props }) => {
  return (
    <Box
      {...props}
      borderRadius={1.5}
      sx={(theme) => ({
        position: 'relative',
        height: height ? height : 'auto',
        backgroundColor: theme.palette.grey[50],
        filter: 'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))',
        zIndex: 15,
        maxHeight: maxHeight ? maxHeight : 'auto',
        ...props.sx,
      })}
    >
      {children}
    </Box>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  height: PropTypes.string,
};

export const Card = ({ leftNode, title, description }) => {
  const classes = useStyles();
  return (
    <Container sx={{
      alignItems: 'center',
      display: 'flex',
      flex: 1,
    }}>
      <Stack
        className={classes.card}
        direction="row"
        alignItems="center"
        spacing={0.75}
      >
        {!!leftNode && leftNode}
        <Stack spacing={0}>
          <Typography className="text">{description}</Typography>
          <Typography className="title">{title}</Typography>
        </Stack>
      </Stack>
    </Container>
  );
};

Card.proTypes = {
  leftNode: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export const IconContainer = ({ children }) => {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      borderRadius={'50%'}
      p={1}
      sx={(theme) => ({
        textAlign: 'center',
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      })}
    >
      {children}
    </Box>
  );
};

IconContainer.propTypes = {
  children: PropTypes.node,
};

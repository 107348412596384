import WorkspaceCard from './WorkspaceCards'
import { connect } from 'react-redux';
import { updateSnackBar } from '../../../redux/actions/globalActions';
import { setLoadedWorkspaces } from '../../../redux/actions/workspaceActions';

const mapStateToProps = state => ({
    userDetails: state.user.userDetails,
    allWorkspacesCarts: state.workspace.allWorkspacesCarts
})


const mapDispatchToProps = dispatch => {
    return {
        updateSnackBar: async (message, type) => dispatch(updateSnackBar(message, type)),
        setLoadedWorkspaces: async (id) => dispatch(setLoadedWorkspaces(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceCard);
import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import classes from './index.module.css'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '../../../assets/icons/search-icon.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { cloneDeep, filter, get, isEmpty, map, find } from 'lodash';
import TablePagination from '../../commons/table-pagination';
import { USER_SORT_OPTIONS, ZONE_1_KEY, ZONE_2_KEY, ZONE_3_KEY } from '../../../constants/strings';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import DropDown from '../../commons/dropdown';
import MuiToolTip from '@mui/material/Tooltip'
import { getUserAccessDataDownloadUrl } from '../../../api/dca-form-api';
import tick from '../../../assets/icons/tick.svg'
import cancel from '../../../assets/icons/cancel.svg'
import { navigate } from '@reach/router';

const DataDistributionDetails = (props) => {
    const { selectedZone = null, selectedZoneData=[], allZonesData=[], selectedUser = null, updateSnackBar = ()=>{}, setData = () =>{ }} = props;
    const [datasetSearchText, setDatasetSearchText] = useState('');
    const [datasetPage, setDatasetPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchText, setSearchText] = useState('')
    const [page, setPage] = useState(0);

    useEffect(() => {
        setDatasetPage(0);
        setPage(0);
    }, [selectedZone, selectedUser])
    const setDatasetTablePage = number => {
        setDatasetPage(number);
    }
    const StyledTableCell = withStyles(theme => ({
        head: {
            backgroundColor: "#E7EAF2",
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 12,
        },
    }))(TableCell);
    
    const StyledTableRow = withStyles(theme => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.background.default,
            },
        },
    }))(TableRow);

    useEffect(() => {
        
    }, []);
    const getSelectedZoneData = (allData, id) => {
        let currentSelectedZoneData = {};
        switch (id) {
            case 1: currentSelectedZoneData = find(allData, (data) => get(data, 'key', 0) === ZONE_1_KEY);
                break;
            case 2: currentSelectedZoneData = find(allData, (data) => get(data, 'key', 0) === ZONE_2_KEY);
                break;
            case 3: currentSelectedZoneData = find(allData, (data) => get(data, 'key', 0) === ZONE_3_KEY);
                break;
            default: break;
        }
        return currentSelectedZoneData
    }

    const onSearchChange = (value) => {
        setSearchText(value);
        let searchUserData;
        if (selectedUser === 1) {
            searchUserData = props.usersData.zone1
        } else if (selectedUser === 2) {
            searchUserData = props.usersData.zone2
        } else {
            searchUserData = props.usersData.zone3
        }
        let searchData = searchUserData?.filter(user => user.searchKey.includes(value.toLowerCase()));
        props.setData(searchData);
        setPage(0);
    }

    const onDatasetSearchChange = (e) => {
        const value = e.target.value;
        setDatasetSearchText(value);
        const allZonesDataClone = cloneDeep(allZonesData);
        let currentZoneData = getSelectedZoneData(allZonesDataClone, selectedZone);
        let searchData = [];
        if (currentZoneData?.termsFields.length) {
            searchData = filter(currentZoneData?.termsFields[0]?.terms, (data) => {
                const searchInString = (data?.key || '').toLowerCase();
                const isPresent = searchInString.includes(value.toLowerCase());
                return isPresent
            })
            let tempTermsOfField = currentZoneData?.termsFields;
            tempTermsOfField[0] = {
                ...tempTermsOfField[0],
                terms: searchData
            }
            currentZoneData = { ...currentZoneData, termsFields: tempTermsOfField }
            props.setSelectedZoneData(currentZoneData);
        }
    }

    const setUserPage = number => {
        setPage(number);
    }
    const onUserDataDownload = async () => {
        let requests = await getUserAccessDataDownloadUrl();
        if (!isEmpty(requests?.users_record_url)) {
            updateSnackBar("Download has started", 'success');
            window.location.href = requests.users_record_url;
        } else {
            updateSnackBar("Unable to download", 'Error');
        }
    }
    const goProfile = (id, tabId) => {
        navigate(`/admin/user-profile/${id}/${tabId}`, { state: { adminDashboard: true, selectedUser: selectedUser } });
    }
    
    const datasetInZoneText = selectedZone === 1 ? 'Data Sets In Zone 1' :
        (selectedZone === 2 ? 'Data Sets In Zone 2'
            : (selectedZone === 3 ? 'Data Sets In Zone 3' : '')
        )

    const maxDatasetCount = !isEmpty(selectedZoneData?.termsFields || []) ?
        (selectedZoneData?.termsFields?.[0]?.terms?.length ?
            Math.ceil(selectedZoneData?.termsFields[0]?.terms.length / 5)
            : 0) : 0;

    const maxCount = props.userData?.length ? Math.ceil(props.userData.length / 5) : 0;

    const downloadRecordsButtonClass = window.navigator.appVersion.indexOf('Win') !== -1
    ? window.devicePixelRatio > 1.25 ? `${classes.highDPI} ${classes.exportButton}`
        : classes.exportButton
    : classes.exportButton;

    return (
        <React.Fragment>
            { selectedZone ? <div>
                <div className={classes.selectedZoneDetailsBlock}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <div className={classes.selectedZoneDetailsItem}>
                                <h2 id='cvb-adminDashboard-dataTypeChartHeading'>Data Type</h2>
                                <div className={classes.dataTypeChartContainer}>
                                    <p id='cvb-adminDashboard-dataTypeChart-noItemFoundText'>No items found</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div className={classes.selectedZoneDetailsItem}>
                                <div className={classes.selectedZoneTableHeader}>
                                    <div className={classes.selectedZoneTableHeaderLeft}>
                                        <h2 id='cvb-adminDashboard-datasetZone-heading'>{datasetInZoneText}</h2>
                                    </div>
                                    <div className={classes.selectedZoneTableHeaderRight}>
                                        <OutlinedInput fullWidth type='text'
                                            value={datasetSearchText} placeholder='Search'
                                            className='signup-inputs-large' name='password'
                                            onChange={onDatasetSearchChange}
                                            id='cvb-adminDashboard-datasetSearchInput'
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton aria-label="toggle password visibility" size="large">
                                                        <img alt='visibility On' src={SearchIcon} className={classes.exploreSearchIcon} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                </div>
                                <TableContainer className={classes.cvTableContainer}>
                                    <Table className={classes.table} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    map(selectedZoneData?.termsFields, (zoneData) => {
                                                        return (
                                                            <StyledTableCell id={`cvb-adminDashboard-selectedZoneData-${zoneData?.field}`} align="left" className={classes.tableFileName}>
                                                                {get(zoneData, 'field', '') === 'project_id' ? 'Project Id' : get(zoneData, 'field', '')}
                                                            </StyledTableCell>
                                                        )
                                                    })
                                                }

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                //Need to add support for multiple dynamic columns in future.
                                                map(selectedZoneData?.termsFields?.[0]?.terms, row => {
                                                    return <StyledTableRow key={row?.key}>
                                                        <StyledTableCell id={`cvb-adminDashboard-datasetData-${row?.key}`} align="left">{row?.key}</StyledTableCell>
                                                    </StyledTableRow>
                                                }).slice(datasetPage * rowsPerPage, datasetPage * rowsPerPage + rowsPerPage)
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {maxDatasetCount > 0 && <div className={classes.tablePagination}>
                                    <TablePagination
                                        maxCount={maxDatasetCount}
                                        page={datasetPage}
                                        setPage={setDatasetTablePage}
                                    />
                                </div>}
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {/* selected zone{selectedZone} */}
            </div> : 
            null
        }{
            selectedUser ?

            <Grid item xs={12} className={classes.userTableWrapper}>
                <div className={[classes.selectedZoneDetailsItem, classes.selectedUserContainer].join(' ')}>
                    <div className={classes.selectedZoneTableHeader}>
                        <Grid item xs>
                            <div className={classes.selectedZoneTableHeaderLeft}>
                                <h2 id='cvb-adminDashboard-usersInZoneHeading'>Users In Zone {selectedUser}</h2>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <div className={classes.showFilterContainer}>
                                <label id='cvb-adminDashboard-usersInZone-sortByLabel'>Sort By:</label>
                                <div className={classes.showFilterWrapper}>
                                    <DropDown
                                        values={USER_SORT_OPTIONS}
                                        handleFilterStateChange={props.onUserSortChange}
                                        selectedFilter={props.userSortOption}
                                        placeholder={'Select option'}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <div className={classes.showFilterContainer}>
                                <OutlinedInput fullWidth type='text'
                                    value={searchText} placeholder='Search'
                                    onChange={(e) => onSearchChange(e.target.value)}
                                    className='signup-inputs-large' name='password'
                                    id='cvb-adminDashboard-usersInZone-searchInput'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" size="large">
                                                <img alt='visibility On' src={SearchIcon} className={classes.exploreSearchIcon} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />

                            </div>
                        </Grid>

                        {
                            props.userDetails?.resourceActions?.dashboard?.download_records && selectedUser == "1" &&
                                <button onClick={() => { onUserDataDownload() }} className={`solid-button large-size-button ${downloadRecordsButtonClass}`}>Download Records</button>
                        }
                    </div>
                    <TableContainer

                        elevation={0}
                        className={classes.cvTableContainer}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell id='cvb-adminDashboard-userDetailsTable-nameColumHeading'>Name</TableCell>
                                    <TableCell id='cvb-adminDashboard-userDetailsTable-designationColumHeading' className={classes.searchColumn} width="15%" align="left">Designation</TableCell>
                                    <TableCell id='cvb-adminDashboard-userDetailsTable-orgColumHeading' className={classes.searchColumn} width="15%" align="left">Organization</TableCell>
                                    <TableCell id='cvb-adminDashboard-userDetailsTable-emailColumHeading' className={classes.searchColumn} width="15%" align="left">Email</TableCell>
                                    <TableCell id='cvb-adminDashboard-userDetailsTable-userRoleColumHeading' className={classes.searchColumn} width="15%" align="left">User Role</TableCell>
                                    <TableCell id='cvb-adminDashboard-userDetailsTable-zone2AccessColumHeading' className={[classes.searchColumn, classes.searchColumnIcon].join(' ')} align="left">Access To Zone 2</TableCell>
                                    <TableCell id='cvb-adminDashboard-userDetailsTable-zone3AccessColumHeading' className={[classes.searchColumn, classes.searchColumnIcon].join(' ')} align="left">Access To Zone 3</TableCell>
                                    <TableCell id='cvb-adminDashboard-userDetailsTable-workspaceAccessColumHeading' className={[classes.searchColumn, classes.searchColumnIcon].join(' ')} align="left">Access To Workspace</TableCell>
                                    <TableCell className={classes.searchColumn} width="40%" align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.userData.map((row) => {
                                    const userRoles = row?.user_roles || [];
                                    let roleArr = [];
                                    map(userRoles, (role) => roleArr.push(role?.role_label));
                                    const roles = roleArr.join(', ');
                                    return (
                                        <TableRow key={row.username}>
                                            <TableCell id='cvb-adminDashboard-userDetailsTable-userName' component="th" scope="row">
                                                {row?.name || ''}
                                            </TableCell>

                                            <TableCell id='cvb-adminDashboard-userDetailsTable-designation' component="th" scope="row">
                                                {row?.designation || ''}
                                            </TableCell>

                                            <TableCell className={[classes.organizationCell, classes.userTableCell].join(' ')} component="th" scope="row">
                                                <MuiToolTip id='cvb-adminDashboard-userDetailsTable-orgNameTooltip' placement="bottom-start" title={row?.organisation || ''}>
                                                    <span id='cvb-adminDashboard-userDetailsTable-orgName'>{row?.organisation || ''}</span>
                                                </MuiToolTip>
                                            </TableCell>

                                            <TableCell className={[classes.emailCell, classes.userTableCell].join(' ')} component="th" scope="row">
                                                <MuiToolTip id='cvb-adminDashboard-userDetailsTable-emailTooltip' placement="bottom-start" title={row?.email || ''}>
                                                    <span id='cvb-adminDashboard-userDetailsTable-email'>{row?.email || ''}</span>
                                                </MuiToolTip>
                                            </TableCell>

                                            <TableCell className={[classes.userRoleCell, classes.userTableCell].join(" ")} component="th" scope="row">
                                                <MuiToolTip id='cvb-adminDashboard-userDetailsTable-rolesTooltip' placement="bottom-start" title={roles || ''}>
                                                    <span id='cvb-adminDashboard-userDetailsTable-roles'>{roles || ''}</span>
                                                </MuiToolTip>
                                            </TableCell>

                                            <TableCell className={classes.userTableCell} component="th" scope="row">
                                                {<img id='cvb-adminDashboard-userDetailsTable-zone2AccessImage' src={row.zone2_access ? tick : cancel} />}
                                            </TableCell>

                                            <TableCell className={classes.userTableCell} component="th" scope="row">
                                                {<img id='cvb-adminDashboard-userDetailsTable-zone3AccessImage' src={row.zone3_access ? tick : cancel} />}
                                            </TableCell>

                                            <TableCell className={classes.userTableCell} component="th" scope="row">
                                                {<img id='cvb-adminDashboard-userDetailsTable-workspaceAccessImage' src={row.workspace_access ? tick : cancel} />}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                <div className={classes.statusWrapper}>
                                                    {
                                                        row.status?.toLowerCase() === "enabled" ?
                                                            <span id='cvb-adminDashboard-userDetailsTable-status' className={[classes.statusBox, classes.enabled].join(' ')}>
                                                                {row.status}
                                                            </span> :
                                                            <span id='cvb-adminDashboard-userDetailsTable-status' className={[classes.statusBox, classes.disabled].join(' ')}>
                                                                {row.status}
                                                            </span>
                                                    }
                                                    <button id='cvb-adminDashboard-userDetailsTable-userNameButton' onClick={() => goProfile(row.username, "")} className={classes.statusButton}>Profile</button>
                                                    <button id='cvb-adminDashboard-userDetailsTable-permissionButton' onClick={() => goProfile(row.username, "permissions")} className={classes.statusButton}>Permissions</button>
                                                </div>
                                            </TableCell>

                                        </TableRow>
                                    )
                                }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {
                        maxCount > 0 &&
                        <div className={classes.tablePagination}>
                            <TablePagination
                                maxCount={maxCount}
                                page={page}
                                setPage={setUserPage}
                            />

                        </div>
                    }
                </div>
            </Grid> : null
            }
        </React.Fragment>
    );
}

export default DataDistributionDetails;
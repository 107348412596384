import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconNotification from '../../../assets/icons/Help-new.svg'
import IconNotificationActive from '../../../assets/icons/active_notification.svg'
import ProfilePic from '../../../assets/images/user/default-profile-image.png'
import DownArrow from '../../../assets/icons/W-DropDown.XS.svg'
import clsx from 'clsx';
import Classes from './index.module.css'
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import BCDataPortalLogo from '../../../assets/images/onboarding_logo.png'
import { navigate } from '@reach/router';
import MuiPopover from '@mui/material/Popover';
import UserProfileIcon from '../../../assets/images/UserProfileLinkIcon.png'
import MyAccountIcon from '../../../assets/images/MyAccountIcon.svg'
import LogoutIcon from '../../../assets/images/LogOutIcon.png'
import { Auth } from 'aws-amplify';
import { getProfilePicture, getUserProfile } from '../../../api/profile-api'
import capitalize from 'lodash/capitalize'
import { ABOUT_BRAIN_COMMONS_LINK } from '../../../constants/strings'
import { fetchWorkSpaceOverallStatus, fetchWorkspaceCarts } from '../../../redux/actions/globalActions'
import v4 from 'uuid/v4'
import map from 'lodash/map'
import { IconButton, Tooltip } from '@mui/material';
import Sockette from "sockette";
import moment from 'moment'
import { establishWebSocket } from '../../../redux/actions/globalActions'
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenedNotification } from '../../../redux/actions/topNavBarActions';
import cartIcon from '../../../assets/images/cartIconHeader.svg';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: '0px 4px 20px #00000007'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
}));

const Popover = withStyles(theme => ({
    root: {
        overflowY: 'scroll',
    },
    paper: {
        minWidth: 200,
        right: 10,
        left: 'auto !important',
        top: '65px !important',
        boxShadow: '0px 2px 6px #4141411F;',
        border: '1px solid #DEDEDE',
        borderRadius: 0
    }
}))(MuiPopover);

const NotificationPopover = withStyles(theme => ({
    root: {
        overflowY: 'scroll',
    },
    paper: {
        maxWidth: 350,
        maxHeight: 300,
        right: 200,
        overflowY: 'scroll',
        left: 'auto !important',
        top: '65px !important',
        boxShadow: '0px 2px 6px #4141411F;',
        borderRadius: 0,
    }
}))(MuiPopover);

const TopNavBar = props => {

    const dispatch = useDispatch()
    const classes = useStyles();
    const { open , allNotificationsData = []} = props
    const [anchorEl, setAnchorEl] = useState(null);
    const [userDetails, setUserDetails] = useState({})
    const [isNotificationPopOverOpen, setIsNotificationPopOverOpen] = useState(null)
    // const [webSocketState, setWebSocketState] = useState(null)
    const userName = (capitalize(userDetails?.["first_name"]) + " " + capitalize(userDetails?.["last_name"]))
    const [notificationData, setNotificationData] = useState([])
    const [filesCount, setFilesCount] = useState(0);

    const handleDownArrowClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClosePopover = () => {
        setAnchorEl(null);
    }

    const onMyProfileLinkClick = () => {
        setAnchorEl(null);
        navigate('/my-profile');
        props.profileClickHandler()
    }


    const onLogoutLinkClick = async () => {
        try {
            await Auth.signOut().catch(e => { })
            window.location.href = "/"
            localStorage.clear();
            sessionStorage.clear()
            setAnchorEl(null);
        } catch(error) {
            console.log(error)
        }   
    }


    const handleOnMessage = data => {
        if (data) {
            try {
                let parsedData = JSON.parse(data)
                if (!parsedData) {
                    return;
                }
                if (Array.isArray(parsedData)) {
                    props.setAllNotificationsData(parsedData);
                    setNotificationData(parsedData)
                } else {
                    let newData = cloneDeep(allNotificationsData);
                    newData.unshift(parsedData);
                    props.setAllNotificationsData(newData);
                    setNotificationData(newData)
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        establishWebSocket()
    }, [])

    useEffect(() => {
        let totalFiles = 0
        if(props.allWorkspacesCarts?.workspace_carts){
            for(let workspace in props.allWorkspacesCarts?.workspace_carts){
                if(props.allWorkspacesCarts.workspace_carts[workspace]?.length){
                    for(let order of props.allWorkspacesCarts.workspace_carts[workspace]){
                        if(order?.status?.toLowerCase() === "open"){
                            totalFiles = totalFiles + order?.files?.length
                        }
                    }
                }
            }
        }
        setFilesCount(totalFiles)
    }, [props.allWorkspacesCarts])

    useEffect(() => {
        handleOnMessage(props.webSocketOnMessage)
    }, [props.webSocketOnMessage])

    useEffect(() => {
        getProfilePictureData();
        fetchWorkSpaceOverallStatus();
        fetchWorkspaceCarts();
    }, [])

    useEffect(() => {
        getData()
    }, [props.pullCount]);

    const getProfilePictureData = async () => {
        try {
            const response = await getProfilePicture();
            props.updateProfilePicture(response);
        }
        catch (error) {
            console.log(error)
        }
    }
    const getData = async () => {
        try {
            const userValues = await Auth.currentUserInfo();
            const userInfo = await getUserProfile(userValues?.attributes?.sub);
            setUserDetails(userInfo?.user_details || {})
        }
        catch (error) {
            console.log(error)
        }
    }

    const onViewAboutLinkClick = (aboutLink) => {
        window.open(aboutLink, "_blank")
    }
    const onViewAllNotificationsClick = (e) => {
        setIsNotificationPopOverOpen(false);
        navigate('/view-all-notifications');
    }

    // * Do not delete yet, may be needed in the future
    // 
    // const acknowledgeNotification = () => {
    //     try {
    //         if (props.webSocketConnection) {
    //             props.webSocketConnection.json({
    //                 "action": "acknowledge_notification",
    //                 "messages": notificationData?.map(item => {
    //                     return {
    //                         message_id: item?.message_id
    //                     }
    //                 })
    //             })
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const goToCart = () => {
        navigate('/cart')
    }
    const navigateToNotificationLocation = item => {
        navigate("/" + item?.payload?.permalink)
        if (!item?.read) {
            if (props.webSocketConnection) {
                props.webSocketConnection.json({
                    "action": "read_notification",
                    "messages": [
                        {
                            message_id: item?.message_id
                        }
                    ]
                })
            }
        }

        if(item?.payload?.header.includes('New message')){
            const name = item?.payload?.header.split(' ').slice(2, item?.payload?.header.length).join(' ')
            dispatch(setOpenedNotification(name))
        }
        
        setIsNotificationPopOverOpen(false);
    }

    const markAllNotificationsRead = () => {
        const unreadNotifications = notificationData.map(item => {
            if (!item?.read) {
                return {
                    message_id: item?.message_id
                }
            }
        }).filter(x => x)
        if (unreadNotifications?.length > 0) {
            if (props.webSocketConnection) {
                props.webSocketConnection.json({
                    "action": "read_notification",
                    "messages": unreadNotifications
                })
            }
        }
       
        setIsNotificationPopOverOpen(false)
    }

    const openPopover = Boolean(anchorEl);

    const getTimeStamp = time => {
        const differenceDate = moment().diff(time, 'days')
        if (differenceDate === 0) {
            const messageTime = moment(time).fromNow();
            const messageTimeStamp = messageTime === "a few seconds ago" ? "Just Now" : messageTime
            return messageTimeStamp
        }
        if (differenceDate === 1) {
            return `Yesterday, ${moment(time).format("h:mm A")}`
        }
        return moment(time).format("MM/DD/YYYY, h:mm A")

    }

    const onMyAccountLinkClick = () =>{
        setAnchorEl(null)
        navigate('/my-account')
    }


    return (
        <AppBar
            position="fixed"
            elevation={0}
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
        >{props.showDataPortalLogo &&
            <div className={Classes.leftBCLogoContainer}>
                <img src={BCDataPortalLogo} alt="BRAINcommons DP logo" />
            </div>}
            <Toolbar className={Classes.TopNavRightContent}>
                <div className={Classes.TopNavRightContent}>
                    <ul>
                        <li id='cvb-topNavBar-aboutBCLink' className={Classes.WebSiteLink} onClick={() => onViewAboutLinkClick(ABOUT_BRAIN_COMMONS_LINK)}>About the BRAINCommons</li>
                        <li  className={`${Classes.NotificationIconBlock} ${Classes.CartIconBlock}`}>
                            <IconButton onClick={goToCart} className={Classes.notificationCartContainer} id='cvb-topNavBar-cartContaier'>
                                <img id='cvb-topNavBar-cartIcon' className={Classes.cartIconImage} src={cartIcon} alt="Cart" />
                                {filesCount > 0 && <sup className={`${Classes.NotificationAmount} ${Classes.filesCount}`}>{filesCount}</sup>}
                            </IconButton>
                        </li>
                        <li className={Classes.NotificationIconBlock}>
                            <IconButton className={Classes.notificationBellContainer} id='cvb-topNavBar-notificationItem'
                                        onClick={() => {
                                            setIsNotificationPopOverOpen(true);
                                            // acknowledgeNotification()
                                        }} >
                                <img id='cvb-topNavBar-notificationIcon' src={IconNotification} alt="Notification Icon" />
                                {notificationData.length > 0 && <sup className={Classes.NotificationAmount}>{notificationData.length}</sup>}
                            </IconButton>
                            <NotificationPopover
                                id={'notification-popover'}
                                open={isNotificationPopOverOpen}
                                anchorEl={isNotificationPopOverOpen}
                                onClose={() => setIsNotificationPopOverOpen(false)}
                                elevation={0}
                            ><div className={Classes.popoverContainer}>
                                    {<div className={Classes.popoverHeaderContainer}>
                                        <p className={Classes.notificationsHeading}>
                                            {notificationData?.filter(x => !x.read).length} Unread Notifications
                                            {
                                                notificationData?.length > 0 &&
                                                <span id='cvb-topNavBar-viewAllNotificationButton'
                                                    onClick={onViewAllNotificationsClick}>View All</span>
                                            }
                                        </p>
                                    </div>}
                                    <ul className={Classes.notificationContainer}>

                                        {
                                            map(notificationData, (item => {
                                                //Need to handle read and unread message style
                                                const messageListStyle = item?.read ? Classes.readMessage : Classes.unreadMessage;
                                                return <li className={messageListStyle} key={v4()}
                                                    id='cvb-topNavBar-notification-item'
                                                    onClick={() => navigateToNotificationLocation(item)}>
                                                    <h4>{item?.payload?.header || ""}</h4>
                                                    <p> {item?.payload?.body || ""} </p>
                                                    <p> {getTimeStamp(item?.created_timestamp)} </p>
                                                </li>
                                            }))
                                        }
                                    </ul>
                                    {

                                        (!notificationData || notificationData?.length == 0) &&
                                        <ul className={[Classes.notificationContainer, Classes.notificationContainerMessages].join(' ')}>
                                            <li id='cvb-topNavBar-notification-item'>
                                                <h4>No unread notifications right now </h4>
                                                <p>Please navigate to the notification page to view messages.</p>
                                                <button id='cvb-unStructuredData-mapButton'
                                                    onClick={onViewAllNotificationsClick}
                                                    className={`small-size-button transparent-button  ${Classes.tableActionButton}`}>
                                                    View all notifications
                                                </button>
                                            </li>
                                        </ul>

                                    }

                                    {
                                        notificationData?.find(x => !x.read) &&
                                        <div id='cvb-topNavBar-markAllReadButton' className={Classes.markAllReadDiv} onClick={markAllNotificationsRead}>
                                            <span>Mark All As Read</span>
                                        </div>
                                    }
                                </div>
                            </NotificationPopover>
                        </li>
                        <li className={Classes.IconProfilePicContent}>
                            <Tooltip id='cvb-topNavBar-userNameTooltip' title={userName}>
                                <span onClick={(e) => handleDownArrowClick(e)} id='cvb-topNavBar-userNameText' className={Classes.userName}>{userName}</span>
                            </Tooltip>
                            <span onClick={(e) => handleDownArrowClick(e)} className={Classes.IconProfilePic} ><img id='cvb-topNavBar-profilePic' src={props.proPic || ProfilePic} alt="Profile Pic" /></span>
                            <span id='cvb-topNavBar-optionsIconButton' className={Classes.IconProfileDownArrow} onClick={(e) => handleDownArrowClick(e)} ><img src={DownArrow} alt="Profile Pic" /></span>
                            <Popover
                                id={'profile-popover'}
                                open={openPopover}
                                anchorEl={anchorEl}
                                onClose={handleClosePopover}
                                elevation={0}
                            >
                                <ul className={Classes.dropdownListContainer}>
                                    <li id='cvb-topNavBar-myProfileLinkButton' onClick={onMyProfileLinkClick}><img alt='user profile icon' src={UserProfileIcon} className={Classes.linkIcons} />My Profile</li>
                                    <li id='cvb-topNavBar-myAccountLinkButton' onClick={onMyAccountLinkClick}><img alt='user profile icon' src={MyAccountIcon} className={Classes.linkIcons} />My Account</li>
                                    <li id='cvb-topNavBar-logoutLinkButton' onClick={onLogoutLinkClick}><img alt='logout icon' src={LogoutIcon} className={Classes.linkIcons} />Logout</li>
                                </ul>
                            </Popover>
                        </li>
                    </ul>
                </div>
            </Toolbar>
        </AppBar >
    )
}

export default TopNavBar;
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { useColors } from '../../../utils/hooks';
import { hexColorVariants } from '../../../utils/generatePalette';
import { alpha, Box, Divider, Paper, Stack, Typography } from '@mui/material';
import { vars } from '../../../assets/variables';
import { round } from 'lodash';
import { makeStyles } from '@mui/styles';
import { PieChartLoader } from '../../pages/project-gallery/skeletons/ChartLoaders';

const { subHeaderColor, borderColor, grayTextColor } = vars;

const INITIAL = 0;

const useStyles = makeStyles(() => ({
  divider: {
    borderColor: borderColor,
  },
  ScrollContainer: {
    height: '12.5rem',
    width: '9.375rem',
    marginLeft: 'auto',
    zIndex: -1,
  },
  container: {
    height: '100%',
    // height: '12.5rem',
    // justifyContent: 'center',
    // flexWrap: 'nowrap',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: grayTextColor,
    },
  },
}));

const COLOR_TYPE = 'hex';
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderLegend = (props) => {
  const { payload } = props;
  const styles = useStyles();
  return (
    <ScrollContainer
      className={styles.ScrollContainer}
      horizontal={false}
      vertical={true}
      // hideScrollbars
    >
      <Stack spacing={1} className={styles.container}>
        {payload &&
          payload.map((entry, index) => {
            const val = entry.value;
            if (!val || val?.toLowerCase() === 'total') return null;
            const addTopPadding = payload.length > 4 && index === INITIAL;

            return (
              <Stack
                sx={{
                  flexShrink: 0,
                  paddingTop: addTopPadding ? '1rem' : '',
                }}
                key={`${entry.value}-${index}`}
                direction="row"
                alignItems="baseline"
                spacing={0.5}
              >
                <Box
                  component="span"
                  sx={{
                    width: 8,
                    height: 8,
                    backgroundColor: entry.color,
                    borderRadius: '50%',
                    flexShrink: 0,
                  }}
                />
                <Typography
                  fontSize={14}
                  key={`item-${index}`}
                  lineclamp={2}
                  textOverflow="ellipsis"
                  textTransform="capitalize"
                >
                  {val &&
                    val.charAt(0).toUpperCase() +
                      val.slice(1).toLowerCase().split('_').join(' ')}
                </Typography>
              </Stack>
            );
          })}
      </Stack>
    </ScrollContainer>
  );
};

function getDataAggregateValue(data) {
  let aggregate;
  if (Array.isArray(data) && data.length > 0 && data[0].value) {
    aggregate = data.reduce((prev, cur) => {
      if (Number(cur.value)) {
        prev += cur.value;
      }

      return prev;
    }, 0);
  }

  return aggregate;
}

const SharedPieChart = ({
  data,
  nameKey,
  dataKey,
  xLabel,
  isDoughnut,
  hasLabel,
  loading = false,
}) => {
  const colors = useColors(COLOR_TYPE);
  const styles = useStyles();

  const aggregate = getDataAggregateValue(data);

  const renderPie = useMemo(() => {
    return (
      <Pie
        data={data}
        cx={100}
        cy={100}
        labelLine={false}
        label={!isDoughnut && hasLabel && renderCustomizedLabel}
        outerRadius={90}
        fill="#8884d8"
        dataKey={dataKey}
        nameKey={nameKey}
        paddingAngle={0}
        innerRadius={isDoughnut ? 46 : 0}
      >
        {data?.map((entry, index) => {
          return (
            <Cell
              key={`cell-${index}`}
              fill={alpha(
                colors.reverse()[index % colors.length],
                hexColorVariants[
                  Math.floor(index / colors.length) % hexColorVariants.length
                ]
              )}
            />
          );
        })}
      </Pie>
    );
  }, [data]);

  //Custom Tooltip popper
  const CustomTooltip = ({ active, payload }) => {
    if (!active) return null;
    const cell = payload[0];

    if (payload[0].name !== undefined) {
      return (
        <Paper
          elevation={1}
          sx={(theme) => ({
            paddingY: '0.5rem',
            paddingX: theme.spacing(1),
            border: 'none',
            maxWidth: '350px',
            color: subHeaderColor,
          })}
        >
          <Stack spacing={0.75}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <div
                style={{
                  backgroundColor: `${cell.payload.fill}`,
                  width: '0.75rem',
                  height: '0.75rem',
                  borderRadius: '0.25rem',
                }}
              />
              <Typography
                fontSize={12}
                fontWeight={500}
                lineHeight={1.125}
                textTransform="capitalize"
              >
                {`${cell.name}`}
              </Typography>
            </Stack>
            <Divider className={styles.divider} />
            {!!cell.value && (
              <Stack>
                <Typography
                  fontSize={12}
                  lineHeight={1.125}
                  textTransform="capitalize"
                >
                  {`#Subjects: ${round(cell.value)}`}
                </Typography>
                {!!aggregate && (
                  <Typography
                    fontSize={12}
                    lineHeight={1.125}
                    textTransform="capitalize"
                  >
                    {`Percentage: ${((cell.value / aggregate) * 100).toFixed(
                      2
                    )}%`}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        </Paper>
      );
    }

    return null;
  };

  return (
    <>
      {xLabel && (
        <Box maxHeight={59}>
          <Box p={1.5} mt="0 !important" mb={0}>
            <Typography
              component="span"
              fontSize={14}
              color={subHeaderColor}
              fontWeight={500}
            >
              {xLabel}
            </Typography>
          </Box>
          <Divider className={styles.divider} />
        </Box>
      )}
      {(loading && data?.length <= 0) || !data ? (
        <PieChartLoader />
      ) : Array.isArray(data) && data.length > 0 ? (
        <ResponsiveContainer width="100%" maxHeight={200}>
          <PieChart width={100} height={100} endAngle={0}>
            <Legend
              content={renderLegend}
              width={150}
              verticalAlign="middle"
              align="right"
            />
            <Tooltip content={<CustomTooltip data={data} />} />
            {renderPie}
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" pt={3}>
          No {xLabel}
        </Box>
      )}
    </>
  );
};

export default SharedPieChart;

SharedPieChart.propTypes = {
  data: PropTypes.array,
  nameKey: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  isDoughnut: PropTypes.bool,
  hasLabel: PropTypes.bool,
  loading: PropTypes.bool,
};

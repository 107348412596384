import ApolloClient from 'apollo-boost';
import {env} from '../../env'
import { BASE_URL } from '../../constants/google-login-urls';
import {
  STUDY_GALLERY_FILTERS,
  STUDY_GALLERY_DATA,
  EXPLORE_DATA_COUNT,
  STUDY_GALLERY_DETAILS,
  GET_PROJECT_COUNT,
  ACCESSIBLE_PROJECTS,
  GET_STUDY_GALLERY_PROJECT_DETAILS,
  GET_ASSESSMENTS_TYPES_BY_PROJECTS,
  GET_DATA_TYPES_BY_PROJECTS,
  GET_DISEASE_COUNT_BY_PROJECT,
  STUDY_GALLERY_DETAILS_DATA,
} from '../graphql-query-helpers/graphql';
import { formFilterData } from '../../utils/explore-data-plotly-helper';
import { formatStudyFiltersData } from '../../utils/formatStudyGalleryFiltersData';
import isEmpty from 'lodash/isEmpty';

import axios from 'axios';
import { getCognitoIdToken } from '../utilityMethods';
import { cloneDeep, map } from 'lodash';
import { pdTermsField } from '../graphql-query-helpers/graphql-variables';

const formStudyGalleryFiltersData = (data) => {
  const filters = {
    filter: {
      AND: [],
    },
  };
  if (isEmpty(data)) {
    return filters;
  }
  const dataKeys = Object.keys(data);

  for (let key of dataKeys) {
    if (Array.isArray(data[key])) {
      let minKey = 'min_' + key;
      let maxKey = 'max_' + key;
      filters.filter.AND.push(
        {
          gte: {
            [key]: data[key][0],
          },
        },
        {
          lte: {
            [key]: data[key][1],
          },
        }
      );
      // filters.filter.AND.push({
      //     "IN": { [key]: data[key] }
      // })
    } else {
      const innerKeys = Object.keys(data[key] || {});
      if (innerKeys.length > 0) {
        filters.filter.AND.push({
          IN: { [key]: innerKeys },
        });
      }
    }
  }

  return filters;
};

/**
 * Performs an in-place object transformation by recursively
 * renaming the old key to the new key.
 * @param obj
 * @param oldKey
 * @param newKey
 * @returns {*}
 */
const renameFilterObjectKey = (obj, oldKey, newKey) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (obj[oldKey] !== undefined) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  for (let prop in obj) {
    if (typeof obj[prop] === 'object' && obj[prop] !== null) {
      obj[prop] = renameFilterObjectKey(obj[prop], oldKey, newKey);
    } else if (Array.isArray(obj[prop])) {
      for (let i = 0; i < obj[prop].length; i++) {
        obj[prop][i] = renameFilterObjectKey(obj[prop][i], oldKey, newKey);
      }
    }
  }

  return obj;
}

export const getStudyGalleryFilters = async (filterValues) => {
  let queryVariables = formFilterData(filterValues);
  let queryVariablesFilter2 = cloneDeep(queryVariables);
  //queryVariablesFilter2.filter["AND"].push({"OR":[{"=":{"private_study":"No"}}]})
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/study`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: 'omit',
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });
  const response = await apolloClient.query({
    query: STUDY_GALLERY_FILTERS,
    variables: {
      filter: JSON.parse(JSON.stringify(queryVariables?.filter)) || { AND: [] },
      filter2: JSON.parse(JSON.stringify(queryVariablesFilter2?.filter)) || {
        AND: [],
      },
      nestedAggFields: { termsFields: ['project_id'] },
    },
  });
  let modifiedResponse = formatStudyFiltersData(response);
  return modifiedResponse || new Error('Unable to get study data filters');
};

export const getStudyDataApi = async (
  filterValues,
  pageOffset,
  recordsPerRequest
) => {
  let queryVariables = formStudyGalleryFiltersData(filterValues);
  queryVariables['offset'] = pageOffset;
  queryVariables['first'] = recordsPerRequest;
  let studyFilter = cloneDeep(queryVariables);
  //Ensure we use the correct diagnosis field for the cases filter
  let caseFilter = queryVariables?.filter
  renameFilterObjectKey(caseFilter, "diagnosis", "primary_diagnosis");

  const token = await getCognitoIdToken();

  const header = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  const body = {
    query: STUDY_GALLERY_DATA,
    variables: {
      queryVariables,
      filter: JSON.parse(JSON.stringify(caseFilter)) || { AND: [] },
      filter2: JSON.parse(JSON.stringify(studyFilter?.filter)) || {
        AND: [],
      },
      nestedAggFields: { termsFields: ['project_id'] },
    },
  };

  const response = await axios.post(
    `${BASE_URL}/data_access/search/guppy/aggregate/project_gallery`,
    body,
    { headers: header }
  );
  return response?.data || new Error('Unable to get study data');
};

export const getAccessibleProjects = async (
  filterValues,
  pageOffset,
  recordsPerRequest
) => {
  let queryVariables = formFilterData(filterValues);
  queryVariables['offset'] = pageOffset;
  queryVariables['first'] = recordsPerRequest;
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: 'omit',
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });
  const response = await apolloClient.query({
    query: ACCESSIBLE_PROJECTS,
    variables: {
      queryVariables,
      filter: JSON.parse(JSON.stringify(queryVariables?.filter)) || { AND: [] },
    },
  });
  return response?.data || new Error('Unable to get study data');
};

export const getStudyPerigreinApi = async (project_id) => {
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/pg`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: 'omit',
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });
  const response = await apolloClient.query({
    query: STUDY_GALLERY_DETAILS,
    variables: {
      name: project_id,
    },
  });
  return response?.data || new Error('Unable to get study data filters');
};

export const getManyStudyPerigreinApi = async (project_ids) => {
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/pg`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: 'omit',
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });
  const response = await apolloClient.query({
    query: STUDY_GALLERY_DETAILS_DATA,
    variables: {
      name: project_ids,
    },
  });
  return response?.data || new Error('Unable to get study data filters');
};

export const getStudyDetailsPerigreinApi = async (studyQuery) => {
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/pg`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: 'omit',
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });
  const response = await apolloClient.query({
    query: studyQuery,
  });
  return response?.data || new Error('Unable to get study data filters');
};

export const getTotalCount = async (filterValues) => {
  let queryVariables = formFilterData(filterValues);
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/study`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: 'omit',
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });
  const response = await apolloClient.query({
    query: EXPLORE_DATA_COUNT,
    variables: {
      queryVariables,
      filter: queryVariables?.filter || { AND: [] },
    },
  });
  return response?.data || new Error('Unable to get explore data total count');
};

export const getStudyGalleryItemDetails = async (projectIds) => {
  const BASE_URL_API = `${env.REACT_APP_BASE_API_PATH}`;
  if (projectIds) {
    const endPOINT =
      BASE_URL_API +
      '/study_gallery/get_all_study_gallery?projects=' +
      projectIds;
    const token = await getCognitoIdToken();
    const header = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    const response = await axios.get(endPOINT, { headers: header });
    return response?.data || null;
  }
  return [];
};
export const getProjectCount = async () => {
  // const queryVariables = {
  //     "nestedAggFields": { "termsFields": ["project_id", "cases", "data_format", "visits", "availability_type", "diagnosis"] },
  //     "nestedAggFields2": { "termsFields": ["project_id"] }
  // }
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate`,
    // uri: `${BASE_URL}/data_access/search/guppy/aggregate/disease_distribution_by_project`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: 'omit',
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });
  const response = await apolloClient.query({ query: GET_PROJECT_COUNT });
  return (
    response?.data?._aggregation?.case?.project_id?.histogram ||
    new Error('Unable to get explore data')
  );
};

export const getAssessmentsByProjects = async (filterValues) => {
  let queryVariables = formStudyGalleryFiltersData(filterValues);
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate/project_gallery`,
    // uri: `${BASE_URL}/data_access/search/guppy/aggregate/assessments_by_project`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: 'omit',
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });
  const response = await apolloClient.query({
    query: GET_ASSESSMENTS_TYPES_BY_PROJECTS,
    variables: {
      queryVariables,
      filter: JSON.parse(JSON.stringify(queryVariables?.filter)) || { AND: [] },
    },
  });
  return response?.data || new Error('Unable to get study data');
};

export const getDataTypesByProjects = async (filterValues) => {
  let queryVariables = formStudyGalleryFiltersData(filterValues);
  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate/project_gallery`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: 'omit',
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });
  const response = await apolloClient.query({
    query: GET_DATA_TYPES_BY_PROJECTS,
    variables: {
      queryVariables,
      filter: JSON.parse(JSON.stringify(queryVariables?.filter)) || { AND: [] },
    },
  });
  return response?.data || new Error('Unable to get study data');
};

export const getDiseaseByProjects = async (filterValues) => {
  let queryVariables = formStudyGalleryFiltersData(filterValues);
  //Adjust filter fields to match query schema.
  renameFilterObjectKey(queryVariables, "diagnosis", "primary_diagnosis");

  const token = await getCognitoIdToken();
  const apolloClient = new ApolloClient({
    uri: `${BASE_URL}/data_access/search/guppy/aggregate/project_gallery`,
    request: (operation) => {
      operation.setContext({
        fetchOptions: {
          credentials: 'omit',
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });
  const response = await apolloClient.query({
    query: GET_DISEASE_COUNT_BY_PROJECT,
    variables: {
      filter: JSON.parse(JSON.stringify(queryVariables?.filter)) || { AND: [] },
    },
  });
  return response?.data || new Error('Unable to get study data');
};

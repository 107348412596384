/**
 *  External Imports
 */
 import {connect} from "react-redux";

/**
 *  Imports
 */
import CommunityConnectionsPending from "./CommunityConnectionsPending";
import {initPendingUsersAction, loadMorePendingUsersAction} from "../../../redux/actions/communityActions";

const mapStateToProps = ({community: {pendingUsers}}) => ({
	pendingUsers,
});

const mapDispatchToProps = (dispatch) => ({
	initUsers: (pendingUsers) => dispatch(initPendingUsersAction(pendingUsers)),
	loadMoreUsers: (loadedUsers) => dispatch(loadMorePendingUsersAction(loadedUsers)),
});

/**
 *  Exports
 */
 export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CommunityConnectionsPending);

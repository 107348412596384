import React from 'react';
import { renderIcon } from './connected/statistics/icons';
import { orderBy } from 'lodash';
import { getMinMax } from '../explore-cohorts/utils';

const defaultConjunctions = [ "the", "and", "an", "as", "to", "is", "on", "at", "by", "for", "until", "each", "but", "if"]

const LABELS = {
  attributes: "Data Properties",
}

export function generateStatisticsFromValues({
  clinicalDataPoints,
  participants,
  attributes,
  projects,
}) {
  const stats = { projects, participants, attributes, /*clinicalDataPoints*/ };

  return Object.entries(stats).reduce((prev, [key, value]) => {
    return (prev = {
      ...prev,
      [key]: {
        name: LABELS[key] ?? key.split(/(?=[A-Z])/).join(' '),
        count: value,
        icon: renderIcon(key),
      },
    });
  }, {});
}

export function renameKeys(obj, newKeys) {
  const entries = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;

    return { [newKey]: obj[key] };
  });

  return Object.assign({}, ...entries);
}

export function flattenFilters(filters, filterValues, previousFilterValue) {
  if (!!filterValues && !!previousFilterValue) {
    const currentFilters = filters || {};
    let previousExploreFilters = { ...previousFilterValue };

    const filterKeys = Object.keys(previousExploreFilters);
    for (let item of filterKeys) {
      if (previousExploreFilters[item]?.histogram) {
        for (let eachHistogram of previousExploreFilters[item].histogram) {
          const matchedItem = currentFilters?.[item]?.histogram?.find(
            (x) => x.key === eachHistogram.key
          );
          eachHistogram.count =
            matchedItem?.termsFields?.[0]?.terms?.length || 0;
        }
      }
    }
    return previousExploreFilters;
  } else {
    if (filters?.zone?.histogram?.length) {
      filters.zone.histogram = orderBy(
        filters.zone.histogram,
        ['key'],
        ['asc']
      );
    }
    if (filters?.data_type?.histogram?.length) {
      filters.data_type.histogram = orderBy(
        filters.data_type.histogram,
        [(type) => type.key.toLowerCase()],
        ['asc']
      );
    }
    if (filters?.diagnosis?.histogram?.length) {
      filters.diagnosis.histogram = orderBy(
        filters.diagnosis.histogram,
        [(diagnosis) => diagnosis.key.toLowerCase()],
        ['asc']
      );
    }
    return filters;
  }
}

export function getInitialFilters(filters) {
  // const newCohorts = [...cohorts];
  let obj = {};

  for (const key in filters) {
    const prevData = obj[key] ? obj[key.toString()] : {};
    const newHistogram = Array.isArray(filters[key].histogram)
      ? filters[key].histogram
      : [];

    const newObj = newHistogram.reduce((prev, cur) => {
      if (cur.min !== undefined) {
        return { min: cur.min, max: cur.max };
      }

      // default ni checkbox is selected
      return { ...prev, [cur.key]: false };
    }, {});

    //remove duplicates from histogram values
    const filter =
      Array.isArray(newHistogram) &&
      newHistogram.length > 0 &&
      newHistogram[0].key
        ? { ...prevData, ...newObj }
        : getMinMax(prevData, newObj);

    if (key !== '__typename') {
      obj = {
        ...obj,
        [key]: {
          ...filter,
        },
      };
    }
  }

  // add cohort name to filters object
  obj = {
    ...obj,
  };

  return obj;
}

export function formatFilterToServiceFilters(data) {
  let filter = {};

  for (const key in data) {
    if (data[key].min !== undefined) {
      if (
        data[key].min !== data[key].range.min ||
        data[key].max !== data[key].range.max
      ) {
        filter[key] = [data[key].range.min, data[key].range.max];
      }
    } else {
      const valueMap = Object.entries(data[key]);
      const filtered = valueMap.filter(([key, value]) => value);
      filter[key] = Object.fromEntries(filtered);
    }
  }

  return filter;
}

/**
 * @param  {string!} str
 * @param  {array of exception strings} exceptions=[...defaultConjunctions]
 */
export function titleCase(str, exceptions = defaultConjunctions) {
  str = str.toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    if (Array.isArray(exceptions) && exceptions.includes(str[i])) {
      str[i] = str[i].toLowerCase() 
    } else {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
    }
  }
  return str.join(' ');
}
import React from 'react';
import { styled } from '@mui/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    padding: '0.375rem 0',
    '& .MuiTypography-root': {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1.25rem',
      letterSpacing: '0.0028em',
    },
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingX: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

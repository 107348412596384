import axios from 'axios'
import {env} from '../env'
import get from 'lodash/get'
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk'
import { map } from 'lodash';
import find from 'lodash/find'
import startsWith from 'lodash/startsWith'
export const uploadFileToS3 = async (file, presignedPostData, config) => {
    try {
        const formData = new FormData();
        Object.keys(presignedPostData.fields).forEach(key => {
            formData.append(key, presignedPostData.fields[key]);
        });

        // Actual file has to be appended last.
        formData.append("file", file);
        const header = {
            'Content-Type': 'multipart/form-data',

        };
        const res = await axios.post(presignedPostData.url, formData, { headers: header, ...config });

        return get(res, 'status', '');
    } catch (error) {
        console.log('upload failed', error);
    }
};



const USER_POOL_ID = env.REACT_APP_USER_POOL_ID;
const CLIENT_ID = env.REACT_APP_CLIENT_ID;
const IDENTITY_POOL_ID = env.REACT_APP_IDENTITY_POOL_ID;

export const getAWSConfigObject = async (workspaceId) => {
    let data = {
        UserPoolId: USER_POOL_ID,
        ClientId: CLIENT_ID,
    };
    let awsCredentialConfig = {};
    awsCredentialConfig = await Auth.currentSession().then(x => {
        const congnitoRoles = x?.idToken?.payload?.['cognito:roles'];
        const currentRole = find(congnitoRoles, role => {
            const roleValue = role.split('/')[1];
            return startsWith(roleValue, workspaceId);
        });
        const logins = {}
        AWS.config.region = 'us-east-1';
        logins[`cognito-idp.${AWS.config.region}.amazonaws.com/${data.UserPoolId}`] = x.idToken.jwtToken;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: IDENTITY_POOL_ID,
            CustomRoleArn: currentRole,
            Logins: logins
        });
        return AWS.config.credentials;
    }
    )
    return awsCredentialConfig 
}
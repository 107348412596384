import axios from 'axios';
import {env} from '../env'
const BASE_URL = `${env.REACT_APP_BASE_API_PATH}`
const GET_COUNTRY_CODE_API = 'https://restcountries.eu/rest/v2/all?fields=name;callingCodes'
const GA_MEASUREMENT_ID = `${env.REACT_APP_GA_MEASUREMENT_ID}`

export const getNewsArticles = async() =>{
    const response = await axios.get(`${BASE_URL}/articles`);
    return response?.data?.news_articles || []
}

export const signUpStatus = async () => {
    const response = await axios.get(`${BASE_URL}/user/self_sign_up_enabled_status`);
    return response?.data;
}

export const getTutorials = async() =>{
    const response = await axios.get(`${BASE_URL}/tutorials`);
    return response?.data?.tutorials || []
}

export const getCountriesAndCountryCode = async() => {
    const response = await axios.get(GET_COUNTRY_CODE_API);
    return response?.data;
}

export const getDomainNameForCookies = () =>{
    let domainRegEx = /:\/\/(.[^/]+)/;
    const domainName = window.location.origin.match(domainRegEx)[1];
    return domainName;
}
function gtag() {  window.dataLayer && window.dataLayer.push(arguments); }
export const addGoogleAnalytics = async () => {
    window[`ga-disable-${GA_MEASUREMENT_ID}`] = false;
    const domainName = getDomainNameForCookies();
    window.dataLayer = window.dataLayer || [];
    await gtag('js', new Date());
    await gtag('config', GA_MEASUREMENT_ID, {
        cookie_domain: domainName
    });
}

export const setCookie = (cname, cvalue, exdays)=>{
    const domainName = getDomainNameForCookies();
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = cname + "=" + cvalue + ";" + expires + `;path=/;domain=${domainName}`;
}
export const deleteCookie = (cname, cvalue, exdays)=>{
    const domainName = getDomainNameForCookies();
    document.cookie = cname + "=" + cvalue + ";" + 'expires='+exdays+ `;max-age=-1;path=/;domain=${domainName};`;
}

export const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const sendGAEvents = (eventName = '', eventCategory = '', eventLabel = '', extraParameters = {}) => {
    let user = getCookie("cvb_user");
    if (user === "true") {
        gtag('event', eventName, {
            'event_category': eventCategory,
            'event_label': eventLabel,
            ...extraParameters
        })
    }
}

export const updateGAEventsPageTitleOnPageChange = (pageName) =>{
    gtag('set', {
        'page_title': pageName,
      });
}

export const sendPagedViewedForHelpScout = (pageTitle) => {
    window.Beacon('event', {
        type: 'page-viewed',
        url: document.location.href,
        title: pageTitle,
    })
    window.Beacon('suggest')
}
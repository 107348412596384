import React from 'react';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { string, node } from 'prop-types';
import { vars } from '../../../../../assets/variables';
import { titleCase } from '../../utils';

const {
  borderColor,
  primaryColor,
  boxShadowColor,
  outlinedInputBorderColor,
  inputLabelColor,
  primaryTextColor,
} = vars;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 420,
      padding: 0,
      borderRadius: '0.75rem',
    },
  },
};

export const CustomAutocomplete = withStyles({
  root: {
    minHeight: '2.8125rem',
    height: 'max-content !important',
    position: 'sticky',
    maxWidth: '10.5rem',
    width: '7.5rem',
    zIndex: '1010 !important',
    '& .MuiInputLabel-filled': {
      fontSize: '0.875rem',
      textTransform: 'capitalize',
      color: inputLabelColor,
    },
    '& .MuiInputLabel-filled.Mui-focused': {
      color: primaryTextColor,
    },
    '& .MuiInputBase-root': {
      border: `1px solid ${outlinedInputBorderColor}`,
      background: 'white',
    },
    '& .MuiInputBase-root:hover': {
      content: 'none',
      borderColor: primaryColor,
    },
    '& .MuiInputBase-root:focused': {
      content: 'none',
      borderColor: primaryColor,
      boxShadow: `0 0 0 0.0625rem ${boxShadowColor}`,
    },
    '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
      borderBottom: 'none',
      content: 'none',
    },
  },
  focused: {
    '& .MuiOutlinedInput-root': {
      color: 'red',
      position: 'absolute',
      zIndex: 1010,
    },
  },
  inputRoot: {
    minHeight: '2.8125rem',
    height: 'max-content !important',
    position: 'absolute',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
  },
  tag: {
    height: 20,
    maxWidth: '150px !important',
    position: 'relative',
    zIndex: 0,
    borderRadius: '4px',
    backgroundColor: boxShadowColor,
    fontSize: '0.875rem',
    padding: '0rem 0.125rem',

    '& .MuiChip-label': {
      color: primaryColor,
    },
    '& .MuiChip-deleteIcon': {
      color: primaryColor,
      width: '1.0825rem',
      height: '1.0825rem',
      zIndex: 3,
    },
    '&:after': {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: 'absolute',
      zIndex: 1,
    },
  },
  endAdornment: {
    '& .makeStyles-wrapSecondary-89  .MuiIconButton-root': {
      borderWidth: '0',
    },
    '& .MuiAutocomplete-clearIndicator': {
      border: 'none',
      background: 'none',
      padding: '0.0125rem 0.0125rem !important',
    },
    '& .MuiAutocomplete-popupIndicator': {
      border: 'none',
      background: 'none',
      height: 'max-content',
      '& svg': {
        width: '1rem',
        height: '1rem',
      },
    },
  },
  paper: {
    width: '15.625rem',
    minWidth: '15.625rem',
  },
})(Autocomplete);

const useStyles = makeStyles((theme) => ({
  root: {
    height: '2.938rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& .MuiSelect-filled': {
      paddingTop: 'none !important',
      background: 'white',
      '& .MuiFilledInput-root': {
        background: 'white',
      },
    },
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
    '& .MuiInputBase-root': {
      height: '100%',
      borderRadius: theme.spacing(1),
      '& .css-1gzkxga-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input':
        {
          borderRadius: theme.spacing(1),
          boxShadow:
            ' 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04)',
          paddingTop: '0.375rem',
          paddingBottom: '0.375rem',
          paddingLeft: '0.2rem',
        },
    },
    '& .MuiInputBase-root:hover': {
      content: 'none',
    },
    '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
      borderBottom: 'none',
      content: 'none',
    },
    '& .MuiInputLabel-outlined': {
      top: -3.5,
      fontSize: '0.875rem',
      whiteSpace: 'nowrap',
      maxWidth: '75%',
      color: inputLabelColor,
    },
    '& .MuiInputLabel-shrink': {
      top: 22,
      fontSize: '1.125rem',
      whiteSpace: 'nowrap',
      maxWidth: '98%',
      color: primaryTextColor,
    },
  },
  filter: {
    padding: '1.5rem 2.5rem',
    borderBottom: `1px solid ${borderColor}`,
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
}));

export const FilterSelect = ({ labelId, id, label, children, ...props }) => {
  const classes = useStyles();

  return (
    <FormControl classes={{ root: classes.root }}>
      <InputLabel id={labelId} classes={classes.label}>
        {label && titleCase(label)}
      </InputLabel>
      <Select
        size="small"
        label={label && titleCase(label)}
        labelId={labelId}
        input={<OutlinedInput classes={{ root: classes.root }} />}
        IconComponent={(props) => (
          <ExpandMoreRoundedIcon {...props} fontSize="inherit" />
        )}
        MenuProps={MenuProps}
        {...props}
      >
        {children}
      </Select>
    </FormControl>
  );
};

FilterSelect.propTypes = {
  id: string,
  labelId: string,
  label: string.isRequired,
  children: node,
};

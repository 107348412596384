import { SET_USER_FIRST_NAME, SET_USER_LAST_NAME, SET_USER_PASSWORD, SET_EMAIL, SET_ORG_NAME, 
    SET_CONFIRM_PASSWORD } from '../action-types'

export const onFirstNameChange = (value) => {
    return {
        type: SET_USER_FIRST_NAME,
        payload: value
    }
}

export const onLastNameChange = value =>{
    return {
        type: SET_USER_LAST_NAME,
        payload: value
    }
}

export const onPasswordChange = value =>{
    return {
        type: SET_USER_PASSWORD,
        payload: value
    }
}

export const onEmailChange = value =>{
    return {
        type: SET_EMAIL,
        payload: value
    }
}

export const onOrgNameChange = value =>{
    return {
        type: SET_ORG_NAME,
        payload: value
    }
}

export const onConfirmPassChange = value => {
    return {
        type: SET_CONFIRM_PASSWORD,
        payload: value
    }
}
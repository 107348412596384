/**
 *  External Imports
 */
import {connect} from "react-redux";

/**
 *  Internal Imports
 */
import {
	changeLeftMenuActiveItemAction,
	changeLeftMenuItemCountersAction,
	clickLeftMenuItemAction,
	toggleLeftMenuItemAction,
} from "../../../redux/actions/communityActions";

import CommunityLeftMenu from "./CommunityLeftMenu";

/**
 *  Functions
 */
const mapStateToProps = ({community: {leftMenuItems}}) => ({
	leftMenuItems,
});

const mapDispatchToProps = (dispatch) => ({
	onChangeActiveItem: (uniqKey) => dispatch(changeLeftMenuActiveItemAction(uniqKey)),
	onChangeItemCounters: (counters) => dispatch(changeLeftMenuItemCountersAction(counters)),
	onClickItem: (uniqKey, fn) => dispatch(clickLeftMenuItemAction(uniqKey, fn)),
	onToggleItem: (uniqKey) => dispatch(toggleLeftMenuItemAction(uniqKey)),
});

/**
 *  Exports
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CommunityLeftMenu);


import axios from 'axios'
import {env} from '../env'
import { getCognitoIdToken } from './utilityMethods';
import { BASE_URL } from '../constants/google-login-urls'

export const getRecentUpdates = async () => {
    const header = {
        'Content-Type': 'application/json'
    };
    const url = `${env.REACT_APP_DATA_MODEL_VERSION_URL}`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

import axios from 'axios'
import {env} from '../../env'
import get from 'lodash/get'
import { getCognitoIdToken } from '../utilityMethods';
import { BASE_URL } from '../../constants/google-login-urls';
import { UPDATE_DATA_USE_POLICY } from '../endpoints'

const APP_BASE_URL = `${env.REACT_APP_BASE_API_PATH}`

export const getAdvanceComputeRequests = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${APP_BASE_URL}/workspace/get_pending_approval_workspace_requests`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const getCommunityKeywords = async (status) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/social/community/keywords?status=${status}`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const approveCommunityKeywords = async (body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/social/community/keywords`;
    const res = await axios.post(url, body, { headers: header });
    return res?.data || {};
}

export const getPresignedURLForServiceAgreement = async (userSub, requestId) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${APP_BASE_URL}/workspace/manual_uploaded_service_agreement/upload_url/${userSub}/${requestId}`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const getDownloadURLForServiceAgreement = async (userSub, requestId, fileName) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${APP_BASE_URL}/workspace/manual_uploaded_service_agreement/download_url/${userSub}/${requestId}/${fileName}`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const getZone2Requests = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${APP_BASE_URL}/dataset/all/zone2`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const getZone3Requests = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${APP_BASE_URL}/dataset/pending_request/zone3`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const getProjectFileMappings = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/index/index/project_file_mapping`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const getAllUnapprovedDCAFormsData = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${APP_BASE_URL}/data_contributor/get_all_pending_dca`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const getAllUnapprovedDIFFormsData = async () => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${APP_BASE_URL}/data_contributor/all_data_inventory_forms`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const approveWorkspaceAccessRequest = async (body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${APP_BASE_URL}/workspace/approve-workspace-access-request`;
    const res = await axios.put(url, body, { headers: header });
    return res?.data || {};
}

export const approveZone2AccessRequest = async (body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${APP_BASE_URL}/dataset/approve/zone2`;
    const res = await axios.put(url, body, { headers: header });
    return res?.data || {};
}
export const approveZone3AccessRequest = async (body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${APP_BASE_URL}/dataset/approve/zone3`;
    const res = await axios.put(url, body, { headers: header });
    return res?.data || {};
}

export const uploadFileForZone2StatusUpdate = async (file, id, sub, actionId= '') => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    let fileName = actionId === 'PENDING_ADMIN_APPROVAL' ? "data_use_policy_approved_by_institution.pdf" : `${file.name}`;
    const controller = (id === "APPROVED" || id === "REJECTED") ? 'data_access_committee_file' : 'signed_ao_certificate_file'
    const res = await axios.get(`${APP_BASE_URL}/dataset/upload_url/${controller}/${sub}/${fileName}`, { headers: header });
    return res?.data
}

export const updateZone2Status = async (body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${UPDATE_DATA_USE_POLICY}change/zone2`;
    const res = await axios.put(url, body, { headers: header });
    return res?.data || {};
}

export const getAllPendingApprovalWorkspaceCarts = async () => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${BASE_URL}/cost/workspace_cart/pending_for_approval`;
        const res = await axios.get(url, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const approveWorkspaceCart = async (orderId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${BASE_URL}/cost/workspace_cart/${orderId}/approve`;
        const res = await axios.put(url, {}, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const rejectWorkspaceCart = async (orderId) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${BASE_URL}/cost/workspace_cart/${orderId}/reject`;
        const res = await axios.put(url, {}, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const getDataAccessCommitteeFileDownloadUrl = async (user_sub) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${APP_BASE_URL}/dataset/download_url/data_access_committee_file/${user_sub}`;
        const res = await axios.get(url, { headers: header });
        return get(res, 'data', '');
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const rejectWorkspaceAccessRequest = async (body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${APP_BASE_URL}/workspace/reject-workspace-access-request`;
    const res = await axios.put(url, body, { headers: header });
    return res?.data || {};
}
export const updateAdvanceComputeAsPaid = async (userSub, requestId) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${APP_BASE_URL}/workspace/mark/paid/${userSub}/${requestId}`;
    const res = await axios.put(url, null, { headers: header });
    return res?.data || {};
}

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Box,
  DialogContentText,
  Slider,
  Hidden,
} from '@mui/material';
import { CloseOutlined, Search } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useMemo } from 'react';
import { debounce } from 'lodash';
import CohortFilterItem from './CohortFilterItem';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { ceilFilterValues, floorFilterValues, formatLargeNumbers, getDefaultSliderValue } from './utils';

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 0,
    },
  },
  dialogText: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
}));

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: 'rgba(77, 128, 198, 1)',
  height: 2,
  width: 352,
  padding: '15px 0px',
  '& .MuiSlider-valueLabel': {
    fontSize: 14,
    fontWeight: 'normal',
    top: 36,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'unset',
    color: 'rgba(77, 128, 198, 1)',
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: 'rgba(77, 128, 198, 1)',
    },
  },
}));

const minDistance = 10;

const CohortsFilterDialog = ({
  open,
  onClose,
  filters,
  title,
  filterKey,
  handleFilterChange,
  handleReset,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [sliderValues, setSliderValues] = React.useState(
    () => getDefaultSliderValue(filters) ?? [20, 70]
  );

  const filterArr = filters ? Object.entries(filters) : [];

  //debounce search term
  const debounceFn = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 800),
    [searchTerm]
  );

  const debounceSliderValues = useCallback(
    debounce((value) => {
      handleFilterChange(filterKey, '_', value);
    }, 800),
    [filterKey]
  );

  function onSearch(event) {
    setValue(event.target.value);
    debounceFn(event.target.value);
  }

  const filteredCohortsFilters = useMemo(() => {
    //filter enabled filters by search term
    const filtered =
      Array.isArray(filterArr) &&
      filterArr.length > 1 &&
      filterArr?.filter(([filterKey, value]) =>
        filterKey.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
    return !searchTerm ? filterArr : filtered;
  }, [filters, filterArr, searchTerm]);

  const handleSliderChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setSliderValues([clamped, clamped + minDistance]);
        debounceSliderValues([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setSliderValues([clamped - minDistance, clamped]);
        debounceSliderValues([clamped - minDistance, clamped]);
      }
    } else {
      setSliderValues(newValue);
      debounceSliderValues(newValue);
    }
  };


  useEffect(() => {
    if (filters && filters?.min !== undefined) {
      setSliderValues([
        floorFilterValues(filters?.range?.min),
        ceilFilterValues(filters?.range?.max)
      ]);
    }
  }, [filters]);

  const renderFilters = useMemo(() => {
    if (filters && filters?.min !== undefined && filters?.max !== undefined) {
      return (
        <Box paddingX={2} paddingY={3}>
          <CustomSlider
            getAriaLabel={() => 'Minimum distance shift'}
            value={sliderValues}
            valueLabelDisplay="on"
            onChange={handleSliderChange}
            valueLabelFormat={(value) => formatLargeNumbers(value)}
            disableSwap
            min={filters?.min ? floorFilterValues(filters?.min) : 0}
            max={filters?.max ? ceilFilterValues(filters?.max) : 100}
            step={1}
          />
        </Box>
      );
    }

    if (filters && filterArr) {
      return (
        <>
          <Box paddingX={2} paddingY={1}>
            <TextField
              placeholder="Search for Metadata"
              value={value}
              variant="outlined"
              onChange={(e) => onSearch(e)}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>

          <Box>
            {filteredCohortsFilters && filteredCohortsFilters.length > 0 ? (
              filteredCohortsFilters.map(([attrKey, value], index) => (
                <CohortFilterItem
                  key={attrKey}
                  filter={{ key: attrKey, enabled: value }}
                  handleChange={() => {
                    handleFilterChange(filterKey, attrKey);
                  }}
                />
              ))
            ) : (
              <DialogContentText
                className={classes.dialogText}
              >{`No cohort filters   ${
                !searchTerm ? '' : `starts with ${searchTerm}`
              }`}</DialogContentText>
            )}
          </Box>
        </>
      );
    }

    return (
      <Box paddingX={2} paddingY={1}>
        <Typography>No Filter data</Typography>
      </Box>
    );
  }, [
    filters,
    filterArr,
    sliderValues,
    value,
    filteredCohortsFilters,
    classes,
    searchTerm,
    onSearch,
    handleFilterChange,
  ]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="xs"
      className={classes.dialog}
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        sx={{
          paddingTop: '1.5rem',
          paddingX: '1rem',
          fontWeight: 500,
        }}
      >
        <Typography
          noWrap
          sx={{ maxWidth: '80%' }}
          textTransform="capitalize"
          lineHeight={1}
        >
          {title}
        </Typography>
        <IconButton
          onClick={() => {
            if (onClose) onClose();
            setValue('');
            debounceFn('');
          }}
        >
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>{renderFilters}</DialogContent>
      <DialogActions
        sx={{
          display: 'block',
          padding: '1rem',
          marginTop: 0,
          minWidth: '100%',
          border: `1px solid rgba(208, 213, 221, 1)`,
          boxShadow: ` 0px -4px 8px 0px rgba(16, 24, 40, 0.1)`,
        }}
      >
        <Button
          onClick={async () => {
            // reset cohorts filters
            await handleReset(filterKey);
          }}
          color="primary"
          className="disable-shadow"
          variant="outlined"
          fullWidth
          sx={{ minWidth: '100%' }}
        >
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CohortsFilterDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  filters: PropTypes.object,
  filterKey: PropTypes.string,
  updateCohortFilers: PropTypes.func,
};

export default CohortsFilterDialog;

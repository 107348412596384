import React, { useState, useEffect } from 'react';
import { COMMUNITY_URLS } from "../../../constants/urls";
import Classes from './index.module.css'
import Modal from '../../commons/modal'
import ImgFace1 from '../../../assets/images/home-animate/brain_data.png'
import ImgFace2 from '../../../assets/images/home-animate/brain_comm.png'
import ImgFace3 from '../../../assets/images/home-animate/brain_tools.png'
import ImgCommLayer1 from '../../../assets/images/home-animate/Community/comm-layer-1.svg'
import ImgCommLayer2 from '../../../assets/images/home-animate/Community/comm-layer-2.svg'
import ImgCommLayer3 from '../../../assets/images/home-animate/Community/comm-layer-3.svg'
import ImgDataLayer1 from '../../../assets/images/home-animate/Data/data-layer-1.svg'
import ImgDataLayer2 from '../../../assets/images/home-animate/Data/data-layer-2.svg'
import ImgDataLayer3 from '../../../assets/images/home-animate/Data/data-layer-3.svg'
import ImgToolsLayer1 from '../../../assets/images/home-animate/Tools/tools-layer-1.svg'
import ImgToolsLayer2 from '../../../assets/images/home-animate/Tools/tools-layer-2.svg'
import ImgToolsLayer3 from '../../../assets/images/home-animate/Tools/tools-layer-3.svg'
import ImgCommBack from '../../../assets/images/home-animate/Community/comm-back-img.svg'
import ImgDataBack from '../../../assets/images/home-animate/Data/data-back-img.png'
import ImgToolsBack from '../../../assets/images/home-animate/Tools/tools-back-image.svg'
import InfoIconModal from '../../../assets/icons/InfoIconModal.svg'
import ImgTools from '../../../assets/images/home-animate/tools_ic.svg'
import ImgData from '../../../assets/images/home-animate/data-ic.svg'
import ImgCommunity from '../../../assets/images/home-animate/community_ic.svg'
import ActiveTabGlow from '../../../assets/images/home-animate/Data/bc-home-header-bg.png'
import clsx from 'clsx';
import { navigate } from '@reach/router';
import Grid from "@mui/material/Grid";

import dataIco from '../../../assets/icons/data-ic.svg';
import toolsIco from '../../../assets/icons/virous-ic.svg';
import CommunityIco from '../../../assets/icons/Community_ic.svg';
import useScrollToTop from '../../commons/scroll-top';
import CookieBanner from '../../commons/cookie-banner';
import { Auth } from 'aws-amplify'
import v4 from 'uuid/v4'

export default function Home(props) {
    // Code for the modal
    const [showModal, setShowModal] = useState(false);
    const [showSignDataUsageModal, setShowSignDataUsageModal] = useState(false)


    const [signDataUsagePolicy, setSignDataUsagePolicy] = useState(false)

    useScrollToTop();

    


    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const modalPayload = {
        modalTitle: "Terms of use",
        modalContent: "main content of the modal",
        positiveButtonText: "Accept",
        negativeButtonText: "Decline",
        positiveButtonAction: toggleModal,
        negativeButtonAction: toggleModal
    }

    const signDataUsageModalPayload = {
        modalTitle: "",
        modalContent: "",
        negativeButtonText: "I'll do it later",
        positiveButtonText: "Review Data Use Agreement",
        negativeButtonAction: () => { setShowSignDataUsageModal(false); navigate('/') },
        positiveButtonAction: () => { setShowSignDataUsageModal(false); navigate("/my-profile/request-access") }
    }


    const [selectedButtonIndex, setSelectedButtonIndex] = useState(0)


    const buttonOptions = [
        {
            "caption": "Data",
            "imageSource": dataIco,
            "defaultClass": Classes.dataButton,
            "tagline": "Multi-modal datasets integrate-able at all spatial scales",
            "link": "explore/data/graphical-data"
        },
        {
            "caption": "Tools",
            "imageSource": toolsIco,
            "defaultClass": Classes.toolsButton,
            "tagline": "Intuitive visualization  tools coupled with advanced computational modeling",
            "link": "explore/tools"
        },
        {
            "caption": "Community",
            "imageSource": CommunityIco,
            "defaultClass": Classes.communityButton,
            "tagline": "A solution designed to encourage collaboration outside pre-existing research circles",
            "link": COMMUNITY_URLS.COMMUNITY
        },
    ]

    const redirectLink = link => {
        navigate(link)
    }

    const renderButton = (item, index) => {
        return (
            <div
                className={clsx(item.defaultClass, { [Classes.selectedButton]: selectedButtonIndex === index })}
                onClick={() => { setSelectedButtonIndex(index) }}>
                <div className={Classes.blockRow}>
                    <div className={Classes.blockColumnImage}>
                        <img src={item.imageSource} alt="icon" className={Classes.buttonIcon} />
                    </div>
                    <div className={Classes.blockColumnContent}>
                        <h2>{item.caption}</h2>
                        <p className={Classes.taglineText}>{item.tagline}</p>
                        {
                            selectedButtonIndex === index &&
                            <button className={Classes.actionBtnSmall}
                                onClick={() => { redirectLink(item.link) }}>View</button>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const RenderDataLayers = () => {
        const dataLayerClassName = selectedButtonIndex === 0 ? Classes.selectedLayer : Classes.nonSelectedLayer;
        const backgroundImageCN = selectedButtonIndex === 0 ? Classes.selectedBackground : Classes.nonSelectedBackground;
        return (
            <>
                <img id='cvb-home-dataLayer-backImage' className={backgroundImageCN} src={ImgDataBack} alt="background" />
                <img id='cvb-home-dataLayer-layer1Image' className={dataLayerClassName} src={ImgDataLayer1} alt="layer-1" />
                <img id='cvb-home-dataLayer-layer2Image' className={dataLayerClassName} src={ImgDataLayer2} alt="layer-2" />
                <img id='cvb-home-dataLayer-layer3Image 'className={dataLayerClassName} src={ImgDataLayer3} alt="layer-3" />
            </>
        )
    }

    const RenderToolsLayers = () => {
        const dataLayerClassName = selectedButtonIndex === 1 ? Classes.selectedLayer : Classes.nonSelectedLayer;
        const backgroundImageCN = selectedButtonIndex === 1 ? Classes.selectedBackground : Classes.nonSelectedBackground;

        return (
            <>
                <img id='cvb-home-toolsLayer-backImage' className={backgroundImageCN} src={ImgToolsBack} alt="background" />
                <img id='cvb-home-toolsLayer-layer1Image' className={dataLayerClassName} src={ImgToolsLayer1} alt="layer-1" />
                <img id='cvb-home-toolsLayer-layer2Image' className={dataLayerClassName} src={ImgToolsLayer2} alt="layer-2" />
                <img id='cvb-home-toolsLayer-layer3Image' className={dataLayerClassName} src={ImgToolsLayer3} alt="layer-3" />
            </>
        )
    }

    const RenderCommunityLayers = () => {
        const commLayerClassName = selectedButtonIndex === 2 ? Classes.selectedLayer : Classes.nonSelectedLayer;
        const backgroundImageCN = selectedButtonIndex === 2 ? Classes.selectedBackground : Classes.nonSelectedBackground;

        return (
            <>
                <img id='cvb-home-communityLayer-backImage' className={backgroundImageCN} src={ImgCommBack} alt="background" />
                <img id='cvb-home-communityLayer-layer1Image' className={commLayerClassName} src={ImgCommLayer1} alt="layer-1" />
                <img id='cvb-home-communityLayer-layer2Image' className={commLayerClassName} src={ImgCommLayer2} alt="layer-2" />
                <img id='cvb-home-communityLayer-layer3Image' className={commLayerClassName} src={ImgCommLayer3} alt="layer-3" />
            </>
        )
    }

    const RenderCarousal = () => {
        return (
            <div className={Classes.carousalContainer}>
                {buttonOptions.map((item, index) => {
                    const btnClass = index === selectedButtonIndex ? Classes.selectedCarousalBtn : Classes.nonSelectedCarousalBtn
                    return (<div key={v4()} id='cvb-home-selectLayer-button' className={btnClass} onClick={() => { setSelectedButtonIndex(index) }}>&nbsp;</div>)
                })}
            </div>
        )
    }

    const faceImageSource = selectedButtonIndex === 0 ?
        ImgFace1 : selectedButtonIndex === 1 ? ImgFace2 : ImgFace3


    useEffect(() => {
        if (props?.location?.search?.includes("show-dialog=true") && !signDataUsagePolicy) {
            setSignDataUsagePolicy(true)
            setShowSignDataUsageModal(true)
        }
    }, [])

    return (
        <>
            {/* <button onClick={toggleModal}>openModal</button> */}
            <div>
                <div className={clsx(Classes.faceContainer,
                    { [Classes.closeFaceContainer]: !props.open }
                )}>
                    <div className={Classes.tileContainer}>
                        <RenderDataLayers />
                        <RenderToolsLayers />
                        <RenderCommunityLayers />
                        <img id='cvb-home-faceImage' className={Classes.face} src={faceImageSource} alt="face" />
                        <img id='cvb-home-glowImage' className={Classes.tabGlow} src={ActiveTabGlow} alt="face" />
                    </div>
                    {/* {
          buttonOptions.map((item, index) => renderButton(item, index))
        } */}
                </div>
                <Grid container spacing={4} className={Classes.navigationContainer}>
                    {buttonOptions !== undefined ? buttonOptions.map((val, index) => {
                        return <Grid key={v4()} item xs={4} spacing={4}>
                            <div className={Classes.navigationBlocks}
                                id={`cvb-home-layersSelectionButton-${val.link}`}
                                onClick={() => { navigate(val.link) }}>
                                <div className={`${Classes.iconCont}`}>
                                    <div id={`cvb-home-layersIconBlock-${val.link}`} className={index === 0 ? Classes.iconBlockOne : index === 1 ? Classes.iconBlockTwo : Classes.iconBlockThree}>
                                        <img id={`cvb-home-layerImage-${val.link}`} src={val.imageSource} alt="Data" />
                                    </div>
                                </div>
                                <div className={Classes.contentBlock}>
                                    <p id={`cvb-home-layerDetailsTitle-${val.caption}`} className={Classes.imgDescriptionTitle}>{val.caption}</p>
                                    <p id={`cvb-home-layerDetailsDescription-${val.caption}`}  className={Classes.descriptionTile}>{val.tagline}</p>
                                    <p className={Classes.lefLine}></p>
                                </div>
                            </div>
                            <div className={Classes.shadow}> </div>
                        </Grid>
                    }) : null}
                </Grid>
                {/* <RenderCarousal /> */}
                &nbsp;
                <Modal className={Classes.signInPolicyModal} open={showSignDataUsageModal} handleClose={() => setShowSignDataUsageModal(false)} disableTitleDivider dialogProps={signDataUsageModalPayload} >
                    <div className={Classes.signInPolicy}>
                        <Grid container>
                            <Grid item xs={1}>
                                <img  id={`cvb-home-signPolicyInfoIcon`} src={InfoIconModal} alt="Info Icon" />
                            </Grid>
                            <Grid item xs={10} className={Classes.signInPolicyContent}>
                                <h3> Review and sign the Data Use Agreement (DUA)</h3>
                                <p>Sign and fill out the DUA as part of the process to request access to Zone 2 data as a Qualified Researcher in the BRAINCommons.</p>
                            </Grid>
                        </Grid>
                    </div>
                </Modal>
            </div>
        </>
    );
}

import React from 'react';
import ExploreCreateCohort from './ExploreCreateCohort';
import { connect } from 'react-redux';
import {updateSnackBar} from "../../../../redux/actions/globalActions";

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type))
});



export default connect(mapStateToProps, mapDispatchToProps)(ExploreCreateCohort);

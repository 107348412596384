/**
 *  External Imports
 */
import axios from "axios";

/**
 *  Internal Imports
 */
import defaultIconUser from "../assets/images/user/default-profile-image.png";

import { BASE_URL } from "../constants/google-login-urls";
import { CONSTELLATION_USER_TYPE } from "../constants/constellation";

import { getCognitoIdToken } from "./utilityMethods";

/**
 *  Constants
 */
const COMMUNITY_RECOMMENDATION_STATUSES = {
    CONNECT: "connect",
    DECLINE: "decline",
};

const COMMUNITY_CONNECTION_STATUSES = {
    ACCEPT: "accept",
    DECLINE: "decline",
};

const RES_COMMUNITY_COUNTER_KEYS = {
    INCOMING: "incoming",
    PENDING: "pending",
    NEW_CONNECTION: "new_connection",
    NEW_MESSAGE: "new_message",
};

const RES_COMMUNITY_RECOMMENDATION_STATUSES = {
    DEFAULT: "",
    DECLINED: "Declined",
    PENDING: "Pending",
    CONNECTED: "Connected",
    NEW: "New"
};

const RES_COMMUNITY_GLOBAL_SEARCH_STATUSES = {
    DEFAULT: "",
    PENDING: "pending",
    CONNECTED: "connected",
};

/**
 *  Variables
 */
const defaultOrganization = "Cohen Veterans Bioscience";
const defaultDataSize = 10;
const defaultSortValue = "name";

const getFormedRequestHeaders = async () => {
    const cognitoToken = await getCognitoIdToken();
    return {
        Authorization: `Bearer ${cognitoToken}`,
        "Content-Type": "application/json",
    };
};

const getFormedRequestHeadersWithoutAuthToken = () => {
    return {
        "Content-Type": "application/json",
    };
};

const mapConstellationResponseData = (data, key) => {
    return data.reduce((accumulator, el) => {
        const basicFormedUser = {
            userIcon: el.avatar_image_url
                ? el.avatar_image_url
                : defaultIconUser,
            current: el.current,
            designation: el.designation,
            firstName: el.first_name,
            fullName: el.full_name,
            id: el.id_user,
            connectionId: el.id_connection,
            isUserUnsubscribedFromPendingNotificationEmail:
                el.is_user_unsubscribed_from_pending_notification_email,
            lastName: el.last_name,
            organization: el.organization,
            userSub: el.user_sub,
            status: el.status || "",
            email: el.email
        };

        const recommendedUserAdditionalData = {
            recommendationId: key === CONSTELLATION_USER_TYPE.RECOMMENDED && !el.current ? el.id_recommendation : -1,
            connectionId: key === CONSTELLATION_USER_TYPE.RECOMMENDED && !el.current ? el.connection_id : -1,
            isIncoming: key === CONSTELLATION_USER_TYPE.RECOMMENDED && !el.current ? el.incoming : null
        };

        if (el.current) {
            const user = {
                ...basicFormedUser,
                allLabels: el.all_labels || [],
                labelsAnswer: el.labels_answer,
                labelsKeyword: el.labels_keyword,
            };
            if (!user.allLabels || user.allLabels.length === 0) {
                const labelAnswers = user.labelsAnswer.map((label) => {
                    return {
                        isKeyword: false,
                        value: label,
                    };
                });
                const labelKeywords = user.labelsKeyword.map((label) => {
                    return {
                        isKeyword: true,
                        value: label,
                    };
                });
                const arrayToIterate =
                    labelAnswers.length >= labelKeywords.length
                        ? labelAnswers
                        : labelKeywords;
                const arrayToPatch =
                    labelAnswers.length < labelKeywords.length
                        ? labelAnswers
                        : labelKeywords;
                accumulator.labels =
                    labelAnswers.length !== 0 || labelKeywords.length !== 0
                        ? arrayToIterate.reduce((acc, label, index) => {
                              arrayToPatch[index]
                                  ? acc.push(label, arrayToPatch[index])
                                  : acc.push(label);
                              return acc;
                          }, [])
                        : [];
            } else {
                accumulator.labels =
                    key === CONSTELLATION_USER_TYPE.RECOMMENDED
                        ? user.allLabels.map((label) => {
                              return {
                                  isKeyword: false,
                                  value: label,
                              };
                          })
                        : [];
            }
            accumulator.currentUser = user;
            if (!accumulator[key]) {
                accumulator[key] = [];
            }
        } else {
            const commonUser =
                key === CONSTELLATION_USER_TYPE.RECOMMENDED
                    ? {
                          ...basicFormedUser,
                          ...recommendedUserAdditionalData,
                          connectionLinks: [
                              ...el.both_keywords,
                              ...el.both_answers,
                          ],
                          weight: el.weight,
                      }
                    : {
                          ...basicFormedUser,
                          connectionLinks: [
                              ...el.both_keywords,
                              ...el.both_answers,
                          ],
                          weight: el.weight,
                      };

            accumulator[key]
                ? accumulator[key].push(commonUser)
                : (accumulator[key] = [commonUser]);
        }

        return accumulator;
    }, {});
};

/**
 *  API
 */
const communityAPI = {
    /**
     *  GET Requests
     */
    /**
     *  @function getCommunityCounters
     *
     *  @param {CancelToken | undefined?} [cancelToken=undefined] Set a cancel token
     *
     *  @return {Promise<RES_COMMUNITY_COUNTER_KEYS | object>}
     */
    async getCommunityCounters(cancelToken) {
        // Variables
        const headers = await getFormedRequestHeaders();

        let response;

        // Emit a request
        try {
            response = await axios.get(
                `${BASE_URL}/social/community/counters`,
                {
                    headers,
                    cancelToken,
                }
            );
        } catch (err) {
            response = {
                data: {
                    isCanceled: !!cancelToken,
                },
            };

            if (typeof cancelToken === "undefined") {
                console.error("communityAPI - getCommunityCounters:", err);
            }
        }

        // Return the data
        return response.data;
    },
    /**
     *  @function getRecommendations
     *
     *  @param {CancelToken | undefined?} [cancelToken=undefined] Set a cancel token
     *  @param {number?} [dataSize=defaultDataSize] Set a data size
     *  @param {boolean?} [isWithMutual=false] Set a "mutual" flag
     * 	@param {string?} [page=''] Set a data page
     * 	@param {string?} [sortBy] Set a sort field
     *  @param {string?} [orderBy='asc'] Set a sort order
     *
     *  @return {Promise<object>}
     */
    async getRecommendations(
        cancelToken,
        dataSize = defaultDataSize,
        isWithMutual = false,
        page = "",
        sortBy = null,
        orderBy = "asc"
    ) {
        // Variables
        const headers = await getFormedRequestHeaders();
        const basicUrl = `${BASE_URL}/social/community/recommendations`;
        const urlQuery = page
            ? `${page}&mutual=${isWithMutual}`
            : `?size=${dataSize}&mutual=${isWithMutual}`;
        const sortQuery = sortBy
            ? `&sort_by=${sortBy}&order_by=${orderBy}`
            : "";

        let response;

        // Emit a request
        try {
            response = await axios.get(`${basicUrl}${urlQuery}${sortQuery}`, {
                headers,
                cancelToken,
            });
        } catch (err) {
            response = {
                data: {
                    isCanceled: !!cancelToken,
                    data: [],
                },
            };

            if (typeof cancelToken === "undefined") {
                console.error("communityAPI - getRecommendations:", err);
            }
        }

        // Return the data
        return response.data;
    },
    /**
     *  @function getAcceptedConnections
     *
     *  @param {CancelToken | undefined?} [cancelToken=undefined] Set a cancel token
     *  @param {number?} [dataSize=defaultDataSize] Set a data size
     * 	@param {string?} [page=''] Set a data page
     * 	@param {string?} [sortBy] Set a sort field
     *  @param {string?} [orderBy='asc'] Set a sort order
     *
     *  @return {Promise<object>}
     */
    async getAcceptedConnections(
        cancelToken,
        dataSize = defaultDataSize,
        page = "",
        sortBy = null,
        orderBy = "desc"
    ) {
        // Variables
        const headers = await getFormedRequestHeaders();
        const basicUrl = `${BASE_URL}/social/community/connections`;
        const urlQuery = page
            ? `${page}&status=accepted`
            : `?status=accepted&size=${dataSize}`;
        const sortQuery = sortBy
            ? `&sort_by=${sortBy}&order_by=${orderBy}`
            : "";

        let response;

        // Emit a request
        try {
            response = await axios.get(`${basicUrl}${urlQuery}${sortQuery}`, {
                headers,
                cancelToken,
            });
        } catch (err) {
            response = {
                data: {
                    isCanceled: !!cancelToken,
                    data: [],
                },
            };

            if (typeof cancelToken === "undefined") {
                console.error("communityAPI - getAcceptedConnections:", err);
            }
        }

        // Return the data
        return response.data;
    },
    /**
     *  @function getIncomingConnections
     *
     *  @param {CancelToken | undefined?} [cancelToken=undefined] Set a cancel token
     *  @param {number?} [dataSize=defaultDataSize] Set a data size
     * 	@param {string?} [page=''] Set a data page
     * 	@param {string?} [sortBy] Set a sort field
     *  @param {string?} [orderBy='asc'] Set a sort order
     *
     *  @return {Promise<object>}
     */
    async getIncomingConnections(
        cancelToken,
        dataSize = defaultDataSize,
        page = "",
        sortBy = null,
        orderBy = "desc"
    ) {
        // Variables
        const headers = await getFormedRequestHeaders();
        const basicUrl = `${BASE_URL}/social/community/connections`;
        const urlQuery = page
            ? `${page}&status=incoming`
            : `?status=incoming&size=${dataSize}`;
        const sortQuery = sortBy
            ? `&sort_by=${sortBy}&order_by=${orderBy}`
            : "";

        let response;

        // Emit a request
        try {
            response = await axios.get(`${basicUrl}${urlQuery}${sortQuery}`, {
                headers,
                cancelToken,
            });
        } catch (err) {
            response = {
                data: {
                    isCanceled: !!cancelToken,
                    data: [],
                    total: 0,
                    pages: 0,
                    next_page: null,
                    prev_page: null,
                },
            };

            if (typeof cancelToken === "undefined") {
                console.error("communityAPI - getIncomingConnections:", err);
            }
        }

        // Return the data
        return response.data;
    },
    /**
     *  @function getPendingConnections
     *
     *  @param {CancelToken | undefined?} [cancelToken=undefined] Set a cancel token
     *  @param {number?} [dataSize=defaultDataSize] Set a data size
     * 	@param {string?} [page=''] Set a data page
     * 	@param {string?} [sortBy] Set a sort field
     *  @param {string?} [orderBy='asc'] Set a sort order
     *
     *  @return {Promise<object>}
     */
    async getPendingConnections(
        cancelToken,
        dataSize = defaultDataSize,
        page = "",
        sortBy = false,
        orderBy = "asc"
    ) {
        // Variables
        const headers = await getFormedRequestHeaders();
        const basicUrl = `${BASE_URL}/social/community/connections`;
        const urlQuery = page
            ? `${page}&status=pending`
            : `?status=pending&size=${dataSize}`;
        const sortQuery = sortBy
            ? `&sort_by=${sortBy}&order_by=${orderBy}`
            : "";

        let response;

        // Emit a request
        try {
            response = await axios.get(`${basicUrl}${urlQuery}${sortQuery}`, {
                headers,
                cancelToken,
            });
        } catch (err) {
            response = {
                data: {
                    isCanceled: !!cancelToken,
                    data: [],
                    total: 0,
                    pages: 0,
                    next_page: null,
                    prev_page: null,
                },
            };

            if (typeof cancelToken === "undefined") {
                console.error("communityAPI - getPendingConnections:", err);
            }
        }

        // Return the data
        return response.data;
    },
    /**
     *  @function getGlobalSearchFilters
     *
     *  @param {CancelToken | undefined?} [cancelToken=undefined] Set a cancel token
     *
     *  @return {Promise<object>}
     */
    async getGlobalSearchFilters(cancelToken) {
        // Variables
        const headers = await getFormedRequestHeaders();

        let response;

        // Emit a request
        try {
            response = await axios.get(
                `${BASE_URL}/social/community/search?filters=1`,
                {
                    headers,
                    cancelToken,
                }
            );
        } catch (err) {
            response = {
                data: {
                    isCanceled: !!cancelToken,
                    filters: {},
                },
            };

            if (typeof cancelToken === "undefined") {
                console.error("communityAPI - getGlobalSearchFilters:", err);
            }
        }

        // Return the data
        return response.data;
    },
    /**
     *  @function getGlobalSearchResults
     *
     *  @param {CancelToken | undefined?} [cancelToken=undefined] Set a cancel token
     *  @param {string?} [searchQuery=""] Set a search query
     *
     *  @return {Promise<object>}
     */
    async getGlobalSearchResults(
        cancelToken,
        searchQuery = "",
        sortBy = "",
        orderBy = "asc"
    ) {
        // Variables
        const headers = await getFormedRequestHeaders();
        const sortQuery = sortBy
            ? `&sort_by=${sortBy}&order_by=${orderBy}`
            : "";

        let response;

        // Emit a request
        try {
            response = await axios.get(
                `${BASE_URL}/social/community/search?${searchQuery}${sortQuery}`,
                {
                    headers,
                    cancelToken,
                }
            );
        } catch (err) {
            response = {
                data: {
                    isCanceled: !!cancelToken,
                    results: [],
                },
            };

            if (typeof cancelToken === "undefined") {
                console.error("communityAPI - getGlobalSearchResults:", err);
            }
        }

        // Return the data
        return response.data;
    },

    /**
     *  POST Requests
     */
    /**
     *  @function postRecommendationByID
     *  @param {requestParam<object>}
     *  @param {number} [requestParam.id] Set a recommendation ID
     *  @param {string?} [requestParam.actionStatus=COMMUNITY_RECOMMENDATION_STATUSES.CONNECT] Set a recommendation status
     *  @param {string?} [requestParam.userMessage=""] Set a message text
     *
     *  @return {Promise<object>}
     */
    async postRecommendationByID(requestParam) {
        const {
            id,
            actionStatus = COMMUNITY_RECOMMENDATION_STATUSES.CONNECT,
            userMessage: messageText = "",
        } = requestParam;
        // Variables
        const headers = await getFormedRequestHeaders();

        let response;

        // Emit a request
        try {
            response = await axios.post(
                `${BASE_URL}/social/community/recommendations/${id}`,
                {
                    action: actionStatus,
                    text: messageText,
                },
                {
                    headers,
                }
            );
        } catch (err) {
            response = {
                data: {},
                status: 500,
            };

            console.error("communityAPI - postRecommendationByID:", err);
        }

        // Return a response
        return response;
    },
    /**
     *  @function postConnectionStatusByID
     * 	@param {requestParam<object>}
     *  @param {number} [requestParam.id] Set a connection ID
     *  @param {string?} [requestParam.connectionStatus=COMMUNITY_CONNECTION_STATUSES.ACCEPT] Set a connection status
     *
     *  @return {Promise<object>}
     */
    async postConnectionStatusByID(requestParam) {
        const {
            id,
            actionStatus = COMMUNITY_CONNECTION_STATUSES.ACCEPT,
        } = requestParam;
        // Variables
        const headers = await getFormedRequestHeaders();

        let response;

        // Emit a request
        try {
            response = await axios.post(
                `${BASE_URL}/social/community/connections/${id}`,
                {
                    action: actionStatus,
                },
                {
                    headers,
                }
            );
        } catch (err) {
            response = {
                data: {},
                status: 500,
            };

            console.error("communityAPI - postConnectionStatusByID:", err);
        }

        // Return a response
        return response;
    },
    /**
     *  @function postConnectionUserByUUID
     *
     * 	@param {requestParam<object>}
     *  @param {string} requestParam.id Set a user UUID
     *  @param {string?} [requestParam.messageText=""] Set a message text
     *
     *  @return {Promise<object>}
     */
    async postConnectionUserByUUID(requestParam) {
        // Variables
        const { id: userUUID, userMessage: messageText = "" } = requestParam;
        const headers = await getFormedRequestHeaders();

        let response;

        // Emit a request
        try {
            response = await axios.post(
                `${BASE_URL}/social/community/connections`,
                {
                    connect_user_uuid: userUUID,
                    text: messageText,
                },
                {
                    headers,
                }
            );
        } catch (err) {
            response = {
                data: {},
                status: 500,
            };

            console.error("communityAPI - postConnectionUserByUUID:", err);
        }

        // Return a response
        return response;
    },
    async getFeedbackAvailability(query) {
        // Variables
        const headers = getFormedRequestHeadersWithoutAuthToken();
        let response;

        // Emit a request
        try {
            response = await axios.get(
                `${BASE_URL}/social/community/feedback?token=${query}`,
                {
                    headers,
                }
            );
        } catch (err) {
            response = {
                data: {
                    username: "",
                    error: err.response.status,
                },
            };
        }

        // Return the data
        return response.data;
    },
    async postUserFeedback(feedback) {
        // Variables
        const headers = await getFormedRequestHeaders();

        let response;

        // Emit a request
        try {
            response = await axios.post(
                `${BASE_URL}/social/community/feedback`,
                {
                    ...feedback,
                },
                {
                    headers,
                }
            );
        } catch (err) {
            response = {
                data: {
                    error: err?.response?.status || err,
                },
            };
        }

        // Return the data
        return response.data;
    },
    async deleteAcceptedConnection(id) {
        const headers = await getFormedRequestHeaders();

        let response;

        try {
            response = await axios.delete(
                `${BASE_URL}/social/community/connections/${id}`,
                {
                    headers,
                }
            );
        } catch (err) {
            response = {
                data: {
                    error: err.response.status,
                },
            };
        }

        return response.data;
    },
    async uploadMessageFile(id, formData) {
        const cognitoToken = await getCognitoIdToken();
        const headers = {
            Authorization: `Bearer ${cognitoToken}`,
            "Content-Type": "multipart/form-data",
        };
        let response;

        try {
            response = await axios.post(
                `${BASE_URL}/social/community/messages/upload/${id}`,
                formData,
                {
                    headers,
                }
            );
        } catch (err) {
            response = {
                data: {
                    error: err.response.status,
                },
            };
        }

        return response.data;
    },
    async downloadMessageFile(messageId) {
        const headers = await getFormedRequestHeaders();
        let response;
        let result;

        try {
            response = await axios.get(
                `${BASE_URL}/social/community/messages/${messageId}/download`,
                {
                    headers,
                    responseType: "arraybuffer",
                }
            );
            const fileTitleHeaders = response.headers["content-disposition"];
            const fileTitleIndex = fileTitleHeaders.search("filename=");
            result = {
                file: response.data,
                fileTitle:
                    fileTitleIndex !== -1
                        ? fileTitleHeaders.split("filename=")[1]
                        : "File",
                fileType: response.headers["content-type"],
            };
        } catch (err) {
            result = {
                error: err.response.status,
            };
        }
        return result;
    },
    async getConstellationRecommendations(cancelToken) {
        const headers = await getFormedRequestHeaders();
        let result;
        let response;

        try {
            response = await axios.get(
                `${BASE_URL}/social/community/constellation/recommended`,
                {
                    headers,
                    cancelToken,
                }
            );
            result = {
                data: response.data,
            };
        } catch (err) {
            result = {
                isCanceled: !!cancelToken,
                data: [],
            };

            if (typeof cancelToken === "undefined") {
                console.error(
                    "communityAPI - getConstellationRecommendations:",
                    err
                );
            }
        }

        return result;
    },
    async getConstellationConnections(cancelToken) {
        const headers = await getFormedRequestHeaders();
        let result;
        let response;

        try {
            response = await axios.get(
                `${BASE_URL}/social/community/constellation/connections`,
                {
                    headers,
                    cancelToken,
                }
            );
            result = {
                data: response.data,
            };
        } catch (err) {
            result = {
                isCanceled: !!cancelToken,
                data: [],
            };

            if (typeof cancelToken === "undefined") {
                console.error(
                    "communityAPI - getConstellationRecommendations:",
                    err
                );
            }
        }

        return result;
    },
    async saveConstellationLabelAnswers(answersList) {
        const headers = await getFormedRequestHeaders();
        let response;

        try {
            response = await axios.post(
                `${BASE_URL}/social/community/constellation/add_answers`,
                { answers: answersList },
                {
                    headers,
                }
            );
        } catch (err) {
            response = {
                data: {
                    error: err.response.status,
                },
            };
        }

        return response.data;
    },
};

/**
 *  Mapped API
 */
const communityMappedAPI = {
    /**
     *  @function getMappedRecommendations
     *
     *  @param {requestParam<object>}
     * 	@param {string?} [requestParam.page=''] Set a data page
     *  @param {CancelToken | undefined?} [requestParam.cancelToken=undefined] Set a cancel token
     *  @param {number?} [requestParam.dataSize=defaultDataSize] Set a data size
     *  @param {boolean?} [requestParam.isWithMutual=false] Set a "mutual" flag
     * 	@param {string?} [requestParam.sortBy] Set a sort field
     *  @param {string?} [requestParam.orderBy='asc'] Set a sort order
     *
     *  @return {Promise<object>}
     */
    async getMappedRecommendations(requestParam) {
        // Form request params
        const {
            cancelToken,
            dataSize = 50,
            isWithMutual = false,
            page = "",
            sortBy = false,
            orderBy = "asc",
        } = requestParam;
        // Get a raw data
        const { isCanceled, data } = await communityAPI.getRecommendations(
            cancelToken,
            dataSize,
            isWithMutual,
            page,
            sortBy,
            orderBy
        );

        // Preparing of the data and return the result
        return {
            isCanceled,
            data: data.map(
                ({
                    id_user,
                    id_recommendation,
                    first_name,
                    last_name,
                    organization,
                    email,
                    reason,
                    status,
                    mutual,
                    avatar_image_url,
                }) => ({
                    idUser: id_user,
                    idRecommendation: id_recommendation,
                    userIcon: avatar_image_url
                        ? avatar_image_url
                        : defaultIconUser,
                    name: `${first_name} ${last_name}`,
                    organization: organization || defaultOrganization,
                    reason:
                        reason ||
                        `You both went to <strong>${
                            organization || defaultOrganization
                        }</strong> and study <strong>Other Cell Surface Receptors</strong>`,
                    status:
                        status || RES_COMMUNITY_RECOMMENDATION_STATUSES.DEFAULT,
                    mutualDescription:
                        typeof mutual === "number"
                            ? `${mutual} mutual connections`
                            : "",
                })
            ),
        };
    },
    /**
     *  @function getMappedAcceptedConnections
     *
     *  @param {requestParam<object>}
     * 	@param {string?} [requestParam.page=''] Set a data page
     *  @param {CancelToken | undefined?} [requestParam.cancelToken=undefined] Set a cancel token
     *  @param {number?} [requestParam.dataSize=defaultDataSize] Set a data size
     * 	@param {string?} [requestParam.sortBy] Set a sort field
     *  @param {string?} [requestParam.orderBy='asc'] Set a sort order
     *
     *  @return {Promise<object>}
     */
    async getMappedAcceptedConnections(requestParam) {
        // Get a raw data
        const {
            cancelToken,
            dataSize = defaultDataSize,
            page = "",
            sortBy = false,
            orderBy = "asc",
        } = requestParam;
        const { isCanceled, data } = await communityAPI.getAcceptedConnections(
            cancelToken,
            dataSize,
            page,
            sortBy,
            orderBy
        );
        // Preparing of the data and return the result
        return {
            isCanceled,
            data: data.map(
                ({
                    id,
                    avatar_image_url,
                    recipient_id,
                    first_name,
                    last_name,
                    organization,
                    email,
                    status,
                    text,
                    user_sub,
                    reason,
                }) => ({
                    id,
                    recipientID: recipient_id,
                    userIcon: avatar_image_url
                        ? avatar_image_url
                        : defaultIconUser,
                    name: `${first_name} ${last_name}`,
                    organization: organization || defaultOrganization,
                    email,
                    status: status || "",
                    text: text || "",
                    userUid: user_sub,
                    reason: reason || "",
                })
            ),
        };
    },
    /**
     *  @function getMappedIncomingConnections
     *
     *  @param {requestParam<object>}
     * 	@param {string?} [requestParam.page=''] Set a data page
     *  @param {CancelToken | undefined?} [requestParam.cancelToken=undefined] Set a cancel token
     *  @param {number?} [requestParam.dataSize=defaultDataSize] Set a data size
     * 	@param {string?} [requestParam.sortBy] Set a sort field
     *  @param {string?} [requestParam.orderBy='asc'] Set a sort order
     *
     *  @return {Promise<object>}
     */
    async getMappedIncomingConnections(requestParam) {
        // Get a raw data
        const {
            cancelToken,
            dataSize = defaultDataSize,
            page = "",
            sortBy = false,
            orderBy = "asc",
        } = requestParam;
        const {
            isCanceled,
            data,
            total,
            pages,
            next_page,
            prev_page,
        } = await communityAPI.getIncomingConnections(
            cancelToken,
            dataSize,
            page,
            sortBy,
            orderBy
        );

        // Preparing of the data and return the result
        return {
            isCanceled,
            data: data.map(
                ({
                    id,
                    avatar_image_url,
                    recipient_id,
                    first_name,
                    last_name,
                    organization,
                    email,
                    text,
                }) => ({
                    id,
                    recipientID: recipient_id,
                    userIcon: avatar_image_url
                        ? avatar_image_url
                        : defaultIconUser,
                    name: `${first_name} ${last_name}`,
                    organization: organization || defaultOrganization,
                    email,
                    text: text || "",
                })
            ),
            total,
            pages,
            nextPage: next_page,
            prevPage: prev_page,
        };
    },
    /**
     *  @function getMappedPendingConnections
     *
     *  @param {requestParam<object>}
     * 	@param {string?} [requestParam.page=''] Set a data page
     *  @param {CancelToken | undefined?} [requestParam.cancelToken=undefined] Set a cancel token
     *  @param {number?} [requestParam.dataSize=defaultDataSize] Set a data size
     * 	@param {string?} [requestParam.sortBy] Set a sort field
     *  @param {string?} [requestParam.orderBy='asc'] Set a sort order
     *
     *  @return {Promise<object>}
     */
    async getMappedPendingConnections(requestParam) {
        // Get a raw data
        const {
            cancelToken,
            dataSize = defaultDataSize,
            page = "",
            sortBy = false,
            orderBy = "asc",
        } = requestParam;
        const {
            isCanceled,
            data,
            total,
            pages,
            next_page,
            prev_page,
        } = await communityAPI.getPendingConnections(
            cancelToken,
            dataSize,
            page,
            sortBy,
            orderBy
        );

        // Preparing of the data and return the result
        return {
            isCanceled,
            data: data.map(
                ({
                    id,
                    avatar_image_url,
                    sender_id,
                    first_name,
                    last_name,
                    organization,
                    email,
                    text,
                }) => ({
                    id,
                    senderID: sender_id,
                    userIcon: avatar_image_url
                        ? avatar_image_url
                        : defaultIconUser,
                    name: `${first_name} ${last_name}`,
                    organization: organization || defaultOrganization,
                    email,
                    text: text || "",
                })
            ),
        };
    },
    /**
     *  @function getMappedGlobalSearchFilters
     *
     *  @param {CancelToken | undefined?} [cancelToken=undefined] Set a cancel token
     *
     *  @return {Promise<object>}
     */
    async getMappedGlobalSearchFilters(cancelToken) {
        // Get a raw data
        const {
            isCanceled,
            filters,
        } = await communityAPI.getGlobalSearchFilters(cancelToken);

        // Preparing of the data and return the result
        return {
            isCanceled,
            data: {
                molecularClasses: Array.isArray(filters?.molecular_class)
                    ? filters.molecular_class
                    : [],
                therapeuticAreas: Array.isArray(
                    filters?.therapeutic_area_of_interest
                )
                    ? filters.therapeutic_area_of_interest
                    : [],
            },
        };
    },
    /**
     *  @function getMappedGlobalSearchResults
     *
     *  @param {CancelToken | undefined?} [cancelToken=undefined] Set a cancel token
     *  @param {string?} [searchQuery=""] Set a search query
     *
     *  @return {Promise<object>}
     */
    async getMappedGlobalSearchResults(
        cancelToken,
        searchQuery = "",
        sort = defaultSortValue,
        order = "asc"
    ) {
        // Get a raw data
        const {
            isCanceled,
            results,
        } = await communityAPI.getGlobalSearchResults(
            cancelToken,
            searchQuery,
            sort,
            order
        );

        // Preparing of the data and return the result
        return {
            isCanceled,
            data: results.map(
                ({
                    uuid,
                    avatar_image_url,
                    name,
                    organization,
                    email,
                    status,
                }) => ({
                    id: uuid,
                    userIcon: avatar_image_url
                        ? avatar_image_url
                        : defaultIconUser,
                    name,
                    organization: organization || defaultOrganization,
                    email,
                    status:
                        status || RES_COMMUNITY_GLOBAL_SEARCH_STATUSES.DEFAULT,
                })
            ),
        };
    },
    async getMappedConstellationRecommendations(cancelToken) {
        const {
            isCanceled,
            data,
        } = await communityAPI.getConstellationRecommendations(cancelToken);
        return {
            isCanceled,
            data:
                data && data.length !== 0
                    ? mapConstellationResponseData(
                          data,
                          CONSTELLATION_USER_TYPE.RECOMMENDED
                      )
                    : { recommendedUser: [], currentUser: null, labels: [] },
        };
    },
    async getMappedConstellationConnections(cancelToken) {
        const {
            isCanceled,
            data,
        } = await communityAPI.getConstellationConnections(cancelToken);

        return {
            isCanceled,
            data:
                data && data.length !== 0
                    ? mapConstellationResponseData(
                          data,
                          CONSTELLATION_USER_TYPE.CONNECTED
                      )
                    : { connectedUser: [], currentUser: null, labels: [] },
        };
    },
};

/**
 *  Exports
 */
export {
    // Constants
    COMMUNITY_RECOMMENDATION_STATUSES,
    COMMUNITY_CONNECTION_STATUSES,
    RES_COMMUNITY_COUNTER_KEYS,
    RES_COMMUNITY_RECOMMENDATION_STATUSES,
    RES_COMMUNITY_GLOBAL_SEARCH_STATUSES,
    // API
    communityAPI,
    communityMappedAPI,
};

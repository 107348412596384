import { SET_DIF_TIMELINE_IS_DEADLINE_TO_UPLOAD, SET_DIF_TIMELINE_IS_DEADLINE_TO_DATA_ACCESS,
    SET_DIF_TIMELINE_IS_DATA_EMBARGOED, SET_DIF_TIMELINE_SUBMISSION_DATE, SET_DIF_TIMELINE_DATA_ACCESS_DEADLINE_DATE,
    SET_DIF_TIMELINE_DATA_EMBARGOED_DATE, SET_DIF_TIMELINE_DATA_EMBARGOED_DESC, SET_TIMELINE_FORM_DATA, RESET_TIMELINE_FORM_DATA
} from '../action-types'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

const initialState = {
   difTimelineIsDeadlineToUpload: '',
   difTimelineIsDeadlineToDataAccess: '',
   difTimelineIsDataEmbargoed: '',
   difTimelineSubmissionDate: null,
   difTimelineDataAccessDeadlineDate: null ,
   difTimelineEmbargoedDate:null ,
   difTimelineEmbargoedDesc: ''
}

const difTimelineReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_DIF_TIMELINE_IS_DEADLINE_TO_UPLOAD: {
            return {
                ...state,
                difTimelineIsDeadlineToUpload: action.payload
            }
        }
        case SET_DIF_TIMELINE_IS_DEADLINE_TO_DATA_ACCESS: {
            return {
                ...state,
                difTimelineIsDeadlineToDataAccess: action.payload
            }
        }
        case SET_DIF_TIMELINE_IS_DATA_EMBARGOED: {
            return {
                ...state,
                difTimelineIsDataEmbargoed: action.payload
            }
        }
        case SET_DIF_TIMELINE_SUBMISSION_DATE: {
            return {
                ...state,
                difTimelineSubmissionDate: action.payload
            }
        }
        case SET_DIF_TIMELINE_DATA_ACCESS_DEADLINE_DATE: {
            return {
                ...state,
                difTimelineDataAccessDeadlineDate: action.payload
            }
        }
        case SET_DIF_TIMELINE_DATA_EMBARGOED_DATE: {
            return {
                ...state,
                difTimelineEmbargoedDate: action.payload
            }
        }
        case SET_DIF_TIMELINE_DATA_EMBARGOED_DESC: {
            return {
                ...state,
                difTimelineEmbargoedDesc: action.payload
            }
        }
        case SET_TIMELINE_FORM_DATA: {
            const formData = action.payload;
            const submissionDeadlineStr = get(formData, 'submission_deadline', '');
            const dataAccessDeadlineStr = get(formData, 'data_access_deadline', '');
            const dataEmbargoedTillDateStr = get(formData, 'data_embargoed_till', '');
            const isDeadlineToUpload  = get(formData, 'is_there_data_submission_deadline', '');
            const isDeadlineToAccessData = get(formData, 'is_there_data_access_deadline', '');
            const isDataEmbargoed = get(formData, 'is_data_embargoed', '');

            const submissionDeadlineDate = !isEmpty(submissionDeadlineStr) ? moment.utc(submissionDeadlineStr).toDate(): null;
            const dataAccessDeadlineDate = !isEmpty(dataAccessDeadlineStr) ? moment.utc(dataAccessDeadlineStr).toDate() : null;
            const tillDate = !isEmpty(dataEmbargoedTillDateStr) ? moment.utc(dataEmbargoedTillDateStr).toDate() : null
            return {
                ...state,
                difTimelineIsDeadlineToUpload: isDeadlineToUpload,
                difTimelineIsDeadlineToDataAccess: isDeadlineToAccessData,
                difTimelineIsDataEmbargoed: isDataEmbargoed,
                difTimelineSubmissionDate: submissionDeadlineDate,
                difTimelineDataAccessDeadlineDate: dataAccessDeadlineDate,
                difTimelineEmbargoedDate: tillDate,
                difTimelineEmbargoedDesc: get(formData, 'data_embargoed_description', '')
            }
        }
        case RESET_TIMELINE_FORM_DATA: {
            return {
                ...state,
                difTimelineIsDeadlineToUpload: '',
                difTimelineIsDeadlineToDataAccess: '',
                difTimelineIsDataEmbargoed: '',
                difTimelineSubmissionDate: null,
                difTimelineDataAccessDeadlineDate: null ,
                difTimelineEmbargoedDate:null ,
                difTimelineEmbargoedDesc: ''
            }
        }
        default: return state
    }
}

export default difTimelineReducers;
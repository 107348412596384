import trim from 'lodash/trim';
export const trimStringToTenChar = (str) => {
  try {
    return trim(str).substr(0, 12) + '...';
  } catch (error) {
    console.log('error in trim');
  }
};

export const getFileType = (str) => {
  try {
    if (str) {
      const lastIndexOfDot = `${str}`.lastIndexOf('.');
      const fileTypeString = `${str}`.substr(
        lastIndexOfDot + 1,
        `${str}`.length
      );
      return fileTypeString;
    } else return '';
  } catch (error) {
    console.log('error in getting file str');
  }
};

export const getDCACurrentFormStage = (dcaStage) => {
  try {
    let stageToSend = '';
    switch (dcaStage) {
      case 1:
        stageToSend = '1';
        break;
      case 2:
        stageToSend = '2';
        break;
      case 3:
        stageToSend = '3A';
        break;
      case 4:
        stageToSend = '3B';
        break;
      case 5:
        stageToSend = '3C';
        break;
      case 6:
        stageToSend = '3D';
        break;
      case 7:
        stageToSend = '3E';
        break;
      case 8:
        stageToSend = '3F';
        break;
      case 9:
        stageToSend = '3G';
        break;
      case 10:
        stageToSend = '4';
        break;
      default:
        stageToSend = '1';
        break;
    }
    return stageToSend;
  } catch (error) {
    console.log('error in getting form stage');
  }
};
export const getDCANextFormStage = (dcaStage) => {
  try {
    let stageToSend = '';
    switch (dcaStage) {
      case 1:
        stageToSend = '2';
        break;
      case 2:
        stageToSend = '3A';
        break;
      case 3:
        stageToSend = '3B';
        break;
      case 4:
        stageToSend = '3C';
        break;
      case 5:
        stageToSend = '3D';
        break;
      case 6:
        stageToSend = '3E';
        break;
      case 7:
        stageToSend = '3F';
        break;
      case 8:
        stageToSend = '3G';
        break;
      case 9:
        stageToSend = '4';
        break;
      case 10:
        stageToSend = 'Completed';
        break;
      default:
        stageToSend = '1';
        break;
    }
    return stageToSend;
  } catch (error) {
    console.log('error in getting form stage');
  }
};
export const getStageNumberFromRes = (dcaStage) => {
  try {
    let stageToSend = 1;
    switch (dcaStage) {
      case '1':
        stageToSend = 1;
        break;
      case '2':
        stageToSend = 2;
        break;
      case '3A':
        stageToSend = 3;
        break;
      case '3B':
        stageToSend = 4;
        break;
      case '3C':
        stageToSend = 5;
        break;
      case '3D':
        stageToSend = 6;
        break;
      case '3E':
        stageToSend = 7;
        break;
      case '3F':
        stageToSend = 8;
        break;
      case '3G':
        stageToSend = 9;
        break;
      case '4':
        stageToSend = 10;
        break;
      case 'Completed':
        stageToSend = 1;
        break;
      default:
        stageToSend = 1;
        break;
    }
    return stageToSend;
  } catch (error) {
    console.log('error in getting form stage');
  }
};

/**
 *  @function getRealTextWidthPx
 *
 *  @param {string | *?} [str=undefined] Set a target string
 *  @param {string | null | *?} [fontSettings=null] Set a font settings
 *
 *  @return {number} Return a real text width in pixels
 */
export function getRealTextWidthPx(str, fontSettings = null) {
  // Checking an input data
  if (typeof str !== 'string' || typeof fontSettings !== 'string') {
    return 0;
  }

  // Variables
  const canvasCtx = document.createElement('canvas').getContext('2d');

  let textMetrics;

  // Checking an exception
  if (canvasCtx === null) {
    return 0;
  }

  // Set a new font settings
  canvasCtx.font = fontSettings;

  // Getting a text metrics
  textMetrics = canvasCtx.measureText(str);

  // Return a result
  return textMetrics.width;
}

/**
 *  @function getTruncTextByMaxLen
 *
 *  @param {string | *?} [str=undefined] Set a target string
 *  @param {number | *?} [maxLen=0] Set a maximum text length
 *
 *  @return {string} Return a new text line
 */
export function getTruncTextByMaxLen(str, maxLen = 0) {
  if (typeof str !== 'string' || typeof maxLen !== 'number') {
    return '';
  }

  return str.length > maxLen ? `${str.substring(0, maxLen)}...` : str;
}

// program to convert first letter of a string to uppercase
export function capitalizeFirstLetter(str) {
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
}

/**
 *  @function getSpacedTextByCode
 *
 *  @param {string | *?} [str=undefined] Filter   code e.g project_size
 *
 *  @return {string} Return a spaced string  e.g project size
 */
export const getSpacedTextByCode = (value) => {
  if (value === 'project_size') {
    value = 'number_of_participants';
  }

  return value ? capitalizeFirstLetter(value.split('_').join(' ')) : '';
};

import React, { useState, useEffect } from 'react'
import classes from './index.module.css'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import BackButtonIcon from '../../../assets/icons/left_arrow.png'
import { getUserSearchAdminApi } from '../../../api/admin/user'
import uniq from 'lodash/uniq'
import {
    getAdvanceComputeRequests, getZone2Requests, getZone3Requests, getAllUnapprovedDCAFormsData, getAllUnapprovedDIFFormsData,
     getProjectFileMappings, getCommunityKeywords, approveCommunityKeywords, getAllPendingApprovalWorkspaceCarts
} from '../../../api/admin/admin-dashboard';
import map from 'lodash/map'
import v4 from 'uuid/v4'
import moment from 'moment'
import get from 'lodash/get'
import { getUserProfile, getProfilePicture} from '../../../api/profile-api';
import isEmpty from 'lodash/isEmpty';
import { lowerCase } from 'lodash';
import { getDatasetForAllZones } from '../../../api/graphql/admin-dashboard';
import find from 'lodash/find'
import useScrollToTop from '../../commons/scroll-top';
import {
    ZONE_1_KEY, ZONE_2_KEY, ZONE_3_KEY, USER_SORT_OPTIONS
} from '../../../constants/strings';
import { Auth } from 'aws-amplify'
import sortBy from 'lodash/sortBy'
import { getAllManualDCAUploads} from '../../../api/dca-form-api';
import MuiTab from '@mui/material/Tab';
//import changes 
import DatasetAndUsersDistribution from '../../controls/dataset-and-users-distribution';
import UploadManualDCA from '../../controls/upload-manual-dca';
import PendingApprovals from '../../controls/pending-approvals';
import PendingApprovalsStepper from '../../commons/pending-approvals-stepper';
import WorkspaceCostSummary from '../../controls/workspace-cost-summary';
import { getAllWorkspaceOwnerShipDetails, getAllWorkspaceCarts } from '../../../api/workspace-api/workspace-api'
import { setAllWorkspacesCarts } from '../../../redux/actions/workspaceActions'
import Store from '../../../redux/store';

import AdminDashboardTabs from './admin-dashboard-tabs/AdminDashboardTabs'
import { useRef } from 'react';
import SectionHeader from '../../commons/section-header';
import ReviewApproveKeywords from '../../controls/review-approve-keywords/ReviewApproveKeywords';
import {formatBytes} from '../../../utils/file-size-formatter';
const useStyles = makeStyles(theme => ({
    flexContainer: {
        borderBottom: '2px solid #637BBD',
        background: '#fafafa'
    }
}))

const DEFAULT_HEADER_HEIGHT = 187;

export default function AdminDashboard(props) {
    const { currentUserDetails = {} } = props;
    const { userDetails = {} } = props;
    const [selectedZone, setSelectedZone] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [userData, setData] = useState([])
    const [usersData, setUsersData] = useState([])
    const [page, setPage] = useState(0);
    const [workspaceAccessRequest, setWorkspaceAccessRequest] = useState([]);
    const [datasetRequests, setDatasetRequests] = useState([]);
    const [zone3Requests, setZone3Requests] = useState([]);
    const [ownershipsRequests, setOwnershipsRequests] = useState([]);
    const [workspaceUserProfileDetails, setWorkspaceUserProfileDetails] = useState({});
    const [workspaceUsersProfilePic, setWorkspaceUsersProfilePic] = useState({});
    const [zone1DatasetCount, setZone1DatasetCount] = useState(0);
    const [zone2DatasetCount, setZone2DatasetCount] = useState(0);
    const [zone3DatasetCount, setZone3DatasetCount] = useState(0);
    const [allProjectsDetails, setAllProjectsDetails] = useState([]);
    const [allZonesData, setAllZonesData] = useState([]);
    const [selectedZoneData, setSelectedZoneData] = useState({});
    const [viewDatasetDetails, setViewDatasetDetails] = useState({});
    const [hideAllDetails, setHideAllDetails] = useState(true);
    const [allDCARequests, setAllDCARequests] = useState([]);
    const [difRequestData, setDifRequestData] = useState([]);
    const [userSortOption, setUserSortOption] = useState('User Name');
    const [filteredDcaRequest, setFilteredDcaRequest] = useState([]);
    const [filteredDifRequest, setFilteredDifRequest] = useState([]);
    const [manualUploadedDca, setManualUploadedDca] = useState([]);

    const [workspaceOwnerships, setWorkspaceOwnerships] = useState([]);
    const [workspaceCarts, setWorkspaceCarts] = useState([]);

    const [selectedKeywords, setSelectedKeywords] = useState([])
    const [keywordsData, setKeywordsData] = useState({})
    const [barChartDataForFiles, setBarChartDataForFiles] = useState([]);
    const [yAxisValues, setYAxisValues] = useState([]);
    const [isPartnerAdmin, setIsPartnerAdmin] = useState(true)
    const [isProjectAdmin, setIsProjectAdmin] = useState(false)
    const [dashboardDataTitle, setDashboardDataTitle] = useState('Datasets & User Distribution Across Zones');
    const [DCADIFTitle, setDCADIFTitle] = useState('Submitted Data Contribution Agreement and Data Inventory Form');
    const [barChartData, setBarChartData] = useState([
        {
            name: 'Zone 1', count: 0, color: "#6A51A0"
        },
        {
            name: 'Zone 2', count: 0, color: "#EA5B71"
        },
        {
            name: 'Zone 3', count: 0, color: "#F8B64C"
        }
    ]);

    const [tabValue, setTabValue] = useState('workspace');
    const [tabValueKeyWord, setTabValueKeyWord] = useState(0);

    const [dashboardTitle, setDashboardTitle] = useState('Dashboard')
    const [currentTab, setCurrentTab] = useState(null)
    const [scrollPos, setScrollPos] = useState(0)
    const [tabRefs, setTabRefs] = useState([{ section: 'Datasets & User Distribution Across Zones', ref: projectAndUserDistibutionAcrossZones }])

    const Tab = withStyles(theme => ({
        root: {
            minWidth: 50,
            padding: '14px 6px',
            marginRight: 10,
            border: '2px solid #637BBD',
            color: '#637BBD',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            opacity: 1,
            borderBottom: 'none'
        },
        flexContainer: {
            border: '2px solid #637BBD'
        },
        selected: {
            color: "#000000",
            borderBottom: '2px solid #fafafa'

        }
    }))(MuiTab);
    const styledClasses = useStyles();

    const approveOrRejectKeywords = async (action, type) => {
        let body = {
            "accept": [],
            "reject": []
        }
        if(type==="pending" && keywordsData?.pending?.length){
            keywordsData.pending.map((keyword) => {
                if(keyword.isSelected){
                    if(action === 'approve'){
                        body['accept'].push(keyword.keyword)
                    }else {
                        body['reject'].push(keyword.keyword)
                    }     
                }
            });
        }else if(type === 'rejected' && keywordsData?.rejected?.length){
            keywordsData.rejected.map((keyword) => {
                if(keyword.isSelected){
                    body['accept'].push(keyword.keyword)
                }
            });
        }
        await approveCommunityKeywords(body);
        keyWordsData()
    }
 
    const handleChangeKeyWords = (event, newValue) => {
        setTabValueKeyWord(newValue);
    };

    useScrollToTop();

    useEffect(() => {
        setPage(0);
    }, [userData]);
    const getManuallyUploadedDCA = async () => {
        try {
            const res = await getAllManualDCAUploads();
            setManualUploadedDca(res);
        } catch (error) {
            setManualUploadedDca([]);
            console.error('error in getting dca upload');
        }
    }
    const getUnApproveDIFForms = async () => {
        try {
            const difRes = await getAllUnapprovedDIFFormsData();
            setDifRequestData(difRes);
            setFilteredDifRequest(difRes);
        } catch (error) {
            console.log('error in getting unapproved dif form ', error)
        }
    }
    
    const getUnApprovedDCAForms = async () => {
        try {
            const dcaRes = await getAllUnapprovedDCAFormsData();
            setAllDCARequests(dcaRes);
            setFilteredDcaRequest(dcaRes);
        } catch (error) {
            console.log('error in getting unapproved dca form ', error)
        }
    }
    const getUserRole = async () => {
        try {
            const user = await Auth.currentSession();
            const cognitoGroups = user?.accessToken?.payload?.["cognito:groups"] || [];
            const bcAdmin = cognitoGroups.find(item => item === "bc-admin")
            const partnerAdmin = cognitoGroups.find(item => item === "partner-admin")
            const projectAdministrator = cognitoGroups.find(item => item === "project-administrator")
            setIsProjectAdmin(!!projectAdministrator)
            const isSubmitter = cognitoGroups.find(item => item === "data-submitter")
            if ((isSubmitter) && !bcAdmin) {
                setHideAllDetails(true)
            } else {
                setHideAllDetails(false)
            }
            if (bcAdmin) {
                setViewDatasetDetails(true);
            }
            setIsPartnerAdmin(!!partnerAdmin)
        } catch (error) {
            setViewDatasetDetails(false)
        }
    }
    const loadZonesData = async () => {
        try {
            const res = await getDatasetForAllZones();
            let dataForBarChart = [];
            const histogram = res?.data?._aggregation?.study?.zone?.histogram;
            const zone1Details = find(histogram, (data) => get(data, 'key', 0) === ZONE_1_KEY);
            const zone2Details = find(histogram, (data) => get(data, 'key', 0) === ZONE_2_KEY);
            const zone3Details = find(histogram, (data) => get(data, 'key', 0) === ZONE_3_KEY);
            const zone1DatasetCount = zone1Details?.termsFields?.[0]?.terms?.length || 0;
            const zone2DatasetCount = zone2Details?.termsFields?.[0]?.terms?.length || 0;
            const zone3DatasetCount = zone3Details?.termsFields?.[0]?.terms?.length || 0;

            dataForBarChart = [
                {
                    name: 'Zone 1', count: zone1DatasetCount, color: "#58C9DD"
                },
                {
                    name: 'Zone 2', count: zone2DatasetCount, color: "#58C9DD"
                }, {
                    name: 'Zone 3', count: zone3DatasetCount, color: "#58C9DD"
                }
            ]

            setZone1DatasetCount(zone1DatasetCount);
            setZone2DatasetCount(zone2DatasetCount);
            setZone3DatasetCount(zone3DatasetCount);
            setBarChartData(dataForBarChart);
            setAllProjectsDetails(dataForBarChart);
            setAllZonesData(histogram);
        } catch (error) {
            setZone1DatasetCount(0);
            console.log('error in getting zones data', error)
        }
    }
    const getDatasetAccessRequests = async () => {
        try {
            const zone2Requests = await getZone2Requests();
            setDatasetRequests(zone2Requests?.all_zone2_request);
        } catch (error) {
            setDatasetRequests([]);
            console.log('error', error);
        }
    }
    const getZone3AccessRequests = async () => {
        try {
            const zone3Requests = await getZone3Requests();
            setZone3Requests(zone3Requests?.pending_zone3_request);
        } catch (error) {
            setZone3Requests([]);
            console.log('error', error);
        }
    }

    const getAllPendingApprovalWorkspaceCartsRequests = async (fetchAllCarts=false) => {
        try{
            const response = await getAllPendingApprovalWorkspaceCarts();
            if(fetchAllCarts){
                const allWorkspaceCarts = await getAllWorkspaceCarts();
                if(!isEmpty(allWorkspaceCarts)){
                    Store.dispatch(setAllWorkspacesCarts(allWorkspaceCarts))
                }
            }
            if (Array.isArray(response?.workspace_carts)) {
                for (let item of response.workspace_carts) {
                  let fileSize = 0;
                  const initiatedBy = `${item?.first_name} ${item?.last_name}`
                  item.initiatedBy = initiatedBy;
                  if(item?.files?.length){
                    for(let file of item.files){
                        fileSize = fileSize + file.size
                      }
                  }
                  item.fileSize = formatBytes(fileSize);
                }
              }
            setWorkspaceCarts(response?.workspace_carts);
        }catch (error){
            setWorkspaceCarts([])
            console.log(error)
        }
    }
    
    const getWorkspaceAccessRequest = async () => {
        try {
            let workspaceAccessRequest = await getAdvanceComputeRequests();
            let allUsersDetails = {};
            let usersProfilePicture = {};
            setWorkspaceAccessRequest(workspaceAccessRequest);
            map(workspaceAccessRequest, async (requestData) => {
                const userSub = get(requestData, 'user_sub', '');
                const res = await getUserProfile(userSub);
                const userDetailsRes = get(res, 'user_details', {});
                if (!isEmpty(userSub)) {
                    allUsersDetails = {
                        ...allUsersDetails,
                        [userSub]: userDetailsRes,
                    };
                }
                setWorkspaceUserProfileDetails(allUsersDetails);
                const profilePicRes = await getProfilePicture(userSub);
                if (!isEmpty(userSub)) {
                    usersProfilePicture = {
                        ...usersProfilePicture,
                        [userSub]: profilePicRes
                    }
                }
                setWorkspaceUsersProfilePic(usersProfilePicture);
            })
        } catch (error) {
            setWorkspaceAccessRequest([]);
            console.log('error', error);
        }
    }
    const getUsersData = async () => {
        let data = await getUserSearchAdminApi('', 'zone');
        const processData = {};
        Object.keys(data).map(key => {
            processData[key] = data[key].map(item => {
                item.searchKey = `${item.name} ${item.designation} ${item.organisation} ${item.user_role}`.toLowerCase();
                return item
            })
        });
        setUsersData(processData);
    }

    useEffect(() => {
        getUsersData();
    }, []);

    const fetchOwnerships = async () => {
        try {
          const response = await getAllWorkspaceOwnerShipDetails();
          if (Array.isArray(response)) {
            for (let item of response) {
              const owner = item?.workspace_owner || {};
              const recipient = item?.workspace_recipient || {}
              const initiatedBy = `${owner?.first_name} ${owner?.last_name}`
              const nominatedUser = `${recipient?.first_name} ${recipient?.last_name}`
              item.initiatedBy = initiatedBy;
              item.nominatedUser = nominatedUser;
            }
          }
          setWorkspaceOwnerships(response)
        }
        catch (error) {
          console.log(error)
        }
      }

    useEffect(() => {
        if (props.userDetails?.resourceActions?.dashboard?.list_advance_compute_requests) {
            getWorkspaceAccessRequest();
        }else{
            setTabValue('dca');
        }
        loadZonesData();
        getUserRole();
        getProjectFileMappingsData();

        if (props.userDetails?.resourceActions?.dashboard?.list_data_inventory_form) {
            getUnApproveDIFForms();
        }
        if (props.userDetails?.resourceActions?.dashboard?.list_data_contribution_agreement) {
            getUnApprovedDCAForms();
        }
        if (props.userDetails?.resourceActions?.dashboard?.list_manual_dca) {
            getManuallyUploadedDCA();
        }

        if (props.userDetails?.resourceActions?.dashboard?.list_zone2_access_requests) {
            getDatasetAccessRequests();
        }

        if (props.userDetails?.resourceActions?.dashboard?.list_zone3_access_requests) {
            getZone3AccessRequests();
            fetchOwnerships();
        }

        if (props.userDetails?.resourceActions?.dashboard?.view_workspace_files_download) {
            getAllPendingApprovalWorkspaceCartsRequests()
        }

        if(props.userDetails?.resourceActions?.dashboard?.view_workspace_summary || props.userDetails?.resourceActions?.dashboard?.view_review_and_approve_keywords){
            let sectionText = 'Workspace Summary & Review & Approve Keywords';
            if(props.userDetails?.resourceActions?.dashboard?.view_workspace_summary && !props.userDetails?.resourceActions?.dashboard?.view_review_and_approve_keywords){
                sectionText = 'Workspace Summary'
            }
            if(!props.userDetails?.resourceActions?.dashboard?.view_workspace_summary && props.userDetails?.resourceActions?.dashboard?.view_review_and_approve_keywords){
                sectionText = 'Review & Approve Keywords'
            }
            setTabRefs(tabRefs => [...tabRefs, { section: sectionText, ref: workspaceAndCostSummary }])
        }

        if(props.userDetails?.resourceActions?.dashboard?.view_uploaded_dca_documents){
            setTabRefs(tabRefs => [...tabRefs, { section: 'Uploaded DCA Documents', ref: uploadedDcaDocuments }])
        }

        if(props.userDetails?.resourceActions?.dashboard?.view_agreements_and_requests){
            setTabRefs(tabRefs => [...tabRefs, { section: 'Agreements & Requests', ref: agreementsAndRequest }])
        }
        

        let dataDistributionTitle = "";
        let DCADIFFormTitle = "";
        let userAccess = false;
        let datasetAccess = false;
        if (props.userDetails?.resourceActions?.dashboard?.view_zone1_users ||
            props.userDetails?.resourceActions?.dashboard?.view_zone2_users ||
            props.userDetails?.resourceActions?.dashboard?.view_zone3_users) {
            userAccess = true;
        }
        if (props.userDetails?.resourceActions?.dashboard?.view_zone1_datasets
            || props.userDetails?.resourceActions?.dashboard?.view_zone2_datasets
            || props.userDetails?.resourceActions?.dashboard?.view_zone3_datasets) {
            datasetAccess = true;
        }
        if (userAccess && !datasetAccess) {
            dataDistributionTitle = 'User Distribution Across Zones';
        } else if (!userAccess && datasetAccess) {
            dataDistributionTitle = 'Datasets Distribution Across Zones';
        }
        if (dataDistributionTitle) {
            setDashboardDataTitle(dataDistributionTitle);
        }

        if (props.userDetails?.resourceActions?.dashboard?.list_data_inventory_form
            && !props.userDetails?.resourceActions?.dashboard?.list_data_contribution_agreement) {
            DCADIFFormTitle = 'Submitted Data Inventory Form';
        }
        if (!props.userDetails?.resourceActions?.dashboard?.list_data_inventory_form
            && props.userDetails?.resourceActions?.dashboard?.list_data_contribution_agreement) {
            DCADIFFormTitle = 'Submitted Data Contribution Agreement';
        }
        if (DCADIFFormTitle) {
            setDCADIFTitle(DCADIFFormTitle);
        }

    }, [props.userDetails]);

    function createData(name, calories, fat, carbs, guid, protein) {
        return { name, calories, fat, carbs, guid, protein };
    }

    const getProjectFileMappingsData = async () => {
        try {
            let data = await getProjectFileMappings();
            let filesData = [];
            let yAxisValues = [];

            if (data && !Object.values(data).every(o => o === null)) {
                Object.keys(data).map(function (keyName, keyIndex) {
                    let obj = {
                        "name": keyName
                    }
                    Object.keys(data[keyName]).map(function (k, i) {
                        obj[k] = data[keyName][k];
                        yAxisValues.push(k);
                    })
                    filesData.push(obj);
                });
                yAxisValues = uniq(yAxisValues);
                setBarChartDataForFiles(filesData);
                setYAxisValues(yAxisValues);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const sortUsersBySelectedOption = (option, data = []) => {
        let sortedData = [];
        switch (option) {
            case USER_SORT_OPTIONS[0]: {
                sortedData = sortBy(data, (item) => lowerCase(item?.name));
                break;
            }
            case USER_SORT_OPTIONS[1]: {
                sortedData = sortBy(data, (item) => {
                    const userRoles = item?.user_roles || [];
                    let roleArr = [];
                    map(userRoles, (role) => roleArr.push(role?.role_label));
                    const roles = roleArr.join(', ');
                    return lowerCase(roles);
                }
                );
                break;
            }
            case USER_SORT_OPTIONS[2]: {
                sortedData = sortBy(data, (item) => lowerCase(item?.organisation));
                break;
            }
            default: sortedData = []
        }
        return sortedData;
    }

    useEffect(() => {
        if (props.location?.state?.selectedUser) {
            onViewUserClick(props.location.state.selectedUser);
        }
    }, [usersData]);

    useEffect(()=>{
        keyWordsData(); 
    }, [])

    const onViewUserClick = zone => {
        let zoneData = [];
        switch (zone) {
            case 1: {
                setSelectedUser(1);
                zoneData = sortUsersBySelectedOption(userSortOption, usersData.zone1);
                break;
            }
            case 2: {
                setSelectedUser(2);
                zoneData = sortUsersBySelectedOption(userSortOption, usersData.zone2)
                setData(usersData.zone2);
                break;
            }
            case 3: {
                setSelectedUser(3);
                zoneData = sortUsersBySelectedOption(userSortOption, usersData.zone3);
                break;
            }
            default: setSelectedUser(1); setData(usersData.zone1);
        }
        setSelectedZone(null);
        setData(zoneData);
    }
    // Remove this after integrating with API
    const keyWordsData =  async () => {
        const pendingKeywords = await getCommunityKeywords("pending");
        const rejectedKeywords = await getCommunityKeywords("rejected");
        let keywords = {
            "pending": pendingKeywords?.res,
            "rejected": rejectedKeywords?.res
        }
        // map(pendingKeywords?.res, async (keyword) => {
        //     const res = await getUserProfile(keyword?.create_user);
        //     const userDetailsRes = get(res, 'user_details', {});
        //     keyword.user = `${userDetailsRes?.first_name} ${userDetailsRes?.last_name}`
        // })
        // map(rejectedKeywords?.res, async (keyword) => {
        //     const res = await getUserProfile(keyword?.create_user);
        //     const userDetailsRes = get(res, 'user_details', {});
        //     keyword.user = `${userDetailsRes?.first_name} ${userDetailsRes?.last_name}`
        // })
        setKeywordsData(keywords)
    }

    const onEditKeyword = async (prevVal, newVal, action) => {
        try{
            let body = {
                "edit": {}
            }
            body['edit'][`${prevVal}`] = newVal;
            await approveCommunityKeywords(body);
            if(action === 'pending' && newVal !== ''){
                let cloneData = {...keywordsData};
                const matchedItem = cloneData?.pending?.find(x => x.keyword === prevVal);
                matchedItem.keyword = newVal;
                setKeywordsData(cloneData);
            }else if(action === 'rejected' && newVal !== ''){
                let cloneData = {...keywordsData};
                const matchedItem = cloneData?.rejected?.find(x => x.keyword === prevVal);
                matchedItem.keyword = newVal;
                setKeywordsData(cloneData);
            }
        }catch(error){
            console.log("Error", error)
        }
    }

    const onToggleKeyword = (label,action) => {
        if(action === 'pending'){
            let cloneData = {...keywordsData}
            const matchedItem = cloneData?.pending?.find(x => x.keyword === label);
            matchedItem.isSelected = !matchedItem.isSelected;
            setKeywordsData(cloneData)
        }else if(action === 'rejected'){
            let cloneData = {...keywordsData}
            const matchedItem = cloneData?.rejected?.find(x => x.keyword === label);
            matchedItem.isSelected = !matchedItem.isSelected;
            setKeywordsData(cloneData)
        }
    }

    const onToggleAllKeywords = (e,action) => {
        if(action === 'pending'){
            let pendingKeywords = {...keywordsData}
            pendingKeywords.pending = pendingKeywords.pending.map(item => {return {...item, isSelected: e.target.checked ? true : false}})
            setKeywordsData(pendingKeywords);
        }else if(action === 'rejected'){
            let rejectedKeywords = {...keywordsData}
            rejectedKeywords.rejected = rejectedKeywords.rejected.map(item => {return {...item, isSelected: e.target.checked ? true : false}})
            setKeywordsData(rejectedKeywords);
        }
    }

    const projectAndUserDistibutionAcrossZones = useRef(null),
        workspaceAndCostSummary = useRef(null),
        reviewAndApproveKeywords = useRef(null),
        uploadedDcaDocuments = useRef(null),
        agreementsAndRequest = useRef(null);

    const onUserZoneSelected = (zone) =>{
        setSelectedUser(zone);
    }

    const onDatasetZoneSelected = (zone) =>{
        setSelectedZone(zone)
    }

	useEffect(() => {
		if (dashboardTitle === 'Dashboard') {
			setTimeout(() => {
                window.scroll({
                    top: scrollPos - DEFAULT_HEADER_HEIGHT,
                    behavior: 'instant',
                });
            }, 1);
		} else {
            window.scroll(0, 0);
		}

    }, [dashboardTitle]);


    return (
        <div className={classes.mainContainer}>
           <SectionHeader sectionHeaderProps={{title: dashboardTitle}} open={props.open} />
           <div className={classes.dashboardHeader}>
            {!(selectedZone || selectedUser || currentTab !== null) ?
               <AdminDashboardTabs 
                    setScrollPos={setScrollPos}
                    tabRefs={tabRefs}
                /> :
                <div className={classes.innerContainer}>
                    <Grid container>
                        {
                            (selectedZone || selectedUser || currentTab !== null) &&
                                <div id='cvb-adminDashboard-backButtonContainer' className={classes.backButtonContainer} onClick={() => { setSelectedZone(null); setSelectedUser(null); setDashboardTitle('Dashboard'); setCurrentTab(null) }}>
                                    <p id='cvb-adminDashboard-backButtonText'><img id='cvb-adminDashboard-backButtonImage' src={BackButtonIcon} alt="black button" /> Back </p>
                                </div>
                        }
                    </Grid>
                </div>
          }
           </div>
            <div className={classes.innerContainer}>
                {
                    <>
                        {currentTab === null && <DatasetAndUsersDistribution
                            ref={projectAndUserDistibutionAcrossZones}
                            allZonesData={allZonesData}
                            selectedZone={selectedZone}
                            selectedUser={selectedUser}
                            onUserZoneSelected={onUserZoneSelected}
                            onDatasetZoneSelected={onDatasetZoneSelected}
                            usersData={usersData}
                            barChartData={barChartData}
                            allProjectsDetails={allProjectsDetails}
                            barChartDataForFiles={barChartDataForFiles}
                            yAxisValues={yAxisValues}
                            selectedUserFromQueryParams={props.location?.state?.selectedUser}
                            setDashboardTitle={setDashboardTitle}
                        />}
                    </>
                }


                {
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{gap: '24px'}}>
                        {!!((currentTab === null || currentTab === 'workspace') & !(selectedZone || selectedUser)) &&  props.userDetails?.resourceActions?.dashboard?.view_workspace_summary && 
                        
                        <Grid item xs>
                                <WorkspaceCostSummary 
                                    ref={workspaceAndCostSummary}
                                    currentTab={currentTab}
                                    setCurrentTab={setCurrentTab}
                                    setDashboardTitle={setDashboardTitle}
                                /> 
                        </Grid>}
                        
                        {!!((currentTab === null || currentTab === 'review-keywords') & !(selectedZone || selectedUser)) && props.userDetails?.resourceActions?.dashboard?.view_review_and_approve_keywords && 
                        <Grid item xs>
                            <ReviewApproveKeywords
                                ref={reviewAndApproveKeywords}
                                currentTab={currentTab}
                                setCurrentTab={setCurrentTab}
                                setDashboardTitle={setDashboardTitle}
                                setTabValueKeyWord={setTabValueKeyWord}
                                tabValueKeyWord={tabValueKeyWord}
                                keywordsData={keywordsData}
                                setKeywordsData={setKeywordsData}
                                styledClasses={styledClasses}
                                approveOrRejectKeywords={approveOrRejectKeywords}
                                onToggleAllKeywords={onToggleAllKeywords}
                                onEditKeyword={onEditKeyword}
                                onToggleKeyword={onToggleKeyword}
                            />
                        </Grid>}
                    </Grid>
                    
                }
                {
                    <>
                        {!!((currentTab === null || currentTab === 'upload-dca') & !(selectedZone || selectedUser)) && props.userDetails?.resourceActions?.dashboard?.view_uploaded_dca_documents &&
                            < UploadManualDCA
                                ref={uploadedDcaDocuments}
                                manualUploadedDca={manualUploadedDca}
                                currentUserDetails={currentUserDetails}
                                getManuallyUploadedDCA={getManuallyUploadedDCA}
                                setCurrentTab={setCurrentTab}
                                currentTab={currentTab}
                                setDashboardTitle={setDashboardTitle}
                            />}
                        {
                            ((currentTab === null || currentTab === 'agreements-and-requests') & !(selectedZone || selectedUser))
                             && props.userDetails?.resourceActions?.dashboard?.view_agreements_and_requests
                                ?
                                <PendingApprovals
                                    ref={agreementsAndRequest}
                                    workspaceAccessRequest={workspaceAccessRequest}
                                    workspaceOwnerships={workspaceOwnerships}
                                    workspaceCarts={workspaceCarts}
                                    getAllPendingApprovalWorkspaceCartsRequests={getAllPendingApprovalWorkspaceCartsRequests}
                                    datasetRequests={datasetRequests}
                                    filteredDcaRequest={filteredDcaRequest}
                                    filteredDifRequest={filteredDifRequest}
                                    zone3Requests={zone3Requests}
                                    ownershipsRequests={ownershipsRequests}
                                    getDatasetAccessRequests={getDatasetAccessRequests}
                                    getZone3AccessRequests={getZone3AccessRequests}
                                    getWorkspaceAccessRequest={getWorkspaceAccessRequest}
                                    currentTab={currentTab}
                                    setCurrentTab={setCurrentTab}
                                    pendingApprovals={keywordsData?.pending?.length || 0}
                                    setDashboardTitle={setDashboardTitle}
                                />
                                :
                                ((currentTab === null || currentTab === 'agreements-and-requests') & !(selectedZone || selectedUser))
                                && props.userDetails?.resourceActions?.dashboard?.list_data_contribution_agreement ?
                                <Grid item lg={12} md={12} xs={12}>
                                    {map(filteredDcaRequest, (requestData) => {
                                        return (
                                            <div className={classes.timeLineBlock}>
                                                <PendingApprovalsStepper
                                                    requestData={requestData}
                                                    tabValue={'dca'}
                                                    getDatasetAccessRequests={getDatasetAccessRequests}
                                                    getZone3AccessRequests={getZone3AccessRequests}
                                                    getWorkspaceAccessRequest={getWorkspaceAccessRequest}
                                                />
                                            </div>
                                        )
                                    })
                                    }
                                </Grid>
                                : null
                        }
                        

                    </>
                }
            </div>
        </div>
    )
    
}

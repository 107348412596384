import React, { useState } from 'react'
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip'
import classes from './index.module.css';
import EditIcon from '../../../assets/icons/edit_small.svg'
import InfoIcon from '../../../assets/icons/Info_small.svg'
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import OutlinedInput from '@mui/material/OutlinedInput'
import SuccessIcon from '../../../assets/icons/success.svg'

const ReviewKeyword = ({ onEditKeyword, label, type, isSelected, user, time, onToggleKeyword, keywordData }) => {
    const [isEditView, setIsEditView] = useState(false)
    const [newKeywordValue, setNewKeywordValue] = useState("")

    const handleEditKeyword = () => {
        onEditKeyword(label, newKeywordValue, type);
        setNewKeywordValue("")
        setIsEditView(false);
    }
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: "#FCF8C8",
            color: "#222222",
            boxShadow: "-1px 1px 106px -61px rgba(74,73,74,1);",
            fontSize: 14,
            fontWeight: "medium",
            marginRight: '3px',
            fontFamily: "Raleway",
            borderRadius: 0,
            padding: 15,
            textAlign: "left"
        },
        arrow: {
            color: "#FCF8C8"
        },
        customWidth: {
            maxWidth: 200,
        },
    }))(Tooltip);
    if (isEditView) {
        return <li className={classes.keyWordReviewListItems}>
            <div className={classes.keyWordsContentContainer}>
                <div className={classes.keyWordsContentLeftContainer}>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter keyword"
                        value={newKeywordValue || label}
                        onChange={e => setNewKeywordValue(e.target.value)} 
                        fullWidth
                    />
                </div>
                <div className={classes.keyWordsContentRightContainer}>
                    <div>
                        <span className={classes.iconsKeywordSubmit} onClick={handleEditKeyword}>
                            <img src={SuccessIcon} alt="edit icon" />
                        </span>
                    </div>
                </div>
            </div>
        </li >
    }
    return (
        <li className={classes.keyWordReviewListItems}>
            <div className={classes.keyWordsContentContainer}>
                <div className={classes.keyWordsContentLeftContainer}>
                    <Checkbox
                        checked={isSelected}
                        color="primary"
                        id='cvb-reviewKeyword-selectKeyWords'
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onChange={(e) => onToggleKeyword(label, type)}
                    />
                    <LightTooltip id='cvb-reviewKeyword-text-tooltip' title={label} arrow>
                        <span className={classes.keyWordText}>{label}</span>
                    </LightTooltip>
                </div>
                <div className={classes.keyWordsContentRightContainer}>
                    <div>
                        <span className={classes.iconsKeyWordEdit} onClick={() => setIsEditView(true)}>
                            <img src={EditIcon} alt="edit icon" />
                        </span>
                        <LightTooltip id='cvb-reviewKeyword-tooltip' title={`Submitted by: ${user} on ${time}`} arrow>
                            <span className={classes.iconsKeyWordInfo}><img src={InfoIcon} alt="info icon" /></span>
                        </LightTooltip>
                    </div>
                </div>
            </div>
        </li >
    )
}

const areEqual = (prevProps, nextProps) => {
    return prevProps.label !== nextProps.label &&
        prevProps.isSelected !== nextProps.isSelected
}

const MemorizedReviewKeyword = React.memo(ReviewKeyword, areEqual)

export default MemorizedReviewKeyword

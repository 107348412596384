import ChangePassword from './ChangePassword'
import { connect } from 'react-redux'
import { updateSnackBar } from '../../../redux/actions/globalActions'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
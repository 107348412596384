import {
    UPDATE_EXPLORE_DATA_FILTERS,
    UPDATE_EXPLORE_DATA,
    UPDATE_CUSTOMIZE_OPTIONS,
    UPDATE_EXPLORE_TOTAL_COUNT,
    UPDATE_EXCLUDE_FROM_EXPLORATION,
    UPDATE_EXPLORE_FILES_DATA,
    UPDATE_EXPLORE_DATA_LOADER,
    UPDATE_PROJECTS_COUNT,
    UPDATE_ACCESSIBLE_EXPLORE_TOTAL_COUNT,
    UPDATE_EXPLORE_DATA_FILTER_SELECTION,
    UPDATE_EXPLORE_SUN_BURST_GRAPH_DATA,
    UPDATE_EXPLORE_AGE_AT_ENROLLMENT_GRAPH_DATA,
    UPDATE_UNSTRUCTURED_FILES_GRAPH_DATA,
} from "../action-types"
import {
    getExploreDataFiltersApi,
    getExploreHomeDataApi,
    getExploreDataTotalCountApi,
    getExploreDataHierarchyFiltersApi,
    getSunBurstChartAPI,
    getAgeDistributionDataApi
} from '../../api/graphql/exploreData'
import {env} from '../../env'
import isEqual from 'lodash/isEqual'
import store from '../store'
import isEmpty from "lodash/isEmpty";
import map from 'lodash/map'
const updateAgeDistributionData = (dispatch, histograms) => {
    const maleRecord = histograms?.find(({ key }) => key === "Male")
    const femaleRecord = histograms?.find(({ key }) => key === "Female");
    const steps = {
        "0-20": 0,
        "20-40": 0,
        "40-60": 0,
        "60-80": 0,
        "80-100": 0
    }
    let maleSteps = { ...steps }
    let femaleSteps = { ...steps }
    const categories = [
        {
            records: maleRecord,
            steps: maleSteps
        },
        {
            records: femaleRecord,
            steps: femaleSteps
        },
    ]
    for (let cat of categories) {
        if (!isEmpty(cat.records)) {
            const terms = cat.records?.termsFields?.[0]?.terms;
            if(terms){
                for (let term of terms) {
                    switch (true) {
                        case term.key >= 0 && term.key < 20: {
                            cat.steps["0-20"] += term.count;
                            break;
                        }
                        case term.key >= 20 && term.key < 40: {
                            cat.steps["20-40"] += term.count;
                            break;
                        }
                        case term.key >= 40 && term.key < 60: {
                            cat.steps["40-60"] += term.count;
                            break;
                        }
                        case term.key >= 60 && term.key < 80: {
                            cat.steps["60-80"] += term.count;
                            break;
                        }
                        case term.key >= 80 && term.key < 100: {
                            cat.steps["80-100"] += term.count;
                            break;
                        }
                    }
                }
            }else{
                const restrictedStepData = {
                    "0-20": -1,
                    "20-40": -1,
                    "40-60": -1,
                    "60-80": -1,
                    "80-100": -1
                };
                maleSteps = {... restrictedStepData} 
                femaleSteps = {... restrictedStepData}
            }
            
        }
    }
    dispatch(updateAgeAtEnrollmentGraphData({
        "male": maleSteps,
        "female": femaleSteps
    }))
}

const parseFilterData = response => {
    let overAllProjects = response?._aggregation?.case?.project_id?.histogram;
    const unaccessibleProjects = response?._aggregation?.unaccessible_projects?.project_id?.histogram || []
    overAllProjects = overAllProjects.map((item) => {
        if (unaccessibleProjects.find(x => x.key === item.key)) {
            return { ...item, isAccessible: false }
        }
        return { ...item, isAccessible: true }
    })
    let otherFilters = {}

    const otherFilterKeys = [
        "age_at_enrollment",
        // "age_at_onset",
        "comorbidity",
        "data_type",
        "gender",
        "primary_diagnosis",
        "race",
        "ethnicity"
    ]

    for (let key of otherFilterKeys) {
        otherFilters[key] = {
            histogram: response?._aggregation?.case[key]?.histogram
        }
    }

    let filterItems = {
        project_id: {
            histogram: overAllProjects
        }
    }
    return { ...filterItems, ...otherFilters }
}

export const getExploreDataFilters = (filterValues) => async (dispatch) => {
    try {
        await dispatch(updateExploreDataTotalCount(-1))
        const response = await getExploreDataFiltersApi(filterValues);
        const totalCount = (response?._aggregation?.case?._totalCount || 0)
        const totalAccessibleCount = (response?._aggregation?.accessible_case_count?._totalCount || 0)
        await dispatch(updateExploreDataTotalCount(totalCount))
        dispatch(updateExploreDataFilters(parseFilterData(response)));
        dispatch(updateProjectCount(response?._aggregation?.project_id?.project_id?.histogram?.length))
        await dispatch(updateAccessibleTotalCount(totalAccessibleCount))
    }
    catch (error) {
        console.log(error)
        dispatch(updateExploreDataFilters({}));
    }
}

export const constructFilterQuery = (appliedFilters) => {
    let queryFilter = {
        "AND": []
    }
    let hiddenProjects = `${env.REACT_APP_PROJECT_HIDDEN}`.split(',');
    let hiddenProjectFilters = [];
    map(hiddenProjects, (project)=> hiddenProjectFilters.push({"!=": {"project_id": project.trim()}}));

    if (!isEmpty(appliedFilters)) {
        const filterKeys = Object.keys(appliedFilters)
        for (let key of filterKeys) {
            if (Array.isArray(appliedFilters[key])) {
                queryFilter.AND.push(
                    {
                    "gte": {
                        [key]: appliedFilters[key][0]
                    }
                },
                    {
                        "lte": {
                            [key]: appliedFilters[key][1]
                        }
                    }
                )
            } else {
                const selectedValues = Object.keys(appliedFilters[key]);
                if (selectedValues.length > 0) {
                    queryFilter.AND.push(
                    {
                        "IN": { [key]: selectedValues }
                    })
                }
            }

        }
    }
    queryFilter = {
        "AND": [
            ...queryFilter["AND"],
            ...hiddenProjectFilters
        ]
    }
    return queryFilter
}

export const getExploreDataHierarchyFilters = (appliedFilters = {}, currentOverallProject) => async (dispatch) => {
    try {
        await dispatch(updateExploreDataTotalCount(-1))
        const response = await getExploreDataHierarchyFiltersApi(constructFilterQuery(appliedFilters));
        const totalCount = (response?._aggregation?.case?._totalCount || 0)
        const totalAccessibleCount = (response?._aggregation?.accessible_case_count?._totalCount || 0);

        await dispatch(updateExploreDataTotalCount(totalCount));
        const subBurstHistogram = response?._aggregation?.sub_burst_graph?.meddra_soc?.histogram || [];
        dispatch(updateSunBurstGraphData(subBurstHistogram))

        const fileDataHistogram = response?._aggregation?.file_data_type?.data_type?.histogram || [];
        dispatch(updateStructuredFilesGraphData(fileDataHistogram))

        const ageDistributionDataHistograms = response?._aggregation?.age_distribution_graph?.gender?.histogram || [];
        if(!isEmpty(ageDistributionDataHistograms)){
            updateAgeDistributionData(dispatch, ageDistributionDataHistograms);
        }else{
            dispatch(updateAgeAtEnrollmentGraphData({}))
        }
       
        let overAllProjects = isEmpty(currentOverallProject)? response?._aggregation?.case?.project_id?.histogram : currentOverallProject;
        const unaccessibleProjects = response?._aggregation?.unaccessible_projects?.project_id?.histogram || []
        overAllProjects = overAllProjects?.map((item) => {
            if (unaccessibleProjects.find(x => x.key === item.key)) {
                return { ...item, isAccessible: false }
            }
            return { ...item, isAccessible: true }
        }) || []
        response._aggregation.case.project_id.histogram = overAllProjects
        dispatch(updateExploreDataFilters(response?._aggregation?.case));
        dispatch(updateProjectCount(response?._aggregation?.project_id?.project_id?.histogram?.length))
        await dispatch(updateAccessibleTotalCount(totalAccessibleCount))
        return;
    }
    catch (error) {
        console.log(error)
        dispatch(updateExploreDataFilters({}));
    }
}

export const getSunBurstChartData = (appliedFilters = {}) => async (dispatch) => {
    try {
        let queryFilter = {
            "AND": []
        }
        if (!isEmpty(appliedFilters)) {
            const filterKeys = Object.keys(appliedFilters)
            for (let key of filterKeys) {
                if (Array.isArray(appliedFilters[key])) {
                    queryFilter.AND.push({
                        "gte": {
                            [key]: appliedFilters[key][0]
                        }
                    },
                        {
                            "lte": {
                                [key]: appliedFilters[key][1]
                            }
                        }
                    )
                } else {
                    const selectedValues = Object.keys(appliedFilters[key]);
                    if (selectedValues.length > 0) {
                        queryFilter.AND.push({
                            "IN": { [key]: selectedValues }
                        })
                    }
                }

            }
        }
        const response = await getSunBurstChartAPI(queryFilter);
        const histograms = response?._aggregation?.case?.meddra_soc?.histogram || [];
        dispatch(updateSunBurstGraphData(histograms))
    }
    catch (error) {
        console.log(error)
    }
}

export const getAgeDistributionData = (appliedFilters = {}) => async (dispatch) => {
    try {
        let queryFilter = {
            "AND": []
        }
        if (!isEmpty(appliedFilters)) {
            const filterKeys = Object.keys(appliedFilters)
            for (let key of filterKeys) {
                if (Array.isArray(appliedFilters[key])) {
                    queryFilter.AND.push({
                        "gte": {
                            [key]: appliedFilters[key][0]
                        }
                    },
                        {
                            "lte": {
                                [key]: appliedFilters[key][1]
                            }
                        }
                    )
                } else {
                    const selectedValues = Object.keys(appliedFilters[key]);
                    if (selectedValues.length > 0) {
                        queryFilter.AND.push({
                            "IN": { [key]: selectedValues }
                        })
                    }
                }

            }
        }
        const response = await getAgeDistributionDataApi(queryFilter);
        const histograms = response?._aggregation?.case?.gender?.histogram || [];
        if(!isEmpty(histograms)){
            updateAgeDistributionData(dispatch, histograms);
        }else{
            dispatch(updateAgeAtEnrollmentGraphData({}))
        }
       
    }
    catch (error) {
        console.log(error)
    }
}

export const updateExploreDataFiltersCount = (filterValues) => async (dispatch) => {
    try {
        // await dispatch({ type: UPDATE_EXPLORE_DATA_LOADER, payload: true })
        await dispatch(updateExploreDataTotalCount(-1))
        await dispatch(updateAccessibleTotalCount(-1))
        const response = await getExploreDataFiltersApi(filterValues);
        const totalCount = (response?._aggregation?.case?._totalCount || 0)
        const totalAccessibleCount = (response?._aggregation?.accessible_case_count?._totalCount || 0)
        const parsedData = parseFilterData(response)

        const oldValues = JSON.parse(JSON.stringify(filterValues))

        const allKeys = Object.keys(oldValues)
        for (let key of allKeys) {
            const currentHistogram = oldValues[key].histogram
            for (let hist of currentHistogram) {
                const matchedItem = parsedData[key].histogram.find(x => isEqual(x.key, hist.key))
                hist.count = matchedItem?.count || 0
            }
        }
        await dispatch(updateExploreDataFilters(oldValues))
        await dispatch(updateExploreDataTotalCount(totalCount))
        await dispatch(updateAccessibleTotalCount(totalAccessibleCount))
        // await dispatch({ type: UPDATE_EXPLORE_DATA_LOADER, payload: false })
        dispatch(updateProjectCount(response?._aggregation?.project_id?.project_id?.histogram?.length))
    }
    catch (error) {
        console.log(error)
        // await dispatch({ type: UPDATE_EXPLORE_DATA_LOADER, payload: false })
    }
}



export const getExploreDataTotalCount = (filterValues, excludeFromExploration = [], processedFilterValues) => async dispatch => {
    try {
        await dispatch(updateExploreDataTotalCount(-1))
        await dispatch(updateAccessibleTotalCount(-1))
        const response = await getExploreDataTotalCountApi(filterValues, excludeFromExploration, processedFilterValues);
        await dispatch(updateExploreDataTotalCount(response?._aggregation?.case?.["_totalCount"] || 0))
        await dispatch(updateAccessibleTotalCount(response?._aggregation?.accessible_case_count?.["_totalCount"] || 0))
        return response?._aggregation?.case?.["_totalCount"] || 0
    }
    catch (error) {
        console.log(error)
    }
}



export const updateExploreDataFilters = value => ({ type: UPDATE_EXPLORE_DATA_FILTERS, payload: value });
export const updateProjectCount = value => ({ type: UPDATE_PROJECTS_COUNT, payload: value })
export const updateExcludeFromExploration = value => ({ type: UPDATE_EXCLUDE_FROM_EXPLORATION, payload: value })
const updateCustomizeOptions = value => ({ type: UPDATE_CUSTOMIZE_OPTIONS, payload: value })
export const updateExploreDataTotalCount = value => ({ type: UPDATE_EXPLORE_TOTAL_COUNT, payload: value })
export const updateAccessibleTotalCount = value => ({ type: UPDATE_ACCESSIBLE_EXPLORE_TOTAL_COUNT, payload: value })
export const updateExploreDataFiltersSelection = value => ({ type: UPDATE_EXPLORE_DATA_FILTER_SELECTION, payload: value })
export const updateSunBurstGraphData = value => ({ type: UPDATE_EXPLORE_SUN_BURST_GRAPH_DATA, payload: value })
export const updateAgeAtEnrollmentGraphData = value => ({ type: UPDATE_EXPLORE_AGE_AT_ENROLLMENT_GRAPH_DATA, payload: value })
export const updateStructuredFilesGraphData = value => ({ type: UPDATE_UNSTRUCTURED_FILES_GRAPH_DATA, payload: value })

export const updateExploreFilesData = value => ({ type: UPDATE_EXPLORE_FILES_DATA, payload: value })

const removeItemFromArray = (array, item) => {
    const arrayClone = [...array]
    const index = arrayClone.indexOf(item);
    if (index > -1) {
        arrayClone.splice(index, 1);
    }
    return arrayClone;
}

import DIFTimeline from './DIFTimeline'
import { connect } from 'react-redux'
import { setDifTimelineIsDeadlineToUpload, setDifTimelineIsDeadlineToDataAccess, setDifTimelineIsDataEmbargoed 
    , setDifTimelineSubmissionDate, setDifTimelineDataAccessDeadlineDate, setDifTimelineDataEmbargoedDate
    , setDifTimelineDataEmbargoedDesc
} from '../../../redux/actions/difTimelineActions'
const mapStateToProps = state => {
    return {
        difTimelineIsDeadlineToUpload: state.difTimeline.difTimelineIsDeadlineToUpload,
        difTimelineIsDeadlineToDataAccess: state.difTimeline.difTimelineIsDeadlineToDataAccess,
        difTimelineIsDataEmbargoed: state.difTimeline.difTimelineIsDataEmbargoed,
        difTimelineSubmissionDate: state.difTimeline.difTimelineSubmissionDate,
        difTimelineDataAccessDeadlineDate: state.difTimeline.difTimelineDataAccessDeadlineDate,
        difTimelineEmbargoedDate : state.difTimeline.difTimelineEmbargoedDate,
        difTimelineEmbargoedDesc: state.difTimeline.difTimelineEmbargoedDesc,
        currentDIFFormStage: state.dataInventory.currentDIFFormStage
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setDifTimelineIsDeadlineToUpload: async (value) => await dispatch(setDifTimelineIsDeadlineToUpload(value)),
        setDifTimelineIsDeadlineToDataAccess: async (value) => await dispatch(setDifTimelineIsDeadlineToDataAccess(value)),
        setDifTimelineIsDataEmbargoed: async (value) => await dispatch(setDifTimelineIsDataEmbargoed(value)),
        setDifTimelineSubmissionDate: async (value) => await dispatch(setDifTimelineSubmissionDate(value)),
        setDifTimelineDataAccessDeadlineDate: async (value) => await dispatch(setDifTimelineDataAccessDeadlineDate(value)),
        setDifTimelineDataEmbargoedDate: async (value) => await dispatch(setDifTimelineDataEmbargoedDate(value)),
        setDifTimelineDataEmbargoedDesc: async (value) => await dispatch(setDifTimelineDataEmbargoedDesc(value)),
        
        
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DIFTimeline);
import WorkspaceCostSummary from './WorkspaceCostSummary'
import { connect } from 'react-redux'
import { updateSnackBar } from '../../../redux/actions/globalActions'
const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = ({
    updateSnackBar
})
export default connect(mapStateToProps, mapDispatchToProps, null,{forwardRef: true})(WorkspaceCostSummary);
import React from 'react';
import { Slider } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomSlider = styled(Slider)(({ theme }) => ({
  color: 'rgba(77, 128, 198, 1)',
  height: 2,
  width: 352,
  padding: '15px 0px',
  '& .MuiSlider-valueLabel': {
    fontSize: 14,
    fontWeight: 'normal',
    top: 36,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'unset',
    color: 'rgba(77, 128, 198, 1)',
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: 'rgba(77, 128, 198, 1)',
    },
  },
}));

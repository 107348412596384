import { navigate } from '@reach/router'
import React, { useState, useEffect } from 'react'
import classes from './OrderSuccess.module.css'
import BCDataPortalLogo from '../../../assets/images/onboarding_logo.png'
import strokesBG from '../../../assets/images/strokes-bg.png'
import Grid from '@mui/material/Grid'
import cartSuccess from '../../../assets/images/cartSuccess.svg';
import SectionHeader from '../../commons/section-header'

const OrderSuccess = (props) => {

    const {workspaceId, orderId, projectId, selectedPricingOptionDetails} = props;
    

    const viewMyStuff = () => {
        const navigateTo = workspaceId ? '/explore/tools/workspace' : '/explore/project-gallery'
        navigate(navigateTo);
    }

    return (
        <div className={classes.mainContainer}>

            <Grid className={classes.backroundImageContainer}>
                <img className={classes.strokesImage} src={strokesBG} alt='strokes background' />
            </Grid>

            <div id="cvb-orderSuccess-box" className={classes.successBox}>
                        
                <Grid container>
                    <Grid lg={12}>
                        <img src={cartSuccess}  />
                    </Grid>
                
            
                    <Grid lg={12}>
                        <div id="cvb-orderSuccess-title" className={classes.successTitle}>
                        Thank you for your order {orderId}
                            
                        </div>
                        <div id="cvb-orderSuccess-subtitle" className={classes.successSubTitle}>
                        
                        {
                           selectedPricingOptionDetails?.name?.toLowerCase()?.includes('s3') ? 'The files will be copied to your S3 bucket immediately. Please allow some time for the transfer to complete, but if you haven’t received them within 24 hours please contact us and we will be happy to help.'
                           : 'The files will be be downloaded immediately. Please allow some time for the download to complete.'
                        }
                        </div>

                        <div id="cvb-orderSuccess-subtitle2" className={classes.successSubTitle}>You can also start a new order if you wish to download more files.</div>

                        <button onClick={viewMyStuff} className={`medium-size-button ${classes.viewMyStuffButton}`}>
                            {workspaceId ? 'View Workspaces To Download Files' : 'View Project Gallery To Download Files'}
                        </button>
                    </Grid>
                </Grid>

            </div>
            
        </div>
    )
}

export default OrderSuccess
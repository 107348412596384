import React from 'react'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import Classes from './index.module.css'
import ErrorText from '../error-text';
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { DIF_FORM_COMPLETED_STATUS } from '../../../constants/strings'
import CommonDatePicker from '../../commons/common-date-picker'
const DIFTimeline = props => {
    const {
        difTimelineIsDeadlineToUpload,
        difTimelineIsDeadlineToDataAccess,
        difTimelineIsDataEmbargoed,
        difTimelineSubmissionDate,
        difTimelineDataAccessDeadlineDate,
        difTimelineEmbargoedDate,
        difTimelineEmbargoedDesc,
        isFormSubmitted,
        currentDIFFormStage,

        setDifTimelineIsDeadlineToUpload,
        setDifTimelineIsDeadlineToDataAccess,
        setDifTimelineIsDataEmbargoed,
        setDifTimelineSubmissionDate,
        setDifTimelineDataAccessDeadlineDate,
        setDifTimelineDataEmbargoedDate,
        setDifTimelineDataEmbargoedDesc
    } = props;
    const disableInput = currentDIFFormStage === DIF_FORM_COMPLETED_STATUS;

    const onUploadDeadlineRadioChange = value => {
        setDifTimelineIsDeadlineToUpload(value);
    }
    const onDataAccessibleChange = value => {
        setDifTimelineIsDeadlineToDataAccess(value);
    }
    const onDataEmbargoedChange = value => {
        setDifTimelineIsDataEmbargoed(value);
    }
    const onSubmissionDateChange = date => {
        setDifTimelineSubmissionDate(date);
    }
    const onDataAccessDeadlineDateChange = date => {
        setDifTimelineDataAccessDeadlineDate(date);
    }
    const onTillDateChange = date => {
        setDifTimelineDataEmbargoedDate(date);
    }
    const onDescriptionChange = event => {
        setDifTimelineDataEmbargoedDesc(event.target.value);
    }

    return (
        <div className={Classes.container} id="cvb-DIFTimeline-mainContainer">
            <p className={Classes.formTitle} id="cvb-DIFTimeline-timelineHeading">Timeline</p>
            <Grid container direction='column' spacing={3}>
                <Grid item xs={10} >
                    <label id="cvb-DIFTimeline-deadLineLabel">Is there a deadline by which these data must be uploaded to the BRAINCommons?<sup> *</sup></label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFTimeline-deadLineYesLabel">Yes</label>
                            <input id='isDeadlineToUpload1' type="radio" checked={difTimelineIsDeadlineToUpload === 'yes'}
                                disabled={disableInput}
                                onChange={() => onUploadDeadlineRadioChange('yes')} name="isDeadlineToUpload" />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFTimeline-deadLineNoLabel">No</label>
                            <input id='isDeadlineToUpload2' type="radio" checked={difTimelineIsDeadlineToUpload === 'no'}
                                disabled={disableInput}
                                onChange={() => onUploadDeadlineRadioChange('no')} name="isDeadlineToUpload" />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                    {
                        isFormSubmitted && isEmpty(difTimelineIsDeadlineToUpload) &&
                        <ErrorText errorMessage="Select value" />
                    }
                </Grid>
                {difTimelineIsDeadlineToUpload === 'yes' &&
                    <Grid item xs={5} id="cvb-DIFTimeline-deadLineSubmissionDatePicker">
                        <label id="cvb-DIFTimeline-deadLineSubmissionLabel">Deadline for submission<sup> *</sup></label>
                        <CommonDatePicker
                            onCalenderDateSelected={onSubmissionDateChange}
                            date={difTimelineSubmissionDate}
                            minDate={new Date()}
                            showValidationError={isFormSubmitted && (difTimelineSubmissionDate === null || !moment.isDate(difTimelineSubmissionDate))}
                            errorMessage='Select date'
                            id='cvb-dif-timeline-subDeadline'
                            disabled={disableInput}
                            canClearInput
                        />
                    </Grid>}
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={10} >
                    <label id="cvb-DIFTimeline-isDataAccessibleDeadlineLabel">Is there a deadline by which these data will need to be accessible to users of the BRAINCommons?<sup> *</sup></label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFTimeline-isDataAccessibleDeadlineYesLabel">Yes</label>
                            <input id='isDataAccessibleDeadline1' name="isDataAccessibleDeadline" type="radio"
                                disabled={disableInput}
                                checked={difTimelineIsDeadlineToDataAccess === 'yes'} onChange={() => onDataAccessibleChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFTimeline-isDataAccessibleDeadlineNoLabel">No</label>
                            <input id='isDataAccessibleDeadline2' name="isDataAccessibleDeadline" type="radio"
                                disabled={disableInput}
                                checked={difTimelineIsDeadlineToDataAccess === 'no'} onChange={() => onDataAccessibleChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                    {
                        isFormSubmitted && (isEmpty(difTimelineIsDeadlineToDataAccess)) &&
                        <ErrorText errorMessage="Select value" />
                    }
                </Grid>
                {difTimelineIsDeadlineToDataAccess === 'yes' &&
                    <Grid item xs={5} id="cvb-DIFTimeline-deadlineAccessDataPicker">
                        <label id="cvb-DIFTimeline-deadlineAccessLabel">Deadline for Data Access<sup> *</sup></label>
                        <CommonDatePicker
                            onCalenderDateSelected={onDataAccessDeadlineDateChange}
                            date={difTimelineDataAccessDeadlineDate}
                            minDate={new Date()}
                            showValidationError={isFormSubmitted && (difTimelineDataAccessDeadlineDate === null || !moment.isDate(difTimelineDataAccessDeadlineDate))}
                            errorMessage='Select date'
                            id='cvb-dif-timeline-dataAccessDeadline'
                            disabled={disableInput}
                            canClearInput
                        />
                    </Grid>}
                <Grid item xs={10}>
                    <div className={Classes.dottedLine}></div>
                </Grid>
                <Grid item xs={12} >
                    <label id="cvb-DIFTimeline-deadlineEmbargoed">Is the data embargoed?<sup> *</sup></label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFTimeline-deadlineYesEmbargoed">Yes</label>
                            <input id='isDataEmbargoed1' name="isDataEmbargoed" type="radio"
                                disabled={disableInput}
                                checked={difTimelineIsDataEmbargoed === 'yes'} onChange={() => onDataEmbargoedChange('yes')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-DIFTimeline-deadlineNoEmbargoed">No</label>
                            <input id='isDataEmbargoed2' name='isDataEmbargoed' type="radio"
                                disabled={disableInput}
                                checked={difTimelineIsDataEmbargoed === 'no'} onChange={() => onDataEmbargoedChange('no')} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                    {
                        isFormSubmitted && isEmpty(difTimelineIsDataEmbargoed) &&
                        <ErrorText errorMessage="Select value" />
                    }
                </Grid>
                {difTimelineIsDataEmbargoed === 'yes' &&
                    <Grid item xs={12}>
                        <Grid item xs={10} >
                            <label id="cvb-DIFTimeline-descriptionLabel">Description<sup> *</sup></label>
                            <textarea placeholder='Enter description' onChange={onDescriptionChange}
                                disabled={disableInput} id="isDataEmbargoed1Desc"
                                className={Classes.textAreaClass} rows={5} value={difTimelineEmbargoedDesc}>

                            </textarea>
                        {
                            isFormSubmitted && isEmpty(difTimelineEmbargoedDesc) &&
                            <ErrorText errorMessage="Enter text" />
                        }
                        </Grid>
                    <Grid item xs={5} id="cvb-DIFTimeline-tillDatePickerContainer">
                        <label id="cvb-DIFTimeline-tillLabel">Till<sup> *</sup></label>
                        <CommonDatePicker
                            onCalenderDateSelected={onTillDateChange}
                            date={difTimelineEmbargoedDate}
                            minDate={new Date()}
                            showValidationError={isFormSubmitted && (difTimelineEmbargoedDate === null || !moment.isDate(difTimelineEmbargoedDate))}
                            errorMessage='Select date'
                            id='cvb-dif-timeline-tillDate'
                            disabled={disableInput}
                            canClearInput
                        />
                    </Grid>
                    </Grid>
                }
            </Grid>
        </div>
    )
}

DIFTimeline.defaultProps = {
    difTimelineIsDeadlineToUpload: '',
    difTimelineIsDeadlineToDataAccess: '',
    difTimelineIsDataEmbargoed: '',
    difTimelineSubmissionDate: null,
    difTimelineDataAccessDeadlineDate: null,
    difTimelineEmbargoedDate: null,
    difTimelineEmbargoedDesc: '',
    isFormSubmitted: false,
    currentDIFFormStage: '',
    setDifTimelineIsDeadlineToUpload: () => { },
    setDifTimelineIsDeadlineToDataAccess: () => { },
    setDifTimelineIsDataEmbargoed: () => { },
    setDifTimelineSubmissionDate: () => { },
    setDifTimelineDataAccessDeadlineDate: () => { },
    setDifTimelineDataEmbargoedDate: () => { },
    setDifTimelineDataEmbargoedDesc: () => { }
}
export default DIFTimeline;

import React, { useState, useEffect } from 'react'

import map from 'lodash/map'
import classes from './index.module.css';
import { isEmpty } from 'lodash';

const PendingApprovalCard = (props) => {
    const {value, title} = props

    const splittedTitle = title.split(' ')

    return (
        <>
            <div className={classes.cardContainer} style={{backgroundColor: props.backgroundColor, borderTop: `4px solid ${props.topBorderColor}`}}>
                <p className={classes.cardTitle}>
                    {splittedTitle.length === 2 ? 
                        <>
                            <span>{splittedTitle[0]}</span>
                            <br />
                            <span>{splittedTitle[1]}</span>
                        </> : <>
                            <span>{splittedTitle[0]} {splittedTitle[1]}</span> 
                            <br />   
                            <span>{splittedTitle[2]} {splittedTitle[3]}</span>
                        </>}
                </p>
                {value?.length !== 0 && <p className={classes.cardValue}>{value}</p>}
            </div>
        </>
    )
}

export default PendingApprovalCard
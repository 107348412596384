import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
// Table imports
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import fill from 'lodash/fill';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import v4 from 'uuid/v4';
import { getTransactionLogs, getTransactionLogsCount } from '../../../api/graphql/history';
import disabledIcon from '../../../assets/icons/disabled.svg';
import SearchIcon from '../../../assets/icons/search-icon.svg';
import EnabledTickIcon from '../../../assets/icons/success.svg';
import DropDown from '../../commons/dropdown';
import TablePagination from '../../commons/table-pagination';
import classes from './index.module.css';

const SORT_OPTIONS = ["ID", "Submitter", "Project", "Uploaded on"]

const UploadsHistory = () => {
    const [transactionLogs, setTransactionLogs] = useState([])
    const [pageNumber, setPageNumber] = useState(-1)
    const [sortBy, setSortBy] = useState(SORT_OPTIONS[0])
    const [loadingTableData, setLoadingTableData] = useState(false)
    const [tableSearch, setTableSearch] = useState("")

    const [totalCount, setTotalCount] = useState(-1)

    const [filtersData, setFiltersData] = useState({})
    const [filterCollapseState, setFilterCollapseState] = useState([])
    const [showMoreItems, setShowMoreItems] = useState([]);
    const [filteredTransactionLogs, setFilteredTransactionLogs] = useState([])
    const [userPageInputValue, setUserPageInputValue] = useState(1);

    useEffect(() => {
        if (totalCount != -1) {
            setTimeout(() => {
                setPageNumber(0)
            }, 100)
            setPageNumber(-1)
        }
    }, [totalCount])

    useEffect(() => {
        if (pageNumber != -1) {
            loadData();
        }
    }, [sortBy, pageNumber])

    useEffect(() => {
        loadTotalCount()
    }, [])


    useEffect(() => {
        let matchedValues = null;
        if (transactionLogs.length > 0) {
            let submitterFilterValues = []
            let projectFilterValues = []
            const projectHistogram = filtersData?.['project_id']?.histogram || []
            for (let item of projectHistogram) {
                if (item.isSelected) {
                    projectFilterValues.push(item.key)
                }
            }
            if (projectFilterValues.length === projectHistogram.length) {
                projectFilterValues = []
            }
            const submitterHistogram = filtersData?.['submitter']?.histogram || []
            for (let item of submitterHistogram) {
                if (item.isSelected) {
                    submitterFilterValues.push(item.key)
                }
            }
            if (submitterHistogram.length === submitterFilterValues.length) {
                submitterFilterValues = []
            }

            if (projectFilterValues.length > 0 && submitterFilterValues.length > 0) {
                if (!matchedValues) {
                    matchedValues = []
                }
                matchedValues = transactionLogs.filter(item => submitterFilterValues.find(x => {
                    if (x == "No Data") {
                        return item?.['submitter'] == null
                    } else {
                        return x === item?.["submitter"]
                    }
                }) &&
                    projectFilterValues.find(x => x === item?.["project_id"]))
            } else {
                if (projectFilterValues.length > 0) {
                    if (!matchedValues) {
                        matchedValues = []
                    }
                    matchedValues = transactionLogs.filter(item => projectFilterValues.find(x => x === item?.["project_id"]))
                }
                if (submitterFilterValues.length > 0) {
                    if (!matchedValues) {
                        matchedValues = []
                    }
                    matchedValues = transactionLogs.filter(item => submitterFilterValues.find(x => {
                        if (x == "No Data") {
                            return item?.['submitter'] == null
                        } else {
                            return x === item?.["submitter"]
                        }
                    }))
                }
            }

            if (filtersData?.['uploaded_on']?.selected_start_date && filtersData?.['uploaded_on']?.selected_end_date) {
                if (!matchedValues) {
                    matchedValues = []
                }

                if (!(submitterFilterValues?.length > 0 || projectFilterValues?.length > 0)) {
                    matchedValues = transactionLogs;
                }

                matchedValues = filter(matchedValues, (x) => {
                    const currentDateTime = new Date(x.created_datetime).getTime();
                    const startDateTime = new Date(filtersData?.['uploaded_on']?.selected_start_date).getTime();
                    const endDateTime = new Date(filtersData?.['uploaded_on']?.selected_end_date).getTime();
                    return currentDateTime >= startDateTime && currentDateTime <= endDateTime;
                })

            }
        }
        const final = matchedValues?.length >= 0 ? matchedValues : transactionLogs
        setFilteredTransactionLogs(final)
    }, [transactionLogs, filtersData])

    const loadTotalCount = async () => {
        try {
            setTotalCount(-1)
            setLoadingTableData(true)
            const result = await getTransactionLogsCount(tableSearch);
            setTotalCount(result?.["_transaction_log_count"] || 0)
        }
        catch (error) {
            setLoadingTableData(false)
            setTotalCount(0)
        }
    }

    const loadData = async (page = pageNumber) => {
        try {
            setLoadingTableData(true)
            const response = await getTransactionLogs(20, page * 20, getSortedField(), tableSearch);
            const logs = response?.transaction_log || [];
            setTransactionLogs(logs);
            setLoadingTableData(false)
        }
        catch (error) {
            console.log(error)
            setLoadingTableData(false)
            setTransactionLogs([])
        }
    }

    const getSortedData = () => {
        let sortByItemValue = "id";
        switch (sortBy) {
            case SORT_OPTIONS[0]: sortByItemValue = "id"; break;
            case SORT_OPTIONS[1]: sortByItemValue = "submitter"; break;
            case SORT_OPTIONS[2]: sortByItemValue = "project_id"; break;
            case SORT_OPTIONS[3]: sortByItemValue = "created_datetime"; break;
            case SORT_OPTIONS[4]: sortByItemValue = "doc_size"; break;
            case SORT_OPTIONS[5]: sortByItemValue = "status"; break;
        }
        if (sortByItemValue == "id" || sortByItemValue == "submitter" || sortByItemValue == "project_id" ||
            sortByItemValue == "created_datetime") {
            return orderBy(filteredTransactionLogs, (item) => item?.[sortByItemValue]);
        }
        if (sortByItemValue == "doc_size") {
            return orderBy(filteredTransactionLogs, (item) => { return new Number(item?.documents?.[0]?.doc_size) }, ["desc"])
        }
        if (sortByItemValue == "status") {
            return orderBy(filteredTransactionLogs, (item) => { return item?.documents?.[0]?.response?.success.toString() }, ["asc"])
        }
    }
    const getSortedField = () => {
        let sortByItemValue = "id";
        switch (sortBy) {
            case SORT_OPTIONS[0]: sortByItemValue = "created_datetime"; break;
            case SORT_OPTIONS[1]: sortByItemValue = "submitter"; break;
            case SORT_OPTIONS[2]: sortByItemValue = "project_id"; break;
            case SORT_OPTIONS[3]: sortByItemValue = "created_datetime"; break;
            case SORT_OPTIONS[4]: sortByItemValue = "doc_size"; break;
            case SORT_OPTIONS[5]: sortByItemValue = "status"; break;
            default: sortByItemValue = "created_datetime"; break;
        }
        return sortByItemValue;
    }

    const constructFilters = () => {
        let filters = {
            submitter: {
                histogram: []
            },
            project_id: {
                histogram: []
            },
            uploaded_on: {
                isDateField: true,
            }
        }
        for (let log of transactionLogs) {
            let matchedSubmitterItem = filters.submitter.histogram.find(item => item.key === (log["submitter"] || "No Data"))
            if (matchedSubmitterItem) {
                matchedSubmitterItem.count += 1;
            } else {
                filters.submitter.histogram.push({
                    key: (log["submitter"] || "No Data"),
                    count: 1
                })
            }
            let matchedProjectItem = filters.project_id.histogram.find(item => item.key === log["project_id"])
            if (matchedProjectItem) {
                matchedProjectItem.count += 1;
            } else {
                filters.project_id.histogram.push({
                    key: log["project_id"],
                    count: 1
                })
            }
        }
        const sortedTransactions = orderBy(transactionLogs, x => x.created_datetime);
        filters.uploaded_on["start_date"] = moment(sortedTransactions?.[0]?.created_datetime).format("MM/DD/YYYY");
        filters.uploaded_on["end_date"] = moment(sortedTransactions?.[sortedTransactions.length - 1]?.created_datetime).format("MM/DD/YYYY");
        setFiltersData(filters)
    }

    // Closes all the opened filter categories
    const resetFilterCollapseState = (numberOfFilters = 2) => {
        setFilterCollapseState(fill(Array(numberOfFilters), false));
        setShowMoreItems(fill(Array(numberOfFilters), false))
    }


    useEffect(() => {
        if (transactionLogs?.length > 0) {
            constructFilters();
        }
    }, [transactionLogs])

    const filteredLogs = useMemo(() => {
        let sortedItems = getSortedData();
        if (tableSearch) {
            sortedItems = sortedItems.filter(item => {
                const searchText = tableSearch.toLowerCase();
                const project = item?.project_id?.toLowerCase() || "";
                const submitter = item?.submitter?.toLowerCase() || ""
                const id = item?.id?.toString()?.toLowerCase() || ""
                return project.includes(searchText) || submitter.includes(searchText) || id.includes(searchText)
            })
        }
        if (sortedItems?.length) {
            let showingRecords = []
            for (let i = (pageNumber * 10); i < (pageNumber + 1) * 10; i++) {
                if (sortedItems[i])
                    showingRecords.push(sortedItems[i])
            }
            return showingRecords;
        }
        return []
    }, [filteredTransactionLogs, sortBy, tableSearch, pageNumber])

    const filteredDataCount = useMemo(() => {
        let sortedItems = [...filteredTransactionLogs];
        if (tableSearch) {
            sortedItems = sortedItems.filter(item => {
                const searchText = tableSearch.toLowerCase();
                const project = item?.project_id?.toLowerCase() || "";
                const submitter = item?.submitter?.toLowerCase() || ""
                const id = item?.id?.toString()?.toLowerCase() || ""
                return project.includes(searchText) || submitter.includes(searchText) || id.includes(searchText)
            })
        }
        return sortedItems.length;
    }, [filteredTransactionLogs, tableSearch])

    const maxCount = totalCount !== -1 ? Math.ceil(totalCount / 20) : 0

    // Clears applied filter per particular category
    const clearAppliedFiltersPerCategory = category => {
        let newExploreFilters = { ...filtersData }
        if (newExploreFilters[category]?.isDateField) {
            newExploreFilters[category].selected_start_date = null;
            newExploreFilters[category].selected_end_date = null;
        } else {
            let histograms = newExploreFilters[category].histogram;
            for (let innerItem of histograms) {
                innerItem.isSelected = false;
            }
            newExploreFilters[category].histogram = histograms;
        }
        setFiltersData(newExploreFilters);
    }

    // Removes all the applied filters and
    // close the opened filter categories.
    const resetAllFilters = () => {
        let newExploreFilters = { ...filtersData }
        const categories = Object.keys(newExploreFilters);
        for (let item of categories) {
            if (newExploreFilters[item]?.isDateField) {
                newExploreFilters[item].selected_start_date = null;
                newExploreFilters[item].selected_end_date = null;
            } else {
                let histograms = newExploreFilters[item].histogram;
                for (let innerItem of histograms) {
                    innerItem.isSelected = false;
                }
                newExploreFilters[item].histogram = histograms;
            }
        }
        setFiltersData(newExploreFilters);
    }

    // Toggle open and close of a filter category
    const handleFilterCollapseStateChange = (index, value) => {
        const newState = [...filterCollapseState];
        newState[index] = value;
        setFilterCollapseState(newState)
    }

    // Toggle show more per filter
    const handleFilterShowMoreChange = (index, value) => {
        const newState = [...showMoreItems];
        newState[index] = value;
        setShowMoreItems(newState)
    }


    //toggles all items in a filter
    const togglesAllItemsInFilter = (category, value) => {
        let newExploreFilters = { ...filtersData }
        let histograms = newExploreFilters[category].histogram;
        for (let innerItem of histograms) {
            innerItem.isSelected = value;
        }
        newExploreFilters[category].histogram = histograms;
        setFiltersData(newExploreFilters);
    }

    // Handles the filters items state change ( Checked | Unchecked )
    // e: event to get the checked value
    // filterCatCode: Category code among the filter category
    // ItemCode: code of the item which is checked/ Unchecked
    const onChangeFilterItemState = (e, filterCatCode, itemCode, isDateField = false) => {
        if (isDateField) {
            if (e?.length == 2) {
                const updateExploreFilters = filtersData;
                updateExploreFilters[filterCatCode].selected_start_date = moment(e[0]).format("MM/DD/YYYY")
                updateExploreFilters[filterCatCode].selected_end_date = moment(e[1]).format("MM/DD/YYYY")
                setFiltersData({ ...updateExploreFilters })
            }
            if (e === null) {
                const updateExploreFilters = filtersData;
                updateExploreFilters[filterCatCode].selected_start_date = null;
                updateExploreFilters[filterCatCode].selected_end_date = null
                setFiltersData({ ...updateExploreFilters })
            }
        } else {
            const histograms = filtersData[filterCatCode].histogram;
            let indexFound = -1;
            if (Array.isArray(itemCode)) {
                for (let i = 0; i < histograms.length; i++) {
                    let elementFound = true;
                    for (let j = 0; j < itemCode.length; j++) {
                        if (histograms[i]?.key?.find(x => x == itemCode[j]) === undefined) {
                            elementFound = false;
                        }
                    }
                    if (elementFound) {
                        indexFound = i;
                        break;
                    }
                }
                if (indexFound == -1) {
                    return;
                }
            } else {
                indexFound = findIndex(histograms, item => item.key === itemCode);
            }
            histograms[indexFound].isSelected = e === true ? true : e?.target?.checked || false;
            const updateExploreFilters = filtersData;
            updateExploreFilters[filterCatCode].histogram = histograms;
            setFiltersData({ ...updateExploreFilters })
        }
    }

    const renderSuccessStatus = () => {
        return <div className={classes.successText}>
            <img alt='status icon' src={EnabledTickIcon} className={classes.enabledTickIconImage} />Successful
            </div>
    }

    const renderFailureStatus = () => {
        return <div className={classes.failureText}>
            <img src={disabledIcon} alt="failure Icon" className={classes.uploadStatsItemIcon} /> Failed</div>
    }

    const setTablePage = number => {
        setPageNumber(number);
    }

    const handleOnKeyDown = (event) => {
        if (event.which == 13) {
            loadTotalCount()
        }
    }

    const renderLoadingTemplate = () => {
        return <div>
            <div className={classes.sectionTableHeader}>
                <div className={classes.sectionLeftContainer}>
                    <ul>
                        <li className={classes.sortText}>
                            <label id='cvb-uploadHistory-sortByLabel'>Sort By: </label>
                        </li>
                        <li className={classes.sortFilter} id='cvb-uploadHistory-sortByDropdown'>
                            <Skeleton width="100%" />
                        </li>

                        <li className={classes.sortTextPagination} id='cvb-uploadHistory-tableCountText'>
                            <Skeleton width="100%" />
                        </li>
                    </ul>
                </div>
            </div>
            <div className={classes.selectProjectTableContainer} id='cvb-uploadHistory-uploadHistoryTableContainer'>
                <TableContainer
                    component={Paper}
                    elevation={0}
                    className={classes.tableContainer}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell id='cvb-uploadHistory-tableCellID' width="5%" align="left"><Skeleton width="100%" /></TableCell>
                                <TableCell id='cvb-uploadHistory-tableCellSubmitter' width="25%" align="left"> <Skeleton width="100%" /></TableCell>
                                <TableCell id='cvb-uploadHistory-tableCellProject' width="15%" align="left"> <Skeleton width="100%" /></TableCell>
                                <TableCell id='cvb-uploadHistory-tableCellUpdatedOn' width="15%" align="left"> <Skeleton width="100%" /></TableCell>
                                <TableCell id='cvb-uploadHistory-tableCellFileSize' width="10%" align="left"> <Skeleton width="100%" ></Skeleton></TableCell>
                                <TableCell id='cvb-uploadHistory-tableCellStatus' width="15%" align="left"> <Skeleton width="100%" /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                new Array(20).fill(1)?.map((item, index) => {
                                    return <TableRow key={v4()} >
                                        <TableCell><Skeleton width="100%" /></TableCell>
                                        <TableCell><Skeleton width="100%" /></TableCell>
                                        <TableCell><Skeleton width="100%" /></TableCell>
                                        <TableCell><Skeleton width="100%" /></TableCell>
                                        <TableCell><Skeleton width="100%" /></TableCell>
                                        <TableCell><Skeleton width="100%" /></TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    }

    return (
        <div className={classes.innerContainer} id='cvb-uploadHistory-mainContainer'>
            <Grid container className={classes.searchBackground}>
                <Grid item xs={12} lg={12} md={12}>
                    <div className={classes.sectionHeaderContainer}>
                        <div className={classes.gridTitle}><h2 id='cvb-uploadHistory-headingText'>Upload History</h2></div>
                    </div>
                </Grid>
            </Grid>
            <div className={classes.filterContentContainer}>
                {/* <Grid item lg={3}>
                    <ExploreDataFilters
                        filterCollapseState={filterCollapseState}
                        resetAllFilters={resetAllFilters}
                        exploreFilters={filtersData}
                        onChangeFilterItemState={onChangeFilterItemState}
                        handleFilterCollapseStateChange={handleFilterCollapseStateChange}
                        resetFilterCollapseState={resetFilterCollapseState}
                        handleFilterShowMoreChange={handleFilterShowMoreChange}
                        showMoreItems={showMoreItems}
                        togglesAllItemsInFilter={togglesAllItemsInFilter}
                    />
                </Grid> */}
                <Grid item lg={12} md={12} sm={12} className={classes.contentBlock}>
                    {/* <ExploreAppliedFilters
                        exploreFilters={filtersData}
                        onChangeFilterItemState={onChangeFilterItemState}
                        resetAllFilters={resetAllFilters}
                        clearAppliedFiltersPerCategory={clearAppliedFiltersPerCategory}
                    /> */}
                    <div className={classes.sectionLeftContainer}>
                    </div>

                    <div className={classes.contentMainContentArea}>
                        <div className={classes.contentContainer}>
                            <div className={classes.headerTitle}>
                                <h3 id='cvb-uploadHistory-recentUploadHeading'>Recent Uploads</h3>
                            </div>
                            {
                                loadingTableData ?
                                    <div>
                                        {/* <CustomLoader styleProp={{ position: 'relative', height: 'auto', width: 'auto' }} /> */}
                                        {
                                            renderLoadingTemplate()
                                        }
                                    </div> :
                                    <div>
                                        <div className={classes.sectionTableHeader}>
                                            <div className={classes.sectionLeftContainer}>
                                                <ul>
                                                    <li className={classes.sortText}>
                                                        <label id='cvb-uploadHistory-sortByLabel'>Sort By: </label>
                                                    </li>
                                                    <li className={classes.sortFilter} id='cvb-uploadHistory-sortByDropdown'>
                                                        <DropDown
                                                            selectedFilter={sortBy}
                                                            values={SORT_OPTIONS}
                                                            handleFilterStateChange={(val) => { setSortBy(val); setPageNumber(0) }}
                                                            placeholder={'Upload Date'}
                                                        />
                                                    </li>

                                                    <li className={classes.sortTextPagination} id='cvb-uploadHistory-tableCountText'>
                                                        { (transactionLogs?.length || 0 ) ? 
                                                        <p className={classes.recordsStats}>Showing {(pageNumber * 20) + 1} - {(pageNumber + 1) * 20 < totalCount ? (pageNumber + 1) * 20 : totalCount} of {totalCount}</p>
                                                        : <p className={classes.recordsStats}>Showing 0 of 0</p>

                                                        }

                                                    </li>
                                                </ul>
                                            </div>
                                            <div className={classes.sectionRightContainer}>
                                                <ul className={classes.filterSection}>
                                                    <li>
                                                        <OutlinedInput fullWidth type='text'
                                                            value={tableSearch}
                                                            autoComplete={"off"}
                                                            onChange={e => { setTableSearch(e.target.value); setPageNumber(0) }}
                                                            onKeyDown={handleOnKeyDown}
                                                            placeholder='Search by project'
                                                            className='signup-inputs-large'
                                                            id='cvb-uploadHistory-searchByProjectField'
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => handleOnKeyDown({ which: 13 })}
                                                                        aria-label="Search records"
                                                                        size="large">
                                                                        <img alt='visibility On' src={SearchIcon} className={classes.exploreSearchIcon} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={classes.selectProjectTableContainer} id='cvb-uploadHistory-uploadHistoryTableContainer'>
                                            <TableContainer
                                                component={Paper}
                                                elevation={0}
                                                className={classes.tableContainer}>
                                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell id='cvb-uploadHistory-tableCellID' width="5%" align="left">ID</TableCell>
                                                            <TableCell id='cvb-uploadHistory-tableCellSubmitter' width="25%" align="left">Submitter</TableCell>
                                                            <TableCell id='cvb-uploadHistory-tableCellProject' width="15%" align="left">Project</TableCell>
                                                            <TableCell id='cvb-uploadHistory-tableCellUpdatedOn' width="15%" align="left">Updated on</TableCell>
                                                            <TableCell id='cvb-uploadHistory-tableCellFileSize' width="10%" align="left">File Size</TableCell>
                                                            <TableCell id='cvb-uploadHistory-tableCellStatus' width="15%" align="left">Status</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            transactionLogs?.map((item, index) => {
                                                                return <TableRow key={v4()} >
                                                                    <TableCell id={`cvb-uploadHistory-tableCellSelectCheckbox-${index}`} align="left">{item?.id || ''}</TableCell>
                                                                    <TableCell id={`cvb-uploadHistory-tableCellSubmitterID-${index}`} className={classes.submitterId} align="left">{item?.submitter || "-"}</TableCell>
                                                                    <TableCell id={`cvb-uploadHistory-tableCellProjectID-${index}`} align="left" className={`${classes.dateTimeField} ${classes.projectID}`}>{item?.project_id || ''}</TableCell>
                                                                    <TableCell id={`cvb-uploadHistory-tableCellDateTime-${index}`} className={classes.dateTimeField} align="left">{item?.created_datetime ? moment(item?.created_datetime).format("YYYY/MM/DD") : ''} at {item?.created_datetime ? moment(item?.created_datetime).format("HH:MM") : ''}</TableCell>
                                                                    <TableCell id={`cvb-uploadHistory-tableCellFileSize-${index}`} align="left">{item?.documents?.[0]?.doc_size || "0"} KB</TableCell>
                                                                    <TableCell id={`cvb-uploadHistory-tableCellStatus-${index}`} align="left">{item?.documents?.[0]?.response?.success ? renderSuccessStatus() : renderFailureStatus()}</TableCell>
                                                                </TableRow>
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                        {(transactionLogs?.length || 0) > 0 &&
                                            <div className={classes.tablePagination} id='cvb-uploadHistory-tablePagination'>
                                                <TablePagination
                                                    maxCount={maxCount}
                                                    page={pageNumber}
                                                    setPage={setTablePage}
                                                />
                                            </div>
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                </Grid>
            </div>
        </div>
    );
}

export default UploadsHistory

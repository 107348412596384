import { getCognitoIdToken } from './utilityMethods';
import axios from 'axios'
import {env} from '../env'
import get from 'lodash/get'

const REPORT_PROBLEM_BASE_URL = `${env.REACT_APP_BASE_API_PATH}/user/reported_issue`

export const postProblemAttachment = async (data) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.post(`${REPORT_PROBLEM_BASE_URL}/upload_url`, data, { headers: header });
        return get(res, 'data', {});
    } catch (error) {
        console.log('error in getting cert url', error)
    }
}

export const deleteAttachment = async (data) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const res = await axios.delete(`${REPORT_PROBLEM_BASE_URL}/before_submission`, {data:data, headers: header });
        return get(res, 'data', {});
    } catch (error) {
        console.log('error in getting cert url', error)
    }
}

export const submitProblem = async (data) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    const res = await axios.post(`${REPORT_PROBLEM_BASE_URL}/save`, data, { headers: header });
    return get(res, 'data', {});

}
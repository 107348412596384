import { uniqBy } from 'lodash';
import { renameKeys } from '../components/pages/project-gallery/utils';

import {
  getAssessmentsByProjects,
  getDataTypesByProjects,
  getDiseaseByProjects,
} from '../api/graphql/study-gallery';

import { getStructuredDataModel } from '../api/upload-data-api';
import { getDataModelSchema } from '../api/data-model-api';

export async function getClinicalDataPoints(filters) {
  // FIXME this count is wrong -- see https://cohenveteransbioscience.atlassian.net/jira/software/c/projects/MP/boards/23?selectedIssue=MP-666
  let totalCount = 0;

  for (let prop in filters) {
    const attribute = filters[prop];
    if (attribute.histogram !== undefined) {
      const count = attribute?.histogram.reduce((prev, cur) => {
        if (cur.key == 'no data' || cur.min !== undefined) {
          return prev;
        }

        return prev + cur.count;
      }, 0);

      totalCount += count;
    }
  }

  return totalCount;
}

/**
 * Filters out the attributes that are not relevant to the project
 * Relevant input is like:
 * 
 * {
 *   addiction_severity_index_cases_to_case,
 *   _addiction_severity_index_count:  10,
 *   _adverse_event_count: 10,
 *   _alcohol_use_disorders_id_test_count: 10
 * }
 * 
 * For the above, we filter out the ones without counts and return the name by removing the _count suffix and _ prefix
 * From the above example we get addiction_severity_index, adverse_event, alcohol_use_disorders_id_test
 * @param {*} projectModelData 
 * @returns 
 */
function getProjectRelevantModelAttributes(projectModelData) {
  return Object.keys(projectModelData).filter((key) => key.endsWith('_count') && projectModelData[key])
    .map((key) => key.substring(1, key.length - 6));
}

export async function getAttributesCount(projectIds) {
  let groupAttributes = new Set();
  const modelPromise = getDataModelSchema();
  const promises = projectIds.map(async (projectId) => {
    const projectDataModel = await getStructuredDataModel(projectId);
    for(const property of getProjectRelevantModelAttributes(projectDataModel)) {
      groupAttributes.add(property);
    }
  });
  await Promise.all(promises);
  const model = await modelPromise;
  let count = 0;
  for(const groupAttribute of groupAttributes) {
    if(model[groupAttribute]) {
      count += Object.keys(model[groupAttribute].properties).length;
    }
  }
  return count;
}

export async function getProjectsCount(projects) {
  let filterProjects = uniqBy(projects, 'projectId');
  return filterProjects.length;
}

export async function getRecords(caseCounts) {
  // const attribute = Object.values(filters)[0];

  // const count = attribute?.histogram.reduce((prev, cur) => {
  const count = caseCounts?.reduce((prev, cur) => {
    // if (cur.key == 'no data') {
    // 	return prev;
    // }
    return prev + cur.count;
  }, 0);

  return count;
}

export async function getStudyFromProject(project, caseCounts) {
  const newKeyValue = {
    project_id: 'projectId',
    project_name: 'studyName',
    project_design: 'studyDesign',
  };

  return { ...renameKeys(project, newKeyValue), case_count: getStudyCaseCount(caseCounts, project.projectId) };
}

export function getStudyCaseCount(caseCounts, projectId) {
  // Find the project with the matching id
  const item = caseCounts.find(obj => obj.key === projectId);
  return item ? item.count : null;
}

export async function getProjectsCohorts(projects) {
  return projects.map((p) => ({
    uuid: p.projectId,
    cohort_name: p.studyName,
    cohort_type: 'project',
    cohort_query: {
      OR: [
        {
          IN: {
            project_id: [p.projectId],
          },
        },
      ],
    },
    created_at: p.createdAt,
  }));
}

async function getAggregatesByDisease(aggregates) {
  const aggregatesByDisease = {};
  for (const aggName in aggregates) {
    if (aggregates[aggName].project_id?.histogram !== undefined) {
      for (const agg of aggregates[aggName].project_id.histogram) {
        if (aggregatesByDisease[agg.key] === undefined) {
          aggregatesByDisease[agg.key] = {};
        }
        aggregatesByDisease[agg.key][aggName] =
          agg.count !== undefined ? agg.count : true;
      }
    }
  }

  return aggregatesByDisease;
}

export async function getDiseasesForProject(filterValues) {
  const diseases = await getDiseaseByProjects(filterValues);
  const aggregates = diseases?._aggregation;

  const aggName = 'disease_by_project';
  const histogram = aggregates[aggName].project_id?.histogram;
  const diseaseByProjects = { [aggName]: [] };
  let totalCount = 0;

  if (Array.isArray(histogram)) {
    const flattened = histogram
      .map((project) => project.termsFields[0].terms)
      .flat();

    const diseases = flattened.reduce((prev, cur) => {
      var existing = prev.filter(function (v, i) {
        return v.key == cur.key;
      });

      if (existing.length) {
        var existingIndex = prev.indexOf(existing[0]);
        prev[existingIndex].count = prev[existingIndex].count + cur.count;
      } else {
        prev.push(cur);
      }
      return prev;
    }, []);

    diseaseByProjects[aggName] = diseases;

    totalCount = histogram.reduce((prev, cur) => {
      if (cur.count !== undefined) {
        prev += cur.count;
      }

      return prev;
    }, 0);
  }

  const byProject = await getAggregatesByDisease(aggregates);

  diseaseByProjects.projects = byProject;
  diseaseByProjects.totalCount = totalCount;

  return diseaseByProjects;
}

export async function getAssessmentsByProject(filterValues) {
  const assessments = await getAssessmentsByProjects(filterValues);
  const histogram =
    assessments?._aggregation.assessments_by_project.project_id.histogram;

  return Array.isArray(histogram) ? histogram : [];
}

export async function getDataTypesByProject(filterValues) {
  const dataTypes = await getDataTypesByProjects(filterValues);

  return dataTypes
    ? dataTypes?._aggregation.data_type_by_project.project_id.histogram
    : [];
}

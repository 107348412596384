/**
 *  External Imports
 */
import {navigate} from "@reach/router";

import React from "react";

import v4 from "uuid/v4";

/**
 *  Internal Imports
 */
import iconConnection from "../../assets/icons/icon-connection-w.svg";
import iconConstellation from "../../assets/icons/icon-constellation.svg";

import CommunityGlobalSearchButton from "../../components/controls/community-global-search-button";

import {COMMUNITY_URLS, COMMUNITY_CONNECTIONS_PARENT_ROUTE} from "../urls";

/**
 *  Constants
 */
const COMMUNITY_ACTION_BUTTONS = [
	{
		buttonText: "Constellation",
		buttonImageSource: iconConstellation,
		get customClass() {
			const {pathname} = window.location;
			const isActiveClass = [
				COMMUNITY_URLS.COMMUNITY,
				`${COMMUNITY_URLS.COMMUNITY}${COMMUNITY_URLS.CONSTELLATION}`,
			].includes(pathname);
			
			return isActiveClass ? "cvb-button-active" : "";
		},
		handleOnClick: () => navigate(`${COMMUNITY_URLS.COMMUNITY}${COMMUNITY_URLS.CONSTELLATION}`),
	},
	{
		buttonText: "Connections",
		buttonImageSource: iconConnection,
		get customClass() {
			const {pathname} = window.location;
			const isActiveClass = [
				`${COMMUNITY_CONNECTIONS_PARENT_ROUTE}`,
				`${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.RECOMMENDED}`,
				`${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.REQUESTS}`,
				`${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.REQUESTS}${COMMUNITY_URLS.INCOMING_REQUESTS}`,
				`${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.REQUESTS}${COMMUNITY_URLS.PENDING_REQUESTS}`,
				`${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.REQUESTS}${COMMUNITY_URLS.ACCEPTED_REQUESTS}`,
				`${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.MESSAGES}`,
				`${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.SEARCH_RESULTS}`,
			].includes(pathname);
			
			return isActiveClass ? "cvb-button-active" : "";
		},
		handleOnClick: () => navigate(`${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.REQUESTS}${COMMUNITY_URLS.ACCEPTED_REQUESTS}`),
	},
	{
		customComponent: <CommunityGlobalSearchButton key={v4()} />,
	},
];

/**
 *  Exports
 */
export {
	COMMUNITY_ACTION_BUTTONS,
};

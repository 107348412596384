import ReportAProblem from './ReportAProblem'
import { connect } from 'react-redux'
import { updateSnackBar } from '../../../redux/actions/globalActions'

const mapStateToProps = state => ({})

const mapDispatchToProps = {
    updateSnackBar
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportAProblem);
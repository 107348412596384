import React from 'react'
import Classes from './index.module.css'
import Grid from '@mui/material/Grid'
import CloseSmallIcon from '../../../assets/icons/close-small.svg'
import v4 from 'uuid/v4'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import { validateEmailAddress, scrollToGivenTop } from '../../../utils/input-validations'
import get from 'lodash/get'
import { DIF_FORM_COMPLETED_STATUS } from '../../../constants/strings'
const DIFIndividualDatasetTabs = props => {
    const {
        individualDatasets,
        difDatasetActiveIndex,
        currentDIFFormStage,
        addNewDataset,
        setDatasetCurrentActiveTab,
        removeSelectedDatasetTab,
        onAddMoreClick
    } = props;
    const hasTwoOrMoreAnalysis = individualDatasets.length > 1 ;
    const disableInput = currentDIFFormStage === DIF_FORM_COMPLETED_STATUS;

    const setCurrentActive = (index) => {
        setDatasetCurrentActiveTab(index);
    }
   
    const validateIndividualDatasetForm = () => {
        let isValidForm = false;
        map(individualDatasets, (dataset)=>{
            const ownerEmail = get(dataset, 'owner_email', '');
            if(validateEmailAddress(ownerEmail)){
                isValidForm = true;            
            }else{
                isValidForm = false;
                return false;
            }
        });
        return isValidForm;
    }
    const scrollToErrorFields = () => {
                const ownerEmail = get(individualDatasets,`[${difDatasetActiveIndex}].owner_email`, '');
                if(!validateEmailAddress(ownerEmail)){
                    const ownerNameElement = document.getElementById(`ownerEmail${difDatasetActiveIndex}`);
                    scrollToGivenTop(ownerNameElement);
                }
    }
    const onAddMoreDataset = () => {
        if (!disableInput) {
                const newDataset = {
                    "dataset_name": "",
                    "data_owner_type": "",
                    "owner_name": "",
                    "owner_title": "",
                    "owner_affiliation": "",
                    "owner_email": "",
                    "abstract": "",
                    "agency_name": "",
                    "agency_number": "",
                    "disease_area": "",
                    "types_of_data": {
                        "is_clinical": "",
                        "imaging": "",
                        "precilinical": "",
                        "genomic": "",
                        "proteomic": "",
                        "transcriptomic": "",
                        "other": ""
                    },
                    "is_data_curated_to_any_standard": "",
                    "dataset_name_and_standards": "",
                    "restrict_data_access": "",
                    "allow_data_download_by_others": "",
                    "number_of_samples_in_dataset": "",
                    "data_size": "",
                    "is_data_longitudinal_or_time_series": '',
                    "is_data_set_complete": "",
                    "data_upload_completed_date": "",
                    "data_dictionaries": [],
                    "data_dictionaries_relationship_document": "",
                    "subject_space": {
                        "subject_belong_to_another_study": "",
                        "do_you_want_to_capture_this_information": ""
                    },
                    "biospecimen_space": {
                        "samples_belong_to_another_study": "",
                        "do_you_want_to_capture_this_information": ""
                    },
                    "publications": [
                        {
                            "publication_name": "",
                            "publication_url": ""
                        }
                    ]
                }
                addNewDataset(newDataset);
            }
    }
    const onCloseDatasetTab = (event, index) => {
        if (!disableInput) {
            event.stopPropagation();
            removeSelectedDatasetTab(index);
            if (index === difDatasetActiveIndex && difDatasetActiveIndex !== 0) {
                setDatasetCurrentActiveTab(index - 1);
            }
        }
    }
    return (
        <Grid container id="cvb-DIFIndividualDatasetTabs-mainContainer">
        <Grid item xs={11} md={11} lg={11} className={Classes.customTabContainerMain}>
            <ul className={Classes.customTabContainer}>
                {
                    individualDatasets.length > 0 &&
                    map(individualDatasets, (item, index) => {
                        const activeOrInactiveClass = difDatasetActiveIndex === index
                            ? Classes.activeCustomTab
                            : Classes.inActiveCustomTab;
                        return <li key={v4()}
                            onClick={() => setCurrentActive(index)}
                            className={`${Classes.customTab} ${activeOrInactiveClass}`}
                        >
                            <div className={Classes.tabsTitleContainer} id="cvb-DIFIndividualDatasetTabs-tabsTitleContainer">
                                <div className={Classes.tabsTitleChildContainer}>
                                    {isEmpty(item['dataset_name']) ? 
                                    <p id="cvb-DIFIndividualDatasetTabs-dataSetName" className={Classes.tabsTitle}>Data Set {index + 1 > 9 ? index + 1 : `0${index + 1}`} </p>
                                    : <p className={Classes.tabsTitle}>{item['dataset_name']}</p>
                                    }
                                </div>
                                <span
                                    className={Classes.closeIconImage}>
                                    {
                                        (!disableInput && index > 0 ) &&
                                        <img src={CloseSmallIcon} alt='close small' id="cvb-DIFIndividualDatasetTabs-closeLink" onClick={(e)=> onCloseDatasetTab(e, index)}/>
                                    }
                                </span>
                            </div>
                        </li>
                    })
                }
                {!disableInput &&
                    <li onClick={onAddMoreDataset}
                        id="cvb-DIFIndividualDatasetTabs-addMoreLink"
                        className={`${Classes.customTab} ${Classes.addAnalysisTab}`}>
                        + Add More
                    </li>
                }
            </ul>
        </Grid>
    </Grid>
    )
}
DIFIndividualDatasetTabs.defaultProps = {
    individualDatasets: [],
    difDatasetActiveIndex: 0,
    currentDIFFormStage: '',
    addNewDataset: ()=>{},
    setDatasetCurrentActiveTab: ()=>{},
    removeSelectedDatasetTab: ()=>{},
    onAddMoreClick: ()=>{}
}
export default DIFIndividualDatasetTabs;
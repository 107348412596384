/**
*  External Imports
*/
import {connect} from "react-redux";

/**
*  Internal Imports
*/
import { selectConstellationLabelsAction, setConstellationLabelNodeHoverAction } from "../../../../redux/actions/communityActions";
import CommunityConstellationGraphMask from "./CommunityConstellationGraphMask";

const mapStateToProps = ({ community: { graphLabelsMask, graphLabelsMaskHovered, nodesParams, constellationGraphReady } }) => ({
    graphLabelsMask, graphLabelsMaskHovered, constellationGraphReady, nodesParams
});

const mapDispatchToProps = (dispatch) => ({
    selectLabel: (label) => dispatch(selectConstellationLabelsAction(label)),
    setLabelNodeParams: (labelParams) => dispatch(setConstellationLabelNodeHoverAction(labelParams)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CommunityConstellationGraphMask);
import React, { useEffect, useState } from 'react'
import classes from './index.module.css';
import map from 'lodash/map'
import Grid from '@mui/material/Grid'
import cloneDeep from 'lodash/cloneDeep'
import { findIndex } from 'lodash';
import { OutlinedInput } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import InputChips from '../input-chips';
import difference from 'lodash/difference'
import isEmpty from 'lodash/isEmpty'
import ErrorText from '../error-text'
import {getCommunityKeywords} from '../../../api/onboarding-api';
import uniq from 'lodash/uniq';
import { select } from 'd3';



const Questionaries = props => {
    const { handleValueChange, setCustomAnswerInput,step, questionaries, customAnswerInput, setFormChanged,
        setCustomAnswersObj = ()=>{}, customAnswerObj ={}, selectedPersona, moreAboutYou: {tagList}, onboardingData } = props;

    const [keywordList, setKeywordList] = useState([]);
    const [tagsInputText, setTagsInputText] = useState('');

   
    
    const onCustomInputChange = e => {
        setCustomAnswerInput(e.target.value);
        const data = {
            ...customAnswerObj,
            [questionaries?.question?.id] : e.target.value
        }
        setCustomAnswersObj(data);
        setFormChanged(true);
    }
    const onAnswerButtonClick = (ansText) => {
        const data = {
            questionId : questionaries?.question?.id,
            answerText : ansText,
            isMultiChoice: questionaries?.isMultipleChoice
        }
        handleValueChange(3, 'answers', data);
    }

    const getKeywords = async () => {
        let suggestions = [];
        if (tagsInputText?.length > 0) {
            try{
                const data = await getCommunityKeywords(tagsInputText);
                const regEx = new RegExp(`^${tagsInputText}`, 'i');
                const filteredArray = difference(data?.res, props.moreAboutYou?.tagList);
                suggestions = filteredArray.sort().filter(v => regEx.test(v))
            }catch(error){
                console.error('error fetching keywords');
            }
        }

        setKeywordList(suggestions);
    }

    const onTagsInputTextChange = value => {
        const endSpace = /\s$/;
        if(endSpace.test(value)){
            addSelectedValueInTags(value);
            return;
        }
        handleValueChange(2, 'tabsInputText', value);
        setTagsInputText(value);
    }

    const addSelectedValueInTags = (value) => {
        const currentTagList = isEmpty(props.moreAboutYou?.tagList) ? [] : props.moreAboutYou?.tagList;
       
        let presentTags = cloneDeep(currentTagList);
        let tagValue = `${value}`.trim();

        if(!isEmpty(tagValue)){
            presentTags.push(tagValue);
            presentTags = uniq(presentTags);
            handleValueChange(2, 'tagList', presentTags);
            setTagsInputText('');
        }
    }

    const deleteSelectedTagFromList = (index) => {
        let presentTags = cloneDeep(props.moreAboutYou?.tagList);
        if (index > -1) {
            presentTags.splice(index, 1);
        }
        handleValueChange(2, 'tagList', presentTags);
        setTagsInputText('');
    }
    
    useEffect(() => {
        getKeywords();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagsInputText])

    const questPrefix = questionaries?.isMultipleChoice ? 'Select all that applies.' : '( Select only 1 )';

    return (
        <div className={classes.questionariesRoot}>
            <p className={classes.questionText}>{props?.questionaries?.question?.text} {questPrefix}</p>
            <div className={classes.questionWrapper}>
            {map(questionaries?.options, (option, index)=> {
                const activeButton = findIndex(questionaries?.answer, (ans)=> ans === option?.text) > -1 ? classes.activeButton : '';
                return <button id={`cvb-questionnaire-${index}`} onClick={()=>onAnswerButtonClick(option?.text)} className={activeButton}>
                    {option?.text}
                    {
                        option?.hint ?
                            <span style={{marginLeft:'10px'}} title={option.hint} ><InfoIcon /></span>
                        : null
                    }
                </button>
            })}
            </div>
            <div className={classes.customInputQuesDiv}>
                {questionaries?.question?.id.toLowerCase() === 'mainareaofinterest' ? <Grid item lg={8} xs={12} md={12} className={classes.inputContainer}>
                    <label htmlFor="tags">Keywords</label>
                    <InputChips
                        id="cvb-tellUsMore-keywordsContainer"
                        keywordList={keywordList}
                        tagsInputText={tagsInputText}
                        onInputTextChange={onTagsInputTextChange}
                        onSelectDropdown={addSelectedValueInTags}
                        tagList={tagList}
                        deleteSelectedTagFromList={deleteSelectedTagFromList}
                        tagsInputError={questionaries?.tagsInputError}
                        inputPlaceholder={`Enter tags`}
						infoTextLabel={`Start typing to add tags`}
                        advancedComputeView={false}
                    />
                </Grid> : (questionaries?.isAllowCustomInput && <Grid item lg={6} md={6} xs={10}>
                <label>Not in the list? Please tell us about it in the space below.</label>
                <OutlinedInput
                    id="cvb-questionnaire-customInput"
                    value={customAnswerInput}
                    placeholder='If more than one, separate by commas'
                    onChange={onCustomInputChange}
                    fullWidth
                    classes={{ input: 'onboardingInputs' }}
                />
            </Grid>)}
                
            </div>
        </div>  
    )
}

export default Questionaries;
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import HttpsIcon from '@mui/icons-material/Https';
import { StudyInfo } from '../../../explore-cohorts/tabs/CohortInfoStudies';
import CloseIcon from '@mui/icons-material/Close';
import { vars } from '../../../../../assets/variables';
import DownloadStudyDialog from './DownloadStudyDialog';
import { getRecentUpdates } from '../../../../../api/recent-updates-api';
import RequestAccessDialog, { ZoneTypes } from './RequestAccessDialog';
import { getPendingZone3RequestData } from '../../../../../api/profile-api';

const { darkTextColor } = vars;
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
  },
  header: {
    borderBottom: '1px solid #D0D5DD',
    backgroundColor: 'white',
    '& .title': {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.875rem',
      color: darkTextColor,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '96%',
    },
    '& .sub': {
      fontSize: '0.875rem',
    },
  },
  footer: {
    backgroundColor: 'white',
    boxShadow:
      '0px -4px 8px rgba(16, 24, 40, 0.1), 0px -2px 4px rgba(16, 24, 40, 0.06)',
    '& .MuiButton-root': {
      flex: 1,
      padding: '10px 18px',
    },
  },
  highlight: {
    backgroundColor: '#FEF3F2',
    borderTop: 'none',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    color: '#B42318;',
    fontSize: '0.88rem',
  },
}));

const ProjectDetail = ({
  studyDetail,
  projectZone,
  closeStudyDetail,
  selected,
  addToSelection,
  updateSnackBar,
  userDetails,
}) => {
  const styles = useStyles();
  const [openStudyDownloadModal, setOpenStudyDownloadModal] = useState(false);
  const [openRequestAccessModal, setOpenRequestAccessModal] = useState(false);
  const [dataModelVersion, setDataModelVersion] = useState('');
  const [zone3Pending, setZone3Pending] = useState(null);

  const disableRequestAccessAction =
    (!zone3Pending && projectZone === ZoneTypes.ZONE3) ||
    (!!zone3Pending &&
      projectZone === ZoneTypes.ZONE3 &&
      zone3Pending.projects?.length <= 0) ||
    (!!zone3Pending &&
      projectZone === ZoneTypes.ZONE3 &&
      !zone3Pending.is_user_allow_to_request_zone3_access);

  const getDataModelVersion = async () => {
    try {
      const data = await getRecentUpdates();
      if (data?.length) {
        setDataModelVersion(data[0]?.version);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPendingZone3Request = async () => {
    try {
      const requests = await getPendingZone3RequestData();
      setZone3Pending(requests);
    } catch (error) {
      console.log(error);
    }
  };

  const onDownloadLinkClick = () => {
    getDataModelVersion();
    setOpenStudyDownloadModal(true);
  };
  const onCloseDownloadStudyModal = () => {
    setOpenStudyDownloadModal(false);
  };

  const onRequestAccessClick = () => {
    // getDataModelVersion();
    setOpenRequestAccessModal(true);
  };

  const onCloseRequestModal = () => {
    setOpenRequestAccessModal(false);
  };

  useEffect(() => {
    getPendingZone3Request();
  }, []);

  return (
    <Box
      position="relative"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      overflow="hidden"
      minWidth="40vw"
      maxWidth="600px"
    >
      <Box p={2} position="sticky" top={0} zIndex={1} className={styles.header}>
        <Box position="relative">
          <Tooltip title={studyDetail.studyName} arrow>
            <Typography className="title">{studyDetail.studyName}</Typography>
          </Tooltip>
          <Typography className="sub">
            {studyDetail.descriptionOfStudy}
          </Typography>
          <IconButton
            onClick={closeStudyDetail}
            sx={{ position: 'absolute', right: 0, top: 0 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box flex={1} overflow="auto" p={3}>
        <StudyInfo study={studyDetail} />
      </Box>
      <Box
        p={2}
        className={styles.footer}
        justifyContent="center"
        display="flex"
        width="100%"
        bottom={0}
        zIndex={1}
      >
        {studyDetail.isAccessible ? (
          <Stack direction="row" spacing={1.5} flex={1}>
            <Button
              variant="outlined"
              m={0}
              onClick={() => addToSelection(studyDetail.projectId)}
            >
              {`${selected ? 'Remove from' : 'Add to'} selection`}
            </Button>
            {
              studyDetail.isDownloadable &&
              <Button variant="contained" onClick={onDownloadLinkClick}>
                Download Study
              </Button>
            }
          </Stack>
        ) : (
          <Box className={styles.study}>
            <Box className={styles.highlight} display="flex" alignItems="top">
              <Box justifyContent="left" display="flex" width={20} flexShrink={0}>
                <HttpsIcon sx={{ fontSize: 15 }} />
              </Box>
              <Box>
                {
                  projectZone === ZoneTypes.ZONE3 ?
                      <>
                        This project is restricted to the Authorized Users included in the Project's Data
                        Contributor Agreement. If you are an Authorized User for this Project, please request
                        access via the "My Profile" page. If you want to be added to the list of Authorized
                        Users or if you experience any problems submitting your access request,
                        please "Report a Problem" using the link in the footer of this page.
                      </>
                      :
                      <>
                        This project is restricted to users with permissions to access it. You {
                        disableRequestAccessAction
                            ? 'cannot request access for this study here, try requesting'
                            : 'can request access for this study here, or request'
                      } access to all {projectZone} projects on your profile.
                      </>
                }
              </Box>
            </Box>
            <Box justifyContent="center" display="flex">
              <Button
                variant="contained"
                onClick={onRequestAccessClick}
                disabled={disableRequestAccessAction}
              >
                Request Access
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <DownloadStudyDialog
        studyDetail={studyDetail}
        updateSnackBar={updateSnackBar}
        dataModelVersion={dataModelVersion}
        openStudyDownloadModal={openStudyDownloadModal}
        onCloseDownloadStudyModal={onCloseDownloadStudyModal}
      />
      <RequestAccessDialog
        studyDetail={studyDetail}
        projectZone={projectZone}
        updateSnackBar={updateSnackBar}
        userDetails={userDetails}
        onCloseRequestModal={onCloseRequestModal}
        openRequestAccessModal={openRequestAccessModal && studyDetail}
      />
    </Box>
  );
};

export default ProjectDetail;

import ProjectPrimaryInstitutional from './ProjectPrimaryInstitutional'
import { connect } from 'react-redux'
import { setPPIFirstName, setPPILastName, setPPIMiddleName, setPPICredentials, setPPIInstituteName,
    setPPIInstituteEmail, setPPIInstituteStreetAddress, setPPIInstituteCity, setPPIInstituteState,
    setPPIInstituteZipCode, setPPIInstituteCountry } from '../../../redux/actions/projectPrimaryInstitutionalActions'
const mapStateToProps = state => {
    return {
        ppiFirstName: state.ppi.ppiFirstName,
        ppiLastName: state.ppi.ppiLastName,
        ppiMiddleName: state.ppi.ppiMiddleName,
        ppiCredentials: state.ppi.ppiCredentials,
        ppiInstituteName: state.ppi.ppiInstituteName,
        ppiInstituteEmail: state.ppi.ppiInstituteEmail,
        ppiInstituteStreetAddress: state.ppi.ppiInstituteStreetAddress,
        ppiInstituteCity: state.ppi.ppiInstituteCity,
        ppiInstituteState: state.ppi.ppiInstituteState,
        ppiInstituteZipCode: state.ppi.ppiInstituteZipCode,
        ppiInstituteCountry: state.ppi.ppiInstituteCountry,
        currentDCAFormStage: state.dca.currentDCAFormStage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPPIFirstName: async (value) => await dispatch(setPPIFirstName(value)),
        setPPILastName: async (value) => await dispatch(setPPILastName(value)),
        setPPIMiddleName: async (value) => await dispatch(setPPIMiddleName(value)),
        setPPICredentials: async (value) => await dispatch(setPPICredentials(value)),
        setPPIInstituteName: async (value) => await dispatch(setPPIInstituteName(value)),
        setPPIInstituteEmail: async (value) => await dispatch(setPPIInstituteEmail(value)),
        setPPIInstituteStreetAddress: async (value) => await dispatch(setPPIInstituteStreetAddress(value)),
        setPPIInstituteCity: async (value) => await dispatch(setPPIInstituteCity(value)),
        setPPIInstituteState: async (value) => await dispatch(setPPIInstituteState(value)),
        setPPIInstituteZipCode: async (value) => await dispatch(setPPIInstituteZipCode(value)),
        setPPIInstituteCountry: async (value) => await dispatch(setPPIInstituteCountry(value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectPrimaryInstitutional)
export const generateHashCode = (str) => {
  return str.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

export const generatePaletteColor = (key) =>
  'hsl(' + (generateHashCode(key) % 360) + ',70%,60%)';

export const colorVariants = [300, 'A700', 'A400', 500, 900];
export const hexColorVariants = [1, 0.8, 0.7, 0.9, 0.6];

export const colors = [
  '#0A1128',
  '#1282A2',
  '#CA96A6',
  '#C9CA96',
  '#96A5CA',
  '#D6BBFB',
  '#DBE7FB',
  '#95CDE7',
  '#7B66AD',
  '#96CAB8',
  '#4D80C6',
];

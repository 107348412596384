import TopNavBar from './TopNavBar'
import { connect } from 'react-redux'
import { updateProfilePicture, setAllNotificationsData } from '../../../redux/actions/globalActions'


const mapStateToProps = state => {
    return {
        proPic: state.global.profilePictureUrl,
        pullCount: state.global.pullCount,
        hasAnyWorkspaceStarted: state.global.hasAnyWorkspaceStarted,
        webSocketConnection: state.global.webSocket,
        webSocketOnMessage: state.global.onSocketMessage,
        allNotificationsData: state.global.allNotificationsData,
        allWorkspacesCarts: state.workspace.allWorkspacesCarts
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfilePicture: async value => await dispatch(updateProfilePicture(value)),
        setAllNotificationsData: async data => await dispatch(setAllNotificationsData(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNavBar)
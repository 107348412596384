import React, { useState, useEffect } from 'react'
import LeftContainerForLogin from '../login/loginLeftContainer'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import BCLogo from '../../../assets/images/BRAINCommons-Platform.svg'
import ShadowImage from '../../../assets/images/shadow.png'
import Classes from './index.module.css'
import { navigate } from '@reach/router'
import EnabledIcon from '../../../assets/images/enabled_icon.png'
import { SIGNUP_ERROR_TEXTS } from '../../../constants/strings'
import { VALID_EMAIL_REGEX } from '../../../constants/regex'
import ErrorText from '../../controls/error-text'
import InfoText from '../../controls/info-text'
import { Auth } from 'aws-amplify'
import IconButton from '@mui/material/IconButton';
import PreviewOn from '../../../assets/images/PreviewOn.png';
import PreviewOff from '../../../assets/images/PreviewOff.png';
import { getNewsArticles } from '../../../api/generic-api'
import InputAdornment from '@mui/material/InputAdornment';
import {
    lowerCharCheckForPassword,
    upperCaseCheckForPassword,
    minLengthCheckForPassword,
    specialCharCheckForPassword,
    numberCharCheckForPassword
} from '../../../utils/input-validations'
import SuccessTickDisabled from '../../../assets/images/successtick.png'
import SuccessTick from '../../../assets/images/successtickenabled.png'
import { updateGAEventsPageTitleOnPageChange } from '../../../api/generic-api'

const ForgotPassword = props => {
    const [email, setEmail] = useState({ text: '', isValid: '', isSubmit: false, invalidText: SIGNUP_ERROR_TEXTS.EMAIL });
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("")
    const [showEmailInput, setShowEmailInput] = useState(true);
    const [showPassword, setShowPassword] = useState(false)
    const [disableNextButton, setDisableNextButton] = useState(true)
    const [authError, setAuthError] = useState("")
    const [newsArticles, setNewsArticles] = useState(null)

    useEffect(() => {
        updateGAEventsPageTitleOnPageChange('forgot_password');
    }, [])
    const onChangeEmail = event => {
        setEmail({ ...email, text: event.target.value, isSubmit: false });
        setAuthError("")
    }
    const validateEmailAddress = () => {
        let re = VALID_EMAIL_REGEX;
        return re.test(email['text']);
    }
    const onSendResetNotificationClick = async () => {
        try {
            const isValid = validateEmailAddress();
            if (isValid) {
                setEmail({ ...email, isSubmit: true, isValid: true });
                await handleRequestPassword()
                setAuthError("")
                setShowEmailInput(false);
            }
            else {
                setEmail({ ...email, isSubmit: true, isValid: false });
            }
        }
        catch (error) {
            setAuthError(error.message)
            console.log(error)
        }
    }

    const validatePassword = (field, password) => {
        switch (field) {
            case "upperCaseChar": return upperCaseCheckForPassword(password)
            case "specialChar": return specialCharCheckForPassword(password)
            case "numberChar": return numberCharCheckForPassword(password)
            case "lowerCaseChar": return lowerCharCheckForPassword(password)
            case "minChar": return minLengthCheckForPassword(password)
            default: return upperCaseCheckForPassword(password) &&
                specialCharCheckForPassword(password) &&
                numberCharCheckForPassword(password) &&
                lowerCharCheckForPassword(password) &&
                minLengthCheckForPassword(password);
        }
    }

    const handlePasswordChangeSubmit = async () => {
        try {
            await Auth.forgotPasswordSubmit(email['text'], code, password);
            navigate("/login")
        }
        catch (error) {
            setAuthError('Invalid verification code provided, Please try again.')
        }
    }


    const handleRequestPassword = () => Auth.forgotPassword(email['text']);

    const onResendClick = () => {
        try {
            onSendResetNotificationClick() 
        } catch (error) {
            console.log('error', error)
        }
    }

    const onPasswordChange = event => {
        setPassword(event.target.value);
        setDisableNextButton(!validatePassword(null, event.target.value));
        setAuthError("");
    }

    const onChangeCode = event => {
        setCode(event.target.value)
        setAuthError("");
    }

    useEffect(() => {
        getNewsArticlesData();
    }, []);

    const getNewsArticlesData = async () => {
        try {
            const response = await getNewsArticles();
            setNewsArticles(response)
        }
        catch (error) {
            console.log(error)
        }
    }

    return (
        <Grid container>
            <LeftContainerForLogin newsArticles={newsArticles} className={Classes.forgotLeftContainer} />
            <Grid container alignItems='center' justifyContent='center' direction='column' item xs={6} className={Classes.forgotRightContainer}>
                <div className={Classes.bcLogoContainer}>
                    <img alt='braincommons log' className={Classes.bcLogo} src={BCLogo} />
                    <img alt='shadow' className={Classes.shadowImage} src={ShadowImage} />
                </div>
                <Grid item xs={6}>
                    <Grid container spacing={2} direction='column'>
                        <p className={Classes.forgotPassTitle}>
                            Forgot your password?
                        </p>
                        {showEmailInput ?
                            <React.Fragment>
                                <p className={Classes.forgotPassDesc}>
                                    Enter your email
                                </p>
                                {authError && <InfoText errorMessage={authError} type="Error" />}
                                <label>Email</label>
                                <OutlinedInput
                                    error={(email['isSubmit'] && !email['isValid'])}
                                    onChange={onChangeEmail}
                                    value={email['text']}
                                    placeholder='Enter Email'/>
                                {(email['isSubmit'] && !email['isValid']) &&
                                    <ErrorText errorMessage={SIGNUP_ERROR_TEXTS.EMAIL} />}
                                <button
                                    className={`solid-button ${Classes.sendResetNotButton}`}
                                    onClick={() => onSendResetNotificationClick()}>
                                    Send Reset Instructions
                                </button>
                                <p
                                    className={`no-outlined-button ${Classes.cancelButton}`}
                                    onClick={() => navigate('/login')}>Cancel
                                </p>
                            </React.Fragment>
                            : <React.Fragment>
                                <div className={Classes.successPass}>
                                    <img alt="enable icon" src={EnabledIcon} />
                                    <p className={Classes.successMsgTitle}>
                                        Instructions to reset password have been sent to your email
                                        address {email['text']}.
                                    </p>
                                    <p onClick={() => setShowEmailInput(true)}
                                        className={Classes.editEmailText}>Edit Email</p>
                                </div>
                                <Grid item xs={12}>
                                    <label htmlFor="password">Code</label>
                                    <OutlinedInput
                                        fullWidth
                                        value={code}
                                        onChange={onChangeCode}
                                        placeholder='Enter code'
                                        className='signup-inputs-large'
                                        name='code'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <label htmlFor="password"> Password</label>
                                    <OutlinedInput
                                        fullWidth
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={onPasswordChange}
                                        placeholder='Enter password'
                                        className='signup-inputs-large'
                                        name='password'
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    size="large">
                                                    {showPassword ?
                                                        <img alt='visibility On' src={PreviewOn} /> :
                                                        <img alt='visibility Off' src={PreviewOff} />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Grid>
                                {
                                    disableNextButton ? <Grid container spacing={1} item xs={12} className={Classes.passChecksDiv}>
                                        <Grid item xs={5} className={Classes.passStrengthCheck}>
                                            {validatePassword('upperCaseChar', password) ?
                                                <img src={SuccessTick} alt='success mark' /> :
                                                <img src={SuccessTickDisabled} alt='failure mark' />}
                                            <p className={Classes.passStrenghtOptText}>One upper case character</p>
                                        </Grid>
                                        <Grid item xs={4} className={Classes.passStrengthCheck}>
                                            {validatePassword('specialChar', password) ?
                                                <img src={SuccessTick} alt='success mark' /> :
                                                <img src={SuccessTickDisabled} alt='failure mark' />}
                                            <p className={Classes.passStrenghtOptText}>One special character</p>
                                        </Grid>
                                        <Grid item xs={3} className={Classes.passStrengthCheck}>
                                            {validatePassword('numberChar', password) ?
                                                <img src={SuccessTick} alt='success mark' /> :
                                                <img src={SuccessTickDisabled} alt='failure mark' />}
                                            <p className={Classes.passStrenghtOptText}>One number</p>
                                        </Grid>
                                        <Grid item xs={5} className={Classes.passStrengthCheck}>
                                            {validatePassword('lowerCaseChar', password) ?
                                                <img src={SuccessTick} alt='success mark' /> :
                                                <img src={SuccessTickDisabled} alt='failure mark' />}
                                            <p className={Classes.passStrenghtOptText}>One lower case character</p>
                                        </Grid>
                                        <Grid item xs={5} className={Classes.passStrengthCheck}>
                                            {validatePassword('minChar', password) ?
                                                <img src={SuccessTick} alt='success mark' /> :
                                                <img src={SuccessTickDisabled} alt='failure mark' />}
                                            <p className={Classes.passStrenghtOptText}>Minimum 8 characters</p>
                                        </Grid>
                                    </Grid>
                                        :
                                        <Grid item xs={12} className={Classes.passSuccessContainer}>
                                            <Grid container
                                                className={Classes.passStrengthCheckSuccessCont}>
                                                <img src={SuccessTick} alt='success mark' />
                                                <span className={Classes.passStrengthCheckSuccess}>
                                                    Your password is secured and you are ready to proceed.</span>
                                            </Grid>
                                        </Grid>
                                }
                                <button className={`solid-button ${Classes.sendResetNotButton}`}
                                    disabled={!validatePassword(null, password) || !code}
                                    onClick={handlePasswordChangeSubmit}>Sign In Now</button>
                                {authError && <InfoText errorMessage={authError} type="Error" />}
                                <p className={`no-outlined-button ${Classes.cancelButton}`}
                                    onClick={onResendClick}>Resend</p>
                            </React.Fragment>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ForgotPassword;
import DCAFormStepper from './DCAFormStepper'
import { connect } from 'react-redux'
import { setDCAStage, resetDCAForm } from '../../../redux/actions/dcaActions'
import { resetProjectDetails } from '../../../redux/actions/projectDetailsActions'
import { resetProjectInstitutionForm } from '../../../redux/actions/projectPrimaryInstitutionalActions'
import { resetPIForm } from '../../../redux/actions/projectPIActions'
import { resetProjectAdminForm } from '../../../redux/actions/projectAdminActions'
import { resetZone3UserForm } from '../../../redux/actions/zone3UsersActions'
import { resetAuthSubmitterForm } from '../../../redux/actions/authorizeSubmitterActions'
const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDCAStage: async (value) => await dispatch(setDCAStage(value)),

        resetDCAForm: async () => await dispatch(resetDCAForm()),
        resetProjectDetails: async () => await dispatch(resetProjectDetails()),
        resetProjectInstitutionForm: async () => await dispatch(resetProjectInstitutionForm()),
        resetPIForm: async () => await dispatch(resetPIForm()),
        resetProjectAdminForm: async () => await dispatch(resetProjectAdminForm()),
        resetZone3UserForm: async () => await dispatch(resetZone3UserForm()),
        resetAuthSubmitterForm: async () => await dispatch(resetAuthSubmitterForm()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DCAFormStepper);
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Box,
  Stack,
} from '@mui/material';
import { CloseOutlined, Search } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useMemo } from 'react';
import { debounce } from 'lodash';
import ProjectFilterAccordion from './ProjectFilterAccordion';
import { vars } from '../../../../../assets/variables';
import { titleCase } from '../../utils';

const { tertiaryButtonColor, btnOutlinedPrimaryHoverBg } = vars;

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 0,
    },
  },
  dialogText: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiButton-root': {
      width: 'max-content',
    },
    '& .tertiary': {
      marginRight: '0.5rem',
      background: tertiaryButtonColor,
      '&:hover': {
        background: btnOutlinedPrimaryHoverBg,
      },
    },
    '& .right-action': {
      display: 'flex',
    },
  },
}));

const ProjectsFilterDialog = ({
  open,
  title,
  filters,
  onClose,
  handleFilterChange,
  handleReset,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');
  const watchFilters = useMemo(() => filters, [filters]);
  const attributes = Object.keys(filters);

  //debounce search term
  const debounceFn = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 800),
    [searchTerm]
  );

  function onSearch(event) {
    setValue(event.target.value);
    debounceFn(event.target.value);
  }

  const renderFilters = useMemo(() => {
    if (watchFilters && Array.isArray(attributes)) {
      return (
        <Stack>
          {attributes?.map((filterKey, index) => {
            const currentFilter = watchFilters[filterKey] ?? {};

            return (
              <>
                <ProjectFilterAccordion
                  key={`attributes_${index}`}
                  filterKey={filterKey}
                  filters={currentFilter}
                  searchTerm={searchTerm}
                  handleFilterChange={handleFilterChange}
                />
              </>
            );
          })}
        </Stack>
      );
    }
  }, [watchFilters, searchTerm, attributes, handleFilterChange]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="xs"
        className={classes.dialog}
      >
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignContent="center"
          sx={{
            paddingTop: '1.5rem',
            paddingX: '1rem',
            fontWeight: 500,
          }}
        >
          <Typography
            noWrap
            sx={{ maxWidth: '80%' }}
            textTransform="capitalize"
            lineHeight={1}
          >
            {title && titleCase(title)}
          </Typography>
          <IconButton
            onClick={() => {
              if (onClose) onClose();
              setValue('');
              debounceFn('');
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box paddingX={2} paddingY={1}>
            <TextField
              placeholder="Search for Filter"
              value={value}
              variant="outlined"
              onChange={(e) => onSearch(e)}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>

          {renderFilters}
        </DialogContent>
        <DialogActions
          sx={{
            display: 'block',
            padding: '0.75rem',
            marginTop: 0,
            minWidth: '100%',
            border: `1px solid rgba(208, 213, 221, 1)`,
            boxShadow: ` 0px -4px 8px 0px rgba(16, 24, 40, 0.1)`,
          }}
        >
          <Box className={classes.actions}>
            <Button
              color="primary"
              className="disable-shadow"
              variant="outlined"
              size="small"
              onClick={async () => {
                // reset cohorts filters
                await handleReset();
              }}
            >
              Clear
            </Button>
            <Box className="right-action">
              <Button
                size="small"
                variant="contained"
                color="primary"
                className="disable-shadow"
                onClick={() => {
                  if (onClose) onClose();
                  setValue('');
                  debounceFn('');
                }}
              >
                See Results
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

ProjectsFilterDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  filters: PropTypes.object,
  attributes: PropTypes.array,
  filterKey: PropTypes.string,
  handleReset: PropTypes.func,
};

export default ProjectsFilterDialog;

import DIFOtherPersonal from './DIFOtherPersonal'
import { connect } from 'react-redux'
import { addDifOPUsers, removeOtherPersonalUser, updateDifOPUsersFirstName, updateDifOPUsersLastName,
    updateDifOPUsersTitle, updateDifOPUsersEmail, setDifOPWillOtherUploadData
} from '../../../redux/actions/difOtherPersonalActions'
const mapStateToProps = state => {
    return {
        difOPAddedUsers: state.difOP.difOPAddedUsers,
        difOPWillOtherUpload: state.difOP.difOPWillOtherUpload,
        currentDIFFormStage: state.dataInventory.currentDIFFormStage
    }
}
const mapDispatchToProps = dispatch => {
    return {
        addDifOPUsers: async (value) => await dispatch(addDifOPUsers(value)),
        removeOtherPersonalUser: async (value) => await dispatch(removeOtherPersonalUser(value)),
        updateDifOPUsersFirstName: async (value, id) => await dispatch(updateDifOPUsersFirstName(value, id)),
        updateDifOPUsersLastName: async (value, id) => await dispatch(updateDifOPUsersLastName(value, id)),
        updateDifOPUsersTitle: async (value, id) => await dispatch(updateDifOPUsersTitle(value, id)),
        updateDifOPUsersEmail: async (value, id) => await dispatch(updateDifOPUsersEmail(value, id)),
        setDifOPWillOtherUploadData: async (value) => await dispatch(setDifOPWillOtherUploadData(value)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DIFOtherPersonal);
import Register from './Register'
import { connect } from 'react-redux'
import { updateUserLogin } from '../../../redux/actions/userActions'
import { onFirstNameChange, onLastNameChange, onPasswordChange, onEmailChange, onOrgNameChange, 
    onConfirmPassChange } from '../../../redux/actions/userSignupActions'

const mapStateToProps = state => {
    return {
        firstname: state.userSignup.firstName,
        lastname: state.userSignup.lastName,
        password: state.userSignup.password,
        email: state.userSignup.email,
        orgName: state.userSignup.orgName,
        confirmPass: state.userSignup.confirmPass,
        showCookieBanner: state.home.showCookieBanner
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFirstNameChange: async(value) => await dispatch(onFirstNameChange(value)),
        onLastNameChange: async(value) => await dispatch(onLastNameChange(value)),
        onPasswordChange: async(value) => await dispatch(onPasswordChange(value)),
        onEmailChange: async(value) => await dispatch(onEmailChange(value)),
        onOrgNameChange: async(value) => await dispatch(onOrgNameChange(value)),
        onConfirmPassChange : async(value) => await dispatch(onConfirmPassChange(value)),
        updateUserLogin: async (value) => await dispatch(updateUserLogin(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
import React from 'react'
import AnalyzeData from '../analyze-data';
import { Router } from '@reach/router'

const AnalysisHome = props => {
    return (
        <Router id="analysis-route" primary={false}>
            <AnalyzeData path="/analyze-data/" open={props.open} />
        </Router>
    )
}

export default AnalysisHome;
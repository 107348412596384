import React from 'react';
import { Router } from "@reach/router";
import CartList from './CartList'
import CartSuccess from './CartSuccess'


const Cart = (props) => <Router id="router" primary={false} >
  <CartList path="/*id" updateSnackBar={props.updateSnackBar} allWorkspacesCarts={props.allWorkspacesCarts} />
  <CartSuccess path="/success" />
</Router>

export default Cart;
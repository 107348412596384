import React from 'react';
import { Router } from "@reach/router";
import InvoiceLanding from './InvoiceLanding'
import GenerateInvoice from './GenerateInvoice'

const Invoice = ({ updateSnackBar }) => <Router id="router" primary={false}>
  <InvoiceLanding path="/" updateSnackBar={updateSnackBar} />
  <GenerateInvoice path="/*" updateSnackBar={updateSnackBar} />
</Router>

export default Invoice;
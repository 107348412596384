import axios from 'axios'
import {env} from '../env'
import get from 'lodash/get'
import { getCognitoIdToken } from './utilityMethods';
import { BASE_URL } from '../constants/google-login-urls'

const REQUEST_BASE_URL = `${env.REACT_APP_BASE_API_PATH}`;


export const requestStudyForDownload = async (body) => {
    try{
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        const url = `${BASE_URL}/cost/project_download_request/`;
        const res = await axios.post(url, body, { headers: header });
        return res?.data || null
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const getAllStudyDownloadRequest = async () => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        };
        const url = `${BASE_URL}/cost/project_download_request`;
        const res = await axios.get(url, { headers: header });
        return res?.data || null
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const getStudyDownloadPresignedURL = async (projectId = '', fileFormat = '') => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
    };
    const url = `${BASE_URL}/cost/project_download_request/url/${projectId}/${fileFormat}`;
    const res = await axios.get(url, { headers: header });
    return res?.data || null
}

export const getIndividualStudyProjectData = async (projectId = '') => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        };
        const url = `${REQUEST_BASE_URL}/project_download_request/available_format/${projectId}`;
        const res = await axios.get(url, { headers: header });
        return res?.data || null
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const getIndividualStudyProjectOrderData = async (projectId = '') => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        };
        const url = `${BASE_URL}/cost/project_download_request/${projectId}`;
        const res = await axios.get(url, { headers: header });
        return res?.data || null
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

export const updateStudyProjectDownloadRequest = async (request_id , body) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const url = `${BASE_URL}/cost/project_download_request/${request_id }`;
        const res = await axios.put(url, body, {headers: header });
        return res?.data || {};
    } catch (error) {
        throw Error(error?.response?.data?.message);
    }
}

import DIFStudy from './DIFStudy'
import { connect } from 'react-redux'
import {
    setDifStudyName,
    setDifStudyObjective,
    setDifStudyDescription,
    setDifStudyLogoFile,
    setDifStudyDesign,
    setDifStudyNumberOfGrants,
    setDifStudyAgencyName,
    setDifStudyAgencyNumber,
    setDifStudyDurationOfMonths,
    setDifStudyLocationOfStudy,
    setDifStudyPublicationName,
    setDifStudyPublicationURL,
    removeDifStudyPublications,
    setDifStudyPublications,
    setDifStudyLogoFileKey,
    setDifStudyOptIn

} from '../../../redux/actions/difStudyActions'
import { updateSnackBar } from '../../../redux/actions/globalActions'
const mapStateToProps = state => {
    return {
        difStudyName : state.difStudy.difStudyName,
        difStudyObjective : state.difStudy.difStudyObjective,
        difStudyDescription : state.difStudy.difStudyDescription,
        difStudyLogoFile : state.difStudy.difStudyLogoFile,
        difStudyDesign : state.difStudy.difStudyDesign,
        difStudyNumberOfGrants : state.difStudy.difStudyNumberOfGrants,
        difStudyDurationOfMoths : state.difStudy.difStudyDurationOfMoths,
        currentDIFFormStage : state.dataInventory.currentDIFFormStage,
        difStudyLocation : state.difStudy.difStudyLocation,
        difStudyPublications : state.difStudy.difStudyPublications,
        difStudyGrantInfo: state.difStudy.difStudyGrantInfo,
        logoS3Key: state.difStudy.logoS3Key,
        difStudyOptIn: state.difStudy.difStudyOptIn
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setDifStudyName : async (value) => await dispatch(setDifStudyName(value)),
        setDifStudyObjective : async (value) => await dispatch(setDifStudyObjective(value)),
        setDifStudyDescription : async (value) => await dispatch(setDifStudyDescription(value)),
        setDifStudyDesign: async (value) => await dispatch(setDifStudyDesign(value)),
        setDifStudyLogoFile : async (value) => await dispatch(setDifStudyLogoFile(value)),
        setDifStudyNumberOfGrants : async (value) => await dispatch(setDifStudyNumberOfGrants(value)),
        setDifStudyAgencyName : async (value, id) => await dispatch(setDifStudyAgencyName(value, id)),
        setDifStudyAgencyNumber : async (value, id) => await dispatch(setDifStudyAgencyNumber(value, id)),
        setDifStudyDurationOfMonths : async (value) => await dispatch(setDifStudyDurationOfMonths(value)),
        setDifStudyLocationOfStudy : async (value) => await dispatch(setDifStudyLocationOfStudy(value)),
        setDifStudyPublicationName : async (value, id) => await dispatch(setDifStudyPublicationName(value, id)),
        setDifStudyPublicationURL : async (value, id) => await dispatch(setDifStudyPublicationURL(value, id)),
        removeDifStudyPublications : async (value) => await dispatch(removeDifStudyPublications(value)),
        setDifStudyPublications : async (value) => await dispatch(setDifStudyPublications(value)),
        updateSnackBar: async (message, type) => await dispatch(updateSnackBar(message, type)),
        setDifStudyLogoFileKey : async (value) => await dispatch(setDifStudyLogoFileKey(value)),
        setDifStudyOptIn : async (value) => await dispatch(setDifStudyOptIn(value)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DIFStudy);
import { 
    SET_DIF_STUDY_NAME,
    SET_DIF_STUDY_OBJECTIVE,
    SET_DIF_STUDY_DESCRIPTION,
    SET_DIF_STUDY_DESIGN,
    SET_DIF_STUDY_LOGO_FILE,
    SET_DIF_STUDY_NUMBER_OF_GRANTS,
    SET_DIF_STUDY_DURATION_OF_MONTHS,
    SET_DIF_STUDY_LOCATION_OF_STUDY,
    SET_DIF_STUDY_PUBLICATIONS,
    SET_DIF_STUDY_AGENCY_NAME,
    SET_DIF_STUDY_AGENCY_NUMBER,
    SET_DIF_STUDY_PUBLICATION_NAME,
    SET_DIF_STUDY_PUBLICATION_URL,
    REMOVE_DIF_STUDY_PUBLICATIONS,
    SET_DIF_STUDY_DATA,
    RESET_DIF_STUDY_DATA,
    SET_DIF_STUDY_LOGO_S3_KEY,
    SET_DIF_STUDY_OPT_IN
} from '../action-types'
import get from 'lodash/get'
const initialState = {
    difStudyName : "",
    difStudyObjective : "",
    difStudyDescription : "",
    difStudyLogoFile : "",
    difStudyDesign : "",
    difStudyNumberOfGrants : "",
    difStudyDurationOfMoths : "",
    difStudyLocation : "",
    difStudyGrantInfo: [{
        "agency_name": '',
        "agency_number": ''
    }],
    logoS3Key: '',
    difStudyPublications: [{"publication_name": '', "publication_url": ''}],
    difStudyOptIn: true
}

const difStudyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DIF_STUDY_NAME: {
            return {
                ...state,
                difStudyName: action.payload
            }
        }
        case SET_DIF_STUDY_OBJECTIVE: {
            return {
                ...state,
                difStudyObjective: action.payload
            }
        }
        case SET_DIF_STUDY_DESCRIPTION: {
            return {
                ...state,
                difStudyDescription: action.payload
            }
        }
        case SET_DIF_STUDY_DESIGN: {
            return {
                ...state,
                difStudyDesign: action.payload
            }
        }
        case SET_DIF_STUDY_LOGO_FILE: {
            return {
                ...state,
                difStudyLogoFile: action.payload
            }
        }
        case SET_DIF_STUDY_NUMBER_OF_GRANTS: {
            return {
                ...state,
                difStudyNumberOfGrants: action.payload
            }
        }
        case SET_DIF_STUDY_DURATION_OF_MONTHS: {
            return {
                ...state,
                difStudyDurationOfMoths: action.payload
            }
        }
        case SET_DIF_STUDY_LOCATION_OF_STUDY: {
            return {
                ...state,
                difStudyLocation: action.payload
            }
        }
        case SET_DIF_STUDY_LOGO_S3_KEY: {
            return {
                ...state,
                logoS3Key: action.payload
            }
        }
        case SET_DIF_STUDY_OPT_IN: {
            return {
                ...state,
                difStudyOptIn: action.payload
            }
        }
        case SET_DIF_STUDY_PUBLICATIONS: {
            const newPublication =  [...state.difStudyPublications];
            newPublication.push(action.payload);
            return {
                ...state,
                difStudyPublications: newPublication
            }
        }
        case SET_DIF_STUDY_AGENCY_NAME: {
            const { value, id } = action.payload;
            let newGrantInfo = [...state.difStudyGrantInfo];
            newGrantInfo[id] = {
                ...newGrantInfo[id],
                'agency_name': value
            }
            return {
                ...state,
                difStudyGrantInfo: newGrantInfo
            }
        }
        case SET_DIF_STUDY_AGENCY_NUMBER: {
            const { value, id } = action.payload;
            let newGrantInfo = [...state.difStudyGrantInfo];
            newGrantInfo[id] = {
                ...newGrantInfo[id],
                'agency_number': value
            }
            return {
                ...state,
                difStudyGrantInfo: newGrantInfo
            }
        }
        case SET_DIF_STUDY_PUBLICATION_NAME: {
            const { value, id } = action.payload;
            let newPublication = [...state.difStudyPublications];
            newPublication[id] = {
                ...newPublication[id],
                'publication_name': value
            }
            return {
                ...state,
                difStudyPublications: newPublication
            }
        }
        case SET_DIF_STUDY_PUBLICATION_URL: {
            const { value, id } = action.payload;
            let newPublication = [...state.difStudyPublications];
            newPublication[id] = {
                ...newPublication[id],
                'publication_url': value
            }
            return {
                ...state,
                difStudyPublications: newPublication
            }
        }
        case REMOVE_DIF_STUDY_PUBLICATIONS: {
            let newPublication =  [...state.difStudyPublications];
            newPublication.splice(action.payload, 1);
            return {
                ...state,
                difStudyPublications: newPublication
            }
        }
        case SET_DIF_STUDY_DATA: {
            const data = { ...action.payload };
            return {
                difStudyName : get(data, 'study_name', ''),
                difStudyObjective : get(data, 'study_objective', ''),
                difStudyDescription : get(data, 'study_description', ''),
                difStudyLogoFile : '',
                difStudyDesign : get(data, 'study_design', ''),
                difStudyNumberOfGrants : get(data, 'number_of_grants', ''),
                difStudyDurationOfMoths : get(data, 'study_duration', ''),
                difStudyLocation : get(data, 'study_location', ''),
                difStudyGrantInfo: get(data, 'grants', [{"agency_name": '', "agency_number": ''}]),
                logoS3Key: get(data, 'logo', ''),
                difStudyPublications: get(data, 'publications', [{"publication_name": '', "publication_url": ''}]),
                difStudyOptIn: !get(data, 'is_opted_out_from_study_gallery', true)
            }
        }
        case RESET_DIF_STUDY_DATA: {
            return {
                difStudyName: "",
                difStudyObjective: "",
                difStudyDescription: "",
                difStudyLogoFile: "",
                difStudyDesign: "",
                difStudyNumberOfGrants: "",
                difStudyDurationOfMoths: "",
                difStudyLocation: "",
                difStudyGrantInfo: [{
                    "agency_name": '',
                    "agency_number": ''
                }],
                logoS3Key: '',
                difStudyPublications: [{ "publication_name": '', "publication_url": '' }],
                difStudyOptIn: true
            }
        }
        default: return state
    }
}

export default difStudyReducer;
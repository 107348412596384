import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import map from 'lodash/map'
import v4 from 'uuid/v4'
import Collapse from '@mui/material/Collapse'
import Classes from './index.module.css'
import WorkspaceLogo from '../../../assets/icons/workspacelogo.svg';
import Tooltip from '@mui/material/Tooltip'
import get from 'lodash/get'
import MuiPopover from '@mui/material/Popover';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {deletePricingConfiguration, suspendPricingConfiguration, activatePricingConfiguration} from '../../../api/pricing-api'
import { Router, Link, navigate } from '@reach/router';
import CustomLoader from '../../commons/custom-loader';
import { cloneDeep, findIndex, isEmpty, find } from 'lodash'
import DotIconDark from "../../../assets/icons/MoreOptionsVertical.S-dark.svg"
import CautionIcon from '../../../assets/images/caution.svg';
import Modal from '../../commons/modal'
import ErrorText from '../../controls/error-text';
import OutlinedInput from '@mui/material/OutlinedInput'
import InfoGreyIcon from '../../../assets/icons/InfoIcon.svg';

const useStyles = makeStyles(theme => ({
    accessMassages: {
        width: '64%',
        textAlign: 'center',
        padding: '30px',
        fontSize:'1.125rem',
        letterSpacing: '.36px',
        color: '#757576',
        opacity: 1,
        margin: '0 auto',
        fontFamily: "Raleway"
    },
    commonBlueButton:{
        background: '#637bbd',
        padding: '0px 20px',
        height: '40px',
        fontFamily: "Raleway"
    },
    buttonAlign:{
        textAlign:'center',
        marginLeft: '19px',
        marginTop: '12px',

    }
}));

const Popover = withStyles(theme => ({
    root: {
      overflowY: 'scroll',
    },
    paper: {
      minWidth: 300,
      right: 'auto !important',
      marginTop: '32px !important',
    }
  }))(MuiPopover);
const PricingContainer = (props) => {
    
    const materialClasses = useStyles();
    const [anchorElChangeWorkspaceCard, setAnchorElChangeWorkspaceCard] = useState(null);
    const [selectedPricingConfiguration, setSelectedPricingConfiguration ] = useState({})
    const openWorkspaceChangePopover = Boolean(anchorElChangeWorkspaceCard);
    const [deleteConfigurationModal, setDeleteConfigurationModal] = useState(false);
    const [suspendConfigurationModal, setSuspendConfigurationModal] = useState(false);
    const [activateConfigurationModal, setActivateConfigurationModal] = useState(false);
    const [deactivationInProgress, setDeactivationInProgress] = useState(false);
    const [reason, setReason] = useState('');
    const { updateSnackBar = () => { } } = props;
    const [errors, setErrors] = useState({})

    const validateForm = () => {
        let errors = {};
        if (!reason) {
          errors['reason'] = 'Please enter suspension reason'
        }
        setErrors(errors)
        return Object.keys(errors)?.length === 0
    }

    const onDeletePricingConfig = async () => {
        try{
            setDeactivationInProgress(true)
            const response = await deletePricingConfiguration(selectedPricingConfiguration?.id);
            await props.loadPricingDetails();
            updateSnackBar("Pricing configuration deleted successfully", "Success");
        }catch (error){
            console.log(error);
            updateSnackBar(error?.message || "Pricing configuration not deleted", "Error");
        }finally{
            setDeactivationInProgress(false);
            closeDeleteConfigurationModal();
            setAnchorElChangeWorkspaceCard(null)
        }

    }

    const onSuspendPricingConfig = async () => {
        const isFormValid = validateForm();
        if (!isFormValid) {
            return;
        }
        try{
            setDeactivationInProgress(true)
            let body = {
                reason: reason
            }
            const response = await suspendPricingConfiguration(body, selectedPricingConfiguration?.id);
            await props.loadPricingDetails();
            updateSnackBar("Pricing configuration suspended successfully", "Success");
        }catch (error){
            console.log(error);
            updateSnackBar(error?.message || "Pricing configuration not suspended", "Error");
        }finally{
            setDeactivationInProgress(false);
            closeSuspendConfigurationModal();
            setAnchorElChangeWorkspaceCard(null)
        }
        
    }

    const onActivatePricingConfig = async () => {
        try{
            setDeactivationInProgress(true)
            const response = await activatePricingConfiguration(selectedPricingConfiguration?.id);
            await props.loadPricingDetails();
            updateSnackBar("Pricing configuration activated successfully", "Success");
        }catch (error){
            console.log(error);
            updateSnackBar(error?.message || "Pricing configuration not activated", "Error");
        }finally{
            setDeactivationInProgress(false);
            closeActivateConfigurationModal();
            setAnchorElChangeWorkspaceCard(null)
        }
        
    }

    const onEditPricingDetail = (id) => {
        navigate(`/pricing/edit-pricing-configuration/${id}`)
    }

    const handleDownArrowClick = (event, row) => {
        setAnchorElChangeWorkspaceCard(event.currentTarget);
        setSelectedPricingConfiguration(row)
    }
    
    const closeDeleteConfigurationModal = () => {
        setDeleteConfigurationModal(false);
    }

    const DELETE_CONFIGURATION_MODAL_PROPS = {
        modalTitle: 'Delete configuration?',
        positiveButtonText: "Delete Configuration",
        negativeButtonText: "Cancel",
        positiveButtonAction: onDeletePricingConfig,
        negativeButtonAction: closeDeleteConfigurationModal,
        imageSrc: CautionIcon,
        popupButtonDisable: deactivationInProgress
    }

    const closeSuspendConfigurationModal = () => {
        setSuspendConfigurationModal(false);
    }

    const SUSPEND_CONFIGURATION_MODAL_PROPS = {
        modalTitle: 'Suspend configuration?',
        positiveButtonText: "Suspend Configuration",
        negativeButtonText: "Cancel",
        positiveButtonAction: onSuspendPricingConfig,
        negativeButtonAction: closeSuspendConfigurationModal,
        imageSrc: CautionIcon,
        popupButtonDisable: deactivationInProgress
    }

    const closeActivateConfigurationModal = () => {
        setActivateConfigurationModal(false);
    }

    const ACTIVATE_CONFIGURATION_MODAL_PROPS = {
        modalTitle: 'Activate configuration?',
        positiveButtonText: "Activate Configuration",
        negativeButtonText: "Cancel",
        positiveButtonAction: onActivatePricingConfig,
        negativeButtonAction: closeActivateConfigurationModal,
        imageSrc: CautionIcon,
        popupButtonDisable: deactivationInProgress
    }

    const getPreviewText = (pricingDetail) => {
        return <span>{
            pricingDetail?.label ? `${pricingDetail?.label}: ` : null
        }
        {
            (pricingDetail?.discount) && (pricingDetail?.price != 0) ?
            
            <span style={{textDecoration: 'line-through'}}>${pricingDetail?.price} </span> 
            
            : null
        }
        {
            (pricingDetail?.price == 0) ?

            <span>FREE </span> :

            (pricingDetail?.price) ?

            ` $${((pricingDetail?.price) * 1) * ((100 - pricingDetail?.discount)/100)} ` : null
        } 
        
        {' ' + pricingDetail?.notes}</span>
    }   
    
    
    return (
        <Grid container spacing={4}>

            <Modal
                open={deleteConfigurationModal}
                handleClose={closeDeleteConfigurationModal}
                dialogProps={DELETE_CONFIGURATION_MODAL_PROPS}
                enableImage={true}
                disableBottomHorizontalDivider={true}
            >
                <div className={Classes.deactivateBody}>
                    <p>Are you sure you want to delete configuration?</p>
                </div>
            </Modal>

            <Modal
                open={activateConfigurationModal}
                handleClose={closeActivateConfigurationModal}
                dialogProps={ACTIVATE_CONFIGURATION_MODAL_PROPS}
                enableImage={true}
                disableBottomHorizontalDivider={true}
            >
                <div className={Classes.deactivateBody}>
                    <p>This download option will be activated.</p>
                </div>
            </Modal>

            <Modal
                open={suspendConfigurationModal}
                handleClose={closeSuspendConfigurationModal}
                dialogProps={SUSPEND_CONFIGURATION_MODAL_PROPS}
                enableImage={true}
                disableBottomHorizontalDivider={true}
            >
                <div className={Classes.deactivateBody}>
                    <p>This download option will be temporarily stopped.</p>
                </div>

                <div>

                    <label htmlFor='reason' id="cvb-pricingConfiguration-projectTitleLabel">Reason<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        value={reason}
                        fullWidth
                        inputProps={{ maxLength: 256 }}
                        onChange={e => setReason(e.target.value)}
                        id='cvb-pricingConfiguration-projectTitle'
                        name='reason'
                        placeholder='Enter an explanatory text to be shown instead of its price'
                    />
                    {errors['reason'] && !reason && <ErrorText errorMessage={errors['reason']} />}
                </div>

                <div style={{marginTop:'15px'}}>
                    <label htmlFor='reason' id="cvb-pricingConfiguration-projectTitlePreview">Preview:</label>
                    <div>
                        {
                            reason ? selectedPricingConfiguration?.name + ': ' + reason : '-'
                        }
                    </div>
                </div>
            </Modal>
            {
                props?.pricingDetails && props.pricingDetails?.length === 0 ? 
                <div id='cvb-workspaceCardsContainer-accessMessage' className={materialClasses.accessMassages}>You don't yet have Pricing configured.</div>
                : 

                props.pricingDetails.map((pricingDetail) => {
                    const workspaceStatusBackground = pricingDetail.status === "ACTIVE"
                    ? Classes.activeWorkspaceBackground
                    : Classes.stoppedWorkspaceBackground;
                    return <Grid key={pricingDetail?.name + pricingDetail.id} item lg={4} md={6} xs={12} className={Classes.cardContainerGrigItem}>
                    <div className={Classes.cardContainerWrapper}>
                      <div className={`${Classes.cardContainer}`}>
                        <div className={Classes.WorkspaceLogoContainer}>
                          
                          <p id='cvb-workspaceCards-workspaceId' className={Classes.WorkspaceText}>
                            {pricingDetail?.label}
                          </p>
                        </div>
                        <Grid container xs={12} className={Classes.idSizeContainer}>
                          <Grid item xs={6}>
                            <div className={Classes.cardInfoContainer}>
                              <div className={Classes.VerticalLine}>
                              </div>
                              <p id='cvb-workspaceCards-id-label' className={Classes.titleText}>Applies To:</p>
                                <p id='cvb-workspaceCards-workspaceId' className={Classes.subTitleText} >
                                  {pricingDetail?.applies_to}
                                </p>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className={` ${Classes.cardInfoContainer} ${Classes.statusReasonContainer} `}>
                              <div className={Classes.VerticalLine}>
                              </div>
                              <p id='cvb-workspaceCards-sharedWith-label' className={`${Classes.titleText} ${Classes.titleTextStatus}`}>Status:</p>
                              <div id='cvb-workspaceCards-type-text' className={`${Classes.workspaceStatus} ${workspaceStatusBackground}`} >{pricingDetail?.status}</div>
                                {
                                    pricingDetail?.suspend_reason && pricingDetail?.status?.toLowerCase() === 'suspended' ?
                                    <Tooltip title={pricingDetail?.suspend_reason}>
                                        <img className={Classes.infoIcon} src={InfoGreyIcon} alt="info icon" />
                                    </Tooltip> : null
                                }
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className={Classes.cardInfoContainer}>
                              <div className={Classes.VerticalLine}>
                              </div>
                              <p id='cvb-workspaceCards-type-label' className={Classes.titleText}>Price:</p>
                              <p id='cvb-workspaceCards-type-text' className={Classes.subTitleText} >
                                ${pricingDetail?.price}/GB
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className={Classes.cardInfoContainer}>
                              <p id='cvb-workspaceCards-type-label' className={Classes.titleText}>Discount:</p>
                              <p id='cvb-workspaceCards-type-text' className={`${Classes.subTitleText}`} >{pricingDetail?.discount ? `${pricingDetail?.discount}%` : '-'}</p>
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                            <div className={Classes.cardInfoContainer}>
                              <p id='cvb-workspaceCards-type-label' className={Classes.titleText}>Note:</p>
                              <p id='cvb-workspaceCards-type-text' className={`${Classes.subTitleText}`} >{pricingDetail?.notes || '-'}</p>
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                            <div className={Classes.cardInfoContainer}>
                              <p id='cvb-workspaceCards-type-label' className={Classes.titleText}>Preview:</p>
                                <Tooltip title={getPreviewText(pricingDetail)}>
                                    <p id='cvb-workspaceCards-type-text' className={`${Classes.subTitleText}`} >
                                        {getPreviewText(pricingDetail)}
                                    </p>
                                </Tooltip>
                            </div>
                          </Grid>
                        </Grid>
                        <div className={Classes.horizontalLine}></div>
                        <Grid item xs={12} className={Classes.launchWorkspaceContainer}>
            
                          {/* <div className={Classes.launchWorkspaceVerticalLine}></div> */}
            
                          <div className={Classes.shareIconsWrapper}>
                            <button id='cvb-workspaceCards-launchWorkspace-button' onClick={() => onEditPricingDetail(pricingDetail?.id)} className={`${Classes.launchText} ${Classes.addPaddingRight} ${Classes.addBorderRight}`}>
                              Edit
                            </button>
                            <button className={`${Classes.launchText} ${Classes.addPaddingRight} ${Classes.addBorderRight}`} onClick={e => handleDownArrowClick(e, pricingDetail)}>
                              <img src={DotIconDark} alt="more icon" />
                            </button>
                            <Popover
                              id={'WorkspaceCard-workspaceCred-popover'}
                              open={openWorkspaceChangePopover}
                              anchorEl={anchorElChangeWorkspaceCard}
                              onClose={() => setAnchorElChangeWorkspaceCard(false)}
                              anchorOrigin={{
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                horizontal: 'left',
                              }}
                            >
                              <div className={Classes.workspaceAccessContainer}>
                                <ul className={Classes.workSpaceOptionsPopover}>
                                  <li>
                                    <div className={Classes.addPaddingMargin} >
                                        <p id='cvb-workspaceCards-downArrow-button' onClick={() => setDeleteConfigurationModal(true)}>Delete</p>
                                    </div>
                                  </li>

                                  <li>
                                    <div className={Classes.addPaddingMargin} >
                                        {
                                            selectedPricingConfiguration?.status === "SUSPENDED" ?

                                            <p id='cvb-workspaceCards-downArrow-button' onClick={() => setActivateConfigurationModal(true)}>Activate</p> :

                                            <p id='cvb-workspaceCards-downArrow-button' onClick={() => setSuspendConfigurationModal(true)}>Suspend</p>
                                            
                                            
                                        }
                                        
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </Popover>
                          </div>
                        </Grid>
                      </div>
                      <div className={`${Classes.workspaceStatusContainer} ${workspaceStatusBackground}`}>
                        
                    </div>
                    </div>
                  </Grid>   
                })
            }
        </Grid>
    )
}

export default PricingContainer;
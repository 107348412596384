
import { SET_DIF_TIMELINE_IS_DEADLINE_TO_UPLOAD, SET_DIF_TIMELINE_IS_DEADLINE_TO_DATA_ACCESS,
    SET_DIF_TIMELINE_IS_DATA_EMBARGOED, SET_DIF_TIMELINE_SUBMISSION_DATE, SET_DIF_TIMELINE_DATA_ACCESS_DEADLINE_DATE,
    SET_DIF_TIMELINE_DATA_EMBARGOED_DATE, SET_DIF_TIMELINE_DATA_EMBARGOED_DESC
    , SET_TIMELINE_FORM_DATA, RESET_TIMELINE_FORM_DATA
} from '../action-types'
    
export const setDifTimelineIsDeadlineToUpload = (value) => {
    return {
        type: SET_DIF_TIMELINE_IS_DEADLINE_TO_UPLOAD,
        payload: value
    }
}
export const setDifTimelineIsDeadlineToDataAccess = (value) => {
    return {
        type: SET_DIF_TIMELINE_IS_DEADLINE_TO_DATA_ACCESS,
        payload: value
    }
}
export const setDifTimelineIsDataEmbargoed = (value) => {
    return {
        type: SET_DIF_TIMELINE_IS_DATA_EMBARGOED,
        payload: value
    }
}
export const setDifTimelineSubmissionDate = (value) => {
    return {
        type: SET_DIF_TIMELINE_SUBMISSION_DATE,
        payload: value
    }
}
export const setDifTimelineDataAccessDeadlineDate = (value) => {
    return {
        type: SET_DIF_TIMELINE_DATA_ACCESS_DEADLINE_DATE,
        payload: value
    }
}
export const setDifTimelineDataEmbargoedDate = (value) => {
    return {
        type: SET_DIF_TIMELINE_DATA_EMBARGOED_DATE,
        payload: value
    }
}
export const setDifTimelineDataEmbargoedDesc = (value) => {
    return {
        type: SET_DIF_TIMELINE_DATA_EMBARGOED_DESC,
        payload: value
    }
}
export const setTimelineData = (value) => {
    return {
        type: SET_TIMELINE_FORM_DATA,
        payload: value
    }
}
export const resetTimelineData = () => {
    return {
        type: RESET_TIMELINE_FORM_DATA,
    }
}
import React from 'react'
import Classes from './index.module.css'
import UserOnboard from '../user-onboard/UserOnboard'
import useScrollToTop from '../../commons/scroll-top';

const EditProfile = props => {
    useScrollToTop();
    return (
        <div className={Classes.editProfileFormContainer}>
            <UserOnboard
                editProfile
                onSubmitEdit={props.onSubmitEdit} 
                contactNumber={props.contactNumber}/>
        </div>
    )
}

export default EditProfile
import { ADD_DIF_OTHER_PERSONAL_USERS, REMOVE_DIF_OTHER_PERSONAL_USERS, UPDATE_DIF_OP_USERS_FIRST_NAME
    , UPDATE_DIF_OP_USERS_LAST_NAME, UPDATE_DIF_OP_USERS_TITLE, UPDATE_DIF_OP_USERS_EMAIL, DIF_OP_WILL_OTHER_UPLOAD_DATA,
    SET_OTHER_PERSONAL_FORM_DATA, RESET_OTHER_PERSONAL_FORM_DATA
} from '../action-types'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get'
const initialState = {
    difOPAddedUsers: [{
        'first_name':'',
        'last_name': '',
        'title': '',
        'institutional_email': '' 
    }],
    difOPWillOtherUpload: ''

}

const difOtherPersonalReducers = (state = initialState, action) => {
    switch (action.type) {
        case ADD_DIF_OTHER_PERSONAL_USERS: {
            let newDifUsers =  cloneDeep(state.difOPAddedUsers);
            newDifUsers.push(action.payload);
            return {
                ...state,
                difOPAddedUsers: newDifUsers
            }
        }
        case REMOVE_DIF_OTHER_PERSONAL_USERS: {
            let newDifUsers =  cloneDeep(state.difOPAddedUsers);
            newDifUsers.splice(action.payload, 1);
            return {
                ...state,
                difOPAddedUsers: newDifUsers
            }
        }
        case UPDATE_DIF_OP_USERS_FIRST_NAME: {
            const { value, id } = action.payload;
            let newDifUsers = cloneDeep(state.difOPAddedUsers);
            newDifUsers[id] = {
                ...newDifUsers[id],
                'first_name': value
            }
            return {
                ...state,
                difOPAddedUsers: newDifUsers
            }
        }
        case UPDATE_DIF_OP_USERS_LAST_NAME: {
            const { value, id } = action.payload;
            let newDifUsers = cloneDeep(state.difOPAddedUsers);
            newDifUsers[id] = {
                ...newDifUsers[id],
                'last_name': value
            }
            return {
                ...state,
                difOPAddedUsers: newDifUsers
            }
        }
        case UPDATE_DIF_OP_USERS_TITLE: {
            const { value, id } = action.payload;
            let newDifUsers = cloneDeep(state.difOPAddedUsers);
            newDifUsers[id] = {
                ...newDifUsers[id],
                'title': value
            }
            return {
                ...state,
                difOPAddedUsers: newDifUsers
            }
        }
        case UPDATE_DIF_OP_USERS_EMAIL: {
            const { value, id } = action.payload;
            let newDifUsers = cloneDeep(state.difOPAddedUsers);
            newDifUsers[id] = {
                ...newDifUsers[id],
                'institutional_email': value
            }
            return {
                ...state,
                difOPAddedUsers: newDifUsers
            }
        }
        case DIF_OP_WILL_OTHER_UPLOAD_DATA: {
            return {
                ...state,
                difOPWillOtherUpload: action.payload
            }
        }
        case SET_OTHER_PERSONAL_FORM_DATA: {
            const users = get(action.payload, 'data_submission_supporters', []);
            const willOtherUpload = get(action.payload, 'will_someone_upload_data', '');
            const userData = !isEmpty(users) ? users : [{
                'first_name':'',
                'last_name': '',
                'title': '',
                'institutional_email': '' 
            }];
            return{
                ...state,
                difOPAddedUsers: userData,
                difOPWillOtherUpload: willOtherUpload
            }
        }
        case RESET_OTHER_PERSONAL_FORM_DATA: {
            return {
                difOPAddedUsers: [{
                    'first_name':'',
                    'last_name': '',
                    'title': '',
                    'institutional_email': '' 
                }],
                difOPWillOtherUpload: ''
            }
        }
        default: return state
    }
}

export default difOtherPersonalReducers;
import axios from 'axios'
import { getCognitoIdToken } from './utilityMethods';
import { BASE_URL } from '../constants/google-login-urls'
import Store from '../redux/store'

export const getSearchResults = async (body, pageNumber, pageSize = 10) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'text/tab-separated-values'
    };
    const response = await axios.post(`${BASE_URL}/data_access/pg/search?page_number=${pageNumber}&page_size=${pageSize}`, body,
        { headers: header });
    return response?.data?.data || null
}


export const saveSearch = async (body) => {
    try {
        const token = await getCognitoIdToken();
        const header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'text/tab-separated-values'
        };
        const response = await axios.post(`${BASE_URL}/data_access/pg/search/save`, body,
            { headers: header });
        return response?.data || null
    } catch (error) {
        throw error;
    }
}


export const getDemographicCount = async (data) => {
    const token = await getCognitoIdToken()
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/data_access/pg/search?page_number=1&page_size=1`
    const res = await axios.post(url, data, {headers: header });

    return res?.data || null
}

import React from 'react'
import Classes from './index.module.css';
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import { COUNTRY_LIST, DCA_FORM_COMPLETED_TEXT } from '../../../constants/strings';
import map from 'lodash/map'
import v4 from 'uuid/v4'
import Select from '@mui/material/Select';
import ErrorText from '../error-text';
import isEmpty from 'lodash/isEmpty';
import { validateEmailAddress } from '../../../utils/input-validations';
import DropDown from '../../commons/dropdown';
const ProjectAdministrator = (props) => {
    const {
        projectAdminFirstName,
        projectAdminLastName,
        projectAdminMiddleName,
        projectAdminCredentials,
        projectAdminInstituteName,
        projectAdminInstituteEmail,
        projectAdminInstituteStreetAddress,
        projectAdminInstituteCity,
        projectAdminInstituteState,
        projectAdminInstituteZipCode,
        projectAdminInstituteCountry,
        isFormSubmitted,
        currentDCAFormStage,

        setProjectAdminFirstName,
        setProjectAdminLastName,
        setProjectAdminMiddleName,
        setProjectAdminCredentials,
        setProjectAdminInstituteName,
        setProjectAdminInstituteEmail,
        setProjectAdminInstituteStreetAddress,
        setProjectAdminInstituteCity,
        setProjectAdminInstituteState,
        setProjectAdminInstituteZipCode,
        setProjectAdminInstituteCountry
    } = props;

    const onFirstNameChange = event => {
        setProjectAdminFirstName(event.target.value);
    }
    const onMiddleNameChange = event => {
        setProjectAdminMiddleName(event.target.value);
    }
    const onLastNameChange = event => {
        setProjectAdminLastName(event.target.value);
    }
    const onCredentialsChange = event => {
        setProjectAdminCredentials(event.target.value);
    }
    const onInstitutionNameChange = event => {
        setProjectAdminInstituteName(event.target.value);
    }
    const onInstitutionalEmailChange = event => {
        setProjectAdminInstituteEmail(event.target.value);
    }
    const onInstitutionStreetChange = event => {
        setProjectAdminInstituteStreetAddress(event.target.value);
    }
    const onInstitutionCityChange = event => {
        setProjectAdminInstituteCity(event.target.value);
    }
    const onInstitutionStateChange = event => {
        setProjectAdminInstituteState(event.target.value);
    }
    const onInstitutionZipChange = event => {
        setProjectAdminInstituteZipCode(event.target.value);
    }
    const onInstitutionCountryChange = value => {
        setProjectAdminInstituteCountry(value);
    }

    const disableTextInput =  (currentDCAFormStage === DCA_FORM_COMPLETED_TEXT);
    return (
        <div className={Classes.container}>
            <p className={Classes.formTitle}>Project Administrator</p>
            <Grid container className={Classes.inputContainers} spacing={3}>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label htmlFor='firstName'>First Name<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter first name"
                        value={projectAdminFirstName}
                        onChange={onFirstNameChange}
                        fullWidth
                        id='firstName'
                        disabled={disableTextInput}
                    />
                    {
                        isFormSubmitted && isEmpty(projectAdminFirstName) &&
                        <ErrorText errorMessage="Enter valid first name" />
                    }
                </Grid>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label htmlFor='middleName'>Middle Name</label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter middle name"
                        value={projectAdminMiddleName}
                        onChange={onMiddleNameChange}
                        fullWidth
                        id='middleName'
                        disabled={disableTextInput}
                    />
                    {/* {
                        isFormSubmitted && isEmpty(projectAdminMiddleName) &&
                        <ErrorText errorMessage="Enter valid middle name" />
                    } */}
                </Grid>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label htmlFor='lastName'>Last Name<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter last name"
                        value={projectAdminLastName}
                        onChange={onLastNameChange}
                        fullWidth
                        id='lastName'
                        disabled={disableTextInput}
                    />
                    {
                        isFormSubmitted && isEmpty(projectAdminLastName) &&
                        <ErrorText errorMessage="Enter valid last name" />
                    }
                </Grid>
            </Grid>
            <Grid container className={Classes.inputContainers} spacing={3}>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label htmlFor='credentials'>Credentials<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter credentials"
                        value={projectAdminCredentials}
                        onChange={onCredentialsChange}
                        fullWidth
                        id='credentials'
                        disabled={disableTextInput}
                    />
                    {
                        isFormSubmitted && isEmpty(projectAdminCredentials) &&
                        <ErrorText errorMessage="Enter valid credentials" />
                    }
                </Grid>
            </Grid>
            <Grid container className={Classes.inputContainers} spacing={3}>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label htmlFor='instituteName'>Institute Name<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter institute name"
                        value={projectAdminInstituteName}
                        onChange={onInstitutionNameChange}
                        fullWidth
                        id='instituteName'
                        disabled={disableTextInput}
                    />
                    {
                        isFormSubmitted && isEmpty(projectAdminInstituteName) &&
                        <ErrorText errorMessage="Enter valid institute name" />
                    }
                </Grid>
                <Grid item xs={4} className={Classes.fieldCont}>
                    <label htmlFor='instituteEmail'>Institutional Email<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter email"
                        value={projectAdminInstituteEmail}
                        onChange={onInstitutionalEmailChange}
                        fullWidth
                        id='instituteEmail'
                        disabled={disableTextInput}
                    />
                    {
                        isFormSubmitted && !validateEmailAddress(projectAdminInstituteEmail) &&
                        <ErrorText errorMessage="Enter valid email" />
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div className={Classes.dottedLine}></div>
            </Grid>
            <p className={Classes.formTitle}>Institution Address<sup> *</sup></p>
            <Grid container className={Classes.inputContainers} spacing={3} direction='row'>
                <Grid item xs={5}>
                    <label htmlFor='country'>Country</label>
                    <DropDown
                        id='instituteCountry'
                        values={COUNTRY_LIST}
                        handleFilterStateChange={onInstitutionCountryChange}
                        selectedFilter={projectAdminInstituteCountry}
                        placeholder={'Select Country'}
                        disable={disableTextInput}
                        searchable
                        isClearable
                    />
                    {
                        isFormSubmitted && isEmpty(projectAdminInstituteCountry) &&
                        <ErrorText errorMessage="Enter select country" />
                    }
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='instituteStreetAddress'>Street Address<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter street address"
                        value={projectAdminInstituteStreetAddress}
                        onChange={onInstitutionStreetChange}
                        fullWidth
                        id='instituteStreetAddress'
                        disabled={disableTextInput}
                    />
                    {
                        isFormSubmitted && isEmpty(projectAdminInstituteStreetAddress) &&
                        <ErrorText errorMessage="Enter valid street address" />
                    }
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='instituteCity'>City<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter city"
                        value={projectAdminInstituteCity}
                        onChange={onInstitutionCityChange}
                        fullWidth
                        id='instituteCity'
                        disabled={disableTextInput}
                    />
                    {
                        isFormSubmitted && isEmpty(projectAdminInstituteCity) &&
                        <ErrorText errorMessage="Enter valid city" />
                    }
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='instituteState'>State<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter state"
                        value={projectAdminInstituteState}
                        onChange={onInstitutionStateChange}
                        fullWidth
                        id='instituteState'
                        disabled={disableTextInput}
                    />
                    {
                        isFormSubmitted && isEmpty(projectAdminInstituteState) &&
                        <ErrorText errorMessage="Enter valid state" />
                    }
                </Grid>
                <Grid item xs={5} className={Classes.fieldCont}>
                    <label htmlFor='instituteZipCode'>Zip Code<sup> *</sup></label>
                    <OutlinedInput
                        type="text"
                        placeholder="Enter zip code"
                        value={projectAdminInstituteZipCode}
                        onChange={onInstitutionZipChange}
                        fullWidth
                        id='instituteZipCode'
                        disabled={disableTextInput}
                    />
                    {
                        isFormSubmitted && isEmpty(projectAdminInstituteZipCode) &&
                        <ErrorText errorMessage="Enter valid zip code" />
                    }
                </Grid>
            </Grid>
        </div>
    )
}

ProjectAdministrator.defaultProps = {
    projectAdminFirstName: '',
    projectAdminLastName: '',
    projectAdminMiddleName: '',
    projectAdminCredentials: '',
    projectAdminInstituteName: '',
    projectAdminInstituteEmail: '',
    projectAdminInstituteStreetAddress: '',
    projectAdminInstituteCity: '',
    projectAdminInstituteState: '',
    projectAdminInstituteZipCode: '',
    projectAdminInstituteCountry: '',
    isFormSubmitted: false,
    currentDCAFormStage: '',

    setProjectAdminFirstName: () => { },
    setProjectAdminLastName: () => { },
    setProjectAdminMiddleName: () => { },
    setProjectAdminCredentials: () => { },
    setProjectAdminInstituteName: () => { },
    setProjectAdminInstituteEmail: () => { },
    setProjectAdminInstituteStreetAddress: () => { },
    setProjectAdminInstituteCity: () => { },
    setProjectAdminInstituteState: () => { },
    setProjectAdminInstituteZipCode: () => { },
    setProjectAdminInstituteCountry: () => { },
}
export default ProjectAdministrator;
/**
 *  External Imports
 */
import Grid from "@mui/material/Grid";
import React, {useState} from "react";
import {arrayOf, func, number, shape, string} from "prop-types";

/**
 *  Internal Imports
 */
import useScrollToTop from "../../commons/scroll-top";
import styles from "./index.module.css";
import CommunityWidgetBody from "../../controls/community-widget-body";
import {useConnectionsUsersList, useUserListFilter} from "../../../utils/hooks";
import {communityMappedAPI} from "../../../api/community-api";
import {WIDGET_TYPES} from "../../../constants/widget-types";
import CommunitySuccessModal from "../../controls/community-success-modal";

const CommunityConnectionsIncoming = (props) => {
    const {incomingUsers} = props;
	const {initUsers, loadMoreUsers} = props;

    const [searchTerm, setSearchTerm] = useState("");

	const {isFullyLoaded, isLoaded, loadMoreData, setSortBy, isSortLoaded} = 
		useConnectionsUsersList(initUsers, loadMoreUsers, communityMappedAPI.getMappedIncomingConnections);
	const {filteredUserList} = useUserListFilter(searchTerm, incomingUsers);
	// Functions
	
	const onSearchChange = (value) => setSearchTerm(value);
    // Functions Call
    useScrollToTop();
    // Return
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            className={styles.body}>
            <CommunityWidgetBody
                data-testid="widgetBody"
                usersList={filteredUserList}
                isLoaded={isLoaded}
                isFullyLoaded={isFullyLoaded}
                isSortLoaded={isSortLoaded}
                title={"Incoming Requests"}
                inputPlaceholder={"Search Incoming Requests"}
                setSortBy={setSortBy}
                onSearchChange={onSearchChange}
                loadMoreData={loadMoreData}
                widgetType={WIDGET_TYPES.INCOMING}
            />
            <CommunitySuccessModal />
        </Grid>
    );
};

CommunityConnectionsIncoming.propTypes = {
	incomingUsers: arrayOf(
		shape(
			{
				id: number.isRequired,
				recipientID: number.isRequired,
				userIcon: string.isRequired,
				name: string.isRequired,
				organization: string.isRequired,
				email: string.isRequired,
				text: string.isRequired,
			}
		)
	).isRequired,
	
	initUsers: func.isRequired,
	loadMoreUsers: func.isRequired,
};

/**
 *  Exports
 */
export default CommunityConnectionsIncoming;

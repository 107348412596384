/**
 *  External Imports
 */
 import {connect} from "react-redux";

/**
 *  Imports
 */
import CommunityConnectionsAccepted from "./CommunityConnectionsAccepted";
import {initConnectionUsersAction, loadMoreConnectedUsersAction} from "../../../redux/actions/communityActions";

const mapStateToProps = ({community: {connectionUsers}}) => ({
	connectionUsers,
});

const mapDispatchToProps = (dispatch) => ({
	initUsers: (connectionUsers) => dispatch(initConnectionUsersAction(connectionUsers)),
	loadMoreUsers: (loadedUsers) => dispatch(loadMoreConnectedUsersAction(loadedUsers)),
});

/**
 *  Exports
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CommunityConnectionsAccepted);

/**
 *  External Imports
 */
import { connect } from "react-redux";

/**
 *  Internal Imports
 */
import {
    closeCommunityConnectModalAction,
    resetCommunityConnectModalAction,
} from "../../../redux/actions/communityActions";

import CommunityConnectModal from "./CommunityConnectModal";

/**
 *  Functions
 */
const mapStateToProps = ({ community: { showCommunityConnectModal, dialogConnectData } }) => ({
    showCommunityConnectModal,
    ...dialogConnectData,
});

const mapDispatchToProps = (dispatch) => ({
    onDialogClose: () => dispatch(closeCommunityConnectModalAction()),
    onDialogReset: () => dispatch(resetCommunityConnectModalAction()),
});

/**
 *  Exports
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityConnectModal);

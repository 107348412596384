import { SET_PPI_FIRST_NAME, SET_PPI_LAST_NAME, SET_PPI_MIDDLE_NAME, SET_PPI_CREDENTIALS, 
    SET_PPI_INSTITUTE_NAME, SET_PPI_INSTITUTE_EMAIL, SET_PPI_INSTITUTE_STREET_ADDRESS,
    SET_PPI_INSTITUTE_CITY, SET_PPI_INSTITUTE_STATE, SET_PPI_INSTITUTE_ZIP_CODE, SET_PPI_INSTITUTE_COUNTRY 
    , SET_PROJECT_INSTITUTIONAL_FORM_FIELDS, RESET_PROJECT_INSTITUTIONAL_FORM_FIELDS
} from '../action-types'
export const setPPIFirstName = (value) => {
    return {
        type: SET_PPI_FIRST_NAME,
        payload: value
    }
}
export const setPPILastName = (value) => {
    return {
        type: SET_PPI_LAST_NAME,
        payload: value
    }
}
export const setPPIMiddleName = (value) => {
    return {
        type: SET_PPI_MIDDLE_NAME,
        payload: value
    }
}
export const setPPICredentials = (value) => {
    return {
        type: SET_PPI_CREDENTIALS,
        payload: value
    }
}
export const setPPIInstituteName = (value) => {
    return {
        type: SET_PPI_INSTITUTE_NAME,
        payload: value
    }
}
export const setPPIInstituteEmail = (value) => {
    return {
        type: SET_PPI_INSTITUTE_EMAIL,
        payload: value
    }
}
export const setPPIInstituteStreetAddress = (value) => {
    return {
        type: SET_PPI_INSTITUTE_STREET_ADDRESS,
        payload: value
    }
}
export const setPPIInstituteCity = (value) => {
    return {
        type: SET_PPI_INSTITUTE_CITY,
        payload: value
    }
}
export const setPPIInstituteState = (value) => {
    return {
        type: SET_PPI_INSTITUTE_STATE,
        payload: value
    }
}
export const setPPIInstituteZipCode = (value) => {
    return {
        type: SET_PPI_INSTITUTE_ZIP_CODE,
        payload: value
    }
}
export const setPPIInstituteCountry = (value) => {
    return {
        type: SET_PPI_INSTITUTE_COUNTRY,
        payload: value
    }
}
export const setProjectInstitutionalFields = (value) => {
    return {
        type: SET_PROJECT_INSTITUTIONAL_FORM_FIELDS,
        payload: value
    }
}
export const resetProjectInstitutionForm = () => {
    return {
        type: RESET_PROJECT_INSTITUTIONAL_FORM_FIELDS,
    }
}
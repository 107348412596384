/**
 *  External Imports
 */
import React, { useState, useEffect } from "react";
import { number } from "prop-types";
import { scalePower } from "@visx/scale";
import { Group } from "@visx/group";
import { RadialGradient } from "@vx/gradient";

/**
 *  Internal Imports
 */

import styles from "./index.module.css";

const RADIAL_DATA = [
    { value: 20 },
    { value: 40 },
    { value: 60 },
    { value: 80 },
    { value: 100 },
];

const BASIC_CIRCLE_STEP = 20;

const CommunityConstellationGraphBase = (props) => {
    const { parentWidth, parentHeight, parentTop, parentLeft } = props;
    const [isReadyToDraw, setIsReadyToDraw] = useState(false);

    const drawRadialBackground = () => {
        const radialScaleParams = scalePower({
            domain: [0, Math.max(...RADIAL_DATA.map((circle) => circle.value))],
            range: isReadyToDraw
                ? [Math.min(parentWidth / 2, parentHeight / 2), 0]
                : [100, 0],
        });
        return radialScaleParams.ticks(RADIAL_DATA.length).map((tick, i) => {
            const y = radialScaleParams(tick);
            const opacity = (tick + BASIC_CIRCLE_STEP) / 100;
            return (
                <g key={`radial-grid-${i}`} data-testid="graphBaseItem">
                    <circle
                        r={y}
                        fill="url('#gradient')"
                        fillOpacity={opacity}
                    />
                </g>
            );
        });
    };

    useEffect(() => {
        setIsReadyToDraw(parentWidth && parentHeight);
    }, [parentWidth, parentHeight]);

    return (
        <svg width={parentWidth} height={parentHeight}>
            {isReadyToDraw && (
                <g>
                    <Group top={parentHeight / 2} left={parentWidth / 2} data-testid="graphBase">
                        <RadialGradient
                            from="#FFFFFF"
                            to="#E7EAF2"
                            id="gradient"
                        />
                        {drawRadialBackground()}
                    </Group>
                    {props.children}
                </g>
            )}
        </svg>
    );
};

CommunityConstellationGraphBase.propTypes = {
    parentWidth: number.isRequired,
    parentHeight: number.isRequired,
    parentTop: number.isRequired,
    parentLeft: number.isRequired,
};

export default CommunityConstellationGraphBase;

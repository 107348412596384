/**
 *  External Imports
 */
 import {connect} from "react-redux";

/**
 *  Imports
 */
import CommunityConnectionsIncoming from "./CommunityConnectionsIncoming";
import {
	initIncomingUsersAction,
	loadMoreIncomingUsersAction,
} from "../../../redux/actions/communityActions";

const mapStateToProps = ({community: {incomingUsers}}) => ({
	incomingUsers,
});

const mapDispatchToProps = (dispatch) => ({
	initUsers: (incomingUsers) => dispatch(initIncomingUsersAction(incomingUsers)),
	loadMoreUsers: (loadedUsers) => dispatch(loadMoreIncomingUsersAction(loadedUsers)),
});



/**
 *  Exports
 */

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CommunityConnectionsIncoming);

import React from 'react';
import Classes from './index.module.css'
import Grid from '@mui/material/Grid'

const ToolsCard = props => {
    const { cardData = {} } = props;
    const onCardClick = () => {
        cardData.onClick();
    }
    return (
        <div className={Classes.toolsCard} onClick={onCardClick}>
            <div id='cvb-tools-cardContainer-button' className={Classes.toolsCardInfoContainer}>
                <img id='cvb-tools-icon' src={cardData.icon} className={Classes.settingsIcon} alt='tools icon' />
            </div>
            <p id='cvb-tools-title' className={Classes.toolsCardTitle}>{cardData?.cardName}</p>
        </div>
    )
}

export default ToolsCard;
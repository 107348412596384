/**
 *  External Imports
 */
import Grid from "@mui/material/Grid";

import {navigate} from "@reach/router";

import {number} from "prop-types";

import React, {useEffect, useState} from "react";

/**
 *  Internal Imports
 */
import {MY_PROFILE_URLS} from "../../../../constants/urls";
import {getCurrProgressColor} from "../../../../utils/styles-helpers";

import CustomProgress from "../../../commons/custom-progress";

import styles from "./index.module.css";

/**
 *  Component
 *
 *  @param props
 *
 *  @return {JSX.Element}
 */
const WidgetCompleteProfile = (props) => {
	// Variables
	const {profileProgress} = props;
	
	const [profileProgressVal, setProfileProgressVal] = useState(0);
	const [profileProgressColor, setProfileProgressColor] = useState("");
	
	// Functions
	const initData = () => {
		// Variables
		const currProfileProgressColor = getCurrProgressColor(profileProgress);
		
		// Setting the data
		setProfileProgressVal(profileProgress);
		setProfileProgressColor(currProfileProgressColor);
	};
	
	const onAnswerNowBtnClick = () => navigate(MY_PROFILE_URLS.TELL_US_MORE_WHO_ARE_YOU);
	
	// Life Cycles
	useEffect(() => {
		initData();
	}, [profileProgress]);
	
	// Return
	return (
        <div className={styles.widgetCompleteProfile}>
			
			<Grid container
			
			      className={styles.title}>
				
				Complete your profile
			</Grid>
			
			<Grid container
			
			      direction="column"
			      justifyContent="center"
			      alignItems="center"
			
			      className={styles.infoArea}>
				
				<CustomProgress data-testid="customProgress" className={styles.infoChart}
				
				                trailStrokeColor={"#e7eaf2"}
				                strokeColor={profileProgressColor}
				
				                percentage={profileProgressVal}
				
				                innerText="Answered"
				/>
				
				<div className={styles.infoMsg}>
					Answer the following questions so we can connect you to other users.
				</div>
			</Grid>
			
			<Grid container
			
			      direction="column"
			      justifyContent="center"
			      alignItems="center"
			
			      className={styles.buttonsArea}>
				
				<button data-testid="navigateBtn" className={styles.answerNowBtn}
				
				        onClick={onAnswerNowBtnClick}>
					
					Answer now
				</button>
			</Grid>
		</div>
    );
};

/**
 *  Properties
 */
WidgetCompleteProfile.propTypes = {
	profileProgress: number.isRequired,
};

/**
 *  Exports
 */
export default WidgetCompleteProfile;

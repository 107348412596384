import React from 'react';
import Box from '@mui/material/Box';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { vars } from '../../../../assets/variables';

const { primaryTextColor, outlinedInputBorderColor, white } = vars;

const useStyles = makeStyles(() => ({
  bullet: {
    width: '1.5rem',
    height: '1.5rem',
    background: white,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    marginRight: '0.5rem',
    position: 'relative',
    border: `0.0625rem solid ${outlinedInputBorderColor}`,
    justifyContent: 'center',
    alignSelf: 'center',
  },

  dot: {
    width: '0.25rem',
    borderRadius: '50%',
    background: primaryTextColor,
    height: '0.25rem',
  },

  wrap: {
    width: '5rem',
    marginRight: '0.5rem',

    '& p': {
      justifyContent: 'center',
    },
  },
}));

function CriteriaKeyOption(props) {
  const classes = useStyles();
  const { criteriaKey, setCriteriaKey, index, bullet = true } = props;

  return (
    <Box display="flex" alignItems="center" minWidth="8rem">
      {bullet && (
        <Box className={classes.bullet}>
          <Box className={classes.dot} />
        </Box>
      )}

      <Box className={classes.wrap}>
        {index === 1 ? (
          <FormControl fullWidth>
            <Select
              labelId="label"
              value={criteriaKey.toLowerCase()}
              onChange={(e) => setCriteriaKey(e.target.value)}
            >
              <MenuItem value={'and'}>And</MenuItem>
              <MenuItem value={'or'}>Or</MenuItem>
            </Select>
          </FormControl>
        ) : index === 0 ? (
          <Typography>Where</Typography>
        ) : (
          <Typography textTransform="capitalize">
            {criteriaKey.toLowerCase()}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default CriteriaKeyOption;

import ProjectPI from './ProjectPIForm'
import { connect } from 'react-redux'
import { setProjectPIFirstName, setProjectPILastName, setProjectPIMiddleName, setProjectPICredentials, setProjectPIAffiliation,
    setProjectPIInstituteEmail
} from '../../../redux/actions/projectPIActions'
const mapStateToProps = state => {
    return { 
        projectPIFirstName: state.projectPI.projectPIFirstName,
        projectPILastName: state.projectPI.projectPILastName,
        projectPIMiddleName: state.projectPI.projectPIMiddleName,
        projectPICredentials: state.projectPI.projectPICredentials,
        projectPIAffiliation: state.projectPI.projectPIAffiliation,
        projectPIInstituteEmail: state.projectPI.projectPIInstituteEmail,
        currentDCAFormStage: state.dca.currentDCAFormStage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setProjectPIFirstName: async (value) => await dispatch(setProjectPIFirstName(value)),
        setProjectPILastName: async (value) => await dispatch(setProjectPILastName(value)),
        setProjectPIMiddleName: async (value) => await dispatch(setProjectPIMiddleName(value)),
        setProjectPICredentials: async (value) => await dispatch(setProjectPICredentials(value)),
        setProjectPIAffiliation: async (value) => await dispatch(setProjectPIAffiliation(value)),
        setProjectPIInstituteEmail: async (value) => await dispatch(setProjectPIInstituteEmail(value)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectPI)
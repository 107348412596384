import React, { useEffect, useState, useMemo } from 'react'
import Classes from './index.module.css';
import isEmpty from 'lodash/isEmpty'
import Grid from '@mui/material/Grid'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '../../commons/table-pagination';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip'
import Paper from '@mui/material/Paper';
import { getAllStudyDownloadRequest, getStudyDownloadPresignedURL, getIndividualStudyProjectData, updateStudyProjectDownloadRequest } from '../../../api/study-api'
import lowerCase from 'lodash/lowerCase'
import moment from 'moment'
import sortBy from 'lodash/sortBy'
import Modal from '../../commons/modal';
import cloneDeep from 'lodash/cloneDeep'
import LoaderImg from '../../../assets/images/loader.gif'
import { navigate } from '@reach/router';
import ErrorText from '../../controls/error-text'

const SavedStudyProjectDownloads = props => {

    const [savedStudyDownloadRequest, setSavedStudyDownloadRequest] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openStudyEditModal, setOpenStudyEditModal] = useState(false);
    const [editStudyData, setEditStudyData] = useState({});
    const [editStudyLoader, setEditStudyLoader] = useState(false);
    const [isFormUpdating, setIsFormUpdating] = useState(false);
    const [isRequestsLoading, setIsRequestsLoading] = useState(false);
    const [submitDownloadForm, setSubmitDownloadForm] = useState(false);
    useEffect(() => {
        getStudyDownloadRequestsData();
    }, [])


    const getStudyDownloadRequestsData = async () => {
        try {
            setIsRequestsLoading(true);
            const res = await getAllStudyDownloadRequest();
            setIsRequestsLoading(false);
            if (res?.length) {
                let sortedRes = sortBy(res, (data)=> new Date(data.created_at)).reverse();
                setSavedStudyDownloadRequest(sortedRes);
            }

        } catch (error) {
            console.log('error in loading saved search');
            setIsRequestsLoading(false);
        }
    }

    const maxCount = savedStudyDownloadRequest?.length ? Math.ceil(savedStudyDownloadRequest.length / 5) : 0;

    let count = 1;
    const downloadURL = (url) => {
        let hiddenIFrameID = 'hiddenDownloader' + count++;
        let iframe = document.createElement('iframe');
        iframe.id = hiddenIFrameID;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.src = url;
    }

    const onDownloadClick = async (data) => {
        try{
            navigate('/project-file-download-request/' + data?.project_id+'/'+data?.request_id,{state:{selectedProject:data}})
            // const res = await getStudyDownloadPresignedURL(data?.project_id, data?.structured_data_export_format);
            // downloadURL(res);
            // if(data?.include_unstructured_files === 'true'){
            //     const unstrcuRes = await getStudyDownloadPresignedURL(data?.project_id, 'unstructured');
            //     downloadURL(unstrcuRes);
            // }
        }catch(error){
            props.updateSnackBar(`Failed to Download Project`, "Error");
            console.log('error');
        }
    }

    const setTablePage = (number) => {
        setPage(number);
    }
    const onEditClick = async (data) => {
        try{
            setEditStudyLoader(true);
            setOpenStudyEditModal(true);
            const res = await getIndividualStudyProjectData(data?.project_id);
            setEditStudyData({...data, ...res});
            setEditStudyLoader(false);
        }catch(error){
            props.updateSnackBar(error?.message || `Failed to get project download request`, "error");
            console.log('error in getting single project data', error)
        }
      
    }
    const onSaveProjectConfirm = async () =>{
        try{
            setSubmitDownloadForm(true);
            if(editStudyData?.description?.length > 512){
                return
            }
            setIsFormUpdating(true);
            const includeStructuredFiles = editStudyData?.include_unstructured_files === null ? 'false': editStudyData?.include_unstructured_files;
            const body = {
                "structured_data_export_format" : editStudyData?.structured_data_export_format,
                "include_unstructured_files": includeStructuredFiles,
                "description": editStudyData?.description
            }
            const res = await updateStudyProjectDownloadRequest(editStudyData?.request_id, body);
            if(res){
                props.updateSnackBar(res?.message || `Project download request updated successfully`, "success");
                setOpenStudyEditModal(false);
                getStudyDownloadRequestsData();
                setIsFormUpdating(false);
            }
        }catch(error){
            setIsFormUpdating(false);
            props.updateSnackBar(error?.message || `Failed to update project download request`, "error");
            console.log('error in updating download request', error)
        }
    }
    const onCloseEditStudyModal = ()=>{
        setOpenStudyEditModal(false);
    }
    const onStructuredDataFormatChange = (e) => {
        let editData =  cloneDeep(editStudyData); 
        editData = {
            ...editData,
            structured_data_export_format: e.target.value
        }
        setEditStudyData(editData);
    }
    const onIncludeUnstructuredFilesChange = (e) =>{
        let editData =  cloneDeep(editStudyData); 
        editData = {
            ...editData,
            include_unstructured_files: e.target.value
        }
        setEditStudyData(editData);
    }
    const onDownloadProjectDescriptionChange = (e) =>{
        let editData =  cloneDeep(editStudyData); 
        editData = {
            ...editData,
            description: e.target.value
        }
        setEditStudyData(editData);
    }
    const editStudyProps = {
        modalTitle: 'Edit Download Project',
        positiveButtonText: 'Save',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSaveProjectConfirm,
        negativeButtonAction: onCloseEditStudyModal,
        popupButtonDisable: isFormUpdating
    }
    return (
        <>
        <Modal
         open={openStudyEditModal}
         handleClose={onCloseEditStudyModal}
         dialogProps={editStudyProps}
         disableBottomHorizontalDivider
        >
                {(!editStudyLoader && !isEmpty(editStudyData)) ?
                <Grid container spacing={2}>
                <Grid item xs={12} >
                    <label id="cvb-myStuff-studyDownload-structuredExportLabel">Structure Data Export Format<sup> *</sup></label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-myStuff-studyDownload-jsonExportLabel">JSON</label>
                            <input id='structureDataExport1' name='structureDataExport' type="radio"
                                checked={editStudyData?.structured_data_export_format === 'json'} value='json'
                                disabled={!editStudyData?.structuredDataDownloadFormatAvailable?.includes('json')}
                                onChange={onStructuredDataFormatChange} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-myStuff-studyDownload-tsvExportLabel">TSV</label>
                            <input id='structureDataExport2' name='structureDataExport' type="radio"
                                checked={editStudyData?.structured_data_export_format === 'tsv'} value='tsv'
                                disabled={!editStudyData?.structuredDataDownloadFormatAvailable?.includes('tsv')}
                                onChange={onStructuredDataFormatChange} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} >
                    <label id="cvb-myStuff-studyDownload-includeStructuredLabel">Include Unstructured Files<sup> *</sup></label>
                    <Grid container>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-myStuff-studyDownload-includeYesLabel">Yes</label>
                            <input id='includeFiles1' name='includeFiles' type="radio"
                                checked={editStudyData?.include_unstructured_files === 'true'} value='true'
                                disabled={!editStudyData?.unstructuredDataDownloadAvailable}
                                onChange={onIncludeUnstructuredFilesChange} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                        <Grid item xs={2} className={Classes.radioCheckContainer}>
                            <label className={Classes.radioContainer} id="cvb-myStuff-studyDownload-includeNoLabel">No</label>
                            <input id='includeFiles2' name='includeFiles' type="radio"
                                checked={editStudyData?.include_unstructured_files === 'false'} value='false'
                                disabled={!editStudyData?.unstructuredDataDownloadAvailable}
                                onChange={onIncludeUnstructuredFilesChange} />
                            <span className={Classes.checkmark}></span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <label htmlFor='abstract' id="cvb-myStuff-studyDownload-descriptionLabel">Abstract/Description</label>
                    <textarea
                        value={editStudyData?.description}
                        onChange={onDownloadProjectDescriptionChange}
                        className={`${Classes.textAreaClass} ${(submitDownloadForm && editStudyData?.description?.length > 512) ? Classes.textAreaError : ''}`}
                        placeholder='Enter description'
                        rows={4}
                        id='abstract'
                        name='Description'
                    ></textarea>
                    {
                        <span className={`${editStudyData?.description?.length > 512 ? Classes.maxWords : ''}`} >{editStudyData?.description?.length}/512 characters</span>
                    }
                </Grid>
                {
                    submitDownloadForm && editStudyData?.description?.length > 512 &&
                    <ErrorText errorMessage="Abstract/Description cannot be greater than 512 characters" />
                }
                </Grid> : 
                    <div id="cvb-myStuff-studyDownload-inputChips" className={` ${Classes.overlayLoader}`}>
                        <img src={LoaderImg} alt="loader" />
                    </div>
                }
        </Modal>
            <div className={Classes.container}>
                <div className={Classes.myUploadHeaderContainer} id='cvb-myStuff-studyDownload-blockContainer'>
                    <p className={Classes.myStuffSectionHeaders} id='cvb-myStuff-studyDownload-headingText'>My Project Download Requests</p>
                </div>
                { !isEmpty(savedStudyDownloadRequest) ? <Grid container lg={9} item xs={12} md={12} spacing={4} className={Classes.authorizationContainer} >
                    <div className={Classes.savedSearchContainer} id='cvb-myStuff-studyDownload-tableContainer'>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell id='cvb-myStuff-studyDownload-tableHeaderSearchName' className={Classes.typeColumn} width="20%">Project ID</TableCell>
                                        <TableCell id='cvb-myStuff-studyDownload-tableHeaderDataCategory' className={Classes.searchColumn} width="15%" align="left">Status</TableCell>
                                        <TableCell id='cvb-myStuff-studyDownload-tableHeaderSavedOn' className={Classes.searchColumn} width="12%" align="left">Requested Date</TableCell>
                                        <TableCell id='cvb-myStuff-studyDownload-tableHeaderSavedOn' className={Classes.searchColumn} width="10%" align="left">Export Format</TableCell>
                                        <TableCell id='cvb-myStuff-studyDownload-tableHeaderDataCategory' className={Classes.searchColumn} width="9%" align="left">Unstructured Files</TableCell>
                                        <TableCell id='cvb-myStuff-studyDownload-tableHeaderDataCategory' className={Classes.searchColumn} width="14%" align="left">Abstract/Description</TableCell>
                                        <TableCell id='cvb-myStuff-studyDownload-tableHeaderDataCategory' className={Classes.searchColumn} width="20%" align="left">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {savedStudyDownloadRequest.map((row) =>{ 
                                        const statusStyle = lowerCase(row?.status) === 'available' ? Classes.availableStatus : lowerCase(row?.status) === 'delivered' ? Classes.deliveredBoxText :  Classes.expiredStatus;
                                        const showDownload = lowerCase(row?.status) === 'available' ? true : false;
                                        const statusLabel = `${row?.status}`.replaceAll('_',' ');
                                        return (
                                        <TableRow key={row?.id}>
                                            <TableCell id='cvb-myStuff-studyDownload-tableCellSearchName' className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
                                                <Tooltip placement="bottom-start" className={Classes.userNameTitle} title={row?.project_id || ''}>
                                                    <span id='cvb-myStuff-studyDownload-projectID' className={Classes.userNameTitle}>{row?.project_id}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell id='cvb-myStuff-studyDownload-tableCellNodeName' className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
                                                <Tooltip placement="bottom-start" title={statusLabel}>
                                                    <span id='cvb-myStuff-studyDownload-status' className={statusStyle}>{statusLabel}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell id='cvb-myStuff-studyDownload-tableCellDate' className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
                                                <Tooltip placement="bottom-start" title={row?.created_at || ''}>
                                                <span id='cvb-myStuff-studyDownload-createdAt' className={Classes.userNameTitle}>{row?.created_at ? (moment.utc(row?.created_at)?.local().format('MM/DD/YYYY') + ' at ' + moment.utc(row?.created_at)?.local().format('hh:mm A')) : ''}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell id='cvb-myStuff-studyDownload-tableCellDate' className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
                                                <Tooltip placement="bottom-start" title={row?.structured_data_export_format || ''}>
                                                <span id='cvb-myStuff-studyDownload-exportFormat' className={Classes.userNameTitle}>{row?.structured_data_export_format}</span>
                                                </Tooltip>
                                            </TableCell>

                                            <TableCell id='cvb-myStuff-studyDownload-tableCellNodeName' className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
                                                <Tooltip placement="bottom-start" title={row?.include_unstructured_files || ''}>
                                                    <span id='cvb-myStuff-studyDownload-includeUnstructuredFiles'>{(row?.include_unstructured_files) == null ? 'NA' : 
                                                    (row?.include_unstructured_files === 'true') ? 'Yes' : 'No'}
                                                    </span>
                                                </Tooltip>
                                            </TableCell>

                                            <TableCell id='cvb-myStuff-studyDownload-tableCellNodeName' className={[Classes.organizationCell, Classes.userTableCell, Classes.projectDownloadDescription].join(" ")} component="th" scope="row">
                                                <Tooltip placement="bottom-start" title={row?.description || ''}>
                                                    <span id='cvb-myStuff-studyDownload-description'>{row?.description}</span>
                                                </Tooltip>
                                            </TableCell>
                                    
                                            <TableCell id='cvb-myStuff-studyDownload-tableCellNodeName' className={[Classes.organizationCell, Classes.userTableCell].join(" ")} component="th" scope="row">
                                                    {showDownload ?
                                                        <div className={Classes.downloadButtonWrapper}>
                                                        <button id='cvb-myStuff-studyDownload-editStudyDownload' className={Classes.downloadButton} onClick={() => onEditClick(row)}>Edit</button>
                                                        <button id='cvb-myStuff-studyDownload-downloadStudy' className={Classes.downloadButton} onClick={() => onDownloadClick(row)}>Download</button>
                                                        </div>
                                                        : null}
                                            </TableCell> 
                                        </TableRow>
                                    )}).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}

                                </TableBody>
                            </Table>
                        </TableContainer>


                        {
                            maxCount > 0 &&
                            <div className={Classes.tablePagination} id='cvb-myStuff-studyDownload-tablePagination'>
                                <TablePagination
                                    maxCount={maxCount}
                                    page={page}
                                    setPage={setTablePage}
                                />
                            </div>
                        }
                    </div>
                </Grid> 
                    : isRequestsLoading ?
                        <div id="cvb-myStuff-studyDownload-inputChips" className={` ${Classes.overlayLoader}`}>
                            <img src={LoaderImg} alt="loader" />
                        </div>
                        :
                        <div id="cvb-myStuff-studyDownload-noDownloadRequest">You have no project download requests.</div>
            }
            </div>
        </>
    )
}

export default SavedStudyProjectDownloads;
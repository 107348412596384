import React from 'react';
import ExploreCohorts from './ExploreCohorts';
import { connect } from 'react-redux';
import {
  updateExcludeFromExploration,
  updateExploreDataFiltersCount,
  resetGraph,
  getExploreDataHierarchyFilters,
  getSunBurstChartData,
  getAgeDistributionData,
} from '../../../redux/actions/exploreDataActions';
import { updateSnackBar } from '../../../redux/actions/globalActions';
import {
  saveCohortToDBApi,
} from '../../../api/analysis-api';
import { CohortsTable as _CohortsTable } from './CohortsTable';
import { CohortInfo as _CohortInfo } from './CohortInfo';
import { CohortInfoStudies as _CohortInfoStudies } from './tabs/CohortInfoStudies';
import { updateSelectedProjects } from '../../../redux/actions/studyGalleryActions';

const mapStateToProps = (state) => {
  return {
    allWorkspaces: state.workspace.allWorkspaces,
    userDetails: state.user.userDetails,
    selectedProjects: state.studyGallery.selectedProjects,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSnackBar: async (message, type) =>
    dispatch(updateSnackBar(message, type)),
  updateSelectedProjectsinGallery: async (projects) =>
    await dispatch(updateSelectedProjects(projects)),
});

// TODO aggregations will become part of the user configuration and so redux state
const defaultAggregations = {
  cohortName: 'Cohort name',
  studyName: 'Study name',
  subjects: 'Subjects',
  studyDesign: 'Study design',
  scannerManufacturer: 'Scanner manufacturer',
  parkinsonsDisease: "Parkinson's disease",
  controls: 'Controls',
  imagingModality: 'Imaging Modality',
  MMSE: 'MMSE',
  deepBrainStimulation: 'Deep brain stimulation',
  ethnicity: 'Ethnicity',
};

export const CohortsTable = (props) => (
  <_CohortsTable {...props} columns={defaultAggregations} />
);
export const CohortInfo = (props) => <_CohortInfo {...props} />;
export const CohortInfoStudies = (props) => <_CohortInfoStudies {...props} />;

export default connect(mapStateToProps, mapDispatchToProps)(ExploreCohorts);

import isEmpty from "lodash/isEmpty"
import { Auth } from 'aws-amplify'

export const getCognitoIdToken = async () => {
    try {
        const currentSession = await Auth.currentSession()
        localStorage.setItem("lastTokenAccessedTime", new Date().toString())
        return currentSession.idToken.jwtToken
    }
    catch (error) {
        return sessionStorage.getItem('cognito_id_token') || '';
        // console.log(error)
        // window.location.reload()
    }
}
/**
 *  External Imports
 */
import { connect } from "react-redux";

/**
 *  Internal Imports
 */
 import {toggleGlobalSearchPopover, fetchGlobalSearchUsersAction} from "../../../redux/actions/communityActions";

import CommunityGlobalSearchPopover from "./CommunityGlobalSearchPopover";

/**
 *  Functions
 */

const mapStateToProps = ({ community: { therapeuticAreas, molecularClasses, globalSearchPopoverOpen } }) => ({
    therapeuticAreas, molecularClasses, globalSearchPopoverOpen
});

const mapDispatchToProps = (dispatch) => ({
	togglePopover: (isOpen) => dispatch(toggleGlobalSearchPopover(isOpen)),
    fetchData: (cancelToken, searchQuery) => dispatch(fetchGlobalSearchUsersAction(cancelToken, searchQuery)),
});

/**
 *  Exports
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityGlobalSearchPopover);

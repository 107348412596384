import { SET_DIF_STAGE, SET_DIF_CURRENT_STAGE } from '../action-types'
    
export const setDifStage = (value) => {
    return {
        type: SET_DIF_STAGE,
        payload: value
    }
}
export const setCurrentDIFFormStage = (value) => {
    return {
        type: SET_DIF_CURRENT_STAGE,
        payload: value
    }
}

import Home from './Home'
import { connect } from 'react-redux'
import { setBannerCookie } from '../../../redux/actions/homeActions'

const mapStateToProps = state => {
    return {
        showCookieBanner: state.home.showCookieBanner
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBannerCookie: async () => await dispatch(setBannerCookie()),
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(Home);
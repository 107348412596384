import React from 'react'
import Classes from './index.module.css'
import Modal from '../modal'
import Checkbox from '@mui/material/Checkbox';


const TermsOfUse = props => {
    const {open, showAgreeCheckbox, setTermsOfUseButtonDisable, handleClose, dialogProps,submitted, data, policyDate, policyTime} = props;
    const onTermsOfUseChange = (checked) => {
        if(checked){
            setTermsOfUseButtonDisable(false)
        }else {
            setTermsOfUseButtonDisable(true)
        }
    }
    return (
            <Modal submitted={submitted} policyDate={policyDate} policyTime={policyTime} data={data} open={open} handleClose={handleClose} dialogProps={dialogProps} disableTitleDivider disableBottomHorizontalDivider containerClass="private-policy-content">
                <div className={Classes.impTitleHeaderContainer}>
                    <p>Important: </p>
                    <p>Please read the following terms of use before getting started. By registering with BRAINCommons,
                     you are agreeing to be bound by these terms of use.</p>
                    <p>The following document outlines the terms of use of the BRAINCommons platform. 
                    It describes the expected use, behavior and conduct required from you while using the platform. 
                    Before using any of the BRAINCommons services, you are required to read, understand, and agree to these terms.</p>
                </div>
                {
                
                    submitted ?
                
                    <div className={[Classes.contentStartText, Classes.showHeader].join(' ')}>
                        <p>
                            <span id='cvb-termsOfUse-submitterDetails' className={Classes.submitDetails} ><p id='cvb-termsOfUse-submittedOn' className={Classes.submitText}>Submitted</p> on {policyDate} at {policyTime}</span>
                            <p id='cvb-termsOfUse-submitterName' className={Classes.submitName}>By {data?.first_name + ' ' + data?.last_name}</p>
                        </p>
                        
                    </div> 
                    
                    : null
                }
                <p className={Classes.touStart}>
                <p className={Classes.contentStartText}><b>BRAINCommons&trade;</b></p>
                <p><b>TERMS OF USE</b></p>
                <p>Last update 12/16/2021</p></p>

                <p >Welcome to the BRAINCommons&trade;, a knowledge commons that collocates or integrates data, 
                    storage and computing infrastructure with services, tools, interfaces and applications for managing, analyzing, and sharing 
                    Data, which may include De-identified Personal Data, to create an interoperable resource for the brain and other research 
                    community that enables and encourages data sharing, collaboration and data-driven discovery (the <b>“BRAINCommons”</b>). 
                    The BRAINCommons is managed by BRAINCommons LLC (<b>“BRAINCommons LLC,” “we,”</b> or <b>“us”</b>) or its designees (as applicable, the 
                    <b>“BC Manager”</b>). These BRAINCommons Terms of Use (these <b>“Terms”</b>) are a binding legal agreement between you and BRAINCommons LLC 
                    regarding your access to and use of the BRAINCommons platform, including any website, platform or application, or any embedded 
                    or associated services, software, data or content, provided or made available in connection with the BRAINCommons 
                    (collectively, as may be updated, modified or replaced from time to time, the <b>“BC Platform”</b>).
                </p>

                <p>
                    Please read these Terms carefully. By accessing, registering for, or using the BC Platform, any services provided or made 
                    available in connection with the BC Platform (collectively, <b>“Services”</b>), or any Data (which may include De-identified 
                    Personal Data), tools, software or other materials or content provided or made available on or through the BC Platform 
                    (collectively, <b>“Content”</b>), or by acknowledging agreement below, you are deemed a User of the BC Platform and hereby agree 
                    to be bound by these Terms and to use the BC Platform, including any Services or Content, in accordance with these Terms. 
                    For the purposes of these Terms, (1) <b>“Data”</b> shall mean any information, results, raw or processed data, including 
                    De-Identified Personal Data, that may be stored, uploaded or shared on the BC Platform, (2) <b>“De-identified Personal Data"</b>
                    shall mean personal identifiers are replaced with nonidentifying references or encryption keys so that anyone working with 
                    the data is unable to identify the data subject without the encryption key, and (3) <b>“Users”</b> shall mean (a) registered users, 
                    including the general public, (b) authorized users who are qualified researchers, and (c) designated users designated by Data 
                    Contributor for use of a specific dataset, tool and/or application, in each case, accessing the BC Platform for Education 
                    Purposes or Research Purposes. If you are using the BC Platform on behalf of or in association with an entity, you must 
                    comply with such entity’s policies and procedures relevant to your access, registration, or use of the BC Platform.
                </p>

                
                
                <p>
                    In addition, when using certain features of the BC Platform, you also will be subject to the agreements, 
                    policies, guidelines and terms applicable to such features (collectively, as may be updated from time to time, 
                    <b>“BC Agreements”</b>). All BC Agreements, including without limitation the BRAINCommons Global Privacy Notice, the 
                    BRAINCommons Global Privacy Policy, the BRAINCommons Data Use Agreement (as applicable to Users who wish to receive 
                    Zone 2 and/or Zone 3 access), and any additional terms applicable to accessing or using certain Data, including 
                    De-identified Personal Data, that are established by the contributor of such Data and posted on or otherwise made 
                    available in connection with the BC Platform (collectively, <b>“Additional Data Use Terms”</b>), are incorporated by 
                    reference into these Terms. If these Terms are in conflict with any BC Agreements, the terms in this BRAINCommons 
                    Terms of Use will control to the extent there is any conflict.
                </p>

                <p>
                    We may periodically make changes to these Terms, and we will identify the date of last update. We will post the 
                    updated Terms on the BC Platform, and we will use commercially reasonable efforts to post changes in advance if we 
                    reasonably determine that such changes are material. We may also, in our discretion, use other commercially reasonable 
                    methods to attempt to notify you of such changes. Changes to these Terms will be effective upon posting on the BC 
                    Platform. We encourage you to review the most recent version of these Terms frequently. If you continue to use the BC 
                    Platform after we modify these Terms, you will be deemed to have consented to the updated Terms as of the date of 
                    the modification. If you do not agree to any provision of these Terms, you must not use the BC Platform.
                </p>

                <ol type='1'>
                    <li>
                        <header><b>Eligibility</b> </header>
                        <p>
                            Use of the BC Platform is not allowed where such use would be prohibited by applicable law, rule or 
                            regulation. You represent and warrant that any information you submit to the BC Platform or otherwise to 
                            the BC Manager in order to register for any account is accurate, current and complete, that you are 18 years 
                            of age or older (or your parent or guardian has reviewed and agreed to these Terms on your behalf), and 
                            that you are fully able and competent to enter into and abide by these Terms. Each account on the BC Platform 
                            may be used by only one individual, and each individual may create only a single account.
                     </p>
                    </li>
                    <li>
                        <header><b>Account Registration</b></header>
                        <p>You must register and create an account in order to use the BC Platform. When you register, you agree to </p>
                        <ol type='a'>
                            <li>provide accurate, current and complete information about yourself as may be prompted by registration forms 
                                on the BC Platform (<b>“Registration Data”</b>);
                        </li>
                            <li>maintain the security of any logins, passwords, or other credentials that you select or that
                                are provided to you for use on the BC Platform; and
                            </li>
                            <li>maintain and routinely update the Registration Data, and any other information you provide to us, 
                                and to keep all such information accurate, current and complete. You will promptly notify us of any 
                                unauthorized use of your account or any other breach of security by emailing us at <a href='mailto:notice@braincommons.org'>notice@braincommons.org</a>. All notices received will be triaged 
                                for appropriate action and response.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <header className={Classes.list}><b>Fees  </b></header>
                        <p>
                            If your access to or use of the BC Platform, or any Services or Content, is subject to any applicable fees, 
                            including prepaid credits or fixed fees, you will be notified of such fees prior to your access or use and you 
                            will timely and fully pay us all such amounts in accordance with these Terms. Except as otherwise expressly 
                            set forth by the BC Manager in writing, all amounts due under or in connection with these Terms shall be paid 
                            within 30 days of the date of invoice. You agree and acknowledge that the BC Manager shall be entitled to 
                            (a) interest on any overdue unpaid amounts at the lesser of (i) the rate of 1.5% per month on such unpaid 
                            amounts accruing from the date such amount is due, or (ii) the maximum rate permissible under applicable law; 
                            and (b) reimbursement for all reasonable, documented costs in connection with the collection of such unpaid 
                            amounts.
                        </p>
                    </li>
                    <li>
                        <header><b>Access to and Use Data and other Content </b></header>
                        <ol type="a">
                            <li>
                                <header><b> Overview.</b></header>
                                <p>
                                    Data, which may include De-identified Personal Data will be made available through the BC Platform 
                                    using a tiered system, as discussed in greater detail in the BRAINCommons Data Use Agreement. 
                                    This Section 4(a) contains a brief overview of the tiered Data access system, but you should review 
                                    the BRAINCommons Data Use Agreement carefully if you wish to request for access to Data in Zone 2 
                                    and/or Zone 3. In the event of any conflict between this Section 4(a) and the BRAINCommons Data Use 
                                    Agreement (or any applicable Additional Data Use Terms), the BRAINCommons Global Privacy Notice, 
                                    the BRAINCommons Global Privacy Policy (or Additional Data Use Terms, as applicable), this 
                                    BRAINCommons Terms of Use will govern to the extent of any conflict.
                                </p>
                                <p>
                                    Subject to the terms and conditions of these Terms, effective upon completion and verification of your 
                                    registration, the BC Manager hereby grants you a limited, non- transferable, non-sublicensable, revocable 
                                    license to use the BC Platform and certain Data and, if applicable, certain other Contents, solely in 
                                    accordance with the rules for access and use applicable to such Data (the <b>“Zone Rights”</b>), including rules 
                                    set forth in these Terms, any applicable Additional Data Use Terms, and all Policies, solely for, as applicable, 
                                    the approved purposes and within the approved scope set forth for the applicable Zone as defined below. 
                                    Unless otherwise expressly granted by the BC Manager in writing, your use of the Data and any other Contents 
                                    is limited to Research Purposes or Educational Purposes. For clarity, as used herein, (a) “Educational Purposes” 
                                    means a purpose that relates directly to learning, teaching, training, and professional development from use 
                                    of Data and this includes non-profit and commercial activities that achieve this purpose; and (b) “Research Purposes” 
                                    means non-commercial activities undertaken for the advancement of knowledge. It includes analysis on data to conduct 
                                    a systematic investigation, including research development, testing, or evaluation, which is designed to develop or 
                                    contribute to generalizable knowledge or education. The data contributor will set (and may subsequently modify) the 
                                    Zone Rights for the particular set of Data contributed by such data contributor, which Zone Rights will fall into one 
                                    of three categories (each such category a <b>“Zone”</b>):
                                </p>

                                <p> <b>Zone 1 (Public Access)</b>: designated to a member of the public, subject to restrictions as set forth under 
                                this BRAINCommons Terms of Use, BRAINCommons Global Privacy Notice and BRAINCommons Global Privacy Policy.  
                                Certain access and functionality of the BC may be enhanced once a User has been verified (each verified 
                                User, an <b>“Authorized User”</b>).
                                </p>
                                <p><b>Zone 2 (Controlled Access)</b>: Use by Authorized Users after submission of a Zone access request and 
                                receipt of approval by or through the Data Access Committee (or, as applicable, via another approval process 
                                implemented by the BC Manager). Each Authorized User must enter into a BRAINCommons Data Use Agreement and 
                                if applicable, an organizational or dataset-specific use agreement as part of his or her access request. 
                                </p>
                                <p>  <b>Zone 3 (Restricted Access)</b>: Use only by the BRAINCommons data contributor and Authorized User(s) 
                                that are (a) specifically authorized by such BRAINCommons data contributor or (b) approved through the Data 
                                Access Committee (or, as applicable, via another approval process implemented by the BC Manager). Prior to 
                                being granted access to Zone 3 Data, each Authorized User must enter into a BRAINCommons Data Use Agreement 
                                and if applicable, an organizational or dataset-specific use agreement.
                                </p>
                            </li>
                            <li>
                                <header><b> Contribution.</b></header>
                                <p>  You may not post any raw data or subject-level metadata to the BC Platform, except pursuant to
                                    and in accordance with a separate BRAINCommons Data Contributor Agreement between you and BRAINCommons LLC (if applicable,
                                    the <b>“DCA”</b>).
                        </p>
                            </li>
                            <li>
                                <header><b> Disclaimer.</b></header>

                                <p>
                                    We take no responsibility and assume no liability for (i) any Content made available to you 
                                    through the BC Platform (ii) any data, software or other materials or content (excluding raw data or 
                                    subject-level metadata) provided by you pursuant to the DCA that you post or transmit on or through the BC Platform, 
                                    including any modifications or derivatives thereof (collectively, <b>“User Content”</b>), or (iii) for any loss, damage, 
                                    creation, or processing of any Content or User Content. Although we have no obligation to screen, edit, or monitor 
                                    any Content and User Content posted on or transmitted through the BC Platform, we reserve the right, and have discretion, 
                                    to remove and screen any Content or User Content posted, stored, or transmitted on or through the BC Platform at 
                                    any time and without notice.
                                </p>
                            </li>
                            <li>
                                <header><b> License.</b></header>
                                <p> If you post or transmit any User Content on or through the BC Platform, then you hereby grant us and all 
                                    other Users (including Authorized Users) a non-exclusive, royalty-free, perpetual, irrevocable, worldwide, 
                                    transferable and fully sublicensable right and license to use, reproduce, modify, adapt, publish, translate, 
                                    create derivative works from, distribute, perform, and display such User Content throughout the world in any 
                                    media, including through the BC Platform.
                        </p>
                            </li>
                            <li>
                                <header><b> Representations and Warranties.</b></header>
                                <p>  If you post or transmit any User Content on or through the BC Platform, then you represent and warrant that
    
                            </p>
                                <ol type='i'>
                                    <li> you own and control all of the rights to the User Content that you post or transmit, or you otherwise have the
                                        right and authority to post such User Content to the BC Platform and to grant us and other Users the rights granted
                                        under these Terms;
                                    </li>
                                    <li> no User Content contains any Restricted Data (as defined below); and
    
                                </li>
                                    <li> the use and posting of User Content does not (A) violate the BRAINCommons Data Use Agreement, 
                                        the DCA or these Terms,  (B) violate any rights of or cause injury to any person or entity, and (C) 
                                        otherwise create any harm or liability of any type for us or for third parties.
                                     </li>
                                </ol>
                                <ol type='1'>
                                <p className={Classes.purposeText}>For the purposes of these Terms, <b>“Restricted Data”</b> is </p>
                                    <li> any information that is regulated by:</li>
                                        <ol type='A'>
                                            <li>any applicable national, federal, state or local law, rule, directive or regulation relating to the privacy
                                                of personal information, including without limitation the Health Insurance Portability and Accountability Act of 1996
                                                and its implementing regulations (collectively, <b>“HIPAA”</b>); Regulation (EU) 2016/679 of the European Parliament 
                                                and the Council of 27 April 2016 (General Data Protection Regulation); 
                                            </li>
                                            <li>any applicable privacy policy or practice; or </li>
                                            <li>any law or regulation concerning the notification to data subjects, law enforcement or other
                                                third parties of the occurrence of any actual or suspected disclosure of restricted data or
                                                personally identifiable information;
                                            </li>
                                        </ol>
                                    <li>any personal identifiable information/personal data or special (sensitive) personal data; or </li>
                                    <li>any information or data that is owned by or exclusively licensed to any individual or entity other than 
                                        you, or in respect of which the use, retention, duplication, or disclosure thereof, is restricted by any 
                                        individual or entity (including any governmental or regulatory authority).
                                     </li>
                                </ol>
                                <p>Materials available in connection with the BRAINCommons and BC Platform are not a substitute for 
                                    professional advice on any matter, medical or otherwise. Always seek the advice of a qualified 
                                    health professional. BRAINCommons LLC and the BC Manager do not recommend or endorse any treatment, 
                                    institution, professional, physician, product, procedure, or other information that may be mentioned 
                                    through the BC Platform or in connection with the BRAINCommons.
                                </p>
                            </li>
                        </ol>
                        <li>
                            <header><b>Confidentiality</b></header>
                            <p>   Without limiting any other applicable obligations or restrictions, you will keep strictly 
                                confidential and not disclose or make available to any third party, any information you obtain or access 
                                via, regarding or in connection with the BC Platform or the BRAINCommons that is marked as confidential 
                                or should reasonably be treated as confidential (excluding information specifically identified by 
                                BRAINCommons LLC, the BC Manager or the source as non-confidential).
                        </p>
                        </li>
                        <li>
                            <header><b>	  Use Restrictions </b></header>


                            <p>  In connection with any access to or use of the BC Platform (or any Services or Content),
                            you agree not to:</p>
                            <ol type='a'>
                                <li> Contribute or distribute any Restricted Data; </li>

                                <li> Publish, present or otherwise disclose Data, or results from analysis of Data, obtained through the
                                    BC Platform without properly attributing
                              <ol type='i'>
                                        <li> the Data source (i.e. the data contributor) using the language provided by such data contributor for that set of Data, and </li>
                                        <li> BRAINCommons LLC using the language posted on the BC Platform or otherwise provided by the BC Manager;</li>
                                    </ol>
                                </li>

                                <li> Distribute, disclose, make available or download any Data, except as expressly authorized by the BC Manager in writing and solely in accordance with the BRAINCommons Data Use Agreement and any applicable Additional Data Use Terms;
                                </li>

                                <li> Use or attempt to use Services or Content to harm, marginalize, or discriminate against individuals or populations;
                                 </li>
                                <li> Identify or contact, or make any attempt to identify or contact, any individual to which any De-identified Personal Data pertains; </li>

                                <li> Create a false identity, impersonate another person or entity in any way, or falsely imply that any third-party service is associated with the BC Platform or the BRAINCommons;
                                </li>

                                <li>  Upload or otherwise transmit to or through the BC Platform any Content that infringes,
                                    misappropriates, or violates any third-party rights;
                                </li>

                                <li>  Upload or otherwise transmit to or through the BC Platform any Content that violates, or
                                    causes us or our affiliates to violate, any applicable law or regulations;
                                </li>
                                <li>  Upload or otherwise transmit to or through the BC Platform any unlawful, harmful, harassing, 
                                    defamatory, threatening, hateful or otherwise objectionable or inappropriate Content, or any 
                                    Content that can cause harm or delay to the BC Platform (or any connected or related systems) or 
                                    can expose BRAINCommons or its affiliates, the BC Manager or other Users to risk of harm, damage, 
                                    liability or loss;
                                </li>

                                <li>  Upload or otherwise transmit to or through the BC Platform any trade secrets or information for
                                    which you have any obligation of confidentiality or professional secrecy;
                                </li>

                                <li>  Upload or otherwise transmit to or through the BC Platform any unsolicited or unauthorized
                                    advertising, promotional materials, junk mail, spam, or any other form of solicitation
                                (commercial or otherwise); </li>

                                <li>  Copy or modify any components of the BC Platform that are not licensed under the Apache
                                    Version 2.0 license or under a substantially similar open source license
                                    (collectively, <b>“Proprietary Components”</b>) for any purpose, or reverse engineer, decompile, modify,
                                    translate, disassemble or discover the source code of any Proprietary Components;
                                </li>

                                <li>  Distribute, disclose, market, rent, lease or otherwise transfer the BC Platform (or any component thereof)
                                    to any other individual or entity;
                                </li>

                                <li>  Gain unauthorized access to (i) the BC Platform (or any associated Services or Content), (ii) any other User’s account, 
                                    (iii) any Zones, (iv) personally identifiable information, or (iv) any related or connected systems;
                                </li>

                                <li>  Post, transmit or otherwise make available any virus, worm, spyware or any other computer code, 
                                    file or program that may or is intended to damage or hijack the operation of any hardware, software, 
                                    equipment or systems;
                                  </li>

                                <li>  Remove, disable, damage, bypass, circumvent or otherwise interfere with any security-related features of the BC Platform, including without limitation features that limit or prevent access to Zones, except that you may use passwords and other credentials we provide solely as expressly authorized and intended;
                                 </li>

                                <li>  Interfere with or disrupt the BC Platform (or any related or connected systems) or violate
                                the regulations, policies or procedures of any BC Platform-related systems; </li>

                                <li>  Violate these Terms or any applicable laws or regulations; or </li>

                                <li>  Assist or permit any persons in engaging in any of the activities described above.</li>
                            </ol>
                            <p>Additionally, you acknowledge the following in connection with your use of the BC Platform: we may use third-party vendors and hosting partners to provide the hardware, software, networking, storage, and related technology used to operate the BC Platform, as further specified in the BRAINCommons Global Privacy Notice; and subject to applicable law, technical processing and transmission of the BC Platform, including Services or Content made available thereon, certain elements of the BC Platform may be unencrypted and involve
                         </p>
                            <ol type='i'>
                                <li> transmissions over various networks, and </li>
                                <li> changes to conform and adapt to technical requirements of connecting networks or devices.</li>
                            </ol>
                        </li>
                        <li>
                            <header className={Classes.list}><b>	Modifications </b></header>

                            <p> We reserve the right to modify, discontinue and restrict, temporarily or permanently, 
                                all or part of the BRAINCommons or the BC Platform (including any Services or Content) without 
                                notice in our sole discretion. Neither we nor our licensors, nor any other Users, will be liable 
                                to you or to any third party for any modification, discontinuance or restriction of the BRAINCommons or 
                                the BC Platform (or any associated Services) or any deletion of any Content (including User Content) or 
                                Data (including De-identified Personal Data) stored on, or otherwise associated with, your account on the 
                                BC Platform.
                             </p>
                        </li>
                        <li>
                            <header><b> Term and Termination </b></header>

                            <p>Your account remains in effect until you cancel it or the BC Manager terminates your account. 
                                Notwithstanding any other provision of these Terms, we reserve the right, without notice and in 
                                our sole discretion, to suspend or terminate your account and to block, restrict and prevent your 
                                future access to and use of the BC Platform. Without limiting the generality of the foregoing, the 
                                BC Manager may terminate your account in cases of actual or suspected fraud, abuse or violations of 
                                these Terms or applicable law, or to protect itself or any Users from potential harm, disruption, 
                                damage, liability or loss. If your account is terminated for any reason, we reserve the right 
                                (but do not have the obligation) to delete any Data or other Content or User Content associated 
                                with your account. Upon any suspension or termination of your account, you must immediately cease 
                                using the BC Platform. All provisions of these Terms that by their nature should survive the 
                                termination of your license to access the BC Platform or any termination of these Terms 
                                (including, without limitation, provisions governing indemnification, limitations of liability, 
                                confidentiality, disclaimers of warranty, use restrictions, and intellectual property) will continue 
                                to remain in full force and effect after any termination.
                              </p>
                        </li>
                        <li>
                            <header><b> Feedback </b></header>

                            <p> Any comments, suggestions, ideas or other information, related to the BC Platform, the BRAINCommons, 
                                BC Manager or BRAINCommons LLC, submitted by you to us or the BC Platform (collectively, <b>“Feedback”</b>) 
                                are non-confidential (notwithstanding any written notice to the contrary you may include in any 
                                accompanying communication of such Feedback), and you hereby grant to us and our affiliates a non-exclusive, 
                                royalty-free, perpetual, irrevocable, worldwide, transferable and fully sublicensable right to use your 
                                Feedback for any purpose and in any manner without compensation or attribution to you. Where required by 
                                applicable law or regulation, the BC Manager will respect any privacy restrictions applicable to Feedback 
                                you communicate to it.
                        </p>
                        </li>
                        <li>
                            <header><b>Copyright Infringement </b></header>
                            
                            <p className={Classes.subHeader}>a. Claims</p>

                            <p> We respect the intellectual property rights of others, and ask you to do the same. It is our policy to terminate the access privileges of those who infringe the intellectual property rights of others. If you believe that your work has been posted on the BC Platform in a way that constitutes copyright infringement, please contact us at the address below and provide the following information: 
                            </p>
                            <ol type='a'>

                                <li>  an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;
                                </li>
                                <li> a description of the copyrighted work that you claim has been infringed, and identification of the time(s) and date(s) the material that you claim is infringing was displayed on the BC Platform;
                                </li>
                                <li> your address, telephone number, and email address;
    
                            </li>
                                <li> a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and 
                                </li>
                                <li> a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.
                                </li>
                            </ol>

                            <p className={Classes.subHeader}>b. Disputing Claims</p>

                            <p>If you believe that your User Content which has been removed (or to which access was disabled) is not infringing, or that you have the authorization from the copyright owner, the copyright owner's agent, or pursuant to applicable law, to post and use such User Content, you may send a counter-notice containing the following information to the copyright agent:  </p>
                            <ol type='a'>
                                <li> your physical or electronic signature; </li>
                                <li> identification of the User Content that has been removed or to which access has been
                                    disabled and the location at which the materials appeared before it was removed or disabled;
                                </li>
                                <li> a statement that you have a good faith belief that the User Content was removed or disabled as a result of mistake or a misidentification of the User Content; and
                                </li>
                                <li> your name, address, telephone number, and e-mail address, a statement that, to the extent permitted by applicable law, you consent exclusively to the jurisdiction of any federal or state court within the jurisdiction set forth in Section 19(b), and a statement that you will accept service of process from the person who provided notification of the alleged infringement. If a counter-notice is received by the copyright agent, we may send a copy of the counter-notice to the original complaining party. 
                                </li>
                            </ol>
                            <p>Our designated agent for notice of copyright infringement can be reached at <a id='cvb-termsOfUse-bcNoticeLink' href='mailto:notice@braincommons.org'>notice@braincommons.org</a>. All notices received will be triaged for appropriate action and response.</p>
                        </li>
                        <li>
                            <header><b>Trademarks </b></header>

                            <p>Any trademark, logo or other proprietary indicia contained on the BC Platform are trademarks or registered trademarks of BRAINCommons LLC and its licensors, and may not be copied, imitated or used, in whole or in part, without the prior written permission of the applicable trademark holder. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier, or otherwise, does not constitute or imply endorsement, sponsorship, or recommendation thereof by us, or vice versa.
                        </p>
                        </li>
                        <li>
                            <header><b>Ownership </b></header> 

                            <p>We, our affiliates and our licensors collectively own all right, title, and interest, including all intellectual property rights, in and to the BC Platform. We reserve all rights not expressly granted to you in these Terms, and as applicable, the DCA.
                        </p>
                        </li>


                        <li>
                            <header><b>Citation </b></header>
                        
                            <ol type='a'>
                                <li>Users of the BC Platform must adhere to the citation policy set forth in these Terms below. In accordance with the BC Agreements, Users are permitted to use the Services and Content to publish new research findings in publications or presentations, provided Users have built upon or improved the existing work and original publications connected to the Services and Content.</li>
                                <li>For BC Platform Services, which were key in attaining your results, User shall cite the BRAINCommons. </li>
                                <li>For Data/datasets which contributed to you attaining your results, User shall cite the project as defined in the project page on BC Platform Project Gallery, the dataset name provided alongside the acknowledgement text, and, if available, also cite the primary publication listed alongside the acknowledgement text. [“Project name, dataset name, acknowledgement text”] </li>
                                <li>In cases where neither acknowledgement instructions nor a primary publication are provided alongside the dataset name, and only in such cases, the names of the dataset contributors should be cited. [“Project name, dataset name, contributor(s)”] </li>
                                <li>For Tools, software or other materials, User shall cite as defined in the tools, software or other materials respective citation policy. If you can't identify a clear citation policy, use the open-source repository as the citation link.</li>
                                <li>For all BC Platform Services, Data/datasets, Tools, software or other materials, User shall cite the Digital Object Identifier (DOI) alongside the respective citation requirements as defined above. </li>
                                <li>Failure to cite resources used in a publication or presentation would constitute scientific misconduct and must be corrected by an Erratum and correction of the given article if it is discovered post-publication. </li>
                                <li>While not required, we appreciate it when Users send us a copy of their publication or other work citing our resources. This helps us track when and how our Services and Content are being used, and thus guide and support our continued efforts to provide useful resources. Please email us at <a href='mailto:notice@braincommons.org'>notice@braincommons.org</a> and include “Publications” in the title of your email.</li>
                            </ol>
                        </li>





                        <li>
                            <header><b>Third-Party Content </b></header>

                            <p> The BC Platform may contain links or otherwise provide access to web pages, services, data or
                                other content of third parties (collectively, <b>“Third-Party Content”</b>). Your access to or use of
                                any Third-Party Content is at your sole risk. We do not monitor, endorse, or adopt, or have any control 
                                over, any Third-Party Content. Under no circumstances will BRAINCommons LLC or the BC Manager be 
                                responsible or liable in any way for or in connection with any Third-Party Content. Third-Party 
                                Content may be subject to separate terms and conditions. You should review, and you are solely 
                                responsible for complying with, any such third-party terms.
                        </p>
                        </li>
                        <li>
                            <header><b>Indemnification </b></header>
                            <p>
                                In addition to any other indemnification obligations set forth in the BC Agreements, you will defend, 
                                indemnify and hold harmless BRAINCommons LLC or the BC Manager, and its and their respective affiliates, 
                                and their respective directors, officers, agents, employees, licensors, and suppliers from and against 
                                all claims, losses, liabilities, damages, costs and expenses (including reasonable attorneys’ fees and 
                                expenses) arising out of or related to your User Content, your use of the BC Platform (or any activity 
                                under your account or credentials), your violation of these Terms, or your violation of any rights of a 
                                third party, except to the extent arising from the gross negligence or willful misconduct of the BC Manager.
                        </p>
                        </li>
                        <li>
                            <header><b>	Disclaimer of Warranties </b></header>

                            <p> EXCEPT AS EXPRESSLY SET FORTH IN THESE TERMS, </p>
                            <ol type='A'>
                                <li>  BRAINCOMMONS LLC, ITS AFFILIATES AND THE BC MANAGER HEREBY DISCLAIM ALL WARRANTIES RELATED TO THE BC PLATFORM, THE BRAINCOMMONS, OR ANY SERVICES, DATA OR OTHER CONTENT MADE AVAILABLE THEREON OR ASSOCIATED THEREWITH, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE; AND
                                </li>
                                <li>  THE BC PLATFORM AND THE BRAINCOMMONS ARE PROVIDED “AS IS” AND WITHOUT ANY WARRANTY RELATED TO ACCURACY, COMPLETENESS, QUALITY OR THAT THEY WILL BE UNINTERRUPTED OR ERROR FREE.
                                </li>
                            </ol>
                            <p>
                            YOU MUST NOT MAKE ANY REPRESENTATION OR WARRANTY ON BEHALF OF THE BRAINCOMMONS, BRAINCOMMONS LLC, THE BC MANAGER OR ANY OTHER USER REGARDING THE BC PLATFORM, THE BRAINCOMMONS OR ANY SERVICES, DATA, DE-IDENTIFIED PERSONAL DATA OR OTHER CONTENT THAT YOU OBTAIN ON OR IN CONNECTION WITH THE BC PLATFORM OR THE BRAINCOMMONS, EXCEPT TO THE EXTENT AS MAY BE EXPRESSLY AUTHORIZED BY SUCH USER, BRAINCOMMONS LLC, THE BC MANAGER, AS APPLICABLE.
                            </p>
                            <p>SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.
                        </p>
                        </li>
                        <li>
                            <header><b>Limitations of Liability </b></header>

                            <p>TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, NEITHER BRAINCOMMONS LLC NOR THE BC MANAGER NOR ANY OF THEIR RESPECTIVE AFFILIATES, OFFICERS, DIRECTORS, LICENSORS, OR SUPPLIERS WILL BE LIABLE TO ANY PARTY UNDER THESE TERMS OR OTHERWISE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OR ACCESS OF OR INABILITY TO USE OR ACCESS THE BC PLATFORM, THE BRAINCOMMONS OR ANY SERVICES OR CONTENT MADE AVAILABLE THROUGH THE BC PLATFORM OR THE BRAINCOMMONS, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (REGARDLESS OF THE BASIS OF THE CLAIM AND EVEN IF ADVISED OF THE POSSIBILITY OF THESE DAMAGES).
                            </p>
                            <p>TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, THE MAXIMUM TOTAL LIABILITY OF BRAINCOMMONS LLC AND THE BC MANAGER AND THEIR RESPECTIVE SUPPLIERS AND LICENSORS TO YOU FOR ALL CLAIMS UNDER THESE TERMS OR OTHERWISE IN CONNECTION WITH THE USE OR ACCESS OF THE BC PLATFORM OR THE BRAINCOMMONS IS $50, REGARDLESS OF THE BASIS OF THE CLAIM.
                             </p>
                            
                            <p>THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE, TO THE EXTENT
                                PERMITTED BY APPLICABLE LAW.
                        </p>
                        </li>
                        <li>
                            <header><b> 	Electronic Communications </b></header>
                            <p>
                                By accessing or using the BC Platform, you consent to receiving electronic communications from us.
                                You agree that any notices, agreements, disclosures, or other communications that we send to you electronically
                                will satisfy any legal communication requirements, including that such communications be in writing.
                        </p>
                            <li className={Classes.listWithBottomMargin}>
                                <header><b> 	Governing Law & Jurisdiction </b></header>
                                <ol type='a'>
                                    <li>  <b>Governing Law</b>. Unless otherwise agreed in writing between you and BRAINCommons LLC, these Terms are governed by and construed in accordance with the laws of the State of New York, excluding conflicts of law principles.
                                    </li>

                                    <li>  <b>Jurisdiction</b>. Unless otherwise agreed in writing between you and BRAINCommons LLC, any dispute or claim arising out of or relating to the BC Platform, the BRAINCommons or these Terms must be brought exclusively in the state or federal courts within the State of New York (except that a party may seek preliminary equitable relief in any court of competent jurisdiction in relation to intellectual property rights or confidentiality obligations).
                                       
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <header><b>General </b></header>
                                <p>
                                Our failure to act in a particular circumstance does not waive our ability to act with respect to that circumstance or similar circumstances. We will not be responsible or liable for any failure or delay to perform any of our obligations under these Terms resulting from any event or circumstance beyond our reasonable control. Any provision of these Terms that is found to be invalid, unlawful, or unenforceable will be enforced to the maximum extent permissible under applicable law and in all other jurisdictions and circumstances (and otherwise will be severed from these Terms), and the remaining provisions of these Terms will continue to be in full force and effect. The section headings and titles in these Terms are for convenience only and have no legal or contractual effect. You may not assign or delegate any of your rights or obligations under these Terms without our prior written consent, and any purported assignment in contravention of the foregoing will be null and void. These Terms will be binding upon and inure to the benefit of the parties hereto and their respective successors and permitted assigns. 
                                <b>Any dispute or claim arising out of or relating to these Terms or any use of the BC Platform must
                                    be commenced within one year after the claim arose</b>.
                                </p>
                                <p>   These Terms and all BC Agreements, including any Additional Data Use Terms (as applicable) and BRAINCommons Data Contributor Agreement (if applicable), constitute the entire agreement between you and BRAINCommons LLC concerning the BC Platform. These Terms and all BC Agreements, including any Additional Data Use Terms (if applicable) and BRAINCommons Data Contributor Agreement (if applicable) supersede all prior agreements or communications between you and BRAINCommons LLC regarding the subject matter of these Terms.
                            </p>
                            </li>
                            <li>
                                <header><b>Questions & Contact Information </b></header>
                                <p>
                                    If you have any questions or concerns about the BC Platform, the BRAINCommons, or these Terms, you may contact BRAINCommons LLC by
                                    email at <a id='cvb-termsOfUse-bcInfoLink' href='mailto:notice@braincommons.org'>notice@braincommons.org</a>. All notices received will be triaged for appropriate action and response.
                            </p>
                            </li>
                        </li>
                    </li>
                </ol>
                {
                    showAgreeCheckbox ? 
                    <div className={Classes.checkboxContainer}>
                        <Checkbox
                            color="primary"
                            onChange={(e) => onTermsOfUseChange(e.target.checked)}
                            id='cvb-termsOfUse-checkbox'
                        /> <label className={Classes.labelText}>By accepting these Terms, you acknowledge to BRAINCommons that you have read and hereby agree to these BRAINCommons Terms of Use.  If the User is a minor, these Terms are accepted by such minor’s legal guardian and both the legal guardian and minor have read and hereby agree to these BRAINCommons Terms of Use. </label>
                    </div> : null
                }
        </Modal>
            )
        }
        
export default TermsOfUse;
import Acknowledgment from './Acknowledgment'
import { connect } from 'react-redux'
import { setAcknowledgmentSignature } from '../../../redux/actions/dcaActions'
const mapStateToProps = state => {
    return {
        dcaStage: state.dca.dcaStage,
        currentDCAFormStage: state.dca.currentDCAFormStage,
        acknowledgmentSignature: state.dca.acknowledgmentSignature,
        contributorSigningName: state.dca.contributorSigningName,
        contributorSigningTitle: state.dca.contributorSigningTitle,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setAcknowledgmentSignature: async (value) => await dispatch(setAcknowledgmentSignature(value)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Acknowledgment);
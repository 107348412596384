import React, { useState } from 'react'
import CustomClasses from './index.module.css'
import Popover from '@mui/material/Popover'
import get from 'lodash/get'
import v4 from 'uuid/v4'
import TableCell from '@mui/material/TableCell';
import ImageVerticalIcon from '../../../assets/icons/MoreOptionsVertical.S-dark.svg';
import Modal from '../../commons/modal'
import AddUsersToShare from '../add-users-to-share'
import { shareNotebook, getSharedUserForNotebook, unshareNotebook } from '../../../api/notebook-api'
import { getFileType } from '../../../utils/stringFormatter';


const WorkspaceFileListRow = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [ sharedUserList, setSharedUserList ] = useState([]);
    const { fileData = {} } = props;
    const fileDataType = getFileType(get(fileData, 'Key', ''));
    const { onDownloadClick = () => { } } = props;
    const { onDeleteFile = () => { } } = props;
    const { fileId = '' } = props;
    const { workspaceId = '' } = props;
    const [openSampleNotebookShareModal, setOpenSampleNotebookShareModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const { folderDirectory = '' } = props;
    const getSharedUsersList = async () => {
        try{
            const res = await getSharedUserForNotebook(`${folderDirectory}${fileId}`);
            setSharedUserList(res);
        }catch(error){
            console.log('error in getting shared user list', error);
        }
    }

    const onOptionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClosePopover = () => {
        setAnchorEl(null);
    }
    const onDownloadFileClick = () => {
        onDownloadClick(fileId);
    }
    const onOpenDeleteModal = () => {
        setOpenDeleteModal(true)
    }
    const closeDeleteModal = () =>{
        setOpenDeleteModal(false)
    }
    const onDeleteSelectedFile = () => {
        onDeleteFile(fileId)
        setOpenDeleteModal(false)
    }
    const onShareSelectedFile = () => {
        try {
            setOpenSampleNotebookShareModal(true);
            getSharedUsersList();
        }
        catch (error) {
            console.log('error in sharing', error);
        }
    }
    const onAddUserClick = async (sharedWith) => {
        try {
            const fileKey = [`${folderDirectory}${fileId}`];
            const data = {...sharedWith, keys: fileKey, workspaceAccountNumber: workspaceId};
         //   const res = unShareWorkspaceWithUsers(id, data );
            const res = await shareNotebook(data);
            if (res == '200' || res == 204) {
                getSharedUsersList();
            }
        } catch (error) {
            console.log('error in adding users', error);
        }
    }
    const closeShareSampleNotebookModal = () => {
        try {
            setOpenSampleNotebookShareModal(false);
        } catch (e) {
            console.log('share error file', e);
        }
    }
    const onShareSampleNotebookConfirm = () => {
        try {
            setOpenSampleNotebookShareModal(false);
        } catch (e) {
            console.log('share error file', e);
        }
    }
    const unshareNotebookForSelectedUser = async (email) => {
        try {
            const data = {
                "sharedWith": email,
                "key": `${folderDirectory}${fileId}`
            };
            const res = await unshareNotebook(data);
            if (res == 200 || res == 204 || res == 202) {
                getSharedUsersList();
            }
        } catch (error) {
            console.log('error ins start or stop');
        }
    }
    const SHARE_SAMPLE_NOTEBOOK_MODAL_PROPS = {
        modalTitle: "Share Notebook",
        positiveButtonAction: onShareSampleNotebookConfirm,
        negativeButtonAction: closeShareSampleNotebookModal
    }
    const DELETE_MODAL_PROPS = {
        modalTitle: "Are you sure you want to delete this notebook",
        positiveButtonText: "Delete Notebook",
        negativeButtonText: "Cancel",
        positiveButtonAction: onDeleteSelectedFile,
        negativeButtonAction: closeDeleteModal
    }

    const openPopover = Boolean(anchorEl);

    const fileDetails = {
        'type': fileDataType,
        'id': fileId,
        'size': get(fileData, 'Size', '')
    }
    return (
        <TableCell padding="checkbox">
            <Modal
                open={openSampleNotebookShareModal}
                handleClose={closeShareSampleNotebookModal}
                dialogProps={SHARE_SAMPLE_NOTEBOOK_MODAL_PROPS}
                disableBottomHorizontalDivider
            >
                <AddUsersToShare
                    workspaceDetails={fileDetails}
                    onAddUserClick={onAddUserClick}
                    unshareForSelectedUser={unshareNotebookForSelectedUser}
                    id={fileId}
                    sharedUsersList={sharedUserList}
                    usersForShare={props.usersForShare}
                />
            </Modal>
            <Modal
                open={openDeleteModal}
                handleClose={closeDeleteModal}
                dialogProps={DELETE_MODAL_PROPS}
                disableTitleDivider
                >
            </Modal>

            <div className={CustomClasses.downloadCellOptions}>
                {!folderDirectory?.includes("upload/") ?
                    <button onClick={() => onDownloadFileClick()} className={`small-size-button ${CustomClasses.downloadButton}`}>Download</button>
                    : null
                }
                <img onClick={(e) => onOptionsClick(e)} src={ImageVerticalIcon} alt='more options' />
                <Popover
                    id='cvb-workspaceFileListRow-popover'
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        horizontal: 'right',
                    }}
                >
                    <ul className={CustomClasses.dropdownListContainer}>
                       {!folderDirectory?.includes("upload/") ? 
                       <li  id='cvb-workspaceFileListRow-share' onClick={onShareSelectedFile} className={CustomClasses.linkIcons}>
                            Share
                        </li>: null}
                        <li  id='cvb-workspaceFileListRow-delete' onClick={onOpenDeleteModal} className={CustomClasses.linkIcons}>
                            Delete
                        </li>
                    </ul>
                </Popover>

            </div>
        </TableCell>
    )
}

export default WorkspaceFileListRow;
import { SET_USER_LOGIN, SET_USER_DETAILS, SET_USER_PRIVATE_STATUS } from '../action-types'

const initialState = {
    isUserLoggedIn: false,
    userDetails: {},
    isUserPrivate: true
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_LOGIN: {
            return {
                ...state,
                isUserLoggedIn: action.payload
            }
        }
        case SET_USER_DETAILS: {
            return {
                ...state,
                userDetails: action.payload
            }
        }
        case SET_USER_PRIVATE_STATUS: {
            return {
                ...state,
                isUserPrivate: action.payload
            }
        }
        default: return state
    }
}

export default userReducer;
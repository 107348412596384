import UserOnboardHome from './UserOnboardHome';
import { connect } from 'react-redux'
import { updateUserDetails } from '../../../redux/actions/userActions'

const mapStateToProps = state => {
    return {
        firstname: state.userSignup.firstName,
        userDetails: state.user.userDetails
    }
}
const mapDispatchToProps = dispatch => ({
    updateUserDetails: value => dispatch(updateUserDetails(value))
})

export default connect(mapStateToProps,mapDispatchToProps)(UserOnboardHome);
import axios from 'axios'
import get from 'lodash/get'
import { getCognitoIdToken } from './utilityMethods';
import { BASE_URL } from "../constants/google-login-urls";

export const getUserBalance = async (userId) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/cost/account/user/balance/${userId}`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}
export const getUserTransactions = async (userId) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/cost/account/user/transactions/${userId}`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const addUserCredit = async (body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/cost/invoice`;
    const res = await axios.post(url, body, { headers: header });
    return res?.data || {};
}
export const updateCreditAmount = async (body) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/cost/invoice/adjustment`;
    const res = await axios.post(url, body, { headers: header });
    return res?.data || {};
}
export const getWorkspaceTransactions = async (workspaceId) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/cost/account/workspace/transactions/${workspaceId}`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}

export const getWorkspaceBalance = async (workspaceId) => {
    const token = await getCognitoIdToken();
    const header = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
    const url = `${BASE_URL}/cost/account/workspace/balance/${workspaceId}`;
    const res = await axios.get(url, { headers: header });
    return res?.data || [];
}
import { SET_PROJECT_ADMIN_FIRST_NAME, SET_PROJECT_ADMIN_LAST_NAME, SET_PROJECT_ADMIN_MIDDLE_NAME, SET_PROJECT_ADMIN_CREDENTIALS, 
    SET_PROJECT_ADMIN_INSTITUTE_NAME, SET_PROJECT_ADMIN_INSTITUTE_EMAIL, SET_PROJECT_ADMIN_INSTITUTE_STREET_ADDRESS, SET_PROJECT_ADMIN_INSTITUTE_CITY,
    SET_PROJECT_ADMIN_INSTITUTE_STATE, SET_PROJECT_ADMIN_INSTITUTE_ZIP_CODE, SET_PROJECT_ADMIN_INSTITUTE_COUNTRY,
    SET_PROJECT_ADMINISTRATOR_FIELDS, RESET_PROJECT_ADMINISTRATOR_FIELDS
} from '../action-types'
import get from 'lodash/get'
const initialState = {
    projectAdminFirstName: '',
    projectAdminLastName: '',
    projectAdminMiddleName: '',
    projectAdminCredentials: '',
    projectAdminInstituteName: '',
    projectAdminInstituteEmail: '',
    projectAdminInstituteStreetAddress: '',
    projectAdminInstituteCity: '',
    projectAdminInstituteState: '',
    projectAdminInstituteZipCode: '',
    projectAdminInstituteCountry: ''
}

const projectAdminReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROJECT_ADMIN_FIRST_NAME: {
            return {
                ...state,
                projectAdminFirstName: action.payload
            }
        }
        case SET_PROJECT_ADMIN_LAST_NAME: {
            return {
                ...state,
                projectAdminLastName: action.payload
            }
        }
        case SET_PROJECT_ADMIN_MIDDLE_NAME: {
            return {
                ...state,
                projectAdminMiddleName: action.payload
            }
        }
        case SET_PROJECT_ADMIN_CREDENTIALS: {
            return {
                ...state,
                projectAdminCredentials: action.payload
            }
        }
        case SET_PROJECT_ADMIN_INSTITUTE_NAME: {
            return {
                ...state,
                projectAdminInstituteName: action.payload
            }
        }
        case SET_PROJECT_ADMIN_INSTITUTE_EMAIL: {
            return {
                ...state,
                projectAdminInstituteEmail: action.payload
            }
        }
        case SET_PROJECT_ADMIN_INSTITUTE_STREET_ADDRESS: {
            return {
                ...state,
                projectAdminInstituteStreetAddress: action.payload
            }
        }
        case SET_PROJECT_ADMIN_INSTITUTE_CITY: {
            return {
                ...state,
                projectAdminInstituteCity: action.payload
            }
        }
        case SET_PROJECT_ADMIN_INSTITUTE_STATE: {
            return {
                ...state,
                projectAdminInstituteState: action.payload
            }
        }
        case SET_PROJECT_ADMIN_INSTITUTE_ZIP_CODE: {
            return {
                ...state,
                projectAdminInstituteZipCode: action.payload
            }
        }
        case SET_PROJECT_ADMIN_INSTITUTE_COUNTRY: {
            return {
                ...state,
                projectAdminInstituteCountry: action.payload
            }
        }
        case SET_PROJECT_ADMINISTRATOR_FIELDS: {
            const data = action.payload;
            return {
                projectAdminFirstName: get(data, 'firstName', ''),
                projectAdminLastName: get(data, 'lastName', ''),
                projectAdminMiddleName: get(data, 'middleName', ''),
                projectAdminCredentials: get(data, 'credentials', ''),
                projectAdminInstituteName: get(data, 'institutionDetails.institutionName', ''),
                projectAdminInstituteEmail: get(data, 'institutionDetails.institutionalEmail', ''),
                projectAdminInstituteStreetAddress: get(data, 'institutionDetails.institutionAddress.streetAddress', ''),
                projectAdminInstituteCity:  get(data, 'institutionDetails.institutionAddress.city', ''),
                projectAdminInstituteState:  get(data, 'institutionDetails.institutionAddress.state', ''),
                projectAdminInstituteZipCode:  get(data, 'institutionDetails.institutionAddress.zipCode', ''),
                projectAdminInstituteCountry:  get(data, 'institutionDetails.institutionAddress.country', '')
            }
        }
        case RESET_PROJECT_ADMINISTRATOR_FIELDS:{
         return {   
            projectAdminFirstName: '',
            projectAdminLastName: '',
            projectAdminMiddleName: '',
            projectAdminCredentials: '',
            projectAdminInstituteName: '',
            projectAdminInstituteEmail: '',
            projectAdminInstituteStreetAddress: '',
            projectAdminInstituteCity: '',
            projectAdminInstituteState: '',
            projectAdminInstituteZipCode: '',
            projectAdminInstituteCountry: ''}
        }
        default: return state
    }
}

export default projectAdminReducer;
import React, { useEffect } from 'react'
import { Redirect } from '@reach/router';
import { isEmpty } from 'lodash';

import { sendPagedViewedForHelpScout, updateGAEventsPageTitleOnPageChange } from '../api/generic-api';
import CustomLoader from '../components/commons/custom-loader'
import SessionTimeoutCheckerHook from '../utils/session-timeout-checker.hook'

const AuthorisedRoute = (props) => {
    let { component: Component, isUserAuthorised } = props;
    
    SessionTimeoutCheckerHook();

    useEffect(() => {
        let pageTitle = '';
        const pagePath = props?.path || '';
        switch(pagePath){
            case '/': pageTitle = 'explore_landing'; break;
            case '/dashboard' : pageTitle = 'dashboard'; break;
            case '/users' : pageTitle = 'user_search'; break;
            case '/my-profile': pageTitle = 'user_profile'; break;
            case '/edit-profile/*id' : pageTitle = 'edit_profile'; break;
            case '/change-password' : pageTitle = 'change_password'; break;
            case '/change-notification' : pageTitle = 'notification'; break;
            case '/view-all-notifications' : pageTitle = 'notification'; break;
            case '/admin/user-profile/:id/*' : pageTitle = 'admin_user_profile'; break;
            case '/my-profile/request-access' : pageTitle = 'user_profile'; break;
            case '/my-profile/request-advance-compute' : pageTitle = 'user_profile'; break;
            case '/explore/*': {
                switch(props.location.pathname){
                    case '/explore/data/graphical-data' : pageTitle = 'explore_data'; break;
                    case '/explore/data/files': pageTitle = 'explore_file'; break;
                    case '/explore/tools': pageTitle = 'tools'; break;
                    default: break;
                }
                break;
            }
            case '/explore/analysis/*': pageTitle = 'explore_data'; break;
            case '/explore/tools/workspace/*' : pageTitle = 'workspace'; break;
            case '/explore/project-gallery' : pageTitle = 'project_gallery'; break;
            case '/explore/data-model' : pageTitle = 'data_model'; break;
            case '/my-stuff/*' : pageTitle = 'my_stuff'; break;
            case '/my-stuff-analysis/:id' : pageTitle = 'my_stuff'; break;
            case '/my-stuff/form-status/*id' : pageTitle = 'my_stuff'; break;
            case '/my-stuff/data-contribute-agreement/:id/:requesterEmail' : pageTitle = 'dca'; break;
            case '/my-stuff/data-contribute-agreement' : pageTitle = 'dca'; break;
            case '/my-stuff/data-inventory-form/:id/:requesterEmail' : pageTitle = 'dif'; break;
            case '/my-stuff/data-inventory-form' : pageTitle = 'dif'; break;
            case '/my-uploads/*' : pageTitle = 'my_uploads'; break;
            case '/forgot-password' : pageTitle = 'forgot_password'; break;
            case '/my-profile/tell-us-more/:id' : pageTitle = 'community_onboarding'; break;
            default: break
        }
        if(`${pagePath}`.includes('/explore/community')){
            pageTitle = 'community'
        }
        if(`${props?.location?.pathname}`.includes('/my-uploads')){
            pageTitle = 'my_uploads'
        }
        if(!isEmpty(pageTitle)){
            updateGAEventsPageTitleOnPageChange(pageTitle);
            sendPagedViewedForHelpScout(pageTitle);
        }
    }, [props?.location?.pathname])
   

    return isUserAuthorised === undefined ? <CustomLoader /> :
        (isUserAuthorised ? <Component {...props} /> : <Redirect from="" to="/" noThrow /> );
}

export default AuthorisedRoute;
import React, { useState, useEffect } from 'react';
import Classes from './index.module.css';
import Grid from '@mui/material/Grid'
import RadioGroup from '@mui/material/RadioGroup'
import MuiRadio from '@mui/material/Radio'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import DotChartIcon from '../../../assets/images/dotChartIcon.png'
import BarChartIcon from '../../../assets/images/bar-chart.png'
import withStyles from '@mui/styles/withStyles';
import DropDown from '../../commons/dropdown';
import { getDataModelSchema } from '../../../api/data-model-api'

const FormControlLabel = withStyles(theme => ({
  root: {
    margin: 0
  },
  label: {
    margin: '0px 0px 0px 8px',
    fontFamily: "Raleway",
    fontSize: '0.875rem',

  }
}))(MuiFormControlLabel);

const Radio = withStyles(theme => ({
  colorSecondary: {
    color: '#180E5B !important'
  }
}))(MuiRadio);

const AnalyzeAttributesSelectionCard = props => {

  const {
    updateField = () => { },
    xAxisValues = [],
    yAxisValues = [],
    currentState = {},
  } = props;

  const [dictionary, setDictionary] = useState(null)

  const getOnLoadData = async () => {
    try {
      const resp = await getDataModelSchema();
      setDictionary(resp)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getOnLoadData()
  }, [])


  const activeIconClass = value => {
    if (currentState?.graphType === value) {
      return `${Classes.chartIconImageContainer} ${Classes.activeChartIcon}`
    } else {
      return `${Classes.chartIconImageContainer}`
    }
  }

  const onXAxisChange = value => {
    updateField("xAxis", value, currentState);
  }

  const onYAxisChange = value => {
    updateField("yAxis", value, currentState);
  }

  const handleGraphTypeChange = event => {
    const value = event?.target?.value || event
    updateField("graphType", value, currentState)
  }

  const getAxisAliasing = (axisValues) => {
    let aliasing = {}
    for(let item of axisValues){
      aliasing[item.value] = item.label
    };
    return aliasing;
  }
  const selectedXValue = currentState?.xAxis?.toString().charAt(0).toUpperCase() + currentState?.xAxis?.toString().slice(1);
  const selectedYValue = currentState?.yAxis?.toString().charAt(0).toUpperCase() + currentState?.yAxis?.toString().slice(1);

  return (
    <div className={Classes.analyzeAttributeContainer}>
      <p className={Classes.cardTitles}>What variables would you like to analyze from the cohort?</p>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <label id='cvb-analyzeAttributesSelection-xAxis-label' htmlFor='xAxis'>X Axis</label>
          <DropDown
            values={xAxisValues.map(({ value }) => value)}
            handleFilterStateChange={onXAxisChange}
            selectedFilter={selectedXValue}
            placeholder={'Select'}
            labelAliasing={getAxisAliasing(xAxisValues)}
            parseText={true}
          />
        </Grid>
        <Grid item xs={12}>
          <label id='cvb-analyzeAttributesSelection-yAxis-label' htmlFor='yAxis'>Y Axis</label>
          <DropDown
            values={yAxisValues.map(({ value }) => value)}
            handleFilterStateChange={onYAxisChange}
            selectedFilter={selectedYValue}
            placeholder={'Select'}
            labelAliasing={getAxisAliasing(yAxisValues)}
            parseText
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default AnalyzeAttributesSelectionCard
import React, { useEffect, useState, useRef } from 'react'
import Step from '@mui/material/Step';
import MuiStepLabel from '@mui/material/StepLabel';
import StepIcon from '@mui/material/StepIcon';
import Stepper from '@mui/material/Stepper';
import StepConnector from '@mui/material/StepConnector'
import MobileStepper from '@mui/material/MobileStepper';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Classes from './index.module.css'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { navigate } from '@reach/router';
import findIndex from 'lodash/findIndex'
import DIFInstitution from '../../controls/dif-institution'
import DIFPrimaryInvestigator from '../../controls/dif-primary-investigator'
import DIFDataSharing from '../../controls/dif-data-sharing'
import DIFOtherPersonal from '../../controls/dif-other-personal'
import DIFTimeline from '../../controls/dif-timeline'
import DIFIndividualDataset from '../../controls/dif-individual-dataset'
import BackButtonArrow from '../../../assets/icons/left_arrow.png'
import { postDIFForm } from '../../../api/dif-form-api';
import moment, { isMoment } from 'moment';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get'
import { getDIFFormData } from '../../../api/dif-form-api'
import CustomLoader from '../../commons/custom-loader';
import map from 'lodash/map'
import Modal from '../../commons/modal';
import { DIF_CONSENT_KEY, DIF_FORM_COMPLETED_STATUS, DIF_FORM_STEPS, DCA_UPLOAD_LOGO_KEY, DIF_STUDY_LOGO } from '../../../constants/strings';
import { validateEmailAddress, scrollToGivenTop, validatePhoneNumber } from '../../../utils/input-validations';
import DIFStudy from '../../controls/dif-study';
import isDate from 'lodash/isDate';
import { getUserProfile } from '../../../api/profile-api';
import { Auth } from 'aws-amplify';
import useScrollToTop from '../../commons/scroll-top';
import { sendGAEvents } from '../../../api/generic-api';

const ColorlibConnector = withStyles({
    vertical: {
        padding: 0
    },
    active: {
        '& $line': {
            backgroundColor:
                '#23116C',
        },
    },
    completed: {
        '& $line': {
            backgroundColor:
                '#23116C',
        },
    },
    line: {
        height: 3,
        width: 2,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
        padding: 0
    },
})(StepConnector);

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        backgroundColor: 'inherit',
        display: "flex"
    },
    float: {
        flexGrow: 1,
        marginRight: "15px"
    },
    iconContainer: {
        transform: 'scale(2)',
        marginRight: 10,
    },
    paper: {
        textAlign: 'center'
    },
});


// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//       display: "flex"
//     },
//     float:{
//         flexGrow: 1,
//         marginRight: "15px"
//     },
//     clearfix:{
//         clear:'both'
//     },
//     paper: {
//       padding: theme.spacing(2),
//       textAlign: 'center',
//       color: theme.palette.text.secondary,
//     },
//   }));

const StepLabel = withStyles({
    label: {
        textTransform: 'uppercase',
        fontSize: '14px !important',
        fontFamily: "Raleway",
        fontWeight: '700 !important',
        marginLeft: '16px !important',
        color: 'rgb(204, 204, 204)',
    },
    active: {
        color: 'rgb(204, 204, 204) !important',
    },
    complete: {
        color: '#031235',
    }
})(MuiStepLabel)

const DataInventoryForm = props => {
    const {
        difStage,
        difInstitutionInstitutionName,
        difInstitutionStreetAddress,
        difInstitutionCountry,
        difInstitutionState,
        difInstitutionCity,
        difInstitutionMunicipality,
        difInstitutionZipCode,

        difStudyName,
        difStudyObjective,
        difStudyDescription,
        difStudyLogoFile,
        difStudyDesign,
        difStudyNumberOfGrants,
        difStudyDurationOfMoths,
        difStudyLocation,
        difStudyPublications,
        difStudyGrantInfo,
        logoS3Key,
        difStudyOptIn,

        difPIFirstName,
        difPILastName,
        difPIPhoneNumber,
        difPIEmail,
        difPIDepartmentName,
        difPIStreetAddress,
        difPICountry,
        difPIState,
        difPICity,
        difPIMunicipality,
        difPIZipCode,

        difOPWillOtherUpload,
        difOPAddedUsers,

        difDataSharingIsNewDataSet,
        difDataSharingDatasetName,
        difDataSharingPermissionToShare,
        difDataSharingIRBNumber,
        difDataSharingDocumentFile,
        difDataSharingIsProtectedData,
        difDataSharingIsDeIdentity,
        difDataSharingConsentDocument,

        difTimelineIsDeadlineToUpload,
        difTimelineIsDeadlineToDataAccess,
        difTimelineIsDataEmbargoed,
        difTimelineSubmissionDate,
        difTimelineDataAccessDeadlineDate,
        difTimelineEmbargoedDate,
        difTimelineEmbargoedDesc,

        individualDatasets,

        id, 
        requesterEmail,
        requesterSub,
        difDatasetActiveIndex,

        setDifStage,
        setInstitutionFormData,
        setPrimaryInvestigatorData,
        setDataSharingData,
        setOtherPersonnelData,
        setTimelineData,
        setIndividualDatasetData,
        updateSnackBar,
        setStudyData,
        setDatasetCurrentActiveTab,

        resetInstitutionFormData,
        resetPrimaryInvestigatorData,
        resetDataSharingData,
        resetOtherPersonnelData,
        resetTimelineData,
        resetIndividualDatasetData,
        setCurrentDIFFormStage,
        currentDIFFormStage
    } = props;
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [ currentRequestId, setCurrentRequestID ] = useState(id);
    const [ isFormLoading, setIsFormLoading ] = useState(false);
    const [ submitDIFModal, setSubmitDIFModal ] = useState(false);
    const [ saveAsDraftModal, setSaveAsDraftModal ] = useState(false);
    const [ cancelDIFModal, setCancelDIFModal ] = useState(false);
    const [ currentFormStage, setCurrentFormStage ] = useState('');
    const [ userDetails, setUserDetails ] = useState({});
    const [isFormUpdating, setIsFormUpdating] = useState(false);
    const isDashboard = get(props, 'location.state.dashboard', false);

    const getDatasetDataToSet = (datasetData) => {
        const datasetDataToSend = [];
        map(datasetData, dataset => {
            const dataUploadDate = !isEmpty(dataset['data_upload_completed_date']) ? moment.utc(dataset['data_upload_completed_date']).toDate(): '';
            const curatorDate = !isEmpty(dataset['curation_date']) ? moment.utc(dataset['curation_date']).toDate() : '';
            datasetDataToSend.push({
                ...dataset,
                ['curation_date']: curatorDate,
                ['data_upload_completed_date']: dataUploadDate
            })
        });
        return datasetDataToSend;
    }
    const loadInitialData = async () => {
        try{
            if (!isEmpty(currentRequestId) && !isEmpty(requesterSub)) {
                setIsFormLoading(true);
                const res = await getDIFFormData(requesterSub, currentRequestId);
                if (res) {
                    const currentStage = get(res, 'stage', '');
                    setCurrentFormStage(currentStage);
                    setIsFormLoading(false);
                    const difData = get(res, 'dif', {});
                    const institutionFormData = get(difData, 'institution', {});
                    const primaryInvestigatorData = get(difData, 'primary_investigator', {});
                    const dataSharingData = get(difData, 'data_sharing', {});
                    const otherPersonnelData = get(difData, 'other_personnel', {});
                    const timelineData = get(difData, 'timeline', {});
                    const individualDatasetData = get(difData, 'individual_datasets', []);
                    const studyData = get(difData, 'study', {});

                    const datasetDataToSet = getDatasetDataToSet(individualDatasetData);
                    setInstitutionFormData(institutionFormData);
                    setPrimaryInvestigatorData(primaryInvestigatorData);
                    setDataSharingData(dataSharingData);
                    setOtherPersonnelData(otherPersonnelData);
                    setTimelineData(timelineData);

                    setIndividualDatasetData(datasetDataToSet);
                    setCurrentDIFFormStage(currentStage);
                    setStudyData(studyData);
                    setDatasetCurrentActiveTab(0);
                }
            }else{
                setDifStage(1);
                setCurrentDIFFormStage(1);
            }
        }catch(error){
            console.log('error in getting dif data', error)
        }
    }    
    useEffect(() => {
       loadInitialData();
       getUserData();
    }, [])

    const getUserData = async () => {
        try {
            const userValues = await Auth.currentUserInfo();
            const userInfo = await getUserProfile(userValues?.attributes?.sub)
            setUserDetails(userInfo?.user_details || {})
        }
        catch (error) {
            console.log('error in getting user details')
        }
    };
    
    useScrollToTop();

    const getCurrentDate = () => {
        const day = new Date().getDate();
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();
        return `${year}-${month}-${day}`
    }
    const muClasses = useStyles();

    const CustomStepIcon = (props) => {
        const bgClass = props.index >= difStage ? Classes.incompleteSteps : Classes.currentStep;
        const index = findIndex(DIF_FORM_STEPS, (step) => step === props.label) + 1;
        return (
            <div className={`${Classes.stepIconContainer} ${bgClass}`}>
                <span>{index}</span>
            </div>
        )
    }

    const onCancelClick = () => {
        if (currentDIFFormStage !== DIF_FORM_COMPLETED_STATUS) {
            setCancelDIFModal(true);
        } else {
            if (!isDashboard) {
                navigate('/my-stuff')
            }else{
                navigate('/dashboard')
            }
        }
    }
    const validOPForm = () => {
        let isFormValid = false;
        map(difOPAddedUsers, (usersData)=>{
            for(let key in usersData){
                const data = usersData[key];
                const isEmptyField = data === null ? false : !isEmpty(data);
                const isEmailValid = key === 'institutional_email' ? validateEmailAddress(usersData[key]) : true;
                if(isEmptyField && isEmailValid){
                    isFormValid = true;
                }else{
                    isFormValid = false;
                    break;
                }
            }
        })
        return isFormValid;
    }
    const scrollToDatasetErrorFields = (datasetIndex) => {
        const ownerEmail = get(individualDatasets,`[${datasetIndex}].owner_email`, '');
        const curatorDate = get(individualDatasets, `[${datasetIndex}].curation_date`, '');
        const curatorEmail = get(individualDatasets, `[${datasetIndex}].curator.email`, '');
        const datasetName = get(individualDatasets, `[${datasetIndex}].dataset_name`, '');

        const isCuratorDateRequired = (get(individualDatasets[datasetIndex], 'do_you_need_brain_commons_curation_services', '') === 'no' &&
                                get(individualDatasets[datasetIndex], 'is_your_data_curated', '') === 'yes');
        if(!isEmpty(ownerEmail)? !validateEmailAddress(ownerEmail) : false){
            const ownerNameElement = document.getElementById(`ownerEmail${datasetIndex}`);
            scrollToGivenTop(ownerNameElement);
        }
        if((!isEmpty(curatorEmail) && isCuratorDateRequired)? !validateEmailAddress(curatorEmail) : false){
            const ownerNameElement = document.getElementById(`curatorEmail${datasetIndex}`);
            scrollToGivenTop(ownerNameElement);
        }
        if(isCuratorDateRequired ? !isDate(curatorDate) : false){
            const curatorDateElement = document.getElementById(`curatorDate${datasetIndex}`);
            scrollToGivenTop(curatorDateElement);
        }
        if(isEmpty(datasetName)){
            const datasetNameElement = document.getElementById(`datasetName${datasetIndex}`);
            scrollToGivenTop(datasetNameElement);
        }
    }
    const validateIndividualDatasetForm = () => {
        let isValidForm = false;
        for(let index = 0; index< individualDatasets.length; index++ ){
            const datasetName = get(individualDatasets[index], 'dataset_name', '');
            const ownerEmail = get(individualDatasets[index], 'owner_email', '');
            const curatorEmail = get(individualDatasets[index], 'curator.email', '');
            const isCuratorDateRequired = (get(individualDatasets[index], 'do_you_need_brain_commons_curation_services', '') === 'no' &&
                                get(individualDatasets[index], 'is_your_data_curated', '') === 'yes');
            const curatorDate =  get(individualDatasets[index], 'curation_date', '');
            if (((!isEmpty(ownerEmail) && isCuratorDateRequired) ? validateEmailAddress(ownerEmail) : true) &&
                (!isEmpty(curatorEmail) ? validateEmailAddress(curatorEmail) : true) &&
                (isCuratorDateRequired ? isDate(curatorDate) : true) &&
                !isEmpty(datasetName)
            ) {
                isValidForm = true;
            } else {
                isValidForm = false;
                setDatasetCurrentActiveTab(index);
                scrollToDatasetErrorFields(index);
                break;
            }
        }
        // map(individualDatasets, (dataset, index)=>{
         
        // });
        // debugger
        return isValidForm;
    }
    const validPublications = () => {
        let isFormValid = false;
        if (!isEmpty(difStudyPublications)) {
            map(difStudyPublications, (usersData, index) => {
                for (let key in usersData) {
                    if (!isEmpty(usersData[key])) {
                        isFormValid = true;
                    } else {
                        isFormValid = false;
                        break;
                    }
                }
            })
        } else {
            isFormValid = true;
        }
        return isFormValid;
    }
    const validateDIFForm = () => {
        switch (difStage) {
            case 1: return (!isEmpty(difInstitutionInstitutionName) && !isEmpty(difInstitutionStreetAddress) && !isEmpty(difInstitutionCountry) &&
                !isEmpty(difInstitutionState) && !isEmpty(difInstitutionCity) &&
                !isEmpty(difInstitutionZipCode));
            case 2: return (!isEmpty(difStudyName) && !isEmpty(difStudyObjective) && !isEmpty(difStudyDescription) && !isEmpty(difStudyDesign)
                            && !isEmpty(difStudyDurationOfMoths) && !isEmpty(difStudyLocation) && !isEmpty(difStudyNumberOfGrants)
                            && !isEmpty(get(difStudyGrantInfo, `[0]['agency_name']`, [])) && !isEmpty(get(difStudyGrantInfo, `[0]['agency_number']`, []))
                            && validPublications() );
            case 3: return (!isEmpty(difPIFirstName) && !isEmpty(difPILastName) && validatePhoneNumber(difPIPhoneNumber) &&
                validateEmailAddress(difPIEmail) && !isEmpty(difPIDepartmentName) && !isEmpty(difPIStreetAddress) &&
                !isEmpty(difPICountry) && !isEmpty(difPIState) && !isEmpty(difPICity) && !isEmpty(difPIZipCode));

            case 4: return (!isEmpty(difDataSharingIsNewDataSet) &&
                (difDataSharingIsNewDataSet === 'no' ? !isEmpty(difDataSharingDatasetName) : true)
                && !isEmpty(difDataSharingPermissionToShare) &&
                !isEmpty(difDataSharingIsProtectedData) &&
                (difDataSharingIsProtectedData === 'yes' ? !isEmpty(difDataSharingIsDeIdentity) : true)
            );
            case 5: return (!isEmpty(difOPWillOtherUpload) &&
               ( difOPWillOtherUpload === 'no' ? validOPForm() : true)
            )
            case 6: return (!isEmpty(difTimelineIsDeadlineToUpload) &&
                ((difTimelineIsDeadlineToUpload === 'yes' ? moment.isDate(difTimelineSubmissionDate) : true) &&
                !isEmpty(difTimelineIsDataEmbargoed) &&
                (difTimelineIsDeadlineToDataAccess === 'yes' ? moment.isDate(difTimelineDataAccessDeadlineDate) : true) &&
                !isEmpty(difTimelineIsDataEmbargoed) &&
                (difTimelineIsDataEmbargoed === 'yes' ? (moment.isDate(difTimelineEmbargoedDate) && !isEmpty(difTimelineEmbargoedDesc)) : true))
            )
            case 7: {
                const isValid = validateIndividualDatasetForm(); 
                return isValid 
            };
            default: return false;
        }
    }

    const scrollToErrorFields = () => {
        switch (difStage) {
            case 1: 
                if(isEmpty(difInstitutionInstitutionName)) {
                    const targetDIFElement = document.getElementById('institutionName');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(isEmpty(difInstitutionCountry)) {
                    const targetDIFElement = document.getElementById('streetAddress');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(isEmpty(difInstitutionStreetAddress)) {
                    const targetDIFElement = document.getElementById('streetAddress');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(isEmpty(difInstitutionState) || isEmpty(difInstitutionCity)) {
                    const targetDIFElement = document.getElementById('state');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(isEmpty(difInstitutionZipCode)) {
                    const targetDIFElement = document.getElementById('zipCode');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
            case 2: {
                if(isEmpty(difStudyName) || isEmpty(difStudyObjective)) {
                    const targetDIFElement = document.getElementById('studyName');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if (isEmpty(difStudyDescription)) {
                    const targetDIFElement = document.getElementById('descriptionStudy');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(isEmpty(difStudyDesign)){
                    const targetDIFElement = document.getElementById('studyDesign');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(isEmpty(difStudyLocation)){
                    const targetDIFElement = document.getElementById('locationOfStudy');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(isEmpty(difStudyDurationOfMoths)){
                    const targetDIFElement = document.getElementById('durationOfStudy');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(isEmpty(difStudyNumberOfGrants) || isEmpty(difStudyGrantInfo)){
                    const targetDIFElement = document.getElementById('noOfGrants');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(isEmpty(difStudyPublications?.publication_name) || isEmpty(difStudyPublications?.publication_url)){
                    const targetDIFElement = document.getElementById('publicationName');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
            }
            case 3: {
                if(isEmpty(difPIFirstName) || isEmpty(difPILastName) || isEmpty(difPIEmail) || isEmpty(difPIPhoneNumber)
                || isEmpty(difPIDepartmentName)){
                    const targetDIFElement = document.getElementById('firstName');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(isEmpty(difPIStreetAddress) || isEmpty(difPICountry) || isEmpty(difPIState) || isEmpty(difPICity)
                || isEmpty(difPIZipCode)){
                    const targetDIFElement = document.getElementById('streetAddress');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
            }
            case 4: {
                if(isEmpty(difDataSharingIsNewDataSet) || isEmpty(difDataSharingDatasetName)){
                    const targetDIFElement = document.getElementById('newDataSet1');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(isEmpty(difDataSharingPermissionToShare) || isEmpty(difDataSharingIRBNumber)){
                    const targetDIFElement = document.getElementById('permissionToShare1');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(isEmpty(difDataSharingIsProtectedData) || isEmpty(difDataSharingIsDeIdentity)){
                    const targetDIFElement = document.getElementById('containProtectedData1');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
            }
            case 5: {
                if(difOPWillOtherUpload === "no"){
                    map(difOPAddedUsers, (usersData, id)=>{
                        if(isEmpty(usersData?.first_name) || isEmpty(usersData?.last_name) || isEmpty(usersData?.title)
                        || isEmpty(usersData?.institutional_email)){
                            const targetDIFElement = document.getElementById(`firstName${id}`);
                            scrollToGivenTop(targetDIFElement);
                            return;
                        }
                    })
                    return;
                    
                }

            }
            case 6: {
                if(isEmpty(difTimelineIsDeadlineToUpload)){
                    const targetDIFElement = document.getElementById('isDeadlineToUpload1');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(difTimelineIsDeadlineToUpload === "yes" && isEmpty(difTimelineSubmissionDate)){
                    const targetDIFElement = document.getElementById('isDeadlineToUpload1');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(isEmpty(difTimelineIsDeadlineToDataAccess)){
                    const targetDIFElement = document.getElementById('isDataAccessibleDeadline1');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(difTimelineIsDeadlineToDataAccess === "yes" && isEmpty(difTimelineDataAccessDeadlineDate)){
                    const targetDIFElement = document.getElementById('isDataAccessibleDeadline1');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
                if(isEmpty(difTimelineIsDataEmbargoed) || isEmpty(difTimelineEmbargoedDate) ||
                isEmpty(difTimelineEmbargoedDesc)){
                    const targetDIFElement = document.getElementById('isDataEmbargoed1Desc');
                    scrollToGivenTop(targetDIFElement);
                    return;
                }
            }
            default: return '';
        }
    }
    
    const getIndividualDatasetData = () => {
        const datasetFormToSend = [];
        map(individualDatasets, (datasetForm, index) => {
            const isCuratorDateRequired = (get(datasetForm, 'do_you_need_brain_commons_curation_services', '') === 'no' &&
            get(datasetForm, 'is_your_data_curated', '') === 'yes');

            const curatedDate = isCuratorDateRequired ? (moment.isDate(datasetForm['curation_date']) ? moment.utc(datasetForm['curation_date']).format() : '') : '';
            const dataUploadDate  = moment.isDate(datasetForm['data_upload_completed_date']) ? moment.utc(datasetForm['data_upload_completed_date']).format() : '';
            if(isEmpty(datasetForm['type_of_data'])){
                datasetFormToSend.push(
                    {
                        ...datasetForm,
                        'clinical': {
                            "imaging": "",
                            "imaging_other": "",
                            "genomic": "",
                            "genomic_other": "",
                            "proteomic": "",
                            "transcriptomic": "",
                            "transcriptomic_other": "",
                            "other": "",
                            "tissueSample": "",
                            "metabolomic": "",
                            "disease_area": "",
                            "disease_area_other": "",
                            "behavioral_assessment": "",
                            "wearable_sensors": "",
                            "derived_sensor_data": ""
                        },
                        'preclinical': {
                            "imaging": "",
                            "imaging_other": "",
                            "genomic": "",
                            "genomic_other": "",
                            "proteomic": "",
                            "transcriptomic": "",
                            "transcriptomic_other": "",
                            "other": "",
                            "tissueSample": "",
                            "modalSystem": "",
                            "modalSystem_other": "",
                            "diseaseFeature": "",
                            "metabolomic": "",
                            "behavioralTask": "",
                            "physiologicalOutcome": "",
                            "disease_area": "",
                            "disease_area_other": ""
                        },
                        'data_upload_completed_date': dataUploadDate,
                        'curation_date': curatedDate
                    })
            }
            else{
            if (datasetForm['type_of_data'] === 'clinical') {
                datasetFormToSend.push(
                    {
                        ...datasetForm,
                        'clinical': datasetForm['clinical'],
                        'preclinical': {
                            "imaging": "",
                            "imaging_other": "",
                            "genomic": "",
                            "genomic_other": "",
                            "proteomic": "",
                            "transcriptomic": "",
                            "transcriptomic_other": "",
                            "other": "",
                            "tissueSample": "",
                            "modalSystem": "",
                            "modalSystem_other": "",
                            "diseaseFeature": "",
                            "metabolomic": "",
                            "behavioralTask": "",
                            "physiologicalOutcome": "",
                            "disease_area": "",
                            "disease_area_other": ""
                        },
                        'data_upload_completed_date': dataUploadDate,
                        'curation_date': curatedDate
                    })
            } else{
                datasetFormToSend.push(
                    {
                        ...datasetForm,
                        'preclinical': datasetForm['preclinical'],
                        'clinical': {
                            "imaging": "",
                            "imaging_other": "",
                            "genomic": "",
                            "genomic_other": "",
                            "proteomic": "",
                            "transcriptomic": "",
                            "transcriptomic_other": "",
                            "other": "",
                            "tissueSample": "",
                            "metabolomic": "",
                            "disease_area": "",
                            "disease_area_other": "",
                            "behavioral_assessment": "",
                            "wearable_sensors": "",
                            "derived_sensor_data": ""
                        },
                        'data_upload_completed_date': dataUploadDate,
                        'curation_date': curatedDate
                    }
                    
                )
            }
        }
        })
    
        return datasetFormToSend;
    }
    const onFormSubmit = async (type) => {
        try{
        if (difStage < 8) {
           
            const dataSharingDatasetName = difDataSharingIsNewDataSet === 'no' ? difDataSharingDatasetName : '';
            const dataSharingIRBNumber = difDataSharingPermissionToShare === 'yes' ? difDataSharingIRBNumber : '';
            const dataSharingWillRemovePHI = difDataSharingIsProtectedData === 'yes' ? difDataSharingIsDeIdentity : '';
            const otherPersonalData=  difOPWillOtherUpload === 'no' ? difOPAddedUsers : []; 

            const submissionDate = difTimelineIsDeadlineToUpload === 'yes' ?( moment.isDate(difTimelineSubmissionDate) ? moment.utc(difTimelineSubmissionDate).format() : ''): '';
            const deadlineDate = difTimelineIsDeadlineToDataAccess === 'yes' ? (moment.isDate(difTimelineDataAccessDeadlineDate) ? moment.utc(difTimelineDataAccessDeadlineDate).format(): ''): '';
            const dataEmbargoedDate = difTimelineIsDataEmbargoed === 'yes' ? (moment.isDate(difTimelineEmbargoedDate) ? moment.utc(difTimelineEmbargoedDate).format(): ''): '';
            const dataEmbargoedDesc = difTimelineIsDataEmbargoed === 'yes' ? difTimelineEmbargoedDesc : '';
            const targetDIFElement = document.getElementById('DIFFormId');
            scrollToGivenTop(targetDIFElement);
            let stageToSend = '';
            const nextStage = difStage + 1;
            if(type === 'next'){
                if(difStage < 7){
                    if(Number(currentFormStage) < difStage+1){
                        stageToSend = `${difStage + 1}`;
                    }else{
                        stageToSend = currentFormStage;
                    }   
                }else{
                    if(difStage === 7){
                        stageToSend ='completed';
                    }
                }
            }
            if(type === 'draft'){
                if(Number(currentFormStage) < difStage){
                    stageToSend = `${difStage}`
                }else{
                    stageToSend = `${currentFormStage}`;
                }
            }
            setIsFormLoading(true);
            
            const consentFileKeyName = (!isEmpty(difDataSharingDocumentFile) || !isEmpty(difDataSharingConsentDocument)) ? currentRequestId+DIF_CONSENT_KEY : '';
            const logoFileKeyName = (!isEmpty(difStudyLogoFile)) ? `${currentRequestId}/${difStudyLogoFile[0]?.name}` 
                                    :(!isEmpty(logoS3Key) ? logoS3Key : '' ) ;
            const body = {
                "request_id": currentRequestId,
                "stage": stageToSend,
                "institution": {
                    "name": difInstitutionInstitutionName,
                    "street_address": difInstitutionStreetAddress,
                    "country": difInstitutionCountry,
                    "state": difInstitutionState,
                    "city": difInstitutionCity,
                    "municipality": difInstitutionMunicipality,
                    "zip_code": difInstitutionZipCode

                },
                "study": {
                    "study_name": difStudyName,
                    "study_objective": difStudyObjective,
                    "study_description": difStudyDescription,
                    "logo": logoFileKeyName,
                    "study_design": difStudyDesign,
                    "number_of_grants": difStudyNumberOfGrants,
                    "grants": difStudyGrantInfo,
                    "study_duration": difStudyDurationOfMoths,
                    "study_location": difStudyLocation,
                    "publications": difStudyPublications,
                    "is_opted_out_from_study_gallery": !difStudyOptIn
                  },
                "primary_investigator": {
                    "first_name": difPIFirstName,
                    "last_name": difPILastName,
                    "institutional_email": difPIEmail,
                    "phone": difPIPhoneNumber,
                    "department": difPIDepartmentName,
                    "street_address": difPIStreetAddress,
                    "country": difPICountry,
                    "state": difPIState,
                    "city": difPICity,
                    "municipality": difPIMunicipality,
                    "zip_code": difPIZipCode
                },
                "data_sharing": {
                    "contributing_new_datset": difDataSharingIsNewDataSet,
                    "dataset_name": dataSharingDatasetName,
                    "does_institution_have_permission": difDataSharingPermissionToShare,
                    "irb_number": dataSharingIRBNumber,
                    "consent_document": consentFileKeyName,
                    "contains_phi_pii": difDataSharingIsProtectedData,
                    "will_you_remove_phi_pii_prior": dataSharingWillRemovePHI
                },
                "other_personnel": {
                    "will_someone_upload_data": difOPWillOtherUpload,
                    "data_submission_supporters": otherPersonalData
                },
                "timeline": {
                    "is_there_data_submission_deadline": difTimelineIsDeadlineToUpload,
                    "submission_deadline": submissionDate,
                    "is_there_data_access_deadline": difTimelineIsDeadlineToDataAccess,
                    "data_access_deadline": deadlineDate,
                    "is_data_embargoed": difTimelineIsDataEmbargoed,
                    "data_embargoed_description": dataEmbargoedDesc,
                    "data_embargoed_till": dataEmbargoedDate,
                },
                "individual_datasets": getIndividualDatasetData()
            }
            setIsFormUpdating(true);
            const res = await postDIFForm(body);
            if(res){
                loadInitialData();
                const requestID = get(res, 'request_id', '');
                setIsFormSubmitted(false);
                setIsFormLoading(false);
                if(isEmpty(currentRequestId)){
                    setCurrentRequestID(requestID);
                }
                if (difStage < 7) {
                    updateSnackBar('Form Updated Successfully', 'success');
                    if( type === 'next'){
                        setDifStage(nextStage);
                    }
                }
                if(difStage === 7 && type === 'next'){
                    sendGAEvents('submit_data_inventory_form', 'data_inventory_form', 'Data inventory form submit');
                    const userSub = get(userDetails, 'username', '');
                    updateSnackBar('form submitted successfully', 'success');
                    navigate(`/my-stuff/form-status/${requestID}/${userSub}`, {state: { type: 'dif'}})
                }
                if (type === 'draft') {
                    updateSnackBar('Form Updated Successfully', 'success');
                    navigate('/my-stuff')
                }
            }else{
                setIsFormSubmitted(false);
                setIsFormLoading(false);
            }
        }
        }catch(error){
            console.log('error in posting dif form', error);
            updateSnackBar('Failed to update form', 'error');
        }finally{
            setIsFormUpdating(false);
        }
    }
    const backHandle = () => {
        if (difStage > 1) {
            const nextStage = difStage - 1;
            setDifStage(nextStage);
            setIsFormSubmitted(false);
        }
        else {
            if (!isDashboard) {
                navigate('/my-stuff')
            }else{
                navigate('/dashboard')
            }
        }
    }
    const onSaveDraftButtonClick = () => {
        if (currentDIFFormStage !== DIF_FORM_COMPLETED_STATUS) {
            setSaveAsDraftModal(true);
        }
    }
    const onNextButtonClick = () => {
        if (currentDIFFormStage !== DIF_FORM_COMPLETED_STATUS){
            setIsFormSubmitted(true);
            const isFormValid = validateDIFForm();
            if (isFormValid) {
                if (difStage < 7) {
                    onFormSubmit('next');
                }
                if (difStage === 7) {
                    setSubmitDIFModal(true);
                }
            }else{
                scrollToErrorFields();
            }
        }else{
            if(difStage < 7){
            setDifStage(difStage+1);
            }else if(difStage === 7){
                if (!isDashboard) {
                    navigate('/my-stuff')
                }else{
                    navigate('/dashboard')
                }
            }
        }
    }
    const closeDIFModal = () => {
        setSubmitDIFModal(false)
    }
    const onSubmitDIFConfirm = () =>{
        onFormSubmit('next');
        setSubmitDIFModal(false);
    }
    const continueEditingDraft = () => {
        setSaveAsDraftModal(false);
    }   
    const onSaveAsDraftSelect = () =>{
        onFormSubmit('draft');
        setSaveAsDraftModal(false);
    }
    const continueEditingClick = () =>{
        setCancelDIFModal(false);
    }
    const abortClick = () => {
        if (!isDashboard) {
            navigate('/my-stuff')
        }else{
            navigate('/dashboard')
        }
    }
    const SUBMIT_DIF_MODAL_PROPS = {
        modalTitle: 'Submit Data Inventory Form',
        modalContent: 'You are submitting the data inventory form. Are you sure you want to proceed ?',
        positiveButtonText: 'Submit',
        negativeButtonText: "Cancel",
        positiveButtonAction: onSubmitDIFConfirm,
        negativeButtonAction: closeDIFModal
    }
      
    const CANCEL_DIF_MODAL_PROPS = {
        modalTitle: 'Cancel Data Inventory Form',
        modalContent: 'You are aborting the data inventory form. Are you sure you want to proceed ?',
        positiveButtonText: 'Abort',
        negativeButtonText: "Continue Editing",
        positiveButtonAction: abortClick,
        negativeButtonAction: continueEditingClick
    }
      
    const SAVE_DRAFT_DIF_MODAL_PROPS = {
        modalTitle: 'Save as draft',
        modalContent: 'You are saving the data inventory form as draft. Are you sure you want to proceed ?',
        positiveButtonText: 'Save as draft',
        negativeButtonText: "Continue Editing",
        positiveButtonAction: onSaveAsDraftSelect,
        negativeButtonAction: continueEditingDraft
    }

    const buttonWrapperClass = currentDIFFormStage !== DIF_FORM_COMPLETED_STATUS ? `${Classes.buttonWrapper}` : `${Classes.buttonWrapper} ${Classes.readOnlyForm}`
    return (
        <div className={Classes.agreementContainer} id="cvb-DIFForm-mainContainer">
            <Modal
                open={submitDIFModal}
                handleClose={closeDIFModal}
                dialogProps={SUBMIT_DIF_MODAL_PROPS}
                disableBottomHorizontalDivider
            ></Modal>
            <Modal
                open={cancelDIFModal}
                handleClose={continueEditingClick}
                dialogProps={CANCEL_DIF_MODAL_PROPS}
                disableBottomHorizontalDivider
            ></Modal>
            <Modal
                open={saveAsDraftModal}
                handleClose={continueEditingDraft}
                dialogProps={SAVE_DRAFT_DIF_MODAL_PROPS}
                disableBottomHorizontalDivider
            ></Modal>
            <Grid container spacing={3} className={Classes.difWrapper}>
                <Grid item xs={12} className={Classes.difFormChildWrapper}>
                    <p onClick={backHandle} className={Classes.backButton} id="cvb-DIFForm-backButtonLink">
                        <span><img alt='back button arrow' src={BackButtonArrow} /></span>
                        <p className={Classes.backButtonText}>Back</p>
                    </p>
                    <div className={Classes.titleContainer} id="DIFFormId" >
                        <h2 id="cvb-DIFForm-formHeading">Data Inventory Form</h2>
                    </div>
                    <div className={Classes.contributedDatasetContainer} id="cvb-DIFForm-stepperContainer">
                        <div style={{ position: 'relative' }}>
                            <div className={muClasses.root}>
                                <Grid className={(muClasses.float, Classes.leftContainer)} xs={4}>
                                    <Stepper connector={<ColorlibConnector />} activeStep={difStage} className={Classes.stepperCont} orientation="vertical" steps={7} position="static">
                                        {DIF_FORM_STEPS.map((label, index) => (
                                            <Step key={label}>
                                                <StepLabel StepIconComponent={() => <CustomStepIcon label={label} index={index} />}>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Grid>
                                {isFormLoading ? <div className={Classes.customLoaderWrapper}><CustomLoader/></div> :
                                <Grid className={`${muClasses.float} ${Classes.individualFormWrapper}`} xs={9} id="cvb-DIFForm-formFieldsContainer">
                                   {
                                        difStage === 1 &&
                                        <DIFInstitution
                                            isFormSubmitted={isFormSubmitted}
                                        />
                                    }
                                    {
                                        difStage === 2 &&
                                        <DIFStudy currentRequestId={currentRequestId} isFormSubmitted={isFormSubmitted} />
                                    }
                                    {
                                        difStage === 3 &&
                                        <DIFPrimaryInvestigator isFormSubmitted={isFormSubmitted} />
                                    }
                                    {
                                        difStage === 4 &&
                                        <DIFDataSharing isDashboard={isDashboard} isFormSubmitted={isFormSubmitted} currentRequestId={currentRequestId}/>
                                    }
                                    {
                                        difStage === 5 &&
                                        <DIFOtherPersonal isFormSubmitted={isFormSubmitted} />
                                    }
                                    {
                                        difStage === 6 &&
                                        <DIFTimeline isFormSubmitted={isFormSubmitted} />
                                    }
                                    {
                                        difStage === 7 &&
                                        <DIFIndividualDataset currentRequestId={currentRequestId} isFormSubmitted={isFormSubmitted} />
                                    }
                                </Grid>
                                }
                            </div>
                        </div>

                    </div>
                </Grid>
                <div className={Classes.buttonContainer}>
                    <div className={buttonWrapperClass}>
                        <button className={`no-outlined-button small-size-button ${Classes.cancelButton}`} onClick={onCancelClick}>Cancel</button>
                        {currentDIFFormStage !== DIF_FORM_COMPLETED_STATUS && <button disabled={isFormUpdating} className={`transparent-button medium-size-button ${Classes.saveDraftButton} `} onClick={onSaveDraftButtonClick}>Save As Draft</button>}
                        {/* <button type="submit" className="button is-block is-info is-fullwidth" > Login </button> */}
                        <button disabled={isFormUpdating} id='difSubmitButton' type="submit" className={`medium-size-button solid-button ${Classes.nextButton}`} onClick={onNextButtonClick}>
                            { !(currentDIFFormStage === DIF_FORM_COMPLETED_STATUS && difStage === 7 )? (difStage === 7 ? 'Submit' : 'Next') : "Submitted"}
                        </button>
                    </div>
                </div>
            </Grid>

        </div>
    );
}

DataInventoryForm.defaultProps = {
    difStage: 1,
    difInstitutionInstitutionName: '',
    difInstitutionStreetAddress: '',
    difInstitutionCountry: '',
    difInstitutionState: '',
    difInstitutionCity: '',
    difInstitutionMunicipality: '',
    difInstitutionZipCode: '',

    difStudyName: '',
    difStudyObjective: '',
    difStudyDescription: '',
    difStudyLogoFile: '',
    difStudyDesign: '',
    difStudyNumberOfGrants: '',
    difStudyDurationOfMoths: '',
    difStudyLocation: '',
    difStudyPublications: [],
    difStudyGrantInfo: [],
    logoS3Key: '',
    difStudyOptIn: true,
    
    difPIFirstName: '',
    difPILastName: '',
    difPIPhoneNumber: '',
    difPIEmail: '',
    difPIDepartmentName: '',
    difPIStreetAddress: '',
    difPICountry: '',
    difPIState: '',
    difPICity: '',
    difPIMunicipality: '',
    difPIZipCode: '',

    difDataSharingIsNewDataSet: '',
    difDataSharingDatasetName: '',
    difDataSharingPermissionToShare: '',
    difDataSharingIRBNumber: '',
    difDataSharingDocumentFile: '',
    difDataSharingIsProtectedData: '',
    difDataSharingIsDeIdentity: '',
    difDataSharingConsentDocument: '',

    difOPWillOtherUpload: '',
    difOPAddedUsers: [],

    difTimelineIsDeadlineToUpload: '',
    difTimelineIsDeadlineToDataAccess: '',
    difTimelineIsDataEmbargoed: '',
    difTimelineSubmissionDate: null,
    difTimelineDataAccessDeadlineDate: null,
    difTimelineEmbargoedDate: null,
    difTimelineEmbargoedDesc: '',

    individualDatasets: [],

    id: '',
    difDatasetActiveIndex: 0,
    currentDIFFormStage: '',
    setDifStage: () => { },
    setInstitutionFormData: () => {},
    setPrimaryInvestigatorData: () => {},
    setDataSharingData: () => {},
    setOtherPersonnelData: () => {},
    setTimelineData: () => {},
    setIndividualDatasetData: () => {},
    updateSnackBar: () => {},
    setStudyData : () => {},
    resetInstitutionFormData: () => {},
    resetPrimaryInvestigatorData: () => {},
    resetDataSharingData: () => {},
    resetOtherPersonnelData: () => {},
    resetTimelineData: () => {},
    resetIndividualDatasetData: () => {},
    setCurrentDIFFormStage: () => {},
    setDatasetCurrentActiveTab: () => {},
}

export default DataInventoryForm;
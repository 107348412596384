import { SET_AGREEMENT_DATE, SET_CLIENT_NAME, SET_AGREEMENT_YEAR, SET_SIGNATORY_NAME, SET_SIGNATORY_TITLE,
    SET_NON_COMMERCIAL_CHECK, SET_SERVICES_CHECK, SET_AGREEMENT_CHECK, RESET_AGREEMENT_VALUES  } from '../action-types'

export const setServiceAgreementDate = (date) => {
    return {
        type: SET_AGREEMENT_DATE,
        payload: date
    }
}
export const setClientName = (value) => {
    return {
        type: SET_CLIENT_NAME,
        payload: value
    }
}
export const setAgreementYears = (value) => {
    return {
        type: SET_AGREEMENT_YEAR,
        payload: value
    }
}
export const setSignatoryName = (value) => {
    return {
        type: SET_SIGNATORY_NAME,
        payload: value
    }
}
export const setSignatoryTitle = (value) => {
    return {
        type: SET_SIGNATORY_TITLE,
        payload: value
    }
}
export const setNonCommercialCheck = (value) => {
    return {
        type: SET_NON_COMMERCIAL_CHECK,
        payload: value
    }
}
export const setServicesCheck = (value) => {
    return {
        type: SET_SERVICES_CHECK,
        payload: value
    }
}
export const setAgreementChecks = (value) => {
    return {
        type: SET_AGREEMENT_CHECK,
        payload: value
    }
}
export const resetAgreementValues = () => {
    return {
        type: RESET_AGREEMENT_VALUES,
        
    }
}
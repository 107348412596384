import React, { useState, useEffect } from 'react'
import SectionHeader from '../../commons/section-header'
import classes from './index.module.css'
import WorkspaceRightContainer from '../../controls/workspace-right-container'
import WorkspaceSharedFilesContainer from '../../controls/workspace-shared-files-container'
import WorkspaceCardsContainer from '../../controls/workspace-card-container'
import { Grid } from '@mui/material'
import BackButtonArrow from '../../../assets/icons/left_arrow.png'
import { navigate } from '@reach/router';
import { WORKSPACE_BACK_BUTTON_TEXT } from '../../../constants/strings'
import { getUsersForNotebookShare } from '../../../api/workspace-api/workspace-api'
const Workspace = props => {

    const sectionHeaderProps = {
        title: "Workspace",

    }
    const onBackButtonClick = () => {
        navigate('/explore/tools')
    }
    const [usersForShare, setUsersForShare] = useState([])
    const [isOwnedWorkspacePresent, setIsOwnedWorkspacePresent] = useState(false);
    const getUsersForShare = async () => {
        try {
            const users = await getUsersForNotebookShare();
            if (users?.users) {
                setUsersForShare(users.users)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getUsersForShare();
    }, [])

    return (
        <div className={classes.mainContainer}>
            <SectionHeader
                open={props.open}
                sectionHeaderProps={sectionHeaderProps}
                backButtonText={WORKSPACE_BACK_BUTTON_TEXT}
                onBackButtonClick={onBackButtonClick}
                isOwnedWorkspacePresent={isOwnedWorkspacePresent}
            />

            <Grid container direction='row' xs={12} className={classes.container}>
                <Grid item xs={9} className={classes.leftContainer}>
                    <WorkspaceCardsContainer setIsOwnedWorkspacePresent={setIsOwnedWorkspacePresent} usersForShare={usersForShare} />
                    <WorkspaceSharedFilesContainer className={classes.rightContainer} usersForShare={usersForShare} />
                </Grid>
                <Grid xs={3}>
                    <WorkspaceRightContainer isOwnedWorkspacePresent={isOwnedWorkspacePresent} className={classes.rightContainer} />
                </Grid>
            </Grid>

        </div>
    )
}

export default Workspace;

import React from 'react';
import { Router } from "@reach/router";
import PricingConfigList from './PricingConfigList'
import AddPricingConfig from './AddPricingConfig'

const Pricing = ({ updateSnackBar }) => <Router id="router" primary={false} >
  <PricingConfigList path="/" updateSnackBar={updateSnackBar} />
  <AddPricingConfig path="/add-pricing-configuration" updateSnackBar={updateSnackBar} />
  <AddPricingConfig path="/edit-pricing-configuration/:id" updateSnackBar={updateSnackBar} />
</Router>

export default Pricing;
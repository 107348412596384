/**
 *  External Imports
 */

/**
 *  Internal Imports
 */

/**
 *  Constants
 */
const COMMUNITY_URLS = {
	// Temp
	COMMUNITY: "/explore/community",
	COMMUNITY_CONNECTIONS: "/connections",
	
	// Top Menu
	CONSTELLATION: "/constellation",
	
	// Main
	RECOMMENDED: "/recommended",
	MESSAGES: "/messages",
	REQUESTS: "/requests",
	ACCEPTED_REQUESTS: "/accepted",
	INCOMING_REQUESTS: "/incoming",
	PENDING_REQUESTS: "/pending",
	
	// Global Search
	SEARCH_RESULTS: "/search-results",
};

const COMMUNITY_CONNECTIONS_PARENT_ROUTE = "/explore/community/connections";

const MY_PROFILE_URLS = {
	TELL_US_MORE_WHO_ARE_YOU: "/my-profile/tell-us-more/community-welcome",
};

const BC_COMMUNITY_INFO_URL = "https://www.braincommons.org/community";

/**
 *  Exports
 */
export {
	COMMUNITY_URLS,
	COMMUNITY_CONNECTIONS_PARENT_ROUTE,
	MY_PROFILE_URLS,
	BC_COMMUNITY_INFO_URL
};

import {env} from '../env'
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import RootReducer from './reducers';
import devMiddleware from './middleware/devMiddleware'

const middlewares = [thunk];
if(env.REACT_APP_LOCAL) {
    middlewares.push(devMiddleware)
}

const store = createStore(RootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

export default store;

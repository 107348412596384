import makeStyles from '@mui/styles/makeStyles';

/**
 *  Constants
 */
 const DEFAULT_FIELD_STATE = "";

 const DEFAULT_ERROR_STATE = {nameFieldErr: false, institutionFieldErr: false};
 
 const VALIDATION_ERROR_KEYS = {
     NAME_ERROR: 'nameFieldErr',
     INSTITUTION_ERROR: 'institutionFieldErr',
 };

/**
 *  Styles
 */
 const popoverUseStyles = makeStyles({
	root: {
		margin: "20px 0 0 0",
	},
});

const selectFieldUseStyles = makeStyles({
	root: {
		width: "100%",
		height: "40px",
		fontFamily: "var(--CVB_FONT_FAMILY)",
		fontSize: ".875rem",
		
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "var(--CVB_PLACEHOLDER_COLOR)",
		},
		
		"& .MuiSelect-select:focus": {
			background: "none",
		},
		
		"& .MuiOutlinedInput-input": {
			paddingRight: "12px !important",
			paddingLeft: "12px !important",
		},
		
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "var(--CVB_PLACEHOLDER_COLOR)",
		},
		
		"& .MuiSelect-icon": {
			top: "calc(50% - 11px)",
			right: "7px",
		},
	},
});

const selectFieldMenuUseStyles = makeStyles({
	root: {
		"& .MuiListItem-button:first-child": {
			marginTop: "0 !important",
		},
	},
});

/**
 *  Functions
 */

const isDefaultFieldVal = (val) => val === DEFAULT_FIELD_STATE;
const getRenderVal = (placeholderVal = DEFAULT_FIELD_STATE) => (selectedVal) => (!selectedVal.length ? placeholderVal : selectedVal);

export {
    selectFieldMenuUseStyles,
    selectFieldUseStyles,
    popoverUseStyles,
    VALIDATION_ERROR_KEYS,
    DEFAULT_ERROR_STATE,
    DEFAULT_FIELD_STATE,
    isDefaultFieldVal,
    getRenderVal
}
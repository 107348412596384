/**
 *  Internal Imports
 */

 import WidgetChatMessageInput from "./WidgetChatMessageInput";

 /**
  *  Exports
  */
 export default WidgetChatMessageInput;
 
import DIFDataSharing from './DIFDataSharing'
import { connect } from 'react-redux'
import { setDifDataSharingContributeToNewDataset, setDifDataSharingDatasetName, setDifDataSharingPermissionToShareData,
    setDifDataSharingIRBNumber, setDifDataSharingDocumentFile, setDifDataSharingIsProtectedData, setDifDataSharingDataDeIdentify
} from '../../../redux/actions/difDataSharingActions' 
import { updateSnackBar } from '../../../redux/actions/globalActions'
const mapStateToProps = state => {
    return {
        difDataSharingIsNewDataSet: state.difDataSharing.difDataSharingIsNewDataSet,
        difDataSharingDatasetName: state.difDataSharing.difDataSharingDatasetName,
        difDataSharingPermissionToShare: state.difDataSharing.difDataSharingPermissionToShare,
        difDataSharingIRBNumber: state.difDataSharing.difDataSharingIRBNumber,
        difDataSharingDocumentFile: state.difDataSharing.difDataSharingDocumentFile,
        difDataSharingIsProtectedData: state.difDataSharing.difDataSharingIsProtectedData,
        difDataSharingIsDeIdentity: state.difDataSharing.difDataSharingIsDeIdentity,
        difDataSharingConsentDocument: state.difDataSharing.difDataSharingConsentDocument,
        currentDIFFormStage: state.dataInventory.currentDIFFormStage,
        difDataSharingAssociatedEmail: state.difDataSharing.difDataSharingAssociatedEmail
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setDifDataSharingContributeToNewDataset: async (value) => await dispatch(setDifDataSharingContributeToNewDataset(value)),
        setDifDataSharingDatasetName: async (value) => await dispatch(setDifDataSharingDatasetName(value)),
        setDifDataSharingPermissionToShareData: async (value) => await dispatch(setDifDataSharingPermissionToShareData(value)),
        setDifDataSharingIRBNumber: async (value) => await dispatch(setDifDataSharingIRBNumber(value)),
        setDifDataSharingDocumentFile: async (value) => await dispatch(setDifDataSharingDocumentFile(value)),
        setDifDataSharingIsProtectedData: async (value) => await dispatch(setDifDataSharingIsProtectedData(value)),
        setDifDataSharingDataDeIdentify: async (value) => await dispatch(setDifDataSharingDataDeIdentify(value)),
        updateSnackBar: (message, type) => dispatch(updateSnackBar(message, type))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DIFDataSharing);
const CONSTELLATION_TABS = {
    RECOMMENDED: "Recommended",
    CONNECTIONS: "Connections",
};

const CONSTELLATION_USER_TYPE = {
    RECOMMENDED: "recommendedUser",
    CONNECTED: "connectedUser",
};

const CONSTELLATION_GRAPH_NODE_TYPE = {
    CURRENT_USER: 1,
    COMMON_USER: 2,
    LABEL: 3,
    TABS: 4
};

const GRAPH_LABEL_STATIC_DISTANCE = 0.5;
const GRAPH_NODE_DEFAULT_RADIUS = 60;
const DEFAULT_GRAPH_LINK_DISTANCE = 30;
const DEFAULT_TOOLTIP_HEIGHT = 274;

export {
    CONSTELLATION_TABS,
    CONSTELLATION_GRAPH_NODE_TYPE,
    CONSTELLATION_USER_TYPE,
    GRAPH_LABEL_STATIC_DISTANCE,
    GRAPH_NODE_DEFAULT_RADIUS,
    DEFAULT_GRAPH_LINK_DISTANCE,
    DEFAULT_TOOLTIP_HEIGHT
};

/**
 *  External Imports
 */
import React from "react";
import { func, number, string, oneOfType } from "prop-types";

/**
 *  Internal Imports
 */
import {
    COMMUNITY_CONNECTION_STATUSES,
    communityAPI,
} from "../../../api/community-api";
import styles from "./index.module.css";
import {
    COMMUNITY_URLS,
    COMMUNITY_CONNECTIONS_PARENT_ROUTE,
} from "../../../constants/urls";
import { WIDGET_TYPES } from "../../../constants/widget-types";
import DeleteIcon from '../../../assets/icons/delete-basket.svg';

const CommunityWidgetButtons = (props) => {
    const { widgetType } = props;
    const {
        id,
        userIcon,
        organization,
        reason,
        name,
        recipientID,
        email,
    } = props;
    const {
        onDeclineRecommendedUser,
        onConnectDialogOpen,
        onSearchConnectUser,
        onRecommendedConnectUser,
        onMessageChatRedirect,
        onDeclineIncomingUser,
        onAcceptDialogOpen,
        onAcceptUser,
        onDeleteDialogOpen,
        onDeleteConnectedUser
    } = props;

    const navigateToExactChat = (id) => () => {
        onMessageChatRedirect(
            `${COMMUNITY_CONNECTIONS_PARENT_ROUTE}${COMMUNITY_URLS.MESSAGES}`,
            { userChatId: id }
        );
    };

    const onItemDeleteBtnClick = () => {
        const dialogDeleteData = {
            id: id,
            userIcon: userIcon,
            name: name,
            organization: organization,
            onDeleteUser: onDeleteConnectedUser,
            deleteMethod: communityAPI.deleteAcceptedConnection,
        }

        onDeleteDialogOpen(dialogDeleteData);
    };

    const onItemDeclineBtnClick = async (
        onDeclineUserMethod,
        declineMethod
    ) => {
        const requestParams = {
            id,
            actionStatus: COMMUNITY_CONNECTION_STATUSES.DECLINE,
        };
        const { status } = await declineMethod(requestParams);

        // Checking a response status
        if (status === 200) {
            onDeclineUserMethod(id);
        }
    };
    const onItemConnectBtnClick = (onConnectUserMethod, connectMethod) => {
        const dialogConnectData = {
            userIcon,
            organization,
            reason: reason || "",
            name,
            id,
            onConnectUser: onConnectUserMethod,
            connectMethod: connectMethod,
        };
        onConnectDialogOpen(dialogConnectData);
    };

    const onUserAcceptBtnClick = () => {
        const dialogSuccessData = {
            id,
            userIcon,
            name,
            email,
            connectMethod: communityAPI.postConnectionStatusByID,
            onSuccessEvent: onAcceptUser,
        };

        onAcceptDialogOpen(dialogSuccessData);
    };

    const getCurrentButtons = () => {
        switch (widgetType) {
            case WIDGET_TYPES.RECOMMENDED:
                return (
                    <>
                        <button
                            data-testid="declineBtn"
                            className={styles.declineBtn}
                            onClick={() =>
                                onItemDeclineBtnClick(
                                    onDeclineRecommendedUser,
                                    communityAPI.postRecommendationByID
                                )
                            }>
                            Ignore For Now
                        </button>

                        <button
                            data-testid="connectBtn"
                            className={styles.connectBtn}
                            onClick={() =>
                                onItemConnectBtnClick(
                                    onRecommendedConnectUser,
                                    communityAPI.postRecommendationByID
                                )
                            }>
                            Connect
                        </button>
                    </>
                );
            case WIDGET_TYPES.ACCEPTED:
                return (
                    <>
                        <button
                            data-testid="messageBtn"
                            className={styles.messageBtn}
                            onClick={navigateToExactChat(recipientID)}>
                            Message
                        </button>
                        <button
                            data-testid="deleteBtn"
                            className={styles.deleteBtn}
                            onClick={onItemDeleteBtnClick}>
                            <img src={DeleteIcon} alt="Delete basket"/>
                        </button>
                    </>
                );
            case WIDGET_TYPES.PENDING:
                return null;

            case WIDGET_TYPES.INCOMING:
                return (
                    <>
                        <button
                            data-testid="declineBtn"
                            className={styles.declineBtn}
                            onClick={() =>
                                onItemDeclineBtnClick(
                                    onDeclineIncomingUser,
                                    communityAPI.postConnectionStatusByID
                                )
                            }>
                            Decline
                        </button>

                        <button
                            onClick={onUserAcceptBtnClick}
                            data-testid="acceptBtn"
                            className={styles.connectBtn}>
                            Accept
                        </button>
                    </>
                );
            case WIDGET_TYPES.SEARCH:
                return (
                    <button
                        data-testid="connectBtn"
                        className={styles.connectBtn}
                        onClick={() =>
                            onItemConnectBtnClick(
                                onSearchConnectUser,
                                communityAPI.postConnectionUserByUUID
                            )
                        }>
                        Connect
                    </button>
                );

            default:
                return null;
        }
    };

    return <div className={styles.buttons}>{getCurrentButtons()}</div>;
};

CommunityWidgetButtons.propTypes = {
    id: oneOfType([string, number]),
    userIcon: string,
    name: string,
    organization: string,
    reason: string,
    recipientID: number,
    email: string,
    widgetType: string.isRequired,
    onDeclineRecommendedUser: func.isRequired,
    onConnectDialogOpen: func.isRequired,
    onSearchConnectUser: func.isRequired,
    onRecommendedConnectUser: func.isRequired,
    onMessageChatRedirect: func.isRequired,
    onDeclineIncomingUser: func.isRequired,
    onAcceptDialogOpen: func.isRequired,
    onAcceptUser: func.isRequired,
    onDeleteDialogOpen: func.isRequired,
    onDeleteConnectedUser: func.isRequired
};

export default CommunityWidgetButtons;
